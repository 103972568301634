<template>
  <div class="campaign-form-container">
    <!-- SELECT TRAFFIC SOURCE SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">1</span>
        <h1>Select Traffic Source</h1>
      </div>
      <div class="optimizer-card">
        <!-- Account Select Component -->
        <account-select ref="AccountSelect" :config="config" :mode="mode" :retry="retry" v-model="config.Account.value" isCampaignCreator @onTrafficSourceTypeUpdate="$emit('onTrafficSourceTypeUpdate', $event)" />
      </div>
    </div>

    <loadizer :loading="onInitLoading" message="Loading..." />
    <component v-if="!onInitLoading && $c_accountId && !isFacebookCampaignCreator()" :is="$c_uniqueName" :ref="$c_uniqueName" :config="config" :mode="mode" :retry="retry" :payload="payload" @input="(form) => $emit('input', form)" />
  </div>
</template>

<script>
import props from '../../mixins/props';
import AccountSelect from '../../components/AccountSelect';
import Taboola from './Forms/Taboola';
import RevContent from './Forms/RevContent';
import Outbrain from './Forms/Outbrain';
import Mgid from './Forms/Mgid';
import ContentAd from './Forms/ContentAd';
import Gemini from './Forms/Gemini';
import { TrafficSource } from '@sh/types';

export default {
  name: 'CampaignForm',
  components: {
    AccountSelect,
    Taboola,
    RevContent,
    Outbrain,
    Mgid,
    Adskeeper: Mgid,
    ContentAd,
    Gemini,
  },
  mixins: [props],
  props: {
    payload: { type: Object },
  },
  data() {
    return {
      onInitLoading: false,
    };
  },
  computed: {
    $c_account() {
      try {
        return this.config.Account.value;
      } catch (err) { /* Nothing here */ }
      return null;
    },
    $c_uniqueName() {
      try {
        return this.$c_account.type.uniqueName;
      } catch (err) { /* Nothing here */ }
      return null;
    },
    $c_accountId() {
      try {
        return this.$c_account.id;
      } catch (err) { /* Nothing here */ }
      return null;
    },
  },
  watch: {
    $c_accountId: {
      async handler(value) {
        if (this.isFacebookCampaignCreator()) {
          return;
        }

        if (value) {
          /* ********* onInit lifecycle hook *********** */
          this.onInitLoading = true;
          try {
            await this.config.Models[this.config.Account.value.type.uniqueName].campaign.onInit({ mode: this.mode, cloneMode: this.cloneMode, retry: this.retry, account: this.config.Account.value });
          } catch (err) {
            // Do nothing
            console.log(err);
          }
          this.onInitLoading = false;
          /* ********* ********************* *********** */
        }
      },
      immediate: true,
    },
  },
  methods: {
    validate() {
      return this.isFacebookCampaignCreator() || this.$refs[this.$c_uniqueName].validate();
    },
    isFacebookCampaignCreator() {
      return this.config.Account.selectedTrafficSource?.uniqueName === TrafficSource.Facebook;
    },
  },
};
</script>

<style lang="scss">
.campaign-form-container {
  & > .form-section {
    margin-top: 4rem;
  }

  .form-section {

    &__title {
      margin-bottom: 2rem;

      &__rule-name {
        font-size: 1.7rem;
        font-weight: normal;
        color: $black-600;
      }
    }
  }
}
</style>
