import { IconsV2 } from '@sh/types';

export interface NavItem {
  label: string;
  icon: { type: IconsV2; fill?: boolean };
  url: string;
  children?: Omit<NavItem, 'icon'>[];
  external?: boolean;
  id: string;
  isUnleashProtected?: boolean;
}

export enum NavUrls {
  DASHBOARD = '/dashboard',
  CAMPAIGNS = '/campaigns',
  CAMPAIGN_CREATOR = '/campaign-creator-queue',
  CREATIVE_LIBRARY = '',
  CREATIVE_LIBRARY_CREATIVES = '/media-manager/ads',
  CREATIVE_LIBRARY_IMAGES = '/media-manager/images',
  CREATIVE_LIBRARY_HEADLINES = '/media-manager/headlines',
  AUTOMATION = '',
  RULES = '/rules',
  SMART_LISTS = '/smart-lists',
  ACCOUNT_WIZARD = '/account-wizard',
  TRAFFIC_SOURCES = '/traffic-sources',
  TRACKERS = '/trackers',
  SYSTEM_ACTIVITY = '/system-activity',
  MANUAL_CONNECTION = '/manual-connection',
  REPORTING = '/reporting',
  MANUAL_STATS_UPDATE = '/manual-stats-update',
  INTEGRATIONS = '',
  KNOWLEDGE_BASE = 'https://theoptimizer.io/help',
  FAQ = 'https://theoptimizer.io/help/knowledge-base/frequently-asked-questions-faq/',
  BLOG = 'http://blog.theoptimizer.io/',
}

const navItems: NavItem[] = [
  {
    label: 'Dashboard',
    url: NavUrls.DASHBOARD,
    icon: { type: 'sidebarDashboard' },
    id: 'dashboard',
  },
  {
    label: 'Campaigns',
    url: NavUrls.CAMPAIGNS,
    icon: { type: 'sidebarCampaigns' },
    id: 'campaigns',
  },
  {
    label: 'Campaign Creator',
    url: NavUrls.CAMPAIGN_CREATOR,
    icon: { type: 'sidebarCampaignCreator', fill: true },
    id: 'campaign-creator',
  },
  {
    label: 'Reporting',
    url: NavUrls.REPORTING,
    icon: { type: 'sidebarReporting' },
    id: 'reporting',
  },
  {
    label: 'Logs',
    url: NavUrls.SYSTEM_ACTIVITY,
    icon: { type: 'sidebarSystemActivity' },
    id: 'system-activity',
  },
  {
    label: 'Creative Library',
    url: NavUrls.CREATIVE_LIBRARY,
    icon: { type: 'sidebarCreativeLibrary' },
    isUnleashProtected: true,
    id: 'media-manager',
    children: [
      {
        label: 'Creatives',
        url: NavUrls.CREATIVE_LIBRARY_CREATIVES,
        id: 'creatives',
      },
      {
        label: 'Images',
        url: NavUrls.CREATIVE_LIBRARY_IMAGES,
        id: 'images',
      },
      {
        label: 'Headlines',
        url: NavUrls.CREATIVE_LIBRARY_HEADLINES,
        id: 'headlines',
      },
    ],
  },
  {
    label: 'Automation',
    url: NavUrls.AUTOMATION,
    icon: { type: 'sidebarAutomation' },
    id: 'automation',
    children: [
      {
        label: 'Rules',
        url: NavUrls.RULES,
        id: 'rules',
      },
      {
        label: 'Smart Lists',
        url: NavUrls.SMART_LISTS,
        id: 'smart-lists',
      },
      {
        label: 'Stats Update',
        url: NavUrls.MANUAL_STATS_UPDATE,
        id: 'manual-stats-update',
      },
    ],
  },
  {
    label: 'Integrations',
    url: NavUrls.INTEGRATIONS,
    icon: { type: 'sidebarIntegrations' },
    id: 'integrations',
    children: [
      {
        label: 'Account Wizard',
        url: NavUrls.ACCOUNT_WIZARD,
        id: 'account-wizard',
      },
      {
        label: 'Traffic Sources',
        url: NavUrls.TRAFFIC_SOURCES,
        id: 'traffic-sources',
      },
      {
        label: 'Trackers',
        url: NavUrls.TRACKERS,
        id: 'trackers',
      },
      {
        label: 'Manual Connection',
        url: NavUrls.MANUAL_CONNECTION,
        id: 'manual-connection',
      },
    ],
  },
  {
    label: 'Blog',
    url: NavUrls.BLOG,
    icon: { type: 'sidebarBlog' },
    external: true,
    id: 'blog',
  },
  {
    label: 'Help Center',
    url: NavUrls.KNOWLEDGE_BASE,
    icon: { type: 'sidebarHelpCenter' },
    external: true,
    id: 'knowledge-base',
  },
];

const navItemsByPlatform = {
  native: navItems,
  compliance: [
    {
      id: 'compliance',
      label: 'Reporting',
      url: NavUrls.REPORTING,
      icon: { type: 'sidebarReporting' },
    },
  ] as NavItem[],
};

export default navItemsByPlatform;
