
import { useMediaManagerStore } from '@/stores/media-manager';
import MediaManager from '@/views/Automation/MediaManager/MediaManager.ts.vue';
import ImageHelper from '@sh/components/Utils/ImageEditor/ImageHelper';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';
import { MediaManager as MediaManagerTypes } from '@sh/types';
import md5 from 'md5';
import Vue, { PropType, VueConstructor } from 'vue';

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    MediaManager,
    OptimizerIcon,
  },
  mixins: [notifications],
  model: {
    prop: 'isModalOpen',
    event: 'update:isModalOpen',
  },
  props: {
    mode: {
      type: String as PropType<MediaManagerTypes.Mode>,
      required: true,
    },
    trafficSource: {
      type: String,
      required: false,
    },
    maxDataLength: Number,
    isModalOpen: Boolean,
    entity: String,
  },
  data() {
    return {
      isAddContentsPreloader: false,
      isOpen: false,
    };
  },
  watch: {
    isModalOpen: {
      immediate: true,
      handler() {
        this.isOpen = this.isModalOpen;
      },
    },
    isOpen(value) {
      if (value !== this.isModalOpen) {
        this.$emit('update:isModalOpen', value);
      }
    },
  },
  computed: {
    MediaManagerMode() {
      return MediaManagerTypes.Mode;
    },
    selectedAds() {
      return useMediaManagerStore().selectedAds;
    },
  },
  methods: {
    parallel: (iterator: Array<any>, cb: Function) => {
      const promises = [];
      let index = 0;
      for (const item of iterator) {
        promises.push(cb(item, index, iterator));
        index += 1;
      }
      return Promise.all(promises);
    },
    onAddDataClick() {
      switch (this.mode) {
        case MediaManagerTypes.Mode.ADS: {
          this.onAddContents();
          break;
        }
        case MediaManagerTypes.Mode.IMAGES: {
          this.onAddImages();
          break;
        }
        case MediaManagerTypes.Mode.HEADLINES: {
          this.onAddHeadlines();
          break;
        }
      }
    },
    onAddImages() {
      const images = this.selectedAds.map((item) => item.image?.base64);
      this.$emit('add', images);
      this.$emit('update:isModalOpen', false);
    },
    onAddHeadlines() {
      const headlines = this.selectedAds.map((item) => item.headline);
      this.$emit('add', headlines);
      this.$emit('update:isModalOpen', false);
    },
    async onAddContents() {
      this.isAddContentsPreloader = true;
      try {
        const urls = this.selectedAds.reduce((filtered: string[], item) => {
          if (item.image?.base64) {
            filtered.push(item.image.base64);
          }
          return filtered;
        }, []);
        const response = await this.$api.uploader.imageProxy(urls);
        const dataToEmit: any[] = [];

        await this.parallel(this.selectedAds, async (item: MediaManagerTypes.AdCard) => {
          if (item.image?.base64 && response[item.image.base64]) {
            try {
              const infoImage = await ImageHelper.getInfoFromBase64Array([response[item.image.base64]]);
              if (infoImage.errors.length === 0) {
                const imageData: any = infoImage.images[0];
                imageData.md5 = md5(imageData.data);
                dataToEmit.push({
                  headline: item.headline,
                  image: imageData,
                });
              }
            } catch {
              throw new Error('Something went wrong while getting image info. Please contact support!');
            }
          }
        });
        this.$emit('add', dataToEmit);
        this.$emit('update:isModalOpen', false);
      } catch (error: any) {
        this.$n_failNotification({
          title:
            error?.response?.data?.message ||
            error?.message ||
            'We could not add selected contents, please try again or contact support.',
        });
      }
      this.isAddContentsPreloader = false;
    },
  },
});
