import { Description, DescriptionVariables, Field, FieldDescription } from '@sh/configurations/fields/Field';
import { fieldsMap } from '@sh/configurations/fields/nativeFields';
import { SystemField } from '@sh/configurations/fields/SystemField';

type FormulaBook = Record<string, { text?: string; header?: { content?: string } }>;
function parseFormula(formula: string, book: FormulaBook = {}) {
  // eg:when you pass the formula (revenue-cost)/cost, it will first try to replace revenue, cost, and other terms with their corresponding text values from the book object.
  const sortedKeys = Object.keys(book).sort((a, b) => b.length - a.length);
  const regex = new RegExp(sortedKeys.map((key) => `\\b${key}\\b`).join('|'), 'g');
  return formula.replace(
    regex,
    (value) => book[value]?.text || book[value]?.header?.content || book[value]?.header?.content || value
  );
}

function buildVariableHtmlValue(variable?: DescriptionVariables, book?: FormulaBook) {
  if (variable?.type === 'TEXT') {
    return `<span class="text">${variable.value}</span>`;
  }
  if (variable?.type === 'URL') {
    return `<a href="${variable.value}" target="_blank" rel="noopener noreferrer">${variable.alt}</a>`;
  }
  if (variable?.type === 'FORMULA') {
    return `<span class="formula">${parseFormula(variable.value, book)}</span><br>`;
  }
}

function templateParser(data: FieldDescription, book: FormulaBook) {
  const regex = /{{(.*?)}}/g;
  const matches = data.text?.match(regex);
  let parsedTemplate = data.text;
  (matches || []).forEach((match: string) => {
    const variable = match.replace('{{', '').replace('}}', '');
    const value = (data.variables || {})[variable];
    const parsedValue = buildVariableHtmlValue(value, book);
    if (parsedValue) {
      parsedTemplate = parsedTemplate.replace(match, parsedValue);
    }
  });
  if (parsedTemplate.includes('NOTE') || parsedTemplate.includes('Note')) {
    parsedTemplate = parsedTemplate.replace(/(NOTE|Note):\s*(.*?)(?:<br>|$)/g, (_, note, rest) => {
      const formattedNote = note.toUpperCase();
      const formattedRest = rest.charAt(0).toUpperCase() + rest.slice(1);
      return `<br><span class="note">${formattedNote}: </span><br>${formattedRest}`;
    });
  }

  // Add margin or padding to the text preceding <span class="text">
  const textBeforeSpanRegex = /([^<>]+)(?=\s*<span class="text">)/;
  const match = parsedTemplate.match(textBeforeSpanRegex);
  if (match) {
    const textBeforeSpan = match[0];
    const styledTextBeforeSpan = `<div style="margin-bottom: 8px;">${textBeforeSpan}</div>`;
    parsedTemplate = parsedTemplate.replace(textBeforeSpan, styledTextBeforeSpan);
  }

  return parsedTemplate;
}

function getFieldDescription(field: Field, showSuggestion = false, tsTrType = 'default') {
  const description = field.description || ({} as Description);
  const descriptionOverride = description.overrides?.[tsTrType] || {};
  const defaultDescription: Description['default'] = {
    text: `${showSuggestion ? '{{DESCRIBE_TEXT}}' : ''}`,
    variables: {
      DESCRIBE_TEXT: {
        value: 'Go to Dashboard to switch between Traffic Source/Tracker metrics.',
        type: 'TEXT',
      },
    },
  };
  return {
    text: [descriptionOverride.text || description.default?.text, defaultDescription.text].filter(Boolean).join('<br>'),
    variables: {
      ...defaultDescription.variables,
      ...(description.default?.variables || {}),
      ...(descriptionOverride?.variables || {}),
    },
  };
}

export function getFieldTextAndDescription(field: Field, showSuggestion = false, tsTrType = 'default') {
  const fieldDescription = getFieldDescription(field, showSuggestion, tsTrType);

  // Remove dot after {{formula}} if it exists
  const fieldDescriptionWithoutDot = fieldDescription.text.replace(/{{formula}}\./, '{{formula}}');

  // Replace the text before <br>{{DESCRIBE_LINK}} with a <div> element
  const fieldDescriptionFinal = fieldDescriptionWithoutDot.replace(
    /(.*?)<br>{{DESCRIBE_LINK}}/,
    '<div style="margin-bottom: 8px;">$1</div>{{DESCRIBE_LINK}}'
  );
  fieldDescription.text = fieldDescriptionFinal;
  const description = templateParser(fieldDescription, fieldsMap);
  const text = field?.label?.default || field?.label?.overrides?.[tsTrType] || field?.text || '';
  return { text, description };
}

function parseInfoSystemField(field: SystemField, fieldMap: Record<string, SystemField>) {
  if (field && field.header && field.header.info) {
    try {
      const jsonInfo = JSON.parse(field.header.info);
      if (jsonInfo && jsonInfo.default) {
        field.header.info = templateParser(jsonInfo.default, fieldMap);
      }
    } catch (e) {
      return field;
    }
  }
  return field;
}

export function getFieldsWithDescription(fields: SystemField[]) {
  const fieldMap = fields.reduce((acc, field) => {
    acc[field.item.key] = field;
    return acc;
  }, {} as Record<string, SystemField>);
  return fields.map((field) => parseInfoSystemField(field, fieldMap));
}
