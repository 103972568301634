<template>
  <div class="connect-wizard-step account-wizard-step">
    <div class="config-tracker-sources" v-if="!finished">
      <h4 class="lead text-center mb-4">{{ $c_titleText }}</h4>
      <b-alert :show="alert" variant="danger" dismissible @dismissed="alert=false">{{ alert_message }}</b-alert>
      <div class="connect-wizard-container container">
        <div class="row">
          <div class="col-md-3">
            <div class="multiple-account-container pointer">
              <div class="account-wizard-card min" :class="{item: sources.length > 1 }">
                <div class="account-wizard-card__name">
                </div>
                <div :class="'account-wizard-card__image'">
                  <img :src="$c_sourceLogo" alt="account-wizard-logo">
                </div>
                <div class="fs-16 fw-400">
                  <p>{{ sources.length }} {{ sources.length === 1 ? 'account' : 'accounts' }} selected to connect</p>
                  <button v-if="sources.length > 1" class="button-link" @click="changeVisibility" mode="out-in">
                    {{ showAllAccounts ? 'Close' : 'All Accounts' }}
                    <svg
                      v-if="!showAllAccounts"
                      class="bi bi-caret-down-fill"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div v-if="sources.length > 1" :class="{ hidden: !showAllAccounts }">
                <div class="multiple-account-wizard-card-left">
                  <div v-for="(source, i) in sources" :key="'source' + source.name + i" class="account-wizard-item">
                    <div class="account-wizard-card__name">
                      <h5 class="fs-16 fw-400" :title="source.name">{{ source.name.length > 19 ? `${source.name.slice(0, 19)}...` : source.name }}</h5>
                    </div>
                    <div :class="{'account-wizard-card__image': sources.length === 1}">
                      <img :src="sources.length === 1 ? source.type.logo : `/sh_static/accounts/${source.type.uniqueName}.png`" class="d-inline-block acc-wiz-img" alt="account-wizard-image">
                    </div>
                    <div class="account-wizard-card__type">
                      <span>{{ source.type.uniqueName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <template v-if="Object.keys(tracker).length">
              <div class="connect-accounts-container optimizer-card">
                <GoogleAnalytics4 v-if="tracker.type.uniqueName === 'GoogleAnalytics4'" ref="GoogleAnalytics4" :account-id="accountId" :tracker="tracker" :source="sources" @completed="$_onCompleted" @invalid="$_onInvalid" />
                <GoogleAnalyticsTokens v-if="tracker.type.uniqueName === 'GoogleAnalytics'" ref="GoogleAnalytics" :account-id="accountId" :tracker="tracker" :source="sources" @completed="$_onCompleted" @invalid="$_onInvalid" />
                <AssertiveYieldTokens v-if="tracker.type.uniqueName === 'AssertiveYield'" ref="AssertiveYield" :account-id="accountId" :tracker="tracker" :source="sources" @completed="$_onCompleted" @invalid="$_onInvalid" />
                <MediaNetTokens ref="Media" v-if="tracker.type.uniqueName === 'Media'"
                                :account-id="accountId" :tracker="tracker" :source="sources" @completed="$_onCompleted" @uncompleted="$_onUnCompleted"
                />
                <AccountWizardTokens v-else ref="tokens" :account-id="accountId" :tracker="tracker" :sources="sources" @completed="$_onCompleted" @uncompleted="$_onUnCompleted" />
              </div>
            </template>
          </div>
          <div class="col-md-3">
            <div class="account-wizard-card-right">
              <div
                v-if="Object.keys(tracker).length > 0"
                :key="'tracker' + tracker.type.uniqueName"
                class="account-wizard-card item min"
                :class="[ 'type-' + tracker.type.uniqueName ]"
              >
                <div class="account-wizard-card__name">
                  <h5>{{ tracker.name }}</h5>
                </div>
                <div class="account-wizard-card__image">
                  <img :src="tracker.type.logo" alt="tracker-logo">
                </div>
                <div class="account-wizard-card__type">
                  <span>{{ tracker.type.uniqueName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="finished">
      <Finish
        :sources="sources"
        :tracker="tracker"
        :tokensData="$c_tokensData"
        :sourceIdOnTracker="sourceIdOnTracker"
      />
    </div>
  </div>
</template>

<script>
import AccountWizardTokens from './components/Tokens';
import GoogleAnalytics4 from './components/GoogleAnalytics4';
import GoogleAnalyticsTokens from './components/GoogleAnalyticsTokens';
import AssertiveYieldTokens from './components/AssertiveYieldTokens';
import MediaNetTokens from './components/MediaNetTokens.ts.vue';
import Finish from './components/Finish.ts.vue';
import notifications from '@sh/mixins/notifications';
import configFile from './config';
import uid from 'uid';
import { isArray } from 'lodash';
import { Tracker } from '@sh/types';

export default {
  name: 'AccountWizardConnect',
  components: {
    AccountWizardTokens,
    GoogleAnalyticsTokens,
    AssertiveYieldTokens,
    Finish,
    GoogleAnalytics4,
    MediaNetTokens,
  },
  mixins: [notifications],
  props: {
    tracker: {
      type: Object,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
    accountId: null,
  },
  data() {
    return {
      alert: false,
      alert_message: '',
      valid: false,
      tokens: {},
      finished: false,
      showAllAccounts: false,
      trafficSourceTokens: [],
      sourceIdOnTracker: '',
    };
  },
  computed: {
    $c_componentRef() {
      try {
        if (this.tracker.type.uniqueName && this.$refs[this.tracker.type.uniqueName]) {
          return this.$refs[this.tracker.type.uniqueName];
        }
        return this.$refs.tokens;
      } catch {
        return null;
      }
    },
    $c_sourceLogo() {
      return this.sources[0]?.type?.logo;
    },
    $c_tokensData() {
      const displayNames = [];
      const tokens = [];
      const tokenVariables = isArray(this.tokens.variables) ? this.tokens.variables : [this.tokens.variables];

      if (this.tracker?.type?.uniqueName === Tracker.GoogleAnalytics4) {
        this.tokens.variables.forEach((item) => {
          displayNames.push(item.propertyName);
          tokens.push(item.tokens);
        });
      }
      return {
        tokens: tokens.length ? tokens : tokenVariables,
        displayNames,
      };
    },
    $c_isExternalSource() {
      return this.tracker?.type?.uniqueName === 'CSVSource';
    },
    $c_titleText() {
      if (this.$c_isExternalSource) {
        return '';
      }
      return '* Connect the Traffic Source Account to the Tracker by selecting one of the available sources from the list.';
    },
  },
  async created() {
    this.config = await configFile.getConfig();
    this.trafficSourceTokens = await this.$apiStore.trafficSources?.trackingUrlTokens();
  },
  methods: {
    async isCompleted() {
      const valid = await this.$c_componentRef.validate();
      if (valid) {
        this.$_hideAlert();
        return true;
      }
      this.$_showAlert('Please complete form');
      return false;
    },
    changeVisibility() {
      if (this.showAllAccounts) {
        this.showAllAccounts = false;
      } else {
        this.showAllAccounts = true;
      }
    },
    load() {
      this.$_hideAlert();
    },
    async save() {
      const response = await this.$_save();
      this.$apiStore.trafficSources.accounts.clearCache();
      this.$apiStore.trafficSources.accounts();

      if (response[0].status === 1) {
        this.$n_successNotification({ title: response[0].message });
        this.finished = true;
        return true;
      }
      this.$n_failNotification({ title: response[0].message });
      return false;
    },
    setFinished() {
      this.finished = true;
    },
    $_onCompleted(data) {
      this.valid = true;
      this.tokens = data;
    },
    $_onUnCompleted(data) {
      this.valid = false;
      this.tokens = data;
    },
    $_isExternalSource() {
      return this.tracker.type.uniqueName === 'CSVSource';
    },
    $_titleText() {
      if (this.$_isExternalSource()) {
        return '';
      }
      return '* Connect the Traffic Source Account to the Tracker by selecting one of the available sources from the list.';
    },
    $_onInvalid() {
      this.valid = false;
      this.tokens = {};
    },
    async $_save() {
      try {
        const sourceIdOnTracker = this.tokens.sourceIdOnTracker || 'null';
        this.sourceIdOnTracker = sourceIdOnTracker;

        if (this.tracker.type.uniqueName === 'GoogleAnalytics4') {
          return this.$_saveGoogleAnalytics4();
        }
        if (this.$c_isExternalSource) {
          return this.$_saveCSVSource();
        }

        const promises = this.sources.map((source) => this.config.api.accountWizard.linkTracker(source.id, this.tracker.id, this.tokens.variables, this.sourceIdOnTracker));
        return Promise.all(promises);
      } catch (error) {
        this.$_showAlert(error.response.data.message);
        return false;
      }
    },
    async $_saveCSVSource() {
      const { success, error } = await this.config.api.accountWizard.validateHeaders(this.tracker.credentials, []);
      if (!success) {
        this.$_showAlert(error);
        return false;
      }
      const promises = this.sources.map((source) => this.config.api.accountWizard.linkTracker(source.id, this.tracker.id, this.tokens.variables, this.sourceIdOnTracker));
      return Promise.all(promises);
    },
    async $_saveGoogleAnalytics4() {
      try {
        const promises = [];
        const { tokens } = this.$c_tokensData;
        const metrics = await this.$refs.GoogleAnalytics4.onConfigurationSave(this.tokens.variables);

        metrics.forEach((item, index) => {
          const existingSourceId = this.tokens.variables.find((data) => item.propertyId === data.propertyId && item.accountId === data.accountId)?.sourceIdOnTracker;
          const sourceIdOnTracker = existingSourceId || uid(32);
          promises.push(...this.sources.map((source) => this.config.api.accountWizard.linkTracker(source.id, this.tracker.id, tokens[index], sourceIdOnTracker, item)));
        });

        return Promise.all(promises);
      } catch {
        this.$n_failNotification({ title: 'Something went wrong while saving fields, please try again or contact support.' });
        return false;
      }
    },
    $_showAlert(msg) {
      this.alert_message = msg;
      this.alert = true;
    },
    $_hideAlert() {
      this.alert_message = '';
      this.alert = false;
    },
  },
};
</script>

<style lang="scss">
.connect-wizard-step {
  .config-tracker-sources {
    .connect-wizard-container {
      .account-wizard-card {
        border-radius: 1rem;
        border: $border;
        min-height: 15rem;
        cursor: default;

        &.type-GoogleAnalytics,
        &.type-GoogleAnalytics4,
        &.type-Kintura,
        &.type-FunnelFlux {
          img {
            height: 5.5rem !important;
            width: auto;
          }
        }
      }
    }
  }
}

.button-link {
  background-color: transparent;
  border: none;
  color: $blue-900;
  margin-top: 1rem;
  transition: all 0.25s ease-in;
}

.multiple-account-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.multiple-account-wizard-card-left {
  max-height: 17rem;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem 0.8rem;
  background-color: $color-white;
  border-radius: 1rem;
  box-shadow: $card-bs-md;
  position: relative;
  transition: all 0.25s ease-in;

  .account-wizard-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    border-radius: 0.6rem;
    padding: 0.45rem;
    height: 4rem;
    width: 100%;
    background-color: $color-light;

      .acc-wiz-img {
        height: 3rem;
        width: 3rem;
      }
  }

  .account-wizard-total-accounts {
    position: sticky;
    top: -0.5rem;
    background-color: $color-white;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    font-style: italic;
  }
}
</style>
