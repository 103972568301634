
import ExistingContentsModal from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/ExistingContentsModal.ts.vue';
import ImageEditor from '@sh/components/Utils/ImageEditor/ImageEditor.vue';
import ImageHelper from '@sh/components/Utils/ImageEditor/ImageHelper';
import Loading from '@sh/components/Utils/Loading.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { addImageEditorScript } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { ImageEditorType, ImageHelperType } from '@sh/types/ImageTypes';
import md5 from 'md5';
import Vue, { PropType, VueConstructor } from 'vue';

interface ImageData {
  cropped: Partial<ImageHelperType>;
  original: ImageHelperType;
}

interface ComponentData {
  dragOver: boolean;
  images: ImageData[];
  imagesUrlModal: {
    textarea: string;
    loading: boolean;
    count: number;
    error: string;
  };
  activeIndex: number | null;
  isExistingContentsModal: boolean;
  isImageEditorModal: boolean;
  isImageUrlModal: boolean;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    Loading,
    ExistingContentsModal,
    ImageEditor,
    OptimizerIcon,
  },
  mixins: [notifications],
  props: {
    size: {
      type: Object,
      default: () => ({
        max: 3000,
      }),
    },
    dimension: {
      type: Object,
      default: () => ({
        minWidth: 150,
        minHeight: 150,
      }),
    },
    allowedExt: { type: Array as PropType<string[]>, default: () => ['jpg', 'jpeg', 'gif', 'png'] },
    maxSize: { type: Number, default: 5000 },
    maxImages: { type: Number, default: 5 },
    trafficSourceType: {
      type: Object as PropType<MediaManager.TrafficSource>,
      required: false,
    },
    isUploadFromExistingImagesButton: { type: Boolean, default: true },
    isDisabled: { type: Boolean, default: false },
  },
  data(): ComponentData {
    return {
      dragOver: false,
      images: [],
      imagesUrlModal: {
        textarea: '',
        loading: false,
        count: 0,
        error: '',
      },
      activeIndex: null,
      isExistingContentsModal: false,
      isImageEditorModal: false,
      isImageUrlModal: false,
    };
  },
  computed: {
    maxSizeReadHuman(): string {
      const sizeMax = this.size ? this.size.max : this.maxSize;
      return sizeMax > 1000 ? `${Math.round(sizeMax / 10) / 100} Mb` : `${Math.round(sizeMax * 100) / 100} Kb`;
    },
    activeImage(): ImageData | null {
      if (this.activeIndex !== null) {
        return this.images[this.activeIndex];
      }
      return null;
    },
    textareaUrls(): string[] {
      const text = this.imagesUrlModal.textarea.replace(/(\r\n|\n|\r)/gm, '\n');
      const rows = text.split('\n');
      const urls = rows.filter((url) => this.$validator.rules.url.validate(url));
      return urls.filter((url, index, arr) => arr.indexOf(url) === index);
    },
    isImageWarning(): boolean[] {
      return this.images.map(
        (image) =>
          !!(
            this.dimension.warning &&
            (image.original.width < this.dimension.warning.maxWidth ||
              image.original.height < this.dimension.warning.maxHeight)
          )
      );
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    trafficSourceUniqueName(): string | undefined {
      return this.trafficSourceType?.uniqueName;
    },
  },
  watch: {
    images: {
      handler(images: ImageData[]) {
        const imagesPayload = images.filter((image) => (!image.original.limit.isValid ? !!image.cropped.data : true));
        this.$emit('input', imagesPayload);
      },
      deep: true,
    },
  },
  mounted() {
    addImageEditorScript();
  },
  methods: {
    setDragOver(dragOver: boolean) {
      this.dragOver = dragOver;
    },
    async addFiles(files: FileList | string[]) {
      this.imagesUrlModal.loading = true;
      let exceedImagesLimit = false;

      if (this.images.length < this.maxImages) {
        try {
          let infoImages: { images: ImageHelperType[]; errors: any[] };

          if (files instanceof FileList) {
            infoImages = await ImageHelper.getInfoFromFiles(files);
          } else {
            infoImages = await ImageHelper.getInfoFromBase64Array(files);
          }
          let errorImages = infoImages.errors.length;

          infoImages.images.forEach((image) => {
            let valid = true;

            // Max images limit exceeded
            if (!valid || this.images.length >= this.maxImages) {
              valid = false;
              exceedImagesLimit = true;
            }

            // Max image size exeeded
            if (this.size.max && (!valid || image.size > this.size.max)) {
              valid = false;
            }

            // Ext. not allowed
            if (!valid || this.allowedExt.indexOf(image.ext) < 0) {
              valid = false;
            }

            // Resolve dimensions
            const dimension = this.dimension.validateRatio
              ? this.dimension.validateRatio(image.width, image.height)
              : this.dimension;

            // Min dimesion exeeded
            if (dimension.minWidth && dimension.minHeight) {
              if (!valid || (image.width < dimension.minWidth && image.width < this.dimension.minWidth)) valid = false;
              if (!valid || (image.height < dimension.minHeight && image.height < this.dimension.minHeight))
                valid = false;
            }

            image.limit = dimension;
            image.md5 = md5(image.data);

            if (valid) {
              this.images.push({ original: image, cropped: {} });
            } else {
              errorImages++;
            }
          });

          if (errorImages > 0) {
            const minDimText =
              this.dimension.minWidth && this.dimension.minHeight
                ? `Min image dim. allowed is (${this.dimension.minWidth}x${this.dimension.minHeight}) px.`
                : '';
            const errorMessage = `\nOnly images with extension (${this.allowedExt.join(',')}) are allowed.
                                  ${
                                    this.size.max !== Number.MAX_SAFE_INTEGER
                                      ? `\nMax image size allowed is ${this.maxSizeReadHuman}.`
                                      : ''
                                  }
                                  \n${minDimText}`;
            this.$n_failNotification({ title: `(${errorImages}) Invalid Images`, message: errorMessage });
          }
        } catch (error) {
          /* Nothing here */
        }
      } else {
        // Max images limit exceeded
        exceedImagesLimit = true;
      }

      if (exceedImagesLimit) {
        this.$n_failNotification({ title: `Only ${this.maxImages} images allowed` });
      }
      this.imagesUrlModal.loading = false;
    },
    resetImage(index: number) {
      this.images[index].cropped = {};
    },
    removeImage(index: number) {
      this.images = this.images.filter((image, _index) => index !== _index);
    },
    onImageEditorOpen(index: number) {
      this.activeIndex = index;
      this.isImageEditorModal = true;
    },
    async fetchImages(imageUrls?: string[]) {
      const urls = imageUrls ?? this.textareaUrls;

      if (urls.length <= 0) {
        this.$n_failNotification({ title: '(0) Urls found' });
        return;
      }

      if (this.images.length + urls.length >= this.maxImages) {
        this.$n_failNotification({ title: `Only ${this.maxImages} images allowed` });

        if (this.images.length >= this.maxImages) {
          return;
        }
      }
      this.imagesUrlModal.loading = true;
      let response: string[] = [];

      try {
        response = Object.values(await this.$api.uploader.imageProxy(urls));

        if (response.length !== urls.length) {
          this.$n_failNotification({ title: `(${urls.length - response.length}) invalid urls` });
        }
      } catch (error: any) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }

      if (response.length > 0) {
        await this.addFiles(response);
        this.isImageUrlModal = false;
      }
      this.imagesUrlModal.loading = false;
    },
    hiddenUrlModal() {
      this.imagesUrlModal.textarea = '';
      this.imagesUrlModal.loading = false;
    },
    shownUrlModal() {
      this.imagesUrlModal.textarea = '';
      this.imagesUrlModal.loading = false;
    },
    getEditedImage(editedImageObject: ImageEditorType) {
      if (this.activeIndex !== null) {
        const { original } = this.images[this.activeIndex];

        this.images[this.activeIndex].cropped = {
          data: editedImageObject.imageBase64,
          ext: original.ext,
          mimeType: original.mimeType,
          width: editedImageObject.width,
          md5: md5(editedImageObject.imageBase64),
          height: editedImageObject.height,
          size: ImageHelper.getBase64ImageSize(editedImageObject.imageBase64),
        };
      }
      this.isImageEditorModal = false;
    },
    async onFileDrop(event: DragEvent) {
      this.setDragOver(false);

      if (event.dataTransfer) {
        const { files } = event.dataTransfer;
        await this.addFiles(files);
      }
    },
    async onAddFileClick(event: Event) {
      const { files } = event.target as HTMLInputElement;

      if (files) {
        await this.addFiles(files);
        (this.$refs.hiddenInputFile as HTMLInputElement).value = '';
      }
    },
    onHiddenInputFileClick() {
      if (!this.isDisabled) {
        (this.$refs.hiddenInputFile as HTMLInputElement).click();
      }
    },
    onSaveClick() {
      const saveButton = document.getElementsByClassName('FIE_topbar-save-button')[0];
      (saveButton as HTMLButtonElement)?.click();
    },
    onCancelClick() {
      const closeButton = document.getElementsByClassName('FIE_topbar-close-button')[0];
      (closeButton as HTMLButtonElement)?.click();
      this.isImageEditorModal = false;
    },
  },
});
