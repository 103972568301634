<template>
  <!-- Modal Component -->
  <b-modal
    id="tracker-modal"
    v-model="modal"
    modal-class="optimizer-modal"
    :no-close-on-backdrop="noCloseOnBlur"
    :no-close-on-esc="noCloseOnBlur"
    size="md"
    centered
    @ok="submit"
    @hidden="$_hidden"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 v-if="form.action === 'edit'" class="modal-header__title">Edit Tracker</h2>
      <h2 v-else class="modal-header__title">New Tracker</h2>

      <svg class="modal-header__close-icon" @click="hideModal" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Modal Body -->
    <Tracker ref="trackerForm" :mode="form" />

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn :disabled="loadingStatus" class="secondary-button" @click="hideModal">Cancel</b-btn>
      <b-btn :disabled="loadingStatus" class="primary-button" @click="submit"><template v-if="loadingStatus"><i class="fa fa-spinner fa-spin mr-2"></i> </template>Save</b-btn>
    </template>

  </b-modal>
</template>

<style scoped>

</style>

<script>
import Tracker from './Tracker.vue';

export default {
  name: 'TrackerModal',
  components: {
    Tracker,
  },
  props: {
    noCloseOnBlur: { type: Boolean, default: false },
  },
  data() {
    return {
      modal: false,
      form: { // action: create|edit, accountId: null|Number
        action: 'create',
        accountId: null,
        account: null,
      },
      loadingStatus: false,
    };
  },
  created() {
    // console.info('Created: TrackerModal')
  },
  methods: {
    showModal(data = {}) {
      this.$_setForm(data);
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    async submit(event) {
      // Prevent modal from closing
      event.preventDefault();
      this.loadingStatus = true;
      try {
        const tracker = await this.$refs.trackerForm.save();
        if (tracker !== false) this.modal = false;
        this.$emit('submit', tracker);
      } catch (error) {
        this.$emit('submit', false);
      }
      this.loadingStatus = false;
    },
    $_hidden() {
      this.$_setForm({}); // Reset
    },
    $_setForm({ action = 'create', accountId = null, account = null }) {
      this.form.action = action;
      this.form.accountId = accountId;
      this.form.account = account;
    },
  },
};
</script>
