var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.CcModal,{attrs:{"modalClass":"cc-modal-transparency-program","size":"lg","isModalOpen":_setup.isModalOpen,"save":_setup.save},on:{"onModalClose":_setup.onModalClose,"onCancelClick":_setup.onModalClose,"onSaveClick":_setup.onSaveClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Manage Transparency Program "),(_setup.isLoading)?_c('b-spinner',{attrs:{"variant":"primary"}}):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:({
        content: _setup.isLoading ? 'Loading...' : '',
        customClass: 'cc-popover',
        boundary: 'viewport',
      }),expression:"{\n        content: isLoading ? 'Loading...' : '',\n        customClass: 'cc-popover',\n        boundary: 'viewport',\n      }",modifiers:{"hover":true,"right":true}}]},[_c('div',{staticClass:"d-flex align-items-center mb-12 py-6",class:{ disabled: _setup.isLoading }},[_c('label',{staticClass:"pointer m-0 mr-6 fw-600",attrs:{"for":"search-feed-active"}},[_vm._v("Share Transparency Program:")]),_c(_setup.OptimizerIcon,{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
            content: `The Transparency Program allows you to share the creatives you are using in TheOptimizer with a search feed provider. The information that will be shared is limited to: creative image or video, text, destination url, traffic source clicks, search feed clicks. The search feed provider will not be able to see any other information about your campaign (like budgets, bids, targeting, spending amount, revenue, etc).`,
            customClass: 'cc-popover',
            boundary: 'viewport',
          }),expression:"{\n            content: `The Transparency Program allows you to share the creatives you are using in TheOptimizer with a search feed provider. The information that will be shared is limited to: creative image or video, text, destination url, traffic source clicks, search feed clicks. The search feed provider will not be able to see any other information about your campaign (like budgets, bids, targeting, spending amount, revenue, etc).`,\n            customClass: 'cc-popover',\n            boundary: 'viewport',\n          }",modifiers:{"hover":true,"top":true}}],staticClass:"mr-12",attrs:{"type":"infoDescription"}}),_c('switch-button',{attrs:{"id":"search-feed-active","checked":_setup.isFormActive,"hover-title":{ enabled: 'Hide form', disabled: 'Show Form' },"update":_setup.onSearchFeedActiveChange}})],1),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:({
          content: _setup.isFormActive
            ? ''
            : 'Please enable the Transparency Program to share your creatives with a search feed provider.',
          customClass: 'cc-popover',
          boundary: 'viewport',
        }),expression:"{\n          content: isFormActive\n            ? ''\n            : 'Please enable the Transparency Program to share your creatives with a search feed provider.',\n          customClass: 'cc-popover',\n          boundary: 'viewport',\n        }",modifiers:{"hover":true,"right":true}}],class:{ 'not-allowed': !_setup.isFormActive }},[_c('b-form-group',{staticClass:"optimizer-form-group",class:{ disabled: !_setup.isFormActive || _setup.isLoading }},[_c('div',{staticClass:"d-flex align-items-center mb-12"},[_c('div',{staticClass:"w-50 mr-12"},[_c('label',{staticClass:"m-0 mr-6 fs-13"},[_vm._v("Select search feed provider:")]),_c(_setup.OptimizerIcon,{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
                  content: 'Select which search feed provider do you want to share these creatives with.',
                  customClass: 'cc-popover',
                  boundary: 'viewport',
                }),expression:"{\n                  content: 'Select which search feed provider do you want to share these creatives with.',\n                  customClass: 'cc-popover',\n                  boundary: 'viewport',\n                }",modifiers:{"hover":true,"top":true}}],attrs:{"type":"infoDescription"}})],1),_c('vue-opti-select-light',{staticClass:"optimizer-select icon-select pr-2 w-100",attrs:{"id":"search-feed-dropdown","button-placeholder":"Select Search Feed","button-block":"","single":"","searchable":"","boundary":"viewport","options":_setup.searchFeedDropdown.options},on:{"change":_setup.onSearchFeedChange},scopedSlots:_vm._u([{key:"ITEM",fn:function({ option }){return [_c('img',{staticClass:"acc-item-icon",attrs:{"src":option.content !== 'ClickFlare'
                      ? `/sh_static/accounts/${option.content}.png`
                      : '/sh_static/accounts/ClickFlare.svg',"alt":option.content}}),_c('div',{staticClass:"acc-item-name"},[_vm._v(_vm._s(option.content))])]}}]),model:{value:(_setup.searchFeedDropdown.selected),callback:function ($$v) {_vm.$set(_setup.searchFeedDropdown, "selected", $$v)},expression:"searchFeedDropdown.selected"}})],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"w-50 mr-12"},[_c('label',{staticClass:"m-0 mr-6 fs-13"},[_vm._v("Select your search feed account:")]),_c(_setup.OptimizerIcon,{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
                  content: 'Select the search feed account under which you are running these campaigns.',
                  customClass: 'cc-popover',
                  boundary: 'viewport',
                }),expression:"{\n                  content: 'Select the search feed account under which you are running these campaigns.',\n                  customClass: 'cc-popover',\n                  boundary: 'viewport',\n                }",modifiers:{"hover":true,"top":true}}],attrs:{"type":"infoDescription"}})],1),_c('vue-opti-select-light',{staticClass:"optimizer-select icon-select pr-2 w-100",attrs:{"id":"email-dropdown","button-placeholder":"Select email used on Revenue Stream","button-block":"","single":"","searchable":"","boundary":"viewport","options":_setup.emailDropdown.options},model:{value:(_setup.emailDropdown.selected),callback:function ($$v) {_vm.$set(_setup.emailDropdown, "selected", $$v)},expression:"emailDropdown.selected"}})],1)])],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }