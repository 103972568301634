import { RuleTypes, TrafficSource } from '@sh/types';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export function getOptionValue(data: RuleTypes.ConditionsV2.Option<RuleTypes.Conditions.FieldValue | string>) {
  if (data.value && typeof data.value === 'object') {
    if (data.group === 'cc') {
      return data.value.key.split('::')[0];
    }

    return data.value.key;
  }

  return data.value;
}

function getValueType<T extends RuleTypes.Conditions.ValueType>(
  validValues: T[],
  valueType: RuleTypes.Conditions.ValueType
): T {
  const isValid = validValues.some((value) => value === valueType);
  return (isValid ? valueType : undefined) as T;
}

function getOperatorType(type?: RuleTypes.Conditions.ValueType): RuleTypes.ConditionsV2.OperatorType {
  switch (type) {
    case RuleTypes.Conditions.ValueType.Status:
    case RuleTypes.Conditions.ValueType.TABOOLA_LEARNING_STATE:
      return RuleTypes.ConditionsV2.OperatorType.StatusOperator;
    case RuleTypes.Conditions.ValueType.String:
      return RuleTypes.ConditionsV2.OperatorType.StringSearch;
    default:
      return RuleTypes.ConditionsV2.OperatorType.Numeric;
  }
}

/**
 * Gets the operator from the provided data.
 *
 * @param data Selected left option.
 */
function getOperator(
  type: RuleTypes.Conditions.ValueType,
  data?: Partial<RuleTypes.Conditions.Option>
): RuleTypes.ConditionsV2.Operator {
  return {
    type: 'Operator',
    value: data?.value?.key ?? 'equals',
    operatorType: getOperatorType(type),
  };
}

export function getDateOperation(
  value: number,
  unit: RuleTypes.ConditionsV2.DateOperationsUnit = RuleTypes.ConditionsV2.DateOperationsUnit.Day
): RuleTypes.ConditionsV2.DateOperations {
  return {
    value: Math.abs(value),
    type: value > 0 ? RuleTypes.ConditionsV2.DateOperationsType.Add : RuleTypes.ConditionsV2.DateOperationsType.Remove,
    unit,
  };
}

export function getDateOperations(
  value: number[],
  unit: RuleTypes.ConditionsV2.DateOperationsUnit = RuleTypes.ConditionsV2.DateOperationsUnit.Day
) {
  return value.map((item) => getDateOperation(item, unit));
}

export function getDateOperationValue(operation: RuleTypes.ConditionsV2.DateOperations): number {
  if (operation.unit !== RuleTypes.ConditionsV2.DateOperationsUnit.Day) {
    throw new Error('Date operation unit not supported.');
  }

  if (operation.type === RuleTypes.ConditionsV2.DateOperationsType.Remove) {
    return -operation.value;
  }
  return operation.value;
}

export function getDateOperationValues(operations: RuleTypes.ConditionsV2.DateOperations[]): number[] {
  try {
    return operations.map((operation) => getDateOperationValue(operation));
  } catch {
    return [];
  }
}

/**
 * This function is used to assign baseType or percentage to field option for rule bid conditions.
 */
export function getBidOption(
  data: RuleTypes.ConditionsV2.Option<string>,
  metadata: { unit: RuleTypes.Conditions.UnitValue; value: string }
) {
  const payload = cloneDeep(data);

  if (metadata.unit === RuleTypes.Conditions.UnitValue.Percentage) {
    payload.percentage = { value: parseFloat(metadata.value) };
  }

  return payload;
}

/**
 * This function is used to get the condition payload based on the type of the field.
 *
 * @param option - Selected field option.
 * @param getValue - Function to get option unique value.
 */
export function getField(option: RuleTypes.ConditionsV2.Option): RuleTypes.ConditionsV2.Field | Error {
  const data = cloneDeep(option);
  const value = getOptionValue(data);
  const baseModel = data.baseModel ?? RuleTypes.ConditionsV2.BaseModel.Current;
  const { percentage } = data;

  if (
    [RuleTypes.Conditions.ValueType.Status, RuleTypes.Conditions.ValueType.TABOOLA_LEARNING_STATE].includes(data.type)
  ) {
    data.type = RuleTypes.Conditions.ValueType.String;
  }

  switch (data.baseType) {
    case RuleTypes.Conditions.BaseFieldType.Date:
      return {
        value,
        percentage,
        type: data.baseType,
        operations: data.operations,
        valueType: getValueType(
          [
            RuleTypes.Conditions.ValueType.DateField,
            RuleTypes.Conditions.ValueType.Now,
            RuleTypes.Conditions.ValueType.DateString,
          ],
          data.type
        ),
        ...(data.type === RuleTypes.Conditions.ValueType.DateString && {
          value: moment(data.specificDate).format('YYYY-MM-DD'),
        }),
        ...(data.type === RuleTypes.Conditions.ValueType.DateField && {
          baseModel,
        }),
      };
    case RuleTypes.Conditions.BaseFieldType.Entity:
      return {
        value,
        baseModel,
        percentage,
        type: data.baseType,
        valueType: getValueType(
          [RuleTypes.Conditions.ValueType.Number, RuleTypes.Conditions.ValueType.String],
          data.type
        ),
      };
    case RuleTypes.Conditions.BaseFieldType.Metric:
      return {
        value,
        baseModel,
        percentage,
        type: data.baseType,
        interval: data.interval,
        valueType: RuleTypes.Conditions.ValueType.Number,
      };
    case RuleTypes.Conditions.BaseFieldType.Constant:
      return {
        type: data.baseType,
        value,
        valueType: getValueType(
          [RuleTypes.Conditions.ValueType.Number, RuleTypes.Conditions.ValueType.String],
          data.type
        ),
      };
    default:
      throw new Error('Invalid field type.');
  }
}

/**
 * This function is used to assign baseType or percentage to field option for right condition.
 *
 * @param data - Selected field option.
 * @param row - Current condition row to get the payload from.
 */
function getRightOption(row: RuleTypes.Conditions.Row) {
  let condition: RuleTypes.ConditionsV2.Option | undefined;

  switch (row?.field3?.unit) {
    case undefined: // For Status and Taboola Learning State
    case RuleTypes.Conditions.UnitValue.Static:
      condition = cloneDeep(row.field1);

      if (
        [
          RuleTypes.Conditions.ValueType.Number,
          RuleTypes.Conditions.ValueType.String,
          RuleTypes.Conditions.ValueType.Status,
          RuleTypes.Conditions.ValueType.TABOOLA_LEARNING_STATE,
        ].includes(condition.type)
      ) {
        condition.baseType = RuleTypes.Conditions.BaseFieldType.Constant;
      }

      if (condition.baseType === RuleTypes.Conditions.BaseFieldType.Constant && row.field3.value !== undefined) {
        condition.value = row.field3.value as RuleTypes.Conditions.FieldValue;
      }
      break;
    case RuleTypes.Conditions.UnitValue.Self:
      condition = cloneDeep(row.field3.to_self);
      if (condition?.type === RuleTypes.Conditions.ValueType.Number) {
        condition.percentage = { value: Number(row.field3.value) };
      }
      break;
    case RuleTypes.Conditions.UnitValue.Percentage:
      condition = cloneDeep(row.field3.to);

      if (condition?.type === RuleTypes.Conditions.ValueType.Number) {
        condition.percentage = { value: Number(row.field3.value) };
        condition.baseModel = RuleTypes.ConditionsV2.BaseModel.Campaign;
      }
      break;
  }

  if (!condition) {
    throw new Error('Right part of condition is not found');
  }

  return condition;
}

/**
 * Gets the conditionV2 payload from the provided data.
 *
 * @param row - Current condition row to get the payload from.
 */
export function getCondition(row: RuleTypes.Conditions.Row) {
  try {
    const data = cloneDeep(row);
    const rightOption = getRightOption(row);

    return {
      left: getField(data.field1),
      operator: getOperator(data.field1.type, data.field2),
      right: getField(rightOption),
    };
  } catch {
    return undefined;
  }
}

export const staticDateFields = {
  current_date: {
    key: 'current_date',
    trafficSource: [...Object.values(TrafficSource)],
    notAvailableAsParentCampaignCondition: true,
    group: 'other',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.Now,
    value: {
      behind: 'IS',
      key: 'current_date',
    },
    text: 'Current Date ',
    notAvailableForToComparison: true,
  },
  specific_date: {
    key: 'specific_date',
    trafficSource: [...Object.values(TrafficSource)],
    notAvailableAsParentCampaignCondition: true,
    group: 'other',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateString,
    value: {
      behind: 'IS',
      key: 'specific_date',
    },
    text: 'Specific Date',
    notAvailableForToComparison: true,
  },
};

export function convertKey(key: string, to_self: string) {
  if (!key.includes('custom') && to_self === 'current_date') {
    const baseKey = key.split('campaign_');

    if (baseKey.length > 1) {
      return `campaign_custom_${baseKey[1]}`;
    }
    return `custom_${key}`;
  }
  return key;
}

export const isCustomDateCondition = (
  key: string,
  conditions: RuleTypes.ConditionsV2.CustomDateCondition[] = Object.values(RuleTypes.ConditionsV2.CustomDateCondition)
): boolean => conditions.some((condition) => condition === key || `campaign_${condition}` === key);
