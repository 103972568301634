var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
    customClass: 'custom-tooltip',
    title: `This feature is not available yet.
            Let us know that it is important for you by clicking on Request Feature.
            We will prioritise new features based on requests.`,
    interactive: false,
    boundary: 'viewport',
  }),expression:"{\n    customClass: 'custom-tooltip',\n    title: `This feature is not available yet.\n            Let us know that it is important for you by clicking on Request Feature.\n            We will prioritise new features based on requests.`,\n    interactive: false,\n    boundary: 'viewport',\n  }",modifiers:{"hover":true,"top":true}}],staticClass:"cc-request-feature--btn",on:{"click":function($event){_setup.isRequestModalOpen = true}}},[_c(_setup.CcIcon,{attrs:{"type":"starRounded","height":12}}),_c('span',[_vm._v("Request Feature")]),_c(_setup.CcRequestFeatureModal,{attrs:{"isModalOpen":_setup.isRequestModalOpen,"modalTitle":_vm.modalTitle,"featureId":_vm.featureId},on:{"onCancel":function($event){_setup.isRequestModalOpen = false},"onSave":function($event){_setup.isRequestModalOpen = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }