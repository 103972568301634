import LiveEdit from '@sh/components/Utils/LiveEdit';
import BulkModal from '@sh/components/Utils/BulkModal';
import Task from '@sh/lib/Task.class';
import ui from '@sh/helpers/ui';
import notifications from '@sh/mixins/notifications';
import currencySymbols from '@sh/helpers/symbols';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';

export default {
  components: {
    LiveEdit, LoadingSkeleton, BulkModal,
  },
  props: {
    campaign: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    account: { type: Object, required: true },
    settings: { type: Object, required: true },
    tracker: { type: Object },
    timezone: { type: String },
  },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      ui,
      currencySymbols,
      maxCpcLimit: 0.5,
      userAction: {
        task: new Task(),
        loading: false,
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      table: {
        fields: [],
        items: [],
        statsTotals: {},
        totals: {},
      },
      limit: 100,
      page: 1,
      pages: 1,
      defaultRows: 100,
      search: '',
      sortField: 'cost',
      sortType: 'desc',
      ready: {
        pagination: false,
      },
      columnFilter: {},
    };
  },
  async created() {
    this.preloader = true;
    this.userAction.task.on('change:empty', () => {
      this.userAction.loading = false;
    });
    this.userAction.task.on('change:not-empty', () => {
      this.userAction.loading = true;
    });
    await this.$_init();
    // this.$emit('ready')
  },
  methods: {
    $_bulkAction(data) {
      if (this.tableModel.selectedRows.length > 0 || data.force) {
        data.fn(data.value);
      } else {
        this.$n_failNotification({ title: 'Select at least 1 row' });
      }
    },
    async $_userActionHandler(options) {
      if (!options.request) return false;
      try {
        const result = await options.request;
        if (result.errorMessage) this.$n_failNotification({ title: result.errorMessage });
        if (!result.errorMessage && result.errors && result.errors.length > 0) this.$n_failNotification({ title: 'Action Fail' });
        if (result.successMessage) {
          let message = result.successMessage;
          if (options?.ts === 'Facebook') {
            message = message.replace(options.entityToReplace, options.newEntityName);
          }
          this.$n_successNotification({ title: message });
        }
        if (!result.successMessage && result.success && result.success.length > 0) this.$n_successNotification({ title: 'Success' });
        return result;
      } catch (error) {
        let message = 'An error occurred';
        if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
        this.$n_failNotification({ title: message });
        throw error;
      }
    },
    $_inlineEditArrayPress(refName, index, direction) {
      let nextIndex = direction === 'up' ? index - 1 : index + 1;
      while (this.$refs[`${refName}-${nextIndex}`]) {
        if (this.$refs[`${refName}-${nextIndex}`].getState() === 'READY') {
          this.$refs[`${refName}-${nextIndex}`].focus();
          break;
        }
        nextIndex = direction === 'up' ? nextIndex - 1 : nextIndex + 1;
      }
    },
    $_showBulkModal(props) {
      this.$refs.bulkModal.show(props);
    },
    $_showBulkModalCAD() {
      this.$refs.bulkModalCAD.show();
    },
    debounce(func, wait, immediate) {
      let timeout;
      return function () {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    $_setSearchValue(event) {
      this.search = event.search;
      this.debouncedGetActivityLogs();
    },
    $_setPaginationValues(event, func) {
      this.page = event.page;
      this.limit = event.limit;
      this.sortField = event.sortField;
      this.sortType = event.sortType;
      func();
    },
    $_getCurrency(val) {
      return currencySymbols[val || this.campaign.traffic_source_currency] || '$';
    },
    $_copyToClipboard(key, extraKey = '') {
      if (extraKey) {
        return this.tableModel.selectedRows.map((item) => `${item[key]},${item[extraKey]}`).join('\n');
      }
      return this.tableModel.selectedRows.map((item) => item[key]).join(',');
    },
    $_clipboardSuccessHandler(label = 'ids') {
      this.$n_infoNotification({ title: `${this.tableModel.selectedRows.length} ${label} copied to clipboard` });
    },
    $_clipboardErrorHandler() {
      this.$n_failNotification({ title: 'Clipboard error' });
    },
  },
  watch: {
    async campaign() {
      // this.preloader = true
      // await this.$_init()
    },
    async account() {
      this.preloader = true;
      this.columnFilter = {};
      await this.$_init();
    },
    async dateRange() {
      this.preloader = true;
      this.columnFilter = {};
      await this.$_init();
    },
  },
};
