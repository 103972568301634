var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cc-card__item",class:`cc-card__item--layer-${_vm.layer}`},[(_vm.title)?_c('div',{staticClass:"cc-card__item__title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.editTitle)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
        title: 'Edit Title',
        customClass: 'custom-tooltip max-width-230',
        interactive: false,
        html: true,
        boundary: 'viewport',
      }),expression:"{\n        title: 'Edit Title',\n        customClass: 'custom-tooltip max-width-230',\n        interactive: false,\n        html: true,\n        boundary: 'viewport',\n      }",modifiers:{"hover":true,"right":true}}],staticClass:"cc-btn cc-btn--action",on:{"click":function($event){_setup.isModalOpened = true}}},[_c(_setup.CcIcon,{attrs:{"type":"editName","height":14}})],1):_vm._e(),(_vm.info)?_c(_setup.CcIcon,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
        title: _vm.info,
        customClass: 'custom-tooltip max-width-230',
        interactive: false,
        html: true,
        boundary: 'viewport',
      }),expression:"{\n        title: info,\n        customClass: 'custom-tooltip max-width-230',\n        interactive: false,\n        html: true,\n        boundary: 'viewport',\n      }",modifiers:{"hover":true,"right":true}}],attrs:{"type":"infoIcon","height":16}}):_vm._e(),_c('div',{staticClass:"cc-card__item__title__action"},[(_vm.action && _vm.action.icon)?_c('button',{staticClass:"cc-btn cc-btn--action",on:{"click":function($event){return _setup.emits('action', _vm.action.type)}}},[_c(_setup.CcIcon,{attrs:{"type":_vm.action.icon,"fill":_vm.action.fillIcon}}),_vm._v(" "+_vm._s(_vm.action.text)+" ")],1):_vm._e()])],1):_vm._e(),_vm._t("default"),_c(_setup.CcRenameModal,{attrs:{"isModalOpen":_setup.isModalOpened,"title":_vm.title,"modalTitle":"Enter a new name for this Variable"},on:{"onCancel":function($event){_setup.isModalOpened = false},"onSave":_setup.onTitleChange}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }