<template>
  <div class="manual-stats-update-csv-container">
    <b-alert show variant="info" class="manual-stats-update-csv-container__info">
      Update revenue and conversions data at campaign, and all other campaign levels (ads, ad sets, publishers etc) by uploading a CSV file.
    </b-alert>
    <div class="row">
      <div class="col-md-12 text-muted mb-4">
        The CSV columns must have a specific naming format ( <b-link @click="$_downloadTemplate()">download csv template</b-link> ). For more info on how to use the template <b-link @click="$_openArticle()">checkout this article </b-link>.
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="manual-stats-update-csv__campaigns">
          <upload-btn ref="uploadBtn" @on-parse="loadCsv($event)" @loader="preloader = $event"></upload-btn>
        </div>
      </div>
    </div>

    <!-- Table -->
    <preloader v-if="preloader"></preloader>

    <div class="fixed-footer d-flex justify-content-end">
      <div class="custom-stats-save-container">
        <b-btn class="primary-button" @click="submit()">Submit Data</b-btn>
      </div>
    </div>

    <b-modal centered ref="confirmUpdateModal" hide-footer>
      <div class="text-center mb-4">
        <h4>Are you sure?</h4>
        <p class="text-muted">You are going to overwrite your statistics</p>
      </div>
      <div class="buttons-container d-flex">
        <b-button @click="handleSubmit()" variant="outline-primary" class="mr-2 w-50">Continue</b-button>
        <b-button @click="hideConfirmModal()" variant="outline-danger" class="w-50">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UploadBtn from '@sh/components/Utils/UploadBtn.vue';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'CsvUpload',
  components: {
    UploadBtn,
  },
  mixins: [notifications],
  data() {
    return {
      preloader: false,
      missingHeaders: [],
      extraHeaders: [],
    };
  },

  methods: {
    async handleSubmit() {
      this.hideConfirmModal();
      this.preloader = true;
      try {
        this.preloader = false;
        const formData = new FormData();
        const file = this.$refs.uploadBtn.$refs.file.files[0];
        formData.append('file', file);
        await this.$api.manualStatsUpdate.uploadCsv(formData);
        this.$n_successNotification({ title: 'File uploaded successfully' });
      } catch (err) {
        this.preloader = false;
        this.$n_failNotification({ title: err.response.data.message || 'An error occurred' });
      }
    },
    async $_openArticle() {
      const articleUrl = 'https://theoptimizer.io/help/knowledge-base/how-to-manually-upload-data-using-a-csv-file';
      window.open(articleUrl, '_blank');
    },
    async $_downloadTemplate() {
      const csvUrl = 'https://docs.google.com/spreadsheets/d/1DGpytxaY-dJEW0G6OvNhcSew12H6BmNv3Wspt_r8u2E/export?gid=0&format=csv';
      window.open(csvUrl);
    },
    $_hasError() {
      return this.$refs.uploadBtn.hasError;
    },
    showConfirmModal() {
      this.$refs.confirmUpdateModal.show();
    },
    hideConfirmModal() {
      this.$refs.confirmUpdateModal.hide();
    },
    submit() {
      if (!this.$refs.uploadBtn.fileName) {
        this.$n_failNotification({ title: 'Please upload a csv file' });
        return;
      }
      if (this.$_hasError()) {
        this.$refs.uploadBtn.showErrorModal();
      } else {
        this.showConfirmModal();
      }
    },
  },
};
</script>

<style lang="scss">
.manual-stats-update-csv-container {
  &__info {
      background: rgba(132, 164, 247, 0.2);
      font-size: 1.5rem;
      color: $black-600;
      padding: 2rem;
      border: none;
      border-radius: 0.6rem;
      margin-bottom: 3rem;
      margin-inline: 0;
    }

  .manual-stats-update-csv__filters {
    margin-bottom: 3rem;
  }

  .manual-stats-update-csv__campaigns {
    background: $color-white;
    border: $border;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    margin-bottom: 4rem;

    .select-campaign-title {
      font-size: 1.4rem;
    }
  }

  .optimizer-table {
    td {
      padding: .1rem .2rem !important;

      &.cell-date {
        overflow: visible;

        .optimizer-date-range {
          .reportrange-text {
            padding: .5rem 1rem;
            border-radius: 0;
          }
        }
      }

      &.cell-tr_revenue {
        .field {
          display: flex;

          .input-group-addon {
            padding: .9rem 1.4rem;
            margin-bottom: 0;
            font-size: 1.3rem;
            color: #3e515b;
            text-align: center;
            background-color: #f9f9fa;
            border: 1px solid #e1e6ef;
            border-right: none;
          }
        }
      }

      input {
        font-size: 1.35rem;
        height: 3.5rem;
        color: #3e515b;
        border-color: #e1e6ef;
      }
    }
  }

  .fixed-footer {
    align-items: center;
    padding: 1rem 2.5rem;

    .manual-upload-csv-actions {
      text-align: left;

      b {
        font-size: 1.4rem;
      }

      &__type {
        font-size: 1.4rem;

        .custom-control-label {
          margin-right: 1rem;
          &::before {
            top: 0.3rem;
            left: -1.8rem;
            width: 1.4rem;
            height: 1.4rem;
          }

          &::after {
            top: 0.2rem;
            left: -1.9rem;
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }
    }
  }
}
</style>

<style>
.manual-stats-update-csv .table-wrapper td.cell-name {
  padding: 0 !important;
}

.manual-stats-update-csv .table-holder table .vdp-datepicker__calendar {
  display: none !important;
}

.manual-stats-update-csv td.cell-actions .field,
.manual-stats-update-csv td.cell-ts_revenue .field,
.manual-stats-update-csv td.cell-tr_revenue .field {
  display: flex;
}

.manual-stats-update-csv td.cell-ts_revenue .field span i,
.manual-stats-update-csv td.cell-tr_revenue .field  span i{
  font-size: 12px;
}

.manual-stats-update-csv td.cell-actions .field {
  align-items: center;
  justify-content: center;
}

.manual-stats-update-csv .table-holder table.table tr td {
  width: 1px !important;
}

.manual-stats-update-csv .load-campaigns-date .input-group {
  height: 40px;
}

.manual-stats-update-csv .bulk-table-delete,
.manual-stats-update-csv .bulk-table-clone {
  border: 1px solid #555;
  color: #555;
  padding: 2px 1px;
}

.manual-stats-update-csv .bulk-table-delete i {
  font-size: 11px;
  margin: 0;
  color: inherit;
}

.manual-stats-update-csv .csv-divider {
  display: flex;
  align-items: center;
  margin: 25px 0;
  color: gray;
}

.manual-stats-update-csv .csv-divider::before,
.manual-stats-update-csv .csv-divider::after {
  content: '';
  display: block;
  background: rgb(235, 229, 229);
  height: 1px;
  width: 50%;
}

.manual-stats-update-csv .csv-divider::before {
  margin-right: 20px;
}

.manual-stats-update-csv .csv-divider::after {
  margin-left: 20px;
}

.manual-stats-update-csv .cell-content-preview img {
  transition: .5s;
}

.manual-stats-update-csv .cell-content-preview img:hover {
  transform: scale(5);
  border-radius: 3%;
  cursor: pointer;
}
</style>
