
import { useReportingStore } from '@/views/Tools/Reporting/store';
import { Campaign, CampaignFilterAction } from '@/views/Tools/Reporting/types/Campaign';
import { FilterType } from '@/views/Tools/Reporting/types/Filter';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';
import { cloneDeep } from 'lodash';
import { mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  isModalOpen: boolean;
  searchModel: string;
  campaigns: Campaign[];
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  mixins: [notifications],
  components: { OptimizerIcon },
  data(): ComponentData {
    return {
      isModalOpen: false,
      searchModel: '',
      campaigns: [],
    };
  },
  computed: {
    FilterType() {
      return FilterType;
    },
    CampaignFilterAction() {
      return CampaignFilterAction;
    },
    campaignsOptions(): Campaign[] {
      return cloneDeep(this.campaignFilter?.options ?? []);
    },
    searchResult(): Campaign[] {
      const formattedSearch = this.searchModel.toLowerCase();

      if (formattedSearch.trim() === '') {
        return [];
      }

      return this.campaignsOptions?.filter((item) => item.name.toLowerCase().indexOf(formattedSearch) > -1) ?? [];
    },
    ...mapState(useReportingStore, ['trafficSourceFilter', 'campaignFilter']),
    ...mapWritableState(useReportingStore, ['currentReport']),
  },
  methods: {
    filterCampaigns(action: CampaignFilterAction, campaignsOptions: Campaign[]) {
      switch (action) {
        case CampaignFilterAction.ALL:
          return campaignsOptions;
        case CampaignFilterAction.ACTIVE:
          return campaignsOptions.filter((item) => item.enabled);
        case CampaignFilterAction.DISABLED:
          return campaignsOptions.filter((item) => !item.enabled);
        case CampaignFilterAction.NONE:
          return [];
      }
    },
    async onCampaignsFilter(action: CampaignFilterAction) {
      this.campaigns = this.filterCampaigns(action, this.campaignsOptions);

      if (!this.campaigns?.length && action !== CampaignFilterAction.NONE) {
        this.$n_infoNotification({ title: 'There are no campaigns for selected option!' });
      }
    },
    onModalToggle() {
      this.isModalOpen = !this.isModalOpen;
      this.campaigns = this.campaignFilter?.selectedOptions ?? [];
    },
    onAddSearchCampaignsClick() {
      const modelMap: Record<string, Campaign> = {};
      this.campaigns?.forEach((item) => {
        modelMap[item.id] = item;
      });
      this.searchResult.forEach((item) => {
        if (!modelMap[item.id]) {
          this.campaigns?.push(item);
        }
      });
    },
    onCancelClick() {
      this.campaigns = [];
      this.onModalToggle();
    },
    async onSaveClick() {
      if (this.campaignFilter) {
        this.campaignFilter.selectedOptions = cloneDeep(this.campaigns);
        this.currentReport.resetTable();
      }
      this.onCancelClick();
    },
  },
});
