
import CcIcon from '@/views/Automation/CampaignCreatorV2/components/CcIcon/CcIcon.ts.vue';
import Vue from 'vue';

export type DropdownOption = {
  id: string;
  label: string;
  nestedOptions?: DropdownOption[];
};

export default Vue.component('CcDropdownInput', {
  components: {
    CcIcon,
  },
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
  },
  data() {
    return {
      isShown: false,
      focusOnDropdown: false,
    };
  },
  computed: {
    filteredOptions(): any {
      const hasNestedOptions = this.options.some((option: any) => option.nestedOptions);
      if (hasNestedOptions) return this.options;
      return this.options.filter((option: any) => option.id.toLowerCase().includes(this.inputValue.toLowerCase()));
    },
  },
  methods: {
    onDropdownVisibilityChange(open: boolean) {
      if (this.focusOnDropdown) return;
      this.isShown = open;
    },
    onInputValueChange(value: string, insideDropdown = false, isParent = false) {
      if (isParent) return;

      this.$emit('update', value);
      if (insideDropdown) this.isShown = false;
      this.focusOnDropdown = false;
    },
    onDropdownFocusChange(focus: boolean) {
      this.focusOnDropdown = focus;
    },
  },
});
