import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';

const infoText = {
  epc: 'Epc is calculated as (tracker revenue)/(traffic source clicks)',
  ts_epc: 'Traffic Source Epc is calculated as (traffic source revenue)/(traffic source clicks). Use only if you are posting revenues to the traffic source.',
  tr_epc: 'Tracker Epc is calculated as (tracker revenue)/(tracker clicks)',
  eps: 'EPS is calculated as Revenue/GA Sessions (edited)',
  epv: 'EPV is calculated as Revenue/GA Page Views',
  campaign_bid: 'Campaign level bid',
  bid: 'Current Bid',
};

const infoTextWidgetOnly = {
  avg_cpc: 'Average CPC is calculated as (cost)/(clicks)',
  campaign_avg_cpc: 'Campaign Average CPC is calculated as (campaign cost)/(campaign clicks)',
};

const generateBidOptions = (device = '') => [
  { value: 'set', text: `Set ${device} Bid To` },
  { value: 'increase', text: `Increase ${device} Bid By` },
  { value: 'decrease', text: `Decrease ${device} Bid By` },
];

const bidActionOptions = generateBidOptions();

const bidUnitOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const bidMinMaxOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const bidToOptions = [
  {
    value: 'bid',
    text: 'of Current Bid',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'campaign_bid',
    text: 'of Campaign Bid',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
    baseModel: RuleTypes.ConditionsV2.BaseModel.Campaign,
  },
  {
    value: 'epc',
    text: 'of EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'ts_epc',
    text: 'of Traffic Source EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'tr_epc',
    text: 'of Tracker EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'eps',
    text: 'of EPS',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'epv',
    text: 'of EPV',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'coefficient',
    text: 'of Current Coefficient',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const bidToOptionsWidgetOnly = [
  {
    value: 'avg_cpc',
    text: 'of Average CPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'campaign_avg_cpc',
    text: 'of Campaign Average CPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    baseModel: RuleTypes.ConditionsV2.BaseModel.Campaign,
  },
];

const bidModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              epc: { info: infoText.epc },
              ts_epc: { info: infoText.ts_epc },
              tr_epc: { info: infoText.tr_epc },
              eps: { info: infoText.eps },
              epv: { info: infoText.epv },
              campaign_bid: { info: infoText.campaign_bid },
              bid: { info: infoText.bid },
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              bid: { info: infoText.bid },
              epc: { info: infoText.epc },
              ts_epc: { info: infoText.ts_epc },
              tr_epc: { info: infoText.tr_epc },
              eps: { info: infoText.eps },
              epv: { info: infoText.epv },
              campaign_bid: { info: infoText.campaign_bid },
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              bid: { info: infoText.bid },
              epc: { info: infoText.epc },
              ts_epc: { info: infoText.ts_epc },
              tr_epc: { info: infoText.tr_epc },
              eps: { info: infoText.eps },
              epv: { info: infoText.epv },
              campaign_bid: { info: infoText.campaign_bid },
            },
          },
        },
      },
    },
  },
};

const bidToOptionsCM = [];
const loadCmOptions = async () => {
  if (!bidToOptionsCM.length) {
    const response = await apiStore.configuration.cmFields();
    if (response && response.fields) {
      response.fields.forEach((cmItem) => {
        if (cmItem.customMetric) {
          bidToOptionsCM.push({
            value: cmItem.item.key,
            text: `of ${getFieldTextAndDescription(cmItem).text || cmItem.header.content}`,
            baseType: RuleTypes.Conditions.BaseFieldType.Metric,
            type: RuleTypes.Conditions.ValueType.Number,
          });
        }
      });
    }
  }
};

const widgetBidOptions = () => {
  const bidToOptionsClone = JSON.parse(JSON.stringify(bidToOptions));
  const bidModelTemplatesClone = JSON.parse(JSON.stringify(bidModelTemplates));
  bidToOptionsClone.push(...bidToOptionsWidgetOnly);
  bidToOptionsWidgetOnly.forEach((widgetItem) => {
    bidModelTemplatesClone.classic.action.set.unit.percentage.to[widgetItem.value] = { info: infoTextWidgetOnly[widgetItem.value] };
    bidModelTemplatesClone.classic.action.increase.unit.percentage.to[widgetItem.value] = { info: infoTextWidgetOnly[widgetItem.value] };
    bidModelTemplatesClone.classic.action.decrease.unit.percentage.to[widgetItem.value] = { info: infoTextWidgetOnly[widgetItem.value] };
  });
  return {
    bidToOptionsClone,
    bidModelTemplatesClone,
  };
};

export default {
  async getData() {
    let bidModelTemplatesClone = JSON.parse(JSON.stringify(bidModelTemplates));
    let bidToOptionsClone = JSON.parse(JSON.stringify(bidToOptions));
    const widgetBidOptionsData = widgetBidOptions();
    bidToOptionsClone = widgetBidOptionsData.bidToOptionsClone;
    bidModelTemplatesClone = widgetBidOptionsData.bidModelTemplatesClone;

    try {
      await loadCmOptions();
      if (bidToOptionsCM.length) {
        bidToOptionsClone.push(...bidToOptionsCM);
        bidToOptionsCM.forEach((cmItem) => {
          bidModelTemplatesClone.classic.action.set.unit.percentage.to[cmItem.value] = {};
          bidModelTemplatesClone.classic.action.increase.unit.percentage.to[cmItem.value] = {};
          bidModelTemplatesClone.classic.action.decrease.unit.percentage.to[cmItem.value] = {};
        });
      }
    } catch (error) {
      throw Error('Fail loading dynamics fields custom-metrics');
    }
    return {
      bidActionOptions, bidUnitOptions, bidMinMaxOptions, bidToOptions: bidToOptionsClone, bidModelTemplates: bidModelTemplatesClone, generateBidOptions,
    };
  },
};
