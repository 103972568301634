
import { useTrafficSourcesStore } from '@/stores/traffic-sources';
import ReportFilters from '@/views/Tools/Reporting/components/ReportFilters.ts.vue';
import ReportTable from '@/views/Tools/Reporting/components/ReportTable.ts.vue';
import { ReportBuilder } from '@/views/Tools/Reporting/core/ReportBuilder';
import { ReportTabs } from '@/views/Tools/Reporting/helpers';
import { useReportingStore } from '@/views/Tools/Reporting/store';
import { ReportTab, ReportType } from '@/views/Tools/Reporting/types';
import { FilterType } from '@/views/Tools/Reporting/types/Filter';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';

const COMPONENT_ID = 'ReportingFilters';

export default defineComponent({
  name: 'Reporting',
  components: { ReportTable, ReportFilters, OptimizerIcon },
  computed: {
    docUrl(): string | undefined {
      if (this.$session.isSearchFeed) {
        return '';
      }
      return 'https://theoptimizer.io/help/knowledge-base/performance-report/';
    },
    hasFilters(): boolean {
      return (
        !this.isBackgroundReport &&
        !!Object.keys(this.currentReport.filters).find((filter) =>
          [
            FilterType.TrafficSource,
            FilterType.AdAccount,
            FilterType.ReportType,
            FilterType.Campaign,
            FilterType.User,
            FilterType.TrafficSourceComplianceFilter,
          ].includes(filter as FilterType)
        )
      );
    },
    ReportType() {
      return ReportType;
    },
    ReportTabs() {
      return ReportTabs;
    },
    ...mapState(useReportingStore, [
      'tabOptions',
      'activeTab',
      'isBackgroundReport',
      'filterOptions',
      'dateRangeFilter',
      'breakDownFilter',
      'title',
    ]),
    ...mapWritableState(useReportingStore, ['isLoading', 'currentReport']),
    ...mapState(useTrafficSourcesStore, ['filterState']),
  },
  watch: {
    'currentReport.queryStringHash': {
      handler() {
        this.$router.replace({ query: { data: this.currentReport.queryStringHash } });
      },
    },
  },
  async mounted() {
    this.setCurrentReport();
  },
  methods: {
    ...mapActions(useReportingStore, [
      'onBackgroundReportsLoad',
      'getCachedReport',
      'getAccounts',
      'getReportOptions',
      'getUsers',
      'setExistingReport',
      'createReport',
      'onReportTabChange',
      'onTableChange',
      'onGenerateReport',
    ]),
    onDateFiltersChange(isBreakDownFilter = false) {
      const dateRangeSort = '-date_range';
      const currentSort = this.currentReport.table.sort === dateRangeSort ? '-cost' : this.currentReport.table.sort;
      const sort = isBreakDownFilter ? dateRangeSort : currentSort;
      this.currentReport.patchTable({ items: [], sort });
      this.onTableChange({ page: 1 });
    },
    onQueryParamsChange(data: string | (string | null)[]) {
      try {
        if (typeof data === 'string') {
          ReportBuilder.setQueryStringHash(this.currentReport, data, this.filterOptions);
        }
      } catch {
        this.currentReport.tab = 'unknown';
      }
    },
    handleTabChange(tab: ReportTab) {
      this.onReportTabChange(tab);

      if (this.filterState[COMPONENT_ID]) {
        this.filterState[COMPONENT_ID].filterSession.tags = [];
      }
    },
    generateQueryParamsReport() {
      ReportBuilder.setFilterOptions(this.currentReport, this.filterOptions);
      this.onGenerateReport(this.currentReport);
    },
    setCurrentReport() {
      const existingReport = this.getCachedReport(ReportType.UniqueName);

      if (existingReport) {
        this.setExistingReport(existingReport);
      } else if (this.$session.isSearchFeed) {
        this.getSearchFeedReports();
      } else {
        this.getInitialData();
      }
    },
    async getSearchFeedReports() {
      try {
        this.isLoading = true;
        this.onQueryParamsChange((this.$route.query.data ?? '') as string);

        await Promise.all([this.getUsers(), this.getReportOptions()]);

        const initialReport = this.createReport(ReportType.ClientCompliance);

        if (this.currentReport.tab !== initialReport.tab) {
          this.onGenerateReport(initialReport);
        }

        switch (this.currentReport.tab) {
          case 'unknown': {
            this.currentReport = initialReport;
            break;
          }
          default: {
            this.generateQueryParamsReport();
          }
        }

        this.isLoading = false;
      } catch (error) {
        this.currentReport.errorMessage =
          "Sorry but we couldn't load reporting section, please try again in a few moments.";
        this.$apiStore.reporting.getNativeReportOptions.clearCache();
        this.$apiStore.trafficSources.accounts.clearCache();
        this.$apiStore.trafficSources.users.clearCache();
        this.isLoading = false;
      }
    },
    generateInitialReports() {
      const accountReport = this.createReport(ReportType.Account);
      const trafficSourceReport = this.createReport(ReportType.UniqueName);
      const campaignReport = this.createReport(ReportType.Campaign);
      const contentReport = this.createReport(ReportType.Content);

      if (this.currentReport.tab !== accountReport.tab) {
        this.onGenerateReport(accountReport);
      }

      if (this.currentReport.tab !== trafficSourceReport.tab) {
        this.onGenerateReport(trafficSourceReport);
      }

      if (this.currentReport.tab !== campaignReport.tab) {
        this.onGenerateReport(campaignReport);
      }

      if (this.currentReport.tab !== contentReport.tab) {
        this.onGenerateReport(contentReport);
      }

      return trafficSourceReport;
    },
    async getInitialData() {
      try {
        this.isLoading = true;
        this.onQueryParamsChange((this.$route.query.data ?? '') as string);
        await Promise.all([this.getAccounts(), this.getReportOptions()]);
        const backgroundReport = this.onBackgroundReportsLoad();
        const initialReport = this.generateInitialReports();

        switch (this.currentReport.tab) {
          case backgroundReport.tab: {
            this.currentReport = backgroundReport;
            break;
          }
          case 'unknown': {
            this.currentReport = initialReport;
            break;
          }
          default: {
            this.generateQueryParamsReport();
          }
        }

        this.isLoading = false;
      } catch (error) {
        this.currentReport.errorMessage =
          "Sorry but we couldn't load reporting section, please try again in a few moments.";
        this.$apiStore.reporting.getNativeReportOptions.clearCache();
        this.$apiStore.trafficSources.accounts.clearCache();
        this.isLoading = false;
      }
    },
  },
});
