<template>
  <div class="binom-tracker-view">
    <b-card v-for="(rotation, index) in performance.rotations" :key="index">
      <template slot="header">
        <h5 class="m-0">
          {{ rotation.info.name }} <b-badge v-if="rotation.info.status === '0'" variant="danger" class="pull-right">
            Disabled
          </b-badge>
        </h5>
      </template>
      <TrackerViewTable
        v-if="rotation.direct && rotation.direct.rows.length"
        :fields="directFields"
        :items="rotation.direct.rows"
        :totals="rotation.direct.totals"
        title="Offers (Direct Linked Only)"
      >
        <template slot="name" slot-scope="props">
          <a :href="props.item.url" target="_blank">{{ props.item.name }}</a>
        </template>
        <!-- <template slot="weight" slot-scope="props">
          <live-edit
            v-if="props.item.weight"
            :ref="`weightDirectLiveEdit-${index}-${props.i}`"
            :key="`weightDirectLiveEdit-${index}-${props.i}`"
            v-model="props.item.weight"
            :format="parseInt"
            field-name="Weight"
            :beforeBlur="value => $_updateWeight('direct', props.item, value)"
            @up="$_inlineEditArrayPress(`weightDirectLiveEdit-${index}`, props.i, 'up')"
            @down="$_inlineEditArrayPress(`weightDirectLiveEdit-${index}`, props.i, 'down')" />
            <template v-else>-</template>
        </template> -->
      </TrackerViewTable>
      <TrackerViewTable
        v-if="rotation.landings && rotation.landings.rows.length"
        :fields="landingsFields"
        :items="rotation.landings.rows"
        :totals="rotation.landings.totals"
        title="Landing Pages"
        nested-key="offers"
      >
        <template slot="name" slot-scope="props">
          <a :href="props.item.url" target="_blank">{{ props.item.name }}</a>
        </template>
        <template slot="weight" slot-scope="props">
          <live-edit
            v-if="props.item.weight"
            :ref="`weightLandingsLiveEdit-${index}-${props.i}`"
            :key="`weightLandingsLiveEdit-${index}-${props.i}`"
            v-model="props.item.weight"
            :format="parseInt"
            field-name="Weight"
            :before-blur="value => $_updateWeight( props.item.type, props.item, value)"
            @up="$_inlineEditArrayPress(`weightLandingsLiveEdit-${index}`, props.i, 'up')"
            @down="$_inlineEditArrayPress(`weightLandingsLiveEdit-${index}`, props.i, 'down')"
          />
          <template v-else>
            -
          </template>
        </template>
      </TrackerViewTable>
    </b-card>
  </div>
</template>

<script>
import mixins from './mixins';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'BinomTrackerView',
  mixins: [mixins, notifications],
  data() {
    return {
      directFields: [
        this.$_getField('name'),
        this.$_getField('status'),
        this.$_getField('payout'),
        this.$_getField('clicks'),
        this.$_getField('conversions'),
        this.$_getField('cr'),
        this.$_getField('revenue'),
        this.$_getField('epc'),
      ],
      landingsFields: [
        this.$_getField('name'),
        this.$_getField('status'),
        this.$_getField('payout'),
        this.$_getField('weight'),
        this.$_getField('clicks'),
        this.$_getField('lp_clicks'),
        this.$_getField('lp_ctr'),
        this.$_getField('conversions'),
        this.$_getField('cr'),
        this.$_getField('revenue'),
        this.$_getField('epc'),
      ],
    };
  },
  methods: {
    async $_updateWeight(type, item, value) {
      if (!item._id_t) return false;
      const data = { type, id: item._id, id_t: item._id_t };
      console.log(data, value);
      try {
        const { message, error } = await this.$api.trackers.updateWeight(this.tracker.id, data, value);
        if (error) {
          this.$n_failNotification({ title: 'An error occurred' });
          return false;
        }
        this.$n_successNotification({ title: message || 'Weight successfully updated' });
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.binom-tracker-view {
  .tracker-view-table table {
    td.weight {
      width: 1%;
      .live-edit .form-control {
        width: 100%;
      }
    }
  }
}
</style>
