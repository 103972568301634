import { AbortController, deleteEmptyFields, MediaManagerHelpers, pageSize, TheOptimizer, timeout } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import { ImageType } from '@sh/types/ImageTypes';
import { cloneDeep, uniq, uniqBy } from 'lodash';
import md5 from 'md5';
import { defineStore } from 'pinia';

interface MediaManagerStore {
  mode: MediaManager.Mode;
  ads: MediaManager.AdCard[];
  firstPageAds: MediaManager.AdCard[];
  filters: Omit<Required<MediaManager.RequestTypes.Filters>, 'tsItemIds'>;
  settings: Required<MediaManager.ResponseTypes.Settings>;
  totalPages: number;
  totalRows: number;
  isCampaignCreator: boolean;
  isLoadingAds: boolean;
  isPendingRequest: boolean;
  isTableView: boolean;
  isAdModalOpen: boolean;
  isTableLoading: boolean;
  activeAd?: MediaManager.ActiveAd;
  initialActiveImage?: ImageType;
  editAdData?: MediaManager.AdCard;
  mediaManagerAPI?: MediaManager.API;
  errorMessage: string;
}

export const useMediaManagerStore = defineStore('media-manager', {
  state: (): MediaManagerStore => ({
    mode: MediaManager.Mode.ADS,
    isCampaignCreator: false,
    ads: [],
    firstPageAds: [], // needed when switching from page X in grid view to table view
    activeAd: undefined,
    initialActiveImage: undefined,
    editAdData: undefined,
    filters: {
      order: [],
      trafficsource: [],
      selectedAccounts: [],
      metrics: [],
      tags: [],
      customDateRange: MediaManagerHelpers.getInitialDateRange(),
      querySearch: '',
      page: 1,
      pageSize,
    },
    settings: {
      uniqueTrafficSources: [],
      accounts: [],
      uniqueTags: [],
      metricsDropdown: [],
      orderDropDown: [],
      orderDropDownInitial: [],
    },
    isLoadingAds: true,
    isPendingRequest: false,
    mediaManagerAPI: undefined,
    totalPages: 0,
    totalRows: 0,
    isTableView: false,
    isAdModalOpen: false,
    isTableLoading: false,
    errorMessage: '',
  }),
  getters: {
    selectedAds(state) {
      return state.ads.filter((ad) => ad.selected);
    },
    orderOptions(state) {
      if (state.isTableView) {
        return state.settings.orderDropDown.filter((orderOption) => orderOption.column === 'updated_at');
      }
      return state.settings.orderDropDown;
    },
    filterParams(state) {
      return {
        filters: {
          ...(deleteEmptyFields(state.filters) as MediaManager.RequestTypes.Filters),
          ...(!state.filters?.trafficsource?.length && {
            trafficsource: state.settings.uniqueTrafficSources
              .map((trafficSource) => trafficSource.name)
              .filter((trafficSource) => !process.env.VUE_APP_CREATIVE_LIBRARY_NOT_ALLOWED_TS?.includes(trafficSource)),
          }),
        },
      };
    },
    isLoading(state) {
      return state.isLoadingAds || state.isPendingRequest || state.isTableLoading;
    },
    isTheOptimizerMode(state) {
      return !!state.filters.trafficsource.find((value) => value.includes(TheOptimizer));
    },
    hasTrafficSourceFilter(state) {
      return !!state.filters.trafficsource.find((item) => !item.includes(TheOptimizer));
    },
  },
  actions: {
    reset() {
      this.ads = [];
      this.totalPages = 0;
      this.totalRows = 0;
      this.isLoadingAds = false;
      this.filters = {
        order: [],
        trafficsource: [],
        selectedAccounts: [],
        metrics: [],
        tags: [],
        customDateRange: MediaManagerHelpers.getInitialDateRange(),
        querySearch: '',
        page: 1,
        pageSize,
      };
    },
    updateFilters(newFilters: Partial<MediaManager.RequestTypes.Filters>) {
      this.filters = {
        ...this.filters,
        ...newFilters,
        ...(!newFilters.page && { page: 1 }),
      };

      if (!this.isTableView) {
        this.isLoadingAds = true;
        this.getData();
      }
    },
    updateSettings(newSettings: Partial<MediaManager.ResponseTypes.Settings>) {
      this.settings = {
        ...this.settings,
        ...newSettings,
      };
    },
    insertNewAdsData(data: MediaManager.ResponseTypes.Content[], areCreatedAds = true): Promise<boolean> {
      const ads = data.reduce((filtered: MediaManager.AdCard[], ad) => {
        if (
          ad.id &&
          (!this.filters.trafficsource.length || this.filters.trafficsource.includes(ad.traffic_source_unique_name))
        ) {
          const index = this.ads.findIndex((currentAd) => currentAd.id === ad.id);

          if (index !== -1) {
            this.ads.splice(index, 1);
          }
          filtered.push(
            MediaManagerHelpers.getAdCardTypeData(
              ad,
              this.settings.uniqueTrafficSources,
              this.settings.metricsDropdown,
              areCreatedAds
            )
          );
        }
        return filtered;
      }, []);
      const uniqueAds = uniqBy(ads, 'id');

      if (this.filters.page === 1) {
        this.firstPageAds = uniqueAds;
      }

      if (areCreatedAds) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.ads.unshift(...uniqueAds);

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 1200);
        });
      }

      this.ads.push(...uniqueAds);

      return Promise.resolve(false);
    },
    getUploadedTrafficSourcesFilter(extraTrafficSources: string[] = []) {
      return this.settings.uniqueTrafficSources.reduce((filtered: string[], trafficSource) => {
        if (trafficSource.name.includes(TheOptimizer) || extraTrafficSources.includes(trafficSource.name)) {
          filtered.push(trafficSource.name);
        }
        return filtered;
      }, []);
    },
    onUploadedFilterChange(value: boolean) {
      const trafficsource = value
        ? this.getUploadedTrafficSourcesFilter()
        : this.filters.trafficsource.filter((item) => !item.includes(TheOptimizer));
      this.updateFilters({ trafficsource });
    },
    onAdModalOpen(index: number) {
      this.activeAd = MediaManagerHelpers.getActiveAdDto(this.ads[index]);
      this.initialActiveImage = cloneDeep(this.activeAd.image);
      this.isAdModalOpen = true;
      this.editAdData = cloneDeep(this.activeAd);
      this.setCampaignList(this.activeAd);
    },
    async setCampaignList(activeAd: MediaManager.ActiveAd) {
      let campaignsList: MediaManager.ResponseTypes.CampaignData[] = [];
      let errorMessage = '';

      try {
        if (activeAd.usedOn > 0) {
          activeAd.isCampaignListLoading = true;
          campaignsList =
            (await this.mediaManagerAPI?.getCampaignsList(
              this.mode,
              activeAd.id,
              this.filterParams,
              'media-manager-campaign-list'
            )) ?? [];
        }
      } catch (error: any) {
        if (!AbortController.isAbortError(error)) {
          errorMessage = 'An error occurred while getting list of campaigns';
        }
      }

      this.activeAd = {
        ...activeAd,
        campaignsList,
        errorMessage,
        isCampaignListLoading: false,
      };
    },
    onCreateAdsModalOpen() {
      this.activeAd = undefined;
    },
    async onRemoveTags(options: SelectOption<number>[]) {
      this.isPendingRequest = true;
      const tags = options.map((option) => option.content);
      await this.mediaManagerAPI?.removeTags(this.mode, { tags });
      this.settings.uniqueTags = this.settings.uniqueTags.filter((tag) => !tags.includes(tag));
      this.ads.forEach((ad) => {
        ad.tags = ad.tags.filter((tag) => !tags.includes(tag));
      });
      this.isPendingRequest = false;
    },
    getEditPayload(ad: MediaManager.AdCard) {
      const data: MediaManager.RequestTypes.UpdateContentGenerally = {
        id: ad.id,
      };

      if (this.mode !== MediaManager.Mode.IMAGES) {
        data.headline = ad.headline;
      }

      if (this.mode === MediaManager.Mode.ADS && ad.image && !ad.image.base64?.includes('http')) {
        const base64 = ad.image.base64?.split(';base64,')?.[1] ?? '';

        data.file = {
          ...ad.image,
          base64,
          md5: md5(base64),
        };
      }
      data.tags = ad.tags;

      return cloneDeep(data);
    },
    async editAd(ad: MediaManager.AdCard) {
      const data = this.getEditPayload(ad);
      const serverData = await this.mediaManagerAPI?.editData(this.mode, data);
      const editedData = serverData?.data;

      if (editedData?.id) {
        this.ads.forEach((currentAd) => {
          if (currentAd.id === ad.id) {
            Object.assign(currentAd, {
              ...MediaManagerHelpers.getAdCardTypeData(
                editedData,
                this.settings.uniqueTrafficSources,
                this.settings.metricsDropdown
              ),
              id: editedData.id,
              metrics: currentAd.metrics,
              usedOn: currentAd.usedOn,
            });
            this.activeAd = MediaManagerHelpers.getActiveAdDto(currentAd, this.activeAd);
          }
        });
      }

      return serverData;
    },
    async getData() {
      if (!this.isCampaignCreator) {
        window.scrollTo({ top: 0 });
      }

      try {
        this.ads = [];
        const data = await this.mediaManagerAPI?.getData(this.mode, this.filterParams, 'media-manager-data');

        if (data) {
          this.totalPages = data.total_pages;
          this.totalRows = data.total_items;
          await this.insertNewAdsData(data.contents, false);
        }

        this.isLoadingAds = false;
      } catch (error: any) {
        if (!AbortController.isAbortError(error)) {
          this.errorMessage = `An error occurred while getting ${this.mode}s. Please Try Again.`;
          this.reset();
        }
      }
    },
    async onDeleteAds(deletableAds: MediaManager.AdCard[]) {
      const responses = await Promise.allSettled(
        deletableAds.map((deletableAd) =>
          this.mediaManagerAPI
            ?.deleteData(this.mode, {
              id: deletableAd.id,
              status: 0,
            })
            .then(() => {
              this.ads = this.ads.filter((ad) => !(ad.id === deletableAd.id && deletableAd.isEditable));
            })
        )
      );
      return responses;
    },
    onBulkTagInsert(data: { tags: string[]; deletedTags: string[] }) {
      this.ads.forEach((ad) => {
        if (this.selectedAds.find((selectedAd) => selectedAd.id === ad.id)) {
          const selectedTag = [...ad.tags.filter((tag) => !data.deletedTags.includes(tag)), ...data.tags];
          ad.tags = uniq(selectedTag);
        }
      });
      data.tags.forEach((tag) => {
        if (!this.settings.uniqueTags.includes(tag)) {
          this.settings.uniqueTags.push(tag);
        }
      });
    },
    onSelectAllClick(value: boolean) {
      this.ads.forEach((ad) => {
        ad.selected = value;
      });
    },
    onDisplayChange(isTableView: boolean) {
      this.isTableView = isTableView;
      this.onSelectAllClick(false);
    },
  },
  debounce: {
    updateFilters: timeout,
  },
});
