/* eslint-disable import/no-cycle */
import useAbGroups from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useAdStore from '@/views/Automation/CampaignCreatorV2/store/Ad';
import useAdSetStore from '@/views/Automation/CampaignCreatorV2/store/AdSet';
import useCampaignStore from '@/views/Automation/CampaignCreatorV2/store/Campaign';
import { getIdForEntity, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { Level, NavigationMenu } from '@/views/Automation/CampaignCreatorV2/types';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

export interface Navigation {
  currentStep: number;
  updated?: string;
  currentLevel: Level;
  campaignInternalId: string;
  adSetInternalId?: string;
  adInternalId?: string;
  creativeInternalId: string;
  campaignInternalNavigation: {
    [internalCampaignId: string]: { [abGroupType: string]: string }; // budget: tab1
  };
  adSetInternalNavigation: {
    [internalCmpAdSetId: string]: {
      [abGroupType: string]: string; // targeting: tab2
    };
  };
  adInternalNavigation: {
    [internalCmpAdId: string]: {
      [abGroupType: string]: string; // ad: tag32
    };
  };
  creativeInternalNavigation: {
    [internalCmpAdId: string]: {
      [abGroupType: string]: string; // creative: tag32
    };
  };
  targetingInternalNavigation: {
    [internalCmpAdsetId: string]: {
      [abGroupType: string]: string; // targeting: tag32
    };
  };
  isTransition: boolean;
  nextStepButtonErrorTooltip: string;
}

const useNavigationStore = defineStore('navigation', {
  state: (): Navigation => ({
    currentStep: 0,
    campaignInternalId: '',
    adSetInternalId: '',
    adInternalId: '',
    creativeInternalId: '',
    updated: '',
    campaignInternalNavigation: {},
    adSetInternalNavigation: {},
    adInternalNavigation: {},
    creativeInternalNavigation: {},
    targetingInternalNavigation: {},
    currentLevel: Level.Campaign,
    isTransition: false,
    nextStepButtonErrorTooltip: '',
  }),
  getters: {
    getCurrentLevel(): Level {
      return this.currentLevel;
    },
    getCurrentNavigation(): {
      entity: Level;
      buildId: string;
      internalId: string;
    } {
      const buildId =
        // eslint-disable-next-line no-nested-ternary
        this.getCurrentLevel === Level.Campaign
          ? this.getCampaignBuildId
          : this.getCurrentLevel === Level.AdSet
          ? this.getAdsetBuildId
          : this.getAdBuildId;
      const internalId =
        // eslint-disable-next-line no-nested-ternary
        this.getCurrentLevel === Level.Campaign
          ? this.getCampaignInternalId
          : this.getCurrentLevel === Level.AdSet
          ? this.getAdSetInternalId
          : this.getAdInternalId;
      if (!internalId) throw new Error('Internal Id not found');
      return {
        entity: this.currentLevel,
        buildId,
        internalId,
      };
    },
    getNavigationForEntity(state) {
      return (entity: SupportedEntities) => {
        if (entity === SupportedEntities.campaign) {
          return { internalId: this.campaignInternalId, buildId: this.getCampaignBuildId, entity };
        }
        if (entity === SupportedEntities.adset) {
          return { internalId: this.adSetInternalId, buildId: this.getAdsetBuildId, entity };
        }
        if (entity === SupportedEntities.ad) {
          return { internalId: this.adInternalId, buildId: this.getAdBuildId, entity };
        }
        if (entity === SupportedEntities.creative) {
          return { internalId: this.creativeInternalId, buildId: this.getAdBuildId, entity };
        }
        if (entity === SupportedEntities.targeting) {
          return { internalId: this.adSetInternalId, buildId: this.getAdsetBuildId, entity };
        }
        throw new Error('Entity not found');
      };
    },
    getCurrentStep(): number {
      return this.currentStep;
    },
    getCampaignInternalId(): string {
      return this.campaignInternalId;
    },
    getCampaignBuildId(): string {
      const id = getIdForEntity(SupportedEntities.campaign, [this.campaignInternalId]);
      return id;
    },
    getAdSetInternalId(): string | undefined {
      return this.adSetInternalId;
    },
    getAdsetBuildId(): string {
      if (!this.adSetInternalId) {
        throw new Error('Ad Set Internal Id is not set.');
      }
      const id = getIdForEntity(SupportedEntities.adset, [this.campaignInternalId, this.adSetInternalId]);
      return id;
    },

    getAdBuildId(): string {
      if (!this.adInternalId) {
        throw new Error('Ad Internal Id is not set.');
      }
      const id = getIdForEntity(SupportedEntities.ad, [this.campaignInternalId, this.adInternalId]);
      return id;
    },
    getAdInternalId(): string | undefined {
      return this.adInternalId;
    },
    getAdAccountId(): number {
      return useCampaignStore().getField('account_id').value[0]?.value || 0;
    },
    getCurrentAbGroupFor: (state) => (entity: SupportedEntities, abGroupType: string, overrideBuildId?: string) => {
      if (entity === SupportedEntities.campaign) {
        const id = overrideBuildId || getIdForEntity(SupportedEntities.campaign, [state.campaignInternalId]);
        return state.campaignInternalNavigation[id]?.[abGroupType];
      }
      if (!(overrideBuildId || state.adSetInternalId)) {
        throw new Error('Ad Set Internal Id is not set.');
      }
      if (entity === SupportedEntities.adset) {
        const id =
          overrideBuildId ||
          getIdForEntity(SupportedEntities.adset, [
            state.campaignInternalId,
            (overrideBuildId || state.adSetInternalId)!,
          ]);
        return state.adSetInternalNavigation[id]?.[abGroupType];
      }
      if (entity === SupportedEntities.targeting) {
        const id =
          overrideBuildId ||
          getIdForEntity(SupportedEntities.adset, [
            state.campaignInternalId,
            (overrideBuildId || state.adSetInternalId)!,
          ]);
        return state.targetingInternalNavigation[id]?.[abGroupType];
      }

      if (!state.adInternalId) {
        throw new Error('Ad Internal Id is not set.');
      }
      if (entity === SupportedEntities.ad) {
        const id =
          overrideBuildId || getIdForEntity(SupportedEntities.ad, [state.campaignInternalId, state.adInternalId]);
        return state.adInternalNavigation[id]?.[abGroupType];
      }
      if (entity === SupportedEntities.creative) {
        const id =
          overrideBuildId || getIdForEntity(SupportedEntities.ad, [state.campaignInternalId, state.adInternalId]);
        return state.creativeInternalNavigation[id]?.[abGroupType];
      }

      throw new Error('Entity not found');
    },
    getNavigation(): NavigationMenu[] {
      const campaignStore = useCampaignStore();
      const campaigns = campaignStore.getItemsForNavigation;
      const adsetStore = useAdSetStore();
      const adsets = adsetStore.getItemsForNavigation;
      const adStore = useAdStore();
      const ads = adStore.getItemsForNavigation;

      return campaigns.map((campaign) => {
        const children = adsets.filter((adset) => adset.campaignId === campaign.id);

        const newChildrens = children.map((adset) => {
          const children = ads.filter((ad) => ad.adSetId === adset.id);
          return { ...adset, children };
        });
        return { ...campaign, children: newChildrens };
      });
    },
    getNextStepButtonErrorTooltip(): string {
      return this.nextStepButtonErrorTooltip;
    },
  },
  actions: {
    saveState() {
      return { navigation: this.$state };
    },
    loadState(template: any) {
      const previus = template.navigation as Navigation;
      if (previus) {
        this.campaignInternalNavigation = { ...this.campaignInternalNavigation, ...previus.campaignInternalNavigation };
        this.adSetInternalNavigation = { ...this.adSetInternalNavigation, ...previus.adSetInternalNavigation };
        this.adInternalNavigation = { ...this.adInternalNavigation, ...previus.adInternalNavigation };
        this.creativeInternalNavigation = { ...this.creativeInternalNavigation, ...previus.creativeInternalNavigation };
        this.targetingInternalNavigation = {
          ...this.targetingInternalNavigation,
          ...previus.targetingInternalNavigation,
        };
      }
    },
    setStep(step: number) {
      this.currentStep = step;
    },
    setInternalNavigation(entity: SupportedEntities, abGroupType: string, abGroupId: string) {
      const abStore = useAbGroups();
      const requestedGroup = abStore.getAbGroup(abGroupId, entity);
      if (!requestedGroup) {
        throw new Error(`Requested group not found ${entity} ${abGroupId}.`);
      }
      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [this.campaignInternalId]);
        this.campaignInternalNavigation = {
          ...this.campaignInternalNavigation,
          [id]: {
            ...this.campaignInternalNavigation[id],
            [abGroupType]: abGroupId,
          },
        };
        return;
      }
      if (!this.adSetInternalId) {
        throw new Error('Ad Set Internal Id is not set.');
      }
      if (entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [this.campaignInternalId, this.adSetInternalId]);
        this.adSetInternalNavigation = {
          ...this.adSetInternalNavigation,
          [id]: {
            ...this.adSetInternalNavigation[id],
            [abGroupType]: abGroupId,
          },
        };
        return;
      }
      if (entity === SupportedEntities.targeting) {
        const id = getIdForEntity(SupportedEntities.adset, [this.campaignInternalId, this.adSetInternalId]);
        this.targetingInternalNavigation = {
          ...this.targetingInternalNavigation,
          [id]: {
            ...this.targetingInternalNavigation[id],
            [abGroupType]: abGroupId,
          },
        };
        return;
      }
      if (!this.adInternalId) {
        throw new Error('Ad Internal Id is not set.');
      }
      if (entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [this.campaignInternalId, this.adInternalId]);
        this.adInternalNavigation = {
          ...this.adInternalNavigation,
          [id]: {
            ...this.adInternalNavigation[id],
            [abGroupType]: abGroupId,
          },
        };
        return;
      }
      if (entity === SupportedEntities.creative) {
        const id = getIdForEntity(SupportedEntities.ad, [this.campaignInternalId, this.adInternalId]);
        this.creativeInternalNavigation = {
          ...this.creativeInternalNavigation,
          [id]: {
            ...this.creativeInternalNavigation[id],
            [abGroupType]: abGroupId,
          },
        };
        return;
      }
      throw new Error('Entity not found');
    },
    setCampaignInternalId(campaignInternalId: string) {
      this.campaignInternalId = campaignInternalId;
    },
    setAdSetInternalId(adSetInternalId?: string) {
      this.adSetInternalId = adSetInternalId;
    },
    setAdInternalId(adInternalId?: string) {
      this.adInternalId = adInternalId;
    },
    setCreativeInternalId(creativeInternalId: string) {
      this.creativeInternalId = creativeInternalId;
    },
    setCurrentLevel(entity: Level) {
      this.currentLevel = entity;
    },
    cloneNavigation(
      entity:
        | SupportedEntities.campaign
        | SupportedEntities.adset
        | SupportedEntities.ad
        | SupportedEntities.targeting
        | SupportedEntities.creative,
      { currentBuildId, newBuildId }: { currentBuildId: string; newBuildId: string }
    ) {
      if (entity === SupportedEntities.campaign) {
        this.campaignInternalNavigation = {
          ...this.campaignInternalNavigation,
          [newBuildId]: cloneDeep(this.campaignInternalNavigation[currentBuildId]),
        };
      }
      if (entity === SupportedEntities.adset) {
        this.adSetInternalNavigation = {
          ...this.adSetInternalNavigation,
          [newBuildId]: cloneDeep(this.adSetInternalNavigation[currentBuildId]),
        };
      }
      if (entity === SupportedEntities.ad) {
        this.adInternalNavigation = {
          ...this.adInternalNavigation,
          [newBuildId]: cloneDeep(this.adInternalNavigation[currentBuildId]),
        };
      }
      if (entity === SupportedEntities.creative) {
        this.creativeInternalNavigation = {
          ...this.creativeInternalNavigation,
          [newBuildId]: cloneDeep(this.creativeInternalNavigation[currentBuildId]),
        };
      }
      if (entity === SupportedEntities.targeting) {
        this.targetingInternalNavigation = {
          ...this.targetingInternalNavigation,
          [newBuildId]: cloneDeep(this.targetingInternalNavigation[currentBuildId]),
        };
      }
    },
    delete(
      entity:
        | SupportedEntities.campaign
        | SupportedEntities.adset
        | SupportedEntities.ad
        | SupportedEntities.targeting
        | SupportedEntities.creative,
      buildId: string
    ) {
      if (entity === SupportedEntities.campaign) {
        delete this.campaignInternalNavigation[buildId];
        this.campaignInternalNavigation = { ...this.campaignInternalNavigation };
      }
      if (entity === SupportedEntities.adset) {
        delete this.adSetInternalNavigation[buildId];
        this.adSetInternalNavigation = { ...this.adSetInternalNavigation };
      }
      if (entity === SupportedEntities.ad) {
        delete this.adInternalNavigation[buildId];
        this.adInternalNavigation = { ...this.adInternalNavigation };
      }
      if (entity === SupportedEntities.creative) {
        delete this.creativeInternalNavigation[buildId];
        this.creativeInternalNavigation = { ...this.creativeInternalNavigation };
      }
      if (entity === SupportedEntities.targeting) {
        delete this.targetingInternalNavigation[buildId];
        this.targetingInternalNavigation = { ...this.targetingInternalNavigation };
      }
    },
    triggerTransition() {
      this.isTransition = true;

      setTimeout(() => {
        this.isTransition = false;
      });
    },
    onActiveSelect(view: Level, campaignId: string, adsetId?: string, adId?: string) {
      this.setCurrentLevel(view);
      this.setCampaignInternalId(campaignId);
      this.setAdInternalId(adId);
      this.setAdSetInternalId(adsetId);
      document.getElementById('cc-cc-facebook__body__content__settings')?.scrollTo({ top: 0 });
      document.getElementById('cc-cc-facebook__body__content__preview')?.scrollTo({ top: 0 });

      const activeChild = document.getElementById(`scroll-${adId ?? adsetId ?? campaignId}`);
      activeChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    setNextStepButtonErrorTooltip(tooltip: string) {
      this.nextStepButtonErrorTooltip = tooltip;
    },
  },
});

export default useNavigationStore;
