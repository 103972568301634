<template>
  <div class="content-wrapper contents-form-container has-fixed-footer">
    <div class="contents-form-container__title">
      <h2>Upload New {{ $c_entityName }}</h2>
    </div>
    <preloader v-show="preloaderSubmit"></preloader>
    <preloader v-if="preloader"></preloader>

    <div v-show="!preloaderSubmit" v-else>
      <div>
        <template>
          <!-- Gemini select advertiser -->
          <div class="col-lg-6" style="padding-left: 0">
            <b-form-group
              v-if="adgroups"
              horizontal
              :label-cols="3"
              breakpoint="md"
              label-class="text-md-right"
              label-for="advertiser"
            >
              <label slot="label" style="padding: 0">
                Advertiser:<span class="text-danger"> *</span>
              </label>
              <search-select
                id="advertiser"
                v-validate="'required'"
                placeholder="Select advertiser"
                label="name"
                item-value="id"
                :items="geminiAdvertisers"
                v-model="geminiAdvertiserId"
              ></search-select>
            </b-form-group>
          </div>
          <content-uploader-form
            :traffic-source-type="$c_trafficSourceType"
            :account="$c_account"
            :campaign="$c_campaignData"
            :adgroups="adgroups"
            :ad-group="adGroup"
            v-model="uploaderModel"
            :target-url="$c_targetUrl"
            :site-name="$c_siteName"
            :default-contents="defaultContents"
            mode="create"
            :type="$c_entityName"
          />
        </template>
      </div>

      <div v-if="!preloader" class="fixed-footer">
        <div class="fixed-footer__actions d-flex justify-content-end">
          <b-btn class="primary-button" :disabled="!uploaderModel" @click="$_submit"><i class="fa fa-upload" aria-hidden="true"></i> Upload {{ $c_entityName }}</b-btn>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHelper from '@sh/components/Utils/ImageEditor/ImageHelper';
import contentUploaderForm from './Uploader/Index.vue';
import notifications from '@sh/mixins/notifications';
import SearchSelect from '@/components/Utils/SearchSelect';
import moment from 'moment';

export default {
  name: 'ContentsPage',
  components: {
    contentUploaderForm,
    SearchSelect,
  },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      preloaderSubmit: false,
      account: null,
      campaign: null,
      uploaderModel: null,
      defaultContents: null,
      adgroups: null,
      adGroup: null,
      geminiAdvertisers: null,
      geminiAdvertiserId: null,
      retryId: null,
      campaignType: '',
    };
  },
  computed: {
    $c_entityName() {
      return this.$route.query.type;
    },
    $c_trafficSourceType() {
      return { uniqueName: this.account.type.uniqueName || null, name: this.account.type.name };
    },
    $c_account() {
      return { id: this.account.id, name: this.account.name || null };
    },
    $c_campaignData() {
      return { name: this.campaign.name || null, type: this.campaign.campaignType || this.campaignType || null };
    },
    $c_targetUrl() {
      return this.$route.query.target_url || '';
    },
    $c_siteName() {
      return this.$route.query.site_name || '';
    },
  },
  async created() {
    this.preloader = true;
    try {
      const accountId = this.$route.query.account_id;
      const campaignId = this.$route.query.campaign_id;
      this.retryId = this.$route.query.retryId || null;
      const _defaultContents = this.$route.query.default ? JSON.parse(this.$route.query.default) : null;
      const promises = [this.$api.trafficSources.account(accountId), this.$api.campaigns.get(campaignId)];
      // If Retry content uploading
      if (_defaultContents) {
        const defaultContents = _defaultContents.filter((content) => content.imageUrl !== '');
        const imagesUrls = defaultContents.map((item) => item.imageUrlHQ || item.imageUrl);
        promises.push(this.$api.uploader.imageProxy(imagesUrls));
        let images = null;
        [this.account, this.campaign, images] = await Promise.all(promises);
        const payload = { contents: [], images: [] };
        if (['Mgid', 'Adskeeper'].indexOf(this.campaign.traffic_source_unique_name) > -1) {
          const { campaign: { form: { campaignType } } } = await this.$api.campaignCreator.getCampaignData(this.campaign.traffic_source_unique_name, this.campaign.traffic_source_account_id, this.campaign.traffic_source_campaign_id);
          this.campaignType = campaignType;
        }
        defaultContents.forEach((item) => {
          const content = { headline: item.headline, imageMd5: item.imageMd5, targetUrl: item.targetUrl };
          if (item.cpc) content.cpc = item.cpc.toString();
          if (item.category) content.category = item.category;
          if (item.brandName) content.brandName = item.brandName;
          if (item.contentType) content.contentType = item.contentType;
          if (item.description) content.description = item.description;
          if (item.sponsoredBy) content.sponsoredBy = item.sponsoredBy;
          if (item.displayUrl) content.displayUrl = item.displayUrl;
          if (item.sectionName) content.sectionName = item.sectionName;
          if (item.advertText) content.advertText = item.advertText;
          const mimeType = ImageHelper.getMimeType(images[item.imageUrlHQ || item.imageUrl]);
          const base64 = `data:${mimeType};base64,${images[item.imageUrlHQ || item.imageUrl]}`;
          const image = { base64, md5: item.imageMd5, mimeType };
          payload.contents.push(content);
          payload.images.push({ original: image });
        });
        this.defaultContents = payload;
      } else {
        [this.account, this.campaign] = await Promise.all(promises);
        if (['Mgid', 'Adskeeper'].indexOf(this.campaign.traffic_source_unique_name) > -1) {
          const { campaign: { form: { campaignType } } } = await this.$api.campaignCreator.getCampaignData(this.campaign.traffic_source_unique_name, this.campaign.traffic_source_account_id, this.campaign.traffic_source_campaign_id);
          Object.assign(this.campaign, { campaignType });
        }
      }
      // Get gemini groups
      if (this.account.type.uniqueName === 'Gemini') {
        const groups = await this.$api.adgroups.performance(this.campaign.id, {
          startDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD'),
        });
        this.adgroups = groups.items.map((group) => ({
          id: group.traffic_source_adgroup_id,
          name: group.name,
        }));
        const { ad_group } = this.$route.query;
        if (ad_group) { // eslint-disable-line camelcase
          const adGroup = JSON.parse(ad_group);
          adGroup.id = `${adGroup.id}`;
          const findAdGroup = this.adgroups.find(({ id }) => adGroup.id === id);
          if (!findAdGroup) {
            this.adgroups.push(adGroup);
            this.adGroup = adGroup;
          }
        }
        this.geminiAdvertisers = await this.$api.campaignCreator.getGeminiAdvertisers(this.account.id);
      }
      this.preloader = false;
    } catch (error) {
      this.$n_failNotification({ title: 'An error occurred' });
    }
  },
  methods: {
    async $_submit() {
      if (this.uploaderModel !== null) {
        const payload = {
          contents: {
            contents: this.uploaderModel.contents,
            images: this.uploaderModel.images,
          },
        };
        if (this.retryId) payload.retryId = this.retryId;
        if (payload.contents) {
          payload.contents.contents.forEach((item) => {
            if (item.headline.ctaButton || item.headline.adDescription) {
              if (this.account.trafficSourceType.name === 'Outbrain') {
                item.callToAction = item.headline?.ctaButton.length ? item.headline.ctaButton.toUpperCase().replace(/\s+/g, '_') : null;
              }
              if (this.account.trafficSourceType.name === 'Taboola') {
                item.cta = item.headline?.ctaButton.length ? { cta_type: item.headline.ctaButton.toUpperCase().replace(/\s+/g, '_') } : null;
              }
              item.description = item.headline.adDescription || null;
              item.headline = item.headline.text;
            } else {
              item.headline = item.headline.text;
            }
          });
          payload.contents = {
            contents: payload.contents.contents,
            images: payload.contents.images,
            targetUrl: payload.contents.targetUrl,
          };
        }

        if (this.account.type.uniqueName === 'Gemini') {
          const { traffic_source_campaign_id } = this.campaign;
          const { adgroupId } = this.uploaderModel;
          Object.assign(payload, { advertiserId: this.geminiAdvertiserId });
          try {
            this.preloaderSubmit = true;
            this.$n_infoNotification({ title: 'Your ads are being processed!' });
            await this.$api.campaignCreator.createGeminiContents(traffic_source_campaign_id, adgroupId, this.account.id, payload);
            this.$n_successNotification({ title: 'Contents successfully created' });
            this.$router.push('/campaigns');
          } catch (error) {
            const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred';
            this.$n_failNotification({ title: message });
          }
        } else {
          this.preloaderSubmit = true;
          try {
            await this.$api.campaignCreator.createContents(this.campaign.id, payload);
            this.$n_successNotification({ title: 'Contents successfully created' });
            this.$router.push('/campaign-creator-queue');
          } catch (error) {
            const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred';
            this.$n_failNotification({ title: message });
          }
        }
        this.preloaderSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss">

</style>
