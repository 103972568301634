<template>
  <div class="campaign-creator-form">
    <!-- BASIC INFO SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>Basic Info</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Campaign Type -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('campaignType')" v-bind="$_getLabelProps('campaignType')">
              <cc-multi-select v-bind="$_getProps('campaignType')" v-on="$_getEvents('campaignType')" v-model="$c_components['campaignType'].value" />
            </component-wrapper>
          </div>

          <!-- Campaign Language -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('language')" v-bind="$_getLabelProps('language')">
              <cc-multi-select v-bind="$_getProps('language')" v-on="$_getEvents('language')" v-model="$c_components['language'].value" />
            </component-wrapper>
          </div>

          <!-- Campaign Category -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('categoryId')" v-bind="$_getLabelProps('categoryId')">
              <cc-nested-select v-bind="$_getProps('categoryId')" v-on="$_getEvents('categoryId')" v-model="$c_components['categoryId'].value" labelText="Select Category" />
            </component-wrapper>
          </div>

          <!-- Campaign Keyword for Search Feed Campaign Type -->
          <div class="col-md-6" v-if="$_isActive('keyword')">
            <component-wrapper v-bind="$_getLabelProps('keyword')">
              <cc-input v-bind="$_getProps('keyword')" v-on="$_getEvents('keyword')" v-model="$c_components['keyword'].value" />
            </component-wrapper>
          </div>

          <!-- Advertiser Name for Product, Content and Push Notification campaign types -->
          <div class="col-md-6" v-if="$_isActive('advertiserName')">
            <component-wrapper v-bind="$_getLabelProps('advertiserName')">
              <cc-input v-bind="$_getProps('advertiserName')" v-on="$_getEvents('advertiserName')" v-model="$c_components['advertiserName'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- SCHEDULING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Scheduling</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Start Date -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('start_date_model')" v-bind="$_getLabelProps('start_date_model')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('start_date_model')" v-on="$_getEvents('start_date_model')" v-model="$c_components['start_date_model'].value" />
                </div>
                <div class="col-md-6">
                  <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- SCHEDULING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Countries -->
          <div class="row col-md-4">
            <div class="col-md-4">
              <component-wrapper v-if="$_isActive('geo_Target')" v-bind="$_getLabelProps('geo_Target')">
                <cc-radio-button v-bind="$_getProps('geo_Target')" v-on="$_getEvents('geo_Target')" v-model="$c_components['geo_Target'].value" />
              </component-wrapper>
            </div>
            <div class="col-md-8 hidden-labels">
              <component-wrapper v-if="$_isActive('countries')" v-bind="$_getLabelProps('countries')">
                <cc-multi-select v-bind="$_getProps('countries')" v-on="$_getEvents('countries')" v-model="$c_components['countries'].value" />
              </component-wrapper>
            </div>
          </div>

          <!-- Cities -->
          <div class="row col-md-4">
            <div class="col-md-12 hidden-labels">
              <component-wrapper v-if="$_isActive('cities')" v-bind="$_getLabelProps('cities')">
                <cc-multi-select v-bind="$_getProps('cities')" v-on="$_getEvents('cities')" v-model="$c_components['cities'].value" />
              </component-wrapper>
            </div>
          </div>

          <!-- Browsers -->
          <div class="row col-md-4">
            <div class="col-md-4">
              <component-wrapper v-if="$_isActive('browser_Target')" v-bind="$_getLabelProps('browser_Target')">
                <cc-radio-button v-bind="$_getProps('browser_Target')" v-on="$_getEvents('browser_Target')" v-model="$c_components['browser_Target'].value" />
              </component-wrapper>
            </div>
            <div class="col-md-8 hidden-labels">
              <component-wrapper v-if="$_isActive('browsers')" v-bind="$_getLabelProps('browsers')">
                <cc-multi-select v-bind="$_getProps('browsers')" v-on="$_getEvents('browsers')" v-model="$c_components['browsers'].value" />
              </component-wrapper>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Desktop OS -->
          <div class=" row col-md-4">
            <div class="col-md-4">
              <component-wrapper v-if="$_isActive('os_Target')" v-bind="$_getLabelProps('os_Target')">
                <cc-radio-button v-bind="$_getProps('os_Target')" v-on="$_getEvents('os_Target')" v-model="$c_components['os_Target'].value" />
              </component-wrapper>
            </div>
            <div class="col-md-8 hidden-labels">
              <component-wrapper v-if="$_isActive('desktopOs')" v-bind="$_getLabelProps('desktopOs')">
                <cc-multi-select v-bind="$_getProps('desktopOs')" v-on="$_getEvents('desktopOs')" v-model="$c_components['desktopOs'].value" />
              </component-wrapper>
            </div>
          </div>

          <!-- Mobile OS -->
          <div class="row col-md-4 hidden-labels">

            <div class="col-md-12">
              <component-wrapper v-if="$_isActive('mobileOs')" v-bind="$_getLabelProps('mobileOs')">
                <cc-multi-select v-bind="$_getProps('mobileOs')" v-on="$_getEvents('mobileOs')" v-model="$c_components['mobileOs'].value" />
              </component-wrapper>
            </div>
          </div>

          <!-- Tablet OS -->
          <div class="row col-md-4 hidden-labels">
            <div class="col-md-12">
              <component-wrapper v-if="$_isActive('tabletOs')" v-bind="$_getLabelProps('tabletOs')">
                <cc-multi-select v-bind="$_getProps('tabletOs')" v-on="$_getEvents('tabletOs')" v-model="$c_components['tabletOs'].value" />
              </component-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LIMITS SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Limits</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Limit Type -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('limitType')" v-bind="$_getLabelProps('limitType')">
              <cc-radio-button v-bind="$_getProps('limitType')" v-on="$_getEvents('limitType')" v-model="$c_components['limitType'].value" />
            </component-wrapper>
          </div>

          <!-- Split Budget -->
          <div class="col-md-6" v-if="$_isActive('splitDailyLimitEvenly')">
            <component-wrapper v-if="$_isActive('splitDailyLimitEvenly')" v-bind="$_getLabelProps('splitDailyLimitEvenly')">
              <cc-checkbox v-bind="$_getProps('splitDailyLimitEvenly')" v-on="$_getEvents('splitDailyLimitEvenly')" v-model="$c_components['splitDailyLimitEvenly'].value" />
            </component-wrapper>
          </div>

          <!-- Daily Limit -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('dailyLimit')" v-bind="$_getLabelProps('dailyLimit')">
              <cc-input v-bind="$_getProps('dailyLimit')" v-on="$_getEvents('dailyLimit')" v-model="$c_components['dailyLimit'].value" />
            </component-wrapper>
          </div>

          <!-- Overall Limit -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('overallLimit')" v-bind="$_getLabelProps('overallLimit')">
              <cc-input v-bind="$_getProps('overallLimit')" v-on="$_getEvents('overallLimit')" v-model="$c_components['overallLimit'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TRACKING TAGS SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">6</span>
        <h1>Tracking Tags</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <div class="col-md-6">
            <div class="row">

              <!-- Utm Tagging -->
              <div class="col-md-12">
                <component-wrapper v-if="$_isActive('utm_tagging_model')" v-bind="$_getLabelProps('utm_tagging_model')">
                  <cc-checkbox v-bind="$_getProps('utm_tagging_model')" v-on="$_getEvents('utm_tagging_model')" v-model="$c_components['utm_tagging_model'].value" />
                </component-wrapper>
              </div>

              <!-- Utm Source -->
              <div class="col-md-12">
                <component-wrapper v-if="$_isActive('utm_source')" v-bind="$_getLabelProps('utm_source')">
                  <cc-input v-bind="$_getProps('utm_source')" v-on="$_getEvents('utm_source')" v-model="$c_components['utm_source'].value" />
                </component-wrapper>
              </div>

              <!-- Utm Medium -->
              <div class="col-md-12">
                <component-wrapper v-if="$_isActive('utm_medium')" v-bind="$_getLabelProps('utm_medium')">
                  <cc-input v-bind="$_getProps('utm_medium')" v-on="$_getEvents('utm_medium')" v-model="$c_components['utm_medium'].value" />
                </component-wrapper>
              </div>

              <!-- Utm Campaign -->
              <div class="col-md-12">
                <component-wrapper v-if="$_isActive('utm_campaign')" v-bind="$_getLabelProps('utm_campaign')">
                  <cc-input v-bind="$_getProps('utm_campaign')" v-on="$_getEvents('utm_campaign')" v-model="$c_components['utm_campaign'].value" />
                </component-wrapper>
              </div>

              <!-- Utm Campaign -->
              <div class="col-md-12">
                <component-wrapper v-if="$_isActive('utm_custom')" v-bind="$_getLabelProps('utm_custom')">
                  <cc-input v-bind="$_getProps('utm_custom')" v-on="$_getEvents('utm_custom')" v-model="$c_components['utm_custom'].value" />
                </component-wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <component-wrapper legend="Basic Info"></component-wrapper> -->

    <!-- Name -->
    <!-- <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
      <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
    </component-wrapper> -->

    <!-- Campaign Type -->
    <!-- <component-wrapper v-if="$_isActive('campaignType')" v-bind="$_getLabelProps('campaignType')">
      <cc-multi-select v-bind="$_getProps('campaignType')" v-on="$_getEvents('campaignType')" v-model="$c_components['campaignType'].value" />
    </component-wrapper> -->

    <!-- Campaign Language -->
    <!-- <component-wrapper v-if="$_isActive('language')" v-bind="$_getLabelProps('language')">
      <cc-multi-select v-bind="$_getProps('language')" v-on="$_getEvents('language')" v-model="$c_components['language'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Scheduling"></component-wrapper> -->

    <!-- Start Date -->
    <!-- <component-wrapper v-if="$_isActive('start_date_model')" v-bind="$_getLabelProps('start_date_model')">
      <cc-radio-button v-bind="$_getProps('start_date_model')" v-on="$_getEvents('start_date_model')" v-model="$c_components['start_date_model'].value" />
      <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Targeting"></component-wrapper> -->

    <!-- Countries -->
    <!-- <component-wrapper v-if="$_isActive('countries')" v-bind="$_getLabelProps('countries')">
      <cc-multi-select v-bind="$_getProps('countries')" v-on="$_getEvents('countries')" v-model="$c_components['countries'].value" />
    </component-wrapper> -->

    <!-- Cities -->
    <!-- <component-wrapper v-if="$_isActive('cities')" v-bind="$_getLabelProps('cities')">
      <cc-multi-select v-bind="$_getProps('cities')" v-on="$_getEvents('cities')" v-model="$c_components['cities'].value" />
    </component-wrapper> -->

    <!-- Browsers -->
    <!-- <component-wrapper v-if="$_isActive('browsers')" v-bind="$_getLabelProps('browsers')">
      <cc-multi-select v-bind="$_getProps('browsers')" v-on="$_getEvents('browsers')" v-model="$c_components['browsers'].value" />
    </component-wrapper> -->

    <!-- Desktop OS -->
    <!-- <component-wrapper v-if="$_isActive('desktopOs')" v-bind="$_getLabelProps('desktopOs')">
      <cc-multi-select v-bind="$_getProps('desktopOs')" v-on="$_getEvents('desktopOs')" v-model="$c_components['desktopOs'].value" />
    </component-wrapper> -->

    <!-- Mobile OS -->
    <!-- <component-wrapper v-if="$_isActive('mobileOs')" v-bind="$_getLabelProps('mobileOs')">
      <cc-multi-select v-bind="$_getProps('mobileOs')" v-on="$_getEvents('mobileOs')" v-model="$c_components['mobileOs'].value" />
    </component-wrapper> -->

    <!-- Tablet OS -->
    <!-- <component-wrapper v-if="$_isActive('tabletOs')" v-bind="$_getLabelProps('tabletOs')">
      <cc-multi-select v-bind="$_getProps('tabletOs')" v-on="$_getEvents('tabletOs')" v-model="$c_components['tabletOs'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Limits"></component-wrapper> -->

    <!-- Limit Type -->
    <!-- <component-wrapper v-if="$_isActive('limitType')" v-bind="$_getLabelProps('limitType')">
      <cc-radio-button v-bind="$_getProps('limitType')" v-on="$_getEvents('limitType')" v-model="$c_components['limitType'].value" />
    </component-wrapper> -->

    <!-- Daily Limit -->
    <!-- <component-wrapper v-if="$_isActive('dailyLimit')" v-bind="$_getLabelProps('dailyLimit')">
      <cc-input v-bind="$_getProps('dailyLimit')" v-on="$_getEvents('dailyLimit')" v-model="$c_components['dailyLimit'].value" />
    </component-wrapper> -->

    <!-- Overall Limit -->
    <!-- <component-wrapper v-if="$_isActive('overallLimit')" v-bind="$_getLabelProps('overallLimit')">
      <cc-input v-bind="$_getProps('overallLimit')" v-on="$_getEvents('overallLimit')" v-model="$c_components['overallLimit'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Tracking Tags"></component-wrapper> -->

    <!-- Utm Tagging -->
    <!-- <component-wrapper v-if="$_isActive('utm_tagging_model')" v-bind="$_getLabelProps('utm_tagging_model')">
      <cc-checkbox v-bind="$_getProps('utm_tagging_model')" v-on="$_getEvents('utm_tagging_model')" v-model="$c_components['utm_tagging_model'].value" />
    </component-wrapper> -->

    <!-- Utm Source -->
    <!-- <component-wrapper v-if="$_isActive('utm_source')" v-bind="$_getLabelProps('utm_source')">
      <cc-input v-bind="$_getProps('utm_source')" v-on="$_getEvents('utm_source')" v-model="$c_components['utm_source'].value" />
    </component-wrapper> -->

    <!-- Utm Medium -->
    <!-- <component-wrapper v-if="$_isActive('utm_medium')" v-bind="$_getLabelProps('utm_medium')">
      <cc-input v-bind="$_getProps('utm_medium')" v-on="$_getEvents('utm_medium')" v-model="$c_components['utm_medium'].value" />
    </component-wrapper> -->

    <!-- Utm Campaign -->
    <!-- <component-wrapper v-if="$_isActive('utm_campaign')" v-bind="$_getLabelProps('utm_campaign')">
      <cc-input v-bind="$_getProps('utm_campaign')" v-on="$_getEvents('utm_campaign')" v-model="$c_components['utm_campaign'].value" />
    </component-wrapper> -->

    <!-- Utm Custom -->
    <!-- <component-wrapper v-if="$_isActive('utm_custom')" v-bind="$_getLabelProps('utm_custom')">
      <cc-input v-bind="$_getProps('utm_custom')" v-on="$_getEvents('utm_custom')" v-model="$c_components['utm_custom'].value" />
    </component-wrapper> -->
  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'MgidForm',
  mixins: [formMixin],
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;
    }
  }

  .hidden-labels .component-wrapper .form-group legend {
    visibility:hidden;
  }
</style>
