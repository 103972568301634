<template>
  <div class="content-wrapper rules-container">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Rules</h1>
        <p class="section-header__content__subtitle"><a href="https://theoptimizer.io/help/article-categories/automation-optimization" target="_blank">Learn More <i class="fa fa-angle-right ml-1"></i></a></p>
      </div>
    </div>

    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
    />
    <template v-else>
      <div class="optimizer-table rules-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          name="rulesTableList"
          class="rules-wrapper"
          :hover="true"
          :enable-export="false"
          :selectable="true"
          select-label="Rules"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :resized-columns="resizedColumns"
          @resize="$_handleColumnsResize($event)"
          :sort="{ key: '_id', order: 'desc' }"
          :default-rows="25"
          row-key="_id"
          sticky
        >
          <template slot="search">
            <vue-opti-select-light
              :class="[ tableModel.selectedRows.length ? 'active-select' : '', 'optimizer-select icon-select dropdown-auto-width col-md-auto pr-0 mb-2']"
              :options="$c_batchActionOptions"
              :unique-key="({ value: { value } }) => value"
              button-type="static"
              @change="({ value }) => { $_batchAction(value) }"
              single
            >
              <template #BUTTON_PLACEHOLDER>
                <span class="button-placehoder-filter">
                  <i class="fa fa-ellipsis-h"></i>
                  <span>Actions</span>
                </span>
              </template>
            </vue-opti-select-light>
            <div class="ml-2 pr-0 mb-2">
              <new-rule-dropdown class="mb-2 mb-md-0" :config="config" />
            </div>
            <div class="ml-2 pr-0 mb-2" v-can-tooltip="'GlobalRule,false'">
              <new-rule-dropdown class="mb-2 mb-md-0 global-ddw" :placeholder="'New Global Rule'" :is-global-rule="true" :config="config" :disabled="!hasAccessGlobalRule" />
            </div>
            <b-btn class="secondary-button ml-2 mb-2" @click="$refs.templateModal.show()">
              <i class="fa fa-folder-open-o" aria-hidden="true" /> Load From Template
            </b-btn>
            <b-btn class="secondary-button ml-2 mb-2" @click="$refs.groupsManageModal.show()">
              <i class="fa fa-folder-open-o" aria-hidden="true" /> Manage Groups
            </b-btn>
          </template>
          <template slot="name" slot-scope="props">
            <b-link :to="{name: 'Edit Rule', params: {id: props.item._id}}">
              {{ props.item.name }}
            </b-link>
          </template>
          <template slot="status" slot-scope="props">
            <span
              class="status-item status-item-enabled"
              v-if="props.item.status"
            >
              <span class="circle mr-2" /> Running
            </span>
            <span
              v-else
              class="status-item status-item-disabled"
            >
              <span class="circle mr-2" /> Paused
            </span>
          </template>
          <template slot="conditions" slot-scope="props">
            {{ props.item.conditions }}
          </template>
          <template #conditions="{ item }">
            <template v-if="displayedRuleConditions[item._id].show.length">
              <span v-for="(data, index) in displayedRuleConditions[item._id].show" class="mr-8" :key="data.html + item._id + index" v-html="data.html" />

              <MoreItems v-if="displayedRuleConditions[item._id].more.length" :width="45">
                <div class="condition w-100 text-truncate" v-for="data in displayedRuleConditions[item._id].more" :key="data.html + item._id" v-html="data.html" />
              </MoreItems>
            </template>
          </template>
          <template #actions="{ item }">
            <div class="d-flex justify-content-center">
              <switch-button
                :loading="item.statusProcessing"
                :checked="item.status"
                :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                :update="(value) => $_updateStatus(item, value)"
              />
              <action-btn type="clone" title="Clone" class="mr-2" :to="{ name: 'Clone Rule', params: { id: item._id } }" />
              <action-btn type="execute" title="Execute" class="mr-2" :click="() => $_execute(item)" />
              <action-btn type="activity" title="Activity Log" class="mr-2" :click="() => $_goToSystemActivityPage(item)" />
              <action-btn type="delete" title="Delete" :click="() => $_delete(item)" />
            </div>
          </template>
        </vue-opti-table-light>
      </div>
      <rule-templates-modal ref="templateModal" :config="config" />
      <groups-manage-modal ref="groupsManageModal" :config="config" @update="$_load()" />
    </template>
  </div>
</template>

<script>
import uiHelper from '@sh/helpers/ui';
import NewRuleDropdown from '@sh/views/Rules/other/NewRuleDropdown';
import RuleTemplatesModal from '@sh/views/Rules/other/RuleTemplatesModal';
import GroupsManageModal from '@sh/views/Rules/other/GroupsManageModal';
import notifications from '@sh/mixins/notifications';
import moment from 'moment';
import { canAccess } from '@sh/helpers';
import MoreItems from '@sh/components/Utils/MoreItems.ts.vue';

const FORMAT = 'YYYY-MM-DD';
export default {
  name: 'Rules',
  components: {
    NewRuleDropdown,
    RuleTemplatesModal,
    GroupsManageModal,
    MoreItems,
  },
  mixins: [notifications],
  props: {
    configFile: { type: Object, required: true },
  },
  data() {
    return {
      preloader: true,
      uiHelper,
      selectedRules: [],
      config: null,
      resizedColumns: {},
      table: {
        fields: [{
          header: { content: 'Rule ID', style: '' },
          display: false,
          item: {
            key: '_id',
            content: (item) => item._id,
            style: { textAlign: 'center' },
            searchable: true,
          },
        },
        {
          header: { content: 'Actions', style: '' },
          display: true,
          item: {
            key: 'actions',
            slot: 'actions',
            style: { textAlign: 'center' },
          },
        },
        {
          header: { content: 'Name', style: '' },
          display: true,
          item: {
            key: 'name',
            slot: 'name',
            content: (item) => item.name,
            sortable: true,
            searchable: true,
            cellClass: 'cell-name',
            total: {
              parse: () => 1,
              content: (totals) => `Total: ${totals.name}`,
              style: { background: '#fffdf5', fontWeight: 'bold', textAlign: 'center' },
            },
          },
          colClass: 'sticky sticky--name',
        },
        {
          header: { content: 'Status', style: '' },
          display: true,
          item: {
            key: 'status',
            slot: 'status',
            content: (item) => item.status,
            sortable: true,
            style: { textAlign: 'center' },
          },
        },
        {
          header: { content: 'Type', style: '' },
          display: true,
          item: {
            key: 'type',
            content: (item) => item.type,
            sortable: true,
            searchable: true,
            style: { textAlign: 'center' },
          },
        },
        {
          header: { content: 'Conditions', style: '' },
          display: true,
          item: {
            cellClass: 'cell-conditions',
            key: 'conditions',
            slot: 'conditions',
          },
        },
        {
          header: { content: 'Groups', style: '' },
          display: true,
          item: {
            key: 'group',
            content: (item) => item.group.join(', '),
            searchable: true,
            style: { textAlign: 'center' },
          },
        },
        {
          header: { content: 'Interval', style: '' },
          display: true,
          item: {
            key: 'interval',
            content: (item) => this.config.intervalsConfig.intervalsMap[item.stats_interval] || '',
            style: { textAlign: 'center' },
          },
        },
        {
          header: { content: 'Frequency', style: '' },
          display: true,
          item: {
            key: 'frequency',
            content: (item) => this.config.rotationConfig.rotationMap[item.rotation] || '',
            style: { textAlign: 'center' },
          },
        }],
        items: [],
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      canAccessGlobalRule: false,
      hasAccessGlobalRule: false,
    };
  },
  computed: {
    $c_batchActionOptions() {
      return [
        { value: { value: false, fn: this.$_batchUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Deactivate' },
        { value: { value: true, fn: this.$_batchUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Activate' },
      ];
    },
    displayedRuleConditions() {
      return this.table.items.reduce((conditions, item) => {
        conditions[item._id] = {
          show: this.config.conditionsConfig.conditionsTranslator(item.query, item.hour_of_day_new, true, item).slice(0, 2),
          more: this.config.conditionsConfig.conditionsTranslator(item.query, item.hour_of_day_new, true, item).slice(2),
        };
        return conditions;
      }, {});
    },
  },
  async created() {
    /** ***************** Set Resized Columns from Local Storage **************** */
    this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('rules', 'table');
    /** ************************************************************************* */
    this.hasAccessGlobalRule = await canAccess('GlobalRule');
    await this.$_load();
  },
  methods: {
    async $_updateStatus(item, action) {
      try {
        if (!this.hasAccessGlobalRule && item.is_global && action) {
          this.$n_failNotification({ title: 'You need to upgrade your plan in order to use Global Rules.' });
        } else {
          const result = await this.$api.rules.updateStatus(item._id, action);
          item.status = result.status;
          this.$n_successNotification({ title: result.message || 'Status updated successfully' });
        }
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
    },
    async $_delete(item) {
      const swal = await this.$swal({
        title: 'Delete rule ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        customClass: {
          container: 'confirm-delete-swal',
          confirmButton: 'primary-button primary-button--danger',
          cancelButton: 'secondary-button',
        },
      });
      if (swal.value === true) {
        try {
          const response = await this.$api.rules.remove(item._id);
          this.table.items = this.table.items.filter((row) => row._id !== item._id);
          this.$n_successNotification({ title: response.message || 'Rule successfully deleted!' });
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    async $_execute(item) {
      try {
        const response = await this.$api.rules.execute(item._id);
        this.$n_successNotification({ title: response.message || 'Rule was scheduled for execution!' });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('rules', 'table', payload);
    },
    $_checkUserAccess() {
      const user = this.$session.getUser();
      this.canAccessGlobalRule = (user.id === 2 || user.id === 112479);
    },
    // Load & reload only rules
    async $_load() {
      this.preloader = true;
      this.$_checkUserAccess();

      try {
        const promises = [this.$apiStore.rules.index()];
        if (!this.config) promises.push(this.configFile.getConfig());
        const [result, configuration] = await Promise.all(promises);
        if (configuration) this.config = configuration;
        this.table.items = result.map((_item) => {
          const item = { ..._item };
          const itemAction = item.rule_action_alias || item.rule_action;
          const key = `${item.level.toLowerCase()}_${itemAction.toLowerCase()}`;
          const rule = this.config.ruleConfig.ruleTypes[key] || null;
          item.type = rule ? rule.content : '';
          item.statusProcessing = false;
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error?.response?.data?.message || 'An error occurred' });
      }

      this.preloader = false;
    },
    $_batchAction(data) {
      if (this.tableModel.selectedRows.length > 0 || data.force) {
        data.fn(data.value);
      } else {
        this.$n_failNotification({ title: 'Select at least 1 row' });
      }
    },

    async $_batchUpdateStatus(value) {
      const localValue = value;
      const statusMessage = value ? 'Activated' : 'Deactivated';
      const ids = [];
      const idsMap = {};
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          idsMap[row._id] = true;
          ids.push(row._id);
          row.statusProcessing = true;
        }
      });

      if (ids.length > 0) {
        try {
          const result = await this.$api.rules.updateStatusBatch(ids, value);
          const resultMapIds = {};
          result.forEach((item) => { resultMapIds[item._id] = true; });

          if (result.length > 0) {
            this.table.items.forEach((row) => {
              if (resultMapIds[row._id]) row.status = localValue;
            });
            this.$n_successNotification({ title: `${result.length} rules ${statusMessage}!` });
          }
        } catch (error) {
          try {
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          } catch (err) {
            this.$n_failNotification({ title: 'An error occurred' });
          }
        }
        this.table.items.forEach((row) => {
          if (idsMap[row._id]) row.statusProcessing = false;
        });
      }
    },
    $_goToSystemActivityPage(item) {
      this.$router.push({ name: 'SystemActivityPage', params: { rule: item._id, startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT), endDate: moment().endOf('day').format(FORMAT) } });
    },
  },
};
</script>

<style lang="scss">
.rules-container {
  .section-header {
    margin-bottom: 1.5rem;
  }

  .optimizer-table {
    .table-holder {
      border: $border;

      &:has(.field .more-items .show) {
        min-height: 19rem;
      }
    }
  }

  .cell-conditions {
    &:has(.more-items .show) {
      overflow: visible;
    }

    .more-items-slot .condition span {
      width: 100%;
    }
  }
}

.global-ddw {
  width: 17rem;
}

// TODO: how to make this class global?
.disabled-feature {
  position: relative;
  filter: blur(0px);
}
.disabled-feature:after {
 content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // backdrop-filter: blur(.6px);
}
.no-action {
  pointer-events:none;
}

.disabled-feature:hover:before {
  content: "Please upgrade your plan to use this feature!";
  color: black;
  filter: none !important;
  font-size: 0.9em;
  padding: 5px 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  position: fixed;
  bottom: 113%;
  max-height: 88px;
  width: max-content;
  max-width: 160%;
}

@media (max-width: 767.98px) {
  .rules-container {
    .optimizer-table {
      > .datatable-wrapper {
        &-sticky {
          .stickyFooter {
            bottom: 9.4rem;
          }
        }
        > .header {
          >*:not(.datatable-search-wrapper) {
            width: 100%;
            margin: 0.5rem 1.5rem !important;
            padding: 0 !important;
            height: 4rem;
          }
        }
      }
    }
  }
}
</style>
