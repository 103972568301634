<template>
  <div class="optimizer-global-search">
    <div
      ref="globalSearchContainer"
      :class="[{ active: visible }, 'optimizer-global-search-container']"
    >
      <div v-if="!$session.isSearchFeed" class="search-header" :disabled="preloader" @click="$_showDropdown">
        <input
          type="text"
          placeholder="Search for campaigns, rules, smart lists, etc..."
          v-model="search"
        />
        <OptimizerIconV2 type="searchInputIcon" />
      </div>
      <div :class="[{ visible }, 'global-search-dropdown']">
        <LoadingSkeleton
          v-if="preloader"
          type="table"
          :table="{ headItems: 1, bodyItems: 8 }"
          shape="square"
        />
        <template v-else-if="filteredOptions.length">
          <div v-for="(group, index) in filteredOptions" class="group" :key="index">
            <div
              class="group-name"
              v-html="search ? group.content : `Recently Viewed ${group.content}`"
            ></div>
            <div v-for="option in group.options" class="group-items" :key="option.uid">
              <router-link
                v-html="option.content"
                :to="option.to"
                @click.native="$_onClickItem(group.value, option)"
                replace
              ></router-link>
            </div>
          </div>
        </template>
        <div v-else-if="!search" class="no-recently">
          <!-- <img src="sh_static/other/no-recently.svg"> -->
          <h4>Start typing to search for Campaigns, Rules, Smart Lists etc...</h4>
          <!-- <p>Please try again with a different search term.</p> -->
        </div>
        <div v-else class="no-results">
          <img src="sh_static/other/no-results.svg" />
          <h4>We couldn't find anything matching your search.</h4>
          <p>Please try again with a different search term.</p>
        </div>
      </div>
    </div>
    <div v-if="visible" class="search-backdrop"></div>
  </div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import { useSharedStore } from '@sh/stores';
import { mapWritableState } from 'pinia';
import { getTrafficSourceLogo } from '@sh/helpers';
import OptimizerIconV2 from '@sh/components/Utils/OptimizerIconV2.ts.vue';

export default {
  name: 'GlobalSearch',
  components: { LoadingSkeleton, OptimizerIconV2 },
  data() {
    return {
      search: '',
      visible: false,
      preloader: true,
      groups: [
        {
          value: 'campaigns',
          content: 'Campaigns',
          options: [],
          fuse: null,
          keys: [
            '_data.traffic_source_unique_name',
            '_data.name',
            '_data.traffic_source_campaign_id',
          ],
        },
        { value: 'rules', content: 'Rules', options: [], fuse: null, keys: ['_data.name'] },
        {
          value: 'smartlists',
          content: 'Smart Lists',
          options: [],
          fuse: null,
          keys: ['_data.name', '_data.typeName'],
        },
      ],
      filteredOptions: [],
    };
  },
  computed: {
    ...mapWritableState(useSharedStore, ['globalSearchCampaigns']),
  },
  watch: {
    search(value) {
      if (!this.visible && value) this.$_showDropdown();
      this.$_debounceUpdateFilteredOptions(this);
    },
  },
  created() {
    this.$_load();
  },
  methods: {
    async $_load() {
      this.preloader = true;
      const listsPromise = this.$apiStore.lists.all();
      const trafficSourceAccounts = await this.$apiStore.trafficSources.accounts();
      const trafficSourceAccountIds = trafficSourceAccounts.map((item) => item.id);
      const [trafficSourceTypes, campaigns, rules, smartlists] = await Promise.all([
        this.$apiStore.trafficSources.types(),
        this.$api.campaigns
          .getByFilters({ trafficSourceAccountIds, page: 1, pageSize: 20000 })
          .then((x) => x.data),
        this.$apiStore.rules.index(),
        listsPromise,
      ]);
      this.globalSearchCampaigns = campaigns;
      /* ************** Utils Data ************** */
      this.$options.today = moment(new Date()).format('YYYY-MM-DD');
      this.$options.trafficSourceTypesMap = {};
      trafficSourceTypes.forEach((item) => {
        this.$options.trafficSourceTypesMap[item.uniqueName] = item;
      });
      /* **************************************** */
      /* ************ Normalize Data ************ */
      const options = {
        campaigns: campaigns ? campaigns.map(this.$_normalizeCampaign) : [],
        rules: rules ? rules.map(this.$_normalizeRule) : [],
        smartlists: smartlists ? smartlists.map(this.$_normalizeSmartList) : [],
      };
      /* **************************************** */
      /* ******* Configure Searchable Data ****** */
      this.groups.forEach((group) => {
        group.options = options[group.value];
        group.fuse = new Fuse(group.options, {
          useExtendedSearch: true,
          ignoreFieldNorm: true,
          keys: group.keys,
        });
      });
      /* **************************************** */
      this.$_updateFilteredOptions();
      this.preloader = false;
    },
    $_normalizeCampaign(campaign) {
      const to = {
        name: 'Campaign',
        params: { id: campaign.id, type: campaign.traffic_source_unique_name },
        query: {
          type: this.$options.trafficSourceTypesMap[campaign.traffic_source_unique_name].id,
          account: campaign.traffic_source_account_id,
          tracker: campaign.tracker_account_id || 0,
          startDate: this.$route.query.startDate || this.$options.today,
          endDate: this.$route.query.endDate || this.$options.today,
        },
      };
      const content = `
      <div class="group-item item-campaign">
        <img class="item-icon ${campaign.traffic_source_unique_name}" src="${getTrafficSourceLogo(campaign.traffic_source_unique_name)}" />
        <div class="item-type">${campaign.traffic_source_unique_name}</div>
        <div class="item-name">${campaign.name}</div>
      </div>`;
      const type = 'campaign';
      const normalized = { type, uid: `${type}-${campaign.id}`, to, content, _data: campaign };
      return normalized;
    },
    $_normalizeRule(rule) {
      const to = { name: 'Edit Rule', params: { id: rule._id } };
      const content = `
        <div class="group-item item-rule">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.47682 14.6634H3.05161C2.81786 14.6634 2.6275 14.473 2.6275 14.2392V3.76078C2.6275 3.52703 2.81786 3.33667 3.05161 3.33667H4.52317" stroke="#546582" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M13.5232 14.6634H14.9484C15.1821 14.6634 15.3725 14.473 15.3725 14.2392V3.76078C15.3725 3.52703 15.1821 3.33667 14.9484 3.33667H13.4768" stroke="#546582" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M5.83594 5.87439H12.164" stroke="#546582" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M5.83594 12.1256H12.164" stroke="#546582" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M6.82227 9H11.1778" stroke="#546582" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <div class="item-type">Rule</div>
          <div class="item-name">${rule.name}</div>
        </div>`;
      const type = 'rule';
      const normalized = { type, uid: `${type}-${rule._id}`, to, content, _data: rule };
      return normalized;
    },
    $_normalizeSmartList(list) {
      const to = { name: 'Edit Smart List', params: { id: list.id } };
      const content = `
        <div class="group-item item-smart-list">
          <img class="item-icon ${list.typeName}" src="${getTrafficSourceLogo(list.typeName)}" />
          <div class="item-type">${list.typeName}</div>
          <div class="item-name">${list.name}</div>
        </div>`;
      const type = 'smartlist';
      const normalized = { type, uid: `${type}-${list.id}`, to, content, _data: list };
      return normalized;
    },
    // Filter Search Results
    $_updateFilteredOptions() {
      const limit = 10;
      const filteredOptions = [];
      // Get Recent Search if empty search input
      const recentSearch = !this.search.length ? this.$settings.recentlySearch.getItems() : null;
      this.groups.forEach((group) => {
        if (group.options.length) {
          let options = [];
          if (this.search.length) {
            // Search with Fuse.js
            options = group.fuse.search(this.search).map(({ item }) => item);
          } else if (recentSearch && recentSearch[group.value]) {
            // Get Recent Search
            const recentSearchMap = {};
            recentSearch[group.value].forEach((uid) => {
              recentSearchMap[uid] = true;
            });
            group.options.forEach((item) => {
              if (recentSearchMap[item.uid]) recentSearchMap[item.uid] = item;
            });
            recentSearch[group.value].forEach((uid) => {
              if (typeof recentSearchMap[uid] === 'object') options.push(recentSearchMap[uid]);
            });
          }
          const filteredGroup = {
            content: group.content,
            value: group.value,
            options: options.slice(0, limit),
          };
          if (options.length) filteredOptions.push(filteredGroup);
        }
      });
      this.filteredOptions = filteredOptions;
    },
    $_debounceUpdateFilteredOptions: debounce((vm) => {
      vm.$_updateFilteredOptions();
    }, 500),
    $_onClickEventListner(event) {
      if (this.visible) {
        const searchContainer = this.$refs.globalSearchContainer;
        let targetElement = event.target; // clicked element
        do {
          if (targetElement === searchContainer) return; // Clicked inside
          // Go up the DOM.
          targetElement = targetElement.parentNode;
        } while (targetElement);
        // Clicked outside, hide dropdown
        this.$_hideDropdown();
      }
    },
    $_showDropdown() {
      // Show dropdown
      if (!this.visible) {
        this.$_updateFilteredOptions();
        document.addEventListener('click', this.$_onClickEventListner); // Add event listener on blur
        document.body.classList.add('overflow-hidden');
        this.visible = true;
      }
    },
    $_onClickItem(group, item) {
      this.$settings.recentlySearch.setItem(group, item.uid);
      this.$_hideDropdown();
    },
    $_hideDropdown() {
      this.visible = false;
      document.removeEventListener('click', this.$_onClickEventListner); // Remove event listener on blur
      document.body.classList.remove('overflow-hidden');
      this.search = '';
    },
  },
};
</script>

<style lang="scss">
.optimizer-global-search {
  z-index: 5100;
  &-container {
    .global-search-dropdown {
      position: absolute;
      height: auto;
      // max-height: calc(100vh - 7rem);
      max-height: 50rem;
      width: 100%;
      visibility: hidden;
      padding: 1rem;
      background: $color-white;
      border: 0.1rem solid $color-light-gray;
      margin-top: 0.4rem;
      box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.18);
      border-radius: 0.4rem;
      color: $color-dark;
      overflow-y: auto;
      border-top: 0.1rem solid $color-light;
      z-index: 5100;

      @include scrollbar-on-hover();

      .group {
        &:first-of-type {
          .group-name {
            padding-top: 0;
          }
        }

        .group-name {
          text-align: left;
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: bold;
          color: $black-400;
          padding: 1.5rem 0 0.7rem;
        }

        .group-items {
          a {
            text-decoration: none;
          }

          .group-item {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            border-radius: 0.4rem;
            color: $black-800;
            font-size: 1.4rem;

            .item-icon {
              height: 2.2rem;
              margin-right: 1rem;
            }

            svg {
              margin-right: 1rem;
            }

            .item-type {
              margin-right: 2rem;
              color: $black-400;
              font-weight: 500;
              text-transform: uppercase;
              font-size: 1rem;
              width: 8rem;
            }

            .item-name {
              font-size: 1.4rem;
            }

            &:active,
            &:focus {
              background: $color-light;
            }

            &:hover {
              color: $color-dark;
              background: $color-light;
            }
          }
        }
      }
      .no-recently {
        padding: 3rem 2rem 5rem 2rem;
        text-align: center;

        img {
          width: 14rem;
          margin-bottom: 2rem;
        }

        h4 {
          font-size: 1.6rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.5rem;
          color: $black-500;
        }
      }

      .no-results {
        padding: 3rem 2rem 5rem 2rem;
        text-align: center;

        img {
          width: 14rem;
          margin-bottom: 2rem;
        }

        h4 {
          font-size: 1.6rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.5rem;
          color: $black-500;
        }
      }

      .optimizer-skeleton {
        // box-shadow: none;
        padding: 0.7rem;
        margin-bottom: 0;

        &-table {
          box-shadow: none;
          padding: 0;

          &__head {
            &__data {
              width: 20rem;
              height: 1.5rem;
              margin-bottom: 0;
            }
          }

          &__body {
            &__data {
              height: 2.7rem;
            }
          }
        }
      }
    }
  }
}

.search-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
