import { alert } from './alert';
import { api } from './api';
import Bid from './bid';
import BidCad from './bidCad';
import {
  bidModifierActionOptions,
  bidModifierModelTemplates,
  bidModifierToOptions,
  bidModifierUnitOptions,
} from './bidModifier';
import {
  adSetBudgetActionOptions,
  budgetActionOptions,
  budgetModelTemplates,
  budgetToOptions,
  budgetUnitOptions,
  otherBudgetActionOptions,
} from './budget';
import { coefficientActionOptions, coefficientModelTemplates, coefficientToOptions, coefficientUnitOptions } from './coefficient';
import Conditions from './conditions';
import { intervals, intervalsExclude, intervalsMap } from './interval';
import { rotation, rotationMap } from './rotation';
import RuleModel from './RuleModel';
import { enabledTypes, ruleTypes } from './ruleTypes';
import TargetCpa from './targetCpa';
import TargetRoas from './targetRoas';
import BidCap from './bidCap';

export default {
  /**
   * @param condition={}
   */
  async getConfig(condition = {}) {
    const [
      { fieldsGroup, fields, fieldsMap, conditions, nameConditions, statusConditions, conditionsTranslator, toFields, toSelfFields, learningStateConditions, dateConditions, dateFields, customDateConditions },
      { bidActionOptions, bidUnitOptions, bidMinMaxOptions, bidToOptions, bidModelTemplates, generateBidOptions },
      { bidCadActionOptions, bidCadUnitOptions, bidCadToOptions, bidCadModelTemplates, generateBidCadOptions },
      { targetCpaActionOptions, targetCpaUnitOptions, targetCpaToOptions, targetCpaModelTemplates },
      { targetRoasActionOptions, targetRoasUnitOptions, targetRoasToOptions, targetRoasModelTemplates },
      { bidCapActionOptions, bidCapUnitOptions, bidCapMinMaxOptions, bidCapToOptions, bidCapModelTemplates },
    ] = await Promise.all([Conditions.getData(condition), Bid.getData(), BidCad.getData(), TargetCpa.getData(), TargetRoas.getData(), BidCap.getData()]);
    return {
      alertConfig: { alert },
      intervalsConfig: { intervals, intervalsExclude, intervalsMap },
      rotationConfig: { rotation, rotationMap },
      conditionsConfig: {
        fieldsGroup, fields, fieldsMap, conditions, nameConditions, statusConditions, conditionsTranslator, toFields, toSelfFields, learningStateConditions, dateConditions, dateFields, customDateConditions,
      },
      bidConfig: {
        bidActionOptions, bidUnitOptions, bidMinMaxOptions, bidToOptions, bidModelTemplates, generateBidOptions,
      },
      budgetConfig: {
        budgetActionOptions, budgetUnitOptions, budgetToOptions, budgetModelTemplates, adSetBudgetActionOptions, otherBudgetActionOptions,
      },
      coefficientConfig: { coefficientActionOptions, coefficientUnitOptions, coefficientToOptions, coefficientModelTemplates },
      bidCadConfig: {
        bidCadActionOptions, bidCadUnitOptions, bidCadToOptions, bidCadModelTemplates, generateBidCadOptions,
      },
      bidModifierConfig: {
        bidModifierActionOptions, bidModifierUnitOptions, bidModifierToOptions, bidModifierModelTemplates,
      },
      targetCpaConfig: {
        targetCpaActionOptions, targetCpaUnitOptions, targetCpaToOptions, targetCpaModelTemplates,
      },
      targetRoasConfig: { targetRoasActionOptions, targetRoasUnitOptions, targetRoasToOptions, targetRoasModelTemplates },
      bidCapConfig: {
        bidCapActionOptions, bidCapUnitOptions, bidCapMinMaxOptions, bidCapToOptions, bidCapModelTemplates,
      },
      ruleConfig: { ruleTypes, enabledTypes },
      api,
      RuleModel,
    };
  },
};
