import widgets from './widgets';
import report from './report';

export default (F) => ({
  ...widgets(F),
  ...report(F),
  traffic_source_widget_id: F.create({
    header: { content: 'Widget ID' },
    item: {
      key: 'traffic_source_item_id',
      total: {
        parse: () => 1,
        content: (totals) => (totals.traffic_source_item_id ? `Total: ${totals.traffic_source_item_id}` : '-'),
      },
    },
    colClass: 'sticky sticky--traffic_source_widget_id',
  }),
});
