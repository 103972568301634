<template>
  <div class="performance-stats-container">
    <div class="performance-stats-header">
      <!-- <h2 v-if="!modal" class="performance-stats-header__title">{{ type.charAt(0).toUpperCase() + type.slice(1) }} Performance for {{ $c_dateRange || 'Last 30 days' }}</h2> -->
      <div class="performance-stats__actions">
        <vue-opti-select-light
          class="optimizer-select"
          label-key="text"
          unique-key="value"
          :value="groupBy"
          @change="({value}) => groupBy = value"
          button-block
          single
          :options="groupByOptions"
        >
        </vue-opti-select-light>
        <date-range v-model="dateRangePicker" :auto-emit="true" :query="false"></date-range>
      </div>
    </div>
    <!-- Table -->
    <loading-skeleton
      v-if="loading"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: false }"
    />
    <div class="py-5 color-theme-1 d-flex justify-content-center" style="min-height:30vh" v-if="$c_noData">
      <h4>Currently there are no data available for this Breakdown</h4>
    </div>
    <div :class="[ modal ? 'mb-0' : '', 'optimizer-table performance-table']">
      <vue-opti-table-light
        v-if="table.items.length > 0"
        :default-rows="100"
        :header-fields="table.fields"
        :items="table.items"
        :export-label="$c_exportLabel"
        :show-search="false"
        name="performance-table"
        v-model="tableModel"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        sticky
      >
        <template v-for="(item, slot) in mappedFields" :slot="slot" slot-scope="props">
          <div class="table-item" :key="slot">
            <span class="table-item__value" v-if="item.options.format === 'currency'">
              {{ ui.table.money(+props.item[slot]) }}
            </span>

            <span v-else-if="item.options.format === 'percentage'" :title="props.item[slot]" class="table-item__value">
              {{ ui.table.percentage(+props.item[slot]) }}
            </span>

            <span v-else class="table-item__value">
              {{ props.item[slot] }}
            </span>

          </div>
        </template>
      </vue-opti-table-light>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import dateHelper from '@sh/helpers/date';
import ui from '@sh/helpers/ui';
import notifications from '@sh/mixins/notifications';

import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'FacebookBreakdownPerformance',
  components: { LoadingSkeleton },
  mixins: [notifications],
  props: {
    trafficSource: { type: Object },
    itemId: { type: String },
    type: { type: String, default: 'campaign' },
    timezone: { type: String },
    id: { type: String },
    dateRange: { type: Object,
      default: () => ({
        startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment().endOf('day').format(FORMAT) }),
    },
    modal: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      ui,
      dateRangePicker: {
        startDate: moment(new Date()).subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment(new Date()).endOf('day').format(FORMAT),
      },
      groupByOptions: [
        { value: 'country', text: 'Country' },
        { value: 'age', text: 'Age' },
        { value: 'ad_format_asset', text: 'Ad Format' },
        { value: 'body_asset', text: 'Body Assets' },
        { value: 'call_to_action_asset', text: 'Call To action' },
        { value: 'description_asset', text: 'Description' },
        { value: 'gender', text: 'Gender' },
        { value: 'image_asset', text: 'Image' },
        { value: 'impression_device', text: 'Impression Device' },
        { value: 'link_url_asset', text: 'Link URL' },
        { value: 'product_id', text: 'Product Id' },
        { value: 'region', text: 'Region' },
        { value: 'title_asset', text: 'Title' },
        { value: 'video_asset', text: 'Video' },
        { value: 'dma', text: 'DMA' },
        { value: 'frequency_value', text: 'Frequency' },
        { value: 'hourly_stats_aggregated_by_advertiser_time_zone', text: 'Hourly, Adv. time' },
        { value: 'hourly_stats_aggregated_by_audience_time_zone', text: 'Hourly, Audience time' },
        { value: 'place_page_id', text: 'Place page Id' },
        { value: 'publisher_platform', text: 'Publisher Platform' },
        { value: 'platform_position', text: 'Platform Position' },
        { value: 'device_platform', text: 'Device Platform' },
      ],

      groupBy: 'age',
      performanceList: {
        tr_clicks: 'Clicks',
        cost: 'Cost',
        tr_conversions: 'Conversions',
        tr_revenue: 'Revenue',
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      resizedColumns: {},
      table: {
        fields: [],
        items: [],
      },
      mappedFields: {},
    };
  },
  computed: {
    $c_dateRange() {
      return dateHelper.getLabel(this.dateRangePicker.startDate, this.dateRangePicker.endDate);
    },
    $c_slotLabels() {
      return Object.keys(this.mappedFields);
    },
    $c_exportLabel() {
      return `${this.type}_performance_${this.dateRangePicker.startDate}_${this.dateRangePicker.endDate}`;
    },
    $c_noData() {
      return !this.table.items.length && !this.loading;
    },
  },
  watch: {
    dateRangePicker: {
      handler() {
        this.$_getPerformance();
      },
      deep: true,
    },
    groupBy() {
      this.$_getPerformance();
    },
  },
  created() {
    this.dateRangePicker = { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate };
    this.$watch('dateRange', () => {
      this.dateRangePicker.startDate = this.dateRange.startDate;
      this.dateRangePicker.endDate = this.dateRange.endDate;
    }, { deep: true });
  },
  methods: {
    async $_getPerformance() {
      this.loading = true;
      const { startDate, endDate } = this.dateRangePicker;


      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('performance', 'table');
      /** ************************************************************************* */

      try {
        const { data: { fields, performance } } = await this.$api.campaigns.getFacebookPerformanceBreakedown(
          moment(startDate).startOf('day').format('YYYY-MM-DD'),
          moment(endDate).endOf('day').format('YYYY-MM-DD'),
          this.itemId, this.groupBy, this.trafficSource.id,
        );
        this.$_handleTableFields(fields, performance);
        this.table.items = performance;
        this.loading = false;
      } catch (err) {
        this.$n_failNotification({ title: 'Something went wrong, please try again later!' });
        this.loading = false;
      }
    },
    $_handleTableFields(fields, performance) {
      const fieldsToRemove = ['traffic_source_campaign_id', 'name', 'status', 'payout', 'est_cost', 'est_net', 'est_roi'];
      const fieldsToMap = {
        // 'additional_fields.daily_budget': 'daily_budget',
        // 'additional_fields.budget': 'budget',
        // 'additional_fields.bid': 'cpc',
        'ga:pvs': 'pvs',
      };

      fields.forEach((item) => {
        fieldsToMap[item.item.key] ? item.item.key = fieldsToMap[item.item.key] : null;
        item.item.slot = item.item.key;
        item.colStyle = { width: '180px' };
      });

      if (performance.length) {
        const performanceFields = Object.keys(performance[0]);
        const filteredFields = fields.filter((item) => performanceFields.includes(item.item.key))
          .map((e) => ({ ...e, item: { ...e.item, content: (val) => val[e.item.key] } }));

        this.mappedFields = filteredFields.reduce((acc, item) => {
          acc[item.item.slot] = item;
          return acc;
        }, {});

        this.table.fields = [...filteredFields];
      } else {
        this.table.fields = [...this.table.fields, ...fields.filter((item) => !fieldsToRemove.includes(item.item.key))];
      }
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('performance', 'table', payload);
    },
    getDateFormat() {
      return this.groupBy === 'day' ? 'MMM D, YYYY' : 'MMM D, YYYY HH:mm:ss';
    },
    getMarkerContent(type, value, change) {
      return `<span>${this.chart.markerTypes[type].name}: ${this.ui.table.money(value - change)} => ${this.ui.table.money(value)} <span class="${change > 0 ? 'text-success' : 'text-danger'}">(${change > 0 ? '+' : ''}${this.ui.table.money(change)})</span></span>`;
    },
  },
};
</script>

<style lang="scss">
.performance-stats-container {
  .performance-stats-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    padding: 2rem 2rem 0 2rem;

    &__title {
      font-size: 1.8rem;
    }

    .performance-stats__actions {
      display: flex;

      .optimizer-select {
        width: 20rem;
      }

      .optimizer-date-range {
        margin-left: 1.5rem;
      }
    }
  }

  .performance-stats__main-chart {
    margin-bottom: 2rem;
    padding: 0;
    position: relative;

    .hide-chart-name {
      position: absolute;
      display: inline-block;
      width: 9rem;
      height: 1rem;
      background: #fff;
      bottom: .5rem;
    }
  }

  .dashboard-main-chart-skeleton {
      height: 40rem;

      .optimizer-skeleton__container {
        box-shadow: none;
        // display: flex;
        // flex-direction: row;

        &__rows {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // margin-right: 2.5rem;
        }

        &__row {
          // display: block;
          // width: 10rem;
          // height: 1.3rem;
        }

        &__shape-square {
          // margin-top: 0;
        }
      }
    }

    .performance-table {
      padding: 0;

      .table-item {
        text-align: center;
        position: relative;

        &__value {
          margin-right: 7rem;
        }

        &__change {
          position: absolute;
          z-index: 10;
          font-size: 1.3rem;
          right: 0;
          // top: 50%;
          // transform: translateY(-50%);
          text-align: right;
        }
      }

      .field {
        padding: .3rem;
      }

      .row.header {
        padding: 0 1.5rem;
      }

      .stickyHeader {

        th {
          border-top: .1rem solid $color-light-gray !important;
        }
      }
    }

    .optimizer-skeleton__actions__search {
      margin-right: 1rem;
    }
}
</style>
