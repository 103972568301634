export default (F) => ({
  traffic_source_widget_id: F.create({
    header: { content: 'Widget ID' },
    item: {
      key: 'traffic_source_widget_id',
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--traffic_source_widget_id',
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: null,
    },
  }),
  'cpc::Gemini': F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'cpc',
      key: 'cpc',
    },
  }).setOptions({}),
  'additional_fields.calculated_cpc': F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'calculated_cpc',
      key: 'additional_fields.calculated_cpc',
    },
  }),
  'additional_fields.negative': F.create({
    header: { content: 'Negative Keyword' },
    item: {
      slot: 'negative',
      key: 'additional_fields.negative',
    },
  }),
  'additional_fields.bid_strategy::Taboola': F.create({
    header: { content: 'Bid Strategy' },
    item: {
      slot: 'bid_strategy',
      key: 'additional_fields.bid_strategy',
    },
  }),
  'additional_fields.cpc::MediaGo': F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'cpc',
      key: 'additional_fields.cpc',
    },
  }),
  coefficient: F.create({
    header: { content: 'Coeff' },
    item: {
      slot: 'coefficient',
      key: 'coefficient',
    },
  }),
  optimization_action: F.create({
    header: { content: 'Performance' },
    item: {
      slot: 'optimization_action',
      key: 'optimization_action',
      sortable: true,
    },
  }),
});
