import { AdvancedReport, BackgroundReport } from '@/views/Tools/Reporting/helpers';
import { ReportType } from '@/views/Tools/Reporting/types';
import { FilterType } from '@/views/Tools/Reporting/types/Filter';
import { TrafficSource } from '@sh/types';

export type AvailableFilters = { activeFilters: FilterType[]; globalFilters: FilterType[] };
export type ReportFilters = Record<ReportType | string, Partial<AvailableFilters>>;

// below is specified each available filter based on traffic source and report type
// if configuration is missing by default all filters will be active and only DateRange and BreakDown will be global
export type TrafficSourceFilters = Record<TrafficSource | 'ALL', Partial<ReportFilters>>;
export const configuration: Partial<TrafficSourceFilters> = {
  ALL: {
    [ReportType.UniqueName]: {
      activeFilters: [FilterType.DateRange, FilterType.BreakDown],
      globalFilters: [FilterType.DateRange, FilterType.BreakDown],
    },
    [ReportType.Account]: {
      activeFilters: [FilterType.TrafficSource, FilterType.DateRange, FilterType.BreakDown],
      globalFilters: [FilterType.TrafficSource, FilterType.DateRange, FilterType.BreakDown],
    },
    [ReportType.Campaign]: {
      activeFilters: [FilterType.TrafficSource, FilterType.AdAccount, FilterType.DateRange, FilterType.BreakDown],
      globalFilters: [FilterType.TrafficSource, FilterType.DateRange, FilterType.BreakDown],
    },
    [ReportType.Content]: {
      activeFilters: [
        FilterType.TrafficSource,
        FilterType.AdAccount,
        FilterType.Campaign,
        FilterType.DateRange,
        FilterType.BreakDown,
      ],
      globalFilters: [FilterType.TrafficSource, FilterType.DateRange, FilterType.BreakDown],
    },
    [AdvancedReport]: {
      activeFilters: [
        FilterType.TrafficSource,
        FilterType.AdAccount,
        FilterType.ReportType,
        FilterType.Campaign,
        FilterType.DateRange,
        FilterType.BreakDown,
      ],
      globalFilters: [FilterType.TrafficSource, FilterType.DateRange, FilterType.BreakDown],
    },
    [BackgroundReport]: {
      activeFilters: [],
      globalFilters: [],
    },
    [ReportType.ClientCompliance]: {
      activeFilters: [FilterType.DateRange],
      globalFilters: [FilterType.DateRange],
    },
    [ReportType.AccountCompliance]: {
      activeFilters: [FilterType.User, FilterType.TrafficSourceComplianceFilter, FilterType.DateRange],
      globalFilters: [FilterType.User, FilterType.TrafficSourceComplianceFilter, FilterType.DateRange],
    },
    [ReportType.ContentCompliance]: {
      activeFilters: [FilterType.User, FilterType.TrafficSourceComplianceFilter, FilterType.DateRange],
      globalFilters: [FilterType.User, FilterType.TrafficSourceComplianceFilter, FilterType.DateRange],
    },
  },
};

export const ValidationKeys: Partial<Record<FilterType, string>> = {
  [FilterType.ReportType]: 'reportType',
  [FilterType.TrafficSource]: 'typeName',
  [FilterType.User]: 'selectedUserId',
  [FilterType.TrafficSourceComplianceFilter]: 'trafficSourceTypes',
};
