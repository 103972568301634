import { IconsV2 } from '@sh/types';

export interface NavItem {
  label: string;
  icon: { type: IconsV2; fill?: boolean };
  url: string;
  external?: boolean;
  id: string;
  disabled?: boolean;
}

export enum NavUrls {
  PROFILE = '/profile',
  SETTINGS = '/settings',
}

const profileNavItems: NavItem[] = [
  {
    label: 'Profile',
    url: NavUrls.PROFILE,
    icon: { type: 'userRounded' },
    id: 'profile',
  },
  {
    label: 'Settings',
    url: NavUrls.SETTINGS,
    icon: { type: 'settings' },
    id: 'settings',
  },
];

export default profileNavItems;
