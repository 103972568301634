<template>
  <div class="campaign-creator-form">
    <!-- BASIC INFO SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>Basic Info</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Content Type -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('content_type')" v-bind="$_getLabelProps('content_type')">
              <cc-radio-button v-bind="$_getProps('content_type')" v-on="$_getEvents('content_type')" v-model="$c_components['content_type'].value" />
            </component-wrapper>
          </div>

          <!-- Scheduling -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('scheduling_wrapper')" v-bind="$_getLabelProps('scheduling_wrapper')">
              <div v-if="$_isActive('scheduling_wrapper')" class="row">
                <div class="col-md-6">
                  <cc-date-picker v-bind="$_getProps('campaign_start')" v-on="$_getEvents('campaign_start')" v-model="$c_components['campaign_start'].value" />
                </div>
                <div class="col-md-6">
                  <cc-date-picker v-bind="$_getProps('campaign_end')" v-on="$_getEvents('campaign_end')" v-model="$c_components['campaign_end'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- BUDGET SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Budget</h1>
      </div>
      <div class="optimizer-card">
        <!-- <div class="row"> -->
        <!-- Network Targeting -->
        <!-- <div class="col-md-6">
            <component-wrapper v-if="$_isActive('tier_targeting')" v-bind="$_getLabelProps('tier_targeting')">
              <cc-radio-button v-bind="$_getProps('tier_targeting')" v-on="$_getEvents('tier_targeting')" v-model="$c_components['tier_targeting'].value" />
            </component-wrapper>
          </div> -->
        <!-- </div> -->
        <div class="row">
          <!-- CPC -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('cpc_model')" v-bind="$_getLabelProps('cpc_model')">
              <cc-checkbox v-bind="$_getProps('cpc_model')" v-on="$_getEvents('cpc_model')" v-model="$c_components['cpc_model'].value" />
              <cc-input v-if="$_isActive('cpc_desktop')" v-bind="$_getProps('cpc_desktop')" v-on="$_getEvents('cpc_desktop')" v-model="$c_components['cpc_desktop'].value" class="mt-3" />
              <cc-input v-if="$_isActive('cpc_tablet')" v-bind="$_getProps('cpc_tablet')" v-on="$_getEvents('cpc_tablet')" v-model="$c_components['cpc_tablet'].value" class="mt-3" />
              <cc-input v-if="$_isActive('cpc_mobile')" v-bind="$_getProps('cpc_mobile')" v-on="$_getEvents('cpc_mobile')" v-model="$c_components['cpc_mobile'].value" class="mt-3" />
            </component-wrapper>
          </div>
        </div>

        <div class="row">
          <!-- Maximum Daily Budget -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('cap_day')" v-bind="$_getLabelProps('cap_day')">
              <cc-input v-bind="$_getProps('cap_day')" v-on="$_getEvents('cap_day')" v-model="$c_components['cap_day'].value" />
            </component-wrapper>
          </div>

          <!-- Maximum Total Budget -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('cap_total')" v-bind="$_getLabelProps('cap_total')">
              <cc-input v-bind="$_getProps('cap_total')" v-on="$_getEvents('cap_total')" v-model="$c_components['cap_total'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TARGETING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Countries -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('countries_model')" v-bind="$_getLabelProps('countries_model')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('countries_model')" v-on="$_getEvents('countries_model')" v-model="$c_components['countries_model'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('country_targeting')" v-bind="$_getProps('country_targeting')" v-on="$_getEvents('country_targeting')" v-model="$c_components['country_targeting'].value" />
                </div>
                <div class="col-md-12">
                  <cc-multi-select v-if="$_isActive('country_codes')" v-bind="$_getProps('country_codes')" v-on="$_getEvents('country_codes')" v-model="$c_components['country_codes'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Operating Systems -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('device_os')" v-bind="$_getLabelProps('device_os')">
              <cc-checkbox v-bind="$_getProps('device_os')" v-on="$_getEvents('device_os')" v-model="$c_components['device_os'].value" />
            </component-wrapper>
          </div>

          <!-- Browsers -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('browser_types')" v-bind="$_getLabelProps('browser_types')">
              <cc-checkbox v-bind="$_getProps('browser_types')" v-on="$_getEvents('browser_types')" v-model="$c_components['browser_types'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TRACKING & REPORTING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Tracking &amp; Reporting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Tracking Params -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('tracking_params')" v-bind="$_getLabelProps('tracking_params')">
              <cc-textarea v-bind="$_getProps('tracking_params')" v-on="$_getEvents('tracking_params')" v-model="$c_components['tracking_params'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- <component-wrapper legend="Basic Info"></component-wrapper> -->

    <!-- Name -->
    <!-- <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
      <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
    </component-wrapper> -->

    <!-- Content Type -->
    <!-- <component-wrapper v-if="$_isActive('content_type')" v-bind="$_getLabelProps('content_type')">
      <cc-radio-button v-bind="$_getProps('content_type')" v-on="$_getEvents('content_type')" v-model="$c_components['content_type'].value" />
    </component-wrapper> -->

    <!-- Scheduling -->
    <!-- <component-wrapper v-if="$_isActive('scheduling_wrapper')" v-bind="$_getLabelProps('scheduling_wrapper')">
      <div class="d-flex" v-if="$_isActive('scheduling_wrapper')">
        <cc-date-picker v-bind="$_getProps('campaign_start')" v-on="$_getEvents('campaign_start')" v-model="$c_components['campaign_start'].value" />
        <cc-date-picker v-bind="$_getProps('campaign_end')" v-on="$_getEvents('campaign_end')" v-model="$c_components['campaign_end'].value" class="ml-3" />
      </div>
    </component-wrapper> -->

    <!-- <component-wrapper legend="Budget"></component-wrapper> -->

    <!-- Network Targeting -->
    <!-- <component-wrapper v-if="$_isActive('tier_targeting')" v-bind="$_getLabelProps('tier_targeting')">
      <cc-radio-button v-bind="$_getProps('tier_targeting')" v-on="$_getEvents('tier_targeting')" v-model="$c_components['tier_targeting'].value" />
    </component-wrapper> -->

    <!-- CPC -->
    <!-- <component-wrapper v-if="$_isActive('cpc_model')" v-bind="$_getLabelProps('cpc_model')">
      <cc-checkbox v-bind="$_getProps('cpc_model')" v-on="$_getEvents('cpc_model')" v-model="$c_components['cpc_model'].value" />
      <cc-input v-if="$_isActive('cpc_desktop')" v-bind="$_getProps('cpc_desktop')" v-on="$_getEvents('cpc_desktop')" v-model="$c_components['cpc_desktop'].value" class="mt-3" />
      <cc-input v-if="$_isActive('cpc_tablet')" v-bind="$_getProps('cpc_tablet')" v-on="$_getEvents('cpc_tablet')" v-model="$c_components['cpc_tablet'].value" class="mt-3" />
      <cc-input v-if="$_isActive('cpc_mobile')" v-bind="$_getProps('cpc_mobile')" v-on="$_getEvents('cpc_mobile')" v-model="$c_components['cpc_mobile'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Maximum daily budget -->
    <!-- <component-wrapper v-if="$_isActive('cap_day')" v-bind="$_getLabelProps('cap_day')">
      <cc-input v-bind="$_getProps('cap_day')" v-on="$_getEvents('cap_day')" v-model="$c_components['cap_day'].value" />
    </component-wrapper> -->

    <!-- Maximum total budget -->
    <!-- <component-wrapper v-if="$_isActive('cap_total')" v-bind="$_getLabelProps('cap_total')">
      <cc-input v-bind="$_getProps('cap_total')" v-on="$_getEvents('cap_total')" v-model="$c_components['cap_total'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Targeting"></component-wrapper> -->

    <!-- Countries -->
    <!-- <component-wrapper v-if="$_isActive('countries_model')" v-bind="$_getLabelProps('countries_model')">
      <cc-radio-button v-bind="$_getProps('countries_model')" v-on="$_getEvents('countries_model')" v-model="$c_components['countries_model'].value" />
      <cc-multi-select v-if="$_isActive('country_codes')" v-bind="$_getProps('country_codes')" v-on="$_getEvents('country_codes')" v-model="$c_components['country_codes'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- States -->
    <!-- <component-wrapper v-if="$_isActive('states_model')" v-bind="$_getLabelProps('states_model')">
      <cc-radio-button v-bind="$_getProps('states_model')" v-on="$_getEvents('states_model')" v-model="$c_components['states_model'].value" />
      <cc-multi-select v-if="$_isActive('state_codes')" v-bind="$_getProps('state_codes')" v-on="$_getEvents('state_codes')" v-model="$c_components['state_codes'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Operating Systems -->
    <!-- <component-wrapper v-if="$_isActive('device_os')" v-bind="$_getLabelProps('device_os')">
      <cc-checkbox v-bind="$_getProps('device_os')" v-on="$_getEvents('device_os')" v-model="$c_components['device_os'].value" />
    </component-wrapper> -->

    <!-- Browsers -->
    <!-- <component-wrapper v-if="$_isActive('browser_types')" v-bind="$_getLabelProps('browser_types')">
      <cc-checkbox v-bind="$_getProps('browser_types')" v-on="$_getEvents('browser_types')" v-model="$c_components['browser_types'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Tracking & Reporting"></component-wrapper> -->

    <!-- Tracking & Reporting -->
    <!-- <component-wrapper v-if="$_isActive('tracking_params')" v-bind="$_getLabelProps('tracking_params')">
      <cc-textarea v-bind="$_getProps('tracking_params')" v-on="$_getEvents('tracking_params')" v-model="$c_components['tracking_params'].value" />
    </component-wrapper> -->
  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'ContentadForm',
  mixins: [formMixin],
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;
    }
  }
</style>
