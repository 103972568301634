<template>
  <div class="apply-rule-to-campaigns-wrapper">
    <b-form-group
      label="Apply Rule to Campaigns"
      :state="$_hasError('campaigns')"
      class="h-display-sm campaign-form optimizer-form-group"
    >
      <b-input-group>
        <campaign-select-light
          v-model="campaigns"
          name="campaigns"
          v-validate="'required'"
          data-vv-as="Campaigns"
          :traffic-source-filter="ts_types"
          :tracker-account-filter="$c_trackerAccountFilter"
          :custom-filter="$c_customFilter"
          :archived="false"
          managed
          :propellerBidType="$c_propellerBidType"
          @input="$_emit('campaigns')"
        />
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('campaigns') }}</small>
      <span slot="description" v-if="cmp_config.info" class="text-info" v-html="cmp_config.info"></span>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import CampaignSelectLight from '@sh/components/Utils/CampaignSelectLight/CampaignSelectLight';

export default {
  name: 'RuleCampaigns',
  components: { CampaignSelectLight },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    filter: { type: Object, default: () => ({ trackerId: null }) },
  },
  data() {
    return {
      campaigns: [],
      ts_types: [],
      cmp_config: this.ruleModel.rule.components.campaigns,
    };
  },
  computed: {
    $c_trackerAccountFilter() {
      return this.filter && this.filter.trackerId !== null ? [this.filter.trackerId] : null;
    },
    $c_customFilter() {
      return this.cmp_config.filter || null;
    },
    $c_propellerBidType() {
      return this.ruleModel.type === 'campaign_change_bid_cpc' ? 'cpc' : this.ruleModel.type === 'campaign_change_bid_cpm' ? 'cpm' : null;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        if (filter.trackerId) {
          // this.campaigns = this.campaigns.filter((item) => `${item.tracker_account_id}` === `${filter.trackerId}`);
          this.$_emit('campaigns');
        }
      },
    },
  },
  created() {
    this.campaigns = this.value;
    this.ts_types = this.ruleModel.rule.ts_types;
    this.$_emit('campaigns');
  },
};
</script>

<style lang="scss">
.apply-rule-to-campaigns-wrapper {
  .campaign-form {
    margin-bottom: 0;
  }

  .input-group-append {
    position: relative;

    .dropdown {
      .dropdown-toggle {
        display: flex;
        align-items: center;
        height: 4.2rem;

        i {
          margin-right: .5rem;
        }

        &::after {
          margin-left: .7rem;
        }
      }

      .dropdown-menu {
        top: unset !important;
        bottom: -6rem !important;
      }
    }
  }

  .dropdown-toggle {
    min-height: 4.2rem;
    border-top-left-radius: .6rem !important;
    border-bottom-left-radius: .6rem !important;

    .button-placehoder-tag {
      max-height: 21rem;
      overflow: auto;
    }
  }
}
</style>
