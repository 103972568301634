<template>
  <div class="telegram-settings-wrapper">
    <channel-ui
      :name="name"
      label="Telegram"
      :channel="$c_channel"
      :columns="['first_name', 'last_name', 'RE', 'AI', 'AN']"
      :update="$_update"
      :get-notification-field="config.notification.model.getTableHeaderField"
      @add="$_openAccountModal"
    />
    <telegram-modal ref="account-modal" :config="config" @add="$_addAccount" />
    <channel-action-bar v-if="$c_hasChanges" :loading="loadingUpdateSettings" @update="$_updateSettings" />
  </div>
</template>

<script>
import notificationsMixins from './notificationMixins';
import TelegramModal from '../modals/TelegramModal';

export default {
  name: 'TelegramSettings',
  components: { TelegramModal },
  mixins: [notificationsMixins],
  data() {
    return {
      name: 'telegram', // Channel identifier
    };
  },
};
</script>

<style lang="scss">
.slack-settings-wrapper {

}
</style>
