import icons from '@sh/components/Utils/icons.ts';
import moment from 'moment';
import { calculateTableStatus } from '../../../../platforms/native/src/components/SystemActivity/config/log';

const FORMAT = 'YYYY-MM-DD';

export default function () {
  const affectedItemName = (item) => {
    if (item.trafficSourceType && item.trafficSourceType !== '') {
      if (['widgets', 'Widget'].includes(item.level) && item.trafficSourceType === 'Facebook') {
        return 'placement';
      }
      if (['domains', 'Domain'].includes(item.level) && item.trafficSourceType === 'Facebook') {
        return 'device';
      }
      if (['exchanges', 'Exchange'].includes(item.level) && item.trafficSourceType === 'Facebook') {
        return 'geo';
      }
    }
    return item.level;
  };
  const generateFilterData = () => [
    { value: 'all', content: 'All Types', group: 'type' },
    { value: 'all', content: 'All Affected Items', group: 'affected' },
    { value: 'all', content: 'All Statuses', group: 'status' },
    { value: 'all', content: 'Show Info Logs', group: 'log' },
  ];
  return {
    config: {},
    preloader: false,
    apiLoading: true,
    dateRangePicker: {
      startDate: moment(new Date()).subtract(2, 'days').startOf('day').format(FORMAT),
      endDate: moment(new Date()).endOf('day').format(FORMAT),
    },
    rollbackActivities: [],
    tableModel: {
      selectedRows: [],
      displayColumns: [],
      columnsOrder: [],
    },
    resizedColumns: {},
    limit: 25,
    page: 1,
    pages: 1,
    search: '',
    sortField: 'activityTimestamp',
    sortType: 'desc',
    table: {
      fields: [
        {
          header: {
            content: 'Time',
            style: '',
            info: this.showUTCtime ? ' <div class="header-info">The date and time, in UTC time zone, when this action was executed.</div>' : '',
          },
          item: {
            key: 'activityTimestamp',
            content: (item) => `${moment.utc(item.activityTimestamp).format('YYYY-MM-DD, h:mm:ss a').toUpperCase()}
              ${this.showUTCtime ? '<span class="ml-1" style="color: grey;">(UTC)</span>' : ''}`,
            sortable: true,
            style: { textAlign: 'center', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Activity Type',
            info: `
            <div class="header-info">
              <span>Indicates the origin of this activity.</span><br>
              <span>It can be:</span>
              <ul>
                <li><strong>manual:</strong> manual adjustments made from the dashboard)</li>
                <li><strong>rule:</strong> adjustments made by an automatic rule</li>
                <li><strong>list:</strong> adjustments made by a Smart List</li>
              </ul>
              <span>Use the Filters to filter by a specific activity type</span>
            </div>
           `,
          },
          item: {
            key: 'type',
            content: (item) => item.type,
            sortable: true,
            style: { textAlign: 'center', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Affected Item',
            style: '',
            info: ' <div class="header-info"> The item type or campaign dimension that was affected by this action (Campaign, Ad Group, Content/Ad, Widget/Publisher, etc)</div>',
          },
          item: {
            key: 'level',
            content: (item) => affectedItemName(item),
            sortable: true,
            style: { textAlign: 'center', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Item Id',
            style: '',
            info: ' <div class="header-info">The id of the affected item, as reported by the Traffic Source</div>',
          },
          item: {
            key: 'itemId',
            slot: 'itemId',
            content: (item) => item.itemId,
            sortable: true,
            style: { textAlign: 'left', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Item Name',
            style: '',
            info: ' <div class="header-info">The name of the affected item, as reported by the Traffic Source</div>',
          },
          item: {
            key: 'itemName',
            slot: 'itemName',
            content: (item) => item.itemName,
            sortable: true,
            style: { textAlign: 'left', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Action',
            style: '',
            info: ' <div class="header-info">The type of adjustment that was attempted to be made on this item (pause, start, change bid, change budget, etc)</div>',
          },
          item: {
            key: 'action',
            slot: 'action',
            content: (item) => item.action,
            sortable: true,
            style: { textAlign: 'center', padding: '2px 5px !important' },
          },
        },
        {
          header: { content: 'Campaign Name', style: '', info: ' <div class="header-info">The name of the campaign to which this item belongs</div>' },
          item: {
            key: 'campaignName',
            slot: 'campaignName',
            content: (item) => item.campaignName,
            sortable: true,
            style: { textAlign: 'left', padding: '2px 5px !important' },
          },
        },
        {
          header: {
            content: 'Description',
            style: '',
            info: `
                <div class="header-info">
                  <span>A more detailed description on the final result of this action.</span><br>
                    <ul>
                    <li>If the action failed it will report the reason of the failure.</li>
                    <li>If the action succeeded it will report the source of the action (name of the rule or manual),as well as more details about what were the values of the rule conditions at the moment of the execution.</li>
                    </ul>
                  <span>Check the <span class='mx-1'>${icons.infoDescriptionTooltip}</span> icon for more details.</span>
               </div>`,
          },
          item: {
            key: 'description',
            slot: 'description',
            sortable: true,
            style: { textAlign: 'left', padding: '2px 5px !important' },
          },
        },
        {
          header: { content: 'Status', style: '', info: `
           <div class="header-info">
              <span>Indicates the status of the action. It can be one of:</span><br>
              <ul>
                <li><strong>Completed:</strong>: the action has been completed successfully.</li>
                <li><strong>Failed:</strong>: the action has failed. See the description column for more details</li>
                <li><strong>No changes:</strong>: Rule was executed but there were no necessary changes to be made. This usually means that the item is already in the desired status, has already the desired bid or budget adjustment, or there is a time condition which has not been met yet. See the "Description" column for some more details</li>
              </ul>
              <span>Use the Filters to filter by a specific activity type</span>
          </div>
        ` },
          item: {
            key: 'error',
            content: (item) => calculateTableStatus(item),
            sortable: true,
            style: { textAlign: 'center', padding: '2px 5px !important' },
          },
        },
        {
          header: { content: 'Change', style: '' },
          item: {
            key: 'itemAffectedFields',
            cellClass: 'bg-info',
            content: (item) => this.config.logConfig.itemChanged(item),
            sortable: true,
            style: { textAlign: 'center' },
          },
        },
      ],
      items: [],
    },
    dataFilter: {
      affectedItem: 'all',
      type: 'all',
      error: 'all',
      log: 'all',
    },
    allCampaigns: [],
    selectedCampaigns: [],
    filterGroups: [
      { value: 'type', content: 'Type', inputType: 'radio' },
      { value: 'affected', content: 'Affected Items', inputType: 'radio' },
      { value: 'status', content: 'Status', inputType: 'radio' },
      { value: 'log', content: 'Log', inputType: 'radio' },
    ],
    filterData: generateFilterData(),
    defaultFilterData: generateFilterData(),
    campaigns: [],
    rules: [],
    userRules: [],
    infoTitle:
    '<div class="header-info"><span>All dates and times in Logs are in UTC timezone, which might be different with your Ad Account\'s timezone.<span><br><span> In order to see activities using the same timezone as your Ad Account, use the Logs tab inside the campaign</span></div>',
    isRefreshLoading: false,
  };
}
