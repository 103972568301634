export default class BreakDownFilter {
  constructor(public availableOptions?: SelectOption<string>[], private selected: string | null = null) {}

  get options(): SelectOption<string>[] {
    return this.availableOptions ?? [];
  }

  get selectedOption(): SelectOption<string> | null {
    return this.options.find((option) => this.value === option.value) ?? null;
  }

  set selectedOption(option: SelectOption<string> | null) {
    this.selected = option?.value ?? null;
  }

  get value(): string | null {
    return this.selected;
  }

  set value(value: string | null) {
    this.selected = value;
  }
}
