import { AnonymousField, NameType } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { Budget, ExecutionOption, Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import * as Campaign from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { GeoTargeting } from '@/views/Automation/CampaignCreatorV2/types';

export enum BillingEvent {
  APP_INSTALLS = 'APP_INSTALLS',
  CLICKS = 'CLICKS',
  IMPRESSIONS = 'IMPRESSIONS',
  LINK_CLICKS = 'LINK_CLICKS',
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
}

export enum BidStrategy {
  COST_CAP = 'COST_CAP',
  LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP',
}

export const BidStrategyOptions = [
  {
    id: BidStrategy.COST_CAP,
    label: 'Cost per result goal',
    info: 'Best for getting the most volume.',
  },
  {
    id: BidStrategy.LOWEST_COST_WITH_BID_CAP,
    label: 'Bid cap',
    info: 'Best for controlling bids in the auction.',
  },
];

export enum OptimizationGoal {
  NONE = 'NONE',
  APP_INSTALLS = 'APP_INSTALLS',
  AD_RECALL_LIFT = 'AD_RECALL_LIFT',
  ENGAGED_USERS = 'ENGAGED_USERS',
  EVENT_RESPONSES = 'EVENT_RESPONSES',
  IMPRESSIONS = 'IMPRESSIONS',
  LEAD_GENERATION = 'LEAD_GENERATION',
  QUALITY_LEAD = 'QUALITY_LEAD',
  LINK_CLICKS = 'LINK_CLICKS',
  OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS',
  ONSITE_CONVERSIONS = 'ONSITE_CONVERSIONS',
  PAGE_LIKES = 'PAGE_LIKES',
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  QUALITY_CALL = 'QUALITY_CALL',
  REACH = 'REACH',
  LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
  VISIT_INSTAGRAM_PROFILE = 'VISIT_INSTAGRAM_PROFILE',
  VALUE = 'VALUE',
  THRUPLAY = 'THRUPLAY',
  DERIVED_EVENTS = 'DERIVED_EVENTS',
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS = 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS',
  CONVERSATIONS = 'CONVERSATIONS',
  IN_APP_VALUE = 'IN_APP_VALUE',
  MESSAGING_PURCHASE_CONVERSION = 'MESSAGING_PURCHASE_CONVERSION',
  SUBSCRIBERS = 'SUBSCRIBERS',
  REMINDERS_SET = 'REMINDERS_SET',
  MEANINGFUL_CALL_ATTEMPT = 'MEANINGFUL_CALL_ATTEMPT',
  PROFILE_VISIT = 'PROFILE_VISIT',
  MESSAGING_APPOINTMENT_CONVERSION = 'MESSAGING_APPOINTMENT_CONVERSION',
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
}

export const performanceGoalOptions = [
  {
    id: OptimizationGoal.OFFSITE_CONVERSIONS,
    group: 'Conversion Goals',
    label: 'Maximize number of conversions',
    info: "We'll try to show your ads to the people most likely to take a specific action on your website.",
  },
  {
    id: OptimizationGoal.VALUE,
    group: 'Conversion Goals',
    label: 'Maximize value of conversions',
    info: "We'll try to show your ads to the people most likely to make higher value purchases. If you're optimizing Campaign using Bid this it's not applicable",
    exclude: [
      {
        campaignObjective: Campaign.Objective.OUTCOME_SALES,
        bidTypes: [Campaign.BidStrategy.COST_CAP, Campaign.BidStrategy.LOWEST_COST_WITH_BID_CAP],
      },
    ],
  },
  {
    id: OptimizationGoal.LANDING_PAGE_VIEWS,
    group: 'Traffic Goals',
    label: 'Maximize number of landing page views',
    info: "We'll try to show your ads to the people most likely to view the website or Instant Experience linked in your ad.",
  },
  {
    id: OptimizationGoal.LINK_CLICKS,
    group: 'Traffic Goals',
    label: 'Maximize number of link clicks',
    info: 'Maximize the number of people who click on your ad and then successfully load the landing page',
  },
  {
    id: OptimizationGoal.REACH,
    group: 'Other Goals',
    label: 'Maximize daily unique reach',
    info: 'Maximize the number of unique people who see your ad each day',
  },
  {
    id: OptimizationGoal.CONVERSATIONS,
    group: 'Other Goals',
    label: 'Maximize number of conversations',
    info: 'Maximize the number of people who start a conversation with your business',
  },
  {
    id: OptimizationGoal.IMPRESSIONS,
    group: 'Other Goals',
    label: 'Maximize number of impressions',
    info: 'Maximize the number of times your ad is shown',
  },
];

export enum CustomEventType {
  AD_IMPRESSION = 'AD_IMPRESSION',
  RATE = 'RATE',
  TUTORIAL_COMPLETION = 'TUTORIAL_COMPLETION',
  CONTACT = 'CONTACT',
  CUSTOMIZE_PRODUCT = 'CUSTOMIZE_PRODUCT',
  DONATE = 'DONATE',
  FIND_LOCATION = 'FIND_LOCATION',
  SCHEDULE = 'SCHEDULE',
  START_TRIAL = 'START_TRIAL',
  SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',
  SUBSCRIBE = 'SUBSCRIBE',
  ADD_TO_CART = 'ADD_TO_CART',
  ADD_TO_WISHLIST = 'ADD_TO_WISHLIST',
  INITIATED_CHECKOUT = 'INITIATED_CHECKOUT',
  ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO',
  PURCHASE = 'PURCHASE',
  LEAD = 'LEAD',
  COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
  CONTENT_VIEW = 'CONTENT_VIEW',
  SEARCH = 'SEARCH',
  SERVICE_BOOKING_REQUEST = 'SERVICE_BOOKING_REQUEST',
  MESSAGING_CONVERSATION_STARTED_7D = 'MESSAGING_CONVERSATION_STARTED_7D',
  LEVEL_ACHIEVED = 'LEVEL_ACHIEVED',
  ACHIEVEMENT_UNLOCKED = 'ACHIEVEMENT_UNLOCKED',
  SPENT_CREDITS = 'SPENT_CREDITS',
  LISTING_INTERACTION = 'LISTING_INTERACTION',
  VIEW_CONTENT = 'VIEW_CONTENT',
  OTHER = 'OTHER',
}

// LEAD - Website :  Complete registration, Contact, Find location, Lead, Schedule, Search, Start trial, Submit application, Subscribe, View content
// Sales - Website : Add payment info, Add to cart, Add to wishlist, Complete registration, Donate, Initiate checkout, Purchase, Search, Start trial, Subscribe, View content
export const ExclusiveEventsOnly = {
  [Campaign.Objective.OUTCOME_LEADS]: [
    CustomEventType.COMPLETE_REGISTRATION,
    CustomEventType.CONTACT,
    CustomEventType.FIND_LOCATION,
    CustomEventType.LEAD,
    CustomEventType.SCHEDULE,
    CustomEventType.SEARCH,
    CustomEventType.START_TRIAL,
    CustomEventType.SUBMIT_APPLICATION,
    CustomEventType.SUBSCRIBE,
    CustomEventType.VIEW_CONTENT,
  ],
  [Campaign.Objective.OUTCOME_SALES]: [
    CustomEventType.ADD_PAYMENT_INFO,
    CustomEventType.ADD_TO_CART,
    CustomEventType.ADD_TO_WISHLIST,
    CustomEventType.COMPLETE_REGISTRATION,
    CustomEventType.DONATE,
    CustomEventType.INITIATED_CHECKOUT,
    CustomEventType.PURCHASE,
    CustomEventType.SEARCH,
    CustomEventType.START_TRIAL,
    CustomEventType.SUBSCRIBE,
    CustomEventType.VIEW_CONTENT,
  ],
};

export const CustomEventOptions = [
  { id: CustomEventType.ADD_PAYMENT_INFO, label: 'AddPaymentInfo' },
  {
    id: CustomEventType.ADD_TO_CART,
    label: 'AddToCart',
  },
  {
    id: CustomEventType.ADD_TO_WISHLIST,
    label: 'AddToWishlist',
  },
  {
    id: CustomEventType.COMPLETE_REGISTRATION,
    label: 'CompleteRegistration',
  },
  {
    id: CustomEventType.CONTACT,
    label: 'Contact',
  },
  {
    id: CustomEventType.CUSTOMIZE_PRODUCT,
    label: 'CustomizeProduct',
  },
  {
    id: CustomEventType.FIND_LOCATION,
    label: 'FindLocation',
  },
  {
    id: CustomEventType.INITIATED_CHECKOUT,
    label: 'InitiatedCheckout',
  },
  {
    id: CustomEventType.LEAD,
    label: 'Lead',
  },
  {
    id: CustomEventType.PURCHASE,
    label: 'Purchase',
  },
  {
    id: CustomEventType.SCHEDULE,
    label: 'Schedule',
  },
  {
    id: CustomEventType.SEARCH,
    label: 'Search',
  },
  {
    id: CustomEventType.SUBMIT_APPLICATION,
    label: 'SubmitApplication',
  },
  {
    id: CustomEventType.SUBSCRIBE,
    label: 'Subscribe',
  },
  {
    id: CustomEventType.VIEW_CONTENT,
    label: 'ViewContent',
  },
  {
    id: CustomEventType.START_TRIAL,
    label: 'StartTrial',
  },
];

export enum DestinationType {
  APP = 'APP',
  APPLINKS_AUTOMATIC = 'APPLINKS_AUTOMATIC',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM_DIRECT = 'INSTAGRAM_DIRECT',
  INSTAGRAM_PROFILE = 'INSTAGRAM_PROFILE',
  MESSAGING_INSTAGRAM_DIRECT_MESSENGER = 'MESSAGING_INSTAGRAM_DIRECT_MESSENGER',
  MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP = 'MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP',
  MESSAGING_INSTAGRAM_DIRECT_WHATSAPP = 'MESSAGING_INSTAGRAM_DIRECT_WHATSAPP',
  MESSAGING_MESSENGER_WHATSAPP = 'MESSAGING_MESSENGER_WHATSAPP',
  MESSENGER = 'MESSENGER',
  LEAD_FORM_MESSENGER = 'LEAD_FORM_MESSENGER',
  ON_AD = 'ON_AD',
  ON_POST = 'ON_POST',
  ON_VIDEO = 'ON_VIDEO',
  ON_PAGE = 'ON_PAGE',
  ON_EVENT = 'ON_EVENT',
  PHONE_CALL = 'PHONE_CALL',
  SHOP_AUTOMATIC = 'SHOP_AUTOMATIC',
  WEBSITE = 'WEBSITE',
  WHATSAPP = 'WHATSAPP',
  ON_REMINDER = 'ON_REMINDER',
  MESSAGE_PHONE_CALL = 'MESSAGE_PHONE_CALL',
  MESSAGE_THREAD = 'MESSAGE_THREAD',
  VIDEO_PLAYBACK = 'VIDEO_PLAYBACK',
  LEAD_FORM = 'LEAD_FORM',
  ON_CLICK = 'ON_CLICK',
  SHOP = 'SHOP',
  IN_APP_BROWSER = 'IN_APP_BROWSER',
  LEAD_FORM_AUTOMATIC = 'LEAD_FORM_AUTOMATIC',
  CONTACT_US = 'CONTACT_US',
}

export const DestinationTypeOptions = [
  { id: DestinationType.APP, label: 'App', info: 'Directs users to an app' },
  {
    id: DestinationType.APPLINKS_AUTOMATIC,
    label: 'App Links Automatic',
    info: 'Automatically directs users via App Links',
  },
  { id: DestinationType.FACEBOOK, label: 'Facebook', info: 'Directs users to a Facebook page' },
  { id: DestinationType.INSTAGRAM_DIRECT, label: 'Instagram Direct', info: 'Directs users to Instagram Direct' },
  { id: DestinationType.INSTAGRAM_PROFILE, label: 'Instagram Profile', info: 'Directs users to an Instagram profile' },
  {
    id: DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER,
    label: 'Instagram Direct Messenger',
    info: 'Directs users to Instagram Direct Messenger',
  },
  {
    id: DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP,
    label: 'Instagram Direct Messenger & WhatsApp',
    info: 'Directs users to both Instagram Direct Messenger and WhatsApp',
  },
  {
    id: DestinationType.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP,
    label: 'Instagram Direct & WhatsApp',
    info: 'Directs users to both Instagram Direct and WhatsApp',
  },
  {
    id: DestinationType.MESSAGING_MESSENGER_WHATSAPP,
    label: 'Messenger & WhatsApp',
    info: 'Directs users to both Messenger and WhatsApp',
  },
  { id: DestinationType.MESSENGER, label: 'Messenger', info: 'Directs users to Facebook Messenger' },
  {
    id: DestinationType.LEAD_FORM_MESSENGER,
    label: 'Lead Form Messenger',
    info: 'Directs users to a lead form on Messenger',
  },
  { id: DestinationType.ON_AD, label: 'On Ad', info: 'Actions taken directly on the ad' },
  { id: DestinationType.ON_POST, label: 'On Post', info: 'Actions taken directly on the post' },
  { id: DestinationType.ON_VIDEO, label: 'On Video', info: 'Actions taken directly on the video' },
  { id: DestinationType.ON_PAGE, label: 'On Page', info: 'Actions taken directly on the page' },
  { id: DestinationType.ON_EVENT, label: 'On Event', info: 'Actions taken directly on the event' },
  { id: DestinationType.PHONE_CALL, label: 'Phone Call', info: 'Directs users to make a phone call' },
  { id: DestinationType.SHOP_AUTOMATIC, label: 'Shop Automatic', info: 'Automatically directs users to a shop' },
  { id: DestinationType.WEBSITE, label: 'Website', info: 'Directs users to a website' },
  { id: DestinationType.WHATSAPP, label: 'WhatsApp', info: 'Directs users to WhatsApp' },
  { id: DestinationType.ON_REMINDER, label: 'On Reminder', info: 'Actions taken based on reminders' },
  { id: DestinationType.MESSAGE_THREAD, label: 'Message Thread', info: 'Directs users to a message thread' },
  {
    id: DestinationType.MESSAGE_PHONE_CALL,
    label: 'Message Phone Call',
    info: 'Directs users to a phone call within messaging',
  },
  { id: DestinationType.VIDEO_PLAYBACK, label: 'Video Playback', info: 'Directs users to video playback' },
  { id: DestinationType.LEAD_FORM, label: 'Lead Form', info: 'Directs users to a lead form' },
  { id: DestinationType.ON_CLICK, label: 'On Click', info: 'Actions taken on click' },
  { id: DestinationType.SHOP, label: 'Shop', info: 'Directs users to a shop' },
  { id: DestinationType.IN_APP_BROWSER, label: 'In App Browser', info: 'Directs users to an in-app browser' },
  { id: DestinationType.CONTACT_US, label: 'Contact Us', info: 'Directs users to a contact us form' },
  {
    id: DestinationType.LEAD_FORM_AUTOMATIC,
    label: 'Lead Form Automatic',
    info: 'Automatically directs users to a lead form',
  },
];

export enum CustomDestinationType {
  MESSAGING_APPS = 'MESSAGING_APPS',
  ON_ENGAGEMENT_AD = 'ON_ENGAGEMENT_AD',
  WEBSITE_AND_APP = 'WEBSITE_AND_APP',
}

export const ConversionGoalsMetadata = [
  {
    id: DestinationType.WEBSITE,
    text: 'Website',
    info: 'Drive sales and conversions on your website.',
    value: DestinationType.WEBSITE,
    disabled: false,
  },
  {
    id: DestinationType.APP,
    value: DestinationType.APP,
    disabled: true,
    text: 'App',
    info: 'Drive sales and conversions on your app',
    requestFeature: true,
  },
  {
    id: CustomDestinationType.WEBSITE_AND_APP,
    value: CustomDestinationType.WEBSITE_AND_APP,
    text: 'Website and app',
    info: 'Drive sales and conversions through Messenger, Instagram and WhatsApp.',
    disabled: true,
    requestFeature: true,
  },
  {
    id: DestinationType.PHONE_CALL,
    value: DestinationType.PHONE_CALL,
    text: 'Calls',
    info: 'Drive sales and conversions through phone calls',
    disabled: true,
    requestFeature: true,
  },
];

export const MessagingApps = [
  DestinationType.MESSENGER,
  DestinationType.INSTAGRAM_DIRECT,
  DestinationType.WHATSAPP,
  // value if combinations are selected
  DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER, // instagram + messenger
  DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP, // instagram + messenger + whatsapp
  DestinationType.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP, // instagram + whatsapp
  DestinationType.MESSAGING_MESSENGER_WHATSAPP, // messenger + whatsapp
];
export const EngagementType = [
  DestinationType.ON_EVENT,
  DestinationType.ON_POST,
  DestinationType.ON_VIDEO,
  DestinationType.ON_REMINDER,
];

export const supportMapCampaignObjectiveToConversionLocation = {
  [Campaign.Objective.OUTCOME_AWARENESS]: [],
  [Campaign.Objective.OUTCOME_TRAFFIC]: [
    DestinationType.WEBSITE,
    DestinationType.APP,
    CustomDestinationType.MESSAGING_APPS, // ui only, value is determined after the user selects the messaging apps
    DestinationType.INSTAGRAM_PROFILE,
    DestinationType.PHONE_CALL,
  ],
  [Campaign.Objective.OUTCOME_ENGAGEMENT]: [
    CustomDestinationType.MESSAGING_APPS,
    CustomDestinationType.ON_ENGAGEMENT_AD, // ui only, value is determined after the user selects the Engagement Type
    DestinationType.PHONE_CALL,
    DestinationType.WEBSITE,
    DestinationType.APP,
    DestinationType.FACEBOOK,
  ],
  [Campaign.Objective.OUTCOME_LEADS]: [
    DestinationType.WEBSITE,
    DestinationType.ON_AD, // instant forms
    DestinationType.MESSENGER,
    DestinationType.LEAD_FORM_MESSENGER, // instant forms and messenger
    DestinationType.INSTAGRAM_DIRECT,
    DestinationType.PHONE_CALL,
    DestinationType.APP,
  ],
  [Campaign.Objective.OUTCOME_APP_PROMOTION]: [],
  [Campaign.Objective.OUTCOME_SALES]: [
    DestinationType.WEBSITE,
    DestinationType.APP,
    CustomDestinationType.WEBSITE_AND_APP, // website and app ?? todo se gjej dot
    CustomDestinationType.MESSAGING_APPS,
    DestinationType.PHONE_CALL,
  ],
};

export const supportMapObjectiveConversionLocationPerformanceGoal = {
  [Campaign.Objective.OUTCOME_TRAFFIC]: {
    [DestinationType.WEBSITE]: [
      OptimizationGoal.LANDING_PAGE_VIEWS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.CONVERSATIONS,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.APP]: [OptimizationGoal.LINK_CLICKS, OptimizationGoal.REACH],
    [DestinationType.INSTAGRAM_PROFILE]: [OptimizationGoal.VISIT_INSTAGRAM_PROFILE],
    [DestinationType.PHONE_CALL]: [OptimizationGoal.QUALITY_CALL],
    [DestinationType.MESSENGER]: [
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.CONVERSATIONS,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.INSTAGRAM_DIRECT]: [
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.CONVERSATIONS,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.WHATSAPP]: [
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.CONVERSATIONS,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER]: [OptimizationGoal.CONVERSATIONS],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
  },
  [Campaign.Objective.OUTCOME_LEADS]: {
    [DestinationType.WEBSITE]: [
      OptimizationGoal.OFFSITE_CONVERSIONS,
      // OptimizationGoal.ONSITE_CONVERSIONS,
      OptimizationGoal.LANDING_PAGE_VIEWS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.IMPRESSIONS,
      OptimizationGoal.REACH,
    ],
    [DestinationType.ON_AD]: [OptimizationGoal.LEAD_GENERATION, OptimizationGoal.QUALITY_LEAD],
    [DestinationType.MESSENGER]: [OptimizationGoal.LEAD_GENERATION],
    [DestinationType.LEAD_FORM_MESSENGER]: [OptimizationGoal.LEAD_GENERATION],
    [DestinationType.INSTAGRAM_DIRECT]: [OptimizationGoal.LEAD_GENERATION],
    [DestinationType.PHONE_CALL]: [OptimizationGoal.QUALITY_CALL],
    [DestinationType.APP]: [
      OptimizationGoal.APP_INSTALLS_AND_OFFSITE_CONVERSIONS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
    ],
  },
  [Campaign.Objective.OUTCOME_SALES]: {
    [DestinationType.WEBSITE]: [
      OptimizationGoal.OFFSITE_CONVERSIONS,
      OptimizationGoal.ONSITE_CONVERSIONS,
      OptimizationGoal.VALUE,
      OptimizationGoal.LANDING_PAGE_VIEWS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.APP]: [
      OptimizationGoal.APP_INSTALLS_AND_OFFSITE_CONVERSIONS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
    ],
    [DestinationType.MESSENGER]: [
      OptimizationGoal.CONVERSATIONS,
      OptimizationGoal.OFFSITE_CONVERSIONS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
      OptimizationGoal.IMPRESSIONS,
    ],
    [DestinationType.INSTAGRAM_DIRECT]: [OptimizationGoal.CONVERSATIONS],
    [DestinationType.WHATSAPP]: [
      OptimizationGoal.OFFSITE_CONVERSIONS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.IMPRESSIONS,
      OptimizationGoal.REACH,
    ],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER]: [OptimizationGoal.CONVERSATIONS],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.PHONE_CALL]: [OptimizationGoal.QUALITY_CALL],
  },
  [Campaign.Objective.OUTCOME_ENGAGEMENT]: {
    [DestinationType.MESSENGER]: [OptimizationGoal.LINK_CLICKS, OptimizationGoal.CONVERSATIONS],
    [DestinationType.INSTAGRAM_DIRECT]: [OptimizationGoal.LINK_CLICKS, OptimizationGoal.CONVERSATIONS],
    [DestinationType.WHATSAPP]: [OptimizationGoal.LINK_CLICKS, OptimizationGoal.CONVERSATIONS],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER]: [OptimizationGoal.CONVERSATIONS],
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP]: [OptimizationGoal.CONVERSATIONS], // todo i assumed
    [DestinationType.ON_VIDEO]: [OptimizationGoal.THRUPLAY, OptimizationGoal.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS],
    [DestinationType.ON_POST]: [OptimizationGoal.POST_ENGAGEMENT, OptimizationGoal.IMPRESSIONS, OptimizationGoal.REACH],
    [DestinationType.ON_EVENT]: [
      OptimizationGoal.EVENT_RESPONSES,
      OptimizationGoal.IMPRESSIONS,
      OptimizationGoal.POST_ENGAGEMENT,
      OptimizationGoal.REACH,
    ],
    [DestinationType.ON_PAGE]: [OptimizationGoal.PAGE_LIKES],
    [DestinationType.ON_REMINDER]: [OptimizationGoal.REMINDERS_SET],
    [DestinationType.PHONE_CALL]: [OptimizationGoal.QUALITY_CALL],
    [DestinationType.WEBSITE]: [
      OptimizationGoal.OFFSITE_CONVERSIONS,
      OptimizationGoal.ONSITE_CONVERSIONS,
      OptimizationGoal.LANDING_PAGE_VIEWS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.IMPRESSIONS,
      OptimizationGoal.REACH,
    ],
    [DestinationType.APP]: [
      OptimizationGoal.APP_INSTALLS_AND_OFFSITE_CONVERSIONS,
      OptimizationGoal.LINK_CLICKS,
      OptimizationGoal.REACH,
    ],
    [DestinationType.FACEBOOK]: [OptimizationGoal.PAGE_LIKES],
  },
};

export enum PublisherPlatforms {
  facebook = 'facebook',
  instagram = 'instagram',
  messenger = 'messenger',
  audience_network = 'audience_network',
}

export enum DevicePlatforms {
  mobile = 'mobile',
  desktop = 'desktop',
}

export enum FacebookPositions {
  feed = 'feed',
  profile_feed = 'profile_feed',
  right_hand_column = 'right_hand_column',
  marketplace = 'marketplace',
  video_feeds = 'video_feeds',
  story = 'story',
  search = 'search',
  instream_video = 'instream_video',
  facebook_reels = 'facebook_reels',
  facebook_reels_overlay = 'facebook_reels_overlay',
}

export enum InstagramPositions {
  stream = 'stream',
  story = 'story',
  explore = 'explore',
  explore_home = 'explore_home',
  reels = 'reels',
  profile_feed = 'profile_feed',
  ig_search = 'ig_search',
  reels_overlay = 'reels_overlay',
}

export enum AudienceNetworkPlacements {
  classic = 'classic',
  rewarded_video = 'rewarded_video',
}

export enum MessengerPositions {
  messenger_home = 'messenger_home',
  story = 'story',
  sponsored_messages = 'sponsored_messages',
}

export enum AttributionSpecEvent {
  VIEW_THROUGH = 'VIEW_THROUGH',
  CLICK_THROUGH = 'CLICK_THROUGH',
}
export interface Create extends Omit<CreateApi, 'name' | 'attribution_spec'> {
  attribution_spec: {
    [AttributionSpecEvent.CLICK_THROUGH]: number;
    [AttributionSpecEvent.VIEW_THROUGH]: number;
  };
  name: NameType;
}

export interface CreateApi {
  adlabels?: Array<{ name: string }>;
  bid_amount: number;
  bid_strategy: Campaign.BidStrategy;
  billing_event: BillingEvent;
  budget_schedule_specs?: Array<Budget>;
  destination_type: DestinationType;
  campaign_id: AnonymousField | string;
  daily_budget: number;
  daily_imps: number; // Daily impressions. Available only for campaigns with buying_type=FIXED_CPM
  lifetime_imps: number; // Lifetime impressions. Available only for campaigns with buying_type=FIXED_CPM
  daily_min_spend_target: number;
  daily_spend_cap: number;
  lifetime_min_spend_target: number;
  lifetime_spend_cap: number;
  end_time: Date;
  attribution_spec: Array<{ event_type: AttributionSpecEvent; window_days: number }> | undefined;
  lifetime_budget: number;
  name: string;
  optimization_goal: OptimizationGoal;
  promoted_object: {
    application_id?: string;
    object_store_url?: string;
    page_id?: string;
    pixel_id?: string;
    custom_conversion_id?: string;
    custom_event_str?: string;
    custom_event_type?: CustomEventType;
  };
  execution_options?: Array<ExecutionOption>;
  start_time: Date;
  source_adset_id?: string;
  is_dynamic_creative?: boolean;
  status: Status;
  targeting: TargetingObject;
}

export interface TargetingObjectAbVariables {
  age_max: number;
  age_min: number;
  genders: Array<number>;
  geo_locations: {
    countries: Array<GeoTargeting>;
    cities: Array<GeoTargeting>;
    // location_types?: Array<string>;
    regions: Array<GeoTargeting>;
    zips: Array<GeoTargeting>;
    country_group: Array<GeoTargeting>;
  };
  excluded_geo_locations: {
    countries: Array<GeoTargeting>;
    cities: Array<GeoTargeting>;
    // location_types?: Array<string>;
    regions: Array<GeoTargeting>;
    zips: Array<GeoTargeting>;
    country_group: Array<GeoTargeting>;
  };
  targeting_automation: {
    advantage_audience: number;
  };
  locales: Array<string>;
  publisher_platforms: Array<PublisherPlatforms>;
  facebook_positions: Array<FacebookPositions>;
  instagram_positions: Array<InstagramPositions>;
  device_platforms: Array<DevicePlatforms>;
  messenger_positions: Array<MessengerPositions>;
  audience_network_positions: Array<AudienceNetworkPlacements>;
  id?: string;
  campaign_id?: string;
  effective_publisher_platforms?: Array<PublisherPlatforms>;
  effective_instagram_positions?: Array<InstagramPositions>;
  effective_audience_network_positions?: Array<AudienceNetworkPlacements>;
  effective_messenger_positions?: Array<MessengerPositions>;
  effective_facebook_positions?: Array<FacebookPositions>;
  effective_device_platforms?: Array<DevicePlatforms>;
}

export interface TargetingObject {
  age_max: number;
  age_min: number;
  genders: Array<number>;
  geo_locations: {
    countries?: Array<string>;
    cities?: Array<GeoTargeting>;
    // location_types?: Array<string>;
    regions?: Array<GeoTargeting>;
    zips?: Array<GeoTargeting>;
    country_group?: Array<string>;
  };
  excluded_geo_locations: {
    countries?: Array<string>;
    cities?: Array<GeoTargeting>;
    // location_types?: Array<string>;
    regions?: Array<GeoTargeting>;
    zips?: Array<GeoTargeting>;
    country_group?: Array<string>;
  };
  targeting_automation: {
    advantage_audience: number;
  };
  publisher_platforms: Array<PublisherPlatforms>;
  facebook_positions: Array<FacebookPositions>;
  instagram_positions: Array<InstagramPositions>;
  device_platforms: Array<DevicePlatforms>;
  messenger_positions: Array<MessengerPositions>;
  audience_network_positions: Array<AudienceNetworkPlacements>;
  id?: string;
  campaign_id?: string;
  locales?: Array<string>;
  effective_publisher_platforms?: Array<PublisherPlatforms>;
  effective_instagram_positions?: Array<InstagramPositions>;
  effective_audience_network_positions?: Array<AudienceNetworkPlacements>;
  effective_messenger_positions?: Array<MessengerPositions>;
  effective_facebook_positions?: Array<FacebookPositions>;
  effective_device_platforms?: Array<DevicePlatforms>;
}
