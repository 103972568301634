<template>
  <b-modal
    modal-class="optimizer-modal rules-by-group-modal"
    @show="$_load"
    centered
    size="xl"
    ref="GroupsManageModal"
    no-close-on-backdrop
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h2 class="modal-header__title">Manage Groups</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>
    <div class="rules-by-group-container">
      <LoadingSkeleton
        v-if="preloader"
        :rows="2"
        type="table"
        :table="{ headItems: 3, bodyItems: 5 }"
        shape="square"
      />
      <div v-else class="rules-by-group-wrapper optimizer-table">
        <vue-opti-table-light
          class="rules-by-group-table"
          name="rulesByGroupTableList"
          :hover="true"
          :enable-export="false"
          :show-search="false"
          :show-pagination="false"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :default-rows="1000"
        >
          <template #actions="{ i }">
            <action-btn type="delete" class="mx-auto" :click="() => $_removeGroup(i)" />
          </template>

          <template #group="{ item }">
            <live-edit
              v-model="item.group"
              field-name="Group"
            />
            <!-- {{ item.group }} -->
          </template>

          <template #rules="{ item }">
            <vue-opti-select-light
              class="optimizer-select"
              :options="rules"
              unique-key="_id"
              label-key="name"
              option-type="checkbox"
              searchable
              :search-fields="['name']"
              v-model="item.rules"
              button-placeholder="Select Rules"
              button-type="tag"
              button-block
            >
            </vue-opti-select-light>
          </template>
        </vue-opti-table-light>
      </div>
    </div>
    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="$_addGroup" :disabled="loading"><i class="fa fa-plus"></i> Add group</b-btn>
      <b-btn class="primary-button" @click="$_save" :disabled="loading">
        <template v-if="loading"><i class="fa fa-spinner fa-spin" /> Saving...</template>
        <template v-else><i class="fa fa-check" /> Save</template>
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import LiveEdit from '@sh/components/Utils/LiveEdit';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'GroupsManageModal',
  components: { LoadingSkeleton, LiveEdit },
  mixins: [notifications],
  props: {
    config: { type: [Object, null], default: () => null },
  },
  data() {
    return {
      preloader: true,
      loading: false,
      table: {
        fields: [
          {
            header: { content: 'Actions' },
            item: {
              key: 'actions',
              slot: 'actions',
              content: () => '-',
              style: { textAlign: 'center', width: '5rem' },
            },
          },
          {
            header: { content: 'Group' },
            item: {
              key: 'group',
              slot: 'group',
              content: (item) => item.group,
              sortable: true,
              style: { textAlign: 'center', width: '20rem' },
            },
          },
          {
            header: { content: 'Rules' },
            item: {
              key: 'rules',
              slot: 'rules',
              content: (item) => item.rules,
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      rules: [],
    };
  },
  methods: {
    show() {
      this.$refs.GroupsManageModal.show();
    },
    hide() {
      this.$refs.GroupsManageModal.hide();
    },
    async $_save() {
      this.loading = true;
      // Unify payload data
      const payloadMap = {};
      this.table.items.forEach(({ group, rules }) => {
        if (!payloadMap[group]) payloadMap[group] = {};
        rules.forEach((rule) => {
          if (!payloadMap[group][rule._id]) payloadMap[group][rule._id] = rule;
        });
      });
      // Create Payload
      const payload = {};
      Object.entries(payloadMap).forEach(([group, rules]) => {
        payload[group] = Object.values(rules);
        if (!payload[group].length) delete payload[group];
      });
      // Update Groups
      try {
        await this.$api.rules.updateRulesByGroup(payload);
        this.$n_successNotification({ title: 'Groups successfully updated' });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      this.$emit('update');
      this.hide();
      this.loading = false;
    },
    $_addGroup() {
      this.table.items.push({ group: 'New Group', rules: [] });
    },
    $_removeGroup(index) {
      this.table.items.splice(index, 1);
    },
    async $_load() {
      // TODO Load & Reload, clear form ...
      this.preloader = true;
      this.table.items = [];
      try {
        const [allRules, ruleByGroups] = await Promise.all([this.$apiStore.rules.index(), this.$api.rules.getRulesByGroup()]);
        this.rules = allRules.map(({ _id, name }) => ({ _id, name }));
        Object.entries(ruleByGroups).forEach(([group, rules]) => {
          this.table.items.push({ group, rules });
        });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      this.preloader = false;
    },
  },
};
</script>

<style lang="scss">
.rules-by-group-modal {
  .modal-body {
    // overflow-y: auto;
    // max-height: 600px;
    .rules-by-group-container {
      padding: 15px 0 25px 0;

      .optimizer-skeleton {
        &-table {
          box-shadow: none;
          padding: 2rem 0 0 0 !important;
        }
      }

      .rules-by-group-table {
        .table-holder {
          box-shadow: none;
          overflow: visible !important;

          table {
            th {
              border-top: 1px solid #e1e6ef;
            }

            td {
              overflow: visible;

              // .live-edit {
              //   .form-control {
              //     width: auto;
              //   }
              // }
            }
          }
        }
      }
    }
  }
}


</style>
