import { render, staticRenderFns } from "./TutorialDropdown.ts.vue?vue&type=template&id=139a130c"
import script from "./TutorialDropdown.ts.vue?vue&type=script&setup=true&lang=ts"
export * from "./TutorialDropdown.ts.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TutorialDropdown.ts.vue?vue&type=style&index=0&id=139a130c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports