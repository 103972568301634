import { useTrafficSourcesStore } from '@/stores/traffic-sources';
import { ReportType } from '@/views/Tools/Reporting/types';
import { Account, TrafficSource } from '@sh/types';
import { uniq } from 'lodash';

export default class AdAccountFilter {
  constructor(
    public availableOptions?: Account[],
    public trafficSource?: TrafficSource,
    public reportType?: ReportType,
    private selected: number[] = [],
    private isSelectApplied: boolean = false
  ) {}

  get options(): Account[] {
    return (
      this.availableOptions?.filter(
        (account) =>
          account.type.uniqueName === this.trafficSource ||
          (!this.trafficSource &&
            this.reportType &&
            [ReportType.Campaign, ReportType.Content].includes(this.reportType))
      ) ?? []
    );
  }

  get selectedOptions(): Account[] {
    const options = this.options.filter((option) => this.value.includes(option.id));

    if (options.length !== this.options.length) {
      return options;
    }
    if (this.isSelectApplied && options.length === this.options.length) {
      return this.options.filter((option) => this.selected.includes(option.id));
    }
    return [];
  }

  set selectedOptions(options: Account[]) {
    this.selected = options.map((option) => option.id);
  }

  get value(): number[] {
    if (this.selected?.length) {
      return this.selected;
    }

    if (this.options?.length) {
      return this.options.map(({ id }) => id);
    }

    return [];
  }

  set value(value: number[]) {
    this.selected = value;
  }

  get disabledText(): string {
    if (this.options?.length === 0) {
      if (this.trafficSource) {
        return 'There are no accounts for selected traffic source.';
      }
      return 'Please start by choosing the traffic source type.';
    }
    return '';
  }

  getSelectedText(totalSelected: number): number | 'All' {
    if (!totalSelected || totalSelected === this.options?.length) {
      return 'All';
    }
    return totalSelected;
  }

  get selectState(): { indeterminate: boolean; allAccountsSelected: boolean } {
    return {
      indeterminate: (this.selected.length && this.selected.length !== this.options.length) || false,
      allAccountsSelected: this.isSelectApplied && this.selectedOptions.length === this.options.length,
    };
  }

  set selectState(value: { indeterminate: boolean; allAccountsSelected: boolean }) {
    if (value.allAccountsSelected) {
      this.selectedOptions = this.options;
    } else {
      this.selectedOptions = [];
    }
    this.isSelectApplied = value.allAccountsSelected;
  }

  handleSelectAllAccount(checked: boolean) {
    this.isSelectApplied = checked;
    this.selectedOptions = checked ? this.options : [];
  }

  setAccountValuesSelection(selected: Account, checked: boolean) {
    this.isSelectApplied = false;

    if (checked) {
      const selectedIds = this.options?.filter((option) => option.id === selected.id);
      this.selectedOptions = uniq([...this.selectedOptions, ...selectedIds]);
    } else {
      const selectedIds = this.selected.filter((option) => option !== selected?.id);
      this.selectedOptions = this.options?.filter((option) => selectedIds.includes(option.id));
    }

    if (this.selected.length === this.options?.length) this.isSelectApplied = true;
  }

  selectedAccountsOptions(options: string[]) {
    const store = useTrafficSourcesStore();
    const filterOptions = { trafficSource: [], linkedTrackers: [], status: '', currency: [] };

    const selectedAccounts = store.filterTrafficSourceAccounts({
      ...filterOptions,
      tags: options.length ? options : [''],
    });
    const uniqueTrafficSourceNames = [...new Set(selectedAccounts.map((item) => item.id))];
    const filteredData = this.options?.filter((item) => uniqueTrafficSourceNames.includes(item.id));

    if (filteredData.length === this.options?.length) {
      this.handleSelectAllAccount(true);
    }

    this.selectedOptions = filteredData;
  }
}
