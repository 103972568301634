<template>
  <vue-opti-select-light
    class="optimizer-select filter-select col-md-auto pl-md-0 pr-md-2 mb-2 mb-md-0 mr-2"
    :options="filterOptions"
    :value="value"
    unique-key="value"
    label-key="content"
    option-type="checkbox"
    button-type="static"
    searchable
    lazyRender
    allowClear
    @clear="$_onClear()"
    @change="(e)=>$emit('filterActivities',{options: e, keyToFilter: filteredKey})"
  >
    <template #BUTTON_PLACEHOLDER="{ options }">
      <span class="button-placehoder-filter">
        <i :class="`fa fa-${iconToShow}`"></i>
        <span class="ml-8">{{ label }}</span>
        <b-badge v-show="options.length" pill variant="info">{{ options.length }}</b-badge>
      </span>
    </template>
    <template #ITEM="{ option }">
      <img v-if="filteredKey === 'campaigns'" class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.tsName)" alt="traffic-source-logo" />
      <div class="acc-item-name" v-b-tooltip.hover.top.html="{
        customClass: 'custom-tooltip',
        title: isTruncated(option.content),
      }"
      >
        {{ option.content }}
      </div>
    </template>
  </vue-opti-select-light>
</template>

<script>
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'ActivityFilters',
  props: {
    filterOptions: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    value: Array,
    filteredKey: { type: String, required: true },
    iconToShow: { type: String, required: true },
  },
  methods: {
    getTrafficSourceLogo,
    $_onClear() {
      this.$emit('clear');
    },
    isTruncated(text) {
      const maxLength = 45;
      if (text) {
        return text.length > maxLength ? text : '';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.select-wrapper {
  .optimizer-select {
    min-width: 6rem;
    padding: 0 !important;
    margin-right: 0.8rem;
    .dropdown {
      height: 100%;

      button {
        height: 100%;
      }
    }
    .btn.dropdown-toggle {
      color: $text-secondary !important;

      &::after {
        margin-left: -2rem;
      }

      .button-placehoder-filter {
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        width: -moz-available;
        width: -webkit-fill-available;

        .badge {
          top: -1.7rem;
          right: -5rem;
          background: $color-light-blue;
          padding: 0.4rem 0.7rem 0.3rem;
        }
      }

      .option-text {
        color: $text-secondary !important;
      }

      .clear-btn-wrapper {
        position: relative;
        top: unset !important;
        opacity: 1;
        min-width: 1.7rem;
        right: 2.25rem;
        background-color: $color-gray;

        &:hover {
          background-color: $color-light-gray;
        }

        i {
          margin-left: 0;
        }
      }
    }
    &.filter-select .option-content {
      &::after {
        color: $blue-300 !important;
        max-height: 1.8rem;
      }
    }
  }
}
</style>
