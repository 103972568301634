
import NoData from '@sh/components/MediaManager/NoData/NoData.ts.vue';
import { NoRowsOverlayComponentParams } from '@sh/components/Utils/Table/types';
import { defineComponent } from 'vue';

interface ComponentData {
  params?: NoRowsOverlayComponentParams;
  message?: string;
  errorMessage: string;
  classList: string[];
}

export default defineComponent({
  components: {
    NoData,
  },
  data(): ComponentData {
    return {
      params: undefined,
      message: '',
      errorMessage: '',
      classList: [],
    };
  },
  beforeMount() {
    // this.params contains the cell and row information and is made available to this component at creation time
    // see ICellRendererParams for more details
    if (this.params) {
      this.updateData(this.params);
    }
  },
  methods: {
    updateData(params: NoRowsOverlayComponentParams) {
      this.message = params.noRowsOverlayMessage;
      this.errorMessage = params.noRowsOverlayErrorMessage;
      this.classList = params.noRowsOverlayComponentClassList;
    },
  },
});
