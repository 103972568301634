import { backendApi } from './api';
import { affectedOptions, typeOptions, errorOptions, logOptions } from './filter';
import { getTemplate, itemChanged, itemChangedRollback } from './log';
import RuleConfig from '@/views/Automation/Rules/config';

export default {
  async getConfig() {
    const ruleConfig = await RuleConfig.getConfig();
    return {
      filterConfig: { affectedOptions, typeOptions, errorOptions, logOptions },
      logConfig: { getTemplate, itemChanged, itemChangedRollback, ruleConditionsTranslator: ruleConfig.conditionsConfig.conditionsTranslator },
      api: backendApi,
    };
  },
};
