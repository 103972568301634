
import CcIcon from '@/views/Automation/CampaignCreatorV2/components/CcIcon/CcIcon.ts.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CcIcon,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: String,
    value: Boolean,
    text: String,
    info: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCheckboxClick(value: boolean) {
      this.$emit('update', value);
    },
  },
});
