
import Vue, { VueConstructor } from 'vue';

interface ComponentRefs {
  $refs: {
    input?: {
      $el: HTMLInputElement;
    };
  };
}

type ComponentTypes = VueConstructor<Vue & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  props: {
    value: String,
    beforeBlur: { type: Function, default: () => () => true },
  },
  data() {
    return {
      state: 'READY',
      localValue: this.value,
    };
  },
  methods: {
    onKeyArrayPress(direction: string) {
      this.onSave();
      this.$emit(direction);
    },
    async onInputFocus() {
      if (this.state === 'READY') {
        this.state = 'ACTIVE';
        await this.$nextTick();
        this.$refs.input?.$el.focus();
      }
    },
    async onSave() {
      if (this.state === 'ACTIVE') {
        const value = this.localValue.trim();

        if (!(value === '' || this.localValue === this.value)) {
          this.state = 'BUSY';
          const result = !!(await this.beforeBlur(this.localValue));

          if (result) {
            this.$emit('input', value);
          } else {
            this.localValue = this.value;
          }
        } else {
          this.localValue = this.value;
        }
        this.state = 'READY';
      }
    },
    onDiscard() {
      if (this.state === 'ACTIVE') {
        this.localValue = this.value;
        this.state = 'READY';
      }
    },
  },
});
