<template>
  <div :class="`cc-rule-select-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('ruleSelect')" class="mb-0">
      <b-input-group class="d-block">
        <div class="row">
          <div class="col-md-6">
            <div class="campaign-select-container">
              <v-select
                name="ruleSelect"
                :data-vv-as="model.label"
                v-validate="model['v-validate']"
                data-vv-validate-on="input|search:blur"
                :class="[{ 'is-invalid': $_hasError('ruleSelect') === false }, 'w-100']"
                ref="ruleSelect"
                v-model="selected"
                label="name"
                max-height="400px !important"
                placeholder="Select rules"
                class="multi-select-wrapper form-control multiselect"
                :close-on-select="false"
                :clear-search-on-select="false"
                multiple
                :disabled="!!model.disabled"
                :options="$options.ruleOptions"
                @input="$_emit()"
                :filterable="true"
                @search:blur="$refs.ruleSelect.$data.search = ''"
              >
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <rules-select-group :options="$options.ruleOptions" @change-diff="$_changeRulesGroup" :disabled="!!model.disabled" />
          </div>
        </div>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('ruleSelect') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import RulesSelectGroup from '@sh/components/Utils/RulesSelectGroup';
import component from '../mixins/component';

export default {
  name: 'CcRuleSelect',
  components: { RulesSelectGroup },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: [],
    };
  },
  created() {
    // Static Options
    this.$options.ruleOptions = this.model.options;
    this.$options.ruleOptionsMap = {};
    this.model.options.forEach((rule) => {
      this.$options.ruleOptionsMap[rule._id] = rule;
    });
    // Bind Model
    this.$watch('value', (value) => {
      this.selected = [];
      value.forEach((id) => {
        if (this.$options.ruleOptionsMap[id]) this.selected.push(this.$options.ruleOptionsMap[id]);
      });
    }, { deep: true, immediate: true });
  },
  methods: {
    $_emit() {
      this.$emit('input', this.selected.map((item) => item._id));
    },
    $_changeRulesGroup(diff) {
      const model = Object.keys(diff.remove).length ? this.selected.filter(({ _id }) => !diff.remove[_id]) : this.selected;
      if (Object.keys(diff.add).length) {
        const modelMap = {};
        model.forEach(({ _id }) => { modelMap[_id] = _id; });
        this.$options.ruleOptions.forEach((rule) => {
          if (diff.add[rule._id] && !modelMap[rule._id]) {
            modelMap[rule._id] = rule._id;
            model.push(rule);
          }
        });
      }
      this.selected = model;
      this.$_emit();
    },
  },
};
</script>

<style lang="scss">
.cc-rule-select-wrapper {
  .multi-select-wrapper {
    z-index: unset !important;
    .vs__dropdown-menu {
      z-index: 9999;
      .vs__dropdown-option.vs__dropdown-option--selected {
        opacity: 0.5;
      }
    }
    .vs__dropdown-toggle {
      input {
        width: auto;
        &:focus, &:active {
          border: 1px solid #ddd !important;
        }
      }
    }
  }
}
</style>
