<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table spots-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getSpotsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getSpotsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getSpotsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getSpotsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="spots-table"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true" select-label="Spots"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasComparisonColumns="true"
            :hasGroups="true"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showHeaderPopover="true"
            :updateComparisonColumns="updateComparisonColumns"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <vue-opti-select-light
                :class="[ tableModel.selectedRows.length ? 'active-select' : '', 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                :options="bulkActionOptions"
                :unique-key="({ value: { value } }) => value"
                label-key="content"
                button-type="static"
                @change="({ value }) => { $_bulkAction(value) }"
                single
              >
                <template #BUTTON_PLACEHOLDER>
                  <span class="button-placehoder-static">
                    <i class="fa fa-ellipsis-h"></i>
                    <span>Actions</span>
                  </span>
                </template>
              </vue-opti-select-light>
              <div class="col-md-auto mb-2 mb-md-0 pl-0">
                <b-btn
                  class="secondary-button h-100"
                  block
                  v-clipboard="$_copyToClipboard('traffic_source_spot_id')"
                  @success="$_clipboardSuccessHandler('spot')"
                  @error="$_clipboardErrorHandler"
                >
                  <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected spots id's to clipboard." />
                </b-btn>
              </div>
              <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                <i class="fa fa-spinner fa-spin" /> Processing...
              </div>
              <!-- <div v-show="ready.pagination" class="col-md-auto" style="line-height: 2.5em">
                <i class="fa fa-spinner fa-spin" /> Loading...
              </div> -->
              <loadizer :loading="ready.pagination" />
            </template>
            <template #actions="{ item }">
              <switch-button
                :loading="item.statusProcessing"
                :checked="item.status === 'RUNNING'"
                :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                :update="(value) => $_updateStatus(item, value)"
              />
            </template>
            <template slot="cpc" slot-scope="props">
              <span v-if="props.item.cpc === null">-</span>
              <template v-else-if="campaign.additional_fields.traffic_selection === 'Prime'">
                <live-edit
                  :ref="`bidLiveEdit-${props.i}`"
                  :key="`bidLiveEdit-${props.item.id}`"
                  v-model="props.item.cpc"
                  :live-state="props.item.bidLiveEditState"
                  prefix="$"
                  :format="$_cpcSlotNumFormat(props.field.options)"
                  field-name="Bid"
                  :max-limit-warning="maxCpcLimit"
                  :before-blur="value => $_updateBid(props.item, value)"
                  @up="$_inlineEditArrayPress('bidLiveEdit', props.i, 'up')"
                  @down="$_inlineEditArrayPress('bidLiveEdit', props.i, 'down')"
                />
              </template>
              <template v-else>
                {{ ui.currencyFormat(props.item.cpc) }}
              </template>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import tableFields from '@/helpers/fields/index';
import helperMixins from './mixins/helperMixins';
import tableMixins from '@sh/mixins/table';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import { usePresetStore } from '@/stores/presets';
import { mapActions, mapState } from 'pinia';


export default {
  name: 'Spots',
  mixins: [helperMixins, tableMixins, columnsMixin, presetHelper],
  data() {
    return {
      bulkActionOptions: [
        { value: { value: 'pause', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Deactivate' },
        { value: { value: 'start', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Activate' },
      ],
    };
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_tableName() {
      return `${this.account.type.name}SpotsTable`;
    },
    $c_exportLabel() {
      return `${this.account.type.name}_spots_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('spots', this.campaign.traffic_source_unique_name);
      /** ************************************************************************* */

      try {
        await this.getFilteredPresets();
        await this.$_getSpotsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.preloader = false;
      this.debouncedGetActivityLogs = this.debounce(this.$_getSpotsPaginated, 1000);
    },
    async $_getSpotsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        typeName: this.account.type.uniqueName,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.search !== '') {
        filter.search = this.search;
      }
      let spots = [];
      try {
        spots = await this.$api.spots.performance(this.campaign.id, filter);
        spots.items = spots.items.map((item) => {
          item.statusProcessing = false;
          item.traffic_source_currency = this.campaign.traffic_source_currency;
          item.bidLiveEditState = { state: 'READY' };
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      if (spots.pagination.lastPage) {
        this.pages = spots.pagination.lastPage;
        this.defaultRows = spots.pagination.pageSize;
      }
      if (!spots.items.length) {
        this.page = 1;
      }
      if (Object.keys(spots).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('SPOTS', { name: 'actions' }));
        spots.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('SPOTS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        this.table.items = spots.items;
        spots.totals.totalElements = spots.pagination.total;
        this.table.totals = spots.totals;
      }
      this.preloader = false;
      this.ready.pagination = false;
      return spots;
    },
    async $_bulkUpdateStatus(value) {
      const localValue = value === 'start' ? 'RUNNING' : 'PAUSED';
      const payload = [];
      const payloadIdsMap = {};
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          payloadIdsMap[row.traffic_source_spot_id] = true;
          const payloadItem = { traffic_source_spot_id: row.traffic_source_spot_id };
          payload.push(payloadItem);
          row.statusProcessing = true;
        }
      });
      if (payload.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.spots.changeStatus(this.campaign.id, value, payload),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_spot_id) > -1) row.status = localValue;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payloadIdsMap[row.traffic_source_spot_id]) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_updateStatus(item, value) {
      try {
        const payload = { traffic_source_spot_id: item.traffic_source_spot_id };
        const result = await this.$_userActionHandler({
          request: this.$api.spots.changeStatus(item.campaign_id, value ? 'start' : 'pause', [payload]),
        });
        if (result.success.length > 0) item.status = value ? 'RUNNING' : 'PAUSED';
      } catch (error) { /** * Do nothing ** */ }
    },
    async $_bulkUpdateBid(value) {
      const rowsId = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_section_id);
          row.bidLiveEditState.state = 'BUSY';
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.spots.changeBid(this.campaign.id, value, rowsId),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_spot_id) > -1) row.cpc = parseFloat(value);
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_spot_id) > -1) row.bidLiveEditState.state = 'READY';
        });
        task.finish();
      }
    },
    async $_updateBid(item, value) {
      const task = this.userAction.task.create();
      try {
        const payload = { traffic_source_spot_id: item.traffic_source_spot_id };
        await this.$api.spots.changeBid(this.campaign.id, value, [payload]);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'SPOT', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getSpotsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('spots', this.campaign.traffic_source_unique_name, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      return this.$_exportCsv({ level: 'spots', campaignId: this.campaign.id, filter });
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'SPOT');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getSpotsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getSpotsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getSpotsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
  },
};
</script>

