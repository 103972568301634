import routes from '@/router';
import { canAccess, unleashFeatures } from '@sh/helpers';
import VueRouter from 'vue-router';
import session from '@sh/services/session';

const router = new VueRouter({
  // mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
const hasPermission = [];

router.beforeEach(async (to, from, next) => {
  if (to && to.name === 'Login') {
    hasPermission.splice(0, hasPermission.length);
  }

  if (session.isSearchFeed && to.name !== 'Reporting') {
    return next({ name: 'Reporting' });
  }

  if (!session.hasIntegration && ['Rules', 'GotoCampaignCreator', 'Campaigns', 'TrafficSources', 'Trackers', 'Dashboard'].includes(to.name)) {
    return next({ name: 'AccountWizard', query: { noIntegrations: true } });
  }

  if (unleashFeatures.length) {
    unleashFeatures.forEach(async (feature) => {
      if (to.fullPath.includes(feature)) {
        if (hasPermission.includes(feature) || await canAccess(feature)) {
          hasPermission.push(feature);
        } else {
          return next({ name: 'Dashboard' });
        }
      }
      return next();
    });
  } else {
    return next();
  }
});

export default router;
