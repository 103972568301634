<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table rules-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            name="rulesTable"
            class="rules-wrapper"
            :hover="true"
            :enable-export="false"
            :show-search="true"
            :selectable="true"
            select-label="Rules"
            :header-fields="table.fields"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: '_id', order: 'desc' }"
            :default-rows="100"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <div class="col-md-auto pr-0 mr-2">
                <new-rule-dropdown
                  class="mb-2 mb-md-0"
                  :campaign="campaign.id"
                  :ts-type="campaign.traffic_source_unique_name"
                  :config="config"
                />
              </div>
              <b-btn class="secondary-button" v-b-modal.addRuleModal>
                <i class="fa fa-thumb-tack" /> Add Existing Rule To This Campaign
              </b-btn>
            </template>
            <template slot="name" slot-scope="props">
              <b-link :to="{name: 'Edit Rule', params: {id: props.item._id}}">
                {{ props.item.name }}
              </b-link>
            </template>
            <template slot="status" slot-scope="props">
              <span
                class="status-item status-item-enabled"
                v-if="props.item.status"
              >
                <span class="circle" /> Running
              </span>
              <span
                v-else
                class="status-item status-item-disabled"
              >
                <span class="circle" /> Paused
              </span>
            </template>

            <template slot="conditions" slot-scope="props">
              {{ props.item.conditions }}
            </template>

            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <action-btn :disabled="$_isGlobalRule(item)" :class="$_isGlobalRule(item) ? 'not-allowed': ''" type="unlink" title="Unlink" :click="() => $_unlinkAction(item)" />
              </div>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>

    <AddRuleModal ref="addRuleModal" :item="table.items" :campaign="[campaign]" @addRule="addRule" />
  </div>
</template>

<script>
import NewRuleDropdown from '@sh/views/Rules/other/NewRuleDropdown';
import rulesConfig from '@/views/Automation/Rules/config';
import helperMixins from '@/views/Campaign/Tabs/mixins/helperMixins';
import AddRuleModal from '../components/AddRuleModal.ts.vue';

export default {
  name: 'Rules',
  components: { NewRuleDropdown, AddRuleModal },
  mixins: [helperMixins],
  data() {
    return {
      config: null,
      rulesModal: {
        model: [],
        loading: false,
        otherRules: [],
      },
      resizedColumns: {},
      table: {
        fields: [
          {
            header: { content: 'Rule ID', style: '' },
            display: false,
            item: {
              key: '_id',
              content: (item) => item._id,
              style: { textAlign: 'center' },
              searchable: true,
            },
          },
          {
            header: { content: 'Actions', style: '' },
            item: {
              key: 'actions',
              slot: 'actions',
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Name', style: '' },
            item: {
              key: 'name',
              slot: 'name',
              content: (item) => item.name,
              sortable: true,
              searchable: true,
              cellClass: 'cell-name',
              total: {
                parse: () => 1,
                content: (totals) => `Total: ${totals.name}`,
                style: { background: '#fffdf5', fontWeight: 'bold', textAlign: 'center' },
              },
            },
          },
          {
            header: { content: 'Status', style: '' },
            item: {
              key: 'status',
              slot: 'status',
              content: (item) => item.status,
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Type', style: '' },
            item: {
              key: 'type',
              content: (item) => item.type,
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Conditions', style: '' },
            item: {
              cellClass: 'cell-conditions',
              key: 'conditions',
              content: (item) => this.config.conditionsConfig.conditionsTranslator(item.query, item.hour_of_day_new, false, item),
            },
          },
          {
            header: { content: 'Groups', style: '' },
            item: {
              key: 'groups',
              content: (item) => item.group.join(', '),
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Interval', style: '' },
            item: {
              key: 'interval',
              content: (item) => this.config.intervalsConfig.intervalsMap[item.stats_interval] || '',
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Frequency', style: '' },
            item: {
              key: 'frequency',
              content: (item) => this.config.rotationConfig.rotationMap[item.rotation] || '',
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
    };
  },
  computed: {
    $c_ruleMap() {
      const options = {};
      for (const key in this.config.ruleConfig.ruleTypes) {
        const item = this.config.ruleConfig.ruleTypes[key];
        if (item.ts_types.indexOf(this.campaign.traffic_source_unique_name) > -1) {
          options[`${item.level}:${item.rule_action}`] = key;
        }
      }
      return options;
    },
  },
  methods: {
    addRule(rulesModal) {
      this.table.items = [...this.table.items, ...rulesModal.model];
    },
    async $_init() {
      this.preloader = true;
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('rules', this.campaign.traffic_source_unique_name);
      /** ************************************************************************* */

      try {
        const [configuration, result] = await Promise.all([rulesConfig.getConfig(), this.$api.rules.getByCampaign(this.campaign.id)]);
        this.config = configuration;
        this.table.items = result.map((item) => {
          const key = `${item.level.toLowerCase()}_${item.rule_action.toLowerCase()}`;
          const rule = this.config.ruleConfig.ruleTypes[key] || null;
          item.type = rule ? rule.content : '';
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.preloader = false;
    },
    $_isGlobalRule(item) {
      return (item.account_ids?.length > 0 && item.campaign_ids?.length === 0);
    },
    async $_unlinkAction(item) {
      if (item.campaign_ids.length === 1) {
        try {
          const swal = await this.$swal({
            title: `<small>You are removing the last campaign from rule <b>"${item.name}"</b>! The rule will be disabled.</small>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          });
          if (swal.value === true) return this.$_unlink(item);
        } catch (error) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      } else {
        return this.$_unlink(item);
      }
    },
    async $_unlink(item) {
      try {
        const campaign_ids = [this.campaign.id];
        const result = await this.$api.rules.editCampaign({
          action: 'remove',
          campaign_ids,
          rules: [item._id],
        });
        this.table.items = this.table.items.filter((row) => row._id !== item._id);
        this.$n_successNotification({ title: result.message || 'Successfully unlinked rule' });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
    },
    async $_modalShown() {
      this.rulesModal.otherRules = [];
      this.rulesModal.loading = true;
      this.rulesModal.model = [];
      try {
        const response = await this.$api.rules.index();
        const attachedRulesIds = this.table.items.map((row) => row._id);
        this.rulesModal.otherRules = response.filter((item) => this.$c_ruleMap[`${item.level}:${item.rule_action}`]
          && attachedRulesIds.indexOf(item._id) === -1
          && !(item.rule_action === 'change_budget'
          && this.campaign.traffic_source_unique_name === 'Taboola'
          && typeof this.campaign.additional_fields.daily_budget === 'undefined'));
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.rulesModal.loading = false;
    },
    async $_addRules() {
      const campaign_ids = [this.campaign.id];
      this.rulesModal.loading = true;
      const ruleIds = this.rulesModal.model.map((row) => row._id);
      try {
        const result = await this.$api.rules.editCampaign({
          action: 'add',
          campaign_ids,
          rules: ruleIds,
        });
        this.rulesModal.model.map((row) => {
          row.campaign_ids.push(this.campaign.id);
          return row;
        });
        this.table.items = [...this.table.items, ...this.rulesModal.model];
        this.$n_successNotification({ title: result.message || 'Successfully rules attached' });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.rulesModal.model = [];
      this.rulesModal.otherRules = [];
      this.rulesModal.loading = false;
      this.$refs.addRuleModal.hide();
    },
    $_changeRulesGroup(diff) {
      const model = Object.keys(diff.remove).length ? this.rulesModal.model.filter((rule) => !diff.remove[rule._id]) : this.rulesModal.model;
      if (Object.keys(diff.add).length) {
        const modelMap = {};
        model.forEach((item) => { modelMap[item._id] = item; });
        this.rulesModal.otherRules.forEach((rule) => {
          if (diff.add[rule._id] && !modelMap[rule._id]) {
            modelMap[rule._id] = rule;
            model.push(rule);
          }
        });
      }
      this.rulesModal.model = model;
    },
    $_clear() {
      this.rulesModal.model = [];
      this.$refs.groupSelect.clear();
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('rules', this.campaign.traffic_source_unique_name, payload);
    },
  },
};
</script>

<style lang="scss">
#addRuleModal {
  .rule-v-select {
    .vs__dropdown-toggle {
      padding-top: .2rem;
    }
  }

  .modal-body {
    min-height: 17rem;

    .rules-select-wrapper {
      z-index: 99999999999 !important;
    }
  }
}
</style>
