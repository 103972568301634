<template>
  <div class="mgid-categories-dropdown-wrapper">
    <input ref="MLDInput" type="hidden" />
  </div>
</template>

<script>
import $ from 'jquery';
import MultiLevelDropdown from './MultiLevelDropdown';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'MgidCategoriesDropdown',
  mixins: [notifications],
  props: {
    placeholder: { type: String, default: '-- Select Category --' },
    searchPlaceholder: { type: String, default: 'Search' },
    type: { type: String, required: false },
    value: null,
  },
  data() {
    return {
      teasers: {},
      dropdownEl: null,
      localValue: '',
    };
  },
  watch: {
    type(value) {
      if (value) {
        const teasers = this.teasers[value];
        document.getElementById('category_select').remove();
        this.dropdownEl = new MultiLevelDropdown('category', teasers, this.$refs.MLDInput, { notDefined: this.placeholder, searchPlaceholder: this.searchPlaceholder });
      }
    },
    value: {
      handler(value) {
        if (value !== this.localValue) this.dropdownEl.setValue(value);
      },
    },
    localValue: {
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    $(this.$refs.MLDInput).change((event) => {
      this.localValue = event.target.value;
    });
    await this.$_load();
  },
  methods: {
    async $_load() {
      try {
        this.teasers = await this.$api.uploader.mgidTeasers();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.dropdownEl = new MultiLevelDropdown('category', this.teasers[this.type || 'product'], this.$refs.MLDInput, { notDefined: this.placeholder, searchPlaceholder: this.searchPlaceholder });
      if (this.value !== null) this.dropdownEl.setValue(this.value);
    },
  },
};
</script>

<style lang="scss">
.mgid-categories-dropdown-wrapper {
  width: 100%;
  .selector-container {
    clear: both;
    margin-bottom: 7px;
    margin-top: 3px;
    position: relative;
  }

  .selector-container input[id$="_search-input"] {
    margin: 0px;
    width: calc(100% - 5px);
    padding: 5px;
    height: 25px;
  }

  .selector-container .dropdown-container {
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
  }

  .selector-container .main-drop-down {
    display: none;
    max-height: 250px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    padding: 5px 0 0 5px;
    border: 1px solid #cccccc;
    z-index: 999999999;
    position: absolute;
    background-color: white;
    box-sizing: border-box;
    color: #555;
    outline: 0;
  }

  .selector-container .selector-drop-down-button {
    position: relative;
    width: 100%;
    display: block;
    outline: 0;
    text-transform: uppercase;
    font-weight: normal;
    box-sizing: border-box;
    border: 1px solid #c2cad8;
    padding: 5px 10px;
    color: #555;
    font-size: 12px;
    cursor: pointer;
  }

  .selector-container .selector-drop-down-button:before{
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    margin-top: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 3px 3px;
    border-color: transparent transparent #3e3e3e transparent;
  }

  .selector-container .selector-drop-down-button:after{
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    margin-top: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 3px 0 3px;
    border-color: #3e3e3e transparent transparent transparent;
  }


  .selector-container .name .drop-down-search-input {
    width: 403px!important;
    padding: 4px 5px!important;
    margin: 0 !important;
    margin-bottom: 5px!important;
  }
  .selector-container .cat_row {
    display: block;
    border-bottom: 1px solid #cccccc;
    margin: 0px;
    padding: 3px;
    cursor: pointer;
    font-size: 12px;
  }

  .selector-container .cat_row:hover {
    background-color: #d1d1d1;
  }

  .selector-container .parent-row {
    font-weight: bold;
  }

  .selector-container .parent-open {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #aaa;
    margin-right: 5px;
  }

  .selector-container .parent-close {
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    -moz-border-top-colors: none;
    -moz-border-right-colors: none;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    border-image-source: none;
    border-image-slice: 100% 100% 100% 100%;
    border-image-width: 1 1 1 1;
    border-image-outset: 0 0 0 0;
    border-image-repeat: stretch stretch;
    border-left-color: #aaa;
    height: 0;
    width: 0;
    display: inline-block;
  }

  .selector-container .cat_level_1 {
    padding-left: 2px;
  }

  .selector-container .cat_level_2 {
    padding-left: 22px;
    display: none;
  }

  .selector-container .cat_level_3 {
    padding-left: 42px;
    display: none;
  }

  .selector-container .cat_level_4 {
    padding-left: 62px;
    display: none;
  }

  .selector-container .selected-cat {
    background-color: #aaa;
  }

  .selector-container .parent-cat {
    float: right;
    font-size: 9px;
    color: #cccccc;
    text-overflow: ellipsis;
    max-width: 155px;
    overflow: hidden;
    white-space: nowrap;
  }

  .selector-container .cat_row:hover .parent-cat {
    color: white;
  }
}
</style>
