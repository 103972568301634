import { ABBase } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { ABDependency, ABTestType, ABValue, Macro } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { FieldConfiguration } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';

// Single variable (Single value)
// Two variables of same typeId (key) or interchanged typeId are joined together using GroupABJoiner.
export class AbVariable<
  Value = string | number | boolean | Record<string, any> | Array<any> | Date,
  OutputValue = Value,
  AbValues extends Array<ABValue<Value> & { variableId: string }> = Array<ABValue<Value> & { variableId: string }>
> extends ABBase<AbValues> {
  public get dependencies(): ABDependency<any>[] {
    return this.abValues.map((v) => ({
      ...v,
      abGroups: [],
      abId: v.variableId,
      model: 'Campaign',
    }));
  }

  public get outputValue(): OutputValue {
    return this.abValues[0].value as unknown as OutputValue;
  }

  public get name(): string {
    return this.fieldMetadata.key!;
  }

  public get variableUsage(): Record<string, number> {
    return {
      [this.abValues[0].field]: 1,
    };
  }

  public get macros(): Array<Macro> {
    const variableNameMacro: Macro = {
      name: `#${this.abValues[0].field.toUpperCase()}_VARIABLE_NAME#`,
      value: {
        abId: this.abValues[0].variableId,
        variableUniquePath: this.abValues[0].variableUniquePath!,
        value: this.abValues[0].name || this.abValues[0].field,
        description: 'Variable name',
      },
      nestedMacros: [],
    };
    const valueMacro: Macro = {
      name: `#${this.abValues[0].field.toUpperCase()}_VARIABLE_VALUE#`,
      value: {
        variableUniquePath: this.abValues[0].variableUniquePath!,
        abId: this.abValues[0].variableId,
        value: (this.abValues[0].value as unknown as string)?.toString() || '',
        description: 'Variable value',
      },
      nestedMacros: [],
    };
    const allMacros = [variableNameMacro, valueMacro];
    return allMacros;
  }

  public get description(): string {
    return this.fieldMetadata.fieldGroup!;
  }

  public get id(): string {
    return this.abValues?.[0]?.variableUniquePath!;
  }

  public get possibleOptions(): Array<ABDependency<AbValues>[]> {
    return this.abValues.map((value) => [
      {
        ...value,
        key: value.field,
        model: 'Campaign',
        abId: value.variableId,
        abGroups: [],
      } as unknown as ABDependency<AbValues>,
    ]);
  }

  constructor(protected abValues: AbValues, protected fieldMetadata: FieldConfiguration) {
    super();
  }

  public get type(): ABTestType {
    return 'VARIABLE';
  }
}
