<template>
  <div class="trackers-wizard-step account-wizard-step">
    <div class="account-wizard-step__content">
      <search v-model="searchAccountsMap" :items="accounts" :map-key="item => item.id" :map-value="item => `${item.type.uniqueName}:${item.name}`" />
      <b-alert ref="alert" :show="alert" variant="danger" dismissible @dismissed="alert=false">{{ alert_message }}</b-alert>
      <div class="account-wizard-step__content__cards">
        <div class="account-wizard-card account-new item" @click="showModal">
          <div class="account-wizard-card__image">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="30" cy="30" r="29" stroke="#7ABDA5" stroke-width="2" />
              <rect x="28.75" y="20" width="2.5" height="20" fill="#7ABDA5" />
              <rect x="40" y="28.75" width="2.5" height="20" transform="rotate(90 40 28.75)" fill="#7ABDA5" />
            </svg>
          </div>
          <div class="caption">
            <h4>Add new</h4>
          </div>
        </div>
        <div v-for="account in $_accounts" :key="account.id" :class="['account-wizard-card', 'item', 'type-'+account.type.uniqueName, {selected: accountSelected.id === account.id}]" @click="select(account.id)">
          <div class="account-wizard-card__name">
            <h5>{{ account.name }}</h5>
          </div>
          <div class="account-wizard-card__image">
            <img :src="account.type.logo">
          </div>
          <div class="account-wizard-card__type">
            <span>{{ account.type.uniqueName }}</span>
          </div>
          <span class="account-wizard-card__selected-icon">
            <i class="fa fa-check"></i>
          </span>
        </div>
        <!-- <div class="account-new item" @click="showModal">
          <img src="/sh_static/utils/AdNewPlus.svg">
          <div class="caption">
            <h4><b class="font-blue-steel">Add new</b></h4>
          </div>
        </div> -->
      </div>
    </div>
    <TrackerModal ref="refTrackerModal" @submit="saveAccount" />
  </div>
</template>

<script>
/* eslint-disable */
import TrackerModal from '@sh/components/Trackers/TrackerModal';
import configFile from './config';
import Search from './components/Search';

export default {
  name: 'AccountWizardTrackers',
  components: {
    TrackerModal,
    Search,
  },
  data() {
    return {
      config: null,
      alert: false,
      alert_message: '',
      accounts: [],
      accountsMap: {},
      accountSelected: {},
      types: [],
      typesMap: {},
      searchAccountsMap: null,
    };
  },
  computed: {
    $_accounts() {
      if (this.searchAccountsMap) {
        return this.accounts.filter((item) => typeof this.searchAccountsMap[item.id] !== 'undefined');
      }
      return this.accounts;
    },
  },
  async created() {
    this.config = await configFile.getConfig();
  },
  methods: {
    async load(forceAccounts = false, forceTypes = false) {
      if (this.types.length === 0 || forceTypes) {
        let types = [];
        types = await this.config.api.accountWizard.loadTrackerTypes();
        types = JSON.parse(JSON.stringify(types));
        types.forEach((item, index) => {
          // Set Types obj
          item.logo = this.config.model.images[item.uniqueName.toLowerCase()];
          this.typesMap[item.id] = item;
        });
        // Set Types
        this.types = types;
      }

      if (this.accounts.length === 0 || forceAccounts) {
        let accounts = [];
        accounts = await this.config.api.accountWizard.loadTrackerAccounts();
        accounts.forEach((item, index) => {
          item.type.logo = this.typesMap[item.type.id].logo;
          this.accountsMap[item.id] = item;
        });
        // Set Accounts
        this.accounts = accounts;
      }
    },
    select(id) {
      if (this.accountSelected.id === id) {
        this.accountSelected = {};
        return;
      }
      this.accounts.forEach((item, index) => {
        if (item.id === id) {
          this.accountSelected = item;
          this.$emit('on-select', this.accountSelected);
        }
      });
    },
    showModal() {
      this.$refs.refTrackerModal.showModal();
    },
    $_showAlert(msg) {
      this.alert_message = msg;
      this.alert = true;
    },
    $_hideAlert() {
      this.alert_message = '';
      this.alert = false;
    },
    isCompleted() {
      const isValid = Object.keys(this.accountSelected).length > 0;
      if (isValid) {
        this.$_hideAlert();
        return true;
      }
      this.$_showAlert('Please select one tracker.');
      return false;
    },
    getAccount() {
      return this.accountSelected;
    },
    getSelectedId() {
      return this.accountSelected.id;
    },
    saveAccount(account) {
      if (account !== false) {
        account.type.logo = this.typesMap[account.type.id].logo;
        this.accounts.push(account);
        this.accountsMap[account.id] = account;
        this.accountSelected = account;
        this.$n_showNewAccountMsg();
      }
    },
  },
  notifications: {
    $n_showNewAccountMsg: {
      title: 'New Account',
      message: 'Tracker Created Successfully',
      type: 'success',
      timeout: 5000,
    },
  },
};
</script>

<style lang="scss">
  .trackers-wizard-step {
    .account-wizard-card {
      &.type-GoogleAnalytics,
      &.type-GoogleAnalytics4,
      &.type-Kintura,
      &.type-FunnelFlux { 
        img { height: 5.5rem !important; width: auto; }
      }
    }
  }
</style>
