/* eslint-disable import/no-cycle */
import { EntityForCreation } from '@/views/Automation/CampaignCreatorV2//ab/core/GroupABEntity';
import { ABDependency, Macro } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import * as CampaignMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { ErrorData } from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import { ErrorType, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { AdSetDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdSetDTO';
import { CampaignValidation } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import {
  CampaignCreatorTask,
  TaskCategory,
  TaskType,
} from '@/views/Automation/CampaignCreatorV2/validation/CampaignCreatorTask';
import { FieldConfiguration, MacroType } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import moment from 'moment';

export type FacebookDTOMetrics = {
  meta: Record<
    string,
    {
      name: string;
      key: string;
      entity: string;
      suffix?: string;
    }
  >;
  metrics: Record<string, number | string[]>;
};

export type AbVariableErrorDTO = ErrorData<any, any, any> & {
  variableId?: string;
  itemEntity?: SupportedEntities;
  entityBuildId: string;
  abGroup?: string;
};

export class CampaignDTO extends CampaignCreatorTask<CampaignMeta.CreateApi> {
  ruleIds: string[] | undefined;
  public getTsAccountId(): number {
    const acc = this.findVariable('account_id')?.value;
    return acc!;
  }

  public itemMetrics(): FacebookDTOMetrics {
    return {
      meta: {
        campaignNumber: {
          name: 'Number of Campaigns',
          key: 'campaignNumber',
          entity: 'Campaign',
          suffix: 'campaigns will be created',
        },
        campaignDailyBudget: {
          name: 'Campaign Daily Budget',
          key: 'campaignDailyBudget',
          entity: 'Campaign',

          suffix: '$ Allocated daily budget',
        },
        campaignLifetimeBudget: {
          name: 'Campaign Lifetime Budget',
          key: 'campaignLifetimeBudget',
          entity: 'Campaign',

          suffix: '$ Allocated lifetime budget',
        },
      },
      metrics: {
        campaignNumber: 1,
        campaignDailyBudget: this.getPayload().daily_budget / 100 || 0,
        campaignLifetimeBudget: this.getPayload().lifetime_budget / 100 || 0,
      },
    };
  }

  public static reduceMetrics(
    allTaskMetrics: {
      meta: Record<
        string,
        {
          name: string;
          key: string;
        }
      >;
      metrics: Record<string, number | string[]>;
    }[]
  ) {
    const reducedMetrics = allTaskMetrics.reduce<{
      meta: Record<string, { name: string; key: string; suffix?: string }>;
      metrics: Record<string, number | string[]>;
    }>(
      (acc, taskMetrics) => {
        Object.entries(taskMetrics.metrics).forEach(([key, value]) => {
          if (typeof value === 'number') {
            acc.metrics[key] = ((acc.metrics[key] as number) || 0) + value;
          } else {
            acc.metrics[key] = ((acc.metrics[key] as string[]) || []).concat(value);
          }
        });

        acc.meta = { ...acc.meta, ...taskMetrics.meta };

        return acc;
      },
      {
        meta: {},
        metrics: {},
      }
    );

    for (const key in reducedMetrics.metrics) {
      if (Array.isArray(reducedMetrics.metrics[key])) {
        reducedMetrics.metrics[key] = [...new Set(reducedMetrics.metrics[key] as string[])].filter(Boolean).length;
      }
    }

    // Filter unused keys
    reducedMetrics.meta = Object.entries(reducedMetrics.meta).reduce((acc, [key, value]) => {
      if (reducedMetrics.metrics[key]) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, { name: string; key: string }>);

    return reducedMetrics;
  }

  public static getFieldConfiguration(field: string) {
    const config = CampaignValidation[field as 'name'];

    if (!config) {
      throw new Error(
        'Field does not have a configuration, please make sure to add metadata before using it as AB variable'
      );
    }

    return config;
  }

  constructor(
    private variableList: ABDependency<any>[],
    private entityBuildId: string,
    private internalEntityId: string,
    private variationIndex: number,
    private macros: Macro[]
  ) {
    super({ taskType: TaskType.CREATE_ALL_IN_ONE, taskCategory: TaskCategory.Facebook });
    this.calculateName();
  }

  public calculateName() {
    const nameVariable = this.findVariable('name')?.value!;
    const flatMacros = this.macros.map((m) => [m, ...m.nestedMacros]).flat();
    const usedAbGroups = this.variableList.map((e) => [e.variableUniquePath, e.abGroup]).flat();
    // const newName = calculateName(nameVariable, this.macros, this.variableList);
    const macrosInNameValues = nameVariable.macros?.map((m) => {
      let fieldConfig: FieldConfiguration = {} as any;
      try {
        fieldConfig = CampaignDTO.getFieldConfiguration(m.field!);
      } catch (e) {
        // Do nothing
      }
      const variable = this.findVariable(m.field as 'name');
      if (m.type === MacroType.Value) {
        if (fieldConfig?.renderFunction && variable) {
          return fieldConfig.renderFunction(variable?.value);
        }
        return variable?.value?.toString();
      }
      if (m.type === MacroType.VariableName) {
        return variable?.name;
      }
      if (m.type === MacroType.GroupName) {
        return flatMacros.find((e) => e.name === m.value && usedAbGroups.includes(e.value.variableUniquePath))?.value
          ?.value;
      }
      if (m.type === MacroType.TextValue) {
        return m.value;
      }
      if (m.type === MacroType.CurrentDate) {
        return moment().format('YYYY-MM-DD');
      }
      if (m.type === MacroType.CurrentTime) {
        return moment().format('HH:mm');
      }
      if (m.type === MacroType.SubEntityName) {
        return (
          this.getSubTaskList()
            .map((e) => {
              (e as AdSetDTO).calculateName();
              return e.getName();
            })
            .filter(Boolean)
            .join(nameVariable.itemSeparator) || '#ADSET_NAME#'
        );
      }
      if (m.type === MacroType.SubEntityNumber) {
        return this.getSubTaskList().length || '#NUMBER_OF_ADSETS#';
      }
    });
    const calculatedName = nameVariable.name + (macrosInNameValues?.join(nameVariable.fieldSeparator) || '');
    this.setName(calculatedName);
  }

  private findVariable<
    M extends typeof CampaignValidation,
    K extends keyof M,
    V = K extends keyof CampaignMeta.Create ? CampaignMeta.Create[K] : never
  >(fieldName: K): ABDependency<V> | undefined {
    return this.variableList.find((variable) => variable.field === (fieldName as string));
  }

  public static validateField<M extends typeof CampaignValidation, F extends keyof typeof CampaignValidation>(
    field: F,
    value: M[F]
  ) {
    const fieldConfig = CampaignValidation[field];

    if (fieldConfig.validation) {
      const result = fieldConfig.validation.validate(value);

      if (result.error) {
        return result.error;
      }
    }
    return null;
  }

  private getError<M extends typeof CampaignValidation, K extends keyof M>(
    payload: { field: K; message: string },
    variable?: ABDependency<any>
  ) {
    return {
      entity: SupportedEntities.campaign,
      entityBuildId: this.entityBuildId,
      field: payload.field,
      message: payload.message,
      type: ErrorType.MISSING,
      abGroup: variable?.abGroup,
      variableId: variable?.abId,
      itemEntity: SupportedEntities.campaign,
    };
  }

  public validate(payload: CampaignMeta.CreateApi) {
    const errors: Array<AbVariableErrorDTO> = [];

    const special_ad_categories = this.findVariable('special_ad_categories')?.value;
    const special_ad_category_country = this.findVariable('special_ad_category_country');
    if (special_ad_categories?.length) {
      if (!special_ad_category_country?.value?.length) {
        errors.push(
          this.getError(
            {
              field: 'special_ad_category_country',
              message: 'At least one country should be selected, if campaign runs on Special Categories',
            },
            special_ad_category_country
          )
        );
      }
    }
    if (!this.getTsAccountId()) {
      errors.push(
        this.getError({
          field: 'account_id',
          message: 'Please select your ad account!',
        })
      );
    }
    return errors;
  }

  public getCampaignStructureTask(): {
    entityId: string;
    variationIndex: number;
    name: string;
    description: string;
    structured: {
      name: string;
      children: { name: string; children: { name: string }[] }[];
    };
  } {
    return {
      entityId: this.internalEntityId,
      variationIndex: this.variationIndex,
      name: this.getName(),
      description: `Campaign with (${this.subTasks.length}) Ad Sets and (${this.getSubTaskList().reduce(
        (acc, task) => acc + task.getSubTaskList().length,
        0
      )}) Ads`,
      structured: {
        name: this.getName(),
        children: this.getSubTaskList().map((adset) => {
          return {
            name: adset.getName(),
            children: adset.getSubTaskList().map((ad) => {
              return {
                name: ad.getName(),
              };
            }),
          };
        }),
      },
    };
  }

  public setRuleIds(ruleIds?: string[]) {
    this.ruleIds = ruleIds;
    return this;
  }

  public buildCampaign(): CampaignMeta.CreateApi {
    if (!this.getName()) {
      throw new Error('Please set name for campaign.');
    }
    const specialCategories = this.findVariable('special_ad_categories')?.value;

    return {
      name: this.getName(),
      special_ad_category_country: this.findVariable('special_ad_category_country')?.value?.map((e) => e.country_code)!,
      adlabels: [],
      objective: this.findVariable('objective')?.value!,
      special_ad_categories: specialCategories?.length ? specialCategories : [''],
      bid_strategy: this.findVariable('bid_strategy')?.value!,
      buying_type: this.findVariable('buying_type')?.value!,
      daily_budget: this.findVariable('daily_budget')?.value!,
      lifetime_budget: this.findVariable('lifetime_budget')?.value!,
      status: Status.PAUSED,
      ruleIds: this.ruleIds,
      spend_cap: this.findVariable('spend_cap')?.value!,
    };
  }

  public static variationPreview(preview: EntityForCreation<unknown>) {
    const id = preview.entityId + preview.variables.map((e) => e.variableUniquePath).join('-');
    const dto = new CampaignDTO(preview.variables, preview.entityId, 'notRequired', 1, preview.macros);

    return {
      id,
      title: dto.getName(),
      name: dto.getName(),
      status: preview.status,
      movingVariables: preview.movingVariables,
      variables: preview.variables
        .filter((e) => e.type !== 'internal')
        .map((item) => {
          const fieldConfig = CampaignDTO.getFieldConfiguration(item.field);

          return {
            field: fieldConfig.readableName ?? item.field,
            name: fieldConfig.readableName ?? item.field,
            value: fieldConfig.renderFunction ? fieldConfig.renderFunction(item.value) : item.value,
          };
        }),
      active: preview.status,
      entity: 'campaign',
    };
  }

  public setupTask(): AbVariableErrorDTO[] {
    this.calculateName();
    const payload = this.buildCampaign();

    this.setPayload(payload);

    return this.validate(payload);
  }

  public getAllInOneTask() {
    this.setupTask();
    const campaignTask = this.getTaskForCreation();

    const adsetAccumulator: Record<string, any> = {};
    for (const adsetTask of this.getSubTaskList()) {
      adsetTask.setupTask();
      const adset = adsetTask.getTaskForCreation();
      const adTasks = adsetTask.getSubTaskList();
      const adsAccumulator: Record<string, any> = {};
      for (const adTask of adTasks) {
        adTask.setupTask();
        const ad = adTask.getTaskForCreation();
        adsAccumulator[ad.taskId] = ad.payload;
      }
      adsetAccumulator[adset.taskId] = { ...adset.payload, ads: adsAccumulator };
    }

    return {
      ...campaignTask,
      payload: {
        ...campaignTask.payload,
        adsets: adsetAccumulator,
      },
      subTasks: [],
    };
  }
}
