<template>
  <div class="rule-template-description-wrapper">
    <b-form-group :state="$_hasError('description')">
      <b-input-group left="Rule Description">
        <b-form-textarea
          v-model="description"
          name="description"
          placeholder="Enter description..."
          v-validate="'required'"
        >
        </b-form-textarea>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('description') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleTemplateDescription',
  mixins: [component],
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      description: '',
    };
  },
  watch: {
    description: {
      immediate: true,
      handler() {
        this.$_emit('description');
      },
      deep: true,
    },
  },
  created() {
    this.description = this.value;
  },
};
</script>

<style lang="scss">
.rule-template-description-wrapper {
  textarea[name="description"] {
    min-height: 70px;
  }
}
</style>
