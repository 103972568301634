import { Alignment, driver, DriveStep, Side } from 'driver.js';
import icons from './icons';

export const tourPrompts = {
  Campaign: {
    STEP_1_CREATE_CAMPAIGN: {
      element: '#step_0',
      popover: {
        title: 'Campaigns & Variations',
        description: `Create your campaign the same way you would do on Facebook, but with the extra option of being able to generate multiple campaign, ad set or ad variations.</br>`,
        side: 'right' as Side,
        align: 'start' as Alignment,
      },
    },
    STEP_2_MANAGE_VARIATIONS: {
      element: '#step_1',
      popover: {
        title: 'Manage Variations',
        description: `Review all generated variations, decide which ones you want to keep, or if you want to generate more variations by modifying the current structure of your campaign.
<div>${icons.MANAGE_VARIATIONS} </div>`,
        side: 'right' as Side,
        align: 'start' as Alignment,
        popoverClass: 'cc-popover--restructure',
      },
    },
    STEP_2_PUBLISH_AND_AUTOMATION: {
      element: '#step_2',
      popover: {
        title: 'Add Automation & Publish',
        description:
          'Attach automatic rules to the campaigns you are about to create, perform a final review, and publish',
        side: 'right' as Side,
        align: 'start' as Alignment,
      },
    },

    ACCOUNT_SELECTION: {
      element: '#cc-cc-facebook__body__content__settings',
      popover: {
        title: 'Let’s create your first campaign',
        description:
          'Fill your options the same way you would on Facebook. Later you will be able to generate multiple variations/campaigns based on those data.',
        side: 'right' as Side,
        align: 'start' as Alignment,
      },
    },
    EXPLAIN_NAVIGATION: {
      element: '#cc-sidebar > div > div > div > div.cc-facebook__body__sidebar',
      popover: {
        title: 'Navigation',
        description: "When you're done with the Campaign use the side bar to navigate to Ad Sets or Ads.",
        side: 'right' as Side,
        align: 'start' as Alignment,
      },
    },
    EXPLAIN_ENTITY_ACTIONS: {
      element: '.DROPDOWN_CLICK',
      popover: {
        title: 'Side Bar Actions',
        description: 'Click on the °°° menu to quickly Clone, Delete, or Create a new Campaign, Ad Set or Ad',
        side: 'right' as Side,
        align: 'start' as Alignment,
      },
    },
    TEMPLATE_APPLY: {
      element: '#APPLY_TEMPLATE',
      popover: {
        title: 'Templates',
        description:
          'If you have previously saved a template you will see them on the right panel called My Space. Click Use Template to load a previously saved template.',
      },
    },
    FEEDBACK_REQUEST: {
      element: '.cc-request-feature--btn',
      popover: {
        title: 'Request feature',
        description:
          "If the feature you're trying to use isn't available, simply click this button to request it, and we'll work to implement it as soon as possible.",
      },
    },
  },
  AdSet: {
    TEST_GROUPS_FEATURES: {
      element: '.cc-variable-tags:nth-of-type(0)',
      popover: {
        title: 'Groups',
        description:
          'Use Groups to manage a set of targeting options that you want to evaluate together and create variations for each.',
      },
    },
    TEST_GROUPS_ADD: {
      element:
        '#cc-cc-facebook__body__content__settings > div > div:nth-child(3) > div.cc-card__body > div.cc-variable-tags > button',
      popover: {
        title: 'Add Group',
        description: 'Add a new group to create a new set of targeting options and test them together',
      },
    },
    TEST_GROUPS_ACTIONS: {
      element: '.cc-variable-tag--active .cc-btn--action',
      popover: {
        title: 'Group Actions',
        description: 'Rename, delete, or clone the Group to manage generated variations',
      },
    },
    ADD_OPTION: {
      element: '#add-budget-button',
      popover: {
        title: 'Add Targeting Option',
        description:
          'Add a new targeting option if you want to create variations with different values of a specific field',
      },
    },
    NAME_TEMPLATE: {
      element: '#NAME_TEMPLATE',
      popover: {
        title: 'Build Dynamic Ad Set Names',
        description: 'Use the Ad Set name template to build dynamic Ad Set names using values from test options',
      },
    },
    ADSET_VARIATION_PREVIEW: {
      element: '#AD_SET_PREVIEW',
      popover: {
        title: 'Preview Ad Set Variations',
        description:
          "In this section, you can manage all variations created for each Ad Set or Ad. Turn off variations that you don't want to be uploaded.",
      },
    },
    ADSET_VARIATION_STOP: {
      element: '.cc-facebook-preview__cards',
      popover: { title: 'Turn Off Ad Set Variation', description: 'Exclude the Ad Set variation from being created' },
    },
  },
  Ads: {
    SUGGESTIONS: {
      element: '.cc-facebook-preview__bottom',
      popover: {
        title: 'Suggestions',
        description: 'Always keep an eye at the suggestions provided by the system for ideas, and apply them',
      },
    },
    BULK_MEDIA: {
      element: '.ad-creative-card__images',
      popover: {
        title: 'Bulk Media',
        description: 'Add multiple media assets at once, and create Ad variations for each media',
      },
    },
    GENERAL_OVERVIEW: {
      element: '.cc-facebook-preview__widgets',
      popover: {
        title: 'General Overview',
        description:
          'Review the total number of campaigns, ad sets and ads that you have created, and make any final changes',
      },
    },
    GO_TO_MANAGE_VARIATIONS: {
      element: '#next-button',
      popover: {
        title: 'Are You Finished with Your Campaign Settings?',
        description: 'Move to the Manage Variations step, for a final review before publishing',
      },
    },
  },
  ManageVariations: {
    MANAGE_VARIATIONS: {
      element: '.cc-step-2',
      popover: {
        title: 'Manage Variations',
        description:
          'A final chance to turn off unwanted variations, clone, delete, or re-structure campaigns to further control the number of total campaigns that will be uploaded to Facebook.',
      },
    },
    MANAGE_VARIATION_PAUSE_CAMPAIGN: {
      element: '.cc-step-2__group',
      popover: {
        title: 'Sidebar Navigation',
        description:
          'Turn Off to exclude or click Clone to duplicate a campaign. Click on the Campaign to load all Ad Set and Ad variations for that campaign and make changes.',
      },
    },
    MANAGE_VARIATION_CAMPAIGN_ADSET: {
      element: '.cc-dd-large',

      popover: {
        title: 'Ad Set and Ad Variations',
        description:
          "In this section you can manage all variations created for each Ad Set or Ad. Turn off variations that you don't want to be uploaded.",
      },
    },
    MANAGE_VARIATION_PAUSE_CAMPAIGN_ADSET: {
      element: '.cc-step-2__two-col__items .cc-preview-card',

      popover: {
        title: 'Manage Ad Set Variation',
        description: 'Here you can pause a variation or navigate to pause a specific Ad variation for this Ad Set',
      },
    },
    MANAGE_VARIATION_PAUSE_CAMPAIGN_AD: {
      element: '.cc-step-2__two-col__items:nth-of-type(2) .cc-preview-card',

      popover: {
        title: 'Manage Ad Variations for Current Campaign / Ad Set Variation',
        description: 'Prevent this Ad Variation from being created on the selected Ad Set Variation',
      },
    },
    MANAGE_CAMPAIGN_RESTRUCTURE_CREATED_CAMPAIGNS: {
      element: '.cc-step-2__body__cols .cc-card:nth-of-type(2)',
      popover: {
        title: 'Generate More Campaign Variations',
        description: `By defining a new Ad Set & Ad structure for the existing campaign, the system will create more campaign variations based on your new structure.<div>${icons.MANAGE_VARIATIONS} </div>`,
        side: 'bottom' as Side,
        align: 'start' as Alignment,
        popoverClass: 'cc-popover--restructure',
      },
    },
    GO_TO_MANAGE_PUBLISH: {
      element: '#next-button',
      popover: {
        title: 'Is It All You Want to Test?',
        description: "If that's all you want to test, go to the Publish & Automation step",
      },
    },
  },
  PublishAndAutomation: {
    PUBLISH_OVERVIEW_ADD_AUTOMATION: {
      element: '.preview-publish__rules',
      popover: {
        title: 'Add Automation',
        description: 'Add automation to created campaigns',
      },
    },
    PUBLISH_OVERVIEW_FOR_CREATED_CAMPAIGNS: {
      element: '.preview-publish__general-information',
      popover: {
        title: 'Overview for Test Suite',
        description: 'Some information about the test suite you have created',
      },
    },
    PUBLISH_OVERVIEW_CAMPAIGN_FOR_CREATION_LIST: {
      element: '.preview-publish__campaign-list',
      popover: {
        title: 'Campaign List',
        description: 'Here you can review the campaign list before publishing',
      },
    },
    PUBLISH_START: {
      element: '#next-button',
      popover: {
        title: 'Start Publishing',
        description: 'Start publishing the campaign',
      },
    },
    PUBLISH_SAVE_AS_TEMPLATE: {
      element: '.cc-step-3__body__cols .cc-card:nth-of-type(1)',
      popover: {
        title: 'Save as Template',
        description: 'Save the campaign as a template for future use',
      },
    },
    PUBLISH_GO_TO_CAMPAIGN_QUEUE: {
      element: '.cc-step-3__body__cols .cc-card:nth-of-type(1)',
      popover: {
        title: 'Go to Campaign Queue',
        description: 'Go to the campaign queue to view the status of the published campaigns',
      },
    },
  },
};

type AvailableTours = typeof tourPrompts;

let driverObj: ReturnType<typeof driver> | null = null;

// let TourInProgress: keyof AvailableTours;

function getActiveTourSteps(currentTour: Record<string, DriveStep>, tourUniqueName: string) {
  const tourStatus = JSON.parse(localStorage.getItem(tourUniqueName) || '{}');
  const steps = Object.entries(currentTour)
    .map(([key, value]) => {
      value.popover = {
        ...value.popover,
        onNextClick: () => {
          const tourStatus = JSON.parse(localStorage.getItem(tourUniqueName) || '{}');
          tourStatus[key] = true;
          localStorage.setItem(tourUniqueName, JSON.stringify(tourStatus));
          driverObj?.moveNext();
        },
        onCloseClick: () => {
          const tourStatus = JSON.parse(localStorage.getItem(tourUniqueName) || '{}');
          const currentSteps = Object.keys(currentTour);
          currentSteps.forEach((step) => {
            tourStatus[step] = true;
          });
          localStorage.setItem(tourUniqueName, JSON.stringify(tourStatus));
          driverObj?.destroy();
          driverObj = null;
        },
      };
      if (tourStatus[key as string]) {
        return false;
      }
      return {
        ...value,
      };
    })
    .filter(Boolean) as DriveStep[];
  const visibleSteps = steps.filter((step) => {
    const isElementVisible = (document.querySelector(step.element as string) as any)?.checkVisibility();
    return !!isElementVisible;
  });
  return { visibleSteps, steps };
}

let currentVisibleSteps: DriveStep[] = [];
let currentVisibleTour: string;
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function prepareTour(tour: keyof AvailableTours) {
  const tourUniqueName = `tour-${tour}`;
  const currentTour = tourPrompts[tour];
  const { visibleSteps, steps } = getActiveTourSteps(currentTour, tourUniqueName);
  currentVisibleSteps = visibleSteps;

  // If some steps are not visible, we need to check if they are in the DOM periodically
  if (visibleSteps.length !== steps.length) {
    const interval = setInterval(async () => {
      const { visibleSteps, steps } = getActiveTourSteps(currentTour, tourUniqueName);
      if (tour !== currentVisibleTour) {
        clearInterval(interval);
        return;
      }

      if (visibleSteps.length === steps.length) {
        clearInterval(interval);
      }
      if (
        visibleSteps.length > 0 &&
        visibleSteps.some((step) => !currentVisibleSteps.find((current) => current.element === step.element))
      ) {
        currentVisibleSteps = visibleSteps;
        driverObj?.destroy();
        driverObj?.setSteps(visibleSteps);
        return driverObj?.drive();
      }
    }, 1000);
  }
  driverObj?.setSteps(visibleSteps);
  return driverObj?.drive();
}

export function useTour<TKey extends keyof AvailableTours, T extends AvailableTours[TKey]>(tour: TKey) {
  // Wait 1 second before starting the tour
  (async () => {
    await sleep(2500);
  })();
  // Check if current tour is already completed
  if (driverObj?.isActive()) {
    console.log('Driver is already active');
    return driverObj;
  }
  // TourInProgress = tour;
  currentVisibleTour = tour;
  // Wait for the page to load (If driver doesn't exist)
  if (!driverObj) {
    driverObj = driver({
      allowClose: true,
      showProgress: true,
      onDestroyStarted: (e) => {
        const currentTour = tourPrompts[tour];
        const tourUniqueName = `tour-${tour}`;
        const tourStatus = JSON.parse(localStorage.getItem(tourUniqueName) || '{}');
        const currentSteps = Object.keys(currentTour);
        currentSteps.forEach((step) => {
          tourStatus[step] = true;
        });
        localStorage.setItem(tourUniqueName, JSON.stringify(tourStatus));
        driverObj?.destroy();
        driverObj = null;
      },
    });
  }
  if (document.readyState !== 'complete') {
    document.addEventListener('DOMContentLoaded', () => {
      return prepareTour(tour);
    });
  } else {
    return prepareTour(tour);
  }
}

export function useTourReset() {
  driverObj?.destroy();
  driverObj = null;
  localStorage.removeItem(`tour-${currentVisibleTour}`);
  useTour(currentVisibleTour as any);
}
