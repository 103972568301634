
import uid from 'uid';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    maxCharacter: { type: Number, default: 30 },
    labelCols: { type: Number, default: 3 },
    breakpoint: { type: String, default: 'md' },
    labelClass: { type: String, default: 'text-md-right' },
    size: { type: String, default: 'sm' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      input: '',
      id: `brandnameInput-${uid(10)}`,
    };
  },
  watch: {
    input() {
      this.$emit('input', this.input);
    },
  },
  created() {
    this.input = this.value;
  },
});
