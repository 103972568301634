<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/**
 * Style order:
 * 1- Bootstrap
 * 2- Bootstrap-vue
 * 3- Shared
 * 4- Platform
 */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-slider-component/theme/default.css';
import '@root_shared/scss/style.scss';
import '@root_platform/scss/style.scss';

export default {
  name: 'App',
};
</script>

<style lang="scss">
// Container (chat support - chat)
#beacon-container {
  --hs-beacon-window-h-offset-left: 32px;
  --hs-beacon-window-v-offset: 84px;
}

// Container (chat support - notifications)
.hsds-beacon .faMuzk {
  bottom: 74px !important;
  left: 22px !important;
}

// Button (chat support)
.BeaconFabButtonFrame {
  display: none !important;
}
</style>
