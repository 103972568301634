import jquery from 'jquery';

const $ = jquery;
if (!jquery.fn) {
  jquery.fn = {};
}

/* eslint-disable */
var multiLevelDropdown = (function() {

  var Constructor = function(name, data, idPositionElement, options) {
      if (typeof data == 'undefined' || !data.length) {
          return;
      }
      this.containerName = name;
      this.data = data;
      this.idElement = idPositionElement;
      this.selectedCat = null;

      var self = this;
      var mainContainer = $('<div>', {
          id: this.containerName + '_select',
          class: 'selector-container',
      });

      var select = $('<div>', {
          id: this.containerName + "_selector-button",
          class: 'selector-drop-down-button',
          text: options.notDefined,
          tabindex: 1,
          click: function() {
              if ($("#" + self.containerName + "_main-drop-down-").is(':hidden')) {
                  $("#" + self.containerName + "_main-drop-down-").show().focus()
              } else {
                  $("#" + self.containerName + "_main-drop-down-").hide()
              }
          }
      });

      var mainDropDown = $('<div>', {
          id: this.containerName + '_main-drop-down-',
          class: 'main-drop-down',
          tabindex: 2,
          blur: function(e) {
              if (e.relatedTarget != null && (e.relatedTarget.id == self.containerName + '_search-input' || e.relatedTarget.id == self.containerName + "_selector-button")) {
                  e.stopPropagation();
                  return;
              }
              $("#" + self.containerName + "_main-drop-down-").hide();
          }
      });

      var searchInput = $('<input>', {
          type: 'text',
          id: this.containerName + '_search-input',
          class: 'drop-down-search-input form-control input-sm',
          placeholder: options.searchPlaceholder,
          blur: function(e) {
              if (e.relatedTarget != null && e.relatedTarget.id == self.containerName + "_main-drop-down-") {
                  return;
              }
              $("#" + self.containerName + "_main-drop-down-").hide();
          },
          keyup: function() {
              var el = $(this);
              if (el.val().length >= 3) {
                  var searchResult = [];
                  var reg = new RegExp(el.val(), 'i');
                  for (var rowId in self.data) {
                      var row = self.data[rowId];

                      if (reg.test(row.name)) {
                          if (row.children.length) {
                              for (var subRowId in self.data) {
                                  var subRow = self.data[subRowId];
                                  if (subRow.path.indexOf(row.id + "_" + row.path) !== -1 && !subRow.children.length) {
                                      searchResult[subRow.id] = subRow;
                                  }
                              }
                          } else {
                              searchResult[row.id] = row;
                          }
                      }
                  }
                  if (searchResult.length) {
                      dropdownContainer.empty();
                      self.buildList(
                          searchResult.sort(function(a, b) {
                              return a.name > b.name ? 1 : -1;
                          }),
                          dropdownContainer,
                          true
                      );
                  }
              }
              if (el.val().length == 0) {
                  dropdownContainer.empty();
                  self.buildList(self.data, dropdownContainer, false);
              }
          }

      });

      var dropdownContainer = $('<div>', {
          id: this.containerName + "_dropdown",
          class: 'dropdown-container'
      });

      this.buildList(this.data, dropdownContainer, false);
      mainDropDown.append(searchInput).append(dropdownContainer);
      mainContainer.append(select).append(mainDropDown);
      $(this.idElement).after(mainContainer);

  };

  Constructor.prototype.buildRow = function(row) {
      var self = this;

      var path = "_" + row.path
      var htmlRow = $('<p>', {
          html: (row.children.length ? '<div class="parent-close"></div>' : '') + row.name,
          class: 'cat_level_' + row.path.split("_").length,
          id: row.id + path.replace("_0", "")
      });
      htmlRow.attr('data-id', row.id);
      htmlRow.attr('data-has-children', row.children.length ? 1 :0);
      htmlRow.addClass('cat_row');
      htmlRow.click(function() {
          var el = $(this);
          var elId = el.attr('id');
          var nextRowId = el.next().attr('id');
          if (el.data('has-children') == 1) {
              var isHide = $("#" + nextRowId).is(':hidden');
              if (isHide) {
                  el.children('div').removeClass('parent-close').addClass('parent-open');
                  $('p[id$="_' + elId + '"]').each(function(index, elem) {
                      var pieces = elem.id.split("_");
                      $("#" + pieces[0] + "_" + elId).toggle();
                  });
              } else {
                  $('p[id$="_' + elId + '"]').hide();
                  $('p[id$="_' + elId + '"]').children('div').removeClass('parent-open').addClass('parent-close');
                  el.children('div').removeClass('parent-open').addClass('parent-close');
              }
          } else {
              $(self.idElement).attr('value', el.data('id'));
              $('p.cat_row').removeClass('selected-cat');
              el.addClass('selected-cat');
              $("#" + self.containerName + "_main-drop-down-").hide();
              $("#" + self.containerName + "_selector-button").text(row.name);
              self.selectedCat = row;
              $(self.idElement).trigger('change');
          }
      });
      return htmlRow;
  };

  Constructor.prototype.buildRowSearch = function(row) {
      var self = this;
      var parentName = '';
      var parents = row.path.split("_");
      for (var relative in parents) {
          var parentId = parents[relative];
          if (parentId == 0) {
              continue;
          }
          parentName += ' < ' + self.data.filter(function(item) {return item.id == parentId})[0].name
      }

      var path = "_" + row.path
      var htmlRow = $('<p>', {
          html: row.name + '<span class="parent-cat">' + parentName + '</span>',
          class: 'cat_level_1',
          title: parentName,
          id: row.id + path.replace("_0", "")
      });
      htmlRow.attr('data-id', row.id);
      htmlRow.addClass('cat_row');
      htmlRow.click(function() {
          var el = $(this);
          $(self.idElement).attr('value', el.data('id'));
          $('p.cat_row').removeClass('selected-cat');
          el.addClass('selected-cat');
          $("#" + self.containerName + "_main-drop-down-").hide();
          $("#" + self.containerName + "_selector-button").text(row.name);
          self.selectedCat = row;
          $(self.idElement).trigger('change');
      });
      return htmlRow;
  };

  Constructor.prototype.buildList = function(data, $container, isSearch) {
      var self = this;

      if (isSearch) {
          for (var rowId in data) {
              $container.append(self.buildRowSearch(data[rowId]));
          }
          $container.children('p').show();
      } else {

          var rows = data.slice();
          var k = 0;
          $container.append('<p class="cat_row" data-id="0" style="display: none;"></p>');
          while (rows.length != 0 && k < 1000) {
              var r = rows.shift();
              if ($container.find('p.cat_row[data-id="' + r.parent + '"]').length) {
                  if (!$container.find('p.cat_row[data-id="' + r.parent + '"] div').length) {
                      $container.find('p.cat_row[data-id="' + r.parent + '"]').prepend('<div class="parent-close"></div>');
                  }

                  $container.find('p.cat_row[data-id="' + r.parent + '"]').after(self.buildRow(r));
              } else {
                  rows.push(r);
              }
              k++
          }
      }
  };

  Constructor.prototype.setValue = function(value) {
      var selectedEl = $('p.cat_row[data-id="' + value + '"]');
      if (selectedEl.is(':hidden')) {
          var parents = selectedEl.attr('id').split("_");
          var path = [];
          while (parents.length > 0) {
              path.unshift(parents.pop())
              $("#" + path.join("_")).trigger('click').show();
          }
      } else {
          selectedEl.trigger('click');
      }
  };

  Constructor.prototype.getSelected = function() {
      return this.selectedCat;
  };

  return Constructor;
}());
/* eslint-enable */

export default multiLevelDropdown;
