<template>
  <div class="rule-bid-wrapper">
    <RuleBidOptions
      :template="config.bidModifierConfig.bidModifierModelTemplates[ruleModel.rule.components.bidModifier.template]"
      :action-options="config.bidModifierConfig.bidModifierActionOptions"
      :to-options="config.bidModifierConfig.bidModifierToOptions"
      :unit-options="config.bidModifierConfig.bidModifierUnitOptions"
      v-model="form"
      :bid="ruleModel.rule.components.bidModifier"
      @max-limit-exhausted="$_maxLimitExhausted"
      name="Bid Modifier"
      ref="ruleBidOptions"
    />
    <RuleMinMax v-if="$c_showMinMax"
                name="Bid Modifier"
                :min="form.min"
                :minObject="form.minObject"
                @min="$_handleMin"
                @minObject="$_handleMinObject"
                :max="form.max"
                :maxObject="form.maxObject"
                @max="$_handleMax"
                @maxObject="$_handleMaxObject"
                :unit="ruleModel.rule.rule_action"
                :limits="ruleModel.rule.components.bidModifier.limits"
                ref="ruleMinMax"
    />
  </div>
</template>

<script>
import RuleBidOptions from './subcomponents/RuleBidOptions.vue';
import RuleMinMax from './subcomponents/RuleMinMax.vue';
import component from '../mixins/component';

export default {
  name: 'RuleBidModfier',
  components: { RuleBidOptions, RuleMinMax },
  mixins: [component],
  props: {
    value: {
      type: Object,
      default: () => ({
        action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
      }),
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    $c_showMinMax() {
      return !(this.form.action === 'set' && this.form.unit === 'static');
    },
  },
  watch: {
    form: {
      handler() {
        if (!this.$c_showMinMax) {
          this.form.minObject.value = this.form.value;
          this.form.maxObject.value = this.form.value;
          this.form.min = this.form.value;
          this.form.max = this.form.value;
        }
        this.$_emit('form');
      },
      deep: true,
    },
    $c_showMinMax() {
      this.form.minObject = { unit: 'static', to: '', value: '' };
      this.form.maxObject = { unit: 'static', to: '', value: '' };
      this.form.min = '';
      this.form.max = '';
    },
  },
  created() {
    this.form = this.value;
  },
  methods: {
    async validate() {
      try {
        const subcomponentsStatus = [];
        Object.values(this.$refs).forEach((ref) => {
          if (ref) {
            subcomponentsStatus.push(ref.validate());
          }
        });
        const validStatuses = await Promise.all([...subcomponentsStatus]);
        if (validStatuses.includes(false)) {
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    async $_maxLimitExhausted() {
      const swal = await this.$swal({
        title: 'Bid seems unusually high!',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Keep Anyway',
        cancelButtonText: 'Reset',
      });
      if (swal.value !== true) this.form.value = '';
    },
    $_handleMax(value) {
      this.form.max = value;
    },
    $_handleMaxObject(maxObject) {
      if (this.form.maxObject) {
        this.form = { ...this.form, maxObject };
      }
    },
    $_handleMin(value) {
      this.form.min = value;
    },
    $_handleMinObject(minObject) {
      if (this.form.minObject) {
        this.form = { ...this.form, minObject };
      }
    },
  },
};
</script>

<style lang="scss">
// .rule-bid-wrapper {
//   .least-one-checkbox-message {
//     position: relative;
//     top: -17px;
//   }
//   .limit-input-wrapper {
//     width: 500px;
//     .state-addon {
//       width: 320px;
//       @media only screen and (max-width : 575px) {
//         width: 150px;
//       }
//     }
//     input.form-control {
//       text-align: center;
//     }
//     .input-group-addon {
//       &:last-child {
//         text-align: center;
//         display: inline-block;
//       }
//     }
//   }
// }
</style>
