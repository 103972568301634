<template>
  <b-modal
    modal-class="optimizer-modal"
    size="lg"
    v-model="modal"
    centered
    @hidden="$_resetForm"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Add Telegram Account</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Error message -->
    <b-alert v-model="alert.show" :variant="alert.variant" dismissible>{{ alert.message }}</b-alert>
    <!-- Telegram Login Button -->
    <div class="text-center py-5" v-if="!form">
      <div v-if="telegramIsActivating">
        We are activating your telegram account....
      </div>
      <div v-else>
        Authorize theOptimizer to send message over Telegram
        <vue-telegram-login class="telegram-iframe" mode="callback" :telegram-login="telegramLogin" request-access="write" @callback="$_onTelegramAuth" />
      </div>
    </div>
    <!-- After Telegram Login -->
    <template v-else>
      <div class="row mb-4">
        <div class="col-12">
          <label><b>Telegram Configurations:</b></label>
        </div>
        <div class="col">First Name: <b> {{ form.first_name }} </b></div>
        <div class="col">Last Name: <b> {{ form.last_name }} </b></div>
      </div>
      <hr class="pb-2">
      <div class="row">
        <div class="col">
          Rule Executed <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.RE('Email'), boundary: 'window' }"></i>
          <switch-button
            v-model="form.notification.RE"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
          />
        </div>
        <div class="col">
          Account Issues <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AI('Email'), boundary: 'window' }"></i>
          <switch-button
            :checked="form.notification.AI"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
            :update="$_updateAI"
          />
        </div>
        <div class="col">
          Smart Notifications <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AN('Email'), boundary: 'window' }"></i>
          <switch-button
            v-model="form.notification.AN"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
          />
        </div>
      </div>
    </template>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn class="primary-button" @click="$_addAccount">Add Telegram</b-btn>
    </template>

  </b-modal>
</template>

<script>
import { vueTelegramLogin } from 'vue-telegram-login';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'TelegramModal',
  components: { vueTelegramLogin },
  mixins: [notifications],
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      modal: false,
      telegramLogin: process.env.VUE_APP_TELEGRAM_BOT,
      telegramIsActivating: false,
      form: null,
      alert: {
        show: false,
        message: '',
        variant: 'danger',
      },
    };
  },
  computed: {
    $c_config() {
      return this.config.notification.model;
    },
    $c_accountsId() {
      return this.$c_config.settingsModel.channels.telegram.accounts.map(({ id }) => id);
    },
  },
  methods: {
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    async $_onTelegramAuth(user) {
      if (user && user.id && user.hash && user.auth_date) {
        this.alert.show = false;
        this.alert.message = '';
        this.telegramIsActivating = true;
        try {
          const response = await this.$api.notification.activateTelegram(user);
          if (response.success) {
            this.$n_successNotification({ title: 'Telegram successfully activated' });
            // Set Form
            this.form = { ...response.account, notification: this.$c_config.getDefaultNotification() };
          } else {
            this.$n_failNotification({ title: 'An error occurred' });
          }
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
        this.telegramIsActivating = false;
      }
    },
    async $_addAccount(event) {
      event.preventDefault();
      if (!this.form) {
        this.alert.show = true;
        this.alert.variant = 'danger';
        this.alert.message = 'Please Click telegram button to add a telegram user for notifications';
        return;
      }
      if (this.$c_accountsId.includes(this.form.id)) {
        this.alert.show = true;
        this.alert.variant = 'danger';
        this.alert.message = 'This telegram account already exists';
        return;
      }
      this.$emit('add', JSON.parse(JSON.stringify(this.form)));
      this.hide();
    },
    $_updateAI(value) {
      this.form.notification.AI = value;
      this.form.notification.DA = value;
      this.form.notification.MAT = value;
    },
    $_resetForm() {
      this.form = null;
      this.alert.show = false;
      this.alert.message = '';
    },
  },
};
</script>

<style lang="scss">
.email-modal {

}
</style>
