import { api, apiStore } from '@sh/services/api';
import { TrafficSource } from '@sh/types';
import { canAccess } from '@sh/helpers';

export default {
  async getConfig() {
    const allowedTypes = [TrafficSource.Taboola, TrafficSource.RevContent, TrafficSource.Outbrain, TrafficSource.Mgid, TrafficSource.Adskeeper, TrafficSource.Gemini];

    const [types, accounts] = await Promise.all([apiStore.trafficSources.types(), api.trafficSources.accounts()]);
    const canAccessFacebookCampaignCreator = await canAccess('facebookCampaignCreator');

    if (canAccessFacebookCampaignCreator) {
      allowedTypes.push(TrafficSource.Facebook);
    }

    /* ***************** MAP ***************** */
    const allowedTypesMap = {};
    const typesMap = {};
    const accountsMap = {};
    allowedTypes.forEach((uniqueName) => { allowedTypesMap[uniqueName] = true; });
    types.forEach((item) => { typesMap[item.uniqueName] = item; });
    accounts.forEach((item) => { accountsMap[item.id] = item; });
    /* ***************** *** ***************** */

    const exportData = {
      value: null,
      destinationAccount: null,
      types,
      typesMap,
      accounts,
      accountsMap,
      allowedTypes,
      allowedTypesMap,
    };
    exportData.setAccount = (accountId) => {
      if (!accountsMap[accountId]) throw Error('Account not found');
      exportData.value = accountsMap[accountId];
    };
    exportData.setDestinationAccount = (accountId) => {
      if (!accountsMap[accountId]) throw Error('Account not found');
      exportData.destinationAccount = accountsMap[accountId];
    };
    return exportData;
  },
};
