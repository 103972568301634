import { cloneDeep } from 'lodash';
import { ruleTypes } from './ruleTypes';

export default class RuleModel {
  constructor(type, isGlobalRule) {
    if (!ruleTypes[type]) {
      throw Error('Rule Type not found');
    }
    this.type = type;
    this.rule = this.setRule(ruleTypes[type], isGlobalRule);
    this.template_id = null;
    this.isGlobalRule = isGlobalRule;
    this.timezone = 'UTC';
  }

  getRule() {
    return this.rule;
  }

  setRuleData(rule, mode) {
    // Components that exists in all rules
    this.rule.components.name.value = mode === 'clone' ? `${rule.name} (Copy)` : rule.name;
    this.rule.components.groups.value = rule.group.map((group) => ({ value: group, label: group }));
    this.setCampaignsOrAccounts(rule);
    this.rule.components.conditions.value = rule.query.map((item) => {
      const _item = JSON.parse(JSON.stringify(item));
      const key = Object.keys(_item)[0];
      const value = _item[key];
      // Custom Conversion To field OR To Self
      if (value.tracker_account_id_2) {
        if (value.to && value.to !== 'static') {
          value.to = `${value.to}::${value.tracker_account_id_2}`;
          delete value.tracker_account_id_2;
        } else if (value.to_self && value.to_self !== 'static') {
          value.to_self = `${value.to_self}::${value.tracker_account_id_2}`;
          delete value.tracker_account_id_2;
        }
      }
      // Custom Conversion trick
      if (value.tracker_account_id) {
        _item[`${key}::${value.tracker_account_id}`] = value;
        delete _item[key];
      }
      return _item;
    });
    // if (rule.hour_of_day) this.rule.components.conditions.value.push({ hour_of_day: { condition: '', value: rule.hour_of_day } })
    if (rule.hour_of_day_new) this.rule.components.conditions.value.push({ hour_of_day: { condition: '', value: { timezone: rule.hour_of_day_new.timezone, scheduler: rule.hour_of_day_new.time_matrix } } });
    this.rule.components.alert.value = rule.alert;

    // Optional components
    if (this.hasComponent('interval')) {
      this.rule.components.interval.value.input = rule.stats_interval || '';
      this.rule.components.interval.value.interval_exclude = rule.stats_interval_exclude;
    }
    if (this.hasComponent('rotation')) {
      this.rule.components.rotation.value = rule.rotation;
    }

    if (this.hasComponent('ruleWithPausedCampaigns')) {
      this.rule.components.ruleWithPausedCampaigns.value = rule.ruleWithPausedCampaigns;
    }

    if (this.hasComponent('ruleWithScheduledAdGroup')) {
      this.rule.components.ruleWithScheduledAdGroup.value = rule.ruleWithScheduledAdGroup;
    }

    if (this.hasComponent('campaign_action')) this.rule.components.campaign_action.value = rule.day_parting;
    if (this.hasComponent('adgroup')) {
      this.rule.components.adgroup.value = rule.change_bid_adgroup.bid_to_change;
      this.rule.components.bid.value = rule.change_bid_adgroup;
      delete this.rule.components.bid.value.bid_to_change;
    }
    if (this.hasComponent('widget')) {
      this.rule.components.widget.value = rule.change_bid_revcontent.active_widgets;
      this.rule.components.bid.value = rule.change_bid_revcontent;
      delete this.rule.components.bid.value.active_widgets;
    }

    if (this.hasComponent('bid')) {
      if (rule.change_bid) this.rule.components.bid.value = rule.change_bid;
      if (rule.change_bid_voluumdsp) this.rule.components.bid.value = rule.change_bid_voluumdsp;
      if (rule.change_bid_facebook) this.rule.components.bid.value = rule.change_bid_facebook;
      // There are cases of old rules where (min/max)Object(s) do not exist
      if (!this.rule.components.bid.value.minObject) this.rule.components.bid.value.minObject = { unit: 'static', to: '', value: this.rule.components.bid.value.min };
      if (!this.rule.components.bid.value.maxObject) this.rule.components.bid.value.maxObject = { unit: 'static', to: '', value: this.rule.components.bid.value.max };
    }
    if (this.hasComponent('bidCap')) {
      if (rule.change_bid_cap) this.rule.components.bidCap.value = rule.change_bid_cap;
    }
    if (this.hasComponent('targetCpa')) {
      if (rule.change_target_cpa) this.rule.components.targetCpa.value = rule.change_target_cpa;
    }
    if (this.hasComponent('targetRoas')) {
      if (rule.change_target_cpa) this.rule.components.targetRoas.value = rule.change_target_cpa;
      if (rule.change_target_roas) this.rule.components.targetRoas.value = rule.change_target_roas;
    }
    if (this.hasComponent('numberOfCopies')) {
      if (rule.change_number_of_copies) this.rule.components.numberOfCopies.value = rule.change_number_of_copies;
      if (this.rule.components.numberOfCopies.budget && rule.change_budget) {
        this.rule.components.numberOfCopies.budget.value = rule.change_budget;
        if (rule.change_budget.value) {
          this.rule.components.numberOfCopies.budget.enableBudget = true;
        } else {
          this.rule.components.numberOfCopies.budget.enableBudget = false;
        }
      }
      if (this.rule.components.numberOfCopies?.adsetBudget && rule.change_budget_adset) {
        this.rule.components.numberOfCopies.adsetBudget.value = rule.change_budget_adset;
        if (rule.change_budget_adset.value) {
          this.rule.components.numberOfCopies.adsetBudget.enableAdSetBudget = true;
        } else {
          this.rule.components.numberOfCopies.adsetBudget.enableAdSetBudget = false;
        }
      }
    }
    if (this.hasComponent('budget')) if (rule.change_budget) this.rule.components.budget.value = rule.change_budget;
    if (this.hasComponent('coefficient')) if (rule.change_coefficient) this.rule.components.coefficient.value = rule.change_coefficient;
    if (this.hasComponent('bidModifier')) if (rule.change_bid_gemini) this.rule.components.bidModifier.value = rule.change_bid_gemini;
    if (this.hasComponent('bidCad')) {
      if (rule.change_bid_cad) {
        ['desktop', 'mobile'].forEach((device) => {
          if (rule.change_bid_cad[device]) {
            this.rule.components.bidCad.value[device].action = rule.change_bid_cad[device].action;
            this.rule.components.bidCad.value[device].unit = rule.change_bid_cad[device].unit;
            this.rule.components.bidCad.value[device].value = rule.change_bid_cad[device].value;
            if (rule.change_bid_cad[device].to) this.rule.components.bidCad.value[device].to = rule.change_bid_cad[device].to;
            if (rule.change_bid_cad.min) this.rule.components.bidCad.value.min = rule.change_bid_cad.min;
            if (rule.change_bid_cad.max) this.rule.components.bidCad.value.max = rule.change_bid_cad.max;
          } else { this.rule.components.bidCad.value[device].status = false; }
        });
      }
    }

    if (this.hasComponent('flags')) {
      if (rule.flags) {
        Object.entries(rule.flags, ([key, value]) => {
          const item = {};
          item[key] = value;
          this.rule.components.flags.value.push(item);
        });
      }
    }

    if (this.hasComponent('widgetsExclude') && rule.exclude_widgets) {
      this.rule.components.widgetsExclude.value.widgets = rule.exclude_widgets;
      if (typeof rule.is_whitelist !== 'undefined') {
        this.rule.components.widgetsExclude.value.type = rule.is_whitelist ? 'INCLUDE' : 'EXCLUDE';
      }
    }

    if (this.hasComponent('entitySelector') && rule.exclude_widgets) {
      const { exclude_widgets, is_whitelist } = rule;
      this.rule.components.entitySelector.value.entities = [...new Set(exclude_widgets)];
      if (is_whitelist && is_whitelist !== 'undefined') {
        this.rule.components.entitySelector.value.type = is_whitelist ? { value: 'INCLUDE', content: 'Include' } : { value: 'EXCLUDE', content: 'Exclude' };
      }
    }
    if (this.hasComponent('parentWhitelist') && rule) {
      const { parent_level, parent_ids, parent_whitelist } = rule;
      this.rule.components.parentWhitelist.value.entities = parent_ids ?? [];

      if (parent_level !== 'undefined' && parent_level !== undefined) {
        this.rule.components.parentWhitelist.value.parent_level = parent_level;
      }
      if (parent_whitelist !== 'undefined') {
        this.rule.components.parentWhitelist.value.parent_whitelist = parent_whitelist ? { value: 'INCLUDE', content: 'Include' } : { value: 'EXCLUDE', content: 'Exclude' };
      }
    }

    if (rule.template_id) this.template_id = rule.template_id;
    if (rule.timezone) this.timezone = rule.timezone;
  }

  setCampaignsOrAccounts(rule) {
    if (this.isGlobalRule) {
      this.rule.components.accounts.value = rule.account_ids;
    } else {
      this.rule.components.campaigns.value = rule.campaign_ids;
    }
  }

  setRule(ruleType, isGlobalRule) {
    const rule = cloneDeep(ruleType);

    if (isGlobalRule) {
      rule.components.campaigns.active = false;
    } else {
      rule.components.accounts.active = false;
    }
    return rule;
  }

  getApiForm(rulePayload) {
    const form = {};
    const conditions = [];
    let hour_of_day;

    // Components that exists in all rules
    form.level = this.rule.level;
    form.rule_action = this.rule.rule_action;
    form.status = typeof rulePayload.status !== 'undefined' ? rulePayload.status : true;
    form.name = this.rule.components.name.value;
    form.group = this.rule.components.groups.value.map((group) => group.value);
    form.campaign_ids = this.rule.components.campaigns.value.map((campaign) => campaign.id);
    form.account_ids = this.rule.components.accounts.value.map((account) => account.id.toString());
    form.is_global = this.isGlobalRule;
    form.alert = this.rule.components.alert.value;
    if (this.rule.rule_action_alias) { form.rule_action_alias = this.rule.rule_action_alias; }

    // Prepare rule conditions dividing hour of day and the other conditions
    this.rule.components.conditions.value.forEach((item) => {
      if (item.hour_of_day) {
        hour_of_day = {};
        hour_of_day.timezone = item.hour_of_day.value.timezone;
        hour_of_day.time_matrix = item.hour_of_day.value.scheduler;
        // hour_of_day.days = item.hour_of_day.value.days
        // hour_of_day.hours = item.hour_of_day.value.hours
      } else {
        // Status Fields
        if (item.status) {
          item.status.value = item.status.value.key ?? item.status.value;
          item.status.condition = 'equals';
        }
        if (item.learning_state) {
          item.learning_state.value = item.learning_state.value.key ?? item.learning_state.value;
          item.learning_state.condition = 'equals';
        }

        // Custom Conversion trick With To fields
        const key = Object.keys(item)[0];
        const explodeKey = key.split('::');
        const value = JSON.parse(JSON.stringify(item[key]));
        const explodeToKey = value.to.split('::');
        const explodeToSelfKey = value.to_self.split('::');

        if (explodeKey.length > 1) {
          value.tracker_account_id = Number(explodeKey[1]);
        }

        if (explodeToKey.length > 1) {
          [value.to] = explodeToKey;
          value.tracker_account_id_2 = Number(explodeToKey[1]);
        }

        if (explodeToSelfKey.length > 1) {
          [value.to_self] = explodeToSelfKey;
          value.tracker_account_id_2 = Number(explodeToSelfKey[1]);
        }

        const newItem = {};
        newItem[explodeKey[0]] = value;
        conditions.push(newItem);
      }
    });

    if (conditions.length) form.query = conditions;
    if (hour_of_day) form.hour_of_day_new = hour_of_day;

    // Optional components
    if (this.hasComponent('interval')) {
      form.stats_interval = this.rule.components.interval.value.input;
      form.stats_interval_exclude = this.rule.components.interval.value.interval_exclude;
    }
    if (this.hasComponent('rotation')) {
      form.rotation = this.rule.components.rotation.value;
    }
    if (this.hasComponent('ruleWithPausedCampaigns') && this.rule.components.ruleWithPausedCampaigns.value) {
      form.ruleWithPausedCampaigns = this.rule.components.ruleWithPausedCampaigns.value;
    }
    if (this.hasComponent('ruleWithScheduledAdGroup') && this.rule.components.ruleWithScheduledAdGroup.value) {
      form.ruleWithScheduledAdGroup = this.rule.components.ruleWithScheduledAdGroup.value;
    }
    if (this.hasComponent('campaign_action')) form.day_parting = this.rule.components.campaign_action.value;

    if (this.hasComponent('bid')) {
      form[this.rule.rule_action] = this.rule.components.bid.value;
      if (!this.rule.components.bid.value.to) { delete form[this.rule.rule_action].to; }
    }
    if (this.hasComponent('bidCap')) {
      form[this.rule.rule_action] = this.rule.components.bidCap.value;
      if (!this.rule.components.bidCap.value.to) { delete form[this.rule.rule_action].to; }
    }
    if (this.hasComponent('budget')) {
      form.change_budget = this.rule.components.budget.value;
      if (!this.rule.components.budget.value.to) {
        delete form.change_budget.to;
      }
    }
    if (this.hasComponent('targetCpa')) {
      form.change_target_cpa = this.rule.components.targetCpa.value;
      if (!this.rule.components.targetCpa.value.to) {
        delete form.change_target_cpa.to;
      }
    }
    if (this.hasComponent('targetRoas')) {
      if (form.rule_action === 'change_target_roas') {
        form.change_target_roas = this.rule.components.targetRoas.value;
        if (!this.rule.components.targetRoas.value.to) {
          delete form.change_target_roas.to;
        }
      } else {
        form.change_target_cpa = this.rule.components.targetRoas.value;
        if (!this.rule.components.targetRoas.value.to) {
          delete form.change_target_cpa.to;
        }
      }
    }
    if (this.hasComponent('numberOfCopies')) {
      if (this.rule.components.numberOfCopies.budget) {
        form.change_budget = this.rule.components.numberOfCopies.budget.value;
        if (!this.rule.components.numberOfCopies.budget.value.to) {
          delete form.change_budget.to;
        }
        if (!this.rule.components.numberOfCopies.budget?.enableBudget) {
          delete form.change_budget;
        }
      }
      if (this.rule.components.numberOfCopies.adsetBudget) {
        form.change_budget_adset = this.rule.components.numberOfCopies.adsetBudget.value;
        if (!this.rule.components.numberOfCopies.adsetBudget?.enableAdSetBudget) {
          delete form.change_budget_adset;
        }
      }
      form.change_number_of_copies = this.rule.components.numberOfCopies.value;
    }
    if (this.hasComponent('coefficient')) {
      form.change_coefficient = this.rule.components.coefficient.value;
      if (!this.rule.components.coefficient.value.to) {
        delete form.change_coefficient.to;
      }
    }
    if (this.hasComponent('bidModifier')) {
      form.change_bid_gemini = this.rule.components.bidModifier.value;
      if (!this.rule.components.bidModifier.value.to) {
        delete form.change_bid_gemini.to;
      }
    }
    if (this.hasComponent('bidCad')) {
      form.change_bid_cad = {};
      form.change_bid_cad.min = this.rule.components.bidCad.value.min;
      form.change_bid_cad.max = this.rule.components.bidCad.value.max;

      ['desktop', 'mobile'].forEach((device) => {
        if (this.rule.components.bidCad.value[device].status) {
          form.change_bid_cad[device] = { ...this.rule.components.bidCad.value[device] };
          if (!this.rule.components.bidCad.value[device].to) { delete form.change_bid_cad[device].to; }
          delete form.change_bid_cad[device].status;
          delete form.change_bid_cad[device].min;
          delete form.change_bid_cad[device].max;
        }
      });
    }

    if (this.hasComponent('adgroup')) {
      form.change_bid_adgroup.bid_to_change = this.rule.components.adgroup.value;
    }
    if (this.hasComponent('widget')) {
      form.change_bid_revcontent.active_widgets = this.rule.components.widget.value;
    }

    if (this.hasComponent('flags')) {
      form.flags = {};
      this.rule.components.flags.value.forEach((item) => {
        const key = Object.keys(item)[0];
        form.flags[key] = item[key];
      });
    }

    if (this.hasComponent('widgetsExclude')) {
      if (this.rule.components.widgetsExclude.value.type === null) {
        form.exclude_widgets = [];
      } else {
        form.is_whitelist = this.rule.components.widgetsExclude.value.type === 'INCLUDE';
        form.exclude_widgets = this.rule.components.widgetsExclude.value.widgets;
      }
    }
    if (this.hasComponent('entitySelector')) {
      let { value } = this.rule.components.entitySelector;

      if (!value) {
        value = { entities: [], type: { value: null, content: 'Select Filter' } };
      }
      if (value.type.value === null) {
        form.exclude_widgets = [];
      } else {
        form.is_whitelist = value.type.value === 'INCLUDE';
        form.exclude_widgets = value.entities.map((el) => ({ id: el.traffic_source_adgroup_id }));
      }
    }
    if (this.hasComponent('parentWhitelist')) {
      if (this.rule.components.parentWhitelist.value.type === null) {
        form.parent_ids = [];
      } else {
        form.parent_whitelist = this.rule.components.parentWhitelist.value.type.value === 'INCLUDE';
        form.parent_ids = this.rule.components.parentWhitelist.value.entities.map((item) => ({ id: item.id }));
        form.parent_level = this.rule.components.parentWhitelist.value.parent_level;
      }
    }

    if (this.hasComponent('budget') && (!this.rule.components.budget?.enableBudget && this.rule.components.budget?.isOptional)) {
      delete form.change_budget;
    }

    if (this.template_id) form.template_id = this.template_id;

    return form;
  }

  // return if the component has the attribute
  hasComponent(attribute) {
    return this.rule.components[attribute]?.active;
  }

  getActiveComponentskey() {
    const keys = [];
    Object.entries(this.rule.components).forEach(([key, component]) => {
      if (component.active) {
        keys.push(key);
      }
    });
    return keys;
  }
}
