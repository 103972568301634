<template>
  <div class="optimizer-select optimizer-single-select">
    <b-dropdown
      menu-class="optimizer-single-select__dropdown-menu"
      @hidden="$_hidden"
      @shown="$_shown"
    >
      <div
        slot="button-content"
        class="placeholder"
        v-html="$c_placeholder"
      />

      <b-dropdown-item
        v-for="(item, i) in list"
        :key="i"
        class="item"
        :class="{ 'active-item': item.selected }"
        :to="item.to || null"
        @click="$_itemClickAction(item)"
      >
        <slot :item="item">
          <span v-html="item.content" />
        </slot>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SingleSelect',
  props: {
    list: { type: Array, required: true },
    placeholder: { type: String, default: '' },
    staticPlaceholder: { type: String, default: '' },
    onClick: { type: Function, default: null },
  },
  data() {
    return {
      json_selected: {},
    };
  },
  computed: {
    $c_selectedItem() {
      return this.list.find((item) => item.selected);
    },
    $c_placeholder() {
      if (this.staticPlaceholder) {
        return this.staticPlaceholder;
      }
      return (this.$c_selectedItem && this.$c_selectedItem.content) || this.placeholder;
    },
  },
  watch: {
    lists() {
      this.$emit('change', this.$c_selectedItem);
    },
  },
  created() {
    if (this.list.length) {
      this.$emit('input', this.$c_selectedItem || null);
      this.$emit('change', this.$c_selectedItem || null);
    }
  },
  methods: {
    $_itemClickAction(item) {
      this.list.forEach((el) => (el.selected = false));
      item.selected = true;
      this.$emit('input', item);
      if (this.onClick) {
        this.onClick(item);
      }
    },
    $_shown() {
      this.json_selected = JSON.stringify(this.$c_selectedItem);
    },
    $_hidden() {
      if (JSON.stringify(this.$c_selectedItem) !== this.json_selected) this.$emit('change', this.$c_selectedItem || null);
    },
  },
};
</script>

<style lang="scss">
  // .single-select-wrapper {
  //   .dropdown-menu {
  //     z-index: 99999999;
  //   }
  // }
  // .b-dropdown > * {
  //   width: 100% !important;
  // }
  // .b-dropdown {
  //   .dropdown-item a {
  //     color: unset;
  //     text-decoration: unset;
  //   }
  // }
</style>

<style scoped>
  .b-dropdown {
    width: 100%;
  }
  .placeholder {
    width: calc(100% - 10px) !important;
    display: inline-block;
    text-align: left;
  }
  /* .item {
    width: 100% !important;
    font-size: 13px;
    height: 35px;
    line-height: 35px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    border: 0px;
  } */
  /* .item:hover {
    background: #eee;
  }
  .item:focus {
    color: black;
    outline: none !important;
  }
  .active-item {
    background: #f5f5f5 !important;
  } */
</style>
