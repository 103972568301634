<template>
  <b-form :ref="getScope()" :data-vv-scope="getScope()" @submit.prevent @keyup.enter="$_handleOk">
    <b-modal
      id="createTagModal"
      title="Create New Tag"
      @ok="$_handleOk"
      @show="$_clear"
      @shown="$refs._name.$el.focus()"
      ref="createTagModal"
    >
      <b-form-group :state="hasError('_name')">
        <b-form-input
          ref="_name"
          data-vv-as="Tag Name"
          v-validate="'required'"
          data-vv-validate-on="input"
          autocomplete="false"
          type="text"
          v-model="name"
          name="_name"
          placeholder="Enter Tag Name"
        >
        </b-form-input>
        <small slot="invalid-feedback">{{ getError('_name') }}</small>
      </b-form-group>
    </b-modal>
  </b-form>
</template>

<script>
const scope = 'modalNewTemplateTagForm';
export default {
  name: 'NewTagModal',
  data() {
    return {
      name: '',
    };
  },
  methods: {
    show() {
      this.$refs.createTagModal.show();
    },
    hide() {
      this.$refs.createTagModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.getScope());
      if (isValid) {
        this.$emit('newTag', this.name);
        this.$_clear();
        this.hide();
      }
    },
    $_clear() {
      this.name = '';
      this.$validator.reset(this.getScope());
    },
    getScope() {
      return scope;
    },
    hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>
