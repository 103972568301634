
import {
  FilterResultProps,
  SupportedTrafficSourceFilters,
  TrafficSourceFilteredProps,
  useTrafficSourcesStore,
} from '@/stores/traffic-sources';
import Checkbox from '@sh/components/Utils/Checkbox.ts.vue';
import DropdownTabContent from '@sh/components/Utils/DropdownTabContent.ts.vue';
import FilterDropdown from '@sh/components/Utils/FilterDropdown.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { BDropdown } from 'bootstrap-vue';
import { filter, map, omit } from 'lodash';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';

interface TrafficSourceProps {
  COMPONENT_ID: string;
  allTrafficSourceSelected: boolean;
  allLinkedTrackersSelected: boolean;
  indeterminateTs: boolean;
  indeterminateTrackers: boolean;
  searchTrafficSource: string;
  searchLinkedTrackers: string;
  searchTagValue: string;
  searchCurrencies: string;
}

type IndeterminateTypeProps = 'indeterminateTs' | 'indeterminateTrackers';
type SelectedTypeProps = 'allTrafficSourceSelected' | 'allLinkedTrackersSelected';

export default defineComponent({
  name: 'TrafficSourceFilters',
  components: {
    DropdownTabContent,
    OptimizerIcon,
    Checkbox,
    FilterDropdown,
  },
  data(): TrafficSourceProps {
    return {
      COMPONENT_ID: 'trafficSourceFilters',
      allTrafficSourceSelected: false,
      allLinkedTrackersSelected: false,
      indeterminateTs: false,
      indeterminateTrackers: false,
      searchTrafficSource: '',
      searchLinkedTrackers: '',
      searchTagValue: '',
      searchCurrencies: '',
    };
  },
  computed: {
    getAppliedFilter(): SupportedTrafficSourceFilters {
      return this.getFilters(this.COMPONENT_ID);
    },
    trafficSourcesFilteredValues(): TrafficSourceFilteredProps[] {
      return this.getTrafficSourceUniqueNames.filter(
        (item) => !this.searchTrafficSource || item.name.toLowerCase().includes(this.searchTrafficSource.toLowerCase())
      );
    },
    linkedTrackersFilteredValues(): FilterResultProps[] {
      return this.getLinkedTrackers.filter(
        (item) =>
          !this.searchLinkedTrackers || item.name.toLowerCase().includes(this.searchLinkedTrackers.toLowerCase())
      );
    },
    tagsFilteredValues(): string[] {
      return this.getTags.filter(
        (item) => !this.searchTagValue || item.toLowerCase().includes(this.searchTagValue.toLowerCase())
      );
    },
    currenciesFilteredValues(): string[] {
      return this.getCurrencies.filter(
        (item) => !this.searchCurrencies || item.toLowerCase().includes(this.searchCurrencies.toLowerCase())
      );
    },
    getAppliedFiltersLength(): number {
      const savedFilters = omit(
        this.filterState[this.COMPONENT_ID].savedFilters.find((el) => el.id === this.COMPONENT_ID),
        ['id', 'type']
      );
      return filter(savedFilters, (arr) => arr?.length > 0).length;
    },
    filterSuffix(): string {
      return `(${this.getFilterResultCount(this.COMPONENT_ID)})`;
    },
    isTsSearchShown(): boolean {
      return this.trafficSourcesFilteredValues.length > 0 || Boolean(this.searchTrafficSource);
    },
    isLinkedTrackersSearchShown(): boolean {
      return this.linkedTrackersFilteredValues.length > 0 || Boolean(this.searchLinkedTrackers);
    },
    isTagsSearchShown(): boolean {
      return this.tagsFilteredValues.length > 0 || Boolean(this.searchTagValue);
    },
    isCurrencySearchShown(): boolean {
      return this.currenciesFilteredValues.length > 0 || Boolean(this.searchCurrencies);
    },
    accountStatusOptions(): Array<{ value: string; text: string }> {
      return [
        {
          value: 'all',
          text: 'All Statuses',
        },
        {
          value: '1',
          text: 'Enable',
        },
        {
          value: '0',
          text: 'Disable',
        },
      ];
    },
    ...mapState(useTrafficSourcesStore, [
      'filters',
      'getFilters',
      'getTrafficSourceUniqueNames',
      'getLinkedTrackers',
      'getTags',
      'getCurrencies',
      'getFilterResultCount',
      'getIsFilterUsed',
    ]),
    ...mapWritableState(useTrafficSourcesStore, ['filterState']),
  },
  watch: {
    'getAppliedFilter.trafficSource': function (newValue) {
      this.handleSelectedChange(
        newValue,
        this.getTrafficSourceUniqueNames.length,
        'indeterminateTs',
        'allTrafficSourceSelected'
      );
    },
    'getAppliedFilter.linkedTrackers': function (newValue) {
      this.handleSelectedChange(
        newValue,
        this.getLinkedTrackers.length,
        'indeterminateTrackers',
        'allLinkedTrackersSelected'
      );
    },
  },
  created() {
    this.initFilters(this.COMPONENT_ID);
  },
  methods: {
    ...mapActions(useTrafficSourcesStore, ['registerAccounts', 'initFilters']),
    hideTsFilters() {
      const savedFilters = this.filterState[this.COMPONENT_ID].savedFilters.find(
        (item) => item.id === this.COMPONENT_ID
      );
      if (savedFilters) {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: omit(savedFilters, ['id', 'type']),
        };
      } else {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: {
            tags: [],
            currency: [],
            linkedTrackers: [],
            status: '',
            trafficSource: [],
          },
        };
      }
    },
    isTagSelected(tag: string) {
      return this.getFilters(this.COMPONENT_ID).tags.includes(tag);
    },
    handleOnFilterValues() {
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        savedFilters: [
          {
            ...this.filterState[this.COMPONENT_ID].filterSession,
            id: this.COMPONENT_ID,
            type: 'temp',
          },
        ],
      };

      this.$emit('onFilter', this.getAppliedFilter);
      const dropdownRef = (this.$refs.filterDropdown as Vue)?.$refs.dropdown as BDropdown;
      dropdownRef.hide(true);
    },
    clearSelectedValues(filterKey: keyof SupportedTrafficSourceFilters) {
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          [filterKey]: filterKey === 'status' ? '' : [],
        },
      };
    },
    handleSelectedChange(
      newValue: string[],
      totalItemsLength: number,
      indeterminateProp: IndeterminateTypeProps,
      allSelectedProp: SelectedTypeProps
    ) {
      if (newValue.length === 0) {
        this[indeterminateProp] = false;
        this[allSelectedProp] = false;
      } else if (newValue.length === totalItemsLength) {
        this[indeterminateProp] = false;
        this[allSelectedProp] = true;
      } else {
        this[indeterminateProp] = true;
        this[allSelectedProp] = false;
      }
    },
    toggleAllTrafficSource(checked: boolean) {
      this.filterState[this.COMPONENT_ID].filterSession.trafficSource = checked
        ? map(this.getTrafficSourceUniqueNames, (value) => value.uniqueName).slice()
        : [];
    },
    toggleAllLinkedTrackers(checked: boolean) {
      this.filterState[this.COMPONENT_ID].filterSession.linkedTrackers = checked
        ? map(this.getLinkedTrackers, (value) => value.id).slice()
        : [];
    },
    toggleTagsSelection(tag: string) {
      const index = this.getAppliedFilter.tags.indexOf(tag);
      if (index === -1) {
        this.filterState[this.COMPONENT_ID].filterSession.tags.push(tag);
      } else {
        this.filterState[this.COMPONENT_ID].filterSession.tags.splice(index, 1);
      }
    },
    handleValueChanged(value: string, selected: boolean, tabKey: keyof Omit<SupportedTrafficSourceFilters, 'status'>) {
      if (selected) {
        this.filterState[this.COMPONENT_ID].filterSession[tabKey] = [...(this.getAppliedFilter[tabKey] || []), value];
      } else {
        // Remove the selected field from the array if the checkbox is unchecked
        this.filterState[this.COMPONENT_ID].filterSession[tabKey] = (
          (this.getAppliedFilter[tabKey] as string[]) || []
        ).filter((v) => v !== value);
      }
    },
    handleClearItems() {
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          tags: [],
          currency: [],
          linkedTrackers: [],
          status: '',
          trafficSource: [],
        },
      };

      this.$emit('onClear');
    },
  },
});
