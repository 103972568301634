<template>
  <b-modal
    modal-class="optimizer-modal"
    size="lg"
    v-model="modal"
    centered
    @hidden="$_resetForm"
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Add Slack Account</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Error message -->
    <b-alert v-model="alert.show" :variant="alert.variant" dismissible>{{ alert.message }}</b-alert>
    <!-- Slack Login Button -->
    <div class="text-center py-5" v-if="!form">
      <div v-if="slackIsActivating">
        We are activating your slack account....
      </div>
      <div v-else>
        Authorize theOptimizer to send message over Slack
        <a :href="slackHref" class="ml-3">
          <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png"
               srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
    </div>
    <!-- After Slack Login -->
    <template v-else>
      <!-- Account Name -->
      <b-form-group
        class="optimizer-form-group"
        label="Name:"
        :invalid-feedback="$_getError('name')"
        :state="$_hasError('name')"
      >
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Account Name"
          :state="$_hasError('name')"
          v-validate="'required'"
          type="text"
          v-model="form.name"
          name="name"
          key="name"
          placeholder="Account Name"
        />
      </b-form-group>
      <!-- <hr> -->
      <div class="row pb-2">
        <div class="col-12">
          <label><b>Slack Configurations:</b></label>
        </div>
        <div class="col">Team Name: <b> {{ form.team_name }} </b></div>
        <div class="col">Channel: <b> {{ form.incoming_webhook ? form.incoming_webhook.channel : '' }} </b></div>
      </div>
      <hr class="pb-2">
      <div class="row">
        <div class="col">
          Rule Executed <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.RE('Email'), boundary: 'window' }"></i>
          <switch-button
            v-model="form.notification.RE"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
          />
        </div>
        <div class="col">
          Account Issues <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AI('Email'), boundary: 'window' }"></i>
          <switch-button
            :checked="form.notification.AI"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
            :update="$_updateAI"
          />
        </div>
        <div class="col">
          Smart Notifications <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AN('Email'), boundary: 'window' }"></i>
          <switch-button
            v-model="form.notification.AN"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
          />
        </div>
      </div>
    </template>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn class="primary-button" @click="$_addAccount">Add Slack</b-btn>
    </template>

  </b-modal>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'SlackModal',
  mixins: [notifications],
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      modal: false,
      slackHref: `https://slack.com/oauth/authorize?scope=incoming-webhook,files:write:user&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_SLACK_REDIRECT_URL)}`,
      slackIsActivating: false,
      form: null,
      alert: {
        show: false,
        message: '',
        variant: 'danger',
      },
    };
  },
  computed: {
    $c_config() {
      return this.config.notification.model;
    },
    $c_accountsChannelId() {
      return this.$c_config.settingsModel.channels.slack.accounts.map(({ incoming_webhook }) => incoming_webhook.channel_id);
    },
  },
  methods: {
    show(account) {
      this.modal = true;
      if (account) {
        // Set Form
        this.form = { name: '', ...account, notification: this.$c_config.getDefaultNotification() };
      }
    },
    hide() {
      this.modal = false;
    },
    async $_addAccount(event) {
      event.preventDefault();
      if (!this.form) {
        this.alert.show = true;
        this.alert.variant = 'danger';
        this.alert.message = 'Please Click telegram button to add a slack user for notifications';
        return;
      }
      if (this.$c_accountsChannelId.includes(this.form.incoming_webhook.channel_id)) {
        this.alert.show = true;
        this.alert.variant = 'danger';
        this.alert.message = 'This Slack account already exists';
        return;
      }
      try {
        const validation = await this.$validator.validateAll();
        if (!validation) {
          this.$n_warnNotification({ title: 'Invalid form' });
          return;
        }
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
        return;
      }
      // Success, Validation pass
      this.$emit('add', JSON.parse(JSON.stringify(this.form)));
      this.hide();
    },
    $_updateAI(value) {
      this.form.notification.AI = value;
      this.form.notification.DA = value;
      this.form.notification.MAT = value;
    },
    $_resetForm() {
      this.form = null;
      this.alert.show = false;
      this.alert.message = '';
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
  },
};
</script>

<style lang="scss">
.email-modal {

}
</style>
