
import CcIcon from '@/views/Automation/CampaignCreatorV2/components/CcIcon/CcIcon.ts.vue';
import RequestFeature from '@/views/Automation/CampaignCreatorV2/components/CcRequestFeature/RequestFeature.ts.vue';
import { ObjectiveOptions } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { Icons } from '@/views/Automation/CampaignCreatorV2/helpers/icons';
import { RadioGroupIconOption } from '@/views/Automation/CampaignCreatorV2/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    CcIcon,
    RequestFeature,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<RadioGroupIconOption[]>,
      default: () =>
        Object.values(ObjectiveOptions).map((i) => ({
          text: i.label,
          value: i.value,
          disabled: !!(i as any).disabled,
          icon: i.icon as Icons,
          requestFeature: (i as any).requestFeature,
        })),
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    state(): boolean {
      return !!this.value;
    },
  },
  methods: {
    onCheckboxClick(value: boolean) {
      this.$emit('update', value);
    },
  },
});
