
import rulesConfig from '@/views/Automation/Rules/config';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import RulesSelectGroup from '@sh/components/Utils/RulesSelectGroup.vue';
import notifications from '@sh/mixins/notifications';
import { Campaign } from '@sh/types';
import { Config, RuleGroupType, RuleType, RuleTypeKey } from '@sh/views/Campaign/types';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentRefs {
  $refs: {
    groupSelect?: VueOptiSelect;
  };
}

interface ComponentData {
  config?: Config;
  modal: boolean;
  rulesModal: {
    model: RuleType[];
    loading: boolean;
    otherRules: RuleType[];
  };
}

export default (Vue as VueConstructor<Vue & NotificationMixin & ComponentRefs>).extend({
  name: 'AddRuleModal',
  components: { RulesSelectGroup, OptimizerIcon },
  mixins: [notifications],
  props: {
    item: { type: Array as PropType<{ _id: string }[]>, default: () => [] },
    campaign: { type: Array as PropType<Campaign[]>, required: true },
  },
  data(): ComponentData {
    return {
      config: undefined,
      modal: false,
      rulesModal: {
        model: [],
        loading: false,
        otherRules: [],
      },
    };
  },
  computed: {
    ruleMap(): Record<string, string> {
      const options: Record<string, string> = {};
      let key: RuleTypeKey;
      for (key in this.config?.ruleConfig.ruleTypes) {
        if (Object.prototype.hasOwnProperty.call(this.config?.ruleConfig.ruleTypes, key)) {
          const item = this.config?.ruleConfig.ruleTypes[key];
          if (
            item?.ts_types.some((tsType: string) =>
              this.campaign.some(
                (campaignItem: Record<string, any>) => campaignItem.traffic_source_unique_name === tsType
              )
            )
          ) {
            options[`${item.level}:${item.rule_action}`] = key;
          }
        }
      }

      return options;
    },
  },
  created() {
    this.initializeConfig();
  },
  methods: {
    async initializeConfig() {
      try {
        this.config = await rulesConfig.getConfig();
      } catch (error) {
        console.error('Error while initializing:', error);
      }
    },
    async show() {
      this.modal = true;
      this.rulesModal.otherRules = [];
      this.rulesModal.loading = true;
      this.rulesModal.model = [];
      try {
        const response = await this.$api.rules.index();
        const attachedRulesIds = this.item.map((row: any) => row._id);
        this.rulesModal.otherRules = response.filter(
          (item: any) =>
            this.ruleMap[`${item.level}:${item.rule_action}`] &&
            attachedRulesIds.indexOf(item._id) === -1 &&
            !(
              item.rule_action === 'change_budget' &&
              this.campaign.filter((item) => item.traffic_source_unique_name === 'Taboola').length > 0 &&
              this.campaign.some((item) => item.additional_fields.daily_budget === undefined)
            )
        );
      } catch (error: any) {
        this.$n_failNotification({
          title: error?.response?.data?.message || 'Something went wrong, please try again later!',
        });
      }
      this.rulesModal.loading = false;
    },
    async addRules() {
      const campaign_ids = this.campaign.map((item: any) => item.id);
      this.rulesModal.loading = true;
      const ruleIds = this.rulesModal.model.map((row: RuleType) => row._id);
      try {
        const result = await this.$api.rules.editCampaign({
          action: 'add',
          campaign_ids,
          rules: ruleIds,
        });
        this.$emit('addRule', this.rulesModal);
        this.$n_successNotification({ title: result.message || 'Successfully rules attached' });
      } catch (error: any) {
        this.$n_failNotification({
          title: error.response.data.message || 'Something went wrong, please try again later!',
        });
      }
      this.rulesModal.model = [];
      this.rulesModal.otherRules = [];
      this.rulesModal.loading = false;
      this.hide();
    },
    changeRulesGroup(diff: RuleGroupType) {
      const model: RuleType[] = Object.keys(diff.remove).length
        ? this.rulesModal.model.filter((rule) => !diff.remove[rule._id])
        : this.rulesModal.model;

      if (Object.keys(diff.add).length) {
        const modelMap: Record<string, RuleType> = {};
        model.forEach((item) => {
          modelMap[item._id] = item;
        });
        this.rulesModal.otherRules.forEach((rule) => {
          if (diff.add[rule._id] && !modelMap[rule._id]) {
            modelMap[rule._id] = rule;
            model.push(rule);
          }
        });
      }
      this.rulesModal.model = model;
    },
    clear() {
      this.rulesModal.model = [];
      this.$refs.groupSelect?.clear();
    },
    hide() {
      this.clear();
      this.modal = false;
    },
  },
});
