<template>
  <div class="rule-adgroup-option-wrapper">
    <b-form-group
      class="optimizer-form-group"
      label="Apply Rule To"
      :state="$_hasError('optionInput')"
      :invalid-feedback="$_getError('optionInput')"
    >
      <b-form-radio-group
        class="apply-rule-adgroup-options"
        v-model="adgroup"
        :options="options"
        stacked
        name="optionInput"
        data-vv-as="Adgroup option"
        v-validate="'required'"
      >
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleAdgroup',
  mixins: [component],
  props: {
    value: { type: [String, null], default: null },
  },
  data() {
    return {
      adgroup: '',
      options: [
        { text: 'Apply to All AdGroups', value: 'all' },
        { text: 'Apply to CPC Native', value: 'native' },
        { text: 'Apply to CPC Search', value: 'search' },
      ],
    };
  },
  watch: {
    adgroup: {
      immediate: true,
      handler() {
        this.$_emit('adgroup');
      },
      deep: true,
    },
  },
  created() {
    this.adgroup = this.value;
  },
  methods: {
  },
};
</script>

<style lang="scss">
.rule-adgroup-option-wrapper {
  margin-top: 2.5rem;

  .optimizer-form-group {
    margin-bottom: 0;

    .apply-rule-adgroup-options {
      display: flex;

      .custom-control-input:checked {
        ~ .custom-control-label::before {
          background-color: $color-light-blue;
          border-color: $color-light-blue;
        }
      }

      .custom-control-label {
        margin-right: 2.5rem;
        font-size: 1.5rem;

        span {
          padding-left: .3rem;
        }

        &::before {
          top: .3rem;
          left: -1.9rem;
          width: 1.5rem;
          height: 1.5rem;
          box-shadow: none;
          outline: none;
        }

        &::after {
          top: 0.3rem;
          left: -1.9rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}
</style>
