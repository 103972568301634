<template>
  <div class="email-settings-wrapper">
    <channel-ui
      :name="name"
      label="Email"
      :channel="$c_channel"
      :columns="['email', 'name', 'RE', 'AI', 'AN']"
      :update="$_update"
      :get-notification-field="config.notification.model.getTableHeaderField"
      @add="$_openAccountModal"
    />
    <email-modal ref="account-modal" :config="config" @add="$_addAccount" />
    <channel-action-bar v-if="$c_hasChanges" :loading="loadingUpdateSettings" @update="$_updateSettings" />
  </div>
</template>

<script>
import notificationsMixins from './notificationMixins';
import EmailModal from '../modals/EmailModal';

export default {
  name: 'EmailSettings',
  components: { EmailModal },
  mixins: [notificationsMixins],
  data() {
    return {
      name: 'email', // Channel identifier
    };
  },
};
</script>

<style lang="scss">
.email-settings-wrapper {

}
</style>
