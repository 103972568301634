<template>
  <!-- Modal Component -->
  <b-modal
    id="traffic-source-modal"
    v-model="modal"
    :modal-class="modalClass"
    :no-close-on-backdrop="noCloseOnBlur"
    :no-close-on-esc="noCloseOnBlur"
    size="lg"
    centered
    @ok="onSaveClick"
    @hidden="$_hidden"
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 v-if="form.action === 'edit'" class="modal-header__title">Edit Traffic Source</h2>
      <h2 v-else class="modal-header__title">New Traffic Source</h2>

      <svg class="modal-header__close-icon" @click="hideModal" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Modal Body -->
    <TrafficSource ref="trafficSourceForm" @onTableUpdate="onTableUpdate" @changeTrafficSourceType="$_typeChange" :mode="form" :trafficSourceTokens="trafficSourceTokens" />

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <a
        v-if="canAccessComplianceProgram"
        class="pointer fs-12 info-link"
        @click.prevent="onUpdateTransparencySettings"
      > Update Transparency Settings</a>
      <div
        v-if="redirectAdAccount"
        class="d-flex align-items-center pointer"
        :class="redirectAdAccount.uniqueName === AdAccounts.Facebook ? 'facebook-btn' : 'social-btn'"
        @click="onSaveClick"
      >
        <div class="social-icon-wrapper d-flex justify-content-center align-items-center">
          <img :src="redirectAdAccount.logoURL" alt="social-icon" />
        </div>
        <p class="btn-text fs-14 fw-700 ml-8">
          Sign in with
          {{ redirectAdAccount.uiName }}
        </p>
      </div>
      <b-btn v-else :disabled="isLoading" class="primary-button" @click="onSaveClick">
        <i v-if="isLoading" class="fa fa-spinner fa-spin" />
        Save
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import TrafficSource from './TrafficSource.vue';
import { TrafficSource as AdAccounts } from '@sh/types';
// import Loading from '@sh/components/Utils/Loading.vue';

export default {
  name: 'TrafficSourceModal',
  components: {
    TrafficSource,
    // Loading,
  },
  props: {
    noCloseOnBlur: { type: Boolean, default: false },
    canAccessComplianceProgram: Boolean,
  },
  data() {
    return {
      modal: false,
      form: { // action: create|edit, accountId: null|Number
        action: 'create',
        accountId: null,
        account: null,
        uniqueName: '',
      },
      isLoading: false,
      trafficSourceTokens: [],
    };
  },
  computed: {
    AdAccounts() {
      return AdAccounts;
    },
    redirectAdAccount() {
      return this.redirectAdAccounts.find((account) => account.uniqueName === this.form.uniqueName);
    },
    redirectAdAccounts() {
      return [{
        uniqueName: AdAccounts.GoogleAds,
        uiName: 'Google',
        logoURL: '/sh_static/logos/trafficsources/Google_ G _Logo.svg',
      },
      {
        uniqueName: AdAccounts.AmazonDSP,
        uiName: 'Amazon',
        logoURL: '/sh_static/accounts/AmazonDSP.png',
      },
      {
        uniqueName: AdAccounts.TikTok,
        uiName: 'TikTok',
        logoURL: '/sh_static/accounts/TikTok.png',
      },
      {
        uniqueName: AdAccounts.Gemini,
        uiName: 'Yahoo',
        logoURL: '/sh_static/accounts/Gemini.png',
      },
      {
        uniqueName: AdAccounts.Facebook,
        uiName: 'Facebook',
        logoURL: '/sh_static/logos/trafficsources/facebook-white.svg',
      }];
    },
    modalClass() {
      let scrollClass = '';

      if (this.form.account?.linkedTrackers) {
        scrollClass = this.form.account.linkedTrackers.length > 5 ? 'modal-scroll' : '';
      }
      return `optimizer-modal ${scrollClass}`;
    },
  },
  async created() {
    this.trafficSourceTokens = await this.$apiStore.trafficSources?.trackingUrlTokens();
  },
  methods: {
    showModal(data = {}) {
      this.$_setForm(data);
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    async onSaveClick(event) {
      // Prevent modal from closing
      event.preventDefault();

      if (this.form.action === 'edit' && this.form.uniqueName === AdAccounts.Facebook) {
        const res = await this.onConfirmationOpen();
        const updateAllCredentials = res.isConfirmed;
        this.$refs.trafficSourceForm.form.updateAllCredentials = updateAllCredentials;
      }
      this.isLoading = true;
      try {
        const account = await this.$refs.trafficSourceForm.save();
        if (account !== false) this.modal = false;
        this.$emit('submit', account);
      } catch (error) {
        this.$emit('submit', false);
      }
      this.isLoading = false;
    },
    $_hidden() {
      this.$_setForm({}); // Reset
    },
    $_setForm({ action = 'create', accountId = null, account = null }) {
      this.form.action = action;
      this.form.accountId = accountId;
      this.form.account = account;
    },
    $_typeChange(uniqueName) {
      this.form.uniqueName = uniqueName;
    },
    onTableUpdate() {
      this.$emit('onTableUpdate');
    },
    onConfirmationOpen() {
      return this.$swal({
        title: 'Do you want to update credentials for all Facebook accounts related to this profile?',
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Update all',
        cancelButtonText: 'Only this',
        customClass: {
          container: 'confirm-delete-reporting larger box',
          confirmButton: 'primary-button',
          cancelButton: 'secondary-button',
        },
      });
    },
    onUpdateTransparencySettings() {
      this.$emit('onUpdateTransparencySettings', this.form.account);
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
#traffic-source-modal {
  .modal-scroll .modal-body {
    max-height: 80vh;
    overflow: auto;
  }

  .modal-footer:has(.info-link)  {
    padding-inline: 2rem;
    justify-content: space-between;
  }

  .facebook-btn {
    background-color: #1877f2;
    border-radius: 0.6rem;
    padding: 1rem 2rem;

    .icon-wrapper img {
      width: 1.8rem;
      height: 1.8rem;
    }

    .btn-text {
      color: $color-white;
      font-family: 'Helvetica', sans-serif;
    }
  }

  .social-btn {
    background-color: $color-white;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.084), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.168);
    border-radius: 0.6rem;
    padding: 1rem 3rem;

    .social-icon-wrapper img {
      width: 1.8rem;
      height: 1.8rem;
    }

    .btn-text {
      color: rgba(0, 0, 0, 0.54);
    }

    &:hover {
      transition: background-color 0.3s;
      background-color: $color-lighter-gray;
    }
  }
}
</style>
