import report from './report';

export default (F) => ({
  ...report(F),
  name: F.create({
    header: {
      content: 'Campaign Name',
    },
    item: {
      key: 'name',
      sortable: true,
    },
    options: {
      format: 'string',
      precision: 0,
      style: '',
    },
    colClass: 'sticky',
  }),
});
