import { RuleTypes, Tracker } from '@sh/types';

export const PublisherTrackers = {
  Bodis: Tracker.Bodis,
  Ads: Tracker.Ads,
  Sedo: Tracker.Sedo,
  System1: Tracker.System1,
  DomainActive: Tracker.DomainActive,
};

export const MetricGroupTypes = {
  Tracker: 'tr',
  TrafficSource: 'ts',
  GoogleAnalytics: 'ga',
  GoogleAnalytics4: 'ga4',
  CustomConversions: 'cc',
  SearchFeed: 'pf',
  AssertiveYield: 'ayf',
  Facebook: 'facebook',
  YahooDSP: 'yahoodsp',
  AmazonDSP: 'amazondsp',
  GoogleAds: 'googleads',
  TikTok: 'tiktok',
  Campaign: 'campaign',
  Other: 'other',
  CustomMetric: 'cm',
} as const;

export interface SupportedFieldFilter {
  tracker?: string[];
  trafficSource?: string[];
  groups?: string[];
  useInCustomMetric?: boolean;
  types?: RuleTypes.Conditions.ValueType[];
  baseTypes?: RuleTypes.Conditions.BaseFieldType[];
  fieldsForToComparison?: boolean;
  fieldsForParentCampaignCondition?: boolean;
}

export interface FieldFilter {
  trackers?: string[];
  trafficSource?: string[];
  notAvailableForToComparison?: boolean;
  notAvailableAsParentCampaignCondition?: boolean;
  useInCustomMetric?: boolean;
  availableOnLevel?: string[];
}

export interface FieldGroup extends FieldFilter {
  priority?: number;
  value: string;
  label: string;
  group?: (typeof MetricGroupTypes)[keyof typeof MetricGroupTypes];
  baseType?: RuleTypes.Conditions.BaseFieldType;
  type?: RuleTypes.Conditions.ValueType;
}

export type VariableType = 'TEXT' | 'URL' | 'FORMULA';
type BaseVariable = {
  type: VariableType;
};
type TextVariable = {
  value: string;
  type: 'TEXT';
} & BaseVariable;
type UrlVariable = {
  value: string;
  alt: string;
  type: 'URL';
} & BaseVariable;
type FormulaVariable = {
  value: string;
  type: 'FORMULA';
} & BaseVariable;

export type FieldDescription = {
  text: string;
  variables?: { [variable: string]: DescriptionVariables };
};

export type DescriptionVariables = TextVariable | UrlVariable | FormulaVariable;
export type Description = {
  default?: FieldDescription;
  overrides?: {
    [tsTrType: string]: Partial<FieldDescription>;
  };
};

export type FieldLabel = {
  default?: string;
  overrides?: {
    [tsTrType: string]: string;
  };
};

export interface Field extends FieldFilter {
  value: { behind: string; step?: string; type?: 'hour_of_day'; key?: string };
  group: (typeof MetricGroupTypes)[keyof typeof MetricGroupTypes];
  key: string;
  baseType: RuleTypes.Conditions.BaseFieldType;
  type: RuleTypes.Conditions.ValueType;
  baseModel?: RuleTypes.ConditionsV2.BaseModel;
  text: string;
  class?: string;
  disabled?: boolean;
  renameOnLevel?: string[];
  renameKey?: string;
  sourceType?: 'Tracker' | 'TrafficSource';
  label?: FieldLabel;
  description?: Description;
  tooltip?: string;
}
