
<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table adgroups-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getAdGroupsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getAdGroupsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getAdGroupsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getAdGroupsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="adgroups-wrapper"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true"
            select-label="AdGroups"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :hasComparable="true"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasComparisonColumns="true"
            :hasGroups="true"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showHeaderPopover="true"
            :updateComparisonColumns="updateComparisonColumns"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <div class="d-flex flex-grow-1">
                <vue-opti-select-light
                  :class="[ tableModel.selectedRows.length ? 'active-select' : '', 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                  :options="bulkActionOptions"
                  :unique-key="({ value: { value } }) => value"
                  label-key="content"
                  button-type="static"
                  @change="({ value }) => { $_bulkAction(value) }"
                  single
                  :disabled="$c_disableHeaderButtons"
                >
                  <template #BUTTON_PLACEHOLDER>
                    <span class="button-placehoder-static">
                      <i class="fa fa-ellipsis-h"></i>
                      <span>Actions</span>
                    </span>
                  </template>
                </vue-opti-select-light>
                <div class="col-md-auto mb-2 mb-md-0 pl-0">
                  <b-btn
                    class="secondary-button h-100"
                    block
                    v-clipboard="$_copyToClipboard('traffic_source_adgroup_id')"
                    @success="$_clipboardSuccessHandler('adgroup')"
                    @error="$_clipboardErrorHandler"
                  >
                    <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected adgroups id's to clipboard." />
                  </b-btn>
                </div>
                <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                  <i class="fa fa-spinner fa-spin" /> Processing...
                </div>
                <loadizer :loading="ready.pagination" />

                <div class="col-md-auto mb-2 mb-md-0">
                  <bulk-action
                    level="adgroup"
                    :items="tableModel.selectedRows"
                    :bulkConfig="trafficSources[$c_trafficSourceType]"
                    :bulkAction="bulkAction"
                    :failedItems="failedItems"
                    :showApplyChangesButton="showApplyChangesButton"
                    :selectedRows="tableModel.selectedRows"
                    :cancelHandler="$_cancelBulkOperation"
                    :table="table"
                    :toBeUpdatedItems="toBeUpdatedItems"
                    :clearPreview="$_clearPreviewRows"
                    :setFailedItems="$_setFailedItems"
                    :switchApplyButton="$_switchApplyButton"
                    :switchDisableActions="$_switchDisableActions"
                    :removeStyles="$_removeStyles"
                    :setItems="$_setItems"
                  />
                </div>
              </div>

              <div class="refresh-button" v-b-tooltip title="Refresh Table">
                <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
                  <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
                </b-btn>
              </div>
            </template>
            <template slot="cpc_native" slot-scope="props">
              <span v-if="!props.item.cpc_native">-
                <span
                  v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
                  class="error"
                  v-b-tooltip.hover.bottomleft
                  title="Unable to update field, the action will be skipped!"
                >
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
              </span>
              <span v-else-if="$c_isGoogleRoasCampaign">-</span>
              <span v-else-if="props.item.cpc_native === 0">Unlimited
                <span
                  v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
                  class="error"
                  v-b-tooltip.hover.bottomleft
                  title="Unable to update field, the action will be skipped!"
                >
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
              </span>
              <span v-else-if="props.item.cpc_native === -1">-
                <span
                  v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
                  class="error"
                  v-b-tooltip.hover.bottomleft
                  title="Unable to update field, the action will be skipped!"
                >
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
              </span>
              <live-edit
                v-else
                :ref="`nativeBidLiveEdit-${props.i}`"
                :key="`nativeBidLiveEdit-${props.item.id}`"
                v-model="props.item.cpc_native"
                :live-state="props.item.nativeBidLiveEditState"
                prefix="$"
                :format="$_cpcSlotNumFormat(props.field.options)"
                :suffix="$_addSuffixBid(props.item)"
                field-name="Native Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBid(props.item, value, 'cpc_native')"
                @up="$_inlineEditArrayPress('nativeBidLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('nativeBidLiveEdit', props.i, 'down')"
                :preview="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'bid', props.item.traffic_source_currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid')"
                :failedItemKey="props.item.traffic_source_adgroup_id"
              />
            </template>
            <template slot="additional_fields.targetCpmMicros" slot-scope="props">
              <span v-if="$c_isGooglePerformanceMax || $c_isGoogleRoasCampaign ">-</span>
              <span v-else-if="(props.item.additional_fields.targetCpmMicros === -1 || !props.item.additional_fields.targetCpmMicros) && account.type.uniqueName === 'GoogleAds'">-</span>
              <live-edit
                v-else
                :ref="`targetCpmLiveEdit-${props.i}`"
                :key="`targetCpmLiveEdit-${props.item.id}`"
                v-model="props.item.additional_fields.targetCpmMicros"
                :live-state="props.item.targetCpmLiveEditState"
                prefix="$"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Target Cpm"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateTargetCpm(props.item, value, 'target_cpm')"
                @up="$_inlineEditArrayPress('targetCpmLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('targetCpmLiveEdit', props.i, 'down')"
              />
            </template>
            <template v-if="$c_isGoogleRoasCampaign" slot="additional_fields.roas_bid" slot-scope="props">
              <span v-if="(props.item.additional_fields.roas_bid === -1 || !props.item.additional_fields.roas_bid) && account.type.uniqueName === 'GoogleAds'">-</span>
              <live-edit
                v-else
                :ref="`nativeBidLiveEdit-${props.i}`"
                :key="`nativeBidLiveEdit-${props.item.id}`"
                v-model="props.item.additional_fields.roas_bid"
                :live-state="props.item.nativeBidLiveEditState"
                prefix=""
                :format="$_cpcSlotNumFormat(props.field.options)"
                suffix=" %"
                field-name="Target Roas"
                :before-blur="value => $_updateBid(props.item, value, 'roas_bid')"
                @up="$_inlineEditArrayPress('nativeBidLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('nativeBidLiveEdit', props.i, 'down')"
                :preview="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'roas_bid', props.item.traffic_source_currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'roas_bid')"
                :failedItemKey="props.item.traffic_source_adgroup_id"
              />
            </template>
            <template slot="cpc_search" slot-scope="props">
              <span v-if="(props.item.cpc_search === -1 || !props.item.cpc_search) && account.type.uniqueName === 'AmazonDSP'">-</span>
              <span v-else-if="$c_isGooglePerformanceMax || $c_isGoogleRoasCampaign">-</span>
              <live-edit v-else
                         :ref="`searchBidLiveEdit-${props.i}`"
                         :key="`searchBidLiveEdit-${props.item.id}`"
                         v-model="props.item.cpc_search"
                         :live-state="props.item.searchBidLiveEditState"
                         prefix="$"
                         :format="$_cpcSlotNumFormat(props.field.options)"
                         field-name="Search Bid"
                         :max-limit-warning="maxCpcLimit"
                         :before-blur="value => $_updateBid(props.item, value, 'cpc_search')"
                         @up="$_inlineEditArrayPress('searchBidLiveEdit', props.i, 'up')"
                         @down="$_inlineEditArrayPress('searchBidLiveEdit', props.i, 'down')"
              />
            </template>
            <template slot="name" slot-scope="props">
              <a v-if="$c_shoulDrillDown" @click="() => $_goToContents(props.item.traffic_source_adgroup_id, props.item.name)" class="cell-name name-cursor"> {{ props.item.name }}</a>
              <span class="name-field" v-else>{{ props.item.name }}</span>
            </template>
            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <switch-button
                  :loading="item.statusProcessing"
                  :checked="item.enabled"
                  :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
                  :update="(value) => $_updateStatus(item, value)"
                />
                <action-btn v-if="$c_showCloneButton" type="clone" title="Clone" class="mr-2" :click="() => $_showCloneModal(item)" />
                <action-btn type="breakdown" title="Performance Breakdown" :click="() => $_showPerformanceModal(item)" />
              </div>
            </template>
            <template
              slot="additional_fields.daily_budget"
              slot-scope="props"
            >
              <span v-if="!props.item.additional_fields.daily_budget">- <span v-if="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'error', props.item.traffic_source_currency)" class="error" v-b-tooltip.hover.bottomleft title="Unable to update field, the action will be skipped!">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span></span>
              <span v-else-if="props.item.additional_fields.daily_budget === 0">Unlimited <span v-if="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'error', props.item.traffic_source_currency)" class="error" v-b-tooltip.hover.bottomleft title="Unable to update field, the action will be skipped!">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span></span>
              <span v-else-if="props.item.additional_fields.daily_budget === -1">- <span v-if="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'error', props.item.traffic_source_currency)" class="error" v-b-tooltip.hover.bottomleft title="Unable to update field, the action will be skipped!">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span></span>

              <live-edit
                v-else
                :ref="`dailyBudgetLiveEdit-${props.i}`"
                :key="`dailyBudgetLiveEdit-${props.item.id}`"
                v-model="props.item.additional_fields.daily_budget"
                :live-state="props.item.dailyBudgetLiveEditState"
                :prefix="props.item.additional_fields.daily_budget === -1 ? '' : '$'"
                :suffix="props.item.additional_fields.scheduled_budget > 0 ? ` <i class='fa fa-clock-o' title='The budget will be adjusted to $${props.item.additional_fields.scheduled_budget} starting tomorrow.'></i>` : ''"
                :format="ui.numFormat"
                field-name="Daily Budget"
                :preview="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'daily_budget', props.item.traffic_source_currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'daily_budget')"
                :failedItemKey="props.item.traffic_source_adgroup_id"
                :before-blur="value => $_updateDailyBudget(campaign,props.item, value)"
              />
            </template>
            <template
              slot="additional_fields.budget"
              slot-scope="props"
            >
              <span v-if="!props.item.additional_fields.budget">-</span>
              <span v-else-if="props.item.additional_fields.budget === 0">Unlimited</span>
              <span v-else-if="props.item.additional_fields.budget === -1">-</span>
              <live-edit
                v-else
                :ref="`budgetLiveEdit-${props.i}`"
                :key="`budgetLiveEdit-${props.item.id}`"
                :live-state="props.item.budgetLiveEditState"
                v-model="props.item.additional_fields.budget"
                :prefix="props.item.additional_fields.budget === -1 ? '' : '$'"
                :format="ui.numFormat"
                field-name="Lifetime Budget"
                :before-blur="value => $_updateBudget(campaign,props.item, value)"
              />
            </template>
            <template slot="status" slot-scope="props">
              <span
                :class="`status-item status-item-${props.item.status}`"
                v-b-tooltip.hover.right
                :title="props.item.status"
                v-html="ui.content.status(props.item.status)"
              />
            </template>
            <template slot="additional_fields.learning_phase" slot-scope="props">
              <div v-if="props.item.additional_fields.learning_phase && props.item.additional_fields.learning_phase.status" class="d-flex justify-content-center">
                <action-btn type="learningPhase" :class="`learningPhase${props.item.additional_fields.learning_phase.status}-icon`" v-b-tooltip.hover.right :title="$learningPhaseInfo(props.item.additional_fields.learning_phase)" />
              </div>
              <div v-else class="d-flex justify-content-center">
                <action-btn type="learningPhase" v-b-tooltip.hover.right title="No data available for this AdSet" />
              </div>
            </template>
            <template slot="bid_strategy" slot-scope="props">
              <bid-strategy
                v-if="props.item.additional_fields && props.item.additional_fields.bid_strategy"
                v-model="props.item.additional_fields.bid_strategy"
                :on-update="(value) => $_updateBidStrategy(value, props.item)"
              />
              <template v-else>
                -
              </template>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
    <bulk-modal
      title="Change Search Bid"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkSearchBidModal"
      :items="tableModel.selectedRows"
      :min="0.01"
      :step="0.01"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="(value, rows) => $_bulkUpdateBid(value, rows, 'cpc_search')"
    />
    <bulk-modal
      title="Change Target CPM"
      placeholder="Enter Target CPM"
      field-name="Target CPM"
      ref="bulkTargetCpmModal"
      :items="tableModel.selectedRows"
      :min="0.01"
      :step="0.01"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="(value, rows) => $_bulkUpdateTargetCpm(value, rows, 'target_cpm')"
    />
    <bulk-modal
      title="Change Target Roas"
      placeholder="Enter Target Roas"
      field-name="Target Roas"
      ref="bulkTargetRoasModal"
      :items="tableModel.selectedRows"
      :min="1"
      :step="0.01"
      right="<i class='fa fa-percent'></i>"
      :max-limit-warning="100000"
      :handler="(value, rows) => $_bulkUpdateRoas(value, rows, 'roas_bid')"
    />
    <bulk-modal
      :title="$bid_templateTitle"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkNativeBidModal"
      :items="tableModel.selectedRows"
      :min="0.01"
      :step="0.01"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="(value, rows) => $_bulkUpdateBid(value, rows, 'cpc_native')"
    />
    <bulk-modal
      title="Change Budget"
      placeholder="Enter Budget"
      field-name="Budget"
      ref="bulkBudgetModal"
      :items="tableModel.selectedRows"
      right="<i class='fa fa-dollar'></i>"
      :handler="(value, rows) => $_bulkUpdateBudget(value, rows)"
    />
    <rule-bulk-modal
      v-if="$c_trafficSourceType"
      ref="ruleBulkModal"
      :items="tableModel.selectedRows"
      :handler="(value) => $_setItemsThatWillBeUpdated(value, table.items)"
      :options="$_numericActiveColumns(table.fields, bulkAction)"
      level="adgroup"
      :bulkConfig="trafficSources[$c_trafficSourceType]"
      :bulkAction="bulkAction"
    />
    <performance-modal
      ref="performanceModal"
      :type="type"
      :trafficSource="account"
      :itemId="currentAdGroupPerformance.traffic_source_adgroup_id"
      :id="currentAdGroupPerformance.id || ''"
      :name="currentAdGroupPerformance.name || currentAdGroupPerformance.traffic_source_adgroup_id || ''"
      :timezone="timezone"
      :dateRange="dateRange"
    />
    <CloneModal v-if="campaign&&adGroupData" ref="cloneModal" :adGroupData="adGroupData" :campaign="campaign" :trafficSource="account" />
  </div>
</template>

<script>
import tableFields from '@/helpers/fields/index';
import helperMixins from './mixins/helperMixins';
import tableMixins from '@sh/mixins/table';
import config from '@/views/Campaigns/bulkOperationsConfig';
import symbols from '@sh/helpers/symbols';
import LiveEdit from '@sh/components/Utils/LiveEdit';
import ui from '@sh/helpers/ui';
import PerformanceModal from '@sh/components/Performance/PerformanceModal';
import CloneModal from '@/views/Campaign/Tabs/components/CloneModal.vue';
import moment from 'moment';
import { pluralize } from '@sh/helpers';
import RuleBulkModal from '@sh/components/Utils/RuleBulkModal.ts.vue';
import BulkAction from '@sh/components/Utils/BulkAction.ts.vue';
import { TrafficSource } from '@sh/types';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import { mapActions, mapState } from 'pinia';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import { usePresetStore } from '@/stores/presets';

export default {
  name: 'AdGroups',
  components: { LiveEdit, PerformanceModal, CloneModal, RuleBulkModal, BulkAction },
  mixins: [helperMixins, tableMixins, config, columnsMixin, presetHelper],
  props: {
    changeActiveTab: { type: Function },
    type: { type: Object },
  },
  data() {
    return {
      symbols,
      ui,
      bulkActionOptions: [
        { value: { value: false, fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Disable' },
        { value: { value: true, fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Enable' },
      ],
      optionalBulkOptions: [
        { value: { value: 'cpc_native', fn: () => this.$refs.bulkNativeBidModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Native Bid' },
        { value: { value: 'budget', fn: () => this.$refs.bulkBudgetModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Budget' },
        { value: { value: 'clone', fn: this.$_bulkCloneAdGroup }, content: '<i class=\'fa fa-copy\'></i>&nbsp; Clone' },
      ],
      currentAdGroupPerformance: {},
      adGroupData: [],
      isRefreshLoading: false,
    };
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_bulkActionOptions() {
      // Remove budget for other TS
      if (![TrafficSource.TikTok].includes(this.account.type.uniqueName)) {
        return this.$data.bulkActionOptions.filter((option) => !['budget', 'clone'].includes(option.value.value));
      }

      // return this.bulkActionOptions.map((option) => {
      // if (this.account.type.uniqueName === 'TikTok' && option.value.value === 'cpc_native') {
      //   option.content = '<i class=\'fa fa-dollar\'></i>&nbsp; Change Bid';
      // }
      //   return option;
      // });
    },
    $c_tableName() {
      return `${this.account.type.name}AdGroupsTable`;
    },
    $c_shoulDrillDown() {
      return ['Facebook', 'TikTok', 'GoogleAds'].includes(this.account.type.uniqueName);
    },
    $c_exportLabel() {
      return `${this.account.type.name}_adgroups_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $bid_templateTitle() {
      return this.account.type.uniqueName !== 'TikTok' ? 'Change Native Bid' : 'Change Bid';
    },
    $c_showCloneButton() {
      const allowedTs = ['TikTok', 'Facebook'];
      if (!this.$c_isGooglePerformanceMax) {
        allowedTs.push('GoogleAds');
      }
      return allowedTs.includes(this.account.type.uniqueName);
    },
    $c_isGooglePerformanceMax() {
      return this.campaign.advertisingChannelType && this.campaign.advertisingChannelType === 'PERFORMANCE_MAX' && this.campaign.traffic_source_unique_name === 'GoogleAds';
    },
    $c_isGoogleRoasCampaign() {
      return this.campaign.biddingStrategyType && (this.campaign.biddingStrategyType === 'Maximize Conversion Value - Target ROAS' || this.campaign.biddingStrategyType === 'Target ROAS') && this.campaign.traffic_source_unique_name === 'GoogleAds';
    },
    $c_trafficSourceType() {
      if (this.account?.type && this.$c_availableTrafficSourcesAdgroupLevel.includes(this.account.type.uniqueName)) {
        return this.account?.type?.uniqueName;
      }
      return null;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  created() {
    [TrafficSource.TikTok, TrafficSource.GoogleAds].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'change_bid_bulk',
      fn: () => {
        this.bulkAction = 'change_bid_bulk';
        this.$refs.ruleBulkModal.show();
      } },
    content: `<i class='fa fa-dollar'></i>&nbsp; ${this.trafficSources[this.$c_trafficSourceType].entities.adgroup.bulkActions.change_bid_bulk.action_name}` });
    [TrafficSource.TikTok, TrafficSource.Facebook].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'change_daily_budget_bulk',
      fn: () => {
        this.bulkAction = 'change_daily_budget_bulk';
        this.$refs.ruleBulkModal.show();
      } },
    content: `<i class='fa fa-dollar'></i>&nbsp; ${this.trafficSources[this.$c_trafficSourceType].entities.adgroup.bulkActions.change_daily_budget_bulk.action_name}` });
    [TrafficSource.Gemini, TrafficSource.AmazonDSP].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'cpc_search', fn: () => this.$refs.bulkSearchBidModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Search Bid' });
    [TrafficSource.GoogleAds].includes(this.account.type.uniqueName) && this.campaign.advertisingChannelType && this.campaign.advertisingChannelType !== 'PERFORMANCE_MAX' && this.$data.bulkActionOptions.push({ value: { value: 'cpc_search', fn: () => this.$refs.bulkSearchBidModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp;  Change CPM Bid' }, { value: { value: 'additional_fields.targetCpmMicros', fn: () => this.$refs.bulkTargetCpmModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Target CPM' }, { value: { value: 'cpc_native', fn: () => this.$refs.bulkNativeBidModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp;  Change CPC Native' }, { value: { value: 'clone', fn: this.$_bulkCloneAdGroup }, content: '<i class=\'fa fa-copy\'></i>&nbsp; Clone' });
    [TrafficSource.GoogleAds].includes(this.account.type.uniqueName) && this.campaign.biddingStrategyType && this.campaign.biddingStrategyType === 'Maximize Conversion Value - Target ROAS' && this.$data.bulkActionOptions.push({ value: { value: 'additional_fields.roas_bid', fn: () => this.$refs.bulkTargetRoasModal.show() }, content: '<i class=\'fa fa-percent\'></i>&nbsp; Change Target Roas' });
    // ['TikTok', 'Facebook'].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'cpc_native', fn: () => this.$refs.bulkNativeBidModal.show() }, content: '<i class=\'fa fa-dollar\'></i>&nbsp;  Change Bid' });
    [TrafficSource.TikTok, TrafficSource.Facebook, TrafficSource.GoogleAds].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'clone', fn: this.$_bulkCloneAdGroup }, content: '<i class=\'fa fa-copy\'></i>&nbsp; Clone' });
    [TrafficSource.TikTok, TrafficSource.Facebook].includes(this.account.type.uniqueName) && this.$data.bulkActionOptions.push({ value: { value: 'clone', fn: this.$_bulkCloneAdGroup }, content: '<i class=\'fa fa-copy\'></i>&nbsp; Clone' });
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('adGroups', this.campaign.traffic_source_unique_name);
      /** ************************************************************************* */

      try {
        await this.getFilteredPresets();
        await this.$_getAdGroupsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.debouncedGetActivityLogs = this.debounce(this.$_getAdGroupsPaginated, 1000);
    },
    async $_getAdGroupsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        typeName: this.account.type.uniqueName,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.search !== '') {
        filter.search = this.search;
      }
      let adgroups = [];
      try {
        adgroups = await this.$api.adgroups.performance(this.campaign.id, filter);
        adgroups.items = adgroups.items.map((item) => {
          item.statusProcessing = false;
          item.traffic_source_currency = this.campaign.traffic_source_currency;
          item.searchBidLiveEditState = { state: 'READY' };
          item.dailyBudgetLiveEditState = { state: 'READY' };
          item.budgetLiveEditState = { state: 'READY' };
          item.nativeBidLiveEditState = { state: 'READY' };
          item.targetCpmLiveEditState = { state: 'READY' };
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.ready.pagination = false;
      }
      if (adgroups.pagination.lastPage) {
        this.pages = adgroups.pagination.lastPage;
        this.defaultRows = adgroups.pagination.pageSize;
      }
      if (!adgroups.items.length) {
        this.page = 1;
      }
      if (Object.keys(adgroups).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('ADGROUPS', { name: 'actions' }));
        adgroups.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('ADGROUPS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        this.table.items = adgroups.items;
        if (!adgroups.totals) {
          adgroups.totals = {};
        }
        adgroups.totals.totalElements = adgroups.pagination.total;
        this.table.totals = adgroups.totals;
      }
      this.preloader = false;
      this.ready.pagination = false;
      return adgroups;
    },
    async $_bulkUpdateStatus(value) {
      const rowsId = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_adgroup_id);
          row.statusProcessing = true;
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.adgroups.changeStatus(this.campaign.id, value, rowsId),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) row.enabled = value;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_adgroup_id) > -1) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_updateStatus(item, value) {
      try {
        const result = await this.$_userActionHandler({
          request: this.$api.adgroups.changeStatus(item.campaign_id, value, [item.traffic_source_adgroup_id]),
        });
        if (result.success.length > 0) item.enabled = value;
      } catch (error) { /** * Do nothing ** */ }
    },
    async $_bulkCloneAdGroup(item, value) {
      const rowsIds = [];
      const rowsNames = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsIds.push(row.traffic_source_adgroup_id);
          rowsNames.push(row.name);
          row.statusProcessing = true;
        }
      });
      if (rowsIds.length > 0) {
        this.$_showCloneModal(this.tableModel.selectedRows);
      }
      this.table.items.forEach((row) => {
        if (rowsIds.indexOf(row.traffic_source_adgroup_id) > -1) row.statusProcessing = false;
      });
    },
    async $_cloneAdGroups(rowsIds, rowsNames) {
      const swal = await this.$swal({
        title: `Do you want to clone ${rowsNames.length} ${pluralize(rowsNames.length, 'Ad Group')}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          container: 'larger-box',
          confirmButton: 'primary-button primary-button--success',
          cancelButton: 'secondary-button',
        },
      });
      if (swal.value === true) {
        try {
          const { successMessage, errorMessage } = await this.$api.adgroups.clone(this.campaign.id, rowsIds);
          if (successMessage) {
            await this.$swal({
              title: successMessage,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                cancelButton: 'hide-cancel-btn',
              },
            });
          } else if (errorMessage) {
            await this.$swal({
              title: `${errorMessage} Please try again in a few seconds.`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                container: 'confirm-delete-swal larger box',
                confirmButton: 'primary-button primary-button--danger',
                cancelButton: 'hide-cancel-btn',
              },
            });
          }
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    async $_bulkUpdateBid(value, rows, type) {
      const rowsId = [];
      const stateKey = type === 'cpc_native' ? 'nativeBidLiveEditState' : 'searchBidLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_adgroup_id);
          row[stateKey].state = 'BUSY';
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          if (type === 'cpc_search') {
            const native_cpc = 0;
            const result = await this.$_userActionHandler({
              request: this.$api.adgroups.changeSearchBid(this.campaign.id, native_cpc, value, rowsId),
            });
            if (result.success.length > 0) {
              this.table.items.forEach((row) => {
                if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) row.cpc_search = parseFloat(value);
              });
            }
          } else if (type === 'cpc_native') {
            const search_cpc = 0;
            const result = await this.$_userActionHandler({
              request: this.$api.adgroups.changeNativeBid(this.campaign.id, value, search_cpc, rowsId),
            });
            if (result.success.length > 0) {
              this.table.items.forEach((row) => {
                if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) row.cpc_native = parseFloat(value);
              });
            }
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_adgroup_id) > -1) row[stateKey].state = 'READY';
        });
        task.finish();
      }
    },
    async $_bulkUpdateBudget(value) {
      const rowsId = [];
      const stateKey = 'budgetLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        rowsId.push(row.traffic_source_adgroup_id);
        row[stateKey].state = 'BUSY';
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.adgroups.changeAdGroupBudget(this.campaign.id, rowsId, value, 'limited'),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) {
                row.additional_fields.budget = parseFloat(value);
              }
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_adgroup_id) > -1) row[stateKey].state = 'READY';
        });
        task.finish();
      }
    },
    async $_bulkUpdateTargetCpm(value, rows, type) {
      const rowsId = [];
      const stateKey = type === 'target_cpm' ? 'targetCpmLiveEditState' : 'targetCpmLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_adgroup_id);
          row[stateKey].state = 'BUSY';
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          if (type === 'target_cpm') {
            const native_cpc = 0;
            const targetCpm = value;
            const result = await this.$_userActionHandler({
              request: this.$api.adgroups.changeTargetCpm(this.campaign.id, native_cpc, targetCpm, rowsId),
            });
            if (result.success.length > 0) {
              this.table.items.forEach((row) => {
                if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) row.additional_fields.targetCpmMicros = parseFloat(value);
              });
            }
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_adgroup_id) > -1) row[stateKey].state = 'READY';
        });
        task.finish();
      }
    },
    async $_bulkUpdateRoas(value, rows, type) {
      const rowsId = [];
      const stateKey = 'nativeBidLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_adgroup_id);
          row[stateKey].state = 'BUSY';
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          if (type === 'roas_bid') {
            const native_cpc = 0;
            const roasBid = value;
            const result = await this.$_userActionHandler({
              request: this.$api.adgroups.changeRoasBid(this.campaign.id, native_cpc, roasBid, rowsId),
            });
            if (result.success.length > 0) {
              this.table.items.forEach((row) => {
                if (result.success.indexOf(row.traffic_source_adgroup_id) > -1) row.additional_fields.roas_bid = parseFloat(value);
              });
            }
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_adgroup_id) > -1) row[stateKey].state = 'READY';
        });
        task.finish();
      }
    },
    async $_updateBid(item, value, type) {
      const task = this.userAction.task.create();
      try {
        if (type === 'cpc_search') {
          await this.$api.adgroups.changeSearchBid(this.campaign.id, item.cpc_native, value, [item.traffic_source_adgroup_id]);
          this.$n_successNotification({ title: 'Bid successfully updated' });
        } else if (type === 'cpc_native') {
          const cpc_search = ['Facebook', 'AmazonDisplay', 'AmazonDSP'].includes(this.account.type.uniqueName) ? 0 : item.cpc_search;
          await this.$api.adgroups.changeNativeBid(this.campaign.id, value, cpc_search, [item.traffic_source_adgroup_id]);
          this.$n_successNotification({ title: 'Bid successfully updated' });
        } else if (type === 'roas_bid') {
          await this.$api.adgroups.changeRoasBid(this.campaign.id, item.cpc_native, value, [item.traffic_source_adgroup_id]);
          this.$n_successNotification({ title: 'Roas Bid successfully updated' });
        }
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_updateTargetCpm(item, value, type) {
      const task = this.userAction.task.create();
      try {
        if (type === 'target_cpm') {
          await this.$api.adgroups.changeTargetCpm(this.campaign.id, item.additional_fields.targetCpmMicros, value, [item.traffic_source_adgroup_id]);
          this.$n_successNotification({ title: 'Target CPM successfully updated' });
        }
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_updateDailyBudget(campaign, adgroup, value, type = 'limited') {
      const task = this.userAction.task.create();
      try {
        const dailyBudgetType = value ? 'limited' : type;
        const response = await this.$api.adgroups.changeAdGroupDailyBudget(campaign.id, [adgroup.traffic_source_adgroup_id], value, dailyBudgetType);
        this.$n_successNotification({ title: response.message || 'Daily Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    $_addSuffixBid(item) {
      if (item.cpc_native !== null && item.traffic_source_unique_name === 'TikTok' && item.additional_fields.optimization_goal) {
        return `  ${item.additional_fields.optimization_goal}`;
      }
      return '';
    },
    async $_updateBudget(campaign, adgroup, value, type = 'limited') {
      const task = this.userAction.task.create();
      try {
        const budgetType = value ? 'limited' : type;
        const response = await this.$api.adgroups.changeAdGroupBudget(campaign.id, [adgroup.traffic_source_adgroup_id], value, budgetType);
        this.$n_successNotification({ title: response.message || 'Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'ADGROUP', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getAdGroupsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('adGroups', this.campaign.traffic_source_unique_name, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      return this.$_exportCsv({ level: 'adgroups', campaignId: this.campaign.id, filter });
    },
    $_showPerformanceModal(item) {
      this.currentAdGroupPerformance = item;
      this.$refs.performanceModal.showModal();
    },
    $learningPhaseInfo(learning_phase) {
      const status = learning_phase.status ? learning_phase.status : 'N/A';
      const lastEditTimestamp = learning_phase.last_sig_edit_ts ? moment.unix(learning_phase.last_sig_edit_ts).format('YYYY-MM-DD HH:mm') : 'N/A';
      const conversions = learning_phase.conversions ? learning_phase.conversions : 0;
      return `Status: ${status}; Last edited: ${lastEditTimestamp};  Conversions since last edit: ${conversions}`;
    },
    $_goToContents(traffic_source_adgroup_id, adgroup_name) {
      this.$router.push({
        query: {
          ...this.$route.query,
          adGroupId: traffic_source_adgroup_id,
          adGroupName: adgroup_name,
        } }).catch(() => {});
      this.changeActiveTab({ id: 'contents' });
    },
    $_showCloneModal(item) {
      if (Array.isArray(item)) {
        this.adGroupData = item;
      } else {
        this.adGroupData = [item];
      }
      this.$refs.cloneModal.showModal();
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getAdGroupsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'ADGROUP');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getAdGroupsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getAdGroupsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getAdGroupsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
  },
};
</script>

<style lang="scss">
.adgroups-wrapper {
  .name-cursor {
    cursor: pointer;
    color: #007bff!important;
  }
}
</style>
