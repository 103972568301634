<template>
  <div class="apply-rule-to-campaigns-wrapper">
    <b-form-group
      label="Apply Rule to Accounts"
      :state="$_hasError('accounts')"
      class="h-display-sm campaign-form optimizer-form-group"
    >
      <b-input-group>
        <traffic-source-account-select-light
          v-model="accounts"
          name="accounts"
          v-validate="'required'"
          data-vv-as="Accounts"
          :traffic-source-filter="ts_types"
          :archived="false"
          managed
          @input="$_emit('accounts')"
        />
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('accounts') }}</small>
      <span slot="description" v-if="cmp_config.info" class="text-info" v-html="cmp_config.info"></span>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import TrafficSourceAccountSelectLight
from '@sh/components/Utils/TrafficSourceAccountSelectLight/TrafficSourceAccountSelectLight';

export default {
  name: 'RuleAccounts',
  components: { TrafficSourceAccountSelectLight },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    filter: { type: Object, default: () => ({ trackerId: null }) },
  },
  data() {
    return {
      accounts: [],
      ts_types: [],
      cmp_config: this.ruleModel.rule.components.accounts,
    };
  },
  created() {
    this.accounts = this.value;
    this.ts_types = this.ruleModel.rule.ts_types;
    this.$_emit('accounts');
  },
};
</script>
