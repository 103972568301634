
/* eslint-disable vue/no-unused-components */
import ImageCellRenderer from '@sh/components/Utils/Table/CellRenderers/ImageCellRenderer.ts.vue';
import LoadingOverlay from '@sh/components/Utils/Table/CellRenderers/LoadingOverlay.ts.vue';
import TagsCellRenderer from '@sh/components/Utils/Table/CellRenderers/TagsCellRenderer.ts.vue';
import { selectContainer } from '@sh/components/Utils/Table/helpers';
import NoTableData from '@sh/components/Utils/Table/NoTableData.ts.vue';
import TablePagination from '@sh/components/Utils/Table/TablePagination.ts.vue';
import { ColumnDef, NoRowsOverlayComponentParams, Pagination, RowModelType } from '@sh/components/Utils/Table/types';
import {
  ColumnApi,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  PaginationChangedEvent,
  RowClickedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import { defineComponent, PropType } from 'vue';

interface ComponentData {
  gridColumnApi?: ColumnApi;
  gridApi?: GridApi;
  gridOptions?: GridOptions;
}

const GridWrapper = defineComponent({
  components: {
    AgGridVue,
    TablePagination,
    NoTableData,
    LoadingOverlay,
    TagsCellRenderer,
    ImageCellRenderer,
  },
  props: {
    isTableLoading: Boolean,
    theme: Array as PropType<string[]>,
    dataSource: Object as PropType<IServerSideDatasource>,
    columnDefs: Array as PropType<ColumnDef[]>,
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    noRowsOverlayComponentParams: Object as PropType<NoRowsOverlayComponentParams>,
    suppressCopyRowsToClipboard: Boolean,
    suppressCellFocus: Boolean,
    rowData: Array as PropType<unknown[] | undefined>,
    rowHeight: Number,
    headerHeight: Number,
    rowClassRules: Object as PropType<Record<string, Function>>,
    enableRangeSelection: {
      type: Boolean,
      default: true,
    },
    rowSelection: {
      type: String,
      default: 'multiple',
    },
    rowModelType: {
      type: String as PropType<RowModelType>,
      default: RowModelType.SERVER_SIDE,
    },
    serverSideStoreType: {
      type: String,
      default: 'partial',
    },
    noContextColumnIds: {
      type: Array as PropType<string[] | undefined>,
      default: () => [],
    },
    sortOrder: {
      type: Array as PropType<Array<string | null>>,
      default: () => ['desc', 'asc', null],
    },
    clearRangeSelection: {
      type: Boolean,
      default: true,
    },
    animateRows: {
      type: Boolean,
      default: true,
    },
    rowMultiSelectWithClick: {
      type: Boolean,
      default: true,
    },
    suppressRowClickSelection: {
      type: Boolean,
      default: true,
    },
    defaultColDef: {
      type: Object as PropType<ColumnDef>,
      default: () => ({
        sortable: true,
        resizable: true,
        enableValue: true,
        minWidth: 100,
      }),
    },
  },
  data(): ComponentData {
    return {
      gridApi: undefined,
      gridColumnApi: undefined,
      gridOptions: undefined,
    };
  },
  computed: {
    RowModelType() {
      return RowModelType;
    },
  },
  beforeMount() {
    this.gridOptions = {
      headerHeight: this.headerHeight ?? 35,
      rowHeight: this.rowHeight ?? 40,
      tooltipShowDelay: 0,
      noRowsOverlayComponent: 'NoTableData',
      loadingOverlayComponent: 'LoadingOverlay',
      onSortChanged: (params: SortChangedEvent) => this.$emit('onSortChanged', params),
    };
  },
  methods: {
    refresh(updatePagination = false) {
      if (this.dataSource) {
        if (updatePagination) {
          this.gridApi?.setServerSideDatasource(this.dataSource);
          this.gridApi?.paginationSetPageSize(this.pagination.pageSize);
        } else {
          this.gridApi?.refreshServerSide({});
        }
      }
    },
    checkSort(orderedColumns: string[]) {
      const sortModel = this.getSortState() ?? [];

      if (sortModel.find((model) => model.colId && !orderedColumns.includes(model.colId))) {
        this.clearSort();
      }
    },
    clearSort() {
      this.gridColumnApi?.applyColumnState({
        defaultState: {
          sort: null,
        },
      });
    },
    onDataUpdated() {
      this.$emit('onDataUpdated');
    },
    getSortState() {
      return this.gridColumnApi?.getColumnState().filter((column) => !!column.sort);
    },
    getContextMenuItems(params: GetContextMenuItemsParams) {
      const columnId = params.column?.getColId();
      const contextMenuItems: string[] = [];

      if (columnId && !this.noContextColumnIds?.includes(columnId)) {
        contextMenuItems.push('copy');
      }

      return contextMenuItems;
    },
    onGridReady(params: GridReadyEvent) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onPaginationChanged(event: PaginationChangedEvent) {
      this.$emit(
        'onPaginationChanged',
        event,
        this.gridApi?.paginationGetCurrentPage(),
        this.gridApi?.paginationGetPageSize()
      );
      this.gridApi?.deselectAll();

      if (this.clearRangeSelection) {
        this.gridApi?.clearRangeSelection();
      }
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi?.getSelectedRows();
      this.$emit('onSelectionChanged', selectedRows);

      if (this.clearRangeSelection) {
        this.gridApi?.clearRangeSelection();
      }
    },
    onRowClicked(params: RowClickedEvent) {
      if (this.clearRangeSelection) {
        this.gridApi?.clearRangeSelection();
      }

      if (
        (params.event?.target as HTMLDivElement)?.className.includes(selectContainer) ||
        (params.event?.target as HTMLDivElement)?.parentElement?.className.includes(selectContainer)
      ) {
        params.node.setSelected(!params.data.selected);
      } else {
        params.rowIndex = (params.rowIndex ?? 0) % this.pagination.pageSize;
        this.$emit('onTableRowClick', params);
      }
    },
  },
});

export default GridWrapper;
export type GridWrapperInstance = InstanceType<typeof GridWrapper>;
