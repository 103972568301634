<template>
  <div class="performance-wrapper">
    <preloader margin="30px" v-if="preloader" />
    <PerformanceStats
      v-else
      :id="campaign.id"
      type="campaign"
      :date-range="dateRange"
      :timezone="timezone"
    />
  </div>
</template>

<script>
import helperMixins from './mixins/helperMixins';
import PerformanceStats from '@sh/components/Performance/PerformanceStats';
// import OptimizationSettings from './performanceTabs/OptimizationSettings';

export default {
  name: 'Performance',
  components: { PerformanceStats },
  mixins: [helperMixins],
  data() {
    return {};
  },
  methods: {
    async $_init() {
      this.preloader = false;
    },
  },
};
</script>

<style lang="scss">
// .performance-wrapper {
//   &__tabs {
//     background: white;
//     border-radius: 1rem;

//     .nav {
//       padding: 1.5rem;
//       border-bottom: .1rem solid #ebebeb;

//       li {
//         border: .1rem solid #375992;

//         &:first-of-type {
//           border-top-left-radius: .6rem;
//           border-bottom-left-radius: .6rem;
//         }

//         &:last-of-type {
//           border-top-right-radius: .6rem;
//           border-bottom-right-radius: .6rem;
//         }

//         .nav-link {
//           color: $black-700;
//           border-radius: 0;
//           font-size: 1.4rem;
//           padding: .6rem 2rem;

//           &.active {
//             color: #FFFFFF;
//             background: #114fb9;
//           }
//         }
//       }
//     }

//     .system-activity-table {
//       .stickyFooter {
//         bottom: 6.1rem !important;
//       }

//       .row.header,
//       .row.footer {
//         // padding: 0rem;
//         margin-bottom: 0;
//         padding-bottom: 1.5rem;
//         margin-left: 0;
//         margin-right: 0;
//       }

//       .row.header {
//         padding-bottom: 2rem;
//         border-bottom: .1rem solid #ebebeb;
//       }

//       .row.footer {
//         padding-left: .3rem;
//         margin-left: -.5rem;

//         .optimizer-select {
//           padding-left: 0;
//         }
//       }
//     }
//   }

//   .tab-content {
//     // overflow-x: auto;
//     // min-height: 270px;
//   }
// }
</style>
