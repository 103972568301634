<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div :class="`optimizer-table contents-table mt-4 ${$route.params.type}`">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getContentsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getContentsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getContentsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getContentsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="contents-wrapper"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true"
            select-label="Contents"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasComparisonColumns="true"
            :updateComparisonColumns="updateComparisonColumns"
            :hasGroups="true"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            :showHeaderPopover="true"
            sticky
            focusSelectedRows
          >
            <template #export="{xlsDownloadLoading, csvDownloadLoadig, downloadCsv, downloadXls}">
              <b-btn class="ml-4" :disabled="csvDownloadLoadig" @click="downloadCsv"><i v-if="csvDownloadLoadig" class="fa fa-spinner fa-spin mr-2"> </i> Download CSV</b-btn>
              <div>
                <b-dropdown menu-class="optimizer-nav-dropdown-menu" class="download-xls ml-2" :disabled="xlsDownloadLoading || downloading" split @click="downloadXls">
                  <template #button-content>
                    <i v-if="xlsDownloadLoading || downloading" class="fa fa-spinner fa-spin mr-2" /> Download Excel
                  </template>
                  <b-dropdown-item @click="$_saveExcel">Download Xls with Images</b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template slot="search">
              <div class="d-flex flex-grow-1">
                <vue-opti-select-light
                  :class="[ { 'active-select': tableModel.selectedRows.length }, 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                  :options="$c_bulkActionOptions"
                  :unique-key="({ value: { value } }) => value"
                  button-type="static"
                  @change="({ value }) => { $_bulkAction(value) }"
                  single
                  :disabled="$c_disableHeaderButtons"
                >
                  <template #BUTTON_PLACEHOLDER>
                    <span class="button-placehoder-static">
                      <i class="fa fa-ellipsis-h"></i>
                      <span>Actions</span>
                    </span>
                  </template>
                </vue-opti-select-light>

                <CampaignsFilters
                  :status-options="statusOptions"
                  @on-filter-changed="onFiltersChange"
                  tags-level="content"
                  :ts-type="account.type.uniqueName"
                  :show-managed-status-tab="false"
                />

                <b-btn
                  class="secondary-button mr-2"
                  v-clipboard="$_copyToClipboard('traffic_source_content_id')"
                  @success="$_clipboardSuccessHandler('content')"
                  @error="$_clipboardErrorHandler"
                >
                  <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected contents id's to clipboard." />
                </b-btn>
                <div v-if="$c_adGroup.adGroupName" class="filter-tag mr-2">
                  <span class="filter-tag__content" v-b-tooltip.hover :title="$c_adGroup.adGroupName">{{ $c_adGroup.adGroupTabName }}: {{ $c_adGroup.adGroupName }}</span>
                  <span @click="$_removeAdgroupFilter"><optimizer-icon type="trackerClose" /></span>
                </div>

                <b-btn v-if="$c_showAddNewContent" class="secondary-button" :to="{name: 'ContentUploader', query:{campaign_id: campaign.id, account_id: account.id, target_url: $c_targetUrl, site_name: $c_siteName, type: type.name }}">
                  <i class="fa fa-plus" /> Upload New {{ type.name }}
                </b-btn>

                <div class="col-md-auto mb-2 mb-md-0">
                  <bulk-action
                    level="content"
                    :items="tableModel.selectedRows"
                    :bulkConfig="trafficSources[$c_trafficSourceType]"
                    :bulkAction="bulkAction"
                    :failedItems="failedItems"
                    :showApplyChangesButton="showApplyChangesButton"
                    :selectedRows="tableModel.selectedRows"
                    :cancelHandler="$_cancelBulkOperation"
                    :table="table"
                    :toBeUpdatedItems="toBeUpdatedItems"
                    :clearPreview="$_clearPreviewRows"
                    :setFailedItems="$_setFailedItems"
                    :switchApplyButton="$_switchApplyButton"
                    :removeStyles="$_removeStyles"
                    :setItems="$_setItems"
                    :switchDisableActions="$_switchDisableActions"
                  />
                </div>

                <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                  <i class="fa fa-spinner fa-spin" /> Processing...
                </div>

                <loadizer :loading="ready.pagination" />
              </div>

              <div class="refresh-button" v-b-tooltip title="Refresh Table">
                <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
                  <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
                </b-btn>
              </div>
            </template>
            <template slot="cpc" slot-scope="props">
              <live-edit
                :ref="`bidLiveEdit-${props.i}`"
                :key="`bidLiveEdit-${props.item.id}`"
                v-model="props.item.cpc"
                :live-state="props.item.bidLiveEditState"
                :prefix="symbols.get(props.item.traffic_source_currency)"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBid(props.item, value)"
                @up="$_inlineEditArrayPress('bidLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('bidLiveEdit', props.i, 'down')"
                :preview="$_findPreviewRow(props.item.traffic_source_content_id, 'bid', props.item.traffic_source_currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid')"
                :failedItemKey="props.item.traffic_source_content_id"
              />
            </template>
            <template slot="additional_fields.quality_ranking" slot-scope="props">
              <div style="display: flex; justify-content:center;">
                <b-progress style="width: 70%;" :value="props.item.additional_fields.quality_ranking"
                            v-b-tooltip.hover.right
                            :title="`Your ad is performing better then ${props.item.additional_fields.quality_ranking || 0}% of your competitors`"
                            :variant="`${props.item.additional_fields.quality_ranking <25 ?'info': props.item.additional_fields.quality_ranking < 50 ? 'warning' : props.item.additional_fields.quality_ranking <70 ? 'info':'success'}`" :max="100"
                />
              </div>
            </template>
            <template slot="additional_fields.conversion_ranking" slot-scope="props">
              <div style="display: flex; justify-content:center;">
                <b-progress style="width: 70%;" :value="props.item.additional_fields.conversion_ranking"
                            v-b-tooltip.hover.right
                            :title="`Your ad conversion is performing better then ${props.item.additional_fields.conversion_ranking || 0 }% of your competitors`"
                            :variant="`${props.item.additional_fields.conversion_ranking <25 ?'info': props.item.additional_fields.conversion_ranking < 50 ? 'warning' : props.item.additional_fields.conversion_ranking <70 ? 'info':'success'}`" :max="100"
                />
              </div>
            </template>
            <template #tags="{ item }">
              <TagsList
                :isRemoveIconVisible="true"
                :tags="item?.tags"
                @enableTagManagement="singleTagClicked(item)"
                @deleteTag="tag => handleSingleTagDelete(item, tag)"
                @tagClicked="singleTagClicked(item)"
              />
            </template>

            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <switch-button
                  :loading="item.statusProcessing"
                  :checked="item.enabled"
                  :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
                  :update="(value) => $_updateStatus(item, value)"
                />
                <action-btn type="performance" title="Performance Breakdown" :click="() => $_showPerformanceModal(item)" />
                <action-btn v-if="$c_showCloneButton" type="clone" title="Clone" class="ml-2" :click="() => $_showCloneModal(item)" />
              </div>
            </template>
            <template slot="content_preview" slot-scope="props">
              <a class="content-preview-anchor" @click="onPreviewOpen(props.item)">
                <div class="content-preview pointer" v-b-tooltip.hover.top :title="getTitle(props.item)">
                  <div class="content-image pull-left">
                    <a v-if="isVideoUrl(props.item.image_url)">
                      <img width="200" height="60" :src="ui.table.urlFix(props.item.additional_fields.video_url)" @error="onVideoPreviewError">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="black" fill-opacity="0.51" />
                        <path d="M15.25 9.56699C15.5833 9.75944 15.5833 10.2406 15.25 10.433L7.75 14.7631C7.41667 14.9556 7 14.715 7 14.3301L7 5.66987C7 5.28497 7.41667 5.04441 7.75 5.23686L15.25 9.56699Z" fill="white" />
                      </svg>
                    </a>
                    <img v-else-if="props.item.image_url" :src="ui.table.urlFix(props.item.image_url)" @error="onImageError">
                    <img :src="props.item.image_base64" @error="onImageError">
                  </div>
                  <div class="content-text pull-left">
                    <b class="brandname d-block">{{ props.item.headline }} </b>
                  </div>
                </div>
                <TransparencyProgramInfo v-if="transparencyProgram[props.item.id].isActive" :id="props.item.id" :email="transparencyProgram[props.item.id].email" :searchFeed="transparencyProgram[props.item.id].searchFeed" />
              </a>
              <a
                v-if="props.item.url || props.item.image_url"
                target="_blank"
                :href="props.item.url || props.item.image_url"
                class="link pt-2 pr-2"
              >
                <OptimizerIcon class="external-icon" type="externalLink" />
              </a>
            </template>
            <template slot="status" slot-scope="props">
              <span
                :class="`status-item status-item-${props.item.status}`"
                v-b-tooltip.hover.right
                :title="props.item.status"
                v-html="ui.content.status(props.item.status)"
              />
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
    <bulk-modal
      title="Change Bid"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkModal"
      :items="tableModel.selectedRows"
      :min="0.01"
      :step="0.01"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="$_bulkUpdateBid"
    />

    <rule-bulk-modal
      v-if="$c_trafficSourceType"
      ref="ruleBulkModal"
      :items="tableModel.selectedRows"
      :handler="(value) => $_setItemsThatWillBeUpdated(value, table.items)"
      :options="$_numericActiveColumns(table.fields, bulkAction)"
      level="content"
      :bulkConfig="trafficSources[$c_trafficSourceType]"
      :bulkAction="bulkAction"
    />

    <performance-modal
      ref="performanceModal"
      :type="type"
      :itemId="String(currentContentPerformance.traffic_source_content_id)"
      :id="currentContentPerformance.id || ''"
      :name="currentContentPerformance.headline"
      :timezone="timezone"
      :dateRange="dateRange"
    />
    <ContentPreview
      v-if="previewContent"
      :isOpen.sync="isContentPreview"
      :previewContent="previewContent"
      :trafficSource="campaign.traffic_source_unique_name"
      :trafficSourceAccountId="campaign.traffic_source_account_id"
    />
    <CloneModal ref="cloneModal" :campaign="campaign" :contentData="contentData" :trafficSource="account" />
    <ComplianceModal ref="complianceModal" :campaignId="campaign.id" :entities="tableModel.selectedRows" @onNotification="onNotification" />
    <BulkTagInsertModal
      ref="bulkContentRef"
      title="Add Tag"
      mode="content"
      :autocompleteItems="tagAutocompleteItems || []"
      @onTagsSave="handleOnSaveTags"
      @onBulkTagInsert="onBulkTagModalClosed"
    />
  </div>
</template>

<script>
import PerformanceModal from '@sh/components/Performance/PerformanceModal';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import RuleBulkModal from '@sh/components/Utils/RuleBulkModal.ts.vue';
import tableFields from '@/helpers/fields/index';
import bulkOperationConfig from '@/views/Campaigns/bulkOperationsConfig';
import symbols from '@sh/helpers/symbols';
import tableMixins from '@sh/mixins/table';
import excelBuilder from '@sh/mixins/excelBuilder';
import helperMixins from '@/views/Campaign/Tabs/mixins/helperMixins';
import ContentPreview from '@sh/components/Utils/ContentPreview/Index.ts.vue';
import CloneModal from '@/views/Campaign/Tabs/components/CloneModal.vue';
import ComplianceModal from '@sh/views/Campaign/components/ComplianceModal.ts.vue';
import config from '@/views/Campaign/config';
import { canAccess, onImageError, pluralize, timeout } from '@sh/helpers';
import { useAppStore } from '@/stores';
import { mapActions, mapState, mapWritableState } from 'pinia';
import preview from '@sh/mixins/preview';
import BulkAction from '@sh/components/Utils/BulkAction.ts.vue';
import { TrafficSource } from '@sh/types';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import { usePresetStore } from '@/stores/presets';
import TransparencyProgramInfo from '@sh/components/Utils/TransparencyProgramInfo.ts.vue';
import BulkTagInsertModal from '@sh/components/MediaManager/BulkTagInsertModal/BulkTagInsertModal.ts.vue';
import { useCampaignsStore } from '@/stores/campaigns-store';
import TagsList from '@sh/components/MediaManager/TagsContainer/TagsList.ts.vue';
import { uniq } from 'lodash';
import CampaignsFilters from '../../Campaigns/components/CampaignsFilters.ts.vue';

export default {
  name: 'Contents',
  components: { TagsList, BulkTagInsertModal, CloneModal, PerformanceModal, OptimizerIcon, ContentPreview, RuleBulkModal, BulkAction, TransparencyProgramInfo, ComplianceModal, CampaignsFilters },
  mixins: [helperMixins, tableMixins, excelBuilder, preview, bulkOperationConfig, columnsMixin, presetHelper],
  props: {
    type: { type: Object },
  },
  data() {
    return {
      statusFilter: [],
      statusOptions: [
        { value: { enabled: false, status: 'PAUSED' }, content: 'PAUSED', group: 'status' },
        { value: { enabled: true, status: 'RUNNING' }, content: 'RUNNING', group: 'status' },
        { value: { status: 'APPROVED' }, content: 'APPROVED', group: 'status' },
        { value: { status: 'PENDING' }, content: 'PENDING', group: 'status' },
        { value: { status: 'REJECTED' }, content: 'REJECTED', group: 'status' },
        { value: { status: 'ARCHIVED' }, content: 'ARCHIVED', group: 'status' },
        { value: { status: 'OTHER' }, content: 'OTHER', group: 'status' },
      ],
      downloading: false,
      contents: [],
      symbols,
      isContentPreview: false,
      currentContentPerformance: {},
      contentData: [{}],
      canAccessComplianceProgram: false,
      isRefreshLoading: false,
      accounts: [],
    };
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_tableName() {
      return `${this.account.type.name}ContentsTable`;
    },
    $c_exportLabel() {
      return `${this.account.type.name}_contents_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $c_targetUrl() {
      return this.table.items[0] ? this.table.items[0].url : '';
    },
    $c_siteName() {
      try {
        return this.table.items[0].additional_fields.site_name || '';
      } catch (err) {
        return '';
      }
    },
    $c_showAddNewContent() {
      return ![TrafficSource.Facebook, TrafficSource.AmazonDSP, TrafficSource.AmazonDisplay, TrafficSource.TikTok, TrafficSource.Runative, TrafficSource.ActiveRevenue, TrafficSource.MediaGo, TrafficSource.GoogleAds].includes(this.account.type.uniqueName);
    },
    $c_bulkActionOptions() {
      const options = [];
      if ([TrafficSource.Facebook, TrafficSource.TikTok].indexOf(this.account.type.uniqueName) > -1) {
        options.push({ value: { value: 'cloneContent', fn: this.$_bulkCloneContent }, content: '<i class=\'fa fa-copy\'></i>&nbsp; Clone ' });
      }
      if ([TrafficSource.Adskeeper, TrafficSource.Mgid, TrafficSource.Outbrain].indexOf(this.account.type.uniqueName) > -1) {
        const { action_name } = this.trafficSources[this.$c_trafficSourceType].entities.content.bulkActions.change_bid_bulk;
        options.push({ value: { value: 'change bid bulk',
          fn: () => {
            this.bulkAction = 'change_bid_bulk';
            this.$refs.ruleBulkModal.show();
          } },
        content: `<i class='fa fa-dollar'></i>&nbsp; ${action_name} ` });
      }
      options.push(
        { value: { value: 'tags', fn: this.bulkTagModalOpen }, content: '<i class=\'fa fa-hashtag\'></i>&nbsp; Add Tags' },
        { value: { value: true, fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Enable' },
        { value: { value: false, fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-stop\'></i>&nbsp; Disable' },
        { value: { value: 'archive', fn: this.$_bulkArchive }, content: '<span class="outline-fa-icon dark"><i class="fa fa-lock"></i></span>&nbsp; Archive' },
        { value: { value: 'unarchive', fn: this.$_bulkArchive }, content: '<span class="outline-fa-icon dark"><i class="fa fa-unlock"></i></span>&nbsp; Un-Archive' },
      );
      if (this.canAccessComplianceProgram) {
        options.push(
          { value: { value: 'allow_compliance', fn: this.$_complianceModal }, content: '<span class="outline-fa-icon dark"><i class="fa fa-plus"></i></span>&nbsp; Share with Transparency Program' },
          { value: { value: 'remove_compliance', fn: this.$_complianceModal }, content: '<span class="outline-fa-icon dark"><i class="fa fa-times"></i></span>&nbsp; Remove from Transparency Program' },
        );
      }
      return options;
    },
    $c_adGroup() {
      const { adGroupName, adGroupId } = this.$route.query;
      const adGroupTabName = config.getAdGroupTabName(this.account.type.uniqueName);
      return { adGroupName, adGroupTabName, adGroupId };
    },
    $c_showCloneButton() {
      const allowedAccountTypes = ['Facebook', 'TikTok', 'GoogleAds'];
      return allowedAccountTypes.includes(this.account.type.uniqueName);
    },
    $c_trafficSourceType() {
      if (this.account?.type && this.$c_availableTrafficSourcesContentLevel.includes(this.account.type.uniqueName)) {
        return this.account?.type?.uniqueName;
      }
      return null;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    transparencyProgram() {
      return this.table.items?.reduce((acc, item) => {
        if (item.id) {
          acc[item.id] = this.getTransparencyProgram(item);
        }
        return acc;
      }, {}) ?? {};
    },
    ...mapWritableState(useAppStore, ['contentsFilter']),
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
    ...mapWritableState(useCampaignsStore, ['campaignFilters']),
    ...mapState(useCampaignsStore, ['tagAutocompleteItems', 'getAllTags', 'filterState']),
  },
  created() {
    this.initialFilters();
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    singleTagClicked(item) {
      this.tableModel.selectedRows = [item];
      this.$refs.bulkContentRef.handleOnOpenModal([item]);
    },
    bulkTagModalOpen() {
      this.$refs.bulkContentRef.handleOnOpenModal(this.tableModel.selectedRows);
    },
    onBulkTagModalClosed() {
      if (this.tableModel.selectedRows.length === 1) {
        this.tableModel.selectedRows = [];
      }
    },
    getContentTagEditPayload(tags, selectedContentIds, action) {
      return {
        contentIds: selectedContentIds,
        tags,
        tsType: this.account.type.uniqueName,
        action,
      };
    },
    async handleOnSaveTags(data) {
      const addedTagsLength = data.addedTags.tags.length;
      const selectedContentIds = this.tableModel.selectedRows.map((el) => el.traffic_source_content_id);
      try {
        const result = [];
        if (data.deletedTags.tags.length > 0) {
          const payload = this.getContentTagEditPayload(data.deletedTags.tags, selectedContentIds, 'remove');
          const removeTags = await this.$api.campaigns.updateContentTags(this.campaign.campaign_id, payload);

          await new Promise((resolve) => setTimeout(resolve, timeout));
          result.push(removeTags);
        }

        if (addedTagsLength > 0) {
          const payload = this.getContentTagEditPayload(uniq(data.addedTags.tags), selectedContentIds, 'add');
          const addTags = await this.$api.campaigns.updateContentTags(this.campaign.campaign_id, payload);

          result.length === 0 && this.addNewTags(uniq(data.addedTags.tags));
          result.push(addTags);
        }
        await this.onRefreshClick();
        let updatedMessage = `Tags were ${addedTagsLength ? 'added' : 'deleted'} successfully!`;
        if (result.length === 2) {
          await this.getAllAvailableTagsForCampaign('content', this.account.type.uniqueName);
          updatedMessage = 'Tags were successfully updated!';
        }
        if (result.length) {
          this.$n_successNotification({ title: updatedMessage });
        }
      } catch (e) {
        this.$n_failNotification({ title: `Failed to ${addedTagsLength ? 'add' : 'delete'} tags!` });
      }
    },
    async handleSingleTagDelete(item, tag) {
      const confirmModal = await this.$swal({
        title: `Delete '${tag}' tag?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        allowEnterKey: false,
        customClass: {
          container: 'confirm-delete-swal',
          confirmButton: 'primary-button primary-button--danger',
          cancelButton: 'secondary-button',
        },
      });

      if (confirmModal.value === true) {
        try {
          const payload = this.getContentTagEditPayload([tag], [item.id], 'remove');
          await this.$api.campaigns.updateContentTags(this.campaign.campaign_id, {
            ...payload,
            contentIds: [item.traffic_source_content_id],
          });
          await this.onRefreshClick();
          this.getAllAvailableTagsForCampaign('content', this.account.type.uniqueName);
          this.$n_successNotification({ title: 'Tags were deleted successfully!' });
        } catch (error) {
          this.$n_failNotification({ title: 'Failed to delete tags!' });
        }
      }
    },
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      this.statusFilter = this.contentsFilter;
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('contents', this.campaign.traffic_source_unique_name);
      this.canAccessComplianceProgram = await canAccess('ComlianceProgram');
      this.accounts = await this.$apiStore.trafficSources.accounts();

      try {
        await this.getFilteredPresets();
        await this.$_getContentsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.debouncedGetActivityLogs = this.debounce(this.$_getContentsPaginated, 1000);
      // api call for tags on campaign-store
      this.getAllAvailableTagsForCampaign('content', this.account.type.uniqueName);
    },
    async $_getContentsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        typeName: this.account.type.uniqueName,
        tags: this.filterState.filterSession.tags,
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        status: [],
        enabled: [],
        archived: null,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.$c_adGroup.adGroupId) {
        filter.search = this.$c_adGroup.adGroupId;
      } else if (this.search !== '') {
        filter.search = this.search;
      }
      if (this.statusFilter.length > 0) {
        this.statusFilter
          .forEach((selected) => {
            if (selected.value.status) {
              if (['ARCHIVED', 'UNARCHIVED'].includes(selected.value.status)) {
                filter.archived = selected.value.status === 'ARCHIVED';
              } else {
                filter.status.push(selected.value.status);
              }
            }
            if (selected.value.enabled !== undefined) {
              filter.enabled.push(selected.value.enabled);
            }
          });
      }
      if (!filter.status.length) delete filter.status;
      if (!filter.enabled.length) delete filter.enabled;
      if (filter.archived === null) delete filter.archived;
      let contents = [];
      try {
        contents = await this.$api.contents.performance(this.campaign.id, filter);
        contents.items = contents.items.map((content) => {
          if (this.campaign.traffic_source_unique_name === 'GoogleAds') {
            content.headline = `${content.headline.slice(0, 60)}...`;
            content.brand_name = `${content.brand_name.slice(0, 65)}...`;
          }
          Object.assign(content, { traffic_source_currency: this.campaign.traffic_source_currency });
          return content;
        });

        // Save ads filters on store
        this.campaignFilters = {
          status: filter.status ?? [],
          archived: filter.archived ?? false,
        };
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.ready.pagination = false;
      }
      if (contents.pagination.lastPage) {
        this.pages = contents.pagination.lastPage;
        this.defaultRows = contents.pagination.pageSize;
      }
      if (!contents.items.length) {
        this.page = 1;
      }
      if (Object.keys(contents).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('CONTENTS', { name: 'actions' }));
        contents.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('CONTENTS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        this.contents = contents.items.map((item) => {
          if (!['Outbrain', 'VoluumDSP', 'Facebook', 'AmazonDSP', 'GoogleAds'].includes(this.campaign.traffic_source_unique_name)) item.traffic_source_content_id = parseInt(item.traffic_source_content_id, 10);
          item.statusProcessing = false;
          item.bidLiveEditState = { state: 'READY' };
          /** When no tags are saved for a content, the first element of item.tags is an array with an empty string([""]), so assign an empty array instead */
          item.tags = !(item.tags[0]) ? [] : item.tags;
          return item;
        });
        this.table.items = contents.items;
        contents.totals.totalElements = contents.pagination.total;
        this.table.totals = contents.totals;
      }
      this.preloader = false;
      this.ready.pagination = false;

      return contents;
    },
    async $_bulkUpdateStatus(value) {
      const rowsId = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(row.traffic_source_content_id);
          row.statusProcessing = true;
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.contents.changeStatus(this.campaign.id, value, rowsId.map((id) => id.toString())),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(`${row.traffic_source_content_id}`) > -1) row.enabled = value;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_content_id) > -1) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_cloneContents(rowsId, rowsName) {
      const swal = await this.$swal({
        title: `Do you want to clone ${rowsName.length} ${pluralize(rowsName.length, 'Ad')}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          container: 'larger-box',
          confirmButton: 'primary-button primary-button--success',
          cancelButton: 'secondary-button',
        },
      });
      if (swal.value === true) {
        try {
          const { successMessage, errorMessage } = await this.$api.contents.clone(this.campaign.id, rowsId.map((id) => id.toString()));
          if (successMessage) {
            await this.$swal({
              title: successMessage.replace('Contents', 'Ads'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                cancelButton: 'hide-cancel-btn',
              },
            });
          } else if (errorMessage) {
            await this.$swal({
              title: `${errorMessage} Please try again in a few seconds.`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                container: 'confirm-delete-swal larger box',
                confirmButton: 'primary-button primary-button--danger',
                cancelButton: 'hide-cancel-btn',
              },
            });
          }
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    async $_bulkCloneContent() {
      const rowsId = [];
      const rowsName = [];
      this.tableModel.selectedRows.forEach((row) => {
        rowsId.push(row.traffic_source_content_id);
        rowsName.push(row.headline);
        row.statusProcessing = true;
      });
      if (rowsId.length > 0) {
        this.$_showCloneModal(this.tableModel.selectedRows);
      }
      this.table.items.forEach((row) => {
        if (rowsId.indexOf(row.traffic_source_content_id) > -1) row.statusProcessing = false;
      });
    },
    async $_updateStatus(item, value) {
      try {
        const result = await this.$_userActionHandler({
          request: this.$api.contents.changeStatus(item.campaign_id, value, [item.traffic_source_content_id.toString()]),
        });
        if (result.success.length > 0) item.enabled = value;
      } catch (error) { /** * Do nothing ** */ }
    },
    getTransparencyProgram(item) {
      const getData = (isActive = false, email = '', searchFeed = '') => ({
        isActive: !!isActive,
        email,
        searchFeed,
      });
      const { allow_compliance: isActive, revenue_streaming_sharing_compliance: email, compliance_revenue_stream_type: searchFeed } = item;
      const account = this.accounts.find((account) => account.id === this.campaign.traffic_source_account_id);

      const creativeCompliance = getData(isActive !== '0', email, searchFeed);
      const campaignCompliance = getData(this.campaign.allow_compliance !== '0', this.campaign.revenue_streaming_sharing_compliance, this.campaign.compliance_revenue_stream_type);
      const accountCompliance = getData(account?.settings?.complianceProgram?.is_complianced !== false, account?.settings?.complianceProgram?.email, account?.settings?.complianceProgram?.type);

      if (creativeCompliance.isActive && campaignCompliance.isActive !== false && accountCompliance.isActive !== false && creativeCompliance.email) {
        return creativeCompliance;
      }

      if (campaignCompliance.isActive && accountCompliance.isActive !== false && campaignCompliance.email) {
        return campaignCompliance;
      }

      if (accountCompliance.isActive && accountCompliance.email) {
        return accountCompliance;
      }

      return getData();
    },
    async $_bulkArchive(action) {
      if (this.tableModel.selectedRows.length > 0) {
        const task = this.userAction.task.create();
        try {
          if (action === 'archive' || action === 'unarchive') {
            const archived = action === 'archive';
            const selectedIds = this.tableModel.selectedRows.filter((row) => row.archived !== archived)
              .map((row) => row.id);
            if (selectedIds.length > 0) {
              const result = await this.$api.contents.changeArchive(this.campaign.id, archived, selectedIds);
              this.table.items.forEach((row) => {
                if (selectedIds.indexOf(row.id) > -1) row.archived = archived;
              });
              this.$n_successNotification({ title: result.message || 'Contents successfully updated' });
            }
          }
        } catch (error) {
          const title = error?.response?.data?.message ?? 'An error occurred';
          this.$n_failNotification({ title });
        }
        task.finish();
      }
    },
    async $_complianceModal(action) {
      if (this.tableModel.selectedRows.length > 0) {
        this.$refs.complianceModal.entities = this.tableModel.selectedRows;

        if (action === 'allow_compliance') {
          this.$refs.complianceModal.show('allow', 'content');
        } else {
          this.$refs.complianceModal.show('remove', 'content');
        }
      } else {
        this.$n_failNotification({ title: 'Please select at least one content' });
      }
    },
    async $_bulkUpdateBid(value) {
      const rowsId = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.enabled !== value) {
          rowsId.push(`${row.traffic_source_content_id}`);
          row.bidLiveEditState.state = 'BUSY';
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.contents.changeBid(this.campaign.id, value, rowsId.map((id) => id.toString())),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(`${row.traffic_source_content_id}`) > -1) row.cpc = parseFloat(value);
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(`${row.traffic_source_content_id}`) > -1) row.bidLiveEditState.state = 'READY';
        });
        task.finish();
      }
    },
    async $_updateBid(item, value) {
      const task = this.userAction.task.create();
      try {
        await this.$api.contents.changeBid(this.campaign.id, value, [item.traffic_source_content_id.toString()]);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'CONTENT', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getContentsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('contents', this.campaign.traffic_source_unique_name, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.$c_adGroup.adGroupId) {
        filter.search = this.$c_adGroup.adGroupId;
      }

      if (this.statusFilter !== 'ALL') {
        if (this.statusFilter.enabled !== undefined) {
          filter.enabled = this.statusFilter.enabled;
        } else {
          filter.status = this.statusFilter.status;
        }
      }
      return this.$_exportCsv({ level: 'contents', campaignId: this.campaign.id, filter });
    },
    $_showPerformanceModal(item) {
      this.currentContentPerformance = item;
      this.$refs.performanceModal.showModal();
    },
    $_showCloneModal(item) {
      if (Array.isArray(item)) {
        this.contentData = item;
      } else {
        this.contentData = [item];
      }
      this.$refs.cloneModal.showModal();
    },
    async $_saveExcel() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.$c_adGroup.adGroupId) {
        filter.search = this.$c_adGroup.adGroupId;
      }

      if (this.statusFilter !== 'ALL') {
        if (this.statusFilter.enabled !== undefined) {
          filter.enabled = this.statusFilter.enabled;
        } else {
          filter.status = this.statusFilter.status;
        }
      }
      try {
        this.downloading = true;
        await this.$createWorkbook({ level: 'contents', campaignId: this.campaign.id, filter, fileName: this.$c_exportLabel });
        this.downloading = false;
      } catch (error) {
        console.log(error);
        this.$n_failNotification({ title: 'Download error' });
        this.downloading = false;
      }
    },
    async $_removeAdgroupFilter() {
      const { query: { adGroupId, adGroupName, ...query } } = this.$route;
      this.$router.replace({ query });
      await this.$_getContentsPaginated();
    },
    onImageError,
    onVideoPreviewError(event) {
      const eventTarget = event.target;

      if (eventTarget?.dataset && !eventTarget.dataset.image_error) {
        eventTarget.dataset.image_error = 'true';
        eventTarget.src = '/sh_static/img/nopreview.svg';
      }
    },
    onFiltersChange() {
      const statusFilterValues = [
        ...this.filterState.filterSession.status,
        ...this.filterState.filterSession.managed,
        ...this.filterState.filterSession.archived,
      ];

      this.contentsFilter = statusFilterValues;
      this.statusFilter = statusFilterValues;
      this.$_getContentsPaginated();
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getContentsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    getTitle(item) {
      if (item.hasVideoError) {
        return '';
      }
      return 'Click to preview';
    },
    onNotification(title, type, metadata) {
      switch (type) {
        case 'success':
          this.$n_successNotification({ title });

          if (metadata) {
            this.table.items.forEach((item) => {
              if (this.selectedCampaigns.find((selected) => selected.id === item.id)) {
                item.allow_compliance = metadata?.action === 'allow' || '0';
                item.revenue_streaming_sharing_compliance = metadata.email;
                item.compliance_revenue_stream_type = metadata.type;
              }
            });
          }
          break;
        case 'error':
          this.$n_failNotification({ title });
          break;
        default:
          this.$n_infoNotification({ title });
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'CONTENT');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getContentsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getContentsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getContentsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
    ...mapActions(useCampaignsStore, ['getAllAvailableTagsForCampaign', 'addNewTags', 'initialFilters', 'onBulkTagInsert']),
  },
};
</script>

<style lang="scss">
.contents-wrapper {
  .content-preview-anchor {
    .content-preview {
      display: flex;
      max-width: 330px;
      white-space: normal;
      .content-image {
        margin-right: 10px;
        position: relative;
        display: flex;
        align-items: center;

        a { cursor: pointer; }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          cursor: pointer;
          max-height: 75px;
          max-width: 100px;
          object-fit: cover;
        }
        .hide-video-play-icon {
          display: none;
        }
        .show-video-play-icon {
          display: block;
        }
      }

      .content-text {
        text-overflow: ellipsis;
        width: 200px;

        a {
          display: block;
          text-align: left;
          b {
            display: flex;
            align-items: center;
            text-align: left;
          }
        }

        .brandname {
          color: #505050;
          text-align: left;
        }
      }

      .external-icon {
        width: 2rem;
        height: 2rem;

        &.optimizer-icon svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .transparency-program-info.flags {
      top: 3rem;
    }
  }

  .campaigns-filters .col-auto .nav-pills .filter-button {
    margin-top: 12.1rem !important;
  }

 .link {
    position: absolute !important;
    right: 0 ;
    top: 0 ;

    svg {
      width:25px;
      height: 25px;
    }
  }

  #twolineText{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .download-xls {
    .btn-secondary:first-of-type {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .optimizer-nav-dropdown-menu {
      padding: .7rem;

      li {
        a {
          padding: .4rem 1.5rem .4rem 1.2rem;
          font-weight: normal;
        }
      }
    }
  }

  .dropdown-toggle-split {
    min-width: 2rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .filter-tag {
    display: inline-flex;
    align-items: center;
    background: #f4f4fb;
    border-radius: .5rem;
    padding: .5rem 1rem;
    border: .1rem solid #e4e2e2;

    &__content {
      color: #595555;
      max-width: 25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.3rem;
      cursor: default;
      margin-right: .5rem;
    }

    .optimizer-icon {
      cursor: pointer;

      &:hover {
        svg {
          rect {
            fill: #b9b9bd;
          }
        }
      }
    }
  }
}
</style>
