<template>
  <div class="content-wrapper profile-container">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Profile</h1>
      </div>
    </div>

    <!-- <preloader v-if="loading"></preloader> -->
    <b-tabs class="settings-container__tabs">
      <b-tab title="User Profile">
        <user-profile :profile="profile" :loading="loading" />
      </b-tab>
      <b-tab title="Subscriptions">
        <subscriptions :accesses="accesses" :payments="payments" :loading="loading" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import UserProfile from './tabs/UserProfile';
import Subscriptions from './tabs/Subscriptions';

export default {
  name: 'Profile',
  components: { UserProfile, Subscriptions },
  data() {
    return {
      loading: true,
      profile: {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        companyAddress: '',
        taxId: '',
      },
      accesses: [],
      payments: [],
    };
  },
  created() {
    this.$_getProfileData();
  },
  methods: {
    async $_getProfileData() {
      try {
        const { data: { accesses, payments, profile } } = await this.$api.profile.getProfileData();
        this.$_handleProfile(profile);
        this.accesses = accesses;
        this.payments = payments.sort((a, b) => new Date(b.dattm) - new Date(a.dattm));
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    $_handleProfile(data) {
      const { name_f: firstName, name_l: lastName, email, company_name: companyName, company_address: companyAddress, taxid: taxId } = data;
      this.profile = { ...this.profile, firstName, lastName, email, companyName, companyAddress, taxId };
    },
  },
};
</script>

<style lang="scss">
  .profile-container {
    .section-header {
      margin-bottom: 3rem;
    }
  }
</style>
