<template>
  <div class="manual-stats-update-container">
    <b-alert show variant="info" class="manual-stats__info">
      In this section you can manually update the tracker and traffic source statistics for a specific date for selected Ad Accounts. Once you submit the update request allow up to 1 hour before checking your campaigns for the updated statistics.
    </b-alert>
    <preloader v-if="preloader"></preloader>
    <div v-else class="manual-stats">
      <div class="manual-stats__filters row">
        <div class="col-md-3 pb-2">
          <vue-opti-select-light
            ref="trafficSourceType"
            class="optimizer-select"
            :options="options.trafficSourceTypes"
            unique-key="id"
            label-key="name"
            @change="$_changeTrafficSourceType"
            button-placeholder="Select Traffic Source Type"
            button-block
            v-model="form.trafficSourceType"
          >
            <template #ITEM="{ option }">
              <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.uniqueName)" :alt="option.uniqueName" :title="option.uniqueName" />
              <div class="acc-item-name">
                {{ option.name }}
              </div>
            </template>
          </vue-opti-select-light>
        </div>
        <div class="col-md-3 pb-2">
          <vue-opti-select-light
            ref="trafficSourceAccount"
            class="optimizer-select"
            :options="options.trafficSourceAccounts"
            unique-key="id"
            label-key="name"
            option-type="checkbox"
            searchable
            :search-fields="['name']"
            @change="$_changeTrafficSourceAccounts"
            button-placeholder="Select Accounts"
            button-placeholder-all-selected="All Accounts"
            :button-placeholder-multiple="({ count, suffix }) => `${count} Account${suffix}`"
            button-block
            v-model="form.trafficSourceAccounts"
          >
            <template #ITEM="{ option }">
              <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.trafficSourceType.uniqueName)" :alt="option.trafficSourceType.uniqueName" :title="option.trafficSourceType.uniqueName" />
              <div class="acc-item-name">
                {{ option.name }}
              </div>
            </template>
            <template #HEADER_2>
              <header class="dropdown-select-all" @click="() => options. trafficSourceAccounts.length === form.trafficSourceAccounts.length ? $refs.trafficSourceAccount.clear() : $refs.trafficSourceAccount.selectAll()">
                <b-btn class="secondary-button select-all-btn">{{ options.trafficSourceAccounts.length === form.trafficSourceAccounts.length ? 'Deselect accounts' : 'Select all accounts' }}</b-btn>
              </header>
            </template>
          </vue-opti-select-light>
        </div>
        <div class="col-md-3 pb-2">
          <date-range
            v-model="form.dateRangePicker"
            :auto-emit="true"
          />
        </div>
      </div>
    </div>
    <div class="fixed-footer">
      <div class="custom-stats-save-container">
        <b-btn :disabled="loadingStatus" class="primary-button" @click="$_submit">
          <template v-if="loadingStatus"><i class="fa fa-spinner fa-spin mr-1"></i> Updating...</template>
          <template v-else>Update Data</template>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import configFile from './config';
import { getTrafficSourceLogo } from '@sh/helpers';
import moment from 'moment';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'ManualStatsUpdate',
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      loadingStatus: false,
      config: null,
      api: {
        trafficSourceAccountsApi: [],
        trafficSourceTypesApi: [],
      },
      maps: {
        trafficSourceAccountsByTypeId: {},
      },
      options: {
        trafficSourceTypes: [],
        trafficSourceAccounts: [],
      },
      form: {
        trafficSourceType: [],
        trafficSourceAccounts: [],
        dateRangePicker: {
          startDate: moment(new Date()).format(FORMAT),
          endDate: moment(new Date()).endOf('day').format(FORMAT),
        },
      },
    };
  },
  async created() {
    try {
      this.config = await configFile.getConfig();
      [this.api.trafficSourceAccountsApi, this.api.trafficSourceTypesApi] = await Promise.all([this.config.api.trafficSources.accounts(), this.config.api.trafficSources.types()]);
    } catch (error) {
      this.$n_failNotification({ title: error.response.data.message });
    }
    if (this.api.trafficSourceAccountsApi) {
      // Map Accounts
      this.api.trafficSourceAccountsApi.forEach((account) => {
        if (!this.maps.trafficSourceAccountsByTypeId[account.type.id]) this.maps.trafficSourceAccountsByTypeId[account.type.id] = [];
        this.maps.trafficSourceAccountsByTypeId[account.type.id].push(account);
      });
      // Set TrafficSourceType Options (Keep order)
      this.options.trafficSourceTypes = this.api.trafficSourceTypesApi.filter((trafficSourceType) => this.maps.trafficSourceAccountsByTypeId[trafficSourceType.id] && trafficSourceType.status);
      this.preloader = false;
    }
  },
  methods: {
    getTrafficSourceLogo,
    async $_submit() {
      this.loadingStatus = true;
      try {
        const validator = await this.$validator.validateAll(this.$_getScope());
        if (this.form.trafficSourceAccounts.length !== 0 && validator) {
          await this.config.api.manualStatsUpdate.submit(this.form.trafficSourceAccounts.map((c) => c.id), this.form.dateRangePicker.startDate, this.form.dateRangePicker.endDate);
          this.$n_infoNotification({ title: 'Data is being pulled for the selected accounts!', message: 'Please check the campaigns for the updated stats.', timeout: 6000 });
          this.$nextTick(() => { this.$validator.reset(this.$_getScope()); });
        } else {
          this.$n_warnNotification({ title: 'Please complete the form.' });
        }
      } catch (error) {
        this.$n_failNotification({ title: 'Limit reached. Please try again after 10 minutes.' });
      }
      this.loadingStatus = false;
    },
    $_getScope() {
      return 'manualStatsUpdateForm';
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${this.$_getScope()}.${name}`) ? 'invalid' : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${this.$_getScope()}.${name}`);
    },
    $_updateAccounts({ trafficSourceTypeId, trafficSourceAccountsIds }, force = false) {
      if (trafficSourceTypeId || force) {
        // Set Traffic Source Type
        this.$refs.trafficSourceType.clear();
        this.$refs.trafficSourceAccount.clear();
        this.$refs.trafficSourceType.add(trafficSourceTypeId);
        // Set Traffic Source Accounts Options
        this.options.trafficSourceAccounts = this.maps.trafficSourceAccountsByTypeId[trafficSourceTypeId]; // Resolve Accounts
      }
      // Set Traffic Source Accounts
      if (trafficSourceAccountsIds) {
        this.$refs.trafficSourceAccount.clear();
        trafficSourceAccountsIds.forEach((id) => {
          this.$refs.trafficSourceAccount.add(id);
        });
      }
    },
    $_changeTrafficSourceType(trafficSourceType) {
      this.$_updateAccounts({ trafficSourceTypeId: trafficSourceType[0].id }, true);
    },
    $_changeTrafficSourceAccounts(trafficSourceAccounts) {
      this.$_updateAccounts({ trafficSourceAccountsIds: trafficSourceAccounts.map(({ id }) => id) });
    },
  },
};
</script>

<style lang="scss">
.manual-stats-update-container {
  .dropdown-select-all {
    position: sticky;
    top: 0;
    background: $color-white;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    color: $black-700;
    border-bottom: 0.1rem solid $color-light;
  }

  .dropdown-select-all:hover {
    cursor: pointer;
  }

  .select-all-btn {
    height: 3.1rem;
    padding: .3rem 1rem;
  }

  .manual-stats {
    &__info {
      background: rgba(132, 164, 247, 0.2);
      font-size: 1.5rem;
      color: $black-600;
      padding: 2rem;
      border: none;
      border-radius: 0.6rem;
      margin-bottom: 3rem;
      margin-inline: 0;
    }

    &__filters {
      margin-bottom: 3rem;

      .trafficSourceAccount {
        .quick-actions {
          border-bottom: 0.1rem solid $table-border-color;

          .btn {
            width: calc( 50% - 10px );
          }
        }
      }
    }

    &__campaigns-select {
      background: $color-white;
      box-shadow: $card-bs-sm;
      padding: 2rem;
      border-radius: 1rem;
    }
  }
}
</style>
