<template>
  <div class="campaign-creator-form">
    <!-- BASIC INFO SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>Basic Info</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Branding Text -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('branding_text')" v-bind="$_getLabelProps('branding_text')">
              <cc-input v-bind="$_getProps('branding_text')" v-on="$_getEvents('branding_text')" v-model="$c_components['branding_text'].value" />
            </component-wrapper>
          </div>

          <!-- Marketing Objective -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('marketing_objective')" v-bind="$_getLabelProps('marketing_objective')">
              <cc-radio-button v-bind="$_getProps('marketing_objective')" v-on="$_getEvents('marketing_objective')" v-model="$c_components['marketing_objective'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- SCHEDULE SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Schedule</h1>
      </div>
      <div class="optimizer-card">
        <!-- TIMEFRAME SECTION -->
        <p class="font-weight-bold mb-3">Timeframe</p>
        <div class="row mb-3">
          <!-- Start Date -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('start_date_switch')" v-bind="$_getLabelProps('start_date_switch')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('start_date_switch')" v-on="$_getEvents('start_date_switch')" v-model="$c_components['start_date_switch'].value" />
                </div>
                <div class="col-md-6">
                  <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- End Date -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('end_date_switch')" v-bind="$_getLabelProps('end_date_switch')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('end_date_switch')" v-on="$_getEvents('end_date_switch')" v-model="$c_components['end_date_switch'].value" />
                </div>
                <div class="col-md-6">
                  <cc-date-picker v-if="$_isActive('end_date')" v-bind="$_getProps('end_date')" v-on="$_getEvents('end_date')" v-model="$c_components['end_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>

        <!-- STATUS SECTION -->
        <div class="row mb-3">
          <div class="col-md-8">
            <component-wrapper v-if="$_isActive('is_active')" v-bind="$_getLabelProps('is_active')">
              <cc-radio-button v-bind="$_getProps('is_active')" v-on="$_getEvents('is_active')" v-model="$c_components['is_active'].value" />
            </component-wrapper>
          </div>
        </div>

        <!-- Days and Hours -->
        <div class="row">
          <div class="col-md-8">
            <component-wrapper v-if="$_isActive('activity_schedule_switch')" v-bind="$_getLabelProps('activity_schedule_switch')">
              <cc-radio-button v-bind="$_getProps('activity_schedule_switch')" v-on="$_getEvents('activity_schedule_switch')" v-model="$c_components['activity_schedule_switch'].value" />
            </component-wrapper>
          </div>
          <div class="col-md-12 col-xl-10">
            <component-wrapper v-if="$_isActive('schedule')" v-bind="$_getLabelProps('schedule')">
              <cc-activity-schedule v-bind="$_getProps('activity_schedule_rules')" v-on="$_getEvents('activity_schedule_rules')" v-model="$c_components['activity_schedule_rules'].value" :allowCopyTimeFrame="true" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TARGETING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Location -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('targeting_type')" v-bind="$_getLabelProps('targeting_type')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('targeting_type')" v-on="$_getEvents('targeting_type')" v-model="$c_components['targeting_type'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('countries')" v-bind="$_getProps('countries')" v-on="$_getEvents('countries')" v-model="$c_components['countries'].value" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-if="$_isActive('location_type')" v-bind="$_getProps('location_type')" v-on="$_getEvents('location_type')" v-model="$c_components['location_type'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('regions')" v-bind="$_getProps('regions')" v-on="$_getEvents('regions')" v-model="$c_components['regions'].value" class="mt-3" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('postal_codes')" v-bind="$_getProps('postal_codes')" v-on="$_getEvents('postal_codes')" v-model="$c_components['postal_codes'].value" class="mt-3" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('dma')" v-bind="$_getProps('dma')" v-on="$_getEvents('dma')" v-model="$c_components['dma'].value" class="mt-3" />
                </div>
              </div>

            </component-wrapper>
          </div>

          <!-- Platform -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('platform_targeting')" v-bind="$_getLabelProps('platform_targeting')">
              <cc-checkbox v-bind="$_getProps('platform_targeting')" v-on="$_getEvents('platform_targeting')" v-model="$c_components['platform_targeting'].value" />
            </component-wrapper>
          </div>

          <!-- Operating System -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('os_targeting')" v-bind="$_getLabelProps('os_targeting')">
              <cc-radio-button v-bind="$_getProps('os_targeting')" v-on="$_getEvents('os_targeting')" v-model="$c_components['os_targeting'].value" />
              <cc-os-targeting-select v-if="$_isActive('os')" v-bind="$_getProps('os')" v-on="$_getEvents('os')" v-model="$c_components['os'].value" />
            </component-wrapper>
          </div>

          <!-- Browser Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('browser_targeting')" v-bind="$_getLabelProps('browser_targeting')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('browser_targeting')" v-on="$_getEvents('browser_targeting')" v-model="$c_components['browser_targeting'].value" />
                </div>
                <div class="col-md-6" v-if="$_isActive('browser')">
                  <cc-multi-select v-bind="$_getProps('browser')" v-on="$_getEvents('browser')" v-model="$c_components['browser'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Connection Type -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('connection_type')" v-bind="$_getLabelProps('connection_type')">
              <cc-radio-button v-bind="$_getProps('connection_type')" v-on="$_getEvents('connection_type')" v-model="$c_components['connection_type'].value" />
            </component-wrapper>
          </div>

          <!-- Block Sites -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('publisher_targeting_switch')" v-bind="$_getLabelProps('publisher_targeting_switch')">
              <div class="row mt-4">
                <div class="col-md-12" v-if="$_isActive('publisher_targeting')">
                  <cc-blocked-sites v-bind="$_getProps('publisher_targeting')" v-on="$_getEvents('publisher_targeting')" v-model="$c_components['publisher_targeting'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Brand Safety Pre-Bid -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('external_brand_safety_switch')" v-bind="$_getLabelProps('external_brand_safety_switch')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('external_brand_safety_switch')" v-on="$_getEvents('external_brand_safety_switch')" v-model="$c_components['external_brand_safety_switch'].value" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6" v-if="$_isActive('external_brand_safety')">
                  <cc-multi-select v-bind="$_getProps('external_brand_safety')" v-on="$_getEvents('external_brand_safety')" v-model="$c_components['external_brand_safety'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- BIDDING SECTION -->

    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Bidding</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Bid Strategy -->
          <div class="col-md-6 text-secondary">
            <component-wrapper v-if="$_isActive('bid_strategy')" v-bind="$_getLabelProps('bid_strategy')">
              <vue-opti-select-light
                class="optimizer-select w-100"
                :options="$_getOptions('bid_strategy')"
                unique-key="value"
                label-key="text"
                button-placeholder="Select Bidding Strategy"
                button-block
                single
                v-validate="'required'"
                data-vv-validate-on="input|update"
                :selectFirst="true"
                v-model="$c_components['bid_strategy'].value"
                data-vv-as="Bidding Strategy"
              >
                <template #ITEM="{ option }">
                  <div class="acc-item-name">
                    {{ option.text }}
                  </div>
                </template>
              </vue-opti-select-light>
              <span class="cpa-goal-text">Optimize your campaigns manually by maintaining control of your bids.</span>
              <span class="cpa-goal-text" v-if="$_selectedValue('bid_strategy') === 'MAX_CONVERSIONS'"> This optimization requires at least on active conversion event. <a target="blank" href="https://ads.taboola.com/tracking/conversion/new?locale=en&accountId=1063858&campaignId=-1">Add here.</a> To continue without it, please select a different bidding method. Based on the selection here, this campaign will use <strong>Max Conversions</strong> bidding strategy</span>
              <span class="cpa-goal-text" v-if="$_selectedValue('bid_strategy') === 'TARGET_CPA'">Based on the selection here, this campaign will use <strong>Target CPA</strong> bidding strategy</span>            </component-wrapper>
          </div>

          <!-- Cpa Goal -->
          <div class="col-md-6 text-secondary" v-if="$_selectedValue('bid_strategy') === 'TARGET_CPA'">
            <div>
              <component-wrapper v-if="$_isActive('cpa_goal')" v-bind="$_getLabelProps('cpa_goal')">
                <cc-input v-bind="$_getProps('cpa_goal')" v-on="$_getEvents('cpa_goal')" v-model="$c_components['cpa_goal'].value" />
                <span class="cpa-goal-text">We recommend setting a Cost-Per-Action (CPA) goal. This will help you measure how your campaign is performing against your goal.</span>
              </component-wrapper>
            </div>
          </div>

          <!-- BID TYPE -->
          <div class="col-md-6" v-if="$_selectedValue('bid_strategy') === 'BID_CONTROL'">
            <component-wrapper v-if="$_isActive('cpc_type')" v-bind="$_getLabelProps('cpc_type')">
              <cc-radio-button v-bind="$_getProps('cpc_type')" v-on="$_getEvents('cpc_type')" v-model="$c_components['cpc_type'].value" />
            </component-wrapper>
          </div>
          <!-- Bid Amount -->
          <div class="col-md-6" v-if="$_selectedValue('bid_strategy') === 'BID_CONTROL'">
            <component-wrapper v-if="$_isActive('cpc')" v-bind="$_getLabelProps('cpc')">
              <cc-input v-bind="$_getProps('cpc')" v-on="$_getEvents('cpc')" v-model="$c_components['cpc'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- BUDGET SECTION -->

    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">6</span>
        <h1>Budget</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Spending Limit -->
          <div class="col-md-6">
            <component-wrapper v-bind="$_getLabelProps('spending_limit')">
              <cc-radio-button v-if="$_isActive('spending_limit_model')" v-bind="$_getProps('spending_limit_model')" v-on="$_getEvents('spending_limit_model')" v-model="$c_components['spending_limit_model'].value" />
            </component-wrapper>
          </div>
          <div class="col-md-6 hidden-labels">
            <component-wrapper v-bind="$_getLabelProps('spending_limit')">
              <cc-input v-if="$_isActive('spending_limit')" v-bind="$_getProps('spending_limit')" v-on="$_getEvents('spending_limit')" v-model="$c_components['spending_limit'].value">
              </cc-input>
            </component-wrapper>
          </div>

          <!-- Daily Ad Delivery -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('daily_ad_delivery_model')" v-bind="$_getLabelProps('daily_ad_delivery_model')">
              <cc-radio-button v-bind="$_getProps('daily_ad_delivery_model')" v-on="$_getEvents('daily_ad_delivery_model')" v-model="$c_components['daily_ad_delivery_model'].value" />
            </component-wrapper>
          </div>

          <div class="col-md-6">
            <div v-if="$_isActive('daily_cap')">
              <component-wrapper :label="$c_components['daily_cap'].label">
                <cc-input v-bind="$_getProps('daily_cap')" v-on="$_getEvents('daily_cap')" v-model="$c_components['daily_cap'].value" />
                <p class="info-text">Actual daily spend may be up to 25% higher <i v-if="$c_components['daily_cap'].popover" class="fa fa-question-circle" v-b-popover.hover="$c_components['daily_cap'].popover"></i></p>
              </component-wrapper>
            </div>
          </div>

          <!-- Creative Traffic Allocation -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('traffic_allocation_mode')" v-bind="$_getLabelProps('traffic_allocation_mode')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('traffic_allocation_mode')" v-on="$_getEvents('traffic_allocation_mode')" v-model="$c_components['traffic_allocation_mode'].value" />
                </div>

                <div class="col-md-6">
                  <cc-date-picker v-if="$_isActive('traffic_allocation_ab_test_end_date')" v-bind="$_getProps('traffic_allocation_ab_test_end_date')" v-on="$_getEvents('traffic_allocation_ab_test_end_date')" v-model="$c_components['traffic_allocation_ab_test_end_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

        </div>
      </div>
    </div>

    <!-- CONVERSION PIXEL -->

    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">7</span>
        <h1>Conversion Goal</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Conversion Pixel -->
          <div class="col-md-6 text-secondary">
            <component-wrapper v-if="$_isActive('conversion_rules')" v-bind="$_getLabelProps('conversion_rules')">
              <cc-multi-select v-if="$_isActive('conversion_rules')" v-bind="$_getProps('conversion_rules')" v-on="$_getEvents('conversion_rules')" v-model="$c_components['conversion_rules'].value" />
              <span class="cpa-goal-text">With the Taboola Pixel, you can use tracking pixels to set up customized conversions, helping you track actions and activities that users perform on your site and measure the return on ad spend. (A Campaign can only be optimized to one conversion rule)</span>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TRACKING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">6</span>
        <h1>Tracking</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Tracking Code -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('tracking_code')" v-bind="$_getLabelProps('tracking_code')">
              <cc-textarea v-bind="$_getProps('tracking_code')" v-on="$_getEvents('tracking_code')" v-model="$c_components['tracking_code'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- COMMENTS SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">7</span>
        <h1>Comments</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Comments -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('comments')" v-bind="$_getLabelProps('comments')">
              <cc-textarea v-bind="$_getProps('comments')" v-on="$_getEvents('comments')" v-model="$c_components['comments'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- <component-wrapper legend="Campaign Id"></component-wrapper> -->

    <!-- Name -->
    <!-- <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
      <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
    </component-wrapper> -->

    <!-- Branding Text
    <component-wrapper v-if="$_isActive('branding_text')" v-bind="$_getLabelProps('branding_text')">
      <cc-input v-bind="$_getProps('branding_text')" v-on="$_getEvents('branding_text')" v-model="$c_components['branding_text'].value" />
    </component-wrapper> -->

    <!-- Marketing Objective -->
    <!-- <component-wrapper v-if="$_isActive('marketing_objective')" v-bind="$_getLabelProps('marketing_objective')">
      <cc-radio-button v-bind="$_getProps('marketing_objective')" v-on="$_getEvents('marketing_objective')" v-model="$c_components['marketing_objective'].value" />
    </component-wrapper>

    <component-wrapper legend="Timeframe"></component-wrapper> -->

    <!-- Start Date -->
    <!-- <component-wrapper v-if="$_isActive('start_date_switch')" v-bind="$_getLabelProps('start_date_switch')">
      <cc-radio-button v-bind="$_getProps('start_date_switch')" v-on="$_getEvents('start_date_switch')" v-model="$c_components['start_date_switch'].value" />
      <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- End Date -->
    <!-- <component-wrapper v-if="$_isActive('end_date_switch')" v-bind="$_getLabelProps('end_date_switch')">
      <cc-radio-button v-bind="$_getProps('end_date_switch')" v-on="$_getEvents('end_date_switch')" v-model="$c_components['end_date_switch'].value" />
      <cc-date-picker v-if="$_isActive('end_date')" v-bind="$_getProps('end_date')" v-on="$_getEvents('end_date')" v-model="$c_components['end_date'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Campaign Targeting"></component-wrapper> -->

    <!-- Targeting -->
    <!-- <component-wrapper v-if="$_isActive('targeting_type')" v-bind="$_getLabelProps('targeting_type')">
      <cc-radio-button v-bind="$_getProps('targeting_type')" v-on="$_getEvents('targeting_type')" v-model="$c_components['targeting_type'].value" />
      <cc-multi-select v-if="$_isActive('countries')" v-bind="$_getProps('countries')" v-on="$_getEvents('countries')" v-model="$c_components['countries'].value" class="mt-3" />
      <cc-radio-button v-if="$_isActive('location_type')" v-bind="$_getProps('location_type')" v-on="$_getEvents('location_type')" v-model="$c_components['location_type'].value" class="mt-3" />
      <cc-multi-select v-if="$_isActive('regions')" v-bind="$_getProps('regions')" v-on="$_getEvents('regions')" v-model="$c_components['regions'].value" class="mt-3" />
      <cc-multi-select v-if="$_isActive('postal_codes')" v-bind="$_getProps('postal_codes')" v-on="$_getEvents('postal_codes')" v-model="$c_components['postal_codes'].value" class="mt-3" />
      <cc-multi-select v-if="$_isActive('dma')" v-bind="$_getProps('dma')" v-on="$_getEvents('dma')" v-model="$c_components['dma'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Platform -->
    <!-- <component-wrapper v-if="$_isActive('platform_targeting')" v-bind="$_getLabelProps('platform_targeting')">
      <cc-checkbox v-bind="$_getProps('platform_targeting')" v-on="$_getEvents('platform_targeting')" v-model="$c_components['platform_targeting'].value" />
    </component-wrapper> -->

    <!-- Operating System -->
    <!-- <component-wrapper v-if="$_isActive('os_targeting')" v-bind="$_getLabelProps('os_targeting')">
      <cc-radio-button v-bind="$_getProps('os_targeting')" v-on="$_getEvents('os_targeting')" v-model="$c_components['os_targeting'].value" />
      <cc-os-targeting-select v-if="$_isActive('os')" v-bind="$_getProps('os')" v-on="$_getEvents('os')" v-model="$c_components['os'].value" />
    </component-wrapper> -->

    <!-- Connection Type -->
    <!-- <component-wrapper v-if="$_isActive('connection_type')" v-bind="$_getLabelProps('connection_type')">
      <cc-radio-button v-bind="$_getProps('connection_type')" v-on="$_getEvents('connection_type')" v-model="$c_components['connection_type'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Bidding and Budget"></component-wrapper> -->

    <!-- Bid Amount -->
    <!-- <component-wrapper v-if="$_isActive('cpc')" v-bind="$_getLabelProps('cpc')">
      <cc-input v-bind="$_getProps('cpc')" v-on="$_getEvents('cpc')" v-model="$c_components['cpc'].value" />
    </component-wrapper> -->

    <!-- Bid Type -->
    <!-- <component-wrapper v-if="$_isActive('bid_type')" v-bind="$_getLabelProps('bid_type')">
      <cc-radio-button v-bind="$_getProps('bid_type')" v-on="$_getEvents('bid_type')" v-model="$c_components['bid_type'].value" />
    </component-wrapper> -->

    <!-- Spending Limit -->
    <!-- <component-wrapper v-if="$_isActive('spending_limit')" v-bind="$_getLabelProps('spending_limit')">
      <cc-input v-bind="$_getProps('spending_limit')" v-on="$_getEvents('spending_limit')" v-model="$c_components['spending_limit'].value">
        <cc-radio-button slot="right" v-if="$_isActive('spending_limit_model')" v-bind="$_getProps('spending_limit_model')" v-on="$_getEvents('spending_limit_model')" v-model="$c_components['spending_limit_model'].value" />
      </cc-input>
    </component-wrapper> -->

    <!-- Daily Ad Delivery -->
    <!-- <component-wrapper v-if="$_isActive('daily_ad_delivery_model')" v-bind="$_getLabelProps('daily_ad_delivery_model')">
      <cc-radio-button v-bind="$_getProps('daily_ad_delivery_model')" v-on="$_getEvents('daily_ad_delivery_model')" v-model="$c_components['daily_ad_delivery_model'].value" />
      <div v-if="$_isActive('daily_cap')" class="row mt-3">
        <div class="col-auto">
          <b>{{ $c_components['daily_cap'].label }}</b>
        </div>
        <div class="col-auto">
          <cc-input v-bind="$_getProps('daily_cap')" v-on="$_getEvents('daily_cap')" v-model="$c_components['daily_cap'].value" />
        </div>
        <div class="col-12">
          <p class="mb-0 mt-1">Actual daily spend may be up to 25% higher <i v-if="$c_components['daily_cap'].popover" class="fa fa-question-circle" v-b-popover.hover="$c_components['daily_cap'].popover"></i></p>
        </div>
      </div>
    </component-wrapper> -->

    <!-- Creative Traffic Allocation -->
    <!-- <component-wrapper v-if="$_isActive('traffic_allocation_mode')" v-bind="$_getLabelProps('traffic_allocation_mode')">
      <cc-radio-button v-bind="$_getProps('traffic_allocation_mode')" v-on="$_getEvents('traffic_allocation_mode')" v-model="$c_components['traffic_allocation_mode'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Tracking"></component-wrapper> -->

    <!-- Tracking Code -->
    <!-- <component-wrapper v-if="$_isActive('tracking_code')" v-bind="$_getLabelProps('tracking_code')">
      <cc-textarea v-bind="$_getProps('tracking_code')" v-on="$_getEvents('tracking_code')" v-model="$c_components['tracking_code'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Comments"></component-wrapper> -->

    <!-- Comments -->
    <!-- <component-wrapper v-if="$_isActive('comments')" v-bind="$_getLabelProps('comments')">
      <cc-textarea v-bind="$_getProps('comments')" v-on="$_getEvents('comments')" v-model="$c_components['comments'].value" />
    </component-wrapper> -->
  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'TaboolaForm',
  mixins: [formMixin],
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;

      .info-text {
        font-size: 1.4rem;
        font-style: italic;
        color: $black-400;
      }
    }

  .cpa-goal-text {
    font-size: 13px;
  }
  }

 .hidden-labels .component-wrapper .form-group legend {
    visibility:hidden;
  }
</style>
