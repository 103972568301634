<template>
  <div class="subscriptions-container">
    <!-- Active Subscriptions -->
    <div class="active-subscriptions">
      <h4 class="active-subscriptions__title">Active Subscriptions</h4>

      <loading-skeleton
        v-if="loading"
        type="table"
        :table="{ headItems: 6, bodyItems: 2 }"
      />
      <!-- Subscription Block -->
      <template v-else>
        <div v-for="item in accesses" :key="item.access_id" class="subscription-block optimizer-card">
          <h3 class="subscription-block__name">
            {{ item.product_title }}
            <sub>({{ $_formatDate(item.begin_date, 'access') }} - {{ $_formatDate(item.expire_date, 'access') }})</sub>
            <a v-if="$_showChangePlan(item.begin_date)" href="https://membership.theoptimizer.io" target="_blank">Upgrade / Downgrade Plan</a>
            <a class="cancel-plan-btn" href="https://membership.theoptimizer.io" target="_blank">Cancel Subscription</a>
          </h3>
          <div class="subscription-block__content">
            <div class="subscription-block__content__box">
              <h6 class="subscription-block__content__box__title">Spend (current period)</h6>
              <p>${{ item.spend.toFixed(2) }}</p>
            </div>
            <div class="subscription-block__content__box">
              <h6 class="subscription-block__content__box__title">Spend Covered</h6>
              <p>${{ item.spend_covered_by_plan.toFixed(2) }}</p>
            </div>
            <div class="subscription-block__content__box">
              <h6 class="subscription-block__content__box__title">Overage</h6>
              <p>${{ item.overage.toFixed(2) }}</p>
            </div>
            <div class="subscription-block__content__box">
              <h6 class="subscription-block__content__box__title" title="Estimated Spend">
                Est. Spend
                <i v-b-tooltip.hover title="The estimated spend that you might reach by the end of the period based on the daily spent so far." class="fa fa-question-circle"></i>
              </h6>
              <p>${{ item.projected_spend.toFixed(2) }}</p>
            </div>
            <div class="subscription-block__content__box">
              <h6 class="subscription-block__content__box__title" title="Estimated Overage">
                Est. Overage
                <i v-b-tooltip.hover title="The overages that you might accumulate by the end of the period based on the estimated spend." class="fa fa-question-circle"></i>
              </h6>
              <p>${{ item.projected_overage.toFixed(2) }}</p>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- Payment History -->
    <div class="payment-history">
      <h4 class="payment-history__title">Payment History</h4>

      <loading-skeleton
        v-if="loading"
        type="table"
        :table="{ headItems: 6, bodyItems: 5 }"
      />
      <div v-else class="payment-history__wrapper optimizer-card">
        <div class="payment-history-header">
          <h4 class="payment-history-header__title date">Payment Date</h4>
          <h4 class="payment-history-header__title description">Description</h4>
          <h4 class="payment-history-header__title amount">Amount</h4>
          <h4 class="payment-history-header__title details">Details</h4>
        </div>

        <div v-for="item in payments.slice(0, 5)" :key="item.id" class="payment-history-block">
          <div class="payment-history-block__box date">
            <p>{{ $_formatDate(item.dattm) }}</p>
          </div>
          <div class="payment-history-block__box description-box description">
            <p class="mr-3">{{ item.payment_item_title }}</p>
            <small>{{ item.payment_item_description }}</small>
          </div>
          <div class="payment-history-block__box amount">
            <p class="bold">${{ item.amount.toFixed(2) }}</p>
          </div>
          <div class="payment-history-block__box details">
            <a href="https://membership.theoptimizer.io" target="_blank"><i class="fa fa-download"></i>Invoice</a>
          </div>
        </div>

        <b-btn @click="$_showPaymentsModal" type="link" class="view-all-btn">View All Payments</b-btn>
      </div>
    </div>
    <payments-modal :payments="payments" ref="paymentsModal" />
  </div>
</template>

<script>
import moment from 'moment';
import PaymentsModal from '../modals/PaymentsModal';

export default {
  name: 'Subscriptions',
  components: { PaymentsModal },
  props: {
    accesses: { type: Array },
    payments: { type: Array },
    loading: { type: Boolean, default: false },
  },
  methods: {
    $_showPaymentsModal() {
      this.$refs.paymentsModal.show();
    },
    $_formatDate(date, type) {
      return type === 'access' ? moment(date).format('MM/DD/YYYY') : moment(date).format('MMMM DD, YYYY');
    },
    $_showChangePlan(date) {
      return moment(new Date()).diff(moment(date), 'days') <= 15;
    },
  },
};
</script>

<style lang="scss">
.subscriptions-container {
  .active-subscriptions {
    margin-bottom: 3rem;

    &__title {
      font-size: 1.9rem;
      color: $text-primary;
      margin-bottom: 1rem;
    }

    .subscription-block {
      padding: 2.5rem;
      border-bottom: .1rem solid #E5E5E5;
      border-radius: 0;

      &:first-of-type {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      &:last-of-type {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom: none;
      }

      &__name {
        display: flex;
        align-items: center;
        color: $color-primary;
        font-size: 1.9rem;
        font-weight: 500;
        margin-bottom: 2rem;

        sub {
          bottom: 0;
          font-size: 1.4rem;
          color: #adaeaf;
          margin-right: .7rem;
          margin-left: .7rem;
        }

        a {
          display: inline-block;
          text-align: right;
          font-size: 1.5rem;
          color:  #59acfa;
          margin-left: 10px;

          @media screen and (max-width: 767.98px) {
            margin-left: 0;
            margin-top: 5px;
          }

          &:hover {
            text-decoration: none;
            color: $color-blue;
          }
        }

        .cancel-plan-btn {
          margin-left: auto;
          color: $color-yellow;

          &:hover {
            color: $color-yellow;
          }
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767.98px) {
          flex-direction: column;
        }

        &__box {
          // margin-right: 2rem;

          @media screen and (max-width: 767.98px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lightgray;
            padding: 5px;

            &:last-of-type {
              border-bottom: 0;
            }
          }

          &__title {
            font-size: 1.5rem;
            margin-bottom: .5rem;
            font-weight: bold;
          }

          p {
            font-size: 1.5rem;
            color: #7F828A;
          }

        }
      }
    }
  }

  .payment-history {
    &__title {
      font-size: 1.9rem;
      color: $text-primary;
      margin-bottom: 1rem;
    }

    &__wrapper {
      padding: 2.5rem;

       .payment-history-header {
        display: flex;
        // justify-content: space-between;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
        border-bottom: .1rem solid #e5e5e5;

        &__title {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: .5rem;

          &.date {
            // flex-grow: 1;
          }

          &.description {
            flex-grow: 3;
            text-align: center;
          }

          &.amount {
            // flex-basis: 10rem;
          }

          &.details {
            flex-basis: 10rem;
            text-align: right;
          }
        }
      }

      .payment-history-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: .1rem solid #e5e5e5;

        &:last-of-type {
          border-bottom: .1rem solid #e5e5e5;
        }

        &__box {
          display: flex;
          align-items: center;

          &.date {
            // flex-grow: 1;
          }

          &.description {
            flex-direction: column;
            flex-grow: 3;
          }

          &.amount {
            flex-basis: 10rem;
          }

          &.details {
            // flex-basis: 10rem;

            a {
              display: inline-block;
              text-align: right;
              font-size: 1.4rem;
              color: #59acfa;

              &:hover {
                text-decoration: none;
                color: $color-blue;
              }

              i {
                margin-right: 5px;
              }
            }
          }

          &__title {
            font-size: 1.5rem;
            margin-bottom: .5rem;
            font-weight: bold;
          }

          p {
            font-size: 1.5rem;
            color: #7F828A;

            &.bold {
              color: $text-primary;
              font-weight: bold;
            }
          }
        }
      }

      .view-all-btn {
        background: transparent;
        border: none;
        color: #59acfa;
        font-size: 1.5rem;
        padding: 0;
        margin-left: auto;

        &:focus,
        &:active,
        &:hover {
          background: transparent;
          color: #4299eb;
        }
      }
    }
  }
}
</style>
