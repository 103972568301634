<template>
  <div class="scheduler-container">
    <div class="row">
      <div class="col-md-6">
        <ul class="list-unstyled scheduler-presets">
          <li><span @click="$_toggleAll"> Select All / Clear</span></li>
          <li><i class="fa fa-info-circle" aria-hidden="true" v-b-tooltip.hover title="Mon - Fri | All Hours"></i><span @click="$_presetWeekDays"> Week Days</span></li>
          <li><i class="fa fa-info-circle" aria-hidden="true" v-b-tooltip.hover title="Sat - Sun | All Hours"></i><span @click="$_presetWeekEnds"> Weekends</span></li>
          <li><i class="fa fa-info-circle" aria-hidden="true" v-b-tooltip.hover title="Mon - Fri | 09:00 - 17:00"></i><span @click="$_presetWorkingHours"> Working Hours</span></li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="scheduler-timezone">
          <span class="timezone-label">Timezone: </span>
          <v-select
            v-model="localModel.timezone"
            max-height="300px !important"
            placeholder="-- Select --"
            :class="[{'is-invalid': $_hasError('timezone') === false}, 'form-control multiselect rule-v-select single-select mb-4']"
            :options="$options.timezones"
            v-validate="'required'"
            data-vv-validate-on="input|search:blur"
            name="timezone"
          >
          </v-select>
          <span class="time-now ml-2 pt-1">( Current Time: {{ $options.momentTimezone.tz(localModel.timezone).format('hh:mm A') }} )</span>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table :class="{ invalid: !isValidScheduler }">
        <tbody>
          <tr class="head-item-horizontal-container">
            <td></td>
            <td v-for="(hour, indexHour) in options.hours" v-show="hour.show" :key="`hour-${indexHour}`" :class="`head-item head-item-horizontal hour-${hour.type}`">
              <b-btn v-if="hour.type === '00'" size="sm" block @click="$_toggleColumn(indexHour+1)">
                <i :class="`fa fa-${options.hours[indexHour+1].show ? 'angle-right' : 'angle-down'}`" aria-hidden="true"></i>
              </b-btn>
              <b-btn v-else variant="outline-warning" size="sm" block @click="$_toggleColumn(indexHour)">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </b-btn>
            </td>
          </tr>
          <tr v-for="(day, indexDay) in options.days" :key="`day-${indexDay}`">
            <td class="edge-item edge-item-vertical" @click="$_toggleCheckRows(indexDay)">
              <span v-html="day.text"></span>
            </td>
            <td :class="`item hour-${hour.type}`" v-for="(hour, indexHour) in options.hours" v-show="hour.show" :key="`hour-${indexHour}`">
              <b-form-checkbox
                @change="(val) => $_change(val, indexDay, indexHour)"
                :checked="localModel.scheduler[indexDay][indexHour]"
                :value="1"
                :unchecked-value="0"
              />
              <div v-if="hour.type === '00' && !!localModel.scheduler[indexDay][indexHour+1] && !options.hours[indexHour+1].show" class="hour-half-indicator"></div>
            </td>
          </tr>
          <tr>
            <td class="edge-item edge-item-center" @click="$_toggleAll">
              <span>X</span>
            </td>
            <td v-for="(hour, indexHour) in options.hours" v-show="hour.show" :key="`hour-${indexHour}`" @click="$_toggleCheckColumn(indexHour)" :class="`edge-item edge-item-horizontal hour-${hour.type}`">
              <span v-html="hour.text"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
// import moment from 'moment'
import momentTimezone from 'moment-timezone';

export default {
  name: 'Scheduler',
  props: {
    value: {
      default: null,
      validator: (prop) => {
        if (prop === null || prop === '') return true;
        if (typeof prop === 'object' && prop.hasOwnProperty('scheduler') && prop.hasOwnProperty('timezone')) return true;
        return false;
      },
    },
  },
  data() {
    return {
      localModel: {
        /**
         * Mon|
         * Tue|
         * ...|   1   ,   1   ,   0
         * Sun|   0   ,   1   ,   0
         *    / 00:00 | 00:30 | 01:00 | 01:30 | 02:00 | ... | 23:30
         */
        scheduler: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ],
        timezone: 'UTC',
      },
      isValidScheduler: true,
      options: {
        days: [{ text: 'Mon', selected: 0 }, { text: 'Tue', selected: 0 }, { text: 'Wed', selected: 0 }, { text: 'Thu', selected: 0 }, { text: 'Fri', selected: 0 }, { text: 'Sat', selected: 0 }, { text: 'Sun', selected: 0 }],
        hours: [],
      },
    };
  },
  created() {
    /** ***************** Create Defaults  ******************* */
    // Timezone options
    this.$options.momentTimezone = momentTimezone;
    this.$options.timezones = momentTimezone.tz.names();
    // Hours Options
    for (let i = 0; i < 24; i++) {
      ['00', '30'].forEach((min) => {
        const hour = i.toString().padStart(2, '0');
        if (min === '00') {
          const text = `<span class="hour-${min}">${hour}</span>`;
          this.options.hours.push({
            text, type: min, selected: 0, show: min === '00',
          });
        } else {
          const text = `<span class="hour-${min}"><sup>${min}</sup></span>`;
          this.options.hours.push({
            text, type: min, selected: 0, show: min === '00',
          });
        }
      });
    }
    // Default Model
    // this.$options.days.forEach((day, indexDay) => {
    //   this.localModel.scheduler[indexDay] = []
    //   this.options.hours.forEach(hourItem => {
    //     this.localModel.scheduler[indexDay].push({ value: 0, type: hourItem.type, show: hourItem.show })
    //   })
    // })
    /** *************** ********************  **************** */

    // Set Model value
    if (this.value) {
      // this.localModel = this.value
      const scheduler = [];
      this.value.scheduler.forEach((hoursArray, dayIndex) => {
        scheduler[dayIndex] = [];
        hoursArray.forEach((value, hourIndex) => {
          scheduler[dayIndex][hourIndex] = value;
          // this.localModel.scheduler[dayIndex][hourIndex] = value
        });
      });
      this.$set(this.localModel, 'scheduler', scheduler);
      this.$_onChangeRoutine();
      this.localModel.timezone = this.value.timezone;
    }
  },
  methods: {
    async validate() {
      try {
        this.$_onChangeRoutine(false);
        return await this.$validator.validateAll() && this.isValidScheduler;
      } catch (error) {
        return false;
      }
    },
    $_change(value, indexDay, indexHour) {
      this.$set(this.localModel.scheduler[indexDay], indexHour, value);
      this.$_autoCheck(indexDay, indexHour);
      this.$_onChangeRoutine();
      // const inputValue = { timezone: this.localModel.timezone, scheduler: [] }
      // this.localModel.scheduler.forEach((hoursArray, dayIndex) => {
      //   inputValue.scheduler[dayIndex] = []
      //   hoursArray.forEach((value, hourIndex) => {
      //     inputValue.scheduler[dayIndex][hourIndex] = value
      //   })
      // })
      // this.$emit('input', inputValue)
    },
    $_toggleAll() {
      const value = (this.options.days.reduce((total, { selected }) => total + selected, 0) === 336) ? 0 : 1;
      const scheduler = [];
      for (let i = 0; i < 7; i++) {
        scheduler[i] = new Array(48).fill(value);
      }
      this.$set(this.localModel, 'scheduler', scheduler);
      this.$_onChangeRoutine();
    },
    $_toggleCheckRows(indexDay) {
      const value = this.options.days[indexDay].selected < 48 ? 1 : 0;
      const newRow = new Array(48).fill(value);
      this.$set(this.localModel.scheduler, indexDay, newRow);
      this.$_onChangeRoutine();
    },
    $_toggleCheckColumn(indexHour) {
      const value = this.options.hours[indexHour].selected < 7 ? 1 : 0;
      this.localModel.scheduler.forEach((day, indexDay) => {
        this.$set(day, indexHour, value);
        this.$_autoCheck(indexDay, indexHour);
      });
      this.$_onChangeRoutine();
    },
    $_toggleColumn(index) {
      this.options.hours[index].show = !this.options.hours[index].show;
    },
    $_onChangeRoutine(emit = true) {
      this.options.hours.forEach((item) => item.selected = 0);
      this.options.days.forEach((item) => item.selected = 0);
      let totalSelected = 0;
      this.localModel.scheduler.forEach((day, dayIndex) => {
        day.forEach((value, hourIndex) => {
          if (value) {
            totalSelected++;
            this.options.days[dayIndex].selected++;
            this.options.hours[hourIndex].selected++;
          }
        });
      });
      this.isValidScheduler = totalSelected > 0;
      if (emit) this.$emit('input', this.localModel);
    },
    $_indexHourRotate(index, modifier = 0) {
      const val = index + modifier;
      if (val > 47) {
        return val - 48;
      } if (val < 0) {
        return 48 + val;
      }
      return val;
    },
    $_autoCheck(indexDay, indexHour) {
      if ((indexHour % 2) === 0) {
        const row = this.localModel.scheduler[indexDay];
        const value = row[indexHour];
        const indexRotate = {
          '-2': this.$_indexHourRotate(indexHour, -2),
          '-1': this.$_indexHourRotate(indexHour, -1),
          '+1': this.$_indexHourRotate(indexHour, 1),
          '+2': this.$_indexHourRotate(indexHour, 2),
        };

        if (row[indexRotate['-2']] === value && row[indexRotate['-1']] !== value) {
          this.$set(row, indexRotate['-1'], value);
        }
        if (row[indexRotate['+2']] === value && row[indexRotate['+1']] !== value) {
          this.$set(row, indexRotate['+1'], value);
        }
        if (value === 0) {
          if (row[indexRotate['-1']] !== value) {
            this.$set(row, indexRotate['-1'], value);
          }
          if (row[indexRotate['+1']] !== value) {
            this.$set(row, indexRotate['+1'], value);
          }
        }
      }
    },
    $_presetWeekDays() {
      let sum = 0;
      for (let i = 0; i < 5; i++) {
        sum += this.options.days[i].selected;
      }
      const value = sum < (48 * 5) ? 1 : 0;
      const scheduler = [];
      for (let i = 0; i < 7; i++) {
        if (i < 5) {
          scheduler[i] = new Array(48).fill(value);
        } else {
          scheduler[i] = this.localModel.scheduler[i];
        }
      }
      this.$set(this.localModel, 'scheduler', scheduler);
      this.$_onChangeRoutine();
    },
    $_presetWeekEnds() {
      let sum = 0;
      for (let i = 5; i < 7; i++) {
        sum += this.options.days[i].selected;
      }
      const value = sum < (48 * 2) ? 1 : 0;
      const scheduler = [];
      for (let i = 0; i < 7; i++) {
        if (i >= 5) {
          scheduler[i] = new Array(48).fill(value);
        } else {
          scheduler[i] = this.localModel.scheduler[i];
        }
      }
      this.$set(this.localModel, 'scheduler', scheduler);
      this.$_onChangeRoutine();
    },
    $_presetWorkingHours() {
      let sum = 0;
      for (let i = 0; i < 5; i++) {
        for (let j = 18; j < 35; j++) {
          sum += this.localModel.scheduler[i][j];
        }
      }
      const value = sum < 85 ? 1 : 0;
      const scheduler = [];
      for (let i = 0; i < 7; i++) {
        if (i < 5) {
          scheduler[i] = [];
          for (let j = 0; j < 48; j++) {
            if (j > 17 && j < 35) {
              scheduler[i][j] = value;
            } else {
              scheduler[i][j] = this.localModel.scheduler[i][j];
            }
          }
        } else {
          scheduler[i] = this.localModel.scheduler[i];
        }
      }
      this.$set(this.localModel, 'scheduler', scheduler);
      for (let i = 0; i < 5; i++) {
        this.$_autoCheck(i, 18);
        this.$_autoCheck(i, 34);
      }
      this.$_onChangeRoutine();
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
  },
};
</script>

<style lang="scss">
  .scheduler-container {

    .scheduler-presets {
      padding-left: 6.5rem;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      li {
        margin-right: 3rem;
        color: #459ef1;
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
          color: $color-blue;
          text-decoration: underline;
        }

        i {
          color: $black-300;
          margin-right: .3rem;
        }
      }
    }

    .scheduler-timezone {
      display: flex;
      align-items: center;
      padding-right: 8rem;

      .timezone-label {
        margin-right: 1rem;
        font-size: 1.5rem;
        color: $text-secondary;
      }

      .v-select {
        flex: 1;
        margin-bottom: 0 !important;

        .vs__dropdown-toggle {
          padding-bottom: 0;
          height: 3rem;
        }

        .vs__actions {
          .vs__clear {
            margin-top: -.3rem;
          }
        }
      }

      .time-now {
        font-size: 1.3rem;
        color: $text-secondary;
      }
    }

    .table-container {
      overflow-x: auto;

      table {
        width: 100%;

        &.invalid {
          td.edge-item {
            &.edge-item-horizontal {
              border-top: 1px solid #ff7979 !important;
            }
            &.edge-item-vertical {
              border-right: 1px solid #ff7979 !important;
            }
          }
        }

        .head-item-horizontal-container {
          .head-item {
            &.hour-00,
            &.hour-30 {
              .btn {
                background: #E4EAEE !important;
                border-radius: .3rem;
                color:  #7F828A;
                font-size: 1.7rem;
                border: none !important;
              }
            }

            &.hour-30 {
              .btn {
                background-color: #24a8d824;
                color: #24a8d8;
                &:hover {
                  border-top: 1px solid #24a8d8;
                }
              }
            }
          }
        }

        td {
          &.item {
            text-align: center;
            padding: .7rem .3rem .3rem .3rem;
            height: 2.5rem;
            position: relative;

            &.hour-00 {
              .hour-half-indicator {
                display: inline-block;
                border: 1px solid #24a8d8;
                width: 2px;
                height: 1.6rem;
                position: absolute;
                right: -1px;
                top: .9rem;
              }
            }
            &.hour-30 {
              background-color: #24a8d824;
            }
          }
          &.edge-item {
            font-size: 1.4rem;
            cursor: pointer;

            &:hover {
              background-color: transparent;
            }
            &.edge-item-horizontal {
              border-top: .1rem solid #ebebeb;
              text-align: center;
              font-weight: bold;
              color: $text-primary;
              font-size: 1.3rem;
              padding-top: .7rem;

              &:hover {
                background: transparent;
              }

              &.hour-30 {
                .hour-30 {
                  sup {
                    top: -.2em;
                    color: $color-dark-gray;
                  }
                }
              }
            }
            &.edge-item-center {
              text-align: center;
            }
            &.edge-item-vertical {
              font-weight: bold;
              color: $text-primary;
              border-right: .1rem solid #ebebeb;
              padding-right: 1.2rem;
              padding-left: .5rem;
              text-align: center;
              width: 6rem;

              &:hover {
                background: transparent;
              }
            }
          }

          .custom-checkbox {
            .custom-control-input {
              &:checked {
                ~ .custom-control-label {
                  &::before {
                    background: #53a4f0;
                    border-color: transparent;
                  }
                }
              }
            }

            .custom-control-label {
              cursor: pointer;

              &::before {
                height: 1.6rem;
                width: 1.6rem;
                box-shadow: none;
              }

              &::after {
                height: 1.6rem;
                width: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
</style>
