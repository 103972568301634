<template>
  <div :class="`cc-input-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('input')">
      <b-input-group class="optimizer-form-group-input">
        <b-input-group-addon class="addon-left" v-if="$slots.left"><slot name="left"></slot></b-input-group-addon>
        <b-input-group-addon class="addon-left" v-else-if="model.left">{{ model.left }}</b-input-group-addon>
        <b-form-input
          class="optimizer-form-input"
          type="text"
          v-model="input"
          :disabled="model.disabled"
          @input="$_emit('input')"
          name="input"
          :data-vv-as="model.label"
          :state="$_hasError('input')"
          v-validate="model['v-validate']"
          :placeholder="model.placeholder || null"
        >
        </b-form-input>
        <b-input-group-addon class="addon-right" v-if="$slots.right"><slot name="right"></slot></b-input-group-addon>
        <b-input-group-addon class="addon-right" v-else-if="model.right">{{ model.right }}</b-input-group-addon>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('input') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcInput',
  mixins: [component],
  props: {
    value: { type: [String, Number], default: '' },
  },
  data() {
    return {
      input: '',
    };
  },
  created() {
    // Bind Model
    this.$watch('value', (value) => {
      this.input = value;
    }, { immediate: true });
  },
};
</script>

<style lang="scss">
.cc-input-wrapper {
  &.component-spending_limit {
    .input-group {
      .addon-right {
        padding: 0;
      }
    }
  }
}
</style>
