export default (F) => ({
  traffic_source_spot_id: F.create({
    header: { content: 'Spot ID' },
    item: {
      key: 'traffic_source_spot_id',
      sortable: true,
      total: null,
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--name',
  }),
  cpc: F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'cpc',
      key: 'cpc',
    },
  }),
});
