<template>
  <div
    class="preloader"
    :style="{margin: margin+ ' auto'}"
  >
    <div class="circ1" />
    <div class="circ2" />
    <div class="circ3" />
    <div class="circ4" />
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    margin: { type: String, default: '300px' },
  },
};
</script>

<style scoped>
  .preloader {
    /* margin: 300px auto; */
    width: 70px;
    height: 30px;
    text-align: center;
    font-size: 10px;
  }
  .preloader > div {
    background-color: #979199;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;

    -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
    animation: stretchdelay 0.7s infinite ease-in-out;
  }
  .preloader .circ2 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .preloader .circ3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .preloader .circ4 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .preloader .circ5 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  @-webkit-keyframes stretchdelay {
    0%, 40%, 100% { -webkit-transform: translateY(-10px) }
    20% { -webkit-transform: translateY(-20px) }
  }
  @keyframes stretchdelay {
    0%, 40%, 100% {
      transform: translateY(-10px);
      -webkit-transform: translateY(-10px);
    } 20% {
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
      }
  }
</style>
