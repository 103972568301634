import { Icons } from '@/views/Automation/CampaignCreatorV2/helpers/icons';

export * from '@/views/Automation/CampaignCreatorV2/types/api';

export type NavigationAction = ViewLevelAction<NavigationActionEvent, Partial<NavigationActionPayload>>;

export enum NavigationActionEvent {
  CREATE = 'create',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  COPY_ID = 'copy-id',
  CREATE_ADSET = 'create-adset',
  DELETE_ADSET = 'delete-adset',
  CLONE_ADSET = 'clone-adset',
  CREATE_AD = 'create-ad',
  DELETE_AD = 'delete-ad',
  CLONE_AD = 'clone-ad',
}

export interface NavigationActionPayload {
  campaignId: string;
  adSetId: string;
  adId: string;
}

export interface ViewLevelAction<T extends string = string, P extends Record<string, any> = {}> extends BaseOption {
  description?: string;
  action?: Function;
  event: T;
  active?: boolean;
  payload?: P;
  disabled?: boolean;
}

interface MessageIconContent {
  message: string;
  field: string;
  entity: string;
  type: string;
}

export interface MessageIcon {
  content: MessageIconContent[];
  icon: {
    type: Icons;
    styles?: Record<string, string>;
  };
  containerStyles?: {
    default?: Record<string, string>;
    active?: Record<string, string>;
    semiactive?: Record<string, string>;
    hover?: Record<string, string>;
  };
}

export interface NavigationMenu {
  icon?: Icons;
  name: string;
  id: string; // current item id
  campaignId?: string; // available in AdSet and Ad
  adSetId?: string; // available in Ad
  actions?: NavigationAction[][];
  message?: MessageIcon;
  parent?: string;
  children?: NavigationMenu[];
}

export enum Level {
  Campaign = 'campaign',
  AdSet = 'adset',
  Ad = 'ad',
}

export interface BaseOption {
  name: string;
  icon?: Icons;
}

export enum CardLayer {
  LAYER_0,
  LAYER_1,
  LAYER_2,
  NONE = -1,
}

export enum CardType {
  SMALL = 'small',
  DEFAULT = 'default',
}

export enum PreviewTab {
  MY_SPACE = 'MY_SPACE',
  AD_SET_PREVIEW = 'AD_SET_PREVIEW',
  TEMPLATES = 'TEMPLATES',
  SUGGESTIONS = 'SUGGESTIONS',
}

export enum MainStep {
  CREATE_CAMPAIGN = 'Create Campaign',
  MANAGE_CAMPAIGN = 'Manage Variations',
  PREVIEW_PUBLISH = 'Add Automation & Publish',
}

export interface RadioGroupIconOption {
  text: string;
  value: string;
  icon: Icons;
  info: string;
  disabled: boolean;
  requestFeature?: boolean;
}

export interface TreeselectOption<T extends Record<string, any> = {}> {
  id: string | number;
  label: string;
  children?: Array<TreeselectOption & T>;
  isDisabled?: boolean;
  isNew?: boolean;
}

export interface TreeselectOptionInfo extends TreeselectOption {
  info?: string;
  icon?: Icons;
}

export interface TreeselectOptionProfile extends TreeselectOption {
  info: string;
  img?: string;
}

export interface TreeselectOptionAdAccount extends TreeselectOption {
  message?: string /* if there is a message option option is in error state */;
  info: string;
  isActive?: boolean;
  id: string;
}

export interface Action {
  label?: string;
  icon?: Icons;
  tooltip?: string;
  disabled?: boolean;
  event: string;
  id?: string;
}

export interface Tab {
  title: string;
  id: PreviewTab;
  popover?: {
    content: string;
    position: 'hover' | 'icon';
    delay?: {
      show?: number;
      hide?: number;
    };
  };
}
