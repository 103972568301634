
import CcCheckbox from '@/views/Automation/CampaignCreatorV2/components/CcCheckbox/CcCheckbox.ts.vue';
import CcIcon from '@/views/Automation/CampaignCreatorV2/components/CcIcon/CcIcon.ts.vue';
import { defineComponent, PropType } from 'vue';

interface Option {
  text: string;
  value: string;
  id: string;
  disabled?: boolean;
}

interface ComponentData {
  opened: boolean;
  key: number;
}

export default defineComponent({
  components: {
    CcCheckbox,
    CcIcon,
  },
  model: {
    prop: 'selected',
    event: 'update',
  },
  props: {
    selected: {
      type: Array as () => string[],
      default: () => [],
    },
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      opened: false,
      key: 0,
    };
  },
  computed: {
    allSelected(): boolean {
      return this.selected.length >= this.options.length;
    },
    indeterminate(): boolean {
      return this.selected.length > 0 && this.selected.length < this.options.length;
    },
  },
  methods: {
    toggleAll(checked: boolean) {
      const availableOptions = this.options.filter((option) => !option.disabled);

      if (checked && (!this.indeterminate || availableOptions.length === this.options.length)) {
        const selected = availableOptions.map((option) => option.id);
        this.onSelectedChange(selected);
      } else {
        this.onSelectedChange([]);
      }
      this.key++;
    },
    onSelectedChange(selected: string[] = []) {
      this.$emit('update', selected);
    },
  },
});
