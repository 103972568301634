import config from '@/views/Tools/Reporting/config';
import { getFilter } from '@/views/Tools/Reporting/core/getFilter';
import { ReportBuilder } from '@/views/Tools/Reporting/core/ReportBuilder';
import {
  AdvancedReport,
  BackgroundReport,
  CachedReportKey,
  csvTableFields,
  dateTypeOptions,
  getColumnFilterDto,
  getDateRangePayload,
  getFieldsDto,
  getTableSortServerPayload,
  ReportTabs,
} from '@/views/Tools/Reporting/helpers';
import {
  DateRange,
  ReportingResponse,
  ReportOption,
  ReportTab,
  ReportType,
  Table,
} from '@/views/Tools/Reporting/types';
import { Campaign } from '@/views/Tools/Reporting/types/Campaign';
import { FilterOptions, Filters, FilterType, FilterValues, State } from '@/views/Tools/Reporting/types/Filter';
import { AccountNameKey, getLogoUniqueName, pluralize } from '@sh/helpers';
import session from '@sh/services/session';
import { Account, SearchFeed, TrafficSource, VueOptiTable } from '@sh/types';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

interface ComponentData {
  isLoading: boolean;

  cachedReports: Partial<Record<CachedReportKey, ReportBuilder>>;
  currentReport: ReportBuilder;
  cachedTableFields: Record<string, VueOptiTable.Field[]>;

  globalReportOptions: ReportOption[];
  advancedReportOptions: ReportOption[];
  accounts: Account[];
  campaigns: Partial<Record<TrafficSource, Campaign[]>>;
  users: SearchFeed.User[];
  accountCompliance: Record<string, Record<string, SearchFeed.ComplianceAccount[]>>;

  complianceType: string;

  intervalId: number;

  presetId: string;
}

export const useReportingStore = defineStore('reporting', {
  state: (): ComponentData => ({
    isLoading: false,

    cachedReports: {},
    currentReport: ReportBuilder.createInstance('unknown'),
    cachedTableFields: {
      [`ALL_${BackgroundReport}`]: csvTableFields,
    },

    globalReportOptions: [],
    advancedReportOptions: [],
    accounts: [],
    campaigns: {},
    users: [],
    accountCompliance: {},

    complianceType: '',

    intervalId: 0,

    presetId: '',
  }),
  getters: {
    title(): string {
      if (session.isSearchFeed && this.complianceType) {
        if (this.complianceType === 'Ads') {
          return 'Ads.com Transparency Program';
        }
        return `${this.complianceType} Transparency Program`;
      }
      return 'Reporting';
    },
    tabOptions(): ReportTab[] {
      const tabs: ReportTab[] = this.globalReportOptions.map((option) => ({
        type: option.reportType,
        name: option.uiName ?? '',
        ...(option.reportType === ReportType.AccountCompliance && {
          icon: 'trafficSource2',
        }),
        ...(option.reportType === ReportType.ContentCompliance && {
          icon: 'ads',
        }),
      }));

      if (!session.isSearchFeed) {
        tabs.push(...ReportTabs);
      }

      return tabs;
    },
    AdvancedReportTab(): ReportTab | undefined {
      return this.tabOptions.find((option) => option.type === AdvancedReport);
    },
    BackgroundReportTab(): ReportTab | undefined {
      return this.tabOptions.find((option) => option.type === BackgroundReport);
    },
    activeTab(): ReportTab {
      const globalReport = this.tabOptions.find((option) => option.type === this.currentReport.tab);
      const otherReport = this.advancedReportOptions.find(
        (option) => option.reportType === this.currentReport.activeReportType
      )
        ? this.AdvancedReportTab
        : this.BackgroundReportTab;
      return globalReport ?? otherReport ?? ({} as ReportTab);
    },
    isBackgroundReport(): boolean {
      return this.activeTab.type === BackgroundReport;
    },
    isAdvancedReport(): boolean {
      return this.activeTab.type === AdvancedReport;
    },
    reportTypeName(): string {
      const reportOption = this.globalReportOptions.find(
        (option) => option.reportType === this.currentReport.activeReportType
      );
      const reportTypeName = reportOption?.uiName ?? reportOption?.reportType ?? BackgroundReport;

      if ([AdvancedReport, BackgroundReport].includes(reportTypeName)) {
        return '';
      }

      const tab = pluralize(0, reportTypeName.toLowerCase());
      return `${
        config.model.getTabRenaming(tab, this.currentReport.filters[FilterType.TrafficSource]?.value) ?? reportTypeName
      }`;
    },
    filterOptions(): FilterOptions {
      return {
        [FilterType.BreakDown]: dateTypeOptions,
        [FilterType.TrafficSource]: this.accounts,
        [FilterType.AdAccount]: this.accounts,
        [FilterType.ReportType]: {
          reportOptions: this.reportOptions,
          advancedReportOptions: this.advancedReportOptions,
        },
        [FilterType.Campaign]: this.campaigns,
        [FilterType.User]: this.users,
        [FilterType.TrafficSourceComplianceFilter]: this.accountCompliance,
      };
    },
    isTableShown(): boolean {
      return (
        (!this.currentReport.errorMessage && ReportBuilder.isTableShown(this.currentReport as ReportBuilder)) ||
        (!this.currentReport.isLoadingTable && this.isBackgroundReport)
      );
    },
    hasLoadizer(): boolean {
      return this.currentReport.isLoadingTable && !this.isBackgroundReport && this.isTableShown;
    },
    reportOptions(): ReportOption[] {
      return [...this.globalReportOptions, ...this.advancedReportOptions];
    },
    dateRangeFilter(): Filters[FilterType.DateRange] | undefined {
      return this.currentReport.filters[FilterType.DateRange] as Filters[FilterType.DateRange];
    },
    breakDownFilter(): Filters[FilterType.BreakDown] | undefined {
      return this.currentReport.filters[FilterType.BreakDown] as Filters[FilterType.BreakDown];
    },
    userFilter(): Filters[FilterType.User] | undefined {
      return this.currentReport.filters[FilterType.User] as Filters[FilterType.User];
    },
    trafficSourceComplianceFilter(): Filters[FilterType.TrafficSourceComplianceFilter] | undefined {
      return this.currentReport.filters[
        FilterType.TrafficSourceComplianceFilter
      ] as Filters[FilterType.TrafficSourceComplianceFilter];
    },
    trafficSourceFilter(): Filters[FilterType.TrafficSource] | undefined {
      return this.currentReport.filters[FilterType.TrafficSource] as Filters[FilterType.TrafficSource];
    },
    reportTypeFilter(): Filters[FilterType.ReportType] | undefined {
      return this.currentReport.filters[FilterType.ReportType] as Filters[FilterType.ReportType];
    },
    accountFilter(): Filters[FilterType.AdAccount] | undefined {
      return this.currentReport.filters[FilterType.AdAccount] as Filters[FilterType.AdAccount];
    },
    campaignFilter(): Filters[FilterType.Campaign] | undefined {
      return this.currentReport.filters[FilterType.Campaign] as Filters[FilterType.Campaign];
    },
  },
  actions: {
    getCachedReport(key: CachedReportKey): ReportBuilder | undefined {
      return cloneDeep(this.cachedReports[key] as unknown as ReportBuilder | undefined);
    },
    setCachedReport(key: CachedReportKey, report?: ReportBuilder) {
      this.cachedReports[key] = cloneDeep(report);
    },
    handleNewReportRequest(report: ReportBuilder) {
      report.isFormTouched = true;
      report.errorMessage = '';
      report.noDataMessage = '';
    },
    handleReportResponse(table: ReportingResponse, report: ReportBuilder, existingReport?: ReportBuilder) {
      if ('message' in table) {
        this.setCachedReport(report.tab, existingReport);
        this.setCachedReport(BackgroundReport);
        report.isLoadingTable = false;

        if (this.currentReport.tab === report.tab) {
          this.currentReport = cloneDeep(existingReport ?? report);
        }
      } else {
        this.setReportData(report, table);
        report.isLoadingTable = false;
        this.setCachedReport(report.tab, report);

        if (this.currentReport.tab === report.tab) {
          this.currentReport = cloneDeep(report);
        }
      }
    },
    getAccountName(accountId: number) {
      return this.accounts.find((account) => account.id === accountId)?.name ?? '';
    },
    async getComplianceData(user: Record<'email' | 'id', string>, report: ReportBuilder) {
      try {
        const existingUser = this.users.find((item) => item.id.toString() === user.id && item.email === user.email);

        if (this.accountCompliance[user.id]?.[user.email]?.length) {
          return this.accountCompliance[user.id]?.[user.email] ?? [];
        }

        if (existingUser?.trafficSources?.length) {
          this.accountCompliance[user.id] = {
            ...this.accountCompliance[user.id],
            [user.email]: existingUser.trafficSources,
          };

          ReportBuilder.setFilterOptions(report, this.filterOptions);

          return existingUser.trafficSources;
        }

        this.setLoadingFilter(FilterType.TrafficSourceComplianceFilter, true);

        const { clients_email, traffic_source_account_id } = await config.api.reporting.getUserComplianceValues(user);

        this.accountCompliance[user.id] = {
          ...this.accountCompliance[user.id],
          [user.email]: traffic_source_account_id,
        };

        this.setLoadingFilter(FilterType.TrafficSourceComplianceFilter, false);
        ReportBuilder.setFilterOptions(report, this.filterOptions);
        return { clients_email, traffic_source_account_id };
      } catch (error) {
        this.setLoadingFilter(FilterType.TrafficSourceComplianceFilter, false);
        report.errorMessage = "Sorry but we couldn't load data, please try again in a few moments.";
        return [];
      }
    },
    setLoadingFilter(filterType: FilterType.Campaign | FilterType.TrafficSourceComplianceFilter, isLoading: boolean) {
      const filter = this.currentReport.filters[filterType];

      if (filter) {
        filter.isLoading = isLoading;
      }
    },
    async getCampaigns(trafficSource: TrafficSource, report: ReportBuilder): Promise<Campaign[]> {
      try {
        if (this.campaigns[trafficSource]) {
          return this.campaigns[trafficSource] ?? [];
        }

        this.setLoadingFilter(FilterType.Campaign, true);
        report.errorMessage = '';

        const { data }: { data: Campaign[] } = await config.api.campaigns.getByFilters({
          typeName: [trafficSource],
          pageSize: 1000000,
        });

        this.campaigns = {
          ...this.campaigns,
          [trafficSource]: data.map((item) => ({
            ...item,
            value: item.id,
            uniqueName: getLogoUniqueName(item.traffic_source_unique_name as TrafficSource),
          })),
        };

        if (this.campaignFilter) {
          this.campaignFilter.availableOptions = this.campaigns;
        }
        this.setLoadingFilter(FilterType.Campaign, false);
        ReportBuilder.setFilterOptions(report, this.filterOptions);
        return data;
      } catch (error) {
        this.setLoadingFilter(FilterType.Campaign, false);
        report.errorMessage = "Sorry but we couldn't load data, please try again in a few moments.";
        return [];
      }
    },
    getFilterValues(filters: Partial<Filters> = {}): FilterValues {
      return Object.keys(filters).reduce((newFilters, filterType) => {
        // @ts-ignore
        newFilters[filterType] = cloneDeep(filters?.[filterType]?.value) ?? undefined;
        return newFilters;
      }, {} as FilterValues);
    },
    getFilters(tab: string, trafficSource: TrafficSource | 'ALL', filters?: Partial<Filters>) {
      const availableFilters = ReportBuilder.getAvailableFilters(trafficSource, tab);

      return availableFilters.activeFilters.reduce((newFilters: Partial<Filters>, filterType) => {
        const globalValue = availableFilters.globalFilters.includes(filterType)
          ? this.currentReport.filters[filterType]?.value
          : undefined;
        const state: State = cloneDeep({
          options: this.filterOptions,
          selected: {
            ...this.getFilterValues(newFilters),
            [filterType]: filters ? filters?.[filterType]?.value : globalValue,
          },
          userData: newFilters[FilterType.User]?.selectedData,
          reportType: tab as ReportType,
        });
        // @ts-ignore
        newFilters[filterType] = getFilter(filterType, state);
        return newFilters;
      }, {});
    },
    async getTableColumns(
      typeName: TrafficSource | 'ALL',
      reportType: string,
      report: ReportBuilder
    ): Promise<VueOptiTable.Field[]> {
      try {
        const key = [BackgroundReport].includes(reportType)
          ? `${typeName}_${reportType}`
          : `${typeName}_${reportType}_${this.presetId}`;

        if (this.cachedTableFields[key]?.length || [AdvancedReport, BackgroundReport].includes(reportType)) {
          return this.cachedTableFields[key] ?? [];
        }

        let items: VueOptiTable.Field[] = await config.api.reporting.reportTable({
          typeName,
          reportType,
          presetId: this.presetId,
        });
        if (session.isSearchFeed) {
          items = items.filter((field) =>
            [
              'traffic_source_unique_name',
              'client_email',
              'headline',
              'tracked_clicks',
              'discrepancy',
              'ts_clicks',
            ].includes(field.item.key)
          );
        }
        if (items.length) {
          this.cachedTableFields = {
            ...this.cachedTableFields,
            [key]: items,
          };
        }
        return items;
      } catch (error) {
        if (typeName === 'ALL' && reportType === 'UniqueName') {
          report.errorMessage = "Sorry but we couldn't load data, please try again in a few moments.";
          return [];
        }

        const items: VueOptiTable.Field[] = await this.getTableColumns('ALL', 'UniqueName', report);
        return items;
      }
    },
    async getAccounts() {
      const accounts = await config.api.trafficSources.accounts();
      accounts.sort((first, second) => first.name.localeCompare(second.name));
      this.accounts = accounts;
    },
    async getReportOptions() {
      const data = await config.api.reporting.reportOptions();
      this.globalReportOptions = data.globalReportOptions;
      this.advancedReportOptions = data.reportOptions;
    },
    async getUsers() {
      const [data, users] = await Promise.all([
        config.api.trafficSources.users(),
        config.api.reporting.getUserComplianceValues({}),
      ]);
      this.complianceType = data.settings.compliance_sf_type;
      this.users =
        users.clients_email.map((item) => {
          return {
            id: item.id,
            email: item.email,
            trafficSources: users.traffic_source_account_id.filter((ts) =>
              item.traffic_source_account_id.find((data) => data.id === ts.id)
            ),
          };
        }) ?? [];
    },
    async setReportTableColumns(report: ReportBuilder) {
      const reportType = report.activeReportType ?? report.tab;

      if (!report.trafficSource || !reportType) {
        return;
      }
      const tableFields = await this.getTableColumns(report.trafficSource, reportType, report);
      report.patchTable({
        columns: tableFields,
      });
    },
    setReportData(report: ReportBuilder, table: Table) {
      const newReport = cloneDeep(report);
      const isAccountName = report.reportFields.find((field) => field.item.key === AccountNameKey);

      if (table?.items && isAccountName) {
        table.items.forEach((item) => {
          item[AccountNameKey] = this.getAccountName(item.traffic_source_account_id as number);
        });
      }

      newReport.patchTable({
        ...table,
        pagination: table.pagination,
      });

      if (ReportBuilder.isTableShown(newReport)) {
        report.patchTable({
          ...table,
          pagination: table.pagination,
          totals: {
            ...table.totals,
            totalElements: table.pagination.total,
          },
        });
        report.generatedTime = new Date();
      } else {
        report.noDataMessage = 'There is no data for selected filters.';
      }
    },
    async resolvePossiblePendingRequests(report: ReportBuilder) {
      await this.setReportTableColumns(report);

      if (report.isSearchFeedReport && this.userFilter) {
        await this.getComplianceData(this.userFilter.selectedData, report);
      } else if (report.payload.typeName) {
        await this.getCampaigns(report.payload.typeName, report);
      }
    },
    insertTableItems(report: ReportBuilder) {
      clearInterval(this.intervalId);

      if (report.table.items.length) {
        this.intervalId = setInterval(() => {
          if (
            this.currentReport.table.items.length < report.table.items.length &&
            this.currentReport.tab === report.tab
          ) {
            this.currentReport.table.items.push(
              ...report.table.items.slice(
                this.currentReport.table.items.length,
                this.currentReport.table.items.length + 100
              )
            );
          } else {
            clearInterval(this.intervalId);
          }
        }, 100);
      }
    },
    setExistingReport(report: ReportBuilder) {
      const newReportInstance = ReportBuilder.createInstance(
        report.tab,
        this.getFilters(report.tab, report.trafficSource ?? 'ALL', report.filters)
      );
      newReportInstance.generatedTime = report.generatedTime;
      newReportInstance.isLoadingTable = report.isLoadingTable;
      const table = { ...report.table, items: report.table.items.slice(0, 15) };
      newReportInstance.patchTable(table);
      this.currentReport = newReportInstance;
      this.insertTableItems(report);
    },
    setPresetId(presetId: string) {
      this.presetId = presetId;
    },
    createReport(tab: string, trafficSource: TrafficSource | 'ALL' = 'ALL', filters?: Partial<Filters>): ReportBuilder {
      const globalFilters = this.getFilters(tab, trafficSource, filters);
      const report = ReportBuilder.createInstance(tab, globalFilters);
      this.setReportTableColumns(report);
      return report;
    },
    getPayload(report: ReportBuilder, getAllItems = false) {
      const data = cloneDeep(report.payload);

      return {
        ...data,
        reportFields: getFieldsDto(data.reportFields),
        ...getDateRangePayload(data as DateRange),
        ...(getAllItems && {
          pagination: {},
        }),
      };
    },
    async saveOfflineReport(report: ReportBuilder) {
      const data = await config.api.reporting.saveOfflineReport(this.getPayload(report, true));
      return data;
    },
    async getCsvItems() {
      const payload = this.getPayload(this.currentReport as ReportBuilder, true);
      const data: NullableType<Table> = await config.api.reporting.get(payload);

      const isAccountName = this.currentReport.reportFields.find((field) => field.item.key === AccountNameKey);

      if (data?.items && isAccountName) {
        data.items.forEach((item) => {
          item[AccountNameKey] = this.getAccountName(item.traffic_source_account_id as number);
        });
      }

      return data?.items;
    },
    async getBackgroundReports(backgroundReport: ReportBuilder) {
      try {
        backgroundReport.isLoadingTable = true;
        await this.setReportTableColumns(backgroundReport);
        backgroundReport.table.items = await config.api.reporting.backgroundReporting();
        backgroundReport.generatedTime = new Date();
        backgroundReport.isLoadingTable = false;

        if (backgroundReport.isValid) {
          this.setCachedReport(backgroundReport.tab, backgroundReport);
        }
      } catch {
        /* Do Nothing */
      }
    },
    onBackgroundReportsLoad(message = '') {
      const backgroundReport = ReportBuilder.createInstance(BackgroundReport);
      backgroundReport.tableMessage = message;
      backgroundReport.table.sort = '-date';
      this.getBackgroundReports(backgroundReport);
      return backgroundReport;
    },
    onTableSearch(data: Partial<VueOptiTable.Pagination>) {
      this.onTableChange(data);
    },
    onTableChange(data: Partial<VueOptiTable.Pagination>) {
      this.currentReport.patchTable({
        pagination: {
          currentPage: data.page ?? this.currentReport.table.pagination?.currentPage ?? -1,
          pageSize: data.limit ?? this.currentReport.table.pagination?.pageSize ?? -1,
          lastPage: this.currentReport.table.pagination?.lastPage ?? -1,
          total: this.currentReport.table.pagination?.total ?? -1,
        },
        search: data.search ?? this.currentReport.table.search,
        sort:
          data.sortField && data.sortType
            ? getTableSortServerPayload(data.sortType, data.sortField)
            : this.currentReport.table.sort,
        conditions: data.columnFilter ? getColumnFilterDto(data.columnFilter) : this.currentReport.table.conditions,
      });

      this.onGenerateReport(this.currentReport as unknown as ReportBuilder);
    },
    async onReportTabChange(tab: ReportTab, message = '') {
      const existingReport = this.getCachedReport(tab.type);

      if (tab.type === this.currentReport.tab) {
        return;
      }

      if (existingReport) {
        this.setExistingReport(existingReport);
      } else if (tab.type === BackgroundReport) {
        this.currentReport = this.onBackgroundReportsLoad(message);
      } else {
        this.currentReport = this.createReport(tab.type);
      }

      if (['UniqueName', 'Account'].includes(tab.type) && !this.currentReport.table.items?.length) {
        this.onGenerateReport(this.currentReport as unknown as ReportBuilder);
      }
    },
    async onGenerateReport(report: ReportBuilder) {
      const existingReport = this.getCachedReport(report.tab);

      try {
        this.handleNewReportRequest(report);

        if (!report.validationMessage) {
          report.isLoadingTable = true;
          this.setCachedReport(report.tab, report);
          window.scrollTo({ top: 0 });

          await this.resolvePossiblePendingRequests(report);

          const table: ReportingResponse = await config.api.reporting.get(this.getPayload(report));
          this.handleReportResponse(table, report, existingReport);
        }
      } catch (error: any) {
        report.isLoadingTable = false;
        const trace_id = error?.response?.data?.data?.trace_id;
        let message = error?.response?.data?.message;

        if (message && trace_id) {
          message += ` Request Id: ${trace_id}`;
        }
        report.errorMessage = message || 'We could not generate a report at the moment, please try again in a while!';
        this.setCachedReport(report.tab, existingReport);
      }
    },
  },
  debounce: {
    onTableSearch: 1000,
  },
});
