import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';

const infoText = {
  bid_cap: 'Bid Cap',
  bid: 'Current Bid',
};

const generateBidCapOptions = (device = '') => [
  { value: 'set', text: `Set ${device} Bid Cap To` },
  { value: 'increase', text: `Increase ${device} Bid Cap By` },
  { value: 'decrease', text: `Decrease ${device} Bid Cap By` },
];

const bidCapActionOptions = generateBidCapOptions();

const bidCapUnitOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const bidCapMinMaxOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const bidCapToOptions = [
  {
    value: 'bid_cap',
    text: 'of Bid Cap',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'bid',
    text: 'of Current Bid',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const bidCapModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              bid_cap: { info: infoText.bid_cap },
              bid: { info: infoText.bid },
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              bid_cap: { info: infoText.bid_cap },
              bid: { info: infoText.bid },
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              bid_cap: { info: infoText.bid_cap },
              bid: { info: infoText.bid },
            },
          },
        },
      },
    },
  },
};

const bidCapToOptionsCM = [];
const loadCmOptions = async () => {
  if (!bidCapToOptionsCM.length) {
    const response = await apiStore.configuration.cmFields();
    if (response && response.fields) {
      response.fields.forEach((cmItem) => {
        if (cmItem.customMetric) {
          bidCapToOptionsCM.push({
            value: cmItem.item.key,
            text: `of ${getFieldTextAndDescription(cmItem).text || cmItem.header.content}`,
            baseType: RuleTypes.Conditions.BaseFieldType.Metric,
            type: RuleTypes.Conditions.ValueType.Number,
          });
        }
      });
    }
  }
};


export default {
  async getData() {
    const bidCapModelTemplatesClone = JSON.parse(JSON.stringify(bidCapModelTemplates));
    const bidCapToOptionsClone = JSON.parse(JSON.stringify(bidCapToOptions));

    try {
      await loadCmOptions();
      if (bidCapToOptionsCM.length) {
        bidCapToOptionsClone.push(...bidCapToOptionsCM);
        bidCapToOptionsCM.forEach((cmItem) => {
          bidCapModelTemplatesClone.classic.action.set.unit.percentage.to[cmItem.value] = {};
          bidCapModelTemplatesClone.classic.action.increase.unit.percentage.to[cmItem.value] = {};
          bidCapModelTemplatesClone.classic.action.decrease.unit.percentage.to[cmItem.value] = {};
        });
      }
    } catch (error) {
      throw Error('Fail loading dynamics fields custom-metrics');
    }
    return {
      bidCapActionOptions, bidCapUnitOptions, bidCapMinMaxOptions, bidCapToOptions: bidCapToOptionsClone, bidCapModelTemplates: bidCapModelTemplatesClone, generateBidCapOptions,
    };
  },
};
