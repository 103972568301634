export default (F) => ({
  traffic_source_adgroup_id: F.create({
    header: { content: 'AdGroup ID' },
    item: {
      key: 'traffic_source_adgroup_id',
      sortable: true,
      total: null,
    },
  }),
  traffic_source_campaign_id: F.create({
    header: { content: 'Camp. ID' },
    item: {
      key: 'traffic_source_campaign_id',
      sortable: true,
      total: {
        content: () => '-',
      },
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      slot: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--name',
  }),
  cpc_native: F.create({
    header: { content: 'Native Bid' },
    item: {
      slot: 'cpc_native',
      key: 'cpc_native',
      sortable: true,
    },
  }),
  cpc_search: F.create({
    header: { content: 'Search Bid' },
    item: {
      slot: 'cpc_search',
      key: 'cpc_search',
      sortable: true,
    },
  }),
  'additional_fields.targetCpmMicros': F.create({
    header: { content: 'Target CPM' },
    item: {
      slot: 'additional_fields.targetCpmMicros',
      key: 'additional_fields.targetCpmMicros',
      sortable: true,
    },
  }),
  'additional_fields.roas_bid': F.create({
    header: { content: 'Target Roas' },
    item: {
      slot: 'additional_fields.roas_bid',
      key: 'additional_fields.roas_bid',
      sortable: true,
    },
  }),
  status: F.create({
    header: { content: 'Status' },
    item: {
      slot: 'status',
      key: 'status',
      sortable: true,
    },
  }),
  'additional_fields.learning_phase': F.create({
    header: { content: 'Learning Phase' },
    item: {
      slot: 'additional_fields.learning_phase',
      key: 'additional_fields.learning_phase',
      sortable: true,
    },
  }),
});
