<template>
  <div class="rule-conditions-wrapper">
    <div class="row rule-conditions" v-for="(row, i) in localModel" :key="i">

      <!--Field 1-->
      <b-form-group
        :state="getErrorState(`field1-${i}`)"
        :class="`${$_value(row.field1).type !== 'hour_of_day' ? $c_colsClassName.field1 : ''} col-12 field1 optimizer-form-group`"
      >
        <b-input-group :prepend="i === 0 ? 'IF' : 'AND'" :append="row.field1 && $_value(row.field1).behind || 'IS'">
          <RuleDateConditionField
            :value="row.field1"
            :ref="`validate-field1-${i}`"
            :name="'field1-' + i"
            :options="$c_field1Options"
            :groups="optionsData.fieldsGroup"
            @onErrorUpdate="onErrorUpdate"
            @onChange="$_changeField1(row, $event)"
          />
        </b-input-group>
        <small slot="invalid-feedback">{{ errors[`field1-${i}`] }}</small>
      </b-form-group>

      <template v-if="$_value(row.field1).type === 'hour_of_day'">
        <!--Hour of Day field-->
        <b-form-group class="col-12 day-of-hour-form-group optimizer-form-group">
          <scheduler v-model="row.field3.value" ref="hourOfDay" />
          <div v-if="!cmp_config.disable_buttons" class="day-of-hour-remove-button">
            <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
              <i class="fa fa-close" />
            </button>
          </div>
        </b-form-group>
      </template>

      <template v-else-if="$_value(row.field1).type === 'daily_cap'">
        <b-form-group class="col-sm-4 optimizer-form-group">
          <b-input-group>
            <vue-opti-select-light
              class="optimizer-select w-100"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field2-' + i"
              :key="'field2-' + i"
              v-model="row.field2"
              :options="optionsData.conditionsDailyCap"
              :unique-key="option => option.value.key"
              label-key="text"
              single
              searchable
              button-block
            />
          </b-input-group>
        </b-form-group>
      </template>
      <template v-else-if="$_isTextField($_value(row.field1).key)">
        <b-form-group :state="$_hasError('field2-' + i)" :class="`${$c_colsClassName.field2} col-12 optimizer-form-group`">
          <b-input-group>
            <vue-opti-select-light
              class="optimizer-select w-100"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field2-' + i"
              :key="'field2-' + i"
              :options="optionsData.nameConditions"
              v-model="row.field2"
              :unique-key="option => option.value.key"
              label-key="text"
              single
              searchable
              button-block
            />
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field2-' + i) }}</small>
        </b-form-group>
        <b-form-group :state="$_hasError('field3-' + i)" :class="`${$c_colsClassName.field3} col-12 field3 optimizer-form-group`">
          <b-input-group>
            <template>
              <b-form-input
                class="field3-input optimizer-form-input text-right"
                data-vv-as="Value"
                v-validate="'required'"
                type="text"
                v-model="row.field3.value"
                :name="'field3-' + i"
              >
              </b-form-input>
            </template>

            <div v-if="!$c_enableToFields && $_value(row.field1).placeholderHtml" v-html="$_value(row.field1).placeholderHtml" class="input-group-addon text-center" style="border-right: 1px solid #e1e6ef;display: block;"></div>
            <div v-if="!cmp_config.disable_buttons" class="input-group-button row-actions">
              <button @click.prevent="$_duplicateAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i v-if="isDuplicateAnimation[i]" class="fa fa-check text-success" />
                <i v-else class="fa fa-copy" />
              </button>
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i class="fa fa-close" />
              </button>
            </div>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field3-' + i) }}</small>
        </b-form-group>
      </template>

      <template v-else-if="$_isStatusField($_value(row.field1).key)">
        <b-form-group :state="$_hasError('field3-' + i)" :class="`col-md-8 optimizer-form-group`">
          <b-input-group class="w-100">
            <vue-opti-select-light
              class="optimizer-select col-md-4 pl-0"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field3-' + i"
              :key="'field3-' + i"
              v-model="row.field3"
              :options="optionsData.statusConditions"
              :unique-key="option => option.value.key"
              label-key="text"
              single
              searchable
              button-block
            />
            <div v-if="!cmp_config.disable_buttons" class="input-group-button row-actions">
              <button @click.prevent="$_duplicateAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i v-if="isDuplicateAnimation[i]" class="fa fa-check text-success" />
                <i v-else class="fa fa-copy" />
              </button>
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i class="fa fa-close" />
              </button>
            </div>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field2-' + i) }}</small>
        </b-form-group>
      </template>

      <template v-else-if="$_isLearningState($_value(row.field1).key)">
        <b-form-group :state="$_hasError('field3-' + i)" :class="`col-md-8 optimizer-form-group`">
          <b-input-group class="w-100">
            <vue-opti-select-light
              class="optimizer-select col-md-4 pl-0"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field3-' + i"
              :key="'field3-' + i"
              v-model="row.field3"
              :options="optionsData.learningStateConditions"
              :unique-key="option => option.value.key"
              label-key="text"
              single
              button-block
            />
            <div v-if="!cmp_config.disable_buttons" class="input-group-button row-actions">
              <button @click.prevent="$_duplicateAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i v-if="isDuplicateAnimation[i]" class="fa fa-check text-success" />
                <i v-else class="fa fa-copy" />
              </button>
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i class="fa fa-close" />
              </button>
            </div>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field2-' + i) }}</small>
        </b-form-group>
      </template>

      <template v-else-if="$_isDateField(row.field1)">
        <b-form-group
          :state="$_hasError('field2-' + i)"
          :class="`${$c_colsClassName.field2} col-12 optimizer-form-group`"
        >
          <b-input-group :append="row.field3.to_self && row.field2 && row.field2.value && row.field2.value.behind">
            <vue-opti-select-light
              class="optimizer-select w-100"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field2-' + i"
              :key="'field2-' + i"
              :options="getDateConditionOptions(row)"
              v-model="row.field2"
              :unique-key="(option) => option.value.key"
              label-key="text"
              single
              searchable
              button-block
              @change="onDateConditionChange(row)"
            />
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field2-' + i) }}</small>
        </b-form-group>
        <b-form-group
          :state="$_hasError('field3-' + i)"
          :class="`${$c_colsClassName.field3} col-12 field3 optimizer-form-group date-field`"
        >
          <b-input-group>
            <template v-if="row.field3.to_self">
              <Datepicker
                v-if="row.field3.to_self.key === 'specific_date'"
                v-model="row.field3.to_self.specificDate"
                format="yyyy-MM-dd"
                :bootstrap-styling="true"
                ref="date"
                class="cc-date-picker"
                input-class="cc-input"
                placeholder="Select Date"
              >
                <span slot="afterDateInput" class="cc-date-picker__icon">
                  <OptimizerIcon type="calendarOutline" :height="20" />
                </span>
              </Datepicker>
              <b-form-group
                v-else
                :state="$_hasError('field3-' + i)"
                class="pr-0 days-group-wrapper"
              >
                <b-input-group>
                  <b-form-input
                    class="optimizer-form-input text-right"
                    type="number"
                    :value="getDaysInputValue(row)"
                    name="daysInput"
                    :step="1"
                    :min="0"
                    @change="onDaysInput(row, $event)"
                  />
                  <b-input-group-addon class="text fs-14"> days </b-input-group-addon>
                </b-input-group>
              </b-form-group>
            </template>
            <div
              v-if="!$c_enableToFields && $_value(row.field1).placeholderHtml"
              v-html="$_value(row.field1).placeholderHtml"
              class="input-group-addon text-center"
              style="border-right: 1px solid #e1e6ef; display: block"
            ></div>
            <div v-if="!cmp_config.disable_buttons" class="input-group-button row-actions">
              <button @click.prevent="$_duplicateAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i v-if="isDuplicateAnimation[i]" class="fa fa-check text-success" />
                <i v-else class="fa fa-copy" />
              </button>
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i class="fa fa-close" />
              </button>
            </div>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field3-' + i) }}</small>
        </b-form-group>
      </template>
      <template v-else>
        <!--Condition field-->
        <b-form-group :state="$_hasError('field2-' + i)" :class="`${$c_colsClassName.field2} col-12 optimizer-form-group`">
          <b-input-group :append="row.field2 && row.field2.behind || 'THAN'">
            <vue-opti-select-light
              class="optimizer-select w-100"
              data-vv-as="Condition"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              :name="'field2-' + i"
              :key="'field2-' + i"
              v-model="row.field2"
              :options="optionsData.conditions"
              :unique-key="option => option.value.key"
              label-key="text"
              single
              searchable
              button-block
            />
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field2-' + i) }}</small>
        </b-form-group>

        <!--Condition value-->
        <b-form-group :state="getErrorState('field3-' + i)" :class="`${$c_colsClassName.field3} col-12 field3 optimizer-form-group`">
          <b-input-group>
            <time-input
              v-if="$_value(row.field1).type === 'time'"
              v-model.number="row.field3.value"
              classList="cc-time-picker"
            />
            <template v-else>
              <b-form-input
                class="field3-input optimizer-form-input text-right"
                data-vv-as="Value"
                v-validate="'required'"
                type="number"
                :step="$_value(row.field1).step ? $_value(row.field1).step : 1"
                v-model.number="row.field3.value"
                data-vv-validate-on="change"
                :name="'field3-' + i"
              >
              </b-form-input>
              <template v-if="$c_enableToFields">
                <vue-opti-select-light
                  class="optimizer-select field3-unit"
                  :value="row.field3.unit"
                  @change="(option) => { row.field3.unit = option.value }"
                  :options="$_getToUnitOptions(row.field1)"
                  label-key="text"
                  single
                />
                <RuleDateConditionField
                  v-if="row.field3.unit === 'percentage'"
                  key="percentage"
                  v-model="row.field3.to"
                  validateType="Campaign Type"
                  preventOverflowRight
                  :ref="`validate-field3-to-${i}`"
                  :name="'field3-' + i"
                  :options="$c_fieldsToOptions"
                  :groups="optionsData.fieldsGroup"
                  @onErrorUpdate="onErrorUpdate"
                />
                <RuleDateConditionField
                  v-else-if="row.field3.unit === 'self'"
                  key="self"
                  v-model="row.field3.to_self"
                  preventOverflowRight
                  :ref="`validate-field3-to-self-${i}`"
                  :name="'field3-' + i"
                  :options="$c_fieldToSelfOptions"
                  :groups="optionsData.fieldsGroup"
                  @onErrorUpdate="onErrorUpdate"
                />
              </template>

            </template>

            <div v-if="!$c_enableToFields && $_value(row.field1).placeholderHtml" v-html="$_value(row.field1).placeholderHtml" class="input-group-addon text-center" style="border-right: 1px solid #e1e6ef;display: block;"></div>
            <div v-if="!cmp_config.disable_buttons" class="input-group-button row-actions">
              <button @click.prevent="$_duplicateAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i v-if="isDuplicateAnimation[i]" class="fa fa-check text-success" />
                <i v-else class="fa fa-copy" />
              </button>
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary hide-rule-row-btn">
                <i class="fa fa-close" />
              </button>
            </div>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('field3-' + i) || errors[`field3-${i}`] }}</small>
        </b-form-group>
      </template>
    </div>

    <div v-if="customValidations.length">
      <small class="text-danger d-block my-1" v-for="(error, errIndex) in customValidations" :key="errIndex">{{ error }}</small>
    </div>

    <div class="row pb-0" v-if="!cmp_config.disable_buttons">
      <div class="col">
        <b-btn variant="primary" class="secondary-button" @click.prevent="$_addAction">
          <i class="fa fa-plus"></i> Add
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import component from '@sh/views/Rules/mixins/component';
import TimeInput from '@sh/views/Rules/components/subcomponents/TimeInput';
import Scheduler from '@sh/views/Rules/components/subcomponents/Scheduler';
import RuleDateConditionField from '@sh/views/Rules/components/subcomponents/RuleDateConditionField.ts.vue';
import { RuleHelpers } from '@sh/helpers';
import { RuleTypes } from '@sh/types';
import Datepicker from 'vuejs-datepicker';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import moment from 'moment';

export default {
  name: 'RuleConditions',
  components: {
    Datepicker,
    TimeInput,
    Scheduler,
    RuleDateConditionField,
    OptimizerIcon,
  },
  mixins: [component],
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      cmp_config: this.ruleModel.rule.components.conditions,
      localModel: [{ field1: null, field2: null, field3: { value: '', unit: 'static', to: null } }],
      model: [],
      optionsData: cloneDeep({
        fieldsGroup: this.config.conditionsConfig.fieldsGroup.map((group) => ({ ...group, content: group.label })),
        fields: this.config.conditionsConfig.fields,
        conditions: this.config.conditionsConfig.conditions,
        nameConditions: this.config.conditionsConfig.nameConditions,
        learningStateConditions: this.config.conditionsConfig.learningStateConditions,
        statusConditions: this.config.conditionsConfig.statusConditions,
        toFields: this.config.conditionsConfig.toFields,
        toSelfFields: this.config.conditionsConfig.toSelfFields,
        dateFields: this.config.conditionsConfig.dateFields,
        customDateConditions: this.config.conditionsConfig.customDateConditions,
        conditionsDailyCap: this.config.conditionsConfig.conditionsDailyCap,
        campaignOnlyFields: ['target_cpa', 'payout'],
        placementsNotAllowedGroups: ['tr', 'ga', 'ga4', 'cc', 'cm', 'pf', 'amazondsp', 'ayf'],
      }),
      customValidations: [],
      errors: {},
      unitOptions: [{ value: 'static', text: '...' }, { value: 'percentage', text: '% of campaign' }, { value: 'self', text: '% of' }],
      isDuplicateAnimation: [],
    };
  },
  computed: {
    $c_field1Options() {
      const exceptions = {};
      const hour_of_day = 'hour_of_day';
      let options = [];

      if (this.cmp_config.except) this.cmp_config.except.forEach((key) => { exceptions[key] = true; });
      this.optionsData.fields.forEach((field) => {
        if (!this.cmp_config.filter_fields || this.cmp_config.filter_fields.includes(field.value.key)) {
          if (!exceptions[field.value.key]) {
            options.push({ ...field, inputGroup: field.group });
          }
        }
        if (field.value.key === hour_of_day) {
          field.disabled = !!this.localModel.find((item) => this.$_value(item.field1).key === hour_of_day);
        }
      });
      const optionsMappedByKey = options.reduce((obj, option) => Object.assign(obj, { [option.value.key]: option }), {});
      const foundCampaignOnlyFields = this.optionsData.campaignOnlyFields.filter((field) => optionsMappedByKey[`campaign_${field}`] !== undefined);
      const isFacebookCase = ['Widget', 'Domain', 'Exchange'].includes(this.ruleModel.rule.level) && this.ruleModel.rule.ts_types.length === 1 && this.ruleModel.rule.ts_types.includes('Facebook');
      if (isFacebookCase) {
        options = options.filter((option) => !this.optionsData.placementsNotAllowedGroups.includes(option.group));
      }

      return options.filter((option) => !foundCampaignOnlyFields.includes(option.value.key));
    },
    $c_fieldToSelfOptions() {
      return this.$c_field1Options.filter((option) => !option.notAvailableForToComparison && (option.value.key !== 'hour_of_day' && option.group !== 'campaign') && this.optionsData.toSelfFields.find((field) => field.value.key === option.value.key));
    },
    $c_enableToFields() {
      return !!this.optionsData?.toFields?.length;
    },
    $c_isField3Active() {
      return this.localModel.map((item) => (['percentage', 'self'].includes(item.field3.unit)));
    },
    $c_fieldsToOptions() {
      if (this.$c_enableToFields) {
        return this.optionsData.toFields.map((field) => ({ ...field, inputGroup: field.group }));
      }
      return [];
    },
    $c_colsClassName() {
      if (this.$c_enableToFields) {
        return {
          field1: 'col-xxl-4',
          field2: 'col-xxl-3',
          field3: 'col-xxl-5',
        };
      }

      return {
        field1: 'col-xxl-5',
        field2: 'col-xxl-4',
        field3: 'col-xxl-3',
      };
    },
    $c_daysActions() {
      return [
        { value: RuleTypes.ConditionsV2.DateOperationsType.Add, text: 'Plus' },
        { value: RuleTypes.ConditionsV2.DateOperationsType.Remove, text: 'Minus' },
      ];
    },
  },
  watch: {
    localModel: {
      immediate: true,
      handler(localModel) {
        this.model = localModel.reduce((model, item) => {
          if (item.field1) {
            const modelItem = {};

            modelItem[this.$_value(item.field1).key] = { value: item.field3?.value ?? '' };
            modelItem[this.$_value(item.field1).key].condition = item?.field2?.value?.key ?? null;

            try {
              modelItem[this.$_value(item.field1).key].to = item.field3.unit === 'percentage' ? item.field3.to.value.key : 'static';
              modelItem[this.$_value(item.field1).key].to_self = item.field3.unit === 'self' ? item.field3.to_self.value.key : 'static';
            } catch {
              modelItem[this.$_value(item.field1).key].to = 'static';
              modelItem[this.$_value(item.field1).key].to_self = 'static';
            }
            modelItem[this.$_value(item.field1).key].conditionV2 = RuleHelpers.ConditionsV2.getCondition(item);
            model.push(modelItem);
          }
          return model;
        }, []);
      },
      deep: true,
    },
    model: {
      immediate: true,
      handler() {
        this.$_emit('model');
      },
      deep: true,
    },
  },
  created() {
    if (this.value.length) {
      /** ********** Set LocalModel ************* */
      const fields1Map = {};
      this.optionsData.fields.forEach((item) => { fields1Map[item.value.key] = { ...item }; });
      const fields2Map = {};
      this.optionsData.conditions.forEach((item) => { fields2Map[item.value.key] = item; });
      this.optionsData.nameConditions?.forEach((item) => { fields2Map[item.value.key] = item; });
      // Name Options
      const fields3Map = {};
      this.optionsData.statusConditions?.forEach((item) => { fields3Map[item.value.key] = item; });
      this.optionsData.learningStateConditions?.forEach((item) => { fields3Map[item.value.key] = item; });
      const localModel = [];
      this.value.forEach((obj) => {
        let key = Object.keys(obj)[0];
        const item = obj[key];
        // Set Campaign To Option
        let unit = 'static';
        if (item.to && item.to !== 'static') {
          unit = 'percentage';
        } else if (item.to_self && item.to_self !== 'static') {
          unit = 'self';
        }
        // const unit = item.to && item.to !== 'static' ? item.to : 'static';
        let to = null;
        let to_self = null;
        if (unit === 'percentage') {
          const BreakException = {};
          try {
            this.$c_fieldsToOptions.forEach((option) => {
              if (option.value.key === item.to) {
                to = {
                  ...option,
                  interval: item.conditionV2?.right?.interval,
                };
                throw BreakException;
              }
            });
          } catch (e) {
            if (e !== BreakException) throw e;
          }
        } else if (unit === 'self') {
          const BreakException = {};
          try {
            const dateField = this.optionsData.dateFields.find((field) => field.value.key === item.to_self);
            if (dateField) {
              to_self = {
                ...dateField,
                ...(item.to_self === 'specific_date' && { specificDate: item.conditionV2?.right?.value }),
                operations: item.conditionV2?.right?.operations,
              };
              throw BreakException;
            }
            this.$c_fieldToSelfOptions.forEach((option) => {
              if (option.value.key === item.to_self) {
                to_self = {
                  ...option,
                  interval: item.conditionV2?.right?.interval,
                };
                throw BreakException;
              }
            });
          } catch (e) {
            if (e !== BreakException) throw e;
          }
        }

        key = RuleHelpers.ConditionsV2.convertKey(key, to_self?.value?.key);

        const field1 = cloneDeep(fields1Map[key]) || null;
        if (field1) {
          field1.interval = item.conditionV2?.left?.interval;
        }
        const field2 = cloneDeep(fields2Map[item.condition]) || null;
        const field3 = cloneDeep(key === 'status' || key === 'learning_state' ? fields3Map[item.value] : { value: item.value, unit, to, to_self });
        localModel.push({ field1, field2, field3 });
      });
      this.localModel = localModel;
      /** *************************************** */
    }
  },
  methods: {
    async validate(force) {
      try {
        const forms = [this.$validator.validateAll(), this.$_validateCustom(force), this.validateComponents()];
        if (this.$refs.hourOfDay && this.$refs.hourOfDay.length > 0) forms.push(...this.$refs.hourOfDay.map((hod) => hod.validate()));
        const validationForms = await Promise.all(forms);
        const valid = validationForms.reduce((status, formStatus) => status && formStatus, true);
        return valid;
      } catch (error) {
        return false;
      }
    },
    validateComponents() {
      return Object.keys(this.$refs).reduce((isValid, ref) => {
        if (ref.includes('validate')) {
          const isRefValid = this.$refs[ref]?.[0]?.validate() ?? true;
          isValid = isValid && isRefValid;
        }
        return isValid;
      }, true);
    },
    $_validateCustom(force) {
      this.customValidations = [];
      if (this.$_getValidationRule('no_alone_scheduler', force)) {
        if (this.localModel.length < 2) {
          let field1 = '';
          try {
            field1 = this.localModel[0].field1.value.key;
          } catch (err) {
            // Nothing
          }
          if (field1 === 'hour_of_day') this.customValidations.push('You need to select at least one more condition! Rules with only time condition are not allowed.');
        }
      }
      return this.customValidations.length === 0;
    },
    $_removeAction(index) {
      if (this.localModel.length > 1) this.localModel.splice(index, 1);
    },
    $_duplicateAction(index) {
      if (!this.isDuplicateAnimation[index]) {
        this.$set(this.isDuplicateAnimation, index, true);
        setTimeout(() => {
          this.$set(this.isDuplicateAnimation, index, false);
        }, 1000);
        this.localModel.push(cloneDeep(this.localModel[index]));
      }
    },
    async $_addAction() {
      if (await this.validate({ no_alone_scheduler: false })) {
        this.localModel.push({
          field1: null,
          field2: null,
          field3: { value: '', unit: 'static', to: null, to_self: null },
          field4: null,
        });
      }
    },
    $_isTextField(field) {
      return !!['name', 'headline', 'campaign_name'].includes(field);
    },
    $_isStatusField(field) {
      return field === 'status';
    },
    $_isLearningState(field) {
      return ['campaign_learning_state', 'learning_state'].includes(field);
    },
    $_isDateField(field) {
      return field?.baseType === RuleTypes.Conditions.BaseFieldType.Date;
    },
    $_isStartDate(row) {
      return RuleHelpers.ConditionsV2.isCustomDateCondition(row.field1?.value?.key, [RuleTypes.ConditionsV2.CustomDateCondition.StartDate, RuleTypes.ConditionsV2.CustomDateCondition.CreatedAt]);
    },
    getDaysActionValue(row) {
      return row.field3?.to_self?.operations?.[0]?.type ?? RuleTypes.ConditionsV2.DateOperationsType.Add;
    },
    getDaysInputValue(row) {
      return row.field3?.to_self?.operations?.[0]?.value ?? 0;
    },
    onDateConditionChange(row) {
      let selected = {};
      let value = 0;

      if (row.field1?.value?.key?.includes('custom')) {
        selected = this.optionsData.dateFields.find((field) => field.value.key === 'current_date');
        value = this.$_isStartDate(row) ? -3 : +3;
      } else {
        selected = this.optionsData.dateFields.find((field) => field.value.key === 'specific_date');
      }

      this.onDateFieldChange(row, selected, value);
    },
    onDateFieldChange(row, selected, value) {
      row.field3.unit = 'self';
      row.field3.to_self = {
        ...selected,
        specificDate: moment().format('YYYY-MM-DD'),
        operations: RuleHelpers.ConditionsV2.getDateOperations([value]),
      };
    },
    onDaysInput(row, value) {
      if (row.field3.to_self.operations?.[0].type === RuleTypes.ConditionsV2.DateOperationsType.Remove) {
        value = -value;
      }
      row.field3 = {
        ...row.field3,
        to_self: {
          ...row.field3.to_self,
          operations: RuleHelpers.ConditionsV2.getDateOperations([value]),
        },
      };
    },
    $_changeField1(row, selected) {
      const selectedKey = selected?.value?.key;
      const currentKey = row.field1?.value?.key;

      row.field1 = selected;

      // Custom conversion
      const ccTrackerId = selectedKey?.split('::')[1];

      if (ccTrackerId && selected.group === 'cc') {
        this.localModel.forEach((item) => {
          if (item.field1 && item.field1.group === 'cc' && item.field1.value.key.indexOf('::') > -1 && item.field1.value.key.indexOf(ccTrackerId) === -1) {
            item.field1 = null;
          }
        });
      }

      if (row.field1 && selectedKey !== currentKey) {
        row.field2 = null;
        row.field3 = { value: '', unit: 'static', to: null, to_self: null };
      }
    },
    $_value(obj) {
      try {
        return obj.value || {};
      } catch (err) {
        return {};
      }
    },
    $_getValidationRule(name, force) {
      if (this.cmp_config['v-validate'] && typeof this.cmp_config['v-validate'][name] !== 'undefined') {
        return force && typeof force[name] !== 'undefined' ? force[name] : this.cmp_config['v-validate'][name];
      }
      return null;
    },
    $_getToUnitOptions(field1) {
      const filterOptionsByLevel = (value) => {
        if (this.ruleModel?.rule?.level === 'Campaign') {
          return value !== 'percentage';
        }
        return true;
      };
      const options = this.unitOptions.map((option) => {
        if (option.value === 'static') {
          try {
            return { ...option, text: this.config.conditionsConfig.fieldsMap[field1.value.key].value.placeholder || '...' };
          } catch (err) {
            // Nothing
          }
        }
        return option;
      }).filter((option) => filterOptionsByLevel(option.value));

      if (field1?.group === 'campaign') {
        return options.filter((option) => !['percentage', 'self'].includes(option.value));
      }
      return options;
    },
    getErrorState(name) {
      const index = name.split('-')[1];
      const checkErrors = !name.includes('field3') || this.$c_isField3Active[index];

      return this.$_hasError(name) ?? (this.errors[name] && checkErrors ? false : null);
    },
    onErrorUpdate(item) {
      this.errors = {
        ...this.errors,
        ...item,
      };
    },
    getDateConditionOptions(row) {
      if (row?.field1?.value?.key.includes('custom')) {
        return this.optionsData.customDateConditions;
      }
      return this.optionsData.conditions;
    },
  },
};
</script>

<style lang="scss">
.rule-conditions-wrapper {
  .rule-conditions {
    margin-bottom: 2.5rem;
  }

  .optimizer-select {
    &.has-error > .dropdown > .btn.dropdown-toggle {
      border-color: #ff5454;
    }
  }

  .optimizer-form-group {
    .dropdown {

      .dropdown-toggle {
        padding: 0.5rem 1.2rem;
        height: 4rem;
      }
      .dropdown-menu {
        min-width: 13rem;
      }
    }

    &.field1 {
      .input-group {
        &-prepend {
          .input-group-text {
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &.field3 {
      &.date-field {
        .cc-date-picker {
          height: 4rem;

          .cc-input {
            width: 13rem;
            height: 4rem;
          }
        }

        .optimizer-select {
          max-width: unset;
          min-width: 12.5rem;
        }

        .days-group-wrapper {
          .input-group-prepend {
            margin: 0;
          }

          @include media-breakpoint-up(sm) {
            min-width: 21rem;

            .text {
              border-left: 0 !important;
            }

            .optimizer-select .dropdown-toggle {
              border-right: 0;
            }
          }

          .optimizer-select {
            margin: 0;
            min-width: 8rem;

            .dropdown-toggle {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              background: #f4f9fc;

              &::after {
                margin: 0;
              }

              .button-placehoder-selected {
                padding-right: 0;
                font-size: 1.4rem;
              }
            }
          }

          .optimizer-form-input {
            height: 4rem;
            min-width: unset;
            max-width: 10rem;
          }

          .text {
            background: #f4f9fc;
            border: 0.1rem solid $color-light-gray;
            border-radius: 0 0.6rem 0.6rem 0;
            display: flex;
            align-items: center;
            color: $black-600;
            padding: 0.8rem;
            height: 4rem;
          }
        }
      }

      .time-input .display-time {
        height: 4rem;
      }

      > .optimizer-form-input {
        min-width: 8rem;
      }

      .field3-unit {
        min-width: 14rem;
      }

      .field3-input {
        min-width: 10rem;
        padding: 0.4rem;
      }

      .optimizer-select {
        margin-left: 1rem;

        &.date-condition-field {
          min-width: 5rem;
        }
      }

      .input-group {
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
          gap: 1.5rem;
        }
      }
    }

    .input-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      &-prepend,
      &-append {
        min-width: 4rem;
        display: flex;
        justify-content: center;

        .input-group-text {
          background: transparent;
          border: none;
          display: block;
          font-size: 1.5rem;
          text-transform: lowercase;
          color: $text-primary;
          font-weight: bold;
        }
      }

      &-prepend {
        margin-right: .5rem;
      }

      &-append {
        margin-left: 1rem;
      }

      input {
        border-top-right-radius: .6rem;
        border-bottom-right-radius: .6rem;
      }
    }
  }

  .hide-rule-row-btn {
    background: #E4EAEE;
    border-radius: .6rem;
    color: #546582;
    font-size: 1.5rem;
    padding: .5rem 1rem;
    border: none;

    &:hover,
    &:active,
    &:focus {
      background: #E4EAEE !important;
      color: #546582 !important;
    }
  }

  .row-actions {
    margin-left: auto;
    padding-left: 1.2rem;
    display: flex;
    gap: 0.8rem;
  }

  .row {
    margin: -7px !important;
    padding-bottom: 1rem;

    & > * {
      padding: 7px;
    }
  }
  .form-group {
    margin-bottom: 0px;

    .input-group-addon {
      font-weight: bold;
      color: #99979c;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      display: block;
    }

    &.day-of-hour-form-group {
      & > div[role=group] {
        position: relative;
        > .form-control {
          padding-top: 10px;
        }
      }
      .dropdown-menu {
        z-index: 999999;
      }
      .day-of-hour-remove-button {
        display: block;
        position: absolute;
        top: 1.3rem;
        right: 0;
        padding: 0px 0px 5px 5px;
        background-color: #FFF;
      }
      .hour-of-day-wrapper table thead > tr {
        line-height: 31px;
      }
    }
  }
}
</style>
