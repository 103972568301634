<template>
  <div class="optimizer-skeleton">
    <div :class="[ type === 'card' ? 'optimizer-skeleton-container--card optimizer-card' : '', 'optimizer-skeleton__container']">

      <!-- Card Skeleton -->
      <template v-if="type === 'card'">
        <div class="optimizer-skeleton__container__rows">
          <div
            v-for="(row, index) in card.rows"
            :key="index"
            class="optimizer-skeleton__container__row"
          />
        </div>
        <div
          v-if="card.shape"
          :class="`optimizer-skeleton__container__shape-${card.shape}`"
        />
      </template>

      <!-- Table Skeleton -->
      <template v-else-if="type === 'table'">
        <!-- Actions -->
        <div v-if="table.filters || table.search" class="optimizer-skeleton__actions">
          <!-- <div class="optimizer-skeleton__actions__action"> -->
          <div v-if="table.filters" class="optimizer-skeleton__actions__filters">
            <div v-for="(item, index) in table.filterItems" :key="index" class="optimizer-skeleton__actions__filters__item"></div>
          </div>
          <!-- </div> -->
          <!-- <div class="optimizer-skeleton__actions__action"> -->
          <div v-if="table.search" class="optimizer-skeleton__actions__search"></div>
          <!-- </div> -->
        </div>

        <!-- Table -->
        <div class="optimizer-skeleton-table optimizer-card">
          <div class="optimizer-skeleton-table__head">
            <div v-for="(item, index) in table.headItems" :key="index" class="optimizer-skeleton-table__head__data" />
          </div>
          <div class="optimizer-skeleton-table__body">
            <div v-for="(field, index) in table.bodyItems" :key="index" class="optimizer-skeleton-table__body__data" />
          </div>
        </div>
      </template>

      <!-- Form Skeleton -->
      <template v-else-if="type === 'form'">
        <div class="optimizer-skeleton__container__rows optimizer-skeleton-form">
          <div class="optimizer-skeleton__container__label"></div>
          <div class="optimizer-skeleton__container__row"></div>
        </div>
      </template>

      <!-- Line Skeleton -->
      <template v-else-if="type === 'line'">
        <div class="optimizer-skeleton__container__rows optimizer-skeleton-form">
          <div class="optimizer-skeleton__container__label line-sizing"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSkeleton',
  props: {
    type: { type: String, default: 'card' },
    card: { type: Object, default: () => ({ rows: 1, shape: 'square' }) },
    table: {
      type: Object,
      default: () => ({ headItems: 5, bodyItems: 5, filters: true, search: true, filterItems: 1 }),
    },
  },
};
</script>

<style lang="scss">

@mixin skeleton {
  // background: #f2f2f2;
  background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
  background-size: 400% 100%;
  animation: loading-skeleton 1.1s ease infinite;
  border-radius: .5rem;
}

.optimizer-skeleton {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.optimizer-skeleton-container--card {
      padding: 2rem;
    }

    &__rows {
      .optimizer-skeleton__container__row {
        @include skeleton;
        height: 1.5rem;
        margin-bottom: 1.5rem;
        width: 14rem;

        &:first-of-type {
          width: 10rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__shape-square {
      @include skeleton;
      flex: 1;
      margin-top: 1.5rem;
    }

    .optimizer-skeleton__actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      // padding: 1rem;
      // background: #fff;
      // border-radius: 1rem;

      &__filters {
        display: flex;

        &__item {
          @include skeleton;
          border: 1rem solid #fff;
          outline: $border;
          width: 15rem;
          height: 4rem;
          margin-right: 1rem;
        }
      }

      &__search {
        @include skeleton;
        border: 1rem solid #fff;
        outline: $border;

        width: 35%;
        height: 4rem;
        margin-left: auto;
      }
    }

    .optimizer-skeleton-table {
      padding: 2rem;

      &__head {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &__data {
          @include skeleton;
          height: 2rem;
          margin-bottom: 1.5rem;
          width: 15%;
        }
      }

      &__body {
        &__data {
          @include skeleton;
          height: 3rem;
          margin-bottom: 1rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .optimizer-skeleton-form {

      .optimizer-skeleton__container__label {
        @include skeleton;
        width: 20rem;
        height: 1.5rem;
        margin-bottom: 1rem;
      }

      .optimizer-skeleton__container__row {
        width: 100%;
        height: 3rem;
      }

      .line-sizing {
        padding: 1.6rem;
        width: 278px;
      }
    }
  }
}

.campaigns-select-skeleton {
    height: 3.7rem;

    .optimizer-skeleton__container {
      padding: 1rem;
      border-radius: .7rem;

      &__rows {
        height: 100%;
        display: flex;
        justify-content: space-between;
      }

      &__row {
        width: 100% !important;
        height: 100%;
        margin-right: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .dashboard-main-chart-skeleton {
      height: 41.5rem;

      .optimizer-skeleton__container {
        display: flex;
        flex-direction: row;

        &__rows {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 2.5rem;
        }

        &__row {
          display: block;
          width: 10rem;
          height: 1.3rem;
        }

        &__shape-square {
          margin-top: 0;
        }
      }
    }

@keyframes loading-skeleton {
  0% {
    background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
</style>
