import report from './report';

export default (F) => ({
  ...report(F),
  client_email: F.create({
    item: {
      key: 'client_email',
      slot: 'clientEmail',
      content: (item) => (item.headline.trim().length ? item.headline.trim() : item.traffic_source_content_id),
      sortable: true,
      total: {
        parse: () => 1,
        content: (totals) => (totals.totalElements ? `Total: ${totals.totalElements}` : '-'),
      },
    },
    colClass: 'client-email sticky sticky--headline',
  }),
});
