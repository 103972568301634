export default [
  {
    id: '1',
    name: 'English',
    code: 'en',
  },
  {
    id: '2',
    name: 'Spanish',
    code: 'es',
  },
  {
    id: '65',
    name: 'Afrikaans',
    code: 'af',
  },
  {
    id: '46',
    name: 'Albanian',
    code: 'sq',
  },
  {
    id: '41',
    name: 'Arabic',
    code: 'ar',
  },
  {
    id: '34',
    name: 'Armenian',
    code: 'hy',
  },
  {
    id: '37',
    name: 'Azerbaijani',
    code: 'az',
  },
  {
    id: '67',
    name: 'Belarusian',
    code: 'be',
  },
  {
    id: '47',
    name: 'Bengali',
    code: 'bn',
  },
  {
    id: '26',
    name: 'Bosnian',
    code: 'bs',
  },
  {
    id: '29',
    name: 'Bulgarian',
    code: 'bg',
  },
  {
    id: '62',
    name: 'Burmese',
    code: 'my',
  },
  {
    id: '43',
    name: 'Chinese',
    code: 'zh',
  },
  {
    id: '19',
    name: 'Croatian',
    code: 'hr',
  },
  {
    id: '24',
    name: 'Czech',
    code: 'cs',
  },
  {
    id: '22',
    name: 'Danish',
    code: 'da',
  },
  {
    id: '6',
    name: 'Dutch',
    code: 'nl',
  },
  {
    id: '33',
    name: 'Estonian',
    code: 'et',
  },
  {
    id: '23',
    name: 'Filipino',
    code: 'fil',
  },
  {
    id: '21',
    name: 'Finnish',
    code: 'fi',
  },
  {
    id: '3',
    name: 'French',
    code: 'fr',
  },
  {
    id: '44',
    name: 'Georgian',
    code: 'ka',
  },
  {
    id: '4',
    name: 'German',
    code: 'de',
  },
  {
    id: '10',
    name: 'Greek',
    code: 'el',
  },
  {
    id: '58',
    name: 'Gujarati',
    code: 'gu',
  },
  {
    id: '42',
    name: 'Hebrew',
    code: 'he',
  },
  {
    id: '12',
    name: 'Hindi',
    code: 'hi',
  },
  {
    id: '15',
    name: 'Hungarian',
    code: 'hu',
  },
  {
    id: '9',
    name: 'Indonesian',
    code: 'id',
  },
  {
    id: '7',
    name: 'Italian',
    code: 'it',
  },
  {
    id: '28',
    name: 'Japanese',
    code: 'ja',
  },
  {
    id: '61',
    name: 'Kannada',
    code: 'kn',
  },
  {
    id: '63',
    name: 'Kazakh',
    code: 'kk',
  },
  {
    id: '13',
    name: 'Khmer',
    code: 'km',
  },
  {
    id: '25',
    name: 'Korean',
    code: 'ko',
  },
  {
    id: '56',
    name: 'Kyrgyz',
    code: 'ky',
  },
  {
    id: '57',
    name: 'Lao',
    code: 'lo',
  },
  {
    id: '38',
    name: 'Latvian',
    code: 'lv',
  },
  {
    id: '36',
    name: 'Lithuanian',
    code: 'lt',
  },
  {
    id: '31',
    name: 'Macedonian',
    code: 'mk',
  },
  {
    id: '16',
    name: 'Malay',
    code: 'ms',
  },
  {
    id: '60',
    name: 'Malayalam',
    code: 'ml',
  },
  {
    id: '59',
    name: 'Marathi',
    code: 'mr',
  },
  {
    id: '69',
    name: 'Mongolian',
    code: 'mn',
  },
  {
    id: '45',
    name: 'Nepali',
    code: 'ne',
  },
  {
    id: '18',
    name: 'Norwegian',
    code: 'no',
  },
  {
    id: '53',
    name: 'Persian',
    code: 'fa',
  },
  {
    id: '20',
    name: 'Polish',
    code: 'pl',
  },
  {
    id: '8',
    name: 'Portuguese',
    code: 'pt',
  },
  {
    id: '66',
    name: 'Punjabi',
    code: 'pa',
  },
  {
    id: '17',
    name: 'Romanian',
    code: 'ro',
  },
  {
    id: '39',
    name: 'Russian',
    code: 'ru',
  },
  {
    id: '35',
    name: 'Serbian',
    code: 'sr',
  },
  {
    id: '48',
    name: 'Sinhalese',
    code: 'si',
  },
  {
    id: '27',
    name: 'Slovak',
    code: 'sk',
  },
  {
    id: '30',
    name: 'Slovene',
    code: 'sl',
  },
  {
    id: '54',
    name: 'Somali',
    code: 'so',
  },
  {
    id: '68',
    name: 'Swahili',
    code: 'sw',
  },
  {
    id: '14',
    name: 'Swedish',
    code: 'sv',
  },
  {
    id: '55',
    name: 'Tajik',
    code: 'tg',
  },
  {
    id: '51',
    name: 'Tamil',
    code: 'ta',
  },
  {
    id: '52',
    name: 'Telugu',
    code: 'te',
  },
  {
    id: '11',
    name: 'Thai',
    code: 'th',
  },
  {
    id: '32',
    name: 'Turkish',
    code: 'tr',
  },
  {
    id: '64',
    name: 'Turkmen',
    code: 'tk',
  },
  {
    id: '50',
    name: 'Ukrainian',
    code: 'uk',
  },
  {
    id: '40',
    name: 'Urdu',
    code: 'ur',
  },
  {
    id: '49',
    name: 'Uzbek',
    code: 'uz',
  },
  {
    id: '5',
    name: 'Vietnamese',
    code: 'vi',
  },
];
