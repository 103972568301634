<template>
  <div class="rule-name-option-wrapper">
    <b-form-group
      class="optimizer-form-group"
      label="Rule Name"
      :state="$_hasError('name')"
      :invalid-feedback="$_getError('name')"
    >
      <b-form-input
        class="optimizer-form-input"
        type="text"
        v-model="name"
        name="name"
        placeholder="Rule Name"
        v-validate="'required'"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleName',
  mixins: [component],
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      name: '',
    };
  },
  watch: {
    name: {
      immediate: true,
      handler() {
        this.$_emit('name');
      },
      deep: true,
    },
  },
  created() {
    this.name = this.value;
  },
  methods: {
    // $_emit () {
    //   this.$emit('input', this.name)
    // }
  },
};
</script>

<style lang="scss">
.rule-name-option-wrapper {
  .optimizer-form-group {
    margin-bottom: 0;
  }
}
</style>
