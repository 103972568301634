var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.preloader)?_c('div',{staticClass:"traffic-source-wraper align-tems:center"},[_c('b-alert',{attrs:{"show":!!_vm.alert.message,"variant":_vm.alert.type,"dismissible":""},on:{"dismissed":function($event){return _vm.$_dismissAlert()}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.alert.message)}})]),_c('div',[_c('vue-opti-select-light',{ref:"trafficSourceAccount",staticClass:"optimizer-select",attrs:{"options":_vm.mode.accounts,"unique-key":"id","option-type":"checkbox","search-fields":['name', 'id'],"button-placeholder":"All Accounts","button-placeholder-all-selected":"All Accounts","button-placeholder-multiple":({ count, suffix }) => `${count} Account${suffix}`,"searchable":"","button-block":"","disabled":_vm.mode.accounts.length === 0},on:{"input":_vm.$_changeTrafficSourceAccount},scopedSlots:_vm._u([_vm._l((_vm.mode.accounts),function(item){return {key:`ITEM_BEFORE_${item.id}`,fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:({
            html: true,
            customClass: 'custom-tooltip max-width-400',
            title: item.name,
            interactive: false,
            boundary: 'viewport',
          }),expression:"{\n            html: true,\n            customClass: 'custom-tooltip max-width-400',\n            title: item.name,\n            interactive: false,\n            boundary: 'viewport',\n          }",modifiers:{"hover":true,"bottom":true}}],key:item.id,staticClass:"option-tooltip",class:{ 'not-allowed': item.disabled },on:{"click":function($event){return _vm.onOptionClick(item)}}})]},proxy:true}}),{key:"ITEM",fn:function({ option }){return [_c('img',{staticClass:"acc-item-icon",attrs:{"src":_vm.getTrafficSourceLogo(option.typeName),"alt":"traffic-source-logo"}}),_c('div',{staticClass:"acc-item-name d-flex m-0"},[_c('div',{staticClass:"acc-item-name-text"},[_vm._v(" "+_vm._s(option.name)+" ")]),(option.disabled)?_c('div',{staticClass:"disabled-account-info"},[_c('i',{staticClass:"fa fa-check-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" (Already Connected) ")]):_vm._e()])]}},{key:"HEADER_2",fn:function(){return [_c('header',{staticClass:"select-dropdown-actions"},[_c('b-btn',{staticClass:"secondary-button select-all-btn",on:{"click":_vm.$_toggleSelectAllAccounts}},[_vm._v(" "+_vm._s(_vm.$_toggleSelectText)+" All Accounts ")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.accounts),callback:function ($$v) {_vm.$set(_vm.form, "accounts", $$v)},expression:"form.accounts"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }