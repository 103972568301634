<template>
  <div class="rule-action-campaign-wrapper">
    <b-form-group class="action-group-wrapper mb-0">
      <b-input-group>
        <b-input-group-addon>
          <vue-opti-select-light
            class="optimizer-select action-select w-100"
            :value="action.valid"
            @change="(option) => { action.valid = option.value }"
            :options="$c_validOptions"
            label-key="text"
            single
            button-block
          />
        </b-input-group-addon>
        <b-input-group-addon class="state-addon addon-middle">
          Otherwise
        </b-input-group-addon>
        <b-input-group-addon>
          <vue-opti-select-light
            class="optimizer-select otherwise-select w-100"
            :value="action.otherwise"
            @change="(option) => { action.otherwise = option.value }"
            :options="$c_otherwiseOptions"
            label-key="text"
            single
            button-block
          />
        </b-input-group-addon>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleActionCampaign',
  mixins: [component],
  props: {
    value: { type: Object, default: () => ({ valid: 'start', otherwise: 'pause' }) },
  },
  data() {
    return {
      action: { valid: 'start', otherwise: 'pause' },
    };
  },
  computed: {
    $c_validOptions() {
      return [
        {
          value: 'pause',
          text: 'Pause Campaign',
        },
        {
          value: 'start',
          text: 'Start Campaign',
        },
      ];
    },
    $c_otherwiseOptions() {
      return [
        {
          disabled: this.action.valid === 'pause',
          value: 'pause',
          text: 'Pause Campaign',
        },
        {
          disabled: this.action.valid === 'start',
          value: 'start',
          text: 'Start Campaign',
        },
        {
          value: 'nothing',
          text: 'Do Nothing',
        },
      ];
    },
  },
  watch: {
    'action.valid': function (value) {
      if (value === this.action.otherwise) {
        this.action.otherwise = value === 'start' ? 'pause' : 'start';
      }
    },
    action: {
      immediate: true,
      handler() {
        this.$_emit('action');
      },
      deep: true,
    },
  },
  created() {
    this.action = this.value;
  },
  methods: {
  },
};
</script>

<style lang="scss">
.rule-action-campaign-wrapper {
  .input-group {
    display: flex;

    .input-group-prepend {
      flex-basis: 25%;
      min-width: auto;

      &.state-addon {
        flex-basis: 15%;
        height: 3.7rem;
        background: #F4F9FC;
        border: .1rem solid $color-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black-600;
        padding: 0.7rem 1.2rem;
        font-size: 1.4rem;
      }

      .optimizer-select {
        min-width: 16rem;

        &.action-select {
          .dropdown-toggle {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &.otherwise-select {
          .dropdown-toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
</style>
