<template>
  <div class="content-uploader-form-wrapper" v-if="drawComponent">
    <div class="row">
      <div class="col-lg-6 side-form">
        <div class="optimizer-card p-24">
          <!-- Traffic Source Type -->
          <b-form-group class="optimizer-form-group">
            <template slot="label"> Traffic Source:<span class="text-danger"> *</span> </template>
            <div id="trafficSourceInput" class="optimizer-form-input placeholder-input">
              <img :src="sourceLogoUrl" class="source-logo" />{{ trafficSourceType.name }}
            </div>
          </b-form-group>

          <!-- Traffic Source Account -->
          <b-form-group class="optimizer-form-group">
            <template slot="label"> Account:<span class="text-danger"> *</span> </template>
            <div id="accountInput" class="optimizer-form-input placeholder-input">
              <img :src="sourceLogoUrl" class="source-logo" />{{ account.name }}
            </div>
          </b-form-group>

          <!-- Campaign -->
          <b-form-group class="optimizer-form-group">
            <template slot="label"> Campaign:<span class="text-danger"> *</span> </template>
            <div id="campaignInput" class="optimizer-form-input placeholder-input">
              <img :src="sourceLogoUrl" class="source-logo" />{{ campaign.name }}
            </div>
          </b-form-group>

          <!-- Gemini adgroup select only if not vuex -->
          <b-form-group v-if="adgroups" class="optimizer-form-group">
            <label slot="label"> Ad group:<span class="text-danger"> *</span> </label>
            <search-select
              id="adgroups"
              placeholder="Select adgroup"
              label="name"
              item-value="id"
              :items="adgroups"
              v-model="form.adgroupId"
              watch
            ></search-select>
          </b-form-group>

          <!-- Target Url -->
          <target-url
            :tokens="config.target.tokens"
            :validate="config.target.validation"
            v-model="form.targetUrl"
            :type="trafficSourceType.name"
            :disabled="$c_disableTargetUrl"
          />

          <!-- Display Url -->
          <display-url
            v-if="config.displayUrl"
            v-model="form.displayUrl"
            :max-character="config.displayUrl.maxCharacter"
          />

          <!-- Sponsored By -->
          <sponsored-by
            v-if="config.sponsoredBy && !isEdit"
            :label-cols="3"
            breakpoint="md"
            :max-character="config.sponsoredBy.maxCharacter"
            v-model="form.sponsoredBy"
          />

          <!--Call to action button-->
          <b-form-group v-if="config.callToAction" class="optimizer-form-group">
            <label slot="label"> Call-to-action button: </label>
            <search-select
              id="callToAction"
              placeholder="Select choice"
              label="name"
              item-value="value"
              :items="config.callToActionOptions"
              v-model="form.callToActionText"
            ></search-select>
          </b-form-group>

          <Headlines
            v-if="!isEdit"
            :max-character="config.headline.maxCharacter"
            :tokens="config.headline.tokens"
            :trafficSourceType="trafficSourceType"
            :adDescription="config.headline.adDescription"
            :ctaButton="config.headline.ctaButton"
            v-model="form.headlines"
          />

          <!-- TEASER CATEGORIES -->
          <b-form-group
            v-if="config.category && ['content', 'push'].includes(campaign.type)"
            class="optimizer-form-group"
          >
            <template slot="label"> Category:<span class="text-danger"> *</span> </template>
            <b-input-group size="sm">
              <mgid-categories-dropdown :type="campaign.type" id="categoriesInput" v-model="form.category" />
            </b-input-group>
          </b-form-group>

          <BrandName
            v-if="config.brandName"
            :label-cols="3"
            breakpoint="md"
            :max-character="config.brandName.maxCharacter"
            v-model="form.brandName"
          />

          <AdvertText
            v-if="config.advertText"
            :label-cols="3"
            breakpoint="md"
            :isRequired="config.advertText.required[campaign.type] || false"
            :max-character="config.advertText.maxCharacter[campaign.type]"
            v-model="form.advertText"
          />

          <!-- ContentType -->
          <b-form-group v-if="config.contentType" class="optimizer-form-group">
            <template slot="label"> Content Type:<span class="text-danger"> *</span> </template>
            <b-form-select
              id="contentTypeInput"
              size="sm"
              name="contentTypeInput"
              v-model="form.contentType"
              :options="[
                { value: 'article', text: 'Article' },
                { value: 'app', text: 'Mobile App' },
                { value: 'video', text: 'Video' },
              ]"
            />
          </b-form-group>

          <!-- SITENAME -->
          <b-form-group v-if="config.sectionName" class="optimizer-form-group">
            <template slot="label"> Site name: </template>
            <b-input-group size="sm">
              <b-form-input
                class="optimizer-form-input"
                placeholder="Enter your site name"
                id="sectionName"
                v-model="form.sectionName"
                name="displayUrlInput"
                data-vv-as="Site Name"
                data-vv-delay="0"
                data-vv-validate-on="blur"
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <!-- CPC -->
          <b-form-group v-if="config.cpc" class="optimizer-form-group">
            <label slot="label"> Cpc:<span v-if="config.cpc.required" class="text-danger"> *</span> </label>
            <b-input-group size="sm" class="optimizer-form-group-input">
              <b-form-input
                class="optimizer-form-input cpc-input"
                placeholder="Enter Cpc"
                id="cpcInput"
                type="number"
                :min="config.cpc.min"
                :step="config.cpc.step"
                :disabled="campaign && campaign.disableCpc"
                v-model.number="form.cpc"
                @change="
                  (val) => {
                    if (config.cpc.min && val < config.cpc.min) form.cpc = config.cpc.min;
                  }
                "
                name="cpcInput"
              >
              </b-form-input>
              <b-input-group-addon class="addon-right">{{ config.cpc.prepend }}</b-input-group-addon>
            </b-input-group>
          </b-form-group>

          <!-- DESCRIPTION -->
          <Description
            v-if="config.description"
            :valid="null"
            :label-cols="3"
            breakpoint="md"
            :max-character="config.description.maxCharacter || 0"
            v-model="form.description"
          />

          <!-- Image Uploader -->
          <ImageUploader
            v-if="!isEdit"
            :size="config.image.size"
            :dimension="config.image.dimension"
            :allowed-ext="config.image.allowedExt"
            :max-images="defaults.uploader.maxImages"
            :max-size="defaults.uploader.maxSize"
            :trafficSourceType="trafficSourceType"
            v-model="form.images"
          />
        </div>
      </div>
      <div class="col-lg-6 side-preview">
        <Preview
          :isValidForm="$c_isValidForm"
          :validFromExisting="$c_validFromExisting"
          :images="$c_images"
          :headlines="form.headlines"
          :target-url="form.targetUrl"
          :brand-name="config.brandName ? form.brandName : null"
          :advert-text="config.advertText ? form.advertText : ''"
          :cpc="config.cpc ? form.cpc : ''"
          :section-name="config.sectionName ? form.sectionName : ''"
          :content-type="config.contentType ? form.contentType : ''"
          :category="config.category ? form.category : ''"
          :description="config.description ? form.description : ''"
          :sponsored-by="config.sponsoredBy ? form.sponsoredBy : ''"
          :display-url="config.displayUrl ? form.displayUrl : ''"
          :unique-name="trafficSourceType.uniqueName"
          :existing-contents="existingContents"
          :mode="mode"
          :trafficSourceType="trafficSourceType"
          @addFromExistingContents="$_addFromExistingContents"
          v-model="contents"
          :type="type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uid from 'uid';
import SearchSelect from '@/components/Utils/SearchSelect';
import { getConfigData, getTrafficSourceLogo } from '@sh/helpers';
import TargetUrl from './TargetUrl';
import DisplayUrl from './DisplayUrl';
import SponsoredBy from './SponsoredBy';
import Headlines from './Headlines.ts.vue';
import BrandName from './BrandName.ts.vue';
import AdvertText from './AdvertText';
import Description from './Description';
import ImageUploader from './ImageUploader.ts.vue';
import Preview from './Preview.ts.vue';
import MgidCategoriesDropdown from './MgidCategoriesDropdown/MgidCategoriesDropdown';
import TrafficSourceConfig from '@sh/helpers/TrafficSourceConfig';

export default {
  name: 'ContentUploaderForm',
  components: {
    TargetUrl, DisplayUrl, AdvertText, SponsoredBy, Headlines, BrandName, Description, ImageUploader, Preview, SearchSelect, MgidCategoriesDropdown,
  },
  props: {
    trafficSourceType: { type: Object, required: true },
    account: { type: Object, required: true },
    campaign: { type: Object, required: true },
    cmp: { type: Object, required: false },
    adgroups: { type: Array, required: false },
    adGroup: { type: [Object, null], default: () => null },
    targetUrl: { type: String, default: '' },
    siteName: { type: String, default: '' },
    defaultContents: null,
    mode: {
      type: String,
      required: true,
      validator: (value) => ['create', 'edit', 'clone'].indexOf(value) !== -1,
    },
    type: { type: String, default: '' },
  },
  data() {
    return {
      drawComponent: false,
      form: {
        targetUrl: this.targetUrl,
        headlines: [],
        brandName: '',
        contentType: 'article',
        cpc: '',
        category: '',
        description: '',
        images: [],
        callToActionText: '',
        sponsoredBy: '',
        displayUrl: '',
        advertText: '',
        adgroupId: '',
        sectionName: this.siteName,
      },
      contents: [],
      existingContents: [],
    };
  },
  computed: {
    isEdit() {
      return this.mode === 'edit';
    },
    $c_FormData() {
      const data = {
        trafficSourceType: this.trafficSourceType,
        account: this.account,
        campaign: this.campaign,
        targetUrl: this.form.targetUrl,
        headlines: this.form.headlines,
        images: this.form.images,
      };
      // Diff by TrafficSource Type
      if (this.config.brandName) data.brandName = this.form.brandName;
      if (this.config.contentType) data.contentType = this.form.contentType;
      if (this.config.cpc) data.cpc = this.form.cpc;
      if (this.config.category && this.form.category !== '') data.category = this.form.category;
      if (this.config.advertText) data.advertText = this.form.advertText;
      if (this.config.description) data.description = this.form.description;
      if (this.config.callToAction) data.callToActionText = this.form.callToActionText;
      if (this.config.sponsoredBy) data.sponsoredBy = this.form.sponsoredBy;
      if (this.config.displayUrl) data.displayUrl = this.form.displayUrl;
      if (this.config.sectionName) data.sectionName = this.form.sectionName;
      if (this.adgroups) data.adgroupId = this.form.adgroupId;
      return data;
    },
    $c_images() {
      return this.form.images.map((item) => (Object.keys(item.cropped).length > 0 ? item.cropped : item.original));
    },
    $c_validFromExisting() {
      // Common Fields
      let validateStatus = !!this.$c_FormData.trafficSourceType && !!this.$c_FormData.account && !!this.$c_FormData.campaign && !!this.$c_FormData.targetUrl;
      // Diff by TrafficSource Type
      if (typeof this.$c_FormData.brandName !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.brandName;
      if (typeof this.$c_FormData.contentType !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.contentType;
      if (typeof this.$c_FormData.cpc !== 'undefined' && this.config.cpc.required) validateStatus = validateStatus && !!this.$c_FormData.cpc;
      if (typeof this.$c_FormData.category !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.category;
      if (typeof this.$c_FormData.advertText !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.advertText;
      if (typeof this.$c_FormData.sponsoredBy !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.sponsoredBy;
      if (typeof this.$c_FormData.displayUrl !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.displayUrl;
      if (this.adgroups && typeof this.$c_FormData.adgroupId !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.adgroupId;
      if (typeof this.$c_FormData.sectionName !== 'undefined' && this.config.sectionName.required) validateStatus = validateStatus && !!this.$c_FormData.sectionName;
      return validateStatus;
    },
    $c_isValidForm() {
      // Common Fields
      let validateStatus = !!this.$c_FormData.trafficSourceType && !!this.$c_FormData.account && !!this.$c_FormData.campaign && !!this.$c_FormData.targetUrl && ((this.$c_FormData.headlines.length > 0 && this.$c_FormData.images.length > 0) || this.existingContents.length > 0);
      // Diff by TrafficSource Type
      if (typeof this.$c_FormData.brandName !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.brandName;
      if (typeof this.$c_FormData.contentType !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.contentType;
      if (typeof this.$c_FormData.cpc !== 'undefined' && this.config.cpc.required) validateStatus = validateStatus && !!this.$c_FormData.cpc;
      if (typeof this.$c_FormData.category !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.category;
      if (typeof this.$c_FormData.advertText !== 'undefined') validateStatus = validateStatus && (this.$c_FormData.campaign.type !== 'push' || !!this.$c_FormData.advertText);
      if (typeof this.$c_FormData.sponsoredBy !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.sponsoredBy;
      if (typeof this.$c_FormData.displayUrl !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.displayUrl;
      if (typeof this.$c_FormData.description !== 'undefined' && this.config.description.required) validateStatus = validateStatus && !!this.$c_FormData.description;
      if (this.adgroups && typeof this.$c_FormData.adgroupId !== 'undefined') validateStatus = validateStatus && !!this.$c_FormData.adgroupId;
      if (typeof this.$c_FormData.sectionName !== 'undefined' && this.config.sectionName.required) validateStatus = validateStatus && !!this.$c_FormData.sectionName;
      return validateStatus;
    },
    config() {
      const data = getConfigData(this.trafficSourceType.uniqueName);

      if (data?.description?.verify && !data.description.verify(this.cmp)) {
        delete data.description;
      }
      return data ?? false;
    },
    defaults() {
      return getConfigData('Defaults') || false;
    },
    sourceLogoUrl() {
      return getTrafficSourceLogo(this.trafficSourceType.uniqueName);
    },
    typeName() {
      return this.trafficSourceType.uniqueName;
    },
    $c_disableTargetUrl() {
      return this.config.target.disableEdit && this.isEdit;
    },
  },
  watch: {
    $c_isValidForm(value) {
      if (!value) {
        this.contents = [];
      }
    },
    contents: {
      immediate: true,
      handler() {
        this.$_emitForm();
      },
      deep: true,
    },
    targetUrl: {
      immediate: true,
      handler(value) {
        this.form.targetUrl = value;
      },
    },
    'trafficSourceType.uniqueName': {
      immediate: true,
      handler(value) {
        // Reset whole components
        this.drawComponent = false;
        this.$_resetForm();
        if (value) {
          this.$nextTick(() => {
            this.drawComponent = true;
          });
        }
      },
    },
    'form.advertText': function () {
      this.$_emitForm();
    },
    'form.category': function () {
      this.$_emitForm();
    },
    'form.cpc': function () {
      this.$_emitForm();
    },
    'form.sectionName': function () {
      this.$_emitForm();
    },
    'form.contentType': function () {
      this.$_emitForm();
    },
    'form.description': function () {
      this.$_emitForm();
    },
    'form.callToActionText': function () {
      this.$_emitForm();
    },
    'form.displayUrl': function () {
      this.$_emitForm();
    },
    'form.sponsoredBy': function () {
      this.$_emitForm();
    },
  },
  created() {
    if (this.defaultContents) {
      const firstContent = this.defaultContents.contents[0];
      if (firstContent) {
        if (firstContent.targetUrl) this.form.targetUrl = firstContent.targetUrl;
        if (firstContent.cpc) this.form.cpc = firstContent.cpc;
        if (firstContent.category) this.form.category = firstContent.category;
        if (firstContent.advertText) this.form.advertText = firstContent.advertText;
        if (firstContent.brandName) this.form.brandName = firstContent.brandName;
        if (firstContent.contentType) this.form.contentType = firstContent.contentType;
        if (firstContent.description) this.form.description = firstContent.description;
        if (firstContent.sponsoredBy) this.form.sponsoredBy = firstContent.sponsoredBy;
        if (firstContent.displayUrl) this.form.displayUrl = firstContent.displayUrl;
        if (firstContent.sectionName) this.form.sectionName = firstContent.sectionName;

        if (this.trafficSourceType.uniqueName === 'Taboola') {
          if (TrafficSourceConfig[this.trafficSourceType.uniqueName] && firstContent.cta) this.form.cta = TrafficSourceConfig[this.trafficSourceType.uniqueName].headline.ctaButton.backendOptions.find((item) => item.value === firstContent.cta)?.name;
        }
        if (this.trafficSourceType.uniqueName === 'Outbrain') {
          this.form.cta = TrafficSourceConfig[this.trafficSourceType.uniqueName].headline.ctaButton.backendOptions.find((data) => data.name === firstContent.cta)?.name;
        }
        const defaultContents = [];
        const images = {};
        if (this.defaultContents.images) {
          this.defaultContents.images.forEach((item) => {
            const image = item.cropped || item.original;
            images[image.md5] = {
              md5: image.md5,
              data: image.base64,
              mimeType: image.mimeType,
            };
          });
        }
        if (this.defaultContents.contents) {
          this.defaultContents.contents.forEach((item) => {
            if (images[item.imageMd5]) {
              const content = {
                headline: item.headline,
                targetUrl: item.targetUrl,
                uid: `defaultContent-${uid(10)}`,
                image: images[item.imageMd5],
              };
              if (item.id) Object.assign(content, { id: item.id });
              if (item.brandName) content.brandName = item.brandName;
              if (item.contentType) content.contentType = item.contentType;
              if (item.sponsoredBy) content.sponsoredBy = item.sponsoredBy;
              if (item.displayUrl) content.displayUrl = item.displayUrl;
              if (item.description) content.description = item.description;

              if (this.trafficSourceType.uniqueName === 'Outbrain') {
                content.cta = TrafficSourceConfig[this.trafficSourceType.uniqueName].headline.ctaButton.backendOptions.find((data) => data.name === firstContent.cta)?.name;
              }
              if (this.trafficSourceType.uniqueName === 'Taboola') {
                if (TrafficSourceConfig[this.trafficSourceType.uniqueName] && item.cta) content.cta = TrafficSourceConfig[this.trafficSourceType.uniqueName].headline.ctaButton.backendOptions.find((data) => data.value === item.cta)?.name;
              }
              defaultContents.push(content);
            }
          });
        }

        if (this.adGroup) {
          this.form.adgroupId = this.adGroup.id;
        }
        this.existingContents = defaultContents;
      }
    }
  },
  methods: {
    $_emitForm() {
      if (this.contents.length === 0) {
        this.$emit('input', null);
        return;
      }
      const payload = {
        trafficSourceType: this.trafficSourceType,
        account: this.account,
        campaign: this.campaign,
        contents: this.contents,
        targetUrl: this.$c_FormData.targetUrl,
        images: [],
      };
      if (typeof this.$c_FormData.contentType !== 'undefined') payload.contentType = this.$c_FormData.contentType;
      if (typeof this.$c_FormData.cpc !== 'undefined') payload.cpc = this.$c_FormData.cpc;
      if (typeof this.$c_FormData.category !== 'undefined') payload.category = this.$c_FormData.category;
      if (typeof this.$c_FormData.advertText !== 'undefined') payload.advertText = this.$c_FormData.advertText;
      if (typeof this.$c_FormData.description !== 'undefined') payload.description = this.$c_FormData.description;
      if (typeof this.$c_FormData.sponsoredBy !== 'undefined') payload.sponsoredBy = this.$c_FormData.sponsoredBy;
      if (typeof this.$c_FormData.callToActionText !== 'undefined') payload.callToActionText = this.$c_FormData.callToActionText;
      if (typeof this.$c_FormData.displayUrl !== 'undefined') payload.displayUrl = this.$c_FormData.displayUrl;
      if (typeof this.$c_FormData.adgroupId !== 'undefined') payload.adgroupId = this.$c_FormData.adgroupId;
      if (typeof this.$c_FormData.sectionName !== 'undefined') payload.sectionName = this.$c_FormData.sectionName;
      // Filter used images cropped & original
      const contentsImagesMd5 = this.contents.map((item) => item.imageMd5).filter((item, index, arr) => arr.indexOf(item) === index);
      this.form.images.forEach((image) => {
        const usedImageMd5 = Object.keys(image.cropped).length > 0 ? image.cropped.md5 : image.original.md5;
        if (contentsImagesMd5.indexOf(usedImageMd5) > -1) {
          const imagePayload = {
            original: {
              md5: image.original.md5, base64: image.original.data, mimeType: image.original.mimeType, dimensions: { width: image.original.width, height: image.original.height },
            },
          };
          if (Object.keys(image.cropped).length > 0) {
            imagePayload.cropped = {
              md5: image.cropped.md5, base64: image.cropped.data, mimeType: image.cropped.mimeType, dimensions: { width: image.cropped.width, height: image.cropped.height },
            };
          }
          payload.images.push(imagePayload);
        }
      });
      // Add existing contents images
      const existingContentsImages = {};
      this.existingContents.forEach((content) => {
        if (!existingContentsImages[content.image.md5]) existingContentsImages[content.image.md5] = content.image;
      });
      for (const imageMd5 in existingContentsImages) {
        if (contentsImagesMd5.indexOf(imageMd5) > -1) {
          const image = existingContentsImages[imageMd5];
          const imagePayload = {
            original: {
              md5: image.md5, base64: image.data, mimeType: image.mimeType, dimensions: { width: image.width, height: image.height },
            },
          };
          payload.images.push(imagePayload);
        }
      }
      this.$emit('input', payload);
    },
    $_resetForm() {
      this.form = {
        targetUrl: this.targetUrl,
        headlines: [],
        brandName: '',
        contentType: 'article',
        cpc: '',
        sectionName: this.siteName,
        category: '',
        advertText: '',
        description: '',
        images: [],
        callToActionText: '',
        sponsoredBy: '',
        displayUrl: '',
        adgroupId: '',
      };
      this.existingContents = [];
    },
    $_addFromExistingContents(data) {
      this.existingContents.push(...data);
    },
  },
};
</script>

<style lang="scss">
.content-uploader-form-wrapper {
  .optimizer-card .optimizer-form-input {
    height: 3.7rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    padding-left: 1.2rem;
    margin-bottom: 0.5rem;

    &.cpc-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.placeholder-input {
      display: flex;
      align-items: center;

      img {
        height: 2.3rem;
        margin-right: 1rem;
      }
    }
  }
}
</style>
