/* eslint-disable import/no-cycle */
import { ABBase } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { ABRegistry } from '@/views/Automation/CampaignCreatorV2/ab/core/ABRegistry';
import { possibleCombinations } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import * as ABTest from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { ABGroupMetadata, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';

// GroupAbTest is used to create variations of a group of variables or groups.
// This will make sure to make the cartesian product of all the variables and groups.
export class GroupABTest<Value extends ABTest.ABValue<unknown>[], E extends SupportedEntities> extends ABBase<Value> {
  constructor(
    private _id: string,
    private group_name: string,
    private imetadata: ABGroupMetadata<E>,
    protected registry: ABRegistry
  ) {
    super();
    this._id;
    this.validateAbTests();
  }

  private _mainGroupId?: string;

  public get mainGroupId(): string | undefined {
    return this._mainGroupId;
  }

  public set mainGroupId(value: string | undefined) {
    this._mainGroupId = value;
  }

  protected get abTests(): Array<ABBase<Value>> {
    const abValues = this.children.map((child) => {
      const group = this.registry.getAbGroup<Value>(child, this.entityId);
      const variable = this.registry.getVariable(child, this.entityId);
      if (!group && !variable) {
        throw new Error(`Group or variable not found ${child}`);
      }
      if (group && variable) {
        throw new Error('Group and variable found');
      }
      return group || variable;
    });
    return abValues;
  }

  private validateAbTests(): void {
    const abTests = this.abTests.map((abTest) => abTest.dependencies.map((dep) => dep.field)).flat();
    const uniqueAbTests = [...new Set(abTests)];
    if (abTests.length !== uniqueAbTests.length) {
      throw new Error('AB test cannot be applied to the same field twice');
    }
  }

  public get dependencies(): ABTest.ABDependency[] {
    return this.abTests.map((abTest) => abTest.dependencies).reduce((prev, curr) => [...prev, ...curr], []);
  }

  public get name(): string {
    return this.group_name;
  }

  public get macros(): Array<ABTest.Macro> {
    const childMacros = this.abTests.map((abTest) => abTest.macros).flat();
    const groupMacro: ABTest.Macro = {
      name: `#${this.imetadata.typeId.toUpperCase()}_VARIABLE_NAME#`,
      value: {
        variableUniquePath: this._id,
        abId: this._id,
        value: this.group_name,
        description: 'Group name',
      },
      nestedMacros: childMacros,
    };
    return [groupMacro];
  }

  public get description(): string {
    return this.metadata.description;
  }

  public get possibleOptions(): Array<ABTest.ABDependency<Value>[]> {
    const options = this.abTests.map((abTest) => abTest.possibleOptions);
    const combinations = possibleCombinations(options).map((c) =>
      c.map((v) => ({
        ...v,
        abGroups: [this._id, ...(v.abGroups || [])],
      }))
    );
    return combinations;
  }

  public get variableUsage(): Record<string, number> {
    return this.abTests
      .map((abTest) => abTest.variableUsage)
      .reduce((prev, curr) => {
        const keys = Object.keys(curr);
        keys.forEach((key) => {
          prev[key] = prev[key] ? prev[key] + curr[key] : curr[key];
        });
        return prev;
      }, {} as Record<string, number>);
  }

  public get type(): ABTest.ABTestType {
    return 'GROUP';
  }
}
