import { Account, TrafficSource, TrafficSourceType } from '@sh/types';
import { uniqBy } from 'lodash';

export default class TrafficSourceFilter {
  constructor(public availableOptions?: Account[], private selected: TrafficSource | null = null) {}

  get options(): TrafficSourceType[] {
    const data = this.availableOptions ?? [];
    const options = data.map((item) => item.type);
    options.sort((first, second) => first.uniqueName.localeCompare(second.uniqueName));

    return uniqBy(options, 'id');
  }

  get selectedOption(): TrafficSourceType | null {
    return this.options.find((option) => this.value === option.uniqueName) ?? null;
  }

  set selectedOption(option: TrafficSourceType | null) {
    this.selected = (option?.uniqueName as TrafficSource) ?? null;
  }

  get value(): TrafficSource | null {
    return this.selected;
  }

  set value(value: TrafficSource | null) {
    this.selected = value;
  }
}
