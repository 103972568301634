import { Campaign } from '@sh/types';
import { defineStore } from 'pinia';

interface SharedStore {
  globalSearchCampaigns: Campaign[];
}

export const useSharedStore = defineStore('shared', {
  state: (): SharedStore => ({
    globalSearchCampaigns: [],
  }),
});
