<template>
  <div id="config_account" class="account-config-container p-12 max account-config-google-analytics">
    <div class="account-config-header">
      <div class="account-config-header__title">
        <h2>Connect Google Analytics</h2>
      </div>
    </div>

    <div v-if="Object.keys($c_accountsMap).length > 0" class="collections my-10 mx-20">
      <template v-for="(item, index) in collection">
        <b-btn v-if="$c_accountsMap[item.accountId]" :key="index" block class="ga-view-btn" @click="$_edit(item, index)">
          <p class="collection-info">
            <span class="title">
              {{ $c_accountsMap[item.accountId].properties[item.propertyId].name }}
              <i class="fa fa-info-circle ml-8 info-icon" :id="`view-item-index-${index}`" />
            </span>
            <b-popover :target="`view-item-index-${index}`" triggers="hover focus">
              <p class="mb-1"><b>Account:</b> {{ $c_accountsMap[item.accountId].name }}</p>
              <p class="mb-1"><b>Property:</b> {{ $c_accountsMap[item.accountId].properties[item.propertyId].name }}</p>
            </b-popover>
          </p>
          <span class="remove-icon" @click.prevent.stop="onConfigurationDelete(item, index)">
            <i class="fa fa-times" />
          </span>
        </b-btn>
      </template>
    </div>

    <preloader v-if="$c_preloader" class="pt-16" margin="15px" />

    <b-btn v-else class="add-ga-view-btn" @click.prevent="modal.model = true">
      <OptimizerIcon class="mr-8" type="insertNew" />
      Add Analytics Property
    </b-btn>

    <b-modal
      id="PreventModal"
      modal-class="optimizer-modal add-ga-view-modal"
      v-model="modal.model"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
      centered
    >
      <template slot="modal-header">
        <h2 class="modal-header__title">Configure Tracking Tokens</h2>
        <div class="modal-header__close-icon d-flex justify-content-center align-items-center" @click="$_resetModal">
          <OptimizerIcon type="close" />
        </div>
      </template>
      <b-form
        :ref="$_getScope()"
        @submit.prevent="$_validateForm"
        :data-vv-scope="$_getScope()"
        class="modal-body-form row"
      >
        <div class="col-md-6">
          <b-form-group
            class="optimizer-form-group mb-15 position-relative"
            label="Account:"
            :invalid-feedback="$_getError('googleAnalyticsAccounts')"
            :state="$_hasError('googleAnalyticsAccounts')"
          >
            <vue-opti-select-light
              id="googleAnalyticsAccounts"
              class="optimizer-select w-100"
              :class="[{ 'has-error': $_hasError('googleAnalyticsAccounts') === false }]"
              name="googleAnalyticsAccounts"
              v-validate="'required'"
              data-vv-as="Account"
              data-vv-validate-on="input|update"
              data-vv-value-path="$c_model"
              label-key="text"
              button-placeholder="-- Select Account --"
              :options="modal.options.accounts"
              :value="modal.form.account"
              :searchable="modal.options.accounts && modal.options.accounts.length > 5"
              button-block
              single
              @change="onAccountChange"
            />
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            class="optimizer-form-group mb-15 position-relative"
            label="Property:"
            :invalid-feedback="$c_propertyOptions.length ? $_getError('googleAnalyticsProperties') : ''"
            :state="$_hasError('googleAnalyticsProperties')"
          >
            <vue-opti-select-light
              id="googleAnalyticsProperties"
              class="optimizer-select w-100"
              :class="[{ 'has-error': $_hasError('googleAnalyticsProperties') === false }]"
              name="googleAnalyticsProperties"
              v-validate="'required'"
              data-vv-as="Property"
              data-vv-validate-on="input|update"
              data-vv-value-path="$c_model"
              label-key="text"
              :options="$c_propertyOptions"
              :value="modal.form.property"
              :searchable="$c_propertyOptions.length > 5"
              single
              button-block
              @change="onPropertyChange($event.value)"
            >
              <template #HEADER>
                <div class="fs-14 lh-20">
                  {{ $c_propertyTooltip }}
                </div>
              </template>
              <template #BUTTON_PLACEHOLDER="{ options }">
                <div class="button-placehoder-static d-flex justify-content-between w-100 pr-20">
                  <template v-if="options.length === 1">
                    {{ options[0].text }}
                  </template>
                  <template v-else>
                    -- Select Property --
                  </template>
                  <div
                    v-if="isLoading"
                    class="d-flex"
                    v-b-tooltip.hover.bottom
                    :title="$c_disabledOptionsTooltip"
                  >
                    <b-spinner label="Loading..." variant="primary" />
                  </div>
                </div>
              </template>
            </vue-opti-select-light>
          </b-form-group>
        </div>
        <b-form-group
          class="optimizer-form-group position-relative col-12"
          :invalid-feedback="$c_hasOptionsData ? $_getError('googleAnalyticsMetrics') : ''"
          :state="$c_hasOptionsData ? $_hasError('googleAnalyticsMetrics') : null"
        >
          <template #label>
            Metrics:
            <i
              class="fa fa-info-circle info-icon"
              v-b-tooltip.hover.right
              title="You can select a maximum of 60 different metrics in total, across all your GA4 integrations."
            />
            <template v-if="!isLoading && modal.form.metric && modal.form.metric.length">
              <span class="fs-12 fw-400 text-secondary">
                ( {{ modal.form.metric.length - ($c_requiredMetrics.length / 2) }} metrics selected )
              </span>
              <template v-if="!isMetricsDropdownShown">
                <span
                  class="mx-6 fs-12 fw-400 text-primary pointer clear-all"
                  @click="$refs.googleAnalyticsMetrics.show()"
                >
                  Add More Metrics
                </span>
                |
              </template>
              <span
                class="ml-6 fs-12 fw-400 text-primary pointer clear-all"
                :class="[isMetricsDropdownShown ? 'not-allowed text-decoration-none' : 'pointer']"
                @click="onMetricsClear"
              >
                Clear All
              </span>
            </template>
          </template>
          <vue-opti-select-light
            id="googleAnalyticsMetrics"
            ref="googleAnalyticsMetrics"
            class="optimizer-select w-100 metrics"
            :class="{ 'has-error': $_hasError('googleAnalyticsMetrics') === false }"
            name="googleAnalyticsMetrics"
            v-model="modal.form.metric"
            v-validate="'required|max_metrics'"
            data-vv-as="Metrics"
            data-vv-validate-on="input|update"
            data-vv-value-path="$c_model"
            label-key="text"
            option-type="checkbox"
            buttonType="tag"
            searchPlaceholder="Search for metrics"
            :disabled="!modal.form.property || !modal.form.account"
            :options="$c_metricOptions"
            :groups="$c_metricGroups"
            :searchable="!$c_disabledOptionsTooltip"
            emitOnClick
            @shown="isMetricsDropdownShown = true"
            @hidden="isMetricsDropdownShown = false"
          >
            <template #HEADER>
              <div class="fs-14 lh-20">
                {{ $c_disabledOptionsTooltip }}
              </div>
            </template>
            <template v-if="!(modal.form.metric && modal.form.metric.length) || $c_disabledOptionsTooltip" #BUTTON_PLACEHOLDER>
              <div class="button-placehoder-static">
                -- Select Metrics --
              </div>
            </template>
            <template #TAG="{ option }">
              <span class="tag-item">
                <span class="tag-name my-2 lh-21">
                  {{ option.text }}
                </span>
                <span v-if="!$c_requiredMetrics.includes(option.value) && !option.disabled" class="tag-remove my-2" @click.stop="onMetricRemoveClick(option.value)" title="Remove">
                  <OptimizerIcon type="close" />
                </span>
              </span>
            </template>
            <template #ITEM="{ option }">
              <div class="d-flex justify-content-between align-items-center w-90">
                <div class="mx-8 option-text">
                  {{ option.text }}
                </div>
                <i
                  v-if="!option.disabled"
                  class="fa fa-info-circle mr-8 info-icon"
                  v-b-tooltip.hover.right="{
                    customClass: 'custom-tooltip',
                    title: option.help,
                    interactive: false,
                    boundary: 'viewport',
                  }"
                />
                <i
                  v-if="option.formula"
                  class="fa fa-flag info-icon"
                  v-b-tooltip.hover.right="{
                    customClass: 'custom-tooltip',
                    title: option.formula.help,
                    interactive: false,
                    boundary: 'viewport',
                  }"
                />
                <i v-if="option.disabled && !option.formula" class="fa fa-flag-o info-icon" />
              </div>
            </template>
            <template #ITEM_0="{ option }">
              {{ option.text }}
            </template>
            <template v-if="!$c_disabledOptionsTooltip" #FOOTER>
              <div class="fs-14 pt-12 info-footer">
                <i class="fa fa-flag-o info-icon mr-6" />- Required metric by another compound (calculated) metric.
              </div>
            </template>
          </vue-opti-select-light>
        </b-form-group>
        <div class="tokens col-12">
          <h5 class="tokens__title">Tokens</h5>
          <div class="row" :key="modal.options.tokens.join('')">
            <div class="col-md-6" v-for="(token, key) in data.tokens" :key="key">
              <b-form-group
                class="optimizer-form-group position-relative"
                :state="!(!modal.form.customTrackingEnabled && modal.isTokenError)"
              >
                <template slot="label">
                  {{ token.message }}
                  <b>
                    <code>{{ token.values.join(' Or ') }}</code>
                  </b>
                </template>
                <vue-opti-select-light
                  class="optimizer-select w-100 custom-tracking-select"
                  label-key="text"
                  button-placeholder="-- Select Token --"
                  :id="'googleAnalyticsTokens'"
                  :name="'googleAnalyticsTokens'"
                  :options="modal.options.tokens"
                  :value="modal.form.tokens[key]"
                  :disabled="isInCustomToken(key)"
                  @clear="onTokenClear(key)"
                  @change="onTokenChange($event, key)"
                  single
                  allowClear
                  button-block
                >
                  <template v-if="!modal.options.tokens.length" #HEADER>
                    <div class="fs-14 lh-20">
                      {{ $c_disabledOptionsTooltip }}
                    </div>
                  </template>
                  <template #BUTTON_PLACEHOLDER v-if="isInCustomToken(key)">
                    <div
                      class="custom-tracking-tooltip"
                      v-b-tooltip.hover.bottom
                      title="To use this token please remove it from Custom Tracking"
                    >
                      -- Select Token --
                    </div>
                  </template>
                </vue-opti-select-light>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="fieldsContainer mt-3 col-12">
          <div class="fieldRow">
            <switch-button
              :checked="modal.form.customTrackingEnabled"
              :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
              :update="$_enableCustomTracking"
              class="mb-2"
            />
            <span class="mb-1">Enable Custom Tracking
              <i
                class="fa fa-info-circle info-icon"
                style="color: rgba(84, 101, 130, 0.6)"
                v-b-tooltip.hover.top
                title="Custom tracking allows to pass all Google Analytics info in 1 utm token using a special format. This is useful if you are already using other utm tokens for other purposes and it is not possible to use them with TheOptimizer."
              />
            </span>
          </div>
          <div
            class="row fieldRow"
            :class="{'form-group is-invalid': modal.form.customTrackingEnabled && modal.isTokenError && !modal.form.subSelected}"
          >
            <template>
              <span class="col-12 col-md-6 fieldInput">
                <b-form-input
                  class="optimizer-form-input m-0"
                  :class="{'is-invalid': modal.form.customTrackingEnabled && modal.isTokenError && !modal.form.customTrackingToken}"
                  type="text"
                  :placeholder="'Custom Tracking Token'"
                  :disabled="!modal.form.customTrackingEnabled"
                  size="sm"
                  v-model="modal.form.customTrackingToken"
                />
              </span>
            </template>
            <template>
              <vue-opti-select-light
                class="optimizer-select col-12 col-md-6"
                :options="modal.options.tokens"
                :value="modal.form.subSelected"
                :disabled="!modal.form.customTrackingEnabled"
                label-key="text"
                single
                button-block
                allowClear
                button-placeholder="Select Dimension"
                @clear="onSubIdClear"
                @change="onSubIdChange"
              >
                <template v-if="!modal.options.tokens.length" #HEADER>
                  <div class="fs-14 lh-20">
                    {{ $c_disabledOptionsTooltip }}
                  </div>
                </template>
              </vue-opti-select-light>
            </template>
          </div>
          <div class="fieldRow">
            <div v-if="modal.form.trackingCode != ''" class="ml-2 tokens__title fs-13">
              {{ `IMPORTANT: Make sure to update your ${source[0].type.uniqueName} campaigns so that they include the tracking code below` }}
              <br />
              <span class="font-italic">{{ modal.form.trackingCode }}</span>
            </div>
          </div>
        </div>
      </b-form>
      <template slot="modal-footer">
        <b-btn class="secondary-button ml-0 mr-8" @click="$_resetModal">Cancel</b-btn>
        <b-btn class="primary-button ml-0 mr-16" @click="$_apply">Add</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
/*
  Refactor required: This file, originally at shared/src/views/Accounts/AccountWizard/components/GoogleAnalyticsTokens.vue,
  needs refactoring due to added logic for fields, making it unmanageable and complex.
*/
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import Preloader from '@sh/components/Utils/Preloader.vue';
import { AccountWizardHelpers } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import Joi from 'joi';
import { cloneDeep, debounce, differenceBy, flatten, groupBy, intersectionBy, unionBy, uniqBy, uniq } from 'lodash';

const scope = 'googleAnalytics4Form';

export default {
  name: 'GoogleAnalytics4',
  components: {
    Preloader,
    OptimizerIcon,
  },
  mixins: [notifications],
  props: {
    tracker: {
      type: Object,
      default: null,
      required: true,
    },
    source: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      data: {
        accounts: null,
        tokens: null,
      },
      collection: [],
      multiCollections: [],
      modal: {
        model: false,
        options: {
          accounts: [],
          properties: {},
          attributions: {},
          metrics: {},
          dbConfig: {},
          tokens: [],
        },
        form: {
          account: null,
          property: null,
          view: null,
          metric: null,
          tokens: {},
          customTrackingEnabled: false,
          customTrackingToken: '',
          trackingCode: '',
          subSelected: null,
          index: null,
        },
        totalAccountMetrics: 30,
        totalUniqueMetrics: 60,
        isTokenError: false,
      },
      preloaderMulti: { tracker: true, source: true },
      fields: [],
      isMetricsDropdownShown: false,
      isLoading: false,
    };
  },
  computed: {
    $c_preloader() {
      return this.preloaderMulti.tracker || this.preloaderMulti.source;
    },
    $c_propertyOptions() {
      const { account } = this.modal.form;
      return this.modal.options.properties[account]?.map((property) => {
        const disabled = !!this.collection.find((data) => data.propertyId === property.value) || !!this.multiCollections.find((data) => data.propertyId === property.value);
        const disabledText = disabled ? ' (Already Connected)' : '';

        return {
          ...property,
          text: property.text.concat(disabledText),
          disabled,
        };
      }) || [];
    },
    $c_disabledOptionsTooltip() {
      if (this.isLoading) {
        return 'Loading data, please wait.';
      }
      if (!this.$c_hasOptionsData) {
        return 'Please select account and property first.';
      }
      return '';
    },
    $c_propertyTooltip() {
      if (!this.modal.form.account) {
        return 'Please select an account first.';
      }
      if (!this.$c_propertyOptions.length) {
        return 'There are no properties for selected account.';
      }
      return '';
    },
    $c_accountsMap() {
      const accounts = {};
      const data = this.data.accounts || [];
      data.forEach((acc) => {
        accounts[acc.id] = { id: acc.id, name: acc.name, properties: {} };
        acc.properties.forEach((prop) => {
          accounts[acc.id].properties[prop.id] = { id: prop.id, name: prop.name };
          prop.metrics.forEach((metric) => {
            accounts[acc.id].properties[prop.id].metrics[metric.apiName] = metric;
          });
        });
      });
      return accounts;
    },
    $c_hasOptionsData() {
      const { account, property } = this.modal.form;
      return !!(account && property && this.modal.options.metrics[account]?.[property]?.length);
    },
    $c_metricOptions() {
      if (this.$c_hasOptionsData) {
        return this.modal.options.metrics[this.modal.form.account][this.modal.form.property].map((option) => ({
          ...option,
          disabled: this.$c_requiredMetrics.includes(option.value) || option.preselect,
        }));
      }
      return [];
    },
    $c_metricGroups() {
      if (this.$c_hasOptionsData) {
        return this.modal.options.attributions[this.modal.form.account][this.modal.form.property];
      }
      return [];
    },
    $c_requiredMetrics() {
      if (this.modal.form.metric) {
        return this.modal.form.metric.reduce((filtered, metric) => {
          if (metric.formula?.dependency) {
            filtered.push(...metric.formula.dependency);
          }
          return filtered;
        }, []);
      }
      return [];
    },
    $c_uniqueSelectedMetrics() {
      const selectedMetrics = this.collection.reduce((accumulator, data) => {
        accumulator.push(...data.metrics);
        return accumulator;
      }, []);
      selectedMetrics.push(...this.fields);
      return uniqBy(selectedMetrics, 'value');
    },
    $c_newUniqueMetrics() {
      return this.$c_uniqueSelectedMetrics.filter((metric) => (
        !this.fields.find((field) => field.value === metric.value)
      ));
    },
  },
  watch: {
    tracker: {
      immediate: true,
      async handler() {
        if (this.tracker.id) {
          this.modal.options.accounts = [];
          this.preloaderMulti.tracker = true;
          const accounts = await this.getTrackerData();

          accounts.forEach((account) => {
            const accountId = account.id;
            this.modal.options.accounts.push({ text: account.name, value: accountId });
            this.modal.options.properties[accountId] = [];
            this.modal.options.attributions[accountId] = {};
            this.modal.options.metrics[accountId] = {};
            this.modal.options.dbConfig[accountId] = {};

            account.properties.forEach((prop) => {
              this.modal.options.properties[accountId].push({ text: prop.name, value: prop.id });
              this.modal.options.metrics[accountId][prop.id] = [];
              this.modal.options.attributions[accountId][prop.id] = [];
            });
            this.modal.options.properties[accountId].sort((first, second) => (first.text.localeCompare(second.text)));
          });
          this.modal.options.accounts.sort((first, second) => (first.text.localeCompare(second.text)));
          this.data.accounts = accounts;
          this.preloaderMulti.tracker = false;
          this.onCompleted();
        }
      },
    },
    source: {
      immediate: true,
      async handler() {
        if (this.source) {
          this.collection = [];
          this.modal.options.tokens = [];
          this.preloaderMulti.source = true;

          try {
            [this.data.tokens] = await Promise.all([this.$api.trafficSources.tokens(this.source[0].type.uniqueName), this.getGA4Fields()]);
          } catch {
            this.$n_failNotification({ title: 'Something went wrong please reopen this section or contact support if this issue persists.' });
          }

          for (const key in this.data.tokens) {
            if (typeof this.modal.form.tokens[key] === 'undefined') {
              this.modal.form.tokens[key] = null;
            }
          }
          this.modal.form.customTrackingToken = Object.values(this.data.tokens).map((token) => token.values[0]).join('|');

          if (this.source.length === 1) {
            this.collection.push(...this.getExistingConnections(this.source[0]));
          } else {
            this.source.forEach((source) => {
              this.multiCollections.push(...this.getExistingConnections(source));
            });
          }
          this.preloaderMulti.source = false;
        }
      },
    },
    collection: {
      handler() {
        this.onCompleted();
      },
      deep: true,
    },
    'modal.form.customTrackingToken': {
      immediate: true,
      async handler() {
        const newTokens = this.modal.form.tokens;
        Object.keys(this.modal.form.tokens).forEach((token) => {
          if (this.modal.form.tokens[token] && this.isInCustomToken(token)) {
            newTokens[token] = null;
          }
        });
        this.modal.form.tokens = newTokens;
        this.changeTrackingCode();
      },
    },
    'modal.form.subSelected': {
      immediate: true,
      async handler() {
        this.changeTrackingCode();
      },
    },
  },
  created() {
    this.$validator.extend('max_metrics', {
      getMessage: (field, params, data) => (data?.message) || 'Something went wrong',
      validate: (value) => {
        const selectedMetrics = value.filter((metric) => !metric.formula);
        const totalSelectedMetrics = unionBy(this.$c_uniqueSelectedMetrics, selectedMetrics, 'value').filter((metric) => metric && !metric.formula);
        const validation = {
          valid: true,
          data: {
            message: '',
          },
        };

        this.handleMaxMetricsValidation(totalSelectedMetrics, selectedMetrics, validation);
        this.handleMaxMetricsTypeValidation(totalSelectedMetrics, validation);

        return Promise.resolve(validation);
      },
    });
    this.onPropertyChange = debounce(this.onPropertyChange, 300);
  },
  methods: {
    onCompleted() {
      const data = {
        variables: [],
      };
      if (this.collection.length) {
        this.collection.forEach((item) => {
          const propertyName = this.modal.options.properties[item.accountId]?.find((option) => option.value === item.propertyId)?.text;
          if (this.isValidCollection(item) && propertyName) {
            const itemPayload = {
              accountId: item.accountId,
              propertyId: item.propertyId,
              propertyName,
              sourceIdOnTracker: item.sourceIdOnTracker,
              metrics: item.metrics,
              tokens: { },
            };

            if (item.customTrackingEnabled && item.subSelected) {
              itemPayload.tokens[item.customTrackingToken] = item.subSelected;
            }

            for (const key in item.tokens) {
              itemPayload.tokens[this.data.tokens[key].values[0]] = item.tokens[key];
            }

            data.variables.push(itemPayload);
          }
        });
        this.$emit('completed', data);
      } else {
        this.$emit('invalid');
      }
    },
    isValidCollection(collection) {
      const schema = Joi.object({
        accountId: Joi.string().required(),
        propertyId: Joi.string().required(),
        metrics: Joi.array().required().items(Joi.object()).min(1),
      });

      return schema.validate(collection, { stripUnknown: true, abortEarly: false });
    },
    async getTrackerData() {
      try {
        return await this.$api.trackers.getGoogleAnalytics4Accounts(this.tracker.id);
      } catch {
        this.$n_failNotification({ title: 'We could not get GA4 accounts, please try again or contact support.' });
        return [];
      }
    },
    getExistingConnections(source) {
      const collection = [];
      source?.linkedTrackers.forEach((config) => {
        if (config?.uniqueName === 'GoogleAnalytics4' && config.id === this.tracker.id) {
          const { variables, metrics: data, sourceIdOnTracker } = config;
          const [item] = variables;

          if (item) {
            const customTrackingToken = Object.keys(item).find((token) => token.includes('|'));
            const metrics = this.fields.filter((item) => data?.metrics?.includes(item.dbName));
            const itemPayload = {
              accountId: data.accountId,
              propertyId: data.propertyId,
              customTrackingToken: item.customTrackingToken,
              tokens: {},
              sourceIdOnTracker,
              metrics,
              propertyName: data.propertyName,
              isExistingCollection: true,
            };

            if (customTrackingToken) {
              itemPayload.customTrackingToken = customTrackingToken;
              itemPayload.customTrackingEnabled = true;
              itemPayload.subSelected = item[customTrackingToken];
            }

            for (const key in this.data.tokens) {
              const token = this.data.tokens[key];
              const [tokenValue] = token.values;

              if (item[tokenValue]) {
                itemPayload.tokens[key] = item[tokenValue];
              }
            }
            collection.push(cloneDeep(itemPayload));
          }
        }
      });
      return collection;
    },
    async $_apply() {
      if (await this.$_validateForm()) {
        const { account: accountId, property: propertyId, tokens, subSelected, customTrackingEnabled } = this.modal.form;
        const data = {
          accountId,
          propertyId,
          customTrackingEnabled,
          subSelected,
          tokens: { ...tokens },
          metrics: this.getMetricsData(this.modal.form.metric),
        };

        if (this.modal.form.customTrackingEnabled && this.modal.form.subSelected) {
          data.customTrackingToken = this.modal.form.customTrackingToken;
        }

        if (this.modal.form.index !== null) {
          Object.assign(this.collection[this.modal.form.index], data);
        } else {
          this.collection.push(data);
        }

        this.$_resetModal();
      }
    },
    $_enableCustomTracking(value) {
      this.modal.form.customTrackingEnabled = value;
      for (const key in this.modal.form.tokens) {
        this.modal.form.tokens[key] = null;
      }
      this.modal.isTokenError = false;
    },
    $_edit(item, index) {
      this.modal.form.account = item.accountId;
      this.modal.form.tokens = { ...item.tokens };
      this.modal.form.customTrackingEnabled = item.customTrackingEnabled;
      this.modal.form.subSelected = item.subSelected;
      this.modal.form.customTrackingToken = item.customTrackingToken || Object.values(this.data.tokens).map((token) => token.values[0]).join('|');
      this.modal.form.index = index;
      this.modal.model = true;
      this.onPropertyChange(item.propertyId, item.metrics);
    },
    $_resetModal() {
      this.modal.model = false;
      this.modal.form.account = null;
      this.modal.form.property = null;
      this.modal.form.metric = null;
      this.modal.form.index = null;
      this.modal.form.subSelected = null;
      this.modal.form.customTrackingEnabled = false;
      this.modal.isTokenError = false;

      for (const key in this.modal.form.tokens) {
        this.modal.form.tokens[key] = null;
      }

      this.$validator.reset(this.$_getScope());
    },
    async $_validateForm() {
      try {
        this.modal.isTokenError = this.onTokenValidate();
        const validation = await this.$validator.validateAll(this.$_getScope()) && !this.modal.isTokenError;
        return !!validation;
      } catch (error) {
        return false;
      }
    },
    $_tokenSelect(value, key) {
      if (value !== null) {
        for (const tokenKey in this.modal.form.tokens) {
          if (key !== tokenKey && value === this.modal.form.tokens[tokenKey]) {
            this.modal.form.tokens[tokenKey] = null;
          }
        }
      }
      this.changeTrackingCode();
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
    validate() {
      return this.collection.length > 0;
    },
    setModalOptions(account, property, { metrics, attributions, customDimensions, dbConfig }) {
      const metricsById = groupBy(metrics, (metric) => metric.apiName);
      const metricOptions = metrics.map((metric) => ({
        text: metric.uiName,
        value: metric.apiName,
        help: metric.description,
        type: metric.type,
        group: metric.category,
        dbName: metric.dbName,
        preselect: metric.preselect,
        ...(this.isMetricFormula(metric) && {
          dbName: metric.apiName.toLowerCase(),
          formula: {
            ...metric.formula,
            help: this.getMetricFormulaHelpText(metricsById, metric.formula),
          },
        }),
      }));

      this.modal.options.attributions[account][property] = attributions.map((attribution) => ({
        content: attribution,
        value: attribution,
      }));
      this.modal.options.metrics[account][property] = metricOptions;
      this.modal.options.dbConfig[account][property] = dbConfig;
      this.modal.options.tokens.push(...customDimensions);
      this.modal.options = cloneDeep(this.modal.options);
    },
    changeTrackingCode() {
      const newStr = Object.keys(this.modal.form.tokens).filter((c) => !!this.modal.form.tokens[c]).map((tokenKey) => {
        const tokenVal = this.modal.form.tokens[tokenKey];
        const trToken = this.modal.options.tokens.find((c) => c.value === tokenVal)?.text;
        const tsToken = this.data.tokens[tokenKey].values[0];
        return `${trToken}=${tsToken}`;
      });

      if (this.modal.form.customTrackingEnabled && this.modal.form.subSelected) {
        const trToken = this.modal.options.tokens.find((c) => c.value === this.modal.form.subSelected)?.text;
        newStr.push(`${trToken}=${this.modal.form.customTrackingToken}`);
      }
      this.modal.form.trackingCode = newStr.join('&');
    },
    isInCustomToken(token) {
      const duplicateTokens = Object.keys(this.data.tokens).filter(
        (key) => this.modal.form.customTrackingToken.split('|').filter((c) => this.data.tokens[key].values.includes(c)).length,
      );
      return duplicateTokens.includes(token) && this.modal.form.customTrackingEnabled;
    },
    checkIfInTokens(id) {
      const prevIndex = Object.values(this.modal.form.tokens).findIndex((token) => token === id);

      if (prevIndex > -1) {
        const prevToken = Object.keys(this.modal.form.tokens)[prevIndex];
        const tokens = { ...this.modal.form.tokens, [prevToken]: null };
        this.modal.form.tokens = tokens;
      }
    },
    checkIfInCustomToken(id) {
      if (this.modal.form.subSelected === id) {
        this.modal.form.subSelected = null;
      }
    },
    handleMaxMetricsValidation(metrics, selectedMetrics, validation) {
      if (selectedMetrics.length > this.modal.totalAccountMetrics) {
        validation.valid = false;
        validation.data.message = `A maximum of ${this.modal.totalAccountMetrics} Metrics may be chosen.`;
      }

      if (metrics.length > this.modal.totalUniqueMetrics) {
        validation.valid = false;
        validation.data.message = `A maximum of ${this.modal.totalUniqueMetrics} unique Metrics may be chosen in all accounts.`;
      }
    },
    handleMaxMetricsTypeValidation(selectedMetrics, validation) {
      const metrics = this.getSelectedMetrics(selectedMetrics);
      const groupedData = metrics.reduce((result, metric) => {
        if (metric.dbName && !metric.dbName.includes('gafield')) {
          return result;
        }

        const configType = AccountWizardHelpers.GoogleAnalytics.getDbConfigType(metric.type);

        if (!result[configType]) {
          result[configType] = [];
        }

        result[configType].push(metric);

        return result;
      }, {});

      Object.entries(groupedData).forEach(([key, data]) => {
        const { account, property } = this.modal.form;
        const dbConfig = this.modal.options.dbConfig?.[account]?.[property] ?? [];
        const maxMetrics = dbConfig.filter((config) => config.type === key).length;

        if (validation.valid && data.length > maxMetrics) {
          validation.valid = false;
          validation.data.message = `A maximum of ${maxMetrics} ${key.toLowerCase()} Metrics may be chosen in all accounts.`;
        }
      });
    },
    getSelectedMetrics(metrics) {
      const allSelectedMetrics = this.collection.reduce((metrics, collection) => {
        metrics.push(...collection.metrics.filter((item) => item.dbName));
        return metrics;
      }, []);

      return unionBy(this.fields, metrics, allSelectedMetrics, 'value');
    },
    getMetricsData(data) {
      return data.map((metric) => ({
        ...metric,
        apiName: metric.value,
        uiName: metric.text,
        name: metric.value,
        category: metric.group,
        ...(this.isMetricFormula(metric) && {
          formula: {
            mathFormula: metric.formula.mathFormula,
            dependency: metric.formula.dependency,
          },
        }),
      }));
    },
    getMetricFormulaHelpText(metricsById, formula) {
      if (formula.dependency?.length) {
        const requiredMetricsText = formula.dependency.reduce(
          (filtered, currentValue) => {
            if (metricsById[currentValue]?.[0]?.uiName) {
              filtered.push(`'${metricsById[currentValue][0].uiName}'`);
            }
            return filtered;
          },
          [],
        ).join(' and ');
        return `This is a formula that uses ${requiredMetricsText} metrics.`;
      }
      return '';
    },
    async getPropertyData() {
      try {
        const { account, property } = this.modal.form;

        if (account && property && !this.$c_hasOptionsData) {
          const propertyId = property.split('/')[1] ?? '';
          const data = await this.$api.trackers.getGoogleAnalytics4PropertyMetrics(this.tracker.id, propertyId);
          this.setModalOptions(account, property, data);
        }
      } catch (error) {
        this.$n_failNotification({ title: error?.response?.data?.message || 'We were unable to get property data, please try again later or contact support!' });
      }
    },
    async getGA4Fields() {
      const responseData = await this.$api.fields.getGA4Fields();

      if (responseData?.fields) {
        this.fields = this.getMetricsData(responseData.fields.map((field) => field.data));
      }
    },
    isMetricFormula(metric) {
      return !!(metric.formula?.mathFormula && metric.formula.dependency && metric.formula.dependency.length);
    },
    onMetricsClear() {
      this.$refs.googleAnalyticsMetrics?.clear();
      this.modal.form.metric = this.$c_metricOptions.filter((option) => option.preselect);
    },
    onMetricRemoveClick(metricValue) {
      this.$refs.googleAnalyticsMetrics?.remove(metricValue);
    },
    onAccountChange({ value }) {
      this.modal.form.account = value;

      if (this.$c_propertyOptions.length === 1 && !this.$c_propertyOptions[0].disabled) {
        this.onPropertyChange(this.$c_propertyOptions[0].value);
      } else {
        this.onPropertyChange(null);
      }
    },
    async onPropertyChange(property, metrics = undefined) {
      const existingConfiguration = this.collection.find((value) => value.propertyId === property);

      if (existingConfiguration && !metrics) {
        this.onPropertyChange(property, existingConfiguration.metrics);
      } else {
        this.isLoading = true;
        this.modal.form.property = property;
        await this.getPropertyData();
        this.modal.form.metric = metrics ?? this.$c_metricOptions.filter((option) => option.preselect);
        this.isLoading = false;
      }
    },
    onTokenChange({ value }, key) {
      this.checkIfInCustomToken(value);
      this.modal.form.tokens[key] = value;
      this.$_tokenSelect(value, key);
      this.modal.isTokenError = this.onTokenValidate();
    },
    onTokenClear(key) {
      this.modal.form.tokens[key] = null;
      this.modal.isTokenError = this.onTokenValidate();
    },
    onTokenValidate() {
      if (this.modal.form.customTrackingEnabled) {
        return !(this.modal.form.subSelected && this.modal.form.customTrackingToken);
      }
      const tokenValues = Object.values(this.modal.form.tokens);
      return !tokenValues.reduce((hasValue, value) => (!!value || hasValue), false);
    },
    onSubIdClear() {
      this.modal.form.subSelected = null;
      this.modal.isTokenError = this.onTokenValidate();
    },
    onSubIdChange({ value }) {
      this.checkIfInTokens(value);
      this.modal.form.subSelected = value;
      this.modal.isTokenError = this.onTokenValidate();
    },
    async onConfigurationDelete(item, index) {
      try {
        if (item.isExistingCollection) {
          await this.$api.trafficSources.unlinkTracker(this.source[0].id, this.tracker.id, item.sourceIdOnTracker);
          this.$n_successNotification({ title: 'Configuration deleted successfully!' });
        }
        this.$apiStore.trafficSources.accounts.clearCache();
        this.$apiStore.trafficSources.accounts();
        this.collection.splice(index, 1);
      } catch {
        this.$n_failNotification({ title: 'Configuration could not be deleted, please try again or contact support!' });
      }
    },


    // Calculate dbName and save metrics

    getMetricDbName(existingMetrics, type, offsetIndex, accountIds) {
      const { account, property } = accountIds;
      const slotOptions = this.modal.options.dbConfig[account]?.[property]?.filter((config) => config.type === type && !existingMetrics.find((item) => (item.dbName === config.field))) ?? [];
      let offsetCounter = 0;

      return slotOptions.find(() => {
        if (offsetCounter === offsetIndex) {
          return true;
        }

        offsetCounter++;

        return false;
      })?.field;
    },
    setDbNames(existingMetrics, newMetrics, accountIds) {
      const counter = {
        [AccountWizardHelpers.GoogleAnalytics.DBConfigType.MONEY]: 0,
        [AccountWizardHelpers.GoogleAnalytics.DBConfigType.NUMERIC]: 0,
      };

      newMetrics.forEach((metric) => {
        const dbConfigType = AccountWizardHelpers.GoogleAnalytics.getDbConfigType(metric.type);
        metric.dbName = this.getMetricDbName(existingMetrics, dbConfigType, counter[dbConfigType], accountIds);
        counter[dbConfigType]++;
      });
    },
    async updateGA4Fields(metrics) {
      const newUniqueMetrics = intersectionBy(metrics, this.$c_newUniqueMetrics, 'value');
      const params = {
        fields: AccountWizardHelpers.GoogleAnalytics.ga4FieldFormatter(newUniqueMetrics),
      };

      if (this.fields.length) {
        delete params.userId;
        await this.$api.fields.updateGA4Fields(params);
      } else {
        await this.$api.fields.createGA4Fields(params);
      }
    },
    async onConfigurationSave(configurations) {
      const metrics = configurations.reduce((data, item) => {
        if (item?.metrics && item.metrics.length > 0) {
          const metricsWithDbName = item.metrics.filter((metric) => !!metric.dbName);
          const metricsWithoutDbName = item.metrics.filter((metric) => !metric.dbName);

          const totalExistingMetrics = this.getSelectedMetrics(flatten(data.map((item) => item.metrics)));

          const previouslyAssignedDbNameMetrics = intersectionBy(totalExistingMetrics, metricsWithoutDbName, 'value');
          const newMetrics = differenceBy(metricsWithoutDbName, previouslyAssignedDbNameMetrics, 'value');

          this.setDbNames(totalExistingMetrics, newMetrics, { account: item.accountId, property: item.propertyId });

          data.push({
            propertyId: item.propertyId,
            accountId: item.accountId,
            metrics: [...metricsWithDbName, ...previouslyAssignedDbNameMetrics, ...newMetrics],
            propertyName: item.propertyName,
          });
        }
        return data;
      }, []);
      const totalMetrics = uniqBy(flatten(metrics.map((item) => (item.metrics))), 'value');

      await this.updateGA4Fields(totalMetrics);
      this.$n_successNotification({ title: 'Fields saved successfully.' });

      return metrics.map((item) => ({
        ...item,
        metrics: uniq(item.metrics.map((metric) => (metric.dbName))),
      }));
    },
  },
};
</script>

<style lang="scss">
.account-config-google-analytics {
  .collections {
    .ga-view-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      color: #546582;
      border: 0.1rem solid #d6dfe3;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      padding: 0.7rem 1rem;
      transition: 0.25s ease-in-out;

      &:hover {
        background: #f7f7f7;
      }

      .title {
        font-size: 1.6rem;
      }

      .info-icon {
        color: rgba(84, 101, 130, 0.6);
      }

      .remove-icon {
        position: absolute;
        right: 1rem;
        background: #7fa1f8;
        color: $color-white;
        padding: 0.1rem 0.3rem 0.2rem 0.3rem;
        line-height: 0.55;
        border-radius: 50%;
        font-size: 1.4rem;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }
  }

  .add-ga-view-btn {
    display: inline-flex;
    justify-content: center;
    padding: 0.7rem 1.5rem;
    margin: 0.5rem 2rem 2rem 2rem;
    font-size: 1.4rem;
    width: calc(100% - 4rem);
    background: #779dff;
    border-radius: 0.5rem;
    color: $color-white;
    border-color: #779dff;

    &:hover {
      background: #638efa;
      border-color: #638efa;
    }
  }
}

.add-ga-view-modal {
  .optimizer-form-group {
    margin-top: 1rem;

    .invalid-feedback {
      position: absolute !important;
      width: unset;
    }

    .clear-all:hover {
      text-decoration: underline;
    }
  }

  .tokens {
    &__title {
      width: 100%;
      background: #f0f5f7;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem;
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;
    }

    code {
      color: rgb(235, 110, 21);
    }
  }

  .custom-tracking-select {
    .dropdown-toggle {
      display: flex;

      .custom-tracking-tooltip {
        width: 100%;
        text-align: left;
      }
    }
  }

  .optimizer-select {
    &.metrics .options-list:has(.option-content) {
      min-height: 16rem;
    }

    .dropdown-toggle {
      color: $black-500;

      &:focus,
      &:active {
        color: $black-500;
      }
    }

    .acc-select {
      .acc-item-icon {
        display: inline;
      }

      .acc-item-name {
        display: inline;
        padding-left: 1rem;
      }
    }

    .select-dropdown-actions {
      padding-bottom: 0.5rem;
      border-bottom: 0.1rem solid $table-border-color;
    }

    .dropdown-menu .options-list {
      max-height: 26rem !important;
    }
  }

  .fieldsContainer {
    .fieldRow {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      b {
        width: 30%;
        margin-right: 1rem;
      }

      .fieldInput {
        width: 100%;
      }
    }
  }

  .info-footer {
    border-top: 0.1rem solid $color-light;
    color: $black-500;
  }

  #googleAnalyticsMetrics {
    .button-placehoder-tag {
      max-height: 10.5rem;
      overflow: auto;
      margin-right: 2.5rem;
    }

    .tag-remove svg {
      width: 1.2rem;
      height: 1rem;
    }
  }
}

#PreventModal .modal-body {
  max-height: 80vh;
  overflow: auto;
  overflow: overlay;
}
</style>
