var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cc-view-level"},[_vm._l((_setup.navigationGetters.getNavigation.value),function(item){return [_c('div',{key:item.id,staticClass:"cc-view-level__parent",on:{"click":function($event){$event.stopPropagation();return _setup.changeNavigation(_setup.Level.Campaign, item.id)}}},[_c(_setup.ViewLevelRow,{class:{
          active:
            item.id === _setup.navigationGetters.campaignInternalId.value &&
            _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Campaign,
          semiactive:
            item.id === _setup.navigationGetters.campaignInternalId.value &&
            [_setup.Level.AdSet, _setup.Level.Ad].includes(_setup.navigationGetters.getCurrentLevel.value),
        },style:(_setup.getContainerInlineStyles(
            item.id === _setup.navigationGetters.campaignInternalId.value &&
              _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Campaign,
            item.id === _setup.navigationGetters.campaignInternalId.value &&
              [_setup.Level.AdSet, _setup.Level.Ad].includes(_setup.navigationGetters.getCurrentLevel.value),
            item.message
          )),attrs:{"name":item.name,"id":item.id,"icon":item.icon,"actions":item.actions,"message":item.message,"level":_setup.Level.Campaign},on:{"dispatch":function($event){return _setup.dispatchEvent($event, _setup.Level.Campaign)}}}),_vm._l((item.children),function(child){return _c('div',{key:child.id,staticClass:"cc-view-level__children",on:{"click":function($event){$event.stopPropagation();return _setup.changeNavigation(_setup.Level.AdSet, item.id, child.id)}}},[_c(_setup.ViewLevelRow,{class:{
            active:
              _setup.navigationGetters.getCurrentLevel.value === _setup.Level.AdSet &&
              child.id === _setup.navigationGetters.adSetInternalId?.value,
            semiactive:
              _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Ad &&
              child.id === _setup.navigationGetters.adSetInternalId?.value,
          },style:(_setup.getContainerInlineStyles(
              item.id === _setup.navigationGetters.campaignInternalId.value &&
                _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Campaign,
              item.id === _setup.navigationGetters.campaignInternalId.value &&
                [_setup.Level.AdSet, _setup.Level.Ad].includes(_setup.navigationGetters.getCurrentLevel.value),
              item.message
            )),attrs:{"name":child.name,"id":child.id,"icon":child.icon,"actions":child.actions,"message":child.message,"level":_setup.Level.AdSet},on:{"dispatch":function($event){return _setup.dispatchEvent($event, _setup.Level.AdSet)}}}),_vm._l((child.children || []),function(grandChild){return _c('div',{key:grandChild.id,staticClass:"cc-view-level__children",on:{"click":function($event){$event.stopPropagation();return _setup.changeNavigation(_setup.Level.Ad, item.id, child.id, grandChild.id)}}},[_c(_setup.ViewLevelRow,_vm._b({class:{
              active:
                _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Ad &&
                grandChild.id === _setup.navigationGetters.adInternalId?.value,
            },style:(_setup.getContainerInlineStyles(
                item.id === _setup.navigationGetters.campaignInternalId.value &&
                  _setup.navigationGetters.getCurrentLevel.value === _setup.Level.Campaign,
                item.id === _setup.navigationGetters.campaignInternalId.value &&
                  [_setup.Level.AdSet, _setup.Level.Ad].includes(_setup.navigationGetters.getCurrentLevel.value),
                item.message
              )),attrs:{"name":grandChild.name,"id":grandChild.id,"icon":grandChild.icon,"message":grandChild.message,"actions":grandChild.actions,"level":_setup.Level.Ad},on:{"dispatch":function($event){return _setup.dispatchEvent($event, _setup.Level.Ad)}}},'ViewLevelRow',grandChild,false))],1)})],2)})],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }