import { Coordinates, Cropper } from 'vue-advanced-cropper';

// Based on : https://developers.facebook.com/docs/marketing-api/reference/ad-creative#fields
export interface Create {
  id?: string;
  account_id?: string;
  actor_id: string; // Facebook Page id
  adlabels?: Array<{ name: string }>;
  applink_treatment?: string;
  asset_feed_spec?: AssetFeedSpec;
  authorization_category?: string;
  body?: string;
  branded_content?: any;
  bundle_folder_id?: string;
  call_to_action_type?: unknown;
  categorization_criteria?: string;
  category_media_source?: string;
  collaborative_ads_lsb_image_bank_id?: string;
  degrees_of_freedom_spec?: any;
  destination_set_id?: string;
  dynamic_ad_voice?: string;
  effective_authorization_category?: string;
  effective_instagram_media_id?: string;
  effective_instagram_story_id?: string;
  effective_object_story_id?: string;
  enable_direct_install?: boolean;
  enable_launch_instant_app?: boolean;
  facebook_branded_content?: any;
  image_crops?: any;
  image_hash?: string;
  image_url?: string;
  instagram_actor_id: string; // Instagram Account id
  instagram_branded_content?: any;
  instagram_permalink_url?: string;
  instagram_story_id?: string;
  instagram_user_id?: string;
  interactive_components_spec?: any;
  link_destination_display_url?: string;
  link_og_id?: string;
  link_url?: string;
  messenger_sponsored_message?: string;
  name: string;
  object_id?: string;
  object_store_url?: string;
  object_story_id?: string;
  object_story_spec?: ObjectStorySpec;
  object_type?: string;
  object_url?: string;
  photo_album_source_object_story_id?: string;
  place_page_set_id?: string;
  platform_customizations?: any;
  playable_asset_id?: string;
  portrait_customizations?: any;
  product_set_id?: string;
  recommender_settings?: any;
  referral_id?: string;
  source_instagram_media_id?: string;
  template_url?: string;
  template_url_spec?: any;
  thumbnail_id?: string;
  thumbnail_url?: string;
  title?: string;
  url_tags?: string;
  use_page_actor_override?: boolean;
  video_id?: string;
}
export interface AssetFeedSpec {
  images: Image[];
  bodies: Body[];
  call_to_action_types: string[];
  descriptions: Description[];
  link_urls: LinkURL[];
  titles: Body[];
  ad_formats: string[];
  asset_customization_rules: AssetCustomizationRule[];
  optimization_type: string;
  additional_data: AdditionalData;
  reasons_to_shop: boolean;
  shops_bundle: boolean;
}

export interface AdditionalData {
  multi_share_end_card: boolean;
  is_click_to_message: boolean;
}

export interface AssetCustomizationRule {
  customization_spec: CustomizationSpec;
  image_label: Label;
  body_label: Label;
  link_url_label: Label;
  title_label: Label;
  priority: number;
}

export interface Label {
  name: string;
  id?: string;
}

export interface CustomizationSpec {
  age_max: number;
  age_min: number;
  publisher_platforms?: string[];
  facebook_positions?: string[];
  instagram_positions?: string[];
  messenger_positions?: string[];
  audience_network_positions?: string[];
}

export interface Body {
  adlabels?: Label[];
  text: string;
}

export interface Description {
  text: string;
}

export interface Image {
  adlabels: Label[];
  hash: string;
}

export interface LinkURL {
  adlabels: Label[];
  website_url: string;
  display_url: string;
}

export interface ObjectStorySpec {
  page_id: string;
  instagram_actor_id: string;
}

export interface AppInstall {
  enable_direct_install: boolean;
  object_store_url: string;
  object_story_id: string;
}

export interface InstagramPost {
  instagram_actor_id: string;
  instagram_branded_content: any;
  instagram_permalink_url: string;
  instagram_story_id: string;
  instagram_user_id: string;
  source_instagram_media_id: string;
}

export interface PageCreative {
  object_id: string;
}

export enum CallToActionType {
  OPEN_LINK = 'OPEN_LINK',
  LIKE_PAGE = 'LIKE_PAGE',
  SHOP_NOW = 'SHOP_NOW',
  PLAY_GAME = 'PLAY_GAME',
  INSTALL_APP = 'INSTALL_APP',
  USE_APP = 'USE_APP',
  CALL = 'CALL',
  CALL_ME = 'CALL_ME',
  VIDEO_CALL = 'VIDEO_CALL',
  INSTALL_MOBILE_APP = 'INSTALL_MOBILE_APP',
  USE_MOBILE_APP = 'USE_MOBILE_APP',
  MOBILE_DOWNLOAD = 'MOBILE_DOWNLOAD',
  BOOK_TRAVEL = 'BOOK_TRAVEL',
  LISTEN_MUSIC = 'LISTEN_MUSIC',
  WATCH_VIDEO = 'WATCH_VIDEO',
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  DOWNLOAD = 'DOWNLOAD',
  WATCH_MORE = 'WATCH_MORE',
  NO_BUTTON = 'NO_BUTTON',
  VISIT_PAGES_FEED = 'VISIT_PAGES_FEED',
  CALL_NOW = 'CALL_NOW',
  APPLY_NOW = 'APPLY_NOW',
  CONTACT = 'CONTACT',
  BUY_NOW = 'BUY_NOW',
  GET_OFFER = 'GET_OFFER',
  GET_OFFER_VIEW = 'GET_OFFER_VIEW',
  BUY_TICKETS = 'BUY_TICKETS',
  UPDATE_APP = 'UPDATE_APP',
  GET_DIRECTIONS = 'GET_DIRECTIONS',
}

export const ReadableNameCallToAction = {
  [CallToActionType.OPEN_LINK]: 'Open Link',
  [CallToActionType.LIKE_PAGE]: 'Like Page',
  [CallToActionType.SHOP_NOW]: 'Shop Now',
  [CallToActionType.PLAY_GAME]: 'Play Game',
  [CallToActionType.INSTALL_APP]: 'Install App',
  [CallToActionType.USE_APP]: 'Use App',
  [CallToActionType.CALL]: 'Call',
  [CallToActionType.CALL_ME]: 'Call Me',
  [CallToActionType.VIDEO_CALL]: 'Video Call',
  [CallToActionType.INSTALL_MOBILE_APP]: 'Install Mobile App',
  [CallToActionType.USE_MOBILE_APP]: 'Use Mobile App',
  [CallToActionType.MOBILE_DOWNLOAD]: 'Mobile Download',
  [CallToActionType.BOOK_TRAVEL]: 'Book Travel',
  [CallToActionType.LISTEN_MUSIC]: 'Listen Music',
  [CallToActionType.WATCH_VIDEO]: 'Watch Video',
  [CallToActionType.LEARN_MORE]: 'Learn More',
  [CallToActionType.SIGN_UP]: 'Sign Up',
  [CallToActionType.DOWNLOAD]: 'Download',
  [CallToActionType.WATCH_MORE]: 'Watch More',
  [CallToActionType.NO_BUTTON]: 'No Button',
  [CallToActionType.VISIT_PAGES_FEED]: 'Visit Pages Feed',
  [CallToActionType.CALL_NOW]: 'Call Now',
  [CallToActionType.APPLY_NOW]: 'Apply Now',
  [CallToActionType.CONTACT]: 'Contact',
  [CallToActionType.BUY_NOW]: 'Buy Now',
  [CallToActionType.GET_OFFER]: 'Get Offer',
  [CallToActionType.GET_OFFER_VIEW]: 'Get Offer View',
  [CallToActionType.BUY_TICKETS]: 'Buy Tickets',
  [CallToActionType.UPDATE_APP]: 'Update App',
} as const;

export enum ObjectType {
  APPLICATION = 'APPLICATION',
  DOMAIN = 'DOMAIN',
  EVENT = 'EVENT',
  OFFER = 'OFFER',
  PAGE = 'PAGE',
  PHOTO = 'PHOTO',
  SHARE = 'SHARE',
  STORE_ITEM = 'STORE_ITEM',
  VIDEO = 'VIDEO',
}

export interface BaseCreative {
  headline: string[];
  account_id: string;
  object_type: ObjectType; // ENUM
  adlabels: Array<{ name: string }>;
  title: string[];
  body: string[];
}

export interface CallToAction {
  call_to_action_type: CallToActionType;
  link_destination_display_url: string;
  link_url: string;
  object_url: string;
  template_url: string;
  url_tags: string;
  pixel_id: string;
}

export interface ImageCreative {
  image_hash?: string;
  image_url: string;
  width?: number;
  height?: number;
  size?: number;
  cropVariations?: CropVariations;
  id: string;
}

export type CropVariations = Partial<
  Record<
    SupportedCrop,
    {
      coordinates: Coordinates;
      pixelCoordinates: number[][];
      cropRatio: string;
      useCrop: boolean;
    } & Omit<ReturnType<Cropper['getResult']>['image'], 'src'>
  >
>;

export interface Identity {
  actor_id: string;
  instagram_actor_id: string;
}

export enum SupportedCrop {
  '100x100' = '100x100',
  '100x72' = '100x72',
  '400x150' = '400x150',
  '400x500' = '400x500',
  '600x360' = '600x360',
  '90x160' = '90x160',
  '600x315' = '600x315',
  '191x100' = '191x100',
}

export const SupportedCropRatio = {
  [SupportedCrop['191x100']]: {
    age_max: 65,
    age_min: 13,
    publisher_platforms: ['facebook'],
    facebook_positions: ['right_hand_column', 'search'],
  },
  [SupportedCrop['90x160']]: {
    age_max: 65,
    age_min: 13,
    publisher_platforms: ['facebook', 'instagram', 'audience_network', 'messenger'],
    facebook_positions: ['facebook_reels', 'story'],
    instagram_positions: ['story', 'reels'],
    messenger_positions: ['story'],
    audience_network_positions: ['classic'],
  },
  [SupportedCrop['100x100']]: {
    age_max: 65,
    age_min: 13,
    publisher_platforms: ['facebook', 'instagram', 'messenger'],
    facebook_positions: [
      'feed',
      'facebook_reels_overlay',
      'profile_feed',
      'video_feeds',
      'instream_video',
      'marketplace',
    ],
    instagram_positions: ['stream', 'ig_search', 'explore', 'explore_home', 'profile_feed'],
    messenger_positions: ['messenger_home'],
  },
};

export type ImageCrops = Record<SupportedCrop, number[][]>;

export type AdCreative = BaseCreative & CallToAction & Identity & { image: ImageCreative };

// Generate type based on those fields:
/**
 *         "fields": [
            {
                "name": "account_id",
                "description": "Ad account ID for the account this ad creative belongs to.",
                "type": "numeric string"
            },
            {
                "name": "actor_id",
                "description": "The actor ID (Page ID) of this creative",
                "type": "numeric string"
            },
            {
                "name": "adlabels",
                "description": "[Ad Labels](/docs/marketing-api/reference/ad-label) associated with this creative. Used to group it with related ad objects.",
                "type": "list<adlabel>"
            },
            {
                "name": "degrees_of_freedom_spec",
                "description": "Specifies the types of transformations that are enabled for the given creative",
                "type": "adcreativedegreesoffreedomspec"
            },
            {
                "name": "destination_set_id",
                "description": "The ID of the Product Set for a Destination Catalog that will be used to link with Travel Catalogs",
                "type": "numeric string"
            },
            {
                "name": "dynamic_ad_voice",
                "description": "Used for [Store Traffic Objective inside Dynamic Ads](/docs/marketing-api/guides/dynamic-ad/store-visits). Allows you to control the voice of your ad. If set to `DYNAMIC`, page name and profile picture in your ad post come from the nearest page location. If set to `STORY_OWNER`, page name and profile picture in your ad post come from the main page location.",
                "type": "string"
            },
            {
                "name": "effective_authorization_category",
                "description": "Specifies whether ad is a political ad or not.\nSee [Facebook Advertising Policies](https://www.facebook.com/policies/ads). This field cannot be used for [Dynamic Ads](https://developers.facebook.com/docs/marketing-api/dynamic-ad).\n\nThis value can be different than the authorization_category value in case our systems have identified the ad as political even though it was not configured to be labeled as such.",
                "type": "enum"
            },
            {
                "name": "effective_instagram_media_id",
                "description": "The ID of an Instagram post to use in an ad",
                "type": "numeric string"
            },
            {
                "name": "effective_instagram_story_id",
                "description": "Used for [Instagram Ads](/docs/marketing-api/guides/instagramads). The ID of an Instagram post to display as an Instagram ad.",
                "type": "numeric string"
            },
            {
                "name": "effective_object_story_id",
                "description": "The ID of a page post to use in an ad, regardless of whether it's an organic or unpublished page post. It will return empty if the page is not yet published.",
                "type": "token with structure: post id"
            },
            {
                "name": "enable_direct_install",
                "description": "Whether Direct Install should be enabled on supported devices",
                "type": "bool"
            },
            {
                "name": "enable_launch_instant_app",
                "description": "Whether Instant App should be enabled on supported devices",
                "type": "bool"
            },
            {
                "name": "facebook_branded_content",
                "description": "Stores fields for Facebook Branded Content ",
                "type": "adcreativefacebookbrandedcontent"
            },
            {
                "name": "id",
                "description": "Unique ID for an ad creative, numeric string.",
                "type": "numeric string"
            },
            {
                "name": "applink_treatment",
                "description": "Used for [Dynamic Ads](/docs/marketing-api/dynamic-product-ads/ads-management). Specify what action should occur if a person clicks a link in the ad, but the business' app is not installed on their device. For example, open a webpage displaying the product, or open the app in an app store on the person's mobile device.",
                "type": "enum"
            },
            {
                "name": "asset_feed_spec",
                "description": "Used for [Dynamic Creative](/docs/marketing-api/dynamic-creative/dynamic-creative-optimization) to automatically experiment and deliver different variations of an ad's creative. Specifies an asset feed with multiple images, text and other assets used to generate variations of an ad. Formatted as a JSON string.",
                "type": "adassetfeedspec"
            },
            {
                "name": "authorization_category",
                "description": "Specifies whether ad was configured to be labeled as a political ad or not.\nSee [Facebook Advertising Policies](https://www.facebook.com/policies/ads). This field cannot be used for [Dynamic Ads](https://developers.facebook.com/docs/marketing-api/dynamic-ad).\n\n",
                "type": "enum"
            },
            {
                "name": "body",
                "description": "The body of the ad. Not supported for video post creatives",
                "type": "string"
            },
            {
                "name": "branded_content",
                "description": "branded_content",
                "type": "adcreativebrandedcontentads"
            },
            {
                "name": "bundle_folder_id",
                "description": "The [Dynamic Ad's](/docs/marketing-api/dynamic-product-ads) bundle folder ID",
                "type": "numeric string"
            },
            {
                "name": "call_to_action_type",
                "description": "Type of call to action button in your ad. This determines the button text and header text for your ad. See [Ads Guide](https://www.facebook.com/business/ads-guide/) for [campaign objectives](/docs/marketing-api/adcampaign/) and permitted call to action types.",
                "type": "enum {open_link, like_page, shop_now, play_game, install_app, use_app, call, call_me, video_call, install_mobile_app, use_mobile_app, mobile_download, book_travel, listen_music, watch_video, learn_more, sign_up, download, watch_more, no_button, visit_pages_feed, call_now, apply_now, contact, buy_now, get_offer, get_offer_view, buy_tickets, update_app, get_directions, buy, send_updates, message_page, donate, subscribe, say_thanks, sell_now, share, donate_now, get_quote, contact_us, order_now, start_order, add_to_cart, video_annotation, record_now, inquire_now, confirm, refer_friends, request_time, get_showtimes, listen_now, woodhenge_support, sotto_subscribe, follow_user, raise_money, event_rsvp, whatsapp_message, follow_news_storyline, see_more, book_now, find_a_group, find_your_groups, pay_to_access, purchase_gift_cards, follow_page, send_a_gift, swipe_up_shop, swipe_up_product, send_gift_money, play_game_on_facebook, get_started, open_instant_app, audio_call, get_promotions, join_channel, make_an_appointment, ask_about_services, book_a_consultation, get_a_quote, buy_via_message, ask_for_more_info, chat_with_us}"
            },
            {
                "name": "categorization_criteria",
                "description": "The [Dynamic Category Ad's](/docs/marketing-api/dynamic-product-ads) categorization field, e.g. brand",
                "type": "enum"
            },
            {
                "name": "category_media_source",
                "description": "The [Dynamic Ad's](/docs/marketing-api/dynamic-product-ads) rendering mode for category ads",
                "type": "enum"
            },
            {
                "name": "collaborative_ads_lsb_image_bank_id",
                "description": "Used for CPAS local delivery image bank",
                "type": "numeric string"
            },
            {
                "name": "contextual_multi_ads",
                "description": "contextual_multi_ads",
                "type": "adcreativecontextualmultiads"
            },
            {
                "name": "image_crops",
                "description": "A JSON object defining crop dimensions for the image specified. See [image crop reference](/docs/marketing-api/image-crops/) for more details",
                "type": "adsimagecrops"
            },
            {
                "name": "image_hash",
                "description": "Image hash for ad creative. If provided, do not add `image_url`. See [image library](/docs/marketing-api/adimage/) for more details.",
                "type": "string"
            },
            {
                "name": "image_url",
                "description": "A URL for the image for this creative. We save the image at this URL to the ad account's image library. If provided, do not include `image_hash`.",
                "type": "string"
            },
            {
                "name": "instagram_actor_id",
                "description": "Used for [Instagram Ads](/docs/marketing-api/guides/instagramads). Provide Instagram account ID used for running these ads.",
                "type": "numeric string"
            },
            {
                "name": "instagram_branded_content",
                "description": "Instagram branded content",
                "type": "adcreativeinstagrambrandedcontent"
            },
            {
                "name": "instagram_permalink_url",
                "description": "URL for a post on Instagram you want to run as an ad. Also known as Instagram media.",
                "type": "string"
            },
            {
                "name": "instagram_story_id",
                "description": "Used for [Instagram Stories as Ads](/docs/marketing-api/guides/instagramads/ads_management). Specifies the Instagram story you want to run as an ad. This field will be deprecated in the future. Use `SOURCE_INSTAGRAM_STORY_ID`",
                "type": "numeric string"
            },
            {
                "name": "instagram_user_id",
                "description": "Instagram actor ID",
                "type": "numeric string"
            },
            {
                "name": "interactive_components_spec",
                "description": "Specification for all the interactive components that would show up on the ad",
                "type": "adcreativeinteractivecomponentsspec"
            },
            {
                "name": "link_destination_display_url",
                "description": "Overwrites the display URL for link ads when `object_url` is set to a click tag",
                "type": "string"
            },
            {
                "name": "link_og_id",
                "description": "The Open Graph (OG) ID for the link in this creative if the landing page has OG tags",
                "type": "numeric string"
            },
            {
                "name": "link_url",
                "description": "Identify a specific landing tab on your Facebook page by the Page tab's URL. See [connection objects](/docs/reference/ads-api/connectionobjects/) for retrieving Page tab URLs. You can add [app_data](/docs/facebook-login/manually-build-a-login-flow) parameters to the URL to pass data to a Page's tab.",
                "type": "string"
            },
            {
                "name": "messenger_sponsored_message",
                "description": "Used for Messenger sponsored message. JSON string with message for this ad creative. See [Messenger Platform, Send API Reference](docs/messenger-platform/reference/send-api).",
                "type": "string"
            },
            {
                "name": "name",
                "description": "Name of this ad creative as seen in the ad account's library. This field has a limit of 100 characters.",
                "type": "string"
            },
            {
                "name": "object_id",
                "description": "ID for Facebook object being promoted with ads or relevant to the ad or ad type. For example a page ID if you are running ads to generate Page Likes. See [promoted_object](/docs/marketing-api/reference/ad-campaign/promoted-object).",
                "type": "numeric string"
            },
            {
                "name": "object_store_url",
                "description": "iTunes or Google Play of the destination of an app ad",
                "type": "string"
            },
            {
                "name": "object_story_id",
                "description": "ID of a Facebook Page post to use in an ad. You can get this ID by [querying the posts of the page](/docs/graph-api/reference/page/feed/). If this post includes an image, it should not exceed 8 MB. Facebook will upload the image from the post to your ad account's [image library](/docs/marketing-api/adimage). If you create an unpublished page post via `object_story_spec` at the same time as creating the ad, this ID will be null. However, the `effective_object_story_id` will be the ID of the page post regardless of whether it's an organic or unpublished page post.\n",
                "type": "token with structure: post id"
            },
            {
                "name": "object_story_spec",
                "description": "Use if you want to create a new unpublished page post and turn the post into an ad. The Page ID and the content to create a new unpublished page post. Specify `link_data`, `photo_data`, `video_data`, `text_data` or `template_data` with the content.",
                "type": "adcreativeobjectstoryspec"
            },
            {
                "name": "object_type",
                "description": "The type of Facebook object you want to advertise. Allowed values are:<br>`PAGE`<br>`DOMAIN`<br>`EVENT`<br>`STORE_ITEM`: refers to an iTunes or Google Play store destination<br>`SHARE`: from a page<br>`PHOTO`<br>`STATUS`: of a page<br>`VIDEO`<br>`APPLICATION`: app on Facebook<br>`INVALID`: when an invalid object_id was specified such as a deleted object or if you do not have permission to see the object. In very few cases, this field may be empty if Facebook is unable to identify the type of advertised object<br>`PRIVACY_CHECK_FAIL`: you are missing the permission to load this object type<br>`POST_DELETED`: this object_type has been deleted",
                "type": "enum {application, domain, event, offer, page, photo, share, status, store_item, video, invalid, privacy_check_fail, post_deleted}"
            },
            {
                "name": "object_url",
                "description": "URL that opens if someone clicks your link on a link ad. This URL is not connected to a Facebook page.",
                "type": "string"
            },
            {
                "name": "photo_album_source_object_story_id",
                "description": "photo_album_source_object_story_id",
                "type": "string"
            },
            {
                "name": "place_page_set_id",
                "description": "The ID of the page set for this creative. See the[Local Awareness guide](/docs/marketing-api/guides/local-awareness)",
                "type": "numeric string"
            },
            {
                "name": "platform_customizations",
                "description": "Use this field to specify the exact media to use on different Facebook [placements](/docs/marketing-api/targeting-specs/#placement). You can currently use this setting for images and videos. Facebook replaces the media originally defined in ad creative with this media when the ad displays in a specific placements. For example, if you define a media here for `instagram`, Facebook uses that media instead of the media defined in the ad creative when the ad appears on Instagram.",
                "type": "adcreativeplatformcustomization"
            },
            {
                "name": "playable_asset_id",
                "description": "The ID of the playable asset in this creative",
                "type": "numeric string"
            },
            {
                "name": "portrait_customizations",
                "description": "This field describes the rendering customizations selected for portrait mode ads like IG Stories, FB Stories, IGTV, etc",
                "type": "adcreativeportraitcustomizations"
            },
            {
                "name": "product_set_id",
                "description": "Used for [Dynamic Ad](/docs/marketing-api/dynamic-product-ads). An ID for a product set, which groups related products or other items being advertised.",
                "type": "numeric string"
            },
            {
                "name": "recommender_settings",
                "description": "Used for [Dynamic Ads](/docs/marketing-api/dynamic-product-ads). Settings to display Dynamic ads based on product recommendations.",
                "type": "adcreativerecommendersettings"
            },
            {
                "name": "referral_id",
                "description": "The ID of Referral Ad Configuration in this creative",
                "type": "numeric string"
            },
            {
                "name": "source_instagram_media_id",
                "description": "The ID of an Instagram post for creating ads",
                "type": "numeric string"
            },
            {
                "name": "status",
                "description": "The status of the creative. `WITH_ISSUES` and `IN_PROCESS` are available for 4.0 or higher",
                "type": "enum {active, in_process, with_issues, deleted}"
            },
            {
                "name": "template_url",
                "description": "Used for [Dynamic Ads](/docs/marketing-api/dynamic-product-ads) when you want to use third-party click tracking. See [Dynamic Ads, Click Tracking and Templates](/docs/marketing-api/dynamic-product-ads/ads-management#adtemplate).",
                "type": "string"
            },
            {
                "name": "template_url_spec",
                "description": "Used for [Dynamic Ads](/docs/marketing-api/dynamic-product-ads) when you want to use third-party click tracking. See [Dynamic Ads, Click Tracking and Templates](/docs/marketing-api/dynamic-product-ads/ads-management#adtemplate).",
                "type": "adcreativetemplateurlspec"
            },
            {
                "name": "thumbnail_id",
                "description": "thumbnail_id",
                "type": "numeric string"
            },
            {
                "name": "thumbnail_url",
                "description": "URL for a thumbnail image for this ad creative. You can provide dimensions for this with `thumbnail_width` and `thumbnail_height`. [See example](/docs/marketing-api/reference/ad-creative#thumbnail-example).",
                "type": "string"
            },
            {
                "name": "title",
                "description": "Title for link ad, which does not belong to a page.",
                "type": "string"
            },
            {
                "name": "url_tags",
                "description": "A set of query string parameters which will replace or be appended to urls clicked from page post ads, message of the post, and canvas app install creatives only",
                "type": "string"
            },
            {
                "name": "use_page_actor_override",
                "description": "Used for [App Ads](/docs/app-ads). If `true`, we display the Facebook page associated with the app ads.",
                "type": "bool"
            },
            {
                "name": "video_id",
                "description": "Facebook object ID for video in this ad creative.",
                "type": "numeric string"
            }
        ],
 */

export type AdCreativeFields = {
  account_id: string;
  actor_id: string;
  adlabels: Array<{ name: string }>;
  degrees_of_freedom_spec: any;
  destination_set_id: string;
  dynamic_ad_voice: string;
  effective_authorization_category: string;
  effective_instagram_media_id: string;
  effective_instagram_story_id: string;
  effective_object_story_id: string;
  enable_direct_install: boolean;
  enable_launch_instant_app: boolean;
  facebook_branded_content: any;
  id: string;
  applink_treatment: string;
  asset_feed_spec: AssetFeedSpec;
  authorization_category: string;
  body: string;
  branded_content: any;
  bundle_folder_id: string;
  call_to_action_type: CallToActionType;
  categorization_criteria: string;
  category_media_source: string;
  collaborative_ads_lsb_image_bank_id: string;
};

export interface CreateApi {
  image: ImageCreateApi;
  parentInternalId: string;
  object_story_spec: ObjectStorySpecCreateApi;
  image_crops: ImageCrops;
  asset_feed_spec: AssetFeedSpecCreate;
  url_tags: string;
  degrees_of_freedom_spec: DegreesOfFreedomSpec;
}

export interface AssetFeedSpecCreate {
  descriptions?: Array<{ text: string }>;
  bodies?: Body[];
  titles?: Body[];
  // optimization_type: string;
}

export interface DegreesOfFreedomSpec {
  creative_features_spec: CreativeFeaturesSpec;
}

export interface CreativeFeaturesSpec {
  standard_enhancements: StandardEnhancements;
}

export interface StandardEnhancements {
  enroll_status: string;
}

export interface ImageCreateApi {
  id: string;
  image_url: string;
  width?: number;
  height?: number;
  size?: number;
}

export interface ObjectStorySpecCreateApi {
  page_id: string;
  instagram_actor_id: string;
  link_data: LinkData;
}

export interface LinkData {
  link: string;
  name?: string;
  message?: string;
  description?: string;
  caption: string;
  attachment_style: string;
  image_hash?: string;
  call_to_action: CallToActionCreateApi;
}

export interface CallToActionCreateApi {
  type: string;
}

export interface ImageHash {
  internalId: string;
  key: string;
  key_path: string[];
}
