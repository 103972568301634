import userLocalStorage from '@sh/services/userLocalStorage';

const storageKey = 'column_presets';

export default class Presets {
  getPresetByTrafficSource(tsType) {
    const data = this._getData();
    if (data) {
      return data[tsType];
    }
    return null;
  }

  /**
   * Save chosen preset for each traffic source to Local Storage
   * @param {String} tsType Traffic Source Type
   * @param {String} value Preset Id
   */
  setPresetByTrafficSource(tsType, value) {
    const data = this._getData() || {};
    if (tsType) {
      data[tsType] = value;
    }
    this._setData(data);
  }

  _getData() {
    return userLocalStorage.getSettings(storageKey);
  }

  _setData(payload) {
    userLocalStorage.setSettings(storageKey, payload);
  }
}
