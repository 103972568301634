
import { useMediaManagerStore } from '@/stores/media-manager';
import AdFilters from '@/views/Automation/MediaManager/AdFilters.ts.vue';
import TableView from '@/views/Automation/MediaManager/TableView.ts.vue';
import AdCard from '@sh/components/MediaManager/AdCard/AdCard.ts.vue';
import NoData from '@sh/components/MediaManager/NoData/NoData.ts.vue';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import notifications from '@sh/mixins/notifications';
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

let stickyContainerObserver: IntersectionObserver | undefined;

export default defineComponent({
  components: {
    AdFilters,
    AdCard,
    LoadingSkeleton,
    TableView,
    NoData,
  },
  mixins: [notifications],
  data() {
    return {
      pagination: {
        enabled: true,
        pageSize: 30,
      },
    };
  },
  computed: {
    cardsLoading(): boolean[] {
      return this.ads.map(
        (ad) => this.isPendingRequest && !!this.selectedAds.find((selectedAd) => ad.id === selectedAd.id)
      );
    },
    ...mapState(useMediaManagerStore, [
      'mode',
      'isLoadingAds',
      'isCampaignCreator',
      'selectedAds',
      'totalPages',
      'filters',
      'ads',
      'errorMessage',
      'isPendingRequest',
      'isTableView',
    ]),
  },
  mounted() {
    if (this.$refs.sentinel && this.$refs.stickyContainer) {
      stickyContainerObserver = new IntersectionObserver(([event]) => {
        ((this.$refs.stickyContainer as Vue).$el as HTMLElement).classList.toggle(
          'is-pinned',
          event.intersectionRatio < 1
        );
      });
      stickyContainerObserver.observe(this.$refs.sentinel as Element);
    }
  },
  beforeDestroy() {
    if (stickyContainerObserver && this.$refs.sentinel) {
      stickyContainerObserver.unobserve(this.$refs.sentinel as Element);
    }
  },
  methods: {
    ...mapActions(useMediaManagerStore, ['onAdModalOpen', 'updateFilters']),
    onPageChange(page: number) {
      this.updateFilters({ page });
    },
  },
});
