export default (F) => ({
  traffic_source_domain_id: F.create({
    header: { content: 'Domain ID' },
    item: {
      key: 'traffic_source_domain_id',
      sortable: true,
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--domain-id',
  }),
  status: F.create({
    header: { content: 'Status' },
    item: {
      slot: 'status',
      key: 'status',
      sortable: true,
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: null,
    },
  }),
  'cpc::Gemini': F.create({
    header: { content: 'CPC' },
    item: {
      key: 'cpc',
      sortable: true,
    },
  }),
  'additional_fields.calculated_cpc': F.create({
    header: { content: 'CPC' },
    item: {
      key: 'additional_fields.calculated_cpc',
      sortable: true,
    },
  }),
  'additional_fields.targetUrl': F.create({
    header: { content: 'Target Url' },
    item: {
      key: 'additional_fields.targetUrl',
      slot: 'placement',
      sortable: true,
    },
  }),
});
