<template>
  <div class="rule-interval-wrapper row">
    <div class="col-md-6">
      <b-form-group
        :state="$_hasError('interval')"
        class="optimizer-form-group"
        label="Default Data Interval"
        :invalid-feedback="$_getError('interval')"
      >
        <vue-opti-select-light
          class="optimizer-select w-100"
          name="interval"
          data-vv-as="Interval"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          :value="interval.input"
          @change="onIntervalChange"
          :options="config.intervalsConfig.intervals"
          label-key="text"
          single
          button-block
        >
          <template v-if="hasButtonPlaceholder" #BUTTON_PLACEHOLDER="{ option }">
            <span class="button-placehoder-filter">
              {{ option.text }} <span class="fs-11 ml-6">( Today Included ) </span>
            </span>
          </template>
        </vue-opti-select-light>
      </b-form-group>
    </div>
    <div class="col-md-6">
      <b-form-group
        class="optimizer-form-group"
        label="Exclude Days from Default Interval"
      >
        <vue-opti-select-light
          class="optimizer-select w-100"
          name="interval_exclude"
          data-vv-as="Interval Exclude"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          :options="intervalsExclude"
          :value="interval.interval_exclude"
          @change="(option) => { interval.interval_exclude = option.value }"
          label-key="text"
          single
          button-block
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleInterval',
  mixins: [component],
  props: {
    value: { type: Object, default: () => ({ input: '', interval_exclude: '0' }) },
  },
  data() {
    return {
      interval: {
        input: '',
        interval_exclude: '0',
      },
    };
  },
  computed: {
    intervalsExclude() {
      return this.config.intervalsConfig.intervalsExclude.map((item) => ({
        ...item,
        disabled: this.isOptionDisabled(item.value),
      }));
    },
    hasButtonPlaceholder() {
      const Today = '0';
      const Yesterday = '-1';
      return this.interval.input && ![Today, Yesterday].includes(this.interval.input);
    },
  },
  watch: {
    interval: {
      immediate: true,
      handler() {
        this.$_emit('interval');
      },
      deep: true,
    },
  },
  created() {
    this.interval = this.value;
  },
  methods: {
    /**
     * Default interval options have different value from interval exclude options
     * this.config.intervalsConfig.intervalsExclude - None = 0, Today = 1, Today & Yesterday = 2, Last 3 Days = 3
     * this.config.intervalsConfig.intervals - Today = 0, Yesterday = -1, Today & Yesterday = -1.5, Last 3 Days = -2, Last 4 Days = -3, Last 5 Days = -4 ... This month = +30, Last 30 Days = -29, Last 60 Days = -59, Last 90 Days = -89
     * @param {string} excludeOptionValue - Interval exclude option value
     * First we convert the default interval value to a number and then we convert the value to refer with the exclude options
     * Interval exclude options are disabled for values greater or equal than default interval selected both (considering absolute values)
     */
    isOptionDisabled(excludeOptionValue) {
      const value = parseFloat(this.interval.input, 10);
      const comparisonValue = value <= -2 ? value - 1 : Math.ceil(value);
      const excludeValue = parseInt(excludeOptionValue, 10);

      return excludeValue !== 0 && excludeValue + comparisonValue >= 0;
    },
    onIntervalChange(option) {
      this.interval.input = option.value;

      if (this.isOptionDisabled(this.interval.interval_exclude)) {
        this.interval.interval_exclude = '0';
      }
    },
  },
};
</script>

<style lang="scss">
.rule-interval-wrapper {
  .optimizer-form-group {
    margin-bottom: .8rem;
    margin-top: .8rem;
  }
}
</style>
