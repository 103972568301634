import { apiStore, clearCache } from '@sh/services/api';
import session from '@sh/services/session';

let prefetchStatus = false;

const prefetchHandler = () => {
  if (session.isAuthenticated()) {
    if (!prefetchStatus) {
      clearCache();
      prefetchStatus = true;
      apiStore.trafficSources.types();
      apiStore.trafficSources.accounts();
      apiStore.trackers.types();
      apiStore.trackers.accounts();
      apiStore.presets.getPresets();
      const customPrefetch = require('@/loaders/prefetch');
      customPrefetch.prefetchHandler();
    }
  }
};

session.afterAuthentication(() => {
  prefetchStatus = false;
  prefetchHandler();
});

session.afterLogout(() => {
  prefetchStatus = false;
  clearCache();
});

prefetchHandler();
