import DashboardContainer from '@sh/containers/DashboardContainer.ts.vue';
import session from '@sh/services/session';

// Pages
import Login from '@sh/views/Pages/Login.ts.vue';
import Page404 from '@sh/views/Pages/Page404.vue';
import Page500 from '@sh/views/Pages/Page500.ts.vue';

// Components
import Campaigns from '@/views/Campaigns/Campaigns.vue';
import Dashboard from '@sh/views/Dashboard/Dashboard.vue';

import Campaign from '@/views/Campaign/Campaign.vue';

import AccountWizard from '@sh/views/Accounts/AccountWizard/Index';
import Trackers from '@sh/views/Accounts/Trackers/Index.vue';
import TrafficSources from '@sh/views/Accounts/TrafficSources/Index.vue';

import SmartLists from '@/views/Automation/Lists/Index.vue';
import SmartListPage from '@/views/Automation/Lists/SmartListPage.vue';

import ruleConfig from '@/views/Automation/Rules/config';
import Rules from '@sh/views/Rules/Index.vue';
import Rule from '@sh/views/Rules/Rule';

import ManualStatsUpdate from '@/views/Tools/ManualStatsUpdate/Index.vue';
import SystemActivityPage from '@/views/Tools/SystemActivity/SystemActivityPage.vue';
import ManualConnection from '@sh/views/Tools/ManualConnection/ManualConnection';

import CampaignCreator from '@/views/Automation/CampaignCreator/CampaignCreator';
import CampaignCreatorQueue from '@/views/Automation/CampaignCreator/Queue';
import ContentUploader from '@/views/Automation/CampaignCreator/steps/Contents/ContentsPage';

import Reporting from '@/views/Tools/Reporting/Index.ts.vue';

import Settings from '@sh/views/Tools/Settings/Settings';

import SubuserChangePassword from '@sh/views/Pages/SubuserChangePassword';
import SubuserRegister from '@sh/views/Pages/SubuserRegister';
import Profile from '@sh/views/Profile/Profile';

import GotoComponent from '@sh/components/Utils/GotoComponent';

import CsvUpload from '@/views/Automation/CampaignCreator/components/CsvUpload.ts.vue';
import CreateContents from '@/views/Automation/MediaManager/CreateContents.ts.vue';
import MediaManager from '@/views/Automation/MediaManager/MediaManager.ts.vue';
import { MediaManager as MediaManagerTypes } from '@sh/types';

const notLogged = (to, from, next) => {
  if (to.query.token) {
    const jwt = localStorage.getItem('jwt-token');
    if (jwt) {
      session.removeToken();
    }
    session.setToken(to.query.token);
  }
  if (session.isAuthenticated()) {
    return next({ name: 'Home' });
  }
  session.removeToken();
  next();
};

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
    name: 'Home',
    component: DashboardContainer,
    beforeEnter: (to, from, next) => (!session.isAuthenticated() ? next({ name: 'Login' }) : next()),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: Campaigns,
      },
      {
        path: 'campaign/:id/:type',
        name: 'Campaign',
        component: Campaign,
      },
      {
        path: 'rules',
        name: 'Rules',
        component: Rules,
        props: { configFile: ruleConfig },
      },
      {
        path: 'rules/new',
        name: 'New Rule',
        component: Rule,
        props: {
          configFile: ruleConfig,
          mode: 'create',
        },
      },
      {
        path: 'rules/:id/edit',
        name: 'Edit Rule',
        component: Rule,
        props: { configFile: ruleConfig, mode: 'edit' },
      },
      {
        path: 'rules/:id/clone',
        name: 'Clone Rule',
        component: Rule,
        props: {
          configFile: ruleConfig,
          mode: 'clone',
        },
      },
      {
        path: 'smart-lists',
        name: 'Smart Lists',
        component: SmartLists,
      },
      {
        path: 'smart-lists/new',
        name: 'New Smart List',
        component: SmartListPage,
        props: { mode: 'create' },
      },
      {
        path: 'smart-lists/:id/edit',
        name: 'Edit Smart List',
        component: SmartListPage,
        props: { mode: 'edit' },
      },
      {
        path: 'smart-lists/:id/clone',
        name: 'Clone Smart List',
        component: SmartListPage,
        props: { mode: 'clone' },
      },
      {
        path: 'media-manager',
        name: 'GotoMediaManager',
        component: GotoComponent,
        props: { to: '/media-manager/ads' },
      },
      {
        path: 'media-manager/ads',
        name: 'Creative Library',
        component: MediaManager,
        props: { mode: MediaManagerTypes.Mode.ADS },
      },
      {
        path: 'media-manager/images',
        name: 'Creative Library Images',
        component: MediaManager,
        props: { mode: MediaManagerTypes.Mode.IMAGES },
      },
      {
        path: 'media-manager/headlines',
        name: 'Creative Library Headlines',
        component: MediaManager,
        props: { mode: MediaManagerTypes.Mode.HEADLINES },
      },
      {
        path: 'media-manager/create/ads',
        name: 'Upload New Creatives',
        component: CreateContents,
        props: { mode: MediaManagerTypes.Mode.ADS },
      },
      {
        path: 'media-manager/create/images',
        name: 'Upload New Images',
        component: CreateContents,
        props: { mode: MediaManagerTypes.Mode.IMAGES },
      },
      {
        path: 'media-manager/create/headlines',
        name: 'Upload New Headlines',
        component: CreateContents,
        props: { mode: MediaManagerTypes.Mode.HEADLINES },
      },
      {
        path: 'system-activity',
        name: 'SystemActivityPage',
        component: SystemActivityPage,
        props: { showUTCtime: true },
      },
      {
        path: 'account-wizard',
        name: 'AccountWizard',
        component: AccountWizard,
      },
      {
        path: 'account-wizard/reload',
        name: 'GotoAccountWizard',
        component: GotoComponent,
        props: { to: '/account-wizard' },
      },
      {
        path: 'traffic-sources',
        name: 'TrafficSources',
        component: TrafficSources,
      },
      {
        path: 'trackers',
        name: 'Trackers',
        component: Trackers,
      },
      {
        path: 'manual-connection',
        name: 'ManualConnection',
        component: ManualConnection,
      },
      {
        path: 'campaign-creator-queue',
        name: 'CampaignCreatorQueue',
        component: CampaignCreatorQueue,
      },
      {
        path: 'campaign-creator/reload',
        name: 'GotoCampaignCreator',
        component: GotoComponent,
        props: { to: '/campaign-creator' },
      },
      {
        path: 'campaign-creator',
        name: 'CampaignCreator',
        component: CampaignCreator,
      },
      {
        path: 'content-uploader',
        name: 'ContentUploader',
        component: ContentUploader,
      },
      {
        path: 'reporting',
        name: 'Reporting',
        component: Reporting,
      },
      {
        path: 'manual-stats-update',
        name: 'ManualStatsUpdate',
        component: ManualStatsUpdate,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'csv-upload',
        name: 'CsvUpload',
        component: CsvUpload,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: notLogged,
  },
  {
    path: '/register',
    name: 'SubuserRegister',
    component: SubuserRegister,
  },
  {
    path: '/change_password',
    name: 'SubuserChangePassword',
    component: SubuserChangePassword,
  },
  {
    path: '/500',
    name: 'Page500',
    component: Page500,
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404,
  },
];

export default routes;
