/* eslint-disable import/no-cycle */
import { ABBase } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { ABRegistry } from '@/views/Automation/CampaignCreatorV2/ab/core/ABRegistry';
import * as ABTest from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { ABGroupMetadata, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';

// Group Joiner joins variables of same type/interchange types and presents them as a single AB Group Variable
// This is used to join two variables of same type or interchanged type
// This is used to make sure all items of same type are grouped together and only one of them is selected at a time.
export class GroupABTestJoiner<
  Value extends ABTest.ABValue<unknown>[],
  E extends SupportedEntities
> extends ABBase<Value> {
  constructor(
    private _id: string,
    private group_name: string,
    private Gmetadata: ABGroupMetadata<E>,
    private registry: ABRegistry
  ) {
    super();
    this.Gmetadata;
    this._id;
    this.validateAbTests();
  }

  private _mainGroupId?: string;

  public get mainGroupId(): string | undefined {
    return this._mainGroupId;
  }

  public set mainGroupId(value: string | undefined) {
    this._mainGroupId = value;
  }

  private get abTests(): Array<ABBase<Value>> {
    const abValues = this.children.map((child) => {
      const group = this.registry.getAbGroup<Value>(child, this.entityId);
      const variable = this.registry.getVariable(child, this.entityId);
      if (!group && !variable) {
        throw new Error(`Group or variable not found ${child}`);
      }
      if (group && variable) {
        throw new Error('Group and variable found');
      }
      return group || variable;
    });
    return abValues;
  }

  private validateAbTests(): void {
    const abTests = this.abTests.map((abTest) => abTest.dependencies.map((dep) => dep.field)).flat();
    const uniqueAbTests = [...new Set(abTests)];
    if (abTests.length !== uniqueAbTests.length) {
      throw new Error('AB test cannot be applied to the same field twice');
    }
  }

  public get dependencies(): ABTest.ABDependency[] {
    return this.abTests.map((abTest) => abTest.dependencies).reduce((prev, curr) => [...prev, ...curr], []);
  }

  public get name(): string {
    return this.group_name;
  }

  public get description(): string {
    return this.metadata.description;
  }

  public get macros(): ABTest.Macro[] {
    return this.abTests.map((abTest) => abTest.macros).flat();
  }

  public get variableUsage(): Record<string, number> {
    return this.abTests
      .map((abTest) => abTest.variableUsage)
      .reduce((prev, curr) => {
        const keys = Object.keys(curr);
        keys.forEach((key) => {
          prev[key] = prev[key] ? prev[key] + curr[key] : curr[key];
        });
        return prev;
      }, {} as Record<string, number>);
  }

  public get possibleOptions(): Array<ABTest.ABDependency<Value>[]> {
    const options = this.abTests.map((abTest) => abTest.possibleOptions);
    return options.flat(1);
  }

  public get type(): ABTest.ABTestType {
    return 'GROUP_JOINER';
  }
}
