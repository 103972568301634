import { render, staticRenderFns } from "./Taboola.vue?vue&type=template&id=6995faea"
import script from "./Taboola.vue?vue&type=script&lang=js"
export * from "./Taboola.vue?vue&type=script&lang=js"
import style0 from "./Taboola.vue?vue&type=style&index=0&id=6995faea&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports