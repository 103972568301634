<template>
  <div class="campaigns-performance-filter">
    <div class="row mb-4">
      <div class="col-md-3 pb-2">
        <loading-skeleton
          v-if="loading"
          class="campaigns-select-skeleton"
          type="card"
          :card="{ rows: 1 }"
        />
        <vue-opti-select-light
          v-show="show"
          ref="trafficSourceType"
          :disabled="disabled"
          class="optimizer-select"
          :options="options.trafficSourceTypes"
          unique-key="id"
          label-key="name"
          @change="$_changeTrafficSourceType"
          button-placeholder="Select Traffic Source Type"
          button-block
          v-model="models.trafficSourceType"
        >
          <template #ITEM="{ option }">
            <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.uniqueName)" alt="traffic-source-logo" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
        </vue-opti-select-light>
      </div>
      <div class="col-md-3 pb-2">
        <loading-skeleton
          v-if="loading"
          class="campaigns-select-skeleton"
          type="card"
          :card="{ rows: 1 }"
        />
        <vue-opti-select-light
          v-show="show"
          ref="trafficSourceAccount"
          :disabled="disabled"
          class="optimizer-select account-select"
          :class="{ vertical: filteredTagsLength }"
          :options="options.trafficSourceAccounts"
          unique-key="id"
          label-key="name"
          option-type="checkbox"
          searchable
          :search-fields="['name']"
          @clear="changeTrafficSourceAccounts([])"
          @click="(option, checked) => changeTrafficSourceAccounts([option], checked)"
          button-placeholder="All Accounts"
          button-placeholder-all-selected="All Accounts"
          :button-placeholder-multiple="({ count, suffix }) => `${count} Account${suffix}`"
          button-block
          allowClear
          v-model="models.trafficSourceAccounts"
          @hidden="onAccountClose"
        >
          <template v-if="options.trafficSourceAccounts && options.trafficSourceAccounts[0]" v-slot:[`ITEM_BEFORE_${options.trafficSourceAccounts[0].id}`]>
            <div class="account-text">
              <span>Accounts</span>
            </div>
            <div class="select-content">
              <Checkbox
                :indeterminate="selectState.indeterminate"
                :value="selectState.allAccountsSelected"
                @change="toggleAllAccounts"
              >
                <div class="option-content">
                  <span :class="{ selected: selectState.allAccountsSelected }">{{ selectState.allAccountsSelected ? 'Un-select All' : 'Select All' }}</span>
                </div>
              </Checkbox>
            </div>
          </template>

          <template #HEADER_2 v-if="filteredTags.length > 0">
            <div class="account-tags">
              <div class="account-text">
                <span>Filter account by tags</span>
              </div>
              <div class="select-dropdown-actions">
                <b-list-group class="tags-container">
                  <b-list-group-item
                    v-for="tag in filteredTags"
                    :key="tag"
                    action
                    :active="isTagSelected(tag)"
                    @click="toggleTagsSelection(tag)"
                    class="tag-options-text"
                  >
                    <span class="option-text">{{ tag }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
            <div class="separator" />
          </template>
          <template #FOOTER>
            <AccountsFooter
              :traffic-source-accounts-length="models.trafficSourceAccounts.length"
              @handleValueClear="handleAccountValueClear"
              @handleDropdownClose="handleAccountDropdownClose"
              @handleFilterValues="filterAccounts"
            />
          </template>
        </vue-opti-select-light>
      </div>
      <div class="col-md-3 pb-2">
        <loading-skeleton
          v-if="loading"
          class="campaigns-select-skeleton"
          type="card"
          :card="{ rows: 1 }"
        />
        <vue-opti-select-light
          v-show="show"
          ref="trackerAccount"
          :disabled="disabled"
          class="optimizer-select"
          :options="options.trackerAccounts"
          unique-key="id"
          label-key="name"
          option-type="checkbox"
          :searchable="true"
          :search-fields="['name']"
          @clear="$_changeTrackerAccounts"
          @change="$_changeTrackerAccounts"
          :groups="options.trackerGroups"
          button-placeholder="All Trackers"
          button-placeholder-all-selected="All Trackers"
          :button-placeholder-multiple="({ count, suffix }) => `${count} Tracker${suffix}`"
          button-block
          allowClear
          v-model="models.trackerAccounts"
        >
          <template #ITEM="{ option }">
            <img class="acc-item-icon" :src="option.uniqueName !== 'ClickFlare' ? `/sh_static/accounts/${option.uniqueName}.png` : '/sh_static/accounts/ClickFlare.svg'" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
          <template #ITEM_0="{ option }">
            {{ option.name }}
          </template>
        </vue-opti-select-light>
      </div>
      <div class="col-md-3 pb-2">
        <loading-skeleton
          v-if="loading"
          class="campaigns-select-skeleton"
          type="card"
          :card="{ rows: 1 }"
        />
        <date-range
          v-else
          :disabled="disabled"
          :class="{ disabled }"
          :hasComparison="true"
          :style="`${!show ? 'display: none !important' : ''}`"
          v-model="models.dateRangePicker"
          :auto-emit="true"
          ref="dateRangePicker"
          @change="$_changeDateRangePicker"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'pinia';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import { getTrafficSourceLogo } from '@sh/helpers';
import moment from 'moment';
import { useTrafficSourcesStore } from '@/stores/traffic-sources';
import Checkbox from '@sh/components/Utils/Checkbox.ts.vue';
import AccountsFooter from '@sh/components/Utils/AccountsFilters/AccountsFooter.ts.vue';
import { useCampaignsStore } from '@/stores/campaigns-store';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'CampaignsPerformanceFilter',
  components: {
    AccountsFooter,
    Checkbox,
    LoadingSkeleton,
  },
  props: {
    loading: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      TAGS_LIMIT: 4,
      COMPONENT_ID: 'CampaignsPerformanceFilter',
      rawApi: {
        trafficSourceTypes: [],
        trafficSourceAccounts: [],
      },
      maps: {
        trafficSourceAccountsByTypeId: {},
      },
      options: {
        trafficSourceTypes: [],
        trafficSourceAccounts: [],
        trackerAccounts: [],
        trackerGroups: [],
      },
      models: {
        trafficSourceType: [],
        trafficSourceAccounts: [],
        trackerAccounts: [],
        dateRangePicker: {
          startDate: moment(new Date()).format(FORMAT),
          endDate: moment(new Date()).endOf('day').format(FORMAT),
        },
        dateRangePickerComparison: {
          comparisonStartDate: '',
          comparisonEndDate: '',
          isDateComparisonEnabled: false,
          period: '',
        },
      },
    };
  },
  computed: {
    filteredTags() {
      return this.getAllAvailableTagsForAccounts(this.models.trafficSourceType?.[0]?.uniqueName);
    },
    selectState() {
      return {
        indeterminate: (this.models.trafficSourceAccounts.length && this.models.trafficSourceAccounts.length !== this.options.trafficSourceAccounts.length) || false,
        allAccountsSelected: this.models.trafficSourceAccounts.length === this.options.trafficSourceAccounts.length,
      };
    },
    filteredTagsLength() {
      const totalLength = this.filteredTags.join('').length;
      return totalLength > 55 && this.filteredTags.length > this.TAGS_LIMIT;
    },
    ...mapState(useTrafficSourcesStore, ['getAllAvailableTagsForAccounts', 'filterTrafficSourceAccounts', 'filterState']),
  },
  watch: {
    'models.trafficSourceType': function () {
      this.getAllAvailableTagsForAccounts([this.models.trafficSourceType[0]?.uniqueName]);
    },
  },
  async created() {
    this.initFilters(this.COMPONENT_ID);
    const campaignsPerformanceFilter = this.$settings.campaignsPerformanceFilter.getFilter();

    /* *********** Set Date Range ************ */
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.models.dateRangePicker.startDate = this.$route.query.startDate;
      this.models.dateRangePicker.endDate = this.$route.query.endDate;
      this.models.dateRangePickerComparison.comparisonStartDate = this.$route.query.comparisonStartDate;
      this.models.dateRangePickerComparison.comparisonEndDate = this.$route.query.comparisonEndDate;
      this.models.dateRangePickerComparison.isDateComparisonEnabled = !!((this.$route.query.comparisonStartDate && this.$route.query.comparisonEndDate));
      this.models.dateRangePickerComparison.period = this.$route.query.period;
    } else if (campaignsPerformanceFilter) {
      const { dateRange } = campaignsPerformanceFilter;
      if (dateRange) {
        this.models.dateRangePicker.startDate = dateRange.startDate;
        this.models.dateRangePicker.endDate = dateRange.endDate;
        this.models.dateRangePicker.comparisonStartDate = this.$route.query.comparisonStartDate;
        this.models.dateRangePicker.comparisonEndDate = this.$route.query.comparisonEndDate;
        this.models.dateRangePickerComparison.isDateComparisonEnabled = !!((this.$route.query.comparisonStartDate && this.$route.query.comparisonEndDate));
        this.models.dateRangePickerComparison.period = this.$route.query.period;
        this.$router.replace({ query: { ...this.$route.query, ...{ startDate: dateRange.startDate, endDate: dateRange.endDate } } });
      }
    }

    try {
      /* *********** Get API Data *********** */
      this.registerAccounts();
      [this.rawApi.trafficSourceTypes, this.rawApi.trafficSourceAccounts] = await Promise.all([this.$apiStore.trafficSources.types(), this.$apiStore.trafficSources.accounts()]);
    } catch (error) {
      this.$n_failNotification({ title: error.response.data.message });
    }
    if (this.rawApi.trafficSourceAccounts) {
      // Map Accounts
      this.rawApi.trafficSourceAccounts.forEach((account) => {
        if (!this.maps.trafficSourceAccountsByTypeId[account.type.id]) this.maps.trafficSourceAccountsByTypeId[account.type.id] = [];
        this.maps.trafficSourceAccountsByTypeId[account.type.id].push(account);
      });
      // Set TrafficSourceType Options (Keep order)
      this.options.trafficSourceTypes = this.rawApi.trafficSourceTypes.filter((trafficSourceType) => this.maps.trafficSourceAccountsByTypeId[trafficSourceType.id]).sort((a, b) => a.name.localeCompare(b.name));
      // Set Value
      if (this.$route.query.type) {
        // Set from URI
        let { type, accounts, trackers } = this.$route.query;
        type = parseInt(type, 10);
        accounts = accounts ? accounts.split(',').map((id) => parseInt(id, 10)) : null;
        trackers = trackers ? trackers.split(',').map((id) => parseInt(id, 10)) : null;
        this.$_updateAccounts({ trafficSourceTypeId: type });
        this.$nextTick(() => {
          this.$_updateAccounts({ trafficSourceTypeId: type });
          this.$nextTick(() => {
            if (accounts) this.$_updateAccounts({ trafficSourceAccountsIds: accounts });
            if (trackers) this.$_updateAccounts({ trackerAccountsIds: trackers });
            this.$_emit();
          });
        });
      } else if (campaignsPerformanceFilter) {
        // Set from LocalStorage UserSettings
        const { type, accounts, trackers } = campaignsPerformanceFilter;
        this.getAllAvailableTagsForAccounts([this.models.trafficSourceType[0]?.uniqueName]);
        this.$_updateAccounts({ trafficSourceTypeId: type });
        this.$nextTick(() => {
          this.$_updateAccounts({ trafficSourceTypeId: type });
          this.$nextTick(() => {
            if (accounts) this.$_updateAccounts({ trafficSourceAccountsIds: accounts });
            if (trackers) this.$_updateAccounts({ trackerAccountsIds: trackers });
            this.$_emit();
          });
        });
      } else {
        // Select Default
        this.$nextTick(() => {
          this.$_updateAccounts({ trafficSourceTypeId: this.options.trafficSourceTypes[0].id });
          this.$_emit();
        });
      }
    } else {
      this.$emit('noData');
    }
  },
  methods: {
    isTagSelected(tag) {
      return this.filterState[this.COMPONENT_ID].filterSession.tags?.includes(tag);
    },
    resetSelectedTags() {
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          tags: [],
        },
      };
    },
    selectAllTsAccounts() {
      this.models.trafficSourceAccounts = cloneDeep(this.options.trafficSourceAccounts);
    },
    toggleTagsSelection(tag) {
      const index = this.filterState[this.COMPONENT_ID].filterSession.tags.indexOf(tag);
      const selectedTrafficSource = this.models.trafficSourceType[0]?.uniqueName;
      const filterOptions = { trafficSource: selectedTrafficSource, linkedTrackers: [], status: '', currency: [] };

      if (index === -1) {
        this.filterState[this.COMPONENT_ID].filterSession = {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          tags: [...this.filterState[this.COMPONENT_ID].filterSession.tags, tag],
        };
        const selectedAccountIds = this.filterTrafficSourceAccounts({ ...filterOptions, tags: this.filterState[this.COMPONENT_ID].filterSession.tags });

        this.changeTrafficSourceAccounts(selectedAccountIds, true);
      } else if (index !== -1) {
        this.filterState[this.COMPONENT_ID].filterSession.tags.splice(index, 1);
        // Items selected from specified tag
        const selectedAccountIds = this.filterTrafficSourceAccounts({ ...filterOptions, tags: [tag] });
        const remainSelectedAccounts = this.filterTrafficSourceAccounts({ ...filterOptions, tags: this.filterState[this.COMPONENT_ID].filterSession.tags.length ? this.filterState[this.COMPONENT_ID].filterSession.tags : [''] }).map((ts) => ts.id);
        const accountsForRemove = selectedAccountIds.filter((e) => !remainSelectedAccounts.includes(e.id));

        this.changeTrafficSourceAccounts(accountsForRemove, false);
      }
    },
    onAccountClose() {
      const savedFilters = this.filterState[this.COMPONENT_ID].savedFilters.find(
        (item) => item.id === this.COMPONENT_ID,
      );

      if (savedFilters) {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: {
            ...this.filterState[this.COMPONENT_ID].filterSession,
            tags: savedFilters.tags,
          },
        };

        this.models.trafficSourceAccounts = this.options.trafficSourceAccounts.filter((item) => savedFilters.trafficSource.includes(item.id));
        if (!this.models.trafficSourceAccounts?.length) {
          this.resetSelectedTags();
        }
      }
    },
    toggleAllAccounts(checked) {
      if (checked) {
        this.selectAllTsAccounts();
      } else {
        this.models.trafficSourceAccounts = [];
      }
    },
    getTrafficSourceLogo,
    $_updateAccounts({ trafficSourceTypeId, trafficSourceAccountsIds, trackerAccountsIds }, force = false) {
      let updateTrackerOptions = false;
      if (trafficSourceTypeId || force) {
        // Set Traffic Source Type
        this.$refs.trafficSourceType.clear();
        this.$refs.trafficSourceAccount.clear();
        this.$refs.trafficSourceType.add(trafficSourceTypeId);
        // Set Traffic Source Accounts Options
        this.options.trafficSourceAccounts = this.maps.trafficSourceAccountsByTypeId[trafficSourceTypeId]; // Resolve Accounts
        updateTrackerOptions = true;
      }
      // Set Traffic Source Accounts
      if (trafficSourceAccountsIds) {
        this.$refs.trafficSourceAccount.clear();
        trafficSourceAccountsIds.forEach((id) => {
          this.$refs.trafficSourceAccount.add(id);
        });
        updateTrackerOptions = true;
      }

      // Set Tracker Options
      if (updateTrackerOptions) {
        this.$refs.trackerAccount.clear();
        if (this.models.trafficSourceAccounts.length) {
          this.$_updateAccountsTracker(this.models.trafficSourceAccounts);
        } else {
          this.$_updateAccountsTracker(this.options.trafficSourceAccounts);
        }

        // initialize savedFilters for traffic source accounts if exist from previous session
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          savedFilters: [{
            ...this.filterState[this.COMPONENT_ID].filterSession,
            id: this.COMPONENT_ID,
            type: 'temp',
            trafficSource: this.models.trafficSourceAccounts.map((ts) => ts.id),
          }],
        };
      }

      // Set Tracker Accounts
      if (trackerAccountsIds) {
        this.$refs.trackerAccount.clear();
        trackerAccountsIds.forEach((id) => {
          this.$refs.trackerAccount.add(id);
        });
      }
    },
    $_updateComparisonRange() {
      const { startDate, endDate } = this.$refs.dateRangePicker.compareRanges;
      const period = this.$refs.dateRangePicker.selected.value;
      this.models.dateRangePickerComparison.comparisonStartDate = startDate;
      this.models.dateRangePickerComparison.comparisonEndDate = endDate;
      this.models.dateRangePickerComparison.isDateComparisonEnabled = !!((startDate && endDate));
      this.models.dateRangePickerComparison.period = period;
    },
    $_updateAccountsTracker(trafficSourceAccounts) {
      const trackerAccountsMap = {};
      const trackerAccountOptions = [];
      const trackerGroupMap = {};
      trafficSourceAccounts.forEach((account) => {
        if (account.linkedTrackers) {
          account.linkedTrackers.forEach((tAccount) => {
            if (!trackerAccountsMap[tAccount.trackerTypeId]) trackerAccountsMap[tAccount.trackerTypeId] = {};
            trackerAccountsMap[tAccount.trackerTypeId][tAccount.id] = tAccount;
          });
        }
      });
      Object.values(trackerAccountsMap).forEach((obj) => {
        Object.values(obj).forEach((tAccount) => {
          if (!trackerGroupMap[tAccount.uniqueName]) trackerGroupMap[tAccount.uniqueName] = { value: tAccount.uniqueName, content: tAccount.uniqueName };
          trackerAccountOptions.push({ group: tAccount.uniqueName, ...tAccount });
        });
      });
      const id = 0;
      // Add No Tracker for clickhouse
      trackerAccountOptions.push({ id, name: 'No Tracker', group: 'other' });
      this.options.trackerAccounts = trackerAccountOptions;
      this.options.trackerGroups = Object.values(trackerGroupMap);
      this.options.trackerGroups.push({ value: 'other', content: 'Other' });
    },
    $_changeTrafficSourceType(trafficSourceType) {
      this.campaignsInitialFilters();
      this.$_updateAccounts({ trafficSourceTypeId: trafficSourceType[0].id }, true);
      this.$_updateQuery();
      this.$_emit(true);
    },
    changeTrafficSourceAccounts(trafficSourceAccounts, checked) {
      if (checked) {
        this.models.trafficSourceAccounts.push(...trafficSourceAccounts.filter((ts) => !this.models.trafficSourceAccounts.map((e) => e.id).includes(ts.id)));
      } else {
        // Remove the selected field from the array if the checkbox is unchecked
        this.models.trafficSourceAccounts = this.models.trafficSourceAccounts.filter((v) => !trafficSourceAccounts.map((ts) => ts.id).includes(v.id));
      }

      // Clear all filters when user clicks 'x' in the dropdown
      if (trafficSourceAccounts.length === 0 && checked === undefined) {
        this.filterAccounts();
      }

      // save selected accounts to filter session
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          trafficSource: this.models.trafficSourceAccounts.map((el) => el.id),
        },
      };
      if (!this.models.trafficSourceAccounts?.length) {
        this.resetSelectedTags();
      }
      if (this.models.trafficSourceAccounts.length === this.options.trafficSourceAccounts.length) {
        this.selectAllTsAccounts();
      }
    },
    filterAccounts() {
      this.$_updateAccounts({ trafficSourceAccountsIds: this.models.trafficSourceAccounts?.map(({ id }) => id) });
      this.$_updateQuery();
      this.$_emit();

      // when filter the values, save applied tags and accounts to savedFilters
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        savedFilters: [{
          ...this.filterState[this.COMPONENT_ID].filterSession,
          id: this.COMPONENT_ID,
          type: 'temp',
          tags: this.filterState[this.COMPONENT_ID].filterSession.tags,
          trafficSource: this.models.trafficSourceAccounts.map((ts) => ts.id),
        }],
      };

      const dropdownRef = this.$refs.trafficSourceAccount;
      dropdownRef.hide(true);
    },
    handleAccountValueClear() {
      this.models.trafficSourceAccounts = [];

      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          trafficSource: [],
          tags: [],
        },
      };
    },
    handleAccountDropdownClose() {
      this.$refs.trafficSourceAccount.hide();
      this.handleAccountValueClear();
    },
    $_changeTrackerAccounts() {
      this.$_updateQuery();
      this.$_emit();
    },
    $_changeDateRangePicker() {
      this.$_updateComparisonRange();
      this.$_updateQuery();
      this.$_emit();
    },
    $_emit(updateSearch = false) {
      this.$emit('input', {
        type: this.models.trafficSourceType[0],
        accounts: this.models.trafficSourceAccounts,
        trackers: this.models.trackerAccounts,
        dateRange: this.models.dateRangePicker,
        compareRanges: this.models.dateRangePickerComparison,
      }, updateSearch);
      // Set LocalStorage UserSettings
      const payload = {
        type: this.models.trafficSourceType[0].id,
        dateRange: this.models.dateRangePicker,
        compareRanges: this.models.dateRangePickerComparison,
      };
      if (this.models.trafficSourceAccounts.length) payload.accounts = this.models.trafficSourceAccounts.map(({ id }) => id);
      if (this.models.trackerAccounts.length) payload.trackers = this.models.trackerAccounts.map(({ id }) => id);
      this.$settings.campaignsPerformanceFilter.setFilter(payload);
    },
    $_updateQuery() {
      const query = {
        ...this.$route.query,
        type: this.models.trafficSourceType[0].id,
        startDate: this.models.dateRangePicker.startDate,
        endDate: this.models.dateRangePicker.endDate,
        comparisonStartDate: this.models.dateRangePickerComparison.comparisonStartDate,
        comparisonEndDate: this.models.dateRangePickerComparison.comparisonEndDate,
      };
      if (this.models.trafficSourceAccounts.length !== this.options.trafficSourceAccounts) {
        query.accounts = this.models.trafficSourceAccounts.map(({ id }) => id).join(',');
      } else {
        delete query.accounts;
      }
      if (this.models.trackerAccounts.length !== this.options.trackerAccounts) {
        query.trackers = this.models.trackerAccounts.map(({ id }) => id).join(',');
      } else {
        delete query.trackers;
      }
      this.$router.replace({ query });
    },
    ...mapActions(useTrafficSourcesStore, ['registerAccounts', 'initFilters']),
    ...mapActions(useCampaignsStore, {
      campaignsInitialFilters: 'initialFilters',
    }),
  },
};
</script>

<style lang="scss">
.account-select {
  .list-group-item + .list-group-item.active {
    margin-top: 0;
  }

  .dropdown .dropdown-toggle.btn-block + .dropdown-menu {
    padding: 0 !important;
    min-width: 38rem;

    .search-container {
      margin-bottom: 0 !important;

      .dropdown-header {
        padding: 1rem !important;

        .btn-clear-search {
          top: 1.5rem !important;
        }

        &::after {
          top: 1.5rem !important;
        }
      }
    }

    .account-tags {
      display: flex;
      flex-direction: column;
      gap: 0.3rem !important;
      justify-content: center;
      padding: 1.2rem;

      .account-text {
        padding-bottom: 1.2rem;
      }
    }

    .options-list {
      max-height: 20rem !important;
      padding: 0 1.2rem 1.2rem 1.2rem;

      > div {
        .account-text {
          padding-bottom: 0.4rem;
        }

        .select-content {
          padding: 0.8rem 0.8rem 0.8rem 0;

          .custom-control .custom-control-label {
            font-size: 1.4rem;
            color: $black-600;
            padding-inline-start: 1rem !important;

            &:hover {
              color: $color-dark;
            }

            .option-content .selected {
              color: $color-dark;
            }
          }
        }
      }
    }

    // style for accounts checkboxes list
    .options-list .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      background-color: transparent !important;

      &:hover{
        background-color: transparent !important;
      }

      .option-select {
        display: block !important;
      }

      .option-content {
        padding: 0.8rem 1.2rem 0.8rem 0.1rem;
        display: flex;
        align-items: center;

        &::after {
          display: none;
        }
      }
    }
  }

  &.vertical .dropdown .dropdown-toggle.btn-block + .show {
    width: 67rem !important;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "search search search"
    "tag separator option"
    "tag separator option"
    "footer footer footer";

    &:has(.search-no-results){
      grid-template-areas:
        "search search search"
        "tag separator option"
        "tag separator option"
        "footer footer footer";
    }

    .search-no-results {
      grid-area: option;
      grid-template-areas:
        "search search search"
        "tag separator option"
        "tag separator option"
        "footer footer footer";
      padding-top: 1.5rem;
      font-size: 1.6rem;
      font-weight: 400;
    }

    .search-container {
      grid-area: search;
      margin-bottom: 0 !important;
    }

    .account-tags {
      grid-area: tag;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;


      .select-dropdown-actions {
        @include scrollbar-on-hover;
        max-height: 23rem !important;
        overflow-y: auto;
      }
    }

    .separator {
      grid-area: separator;
      height: 100%;
      margin: 0;
    }

    .options-list {
      @include scrollbar-on-hover;
      grid-area: option;
      max-height: 29rem !important;
      overflow-y: auto;
      overflow-x: hidden;
      width: auto;
      padding: 1.2rem;


      .selected:not(.dropdown-item-text) {
        background-color: transparent !important;
        color: #262626 !important;
      }
    }

    .accounts-footer {
      grid-area: footer;
      justify-content: flex-end;
    }
  }

  .list-group-item + .list-group-item.active {
    margin-top: 0;
  }
}

.campaigns-performance-filter {
  .optimizer-select {

    .dropdown-toggle {
      color: $black-500;

      &:focus,
      &:active {
        color: $black-500;
      }
    }

    .acc-select {
      .acc-item-icon {
        width: 2.4rem;
        display: inline;
      }
      .acc-item-name {
        display: inline;
        padding-left: 10px;
      }
    }

    .select-dropdown-actions {
      display: flex !important;
      flex-direction: row !important;
      flex-flow: row wrap;
      gap: 0.9rem;
      max-height: 11rem;
      overflow-y: auto;

    }

    .search-no-results {
      padding: 0 1.5rem 1.5rem 1.5rem;
      font-size: 1.6rem !important;
      font-weight: 400;
    }
  }
}
</style>
