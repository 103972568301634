import defaults from './defaults';

export default (F) => ({
  ...defaults(F),
  traffic_source_unique_name: F.create({
    item: {
      key: 'traffic_source_unique_name',
      slot: 'traffic_source_unique_name',
      content: (item) => item.traffic_source_unique_name,
      sortable: true,
      total: {
        parse: () => 1,
        content: (totals) => (totals.totalElements ? `Total: ${totals.totalElements}` : '-'),
      },
    },
    colClass: 'sticky',
  }),
  traffic_source_account_name: F.create({
    item: {
      key: 'traffic_source_account_name',
      content: (item) => item.traffic_source_account_name,
      sortable: true,
      total: null,
    },
    colClass: 'sticky',
  }),
  traffic_source_account_id: F.create({
    display: true,
    item: {
      key: 'traffic_source_account_id',
      content: (item) => item.traffic_source_account_id,
      sortable: false,
      searchable: false,
      total: null,
    },
  }),
  status: F.create({
    header: { content: 'Status' },
    item: {
      slot: 'status',
      key: 'status',
      total: null,
    },
  }),
  budget: F.create({
    header: { content: 'Total Budget' },
    item: {
      slot: 'budget',
      key: 'budget',
      total: null,
    },
  }),
  daily_budget: F.create({
    header: { content: 'Daily Budget' },
    item: {
      slot: 'daily_budget',
      key: 'daily_budget',
      total: null,
    },
  }),
  enabled: F.create({
    header: { content: 'Enabled' },
    display: false,
    item: {
      key: 'enabled',
      sortable: true,
      content: (item) => (item.enabled ? 'Running' : 'Paused'),
    },
  }),
  discrepancy: F.create({
    header: { content: 'Clicks Discrepancy' },
    item: {
      slot: 'discrepancy',
      key: 'discrepancy',
      total: null,
    },
  }),
});
