import { RuleHelpers } from '@sh/helpers';
import { RuleTypes } from '@sh/types';
import { defineStore } from 'pinia';

interface RuleStore {
  ruleModel: NullableType<RuleTypes.RuleModel>;
  config: any;
  canAccessCustomInterval: boolean;
  noAccessRuleFrequency: boolean;
}

export const useRuleStore = defineStore('rule', {
  state: (): RuleStore => ({
    ruleModel: null,
    config: null,
    canAccessCustomInterval: false,
    noAccessRuleFrequency: false, // This value is updated after unleash request, if set to 'true' RuleRotation component does not work, it makes an initial value update that should not happen
  }),
  getters: {
    hasCustomIntervalSelected(): boolean {
      return !!this.ruleModel?.rule?.components?.conditions?.value?.some((condition) => {
        const data = Object.values(condition);
        // @ts-ignore
        return data.some((item) => item.conditionV2?.left.interval || item.conditionV2?.right.interval);
      });
    },
    customDataDefaultInterval(): RuleTypes.ConditionsV2.DateOperations[] {
      const intervalValues = this.ruleModel?.rule?.components?.interval?.value ?? {};
      const maxValue = -parseInt(intervalValues.interval_exclude ?? '0', 10);
      const from = parseFloat(intervalValues.input || '-30');
      const to = maxValue >= RuleHelpers.DateConditionSlider.min ? RuleHelpers.DateConditionSlider.min : maxValue;

      if (from === 30) {
        return RuleHelpers.ConditionsV2.getDateOperations([0, 0], RuleTypes.ConditionsV2.DateOperationsUnit.Month);
      }

      if (from === -1.5) {
        return RuleHelpers.ConditionsV2.getDateOperations([-1, 0]);
      }

      if (from === -1) {
        return RuleHelpers.ConditionsV2.getDateOperations([-1, -1]);
      }

      return RuleHelpers.ConditionsV2.getDateOperations([from, to]);
    },
    customIntervalPresets(): RuleTypes.ConditionsV2.IntervalPreset[] {
      const getDefaultPresets = (items: number[]) =>
        items.map((item) => ({
          value: RuleHelpers.ConditionsV2.getDateOperations([item, 0]),
          text: `Last ${Math.abs(item) + 1} Days`,
        }));

      return [
        { value: RuleHelpers.ConditionsV2.getDateOperations([0, 0]), text: 'Today' },
        { value: RuleHelpers.ConditionsV2.getDateOperations([-1, -1]), text: 'Yesterday' },
        { value: RuleHelpers.ConditionsV2.getDateOperations([-1, 0]), text: 'Today & Yesterday' },
        ...getDefaultPresets([-2, -3, -4, -5, -6]),
        {
          value: RuleHelpers.ConditionsV2.getDateOperations([0, 0], RuleTypes.ConditionsV2.DateOperationsUnit.Week),
          text: 'This Week',
        },
        {
          value: RuleHelpers.ConditionsV2.getDateOperations([-1, -1], RuleTypes.ConditionsV2.DateOperationsUnit.Week),
          text: 'Last Week',
        },
        ...getDefaultPresets([-7, -8, -9, -10, -11, -12, -13]),
        {
          value: RuleHelpers.ConditionsV2.getDateOperations([0, 0], RuleTypes.ConditionsV2.DateOperationsUnit.Month),
          text: 'This Month',
        },
        {
          value: RuleHelpers.ConditionsV2.getDateOperations([-1, -1], RuleTypes.ConditionsV2.DateOperationsUnit.Month),
          text: 'Last Month',
        },
        ...getDefaultPresets([-29, -59, -89]),
      ];
    },
  },
});
