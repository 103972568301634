/**
 * Find if token exist in url
 * @param {string} url
 * @param {string} token
 * @returns {boolean} - true if token found else false
 */
const indexOfToken = (url: string, token: string) => url.indexOf(token) > -1 || url.indexOf(encodeURI(token)) > -1;

/**
 * Find if token exist in url custom regex
 * @param {string} url
 * @param {string} token
 * @returns {boolean} - true if token found else false
 */
const indexOfTokenCustom = (url: string, token: string) =>
  url.indexOf(token) > -1 || url.indexOf(encodeURI(token)) > -1 || url.indexOf(token.replace(/({{|}})/g, '$')) > -1;

const callToActionOptions = [
  { name: '(default)', value: null, selected: true },
  { name: 'Apply Now', value: 'Apply Now' },
  { name: 'Bet Now', value: 'Bet Now' },
  { name: 'Book Now', value: 'Book Now' },
  { name: 'Browse Now', value: 'Browse Now' },
  { name: 'Buy Now', value: 'Buy Now' },
  { name: 'Call Now', value: 'Call Now' },
  { name: 'Compare', value: 'Compare' },
  { name: 'Contact Us', value: 'Contact Us' },
  { name: 'Directions', value: 'Directions' },
  { name: 'Donate Now', value: 'Donate Now' },
  { name: 'Download', value: 'Download' },
  { name: 'Enroll Now', value: 'Enroll Now' },
  { name: 'Explore', value: 'Explore' },
  { name: 'Get App', value: 'Get App' },
  { name: 'Get Coupon', value: 'Get Coupon' },
  { name: 'Get Deals', value: 'Get Deals' },
  { name: 'Get Offer', value: 'Get Offer' },
  { name: 'Get Quote', value: 'Get Quote' },
  { name: 'Get Rates', value: 'Get Rates' },
  { name: 'Get Recipe', value: 'Get Recipe' },
  { name: 'Get Sample', value: 'Get Sample' },
  { name: 'Install', value: 'Install' },
  { name: 'Join Now', value: 'Join Now' },
  { name: 'Launch', value: 'Launch' },
  { name: 'Learn More', value: 'Learn More' },
  { name: 'Listen Now', value: 'Listen Now' },
  { name: 'Order Now', value: 'Order Now' },
  { name: 'Play Game', value: 'Play Game' },
  { name: 'Play Now', value: 'Play Now' },
  { name: 'Read More', value: 'Read More' },
  { name: 'Record Now', value: 'Record Now' },
  { name: 'Register', value: 'Register' },
  { name: 'Remind Me', value: 'Remind Me' },
  { name: 'Save Now', value: 'Save Now' },
  { name: 'Sell Now', value: 'Sell Now' },
  { name: 'Shop Now', value: 'Shop Now' },
  { name: 'Sign Up', value: 'Sign Up' },
  { name: 'Start Now', value: 'Start Now' },
  { name: 'Subscribe', value: 'Subscribe' },
  { name: 'Try Free', value: 'Try Free' },
  { name: 'Try Now', value: 'Try Now' },
  { name: 'Upgrade', value: 'Upgrade' },
  { name: 'Use App', value: 'Use App' },
  { name: 'View Deals', value: 'View Deals' },
  { name: 'Vote Now', value: 'Vote Now' },
  { name: 'Watch More', value: 'Watch More' },
  { name: 'Watch Now', value: 'Watch Now' },
];

const config: Record<string, any> = {
  RevContent: {
    target: {
      tokens: [
        ['{boost_id}', '{utm_boost_id}'],
        ['{widget_id}', '{utm_widget_id}'],
        ['{content_id}', '{utm_content_id}'],
        ['{adv_targets}', '{utm_targeting}'],
      ],
      validation: indexOfToken,
    },
    headline: {
      maxCharacter: 80,
      tokens: ['{CITY}', '{STATE}', '{COUNTRY}'],
    },
    brandName: {
      maxCharacter: 30,
    },
    contentType: false,
    image: {
      size: {
        max: 8000,
      },
      dimension: {
        minWidth: 150,
        minHeight: 150,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
  },
  Taboola: {
    target: {
      tokens: [
        ['{campaign_id}'],
        ['{site}'],
        ['{campaign_item_id}'],
        ['{thumbnail}'],
        ['{title}'],
        ['{platform}'],
        ['{timestamp}'],
      ],
      validation: indexOfToken,
    },
    headline: {
      maxCharacter: 150,
      tokens: [
        '${city:capitalized}$',
        '${city:uppercase}$',
        '${country:capitalized}$',
        '${country:uppercase}$',
        '${region:capitalized}$',
        '${region:uppercase}$',
        '${dma:capitalized}$',
        '${dma:uppercase}$',
        '${platform:capitalized}$',
        '${platform:uppercase}$',
        '${dayofweek:capitalized}$',
        '${dayofweek:uppercase}$',
      ],
      adDescription: {
        maxCharacter: 250,
        required: false,
      },
      ctaButton: {
        required: false,
        options: [
          'None',
          'Download',
          'Install Now',
          'Learn More',
          'Shop Now',
          'Click Here',
          'Sign Up',
          'Play Now',
          'Read More',
          'Get Quote',
          'Get Offer',
          'Try Now',
          'Search now',
          'Book Now',
          'Watch Now',
          'Buy Now',
        ],
        backendOptions: [
          { name: 'None', value: 'NONE' },
          { name: 'Download', value: 'DOWNLOAD' },
          { name: 'Install Now', value: 'INSTALL_NOW' },
          { name: 'Learn More', value: 'LEARN_MORE' },
          { name: 'Shop Now', value: 'SHOP_NOW' },
          { name: 'Click Here', value: 'CLICK_HERE' },
          { name: 'Sign Up', value: 'SIGN_UP' },
          { name: 'Play Now', value: 'PLAY_NOW' },
          { name: 'Read More', value: 'READ_MORE' },
          { name: 'Get Quote', value: 'GET_QUOTE' },
          { name: 'Get Offer', value: 'GET_OFFER' },
          { name: 'Try Now', value: 'TRY_NOW' },
          { name: 'Search now', value: 'SEARCH_NOW' },
          { name: 'Book Now', value: 'BOOK_NOW' },
          { name: 'Watch Now', value: 'WATCH_NOW' },
          { name: 'Buy Now', value: 'BUY_NOW' },
        ],
      },
    },
    image: {
      size: {
        max: 4746,
      },
      dimension: {
        minWidth: 600,
        minHeight: 400,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
  },
  ContentAd: {
    target: {
      tokens: [['[cid]'], ['[did]'], ['[adid]'], ['[click_key]']],
      validation: indexOfToken,
    },
    headline: {
      maxCharacter: 60,
      tokens: ['[Country]', '[State]', '[City]', '[Postal_Code]'],
    },
    description: {
      maxCharacter: 200,
      required: true,
    },
    image: {
      size: {
        max: 3000,
      },
      dimension: {
        minWidth: 150,
        minHeight: 150,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
  },
  Adskeeper: {
    target: {
      tokens: [['{click_id}'], ['{widget_id}'], ['{teaser_id}'], ['{campaign_id}'], ['{geo}'], ['{geo_region}']],
      validation: indexOfToken,
    },
    headline: {
      maxCharacter: 65,
      tokens: ['{City}', '{Country}'],
    },
    category: true,
    cpc: {
      required: true,
      min: 1,
      step: 0.1,
      prepend: '¢ ( US cent )',
    },
    description: {
      maxCharacter: 40,
      required: true,
      verify(campaign: { campaignType: string }) {
        try {
          return campaign.campaignType === 'push';
        } catch (err) {
          return false;
        }
      },
    },
    image: {
      size: {
        max: 3000,
      },
      dimension: {
        minWidth: 492,
        minHeight: 328,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
  },
  Mgid: {
    target: {
      tokens: [['{click_id}'], ['{widget_id}'], ['{teaser_id}'], ['{campaign_id}'], ['{geo}'], ['{geo_region}']],
      validation: indexOfToken,
    },
    headline: {
      maxCharacter: 65,
      tokens: ['{City}', '{Country}'],
    },
    advertText: {
      maxCharacter: {
        push: 40,
        content: 75,
        product: 75,
        search_feed: 75,
      },
      required: { push: true },
    },
    category: true,
    cpc: {
      required: true,
      min: 1,
      step: 0.1,
      prepend: '¢ ( US cent )',
    },
    description: {
      maxCharacter: 40,
      required: true,
      verify(campaign: { campaignType: string }) {
        try {
          return campaign.campaignType === 'push';
        } catch (err) {
          return false;
        }
      },
    },
    image: {
      size: {
        max: 3000,
      },
      dimension: {
        minWidth: 492,
        minHeight: 328,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
  },
  Outbrain: {
    target: {
      tokens: [
        ['{{campaign_id}}'],
        ['{{section_id}}'],
        ['{{section_name}}'],
        ['{{publisher_id}}'],
        ['{{publisher_name}}'],
        ['{{ad_id}}'],
      ],
      validation: indexOfTokenCustom,
      disableEdit: true,
    },
    headline: {
      maxCharacter: 150,
      tokens: [
        '${Country}$',
        '${Region}$',
        '${City}$',
        '${dayofweek}$',
        '${dayofweek+1}$',
        '${dayofweek+2}$',
        '${dayofweek+3}$',
        '${dayofweek+4}$',
        '${dayofweek+5}$',
        '${dayofweek+6}$',
      ],
      adDescription: {
        maxCharacter: 150,
        required: false,
      },
      ctaButton: {
        required: false,
        options: [
          'Apply Now',
          'Book Now',
          'Contact Us',
          'Download',
          'Learn More',
          'Get Quote',
          'Shop Now',
          'Sign Up',
          'Watch More',
          'Listen Now',
          'Subscribe',
          'RSVP',
          'Register Now',
          'Install app',
          'Read more',
          'Play game',
          'Donate Now',
          'Order Now',
          'Join Now',
          'Add to Cart',
          'Download app',
          'Test Now',
          'Configure',
          'Click Here',
        ],
        backendOptions: [
          { name: 'Apply Now', value: 'APPLY_NOW' },
          { name: 'Book Now', value: 'BOOK_NOW' },
          { name: 'Contact Us', value: 'CONTACT_US' },
          { name: 'Download', value: 'DOWNLOAD' },
          { name: 'Learn More', value: 'LEARN_MORE' },
          { name: 'Get Quote', value: 'GET_QUOTE' },
          { name: 'Shop Now', value: 'SHOP_NOW' },
          { name: 'Sign Up', value: 'SIGN_UP' },
          { name: 'Watch More', value: 'WATCH_MORE' },
          { name: 'Listen Now', value: 'LISTEN_NOW' },
          { name: 'Subscribe', value: 'SUBSCRIBE' },
          { name: 'RSVP', value: 'RSVP' },
          { name: 'Register Now', value: 'REGISTER_NOW' },
          { name: 'Install app', value: 'INSTALL_APP' },
          { name: 'Read more', value: 'READ_MORE' },
          { name: 'Play game', value: 'PLAY_GAME' },
          { name: 'Donate Now', value: 'DONATE_NOW' },
          { name: 'Order Now', value: 'ORDER_NOW' },
          { name: 'Join Now', value: 'JOIN_NOW' },
          { name: 'Add to Cart', value: 'ADD_TO_CART' },
          { name: 'Download app', value: 'DOWNLOAD_APP' },
          { name: 'Test Now', value: 'TEST_NOW' },
          { name: 'Configure', value: 'CONFIGURE' },
          { name: 'Click Here', value: 'CLICK_HERE' },
        ],
      },
    },
    cpc: {
      required: false,
      min: 0.01,
      step: 0.01,
      prepend: '$',
    },
    image: {
      size: {
        max: 3000,
      },
      dimension: {
        minWidth: 150,
        minHeight: 150,
        isValid: true,
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
    sectionName: {
      required: false,
    },
  },
  Gemini: {
    target: {
      validation: indexOfTokenCustom,
    },
    headline: {
      maxCharacter: 50,
      tokens: ['{city}', '{state}', '{state_abbr}', '{country}', '{country_abbr}', '{time}'],
    },
    description: {
      maxCharacter: 150,
    },
    image: {
      size: {
        max: 3000,
      },
      dimension: {
        minWidth: 1200,
        minHeight: 627,
        validateRatio(width: number, height: number) {
          const dimension = {
            minWidth: 1200,
            minHeight: 627,
            ratio: 1200 / 627,
            viewMode: 3,
            cropBoxResizable: false,
            scalable: false,
            isValid: false,
          };

          if (width === 1200 && height === 627) {
            dimension.isValid = true;
          }
          return dimension;
        },
      },
      allowedExt: ['jpg', 'jpeg', 'gif', 'png'],
    },
    callToAction: true,
    sponsoredBy: {
      maxCharacter: 35,
    },
    displayUrl: {
      maxCharacter: 35,
    },
    callToActionOptions,
  },
  Defaults: {
    uploader: {
      maxImages: 20,
      maxSize: 5000,
    },
  },
};

export default config;
