<template>
  <div :class="`os-targeting-select-wrapper component-${model.name}`">
    <vue-opti-select-light
      class="optimizer-select  w-100"
      :options="parentOptions"
      unique-key="text"
      label-key="text"
      @change="$_addItem"
      button-block
      single
    >
      <template #BUTTON_PLACEHOLDER>
        {{ labelText }}
      </template>
    </vue-opti-select-light>
    <div v-if="options.length" class="selected-targeting-wrapper">
      <div class="selected-target" v-for="(option, index) in options" :key="index">
        <span class="selected-target__label">{{ option.label }}</span>
        <div v-if="option.options && option.options.length" class="selected-target__select campaign-select-container">
          <v-select
            v-model="selected[option.label]"
            multiple
            placeholder="Target All"
            label="text"
            :options="option.options"
            :close-on-select="false"
            @input="$_emit()"
          >
          </v-select>
        </div>
        <span class="remove-icon" @click="$_removeItem(option.label)"><i class="fa fa-times"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcTargetingCategories',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    labelText: { type: String, required: true },
  },
  data() {
    return {
      selected: {},
      options: [],
      parentOptions: [],
    };
  },
  created() {
    if (this.model.options.length) {
      this.parentOptions = this.model.options;
    } else if (this.model.getOptions && this.parentOptions.length === 0) {
      this.model.getOptions().then((res) => {
        if (res) {
          this.model.options = res;
          this.parentOptions = this.model.options;
        }
      }).catch((err) => {
        console.error(err);
      });
    }

    // Bind Model
    this.$watch('value', (value) => {
      const isNotEditing = value.find((item) => item.selectedIds);
      if (this.mode === 'edit' && !!isNotEditing) {
        this.$_setSelectedOption(value);
      }
    }, { immediate: true });
  },
  methods: {
    $_setSelectedOption(items) {
      this.options = [];
      items.forEach((option) => {
        option?.selectedCategories.forEach((op) => {
          this.options.push({ label: op.text, value: op.value, options: op.children });
          const myChildIds = [];
          const myChilds = op.children.map((el) => option.selectedIds.filter((o) => o.value === el.value));

          myChilds.filter((child) => {
            if (child.length) {
              myChildIds.push(child[0]);
            }
            return false;
          });

          this.selected = { ...this.selected,
            [op.text]: myChildIds,
          };
        });
      });
    },
    $_emit() {
      const payload = [];
      this.options.forEach((option) => {
        if (this.selected[option.label]) {
          payload.push({ parentId: option.value, childIds: this.selected[option.label].map((item) => item.value) });
        } else {
          payload.push({ parentId: option.value });
        }
      });
      this.$emit('input', payload);
    },
    $_addItem(item) {
      const findOption = this.options.find((option) => option.label === item.text);
      if (!findOption) {
        this.$set(this.selected, item.value, []);
        this.options.push({ label: item.text, value: item.value, options: item.children });
        this.$_emit();
      }
    },
    $_removeItem(label) {
      this.options = this.options.filter((option) => option.label !== label);
      this.$delete(this.selected, label);
      this.$_emit();
    },
  },
};
</script>

<style lang="scss">
.os-targeting-select-wrapper {
  margin-top: 0;

  .selected-targeting-wrapper {
    border: .1rem solid #e5e5e5;
    border-radius: .6rem;
    padding: 1rem;

    .selected-target {
      display: flex;
      align-items: center;
      margin-bottom: .7rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__label {
        font-size: 1.4rem;
        color: $text-primary;
        font-weight: 500;
        text-align: center;
        width: 10rem;
      }

      &__select {
        flex: 1;
        margin-right: .5rem;
      }

      .remove-icon {
        background: #7fa1f8;
        color: $color-white;
        padding: .1rem .3rem .2rem .4rem;
        line-height: .55;
        border-radius: 50%;
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }

  }
}
</style>
