<template>
  <div class="description-wrapper">
    <b-form-group
      class="optimizer-form-group form-group-info-link"
    >
      <template slot="label">
        <div class="d-flex justify-content-between align-items-center w-100">
          <span>
            Description: <span v-if="required" class="text-danger"> *</span>
          </span>
          <template v-if="maxCharacter > 0">
            <small v-if="maxCharacter > 0" class="character-left small-info-text">Characters left: <b>{{ maxCharacter - input.length }}</b></small>
          </template>
        </div>
      </template>
      <b-input-group size="sm">
        <b-form-textarea
          class="optimizer-form-textarea"
          :required="required"
          v-model="input"
          placeholder="Enter description"
          :state="valid"
          :id="id"
          :maxlength="maxCharacter"
          :name="id"
          :rows="3"
        >
        </b-form-textarea>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import uid from 'uid';

export default {
  name: 'Description',
  props: {
    maxCharacter: { type: Number, default: 0 },
    required: { type: Boolean, default: false },
    labelCols: { type: Number, default: 3 },
    breakpoint: { type: String, default: 'md' },
    labelClass: { type: String, default: 'text-md-right' },
    size: { type: String, default: 'sm' },
    value: { type: String, default: '' },
    valid: { type: Boolean, default: null },
  },
  data() {
    return {
      input: '',
      id: `descriptionInput-${uid(10)}`,
    };
  },
  watch: {
    input() {
      this.$emit('input', this.input);
    },
  },
  created() {
    this.input = this.value;
  },
};
</script>
