
import CreateTag from '@sh/components/MediaManager/TagsContainer/CreateTag/CreateTag.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import TagCell from '@sh/components/Utils/Tags/TagCell.ts.vue';
import { MediaManager } from '@sh/types';
import { isUndefined } from 'lodash';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    TagCell,
    CreateTag,
    OptimizerIcon,
  },
  props: {
    tags: Array as PropType<string[]>,
    isDisabled: { type: Boolean, default: false },
    showDeleteOnBottom: { type: Boolean, default: true },
    autocompleteItems: { type: Array as PropType<MediaManager.Tag[]>, default: () => [] },
    isReadonly: { type: Boolean, default: false },
    hiddenTags: { type: Number, default: 0 },
    isDeleteOnly: { type: Boolean, default: false },
  },
  computed: {
    actionType() {
      return MediaManager.ActionType;
    },
  },
  methods: {
    onRemoveTagClick(index?: number) {
      if (isUndefined(index)) {
        this.tags?.splice(0, this.tags.length);
      } else {
        this.tags?.splice(index, 1);
      }
      this.onTagsChange();
    },
    onTagsChange() {
      this.$emit('onTagsChange');
    },
  },
});
