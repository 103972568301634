<template>
  <div :class="`os-targeting-select-wrapper component-${model.name}`">
    <vue-opti-select-light
      :class="[{ 'has-error': !isValid }, 'optimizer-select  w-100']"
      :options="$options.osOptions"
      unique-key="text"
      label-key="text"
      @change="$_addOS"
      @hidden="validate"
      button-block
      single
    >
      <template #BUTTON_PLACEHOLDER>
        + Add OS
      </template>
    </vue-opti-select-light>
    <div v-if="options.length" class="selected-targeting-wrapper">
      <div class="selected-target" v-for="(os, i) in options" :key="i">
        <span class="selected-target__label">{{ os.label }}</span>
        <div class="selected-target__select campaign-select-container">
          <v-select
            v-model="selected[os.label]"
            multiple
            placeholder="Target All"
            label="text"
            :options="os.options"
            :close-on-select="false"
            @input="$_emit()"
          >
          </v-select>
        </div>
        <span class="remove-icon" @click="$_removeOS(os.label)"><i class="fa fa-times"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import component from '../mixins/component';
// import { VueOptiSelect } from 'vue-opti-select';

export default {
  name: 'OsTargetingSelect',
  // components: { VueOptiSelect },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: {},
      options: [],
      isValid: true,
    };
  },
  created() {
    // Set Options
    this.$options.osOptions = this.model.options;
    this.$options.osOptionsMap = {};
    this.$options.osOptions.forEach((option) => {
      const valueMap = {};
      option.value.forEach((item) => { valueMap[item.value] = item; });
      this.$options.osOptionsMap[option.text] = { value: option.value, valueMap };
    });
    // Bind Model
    this.$watch('value', (value) => {
      this.selected = {};
      this.options = [];
      value.forEach(({ os_family, sub_categories }) => {
        this.options.push({ label: os_family, options: this.$options.osOptionsMap[os_family].value });
        if (!this.selected[os_family]) this.selected[os_family] = [];
        // eslint-disable-next-line camelcase
        sub_categories.forEach((sub_os) => {
          this.selected[os_family].push(this.$options.osOptionsMap[os_family].valueMap[sub_os]);
        });
      });
    }, { immediate: true });
    this.$watch('selected', () => {
      this.validate();
    });
  },
  methods: {
    validate() {
      this.isValid = this.options.length > 0;
      return this.isValid;
    },
    $_emit() {
      const payload = [];
      this.options.forEach((option) => {
        if (this.selected[option.label]) {
          payload.push({ os_family: option.label, sub_categories: this.selected[option.label].map((item) => item.value) });
        }
      });
      this.$emit('input', payload);
    },
    $_addOS(item) {
      const findOS = this.options.find((os) => os.label === item.text);
      if (!findOS) {
        this.$set(this.selected, item.text, []);
        this.options.push({ label: item.text, options: item.value });
        this.$_emit();
      }
    },
    $_removeOS(label) {
      this.options = this.options.filter((os) => os.label !== label);
      this.$delete(this.selected, label);
      this.$_emit();
    },
  },
};
</script>

<style lang="scss">
.os-targeting-select-wrapper {
  margin-top: 1rem;

  .selected-targeting-wrapper {
    border: .1rem solid #e5e5e5;
    border-radius: .6rem;
    padding: 1rem;
    margin-top: 1rem;

    .selected-target {
      display: flex;
      align-items: center;
      margin-bottom: .7rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__label {
        font-size: 1.4rem;
        color: $text-primary;
        font-weight: 500;
        text-align: center;
        width: 10rem;
      }

      &__select {
        flex: 1;
        margin-right: .5rem;
      }

      .remove-icon {
        background: #7fa1f8;
        color: $color-white;
        padding: .1rem .3rem .2rem .4rem;
        line-height: .55;
        border-radius: 50%;
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }

  }
  // width: 100%;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // padding: 8px;
}
</style>
