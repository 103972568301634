export default (F) => ({
  traffic_source_exchange_id: F.create({
    header: { content: 'Exchange ID' },
    item: {
      key: 'traffic_source_exchange_id',
      sortable: true,
      total: null,
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      total: {
        content: (totals) => {
          if (typeof totals.traffic_source_item_id === 'string' && totals.traffic_source_item_id.includes('Total:')) {
            return '';
          }
          return `Total: ${totals.totalElements}`;
        },
      },
    },
  }),
});
