import { api, apiStore } from '@sh/services/api';

export default {
  trafficSources: {
    types() {
      return apiStore.trafficSources.types();
    },
    account(id) {
      return apiStore.trafficSources.account(id);
    },
    create(payload) {
      return api.trafficSources.create(payload);
    },
    update(id, payload) {
      return api.trafficSources.update(id, payload);
    },
    updateBulkCredentials(payload) {
      return api.trafficSources.updateBulkCredentials(payload);
    },
    accounts() {
      return apiStore.trafficSources.accounts();
    },
    delete(id) {
      return api.trafficSources.delete(id);
    },
    updateStatus(id, status) {
      return api.trafficSources.updateStatus(id, status);
    },
    unlinkTracker(sourceId, trackerId, sourceIdOnTracker) {
      return api.trafficSources.unlinkTracker(sourceId, trackerId, sourceIdOnTracker);
    },
    accountsName(params) {
      return api.trafficSources.accountsName(params);
    },
    createBulkAccounts(params) {
      return api.trafficSources.createBulkAccounts(params);
    },
  },
};
