import { AbortController } from '@sh/helpers';
import { apiStore } from '@sh/services/api';
import session from '@sh/services/session';
import {
  campaignCreatorRequest,
  request,
  requestDashboard,
  requestUploader,
  requestV2,
  requestFacebook,
} from '@sh/services/axios';

export default {
  campaigns: {
    async overall(_params) {
      const params = { ..._params };

      if (params.trafficSourceAccountIds) {
        params.trafficSourceAccountIds = JSON.stringify(params.trafficSourceAccountIds);
      }
      if (params.trackerAccountIds) {
        params.trackerAccountIds = JSON.stringify(params.trackerAccountIds);
      }
      return request.get('campaigns/overall', { params });
    },
    async performanceChart(id, startDate, endDate, groupBy) {
      const params = { groupBy, startDate, endDate };
      return request.get(`campaigns/${id}/campaign-performance-chart`, { params });
    },
    async getPerformanceChart(performanceParams) {
      const params = {
        platform: process.env.VUE_APP_PLATFORM,
        ...performanceParams,
      };
      return request.post('chart', params);
    },
    async getPerformanceEvents(performanceParams) {
      const params = {
        platform: process.env.VUE_APP_PLATFORM,
        ...performanceParams,
      };
      return requestDashboard.post('performance/events', params);
    },
    async getFacebookPerformanceBreakedown(
      startDate,
      endDate,
      itemId,
      breakdowns,
      trafficSourceAccountId,
    ) {
      const params = { startDate, endDate, itemId, breakdowns, trafficSourceAccountId };
      return request.post('/performance/getFacebookPerformanceBreakedown', params);
    },
    async changeFacebookBudgetStrategy(id, payload) {
      return request.post(`campaigns/${id}/change-facebook-budget-type`, payload);
    },
    async trackerView(id, startDate, endDate) {
      return request.get(`campaigns/${id}/tracker-view`, {
        params: { startDate, endDate },
      });
    },
    async changeBid(id, bid) {
      return request.post(`campaigns/${id}/change-bid`, { bid });
    },
    async changeTargetCpa(id, targetCpa) {
      return request.post(`campaigns/${id}/change-target-cpa`, { target_cpa: targetCpa });
    },
    async changeBidCap(id, bidCap) {
      return request.post(`campaigns/${id}/change-bid-cap`, { bid_cap: bidCap, override_settings: true });
    },
    async settingsUpdate(id, payload) {
      return request.post(`campaigns/${id}/settings/update`, payload);
    },
    async changeBidCad(id, payload) {
      return request.post(`campaigns/${id}/change-bid-cad`, payload);
    },
    async changeBidGemini(id, payload) {
      return request.post(`campaigns/${id}/change-bid-gemini`, payload);
    },
    async changeBidAdjGoogleAds(id, payload) {
      return request.post(`campaigns/${id}/change-bids-google`, payload);
    },
    async changeBudget(id, budget, budgetType) {
      const payload = { budget };
      if (budgetType?.length > 0) {
        payload.budget_type = budgetType;
      }
      return request.post(`campaigns/${id}/change-budget`, payload);
    },
    async changeDailyBudget(id, dailyBudget) {
      return request.post(`campaigns/${id}/change-daily-budget`, { daily_budget: dailyBudget });
    },
    async changeStatus(id, enabled) {
      return request.post(`campaigns/${id}/change-status`, { enabled });
    },
    async changeManage(manage, campaignIds) {
      return request.post('campaigns/manage', { manage, campaignIds });
    },
    async changeArchive(archive, campaignIds) {
      return request.post('campaigns/archive', { archive, campaignIds });
    },
    async changeCampaignTotalBudget(campaignId, totalBudget, totalBudgetType) {
      return request.post('campaignTotalBudget', { campaignId, totalBudget, totalBudgetType });
    },
    async cloneCampaign(id, deepClone = true, campaignName = '', renamePrefix, renameSuffix, count = 1, budget = null, budgetOnAdset) {
      return request.post(`campaigns/${id}/clone-campaign`, { deepClone, campaignName, renamePrefix, renameSuffix, count, budget, budgetOnAdset });
    },
  },
  widgets: {
    async action(campaignId, publisherIds, action, listId) {
      const payload = { campaignId, publisherIds, action };
      if (listId) {
        payload.listId = listId;
      }
      return request.post('publisher-actions', payload);
    },
    async basicStats(startDate, endDate, campaignId) {
      return request.get('basic-stats', { params: { startDate, endDate, campaignId } });
    },
    async performance(id, params) {
      params.level = 'Widget';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async getAccountMinCPC(trafficSourceAccountId) {
      return request.get('campaigns/getTaboolaCPCList', { params: {
        trafficSourceAccountId,
      },
      });
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/widgets/totals`, { params });
    },
    async changeStatus(id, action, widgetIds) {
      const payload = { action, widgetIds };
      return request.post(`campaigns/${id}/widgets/change-status`, payload);
    },
    async changeStatusKeyword(id, action, widgetIds) {
      const payload = { action, widgetIds };
      return request.post(`campaigns/${id}/widgets/change-status-keyword`, payload);
    },
    async changeBid(id, bid, widgetIds) {
      return request.post(`campaigns/${id}/widgets/change-bid`, { bid, widgetIds });
    },
    async changeBidCad(id, payload) {
      return request.post(`campaigns/${id}/widgets/change-bid-cad`, payload);
    },
    async changeBidBulk(id, payload) {
      return request.post(`campaigns/${id}/widgets/change-bid-bulk`, payload);
    },
    async changeStrategy(id, payload) {
      return request.post(`campaigns/${id}/widgets/change-strategy`, payload);
    },
    async changeWidgetBudget(campaignId, widgetIds, budget, type) {
      return request.post(`campaigns/${campaignId}/widgets/change-budget`, { widgetIds, budget, budgetType: type });
    },
    async changeWidgetDailyBudget(campaignId, widgetIds, dailyBudget, type) {
      return request.post(`campaigns/${campaignId}/widgets/change-daily-budget`, { widgetIds, dailyBudget, budgetType: type });
    },
  },
  contents: {
    async performance(id, params) {
      params.level = 'Content';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/contents/totals`, { params });
    },
    async changeStatus(id, enabled, contentIds) {
      return request.post(`campaigns/${id}/contents/change-status`, { enabled, contentIds });
    },
    async changeBid(id, bid, contentIds) {
      return request.post(`campaigns/${id}/contents/change-bid`, { bid, contentIds });
    },
    async clone(id, contentIds, count = 1, parentAdGroupId = '', renameSuffix, renamePrefix) {
      return request.post(`campaigns/${id}/contents/clone-content`, { contentIds, count, parentAdGroupId, renameSuffix, renamePrefix });
    },
    async changeArchive(campaignId, archive, contentIds) {
      return request.post(`campaigns/${campaignId}/contents/archive`, { archive, contentIds });
    },
    async getPreview(params) {
      return request.post('traffic-sources/ad-preview', params);
    },
  },
  targets: {
    async performance(id, params) {
      params.level = 'Target';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/targets/totals`, { params });
    },
    async changeStatus(id, enabled, targetIds) {
      return request.post(`campaigns/${id}/targets/change-status`, { enabled, targetIds });
    },
    async changeBid(id, bid, targetIds) {
      return request.post(`campaigns/${id}/targets/change-bid`, { bid, targetIds });
    },
  },
  sites: {
    async performance(id, params) {
      params.level = 'Site';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/sites/totals`, { params });
    },
    async changeStatus(id, enabled, siteIds) {
      return request.post(`campaigns/${id}/sites/change-status`, { enabled, siteIds });
    },
    async changeBid(id, bid, siteIds) {
      return request.post(`campaigns/${id}/sites/change-bid`, { bid, siteIds });
    },
  },
  exchanges: {
    async performance(id, params) {
      params.level = 'Exchange';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/exchanges/totals`, { params });
    },
    async changeStatus(id, enabled, exchangeIds) {
      return request.post(`campaigns/${id}/exchanges/change-status`, { enabled, exchangeIds });
    },
    async changeBid(id, bid, exchangeIds) {
      return request.post(`campaigns/${id}/exchanges/change-bid`, { bid, exchangeIds });
    },
  },
  adgroups: {
    async performance(id, params) {
      params.level = 'AdGroup';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async adgroups(id) {
      return request.get(`campaigns/${id}/adgroups/all`);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/adgroups/totals`, { params });
    },
    async changeStatus(id, enabled, adgroupIds) {
      return request.post(`campaigns/${id}/adgroup/change-status`, { enabled, adgroupIds });
    },
    async changeNativeBid(id, nativeBid, searchBid, adgroupIds) {
      return request.post(`campaigns/${id}/adgroup/change-native-bid`, {
        nativeBid,
        searchBid,
        adgroupIds,
      });
    },
    async changeRoasBid(id, nativeBid, roasBid, adgroupIds) {
      return request.post(`campaigns/${id}/adgroup/change-roas-bid`, {
        nativeBid,
        roasBid,
        adgroupIds,
      });
    },
    async changeTargetCpm(id, nativeBid, targetCpm, adgroupIds) {
      return request.post(`campaigns/${id}/adgroup/change-target-cpm`, {
        nativeBid,
        targetCpm,
        adgroupIds,
      });
    },
    async changeSearchBid(id, nativeBid, searchBid, adgroupIds) {
      return request.post(`campaigns/${id}/adgroup/change-search-bid`, {
        nativeBid,
        searchBid,
        adgroupIds,
      });
    },
    async changeAdGroupDailyBudget(campaignId, adgroupIds, dailyBudget, type) {
      return request.post(`campaigns/${campaignId}/adgroup/change-daily-budget`, {
        adgroupIds,
        dailyBudget,
        budgetType: type,
      });
    },
    async clone(campaignId, adgroupIds, count = 1, parentCampaignId = '', renameSuffix, renamePrefix) {
      return request.post(`campaigns/${campaignId}/adgroup/clone-adgroup`, { adgroupIds, count, parentCampaignId, renameSuffix, renamePrefix });
    },
    async changeAdGroupBudget(campaignId, adgroupIds, budget, type) {
      return request.post(`campaigns/${campaignId}/adgroup/change-budget`, {
        adgroupIds,
        budget,
        budgetType: type,
      });
    },
  },
  domains: {
    async performance(id, params) {
      params.level = 'Domain';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/domains/totals`, { params });
    },
    async changeStatus(id, action, domainIds) {
      const payload = { action, domainIds };
      return request.post(`campaigns/${id}/domains/change-status`, payload);
    },
  },
  spots: {
    async performance(id, params) {
      params.level = 'Spot';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/spots/totals`, { params });
    },
    async changeStatus(id, action, spotIds) {
      const payload = { action, spotIds };
      return request.post(`campaigns/${id}/spots/change-status`, payload);
    },
    async changeBid(id, bid, spotIds) {
      return request.post(`campaigns/${id}/spots/change-bid`, { bid, spotIds });
    },
  },
  sections: {
    async performance(id, params) {
      params.level = 'Section';
      params.id = id;
      return requestDashboard.post('performance/entity-performance', params);
    },
    async performanceTotals(id, params) {
      return request.get(`campaigns/${id}/sections/totals`, { params });
    },
    async changeStatus(id, action, sectionIds) {
      return request.post(`campaigns/${id}/sections/change-status`, { action, sectionIds });
    },
    async changeBid(id, bid, sectionIds) {
      return request.post(`campaigns/${id}/sections/change-bid`, { bid, sectionIds });
    },
    async changeBidBulk(id, sectionIds) {
      return request.post(`campaigns/${id}/sections/change-bid-bulk`, { sectionIds });
    },
  },
  trackers: {
    async sources(id) {
      return request.get(`/trackers/${id}/getTrafficSources`);
    },
    async updateWeight(id, data, weight) {
      return request.put(`trackers/${id}/update-weight`, { data, weight });
    },
    async getAccounts(id) {
      return request.get(`trackers/${id}/getAccounts`);
    },
    async getGoogleAnalytics4Accounts(id) {
      return request.get(`trackers/${id}/ga4/accounts`);
    },
    async getGoogleAnalytics4PropertyMetrics(id, propertyId) {
      return request.get(`trackers/${id}/ga4/properties/${propertyId}`);
    },
    async getCsvTemplate(tokens) {
      return request.post('trackers/getCsvTemplate', { typeName: 'CSVSource', tokens });
    },
    async validateHeaders(credentials, tokenVariables) {
      return request.post('trackers/validateHeaders', { typeName: 'CSVSource', credentials, tokenVariables });
    },
  },
  trafficSources: {
    async tokens(typeName) {
      return request.get('/traffic-sources/getTokens', {
        params: { typeName },
      });
    },
    async accountsName(params) {
      return request.post('traffic-sources/accounts-name', params);
    },
    async tokenMapping() {
      return request.get('/traffic-sources/tokenMapping');
    },
    async trackingUrlTokens() {
      return request.get('/traffic-sources/trackingUrl');
    },
  },
  manualConnection: {
    async getAllCampaigns(trafficSourceId, trackerId) {
      return request.post('/manual-connection/get-campaigns', {
        trafficSourceId,
        trackerId,
      });
    },

    async getGaCampaigns(trafficSourceId, trackerId, viewId) {
      return request.post('/manual-connection/get-gaCampaigns', {
        trafficSourceId,
        trackerId,
        viewId,
      });
    },

    async saveLinkedCampaigns(campaigns, accounts, viewId) {
      return request.post('/manual-connection/save-campaigns', {
        campaigns,
        accounts,
        viewId,
      });
    },

    async deleteCampaign(campaignId, viewId) {
      return request.put(`/manual-connection/delete-campaign/${campaignId}`, { viewId });
    },
  },
  manualStatsUpdate: {
    async submitForm(trafficSourceAccountIds, startDate, endDate) {
      return request.post('manual-stats', {
        trafficSourceAccountIds,
        startDate,
        endDate,
      });
    },
    async uploadCsv(payload) {
      console.log('request uploader:', requestUploader);
      return requestUploader.post('upload-csv', payload);
    },
  },
  lists: {
    async all() {
      return request.get('lists');
    },
    async get(id) {
      return request.get(`lists/${id}`);
    },
    async create(params) {
      const result = await request.post('lists', params);
      apiStore.lists.all.clearCache();
      apiStore.lists.all();
      return result;
    },
    async update(id, params) {
      const result = await request.put(`lists/${id}`, params);
      apiStore.lists.all.clearCache();
      apiStore.lists.all();
      return result;
    },
    async delete(id) {
      return request.delete(`lists/${id}`);
    },
    async updateStatus(id, enabled) {
      return request.put(`lists/${id}/status`, { enabled });
    },
  },
  totals: {
    async stats(type, params) {
      return request.get(`${type}/totals`, { params });
    },
  },
  uploader: {
    async mgidTeasers() {
      return request.get('uploader/mgid-teasers');
    },
    async imageProxy(images) {
      return request.post('uploader/image-proxy', { images }, { timeout: 300000 });
    },
    async existingContents(params) {
      return request.get('uploader/existing-contents', { params });
    },
  },
  campaignCreator: {
    async getCampaignData(trafficSourceType, trafficSourceAccountId, trafficSourceCampaignId) {
      return request.get('/campaign-creator/clone', {
        params: {
          trafficSourceType,
          trafficSourceAccountId,
          trafficSourceCampaignId,
        },
      });
    },
    async saveTemplate(payload) {
      return campaignCreatorRequest.post('campaign/facebookTemplate/save', payload);
    },
    async updateTemplate(payload) {
      return campaignCreatorRequest.post('campaign/facebookTemplate/update', payload);
    },
    async createFeatureRequest(payload) {
      return campaignCreatorRequest.post('feedback/create', payload);
    },
    async getTemplate() {
      return campaignCreatorRequest.get('campaign/facebookTemplate/list');
    },
    async deleteTemplate(id) {
      return campaignCreatorRequest.delete(`campaign/facebookTemplate/${id}`);
    },
    async incrementUsage(id) {
      return campaignCreatorRequest.put(`campaign/facebookTemplate/usage/${id}`);
    },
    async getCampaignDataV2(trafficSourceType, trafficSourceAccountId, trafficSourceCampaignId) {
      if (['Taboola', 'RevContent', 'Outbrain'].indexOf(trafficSourceType) > -1) {
        return request.get('/campaign-creator-v2/clone', {
          params: {
            trafficSourceType,
            trafficSourceAccountId,
            trafficSourceCampaignId,
          },
        });
      }
      return request.get('/campaign-creator/clone', {
        params: {
          trafficSourceType,
          trafficSourceAccountId,
          trafficSourceCampaignId,
        },
      });
    },
    async getTaboolaPublishers(trafficSourceAccountId) {
      return request.get('/taboola/publishers', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getTaboolaRegions(trafficSourceAccountId, countryCode) {
      return request.get('/taboola/regions', {
        params: {
          trafficSourceAccountId,
          countryCode,
        },
      });
    },
    async getTaboolaConversionRules(trafficSourceAccountId) {
      return request.get('/taboola/conversions', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getTaboolaPostalCodes(trafficSourceAccountId, countryCode) {
      return request.get('/taboola/postal-codes', {
        params: {
          trafficSourceAccountId,
          countryCode,
        },
      });
    },
    async getTaboolaDMA(trafficSourceAccountId) {
      return request.get('/taboola/DMA', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async searchOutbrainLocations(trafficSourceAccountId, term) {
      return request.get('/outbrain/locations', {
        params: {
          trafficSourceAccountId,
          term,
        },
      });
    },
    async getOutbrainInterests(trafficSourceAccountId) {
      return request.get('/outbrain/interests/all', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getOutbrainIabCategories(trafficSourceAccountId) {
      return request.get('/outbrain/iabcategories/all', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getOutbrainConversions(trafficSourceAccountId) {
      return request.get('/outbrain/conversions', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async searchMgidCities(trafficSourceAccountId, countries) {
      return request.get('/mgid/cities', {
        params: {
          trafficSourceAccountId,
          countries: JSON.stringify(countries),
        },
      });
    },
    async searchGeminiLocations(trafficSourceAccountId, query) {
      return request.get(`/gemini/locations/${query}`, {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getGeminiLocations(trafficSourceAccountId, ids) {
      return request.get('/gemini/getLocations', {
        params: {
          trafficSourceAccountId,
          ids: JSON.stringify(ids),
        },
      });
    },
    async saveCampaign(payload) {
      return campaignCreatorRequest.post('campaign/create', payload);
    },
    async createContents(campaignId, payload) {
      return campaignCreatorRequest.post(`campaign/${campaignId}/create-contents`, payload);
    },

    async queueIndex(page, limit, sortType, sortField, search, status, action) {
      const filters = { sortType, sortField, search, status, action };
      const params = {};
      if (page) {
        params.page = page;
        params.limit = limit;
      }
      if (filters) {
        params.filters = filters;
      }
      return campaignCreatorRequest.get('uploader-action/list', { params });
    },
    async queueItem(_id) {
      return campaignCreatorRequest.get(`uploader-action/${_id}`);
    },
    async createGeminiCampaign(accountId, payload) {
      return request.post('/gemini/createCampaign', { payload, trafficSourceAccountId: accountId });
    },
    async createGeminiVariations(accountId, payload) {
      return request.post('/gemini/createVariations', {
        payload,
        trafficSourceAccountId: accountId,
      });
    },
    async getGeminiAdvertisers(trafficSourceAccountId) {
      return request.get(`/gemini/${trafficSourceAccountId}/advertisers`);
    },
    async createGeminiContents(campaignId, groupId, trafficSourceAccountId, payload) {
      return campaignCreatorRequest.post(
        `campaign/${campaignId}/${groupId}/create-gemini-contents`,
        { ...payload, trafficSourceAccountId },
      );
    },
    async getRevcontentConversions(trafficSourceAccountId) {
      return request.get(`/revcontent/${trafficSourceAccountId}/conversions`);
    },
    async getRevcontentBrowsers(trafficSourceAccountId) {
      return request.get('/revcontent/browsers', {
        params: {
          trafficSourceAccountId,
        },
      });
    },
    async getRevcontentRegions(trafficSourceAccountId, countryCode) {
      return request.get('/revcontent/regions', {
        params: {
          trafficSourceAccountId,
          countryCode,
        },
      });
    },
    async uploadCsv(tsName, payload) {
      return campaignCreatorRequest.post(`upload-csv/trafficsource-upload?tsName=${tsName}`, payload);
    },
    async startJob(payload) {
      return campaignCreatorRequest.post('task/create', payload);
    },
    async retryJob(payload) {
      return campaignCreatorRequest.post('task/retry', payload);
    },
  },
  settings: {
    async getSettings() {
      return request.get('settings');
    },
    async setSettings(payload) {
      return request.post('settings', payload);
    },
  },
  manualUpdateCsv: {
    async updateCampaigns(payload) {
      return request.post('csv-upload/campaign', payload);
    },
    async updateWidgets(id, payload) {
      return request.post(`csv-upload/${id}/widget`, payload);
    },
    async updateContents(id, payload) {
      return request.post(`csv-upload/${id}/content`, payload);
    },
    async updateTargets(id, payload) {
      return request.post(`csv-upload/${id}/target`, payload);
    },
    async updateSections(id, payload) {
      return request.post(`csv-upload/${id}/section`, payload);
    },
  },
  entityFilter: {
    getByStatus(params) {
      return request.post('campaigns/getAllItems', params);
    },
    getStatusStatsGrouped(params) {
      return request.post('campaigns/getStatusStatsGrouped', params);
    },
    getByIds(params) {
      return request.post('campaigns/getEntitiesByIds', params);
    },
  },
  mediaManager: {
    getSettings(params, signalId) {
      AbortController.abort(signalId);
      return requestV2.post('mediamanager/settings', params, { signal: AbortController.create(signalId) });
    },
    getData(mode, params, signalId) {
      AbortController.abort(signalId);
      return requestV2.post(`mediamanager/${mode}`, params, { signal: AbortController.create(signalId) });
    },
    editData(mode, params) {
      return requestV2.post(`mediamanager/${mode}/update`, params);
    },
    createNewData(mode, params) {
      return requestV2.post(`mediamanager/${mode}/create`, params);
    },
    deleteData(mode, params) {
      return requestV2.post(`mediamanager/${mode}/delete`, params);
    },
    getCampaignsList(mode, id, params, signalId) {
      AbortController.abort(signalId);
      return requestV2.post(`mediamanager/${mode}/${id}`, params, { signal: AbortController.create(signalId) });
    },
    cloneContent(mode, params) {
      return requestV2.post(`mediamanager/${mode}/clone`, params);
    },
    editTags(mode, params) {
      return requestV2.post(`mediamanager/${mode}/update-tag`, params);
    },
    removeTags(mode, params) {
      return requestV2.post(`mediamanager/${mode}/remove-tag`, params);
    },
  },
  fields: {
    async getGA4Fields() {
      return request.get('configuration/ga4');
    },
    async createGA4Fields(params) {
      return request.post('configuration/ga4', params);
    },
    async updateGA4Fields(params) {
      return request.put('configuration/ga4', params);
    },
  },
  reporting: {
    async getNativeReport(payload) {
      if (session.isSearchFeed) {
        return requestDashboard.post('/transparency-program', payload);
      }
      return requestDashboard.post('/reporting', payload);
    },
    async getNativeSavedReports() {
      return requestDashboard.get('/reporting/getReporting');
    },
    async getNativeReportOptions() {
      if (session.isSearchFeed) {
        return requestDashboard.get('/transparency-program/report-options/tabs');
      }
      return requestDashboard.get('/reporting/report-options');
    },
    async saveOfflineReport(payload) {
      return requestDashboard.post('/reporting/backgroundReporting', payload);
    },
    async getUserComplianceValues(selectedUserId, email) {
      return requestDashboard.get('/transparency-program/report-options/compliance', {
        params: {
          selectedUserId,
          email,
        },
      });
    },
    async switchComparisonField(payload) {
      return requestDashboard.post('/api/table/comparable', payload);
    },
  },
  presets: {
    async getPresets() {
      return requestDashboard.get('configuration/user-presets');
    },
    async createPreset(payload) {
      const request = await requestDashboard.post('configuration/user-preset', payload);
      return request;
    },
    async editPresetName(id, newName) {
      const request = await requestDashboard.put(`/configuration/user-preset?id=${id}&name=${newName}`);
      apiStore.presets.getPresets.clearCache();
      apiStore.presets.getPresets();
      return request;
    },
    async addSubUsersToPreset(preset, subuser) {
      const payload = { preset, subuser };
      const request = await requestDashboard.put('/configuration/add-preset-to-subuser', payload);
      return request;
    },
    async removeSubUsersFromPreset(preset) {
      const payload = { preset };
      const request = await requestDashboard.put('/configuration/remove-preset-from-subuser', payload);
      return request;
    },
    async deletePreset(id) {
      const request = await requestDashboard.delete(`/configuration/user-preset?id=${id}`);
      apiStore.presets.getPresets.clearCache();
      apiStore.presets.getPresets();
      return request;
    },
  },
  facebook: {
    getPixelList(apiToken, adAccountId) {
      return requestFacebook.get(`${adAccountId}/adspixels`, {
        params: {
          fields: 'id,name',
          access_token: apiToken,
        },
      });
    },
    rawGet(endpoint, params) {
      return requestFacebook.get(endpoint, { params });
    },
    getCustomAudienceList(
      apiToken,
      adAccountId,
    ) {
      return requestFacebook.get(`${adAccountId}/customaudiences`, {
        params: {
          fields: 'id,name',
          access_token: apiToken,
        },
      });
    },
    getTargetLocation(
      apiToken,
      adAccountId,
      query,
      location_types,
    ) {
      return requestFacebook.get('/search', {
        params: {
          type: 'adgeolocation',
          q: query,
          access_token: apiToken,
          location_types,
        },
      });
    },
    getFacebookPages(apiToken) {
      return requestFacebook.get('/me/accounts', {
        params: {
          access_token: apiToken,
          fields: 'id,name,access_token,photos{webp_images}',
        },
      });
    },
    getInstagramAccounts(
      pageAccessToken,
      pageId,
    ) {
      return requestFacebook.get(`/${pageId}/page_backed_instagram_accounts`, {
        params: {
          fields: 'id,username,profile_pic',
          access_token: pageAccessToken,
        },
      });
    },
    async createCampaign(campaign, accountId, apiToken) {
      return requestFacebook.post(`/${accountId}/campaigns`, undefined, {
        params: {
          ...campaign,
          access_token: apiToken,
        },
      });
    },
    async createAdSet(adSet, accountId, apiToken) {
      return requestFacebook.post(`/${accountId}/adsets`, undefined, {
        params: {
          ...adSet,
          access_token: apiToken,
        },
      });
    },
    async createCreative(creative, accountId, apiToken) {
      return requestFacebook.post(`/${accountId}/adcreatives`, undefined, {
        params: {
          ...creative,
          access_token: apiToken,
        },
      });
    },
    async getCustomConversionList(apiToken, adAccountId) {
      return requestFacebook.get(`${adAccountId}/customconversions`, {
        params: {
          fields: 'name,id,custom_event_type,pixel',
          access_token: apiToken,
        },
        config: {},
      });
    },
  },
};
