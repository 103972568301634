// Basic Stats Chart Config
export const basicStatsChart = {
  chart: {
    height: 70,
    sparkline: {
      enabled: true,
    },
    type: 'area',
  },
  colors: [
    '#20a8d8',
  ],
  stroke: {
    // curve: 'straight',
    width: 2.5,
    colors: ['rgb(49, 198, 244)'],
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.4,
      opacityTo: 0.23,
      stops: [
        0,
        0,
        0,
      ],
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '80%',
    },
  },
  series: [],
  xaxis: {
    categories: [],
    // type: 'datetime',
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      show: false,
    },
    marker: {
      show: false,
    },
  },
};

// Performance Chart Config
export const perforamanceStatsChart = {
  chart: {
    height: 400,
    type: 'area',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  grid: {
    show: true,
    borderColor: 'var(--color-light)',
    padding: {
      top: 5,
      right: 25,
      bottom: 0,
      left: 25,
    },
  },
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: 'right',
    markers: {
      width: 15,
      height: 15,
      strokeWidth: 1,
      strokeColor: 'transparent',
      radius: 3,
      offsetX: 0,
      offsetY: 0,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#FC5759', '#1dd1a1', '#20a8d8', '#6b6bfc', '#f8b94c'],
  stroke: {
    curve: 'smooth',
    width: 2,
    colors: ['#FC5759', '#1dd1a1', '#20a8d8', '#6b6bfc', '#f8b94c'],
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.4,
      opacityTo: 0.25,
      stops: [
        0,
        0,
        0,
      ],
    },
  },
  series: [],
  xaxis: {
    type: 'datetim',
    categories: [],
    labels: {
      show: true,
      style: {
        colors: 'var(--black-500)',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: 'var(--black-500)',
      },
    },
  },
};
