import userLocalStorage from '@sh/services/userLocalStorage';

const storageKey = 'globalSearch';

export default class RecentlySearch {
  constructor(limit = 10) {
    this.limit = limit;
  }

  getItems() {
    return this._getData();
  }

  setItem(key, value) {
    let data = this._getData();
    const newValues = [value];
    if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
      // If is object
      if (data[key]) {
        const limit = this.limit - 1;
        for (let i = 0; i < limit; i++) {
          const val = data[key][i];
          if (val) {
            // eslint-disable-next-line no-continue
            if (val === value) continue;
            newValues.push(val);
          } else {
            break;
          }
        }
      }
    } else {
      // If not object
      data = {};
    }
    data[key] = newValues;
    this._setData(data);
  }

  _getData() {
    return userLocalStorage.getSettings(storageKey);
  }

  _setData(payload) {
    userLocalStorage.setSettings(storageKey, payload);
  }
}
