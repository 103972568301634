const tabRenaming = {
  Facebook: {
    adgroups: 'AdSets',
    contents: 'Ad',
  },
  TikTok: {
    contents: 'Ad',
  },
  GoogleAds: {
    contents: 'Ad',
    widgets: 'Keywords',
    targets: 'Search Terms',
  },
  AmazonDSP: {
    adgroups: 'Line Items',
    contents: 'Creatives',
    targets: 'Deals',
    exchanges: 'Supply sources',
  },
  YahooDSP: {
    adgroups: 'Line Items',
    contents: 'Creatives',
    widgets: 'Packages',
  },
  ActiveRevenue: {
    widgets: 'Apps',
  },
  MediaGo: {
    widgets: 'Sites',
  },
  Outbrain: {
    widgets: 'Publisher',
    contents: 'Ad',
  },
  Taboola: {
    widgets: 'Site',
    contents: 'Ad',
  },
};

export default {
  getTabRenaming(tab, trafficSourceUniqueName) {
    return tabRenaming[trafficSourceUniqueName]?.[tab];
  },
};
