export default [
  {
    name: 'Chrome',
    value: 'Chrome',
  },
  {
    name: 'Safari',
    value: 'Safari',
  },
  {
    name: 'Facebook In-App Browser',
    value: 'Facebook In-App Browser',
  },
  {
    name: 'Edge',
    value: 'Edge',
  },
  {
    name: 'Chromium',
    value: 'Chromium',
  },
  {
    name: 'Firefox',
    value: 'Firefox',
  },
  {
    name: 'WebView',
    value: 'WebView',
  },
  {
    name: 'Samsung Browser',
    value: 'Samsung Browser',
  },
  {
    name: 'Internet Explorer',
    value: 'Internet Explorer',
  },
  {
    name: 'Google Search App',
    value: 'Google Search App',
  },
  {
    name: 'Android App',
    value: 'Android App',
  },
  {
    name: 'Opera',
    value: 'Opera',
  },
  {
    name: 'MIUI Browser',
    value: 'MIUI Browser',
  },
  {
    name: 'Amazon Silk',
    value: 'Amazon Silk',
  },
  {
    name: 'UC Browser',
    value: 'UC Browser',
  },
  {
    name: 'Yandex',
    value: 'Yandex',
  },
];
