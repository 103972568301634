export interface Parameter {
  key: string;
  value?: string;
  info?: string;
  title?: string;
}

export interface Parameters {
  default: Parameter[];
  custom: Parameter[];
}

export const defaultUrl = 'https://www.example.io?';

export const defaultParameters: Parameter[] = [
  {
    key: 'utm_source',
    info: 'To identify the source of traffic. For example: Facebook, Instagram, a search engine or other source.',
    title: 'Campaign source',
  },
  {
    key: 'utm_medium',
    info: 'To identify the advertising medium. For example: banner, email, Facebook_Feed or Instagram_Story.',
    title: 'Campaign Medium',
  },
  {
    key: 'utm_campaign',
    info: 'To identify a specific promotion or strategic campaign. For example: summer_sale.',
    title: 'Campaign name',
  },
  {
    key: 'utm_content',
    info: 'To differentiate ads or links that point to the same URL. For example: white_logo, black_logo.',
    title: 'Campaign Content',
  },
];

export const defaultParameterOptions = [
  { id: '{{campaign.id}}', label: '{{campaign.id}}' },
  { id: '{{adset.id}}', label: '{{adset.id}}' },
  { id: '{{ad.id}}', label: '{{ad.id}}' },
  { id: '{{campaign.name}}', label: '{{campaign.name}}' },
  { id: '{{adset.name}}', label: '{{adset.name}}' },
  { id: '{{ad.name}}', label: '{{ad.name}}' },
  { id: '{{placement}}', label: '{{placement}}' },
  { id: '{{site.source_name}}', label: '{{site.source_name}}' },
];
