
import { useMediaManagerStore } from '@/stores/media-manager';
import GridWrapper, { GridWrapperInstance } from '@sh/components/Utils/Table/GridWrapper.ts.vue';
import { selectContainer } from '@sh/components/Utils/Table/helpers';
import { NoRowsOverlayComponentParams } from '@sh/components/Utils/Table/types';
import { EmptyTableCell, isDefined, MediaManagerHelpers, pageSize, pluralize } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ITooltipParams,
  PaginationChangedEvent,
} from 'ag-grid-community';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

let storeSubscription: Function | undefined;

interface ComponentRefs {
  $refs: {
    GridWrapper?: GridWrapperInstance;
  };
}

type ComponentTypes = VueConstructor<Vue & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    GridWrapper,
  },
  data() {
    return {
      tableSearch: '',
      isDataLoaded: true,
    };
  },
  computed: {
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    dataSource(): IServerSideDatasource {
      return {
        getRows: async (params: IServerSideGetRowsParams) => {
          this.$refs.GridWrapper?.gridApi?.hideOverlay();
          const page = (params.request.startRow ?? 0) / this.pagination.pageSize + 1;

          if (!this.isDataLoaded) {
            this.isTableLoading = true;
            this.filters.page = page;
            await this.getData();
          }

          this.$refs.GridWrapper?.gridApi?.hideOverlay();

          if (this.filters.page === page) {
            this.onSetTableRows(params, this.ads);
          } else {
            this.onSetTableRows(params, this.firstPageAds);
            this.$refs.GridWrapper?.gridApi?.paginationGoToPage(this.filters.page - 1);
          }
        },
      };
    },
    columnDefs(): ColDef[] {
      const columnDefs: ColDef[] = [
        {
          field: 'actions',
          checkboxSelection: true,
          sortable: false,
          width: 125,
          pinned: !this.isCampaignCreator,
          resizable: false,
          cellClass: `${selectContainer} justify-content-center`,
        },
        {
          field: 'image',
          sortable: false,
          width: 140,
          cellRenderer: 'ImageCellRenderer',
          cellRendererParams: (params: MediaManager.TableParams) => ({
            imageURL: params.data.image?.base64,
          }),
        },
        {
          field: 'headline',
          minWidth: 400,
          maxWidth: 1000,
          flex: 1,
          tooltipValueGetter: (params: ITooltipParams) => params.data?.headline,
        },
        {
          field: 'trafficSource',
          sortable: false,
          valueGetter: 'data.trafficSource.name',
          cellRenderer: (params: MediaManager.TableParams) =>
            params.data.trafficSource
              ? `<img src="${params.data.trafficSource.imagePath}" alt="${params.data.trafficSource.name}" height="24" style='padding-right:8px' />${params.data.trafficSource.name}`
              : '',
          headerName: 'Traffic Source',
        },
        {
          field: 'usedOn',
          headerName: 'Used On',
          sortable: true,
          hide: this.isTheOptimizerMode,
          valueGetter: (params: MediaManager.TableParams) =>
            params.data?.usedOn ? `${params.data?.usedOn} ${pluralize(params.data?.usedOn ?? 0, 'Campaign')}` : 0,
        },
        ...this.metricColumns,
        {
          field: 'tags',
          cellRenderer: 'TagsCellRenderer',
          sortable: false,
          minWidth: 270,
          flex: 1,
          valueGetter: (params: MediaManager.TableParams) => params.data?.tags?.join(', ') || EmptyTableCell,
          cellRendererParams: (params: MediaManager.TableParams) => ({
            tags: params.data.tags,
          }),
          tooltipValueGetter: (params: ITooltipParams) => params.data?.tags?.join(', '),
        },
      ];

      if (this.mode === MediaManager.Mode.IMAGES) {
        return columnDefs.filter((column) => column.field !== MediaManager.Mode.HEADLINES);
      }

      if (this.mode === MediaManager.Mode.HEADLINES) {
        return columnDefs.filter((column) => column.field !== MediaManager.Mode.IMAGES);
      }

      return columnDefs;
    },
    metricColumns(): ColDef[] {
      return this.settings.metricsDropdown.reduce((filtered: ColDef[], metricData) => {
        if (
          this.isTableLoading ||
          this.ads.find((ad) => ad.metrics.find((metric) => metric.field === metricData.field))
        ) {
          filtered.push({
            headerName: metricData.name,
            colId: metricData.field,
            hide: this.isTheOptimizerMode,
            sortable: !!this.settings.orderDropDown.find((orderOption) => orderOption.column === metricData.field),
            valueGetter: (params: MediaManager.TableParams) => this.getMetricValue(params, metricData.name),
          });
        }
        return filtered;
      }, []);
    },
    pagination() {
      return {
        enabled: true,
        pageSize,
      };
    },
    noRowsOverlayComponentParams(): NoRowsOverlayComponentParams {
      return {
        noRowsOverlayMessage: `No ${this.mode}s Found...`,
        noRowsOverlayErrorMessage: this.errorMessage,
        noRowsOverlayComponentClassList: ['text-capitalize'],
      };
    },
    ...mapState(useMediaManagerStore, [
      'mode',
      'totalRows',
      'isTheOptimizerMode',
      'settings',
      'isCampaignCreator',
      'firstPageAds',
    ]),
    ...mapWritableState(useMediaManagerStore, ['ads', 'errorMessage', 'filters', 'isTableLoading']),
  },
  created() {
    if (
      this.settings.orderDropDown.length &&
      !this.settings.orderDropDown.slice(0, 2).find((option) => option.name === this.filters.order[0].name)
    ) {
      this.filters.order = [this.settings.orderDropDown[0]];
      this.isDataLoaded = false;
    }
  },
  mounted() {
    storeSubscription = useMediaManagerStore().$onAction(({ name, after }) => {
      after(() => {
        switch (name) {
          case 'onSelectAllClick': {
            this.selectTableRows();
            break;
          }
          case 'updateFilters': {
            this.$refs.GridWrapper?.checkSort(this.filters.order.map((order) => order.column));
            this.$refs.GridWrapper?.refresh(true);
            break;
          }
          case 'onDeleteAds':
          case 'onRemoveTags': {
            this.$refs.GridWrapper?.refresh();
            break;
          }
          case 'editAd':
          case 'onBulkTagInsert': {
            this.setDataValue();
            break;
          }
        }
      });
    });
  },
  destroyed() {
    if (storeSubscription) {
      storeSubscription();
    }
  },
  methods: {
    ...mapActions(useMediaManagerStore, ['getData', 'reset', 'onAdModalOpen']),
    onSetTableRows(params: IServerSideGetRowsParams, rowData: MediaManager.AdCard[]) {
      this.isDataLoaded = false;
      this.isTableLoading = false;
      params.success({
        rowData,
        rowCount: this.totalRows || 0,
      });
    },
    onDataUpdated() {
      if (!this.totalRows) {
        this.$refs.GridWrapper?.gridApi?.showNoRowsOverlay();
      }

      if (!this.isTheOptimizerMode) {
        this.$refs.GridWrapper?.gridColumnApi?.autoSizeAllColumns();
      }
    },
    setDataValue() {
      this.$refs.GridWrapper?.gridApi?.forEachNode((node, index) => {
        if (
          node.data &&
          node.rowIndex !== null &&
          node.rowIndex >= (this.filters.page - 1) * pageSize &&
          node.rowIndex < this.filters.page * pageSize
        ) {
          node.setData(this.ads[index]);
        }
      });
    },
    selectTableRows() {
      this.$refs.GridWrapper?.gridApi?.forEachNode((node) => {
        node.setSelected(!!node.data?.selected);
      });
    },
    getMetricValue(params: MediaManager.TableParams, metricName: string) {
      const metric = params.data.metrics.find((metric) => metric.name === metricName);

      if (metric) {
        return MediaManagerHelpers.getMetricValue(metric);
      }
      return EmptyTableCell;
    },
    onSelectionChanged(data: Array<{ id: string }> | undefined) {
      const selectedIds = data?.map((selectedRow) => selectedRow.id);
      this.ads.forEach((ad) => (ad.selected = !!selectedIds?.includes(ad.id)));
    },
    onPaginationChanged(event: PaginationChangedEvent, currentPage?: number, pageSize?: number) {
      if (isDefined(currentPage) && pageSize) {
        const data: MediaManager.AdCard[] = [];
        this.filters.page = currentPage + 1;

        this.$refs.GridWrapper?.gridApi?.forEachNode((node) => {
          if (
            node.data &&
            node.rowIndex !== null &&
            node.rowIndex >= currentPage * pageSize &&
            node.rowIndex < (currentPage + 1) * pageSize
          ) {
            data.push(node.data);
          }
        });

        if (this.ads.find((ad) => data.find((item) => item.id === ad.id))) {
          this.ads = data;
        }
      }
    },
    onSortChanged(params: GridReadyEvent) {
      const sortState = params.columnApi.getColumnState().filter((column) => !!column.sort);

      const orderedColumns = sortState.reduce((filtered: MediaManager.ResponseTypes.OrderDropDown[], sortState) => {
        const orderedColumn = this.settings.orderDropDown.find(
          (option) => option.column === sortState.colId && option.type === sortState.sort?.toUpperCase()
        );

        if (orderedColumn) {
          filtered.push(orderedColumn);
        }
        return filtered;
      }, []);

      if (orderedColumns.length) {
        this.filters.order = orderedColumns;
      }
    },
    onTableRowClick(rowIndex: number) {
      if (!this.isCampaignCreator) {
        this.onAdModalOpen(rowIndex);
      }
    },
  },
});
