import AdAccountFilter from '@/views/Tools/Reporting/core/filters/AdAccountFilter';
import BreakDownFilter from '@/views/Tools/Reporting/core/filters/BreakDownFilter';
import CampaignFilter from '@/views/Tools/Reporting/core/filters/CampaignFilter';
import DateRangeFilter from '@/views/Tools/Reporting/core/filters/DateRangeFilter';
import ReportTypeFilter from '@/views/Tools/Reporting/core/filters/ReportTypeFilter';
import TrafficSourceComplianceFilter from '@/views/Tools/Reporting/core/filters/TrafficSourceComplianceFilter';
import TrafficSourceFilter from '@/views/Tools/Reporting/core/filters/TrafficSourceFilter';
import UserFilter from '@/views/Tools/Reporting/core/filters/UserFilter';
import { DateRange, ReportOptions, ReportType } from '@/views/Tools/Reporting/types';
import { Campaign } from '@/views/Tools/Reporting/types/Campaign';
import { Account, SearchFeed, TrafficSource } from '@sh/types';

export enum FilterType {
  TrafficSource = 'TrafficSource',
  AdAccount = 'AdAccount',
  ReportType = 'ReportType',
  DateRange = 'DateRange',
  BreakDown = 'BreakDown',
  Campaign = 'Campaign',
  User = 'User',
  TrafficSourceComplianceFilter = 'TrafficSourceComplianceFilter',
}

export interface FilterOptions {
  [FilterType.BreakDown]: SelectOption<string>[];
  [FilterType.TrafficSource]: Account[];
  [FilterType.AdAccount]: Account[];
  [FilterType.ReportType]: ReportOptions;
  [FilterType.Campaign]: Partial<Record<TrafficSource, Campaign[]>>;
  [FilterType.User]: SearchFeed.User[];
  [FilterType.TrafficSourceComplianceFilter]: Record<string, Record<string, SearchFeed.ComplianceAccount[]>>;
}

export interface FilterValues {
  [FilterType.DateRange]: DateRange;
  [FilterType.BreakDown]: string;
  [FilterType.TrafficSource]: TrafficSource;
  [FilterType.AdAccount]: number[];
  [FilterType.ReportType]: string;
  [FilterType.Campaign]: string[];
  [FilterType.User]: string;
  [FilterType.TrafficSourceComplianceFilter]: TrafficSource[];
}

export interface State {
  options: FilterOptions;
  selected: Partial<FilterValues>;
  reportType?: ReportType;
  userData?: Record<'email' | 'id', string>;
}

export interface Filters {
  [FilterType.DateRange]: DateRangeFilter;
  [FilterType.BreakDown]: BreakDownFilter;
  [FilterType.TrafficSource]: TrafficSourceFilter;
  [FilterType.AdAccount]: AdAccountFilter;
  [FilterType.ReportType]: ReportTypeFilter;
  [FilterType.Campaign]: CampaignFilter;
  [FilterType.User]: UserFilter;
  [FilterType.TrafficSourceComplianceFilter]: TrafficSourceComplianceFilter;
}
