export default (F) => ({
  traffic_source_campaign_id: F.create({
    header: { content: 'Camp. ID' },
    item: {
      key: 'traffic_source_campaign_id',
      searchable: true,
      total: null,
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      slot: 'name',
      searchable: true,
      cellClass: 'cell-name',
      style: null,
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--name',
  }),
  tags: F.create({
    header: { content: 'Tags' },
    item: {
      total: null,
      slot: 'tags',
      key: 'tags',
      cellClass: 'cell-name',
    },
  }),
  status: F.create({
    header: { content: 'Status' },
    item: {
      slot: 'status',
      key: 'status',
      total: null,
    },
  }),
  avg_cpc: F.create({
    header: { content: 'Avg. CPC' },
    item: {
      key: 'avg_cpc',
      slot: 'avg_cpc',
      total: {
        parse: (value) => parseFloat(value),
        content: (totals) => totals.avg_cpc || 0,
      },
    },
  }).setOptions({ format: 'currency' }),
  'additional_fields.bid_cap': F.create({
    header: { content: 'Bid Cap' },
    item: {
      slot: 'additional_fields.bid_cap',
      key: 'additional_fields.bid_cap',
      sortable: true,
    },
  }),
  payout: F.create({
    header: { content: 'payout' },
    item: {
      slot: 'payout',
      key: 'payout',
    },
  }),
});
