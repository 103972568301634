<template>
  <b-modal
    id="internalDetailsViewModal"
    modal-class="optimizer-modal"
    title="Details View"
    :hide-footer="true"
    :no-close-on-backdrop="campaignRetryLoading"
    :no-close-on-esc="campaignRetryLoading"
    :hide-header-close="campaignRetryLoading"
    size="lg"
    ref="internalDetailsViewModal"
    centered
    @hidden="hiddenModalFn()"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Details View</h2>

      <svg
        class="modal-header__close-icon"
        @click="$_hide"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div v-if="selectedItem" class="details-view-container">
      <details-table-v2 v-if="$c_isV2"
                        :selectedItem="selectedItem"
                        :campaignRetryLoading="campaignRetryLoading"
                        :htmlStatusFn="htmlStatusFn"
                        :htmlAccountNameFn="htmlAccountNameFn"
                        :campaign-name="$c_campaignName"
      >
      </details-table-v2>
      <details-table v-else
                     :selectedItem="selectedItem"
                     :campaignRetryLoading="campaignRetryLoading"
                     :htmlStatusFn="htmlStatusFn"
                     :htmlAccountNameFn="htmlAccountNameFn"
                     :campaign-name="$c_campaignName"
      >
      </details-table>
    </div>
  </b-modal>
</template>

<script>
import helpers from '../mixins/helpers';
import DetailsTable from './DetailsTable.vue';
import DetailsTableV2 from './DetailsTableV2.vue';

export default {
  name: 'DetailsModal',
  components: { DetailsTableV2, DetailsTable },
  mixins: [helpers],
  props: {
    campaignRetryLoading: { type: Boolean, default: false },
    selectedItem: { type: Object, default: null },
    htmlStatusFn: { type: Function, default: () => '' },
    htmlAccountNameFn: { type: Function, default: () => '' },
    hiddenModalFn: { type: Function, default: () => '' },
  },
  computed: {
    $c_isV2() {
      return this.selectedItem.version && this.selectedItem.version === 'v2';
    },
    $c_campaignName() {
      return this.selectedItem.campaign_name || this.selectedItem.campaign.name || '-';
    },
  },
  methods: {
    show() {
      this.$refs.internalDetailsViewModal.show();
    },
    $_hide() {
      this.$refs.internalDetailsViewModal.hide();
    },
    $_isRetryContents(item) {
      return item.status === 'PARTIALLY FINISHED';
    },
    $_isRetryCampaign(item) {
      return item.campaign.status === 'FAILED';
    },
  },
};


</script>
