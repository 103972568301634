import ImageHelper from '@sh/components/Utils/ImageEditor/ImageHelper';
import { roundNumber, TheOptimizer } from '@sh/helpers/General';
import { MediaManager } from '@sh/types';
import { ImageEditorType, ImageHelperType, ImageType } from '@sh/types/ImageTypes';
import Joi from 'joi';
import { cloneDeep } from 'lodash';
import md5 from 'md5';
import moment from 'moment';

const FORMAT = 'YYYY-MM-DD';

const OPERATORS = [
  { id: 1, name: '<' },
  { id: 2, name: '>' },
  { id: 3, name: '=' },
];

const getDeletableText = (length: number, isUndeletableAd: boolean, mode: string) => {
  if (length) {
    if (length === 1) {
      const adsText = isUndeletableAd ? `${length} remaining` : 'this';
      return `Are you sure you want to proceed deleting ${adsText} ${mode} ?`;
    }
    const adsText = isUndeletableAd ? `${length} remaining` : '';
    return `Are you sure you want to proceed deleting ${adsText} ${mode}s ?`;
  }
  return '';
};

const getUndeletableText = (deletableLength: number, totalLength: number, mode: string) => {
  if (deletableLength) {
    const undeletableLength = totalLength - deletableLength;
    if (undeletableLength) {
      if (undeletableLength === 1) {
        return `${undeletableLength} ${mode} can not be deleted because is used in existing campaigns!`;
      }
      return `${undeletableLength} ${mode}s can not be deleted because are used in existing campaigns!`;
    }
    return '';
  }
  return `Selected ${mode}s can not be deleted because are used in existing campaigns!`;
};

const initialValidationsData = {
  maxHeadlineCharacters: Number.MAX_SAFE_INTEGER,
  maxImageSize: Number.MAX_SAFE_INTEGER,
  minImageWidth: Number.MIN_SAFE_INTEGER,
  minImageHeight: Number.MIN_SAFE_INTEGER,
};

const getMetricsData = (
  ad: MediaManager.ResponseTypes.Content,
  metricsDropdown: MediaManager.MetricFilterTypes.DropDown[]
): MediaManager.MetricDto[] => {
  const data: MediaManager.MetricDto[] = [];

  for (const field of MediaManager.MetricFilterTypes.metricsOrder) {
    if (ad[field] !== undefined) {
      const metricsDropdownData = metricsDropdown.find((data) => data.field === field);

      if (metricsDropdownData && ad[field] !== undefined) {
        data.push({
          ...metricsDropdownData,
          value: roundNumber(
            parseFloat(`${ad[field]}`),
            metricsDropdownData.symbol === MediaManager.MetricFilterTypes.SYMBOL.PERCENTAGE
          ),
        });
      }
    }
  }

  return data;
};

const getAdCardTypeData = (
  ad: MediaManager.ResponseTypes.Content,
  uniqueTrafficSources: MediaManager.ResponseTypes.TrafficSource[],
  metricsDropdown: MediaManager.MetricFilterTypes.DropDown[],
  selected = false
): MediaManager.AdCard => ({
  id: ad.id,
  headline: ad.headline,
  ...(ad.image_url && {
    image: {
      mimeType: ad.image_format,
      base64: ad.image_url,
      size: ad.image_size,
      dimensions: {
        width: ad.image_width,
        height: ad.image_height,
      },
      md5: '',
    },
  }),
  trafficSource: uniqueTrafficSources.find(
    (data) =>
      (data.name === ad.traffic_source_unique_name && !ad.traffic_source_unique_name.includes(TheOptimizer)) ||
      (data.name === TheOptimizer && ad.traffic_source_unique_name.includes(TheOptimizer))
  ),
  tags: ad.tags,
  metrics: ad.traffic_source_unique_name.includes(TheOptimizer) ? [] : getMetricsData(ad, metricsDropdown),
  isEditable: !!ad.is_editable,
  usedOn: ad.usedOn,
  selected,
});

const getMetricValue = (data: MediaManager.MetricDto) => {
  switch (data.symbol) {
    case MediaManager.MetricFilterTypes.SYMBOL.PERCENTAGE: {
      return `${roundNumber(parseFloat(data.value.toString()) * 100)}%`;
    }
    case MediaManager.MetricFilterTypes.SYMBOL.MONEY: {
      return `$${data.value}`;
    }
    case MediaManager.MetricFilterTypes.SYMBOL.BYTES: {
      return `${data.value}Mb`;
    }
    default: {
      return `${data.value}`;
    }
  }
};

const getMetricOptionName = (data: MediaManager.MetricFilterTypes.DropDown) => {
  switch (data.symbol) {
    case MediaManager.MetricFilterTypes.SYMBOL.BYTES: {
      return `${data.name} (Mb)`;
    }
    case MediaManager.MetricFilterTypes.SYMBOL.PX: {
      return `${data.name} (px)`;
    }
    default: {
      return `${data.name}`;
    }
  }
};

const validateData = (data: MediaManager.AdCard, context: any) => {
  const schema = Joi.object({
    id: Joi.string().required(),
    headline: Joi.string().required(),
    tags: Joi.array().required().items(Joi.string()),
    image: Joi.object({
      base64: Joi.string().required(),
      size: Joi.number().required().min(0),
      mimeType: Joi.string().required(),
      dimensions: Joi.object({
        width: Joi.number().required().min(0),
        height: Joi.number().required().min(0),
      }),
      md5: Joi.string().valid(''),
    }).required(),
  })
    .when('$mode', {
      is: MediaManager.Mode.IMAGES,
      then: Joi.object({ headline: Joi.forbidden() }),
    })
    .when('$mode', {
      is: MediaManager.Mode.HEADLINES,
      then: Joi.object({ image: Joi.forbidden() }),
    })
    .when('$isCreation', {
      is: true,
      then: Joi.object({ id: Joi.forbidden() }),
    })
    .when('$maxHeadlineCharacters', {
      is: Joi.number().required().min(1),
      then: Joi.object({
        headline: Joi.string()
          .max(Joi.ref('$maxHeadlineCharacters'))
          .messages({
            'string.max': `Headline length must be less than or equal to ${context.maxHeadlineCharacters}.<br>`,
          }),
      }),
    })
    .when('$maxImageSize', {
      is: Joi.number().required().min(1),
      then: Joi.object({
        image: Joi.object({
          size: Joi.number()
            .required()
            .max(Joi.ref('$maxImageSize'))
            .messages({ 'number.max': `Max image size should be ${context.maxImageSize / 1000} kB.<br>` }),
        }),
      }),
    })
    .when('$minImageWidth', {
      is: Joi.number().required().min(1),
      then: Joi.object({
        image: Joi.object({
          dimensions: Joi.object({
            width: Joi.number()
              .required()
              .min(Joi.ref('$minImageWidth'))
              .messages({ 'number.min': `Minimum image width should be ${context.minImageWidth} px.<br>` }),
          }),
        }),
      }),
    })
    .when('$minImageHeight', {
      is: Joi.number().required().min(1),
      then: Joi.object({
        image: Joi.object({
          dimensions: Joi.object({
            height: Joi.number()
              .required()
              .min(Joi.ref('$minImageHeight'))
              .messages({ 'number.min': `Minimum image width should be ${context.minImageHeight} px.<br>` }),
          }),
        }),
      }),
    });

  return schema.validate(data, { stripUnknown: true, abortEarly: false, context });
};

const getImageData = async (files: FileList, isNewImage?: boolean) => {
  const data: ImageHelperType[] = (await ImageHelper.getInfoFromFiles(files)).images;

  return data.map((imageData) => {
    const base64Split = ';base64,';
    const rawBase64 = imageData.data?.split(base64Split)[1];

    return {
      base64: imageData.data,
      dimensions: {
        width: imageData.width,
        height: imageData.height,
      },
      mimeType: imageData.mimeType,
      size: Math.round(imageData.size) * 1000,
      ...(isNewImage && { md5: md5(rawBase64), base64: rawBase64 }),
    };
  });
};

const getEditedImage = (editedImageObject: ImageEditorType): ImageType => ({
  mimeType: editedImageObject.mimeType,
  base64: editedImageObject.imageBase64,
  dimensions: {
    width: editedImageObject.width,
    height: editedImageObject.height,
  },
  size: ImageHelper.getBase64ImageSize(editedImageObject.imageBase64) * 1000,
});

const getTagsToDisplay = (tags: string[]) => {
  const MAX_CHARACTERS = 40;
  const SPACE = 5;

  let characters = 0;
  let showedTags = -1;

  tags.forEach((item, index) => {
    characters += item.length + SPACE;

    if (characters < MAX_CHARACTERS) {
      showedTags = index;
    }
  });
  return tags.slice(0, showedTags + 1);
};

const getActiveAdDto = (ad: MediaManager.AdCard, activeAd?: MediaManager.ActiveAd) =>
  cloneDeep({
    ...ad,
    campaignsList: activeAd?.campaignsList ?? [],
    errorMessage: activeAd?.errorMessage ?? '',
    isCampaignListLoading: activeAd?.isCampaignListLoading ?? false,
    usedOn: ad?.usedOn ?? activeAd?.usedOn ?? 0,
  });

const getInitialDateRange = () => ({
  startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT),
  endDate: moment().endOf('day').format(FORMAT),
});

export {
  OPERATORS,
  getActiveAdDto,
  getAdCardTypeData,
  getDeletableText,
  getEditedImage,
  getImageData,
  getInitialDateRange,
  getMetricOptionName,
  getMetricValue,
  getMetricsData,
  getTagsToDisplay,
  getUndeletableText,
  initialValidationsData,
  validateData,
};
