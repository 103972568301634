<template>
  <div class="campaign-creator-container">
    <preloader v-if="!config"></preloader>
    <form-wizard
      v-else
      @on-change="$_onChangeStep"
      :validate-on-back="true"
      color="#4158D0"
      step-size="xs"
      :class="`step-${stepIndex}`"
    >
      <template slot="title">
        <img src="sh_static/other/campaign-creator.svg">
        <div class="wizard-title-content">
          <h1>{{ mode === 'edit' ? 'Campaign Editor' : 'Campaign Creator' }}</h1>
          <p><a href="https://theoptimizer.io/help/knowledge-base/creating-a-campaign-from-theoptimizer-native" target="_blank">Learn More<i class="fa fa-angle-right ml-1"></i></a></p>
        </div>
      </template>

      <!-- CAMPAIGN -->
      <tab-content :title="mode === 'edit' ? 'Edit Campaign' : 'Create Campaign'" icon="fa fa-file-text-o">
        <loadizer :loading="loading" message="Your campaign is loading..." />
        <loadizer :loading="freeze[0]" message="Validating..." />
        <campaign-form v-if="!loading" v-show="!freeze[0]"
                       :config="config" :mode="mode" :cloneMode="cloneMode" :retry="retry" :model="$c_model"
                       :payload="payload" v-model="model.campaign"
                       ref="tab-0"
                       @onTrafficSourceTypeUpdate="onTrafficSourceTypeUpdate"
        />
      </tab-content>

      <!-- CONTENT -->
      <tab-content v-if="mode !== 'edit' || config.AllowEditContents.indexOf($c_uniqueName) > -1" :title="mode === 'edit' ? 'Edit Contents' : 'Add Contents'" icon="fa fa-cloud-upload">
        <loadizer :loading="loading" message="Your contents is loading..." />
        <loadizer :loading="freeze[1]" message="Validating..." />
        <loadizer :loading="submitBtnLoading" message="Submitting..." />
        <contents-form v-if="!loading && $c_accountId" v-show="!freeze[1] && !submitBtnLoading"
                       :config="config" :mode="mode" :cloneMode="cloneMode" :retry="retry" :model="$c_model"
                       :payload="payload" :campaign="model.campaign" v-model="model.contents"
                       ref="tab-1" :key="`contents-${$c_uniqueName}`"
        />
      </tab-content>

      <!-- VARIATIONS -->
      <tab-content v-if="mode !== 'edit'" title="Generate Variations" icon="fa fa-object-ungroup">
        <loadizer :loading="loading" message="Creating variations." />
        <variations-form v-if="$c_accountId && !freeze[2]"
                         :config="config" :mode="mode" :cloneMode="cloneMode" :retry="retry" :model="$c_model"
                         :campaign="model.campaign" :contents="model.contents" :payload="payload" v-model="model.variations"
                         ref="tab-2" :key="`variations-${$c_uniqueName}`"
        />
      </tab-content>

      <!-- PREVIEW -->
      <tab-content v-if="mode !== 'edit'" title="Preview" icon="fa fa-flag-o">
        <loadizer :loading="loading" message="Preview campaigns." />
        <loadizer :loading="submitBtnLoading" message="Submitting..." />
        <preview-form v-if="$c_accountId && !freeze[3]" v-show="!submitBtnLoading"
                      :config="config" :mode="mode" :cloneMode="cloneMode" :retry="retry" :model="$c_model"
                      :variations-model="model.variations.model"
                      ref="tab-3" :key="`preview-${$c_uniqueName}`"
        />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer fixed-footer">
          <div class="wizard-footer__left">
            <wizard-button v-if="props.activeTabIndex > 0" class="wizard-back-btn secondary-button" :style="props.fillButtonStyle" :disabled="disableButton" @click.native="$_onClickPrevious(props)">Previous</wizard-button>
          </div>
          <div class="wizard-footer__right">
            <wizard-button v-if="!props.isLastStep" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="disableButton" @click.native="$_onClickNext(props)">
              <i v-if="disableButton" class="fa fa-spinner fa-spin" aria-hidden="true"></i><template v-else>Next</template>
            </wizard-button>
            <wizard-button v-else :disabled="submitBtnLoading" class="wizard-footer-right finish-button" @click.native="$_submitCampaigns(props)" :style="props.fillButtonStyle">
              <i v-if="submitBtnLoading" class="fa fa-spinner fa-spin" aria-hidden="true"></i><template v-else>Done</template>
            </wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>


    <!-- CC -->
    <CampaignCreatorV2 :visibility="isFacebookCampaignCreator" @onVisibilityChange="onFacebookCampaignCreator" />
  </div>
</template>

<script>
import {
  FormWizard, TabContent, WizardButton,
} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import CampaignForm from './steps/Campaign/CampaignForm';
import ContentsForm from './steps/Contents/ContentsForm';
import VariationsForm from './steps/Variations/VariationsForm';
import PreviewForm from './steps/Preview/PreviewForm';
import { getConfig } from './config';
import notifications from '@sh/mixins/notifications';
import { cloneDeep } from 'lodash';
import { TrafficSource } from '@sh/types';
import CampaignCreatorV2 from '@/views/Automation/CampaignCreatorV2/Index.ts.vue';

export default {
  name: 'CampaignCreator',
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    CampaignForm,
    ContentsForm,
    VariationsForm,
    PreviewForm,
    CampaignCreatorV2,
  },
  mixins: [notifications],
  data() {
    return {
      mode: 'create',
      retry: false,
      retryId: null,
      cloneMode: null,
      stepIndex: 0,
      loading: true,
      freeze: [false, false, false, false],
      disableButton: false,
      submitBtnLoading: false,
      config: null,
      model: {
        campaign: {},
        contents: {},
        variations: {
          model: [],
          data: [],
        },
      },
      payload: {
        campaign: null,
        contents: null,
        rules: [],
      },
      isFacebookCampaignCreator: false,
    };
  },
  computed: {
    $c_model() {
      try {
        return this.config.Models[this.config.Account.value.type.uniqueName];
      } catch (err) {
        return null;
      }
    },
    $c_uniqueName() {
      try {
        return this.config.Account.value.type.uniqueName;
      } catch (err) {
        return null;
      }
    },
    $c_accountId() {
      try {
        return this.config.Account.value.id;
      } catch (err) {
        return null;
      }
    },
  },
  async created() {
    this.isFacebookCampaignCreator = this.$route.query.facebookCampaignCreator === 'true';
    this.loading = true;
    try {
      // Get query params
      const {
        mode, id, retry, account, rules, cloneMode,
      } = this.$route.query;
      if (mode) this.mode = mode;
      if (cloneMode) this.cloneMode = cloneMode;
      if (retry) {
        this.retry = true;
        this.retryId = retry;
      }
      // Validate
      if (this.mode !== 'create' || this.retry) {
        if ((!id && !retry) || !account) throw Error('ID or Account ID missing!');
      }
      this.config = await getConfig();
      if (this.mode !== 'create' || this.retry) {
        // Set account
        this.config.Account.setAccount(account);
        // Set form
        await this.$_setFormData(retry || id, rules);
      }
      this.loading = false;
    } catch (err) {
      console.log(err);
      this.$n_failNotification({ title: 'An error occurred' });
    }
  },
  methods: {
    async $_submitCampaigns(props) {
      const isValid = await this.$_validateTab(props);
      if (isValid) {
        this.disableButton = true;
        this.submitBtnLoading = true;
        try {
          const payload = this.$_getPayloadForSubmit();
          await this.$api.campaignCreator.saveCampaign(payload);
          this.$n_infoNotification({
            title: 'Your campaign is being processed!',
            message: ' Please check the queue for the campaign status. Refresh to update.',
            timeout: 7000,
          });
          this.$router.push({ path: '/campaign-creator-queue' });
        } catch (err) {
          console.log(err);
          this.$n_failNotification({
            title: 'An error ocurred',
            message: err.message,
            timeout: 8000,
          });
        }
        this.disableButton = false;
        this.submitBtnLoading = false;
      }
    },
    $_getPayloadForSubmit() {
      const action = `${this.mode.toUpperCase()}_CAMPAIGNS`;
      const account = this.config.Account.value;
      const payload = {
        account: {
          ts_account_id: account.id,
          ts_account_name: account.name,
          ts_type: account.type.uniqueName,
          ts_type_name: account.type.name,
        },
        action,
        contents: {},
        rules: [],
        form: this.$c_model.campaign.getForm(this.mode, this.payload.campaign),
        variations: [],
      };
      if (this.retryId) payload.retryId = this.retryId;
      if (this.cloneMode === 'EXACT_CLONE') {
        payload.cloneMode = this.cloneMode;
        payload.currentPayload = {
          campaign: this.payload.campaign,
          contents: this.payload.contents,
        };
      }
      if (this.model.contents) {
        this.model.contents.contents.forEach((item) => {
          if (item.headline.ctaButton || item.headline.adDescription) {
            if (account.trafficSourceType.name === 'Outbrain') {
              item.callToAction = item.headline?.ctaButton?.length
                ? item.headline.ctaButton.toUpperCase().replace(/\s+/g, '_')
                : null;
            }
            if (account.trafficSourceType.name === 'Taboola' && item.headline?.ctaButton?.length) {
              item.cta = item.headline?.ctaButton?.length
                ? { cta_type: item.headline.ctaButton.toUpperCase().replace(/\s+/g, '_') }
                : null;
            }
            item.description = item.headline.adDescription || null;
            item.headline = item.headline.text || item.headline;
          } else {
            item.headline = item.headline.text || item.headline;
          }
        });
        payload.contents = {
          contents: this.model.contents.contents,
          images: this.model.contents.images,
          targetUrl: this.model.contents.targetUrl,
        };
      }
      if (this.model.variations.data) {
        const cloneForm = cloneDeep(payload.form);
        this.model.variations.data.forEach((variation, index) => {
          if (index === 0) {
            this.$_variationBuilder(payload.form, variation, (key, value) => {
              if (key === 'rules') payload.rules = value;
            });
          } else {
            const form = cloneDeep(cloneForm);
            this.$_variationBuilder(form, variation, (key, value) => {
              if (key === 'target_url' && value) {
                form.targetUrl = value;
              } else if (key === 'rules') {
                form.rules = value;
              } else if (key === 'cpc' && value) {
                form.cpc = value;
              }
            });
            payload.variations.push(form);
          }
        });
      }
      if (this.mode === 'edit') {
        payload.form.campaign_id = this.retry ? this.payload.campaign.campaign_id : this.$route.query.id;
      }
      return payload;
    },
    async $_setFormData(id, rules) {
      // Get Data
      const trafficSourceType = this.config.Account.value.type.uniqueName;
      const trafficSourceAccountId = this.config.Account.value.id;
      if (this.retry) {
        const { data: { uploaderAction } } = await this.$api.campaignCreator.queueItem(id);
        this.payload.campaign = uploaderAction.campaign;
        this.payload.contents = uploaderAction.contents;
        this.payload.contents = uploaderAction.contents.map((content) => {
          if (content.imageUrlHQ) content.imageUrl = content.imageUrlHQ;
          return content;
        });
        this.payload.rules = uploaderAction.rules;
      } else {
        const { campaign } = await this.$api.campaignCreator.getCampaignDataV2(trafficSourceType, trafficSourceAccountId, id);
        this.payload.campaign = campaign.form;
        this.payload.contents = campaign.contents.map((content) => {
          if (content.imageUrlHQ) content.imageUrl = content.imageUrlHQ;
          return content;
        });
        // this.payload.contents = campaign.contents;
        this.payload.rules = rules || [];
      }
    },
    $_onClickPrevious(props) {
      window.scrollTo({ top: 0 });
      props.prevTab();
    },
    async $_onClickNext(props) {
      const isValid = await this.$_validateTab(props);

      if (isValid) {
        if (this.config.Account.selectedTrafficSource?.uniqueName === TrafficSource.Facebook) {
          this.onFacebookCampaignCreator(true);
          return;
        }
        window.scrollTo({ top: 0 });
        props.nextTab();
      }

      this.onFacebookCampaignCreator(false);
    },
    async $_validateTab(props) {
      const index = props.activeTabIndex;
      this.freeze[index] = true;
      this.disableButton = true;
      const tab = this.$refs[`tab-${index}`];
      let returnVal = false;
      if (tab) {
        try {
          const result = await tab.validate();
          if (result) {
            returnVal = true;
          } else {
            this.$n_warnNotification({ title: 'Invalid form' });
          }
        } catch (err) {
          console.log(err);
        }
      }
      this.freeze[index] = false;
      this.disableButton = false;
      return returnVal;
    },
    $_onChangeStep(prevIndex, nextIndex) {
      this.stepIndex = nextIndex;
    },
    $_variationBuilder(form, variation, cb) {
      Object.entries(variation).forEach(([key, value]) => {
        if (typeof form[key] !== 'undefined') {
          form[key] = value;
        } else if (key === '_nested') {
          Object.entries(value).forEach(([_key, _value]) => {
            if (variation._nestedParser && variation._nestedParser[_key]) {
              variation._nestedParser[_key](form, _value);
            } else {
              if (!form[_key]) form[_key] = {};
              form[_key] = { ...form[_key], ..._value };
            }
          });
        } else if (cb) {
          cb(key, value);
        }
      });
    },
    onTrafficSourceTypeUpdate() {
      this.onFacebookCampaignCreator(this.config.Account.selectedTrafficSource?.uniqueName === TrafficSource.Facebook);
    },
    onFacebookCampaignCreator(value) {
      const query = cloneDeep(this.$route.query);
      this.isFacebookCampaignCreator = value;

      if (value) {
        query.facebookCampaignCreator = true;
      } else {
        delete query.facebookCampaignCreator;
      }

      const queryParams = new URLSearchParams(query).toString();

      history.pushState(
        {},
        null,
        `/#${this.$route.fullPath.split('?')[0]}${queryParams ? `?${queryParams}` : ''}`,
      );
    },
  },
};
</script>

<style lang="scss">
.campaign-creator-container {
  padding-bottom: 6.6rem;

  .vue-form-wizard {
    padding-bottom: 0;
    &.step-0 {
      .wizard-progress-with-circle {
        .wizard-progress-bar {
          width: 0 !important;
        }
      }
    }

    &.step-1 {
      .wizard-progress-with-circle {
        .wizard-progress-bar {
          width: calc(100% * 1 / 3) !important;
        }
      }
    }

    &.step-2 {
      .wizard-progress-with-circle {
        .wizard-progress-bar {
          width: calc(100% * 2 / 3) !important;
        }
      }
    }

    &.step-3 {
      .wizard-progress-with-circle {
        .wizard-progress-bar {
          width: 100% !important;
        }
      }
    }

    .wizard-header {
      img {
        margin-bottom: 1.5rem;
      }
      .wizard-title-content {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 2.4rem;
          font-weight: 700;
          color: $color-primary;
        }

        a {
          font-size: 1.4rem;
          color: #24C2F3;
          margin-top: 0.7rem;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .wizard-navigation {
      .wizard-progress-with-circle {
        width: 74%;
      }

      .wizard-nav {
        .wizard-icon-circle { margin-bottom: .5rem; }
        .stepTitle {
          font-size: 1.4rem;
          color: #7F828A;
        }
      }
    }
  }

  .wizard-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4.3rem;

    &__left {
      .wizard-back-btn {
        background: $color-white !important;
        border: 0.1rem solid #E5E5E5 !important;
        color: $black-500 !important;
        padding: .6rem 1.2rem;
        height: 100%;
      }
    }
  }
}

// .campaign-creator-wraper {
//   .wizard-navigation {
//     .wizard-tab-content {
//       .wizard-tab-container {
//         position: relative;
//         min-height: 300px;
//       }
//     }
//   }
// }
</style>
