<template>
  <b-modal
    id="facebookChangeBudgetStrategy"
    modal-class="optimizer-modal"
    ref="facebookChangeBudgetStrategy"
    centered
    @ok="$_handleOk"
    @show="$_clear"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Update Budget strategy</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div v-if="canBeChanged.condition" class="gemini-bid-modal-wrapper">
      <b-form
        :ref="$_getScope()"
        :data-vv-scope="$_getScope()"
        @submit.prevent
      >
        <div class="form-group row d-flex flex-column">
          <div class="d-flex bd-highlight">
            <label for="listType" class="col-md-5 col-lg-4 col-xl-3 col-form-label">Strategy: </label>
            <vue-opti-select-light
              id="key"
              :class="[{ 'has-error': $_hasError('listType') === false }, 'optimizer-select', 'flex-grow-1']"
              data-vv-as="Strategy Type"
              v-validate="'required'"
              data-vv-validate-on="input|update"
              name="label"
              v-model="selectedStrategy"
              :options="availableStrategy"
              :value="campaign.additional_fields.bid_strategy"
              single
              button-block
            />
          </div>
          <div class="mt-3">
            <b-alert
              :show="!!alert.message"
              :variant="alert.type"
              class=""
            >
              <div v-html="alert.message"></div>
            </b-alert>
          </div>
          <div v-if="selectedStrategy && selectedStrategy.value ==='LOWEST_COST_WITHOUT_CAP'" class="d-flex bd-highlight">
            <label for="listType" class="col-md-5 col-form-label">Select your new Budget type: </label>
            <vue-opti-select-light
              id="key"
              :class="[{ 'has-error': $_hasError('listType') === false }, 'optimizer-select', 'flex-grow-1']"
              data-vv-as="Budget Type"
              v-validate="'required'"
              name="label"
              v-model="selectedBudgetType"
              :options="budgetType"
              :value="campaign.additional_fields && campaign.additional_fields.daily_budget ? 'daily_budget' : 'lifetime_budget'"
              single
              disabled
              button-block
            />
          </div>
          <small class="text-danger">{{ $_getError('listType') }}</small>
          <b-table
            :items="adGroups"
            :fields="fields"
            borderless
            small
            responsive
          >
            <template #cell(title)="data">
              <div class="publisher-title">
                <i
                  v-b-tooltip.hover="'ID: ' +data.item.id"
                  class="fa fa-question-circle-o text-muted info-icon"
                  aria-hidden="true"
                />
                {{ data.item.name }}
                <span
                  v-b-tooltip="{ disabled: !$_hasError(`adgroup-${data.item.id}`)}"
                  :class="$_hasError(`adgroup-${data.item.id}`) ? 'text-danger' : 'text-muted'"
                >
                  {{ $_getError(`adgroup-${data.item.id}`) }}
                </span>
              </div>
            </template>
            <template v-if="selectedStrategy" #cell(budget)="data">
              <div class="bid-input d-inline-flex pull-right">
                <b-input-group size="sm" :append="selectedStrategy.metric">
                  <b-form-input
                    v-model="data.item.budget"
                    :data-vv-as="`${selectedStrategy.updateFields.split(' ')[1]}`"
                    data-vv-validate-on="blur"
                    v-validate="`required|min_value:0.01`"
                    :name="`adgroup-${data.item.id}`"
                  />
                </b-input-group>
              </div>
            </template>
          </b-table>
        </div></b-form>
    </div>
    <div v-if="!canBeChanged.condition" class="mt-3">
      <b-alert
        :show="!!canBeChanged.message"
        :variant="canBeChanged.type"
        class=""
      >
        <div v-html="canBeChanged.message"></div>
      </b-alert>
    </div>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn v-if="availableStrategy.length !== 0" class="primary-button" @click="$_handleOk">Update budgets</b-btn>
    </template>
  </b-modal>
</template>

<script>
const scope = 'facebookChangeBudgetStrategyForm';
export default {
  name: 'FacebookChangeBudgetStrategyModal',
  data() {
    return {
      availableStrategy: [],
      strategy: [
        { value: 'LOWEST_COST_WITHOUT_CAP', metric: '$', info: 'Using this strategy, you should conisder: <br> You have no control over your cost. Costs can rise as you exhaust least expensive opportunities or as you increase budget.', updateFields: 'Set Budget', content: 'Lowest cost without CAP' },
        { value: 'LOWEST_COST_WITH_BID_CAP', metric: '$', info: 'Using this strategy, you should conisder: <br>Costs can rise as you exhaust cheaper opportunities or increase your budget. May not spend full budget. Bid is not the cost you’ll see in reporting.', updateFields: 'Set Bid', content: 'Lowest cost with BID Cap' },
        { value: 'COST_CAP', metric: '$', info: 'Using this strategy, you should conisder: <br>Cost may rise as you run out of cheapest opportunities. Learning phase requires more aggressive exploration. May not spend full budget once you hit the cap.', updateFields: 'Set Budget', content: 'Cost CAP' },
        // { value: 'LOWEST_COST_WITH_MIN_ROAS', metric: '%', info: 'Using this strategy, you should conisder: <br>Specific to value optimization.Setting the benchmark too high may lead to under-delivery. For iOS 14.5 campaigns, duration must be set to 3 days.', updateFields: 'Set ROAS', content: 'Lowest cost with MIN ROAS' },
      ],
      validStrategies: {
        LOWEST_COST_WITHOUT_CAP: ['APP_INSTALLS', 'CONVERSIONS', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'LINK_CLICKS', 'MESSAGES', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'PRODUCT_CATALOG_SALES', 'REACH', 'STORE_VISITS', 'VIDEO_VIEWS'],
        LOWEST_COST_WITH_BID_CAP: ['APP_INSTALLS', 'CONVERSIONS', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'LINK_CLICKS', 'MESSAGES', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'PRODUCT_CATALOG_SALES', 'REACH', 'STORE_VISITS', 'VIDEO_VIEWS'],
        COST_CAP: ['APP_INSTALLS', 'CONVERSIONS', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'LINK_CLICKS', 'MESSAGES', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'PRODUCT_CATALOG_SALES', 'VIDEO_VIEWS'],
        LOWEST_COST_WITH_MIN_ROAS: ['APP_INSTALLS', 'CONVERSIONS', 'PRODUCT_CATALOG_SALES'],
      },
      budgetType: [
        { value: 'daily_budget', content: 'Daily Budget' },
        { value: 'lifetime_budget', content: 'Lifetime Budget' },
      ],
      selectedBudgetType: null,
      selectedStrategy: null,
      campaign: null,
      adGroups: null,
    };
  },
  computed: {
    fields() {
      return [
        { key: 'title', label: 'AdSet Title' },
        { key: 'budget', label: this.selectedStrategy ? this.selectedStrategy.updateFields : 'Budget', thClass: 'text-right' },
      ];
    },
    alert() {
      return { type: 'info', message: this.selectedStrategy ? this.selectedStrategy.info : 'You cannot change budget strategy' };
    },
    canBeChanged() {
      return { condition: this.campaign && this.availableStrategy.length, type: 'info', message: 'You cannot change budget strategy for this campaign' };
    },
    adgroups: {
      set(adGroups) {
        this.adGroups = adGroups;
      },
      get() {
        return { ...this.adGroups || [] };
      } },
  },
  watch: {
    selectedStrategy(strategy) {
      this.$_clear();
      if (strategy.value !== 'LOWEST_COST_WITHOUT_CAP') {
        this.adGroups.forEach((group) => group.budget = 0.1);
      }
    },

    async campaign(campaign) {
      if (this.campaign && campaign) {
        const { adgroups } = await this.$api.adgroups.adgroups(this.campaign.id);
        const evenlySpend = (this.campaign.additional_fields.daily_budget || this.campaign.additional_fields.budget) / adgroups.length;
        const adGroups = adgroups.map((it) => ({ name: it.name, id: it.traffic_source_adgroup_id, budget: evenlySpend.toFixed(2) }));
        this.adgroups = adGroups || [];
        if (adgroups && adgroups.length) {
          const { optimization_goal } = adgroups[0].additional_fields;
          const filteredAdgroups = adgroups.filter((adgroup) => adgroup.additional_fields.optimization_goal === optimization_goal);
          if (filteredAdgroups.length !== adgroups.length) { this.availableStrategy = this.selectedStrategy; } else {
            this.availableStrategy = this.strategy.filter((el) => !!this.validStrategies[el.value].includes(this.campaign.additional_fields.objective));
          }
        }
      }
    },
  },
  methods: {
    show(campaign) {
      this.campaign = null;
      this.campaign = campaign;
      this.$refs.facebookChangeBudgetStrategy.show();
    },
    hide() {
      this.$refs.facebookChangeBudgetStrategy.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.$_getScope());
      if (isValid) {
        const payload = {};
        // payload.campaignId = this.campaign.id;
        payload.bid_strategy = this.selectedStrategy.value;
        this.adGroups.forEach((adGroup) => {
          if (this.selectedStrategy.value === 'LOWEST_COST_WITHOUT_CAP') {
            if (!payload.adset_budgets)payload.adset_budgets = [];
            payload.adset_budgets.push({ adset_id: adGroup.id, [this.selectedBudgetType.value]: +adGroup.budget });
          } else {
            if (!payload.adset_bid_amounts) payload.adset_bid_amounts = {};
            payload.adset_bid_amounts[adGroup.id] = +adGroup.budget;
          }
        });
        this.$emit('changeFacebookBudgetStrategy', { campaignId: this.campaign.id, payload });
        this.$_clear();
        this.hide();
      }
    },
    $_clear() {
      this.$validator.reset(this.$_getScope());
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss">
.gemini-bid-modal-wrapper {
  .table {
    th {
      border: 0;
    }
    td {
      border: 0;
      .info-icon {
        cursor: pointer;
      }
      .bid-input {
        .btn-secondary {
          width: 2.8rem;
          height: 2.8rem;
        }

        .input-group {
          width: 80px;

          input {
            border: .1rem solid #e5e5e5;
            height: 2.8rem;
            box-shadow: none !important;
            outline: none !important;
            font-size: 1.4rem;
            color: $text-primary;
            padding-left: 1.2rem;
          }

          &-append {
            .input-group-text {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
