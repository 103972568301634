import { getFieldsAndFilters } from '@sh/configurations/fields/FieldFilter';
import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';


const customMetric = [
  { group: 'ts', content: 'Impressions', value: 'impressions' },
  { group: 'ts', content: 'Viewable Impressions', value: 'viewable_impressions' },
  { group: 'ts', content: 'Traffic Source Clicks', value: 'ts_clicks' },
  { group: 'tr', content: 'Tracker Clicks', value: 'tr_clicks' },
  { group: 'ts', content: 'Traffic Source Conversions', value: 'ts_conversions' },
  { group: 'tr', content: 'Tracker Conversions', value: 'tr_conversions' },
  { group: 'ts', content: 'Traffic Source Revenue', value: 'ts_revenue' },
  { group: 'tr', content: 'Tracker Revenue', value: 'tr_revenue' },
  { group: 'ts', content: 'Traffic Source Spent', value: 'cost' },
  { group: 'ts', content: 'CPC', value: 'bid' },
  { group: 'tr', content: 'Landing Page Clicks', value: 'lp_clicks' },
  { group: 'ts', content: 'Traffic Source EPC', value: 'ts_epc' },
  { group: 'tr', content: 'Tracker EPC', value: 'tr_epc' },
  { group: 'tr', content: 'Landing Page CTR', value: 'lp_ctr' },
  { group: 'tr', content: 'Landing Page CR', value: 'lp_cr' },
  { group: 'tr', content: 'Landing Page CPC', value: 'cp_lp_clicks' },
  { group: 'ts', content: 'CTR', value: 'ctr' },
  { group: 'ts', content: 'Traffic Source CVR', value: 'ts_cvr' },
  { group: 'tr', content: 'Tracker CVR', value: 'tr_cvr' },
  { group: 'ts', content: 'Traffic Source NET', value: 'ts_net' },
  { group: 'tr', content: 'Tracker NET', value: 'tr_net' },
  { group: 'ts', content: 'Traffic Source ROI', value: 'ts_roi' },
  { group: 'tr', content: 'Tracker ROI', value: 'tr_roi' },
  { group: 'ts', content: 'Traffic Source CPA', value: 'ts_cpa' },
  { group: 'ts', content: 'Avg. CPC', value: 'avg_cpc' },
  { group: 'ts', content: 'Estimated Spent', value: 'est_cost' },
  { group: 'ts', content: 'Estimated NET', value: 'est_net' },
  { group: 'ts', content: 'Estimated ROI', value: 'est_roi' },
  { group: 'ts', content: 'Target CPA', value: 'target_cpa' },
  { group: 'tr', content: 'Tracker CPA', value: 'tr_cpa' },
  { group: 'tr', content: 'EPC', value: 'epc' },
  { group: 'tr', content: 'Payout', value: 'payout' },
  { group: 'tr', content: 'Search Feed Revenue', value: 'publisher_revenue' },
  { group: 'tr', content: 'Search Feed Clicks', value: 'publisher_clicks' },
  { group: 'tr', content: 'Search Feed Conversions', value: 'publisher_conversions' },
  { group: 'tr', content: 'Clicks', value: 'clicks' },
  { group: 'tr', content: 'Clicks Bounced', value: 'clicksbounced' },
  { group: 'tr', content: 'Clicks Returned', value: 'clicksreturned' },
  { group: 'tr', content: 'Direct Impressions', value: 'direct_impressions' },
  { group: 'tr', content: 'Direct Revenue', value: 'direct_revenue' },
  { group: 'tr', content: 'Dynamic Allocation Impressions', value: 'dynamicallocation_impressions' },
  { group: 'tr', content: 'Dynamic Allocation Predicted Impressions', value: 'dynamicallocation_predicted_impressions' },
  { group: 'tr', content: 'Dynamic Allocation Predicted Revenue', value: 'dynamicallocation_predicted_revenue' },
  { group: 'tr', content: 'Dynamic Allocation Revenue', value: 'dynamicallocation_revenue' },
  { group: 'tr', content: 'Dynamic Allocation Revenue With Forecast', value: 'dynamicallocation_revenue_with_forecast' },
  { group: 'tr', content: 'Dynamic Allocation Revenue With Forecast Client', value: 'dynamicallocation_revenue_with_forecast_client' },
  { group: 'tr', content: 'First Five Indicator', value: 'first_five_indicator' },
  { group: 'tr', content: 'Ay impressions', value: 'ayimpressions' },
  { group: 'tr', content: 'Line Item Revenue', value: 'lineitem_revenue' },
  { group: 'tr', content: 'Page Views', value: 'pageviews' },
  { group: 'tr', content: 'Prebid Lost Impressions', value: 'prebid_lost_impressions' },
  { group: 'tr', content: 'Prebid Lost Revenue', value: 'prebid_lost_revenue' },
  { group: 'tr', content: 'Prebid Revenue', value: 'prebid_revenue' },
  { group: 'tr', content: 'Prebid Won Impressions', value: 'prebid_won_impressions' },
  { group: 'tr', content: 'Prebid Won Revenue', value: 'prebid_won_revenue' },
  { group: 'tr', content: 'Session Starts', value: 'session_starts' },
  { group: 'tr', content: 'Sessions', value: 'sessions' },
  { group: 'tr', content: 'Viewable', value: 'viewable' },
  { group: 'tr', content: 'Nativo Revenue', value: 'nativo_revenue' },
  { group: 'tr', content: 'Nativo Impressions', value: 'nativo_impressions' },
  // { group: 'fb', content: 'Reach', value: 'facebook_reach' },
  // { group: 'fb', content: 'Comments', value: 'facebook_comment_action' },
  // { group: 'fb', content: 'Impressions', value: 'facebook_impressions' },
  // { group: 'fb', content: 'Inline Link Clicks', value: 'facebook_inline_link_clicks' },
  // { group: 'fb', content: 'Revenue', value: 'facebook_revenue' },
  // { group: 'fb', content: 'Likes', value: 'facebook_like_action' },
  // { group: 'fb', content: 'Link Clicks', value: 'facebook_link_click_action' },
  // { group: 'fb', content: 'App installs', value: 'facebook_mobile_app_install_action' },
  // { group: 'fb', content: 'Page Engagment', value: 'facebook_page_engagement_action' },
  // { group: 'fb', content: 'Post Engagment', value: 'facebook_post_engagement_action' },
  // { group: 'fb', content: 'Unique Inline Clicks', value: 'facebook_unique_inline_link_clicks' },
];
async function getCustomConversions() {
  const metrics = [];
  try {
    const [gaFields, ccFields] = await Promise.all([apiStore.rules.gaFields(), apiStore.rules.ccFields()]);
    if (gaFields) {
      gaFields.forEach(({ key, text }) => {
        metrics.push({ group: 'ga', content: text, value: key });
      });
    }
    if (ccFields) {
      ccFields.forEach(({ key, text }) => {
        metrics.push({ group: 'cc', content: text, value: key });
      });
    }
  } catch (e) {
    console.log(e);
  }
  return metrics;
}


const getCustomMetric = async () => {
  const customConversions = await getCustomConversions();
  return [...customMetric, ...customConversions];
};

export const getNativeCustomMetrics = async () => {
  const customMetrics = await getCustomConversions();
  const usedTrafficSources = await apiStore.trafficSources.accounts();
  const usedTrackers = await apiStore.trackers.accounts();
  const trafficSource = usedTrafficSources.map((ts) => ts.trafficSourceType.uniqueName);
  const tracker = usedTrackers.map((tr) => tr.type.uniqueName);
  const { fields, groups } = getFieldsAndFilters({
    tracker,
    trafficSource,
    useInCustomMetric: true,
    baseTypes: [RuleTypes.Conditions.BaseFieldType.Metric],
  });
  const mappedFields = fields.map((s) => ({
    group: s.group,
    content: s.text,
    value: s.key,
  }));
  return {
    fields: [...mappedFields, ...customMetrics],
    groups: groups.map((g) => ({
      value: g.value,
      content: g.label,
    })),
  };
};

export { getCustomMetric };

