export const doubleVerifyOptions = [
  {
    name: 'DoubleVerify (DV) - Adult & Sexual',
    value: 'Adult & Sexual',
  },
  {
    name: 'DoubleVerify (DV) - Ad Server',
    value: 'Ad Server',
  },
  {
    name: 'DoubleVerify (DV) - Alcohol',
    value: 'Alcohol',
  },
  {
    name: 'DoubleVerify (DV) - Aviation Disasters',
    value: 'Aviation Disasters',
  },
  {
    name: 'Alcohol_ContDoubleVerify (DV) - Celebrity Gossipent',
    value: 'Celebrity Gossipent',
  },
  {
    name: 'DoubleVerify (DV) - Crime',
    value: 'Crime',
  },
  {
    name: 'DoubleVerify (DV) - Cults and Survivalism',
    value: 'Cults and Survivalism',
  },
  {
    name: 'DoubleVerify (DV) - Drug Abuse',
    value: 'Drug Abuse',
  },
  {
    name: 'DoubleVerify (DV) - Gambling',
    value: 'Gambling',
  },
  {
    name: 'DoubleVerify (DV) - Hate Speech & Cyberbullying',
    value: 'Hate Speech & Cyberbullying',
  },
  {
    name: 'DoubleVerify (DV) - Human-made Disasters',
    value: 'Human-made Disasters',
  },
  {
    name: 'DoubleVerify (DV) - Incentivized/Malware/Clutter',
    value: 'Incentivized/Malware/Clutter',
  },
  {
    name: 'DoubleVerify (DV) - Inflammatory Politics & News',
    value: 'Inflammatory Politics & News',
  },
  {
    name: 'DoubleVerify (DV) - Natural Disasters',
    value: 'Natural Disasters',
  },
  {
    name: 'DoubleVerify (DV) - Negative News: Financial',
    value: 'Negative News: Financial',
  },
  {
    name: 'DoubleVerify (DV) - Negative News: Pharmaceutical',
    value: 'Negative News: Pharmaceutical',
  },
  {
    name: 'DoubleVerify (DV) - Non-Standard Content: Parking Page',
    value: 'Non-Standard Content: Parking Page',
  },
  {
    name: 'DoubleVerify (DV) - Occult',
    value: 'Occult',
  },
  {
    name: 'DoubleVerify (DV) - Piracy/Copyright Infringement',
    value: 'Piracy/Copyright Infringement',
  },
  {
    name: 'DoubleVerify (DV) - Profanity',
    value: 'Profanity',
  },
  {
    name: 'DoubleVerify (DV) - Terrorism',
    value: 'Terrorism',
  },
  {
    name: 'DoubleVerify (DV) - Tobacco & eCigarettes',
    value: 'Tobacco & eCigarettes',
  },
  {
    name: 'DoubleVerify (DV) - Vehicle Disasters',
    value: 'Vehicle Disasters',
  },
  {
    name: 'DoubleVerify (DV) - Unmoderated UGC: Forums, Images & Video',
    value: 'Unmoderated UGC: Forums, Images & Video',
  },
  {
    name: 'DoubleVerify (DV) - Violence',
    value: 'Violence',
  },
  {
    name: 'DoubleVerify (DV) - Death & Injuries',
    value: 'Death & Injuries',
  },
  {
    name: 'DoubleVerify (DV) - Extreme & Graphic',
    value: 'Extreme & Graphic',
  },
];

export const iasOptions = [
  {
    name: 'Integral Ad Science (IAS) - Adult Content',
    value: 'Adult Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Alcohol Content',
    value: 'Alcohol Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Drug Content',
    value: 'Drug Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Hate Speech Content',
    value: 'Hate Speech Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Illegal Download Content',
    value: 'Illegal Download Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Offensive Language Content',
    value: 'Offensive Language Content',
  },
  {
    name: 'Integral Ad Science (IAS) - Violence',
    value: 'Violence',
  },
];


export const bidStrategyTypes = [
  { text: 'Maximize Conversions (CPA)', value: 'MAX_CONVERSIONS' },
  { text: 'Bid Control (CPC/CPM)', value: 'BID_CONTROL' },
  { text: 'Target CPA', value: 'TARGET_CPA' },
];
