export default (F) => ({
  traffic_source_content_id: F.create({
    header: { content: 'Content ID' },
    item: {
      key: 'traffic_source_content_id',
      sortable: true,
      total: null,
    },
  }),
  headline: F.create({
    header: { content: 'Content Preview' },
    item: {
      key: 'headline',
      slot: 'content_preview',
      content: (item) => (item.headline.trim().length ? item.headline.trim() : item.traffic_source_content_id),
      sortable: true,
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--headline',
  }),
  tags: F.create({
    header: { content: 'Tags' },
    item: {
      total: null,
      slot: 'tags',
      key: 'tags',
    },
  }),
  status: F.create({
    header: { content: 'Status' },
    item: {
      slot: 'status',
      key: 'status',
      sortable: true,
    },
  }),
  'additional_fields.quality_ranking': F.create({
    header: { content: 'Quality Ranking' },
    item: {
      slot: 'additional_fields.quality_ranking',
      key: 'additional_fields.quality_ranking',
      total: null,
    },
  }),
  'additional_fields.conversion_ranking': F.create({
    header: { content: 'Conversion Ranking' },
    item: {
      slot: 'additional_fields.conversion_ranking',
      key: 'additional_fields.conversion_ranking',
      total: null,
    },
  }),
});
