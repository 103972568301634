/* eslint-disable */
const renderRows = (strArray = [], htmlClass = 'text-left') => strArray.map((str) => `<p class="m-0 ${htmlClass}" title="${str}">${str}</p>`).join('') || '-';

const itemChanged = (item, cb = () => null) => {
  const rows = [];
  const affectedFields = JSON.parse(item.itemAffectedFields);
  if (typeof affectedFields === 'object') {
    Object.entries(affectedFields).forEach(([key, field]) => {
      let uiKey = key.split(':');
      uiKey = uiKey[uiKey.length - 1];
      const cbResult = cb(field, key, uiKey);
      if (cbResult) {
        Array.isArray(cbResult) ? rows.push(...cbResult) : rows.push(cbResult);
      } else {
        let { before } = field;
        let { after } = field;
        try { if (typeof before.value !== 'undefined') before = before.value; } catch (err) { /* Do Nothing */ }
        try { if (typeof after.value !== 'undefined') after = after.value; } catch (err) { /* Do Nothing */ }
        rows.push(`${uiKey.toUpperCase()}: ${before} => ${after}`);
      }
    });
  }
  return renderRows(rows);
};

const itemChangedRollback = (item, cb = () => null) => {
  const rows = [];
  const affectedFields = JSON.parse(item.itemAffectedFields);
  if (typeof affectedFields === 'object') {
    Object.entries(affectedFields).forEach(([key, field]) => {
      let uiKey = key.split(':');
      uiKey = uiKey[uiKey.length - 1];
      const cbResult = cb(field, key, uiKey);
      if (cbResult) {
        Array.isArray(cbResult) ? rows.push(...cbResult) : rows.push(cbResult);
      } else {
        let { before } = field;
        let { after } = field;
        try { if (typeof before.value !== 'undefined') before = before.value; } catch (err) { /* Do Nothing */ }
        try { if (typeof after.value !== 'undefined') after = after.value; } catch (err) { /* Do Nothing */ }
        rows.push(`${uiKey.toUpperCase()}: ${after} => ${before}`);
      }
    });
  }
  return renderRows(rows);
};


const calculateTableStatus = (item) => {
  let affectedFields = JSON.parse(item.itemAffectedFields);
  let finished = false;
  while (!finished) {
    try {
      affectedFields = JSON.parse(affectedFields);
    } catch (e) {
      finished = true;
    }
  }
  if (item.error) {
    return 'Failed';
  }
  if (!typeof affectedFields === 'object') {
    return 'Failed';
  }
  if (Object.keys(affectedFields).length) {
    return 'Completed';
  }
  return 'No changes';
};

const logTemplates = {
  campaigns: [
    {
      header: 'Source Campaign ID',
      content: (item) => item.itemId,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  widgets: [
    {
      header: 'Widget ID',
      content: (item) => item.itemId,
    },
    {
      header: 'Widget Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  contents: [
    {
      header: 'Content Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Content Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  targets: [
    {
      header: 'Target Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Target Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  domains: [
    {
      header: 'Domain Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Domain Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  sections: [
    {
      header: 'Section Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Section Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  adgroups: [
    {
      header: 'AdGroup Id',
      content: (item) => item.itemId,
    },
    {
      header: 'AdGroup Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  spots: [
    {
      header: 'Spot Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Spot Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  exchanges: [
    {
      header: 'Exchange Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Exchange Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  sites: [
    {
      header: 'Site Id',
      content: (item) => item.itemId,
    },
    {
      header: 'Site Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Campaign Name',
      content: (item) => item.campaignName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
  default: [
    {
      header: 'ID',
      content: (item) => item.itemId,
    },
    {
      header: 'Name',
      content: (item) => item.itemName,
    },
    {
      header: 'Change',
      content: (item) => itemChanged(item),
      change: true,
    },
  ],
};

const getTemplate = (level) => {
  return logTemplates[level] || logTemplates.default;
};

export { getTemplate, itemChanged, itemChangedRollback, calculateTableStatus };
