<template>
  <div class="content-wrapper manual-connection-container">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Manual Connection</h1>
        <p class="section-header__content__subtitle"><a href="https://theoptimizer.io/help/knowledge-base/using-manual-connector" target="_blank">Learn More <i class="fa fa-angle-right ml-1"></i></a></p>
      </div>
    </div>
    <!-- <preloader v-if="preloader"></preloader> -->
    <div v-if="showPopup">
      <!--POPUP-->
      <row2>
        <div class="col-md-6 col-sm-12 ml-auto mr-auto text-center">
          <i class="fa fa-inbox" style="font-size: 50px; color: #333;"></i>
          <h5 style="padding-top: 15px;">Not enough data</h5>
          <p>You need to have at least one Traffic Source with a linked Tracker to show dashboard data</p>
          <button @click="$router.push({ name: 'AccountWizard' })" class="btn btn-secondary"><i class="fa fa-plus"></i> Account Wizard</button>
        </div>
      </row2>
    </div>
    <div v-else class="manual-connection-wrapper">
      <!--FILTERS-->
      <div class="manual-connection-filters optimizer-card p-4">
        <div class="row">
          <!-- Type -->
          <div :class="[selectedTrackerAccountItem && ['GoogleAnalytics4', 'GoogleAnalytics'].includes(selectedTrackerAccountItem.uniqueName) || selectedTrackerAccountItem.uniqueName === selectedTrackerAccountItem.uniqueName === 'AssertiveYield'? 'col-md-3' : 'col-md-3', 'col-sm-12']">
            <vue-opti-select-light
              class="optimizer-select"
              :options="types || []"
              unique-key="id"
              label-key="name"
              @change="(option) => {selectedType = option.id; $_setSelectedTsType(option);}"
              :value="selectedType"
              button-placeholder="Select Source"
              button-block
              single
            >
              <template #ITEM="{ option }">
                <img class="item-icon new-icon" :src="getTrafficSourceLogo(option.uniqueName)" :alt="option.uniqueName" :title="option.uniqueName" />
                <div class="item-name">
                  {{ option.name }}
                </div>
              </template>
            </vue-opti-select-light>
          </div>

          <!-- Account -->
          <div class="col-md-3 col-sm-12">
            <vue-opti-select-light
              class="optimizer-select"
              :options="(selectedTypeItem && selectedTypeItem.trafficSources) || []"
              :disabled="!selectedType"
              unique-key="id"
              label-key="name"
              @change="(option) => {selectedTrafficSourceAccount = option.id; $_setSelectedTsAccount(option);}"
              :value="selectedTrafficSourceAccount"
              button-placeholder="Select account"
              button-block
              searchable
              single
            >
              <template #ITEM="{ option }">
                <img class="item-icon new-icon" :src="getTrafficSourceLogo(selectedTypeItem.uniqueName)" :alt="selectedTypeItem.uniqueName" :title="selectedTypeItem.uniqueName" />
                <div class="item-name">
                  {{ option.name }}
                </div>
              </template>
            </vue-opti-select-light>
          </div>

          <!-- Tracker -->
          <div :class="[selectedTrackerAccountItem && ['GoogleAnalytics4', 'GoogleAnalytics'].includes(selectedTrackerAccountItem.uniqueName) || selectedTrackerAccountItem.uniqueName === 'AssertiveYield' ? 'col-md-2' : 'col-md-3', 'col-sm-12']">
            <vue-opti-select-light
              class="optimizer-select"
              :options="(selectedTrafficSourceAccountItem && selectedTrafficSourceAccountItem.trackers) || []"
              :disabled="!selectedTrafficSourceAccount"
              unique-key="id"
              label-key="name"
              @change="(option) => {selectedTrackerAccount = option.id; $_setSelectedTracker(option);}"
              :value="selectedTrackerAccount"
              button-placeholder="Select tracker"
              button-block
              searchable
              single
            >
              <template #ITEM="{ option }">
                <img class="item-icon" :src="option.name != 'ClickFlare' ? `/sh_static/accounts/${option.uniqueName}.png` : '/sh_static/accounts/ClickFlare.svg'" />
                <div class="item-name">
                  {{ option.name }}
                </div>
              </template>
            </vue-opti-select-light>
          </div>

          <!-- View Id -->
          <div v-if="selectedTrackerAccountItem && ['GoogleAnalytics4', 'GoogleAnalytics'].includes(selectedTrackerAccountItem.uniqueName) || selectedTrackerAccountItem.uniqueName ==='AssertiveYield' " class="col-md-2 col-sm-12">
            <vue-opti-select-light
              class="optimizer-select"
              :options="(selectedTrackerAccountItem && selectedTrackerAccountItem.variables) || []"
              unique-key="viewId"
              label-key="viewId"
              @change="(option) => {selectedViewId = option.viewId; $_setSelectedViewId(option);}"
              :value="selectedViewId"
              button-placeholder="Select ViewID"
              button-block
              single
            >
              <template #ITEM="{ option }">
                <div class="item-name">
                  {{ option.viewId }}
                </div>
              </template>
            </vue-opti-select-light>
          </div>

          <div :class="[selectedTrackerAccountItem && ['GoogleAnalytics4', 'GoogleAnalytics'].includes(selectedTrackerAccountItem.uniqueName) || selectedTrackerAccountItem.uniqueName ==='AssertiveYield' ? 'col-md-2' : 'col-md-3', 'col-sm-12']">
            <button v-if="searching" disabled class="primary-button w-100">
              <i class="fa fa-spinner fa-spin"></i>
              Loading
            </button>
            <button v-else class="primary-button w-100" @click="$_apiGetAllCampaigns">
              <i class="fa fa-search" aria-hidden="true"></i>
              Search
            </button>
          </div>
        </div>
      </div>

      <div class="manual-connection-link mb-4 pb-4">
        <div class="row">
          <div class="col-md-3">
            <div class="traffic-source-campaigns-container">
              <h4 class="manual-connection-heading">Traffic Source Campaigns</h4>
              <div class="optimizer-card">
                <p class="optimizer-card__title d-flex align-items-center">
                  <svg class="mr-3" width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M12.1839 0H0.574713C0.229885 0 0 0.227273 0 0.568182V9.43182C0 9.77273 0.229885 10 0.574713 10H12.1839C12.5287 10 12.7586 9.77273 12.7586 9.43182V0.568182C12.7586 0.227273 12.5287 0 12.1839 0ZM11.6092 8.86364H1.14943V1.13636H11.6092V8.86364Z" fill="#84A4F6" />
                      <path d="M34.4256 7.44336C34.0807 7.44336 33.8508 7.67063 33.8508 8.01154V8.86381H32.9888C32.6439 8.86381 32.4141 9.09109 32.4141 9.432C32.4141 9.7729 32.6439 10.0002 32.9888 10.0002H34.4256C34.7704 10.0002 35.0003 9.7729 35.0003 9.432V8.01154C35.0003 7.72745 34.7129 7.44336 34.4256 7.44336Z" fill="#84A4F6" />
                      <path d="M30.0565 8.86328H27.1255C26.7807 8.86328 26.5508 9.09055 26.5508 9.43146C26.5508 9.77237 26.7807 9.99964 27.1255 9.99964H30.0565C30.4014 9.99964 30.6312 9.77237 30.6312 9.43146C30.6312 9.09055 30.3439 8.86328 30.0565 8.86328Z" fill="#84A4F6" />
                      <path d="M24.1951 8.86381H23.333V8.01154C23.333 7.67063 23.1031 7.44336 22.7583 7.44336C22.4135 7.44336 22.1836 7.67063 22.1836 8.01154V9.432C22.1836 9.7729 22.4135 10.0002 22.7583 10.0002H24.1951C24.5399 10.0002 24.7698 9.7729 24.7698 9.432C24.7698 9.09109 24.5399 8.86381 24.1951 8.86381Z" fill="#84A4F6" />
                      <path d="M22.7583 6.591C23.1031 6.591 23.333 6.36373 23.333 6.02282V3.97736C23.333 3.63645 23.1031 3.40918 22.7583 3.40918C22.4135 3.40918 22.1836 3.63645 22.1836 3.97736V5.966C22.1836 6.30691 22.471 6.591 22.7583 6.591Z" fill="#84A4F6" />
                      <path d="M24.1951 0H22.7583C22.4135 0 22.1836 0.227273 22.1836 0.568182V1.98864C22.1836 2.32955 22.4135 2.55682 22.7583 2.55682C23.1031 2.55682 23.333 2.32955 23.333 1.98864V1.13636H24.1951C24.5399 1.13636 24.7698 0.909091 24.7698 0.568182C24.7698 0.227273 24.5399 0 24.1951 0Z" fill="#84A4F6" />
                      <path d="M30.0565 0H27.1255C26.7807 0 26.5508 0.227273 26.5508 0.568182C26.5508 0.909091 26.7807 1.13636 27.1255 1.13636H30.0565C30.4014 1.13636 30.6312 0.909091 30.6312 0.568182C30.6312 0.227273 30.3439 0 30.0565 0Z" fill="#84A4F6" />
                      <path d="M34.4256 0H32.9888C32.6439 0 32.4141 0.227273 32.4141 0.568182C32.4141 0.909091 32.6439 1.13636 32.9888 1.13636H33.8508V1.98864C33.8508 2.32955 34.0807 2.55682 34.4256 2.55682C34.7704 2.55682 35.0003 2.32955 35.0003 1.98864V0.568182C35.0003 0.227273 34.7129 0 34.4256 0Z" fill="#84A4F6" />
                      <path d="M34.4243 3.40918C34.0795 3.40918 33.8496 3.63645 33.8496 3.97736V5.966C33.8496 6.30691 34.0795 6.53418 34.4243 6.53418C34.7691 6.53418 34.999 6.30691 34.999 5.966V3.97736C34.999 3.69327 34.7117 3.40918 34.4243 3.40918Z" fill="#84A4F6" />
                      <path d="M20.4025 4.77308C20.345 4.71626 20.345 4.65945 20.2875 4.60263L18.8507 3.18217C18.6208 2.9549 18.276 2.9549 18.0461 3.18217C17.8162 3.40945 17.8162 3.75036 18.0461 3.97763L18.5059 4.43217H14.4829C14.1381 4.43217 13.9082 4.65945 13.9082 5.00036C13.9082 5.34126 14.1381 5.56854 14.4829 5.56854H18.5059L18.0461 6.02308C17.8162 6.25036 17.8162 6.59126 18.0461 6.81854C18.1611 6.93217 18.3335 6.98899 18.4484 6.98899C18.5634 6.98899 18.7358 6.93217 18.8507 6.81854L20.2875 5.39808C20.345 5.34126 20.4025 5.28445 20.4025 5.22763C20.4599 5.11399 20.4599 4.94354 20.4025 4.77308Z" fill="#84A4F6" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="35" height="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Drag campaigns from here</p>
                <div class="manual-connection-search">
                  <input type="text" v-model="searchSource" placeholder="Search campaigns">
                </div>
                <div class="campaigns-wrapper">
                  <draggable class="campaign-list" :list="$c_filteredSourceCampaigns" @start="drag = true" @end="drag = false" @change="(event) => $_changeList(event, 'sourceCampaigns')" v-bind="{ animation: 200, group: 'source', disabled: false, ghostClass: 'ghost' }">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <div class="campaign-card" v-for="(sourceCampaign) in $c_filteredSourceCampaigns" :key="sourceCampaign._uid">
                        <div class="campaign-card__body">
                          {{ sourceCampaign.name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="link-campaigns-container">
              <h4 class="manual-connection-heading">Link Campaigns by Putting them in Parallel Positions</h4>
              <div class="link-campaigns-wrapper">
                <div class="drop-campaigns-container optimizer-card p-4">
                  <div class="row no-gutters">
                    <div class="col-md-6 col-sm-12">
                      <div class="user-source-campaigns">
                        <draggable class="link-campaign-list" v-model="selectedSourceCampaigns" v-bind="{ group: 'source' }">
                          <div v-for="(selectedSourceCampaign, index) in selectedSourceCampaigns" :key="selectedSourceCampaign._uid" :class="[{'text-primary blue-border': index < selectedTrackerCampaigns.length || selectedSourceCampaigns.length < selectedTrackerCampaigns.length}, 'campaign-card']">
                            <div class="campaign-card__body">
                              {{ selectedSourceCampaign.name }}
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="user-tracker-campaigns">
                        <draggable class="link-campaign-list" v-model="selectedTrackerCampaigns" v-bind="{ group: 'tracker' }">
                          <div v-for="(selectedTrackerCampaign, index) in selectedTrackerCampaigns" :key="selectedTrackerCampaign._uid" :class="[{'text-primary blue-border': index < selectedSourceCampaigns.length || selectedSourceCampaigns.length > selectedTrackerCampaigns.length}, 'campaign-card']">
                            <div class="campaign-card__body">
                              <span>{{ selectedTrackerCampaign.tr_name }}</span>
                              <i class="fa fa-trash" title="Remove" @click="$_removeDelectedTrackerCampaigns(index)"></i>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="save-campaigns-container">
                  <button type="button" class="primary-button"
                          :disabled="!selectedSourceCampaigns.length || selectedSourceCampaigns.length !== selectedTrackerCampaigns.length"
                          @click="$_apiSaveLinkedCampaigns"
                  >
                    <i v-show="saveLoading" class="fa fa-spinner fa-spin"></i>
                    Save
                  </button>
                </div>

                <div v-if="userCampaigns.length" class="linked-campaigns-container optimizer-card p-4">
                  <div class="row user-campaign" v-for="(userCampaign, index) in userCampaigns" :key="userCampaign._uid">
                    <div class="col-md-5 col-sm-12">
                      <div class="campaign-card">
                        <div class="campaign-card__body">
                          {{ userCampaign.name }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12 text-center">
                      <button :id="userCampaign.name" class="btn btn-danger btn-sm">Unlink</button>
                      <b-popover :target="userCampaign.name"
                                 triggers="click"
                                 placement="top"
                                 ref="popover"
                                 title="Are you sure you want to delete this campaign?"
                      >
                        <div class="row justify-content-center">
                          <button class="btn btn-danger btn-sm" @click="$_deleteCampaign(userCampaign, index)">YES</button>
                          <button class="btn btn-default btn-sm" @click="$_closePopover()">NO</button>
                        </div>
                      </b-popover>
                      <p v-if="userCampaign.manual_connected === true" class="small-connected-text text-warning">Manual</p>
                      <p v-else class="small-connected-text text-info">Auto</p>
                    </div>
                    <div class="col-md-5 col-sm-12">
                      <div class="campaign-card">
                        <div class="campaign-card__body">
                          {{ $_getTrackerCampaignName(userCampaign) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="tracker-campaigns-container">
              <h4 class="manual-connection-heading">Tracker Campaigns</h4>
              <div class="optimizer-card">
                <p class="optimizer-card__title d-flex align-items-center">
                  <svg class="mr-3" width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M12.1839 0H0.574713C0.229885 0 0 0.227273 0 0.568182V9.43182C0 9.77273 0.229885 10 0.574713 10H12.1839C12.5287 10 12.7586 9.77273 12.7586 9.43182V0.568182C12.7586 0.227273 12.5287 0 12.1839 0ZM11.6092 8.86364H1.14943V1.13636H11.6092V8.86364Z" fill="#84A4F6" />
                      <path d="M34.4256 7.44336C34.0807 7.44336 33.8508 7.67063 33.8508 8.01154V8.86381H32.9888C32.6439 8.86381 32.4141 9.09109 32.4141 9.432C32.4141 9.7729 32.6439 10.0002 32.9888 10.0002H34.4256C34.7704 10.0002 35.0003 9.7729 35.0003 9.432V8.01154C35.0003 7.72745 34.7129 7.44336 34.4256 7.44336Z" fill="#84A4F6" />
                      <path d="M30.0565 8.86328H27.1255C26.7807 8.86328 26.5508 9.09055 26.5508 9.43146C26.5508 9.77237 26.7807 9.99964 27.1255 9.99964H30.0565C30.4014 9.99964 30.6312 9.77237 30.6312 9.43146C30.6312 9.09055 30.3439 8.86328 30.0565 8.86328Z" fill="#84A4F6" />
                      <path d="M24.1951 8.86381H23.333V8.01154C23.333 7.67063 23.1031 7.44336 22.7583 7.44336C22.4135 7.44336 22.1836 7.67063 22.1836 8.01154V9.432C22.1836 9.7729 22.4135 10.0002 22.7583 10.0002H24.1951C24.5399 10.0002 24.7698 9.7729 24.7698 9.432C24.7698 9.09109 24.5399 8.86381 24.1951 8.86381Z" fill="#84A4F6" />
                      <path d="M22.7583 6.591C23.1031 6.591 23.333 6.36373 23.333 6.02282V3.97736C23.333 3.63645 23.1031 3.40918 22.7583 3.40918C22.4135 3.40918 22.1836 3.63645 22.1836 3.97736V5.966C22.1836 6.30691 22.471 6.591 22.7583 6.591Z" fill="#84A4F6" />
                      <path d="M24.1951 0H22.7583C22.4135 0 22.1836 0.227273 22.1836 0.568182V1.98864C22.1836 2.32955 22.4135 2.55682 22.7583 2.55682C23.1031 2.55682 23.333 2.32955 23.333 1.98864V1.13636H24.1951C24.5399 1.13636 24.7698 0.909091 24.7698 0.568182C24.7698 0.227273 24.5399 0 24.1951 0Z" fill="#84A4F6" />
                      <path d="M30.0565 0H27.1255C26.7807 0 26.5508 0.227273 26.5508 0.568182C26.5508 0.909091 26.7807 1.13636 27.1255 1.13636H30.0565C30.4014 1.13636 30.6312 0.909091 30.6312 0.568182C30.6312 0.227273 30.3439 0 30.0565 0Z" fill="#84A4F6" />
                      <path d="M34.4256 0H32.9888C32.6439 0 32.4141 0.227273 32.4141 0.568182C32.4141 0.909091 32.6439 1.13636 32.9888 1.13636H33.8508V1.98864C33.8508 2.32955 34.0807 2.55682 34.4256 2.55682C34.7704 2.55682 35.0003 2.32955 35.0003 1.98864V0.568182C35.0003 0.227273 34.7129 0 34.4256 0Z" fill="#84A4F6" />
                      <path d="M34.4243 3.40918C34.0795 3.40918 33.8496 3.63645 33.8496 3.97736V5.966C33.8496 6.30691 34.0795 6.53418 34.4243 6.53418C34.7691 6.53418 34.999 6.30691 34.999 5.966V3.97736C34.999 3.69327 34.7117 3.40918 34.4243 3.40918Z" fill="#84A4F6" />
                      <path d="M20.4025 4.77308C20.345 4.71626 20.345 4.65945 20.2875 4.60263L18.8507 3.18217C18.6208 2.9549 18.276 2.9549 18.0461 3.18217C17.8162 3.40945 17.8162 3.75036 18.0461 3.97763L18.5059 4.43217H14.4829C14.1381 4.43217 13.9082 4.65945 13.9082 5.00036C13.9082 5.34126 14.1381 5.56854 14.4829 5.56854H18.5059L18.0461 6.02308C17.8162 6.25036 17.8162 6.59126 18.0461 6.81854C18.1611 6.93217 18.3335 6.98899 18.4484 6.98899C18.5634 6.98899 18.7358 6.93217 18.8507 6.81854L20.2875 5.39808C20.345 5.34126 20.4025 5.28445 20.4025 5.22763C20.4599 5.11399 20.4599 4.94354 20.4025 4.77308Z" fill="#84A4F6" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="35" height="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Drag campaigns from here</p>
                <div class="manual-connection-search">
                  <input type="text" v-model="searchTracker" placeholder="Search campaigns">
                </div>
                <div class="campaigns-wrapper">
                  <draggable class="campaign-list" :list="$c_filteredTrackerCampaigns" @start="drag = true" @end="drag = false" v-bind="{ animation: 200, group: { name: 'tracker', pull: 'clone', put: false }, ghostClass: 'ghost', clone: $_cloneTracker }">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <div class="campaign-card" v-for="(trackerCampaign) in $c_filteredTrackerCampaigns" :key="trackerCampaign._uid">
                        <div class="campaign-card__body">
                          {{ trackerCampaign.tr_name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="manual-connection-link">
        <div class="row">
          <div class="col-md-3 col-sm-12 manual-block">
            <div class="card">
              <div class="card-block">
                <p class="lead text-center">Traffic Source Campaigns</p>
                <p class="lead text-center text-info">Drag from Here <i class="fa fa-level-down" aria-hidden="true"></i></p>
                <div class="form-group">
                  <input type="text" class="form-control" v-model="searchSource" placeholder="Search campaigns">
                </div>
                <div class="campaign-wrapper">
                  <draggable class="campaign-list" :list="$c_filteredSourceCampaigns" @start="drag = true" @end="drag = false" @change="(event) => $_changeList(event, 'sourceCampaigns')" v-bind="{ animation: 200, group: 'source', disabled: false, ghostClass: 'ghost' }">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <div class="card" v-for="(sourceCampaign) in $c_filteredSourceCampaigns" :key="sourceCampaign._uid">
                        <div class="card-body" :class="{ 'text-fit': sourceCampaign.name.length > 33 }">
                          {{ sourceCampaign.name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 manual-block">
            <div class="card">
              <div class="card-block" style="overflow: auto; height: 610px">
                <p class="lead text-center">Link Campaigns by Putting them in Parallel Positions</p>
                <p class="lead text-center text-info">Drop Here <i class="fa fa-arrow-down" aria-hidden="true"></i></p>
                <div class="row campaign-wrapper-flex">
                  <div class="col-md-6 col-sm-12 user-source-campaigns">
                    <draggable class="dropzone" v-model="selectedSourceCampaigns" v-bind="{ group: 'source' }">
                      <div v-for="(selectedSourceCampaign, index) in selectedSourceCampaigns" :key="selectedSourceCampaign._uid" :class="[{'text-primary border-primary': index < selectedTrackerCampaigns.length || selectedSourceCampaigns.length < selectedTrackerCampaigns.length}, 'card']">
                        <div class="card-body" :class="{ 'text-fit': selectedSourceCampaign.name.length > 33 }">
                          {{ selectedSourceCampaign.name }}
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="col-md-6 col-sm-12 user-tracker-campaigns">
                    <draggable class="dropzone" v-model="selectedTrackerCampaigns" v-bind="{ group: 'tracker' }">
                      <div v-for="(selectedTrackerCampaign, index) in selectedTrackerCampaigns" :key="selectedTrackerCampaign._uid" :class="[{'text-primary border-primary': index < selectedSourceCampaigns.length || selectedSourceCampaigns.length > selectedTrackerCampaigns.length}, 'card']">
                        <div class="card-body" :class="{ 'text-fit': selectedTrackerCampaign.tr_name.length > 33 }">
                          {{ selectedTrackerCampaign.tr_name }} <b-btn title="Remove" @click="$_removeDelectedTrackerCampaigns(index)">X</b-btn>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
                <div class="row justify-content-center mt-2">
                  <div class="col-md-4">
                    <button type="button" class="btn btn-primary btn-block"
                            :disabled="!selectedSourceCampaigns.length || selectedSourceCampaigns.length !== selectedTrackerCampaigns.length"
                            @click="$_apiSaveLinkedCampaigns"
                    >
                      <i v-show="saveLoading" class="fa fa-spinner fa-spin"></i>
                      Save
                    </button>
                  </div>
                </div>
                <hr>
                <div class="row user-campaign" v-for="(userCampaign, index) in userCampaigns" :key="userCampaign._uid">
                  <div class="col-md-5 col-sm-12">
                    <div class="card text-white bg-primary">
                      <div class="card-body" :class="{ 'text-fit': userCampaign.name.length > 33 }">
                        {{ userCampaign.name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12 text-center">
                    <button :id="userCampaign.name" class="btn btn-danger btn-sm">UNLINK</button>
                    <b-popover :target="userCampaign.name"
                               triggers="click"
                               placement="top"
                               ref="popover"
                               title="Are you sure you want to delete this campaign?"
                    >
                      <div class="row justify-content-center">
                        <button class="btn btn-danger btn-sm" @click="$_deleteCampaign(userCampaign, index)">YES</button>
                        <button class="btn btn-default btn-sm" @click="$_closePopover()">NO</button>
                      </div>
                    </b-popover>
                    <br>
                    <p v-if="userCampaign.manual_connected === true" class="text-xl-center text-warning mt-2">Manual</p>
                    <p v-else class="text-xl-center text-info mt-2">Auto</p>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <div class="card text-white bg-primary">
                      <div class="card-body" :class="{ 'text-fit': userCampaign.tr_name.length > 33 }">
                        {{ userCampaign.tr_name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 manual-block">
            <div class="card">
              <div class="card-block">
                <p class="lead text-center">Tracker Campaigns</p>
                <p class="lead text-center text-info">Drag from Here <i class="fa fa-level-down" aria-hidden="true"></i></p>
                <div class="form-group">
                  <input type="text" class="form-control" v-model="searchTracker" placeholder="Search campaigns">
                </div>
                <div class="campaign-wrapper">
                  <draggable :list="$c_filteredTrackerCampaigns" @start="drag = true" @end="drag = false" v-bind="{ animation: 200, group: { name: 'tracker', pull: 'clone', put: false }, ghostClass: 'ghost', clone: $_cloneTracker }" class="campaign-list">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <div class="card" v-for="(trackerCampaign) in $c_filteredTrackerCampaigns" :key="trackerCampaign._uid">
                        <div class="card-body" :class="{ 'text-fit': trackerCampaign.tr_name.length > 33 }">
                          {{ trackerCampaign.tr_name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import draggable from 'vuedraggable';
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'ManualConnection',
  components: { draggable },
  mixins: [notifications],
  data() {
    return {
      showPopup: false,
      alertDismissed: false,
      types: [],
      selectedType: '',
      selectedTrafficSourceAccount: '',
      selectedTrackerAccount: '',
      selectedTypeItem: {},
      selectedTrafficSourceAccountItem: '',
      selectedTrackerAccountItem: '',
      selectedViewIdItem: '',
      selectedViewId: '',
      items: [],
      sourceCampaigns: [],
      trackerCampaigns: [],
      selectedSourceCampaigns: [],
      selectedTrackerCampaigns: [],
      searchSource: '',
      searchTracker: '',
      userCampaigns: [],
      saveLoading: false,
      searching: false,
      drag: false,
    };
  },
  computed: {
    // if tracker everflow, get only campaigns not already linked or linking soon to prevent duplicates
    $c_filteredTrackerCampaigns() {
      return (this.selectedTrackerAccountItem.uniqueName !== 'Everflow' || this.selectedTrackerAccountItem.uniqueName !== 'CustomTracker')
        ? this.trackerCampaigns.filter((trackerCampaign) => trackerCampaign.tr_name
          .toLowerCase()
          .includes(this.searchTracker.toLowerCase()))
        : this.trackerCampaigns.filter((trackerCampaign) => (
          trackerCampaign.tr_name
            .toLowerCase()
            .includes(this.searchTracker.toLowerCase())
          && !this.selectedTrackerCampaigns
            .map((sC) => sC.tracker_campaign_id)
            .includes(trackerCampaign.tracker_campaign_id)
          && !this.userCampaigns
            .map((linkedC) => linkedC.tracker_campaign_id)
            .includes(trackerCampaign.tracker_campaign_id)
        ));
    },
    $c_filteredSourceCampaigns() {
      return (this.selectedTrackerAccountItem.uniqueName !== 'Everflow' || this.selectedtrackeraccountitem.uniqueName !== 'CustomTracker')
        ? this.sourceCampaigns.filter((sourceCampaign) => sourceCampaign.name
          .toLowerCase()
          .includes(this.searchSource.toLowerCase()))
        : this.sourceCampaigns.filter(
          (sourceCampaign) => sourceCampaign.name
            .toLowerCase()
            .includes(this.searchSource.toLowerCase())
          && !this.userCampaigns
            .map((uC) => uC.traffic_source_campaign_id)
            .includes(sourceCampaign.traffic_source_campaign_id),
        );
    },
  },
  async created() {
    if (localStorage.manualConnectionAlert) {
      this.alertDismissed = true;
    }
    try {
      this.types = await this.$_apiGetAccounts();
      if (!this.types.length) {
        this.showPopup = true;
      }
      this.preloader = false;
    } catch (err) {
      this.$n_failNotification({ title: err });
    }
  },
  methods: {
    getTrafficSourceLogo,
    $_getTrackerCampaignName(userCampaign) {
      if (userCampaign.tr_name) {
        return userCampaign.tr_name;
      }
      if (this.trackerCampaigns.length) {
        const trName = this.trackerCampaigns.find((campaign) => campaign.tracker_campaign_id === userCampaign.tracker_campaign_id)?.tr_name;
        if (trName) {
          return trName;
        }
        if (userCampaign.traffic_source_campaign_id === userCampaign.tracker_campaign_id) {
          return 'Automatically Matched';
        }
        return 'Not Found';
      }
      return 'Not Found';
    },
    async $_apiGetAccounts() {
    // api calls
      let tsAccounts = await this.$apiStore.trafficSources.accounts();
      if (!tsAccounts.length) {
        return [];
      }
      let tsTypes = tsAccounts.filter((item) => item.status).map(({ type }) => type); // extract traffic source types
      tsTypes = tsTypes.filter((tsType, index) => index === tsTypes.findIndex((type) => type.id === tsType.id)); // filter them to remove duplicates
      // format tsAccounts
      tsAccounts = tsAccounts.map(({
        id, name, type, linkedTrackers,
      }) => {
        const trackers = linkedTrackers.filter((tracker) => tracker.status !== '0');
        return {
          name, id, typeId: type.id, trackers, typeUniqueName: type.uniqueName,
        };
      });

      // format tsTypes
      tsTypes = tsTypes.map(({
        id, name, optimizerType, uniqueName, url,
      }) => ({
        id, name, optimizerType, uniqueName, url, trafficSources: tsAccounts.filter((tsAccount) => tsAccount.typeId === id),
      }));
      // update state
      return tsTypes.filter((tsType) => tsType.trafficSources.length);
    },

    async $_apiGetAllCampaigns() {
      if (['Tonic', 'Sedo', 'System1', 'DomainActive', 'Bodis', 'GoogleAnalytics4', 'Ads'].includes(this.selectedTrackerAccountItem.uniqueName)) {
        return this.$n_failNotification({ title: 'Manual connection is not supported for this tracker' });
      }
      this.searching = true;

      this.selectedSourceCampaigns = [];
      this.selectedTrackerCampaigns = [];
      this.userCampaigns = [];

      try {
        const isGa = this.selectedTrackerAccountItem && (this.selectedTrackerAccountItem.uniqueName === 'GoogleAnalytics' || this.selectedTrackerAccountItem.uniqueName === 'AssertiveYield');
        const response = isGa ? await this.$api.manualConnection.getGaCampaigns(this.selectedTrafficSourceAccount, this.selectedTrackerAccount, this.selectedViewId) : await this.$api.manualConnection.getAllCampaigns(this.selectedTrafficSourceAccount, this.selectedTrackerAccount);

        this.sourceCampaigns = response.sourceCampaigns.map((item, index) => ({ ...item, _uid: `sourceCampaigns-${index}` }));
        this.trackerCampaigns = response.trackerCampaigns.map((item, index) => ({ ...item, _uid: `trackerCampaigns-${index}` }));
        this.userCampaigns = response.userCampaigns.map((item, index) => ({ ...item, _uid: `userCampaigns-${index}` }));

        this.searching = false;

        this.$n_successNotification({ title: 'Campaigns loaded.', timeout: 4000 });
      } catch (err) {
        this.searching = false;
        this.$n_failNotification({ title: `${err} Please Refresh Page`, timeout: 10000 });
      }
    },

    async $_apiSaveLinkedCampaigns() {
    // Disable button
      event.preventDefault();
      event.target.disabled = true;
      this.saveLoading = true;
      // Decalare final object to be send in the request
      const allLinkedCampaigns = [];

      // Get source and tracker accounts info for campaign validation
      const accounts = {
        trafficSourceId: this.selectedTrafficSourceAccount,
        trackerId: this.selectedTrackerAccount,
        sourceTypeName: this.selectedTrafficSourceAccountItem.name,
        sourceTypeUniqueName: this.selectedTrafficSourceAccountItem.typeUniqueName,
        trackerTypeName: this.selectedTrackerAccountItem.uniqueName,
      };

      /*  Reindex selectedSourceCampaigns to remove duplicate indexes
       *  with selectedTrackerCampaigns and add neccessary ts data
       */
      const reindexSelectedSourceCampaigns = this.selectedSourceCampaigns.map((sourceCampaign) => ({
        id: sourceCampaign.id,
        name: sourceCampaign.name,
        manual_connected: true,
        traffic_source_campaign_id: sourceCampaign.traffic_source_campaign_id,
      }));

      /*  Reindex selectedTrackerCampaigns to remove duplicate indexes
    **  with selectedSourceCampaigns and add neccessary tr data
    */
      const reindexSelectedTrackerCampaigns = this.selectedTrackerCampaigns.map((trackerCampaign) => {
        const reindexTrackerCampaing = {
          tracker_campaign_id: trackerCampaign.tracker_campaign_id,
          tracker_unique_name: this.selectedTrackerAccountItem.uniqueName,
          tracker_account_id: parseInt(this.selectedTrackerAccount, 10),
          tr_name: trackerCampaign.tr_name,
        };

        return reindexTrackerCampaing;
      });

      // Merge the reindexed objects into one by matching indexes
      reindexSelectedSourceCampaigns.forEach((reindexSourceCampaign, index) => {
        allLinkedCampaigns.push({ ...reindexSourceCampaign, ...reindexSelectedTrackerCampaigns[index] });
      });

      // API call
      try {
        await this.$api.manualConnection.saveLinkedCampaigns(allLinkedCampaigns, accounts, this.selectedViewId || undefined);
        // on success add saved campaigns to user campaigns
        allLinkedCampaigns.forEach((linkedCampaign) => {
          const campaign = this.selectedSourceCampaigns.find((cmp) => cmp.id === linkedCampaign.id);
          this.userCampaigns.unshift({ ...campaign, ...linkedCampaign });
        });
        // remove saved campaigngs from selected campaigns
        this.selectedSourceCampaigns = [];
        this.selectedTrackerCampaigns = [];

        // alert user
        this.$n_successNotification({ title: 'Campaigns linked to tracker successfully', timeout: 3000 });
        // Enable button
        this.saveLoading = false;
      } catch (err) {
        this.$n_failNotification({ title: err });
        // Enable button
        this.saveLoading = false;
      }
    },

    async $_deleteCampaign(userCampaign) {
      event.preventDefault();
      event.target.disabled = true;
      try {
        await this.$api.manualConnection.deleteCampaign(userCampaign.id, this.selectedViewId || undefined);
        // add object to source campaign list
        this.sourceCampaigns.unshift(userCampaign);
        // this.sourceCampaigns.unshift({
        //   id: userCampaign.id,
        //   name: userCampaign.name,
        // });
        // remove object from user campaigns
        this.userCampaigns = this.userCampaigns.filter((userCmp) => userCmp._uid !== userCampaign._uid);
        this.$n_successNotification({ title: 'Campaign unlinked from tracker successfully', timeout: 2000 });
      } catch (err) {
        this.$n_failNotification({ title: err });
      }
      event.target.disabled = false;
    },

    $_setSelectedTsType(selectedTsType) {
      this.selectedTypeItem = selectedTsType;
      this.selectedTrafficSourceAccount = '';
      this.$_setSelectedTsAccount('');
    },

    $_setSelectedTsAccount(selectedTsAccount) {
      this.selectedTrafficSourceAccountItem = selectedTsAccount;
      this.selectedTrackerAccount = '';
      this.$_setSelectedTracker('');
    },

    $_setSelectedTracker(selectedTracker) {
      this.selectedTrackerAccountItem = selectedTracker;
      this.selectedViewId = '';
      this.$_setSelectedViewId('');
    },

    $_setSelectedViewId(selectedViewId) {
      this.selectedViewIdItem = selectedViewId;
    },

    $_changeList(event, listKey) {
      if (event.added) {
        const item = event.added.element;
        this[listKey].unshift(item);
      } else if (event.removed) {
        const item = event.removed.element;
        this[listKey] = this[listKey].filter((campaign) => campaign._uid !== item._uid);
      }
    },

    $_closePopover() {
      this.$root.$emit('bv::hide::popover');
    },

    $_cloneTracker(item) {
      if (!this.$options.trackerCloneCounter) this.$options.trackerCloneCounter = 0;
      this.$options.trackerCloneCounter++;
      return { ...item, clone: true, _uid: `${item._uid}-${this.$options.trackerCloneCounter}` };
    },

    $_removeDelectedTrackerCampaigns(index) {
      this.selectedTrackerCampaigns.splice(index, 1);
    },

  },
};
</script>

<style lang="scss">
  .manual-connection-container {
    .section-header {
      margin-bottom: 1.5rem;
    }

    .manual-connection-wrapper {
      .manual-connection-filters {
        margin-bottom: 4rem;
      }

      .manual-connection-heading {
        font-size: 1.8rem;
        font-weight: bold;
        color: $text-primary;
        margin-bottom: 1rem;
        text-align: center;
      }

      .optimizer-card {
        padding: 1.5rem;

        &__title {
          font-size: 1.5rem;
          color: #84A4F7;
          margin-bottom: 1rem;

          svg {
            margin-top: -.1rem;
          }
        }

        .manual-connection-search {
          position: relative;
          margin-bottom: 2rem;

          input {
            border: .1rem solid #E5E5E5;
            height: 3.5rem;
            border-radius: .6rem;
            padding-left: 3.2rem;
            font-size: 1.4rem;
            width: 100%;
            color: $text-primary;

            &::placeholder {
              color: $black-200;
            }
          }

          &::after {
            content: '\f002';
            font-family: "FontAwesome";
            position: absolute;
            left: 1rem;
            top: .5rem;
            color: $black-400;
          }
        }

        .campaigns-wrapper {
          .campaign-list {
            overflow-y: auto;
            padding: 0 1rem 0 0;

            .campaign-card {
              height: 5rem;
              display: flex;
              align-items: center;
              background: $color-white;
              border: .1rem solid #e5e5e5;
              border-radius: .6rem;
              padding: 0 1rem;
              margin-bottom: 1rem;
              cursor: grab;

              &.ghost {
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
              }

              &__body {
                font-size: 1.3rem;
                color: $text-primary;
              }
            }
          }
        }
      }

      .link-campaigns-container {
        .link-campaigns-wrapper {
          border-radius: 1rem;

          .campaign-card {
            height: 5rem;
            display: flex;
            align-items: center;
            background: $color-white;
            border: .1rem solid #e5e5e5;
            border-radius: .6rem;
            padding: 0 1rem;
            margin-bottom: 1rem;
            cursor: grab;

            &.ghost {
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            }

            &.blue-border {
              border-color: #87a9ff !important;
            }

            &__body {
              font-size: 1.3rem;
              color: $text-primary;
            }
          }

          .drop-campaigns-container {
            border-radius: 1rem;
            margin-bottom: 2rem;

            .user-source-campaigns {
              border-top-left-radius: 1rem;
              border-bottom-left-radius: 1rem;
            }

            .user-tracker-campaigns {
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
            }

            .user-source-campaigns,
            .user-tracker-campaigns {
              background: rgba(231, 232, 235, 0.4);
              padding: 1rem;
              height: 100%;

              .link-campaign-list {
                min-height: 20rem;
                height: 100%;
              }

              .campaign-card {
                position: relative;
                height: 5rem;
                display: flex;
                align-items: center;
                background: $color-white;
                border: .1rem solid #e5e5e5;
                border-radius: .6rem;
                padding: 0 1rem;
                margin-bottom: 1rem;
                cursor: grab;

                &:hover {
                  i.fa-trash {
                    display: inline-block;
                  }
                }

                &.ghost {
                  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
                }

                &__body {
                  font-size: 1.3rem;
                  color: $text-primary;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .fa-trash {
                    display: none;
                    position: absolute;
                    right: 1rem;
                    top: 1.2rem;
                    padding: 0 .5rem .7rem .3rem;
                    line-height: 1.2;
                    color: #ff6868;
                    font-size: 1.6rem;
                    border-radius: 50%;
                    background: #eeeeee;
                    padding: .3rem .5rem;
                    cursor: pointer;

                    &:hover {
                      color: #ff4848;
                      background: #e5e5e5;
                    }
                  }
                }
              }
            }
          }

          .save-campaigns-container {
            text-align: center;
            margin-bottom: 2rem;

            .primary-button {
              min-width: 25rem;

              &[disabled] {
                background: cornflowerblue !important;
                border-color: cornflowerblue !important;
                cursor: not-allowed;
              }
            }
          }

          .linked-campaigns-container {
            .user-campaign {
              .btn {
                font-size: 1.2rem;
                padding: .4rem 1.5rem;
                width: 100%;
                background: #ff6161;
                border-color: #ff6161;
                margin-top: .4rem;
                margin-bottom: .2rem;

                &:hover {
                  background: #fa4848;
                  border-color: #fa4848;
                }
              }

              .small-connected-text {
                font-size: 1.2rem;
              }

              &:last-of-type {
                .campaign-card {
                  margin-bottom: 0;
                }
              }
            }
          }

        }
      }
    }
  }
</style>

<style scoped>
  @media only screen and (min-width : 1224px) {
    .text-fit {
    padding: 8px 0 3px 8px !important;
    }
  }

  @media only screen  and (min-width : 1824px) {
    .text-fit {
      padding: 15px 0 3px 10px !important;
    }
  }

  .campaign-list {
    min-height: 350px;
    max-height: 450px;
    position: relative;
  }

  /* .vddl-list {
    min-height: 200px;
    position: relative;
    overflow: auto;
    padding-bottom: 50px;
    padding-top: 10px;
  }

  .vddl-dragging{
    opacity: 1.2;
  }

  .vddl-dragging-source {
    display: none;
  } */

  .manual-block {
    padding-right: 0px !important;
  }

  .manual-block-select {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .card-block {
    padding: 1rem;
    font-size: 0.8rem;
    line-height: 1.2;
    margin-right: 3px;
  }

  .card-body {
    height: 45px;
    padding: 15px 0 3px 10px;
  }

  .alert-hidden {
    display: none;
  }

  .campaign-wrapper {
    overflow: auto;
    height: 450px;
    max-height: 450px;

  }

  .campaign-wrapper-flex {
    min-height: 150px;
    height: auto;
  }

  .user-campaign {
    position: relative;
  }

  .btn-badge {
    position: absolute;
    left: 43%;
    z-index: 10001;
  }

  .user-source-campaigns {
    border-style: dashed;
    border-color: lightgrey;
    border-width: 2px;
  }

  .user-tracker-campaigns {
    border-style: dashed;
    border-color: lightgrey;
    border-width: 2px;
    border-left-width: 0px;
  }
  .dropzone {
    width: 100%;
    height: 100%;
  }
</style>

<style>

  .dropdown-icon {
    width: 20px;
    display: inline;
  }

  .dropdown-label {
    display: inline;
    padding-left: 10px;
  }
</style>
