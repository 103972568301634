import moment from 'moment';
import CampaignModel from '../../lib/CampaignModel';

/**
 * API Model: {
 *  name: <String>,
 *  content_type: <String|noncommercial|advertorial|advertisement>,
 *  campaign_start: <String|y-m-d>,
 *  campaign_end: <String|y-m-d>,
 *  tier_targeting: <String|all|tier1|tier2>, // Deprecated
 *  cpc_desktop: <Number|0>,
 *  cpc_tablet: <Number|0>,
 *  cpc_mobile: <Number|0>,
 *  cap_day: <Number|0>,
 *  cap_total: <Number|0>,
 *  country_targeting: <Array|[<String|tier1|tier2|tier3>]>,
 *  country_codes: <Array|[<String>]>,
 *  device_os: <Array|[<String>]>,
 *  browser_types: <Array|[<String>]>,
 *  tracking_params: <String>
 * }
 */
export default class ContentAdCampaignModel extends CampaignModel {
  async setModel(form, nextTick, mode) {
    this.components.name.value = mode === 'clone' ? `${form.name} CLONE` : form.name;
    this.components.content_type.value = form.content_type;
    if (mode !== 'clone') {
      this.components.campaign_start.value = form.campaign_start;
      this.components.campaign_end.value = form.campaign_end;
    }
    // this.components.tier_targeting.value = form.tier_targeting
    this.components.cpc_model.value = [];
    if (form.cpc_desktop) {
      this.components.cpc_model.value.push('cpc_desktop');
      this.components.cpc_desktop.value = form.cpc_desktop;
    }
    // if (form.cpc_tablet) {
    //   this.components.cpc_model.value.push('cpc_tablet')
    //   this.components.cpc_tablet.value = form.cpc_tablet
    // }
    if (form.cpc_mobile) {
      this.components.cpc_model.value.push('cpc_mobile');
      this.components.cpc_mobile.value = form.cpc_mobile;
    }
    this.components.cap_day.value = form.cap_day || null;
    this.components.cap_total.value = form.cap_total || null;

    // Country Model/Targeting/Codes
    if (form.country_targeting || form.country_codes) {
      // const countryTargetingMap = {};
      // form.country_targeting.forEach((tier) => { countryTargetingMap[tier] = true; });
      // this.components.country_targeting.options.forEach(({ value }) => { countryTargetingMap[value] = true })
      const allSelected = this.components.country_targeting.options.length === (form.country_targeting || []).length && (form.country_codes || []).length === 0;
      if (allSelected) {
        this.components.countries_model.value = 'all';
      } else {
        this.components.countries_model.value = 'only';
        // Targeting

        // Countries
        if (form.country_codes && form.country_codes.length) {
          form.country_targeting = 'tier1';
          // this.components.countries_model.value = 'only'
          const countriesMap = {};
          form.country_codes.forEach((code) => { countriesMap[code] = true; });
          this.components.country_codes.value = this.components.country_codes.options.filter(({ id }) => countriesMap[id]);
        }
        this.components.country_targeting.value = this.components.country_targeting.options.filter(({ value }) => value === form.country_targeting);
      }
    }

    // Operating Systems
    if (form.device_os && form.device_os.length) {
      this.components.device_os.value = form.device_os;
    } else {
      this.components.device_os.value = this.components.device_os.options.map(({ value }) => value);
    }
    // Browsers
    if (form.browser_types && form.browser_types.length) {
      this.components.browser_types.value = form.browser_types;
    } else {
      this.components.browser_types.value = this.components.browser_types.options.map(({ value }) => value);
    }
    this.components.tracking_params.value = form.tracking_params || '';
    if (mode === 'edit') {
      this.components.content_type.disabled = true;
      // this.components.tier_targeting.disabled = true
    }
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const form = {
      // campaignCreatorV2: true,
      name: modelForm.name,
      content_type: modelForm.content_type,
      campaign_start: modelForm.campaign_start,
      campaign_end: modelForm.campaign_end,
      // tier_targeting: modelForm.tier_targeting,
      cpc_desktop: modelForm.cpc_desktop || 0,
      // cpc_tablet: modelForm.cpc_tablet || 0,
      cpc_mobile: modelForm.cpc_mobile || 0,
      cap_day: modelForm.cap_day || 0,
      cap_total: modelForm.cap_total || 0,
    };
    if (modelForm.countries_model === 'all') {
      form.country_targeting = this.components.country_targeting.options.map(({ value }) => value);
      // form.country_codes = this.components.country_codes.options.map(({ id }) => id);
    } else if ((modelForm.country_codes || []).length) {
      // form.country_targeting = modelForm.country_targeting.map(({ value }) => value);
      form.country_codes = modelForm.country_codes.map(({ id }) => id);
    } else {
      form.country_targeting = modelForm.country_targeting.map(({ value }) => value);
    }
    if (modelForm.device_os.length && modelForm.device_os.length !== this.components.device_os.options.length) {
      form.device_os = modelForm.device_os;
    }
    if (modelForm.browser_types.length && modelForm.browser_types.length !== this.components.browser_types.options.length) {
      form.browser_types = modelForm.browser_types;
    }
    if (modelForm.tracking_params) form.tracking_params = modelForm.tracking_params;
    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      contentTypeOptions: [
        { text: 'Noncommercial', value: 'noncommercial' },
        { text: 'Advertorials', value: 'advertorial' },
        { text: 'Advertisements', value: 'advertisement' },
      ],
      tierTargetingOptions: [
        { text: 'Entire Network', value: 'all' },
        { text: 'Tier 1 only', value: 'tier1' },
        { text: 'Tier 2 only', value: 'tier2' },
      ],
      cpcOptions: [
        { text: 'Desktop', value: 'cpc_desktop' },
        // { text: 'Tablet', value: 'cpc_tablet' },
        { text: 'Mobile', value: 'cpc_mobile' },
      ],
      countriesModelOptions: [
        { text: 'All', value: 'all' },
        { text: 'Specific Tier', value: 'only' },
      ],
      countriesTargetingOptions: [
        { text: 'Tier1', value: 'tier1' },
        { text: 'Tier2', value: 'tier2' },
        { text: 'Tier3', value: 'tier3' },
      ],
      countriesOptions: [
        { id: 'AU', name: 'Australia' },
        { id: 'CA', name: 'Canada' },
        { id: 'IE', name: 'Ireland' },
        { id: 'NZ', name: 'New Zealand' },
        { id: 'ZA', name: 'South Africa' },
        { id: 'CH', name: 'Switzerland' },
        { id: 'GB', name: 'United Kingdom' },
        { id: 'US', name: 'United States' },
      ],
      deviceOsOptions: [
        { text: 'Android', value: 'android' },
        { text: 'iOS', value: 'ios' },
        { text: 'Other', value: 'other' },
      ],
      browserTypesOptions: [
        { text: 'Chrome', value: 'chrome' },
        { text: 'Safari', value: 'safari' },
        { text: 'Other', value: 'other' },
      ],
    };
  }

  __generatePopOver() {
    return {
      contentTypePopover: {
        content: 'You can only have one type of content per campaign. If you have mixed content, you\'ll need to break it out by campaign.',
        html: true,
      },
      schedulingPopover: {
        content: 'Optional. All scheduled campaigns will start and end at midnight Pacific Time.',
        html: true,
      },
      // tierTargetingPopover: {
      //   content: `Sites in Tier 1 represent our more trusted publishing partners, but they're more competitive.`,
      //   html: true
      // },
      capDayPopover: {
        content: 'Your ads will stop serving when budget is reached. You will be charged for clicks on ads that were served before the budget was reached. Leave blank for open.',
        html: true,
      },
      capTotalPopover: {
        content: 'Leave blank for open budget.',
        html: true,
      },
      trackingParamsPopover: {
        content: `Added to the end of all links in this campaign.
        Example: utm_source=contentad&utm_campaign=Cars_CPC5`,
        html: true,
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      name: { active: true, component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required' },
      content_type: { active: true, component: 'cc-radio-button', value: 'noncommercial', label: 'Content Type', 'v-validate': 'required', options: this.options.contentTypeOptions, popover: this.popover.contentTypePopover },
      scheduling_wrapper: { active: true, label: 'Flighting/Scheduling', popover: this.popover.schedulingPopover },
      campaign_start: { active: true, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date' },
      campaign_end: { active: true, component: 'cc-date-picker', value: '', label: 'End Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select End Date' },
      // tier_targeting: { active: true, component: 'cc-radio-button', value: 'all', label: 'Network Targeting', 'v-validate': 'required', options: this.options.tierTargetingOptions, popover: this.popover.tierTargetingPopover },
      cpc_model: { active: true, component: 'cc-checkbox', value: ['cpc_desktop', 'cpc_mobile'], label: 'CPC Bid', 'v-validate': 'required', options: this.options.cpcOptions },
      cpc_desktop: { active: true, component: 'cc-input', value: '', label: 'Cpc Desktop', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Desktop', right: '$' },
      // cpc_tablet: { active: true, component: 'cc-input', value: '', label: 'Cpc Tablet', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Tablet', right: '$' },
      cpc_mobile: { active: true, component: 'cc-input', value: '', label: 'Cpc Mobile', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Mobile', right: '$' },
      cap_day: { active: true, component: 'cc-input', value: '', label: 'Max. daily budget', 'v-validate': 'min_value:100', placeholder: 'Open', right: '$', popover: this.popover.capDayPopover },
      cap_total: { active: true, component: 'cc-input', value: '', label: 'Max. total budget', 'v-validate': 'min_value:100', placeholder: 'Open', right: '$', popover: this.popover.capTotalPopover },
      countries_model: { active: true, component: 'cc-radio-button', value: 'all', label: 'Countries', 'v-validate': 'required', options: this.options.countriesModelOptions },
      country_targeting: { active: false, component: 'cc-multi-select', value: [], label: 'Countries', disableLabel: true, 'v-validate': 'required', options: this.options.countriesTargetingOptions, optionLabel: 'text', placeholder: 'Select Tiers' },
      country_codes: { active: false, component: 'cc-multi-select', value: [], label: 'Countries', disableLabel: true, options: this.options.countriesOptions, optionLabel: 'name', flagLabel: 'id', placeholder: 'Select Countries', type: 'countries' },
      device_os: { active: true, component: 'cc-checkbox', value: ['android', 'ios', 'other'], label: 'Mobile Operating Systems', 'v-validate': 'required', options: this.options.deviceOsOptions },
      browser_types: { active: true, component: 'cc-checkbox', value: ['chrome', 'safari', 'other'], label: 'Browsers', 'v-validate': 'required', options: this.options.browserTypesOptions },
      tracking_params: { active: true, component: 'cc-textarea', value: '', label: 'Tracking parameters', placeholder: 'Tracking code', rows: 3, popover: this.popover.trackingParamsPopover },
    };

    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      // Start Date <=> End Date // Logic
      campaign_start: {
        fn: (value) => {
          const startDate = value;
          const endDate = _this.components.campaign_end.value;
          if (startDate) {
            _this.components.campaign_end.disabledDate.to = new Date(startDate);
            if (endDate && moment(startDate) > moment(endDate)) {
              _this.components.campaign_end.value = '';
            }
          } else {
            _this.components.campaign_end.disabledDate.to = _this.data.today;
          }
        },
      },
      countries_model: {
        fn: (value) => {
          if (value === 'all') {
            _this.components.country_targeting.active = false;
            _this.components.country_targeting.value = [];
          } else {
            _this.components.country_targeting.active = true;
          }
        },
      },
      country_targeting: {
        fn: (options) => {
          if (options.find(({ value }) => value === 'tier1')) {
            _this.components.country_codes.active = true;
          } else {
            _this.components.country_codes.active = false;
            _this.components.country_codes.value = [];
          }
        },
      },
      cpc_model: {
        fn: (value) => {
          const deviceMap = { cpc_desktop: false, cpc_mobile: false };
          value.forEach((device) => { deviceMap[device] = true; });
          Object.entries(deviceMap).forEach(([key, value]) => {
            _this.components[key].active = value;
            if (!value) _this.components[key].value = '';
          });
        },
      },
    };
  }
}
