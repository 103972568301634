<template>
  <b-form :ref="getScope()" :data-vv-scope="getScope()" @submit.prevent @keyup.enter="$_handleOk">
    <b-modal
      id="createGroupModal"
      modal-class="optimizer-modal"
      title="Create New Group"
      @show="$_clear"
      @shown="$refs._name.$el.focus()"
      centered
      ref="createGroupModal"
    >

      <!-- Modal Header -->
      <template slot="modal-header">
        <h2 class="modal-header__title">Create New Group</h2>

        <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
          <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        </svg>
      </template>

      <b-form-group
        label="Name:"
        class="optimizer-form-group"
        :state="hasError('_name')"
        :invalid-feedback="getError('_name')"
      >
        <b-form-input
          ref="_name"
          class="optimizer-form-input"
          data-vv-as="Group Name"
          v-validate="'required'"
          data-vv-validate-on="input"
          autocomplete="false"
          type="text"
          v-model="name"
          name="_name"
          placeholder="Enter Group Name"
        >
        </b-form-input>
      </b-form-group>

      <!-- Modal Footer -->
      <template slot="modal-footer">
        <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
        <b-btn class="primary-button" @click="$_handleOk">Add</b-btn>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
const scope = 'modalNewRuleGroupForm';
export default {
  name: 'NewGroupModal',
  data() {
    return {
      name: '',
    };
  },
  methods: {
    show() {
      this.$refs.createGroupModal.show();
    },
    hide() {
      this.$refs.createGroupModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.getScope());
      if (isValid) {
        this.$emit('newGroup', this.name);
        this.$_clear();
        this.hide();
      }
    },
    $_clear() {
      this.name = '';
      this.$validator.reset(this.getScope());
    },
    getScope() {
      return scope;
    },
    hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>
