export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const SCHEDULEOPTIONS = {
  rules: [
    {
      type: [],
      day: 'Monday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Tuesday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Wednesday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Thursday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Friday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Saturday',
      from_hour: '',
      until_hour: '',
    },
    {
      type: [],
      day: 'Sunday',
      from_hour: '',
      until_hour: '',
    },
  ],

};
