<template>
  <b-modal
    modal-class="optimizer-modal"
    size="lg"
    ok-title="Add Email"
    @ok="$_addAccount"
    v-model="modal"
    centered
    @hidden="$_resetForm"
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Add email account</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div class="row mb-3">
      <!-- Account Name -->
      <div class="col-md-6">
        <b-form-group
          class="optimizer-form-group"
          label="Name"
          :invalid-feedback="$_getError('name')"
          :state="$_hasError('name')"
        >
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Account Name"
            :state="$_hasError('name')"
            v-validate="'required'"
            type="text"
            v-model="form.name"
            name="name"
            key="name"
            placeholder="Account Name"
          />
        </b-form-group>
      </div>

      <!-- Email -->
      <div class="col-md-6">
        <b-form-group
          class="optimizer-form-group"
          label="Email"
          :invalid-feedback="$_getError('email')"
          :state="$_hasError('name')"
        >
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Email"
            :state="$_hasError('email')"
            v-validate="{ required: true, email: true, excluded: $c_accountsEmail }"
            type="text"
            v-model="form.email"
            name="email"
            key="email"
            placeholder="Email"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        Rule Executed <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.RE('Email'), boundary: 'window' }"></i>
        <switch-button
          v-model="form.notification.RE"
          :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
        />
      </div>
      <div class="col">
        Account Issues <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AI('Email'), boundary: 'window' }"></i>
        <switch-button
          :checked="form.notification.AI"
          :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
          :update="$_updateAI"
        />
      </div>
      <div class="col">
        Smart Notifications <i class="fa fa-info-circle" v-b-tooltip="{ hover: true, html: true, title: $c_config.notificationFieldsInfoMap.AN('Email'), boundary: 'window' }"></i>
        <switch-button
          v-model="form.notification.AN"
          :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
        />
      </div>
    </div>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn class="primary-button" @click="$_addAccount">Add Email</b-btn>
    </template>

  </b-modal>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'EmailModal',
  mixins: [notifications],
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      modal: false,
      form: {
        email: '',
        isRoot: false,
        isActive: true,
        name: '',
        notification: {},
      },
    };
  },
  computed: {
    $c_config() {
      return this.config.notification.model;
    },
    $c_accountsEmail() {
      return this.$c_config.settingsModel.channels.email.accounts.map(({ email }) => email);
    },
  },
  created() {
    this.form.notification = this.$c_config.getDefaultNotification();
  },
  methods: {
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    async $_addAccount(event) {
      event.preventDefault();
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          this.$emit('add', JSON.parse(JSON.stringify(this.form)));
          this.hide();
        } else {
          this.$n_warnNotification({ title: 'Invalid form' });
        }
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_updateAI(value) {
      this.form.notification.AI = value;
      this.form.notification.DA = value;
      this.form.notification.MAT = value;
    },
    $_resetForm() {
      this.form = {
        email: '',
        isRoot: false,
        isActive: true,
        name: '',
        notification: this.$c_config.getDefaultNotification(),
      };
    },
  },
};
</script>

<style lang="scss">
.email-modal {

}
</style>
