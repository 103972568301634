<template>
  <div class="content-wrapper trackers-container">

    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center mb-4">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Trackers</h1>
      </div>
    </div>

    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: false, search: true }"
    />

    <div v-else class="optimizer-table trackers-table">
      <vue-opti-table-light
        v-if="table.items.length >= 0"
        name="trackerAccountsTable"
        class="table-wrapper"
        :hover="true"
        :header-fields="table.fields"
        :items="table.items"
        v-model="tableModel"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        :sort="{ key: 'status', order: 'desc' }"
        :default-rows="100"
        sticky
      >
        <template #name="{ item }">
          {{ item.name }}
        </template>
        <template #status="{ item }">
          <span v-if="item.status === 1" class="status-item status-item-enabled"><span class="circle" /> Enabled</span>
          <span v-else-if="item.status === 0" class="status-item status-item-disabled"><span class="circle" /> Disabled</span>
          <span v-else-if="item.status === -1" class="status-item status-item-deleted"><span class="circle" /> Deleted</span>
        </template>
        <template #trackerType="{ item }">
          <div class="acc-item">
            <img class="acc-item-icon" :src="item.type.uniqueName != 'ClickFlare' ? '/sh_static/accounts/'+item.type.uniqueName+'.png' : '/sh_static/accounts/ClickFlare.svg'" />
            <div class="acc-item-name">{{ item.type.name }}</div>
          </div>
        </template>
        <!-- <template #trackerType="{ item }">
          <span :class="$_iconClass(item.type.uniqueName)">{{ item.type.name }}</span>
        </template> -->
        <template #actions="{ item }">
          <div class="d-flex justify-content-center">
            <switch-button
              :checked="item.status"
              :enabled-value="1"
              :disabled-value="0"
              :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
              :update="(value) => $_changeStatus(item, value)"
            />
            <action-btn type="edit" :click="() => $_editAccount(item)" />
          </div>
        </template>
      </vue-opti-table-light>
      <br>
      <h4 v-if="!table || !table.items || !table.items.length" class="lead">
        <a style="color: #252D41">You do not have any tracker integrated yet. To start adding your integrations go to <a v-if="docUrl" @click="docUrl" class="accountWizardUrl" style="color: #4158D0"><b>Account Wizard.</b></a></a>
      </h4>
    </div>
    <TrackerModal ref="refTrackerModal" @submit="$_updateAccount" />
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import configFile from '@sh/components/Trackers/config';
import TrackerModal from '@sh/components/Trackers/TrackerModal';

export default {
  name: 'Trackers',
  components: { TrackerModal },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      config: null,
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      resizedColumns: {},
      table: {
        fields: [
          {
            header: { content: 'Id', style: '' },
            display: true,
            item: {
              key: 'id',
              sortable: true,
              content: (item) => item.id,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Actions', style: 'width: 1%' },
            item: {
              key: 'actions',
              slot: 'actions',
              style: { textAlign: 'center', padding: '.5rem 1.5rem' },
            },
            colStyle: { width: '80px' },
          },
          {
            header: { content: 'Account Name', style: '' },
            display: true,
            item: {
              key: 'name',
              slot: 'name',
              cellClass: 'cell-name',
              sortable: true,
              searchable: true,
              content: (item) => item.name,
            },
          },
          {
            header: { content: 'Status', style: '' },
            display: true,
            item: {
              key: 'status',
              slot: 'status',
              sortable: true,
              content: (item) => item.status,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Tracking Platform', style: '' },
            display: true,
            item: {
              key: 'type.name',
              slot: 'trackerType',
              sortable: true,
              searchable: true,
              content: (item) => item.type.name,
            },
          },
        ],
        items: [],
      },
    };
  },
  async created() {
    /** ***************** Load Config **************** */
    this.config = await configFile.getConfig();
    /** *********************************************** */

    await this.load();
  },
  methods: {
    async load() {
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('trackers');
      /** ************************************************************************* */

      try {
        this.table.items = await this.config.api.trackers.accounts();
      } catch (error) {
        console.log(error);
        this.$n_failNotification({ title: 'Error loading accounts' });
      }
      this.handleEditAccountRequest();
      this.preloader = false;
    },
    $_iconClass(uniqueName) {
      return `my-icon my-${uniqueName.toLowerCase()}`;
    },
    async $_changeStatus(item, status) {
      try {
        const response = await this.config.api.trackers.updateStatus(item.id, status);
        if (response.status === 1) {
          this.$n_successNotification({ title: 'Account enabled' });
        } else {
          this.$n_successNotification({ title: 'Account disabled' });
        }
        item.status = status;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          this.$n_failNotification({ title: 'Update failed' });
        }
      }
    },
    docUrl() {
      this.$router.push({ path: 'account-wizard' });
    },
    // async $_delete(item) {
    //   try {
    //     const swal = await this.$swal({
    //       title: `Delete "${item.name}" account ?`,
    //       type: 'warning',
    //       showCancelButton: true,
    //       confirmButtonText: 'Yes, delete it!',
    //       cancelButtonText: 'No, keep it',
    //     });
    //     if (swal.value === true) {
    //       this.preloader = true;
    //       try {
    //         const response = await this.config.api.trackers.delete(item.id);
    //         this.$n_successNotification({ title: response.message });
    //         this.load();
    //       } catch (error) {
    //         const noticeData = {
    //           title: 'Deleting failed',
    //         };
    //         if (error.response && typeof error.response.message !== 'undefined') noticeData.message = error.response.message;
    //         this.$n_failNotification(noticeData);
    //       }
    //       this.preloader = false;
    //     }
    //   } catch (value) {
    //     //
    //   }
    // },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('trackers', false, payload);
    },
    $_editAccount(item) {
      this.$refs.refTrackerModal.showModal({ action: 'edit', account: item });
    },
    $_updateAccount(account) {
      if (account) {
        const tableItem = this.table.items.find((item) => item.id === account.id);
        if (tableItem) Object.assign(tableItem, account);
      }
    },
    handleEditAccountRequest() {
      const editAccount = this.table.items.find((item) => item.id?.toString() === (this.$route.query.editAccount ?? ''));
      this.$router.replace({ editAccount: undefined });

      if (editAccount) {
        this.$_editAccount(editAccount);
      }
    },
  },
};
</script>

<style lang="scss">
.trackers-container {
  .table-wrapper {
    td.cell-name a {
      color: #20a8d8;
      text-decoration: none;
      display: inline-block;

      &:hover {
        text-decoration: underline !important;
      }
    }
    td.cell-name:hover a {
      color: #167495;
    }
    .column-header {
      th {
        padding: .8rem;
      }
    }

    .acc-item {
      &-icon {
        width: 2rem;
        margin-right: 0.5rem;
        display: inline-block;
      }
      &-name {
        display: inline-block;
      }
    }
  }

  .optimizer-table {
    .table-holder {
      border: $border;
    }
  }
}

  .accountWizardUrl {
      :hover {
          text-decoration: underline !important;
          color: #4158D0;
        }
      }
  .lead {
      font-size: 17px;
      font-family: 'Roboto', sans-serif;
    }

  .my-icon {
  display: inline-block;
  line-height: 13px;

  &:before {
    display: inline-block;
    height: 16px;
    margin-bottom: -3px;
    content: '';
    margin-right: 2px;
    background: no-repeat url('/sh_static/img/logos/logo_icons-min-2.png');
  }

  &.my-revcontent {
    &:before{
      width: 16px;
      background-size: 43px;
      background-position-y: -46px;
      background-position-x: 0;
    }
  }

  &.my-contentad {
    &:before{
      width: 16px;
      background-size: 42px;
      background-position-y: 0;
      background-position-x: 0;
      height: 22px;
      margin-bottom: -9px;
    }
  }

  &.my-taboola{
    &:before{
      width: 16px;
      background-size: 45px;
      background-position-y: -70px;
      background-position-x: 0;
    }
  }

  &.my-gemini{
    &:before{
      width: 16px;
      background-size: 45px;
      background-position-y: -119px;
      background-position-x: 0;
    }
  }

  &.my-mgid{
    &:before{
      width: 16px;
      background-size: 44px;
      background-position-y: -26px;
      background-position-x: 0;
    }
  }

  &.my-outbrain{
    &:before{
      width: 16px;
      background-size: 44px;
      background-position-y: -93px;
      background-position-x: 0;
    }
  }

  &.my-thrive{
    &:before{
      width: 16px;
      background-size: 42px;
      background-position-y: -25px;
      background-position-x: -26px;
    }
  }

  &.my-voluum{
    &:before{
      width: 16px;
      background-size: 45px;
      background-position-y: -49px;
      background-position-x: -29px;
    }
  }

  &.my-voluumdsp{
    &:before{
      width: 16px;
      background-size: 45px;
      background-position-y: -49px;
      background-position-x: -29px;
    }
  }

  &.my-imobitrax{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: 0px;
      background-position-x: -24px;
    }
  }

  &.my-adsbridge{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: -53px;
      background-position-x: -22px;
    }
  }

  &.my-binom{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: -74px;
      background-position-x: -22px;
    }
  }

  &.my-funnelflux{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: -90px;
      background-position-x: -22px;
    }
  }

  &.my-googleanalytics{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: -126px;
      background-position-x: -22px;
    }
  }

  &.my-adskeeper{
    &:before{
      width: 16px;
      background-size: 44px;
      background-position-y: -139px;
      background-position-x: 0;
    }
  }

  &.my-activerevenue{
    &:before{
      width: 16px;
      background-size: 44px;
      background-position-y: -139px;
      background-position-x: 0;
    }
  }

  &.my-runative{
    &:before{
      width: 16px;
      background-size: 44px;
      background-position-y: -160px;
      background-position-x: 0;
    }
  }

  &.my-bemob{
    &:before{
      width: 16px;
      background-size: 35px;
      background-position-y: -108px;
      background-position-x: -22px;
    }
  }
}

.optimizer-table {
  .table-holder {
    // box-shadow: $bs-md;2
    margin-bottom: 1.5rem;
  }

}

.section-title {
  font-size: 2.4rem;
  font-weight: bold;
  color: $color-primary;
  font-family: 'Inter', sans-serif;
  margin-top: 1rem;
}

.datatable-search-field {
  margin-right: 0 !important;
}

</style>
