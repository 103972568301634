import { Campaign } from '@/views/Tools/Reporting/types/Campaign';
import { LoadingMessage } from '@sh/helpers';
import { TrafficSource } from '@sh/types';

export default class CampaignFilter {
  isLoading = false;

  constructor(
    public availableOptions: Partial<Record<TrafficSource, Campaign[]>>,
    public trafficSource?: TrafficSource,
    public accountIds?: number[],
    private selected: string[] | undefined = []
  ) {}

  get options(): Campaign[] {
    const options = this.availableOptions;

    if (this.trafficSource) {
      return (
        options[this.trafficSource]?.filter(
          (item) =>
            !this.accountIds?.length ||
            this.accountIds.find((selectedAccount) => selectedAccount === item.traffic_source_account_id)
        ) ?? []
      );
    }
    return [];
  }

  get selectedOptions(): Campaign[] {
    const options = this.options.filter((option) => this.value?.includes(option.id));
    return options.length === this.options.length ? [] : options;
  }

  set selectedOptions(options: Campaign[]) {
    this.selected = options.map((option) => option.id);
  }

  get value(): string[] | undefined {
    return this.selected?.length ? this.selected : undefined;
  }

  set value(value: string[] | undefined) {
    this.selected = value;
  }

  get selectedText(): number | 'All' {
    const totalSelectedCampaigns = this.value?.length ?? 0;

    if (!totalSelectedCampaigns || totalSelectedCampaigns === this.options?.length) {
      return 'All';
    }
    return totalSelectedCampaigns;
  }

  disabledText(errorMessage: string): string {
    if (this.isLoading) {
      return LoadingMessage;
    }

    if (this.options?.length === 0) {
      if (this.trafficSource) {
        return 'There are no campaigns available for selected traffic source.';
      }
      return 'Please start by choosing the traffic source type.';
    }

    return errorMessage;
  }
}
