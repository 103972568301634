<template>
  <!-- <div class="smart-list-modal-wrapper"> -->
  <b-modal
    v-model="modal"
    modal-class="optimizer-modal smart-list-modal"
    :title="$c_title"
    size="lg"
    centered
    :no-close-on-esc="submitting"
    :no-close-on-backdrop="submitting"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">{{ $c_title }}</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <smart-list
      v-if="modal"
      ref="smartListRef"
      :mode="mode"
      :default-traffic-source-type="defaultTrafficSourceType"
      :default-widgets-id="defaultWidgetsId"
      :default-campaigns-id="defaultCampaignsId"
    />

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="primary-button" @click="$_save"><i class="fa fa-check" /> Save</b-btn>
    </template>

  </b-modal>
  <!-- </div> -->
</template>

<script>
import SmartList from '@/components/smartlist/SmartList';

export default {
  name: 'SmartListModal',
  components: { SmartList },
  props: {
    mode: { type: String, required: true },
    defaultTrafficSourceType: { type: [String, null], default: null },
    defaultWidgetsId: { type: Array, default: () => [] },
    defaultCampaignsId: { type: Array, default: () => [] },
  },
  data() {
    return {
      modal: false,
      submitting: false,
    };
  },
  computed: {
    $c_title() {
      let title = '';
      if (this.mode === 'create') {
        title = 'Create New';
      } else if (this.mode === 'edit') {
        title = 'Edit';
      } else if (this.mode === 'clone') {
        title = 'Clone';
      }
      return `${title} Smart List`;
    },
  },
  methods: {
    async $_save(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitting = true;
      try {
        if (await this.$refs.smartListRef.submit()) this.modal = false;
      } catch (error) {
        // TODO
        console.log(error);
      }
      this.submitting = false;
    },
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.smart-list-modal {

  .new-list-wrapper {
    padding: 0;
    box-shadow: none;
  }

  .modal-dialog {
    max-width: 990px;
  }
  .tooltip {
    z-index: 99999;
  }
}
</style>
