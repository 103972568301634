import { FilterDto } from '@sh/types';
import { defineStore } from 'pinia';

interface ComponentData {
  contentsFilter: FilterDto[];
  widgetsModelFilter: string;
  campaignsStatusFilter: FilterDto[];
}

export const useAppStore = defineStore('app', {
  state: (): ComponentData => ({
    contentsFilter: [],
    widgetsModelFilter: 'all',
    campaignsStatusFilter: [],
  }),
});
