export interface Filter {
  name: string;
  operator: string;
  value: number | string;
}

export interface Row {
  metricType: DropDown;
  operator: Operator;
  value: string;
  valid?: boolean;
}

export interface Operator {
  id: number;
  name: string;
}

export interface DropDown {
  field: keyof Metrics;
  name: string;
  symbol: SYMBOL;
  displayName?: string;
}

export enum SYMBOL {
  MONEY = 'MONEY',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  BYTES = 'BYTES',
  PX = 'PX',
  NONE = '',
}

export interface Metrics {
  traffic_source_item_clicks?: string;
  traffic_source_item_impressions?: string;
  traffic_source_item_revenue?: number;
  traffic_source_item_spend?: number;
  traffic_source_item_ctr?: number;
  traffic_source_item_epc?: number;
}

export const metricsOrder: Array<keyof Metrics> = [
  'traffic_source_item_spend',
  'traffic_source_item_ctr',
  'traffic_source_item_epc',
  'traffic_source_item_revenue',
  'traffic_source_item_clicks',
  'traffic_source_item_impressions',
];
