<template>
  <div class="old-rule-bid-options-wrapper">
    <div :class="view">
      <b-form-group :state="$_hasError(name)" class="col-lg-8 action-group-wrapper">
        <b-input-group>
          <b-input-group-addon v-if="checkbox" class="state-addon">
            <b-form-checkbox v-model="form.status" @change="$_removeError(name); $_removeError(name)"></b-form-checkbox>
          </b-input-group-addon>
          <vue-opti-select-light
            class="optimizer-select dropdown-auto-width set-bid-type"
            :value="form.action"
            @change="(option) => { form.action = option.value; form.to = ''; form.unit = $c_unitOptions[0].value; }"
            :options="$c_actionOptions"
            label-key="text"
            :disabled="!form.status && checkbox"
            single
            button-block
          >
            <template #ITEM="{option}">
              <span v-b-tooltip.hover.top :title="option.tooltip">{{ option.text }}</span>
            </template>
          </vue-opti-select-light>
          <b-form-input
            class="optimizer-form-input"
            :disabled="$c_disableValue"
            type="number"
            @blur.native="$_validateLimits"
            v-model="form.value"
            :step="$c_step"
            :min="bid.limits.min"
            :name="name"
            v-validate="['required', ...$c_action['v-validate'] || []].join('|')"
          ></b-form-input>
          <b-input-group-addon>
            <vue-opti-select-light
              class="optimizer-select set-bid-unit"
              :value="form.unit"
              @change="(option) => { form.unit = option.value; form.to = ''; }"
              :options="$c_unitOptions"
              label-key="text"
              :disabled="!form.status && checkbox"
              single
              button-block
            />
          </b-input-group-addon>
        </b-input-group>
        <small slot="description" class="hint-text" v-show="$c_showHint && form.unit === 'static' && form.value">Equivalent to {{ $c_toCent }} ¢ ( US cent )</small>
        <small slot="invalid-feedback">{{ $_getError(name) }}</small>
      </b-form-group>
      <b-form-group v-if="form.unit === 'percentage' && enableRuleBidOptions" :state="$_hasError('toOptions')" class="col-md-6 col-md-auto rule-bid-to-option-wrapper" :style="view === 'column' ? { marginTop: '1rem' } : {}">
        <b-input-group>
          <vue-opti-select-light
            class="optimizer-select"
            name="toOptions"
            v-validate="'required'"
            data-vv-as="Option"
            data-vv-validate-on="input|update"
            data-vv-value-path="$c_model"
            :value="form.to"
            @change="(option) => { $set(form, 'to', option.value) }"
            :options="$c_toOptions"
            label-key="text"
            button-placeholder="-- Select Option"
            single
            :searchable="true"
            button-block
          >
            <template #ITEM="{option}">
              <field-tooltip :option="option" :info="option.description" />
            </template>
          </vue-opti-select-light>
          <b-input-group-append v-if="toOptions.length && $c_toOptionsTooltip">
            <i class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="$c_toOptionsTooltip"></i>
          </b-input-group-append>
        </b-input-group>
        <small slot="invalid-feedback">{{ $_getError('toOptions') }}</small>
      </b-form-group>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { isEqual } from 'lodash';
import FieldTooltip from '../../../../components/Utils/FieldTooltip.vue';

export default {
  name: 'RuleBidOptions',
  components: { FieldTooltip },
  props: {
    value: {
      type: Object,
      default: () => ({
        action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
      }),
    },
    template: { type: Object, default: () => {} },
    actionOptions: { type: Array, default: () => [] },
    toOptions: { type: Array, default: () => [] },
    toViewOptions: { type: Array, default: () => [] },
    unitOptions: { type: Array, default: () => [] },
    bid: { type: Object, required: true },
    excludeCampaignLevelFields: { type: Array, default: () => [] },
    ruleLevel: { type: String, required: false },
    name: { type: String, default: 'Bid' },
    view: { type: String, default: 'row' },
    checkbox: { type: Boolean, default: false },
    enableRuleBidOptions: { type: Boolean, default: true },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    $c_step() {
      return this.form.unit === 'static' ? this.bid.limits.step : '0.01';
    },
    $c_showHint() {
      return this.bid.show_cent_hint ? this.bid.show_cent_hint : false;
    },
    $c_toCent() {
      return (this.form.value * 100).toFixed(1);
    },
    $c_actionOptions() {
      const option = this.actionOptions.filter((item) => this.template.action.hasOwnProperty(item.value));
      return option;
    },
    $c_disableValue() {
      return (!this.form.status && this.checkbox);
    },
    $c_toOptions() {
      // this options are when applying bulk update bid/budget in campaigns & other entities
      if (this.toViewOptions.length > 0) {
        if (this.ruleLevel?.toLowerCase().includes('campaign')) {
          return this.toViewOptions.filter((field) => !field.value.includes(this.excludeCampaignLevelFields));
        }
        return this.toViewOptions;
      }
      let option;
      if (this.template.action[this.form.action].unit[this.form.unit] && this.template.action[this.form.action].unit[this.form.unit].to) {
        option = this.toOptions.filter((item) => this.template.action[this.form.action].unit.percentage.to.hasOwnProperty(item.value));
        if (this.ruleLevel?.toLowerCase().includes('campaign')) {
          option = option.filter((field) => !field.value.includes(this.excludeCampaignLevelFields));
        }
        // option.unshift({ value: '', text: '--Select option', disabled: true });
      }
      return option;
    },
    $c_action() {
      return this.template.action[this.form.action];
    },
    $c_unitOptions() {
      const option = this.unitOptions.filter((item) => this.template.action[this.form.action].unit.hasOwnProperty(item.value));
      return option;
    },
    $c_toOptionsTooltip() {
      try {
        return this.template.action[this.form.action].unit[this.form.unit].to[this.form.to].info;
      } catch (error) {
        return null;
      }
      // return (this.template.action[this.form.action].unit[this.form.unit] &&
      // this.template.action[this.form.action].unit[this.form.unit].to[this.form.to] &&
      // this.template.action[this.form.action].unit[this.form.unit].to[this.form.to].info)
      //   ? this.template.action[this.form.action].unit[this.form.unit].to[this.form.to].info
      //   : null
    },
  },
  watch: {
    form: {
      // immediate: true,
      handler() {
        this.$_emit();
      },
      deep: true,
    },
    value: {
      immediate: true,
      handler() {
        if (!isEqual(this.form, this.value)) {
          this.form = this.value;
        }
      },
      deep: true,
    },
  },
  methods: {
    async validate() {
      try {
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
    $_emit() {
      const form = { ...this.form };
      if (form.value) form.value = `${parseFloat(form.value)}`;
      // min, max, minObject, maxObject will have the same value
      ['min', 'max'].forEach((key) => {
        if (form[`${key}Object`] && form[`${key}Object`].value) {
          form[key] = `${parseFloat(form[`${key}Object`].value)}`;
        } else if (form[key]) {
          form[key] = `${parseFloat(form[key])}`;
        }
      });
      ['minObject', 'maxObject'].forEach((key) => {
        if (form[key] && form[key].value) form[key].value = `${parseFloat(form[key].value)}`;
      });
      this.$emit('input', form);
    },
    $_validateLimits() {
      if (this.form.unit === 'static' && parseFloat(this.form.value) >= this.bid.limits.max) this.$emit('max-limit-exhausted', this.model);
    },
    resetValue() {
      this.form.value = '';
    },
  },
};
</script>

<style lang="scss">
.old-rule-bid-options-wrapper {
  .action-group-wrapper {
    margin-bottom: 0;
    .optimizer-select {
      .dropdown-toggle {
        background: #F4F9FC;

        &::after {
          margin-left: 1rem;
        }
      }

      &.set-bid-type {
        min-width: 15rem;

        .dropdown-toggle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.set-bid-unit {
        min-width: 7rem;

        .dropdown-toggle {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }

  .rule-bid-to-option-wrapper {
    margin-bottom: 0;
    .input-group {
      .optimizer-select {
        flex: 1;
      }

      &-append {
        color: $black-400;
        margin-left: 1rem;
        display: flex;
        align-items: center;
      }
    }

  }

  .action-group-wrapper {
    .custom-checkbox {
      height: 3.7rem;
      width: 3.7rem;
      background: #F4F9FC;
      border: 0.1rem solid $color-light-gray;
      border-radius: .6rem;
      margin-right: 1rem;

      .custom-control-label {
        display: flex;
        width: 100%;
        padding: 1rem 1rem 1rem 4rem;
        cursor: pointer;

        &::before {
          height: 1.5rem;
          width: 1.5rem;
          left: -.5rem;
          top: 1rem;
          box-shadow: none;
        }

        &::after {
          height: 1.5rem;
          width: 1.5rem;
          left: -.5rem;
          top: 1rem;
        }
      }
    }

    // input.form-control {
    //   text-align: center;
    //   border-right-width: 0 !important;
    //   border-left-width: 0 !important;
    //   &[disabled] {
    //     background-color: #f5f5f5 !important;
    //     cursor: not-allowed;
    //   }
    // }
    .hint-text {
      color: #3c3c3c;
      font-size: 90%;
    }
  }
}
</style>
