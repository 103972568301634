<template>
  <div class="clipboard-container">
    <loading-skeleton
      v-if="trackingUrlLoaded"
      class="optimizer-skeleton__actions__search w-100"
      type="line"
    />
    <div v-else class="clipboard">
      <div class="optimizer-form-input clipboard-url">
        {{ url }}
      </div>
      <button @click="copyToClipboard(url)">
        <i v-if="isCopyAnimation" class="fa fa-check text-success" />
        <i v-else class="fa fa-clipboard" />
      </button>
    </div>
    <div class="tracking-info" v-if="specificTrackers.includes(item.uniqueName) && specificTrackersParams.length > 0 && mode === 'TrafficSources'">
      <i v-b-tooltip.hover title="In order for the integration to work properly, please make sure that all your campaigns or Ad URLs include this tracking code." class="fa fa-info-circle field-info"></i>
    </div>
  </div>
</template>

<script>
import each from 'each.js';

export default {
  name: 'TrackingUrl',
  props: {
    trackers: { type: Array, default: () => [] },
    tracker: { type: Object, default: () => {} },
    item: { type: Object, default: () => {} },
    mode: { type: String, default: 'TrafficSources' },
  },
  data() {
    return {
      specificTrackersParams: [],
      specificTrackers: ['ClickFlare', 'Voluum'],
      trafficSourceTokens: [],
      trackingUrlLoaded: true,
      isCopyAnimation: false,
    };
  },
  computed: {
    url() {
      return this.buildUrlForSpecificTrackers(this.item.sourceIdOnTracker);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.trafficSourceTokens = await this.$apiStore.trafficSources?.trackingUrlTokens();
      const trackerIds = [];
      if (this.trackers?.length > 0) {
        const ids = this.trackers.filter((item) => this.specificTrackers.includes(item.uniqueName)).map((item) => item.id);
        trackerIds.push(...ids);
      } else {
        trackerIds.push(this.tracker.id);
      }
      const response = [];
      await each.parallel(trackerIds, async (trackerId) => {
        const request = await this.$api.trackers.sources(trackerId);
        response.push(...request.results);
      });
      response.forEach((item) => {
        this.specificTrackersParams.push({
          id: item.id,
          params: item.forFrontEnd,
        });
      });
      this.trackingUrlLoaded = false;
    },
    copyToClipboard(text) {
      this.isCopyAnimation = true;
      navigator.clipboard.writeText(text);

      setTimeout(() => {
        this.isCopyAnimation = false;
      }, 1000);
    },
    matchTrackerToTs(sourceIdOnTracker) {
      const matched = this.specificTrackersParams.find((item) => item.id === sourceIdOnTracker)?.params || {};
      if (Object.keys(matched).length) {
        const filterParams = Object.fromEntries(
          Object.entries(matched).filter(
            (t) => !!t.find((token) => this.trafficSourceTokens.includes(token)),
          ),
        );
        return filterParams;
      }
    },
    buildUrlForSpecificTrackers(id) {
      const variables = this.matchTrackerToTs(id) || {};
      const url = [];
      const params = Object.keys(variables);

      Object.values(variables).forEach((item, index) => {
        if (item.length) {
          url.push(`${params[index]}=${item}`);
        }
      });
      return url.join('&') || 'Unable to generate tracking url!';
    },
  },
};
</script>

<style lang="scss">
.clipboard {
    display: flex;
    align-items: flex-start;

    button {
      height: 3.7rem;
      border: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 0rem 1rem;

      i {
        color: black;
        font-size: 1.3rem;
      }
    }

    .clipboard-url {
      background-color: beige;
    }
  }
  .tracking-info {
    position: absolute;
    right: -2rem;
  }
  .clipboard-container {
    display: flex;
    align-items: center;
    position: relative;

    .line-sizing {
      width: unset !important;
      margin: 0 !important;
    }
  }
</style>
