import userLocalStorage from '@sh/services/userLocalStorage';
import { getDateRangePresetsInstance } from '@sh/components/Utils/DateRange/DateRangePresets';

const storageKey = 'campaigns-performance-filter';

export default class CampaignsPerformanceFilter {
  getFilter() {
    const filter = this._getData();
    return filter;
  }

  setFilter(payload) {
    this._setData(payload);
  }

  _getData() {
    const data = userLocalStorage.getSettings(storageKey);
    try {
      const dateRangePresets = getDateRangePresetsInstance();
      dateRangePresets.convertFromLocalStorage(data);
    } catch { /* Do Nothing */ }
    return data;
  }

  _setData(payload) {
    const payloadClone = JSON.parse(JSON.stringify(payload));
    try {
      const dateRangePresets = getDateRangePresetsInstance();
      dateRangePresets.convertToLocalStorage(payloadClone);
    } catch { /* Do Nothing */ }
    userLocalStorage.setSettings(storageKey, payloadClone);
  }
}
