<template>
  <div class="apply-rule-to-wrapper">
    <b-form-group
      :label="$c_label"
      :state="$_hasError('parentWhitelist')"
      class="h-display-sm form optimizer-form-group"
    >
      <b-input-group>
        <EntityParentSelector
          v-model="parentWhitelist"
          class="optimizer-select"
          name="parentWhitelist"
          v-validate="'required'"
          data-vv-as="parentWhitelist"
          :traffic-source-filter="ts_types"
          :custom-filter="$c_customFilter"
          :archived="false"
          :label="label"
          managed
          @input="$_emit('parentWhitelist')"
        />
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('parentWhitelist') }}</small>
      <span slot="description" v-if="cmp_config.info" class="text-info" v-html="cmp_config.info"></span>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import EntityParentSelector from '../../../components/Utils/EntitySelect/FilterByParentSelectLight.vue';

export default {
  name: 'RuleParentWhitelist',
  components: { EntityParentSelector },
  mixins: [component],
  props: {
    // value: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({ entities: [], type: null, parent_level: 'AdGroup' }) },
    filter: { type: Object, default: () => ({ trackerId: null }) },
    label: { type: String, default: () => 'AdGroup' },
  },
  data() {
    return {
      parentWhitelist: { entities: [], type: null, parent_level: null },
      ts_types: [],
      cmp_config: this.ruleModel.rule.components.parentWhitelist,
    };
  },
  computed: {
    $c_customFilter() {
      return this.cmp_config.filter || null;
    },
    $c_label() {
      return `${this.parentWhitelist?.type?.value || 'Apply to'} entities managed by selected ${this.label}s ${this.parentWhitelist.type?.value ? this.parentWhitelist?.type?.value === 'EXCLUDE' ? 'from rule executation' : 'in rule executation' : ''}`;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        if (filter.trackerId) {
          this.parentWhitelist = this.parentWhitelist.filter((item) => `${item.tracker_account_id}` === `${filter.trackerId}`);
          this.$_emit('parentWhitelist');
        }
      },
    },
  },
  created() {
    this.parentWhitelist = this.value;
    this.ts_types = this.ruleModel.rule.ts_types;
    this.$_emit('parentWhitelist');
  },
};
</script>

<style lang="scss">
.apply-rule-to-wrapper {
  .campaign-form {
    margin-bottom: 0;
  }
  .optimizer-select-type {
    min-width: 15rem;
    margin-right: 1.5rem;
    padding-right: 0;
  }
  .input-group-append {
    position: relative;

    .dropdown {
      height: 3.7rem;

      .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          margin-right: .5rem;
        }

        &::after {
          margin-left: .7rem;
        }
      }

      .dropdown-menu {
        top: unset !important;
        bottom: -6rem !important;
      }
    }
  }

  .dropdown-toggle {
    min-height: 3.7rem;
    border-top-left-radius: .6rem !important;
    border-bottom-left-radius: .6rem !important;
  }
}
</style>
