<template>
  <div class="row pl-20">
    <switch-button
      :checked="ruleWithScheduledAdGroup"
      :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
      :update="(value) => $_updateApplyToScheduletBudgetChange(value)"
    />
    <div class="text-with-info">Schedule a budget change if not possible <strong>to update the budget live</strong></div>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleWithScheduledAdGroup',
  mixins: [component],
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      ruleWithScheduledAdGroup: false,
    };
  },
  watch: {
    ruleWithScheduledAdGroup: {
      immediate: true,
      handler() {
        this.$_emit('ruleWithScheduledAdGroup');
      },
      deep: true,
    },
  },
  created() {
    this.ruleWithScheduledAdGroup = this.value;
  },
  methods: {
    $_updateApplyToScheduletBudgetChange(value) {
      this.ruleWithScheduledAdGroup = value;
    },
  },
};
</script>
