<template>
  <div>
    <preloader v-if="preloader" margin="30px" />
    <system-activity
      v-else
      :campaigns-id="[campaign.id]"
      :date-range="dateRange"
      :timezone="timezone"
      tab
    />
  </div>
</template>

<script>
import helperMixins from './mixins/helperMixins';
import notifications from '@sh/mixins/notifications';
import SystemActivity from '@/components/SystemActivity/SystemActivity';

export default {
  name: 'SystemActivityCampaign',
  components: { SystemActivity },
  mixins: [helperMixins, notifications],
  methods: {
    $_init() {
      this.preloader = false;
    },
  },
};
</script>

