<template>
  <div :class="`cc-input-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('input')">
      <b-input-group class="optimizer-form-group-input">
        <vue-tags-input
          name="input"
          class="col-auto exclude-form-wraper pl-1"
          v-model="tag"
          v-validate="model['v-validate']"
          :separators="[',', ';']"
          :placeholder="placeholder"
          :disabled="model.disabled"
          :data-vv-as="model.label"
          :state="$_hasError('input')"
          :tags.sync="input"
          @before-adding-tag="$_beforeAddingTag"
          @tags-changed="newTags => input = newTags"
        />
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError("input") }}</small>
    </b-form-group>
    <p v-if="model.textBottom" class="info-text text-left ml-4" v-html="model.textBottom"></p>
  </div>
</template>

<script>
import component from '../mixins/component';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'CcInputTags',
  components: { VueTagsInput },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
  },
  data() {
    return {
      tag: '',
      input: [],
    };
  },
  created() {
    this.value.forEach((item) => {
      this.input.push({ text: item });
    });

    this.$watch('input', () => {
      this.$_emit('input', this.input);
    }, { deep: true });
  },
  methods: {
    $_beforeAddingTag(data) {
      if (data.tag.text) {
        data.tag.text = data.tag.text.trim();
        data.addTag();
      }
    },
  },
};
</script>

<style lang="scss">
.cc-input-wrapper {
  .exclude-form-wraper {
    padding-right: 0;
    flex: 1;

    .ti-input {
      background: $color-white;
      color: $black-500;
      font-size: 1.4rem;
      height: auto;
      border: 0.1rem solid $color-light-gray;
      border-radius: 0.6rem;
      .ti-new-tag-input {
        font-size: 1.4rem;

        &::placeholder {
          color: $black-300;
        }
      }

      .ti-tags {
        .ti-tag {
          background: #e4eaee;
          border-radius: 0.6rem;
          color: $text-primary;
          padding: 0.2rem 1rem;

          &.ti-deletion-mark {
            background-color: $color-red;
            color: $color-white;
          }

          .ti-content {
            margin-top: 0.2rem;
          }

          .ti-icon-close {
            margin-left: 0.2rem;
            margin-top: 0.2rem;
            color: black;
          }
        }
      }
    }
  }
}
.vue-tags-input {
  max-width: 100% !important;
}
</style>
