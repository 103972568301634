/* eslint-disable import/no-cycle */
import { RecurrenceType, Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import * as Campaign from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import useAdAccountStore from '@/views/Automation/CampaignCreatorV2/store/AdAccount';
import {
  DefaultGroupMacros,
  DefaultMacros,
  FieldConfiguration,
  OptionalLiteralKeys,
  RequiredLiteralKeys,
} from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import { currencyFormatter } from '@sh/helpers';
import Joi from 'joi';

export enum CampaignAbGroupTypes {
  CampaignBudget = 'CampaignBudget',
  DEFAULT = 'DEFAULT',
}

const macros = DefaultMacros;

const groupMacros = DefaultGroupMacros;

export const CampaignABGroupsPublic = {
  [CampaignAbGroupTypes.CampaignBudget]: {
    readableName: 'Campaign Budget',
    macros: groupMacros,
    isActive: () => true,
  },
} as const;

const BudgetValidator: Record<
  | keyof Pick<Campaign.Create, 'spend_cap' | 'daily_budget' | 'lifetime_budget' | 'bid_strategy'>
  | 'enable_budget_for_campaign',
  FieldConfiguration
> = {
  daily_budget: {
    validation: Joi.number().positive().required(),
    objectValidation: Joi.when('enable_budget_for_campaign', {
      is: true,
      then: Joi.number().required(),
      otherwise: Joi.forbidden(),
    }).required(),
    isActive: true,
    readableName: 'Daily Budget',
    interchangeableGroup: 'campaignBudgetValue',
    fieldGroup: CampaignAbGroupTypes.CampaignBudget,
    renderFunction: (value) => currencyFormatter(value / 100),
    macros,
  },
  lifetime_budget: {
    validation: Joi.number().positive().required(),
    fieldGroup: CampaignAbGroupTypes.CampaignBudget,
    interchangeableGroup: 'campaignBudgetValue',
    objectValidation: Joi.when('enable_budget_for_campaign', {
      is: true,
      then: Joi.number().required(),
      otherwise: Joi.forbidden(),
    }).required(),
    isActive: true,
    readableName: 'Lifetime Budget',
    renderFunction: (value) => currencyFormatter(value / 100),
    macros,
  },
  spend_cap: {
    validation: Joi.number().optional(),
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.CampaignBudget,
    readableName: 'Spend Limit',
    renderFunction: (value) => currencyFormatter(value / 100),
  },
  enable_budget_for_campaign: {
    validation: Joi.boolean().required(),
    isActive: true,
    default: false,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
  },
  bid_strategy: {
    validation: Joi.string()
      .valid(...Object.values(Campaign.BidStrategy))
      .required(),
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.CampaignBudget,
    readableName: 'Bid Strategy',
  },
};

const OptionalCampaignFields: Partial<Record<OptionalLiteralKeys<Campaign.Create>, FieldConfiguration>> = {
  budget_schedule_specs: {
    validation: Joi.array()
      .items(
        Joi.object().keys({
          time_start: Joi.number().required(),
          time_end: Joi.number().required(),
          budget_value: Joi.number().required(),
          budget_value_type: Joi.string().valid('ABSOLUTE', 'MULTIPLIER').required(),
          recurrence_type: Joi.string()
            .valid(...Object.values(RecurrenceType))
            .required(),
          weekly_schedule: Joi.array()
            .items(
              Joi.object().keys({
                days: Joi.array().items(Joi.number()).required(),
                minute_start: Joi.number().required(),
                minute_end: Joi.number().required(),
                timezone_type: Joi.string().required(),
              })
            )
            .required(),
        })
      )
      .optional(),
    isActive: false,
  },
};

export const nameValidation = Joi.object().keys({
  name: Joi.string().required().messages({
    'any.required': 'Name is required',
    'string.empty': 'Please enter a prefix name, even if you are using naming templates',
  }),
  macros: Joi.array()
    .items(
      Joi.object()
        .keys({
          value: Joi.string().required(),
          valueType: Joi.string().required(),
          type: Joi.string().required(),
        })
        .unknown(true)
    )
    .optional(),
  fieldSeparator: Joi.string().optional(),
  itemSeparator: Joi.string().optional(),
});

export const CampaignValidation: Record<RequiredLiteralKeys<Campaign.Create>, FieldConfiguration> = {
  // internalId: { validation: Joi.string().required(), isActive: true },
  adlabels: { validation: Joi.array().items(Joi.string()).optional(), isActive: false },
  name: {
    validation: nameValidation,
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    readableName: 'Name',
    renderFunction(v) {
      return v.name || v;
    },
  },
  special_ad_categories: {
    validation: Joi.array().items(Joi.string()).optional(),
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    readableName: 'Special Ad Category',
    renderFunction: (e) => e?.join(','),
  },
  special_ad_category_country: {
    validation: Joi.array()
      .items(
        Joi.object()
          .keys({
            key: Joi.string().required(),
            name: Joi.string().required(),
          })
          .unknown(true)
      )
      .optional(),
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    readableName: 'Special Ad Category Countries',
    renderFunction: (c) => c?.map((e: any) => e.name)?.join(','),
  },
  account_id: {
    validation: Joi.number().required(),
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    readableName: 'Account',
    renderFunction: (v) => {
      const account = useAdAccountStore().getAdAccountsOptions.find((e) => e.id === v);
      return account?.name ?? '';
    },
    macros,
  },
  status: {
    validation: Joi.string()
      .valid(...Object.values(Status))
      .default(Status.PAUSED)
      .required(),
    isActive: true,
    default: Status.PAUSED,
    readableName: 'Status',
  },
  buying_type: {
    validation: Joi.string().valid(Campaign.BuyingType.AUCTION, Campaign.BuyingType.RESERVED).required(),
    default: Campaign.BuyingType.AUCTION,
    isActive: true,
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    macros,
    readableName: 'Buying Type',
    renderFunction: (o: Campaign.BuyingType) => o,
  },
  objective: {
    validation: Joi.string()
      .valid(...Object.values(Campaign.Objective))
      .required(),
    fieldGroup: CampaignAbGroupTypes.DEFAULT,
    isActive: true,
    readableName: 'Objective',
    macros,
  },
  start_time: { validation: Joi.date().optional(), isActive: true },
  stop_time: { validation: Joi.date().optional(), isActive: true },
  ...BudgetValidator,
  ...OptionalCampaignFields,
} as const;

export type CampaignFlat = Campaign.Create;
