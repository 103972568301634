<template>
  <div class="time-input" :class="{'w-100': !classList}">
    <vue-timepicker
      :class="classList || 'w-100'"
      format="HH:mm:ss"
      hide-clear-button
      v-model="model"
    >
    </vue-timepicker>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';

export default {
  name: 'TimeInput',
  components: { VueTimepicker },
  props: {
    value: { type: [Number, String], default: 0 },
    classList: String,
  },
  data() {
    return {
      model: {
        HH: '00',
        mm: '00',
        ss: '00',
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const mTime = moment.utc((parseFloat(value) || 0) * 1000);
        const time = {
          HH: mTime.hours().toString(),
          mm: mTime.minutes().toString(),
          ss: mTime.seconds().toString(),
        };
        Object.entries(time).forEach(([key, val]) => {
          time[key] = val.length === 1 ? `0${val}` : val;
        });
        this.model = time;
      },
    },
    model(value) {
      let sec = parseFloat(value.ss) || 0;
      sec += parseFloat(value.mm) * 60;
      sec += parseFloat(value.HH) * 3600;
      this.$emit('input', sec.toString());
    },
  },
};
</script>

<style lang="scss">
@import '~vue2-timepicker/dist/VueTimepicker.css';

.time-input {
  .time-picker {
    .display-time {
      width: 100%;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      height: calc(2.09375rem + 2px);
      color: #3e515b;
      border: 1px solid #e1e6ef !important;
    }
    .dropdown {
      width: 100%;
      z-index: 99999;
      top: calc(2.2em + 6px);
      .select-list {
        width: 100%;
      }
    }
  }
}
</style>
