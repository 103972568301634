<template>
  <div class="clipboard-container">
    <div v-if="name" class="clipboard-container__title">
      {{ name }}
    </div>
    <div class="clipboard">
      <div class="optimizer-form-input clipboard-url">
        {{ url }}
      </div>
      <button @click="copyToClipboard(url)">
        <i v-if="isCopyAnimation" class="fa fa-check text-success" />
        <i v-else class="fa fa-clipboard" />
      </button>
    </div>
    <div class="tracking-info" v-if="mode === 'TrafficSources'">
      <i
        v-b-tooltip.hover
        :title="`In order for the integration to work properly, please make sure that all your campaigns or Ad URLs include this tracking code.`"
        class="fa fa-info-circle field-info"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFeedTrackingUrl',
  props: {
    item: { type: Object, default: () => ({}) },
    mode: { type: String, default: 'TrafficSources' },
    name: String,
  },
  data() {
    return {
      reverseTokens: ['GoogleAnalytics'],
      ga4Params: [
        { text: 'utm_id', value: 'sessionCampaignId' },
        { text: 'utm_campaign', value: 'sessionCampaignName' },
        { text: 'utm_source', value: 'sessionSource' },
        { text: 'utm_medium', value: 'sessionMedium' },
        { text: 'utm_term', value: 'sessionManualTerm' },
        { text: 'utm_content', value: 'sessionManualAdContent' },
      ],
      assertiveYieldParams: [
        { text: 'utmCampaign', format: 'utm_campaign' },
        { text: 'utmMedium', format: 'utm_medium' },
        { text: 'utmTerm', format: 'utm_term' },
        { text: 'utmContent', format: 'utm_content' },
        { text: 'utmSource', format: 'utm_source' },
        { text: 'custom_1', format: 'custom_1' },
        { text: 'custom_2', format: 'custom_2' },
        { text: 'custom_3', format: 'custom_3' },
        { text: 'custom_4', format: 'custom_4' },
        { text: 'custom_5', format: 'custom_5' },
        { text: 'custom_6', format: 'custom_6' },
        { text: 'custom_7', format: 'custom_7' },
        { text: 'custom_8', format: 'custom_8' },
        { text: 'custom_9', format: 'custom_9' },
        { text: 'custom_10', format: 'custom_10' },
      ],
      isCopyAnimation: false,
    };
  },
  computed: {
    url() {
      if (this.item.variables && this.item.uniqueName) {
        return this.buildUrl(this.item.variables, this.item.uniqueName);
      }
      return 'Unable to generate tracking url!';
    },
  },
  methods: {
    buildUrl(variables, uniqueName) {
      const urls = [];
      variables.forEach((token) => {
        if (!token) {
          return;
        }
        const tokenValues = Object.values(token);
        if (uniqueName === 'GoogleAnalytics4') {
          tokenValues.forEach((item, index) => {
            if (item?.includes('custom')) {
              [, tokenValues[index]] = item.split(':');
            }
          });
        }
        Object.keys(token).forEach((item, index) => {
          if (uniqueName === 'GoogleAnalytics4') {
            const getParam = this.ga4Params.find((param) => param.value === tokenValues[index]);
            if (getParam) {
              urls.push(`${getParam.text}=${item}`);
            } else {
              urls.push(`${tokenValues[index]}=${item}`);
            }
          } else if (uniqueName === 'AssertiveYield') {
            const assertiveYieldParams = this.assertiveYieldParams.find((param) => param.text === tokenValues[index]);
            if (assertiveYieldParams) {
              urls.push(`${assertiveYieldParams.format}=${item}`);
            }
          } else if (this.reverseTokens.includes(uniqueName)) {
            urls.push(`${item}=${tokenValues[index]}`);
          } else if (uniqueName === 'Media') {
            const itemReplaced = item.includes('|') ? item.replaceAll('|', '_') : item;
            urls.push(`${tokenValues[index]}=${itemReplaced}`);
          } else {
            urls.push(`${tokenValues[index]}=${item}`);
          }
        });
      });
      return urls.filter((item) => !item.includes('null')).join('&');
    },
    copyToClipboard(text) {
      this.isCopyAnimation = true;
      navigator.clipboard.writeText(text);

      setTimeout(() => {
        this.isCopyAnimation = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.clipboard-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  &__title {
    font-size: 1.4rem;
    font-weight: 400;
    width: 100%;
  }

  .clipboard {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .optimizer-form-input {
      width: 100%;
      overflow: auto;
      white-space: nowrap;
      display: flex;
      align-items: center;
      border-radius: 0.5rem 0 0 0.5rem;
      margin: 0;
    }

    button {
      height: 3.7rem;
      border: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 0rem 1rem;

      i {
        color: black;
        font-size: 1.3rem;
      }
    }

    .clipboard-url {
      background-color: beige;
    }
  }

  .tracking-info {
    position: absolute;
    right: -2rem;
  }
}
</style>
