import { api, apiStore } from '@sh/services/api';

export default {
  trackers: {
    types() {
      return apiStore.trackers.types();
    },
    account(id) {
      return apiStore.trackers.account(id);
    },
    create(payload) {
      return api.trackers.create(payload);
    },
    update(id, payload) {
      return api.trackers.update(id, payload);
    },
    accounts() {
      return apiStore.trackers.accounts();
    },
    delete(id) {
      return api.trackers.delete(id);
    },
    updateStatus(id, status) {
      return api.trackers.updateStatus(id, status);
    },
    getCsvTemplate(tokens) {
      return api.trackers.getCsvTemplate(tokens);
    },
    async validateHeaders(credentials, tokenVariables) {
      return api.trackers.validateHeaders(credentials, tokenVariables);
    },
  },
  trafficSources: {
    types() {
      return apiStore.trafficSources.types();
    },
    accounts() {
      return apiStore.trafficSources.accounts();
    },
    tokens(type) {
      return api.trafficSources.tokens(type);
    },
  },
};
