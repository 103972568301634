/* eslint-disable import/no-cycle */
import * as AdMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Ad';
import * as CreativeMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import * as AdSetMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import useAbGroups from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import { ErrorType, InternalABVariable, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { AdValidation } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import { AdDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdDTO';
import { AdSetFlat, AdSetValidation, TargetingValidation } from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import { AdSetDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdSetDTO';
import { CampaignFlat, CampaignValidation } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import { CampaignDTO } from '@/views/Automation/CampaignCreatorV2/validation/CampaignDTO';
import { CreativeValidation } from '@/views/Automation/CampaignCreatorV2/validation/Creative';
import { FieldConfiguration } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import uid from 'uid';

export type ErrorData<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M> = {
  message: string;
  field: K;
  entity: E;
  type: ErrorType;
};

export type ValidationErrors<T = never> = Partial<Record<ErrorType, ErrorData<any, any, any>[]>>;

export const initializeErrors = <T = string>(): ValidationErrors<T> => ({
  [ErrorType.BLOCKING]: [],
  [ErrorType.MISSING]: [],
  [ErrorType.SILENT]: [],
});

type Campaign = Pick<CampaignFlat, keyof typeof CampaignValidation>;
type AdSet = Pick<AdSetFlat, keyof typeof AdSetValidation>;
type Target = Pick<AdSetMeta.TargetingObjectAbVariables, keyof typeof TargetingValidation>;
type Ad = Pick<AdMeta.Create, keyof typeof AdValidation>;
type Creative = Pick<CreativeMeta.AdCreative, keyof typeof CreativeValidation>;

export type FieldVariable<valueObject extends Record<string, unknown>, key extends keyof valueObject> = {
  value: Array<InternalABVariable<valueObject[key]>>;
  errors?: ErrorData<any, any, any>[];
};

type GenericState<valueObject extends Record<string, unknown> = Record<string, any>> = {
  [key in keyof valueObject]: FieldVariable<valueObject, key>;
};

export type ExtractSupportedVariablesKeys<T extends keyof SupportedVariablesByEntity> =
  keyof SupportedVariablesByEntity[T];

export type SupportedVariablesByEntity = {
  [SupportedEntities.adset]: AdSet;
  [SupportedEntities.targeting]: Target;
  [SupportedEntities.campaign]: Campaign;
  [SupportedEntities.ad]: Ad;
  [SupportedEntities.creative]: Creative;
};

export type CampaignAbVariables = GenericState<Campaign>;
export type AdSetAbVariables = GenericState<AdSet>;
export type TargetingAbVariables = GenericState<Target>;
export type AdsAbVariables = GenericState<Ad>;
export type CreativeAbVariables = GenericState<Creative>;

function getValidator<E extends SupportedEntities>(e: E, field: any, value: any) {
  switch (e) {
    case SupportedEntities.ad: {
      return AdDTO.validateField(field, value);
    }
    case SupportedEntities.adset: {
      return AdSetDTO.validateField(field, value);
    }
    case SupportedEntities.campaign: {
      return CampaignDTO.validateField(field, value);
    }
    case SupportedEntities.creative: {
      return AdDTO.validateCreativeField(field, value);
    }
    case SupportedEntities.targeting: {
      return AdSetDTO.validateTargetingField(field, value);
    }
    default: {
      throw new Error('Not supported Entity');
    }
  }
}

function getFieldConfiguration<E extends SupportedEntities>(e: E, field: any): FieldConfiguration {
  switch (e) {
    case SupportedEntities.ad: {
      return AdDTO.getFieldConfiguration(field);
    }
    case SupportedEntities.adset: {
      return AdSetDTO.getFieldConfiguration(field);
    }
    case SupportedEntities.campaign: {
      return CampaignDTO.getFieldConfiguration(field);
    }
    case SupportedEntities.creative: {
      return AdDTO.getFieldConfigurationCreative(field);
    }
    case SupportedEntities.targeting: {
      return AdSetDTO.getFieldConfigurationTargeting(field);
    }
    default: {
      throw new Error('Not supported Entity');
    }
  }
}

interface AbVariableState {
  [SupportedEntities.adset]: {
    [adsetBuildId: string]: GenericState<AdSet>;
  };
  [SupportedEntities.targeting]: {
    [adsetBuildId: string]: GenericState<Target>;
  };
  [SupportedEntities.campaign]: {
    [campaignBuildId: string]: GenericState<Campaign>;
  };
  [SupportedEntities.ad]: {
    [adBuildId: string]: GenericState<Ad>;
  };
  [SupportedEntities.creative]: {
    [adBuildId: string]: GenericState<Creative>;
  };
}

const useABVariableStore = defineStore('abVariable', {
  state: (): AbVariableState => ({
    adset: {},
    targeting: {},
    campaign: {},
    ad: {},
    creative: {},
  }),
  getters: {
    getFieldForEntity:
      (state) =>
      <E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
        entity: E,
        key: K,
        buildId: string
      ): GenericState<M>[K] => {
        // Current State for Entity
        const currentState = (state?.[entity as 'adset'][buildId] || {}) as GenericState<M>;
        // Current State For Variable
        const abField = currentState[key] || { value: [] };

        const metadata = getFieldConfiguration(entity, key);

        const field = {
          ...metadata,
          ...abField,
        };
        return field;
      },
    getEntity() {
      return <E extends SupportedEntities>(entity: E): Record<string, GenericState<SupportedVariablesByEntity[E]>> =>
        this[entity] as Record<string, GenericState<SupportedVariablesByEntity[E]>>;
    },
    getAbGroupErrors:
      (state) =>
      <E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
        entity: E,
        abGroupId: string,
        buildId?: string
      ) => {
        const currentBuildId = buildId || useNavigationStore().getCurrentNavigation.buildId;
        const currentState = (state?.[entity][currentBuildId] || {}) as GenericState<M>;
        const allFields = Object.values(currentState) as FieldVariable<Record<string, unknown>, string>[];
        const fieldsOwned = allFields.filter((f) => f.value.some((v) => v.abGroup === abGroupId));

        return fieldsOwned.reduce((errors, f) => {
          const allErrors = [
            ...(f.errors ?? []),
            ...f.value
              .filter((v) => v.abGroup === abGroupId)
              .map((v) => v.errors ?? [])
              .flat(),
          ];

          errors.MISSING?.push(...allErrors.filter((e) => e.type === ErrorType.MISSING));
          errors.BLOCKING?.push(...allErrors.filter((e) => e.type === ErrorType.BLOCKING));
          errors.SILENT?.push(...allErrors.filter((e) => e.type === ErrorType.SILENT));

          return errors;
        }, initializeErrors());
      },
    getEntityErrors:
      (state) =>
      <E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
        entity: E,
        buildId?: string
      ): ValidationErrors<unknown> => {
        const currentBuildId = buildId || useNavigationStore().getCurrentNavigation.buildId;
        const currentState = (state?.[entity][currentBuildId] || {}) as GenericState<M>;
        const allFields = Object.values(currentState) as FieldVariable<Record<string, unknown>, string>[];

        return allFields.reduce((errors, f) => {
          const allErrors = [...(f.errors ?? []), ...f.value.map((v) => v.errors ?? []).flat()];

          errors.MISSING?.push(...allErrors.filter((e) => e.type === ErrorType.MISSING));
          errors.BLOCKING?.push(...allErrors.filter((e) => e.type === ErrorType.BLOCKING));
          errors.SILENT?.push(...allErrors.filter((e) => e.type === ErrorType.SILENT));

          return errors;
        }, initializeErrors());
      },
    getField:
      (state) =>
      <E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
        entity: E,
        key: K
      ): GenericState<M>[K] => {
        const navigation = useNavigationStore();
        const { buildId } = navigation.getNavigationForEntity(entity);
        // Current State for Entity
        const currentState = (state?.[entity as 'adset'][buildId] || {}) as GenericState<M>;
        // Current State For Variable
        const abField = currentState[key] || { value: [] };

        const metadata = getFieldConfiguration(entity, key);
        const currentGroupId = navigation.getCurrentAbGroupFor(entity, metadata.fieldGroup!);

        const field = {
          ...metadata,
          ...abField,
        };

        if (currentGroupId) {
          field.value = abField.value.filter((v) => v.abGroup === currentGroupId);
        }
        return field;
      },
  },
  actions: {
    saveState() {
      return { abVariables: this.$state };
    },
    loadState(template: any) {
      const parsedState = template.abVariables as AbVariableState;
      if (parsedState) {
        this.adset = { ...this.adset, ...parsedState.adset };
        this.targeting = { ...this.targeting, ...parsedState.targeting };
        this.campaign = { ...this.campaign, ...parsedState.campaign };
        this.ad = { ...this.ad, ...parsedState.ad };
        this.creative = { ...this.creative, ...parsedState.creative };
      }
    },
    addVariable<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      value: Omit<InternalABVariable<M[K]>, 'variableId'> & { variableId?: string },
      overrideBuildId?: string
    ) {
      const fieldConfig = getFieldConfiguration(entity, field);

      // Current User Navigation
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const { buildId: navigationBuildId } = navigation.getCurrentNavigation;
      const buildId = overrideBuildId || navigationBuildId;
      const groupIdNavigation = navigation.getCurrentAbGroupFor(entity, fieldConfig.fieldGroup!, overrideBuildId);
      // Fill Optional Data
      value.variableId = value.variableId || uid(25);

      // Each Variable should be associated with a group
      value.abGroup = value.abGroup || groupIdNavigation!;
      const currentAbGroupExists = abGroupStore.getAbGroup(value.abGroup, entity, overrideBuildId);
      if (!currentAbGroupExists) {
        throw new Error('AB Group does not exist');
      }

      // Validate Field
      const errors = getValidator(entity, field, value.value);
      // Current State for Entity
      const currentState = (this[entity][buildId] || {}) as GenericState<M>;

      // Current State For Variable
      const abField = currentState[field] || { value: [] };
      const variables = abField.value;

      if (errors) {
        // Set Error For that field
        abField.errors = [
          {
            message: errors.message?.replace('value', (fieldConfig.readableName || fieldConfig.key!).toString()),
            field: field.toString(),
            entity,
            type: ErrorType.BLOCKING,
          },
        ];
      } else {
        abField.errors = [];
        // Patch Variable
        variables.push(value as InternalABVariable<M[K]>);
      }
      abField.value = variables;

      // Patch EntityState
      currentState[field] = abField;
      // Patch State
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: currentState },
      };
    },
    removeVariable<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      key: string
    ) {
      try {
        const fieldConfig = getFieldConfiguration(entity, field);

        // Current User Navigation
        const navigation = useNavigationStore();
        const { buildId } = navigation.getCurrentNavigation;
        const groupIdNavigation = navigation.getCurrentAbGroupFor(entity, fieldConfig.fieldGroup!);

        if (!groupIdNavigation) {
          throw new Error('Group ID not found');
        }

        const currentState = (this[entity][buildId] || {}) as GenericState<M>;

        // Current State For Variable
        const abField = (currentState[field] || { value: [] }) as FieldVariable<M, K>;
        const variables = abField.value;
        const index = variables?.findIndex((v) => v.variableId === key && v.abGroup === groupIdNavigation);

        if (index === -1 || index === undefined) {
          console.log(`Variable not found ${field?.toString()}`);
          return;
        }

        // Patch AbField
        abField.value = variables.filter((v) => !(v.variableId === key && v.abGroup === groupIdNavigation));
        abField.errors = [];

        // Patch EntityState
        currentState[field] = abField;

        // Patch State
        this[entity] = {
          ...this[entity],
          ...{ [buildId]: currentState },
        };
      } catch (e) {
        console.log('Error removing variable', e);
      }
    },
    editVariable<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      key: string,
      value: InternalABVariable<M[K]>,
      overrideBuildId?: string
    ) {
      const fieldConfig = getFieldConfiguration(entity, field);

      // Current User Navigation
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const { buildId: navigationBuildId } = navigation.getCurrentNavigation;
      const buildId = overrideBuildId || navigationBuildId;
      const groupIdNavigation = navigation.getCurrentAbGroupFor(entity, fieldConfig.fieldGroup!, overrideBuildId);

      // Each Variable should be associated with a group
      value.abGroup = value.abGroup || groupIdNavigation!;
      const currentAbGroupExists = abGroupStore.getAbGroup(value.abGroup, entity, overrideBuildId);
      if (!currentAbGroupExists) {
        throw new Error('AB Group does not exist');
      }

      // Current State for Entity
      const currentState = (this[entity][buildId] || {}) as GenericState<M>;
      // Current State For Variable
      const abField = currentState[field] || { value: [] };
      const variables = abField.value;
      const index = variables?.findIndex((v) => v.variableId === key && v.abGroup === value.abGroup);

      if (index === -1 || index === undefined) {
        throw new Error(`Variable not found ${field?.toString()}`);
      }

      const errors = getValidator(entity, field, value.value);

      if (errors) {
        // Set Error For that field
        variables[index].errors = [
          {
            message: errors.message?.replace('value', (fieldConfig.readableName || fieldConfig.key!).toString()),
            field: field.toString(),
            entity,
            type: ErrorType.BLOCKING,
          },
        ];
      } else {
        // Patch Variable
        variables[index] = { ...variables[index], errors: [], ...value };
        // Patch AbField
      }
      abField.value = variables;
      // Patch EntityState
      currentState[field] = abField;
      // Patch State
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: currentState },
      };
    },
    addOrEditVariable<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      value: InternalABVariable<M[K]>,
      currentBuildId?: string
    ) {
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const fieldConfig = getFieldConfiguration(entity, field);
      const { buildId } = navigation.getCurrentNavigation;
      const state = (this[entity]?.[currentBuildId || buildId] || {}) as unknown as GenericState<M>;
      const groupIdNavigation = navigation.getCurrentAbGroupFor(entity, fieldConfig.fieldGroup!, currentBuildId);

      value.abGroup = value.abGroup || groupIdNavigation;

      const index = state[field]?.value?.findIndex(
        (v) => v.variableId === value.variableId && v.abGroup === value.abGroup
      );
      const currentAbGroupExists = abGroupStore.getAbGroup(value.abGroup, entity, currentBuildId);

      navigation.setNextStepButtonErrorTooltip('');

      if (!currentAbGroupExists) {
        throw new Error('AB Group does not exist');
      }

      if (index !== undefined && index !== -1) {
        const currentid = state?.[field]?.value?.[index]?.variableId;

        if (!currentid) {
          throw new Error('Unable to find currentId');
        }
        this.editVariable(entity, field, currentid, value, currentBuildId);
      } else {
        this.addVariable(entity, field, value, currentBuildId);
      }
    },
    cloneEntity<E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
      entity: E,
      buildId: string,
      newBuildId: string
    ) {
      const currentState = this[entity][buildId] as GenericState<M>;
      this[entity] = {
        ...this[entity],
        ...{ [newBuildId]: cloneDeep(currentState) },
      };
    },
    resetField<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      overrideBuildId?: string
    ) {
      const navigation = useNavigationStore();
      const { buildId } = navigation.getCurrentNavigation;
      const itemBuildId = overrideBuildId || buildId;
      const currentEntity = this[entity][itemBuildId] as GenericState<M>;
      currentEntity[field] = { value: [] };
      this[entity] = {
        ...this[entity],
        [itemBuildId]: currentEntity,
      };
    },
    cloneAbGroup<E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
      entity: E,
      buildId: string,
      currentGroupId: string,
      newAbGroupId: string
    ) {
      const currentEntityState = this[entity][buildId] as GenericState<M>;
      // eslint-disable-next-line guard-for-in
      for (const field of Object.keys(currentEntityState)) {
        const currentField = currentEntityState[field as keyof M];
        const newVariables = currentField.value
          ?.filter((v) => v.abGroup === currentGroupId)
          // TODO: Change the variable Id also
          ?.map((v) => ({ ...v, abGroup: newAbGroupId }));
        currentEntityState[field as keyof M].value = [...(currentField.value || []), ...(newVariables || [])];
      }
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: currentEntityState },
      };
    },
    removeAbGroup<E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
      entity: E,
      buildId: string,
      groupId: string
    ) {
      const currentEntityState = this[entity][buildId] as GenericState<M>;
      // eslint-disable-next-line guard-for-in
      for (const field in currentEntityState) {
        const currentField = currentEntityState[field];
        currentField.value = currentField.value.filter((v) => v.abGroup !== groupId);
      }
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: currentEntityState },
      };
    },
    renameVariable<E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
      entity: E,
      buildId: string,
      groupId: string,
      field: keyof M,
      variableId: string,
      newName: string
    ) {
      const currentEntityState = this[entity][buildId] as GenericState<M>;
      const currentField = currentEntityState[field];
      const index = currentField.value?.findIndex((v) => v.abGroup === groupId && v.variableId === variableId);
      if (index === -1) {
        console.error('Variable not found');
        return;
      }
      currentEntityState[field].value[index].name = newName;
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: currentEntityState },
      };
    },
    removeEntity<E extends SupportedEntities>(entity: E, buildId: string) {
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: undefined },
      };
    },
    initEntity<E extends SupportedEntities, M extends SupportedVariablesByEntity[E]>(
      entity: E,
      buildId: string,
      initState: GenericState<M>
    ) {
      this[entity] = {
        ...this[entity],
        ...{ [buildId]: initState },
      };
    },
    registerError<E extends SupportedEntities, M extends SupportedVariablesByEntity[E], K extends keyof M>(
      entity: E,
      field: K,
      errors: ErrorData<E, M, K>[],
      abVariableId?: string,
      abGroupId?: string,
      buildID?: string
    ) {
      const currentEntityId = buildID || useNavigationStore().getCurrentNavigation.buildId;
      const currentEntity = this[entity][currentEntityId] as GenericState<M>;

      if (!currentEntity?.[field]) {
        currentEntity[field] = { value: [] };
      }

      if (!abGroupId || !abVariableId) {
        currentEntity[field].errors = errors;
      } else {
        const variableIndex = currentEntity[field].value.findIndex(
          (v) => v.abGroup === abGroupId && v.variableId === abVariableId
        );

        if (variableIndex === -1) {
          currentEntity[field].errors = errors;
        } else if (currentEntity[field].value[variableIndex]) {
          currentEntity[field].value[variableIndex].errors = errors;
        }
      }

      this[entity] = {
        ...this[entity],
        ...{ [currentEntityId]: currentEntity },
      };
    },
  },
});

export default useABVariableStore;
