var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.componentTag,{tag:"component",class:[
    'ui-button',
    _vm.disabled && 'ui-button--disabled',
    _vm.size && `ui-button--size-${_vm.size}`,
    _vm.variant && `ui-button--variant-${_vm.variant}`,
    _vm.noText && 'ui-button--no-text',
  ],attrs:{"to":_vm.to,"disabled":_vm.disabled},on:{"click":function($event){return _setup.emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }