import api from './api';
import form from './form';

export default {
  async getConfig() {
    return {
      api,
      form,
    };
  },
};
