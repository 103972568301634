<template>
  <div class="campaigns-container__stats">
    <div class="row">
      <div class="col-md-3 mb-4">

        <loading-skeleton
          v-if="loading"
          class="campaign-stats-skeleton"
          type="card"
          :card="{ rows: 2, shape: 'square' }"
        />
        <div v-else class="optimizer-card campaigns-stats-card cost">
          <div class="campaigns-stats-card__description">
            <div class="campaigns-stats-card__description__total d-flex justify-content-between align-items-center">
              <span class="stats-total-title" v-b-tooltip.hover.top="{ title: stats_info['cost'], html: true, customClass: 'custom-tooltip max-width-280' }">Total Amount Spent</span>
              <span class="stats-total-value">${{ toFixed(cost) }}</span>
            </div>
            <div class="campaigns-stats-card__description__est d-flex justify-content-between align-items-center">
              <span class="stats-est-title">Est Cost</span>
              <span class="stats-est-value">${{ toFixed(est_cost) }}</span>
            </div>
          </div>
          <hr>
          <div class="campaigns-stats-card__footer d-flex justify-content-between">
            <span>Avg CPC</span>
            <span>${{ toFixed(cpc) }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-4">
        <loading-skeleton
          v-if="loading"
          class="campaign-stats-skeleton"
          type="card"
          :card="{ rows: 2, shape: 'square' }"
        />
        <div v-else class="optimizer-card campaigns-stats-card revenue">
          <div class="campaigns-stats-card__description">
            <div class="campaigns-stats-card__description__total d-flex justify-content-between align-items-center">
              <span class="stats-total-title" v-b-tooltip.hover.top="{ title: stats_info['revenue'], html: true, customClass: 'custom-tooltip max-width-280' }">Total Revenue</span>
              <span class="stats-total-value">${{ toFixed(revenue) }}</span>
            </div>
            <div class="campaigns-stats-card__description__est d-flex justify-content-between align-items-center">
              <span class="stats-est-title">Est Revenue</span>
              <span class="stats-est-value">-</span>
            </div>
          </div>
          <hr>
          <div class="campaigns-stats-card__footer d-flex justify-content-between">
            <span>Avg CPA</span>
            <span>${{ toFixed(cpa) }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-4">
        <loading-skeleton
          v-if="loading"
          class="campaign-stats-skeleton"
          type="card"
          :card="{ rows: 2, shape: 'square' }"
        />
        <div v-else class="optimizer-card campaigns-stats-card net">
          <div class="campaigns-stats-card__description">
            <div class="campaigns-stats-card__description__total d-flex justify-content-between align-items-center">
              <span class="stats-total-title" v-b-tooltip.hover.top="{ title: stats_info['net'], html: true, customClass: 'custom-tooltip max-width-280' }">Total Net</span>
              <span class="stats-total-value">{{ net < 0 ? '-' : '' }}${{ toFixed(abs(net)) }}</span>
            </div>
            <div class="campaigns-stats-card__description__est d-flex justify-content-between align-items-center">
              <span class="stats-est-title">Est Net</span>
              <span class="stats-est-value">{{ est_net < 0 ? '-' : '' }}${{ toFixed(abs(est_net)) }}</span>
            </div>
          </div>
          <hr>
          <div class="campaigns-stats-card__footer d-flex justify-content-between">
            <span>Avg EPC</span>
            <span>${{ toFixed(epc) }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-4">
        <loading-skeleton
          v-if="loading"
          class="campaign-stats-skeleton"
          type="card"
          :card="{ rows: 2, shape: 'square' }"
        />
        <div v-else class="optimizer-card campaigns-stats-card roi">
          <div class="campaigns-stats-card__description">
            <div class="campaigns-stats-card__description__total d-flex justify-content-between align-items-center">
              <span class="stats-total-title" v-b-tooltip.hover.top="{ title: stats_info['roi'], html: true, customClass: 'custom-tooltip max-width-280' }">Total ROI</span>
              <span class="stats-total-value">{{ toFixed(roi) }}%</span>
            </div>
            <div class="campaigns-stats-card__description__est d-flex justify-content-between align-items-center">
              <span class="stats-est-title">Est Roi</span>
              <span class="stats-est-value">{{ toFixed(est_roi) }}%</span>
            </div>
          </div>
          <hr>
          <div class="campaigns-stats-card__footer d-flex justify-content-end">
            <span>Return On Investment</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';

export default {
  name: 'CampaignsStats',
  components: { LoadingSkeleton },
  props: {
    /* eslint-disable vue/require-prop-types */
    cost: { default: 0 },
    revenue: { default: 0 },
    net: { default: 0 },
    roi: { default: 0 },
    cpc: { default: 0 },
    cpa: { default: 0 },
    epc: { default: 0 },
    est_cost: { default: 0 },
    est_net: { default: 0 },
    est_roi: { default: 0 },
    loading: { type: Boolean, default: false },
    revenue_from: { type: String, default: 'tr' },
    stats_info: {
      type: Object,
      default: () => ({
        cost: '',
        revenue: '',
        net: '',
        roi: '',
      }),
    },
  },
  methods: {
    abs(nr) {
      if (typeof nr === 'undefined') return 0;
      nr = parseFloat(nr);
      return nr < 0 ? nr * -1 : nr;
    },
    toFixed(num, fixed = 3) {
      const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
      return parseFloat((num || 0).toString().match(re)[0]).toFixed(fixed);
    },
  },
};
</script>

<style lang="scss">
  .campaigns-stats-card {
    padding: 2rem;

    &.cost {
      .stats-total-title { color: $color-light-red; }
      hr { background: $color-light-red; }
    }

    &.revenue {
      .stats-total-title { color: $color-light-green; }
      hr { background: $color-light-green; }
    }

    &.net {
      .stats-total-title { color: $color-yellow; }
      hr { background: $color-yellow; }
    }

    &.roi {
      .stats-total-title { color: $color-light-blue; }
      hr { background: $color-light-blue; }
    }

    &__description {
      &__total {
        font-size: 1.4rem;
        font-weight: bold;

        .stats-total-title {
          color: $color-light-red;
        }

        .stats-total-value {
          color: $color-dark-gray;
          font-size: 1.8rem;
        }
      }

      &__est {
        font-size: 1.4rem;
        color: $black-350;
        margin-bottom: 2rem;
      }
    }

    hr {
      background: $color-light-red;
    }

    &__footer {
      font-size: 1.4rem;
      color: $text-secondary;
      line-height: 1;
      padding: .7rem 0 0 0;
    }
  }

  .campaign-stats-skeleton {
    margin-bottom: 0;
    height: 14rem;
  }
</style>
