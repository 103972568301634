import { Filter } from '@/views/Automation/CampaignCreatorV2/components/Modals/CcAddImageModal/core/Filter';
import { CategoryType } from '@/views/Automation/CampaignCreatorV2/components/Modals/CcAddImageModal/types';
import { TheOptimizer } from '@sh/helpers';
import { TrafficSource } from '@sh/types';

type FiltersType = Record<CategoryType, Filter>;

const getFilters = (): FiltersType => ({
  [CategoryType.ALL]: new Filter(CategoryType.ALL, {}),
  [CategoryType.ACCOUNT]: new Filter(CategoryType.ACCOUNT, {
    trafficsource: [TrafficSource.Facebook],
    selectedAccounts: [],
  }),
  [CategoryType.UPLOADED]: new Filter(CategoryType.UPLOADED, {
    trafficsource: [TheOptimizer, `${TheOptimizer}Clone`],
  }),
  [CategoryType.CREATIVE_LIBRARY]: new Filter(CategoryType.CREATIVE_LIBRARY, {
    trafficsource: [TrafficSource.Facebook],
  }),
  [CategoryType.RECOMMENDED]: new Filter(CategoryType.RECOMMENDED, {
    order: [
      {
        name: 'EPC Highest',
        column: 'traffic_source_item_epc',
        type: 'DESC',
      },
      // TODO: when backend is ready, might need to add this double order
      // {
      //   name: 'Most Used',
      //   column: 'usedOn',
      //   type: 'DESC',
      // },
    ],
  }),
});

async function fetchImages(filters: FiltersType) {
  const data = Object.values(filters);
  const promises = data.map((filter) => filter.fetchImages());

  await Promise.all(promises);

  data.forEach((filter) => {
    filter.visible = filter.visible || filter.images.length > 0;
  });
  return filters;
}

export { getFilters, Filter, FiltersType, fetchImages };
