const intervals = [
  { value: '0', text: 'Today' },
  { value: '-1', text: 'Yesterday' },
  { value: '-1.5', text: 'Today & Yesterday' },
  { value: '-2', text: 'Last 3 Days' },
  { value: '-3', text: 'Last 4 Days' },
  { value: '-4', text: 'Last 5 Days' },
  { value: '-5', text: 'Last 6 Days' },
  { value: '-6', text: 'Last 7 Days' },
  { value: '-7', text: 'Last 8 Days' },
  { value: '-8', text: 'Last 9 Days' },
  { value: '-9', text: 'Last 10 Days' },
  { value: '-10', text: 'Last 11 Days' },
  { value: '-11', text: 'Last 12 Days' },
  { value: '-12', text: 'Last 13 Days' },
  { value: '-13', text: 'Last 14 Days' },
  { value: '30', text: 'This Month' },
  { value: '-29', text: 'Last 30 Days' },
  { value: '-59', text: 'Last 60 Days' },
  { value: '-89', text: 'Last 90 Days' },
];

const intervalsExclude = [
  { value: '0', text: 'None' },
  { value: '1', text: 'Today' },
  { value: '2', text: 'Today & Yesterday' },
  { value: '3', text: 'Last 3 Days' },
];

const intervalsMap = {};
intervals.forEach((item) => {
  intervalsMap[item.value] = item.text;
});

module.exports = { intervals, intervalsExclude, intervalsMap };
