<template>
  <div class="campaign-creator-form">
    <!-- GENERAL INFORMATION SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>General Information</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Advertiser -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('advertiser')" v-bind="$_getLabelProps('advertiser')">
              <cc-multi-select v-bind="$_getProps('advertiser')" v-on="$_getEvents('advertiser')" v-model="$c_components['advertiser'].value" />
            </component-wrapper>
          </div>

          <!-- Campaign Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Channel -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('channel')" v-bind="$_getLabelProps('channel')">
              <cc-radio-button v-bind="$_getProps('channel')" v-on="$_getEvents('channel')" v-model="$c_components['channel'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- DEFINE YOUR AUDIENCE SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Define your Audience</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Language -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('language')" v-bind="$_getLabelProps('language')">
              <cc-multi-select v-bind="$_getProps('language')" v-on="$_getEvents('language')" v-model="$c_components['language'].value" />
            </component-wrapper>
          </div>

          <!-- Locations -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('locations')" v-bind="$_getLabelProps('locations')">
              <cc-multi-select v-bind="$_getProps('locations')" v-on="$_getEvents('locations')" v-model="$c_components['locations'].value" />
            </component-wrapper>
          </div>

          <!-- Gender -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('gender')" v-bind="$_getLabelProps('gender')">
              <cc-multi-select v-bind="$_getProps('gender')" v-on="$_getEvents('gender')" v-model="$c_components['gender'].value" />
            </component-wrapper>
          </div>

          <!-- Age -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('age')" v-bind="$_getLabelProps('age')">
              <cc-multi-select v-bind="$_getProps('age')" v-on="$_getEvents('age')" v-model="$c_components['age'].value" />
            </component-wrapper>
          </div>

          <!-- Device -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('device')" v-bind="$_getLabelProps('device')">
              <cc-multi-select v-bind="$_getProps('device')" v-on="$_getEvents('device')" v-model="$c_components['device'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- CAMPAIGN SETTINGS SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Campaign Settings</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Budget -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('budget')" v-bind="$_getLabelProps('budget')">
              <div class="row">
                <div class="col-md-6">
                  <cc-input v-bind="$_getProps('budget')" v-on="$_getEvents('budget')" v-model="$c_components['budget'].value" />
                </div>
                <div class="col-md-6" v-if="$_isActive('budgetType')">
                  <cc-radio-button v-bind="$_getProps('budgetType')" v-on="$_getEvents('budgetType')" v-model="$c_components['budgetType'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Bid Strategy -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('biddingStrategy')" v-bind="$_getLabelProps('biddingStrategy')">
              <cc-multi-select v-bind="$_getProps('biddingStrategy')" v-on="$_getEvents('biddingStrategy')" v-model="$c_components['biddingStrategy'].value" />
            </component-wrapper>
          </div>

          <!-- Conversion Counting -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('conversionCounting')" v-bind="$_getLabelProps('conversionCounting')">
              <cc-multi-select v-bind="$_getProps('conversionCounting')" v-on="$_getEvents('conversionCounting')" v-model="$c_components['conversionCounting'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- CREATE GROUP SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Create Group</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Ad Group Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('adGroupName')" v-bind="$_getLabelProps('adGroupName')">
              <cc-input v-bind="$_getProps('adGroupName')" v-on="$_getEvents('adGroupName')" v-model="$c_components['adGroupName'].value" />
            </component-wrapper>
          </div>

          <!-- Bid Strategy View -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('biddingStrategy')" v-bind="$_getLabelProps('biddingStrategy')">
              <template v-if="$c_components['biddingStrategy'].value">{{ $c_components['biddingStrategy'].value.name }}</template>
            </component-wrapper>
          </div>

          <!-- AdGroupCpc -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('adGroupcpc')" v-bind="$_getLabelProps('adGroupcpc')">
              <cc-input v-bind="$_getProps('adGroupcpc')" v-on="$_getEvents('adGroupcpc')" v-model="$c_components['adGroupcpc'].value" />
            </component-wrapper>
          </div>
        </div>
        <div class="row">

          <!-- Date Range -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('adGroupSchedule')" v-bind="$_getLabelProps('adGroupSchedule')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('adGroupSchedule')" v-on="$_getEvents('adGroupSchedule')" v-model="$c_components['adGroupSchedule'].value" />
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <cc-date-picker v-if="$_isActive('startDateStr')" v-bind="$_getProps('startDateStr')" v-on="$_getEvents('startDateStr')" v-model="$c_components['startDateStr'].value" class="col-auto" />
                    </div>
                    <div class="col-md-6">
                      <cc-date-picker v-if="$_isActive('endDateStr')" v-bind="$_getProps('endDateStr')" v-on="$_getEvents('endDateStr')" v-model="$c_components['endDateStr'].value" class="col-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- <component-wrapper legend="General information"></component-wrapper> -->

    <!-- Advertiser -->
    <!-- <component-wrapper v-if="$_isActive('advertiser')" v-bind="$_getLabelProps('advertiser')">
      <cc-multi-select v-bind="$_getProps('advertiser')" v-on="$_getEvents('advertiser')" v-model="$c_components['advertiser'].value" />
    </component-wrapper> -->

    <!-- Campaign Name -->
    <!-- <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
      <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
    </component-wrapper> -->

    <!-- Channel -->
    <!-- <component-wrapper v-if="$_isActive('channel')" v-bind="$_getLabelProps('channel')">
      <cc-radio-button v-bind="$_getProps('channel')" v-on="$_getEvents('channel')" v-model="$c_components['channel'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Define your audience"></component-wrapper> -->

    <!-- Language -->
    <!-- <component-wrapper v-if="$_isActive('language')" v-bind="$_getLabelProps('language')">
      <cc-multi-select v-bind="$_getProps('language')" v-on="$_getEvents('language')" v-model="$c_components['language'].value" />
    </component-wrapper> -->

    <!-- Locations -->
    <!-- <component-wrapper v-if="$_isActive('locations')" v-bind="$_getLabelProps('locations')">
      <cc-multi-select v-bind="$_getProps('locations')" v-on="$_getEvents('locations')" v-model="$c_components['locations'].value" />
    </component-wrapper> -->

    <!-- Gender -->
    <!-- <component-wrapper v-if="$_isActive('gender')" v-bind="$_getLabelProps('gender')">
      <cc-multi-select v-bind="$_getProps('gender')" v-on="$_getEvents('gender')" v-model="$c_components['gender'].value" />
    </component-wrapper> -->

    <!-- Age -->
    <!-- <component-wrapper v-if="$_isActive('age')" v-bind="$_getLabelProps('age')">
      <cc-multi-select v-bind="$_getProps('age')" v-on="$_getEvents('age')" v-model="$c_components['age'].value" />
    </component-wrapper> -->

    <!-- Device -->
    <!-- <component-wrapper v-if="$_isActive('device')" v-bind="$_getLabelProps('device')">
      <cc-multi-select v-bind="$_getProps('device')" v-on="$_getEvents('device')" v-model="$c_components['device'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Campaign settings"></component-wrapper> -->

    <!-- Budget -->
    <!-- <component-wrapper v-if="$_isActive('budget')" v-bind="$_getLabelProps('budget')">
      <div class="row">
        <div class="col">
          <cc-input v-bind="$_getProps('budget')" v-on="$_getEvents('budget')" v-model="$c_components['budget'].value" />
        </div>
        <div class="col-auto" v-if="$_isActive('budgetType')">
          <cc-radio-button v-bind="$_getProps('budgetType')" v-on="$_getEvents('budgetType')" v-model="$c_components['budgetType'].value" />
        </div>
      </div>
    </component-wrapper> -->

    <!-- Bid Strategy -->
    <!-- <component-wrapper v-if="$_isActive('biddingStrategy')" v-bind="$_getLabelProps('biddingStrategy')">
      <cc-multi-select v-bind="$_getProps('biddingStrategy')" v-on="$_getEvents('biddingStrategy')" v-model="$c_components['biddingStrategy'].value" />
    </component-wrapper> -->

    <!-- Conversion Counting -->
    <!-- <component-wrapper v-if="$_isActive('conversionCounting')" v-bind="$_getLabelProps('conversionCounting')">
      <cc-multi-select v-bind="$_getProps('conversionCounting')" v-on="$_getEvents('conversionCounting')" v-model="$c_components['conversionCounting'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Create group"></component-wrapper> -->

    <!-- AdGroup Name -->
    <!-- <component-wrapper v-if="$_isActive('adGroupName')" v-bind="$_getLabelProps('adGroupName')">
      <cc-input v-bind="$_getProps('adGroupName')" v-on="$_getEvents('adGroupName')" v-model="$c_components['adGroupName'].value" />
    </component-wrapper> -->

    <!-- Bid Strategy View -->
    <!-- <component-wrapper v-if="$_isActive('biddingStrategy')" v-bind="$_getLabelProps('biddingStrategy')">
      <template v-if="$c_components['biddingStrategy'].value">{{ $c_components['biddingStrategy'].value.name }}</template>
    </component-wrapper> -->

    <!-- AdGroup Cpc -->
    <!-- <component-wrapper v-if="$_isActive('adGroupcpc')" v-bind="$_getLabelProps('adGroupcpc')">
      <cc-input v-bind="$_getProps('adGroupcpc')" v-on="$_getEvents('adGroupcpc')" v-model="$c_components['adGroupcpc'].value" />
    </component-wrapper> -->

    <!-- Date Range -->
    <!-- <component-wrapper v-if="$_isActive('adGroupSchedule')" v-bind="$_getLabelProps('adGroupSchedule')">
      <cc-radio-button v-bind="$_getProps('adGroupSchedule')" v-on="$_getEvents('adGroupSchedule')" v-model="$c_components['adGroupSchedule'].value" />
      <div class="row mt-3">
        <cc-date-picker v-if="$_isActive('startDateStr')" v-bind="$_getProps('startDateStr')" v-on="$_getEvents('startDateStr')" v-model="$c_components['startDateStr'].value" class="col-auto" />
        <cc-date-picker v-if="$_isActive('endDateStr')" v-bind="$_getProps('endDateStr')" v-on="$_getEvents('endDateStr')" v-model="$c_components['endDateStr'].value" class="col-auto" />
      </div>
    </component-wrapper> -->

  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'GeminiForm',
  mixins: [formMixin],
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;
    }
  }
</style>
