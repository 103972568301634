import { ABDependency, ABValue, Macro, MacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { FieldConfiguration } from '../../validation/ValidationTypes';

export type AnonymousField = {
  internalId: string;
  key: string;
  key_path: string[];
};

export type NameType = {
  name: string;
  macros?: Array<{
    value: string;
    valueType: string;
    type: string;
    field?: string;
  }>;
  fieldSeparator?: string;
  itemSeparator?: string;
};
// cartesian product of all variations
export const cartesian = <T>(...a: Array<ABDependency<T>[]>[]) =>
  a.reduce((acc, curr) => acc.map((d) => curr.map((e) => [d, e].flat(1)).flat(1)));

// Product cartesian by key, each key should be unique inside group
export const cartesianByKey = <T>(first: ABDependency<T>[][], second: ABDependency<T>[][]) => {
  const result = [];
  for (let fi = 0; fi < first.length; fi++) {
    const f = first[fi];
    if (f.length === 0) {
      result.push(f);
    }
    for (let si = 0; si < second.length; si++) {
      const s = second[si];
      result.push([...f, ...s]);
    }
  }
  return result;
};

// Given arrays [1,2], -[3,4], [5,6]
// Calculate the cartesian Product as:
// [[1,2,3,4], [1,2,5,6], [3,4,5,6]]
export const possibleCombinations = <T>(variables: Array<ABDependency<T>[]>[]): Array<ABDependency<T>[]> => {
  if (variables.length === 0) {
    return [];
  }
  return variables.reduce((acc, curr) => {
    if (acc.length === 0) {
      return curr;
    }
    if (curr.length === 0) {
      return acc;
    }
    return cartesianByKey(acc, curr);
  }, []);
};

function graphSearch(macro: string, searchInIds: string[], allMacros: Array<Macro>): Macro | undefined {
  if (!allMacros) return;
  const found = allMacros.find((m) => m.name === macro && searchInIds.includes(m.value.variableUniquePath));
  if (found) {
    return found;
  }
  // Go Recursive
  for (const m of allMacros) {
    if (m.nestedMacros) {
      const found = graphSearch(macro, searchInIds, m.nestedMacros);
      if (found) {
        return found;
      }
    }
  }
}

export function calculateName(
  nameWithMacros: NameType,
  allMacros: Array<Macro>,
  ab: ABDependency<ABValue<unknown>[]>[]
) {
  const allVariableGroupIds = ab
    .map((item) => [item.variableUniquePath, ...(item.abGroups || [])].filter(Boolean))
    .flat() as string[];

  // eslint-disable-next-line no-useless-escape
  const macrosInName = nameWithMacros.macros || [];
  const macrosInNameValues = macrosInName.map((macro) => {
    if (macro.type === 'text-field') {
      return macro.value;
    }
    const macroValue = graphSearch(macro.value, allVariableGroupIds, allMacros)?.value;
    return macroValue?.value || '';
  });

  return nameWithMacros.name + macrosInNameValues.join(nameWithMacros.fieldSeparator || ' ');
}

export function getAllMacroOptions(
  validation: Record<string, FieldConfiguration>,
  AbGroupsPublic: Record<string, { readableName: string; macros: Array<{}> }>,
  entityMacros: Array<{ macro: string; type: string; valueType: string; suffix: string }>
): Array<MacroOptions> {
  const campaignFields = Object.keys(validation);
  const publicGroups = Object.keys(AbGroupsPublic);
  const macros: Array<MacroOptions> = [];
  for (const group of publicGroups) {
    const groupMeta = AbGroupsPublic[group as keyof typeof AbGroupsPublic];
    const groupMacro: MacroOptions = {
      value: `#${group}_group#`,
      name: groupMeta.readableName,
      type: 'GROUP',
      valueType: 'GROUP',
      nestedMacros: groupMeta.macros.map((m: any) => ({
        value: `#${group.toUpperCase()}_${m.macro}#`,
        name: `${groupMeta.readableName} ${m.suffix}`,
        description: '',
        type: m.type,
        valueType: m.valueType,
        nestedMacros: [],
      })),
    };
    macros.push(groupMacro);
  }
  campaignFields.forEach((field) => {
    const fieldMeta = validation[field as keyof typeof validation];
    if (fieldMeta?.macros?.length) {
      const fieldMacro: MacroOptions = {
        value: `#${field}_field#`,
        type: 'FIELD',
        name: fieldMeta.readableName! || field,
        description: '',
        valueType: 'FIELD',
        nestedMacros:
          fieldMeta?.macros?.map((m: any) => ({
            value: `#${field.toUpperCase()}_${m.macro}#`,
            name: `${fieldMeta.readableName} ${m.suffix}`,
            description: '',
            type: m.type,
            field,
            valueType: m.valueType,
            nestedMacros: [],
          })) || [],
      };
      const groupIndex = macros.findIndex((m) => m.value === `#${fieldMeta.fieldGroup}_group#`);
      if (groupIndex !== -1) {
        macros[groupIndex].nestedMacros.push(fieldMacro);
      } else {
        macros.push(fieldMacro);
      }
    }
  });
  if (entityMacros) {
    entityMacros.forEach((m) => {
      macros.push({
        value: `#${m.macro}#`,
        name: m.suffix,
        type: m.type,
        valueType: m.valueType,
        nestedMacros: [],
      });
    });
  }
  return macros;
}
