<template>
  <b-form :ref="getScope()" :data-vv-scope="getScope()" @submit.prevent>
    <b-modal
      id="batchModal"
      @ok="$_handleOk"
      @show="$_clear"
      @shown="$refs.textArea.$el.focus()"
      ref="createGroupModal"
    >
      <h5 slot="modal-title">{{ title }} <small class="text-muted">( {{ $c_rows.items.length }} items will be affected )</small></h5>
      <b-form-group :state="hasError('textArea')">
        <b-form-textarea
          ref="textArea"
          :data-vv-as="fieldName"
          v-validate="'required'"
          data-vv-validate-on="input"
          autocomplete="false"
          v-model="textArea"
          name="textArea"
          :placeholder="placeholder"
          :rows="15"
          :max-rows="20"
        >
        </b-form-textarea>
        <small slot="invalid-feedback">{{ getError('textArea') }}</small>
        <small class="text-error" v-if="$c_rows.invalid">( {{ $c_rows.invalid }} invalid items )</small>
      </b-form-group>
    </b-modal>
  </b-form>
</template>

<script>
const scope = 'batchModal';
export default {
  name: 'BatchModal',
  props: {
    title: { type: String, default: 'Batch Edit' },
    placeholder: { type: String, default: 'Enter Value' },
    fieldName: { type: String, default: 'Input' },
    format: { type: Object, default: () => ({ split: ',', colums: 2 }) },
    handler: { type: Function, default: async () => false },
  },
  data() {
    return {
      textArea: '',
    };
  },
  computed: {
    $c_rows() {
      const result = {
        invalid: 0,
        items: [],
      };
      const text = this.textArea.replace(/(\r\n|\n|\r)/gm, '\n').trim();
      if (text.length) {
        const rows = text.split('\n');
        rows.forEach((row) => {
          const arr = row.split(this.format.split).map((str) => str.trim()).filter((str) => str !== '');
          if (arr.length === this.format.colums) {
            result.items.push(arr);
          } else {
            result.invalid++;
          }
        });
      }
      return result;
    },
  },
  methods: {
    show() {
      this.$refs.createGroupModal.show();
    },
    hide() {
      this.$refs.createGroupModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.getScope());
      if (isValid && this.$c_rows.items.length) {
        this.handler(this.$c_rows.items);
        this.$_clear();
        this.hide();
      }
    },
    $_clear() {
      this.textArea = '';
      this.$validator.reset(this.getScope());
    },
    getScope() {
      return scope;
    },
    hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 576px) {
  #batchModal .modal-dialog {
        max-width: 500px !important;
  }
}
</style>
