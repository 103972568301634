var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Loading,{staticClass:"ad-container position-relative pointer blue-loader",class:{
    selected: _vm.data.selected,
    'for-headlines': _vm.mode === _setup.MediaManager.Mode.HEADLINES,
  },attrs:{"loading":_vm.isLoading}},[(!_vm.isLoading)?_c('div',{ref:"checkboxRef",staticClass:"position-absolute checkbox",class:{
      'action-hidden': !_vm.isSelectionActivated && !_vm.data.selected && _vm.mode !== _setup.MediaManager.Mode.HEADLINES,
    }},[_c(_setup.AdCheckbox,{attrs:{"zoomValue":1.1},model:{value:(_vm.data.selected),callback:function ($$v) {_vm.$set(_vm.data, "selected", $$v)},expression:"data.selected"}})],1):_vm._e(),_c('div',{staticClass:"card-container p-14",on:{"click":_setup.onCardContainerClick}},[_c('div',{staticClass:"mb-14",class:{ 'mt-32': _vm.mode === _setup.MediaManager.Mode.HEADLINES }},[(_vm.data.image && _vm.data.image.base64)?_c('img',{staticClass:"image user-select-none",style:([_vm.data.headline ? {} : { borderRadius: '5px' }]),attrs:{"src":_vm.data.image.base64,"alt":"image","loading":"lazy"},on:{"error":_setup.onImageError}}):_vm._e(),(_vm.data.headline)?_c('div',{staticClass:"text text-container p-12",class:{
          'mt-0': !_vm.data.image,
        }},[_c('div',{staticClass:"fs-14 fw-500 lh-20 limit-rows-1"},[_vm._v(" "+_vm._s(_vm.data.headline)+" ")])]):_vm._e()]),(_setup.isTrafficSourceContainer)?_c('div',{staticClass:"d-flex align-items-center traffic-source-container",class:{
        'ml-0': !_vm.data.usedOn || _vm.data.usedOn < 1,
        'mb-14': _vm.data.tags.length,
      }},[_c('div',{class:{ 'border-circle': !_vm.data.usedOn }},[_c('img',{staticClass:"icon",class:{
            hidden: !_vm.data.trafficSource || !_vm.data.trafficSource.imagePath,
          },attrs:{"src":_vm.data.trafficSource ? _vm.data.trafficSource.imagePath : undefined,"alt":_vm.data.trafficSource ? _vm.data.trafficSource.name : undefined}})]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
          title: _vm.data.usedOn
            ? `This ${_setup.capitalizeFirstLetter(_setup.replaceMode)} is being used in ${_vm.data.usedOn} ${
                _vm.data.trafficSource ? _vm.data.trafficSource.name : ''
              } ${_vm.data.usedOn === 1 ? 'campaign' : 'campaigns'}`
            : '',
          customClass: 'z-index-1',
          html: true,
        }),expression:"{\n          title: data.usedOn\n            ? `This ${capitalizeFirstLetter(replaceMode)} is being used in ${data.usedOn} ${\n                data.trafficSource ? data.trafficSource.name : ''\n              } ${data.usedOn === 1 ? 'campaign' : 'campaigns'}`\n            : '',\n          customClass: 'z-index-1',\n          html: true,\n        }",modifiers:{"hover":true,"right":true}}],staticClass:"campaigns-number d-flex align-items-center fs-12 fw-600 lh-16",class:{ 'hidden position-absolute': !_vm.data.usedOn }},[_vm._v(" "+_vm._s(_vm.data.usedOn)+" ")]),(_setup.metrics.length)?_c('div',{staticClass:"metrics d-flex flex-wrap col px-6"},_vm._l((_setup.metrics),function(item,key){return _c('div',{key:key,staticClass:"metric-data d-flex flex-column text text-uppercase fs-12 lh-16 col p-0"},[_c('div',{staticClass:"ml-14 fw-700"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"ml-14"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_vm._e()]):_vm._e(),(_vm.data.tags.length)?_c(_setup.TagsContainer,{attrs:{"tags":_setup.showTags,"isReadonly":true,"hiddenTags":_vm.data.tags.length - _setup.showTags.length}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }