export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export enum AlertStyle {
  stroke = 'stroke',
  lightFill = 'light-fill',
  fill = 'fill',
  coloredStroke = 'colored-stroke',
}

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  RIGHT_TOP = 'righttop',
  RIGHT_BOTTOM = 'rightbottom',
  LEFT_TOP = 'lefttop',
  LEFT_BOTTOM = 'leftbottom',
  BOTTOM_LEFT = 'bottomleft',
  BOTTOM_RIGHT = 'bottomright',
  TOP_LEFT = 'topleft',
  TOP_RIGHT = 'topright',
}

export enum PopoverTriggers {
  CLICK = 'click',
  HOVER = 'hover',
  FOCUS = 'focus',
  BLUR = 'blur',
}

export type TooltipPlacement = Values<typeof Position>;
