enum Task {
  ADVERTISE = 'ADVERTISE',
  ANALYZE = 'ANALYZE',
  CREATE_CONTENT = 'CREATE_CONTENT',
  MESSAGING = 'MESSAGING',
  MODERATE = 'MODERATE',
  MANAGE = 'MANAGE',
}

export type FBR<C> = {
  data: C;
  paging: {
    cursors: {
      before: string;
      after: string;
    };
  };
};

export enum SupportedLocationTypes {
  country = 'country',
  region = 'region',
  country_group = 'country_group',
  city = 'city',
  zip = 'zip',
}

export interface GeoTargeting {
  key: string;
  name: string;
  country: string;
  distance_unit: string | undefined;
  type: SupportedLocationTypes;
  country_code: string;
  country_name: string;
  region: string;
  region_id: number;
  supports_region: boolean;
  supports_city: boolean;
}

type FacebookPagePhotos = {
  id: string;
  webp_images?: Array<{ height: number; source: string; width: number }>;
  images?: Array<{ height: number; source: string; width: number }>;
};

export type FacebookPage = {
  access_token: string;
  category: string;
  category_list: {
    id: string;
    name: string;
  }[];
  connected_page_backed_instagram_account: {
    id: string;
  };
  name: string;
  id: string;
  tasks: Task[];
  instagramAccountId: string;
  instagramOptions: Array<{ id: string; username: string; profile_pic?: string }>;
  photos?: {
    data: Array<FacebookPagePhotos>;
    paging: { cursors: { before: string; after: string } };
  };
};

export type CustomConversionEvent = {
  id: string;
  label: string;
  custom_event_type: string;
  pixel: string;
};

export interface FacebookAPI {
  getPixelList(apiToken: string, adAccountId: string): Promise<FBR<{ id: string; name: string }[]>>;
  getCustomAudienceList(apiToken: string, adAccountId: string): Promise<FBR<{ id: string; name: string }[]>>;
  rawGet<T = any>(endpoint: string, params: Record<string, any>): Promise<{ data: T }>;
  getTargetLocation(
    apiToken: string,
    adAccountId: string,
    query: string,
    location_types: Array<SupportedLocationTypes>
  ): Promise<FBR<GeoTargeting[]>>;
  getFacebookPages(apiToken: string): Promise<FBR<FacebookPage[]>>;
  getInstagramAccounts(pageAccessToken: string, pageId: string): Promise<FBR<Array<{ id: string; username: string }>>>;
  createCampaign(campaign: any, accountId: string, apiToken: string): Promise<any>;
  createAdSet(adSet: any, accountId: string, apiToken: string): Promise<any>;
  createCreative(adSet: any, accountId: string, apiToken: string): Promise<any>;
  getCustomConversionList(
    apiToken: string,
    adAccountId: string
  ): Promise<
    FBR<
      {
        id: string;
        label: string;
        custom_event_type: string;
        pixel: { id: string };
      }[]
    >
  >;
}
