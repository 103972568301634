
<template>
  <div class="content-wrapper settings-container">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Settings</h1>
      </div>
    </div>

    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 5, filters: false, search: false }"
    />

    <b-tabs v-else class="settings-container__tabs" @activate-tab="$_changeTab" v-model="activeTabIndex">
      <b-tab title="Email"><email-settings :config="config" /></b-tab>
      <b-tab title="Slack"><slack-settings :config="config" /></b-tab>
      <b-tab title="Telegram"><telegram-settings :config="config" /></b-tab>
      <b-tab v-if="enableSubusers" title="Subusers"><subusers-settings /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Config from './config';
import EmailSettings from './tabs/EmailSettings';
import SlackSettings from './tabs/SlackSettings';
import TelegramSettings from './tabs/TelegramSettings';
import SubusersSettings from './tabs/SubusersSettings';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'Settings',
  components: { EmailSettings, SlackSettings, TelegramSettings, SubusersSettings },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      config: null,
      activeTabIndex: 0,
      enableSubusers: false,
    };
  },
  async created() {
    try {
      // Enable subusers only on custom users
      this.enableSubusers = this.$session.getUser().roles.indexOf('ROLE_SHOW_MULTIUSER_FEATURE') > -1;
      // Load Configs
      this.config = Config.getConfig();
      await this.config.notification.model.getSettings();
      // Catch Url Callback new Slack account
      const { code } = this.$route.query;
      if (code) this.activeTabIndex = 1; // Activate Slack tab
    } catch (error) {
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    }
    this.preloader = false;
  },
  methods: {
    async $_changeTab(newTabIndex, prevTabIndex, event) {
      // Notifications tabs
      if (prevTabIndex <= 2 && this.config.notification.model.hasChanges()) {
        event.preventDefault();
        try {
          const swal = await this.$swal({
            title: 'Continue with discarding changes!',
            showCancelButton: true,
            confirmButtonText: 'Yes, Discard changes',
            cancelButtonText: 'No, keep me in',
          });
          if (swal.value === true) {
            this.config.notification.model.resetSettings();
            this.activeTabIndex = newTabIndex;
          }
        } catch (err) { console.log(err); }
      }
    },
  },
};
</script>

<style lang="scss">
.settings-container {

  .section-header {
    margin-bottom: 3rem;
  }

  &__tabs {
    .nav-tabs {
      margin-bottom: 3.5rem;
      border: none;

      &:first-of-type {
        .nav-link {
          padding-left: 0;
        }
      }

      .nav-link {
        background: none;
        border: none;
        margin-right: 2rem;
        padding: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: $text-primary;
        border-bottom: .2rem solid transparent;

        &.active {
          color: $color-blue;
          border-bottom: .2rem solid $color-blue;
        }
      }
    }
  }
}
</style>
