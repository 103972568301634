
import BrandName from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/BrandName.ts.vue';
import ExistingContentsModal from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/ExistingContentsModal.ts.vue';
import Headlines from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/Headlines.ts.vue';
import TargetUrl from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/TargetUrl.vue';
import AdCheckbox from '@sh/components/MediaManager/AdCheckbox/AdCheckbox.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { getConfigData } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { Content, ExistingContent, Input } from '@sh/types/CampaignCreator';
import { ImageHelperType } from '@sh/types/ImageTypes';
import uid from 'uid';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  contents: Content[];
  modal: {
    index: null | number;
    content: {
      headlines: Input[];
      targetUrl?: string;
      brandName?: string;
    };
  };
  isExistingContentsModal: boolean;
  isEditContentModal: boolean;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    Headlines,
    TargetUrl,
    BrandName,
    OptimizerIcon,
    ExistingContentsModal,
    AdCheckbox,
  },
  mixins: [notifications],
  props: {
    showEdit: { type: Boolean, default: true },
    images: { type: Array as PropType<ImageHelperType[]>, required: true },
    headlines: { type: Array as PropType<Input[]>, required: true },
    targetUrl: { type: String, required: false },
    brandName: { type: String, default: null },
    cpc: { type: [String, Number], default: '' },
    sectionName: { type: String, default: '' },
    contentType: { type: String, default: '' },
    advertText: { type: String, default: null },
    category: { type: String, default: '' },
    description: { type: String, default: '' },
    sponsoredBy: { type: String, default: '' },
    displayUrl: { type: String, default: '' },
    uniqueName: { type: String, required: false },
    existingContents: { type: Array as PropType<ExistingContent[]>, default: () => [] },
    mode: {
      type: String,
      required: true,
      validator: (value: string) => ['create', 'edit', 'clone'].indexOf(value) !== -1,
    },
    trafficSourceType: {
      type: Object,
      required: false,
    },
    isValidForm: Boolean,
    validFromExisting: Boolean,
    type: { type: String, default: '' },
  },
  data(): ComponentData {
    return {
      contents: [],
      modal: {
        index: null,
        content: {
          headlines: [],
          targetUrl: '',
          brandName: '',
        },
      },
      isExistingContentsModal: false,
      isEditContentModal: false,
    };
  },
  computed: {
    replaceType(): string {
      return this.type === 'Ads' ? 'Creatives' : this.type;
    },
    entity(): string {
      return this.trafficSourceType.uniqueName === 'Taboola' || this.trafficSourceType.uniqueName === 'Outbrain'
        ? 'Ads'
        : 'Contents';
    },
    activeContent(): Content | undefined {
      if (this.modal.index !== null) return this.contents[this.modal.index];
      return undefined;
    },
    config(): ReturnType<typeof getConfigData> {
      return getConfigData(this.uniqueName);
    },
    isValidModalForm(): boolean {
      let isValid = true;
      if (!isValid || this.modal.content.headlines.length === 0 || this.modal.content.headlines[0].text === '')
        isValid = false;
      if (!isValid || !this.modal.content.targetUrl) isValid = false;
      if (this.brandName !== null) {
        if (!isValid || !this.modal.content.brandName) isValid = false;
      }
      return isValid;
    },
    disableTargetUrl(): boolean {
      if (this.config.target.disableEdit && this.mode === 'edit') return true;
      return false;
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
  },
  watch: {
    contents: {
      immediate: true,
      handler(items) {
        const contents: Partial<Content>[] = [];
        items.forEach((item: Content) => {
          if (item.selected) {
            const data: Partial<Content> = {
              headline: item.headline,
              targetUrl: item.targetUrl,
              imageMd5: item.image?.md5,
            };
            if (item.brandName) data.brandName = item.brandName;
            if (item.cpc) data.cpc = item.cpc;
            if (item.sectionName) data.sectionName = item.sectionName;
            if (item.contentType) data.contentType = item.contentType;
            if (item.advertText) data.advertText = item.advertText;
            if (item.category) data.category = item.category;
            if (item.advertText) data.advertText = item.advertText;
            if (item.description) data.description = item.description;
            if (item.sponsoredBy) data.sponsoredBy = item.sponsoredBy;
            if (item.displayUrl) data.displayUrl = item.displayUrl;
            if (item.id) Object.assign(data, { id: item.id });
            contents.push(data);
          }
        });
        this.$emit('input', contents);
      },
      deep: true,
    },
    images() {
      this.prepareContents();
    },
    headlines() {
      this.prepareContents();
    },
    targetUrl(value) {
      if (this.existingContents.length) {
        this.existingContents.forEach((item: ExistingContent) => {
          item.targetUrl = this.targetUrl;
        });
      }
      this.customUpdate('targetUrl', value);
    },
    brandName(value) {
      this.customUpdate('brandName', value);
    },
    cpc(value) {
      this.customUpdate('cpc', value);
    },
    sectionName(value) {
      this.customUpdate('sectionName', value);
    },
    contentType(value) {
      this.customUpdate('contentType', value);
    },
    category(value) {
      this.customUpdate('category', value);
    },
    description(value) {
      this.customUpdate('description', value);
    },
    cta(value) {
      this.customUpdate('cta', value);
    },
    advertText(value) {
      this.customUpdate('advertText', value);
    },
    sponsoredBy(value) {
      this.customUpdate('sponsoredBy', value);
    },
    displayUrl(value) {
      this.customUpdate('displayUrl', value);
    },
    existingContents(value: ExistingContent[]) {
      const newContents: ExistingContent[] = [];
      const newContentsObj: Record<string, ExistingContent> = {};
      value.forEach((item) => {
        newContentsObj[item.uid] = item;
      });
      this.contents.forEach((item) => {
        if (item.uid && newContentsObj[item.uid]) delete newContentsObj[item.uid];
      });
      for (const uid of Object.keys(newContentsObj)) {
        newContents.push(newContentsObj[uid]);
      }
      this.contents = [...this.prepareExisitingContents(newContents), ...this.contents];
    },
  },
  created() {
    this.prepareContents();
  },
  methods: {
    toggleSelect(index: number) {
      this.contents[index].selected = !this.contents[index].selected;
    },
    prepareExisitingContents(existingContents: ExistingContent[]) {
      const contents: Content[] = [];
      existingContents.forEach((content) => {
        const data: Content = {
          image: content.image,
          headline: { text: content.headline, ctaButton: content.cta, adDescription: content.description },
          targetUrl: content.targetUrl || this.targetUrl,
          uid: content.uid,
          id: content.id,
          selected: true,
          imageUrl: content.imageUrl,
        };

        if (this.brandName !== null) {
          data.brandName = this.brandName;
        }
        if (this.cpc) {
          data.cpc = this.cpc;
        }
        if (this.sectionName) {
          data.sectionName = this.sectionName;
        }
        if (this.contentType) {
          data.contentType = this.contentType;
        }
        if (this.category) {
          data.category = this.category;
        }
        if (this.description) {
          data.description = this.description;
        }
        if (this.advertText !== null) {
          data.advertText = this.advertText;
        }
        if (this.sponsoredBy) {
          data.sponsoredBy = this.sponsoredBy;
        }
        if (this.sponsoredBy) {
          data.displayUrl = this.displayUrl;
        }
        contents.push(data);
      });
      return contents;
    },
    prepareContents() {
      const contents = this.prepareExisitingContents(this.existingContents);
      this.images.forEach((image) => {
        this.headlines.forEach((headline) => {
          const data: Content = {
            image,
            headline,
            targetUrl: this.targetUrl,
            selected: true,
          };
          if (this.brandName !== null) data.brandName = this.brandName;
          if (this.cpc) data.cpc = this.cpc;
          if (this.sectionName) data.sectionName = this.sectionName;
          if (this.contentType) data.contentType = this.contentType;
          if (this.advertText) data.advertText = this.advertText;
          if (this.category) data.category = this.category;
          if (this.advertText) data.advertText = this.advertText;
          if (this.description) data.description = this.description;
          if (this.sponsoredBy) data.sponsoredBy = this.sponsoredBy;
          if (this.displayUrl) data.displayUrl = this.displayUrl;
          contents.push(data);
        });
      });
      this.contents = contents;
    },
    customUpdate(key: string, value: Content) {
      if (this.contents.length === 0) {
        this.prepareContents();
      } else {
        this.contents.forEach((item, index) => {
          this.$set(this.contents[index], key, value);
        });
      }
    },
    editContent(index: number) {
      this.modal.index = index;
      this.modal.content.targetUrl = this.activeContent?.targetUrl;

      if (this.brandName !== null) {
        this.modal.content.brandName = this.activeContent?.brandName;
      }
      this.isEditContentModal = true;
    },
    hiddenModificationslModal() {
      this.modal = {
        index: null,
        content: {
          headlines: [],
          targetUrl: '',
          brandName: '',
        },
      };
    },
    saveModifications() {
      const isValid = this.isValidModalForm;
      const { index } = this.modal;

      if (index !== null && isValid) {
        [this.contents[index].headline] = this.modal.content.headlines;
        this.contents[index].targetUrl = this.modal.content.targetUrl;

        if (this.modal.content.brandName) {
          this.contents[index].brandName = this.modal.content.brandName;
        }
        this.isEditContentModal = false;
      }
    },
    hasBrandName(content: { brandName?: string }): boolean {
      return typeof content.brandName !== 'undefined';
    },
    addFromExistingContents(data: ExistingContent[]) {
      data.forEach((item) => {
        item.uid = `existingContent-${uid(10)}`;
      });
      this.$emit('addFromExistingContents', data);
    },
    onExistingContentsModalShow() {
      if (this.validFromExisting) {
        this.isExistingContentsModal = true;
      } else {
        this.$n_infoNotification({ title: 'Please complete form before' });
      }
    },
  },
});
