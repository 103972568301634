<template>
  <div class="rule-template-tag-wrapper">

    <b-form-group :state="$_hasError('tags')">
      <b-input-group left="Rule Tags">
        <v-select
          id="templateTag"
          placeholder="Add Rule Tag..."
          class="form-control multiselect"
          :close-on-select="false"
          multiple
          :options="tagOptions"
          v-model="tags"
          name="tags"
          v-validate="'required'"
          data-vv-validate-on="search:blur|input"
        >
        </v-select>
        <template slot="right">
          <b-btn @click="$_openModal">
            <i class="fa fa-plus"></i> New Tag
          </b-btn>
        </template>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('tags') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleTemplateTag',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    tagOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      tags: [],
      options: [],
    };
  },
  watch: {
    tags: {
      immediate: true,
      handler() {
        this.$_emit('tags');
      },
      deep: true,
    },
  },
  created() {
    try {
      const optionsMap = {};
      this.tagOptions.forEach((option) => { optionsMap[option.value] = option; });
      // Create Tags if not exist
      this.tags = this.value.map((val) => {
        if (!optionsMap[val]) {
          return { value: val, label: `${val.charAt(0).toUpperCase()}${val.slice(1)}` };
        }
        return optionsMap[val];
      });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    $_openModal() {
      this.$emit('openModal');
    },
  },
};
</script>

<style lang="scss">
.rule-template-tag-wrapper {
  #templateTag.v-select {
    border-right-width: 0px;
  }
  .input-group {
    z-index: 9;
  }
}
</style>
