<template>
  <div id="config_account" class="account-config-container item max account-config-assertive-yield">
    <div class="account-config-header">
      <div class="account-config-header__title">
        <h2>Connect AY Entities</h2>
      </div>
    </div>
    <div v-if="collection.length && Object.keys($c_accountsMap).length > 0" class="collections">
      <b-btn v-for="(item, index) in collection" :key="index" block class="ga-view-btn" @click="$_edit(item, index)">
        <p class="collection-info">
          <span class="title">{{ $c_title(item.accountId || item.account) }}
            <i class="fa fa-info-circle info-icon" :id="`view-item-index-${index}`"></i>
          </span>
          <b-popover :target="`view-item-index-${index}`" triggers="hover focus">
            <!-- <template slot="title">Config</template> -->
            <p class="mb-1"><b>Entity:</b>
              {{ $c_title(item.accountId || item.account) }}</p>
          </b-popover>
        </p>
        <span v-if="!item.keep" class="remove-icon" @click.prevent.stop="$_remove(index)"><i class="fa fa-times"></i></span>
      </b-btn>
    </div>

    <preloader v-if="$c_preloader" margin="30px"></preloader>
    <b-btn v-else class="add-ga-view-btn" @click.prevent="modal.model = true">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9.5" stroke="white" />
        <rect x="9.58301" y="6.66602" width="0.833333" height="6.66667" fill="white" />
        <rect x="13.333" y="9.58301" width="0.833334" height="6.66667" transform="rotate(90 13.333 9.58301)"
              fill="white"
        />
      </svg>
      Add Assertive Yield Entity
    </b-btn>

    <!-- Modal Component -->
    <b-modal id="PreventModal" modal-class="optimizer-modal add-ga-view-modal" v-model="modal.model"
             :no-close-on-backdrop="true" :no-close-on-esc="true" size="lg" centered
    >
      <!-- Modal Header -->
      <template slot="modal-header">
        <h2 class="modal-header__title">Configure Tracking Tokens</h2>
        <svg @click="$_hideModal" class="modal-header__close-icon" width="14" height="14" viewBox="0 0 14 14" fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
          <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        </svg>
      </template>

      <b-form :ref="$_getScope()" @submit.prevent="$_validateForm" :data-vv-scope="$_getScope()" class="modal-body-form">
        <div class="row">
          <div class="col-md-6">
            <b-form-group class="optimizer-form-group" label="Entity:"
                          :invalid-feedback="$_getError('assertiveYieldAccounts')" :state="$_hasError('assertiveYieldAccounts')"
            >
              <vue-opti-select-light id="assertiveYieldAccounts"
                                     :class="[{ 'has-error': $_hasError('assertiveYieldAccounts') === false }, 'optimizer-select w-100']"
                                     name="assertiveYieldAccounts" v-validate="'required'" data-vv-as="Entity"
                                     data-vv-validate-on="input|update" data-vv-value-path="$c_model" :options="modal.options.accounts"
                                     label-key="text" v-model="modal.form.account"
                                     optionType="checkbox" button-block button-placeholder="-- Select Entity --"
              />
            </b-form-group>
          </div>
        </div>
        <div class="tokens">
          <h5 class="tokens__title">Tokens</h5>
          <div class="row">
            <div class="col-md-6" v-for="(token, key) in data.tokens" :key="key">
              <b-form-group class="optimizer-form-group">
                <template slot="label">
                  {{ token.message }} <b><code>{{ token.values.join(' Or ') }}</code></b>
                </template>
                <vue-opti-select-light id="`assertiveYield${key}`" class="optimizer-select w-100"
                                       :options="modal.options.tokens" label-key="text" allowClear
                                       @clear="() => { modal.form.tokens[key] = null }" :value="modal.form.tokens[key]"
                                       @change="({ value }) => { checkIfInCustomToken(value); modal.form.tokens[key] = value; $_tokenSelect(value, key); }"
                                       single button-block
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
      <div class="fieldsContainer mt-3">
        <div class="fieldRow">
          <switch-button :checked="modal.form.customTrackingEnabled"
                         :hover-title="{ enabled: 'Disable', disabled: 'Enable' }" :update="(value) => $_enableCustomTracking(value)"
                         class="mb-2"
          />
          <span class="mb-1">Enable Custom Tracking <i class="fa fa-info-circle info-icon"
                                                       style="color: rgba(84, 101, 130, 0.6)" v-b-tooltip.hover.top
                                                       title="Custom tracking allows to pass all Assertive Yield info in 1 utm token using a special format. This is useful if you are already using other utm tokens for other purposes and it is not possible to use them with TheOptimizer."
          /></span>
        </div>
        <div class="fieldRow">
          <template>
            <span class="fieldInput">
              <b-form-input class="optimizer-form-input" type="text" :placeholder="'Custom Tracking Token'"
                            :disabled="!modal.form.customTrackingEnabled" size="sm" :formatter="$_format"
                            v-model="modal.form.customTrackingToken"
              ></b-form-input>
            </span>
          </template>
          <template>
            <vue-opti-select-light class="optimizer-select w-100 mb-2 ml-2" :options="$_subOptions()" unique-key="id"
                                   label-key="name" single :value="modal.form.subSelected" allowClear
                                   @clear="() => { modal.form.subSelected = null }" button-block :disabled="!modal.form.customTrackingEnabled"
                                   button-placeholder="Select Subid"
                                   @change="({ id }) => { checkIfInTokens(id); modal.form.subSelected = id }"
            />
          </template>
        </div>
        <div class="fieldRow">
          <switch-button :checked="modal.form.applyRevenueBias" :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
                         :update="(value) => $_enableNetRevenue(value)" class="mb-2"
                         @change="({ value }) => { modal.form.applyRevenueBias = value; }" single
          />
          <span class="mb-1">Enable Net Revenue <i class="fa fa-info-circle info-icon"
                                                   style="color: rgba(84, 101, 130, 0.6)" v-b-tooltip.hover.top
                                                   title="By default Assertive Yield API is set to use Gross Revenue in reporting. Enable this option if instead you want Net Revenue to be used."
          /></span>
        </div>
      </div>
      <!-- Modal Footer -->
      <template slot="modal-footer">
        <b-btn class="secondary-button" @click="$_hideModal">Cancel</b-btn>
        <b-btn class="primary-button" @click="$_apply">Add</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Preloader from '@sh/components/Utils/Preloader.vue';

const scope = 'assertiveYieldForm';

export default {
  name: 'AssertiveYieldTokens',
  components: {
    Preloader,
  },
  props: {
    tracker: {
      type: Object,
      default: null,
      required: true,
    },
    source: {
      type: Array,
      default: null,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    accountId: null,
  },
  data() {
    return {
      data: {
        accounts: null,
        tokens: null,
      },
      collection: [],
      modal: {
        model: false,
        options: {
          accounts: [],
          tokens: [
            { text: 'utm_campaign', value: 'utmCampaign' },
            { text: 'utm_term', value: 'utmTerm' },
            { text: 'utm_content', value: 'utmContent' },
            { text: 'utm_source', value: 'utmSource' },
            { text: 'utm_medium', value: 'utmMedium' },
            { text: 'custom_1', value: 'custom_1' },
            { text: 'custom_2', value: 'custom_2' },
            { text: 'custom_3', value: 'custom_3' },
            { text: 'custom_4', value: 'custom_4' },
            { text: 'custom_5', value: 'custom_5' },
            { text: 'custom_6', value: 'custom_6' },
            { text: 'custom_7', value: 'custom_7' },
            { text: 'custom_8', value: 'custom_8' },
            { text: 'custom_9', value: 'custom_9' },
            { text: 'custom_10', value: 'custom_10' },
          ],
        },
        form: {
          account: null,
          tokens: {},
          index: null,
          customTrackingEnabled: false,
          customTrackingToken: '',
          subSelected: null,
          applyRevenueBias: false,
        },
      },
      preloaderMulti: { tracker: true, source: true },
    };
  },
  computed: {
    $c_preloader() {
      return this.preloaderMulti.tracker || this.preloaderMulti.source;
    },
    $c_accountsMap() {
      const accounts = {};
      if (this.data.accounts) {
        this.data.accounts.forEach((acc) => {
          accounts[acc.id] = { id: acc.id, name: acc.name };
        });
      }
      return accounts;
    },
    allowsCustomTracking() {
      const allowCustomTrackingList = ['AssertiveYield'];
      return allowCustomTrackingList.includes(this.tracker.type.uniqueName);
    },
  },
  watch: {
    tracker: {
      immediate: true,
      async handler() {
        if (this.tracker.id) {
          this.preloaderMulti.tracker = true;
          // Get Assertive Yield Account Data
          const accounts = await this.$api.trackers.getAccounts(this.tracker.id);
          this.data.accounts = accounts;
          this.data.accounts.forEach((acc) => {
            this.modal.options.accounts.push({ text: acc.name, value: acc.id });
          });
          this.preloaderMulti.tracker = false;
        }
      },
    },
    source: {
      immediate: true,
      async handler() {
        if (this.source) {
          this.preloaderMulti.source = true;
          this.data.tokens = await this.$api.trafficSources.tokens(this.source[0].type.uniqueName);
          this.modal.form.customTrackingToken = Object.values(this.data.tokens).map((token) => token.values[0]).join('|');
          // Set default config
          const config = this.source[0].linkedTrackers[0];
          if (config) {
            const { variables } = config;
            if (Array.isArray(variables)) {
              const collection = [];
              variables.forEach((item) => {
                if (item) {
                  const itemPayload = {
                    keep: true,
                    applyRevenueBias: item.applyRevenueBias,
                    customTrackingToken: item.customTrackingToken,
                    account: (Array.isArray(item.accountId || item.account) ? item.accountId || item.account : [item.accountId, item.account]).filter(Boolean).map((e) => (e.value || e)).map((e) => ({ value: e })),
                    tokens: {},
                  };
                  const customTrackingToken = Object.keys(item).find((c) => c.includes('|'));
                  if (customTrackingToken) {
                    itemPayload.customTrackingToken = customTrackingToken;
                    itemPayload.customTrackingEnabled = true;
                    itemPayload.subSelected = item[customTrackingToken];
                  }
                  for (const key in this.data.tokens) {
                    const token = this.data.tokens[key];
                    if (item[token.values[0]]) itemPayload.tokens[key] = item[token.values[0]];
                  }
                  collection.push(itemPayload);
                }
              });
              this.collection = collection;
            }
          }
          this.preloaderMulti.source = false;
        }
      },
    },
    collection: {
      handler(collection) {
        const data = {
          variables: [],
        };
        if (collection.length) {
          collection.forEach((item) => {
            const itemPayload = {
              accountId: (item.account || item.accountId).map((e) => e.value || e),
            };
            for (const key in item.tokens) {
              itemPayload[this.data.tokens[key].values[0]] = item.tokens[key];
            }
            if (item.customTrackingEnabled && item.subSelected) {
              itemPayload[item.customTrackingToken] = item.subSelected;
            }
            itemPayload.applyRevenueBias = item.applyRevenueBias ?? this.modal.form.applyRevenueBias;
            data.variables.push(itemPayload);
          });
          this.$emit('completed', data);
        } else {
          this.$emit('invalid');
        }
      },
      deep: true,
    },
  },
  created() {
    this.$validator.extend('is_unique_view', {
      getMessage: (field) => `${field} already exist.`,
      validate: (value) => {
        const { form } = this.modal;
        if (form.index !== null && this.collection[form.index]) return true;
        return !this.collection.find((item) => item.viewId === value);
      },
    });
  },
  methods: {
    $c_title(accountId) {
      const accountList = Array.isArray(accountId) ? accountId : [accountId];
      return accountList.filter(Boolean).map((e) => (this.$c_accountsMap[e.value || e]?.name)).join('-').slice(0, 50);
    },
    async $_apply(event) {
      event.preventDefault();
      if (await this.$_validateForm()) {
        // Todo save data
        const tokens = { ...this.modal.form.tokens };
        const data = {
          accountId: this.modal.form.account.map((e) => e.value),
          tokens,
          customTrackingEnabled: this.modal.form.customTrackingEnabled,
          subSelected: this.modal.form.subSelected,
          applyRevenueBias: this.modal.form.applyRevenueBias,
        };

        if (this.modal.form.customTrackingEnabled && this.modal.form.subSelected) {
          data.customTrackingToken = this.modal.form.customTrackingToken;
        }
        if (this.modal.form.index !== null) {
          Object.assign(this.collection[this.modal.form.index], data);
        } else {
          this.collection.push(data);
        }
        this.modal.model = false;
      }
    },
    checkIfInCustomToken(id) {
      if (this.modal.form.subSelected === id) {
        this.modal.form.subSelected = null;
      }
    },
    checkIfInTokens(id) {
      const prevIndx = Object.values(this.modal.form.tokens).findIndex((c) => c === id);
      if (prevIndx > -1) {
        const prevToken = Object.keys(this.modal.form.tokens)[prevIndx];
        const tokens = { ...this.modal.form.tokens, [prevToken]: null };
        this.modal.form.tokens = tokens;
      }
    },
    $_edit(item, index) {
      this.modal.form.account = (item.accountId || item.account).map((e) => ({ value: e.value || e }));
      this.modal.form.tokens = { ...item.tokens };
      this.modal.form.index = index;
      this.modal.form.customTrackingEnabled = item.customTrackingEnabled;
      this.modal.form.applyRevenueBias = item.applyRevenueBias;
      this.modal.form.subSelected = item.subSelected;
      this.modal.form.customTrackingToken = item.customTrackingToken || Object.values(this.data.tokens).map((token) => token.values[0]).join('|');
      this.modal.model = true;
    },
    $_remove(index) {
      if (this.collection.length > 0) this.collection.splice(index, 1);
    },
    $_resetModal() {
      this.modal.form.account = null;
      this.modal.form.index = null;
      this.modal.form.subSelected = null;
      this.modal.form.applyRevenueBias = false;
      this.modal.form.customTrackingEnabled = false;
      for (const key in this.modal.form.tokens) {
        if (key !== 'utmCampaign') this.modal.form.tokens[key] = null;
      }
      this.$validator.reset(this.$_getScope());
    },
    validate() {
      return this.collection.length > 0;
    },
    async $_validateForm() {
      try {
        const validation = await this.$validator.validateAll(this.$_getScope());
        return !!validation;
      } catch (error) {
        return false;
      }
    },
    $_tokenSelect(value, key) {
      if (value !== null) {
        for (const key1 in this.modal.form.tokens) {
          if (key !== key1 && value === this.modal.form.tokens[key1]) this.modal.form.tokens[key1] = null;
        }
      }
    },
    $_enableCustomTracking(value) {
      this.modal.form.customTrackingEnabled = value;
    },
    $_enableNetRevenue(value) {
      this.modal.form.applyRevenueBias = value;
    },
    $_subOptions() {
      return [{ name: 'utm_campaign', id: 'utmCampaign' }, { name: 'utm_term', id: 'utmTerm' }, { name: 'utm_content', id: 'utmContent' }, { name: 'utm_source', id: 'utmSource' }, { name: 'utm_medium', id: 'utmMedium' }];
    },
    $_format(value) {
      return value.trim();
    },
    $_hideModal() {
      this.modal.model = false;
      this.$_resetModal();
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.account-config-assertive-yield {
  .collections {
    margin: 1rem 2rem;

    .ga-view-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      color: #546582;
      border: .1rem solid #D6DFE3;
      border-radius: .5rem;
      margin-bottom: 1rem;
      padding: .7rem 1rem;
      transition: .25s ease-in-out;

      &:hover {
        background: #f7f7f7;
      }

      .title {
        font-size: 1.6rem;
      }

      .info-icon {
        color: rgba(84, 101, 130, 0.6);
        margin-left: .3rem;
      }

      .remove-icon {
        position: absolute;
        right: 1rem;
        background: #7fa1f8;
        color: $color-white;
        padding: .1rem .3rem .2rem .3rem;
        line-height: .55;
        border-radius: 50%;
        font-size: 1.4rem;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }
  }

  .add-ga-view-btn {
    display: inline-flex;
    justify-content: center;
    padding: .7rem 1.5rem;
    margin: .5rem 2rem 2rem 2rem;
    font-size: 1.4rem;
    width: calc(100% - 4rem);
    background: #779dff;
    border-radius: .5rem;
    color: $color-white;
    border-color: #779dff;

    &:hover {
      background: #638efa;
      border-color: #638efa;
    }

    svg {
      margin-right: .7rem;
    }
  }
}

.add-ga-view-modal {
  .optimizer-form-group {
    margin-top: 1rem;
  }

  .tokens {
    &__title {
      width: 100%;
      background: #F0F5F7;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem;
      margin-top: 2.5rem;
      margin-bottom: .5rem;
    }

    code {
      color: rgb(235, 110, 21);
    }
  }

  .fieldsContainer {
    .fieldRow {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      b {
        width: 30%;
        margin-right: 1rem;
      }

      .fieldInput {
        width: 100%;
        // flex: 1;
        // margin-left: 1.5rem;
      }
    }
  }
}

// #config_account .tokens .fieldsContainer {
//   display: table;
// }
// #config_account .tokens .fieldRow {
//   display: table-row;
// }
// #config_account .tokens .fieldRow:hover {
//   display: table-row;
//   background-color: #eef1f5;
// }
// #config_account .tokens .fieldName {
//   display: table-cell;
//   width: 1%;
//   white-space: nowrap;
//   padding: 5px;
// }
// #config_account .tokens .fieldInput {
//   display: table-cell;
//   padding: 5px;
// }
// #config_account .tokens {
//   overflow-y: scroll;
//   height: 243px;
// }
</style>
