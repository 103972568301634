import { getTrafficSourceLogo } from '@sh/helpers';
import { apiStore } from '@sh/services/api';
import { Account, TrafficSource } from '@sh/types';
import { every, flatten, flattenDeep, isEmpty, isUndefined, omit, reject, sortBy, uniq, uniqBy } from 'lodash';
import { defineStore } from 'pinia';

export interface FilterResultProps {
  id: string;
  name: string;
  uniqueName: string;
}

export interface TrafficSourceFilteredProps extends Omit<FilterResultProps, 'id'> {
  imagePath: string;
}

// Component ID - Filter with different scopes are applied.
// For Each Component
type ComponentFilters = {
  savedFilters: Array<SupportedTrafficSourceFilters & { id: string; type: 'saved' | 'temp' }>;
  currentFiltersId: string;
  filterSession: SupportedTrafficSourceFilters;
};

interface TrafficSources {
  tsAccounts: Account[];
  filterState: Record<string, ComponentFilters>; // ComponentId / ComponentFilters
}

export interface SupportedTrafficSourceFilters {
  trafficSource: string[];
  linkedTrackers: string[];
  status: string;
  currency: string[];
  tags: string[];
}

export function extractTagsFromTsSettings(ts: Account): string[] {
  const calculatedTags: string[] = [...(ts.tags || [])];
  if (ts.settings.timezone) {
    calculatedTags.push(ts.settings.timezone);
  }
  if (ts.settings.currency) {
    calculatedTags.push(ts.settings.currency);
  }
  return uniq(calculatedTags);
}

function onFiltersApplied(options: SupportedTrafficSourceFilters, accounts: Account[]) {
  const { currency, linkedTrackers, status, tags, trafficSource } = options;
  let filterAccounts = [...accounts];
  if (!isEmpty(trafficSource)) {
    filterAccounts = filterAccounts.filter((ts) => trafficSource.includes(ts.trafficSourceType.uniqueName));
  }
  if (!isEmpty(linkedTrackers)) {
    filterAccounts = filterAccounts.filter((ts) =>
      ts.linkedTrackers.some((linkTracker) => linkedTrackers.includes(String(linkTracker.id)))
    );
  }
  if (!isEmpty(tags)) {
    filterAccounts = filterAccounts.filter((ts) => {
      const tsTags = [...(ts.tags || []), ...extractTagsFromTsSettings(ts)];
      return tsTags?.some((tag) => tags.includes(tag));
    });
  }
  if (!isEmpty(status)) {
    filterAccounts = filterAccounts.filter((ts) => status === 'all' || Number(status[0]) === ts.status);
  }

  if (!isEmpty(currency)) {
    filterAccounts = filterAccounts.filter((ts) => currency.includes(ts.settings.currency));
  }
  return filterAccounts;
}

export const useTrafficSourcesStore = defineStore('traffic-sources', {
  state: (): TrafficSources => ({
    filterState: {},
    tsAccounts: [],
  }),
  actions: {
    initFilters(componentId: string) {
      this.filterState = {
        [componentId]: {
          savedFilters: [],
          currentFiltersId: componentId,
          filterSession: {
            tags: [],
            currency: [],
            linkedTrackers: [],
            status: '',
            trafficSource: [],
          },
        },
      };
    },
    async registerAccounts() {
      const trafficSourceAccounts = await apiStore?.trafficSources.accounts();
      this.tsAccounts = this.transformTsAccounts(trafficSourceAccounts);
    },
    transformTsAccounts(accounts: Account[]) {
      return accounts.map((account) => {
        return {
          ...account,
          settings: {
            ...account?.settings,
            timezone: account?.settings?.timezone || '',
            currency: account?.settings?.currency || '',
          },
        };
      });
    },
  },
  getters: {
    filters: (state) => (componentId: string) => {
      const defaultFilters: SupportedTrafficSourceFilters = {
        tags: [],
        currency: [],
        linkedTrackers: [],
        status: '',
        trafficSource: [],
      };

      const currentComponentFilters = state.filterState[componentId] || {};
      const filters = currentComponentFilters.savedFilters?.find(
        (e) => e.id === currentComponentFilters.currentFiltersId
      );
      return filters ?? defaultFilters;
    },
    getAllAvailableTagsForAccounts: (state) => (ts: string[]) => {
      const filterOptions: SupportedTrafficSourceFilters = {
        linkedTrackers: [],
        status: '',
        currency: [],
        tags: [],
        trafficSource: ts,
      };
      const availableAccounts = onFiltersApplied(filterOptions, state.tsAccounts);
      const allTags = availableAccounts
        ?.map((item) => [...extractTagsFromTsSettings(item), ...(item.tags || [])])
        .flat();
      return reject([...new Set(allTags)], isUndefined);
    },
    filterTrafficSourceAccounts: (state) => (filterOptions: SupportedTrafficSourceFilters) =>
      onFiltersApplied(filterOptions, state.tsAccounts),
    getFilters: (state) => (componentId: string) =>
      state.filterState[componentId]?.filterSession || {
        tags: [],
        currency: [],
        linkedTrackers: [],
        status: '',
        trafficSource: [],
      },
    getTrafficSourceUniqueNames: (state): TrafficSourceFilteredProps[] => {
      const TsTypeOptions = state?.tsAccounts?.reduce(
        (accumulator: Record<string, TrafficSourceFilteredProps>, currentValue) => {
          accumulator[currentValue.trafficSourceType.uniqueName as unknown as string] = {
            name: currentValue.trafficSourceType.name,
            uniqueName: currentValue.trafficSourceType.uniqueName,
            imagePath: getTrafficSourceLogo(currentValue.trafficSourceType.uniqueName as TrafficSource),
          };
          return accumulator;
        },
        {}
      );
      return Object.values(TsTypeOptions);
    },
    getLinkedTrackers: (state) => {
      const linkedTrackers = state.tsAccounts?.map((item) =>
        item.linkedTrackers.map((el) => ({
          id: String(el.id),
          name: el.name,
          uniqueName: el.uniqueName,
        }))
      );
      return uniqBy(flattenDeep(linkedTrackers), (value) => value.id);
    },
    getTags: (state) => {
      const settingsTagsArray = state.tsAccounts?.map((item) => [
        ...extractTagsFromTsSettings(item),
        ...(item.tags || []),
      ]);
      // Flatten the array of arrays into a single array and then remove duplicates from the array
      const flattenedTagsArray = flatten(settingsTagsArray);
      const uniqueTagsArray = uniq(flattenedTagsArray);
      return sortBy(uniqueTagsArray, (tag: string) => tag.toLowerCase());
    },
    getCurrencies: (state) => {
      const settingsTagsArray = state.tsAccounts
        ?.filter((obj) => obj.settings?.currency)
        .map((item) => item.settings.currency);
      // Flatten the array of arrays into a single array and then remove duplicates from the array
      const flattenedTagsArray = flatten(settingsTagsArray);
      const uniqueTagsArray = uniq(flattenedTagsArray);
      return sortBy(uniqueTagsArray, (tag: string) => tag.toLowerCase());
    },
    getFilterResultCount: (state) => (componentId: string) =>
      onFiltersApplied(state.filterState[componentId].filterSession, state.tsAccounts).length,
    getFilteredTrafficSources: (state) => (componentId: string) => {
      const selectedTs = state.filterState[componentId].savedFilters.find((el) => el.id === componentId);
      return onFiltersApplied(omit(selectedTs, ['id', 'type']), state.tsAccounts);
    },
    getIsFilterUsed: (state) => (componentId: string) => every(state.filterState[componentId]?.filterSession, isEmpty),
  },
});
