import { isVideoUrl } from '@sh/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isContentPreview: false,
      previewContent: undefined,
    };
  },
  methods: {
    isVideoUrl,
    onPreviewOpen(item) {
      if (!item.hasVideoError) {
        this.isContentPreview = true;
        this.previewContent = item;
        // this.videoUrl = item.image_url.replace('http://', '//'); TO-DO check again
      }
    },
  },
});
