/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import { CustomConversionEvent, FacebookPage } from '@/views/Automation/CampaignCreatorV2/types';
/* eslint-disable import/no-cycle */
import { api, apiStore } from '@sh/services/api';
import { Account, TrafficSource } from '@sh/types';
import each from 'each.js';
import moment from 'moment';
import { defineStore } from 'pinia';
import { AdspixelsDatum, FacebookBusiness, FacebookBusinessPixels } from '../helpers/facebook/AdAccount';

type Credentials = { accessToken: string; adAccountId: string; scopes?: string[] };

interface AdAccountStore {
  accountList: Array<Account<Credentials> & { isDisabled?: boolean; message?: string }>;
  pixelList: {
    [adAccountId: number]: { id: string; name: string }[];
  };
  customEventList: {
    [adAccountId: number]: CustomConversionEvent[];
  };
  facebookPages: {
    [adAccountId: number]: Array<{ facebookPageId?: string } & FacebookPage>;
  };
}

const useAdAccountStore = defineStore('facebookAdAccount', {
  state: (): AdAccountStore => ({
    accountList: [],
    pixelList: {},
    customEventList: {},
    facebookPages: {},
  }),
  getters: {
    getAdAccountsOptions(): Account<Credentials>[] {
      return this.accountList || [];
    },
    getPixelsOptions(): { id: string; name: string }[] {
      const navigation = useNavigationStore();
      const currentAccount = navigation.getAdAccountId;
      return this.pixelList[currentAccount] || [];
    },
    currentAdAccount(): (Account<Credentials> & { isDisabled?: boolean; message?: string }) | undefined {
      const navigation = useNavigationStore();
      const currentAccount = navigation.getAdAccountId;
      const account = this.accountList.find((e) => e.id === currentAccount);
      return account;
    },
    getCustomEventList(): CustomConversionEvent[] {
      const navigation = useNavigationStore();
      const currentAccount = navigation.getAdAccountId;
      return this.customEventList[currentAccount] || [];
    },
    getFacebookPages(): Array<{ facebookPageId?: string } & FacebookPage> {
      const navigation = useNavigationStore();
      const currentAccount = navigation.getAdAccountId;
      return this.facebookPages[currentAccount] || [];
    },
  },
  actions: {
    async fetchAdAccounts() {
      const accounts = await apiStore.trafficSources.accounts<Credentials>();
      this.accountList = accounts.filter((e) => e.trafficSourceType?.uniqueName === TrafficSource.Facebook && e.status);
      await this.fetchFacebookPages();
      const FacebookCurrenciesWithOffsetOne = [
        'VND',
        'TWD',
        'PYG',
        'KRW',
        'JPY',
        'IDR',
        'ISK',
        'HUF',
        'CRC',
        'COP',
        'CLP',
      ];
      this.accountList = this.accountList
        .map((a) => {
          if (FacebookCurrenciesWithOffsetOne.includes(a.settings.currency)) {
            return {
              ...a,
              isDisabled: true,
              message: `Your currency ${a.settings.currency} is not supported yet. (Please contact support)`,
            };
          }
          return a;
        })
        .sort((a) => (a.isDisabled ? 1 : -1));
    },
    async prepareAccount(accountId?: number) {
      const currentAccount = useAdAccountStore().currentAdAccount;
      if (currentAccount || accountId) {
        await Promise.allSettled([this.fetchPixelList(accountId), this.fetchFacebookPages(accountId)]);
      }
    },
    async fetchPixelList(accountId?: number) {
      const navigation = useNavigationStore();
      const currentAccount = navigation.getAdAccountId;
      const account = this.accountList.find((e) => e.id === accountId) || this.currentAdAccount;
      if (!account || !currentAccount) {
        return;
      }
      if (this.pixelList[currentAccount]?.[0]?.id === 'loading' || this.pixelList[currentAccount]?.length) {
        return;
      }
      this.pixelList = {
        ...this.pixelList,
        [currentAccount]: [{ id: 'loading', name: 'loading Pixel List...' }],
      };

      const { accessToken: access_token, adAccountId: uniqueAccountId } = account.credentials;
      try {
        const { data: pixelList } = await api.facebook.getPixelList(access_token, uniqueAccountId);
        const pixelsByBusiness = await api.facebook.rawGet<FacebookBusinessPixels[]>(`me/businesses`, {
          access_token,
          fields: 'adspixels{id,name}',
        });
        const allPixels = (
          [...pixelList, ...pixelsByBusiness.data?.map((e) => e.adspixels?.data || []).flat()].filter(
            Boolean
          ) as unknown as AdspixelsDatum[]
        ).reduce<Map<string, { id: string; name: string }>>((acc, c) => {
          if (acc.has(c.id)) {
            return acc;
          }
          acc.set(c.id, { id: c.id, name: c.name });
          return acc;
        }, new Map<string, { id: string; name: string }>());
        this.pixelList = {
          ...this.pixelList,
          [currentAccount]: Array.from(allPixels.values()),
        };
      } catch (e) {
        this.pixelList = {
          ...this.pixelList,
          [currentAccount]: [],
        };
      }
      await this.fetchCustomConversions(accountId);
    },
    async fetchFacebookPages(accountId?: number) {
      const accountByAccessToken = this.accountList.reduce((acc, e) => {
        if (!acc[e.credentials.accessToken]) {
          acc[e.credentials.accessToken] = [];
        }
        acc[e.credentials.accessToken].push(e);
        return acc;
      }, {} as Record<string, Account<Credentials>[]>);
      const promiseList = Object.values(accountByAccessToken).map(async (accountList) => {
        const { accessToken: access_token, scopes } = accountList[0].credentials;
        try {
          if (this.facebookPages[accountList[0].id]?.length) {
            return;
          }
          if (!scopes) {
            throw new Error(
              'Missing permissions for creating campaigns. Please go to Integrations and re-authenticate your Facebook Ad Account.'
            );
          }
          const account = accountList.find((e) => e.id === accountId) || this.currentAdAccount;
          if (!account || !accountList.some((a) => a.id === account.id)) {
            return;
          }

          const pages = await api.facebook.getFacebookPages(access_token);
          const pagesOfBusiness = await api.facebook.rawGet<FacebookBusiness[]>(`me/businesses`, {
            access_token,
            fields: 'client_pages{ id,name,access_token,photos{webp_images}}',
          });
          const allPages = (
            [...pages.data, ...(pagesOfBusiness.data?.map((e) => e.client_pages?.data).flat() || [])].filter(
              Boolean
            ) as FacebookPage[]
          ).reduce<Record<string, FacebookPage>>((acc, c) => {
            acc[c.id] = c;
            return acc;
          }, {});
          if (!Object.values(allPages).length) {
            throw new Error(
              'Ad Account does not have access to any facebook pages. Please go to Integrations and re-authenticate your Facebook Ad Account'
            );
          }
          const newPages: FacebookPage[] = [];

          await each.concurrent(
            Object.values(allPages),
            async (page) => {
              const { access_token, id } = page;
              const linkedAccountsPromise = api.facebook.rawGet(`${id}/instagram_accounts`, {
                access_token,
                fields: 'id,username,profile_pic',
              });
              const dummyNoInstagramIdPromise = api.facebook.getInstagramAccounts(access_token, id);
              const [linkedAccounts, dummyNoInstagramId] = await Promise.all([
                linkedAccountsPromise,
                dummyNoInstagramIdPromise,
              ]);
              const newIg = dummyNoInstagramId?.data?.[0]?.id;
              newPages.push({
                ...page,
                instagramAccountId: newIg,
                instagramOptions: [...(dummyNoInstagramId?.data || []), ...(linkedAccounts?.data || [])],
              });
            },
            4
          );
          const pagesByAcc = accountList.reduce((acc, c) => {
            acc[c.id] = newPages;
            return acc;
          }, {} as Record<number, FacebookPage[]>);
          this.facebookPages = {
            ...this.facebookPages,
            ...pagesByAcc,
          };
        } catch (e: any) {
          const message = e.isAxiosError ? e.response?.data?.error?.message : e.message;
          this.accountList = this.accountList.map((a) => {
            if (accountList.find((acc) => acc.id === a.id)) {
              return {
                ...a,
                isDisabled: true,
                message,
              };
            }
            return a;
          });
        }
      });
      await Promise.all(promiseList);
    },

    async startJob(jobTasks: any) {
      const account = this.currentAdAccount;
      if (!account) {
        return;
      }
      try {
        // @ts-ignore
        return await api.campaignCreator.startJob(jobTasks);
      } catch (err) {
        console.log('Failed to start job', err);
        throw err;
      }
    },
    async fetchCustomConversions(accountId?: number) {
      const account = this.accountList.find((a) => a.id === accountId) || this.currentAdAccount;
      if (!account) {
        return;
      }

      if (this.customEventList[account.id]?.[0]?.id === 'loading' || this.customEventList[account.id]?.length) {
        return;
      }
      try {
        this.customEventList = {
          ...this.customEventList,
          [account.id]: [{ id: 'loading', label: 'loading Custom Conversions...', custom_event_type: '', pixel: '' }],
        };

        const { accessToken: access_token } = account.credentials;
        // get all custom events registered on pixel.
        for (const pixelId of this.pixelList[account.id]) {
          const pixelEvents = await api.facebook.rawGet<Array<{ data: { value: string; count: number }[] }>>(
            `/${pixelId.id}/stats`,
            {
              access_token,
              aggregation: 'event_total_counts',
              filters: JSON.stringify([
                {
                  field: 'start_time',
                  operator: 'GREATER_THAN',
                  value: moment().subtract(30, 'd').format('YYYY-MM-DD'),
                },
              ]),
            }
          );
          this.customEventList = {
            ...this.customEventList,
            [account.id]: pixelEvents.data
              .map((e) => e.data)
              .flat()
              .sort((a, b) => a.count - b.count)
              .map((item) => ({
                id: item.value,
                label: item.value,
                custom_event_type: 'OTHER',
                custom_event_str: item.value,
                pixel: pixelId.id,
              })),
          };
        }
      } catch (e) {
        this.customEventList = {
          ...this.customEventList,
          [account.id]: [],
        };
      }
    },
    async getEstimatedReach(targetingSpec: any, optimize_for: string) {
      const account = this.currentAdAccount;
      if (!account) {
        console.log('getCustomConversions', 'no account');
        return;
      }

      const { accessToken: access_token, adAccountId: uniqueAccountId } = account.credentials;
      const audienceEstimate = await api.facebook.rawGet(`/${uniqueAccountId}/delivery_estimate`, {
        targeting_spec: targetingSpec,
        optimization_goal: optimize_for,
        access_token,
      });

      return audienceEstimate;
    },
  },
});

export default useAdAccountStore;
