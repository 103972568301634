import { defineComponent } from 'vue';

export default defineComponent({
  notifications: {
    $n_successNotification: {
      title: 'Success',
      message: '',
      type: 'success',
      timeout: 5000,
    },
    $n_failNotification: {
      title: 'Fail',
      message: '',
      type: 'error',
      timeout: 5000,
    },
    $n_infoNotification: {
      title: 'Information',
      message: '',
      type: 'info',
      timeout: 5000,
    },
    $n_warnNotification: {
      title: 'Information',
      message: '',
      type: 'warn',
      timeout: 5000,
    },
  },
  helpers: {
    $h_failNotification(error, path = 'response.data.message') {
      let title = 'An error occurred';
      try {
        const explodePath = path.split('.');
        let msgPointer = null;
        explodePath.forEach((key) => {
          msgPointer = msgPointer ? msgPointer[key] : error[key];
        });
        title = `${msgPointer}`;
      } catch (err) {
        // NOTHING
        console.log(err);
      }
      this.$n_failNotification({ title });
    },
    $h_reqFailNotification(error) {
      let title = 'An error occurred';
      if (error?.response?.data?.message) {
        title = error.response.data.message;
      }
      this.$n_failNotification({ title });
    },
  },
});

