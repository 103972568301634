<template>
  <div class="content-wrapper campaigns-container">
    <campaigns-performance-filter
      :loading="!ready.filter && ready.hasData"
      :show="ready.filter"
      v-model="filter"
      @input="$_changeFilter"
      @noData="() => {ready.hasData = false}"
    />

    <!-- Campaigns Stats Cards -->
    <CampaignsStats
      :cost="table.statsTotals.cost"
      :revenue="table.statsTotals.revenue"
      :net="table.statsTotals.net"
      :roi="table.statsTotals.roi"
      :cpc="table.statsTotals.cpc"
      :cpa="table.statsTotals.cpa"
      :epc="table.statsTotals.epc"
      :est_cost="table.statsTotals.est_cost"
      :est_net="table.statsTotals.est_net"
      :est_roi="table.statsTotals.est_roi"
      :stats_info="table.statsInfo"
      :loading="!$c_readyAll"
    />

    <!--CAMPAIGNS DATATABLE-->
    <loading-skeleton
      v-if="!$c_readyAll"
      class="margin-top"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
    />
    <div v-else :class="`campaigns-container__table optimizer-table campaigns-table ${filter.type.uniqueName}`">
      <vue-opti-table-light
        v-if="table.fields.length > 0"
        :name="$c_tableName"
        :server-side-pagination="true"
        :pages="pages"
        :page="page"
        :hover="true"
        @on-pagination="$_setPaginationValues($event)"
        :export-label="$c_exportLabel"
        @on-search="$_setSearchValue($event)"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        :selectable="true"
        @on-row-per-page-change="$_setPaginationValues($event)"
        select-label="Campaigns"
        @on-sort="$_setPaginationValues($event)"
        :header-fields="$c_tableHeaders"
        @on-column-filter="$_setColumnFilterValues($event)"
        :items="table.items"
        v-model="tableModel"
        :sort="{ key: 'cost', order: 'desc' }"
        :default-rows="defaultRows"
        :totals="table.totals"
        :save-settings="$_saveSettings"
        :export-csv-items="$_exportCsvItems"
        :column-filter-enable="true"
        :column-filter.sync="columnFilter"
        :column-filter-reset="false"
        :update-custom-metric="$_updateCustomMetric"
        :custom-metric-options="$options.customMetricOptions"
        :metric-group-options="$options.metricGroupOptions"
        :nativeFields="$c_nativeFields"
        :hasComparisonColumns="true"
        :hasGroups="true"
        :hasPresets="true"
        :presetList="presets"
        :selectedPreset="selectedPreset"
        :deletePreset="$_deletePreset"
        :editPreset="$_editPresetName"
        :savePreset="$_createPreset"
        :changePreset="$_changePreset"
        :updateComparisonColumns="updateComparisonColumns"
        infoType="popover"
        :showHeaderPopover="true"
        :showSubUserSettings="hasSubuserFeatureAccess"
        :switchPresetAccess="switchPresetAccess"
        focusSelectedRows
        showTooltipBeforeText
        row-key="id"
        sticky
      >
        <template #search>
          <div class="d-flex flex-grow-1">
            <vue-opti-select-light
              :class="[ { 'active-select': tableModel.selectedRows.length }, 'optimizer-select icon-select dropdown-auto-width pr-md-2 col-md-auto mb-2 mb-md-0']"
              :options="$c_bulkActionOptions"
              unique-key="value"
              label-key="content"
              button-type="static"
              single.prevent
              prevent
              single
              :disabled="$c_disableHeaderButtons"
              @click="(item) => { $_bulkAction(item.value) }"
            >
              <template #BUTTON_PLACEHOLDER>
                <span class="button-placehoder-filter">
                  <i class="fa fa-ellipsis-h" />
                  <span>Actions</span>
                </span>
              </template>
            </vue-opti-select-light>

            <CampaignsFilters
              @on-filter-changed="onFiltersChange"
              :status-options="statusOptions"
              tags-level="campaign"
              :ts-type="filter.type.uniqueName"
              :show-managed-status-tab="true"
            />

            <!-- Gemini Advertisers -->
            <vue-opti-select-light
              v-if="filter.type.uniqueName === 'Gemini'"
              class="optimizer-select icon-select col-md-auto pl-md-0 pr-md-2 mb-2 mb-md-0"
              :options="$c_advertiserOptions"
              :value="advertisersModel"
              @change="({ value }) => { advertisersModel = value }"
              single
            >
              <template #BUTTON_PLACEHOLDER="{ option }">
                <span class="button-placehoder-filter">
                  <i class="fa fa-filter"></i>
                  {{ option.content }}
                </span>
              </template>
            </vue-opti-select-light>

            <div class="col-md-auto mb-2 mb-md-0">
              <bulk-action
                level="campaign"
                :items="tableModel.selectedRows"
                :bulkConfig="trafficSources[$c_trafficSourceType]"
                :bulkAction="bulkAction"
                :showApplyChangesButton="showApplyChangesButton"
                :selectedRows="tableModel.selectedRows"
                :cancelHandler="$_cancelBulkOperation"
                :table="table"
                :toBeUpdatedItems="toBeUpdatedItems"
                :clearPreview="$_clearPreviewRows"
                :setFailedItems="$_setFailedItems"
                :switchApplyButton="$_switchApplyButton"
                :switchDisableActions="$_switchDisableActions"
                :removeStyles="$_removeStyles"
                :failedItems="failedItems"
                :setItems="$_setItems"
              />
            </div>

            <div v-show="userAction.loading" class="col-md-auto mb-2 mb-md-0" style="line-height: 2.5em">
              <i class="fa fa-spinner fa-spin" /> Processing...
            </div>

            <loadizer :loading="ready.pagination" />
          </div>

          <div class="refresh-button" v-b-tooltip title="Refresh Table">
            <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
              <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
            </b-btn>
          </div>
        </template>

        <template #actions="{ item }">
          <div class="d-flex justify-content-center">
            <switch-button
              :checked="item.enabled"
              :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
              :update="(value) => $_updateStatus(item, value)"
            />
            <action-btn v-if="$c_showCloneButton" type="clone" title="Clone" class="mr-2"
                        :click="() => $_showCloneModal(item)"
            />
            <action-btn v-if="$c_showEditButton" type="edit" title="Edit" class="ml-1"
                        :to="{ name: 'CampaignCreator', query: { account: item.traffic_source_account_id, id: item.traffic_source_campaign_id, mode: 'edit' } }"
            />
          </div>
        </template>
        <template #name="{ item }">
          <campaign-name :campaign="item" :traffic-source-type="filter.type" :tracker-accounts-map="trackerAccountsMap"
                         :campaign-rules-map="rules.campaignsMap" :route="$route" :moment="moment" :trafficSourcesLinkedTrackers="trafficSourcesLinkedTrackers"
                         :compareRanges="filter.compareRanges" :accounts="accounts"
          />
        </template>
        <template #tags="{ item }">
          <TagsList
            :isRemoveIconVisible="true"
            :tags="item?.tags"
            @enableTagManagement="onTagSelected(item)"
            @deleteTag="tag => handleSingleTagDelete(item, tag)"
            @tagClicked="onTagSelected(item)"
          />
        </template>
        <template
          slot="additional_fields.bid"
          slot-scope="props"
        >
          <span v-if="props.item.traffic_source_unique_name === 'GoogleAds' && (props.item.additional_fields.canChangeCpc === false || ['Maximize Conversion Value - Target ROAS'].includes(props.item.biddingStrategyType))">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="props.item.traffic_source_unique_name === 'Taboola' && ['Maximize conversions','Target CPA', '-'].includes(props.item.additional_fields.bid_strategy)">Auto
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <live-edit
            v-else
            :ref="`bidLiveEdit-${props.i}`"
            :key="`bidLiveEdit-${props.item.id}`"
            v-model="props.item.additional_fields.bid"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :suffix="$_setSuffixBidType(props.item)"
            :format="$_cpcSlotNumFormat(props.field.options)"
            :live-state="props.item.bidLiveEditState"
            field-name="Bid"
            :max-limit-warning="$_smartMaxCpcMaxLimit(props.item.avg_cpc, props.item.additional_fields.bid)"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'bid', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            :before-blur="value => $_updateBid(props.item.id, value)"
            @up="$_inlineEditArrayPress('bidLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('bidLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.target_cpa"
          slot-scope="props"
        >
          <span v-if="props.item.traffic_source_unique_name === 'GoogleAds' && (!['Maximize Conversion Value - Target ROAS', 'Target ROAS'].includes(props.item.biddingStrategyType))">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <live-edit
            v-else
            :ref="`targetCpaLiveEdit-${props.i}`"
            :key="`targetCpaLiveEdit-${props.item.id}`"
            :live-state="props.item.targetCpaLiveEditState"
            v-model="props.item.additional_fields.target_cpa"
            :prefix="$_getSymbol(props.item, 'prefix')"
            :suffix="$_getSymbol(props.item, 'suffix')"
            :format="$_cpcSlotNumFormat(props.field.options)"
            field-name="Target CPA"
            :before-blur="value => $_updateTargetCpa(props.item.id, value, props.item.traffic_source_unique_name)"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'target_cpa', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'target_cpa')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            @up="$_inlineEditArrayPress('targetCpaLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('targetCpaLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.bid_cap"
          slot-scope="props"
        >
          <span v-if="!props.item.traffic_source_unique_name === 'Outbrain' || ['Traffic', 'Awareness'].some(str => props.item.additional_fields.objective_type.includes(str))">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <live-edit
            v-else
            :ref="`bidCapLiveEdit-${props.i}`"
            :key="`bidCapLiveEdit-${props.item.id}`"
            :live-state="props.item.bidCapLiveEditState"
            v-model="props.item.additional_fields.bid_cap"
            :prefix="$_getSymbol(props.item, 'prefix')"
            :suffix="(props.item.traffic_source_unique_name === 'Outbrain') ? ` ${props.item.additional_fields.goal_type}` : ''"
            :format="$_cpcSlotNumFormat(props.field.options)"
            field-name="Bid Cap"
            :before-blur="value => $_updateBidCap(props.item.id, value, props.item.traffic_source_unique_name)"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'bid_cap', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid_cap')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            @up="$_inlineEditArrayPress('bidCapLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('bidCapLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.budget"
          slot-scope="props"
        >
          <span
            v-if="(props.item.additional_fields.budget === null || props.item.additional_fields.budget === 0) && props.item.traffic_source_unique_name === 'Facebook'"
          >-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="props.item.additional_fields.budget === 'null'">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="props.item.additional_fields.budget === -1">Unlimited
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="(props.item.additional_fields.daily_budget === 0 || props.item.additional_fields.daily_budget === null) && props.item.traffic_source_unique_name === 'Taboola'">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <live-edit
            v-else
            :ref="`budgetLiveEdit-${props.i}`"
            :key="`budgetLiveEdit-${props.item.id}`"
            :live-state="props.item.budgetLiveEditState"
            v-model="props.item.additional_fields.budget"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :suffix="$_setSuffixBudget(props.item)"
            :format="ui.numFormat"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'budget', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'budget')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            propertyType="budget"
            :before-blur="value => $_updateBudget(props.item, value)"
            @up="$_inlineEditArrayPress('budgetLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('budgetLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.daily_budget"
          slot-scope="props"
        >
          <span
            v-if="(props.item.additional_fields.daily_budget === null || props.item.additional_fields.daily_budget === 0) && (props.item.additional_fields.budget === null || props.item.additional_fields.budget === 0) && props.item.traffic_source_unique_name === 'Facebook'"
          >Using AdSet Budget
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span></span>
          <span
            v-else-if="props.item.additional_fields.daily_budget === null && props.item.additional_fields.budget === null && props.item.traffic_source_unique_name === 'TikTok'"
          >All
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="props.item.additional_fields.daily_budget === 'null'">-
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <span v-else-if="props.item.additional_fields.daily_budget === -1">Unlimited
            <span
              v-if="$_findPreviewRow(props.item.traffic_source_campaign_id, 'error', props.item.traffic_source_currency)"
              class="error"
              v-b-tooltip.hover.bottomleft
              title="Unable to update field, the action will be skipped!"
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
          </span>
          <live-edit
            v-else-if="(props.item.additional_fields.budget !== null && ['Facebook', 'TikTok'].includes(props.item.traffic_source_unique_name) && props.item.additional_fields.daily_budget === null) || (props.item.additional_fields.daily_budget === null || props.item.additional_fields.daily_budget === 0) && ['Taboola'].includes(props.item.traffic_source_unique_name)"
            :ref="`dailyBudgetLiveEdit-${props.i}`"
            :key="`dailyBudgetLiveEdit-${props.item.id}`"
            :live-state="props.item.dailyBudgetLiveEditState"
            v-model="props.item.additional_fields.budget"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :suffix="$_setSuffixBudget(props.item)"
            :format="ui.numFormat"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'daily_budget', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'daily_budget')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            :before-blur="value => $_updateBudget(props.item, value)"
            @up="$_inlineEditArrayPress('dailyBudgetLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('dailyBudgetLiveEdit', props.i, 'down')"
          />
          <live-edit
            v-else
            :ref="`dailyBudgetLiveEdit-${props.i}`"
            :key="`dailyBudgetLiveEdit-${props.item.id + 1}`"
            :live-state="props.item.dailyBudgetLiveEditState"
            v-model="props.item.additional_fields.daily_budget"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :suffix="$_setSuffixDailyBudget(props.item)"
            :preview="$_findPreviewRow(props.item.traffic_source_campaign_id, 'daily_budget', props.item.traffic_source_currency)"
            previewCustomStyle="preview-suffix"
            :failedItems="$_filterItemsToCorrespondingField(failedItems, 'daily_budget')"
            :failedItemKey="props.item.traffic_source_campaign_id"
            :format="ui.numFormat"
            :before-blur="value => $_updateDailyBudget(props.item, value)"
            @up="$_inlineEditArrayPress('dailyBudgetLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('dailyBudgetLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.cpc_desktop"
          slot-scope="props"
        >
          <live-edit
            :ref="`cpcDesktopLiveEdit-${props.i}`"
            :key="`cpcDesktopLiveEdit-${props.item.id}`"
            v-model="props.item.additional_fields.cpc_desktop"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :format="$_cpcSlotNumFormat(props.field.options)"
            field-name="Desktop Bid"
            :max-limit-warning="$_smartMaxCpcMaxLimit(props.item.avg_cpc, props.item.additional_fields.cpc_desktop)"
            :before-blur="value => $_updateBidCad(props.item.id, {cpc_desktop: value})"
            @up="$_inlineEditArrayPress('cpcDesktopLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('cpcDesktopLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.cpc_tablet"
          slot-scope="props"
        >
          <live-edit
            :ref="`cpcTabletLiveEdit-${props.i}`"
            :key="`cpcTabletLiveEdit-${props.item.id}`"
            v-model="props.item.additional_fields.cpc_tablet"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :format="$_cpcSlotNumFormat(props.field.options)"
            field-name="Tablet Bid"
            :max-limit-warning="$_smartMaxCpcMaxLimit(props.item.avg_cpc, props.item.additional_fields.cpc_tablet)"
            :before-blur="value => $_updateBidCad(props.item.id, {cpc_tablet: value})"
            @up="$_inlineEditArrayPress('cpcTabletLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('cpcTabletLiveEdit', props.i, 'down')"
          />
        </template>
        <template
          slot="additional_fields.cpc_mobile"
          slot-scope="props"
        >
          <live-edit
            :ref="`cpcMobileLiveEdit-${props.i}`"
            :key="`cpcMobileLiveEdit-${props.item.id}`"
            v-model="props.item.additional_fields.cpc_mobile"
            :prefix="symbols.get(props.item.traffic_source_currency) || '$'"
            :format="$_cpcSlotNumFormat(props.field.options)"
            field-name="Mobile Bid"
            :max-limit-warning="$_smartMaxCpcMaxLimit(props.item.avg_cpc, props.item.additional_fields.cpc_mobile)"
            :before-blur="value => $_updateBidCad(props.item.id, {cpc_mobile: value})"
            @up="$_inlineEditArrayPress('cpcMobileLiveEdit', props.i, 'up')"
            @down="$_inlineEditArrayPress('cpcMobileLiveEdit', props.i, 'down')"
          />
        </template>
        <template #payout="{ item, field, i }">
          <live-edit
            :ref="`payoutLiveEdit-${i}`"
            :key="`payoutLiveEdit-${item.id}`"
            v-model="item.payout"
            :prefix="symbols.get(item.traffic_source_currency) || '$'"
            :format="$_cpcSlotNumFormat(field.options)"
            field-name="Payout"
            :beforeBlur="value => $_updatePayout(item.id, value)"
            @up="$_inlineEditArrayPress('payoutLiveEdit', i, 'up')"
            @down="$_inlineEditArrayPress('payoutLiveEdit', i, 'down')"
          />
        </template>
        <template
          slot="status"
          slot-scope="props"
        >
          <span
            v-b-tooltip.hover.right
            :class="`status-item status-item-${props.item.status}`"
            :title="props.item.traffic_source_status"
            v-html="ui.campaign.status(props.item.status)"
          />
        </template>
        <template
          slot="avg_cpc"
          slot-scope="props"
        >
          <span
            v-if="props.item.traffic_source_unique_name === 'Gemini'"
            class="clickable-item"
            @click="$_showGeminiBidModal(props.item)"
          >
            {{ symbols.get(props.item.traffic_source_currency) || '$' }}<span class="value">{{ ui.numFormat(props.item.avg_cpc) }}</span>
          </span>
          <span
            v-else-if="props.item.traffic_source_unique_name === 'GoogleAds' && props.item.additional_fields.advertisingChannelType === 'SEARCH'"
            class="clickable-item"
            @click="$_showGoogleBidModal(props.item)"
          >
            {{ symbols.get(props.item.traffic_source_currency) || '$' }}<span class="value">{{ ui.numFormat(props.item.avg_cpc) }}</span>
          </span>
          <template v-else>
            {{ ui.currencyFormat(props.item.avg_cpc, symbols.get(props.item.traffic_source_currency)) }}
          </template>
        </template>
      </vue-opti-table-light>
    </div>
    <gemini-bid-modal
      ref="geminiBidModal"
      @updateBid="$_updateBidGemini"
    />
    <bulk-modal
      title="Change Daily Budget"
      placeholder="Enter Budget"
      field-name="Budget"
      ref="bulkDailyBudgetModal"
      :items="tableModel.selectedRows"
      right="<i class='fa fa-dollar'></i>"
      :handler="(value) => $_bulkUpdateBudget(value, 'daily')"
    />
    <bulk-modal
      title="Change Lifetime Budget"
      placeholder="Enter Budget"
      field-name="Budget"
      ref="bulkLifetimeBudgetModal"
      :items="tableModel.selectedRows"
      right="<i class='fa fa-dollar'></i>"
      :handler="(value) => $_bulkUpdateBudget(value, 'lifetime')"
    />
    <google-ads-bid-modal
      ref="googleAdsCampaignBidModal"
      @updateBid="$_updateBidGoogle"
    />
    <bulk-modal
      title="Change Target CPA"
      placeholder="Enter Target CPA"
      field-name="Target CPA"
      ref="bulkTargetCpaModal"
      :items="tableModel.selectedRows"
      right="<i class='fa fa-dollar'></i>"
      :handler="(value) => $_bulkUpdateTargetCpa(value)"
    />
    <bulk-modal
      title="Change Bid Cap"
      placeholder="Enter Bid Cap"
      field-name="Bid Cap"
      ref="bulkBidCapModal"
      :items="tableModel.selectedRows"
      right="<i class='fa fa-dollar'></i>"
      :handler="(value) => $_bulkUpdateBidCap(value)"
    />
    <rule-bulk-modal
      v-if="$c_trafficSourceType"
      ref="ruleBulkModal"
      :items="tableModel.selectedRows"
      :handler="(value) => $_setItemsThatWillBeUpdated(value, table.items)"
      :options="$_numericActiveColumns($c_displayedColumns, bulkAction)"
      level="campaign"
      :bulkConfig="trafficSources[$c_trafficSourceType]"
      :bulkAction="bulkAction"
    />
    <CloneModal ref="cloneModal" :campaign="campaign" />
    <AddRuleModal ref="addRuleModal" :campaign="selectedCampaigns" />
    <ComplianceModal ref="complianceModal" :entities="selectedCampaigns" @onNotification="onNotification" />
    <BulkTagInsertModal
      ref="campaignTagsRef"
      title="Add Tag"
      mode="campaign"
      :autocompleteItems="tagAutocompleteItems"
      @onTagsSave="onSaveClick"
      @onBulkTagInsert="onBulkClosed"
    />
  </div>
</template>

<script>
import CampaignsPerformanceFilter from '@sh/components/Other/CampaignsPerformanceFilter';
import data from './data';
import config from './bulkOperationsConfig.ts';
import tableFields from '@/helpers/fields/index';
import LiveEdit from '@sh/components/Utils/LiveEdit';
import notifications from '@sh/mixins/notifications';
import tableMixins from '@sh/mixins/table';
import campaignMixins from '@/mixins/campaign';
import BulkModal from '@sh/components/Utils/BulkModal';
import GeminiBidModal from './GeminiBidModal';
import GoogleAdsBidModal from './GoogleAdsBidModal';
import CampaignsStats from '@sh/components/Campaigns/CampaignsStats.vue';
import CampaignName from './components/CampaignName';
import CloneModal from './components/CloneModal.ts.vue';
import AddRuleModal from '@sh/views/Campaign/components/AddRuleModal.ts.vue';
import ComplianceModal from '@sh/views/Campaign/components/ComplianceModal.ts.vue';
import { useAppStore } from '@/stores';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { AbortController, canAccess, defaultColumnsWidth, timeout } from '@sh/helpers';
import { debounce, uniq } from 'lodash';
import RuleBulkModal from '@sh/components/Utils/RuleBulkModal.ts.vue';
import BulkAction from '@sh/components/Utils/BulkAction.ts.vue';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import { fieldsGroup } from '@sh/configurations/fields/nativeFields';
import { getFieldsAndFilters } from '@sh/configurations/fields/FieldFilter';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';
import { TrafficSource } from '@sh/types';
import { usePresetStore } from '@/stores/presets';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import CampaignsFilters from './components/CampaignsFilters.ts.vue';
import BulkTagInsertModal from '@sh/components/MediaManager/BulkTagInsertModal/BulkTagInsertModal.ts.vue';
import { useCampaignsStore } from '@/stores/campaigns-store';
import TagsList from '@sh/components/MediaManager/TagsContainer/TagsList.ts.vue';

export default {
  name: 'Campaigns',
  components: {
    TagsList,
    BulkTagInsertModal,
    CampaignsFilters,
    CampaignsPerformanceFilter,
    LiveEdit,
    GeminiBidModal,
    GoogleAdsBidModal,
    CampaignsStats,
    CampaignName,
    BulkModal,
    CloneModal,
    RuleBulkModal,
    BulkAction,
    AddRuleModal,
    ComplianceModal,
  },
  mixins: [notifications, tableMixins, campaignMixins, config, fieldsGroup, columnsMixin, presetHelper],
  data,
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_bulkActionOptions() {
      // append to bulkActionOptions TS specific actions
      const TsActionsKey = `${this.filter.type.uniqueName}.bulkActionOptions`;
      const allBulkActionOptions = [];
      if (this[TsActionsKey]) {
        allBulkActionOptions.push(...this[TsActionsKey].map((action) => {
          const current = this.trafficSourceBulkActions[action];
          if (current.isBulk) {
            const { action_name } = this.trafficSources[this.$c_trafficSourceType].entities.campaign.bulkActions[action];
            return {
              ...current,
              content: `<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; ${action_name}`,
            };
          }
          return current;
        }));
      }
      allBulkActionOptions.push(...this.bulkActionOptions.slice());

      if (this.canAccessComplianceProgram) {
        allBulkActionOptions.push(
          { value: 'allow_compliance', content: '<span class="outline-fa-icon dark"><i class="fa fa-plus"></i></span>&nbsp; Share with Transparency Program' },
          { value: 'remove_compliance', content: '<span class="outline-fa-icon dark"><i class="fa fa-times"></i></span>&nbsp; Remove from Transparency Program' },
        );
      }
      return allBulkActionOptions;
    },
    $c_displayedColumns() {
      const actionKey = 'tableModel';
      if (this[actionKey]) {
        return this[actionKey].displayColumns;
      }
      return [];
    },
    $c_reportingTimezone() {
      let { timezone } = this.filter.type;
      try {
        if (this.filter.accounts.length === 1 && this.filter.accounts[0].settings.timezone) {
          timezone = this.filter.accounts[0].settings.timezone;
        }
      } catch (error) {
        // Do nothing
      }
      return timezone;
    },
    $c_exportLabel() {
      return `${this.filter.type.name}_campaigns_performance_${this.filter.dateRange.startDate}_${this.filter.dateRange.endDate}`;
    },
    $c_trafficSourceType() {
      if (this.filter.type) {
        if (this.$c_availableTrafficSourcesCampaignLevel.includes(this.filter.type.uniqueName)) {
          return this.filter.type.uniqueName;
        }
        return null;
      }
    },
    $c_readyAll() {
      return this.ready.filter && this.ready.campaigns;
    },
    $c_tableName() {
      return `${this.filter.type.name}CampaignsTable`;
    },
    $c_showCloneButton() {
      return ['RevContent', 'Taboola', 'ContentAd', 'Outbrain', 'Gemini', 'TikTok', 'GoogleAds', 'Facebook'].indexOf(this.filter.type.uniqueName) > -1;
    },
    $c_simpleClone() {
      return ['TikTok', 'GoogleAds', 'Facebook'].indexOf(this.filter.type.uniqueName) > -1;
    },
    $c_showEditButton() {
      return ['RevContent', 'Taboola', 'ContentAd', 'Outbrain', 'Gemini'].indexOf(this.filter.type.uniqueName) > -1;
    },
    $c_advertiserOptions() {
      const options = [{
        value: 'all',
        content: 'All Advertisers',
      }];
      this.table.advertisers.forEach((item) => {
        options.push({
          value: item.advertiser_id,
          content: item.advertiser_name,
        });
      });
      return options;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.filter.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapState(useCampaignsStore, ['tagAutocompleteItems', 'filterState']),
    ...mapWritableState(useCampaignsStore, ['campaignsAccount', 'campaignFilters']),
    ...mapWritableState(useAppStore, ['campaignsStatusFilter']),
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  watch: {
    advertisersModel() {
      this.$_loadCampaignsPaginated();
    },
  },
  async created() {
    this.initialFilters();
    this.statusFilter = this.campaignsStatusFilter;
    this.$_registerUserAction();
    /* *********** Promise Data Map  ************* */
    const [trackerAccounts] = await Promise.all([this.$api.trackers.accounts(), this.$_apiRules()]);
    trackerAccounts.forEach((item) => {
      this.$set(this.trackerAccountsMap, [item.id], item);
    });
    /* *********************************************** */
    this.debouncedGetCampaigns = debounce(this.$_loadCampaignsPaginated, 500);
    this.trafficSourcesLinkedTrackers = await this.getTrafficSourcesLinkedTrackersMap();
    this.accounts = await this.$apiStore.trafficSources.accounts();
  },
  methods: {
    onTagSelected(item) {
      this.tableModel.selectedRows = [item];
      this.$refs.campaignTagsRef.handleOnOpenModal([item]);
    },
    getTagEditPayload(tags, id, action) {
      return {
        campaign_ids: id,
        tags,
        tsType: this.filter.type.uniqueName,
        action,
      };
    },
    async onSaveClick({ addedTags, deletedTags }) {
      try {
        const result = [];
        if (deletedTags.tags.length > 0) {
          const payload = this.getTagEditPayload(deletedTags.tags, deletedTags.id, 'remove');
          const removeTags = await this.$api.campaigns.updateCampaignsTags(payload);

          await new Promise((resolve) => setTimeout(resolve, timeout));
          result.push(removeTags);
        }

        if (addedTags.tags.length > 0) {
          const payload = this.getTagEditPayload(addedTags.tags, addedTags.id, 'add');
          const addTags = await this.$api.campaigns.updateCampaignsTags(payload);
          result.length === 0 && this.addNewTags(uniq(addedTags.tags));
          result.push(addTags);
        }
        await this.onRefreshClick();
        let updatedMessage = `Tags were ${addedTags.tags.length ? 'added' : 'deleted'} successfully!`;

        if (result.length === 2) {
          await this.getAllAvailableTagsForCampaign('campaign', this.filter.type.uniqueName);
          updatedMessage = 'Tags were successfully updated!';
        }
        if (result.length) {
          this.$n_successNotification({ title: updatedMessage });
        }
      } catch (e) {
        this.$n_failNotification({ title: `Failed to ${addedTags.tags.length ? 'add' : 'delete'} tags!` });
      }
    },
    async handleSingleTagDelete(item, tag) {
      const confirmModal = await this.$swal({
        title: `Delete '${tag}' tag?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        allowEnterKey: false,
        customClass: {
          container: 'confirm-delete-swal',
          confirmButton: 'primary-button primary-button--danger',
          cancelButton: 'secondary-button',
        },
      });

      if (confirmModal.value === true) {
        try {
          const payload = this.getTagEditPayload([tag], [item.id], 'remove');
          await this.$api.campaigns.updateCampaignsTags(payload);
          await this.onRefreshClick();
          // api call for tags on campaign-store
          this.getAllAvailableTagsForCampaign('campaign', this.filter.type.uniqueName);
          this.$n_successNotification({ title: 'Tags were deleted successfully!' });
        } catch (error) {
          this.$n_failNotification({ title: 'Failed to delete tags!' });
        }
      }
    },
    onBulkClosed() {
      if (this.tableModel.selectedRows.length === 1) {
        this.tableModel.selectedRows = [];
      }
    },
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async getTrafficSourcesLinkedTrackersMap() {
      const accounts = await this.$apiStore.trafficSources.accounts();
      return accounts.reduce((trafficSourcesLinkedTrackers, item) => {
        if (item.id) {
          trafficSourcesLinkedTrackers[item.id] = item.linkedTrackers.reduce((linkedTrackers, data) => {
            if (data.id) {
              linkedTrackers[data.id] = data;
            }
            return linkedTrackers;
          }, {});
        }
        return trafficSourcesLinkedTrackers;
      }, {});
    },
    // it is called on loading the page or when changing traffic source or tracker type
    // returns both first page elements and table totals and stats
    async $_loadCampaignsInit() {
      try {
        const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.filter.type.uniqueName);
        this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
        this.setFilter(this.filter);
        this.resizedColumns = this.getResizedColumns();
        this.ready.campaigns = false;
        let items = [];
        let totals = {};
        let settings = {};
        await this.getFilteredPresets();
        [settings, items, totals] = await Promise.all([this.$api.settings.getSettings(), this.$_apiCampaignsPerformancePaginated(), this.$_apiCampaignsTotals()]);

        this.canAccessComplianceProgram = await canAccess('ComlianceProgram');
        const userFields = await this.$_getUserFields();
        const hasTracker = Object.keys(this.trackerAccountsMap).length > 0;
        this.revenueFrom = this.$_getRevenueSource(settings, hasTracker);
        items.items = items.items.map((item) => {
          item.budgetLiveEditState = { state: 'READY' };
          item.dailyBudgetLiveEditState = { state: 'READY' };
          item.customBudgetLiveEditState = { state: 'READY' };
          item.targetCpaLiveEditState = { state: 'READY' };
          item.bidCapLiveEditState = { state: 'READY' };
          item.bidLiveEditState = { state: 'READY' };
          return item;
        });

        if (Object.keys(items).length > 0) {
          this.campaigns = items.items;
          this.table = {
            ...this.table,
            fields: [
              tableFields.getField('CAMPAIGNS', { name: 'actions' }),
              ...items.fields.map((item) => tableFields.getField('CAMPAIGNS', {
                name: item.item.key,
                uniqueName: this.filter.type.uniqueName,
              }, item)),
            ],
            items: this.campaigns.slice(0, 15),
            statsTotals: totals.statsTotals[this.revenueFrom],
            statsInfo: {
              cost: getFieldTextAndDescription(userFields.find((field) => (field.key === 'cost')), true).description,
              revenue: getFieldTextAndDescription(userFields.find((field) => (field.key === this.$_prefixedKey('revenue'))), true).description,
              net: getFieldTextAndDescription(userFields.find((field) => (field.key === this.$_prefixedKey('net'))), true).description,
              roi: getFieldTextAndDescription(userFields.find((field) => (field.key === this.$_prefixedKey('roi'))), true).description,
            },
          };
          this.insertTableItems();
        }
        this.ready.campaigns = true;

        // api call for tags on campaign-store
        this.getAllAvailableTagsForCampaign('campaign', this.filter.type.uniqueName);
      } catch (error) {
        console.log(error, 'error');
        if (!AbortController.isAbortError(error)) {
          this.$n_failNotification({ title: error?.response?.data?.message ?? 'We could not load campaigns, please try again or contact support!' });
          this.table = {
            ...this.table,
            items: [],
            statsTotals: {},
          };
          this.campaigns = [];
          this.ready.campaigns = true;
        }
      }
    },
    async $_getUserFields() {
      let userFields = [];
      try {
        const avMetrics = await this.$apiStore.dashboard.getAvailableMetrics();
        userFields = avMetrics.fields.map((item) => ({ ...item, key: item.item.key }));
      } catch (err) {
        const fieldsAndFilters = getFieldsAndFilters();
        userFields = fieldsAndFilters.fields;
      }
      return userFields;
    },
    $_getRevenueSource(settings, hasTracker) {
      return settings?.revenue_from || (hasTracker ? 'tr' : 'ts');
    },
    $_prefixedKey(key) {
      return `${this.revenueFrom}_${key}`;
    },
    insertTableItems() {
      clearInterval(this.intervalId);

      if (this.table.items.length) {
        this.intervalId = setInterval(() => {
          if (this.table.items.length && this.table.items.length < this.campaigns.length) {
            this.table.items.push(
              ...this.campaigns.slice(
                this.table.items.length,
                this.table.items.length + 50,
              ),
            );
          } else {
            clearInterval(this.intervalId);
          }
        }, 100);
      }
    },
    getResizedColumns() {
      return {
        ...defaultColumnsWidth,
        ...this.$settings.resizedColumns.getResizedColumns('campaigns', this.filter.type.uniqueName),
      };
    },
    // called when table sort,pagination or limit is changed
    // returns only the elements of the table
    async $_loadCampaignsPaginated() {
      try {
        this.ready.pagination = true;
        let items = [];
        items = await this.$_apiCampaignsPerformancePaginated();
        items.items = items.items.map((item) => {
          item.budgetLiveEditState = { state: 'READY' };
          item.dailyBudgetLiveEditState = { state: 'READY' };
          item.customBudgetLiveEditState = { state: 'READY' };
          item.targetCpaLiveEditState = { state: 'READY' };
          item.bidCapLiveEditState = { state: 'READY' };
          item.bidLiveEditState = { state: 'READY' };
          return item;
        });

        if (Object.keys(items).length > 0) {
          this.campaigns = items.items;
          this.table = {
            ...this.table,
            fields: [
              tableFields.getField('CAMPAIGNS', { name: 'actions' }),
              ...items.fields.map((item) => tableFields.getField('CAMPAIGNS', {
                name: item.item.key,
                uniqueName: this.filter.type.uniqueName,
              }, item)),
            ],
            items: this.campaigns.slice(0, 15),
          };
          this.insertTableItems();
        }
        this.ready.pagination = false;
      } catch (error) {
        if (!AbortController.isAbortError(error)) {
          this.$n_failNotification({ title: error.response?.data?.message });
          this.table.items = [];
          this.campaigns = [];
          this.ready.campaigns = true;
        }
      }
    },
    // get paginated campaigns
    async $_apiCampaignsPerformancePaginated() {
      this.trafficSourceAccounts = await this.$apiStore.trafficSources.accounts();
      const filter = {
        startDate: this.filter.dateRange.startDate,
        endDate: this.filter.dateRange.endDate,
      };
      filter.typeName = this.filter.type.uniqueName;
      filter.trafficSourceAccountIds = this.filter.accounts.map((item) => parseInt(item.id, 10));
      filter.trackerAccountIds = this.filter.trackers.map((item) => parseInt(item.id, 10));
      filter.page = this.page;
      filter.pageSize = this.limit;
      filter.sort = this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`;
      filter.status = [];
      filter.tags = this.filterState.savedFilters.tags;
      filter.columnFilter = this.columnFilter;
      filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
      filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      filter.preset_id = this.$settings.presets.getPresetByTrafficSource(this.filter.type.uniqueName);
      // Gemini Advertiser
      if (filter.typeName === 'Gemini' && this.advertisersModel !== 'all') filter.advertiserId = this.advertisersModel;

      // Default unachived & managed
      filter.archived = false;
      filter.managed = true;
      if (this.statusFilter.length > 0) {
        this.statusFilter.forEach((selected) => {
          if (selected.value.status) {
            if (['ARCHIVED', 'UNARCHIVED'].includes(selected.value.status)) {
              filter.archived = selected.value.status === 'ARCHIVED';
            } else if (['MANAGED', 'UNMANAGED'].includes(selected.value.status)) {
              filter.managed = selected.value.status === 'MANAGED';
            } else {
              filter.status.push(selected.value.status);
            }
          }
        });
      }

      if (this.search !== '') {
        filter.search = this.search;
      }

      this.filter.accounts.forEach((item) => {
        filter.trafficSourceAccountIds.push(parseInt(item.id, 10));
      });
      const items = await this.$api.campaigns.performance(filter, 'campaigns');

      this.campaignFilters = {
        status: filter.status,
        managed: filter.managed,
        archived: filter.archived,
      };
      if (items.pagination.lastPage) {
        this.pages = items.pagination.lastPage;
        this.defaultRows = items.pagination.pageSize;
      }
      if (!items.items.length) {
        this.page = 1;
      }
      // load totals for pagination
      this.table.totals = items.totals;
      this.table.totals.totalElements = items.pagination.total;
      // Gemini Advertisers
      this.table.advertisers = items.advertisers || [];
      // append account name
      items.items.map((item) => {
        item.traffic_source_account_name = this.trafficSourceAccounts.find((account) => account.id === item.traffic_source_account_id)?.name || '';
        /** When no tags are saved for a Campaign, the first element of item.tags is an array with an empty string([""]), so assign an empty array instead */
        item.tags = !(item.tags[0]) ? [] : item.tags;
        return item;
      });
      return items;
    },
    // get totals and stats for campaigns
    async $_apiCampaignsTotals() {
      const filter = {
        startDate: this.filter.dateRange.startDate,
        endDate: this.filter.dateRange.endDate,
      };
      filter.typeName = this.filter.type.uniqueName;
      filter.trafficSourceAccountIds = [];
      filter.trackerAccountIds = this.filter.trackers.map((item) => parseInt(item.id, 10));
      // const noTracker = filter.trackerAccountIds.indexOf(0) > -1;
      this.filter.accounts.forEach((item) => {
        filter.trafficSourceAccountIds.push(parseInt(item.id, 10));
      });
      const result = await this.$api.campaigns.performanceTotals(filter, 'campaigns-totals');
      return result;
    },
    $_setSearchValue(event) {
      this.search = event.search;
      this.debouncedGetCampaigns();
    },
    // eslint-disable-next-line no-unused-vars
    $_setColumnFilterValues(event) {
      this.debouncedGetCampaigns();
    },
    $_setSuffixBudget(item) {
      if (item.additional_fields.budget !== null && ['Facebook'].includes(item.traffic_source_unique_name)) {
        return '  Lifetime';
      } if (item.additional_fields.budget !== null && ['TikTok'].includes(item.traffic_source_unique_name)) {
        return '  Total';
      } if (item.additional_fields.budget !== null && item.additional_fields.budget_type && ['Taboola'].includes(item.traffic_source_unique_name)) {
        if (item.additional_fields.budget_type === 'ENTIRE') {
          return '  Lifetime';
        }
        return ` ${this.$_capitalizeWords(item.additional_fields.budget_type)}`;
      } if (item.additional_fields.budget !== null && item.additional_fields.budget_type && ['Outbrain', 'RevContent', 'Mgid'].includes(item.traffic_source_unique_name)) {
        return ` ${this.$_capitalizeWords(item.additional_fields.budget_type)}`;
      }
      return '';
    },
    $_setSuffixDailyBudget(item) {
      if (item.additional_fields.daily_budget !== null && ['Facebook', 'TikTok'].includes(item.traffic_source_unique_name)) {
        return '  Daily';
      } if (item.additional_fields.daily_budget !== null && item.additional_fields.daily_budget > 0 && ['Taboola'].includes(item.traffic_source_unique_name)) {
        return '  Daily';
      }
      return '';
    },
    $_setSuffixBidType(item) {
      if (['Taboola'].includes(item.traffic_source_unique_name)) {
        return ' CPC';
      } if (item.additional_fields.bid !== null && item.additional_fields.bid_type !== null && ['RevContent'].includes(item.traffic_source_unique_name)) {
        return this.$_capitalizeWords(item.additional_fields.bid_type);
      }
      return '';
    },
    $_getSymbol(item, type) {
      if (type === 'prefix') {
        if (item.traffic_source_unique_name === TrafficSource.GoogleAds && item.biddingStrategyType === 'Maximize Conversion Value - Target ROAS') {
          return '';
        }
        return item.traffic_source_currency || '$';
      }
      if (type === 'suffix' && item.traffic_source_unique_name === TrafficSource.GoogleAds) {
        return '%';
      }
      return '';
    },
    $_capitalizeWords(input) {
      const words = input.split('_');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
      const result = capitalizedWords.join(' ');
      return ` ${result}`;
    },
    $_setPaginationValues(event) {
      this.page = event.page;
      this.limit = event.limit;
      this.sortField = event.sortField;
      this.sortType = event.sortType;
      this.$_loadCampaignsPaginated();
    },
    // eslint-disable-next-line no-unused-vars
    $_changeFilter(filter, updateSearch) {
      if (updateSearch) {
        this.search = '';
      }
      this.columnFilter = {};
      // Reset Advertiser
      this.advertisersModel = 'all';
      this.$_loadCampaignsInit();
      this.ready.filter = true;
    },
    $_inlineEditArrayPress(refName, index, direction) {
      let nextIndex = direction === 'up' ? index - 1 : index + 1;
      while (this.$refs[`${refName}-${nextIndex}`]) {
        if (this.$refs[`${refName}-${nextIndex}`].getState() === 'READY') {
          this.$refs[`${refName}-${nextIndex}`].focus();
          break;
        }
        nextIndex = direction === 'up' ? nextIndex - 1 : nextIndex + 1;
      }
    },
    async $_bulkAction(action) {
      if (this.tableModel.selectedRows.length > 0) {
        const task = this.userAction.task.create();
        try {
          if (action === 'manage' || action === 'unmanage') {
            const managed = action === 'manage';
            const selectedIds = this.tableModel.selectedRows.filter((row) => row.managed !== managed)
              .map((row) => row.id);
            if (selectedIds.length > 0) {
              const result = await this.$api.campaigns.changeManage(managed, selectedIds);
              this.table.items.forEach((row) => {
                if (selectedIds.indexOf(row.id) > -1) row.managed = managed;
              });
              this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
              this.$_loadCampaignsPaginated();
            }
          } else if (action === 'archive' || action === 'unarchive') {
            const archived = action === 'archive';
            const selectedIds = this.tableModel.selectedRows.filter((row) => row.archived !== archived)
              .map((row) => row.id);
            if (selectedIds.length > 0) {
              const result = await this.$api.campaigns.changeArchive(archived, selectedIds);
              this.table.items.forEach((row) => {
                if (selectedIds.indexOf(row.id) > -1) row.archived = archived;
              });
              this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
              this.$_loadCampaignsPaginated();
            }
          } else if (action === 'activate' || action === 'deactivate') {
            const activated = action === 'activate';
            const selectedIds = this.tableModel.selectedRows.filter((row) => row.activated !== activated)
              .map((row) => row.id);
            if (selectedIds.length > 0) {
              let result;
              for (const id of selectedIds) {
                try {
                  // eslint-disable-next-line no-await-in-loop
                  result = await this.$api.campaigns.changeStatus(id, activated);
                  this.table.items.forEach((row) => {
                    if (id === row.id) row.activated = activated;
                  });
                  this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
                } catch (error) {
                  const title = error.response?.data?.message ?? 'An error occurred';
                  this.$n_failNotification({ title });
                }
              }
              this.$_loadCampaignsPaginated();
            }
          } else if (action === 'change_budget_bulk') {
            this.bulkAction = 'change_budget_bulk';
            this.$refs.ruleBulkModal.show();
          } else if (action === 'change_daily_budget_bulk') {
            this.bulkAction = action;
            this.$refs.ruleBulkModal.show();
          } else if (action === 'change_target_cpa_bulk') {
            this.bulkAction = action;
            this.$refs.ruleBulkModal.show();
          } else if (action === 'change_bid_cap_bulk') {
            this.bulkAction = action;
            this.$refs.ruleBulkModal.show();
          } else if (action === 'change_bid_bulk') {
            this.bulkAction = 'change_bid_bulk';
            this.$refs.ruleBulkModal.show();
          } else if (action === 'change_daily_budget') {
            this.$refs.bulkDailyBudgetModal.show();
          } else if (action === 'change_lifetime_budget') {
            this.$refs.bulkLifetimeBudgetModal.show();
          } else if (action === 'change_target_cpa') {
            this.$refs.bulkTargetCpaModal.show();
          } else if (action === 'change_bid_cap') {
            this.$refs.bulkBidCapModal.show();
          } else if (action === 'add_rule_to_campaign') {
            this.selectedCampaigns = this.tableModel.selectedRows;
            this.$refs.addRuleModal.show();
          } else if (action === 'allow_compliance') {
            this.selectedCampaigns = this.tableModel.selectedRows;
            this.$refs.complianceModal.show('allow', 'campaign');
          } else if (action === 'remove_compliance') {
            this.selectedCampaigns = this.tableModel.selectedRows;
            this.$refs.complianceModal.show('remove', 'campaign');
          } else if (action === 'tags') {
            this.selectedCampaigns = this.tableModel.selectedRows;
            this.$refs.campaignTagsRef.handleOnOpenModal(this.tableModel.selectedRows);
          }
        } catch (error) {
          const title = error.response?.data?.message ?? 'An error occurred';
          this.$n_failNotification({ title });
        }
        task.finish();
      } else {
        this.$n_failNotification({ title: 'Select at least 1 row' });
      }
    },
    async $_bulkUpdateBudget(value, type) {
      const selectedIds = [];
      const lifetime = type === 'lifetime';
      const stateKey = lifetime ? 'budgetLiveEditState' : 'dailyBudgetLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        selectedIds.push(row.id);
        row[stateKey].state = 'BUSY';
      });
      if (selectedIds.length > 0) {
        let result;
        for (const id of selectedIds) {
          try {
          // eslint-disable-next-line no-await-in-loop
            result = lifetime ? await this.$api.campaigns.changeBudget(id, value, 'limited') : await this.$api.campaigns.changeDailyBudget(id, value);
            this.table.items.forEach((row) => {
              if (id === row.id) {
                lifetime ? row.additional_fields.budget = value : row.additional_fields.daily_budget = value;
                row[stateKey].state = 'READY';
              }
            });
            this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
          } catch (error) {
            const title = error.response?.data?.message ?? 'An error occurred';
            this.$n_failNotification({ title });
          }
        }
        this.$_loadCampaignsPaginated();
      }
    },
    async $_bulkUpdateTargetCpa(value) {
      const selectedIds = [];
      const stateKey = 'targetCpaLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        selectedIds.push(row.id);
        row[stateKey].state = 'BUSY';
      });
      if (selectedIds.length > 0) {
        let result;
        for (const id of selectedIds) {
          try {
          // eslint-disable-next-line no-await-in-loop
            result = await this.$api.campaigns.changeTargetCpa(id, value);
            this.table.items.forEach((row) => {
              if (id === row.id) {
                row.additional_fields.target_cpa = value;
                row[stateKey].state = 'READY';
              }
            });
            this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
          } catch (error) {
            const title = error.response?.data?.message ?? 'An error occurred';
            this.$n_failNotification({ title });
          }
        }
        this.$_loadCampaignsPaginated();
      }
    },
    async $_bulkUpdateBidCap(value) {
      const selectedIds = [];
      const stateKey = 'bidCapLiveEditState';
      this.tableModel.selectedRows.forEach((row) => {
        selectedIds.push(row.id);
        row[stateKey].state = 'BUSY';
      });
      if (selectedIds.length > 0) {
        let result;
        for (const id of selectedIds) {
          try {
            // eslint-disable-next-line no-await-in-loop
            result = await this.$api.campaigns.changeBidCap(id, value);
            this.table.items.forEach((row) => {
              if (id === row.id) {
                row.additional_fields.bid_cap = value;
                row[stateKey].state = 'READY';
              }
            });
            this.$n_successNotification({ title: result.message || 'Campaigns successfully updated' });
          } catch (error) {
            const title = error.response?.data?.message ?? 'An error occurred';
            this.$n_failNotification({ title });
          }
        }
        this.$_loadCampaignsPaginated();
      }
    },
    $_showGeminiBidModal(item) {
      if (item.additional_fields.bids) {
        this.$refs.geminiBidModal.show(item);
      } else {
        this.$swal({
          title: 'Bid changing not available for this campaign!',
          type: 'warning',
        });
      }
    },
    $_showGoogleBidModal(item) {
      if (item.additional_fields.bids) {
        this.$refs.googleAdsCampaignBidModal.show(item);
      } else {
        this.$swal({
          title: 'Bid changing not available for this campaign!',
          type: 'warning',
        });
      }
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, {
        type: this.filter.type.uniqueName,
        level: 'CAMPAIGN',
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.filter.type.uniqueName),
      }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_loadCampaignsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('campaigns', this.filter.type.uniqueName, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.filter.dateRange.startDate,
        endDate: this.filter.dateRange.endDate,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      filter.typeName = this.filter.type.uniqueName;
      filter.trafficSourceAccountIds = [];
      filter.trackerAccountIds = this.filter.trackers.map((item) => parseInt(item.id, 10));
      filter.page = 1;
      filter.pageSize = this.table.totals.totalElements;
      filter.sort = this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`;
      filter.status = [];
      if (filter.typeName === 'Gemini' && this.advertisersModel !== 'all') filter.advertiserId = this.advertisersModel;

      if (this.statusFilter.length > 0) {
        // eslint-disable-next-line
        filter.archived = this.statusFilter
          .map((selected) => selected.value.status)
          .includes('ARCHIVED');
        filter.managed = !this.statusFilter
          .map((selected) => selected.value.status)
          .includes('UNMANAGED');
        if (filter.archived) {
          // filter.status.push('OTHER')
        } else {
          this.statusFilter.forEach((selected) => {
            if (selected.value.status) {
              filter.status.push(selected.value.status);
            }
          });
        }
      } else {
        filter.archived = false;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      this.filter.accounts.forEach((item) => {
        filter.trafficSourceAccountIds.push(parseInt(item.id, 10));
      });
      return this.$_exportCsv({
        level: 'campaigns',
        filter,
      });
    },
    // clone campaign, simple or via campaign creator
    async $_showCloneModal(item) {
      const campaign = this.table.items.find((i) => i.campaign_id === item.campaign_id);
      if (this.$c_simpleClone) {
        this.campaign = campaign;
        this.$refs.cloneModal.showModal();
      } else {
        await this.$_cloneCampaign(item);
      }
    },
    onFiltersChange() {
      const statusFilterValues = [
        ...this.filterState.filterSession.status,
        ...this.filterState.filterSession.managed,
        ...this.filterState.filterSession.archived,
      ];

      this.statusFilter = statusFilterValues;
      this.campaignsStatusFilter = statusFilterValues;
      this.$_loadCampaignsPaginated();
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_loadCampaignsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'CAMPAIGN');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_loadCampaignsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error?.response?.data?.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_loadCampaignsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_loadCampaignsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error?.response?.data?.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: 'Preset with name was updated successfully' });
      } catch (error) {
        this.$n_failNotification({ title: error?.response?.data?.message || 'Could not update preset name, please try again' });
      }
    },
    onNotification(title, type, metadata) {
      switch (type) {
        case 'success':
          this.$n_successNotification({ title });

          if (metadata) {
            this.table.items.forEach((item) => {
              if (this.selectedCampaigns.find((selected) => selected.id === item.id)) {
                item.allow_compliance = metadata?.action === 'allow' || '0';
                item.revenue_streaming_sharing_compliance = metadata.email;
                item.compliance_revenue_stream_type = metadata.type;
              }
            });
          }
          break;
        case 'error':
          this.$n_failNotification({ title });
          break;
        default:
          this.$n_infoNotification({ title });
      }
    },
    ...mapActions(useCampaignsStore, ['addNewTags', 'getAllAvailableTagsForCampaign', 'initialFilters', 'onBulkTagInsert']),
  },
};
</script>

<style lang="scss">
.campaigns-container {
  .margin-top {
    margin-top: 4rem;
  }

  .section-head {
    margin-bottom: 3rem;
    margin-top: 2rem;

    .section-title {
      font-size: 2.4rem;
      font-weight: bold;
      font-family: 'Inter', sans-serif;
      margin-bottom: .5rem;
      color: $color-dark-blue;
    }

    .section-subtitle {
      color: $text-secondary;
      font-size: 1.3rem;
    }
  }

  &__table {
    margin-top: 2.5rem;

    .datatable-wrapper {
      .single-select-wrapper {
        padding-right: 0;

        .btn.dropdown-toggle {
          background: $color-white;
          color: $text-secondary;
          border-radius: .6rem;
          font-size: 1.4rem;
          padding: .7rem 1.5rem;
          border: .1rem solid $color-light-gray;
          transition: none;

          &:focus,
          &:active {
            background: $color-white;
            color: $text-secondary;
            border-color: $color-light-gray;
          }

          &::after {
            margin-left: 0;
          }
        }

        .dropdown-menu {
          padding: 1rem;
          background: $color-white;
          border: .1rem solid $color-light-gray;
          border-radius: .6rem;
          color: $color-dark;

          @include small-scrollbar();

          li {
            line-height: unset;
            padding: 0;
            background: $color-white;

            &.active-item {
              background: $color-light !important;
            }

            a {
              display: flex;
              align-items: center;
              padding: .7rem 1.5rem .7rem 1.2rem;
              border-radius: .4rem;
              color: $black-600;
              font-size: 1.4rem;

              &:active,
              &:focus {
                background: $color-light;
              }

              &:hover {
                color: $color-dark;
                background: $color-light;
              }
            }
          }
        }
      }

      .clickable-item {
        color: #36a8cc;
        cursor: pointer;
      }
    }
  }

}
.info-icon {
  margin-right: 0.3rem;
}

.larger-box {
  .swal2-popup {
    width: 25%;
    height: 30%;
  }
}

.hide-cancel-btn {
  display: none !important;
}

.clone-campaign-popup {
  .clone-campaign-form {
    margin-bottom: 0;

    .form-check {
      text-align: center;
      margin-bottom: 1rem;

      .form-check-label {
        font-size: 16px;
        margin-left: 0.7rem;
      }
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .custom-tooltip {
      position: relative;
      display: inline-block;

      .tooltiptext {
        visibility: hidden;
        width: 30rem;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 20%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #000 transparent transparent transparent;
        }
      }

      &:hover {
        .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>
