import uid from 'uid';

class Task {
  constructor() {
    this.tasks = {};
    this.callbacks = {
      change: [],
      'change:empty': [],
      'change:not-empty': [],
    };
    this.on('change', () => {
      if (Object.keys(this.tasks).length === 0) {
        this.__emit('change:empty');
      } else {
        this.__emit('change:not-empty');
      }
    });
  }

  create() {
    const taskId = `task-${uid(10)}`;
    this.tasks[taskId] = {
      finish: () => {
        delete this.tasks[taskId];
        this.__emit('change');
      },
    };
    this.__emit('change');
    return this.tasks[taskId];
  }

  /**
   * Listen for event
   * @param {String} event
   * @param {Function} callback
   */
  on(event, callback) {
    if (this.callbacks[event]) {
      if (typeof callback === 'function') this.callbacks[event].push(callback);
    }
  }

  /**
   * Emit event, internal usage
   * @param {String} event
   * @param {any} data
   */
  __emit(event, data) {
    setTimeout(() => {
      if (this.callbacks[event]) {
        this.callbacks[event].forEach((fn) => {
          setTimeout(() => {
            fn(data);
          }, 0);
        });
      }
    }, 0);
  }
}

export default Task;
