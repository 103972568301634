<template>
  <div class="rules-select-group-wraper">
    <b-form-group class="optimizer-form-group">
      <b-input-group>
        <div class="campaign-select-container w-100">
          <v-select
            placeholder="Select Rule Groups"
            class="form-control multiselect w-100"
            :close-on-select="false"
            multiple
            :reset-on-options-change="true"
            :options="$c_options"
            v-model="groups"
            @input="$_emit"
            :disabled="disabled"
          >
          </v-select>
        </div>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'RulesSelectGroup',
  props: {
    options: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      groups: [],
    };
  },
  computed: {
    $c_options() {
      return Object.entries(this.$c_optionsMap).map(([group, rules]) => ({ label: group, rules }));
    },
    $c_optionsMap() {
      const optionsMap = {};
      this.options.forEach((rule) => {
        if (rule.group.length) {
          rule.group.forEach((group) => {
            if (!optionsMap[group]) optionsMap[group] = {};
            optionsMap[group][rule._id] = { _id: rule._id, label: rule.name, group };
          });
        }
      });
      return optionsMap;
    },
    $c_selectedRulesMap() {
      const rules = {};
      this.groups.forEach((group) => {
        Object.assign(rules, group.rules);
      });
      return rules;
    },
  },
  watch: {
    $c_selectedRulesMap: {
      handler(newValue, oldValue) {
        const add = {};
        const remove = {};
        Object.keys(newValue).forEach((key) => {
          if (!oldValue[key]) add[key] = newValue[key];
        });
        Object.keys(oldValue).forEach((key) => {
          if (!newValue[key]) remove[key] = oldValue[key];
        });
        if (Object.keys(add).length || Object.keys(remove).length) this.$emit('change-diff', { add, remove });
      },
      deep: true,
    },
  },
  methods: {
    clear() {
      this.groups = [];
    },
    $_emit() {
      this.$emit('input', { ...this.$c_selectedRulesMap });
    },
  },
};
</script>

<style lang="scss">
.rules-select-group-wraper {

}
</style>
