<template>
  <b-modal
    id="rollback-modal"
    v-model="modal"
    modal-class="optimizer-modal optimizer-modal--scrollable"
    size="xl"
    @hidden="$_hidden"
    centered
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Rollback Activities</h2>

      <svg class="modal-header__close-icon" @click="hideModal" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Modal Body -->
    <div class="rollback-table">
      <b-table hover bordered :items="activities" :fields="fields">
        <template #cell(level)="{ item }">
          <span v-if="item.level">{{ item.level }}</span>
          <span v-else class="text-center d-block">--</span>
        </template>

        <template #cell(campaignName)="{ item }">
          <span v-if="item.campaignName">{{ item.campaignName }}</span>
          <span v-else class="text-center d-block">--</span>
        </template>

        <template #cell(action)="{ item }">
          <div class="rollback-action" v-html="config.logConfig.itemChangedRollback(item)"></div>
        </template>

        <template #cell(status)="{ item }">
          <template v-if="activitiesMap[item.id]">
            <span v-if="activitiesMap[item.id].loading"><i class="fa fa-spinner"></i></span>
            <span
              v-else
              :class="{ 'text-success': activitiesMap[item.id].status === 'Completed', 'text-danger': activitiesMap[item.id].status === 'Failed' }"
            >
              {{ activitiesMap[item.id].status }}
            </span>
          </template>
          <span v-else>
            <i class="fa fa-exclamation-triangle" v-b-tooltip.hover title="This activity can't be rolled back."></i>
          </span>
        </template>
      </b-table>
    </div>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn v-if="showRollbackBtn && rollbackableActivities.length" :disabled="loadingStatus" class="primary-button" @click="submit">Rollback Activities</b-btn>
      <b-btn v-else-if="retryActivities.length" @click="retry" :disabled="loadingStatus" class="primary-button">Retry Failed Activities</b-btn>
      <b-btn v-else @click="hideModal" class="primary-button">Close</b-btn>
    </template>
  </b-modal>
</template>

<script>
import each from 'each.js';
import actionsApi from './config/api';

export default {
  name: 'RollbackModal',
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      modal: false,
      loadingStatus: false,
      showRollbackBtn: true,
      activities: [],
      activitiesMap: {},
      fields: [
        {
          label: 'Affected Item',
          key: 'level',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Item Name',
          key: 'itemName',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Campaign Name',
          key: 'campaignName',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Action',
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Status',
          key: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    };
  },
  computed: {
    rollbackableActivities() {
      return this.activities.filter((activity) => this.isRollbackable(activity));
    },
    retryActivities() {
      return this.rollbackableActivities.filter((activity) => this.activitiesMap[activity.id].status === 'Failed');
    },
  },
  created() {
    this.$options.rollbackActions = Object.keys(actionsApi);
  },
  methods: {
    showModal(data = {}) {
      this.modal = true;
      this.$_setActivities(data);
    },
    hideModal() {
      this.modal = false;
    },
    async submit() {
      this.loadingStatus = true;
      await each.parallel(this.rollbackableActivities, async (activity) => {
        this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], loading: true } };
        try {
          await actionsApi[activity.action](activity);
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], status: 'Completed' } };
        } catch (err) {
          console.log(err);
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], status: 'Failed' } };
        } finally {
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], loading: false } };
        }
      });

      this.loadingStatus = false;
      this.showRollbackBtn = false;
    },
    async retry() {
      this.loadingStatus = true;
      await each.parallel(this.retryActivities, async (activity) => {
        this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], loading: true } };
        try {
          await actionsApi[activity.action](activity);
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], status: 'Completed' } };
        } catch (err) {
          console.log(err);
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], status: 'Failed' } };
        } finally {
          this.activitiesMap = { ...this.activitiesMap, [activity.id]: { ...this.activitiesMap[activity.id], loading: false } };
        }
      });

      this.loadingStatus = false;
    },
    $_setActivities({ activities = [] }) {
      this.activities = activities;
      this.rollbackableActivities.forEach((activity) => {
        const item = { loading: false, status: 'Ready' };
        this.activitiesMap[activity.id] = item;
      });
    },
    isRollbackable(item) {
      return !item.error && this.$options.rollbackActions.includes(item.action);
    },
    $_hidden() {
      this.$_setActivities({}); // Reset
      this.activitiesMap = {};
      this.showRollbackBtn = true;
      this.$root.$emit('bv::hide::tooltip');
    },
  },
};
</script>

<style lang="scss">
  .rollback-table {
    table {
      margin-bottom: 0;
      font-size: 1.4rem;
    }

    .rollback-action {
      white-space: nowrap;

      p {
        text-align: center !important;
        font-size: 1.4rem;
      }
    }

    .fa-spinner {
      animation: rotateSpinner 1s infinite linear;
    }

    @keyframes rotateSpinner {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
