
import AdCreative from '@/views/Automation/CampaignCreatorV2/steps/AdCards/AdCreative.ts.vue';
import AdName from '@/views/Automation/CampaignCreatorV2/steps/AdCards/AdName.ts.vue';
import AdSetup from '@/views/Automation/CampaignCreatorV2/steps/AdCards/AdSetup.ts.vue';
import Destination from '@/views/Automation/CampaignCreatorV2/steps/AdCards/Destination.ts.vue';
import Identity from '@/views/Automation/CampaignCreatorV2/steps/AdCards/Identity.ts.vue';
import Tracking from '@/views/Automation/CampaignCreatorV2/steps/AdCards/Tracking.ts.vue';
import useAdSetStore from '@/views/Automation/CampaignCreatorV2/store/AdSet';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import { mapState } from 'pinia';
import Vue from 'vue';

export default Vue.extend({
  components: {
    AdName,
    Identity,
    AdSetup,
    AdCreative,
    Destination,
    Tracking,
  },
  setup() {
    const adSetStore = useAdSetStore();
    return {
      adSetStore,
    };
  },
  computed: {
    ...mapState(useNavigationStore, ['getAdBuildId']),
  },
});
