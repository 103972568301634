<template>
  <div :class="`cc-radio-button-wrapper component-${model.name}`">
    <!-- <p v-if="model.textTop" class="mb-1" v-html="model.textTop"></p> -->
    <b-form-group
      class="optimizer-form-group"
      :state="$_hasError('input')"
    >
      <strong slot="label" v-if="model.textTop" v-html="model.textTop"></strong>

      <b-form-radio-group
        class="optimizer-radio-form-group w-100"
        :class="[{ gap: model.gap }, 'cc-buttons']"
        @input="$_emit('selected')"
        v-model="selected"
        :disabled="model.disabled"
        :options="$_options"
        buttons
        name="input"
        :data-vv-as="model.label"
        :state="$_hasError('input')"
        v-validate="model['v-validate']"
      >
      </b-form-radio-group>
      <small slot="invalid-feedback">{{ $_getError('input') }}</small>
    </b-form-group>
    <p v-if="model.textBottom" class="cc-radio-button-info-text" v-html="model.textBottom"></p>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcRadioButton',
  mixins: [component],
  props: {
    value: { type: [String, Number, Boolean, null], default: null },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    $_options() {
      const options = [];
      if (this.model.options) {
        this.model.options.forEach((option) => {
          const text = option.icon ? `<i class="${option.icon}" aria-hidden="true"></i> ${option.text}` : option.text;
          options.push({
            ...option, value: option.value, text, html: text,
          });
        });
      }
      return options;
    },
  },
  created() {
    // Bind Model
    this.$watch('value', (value) => {
      this.selected = value;
    }, { immediate: true });
  },
};
</script>

<style lang="scss">
.cc-radio-button-wrapper {
  .optimizer-form-group {
    margin-bottom: 0;
    // margin-bottom: .3rem;
  }

  .cc-radio-button-info-text {
    font-size: 1.4rem;
    color: $black-400;
    font-style: italic;
  }

  // .cc-buttons {
  //   &.gap {
  //     label {
  //       margin: 3px;
  //     }
  //   }
  //   label {
  //     cursor: pointer;
  //     &.active {
  //       box-shadow: 0 0 0 1.2px rgba(0, 0, 0, 0.5)
  //     }
  //   }
  // }
}
</style>
