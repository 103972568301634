<template>
  <div :class="[`optimizer-card stats-card stats-card--${name}`]">
    <div class="stats-card__description">
      <h5>
        <span class="stats-circle" />
        <span v-if="info" v-b-tooltip.hover.right="{ title: info.description ? info.description : '', html: true, customClass: 'custom-tooltip max-width-280' }">{{ name }}</span>
        <span v-else>{{ name }}</span>
      </h5>
      <h6>{{ String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</h6>
    </div>
    <apexchart v-if="!isChartLoading" :type="type" :height="height" :options="optionsData" :series="series"></apexchart>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'DashboardStatsCard',
  props: {
    name: { type: String },
    info: { type: Object },
    value: { type: [String, Number] },
    type: { type: String },
    height: { type: Number },
    options: { type: Object },
    series: { type: Array },
    chart: { type: Object },
    isChartLoading: Boolean,
  },
  computed: {
    optionsData() {
      return isEmpty(this.options) ? {
        yaxis: {
          labels: {
            formatter(val) {
              return val === 0 ? val.toFixed(2) : undefined;
            },
          },
        },
        grid: {
          show: false,
        },
      } : this.options;
    },
  },
};
</script>

<style lang="scss" scoped>
  .stats-card {
    padding: 2rem;
    margin-bottom: 2.5rem;

    &__description {
      color: $color-yellow;
      margin-bottom: 1rem;

      h5 {
        margin-bottom: 1rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        position: relative;

        .stats-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 2rem;
          width:  2rem;
          background: rgba(247, 206, 104, 0.3);
          margin-right: .7rem;

          &::after {
            content: '';
            display: block;
            line-height: 2rem;
            border-radius: 50%;
            width: 1.2rem;
            height: 1.2rem;
            background: $color-yellow;
          }
        }
      }

      h6 {
        font-size: 1.8rem;
        color: $color-dark-gray;
        font-weight: 500;
      }
    }

    &.stats-card--NET,
    &.stats-card--CPA {
      .stats-card__description {
        color: $color-blue;
      }

      .stats-circle {
        background: rgba(104, 178, 247, 0.3);

        &::after {
          background: $color-blue;
        }
      }
    }

    &.stats-card--Revenue,
    &.stats-card--Conversions {
      .stats-card__description {
        color: $color-purple;
      }

      .stats-circle {
        background: rgba(158, 104, 247, 0.3);

        &::after {
          background: $color-purple;
        }
      }
    }

    &.stats-card--ROI,
    &.stats-card--EPC {
      .stats-card__description {
        color: $red-300;
      }

      .stats-circle {
        background: rgba(247, 138, 104, 0.3);

        &::after {
          background: $red-300;
        }
      }
    }
  }
</style>
