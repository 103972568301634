/* eslint-disable import/no-cycle */
import { ABRegistry, ABRegistryInstance } from '@/views/Automation/CampaignCreatorV2/ab';
import { getAllMacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { MacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as CampaignMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import useAbGroups, { AbGroupForEntity } from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useABVariableStore, { CampaignAbVariables } from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import useAdStore from '@/views/Automation/CampaignCreatorV2/store/Ad';
import useAdAccountStore from '@/views/Automation/CampaignCreatorV2/store/AdAccount';
import useAdSetStore from '@/views/Automation/CampaignCreatorV2/store/AdSet';
import useCreativeStore from '@/views/Automation/CampaignCreatorV2/store/Creative';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import useTargetingStore from '@/views/Automation/CampaignCreatorV2/store/Targeting';
import {
  ABTestableFields,
  CampaignState,
  CampaignStore,
  ErrorType,
  FieldError,
  getIdForEntity,
  ICampaignCreate,
  InternalABVariable,
  SupportedEntities,
} from '@/views/Automation/CampaignCreatorV2/store/Types';
import {
  Level,
  NavigationAction,
  NavigationActionEvent,
  NavigationActionPayload,
  NavigationMenu,
} from '@/views/Automation/CampaignCreatorV2/types';
import { FacebookTemplate } from '@/views/Automation/CampaignCreatorV2/types/Template';
import { AdDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdDTO';
import { AdSetDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdSetDTO';
import {
  CampaignABGroupsPublic,
  CampaignAbGroupTypes,
  CampaignValidation,
} from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import { AbVariableErrorDTO, CampaignDTO } from '@/views/Automation/CampaignCreatorV2/validation/CampaignDTO';
import { FacebookAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/FacebookAbGroups';
import { CampaignEntityMacros } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import { validateData } from '@sh/helpers';
import { api } from '@sh/services/api';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { defineStore } from 'pinia';
import uid from 'uid';

function updateAbGroupsRegistry(
  ABRegistry: ABRegistry,
  campaigns: CampaignStore['campaigns'],
  abVariables: Record<string, CampaignAbVariables>,
  allCampaignsABGroups: Record<string, AbGroupForEntity<SupportedEntities>>,
  globalConstraintById: Array<string[]>,
  fullTree = true,
  perChildCoefficient = 999
) {
  for (const campaignMeta of Object.values(campaigns)) {
    const campaignBuildId = getIdForEntity(SupportedEntities.campaign, [campaignMeta.internalId]);
    const campaign = {
      ...campaignMeta,
      ...abVariables[campaignBuildId],
    };

    const {
      groups: abGroups,
      mainAbGroup: mainGroupId,
      defaultAbGroup,
      constraintById,
    } = allCampaignsABGroups[campaignBuildId];

    ABRegistry.registerAbGroups(
      campaign.internalId,
      mainGroupId,
      defaultAbGroup,
      abGroups,
      campaignBuildId,
      constraintById.concat(globalConstraintById).map((e) => e.filter(Boolean)),
      SupportedEntities.campaign,
      campaign.internalId,
      campaign.internalId,
      fullTree,
      perChildCoefficient
    );

    for (const field of Object.keys(campaign)) {
      const fieldMeta = CampaignValidation[field as keyof typeof CampaignValidation];

      if (fieldMeta) {
        const variables = campaign[field as 'daily_budget'].value || [];
        ABRegistry.registerVariables(variables, fieldMeta, campaignBuildId);
      } else {
        console.log('Field Meta not found, internal field', field);
      }
    }
  }
}

const useCampaignStore = defineStore(SupportedEntities.campaign, {
  state: (): CampaignStore => ({
    globalErrors: [],
    adAccountId: '',
    abUpdated: new Date(),
    campaigns: {},
    templates: [],
    ruleIds: [],
    templatesLoading: false,
    currentTemplate: {
      id: uid(),
      name: `New Template Draft ${moment().format('DD/MM/YYYY')}`,
      lastSaved: 0,
    },
  }),
  getters: {
    getCurrentTemplateInfo(): { id: string; name: string; lastSaved: number } {
      return this.currentTemplate;
    },
    getTemplatesPreview(): Array<{
      id: string;
      name: string;
      labels: Array<{ name: string; value: string; groupName: string }>;
    }> {
      return this.templates
        .filter((t) => t.public || localStorage.getItem('showAllTemplates'))
        .map((template) => ({
          id: template._id,
          name: template.name,
          labels: Object.keys(template.metadata.meta).map((key) => {
            const meta = template.metadata.meta[key];
            const metric = template.metadata.metrics[key];
            return {
              name: meta.name,
              value: `${metric?.toString()} ${meta.suffix || ''}`,
              groupName: meta.entity,
            };
          }),
        }));
    },

    getIsCampaignBudgetActive(): boolean {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignBuildId;
      if (!campaignId) {
        console.log('Campaign ID not found');
        return false;
      }
      return !!this.campaigns[campaignId]?.enable_budget_for_campaign;
    },
    getMacroOptions(): Array<MacroOptions> {
      return getAllMacroOptions(CampaignValidation, CampaignABGroupsPublic, CampaignEntityMacros);
    },
    getAllowedBidOptions(): Array<{ id: CampaignMeta.BidStrategy; label: string; info: string }> {
      const campaignObjective = this.getField('objective').value?.[0]?.value;
      return CampaignMeta.BidStrategyOptions.filter((e) => !e.campaignExclude?.includes(campaignObjective));
    },
    getRegistry(): { updatedAt: Date; registry: typeof ABRegistryInstance; campaignIds: string[] } {
      return {
        updatedAt: this.abUpdated || new Date(),
        campaignIds: Object.values(this.campaigns).map((campaign) => campaign.internalId),
        registry: ABRegistryInstance,
      };
    },
    getResults() {
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const abVariables = useABVariableStore();
      const currentCampaignBuildId = navigation.getCampaignBuildId;
      const allCampaignsABGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.campaign);
      const tempRegistry = new ABRegistry();

      updateAbGroupsRegistry(
        tempRegistry,
        this.campaigns,
        abVariables.getEntity(SupportedEntities.campaign),
        allCampaignsABGroups,
        [],
        false
      );
      const previews = tempRegistry.getMainGroup(currentCampaignBuildId)?.buildEntityForCreation() || [];
      return previews;
    },
    getItemsForNavigation(): Array<NavigationMenu> {
      const abVariableStore = useABVariableStore();
      const getPayload = (adSet: CampaignState): Partial<NavigationActionPayload> => ({
        campaignId: adSet.internalId,
      });
      const cmps = Object.keys(this.campaigns);
      return cmps.map((buildId) => {
        const campaign = this.campaigns[buildId];
        const errors = abVariableStore.getEntityErrors(SupportedEntities.campaign, buildId);

        return {
          name: abVariableStore.getFieldForEntity(SupportedEntities.campaign, 'name', buildId).value[0].value.name,
          id: campaign.internalId,
          type: SupportedEntities.campaign,
          icon: 'speaker',
          actions: [
            [
              {
                name: 'Delete',
                type: 'delete',
                event: NavigationActionEvent.DELETE,
                payload: getPayload(campaign),
              },
            ],
            [
              {
                name: 'Create Ad Set',
                type: 'create-adset',
                event: NavigationActionEvent.CREATE_ADSET,
                payload: getPayload(campaign),
              },
              {
                name: 'Create New Campaign',
                type: 'create',
                event: NavigationActionEvent.CREATE,
              },
              {
                name: 'Duplicate Campaign',
                type: 'duplicate',
                event: NavigationActionEvent.DUPLICATE,
                payload: getPayload(campaign),
              },
            ],
            [
              {
                name: `ID: ${campaign.internalId}`,
                description: 'Copy',
                event: NavigationActionEvent.COPY_ID,
                type: 'copy-id',
                payload: getPayload(campaign),
              },
            ],
          ],
          ...(errors.MISSING?.length && {
            message: {
              content: errors.MISSING,
              icon: {
                type: 'uncompletedStatus',
                styles: {
                  color: 'var(--cc-blue-500)',
                },
              },
            },
          }),
          ...(errors.BLOCKING?.length && {
            message: {
              content: errors.BLOCKING,
              icon: {
                type: 'errorCircle',
                styles: {
                  color: 'var(--cc-error-color)',
                },
              },
            },
          }),
        };
      });
    },
    getComputedCampaignForCreation(): {
      errors: AbVariableErrorDTO[];
      campaignTaskForCreation: CampaignDTO[];
      campaignIds: string[];
      updatedAt?: Date;
    } {
      const errors: AbVariableErrorDTO[] = [];
      // Build Task for each Campaign

      let index = 1;
      const campaignTaskForCreation = [];
      const campaignIds = Object.values(this.campaigns).map((campaign) => campaign.internalId);
      for (const campaign of Object.values(this.campaigns)) {
        if (!ABRegistryInstance.getParentEntityCombiner(campaign.internalId)) {
          console.log('Not ready yet');
          return { errors, campaignTaskForCreation, campaignIds, updatedAt: this.abUpdated };
        }
        const campaignForCreation = ABRegistryInstance.getParentEntityCombiner(
          campaign.internalId
        ).buildEntityForCreation();

        for (const campaignVariation of campaignForCreation) {
          if (!campaignVariation.status) {
            // eslint-disable-next-line no-continue
            continue;
          }
          const campaignTask = new CampaignDTO(
            campaignVariation.variables,
            getIdForEntity(SupportedEntities.campaign, [campaign.internalId]),
            campaign.internalId,
            ++index,
            campaignVariation.macros
          );
          const tsAccount = useAdAccountStore().accountList.find((a) => a.id === campaignTask.getTsAccountId());
          const campaignErrors = campaignTask.setTsAccount(tsAccount!).setRuleIds(this.ruleIds).setupTask();
          errors.push(...campaignErrors);
          for (const adsetVariation of campaignVariation.children) {
            if (!adsetVariation.status) {
              // eslint-disable-next-line no-continue
              continue;
            }
            const pixelList = useAdAccountStore().pixelList[tsAccount?.id || 0];
            const adSetTask = new AdSetDTO(
              adsetVariation.variables,
              adsetVariation.entityId,
              adsetVariation.macros,
              pixelList
            );
            const adSetErrors = adSetTask
              .setJobId(campaignTask.getJobId())
              .setTsAccount(tsAccount!)
              .setCampaignTask(campaignTask)
              .setupTask();
            errors.push(...adSetErrors);

            campaignTask.registerSubTask(adSetTask);

            for (const adVariation of adsetVariation.children) {
              if (!adVariation.status) {
                // eslint-disable-next-line no-continue
                continue;
              }
              const pages = useAdAccountStore().facebookPages[tsAccount?.id || 0];
              const adTask = new AdDTO(
                adVariation.variables,
                adVariation.entityId,
                adVariation.macros,
                pages,
                pixelList
              );
              const adErrors = adTask
                .setJobId(campaignTask.getJobId())
                .setTsAccount(tsAccount!)
                .setCampaignTask(campaignTask)
                .setAdSetTask(adSetTask)
                .setupTask();
              errors.push(...adErrors);
              adSetTask.registerSubTask(adTask);
            }
          }
          campaignTaskForCreation.push(campaignTask);
        }
      }
      return { campaignTaskForCreation, errors, campaignIds, updatedAt: this.abUpdated };
    },
    getField:
      (state) =>
      <K extends keyof Omit<ICampaignCreate, 'internalId'>>(key: K) => {
        const abVariableStore = useABVariableStore();
        return abVariableStore.getField(SupportedEntities.campaign, key);
      },
    isCampaignForSpecialCategories(): boolean {
      const categories = this.getField('special_ad_categories');
      return !!categories.value?.[0]?.value?.length;
    },
    getSelectedRules(): string[] {
      return this.ruleIds;
    },
  },
  actions: {
    saveState(): Record<string, any> {
      const stores = [
        useNavigationStore(),
        useAbGroups(),
        useABVariableStore(),
        useAdSetStore(),
        useAdStore(),
        useTargetingStore(),
        useCreativeStore(),
      ];
      const state = stores.reduce((acc, store) => {
        return {
          ...acc,
          ...store.saveState(),
        };
      }, {});
      return { campaign: { campaigns: this.$state.campaigns, ruleIds: this.$state.ruleIds }, ...state };
    },
    setRuleIds(ruleIds: string[]) {
      this.ruleIds = ruleIds;
    },
    loadState(template: any) {
      const parsedState = template.campaign as CampaignStore;
      const campaignId = Object.values(parsedState.campaigns).map((e) => e.internalId);
      if (parsedState) {
        this.campaigns = { ...this.campaigns, ...parsedState.campaigns };
        this.ruleIds = parsedState.ruleIds;
      }
      useNavigationStore().setCampaignInternalId(campaignId[0]);
    },
    switchCampaignBudgetMode(value: boolean) {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignBuildId;
      const adsetStore = useAdSetStore();
      if (!campaignId) {
        console.log('Campaign ID not found');
        return;
      }
      const currentCampaign = this.campaigns?.[campaignId] || {};
      currentCampaign.enable_budget_for_campaign = value;

      if (!value) {
        useABVariableStore().resetField(SupportedEntities.campaign, 'daily_budget');
        useABVariableStore().resetField(SupportedEntities.campaign, 'lifetime_budget');
        useABVariableStore().resetField(SupportedEntities.campaign, 'bid_strategy');
        useABVariableStore().resetField(SupportedEntities.campaign, 'spend_cap');

        const abGroups = useAbGroups().getAbGroupForEntity(SupportedEntities.campaign);
        const abForCurrentGroup = Object.values(abGroups || {});
        const currentAbGroups = abForCurrentGroup.filter((ab) => ab.typeId === CampaignAbGroupTypes.CampaignBudget);
        currentAbGroups.forEach((ab) => useAbGroups().deleteGroup(SupportedEntities.campaign, ab.id));

        // Add an empty (Default Campaign Budget group, this will be used to set the bidding type for this campaign)
        useAbGroups().addAbGroup(SupportedEntities.campaign, {
          entity: SupportedEntities.campaign,
          description: FacebookAbGroups.CampaignBudget.readableName,
          name: FacebookAbGroups.CampaignBudget.readableName,
          typeId: CampaignAbGroupTypes.CampaignBudget,
        });
        adsetStore.switchCampaignBudgetMode(value);
      } else {
        const abGroups = useAbGroups().getAbGroupForEntity(SupportedEntities.campaign);
        const abForCurrentGroup = Object.values(abGroups || {});
        const currentAbGroups = abForCurrentGroup.filter((ab) => ab.typeId === CampaignAbGroupTypes.CampaignBudget);
        currentAbGroups.forEach((ab) => useAbGroups().deleteGroup(SupportedEntities.campaign, ab.id));

        adsetStore.switchCampaignBudgetMode(value);
      }

      this.campaigns = { ...this.campaigns, [campaignId]: currentCampaign };
    },
    addVariable<K extends keyof ABTestableFields>(field: K, value: InternalABVariable<ABTestableFields[K]>) {
      const abVariableStore = useABVariableStore();
      abVariableStore.addVariable(SupportedEntities.campaign, field, value);
    },
    removeVariable<K extends keyof ABTestableFields>(field: K, key: string) {
      const abVariableStore = useABVariableStore();
      abVariableStore.removeVariable(SupportedEntities.campaign, field, key);
    },
    editVariable<K extends keyof ABTestableFields>(
      field: K,
      key: string,
      value: InternalABVariable<ICampaignCreate[K]>
    ) {
      const abVariableStore = useABVariableStore();
      abVariableStore.editVariable(SupportedEntities.campaign, field, key, value);
    },
    addOrEditVariableName<K extends keyof ABTestableFields>(
      field: K,
      abGroupId: string,
      variableId: string,
      name: string
    ) {
      const abVariable = useABVariableStore();
      const campaignBuildId = useNavigationStore().getCampaignBuildId;
      abVariable.renameVariable(SupportedEntities.campaign, campaignBuildId, abGroupId, field, variableId, name);
    },
    addOrEditVariable<K extends keyof ABTestableFields>(field: K, value: InternalABVariable<ICampaignCreate[K]>) {
      const abVariableStore = useABVariableStore();
      const campaignBuildId = useNavigationStore().getCampaignBuildId;
      abVariableStore.addOrEditVariable(SupportedEntities.campaign, field, value, campaignBuildId);
    },
    addError(error: Partial<FieldError>) {
      const errorId = uid(8);
      this.globalErrors.push({
        errorId,
        message: error.message || '',
        description: error.description || '',
        requiresAction: error.requiresAction || false,
      });
    },
    addNewCampaign() {
      const newInternalId = uid();
      const buildId = getIdForEntity(SupportedEntities.campaign, [newInternalId]);
      const navigation = useNavigationStore();
      const abVariable = useABVariableStore();
      navigation.setCampaignInternalId(newInternalId);
      const abGroupStore = useAbGroups();
      abGroupStore.initAbGroup(SupportedEntities.campaign, newInternalId);
      const defultId = abGroupStore.getDefaultAbGroupForEntity(SupportedEntities.campaign, newInternalId);
      const budgetGroupId = uid();
      abGroupStore.addAbGroup(SupportedEntities.campaign, {
        entity: SupportedEntities.campaign,
        description: FacebookAbGroups.CampaignBudget.readableName,
        name: FacebookAbGroups.CampaignBudget.readableName,
        typeId: CampaignAbGroupTypes.CampaignBudget,
        id: budgetGroupId,
      });
      const campaign = {
        account_id: {
          value: [],
        },
        special_ad_categories: {
          value: [],
        },
        special_ad_category_country: {
          value: [],
        },
        adlabels: {
          value: [],
        },
        objective: {
          value: [
            {
              abGroup: defultId,
              value: CampaignMeta.Objective.OUTCOME_SALES,
              field: 'objective',
              variableId: 'objective',
            },
          ],
        },
        spend_cap: {
          value: [],
        },
        start_time: {
          value: [],
        },
        status: {
          value: [],
        },
        stop_time: {
          value: [],
        },
        bid_strategy: {
          value: [],
        },
        buying_type: {
          value: [
            {
              abGroup: defultId,
              field: 'buying_type',
              value: CampaignMeta.BuyingType.AUCTION,
              variableId: 'buying_type',
            },
          ],
        },
        daily_budget: {
          value: [],
        },
        lifetime_budget: {
          value: [],
        },
        name: {
          value: [
            {
              abGroup: defultId,
              field: 'name',
              value: { name: 'New Campaign' },
              variableId: 'name',
            },
          ],
        },
      };
      abVariable.initEntity(SupportedEntities.campaign, buildId, campaign);
      this.campaigns = {
        ...(this.campaigns || {}),
        [buildId]: { internalId: newInternalId, adsets: [], enable_budget_for_campaign: false },
      };

      const adsetStore = useAdSetStore();
      adsetStore.addNewAdSet(newInternalId);
      this.updateAbGroups();
    },
    cloneCampaign(campaignId: string) {
      if (!campaignId) {
        console.log('Campaign ID not found');
        return;
      }
      const navigation = useNavigationStore();
      const newInternalId = uid();
      const currentBuildId = getIdForEntity(SupportedEntities.campaign, [campaignId]);
      const newBuildId = getIdForEntity(SupportedEntities.campaign, [newInternalId]);

      navigation.setCampaignInternalId(newInternalId);
      navigation.cloneNavigation(SupportedEntities.campaign, { currentBuildId, newBuildId });
      const abGroup = useAbGroups();
      abGroup.clone(SupportedEntities.campaign, { currentBuildId, newBuildId });
      const currentCampaign = this.campaigns[campaignId];
      const newCampaign = {
        ...currentCampaign,
        internalId: newInternalId,
      };
      const abVariable = useABVariableStore();
      abVariable.cloneEntity(SupportedEntities.campaign, currentBuildId, newBuildId);
      const currentName = abVariable.getFieldForEntity(SupportedEntities.campaign, 'name', newBuildId).value[0];
      currentName.value.name = `${currentName.value.name} - Copy`;
      abVariable.addOrEditVariable(SupportedEntities.campaign, 'name', currentName, newBuildId);
      this.campaigns = {
        ...this.campaigns,
        [newBuildId]: cloneDeep(newCampaign),
      };
      const adsetStore = useAdSetStore();
      adsetStore.cloneAdSet({ currentCampaignId: campaignId, newCampaignId: newInternalId });
    },
    deleteCampaign(campaignId: string) {
      const { campaigns } = this;
      const abVariable = useABVariableStore();
      const otherKey = Object.values(campaigns).find((k) => k.internalId !== campaignId);
      if (!otherKey) {
        console.log('No other campaign found');
        return;
      }
      const buildId = getIdForEntity(SupportedEntities.campaign, [campaignId]);
      const navigation = useNavigationStore();
      navigation.setCampaignInternalId(otherKey.internalId);
      const adsetStore = useAdSetStore();
      adsetStore.delete({ campaignId });
      abVariable.removeEntity(SupportedEntities.campaign, buildId);
      delete campaigns[buildId];
      this.campaigns = {
        ...campaigns,
      };
      ABRegistryInstance.deleteEntity(getIdForEntity(SupportedEntities.campaign, [campaignId]));
      const abGroup = useAbGroups();
      abGroup.delete(SupportedEntities.campaign, buildId);
      navigation.setCampaignInternalId(otherKey.internalId);
      navigation.delete(SupportedEntities.campaign, buildId);
    },
    dispatch(action: NavigationAction) {
      const adsetStore = useAdSetStore();

      switch (action.event) {
        case NavigationActionEvent.DUPLICATE:
          validateData(action.payload, { campaignId: true });
          this.cloneCampaign(action.payload.campaignId);
          break;
        case NavigationActionEvent.CREATE:
          this.addNewCampaign();
          break;
        case NavigationActionEvent.CREATE_ADSET:
          validateData(action.payload, { campaignId: true });
          adsetStore.addNewAdSet(action.payload.campaignId);
          break;
        case NavigationActionEvent.DELETE: {
          validateData(action.payload, { campaignId: true });
          this.deleteCampaign(action.payload.campaignId);
          break;
        }
        case NavigationActionEvent.COPY_ID:
          validateData(action.payload, { campaignId: true });
          navigator.clipboard.writeText(action.payload.campaignId);
          break;
        default:
          throw new Error(`Action ${action.event} not found`);
      }
    },
    updateAbGroups(forCreation = false) {
      const abGroupStore = useAbGroups();
      const variables = useABVariableStore();
      const spreadFactor = forCreation ? abGroupStore.getSpreadFactor[SupportedEntities.adset] : 9999;
      updateAbGroupsRegistry(
        ABRegistryInstance,
        this.campaigns,
        variables.getEntity(SupportedEntities.campaign),
        abGroupStore.getAllAbGroupsForEntity(SupportedEntities.campaign),
        abGroupStore.getGlobalConstraints,
        true,
        spreadFactor
      );
      const adsetStore = useAdSetStore();
      adsetStore.updateAbGroups(forCreation);
      this.abUpdated = new Date();
    },
    deleteABGroup(groupId: string) {
      const navigation = useNavigationStore();
      const abVariable = useABVariableStore();
      const campaignBuildId = navigation.getCampaignBuildId;
      abVariable.removeAbGroup(SupportedEntities.campaign, campaignBuildId, groupId);
    },
    cloneABGroup(currentGroupId: string) {
      const navigation = useNavigationStore();
      const abVariable = useABVariableStore();
      const campaignBuildId = navigation.getCampaignBuildId;
      const newGroupId = uid(8);
      useAbGroups().cloneGroup(SupportedEntities.campaign, { groupId: currentGroupId, newGroupId });
      abVariable.cloneAbGroup(SupportedEntities.campaign, campaignBuildId, currentGroupId, newGroupId);
    },
    getTasksForCreation() {
      // Make sure Tree view is built
      this.updateAbGroups(true);
      const { campaignTaskForCreation, errors } = this.getComputedCampaignForCreation;
      console.log(`Campaign Found errors`, errors);
      return campaignTaskForCreation;
    },
    async saveTasks() {
      const campaignTaskForCreation = this.getTasksForCreation();
      for (const campaignTask of campaignTaskForCreation) {
        const jobTasks = campaignTask.getAllInOneTask();
        const adAccount = useAdAccountStore();
        await adAccount.startJob([jobTasks]);
      }
    },
    async saveTemplate(template: Record<string, any>) {
      return api.campaignCreator.saveTemplate(template);
    },
    async fetchTemplates() {
      if (!this.templates.length) {
        this.templatesLoading = true;
        try {
          const templates = (await api.campaignCreator.getTemplate()) as Array<FacebookTemplate>;
          this.templates = templates.sort((a, b) => {
            const diff =
              new Date(a?.lastUsed || a.createdAt).getTime() - new Date(b?.lastUsed || b.createdAt).getTime();
            return diff < 0 ? 1 : -1;
          });
        } catch (error) {
          console.log('Error fetching templates ', error);
        }
        this.templatesLoading = false;
      }
    },
    async deleteTemplate(id: string) {
      try {
        await api.campaignCreator.deleteTemplate(id);
        this.templates = this.templates.filter((t) => t._id !== id);
      } catch (e) {
        console.log('Error deleting template ', e);
      }
    },
    validateForm() {
      this.updateAbGroups();
      const { errors } = this.getComputedCampaignForCreation;
      const abVariable = useABVariableStore();
      console.log('Next step Validation', errors);
      for (const error of errors) {
        const { variableId, abGroup, entityBuildId, ...errorMetadata } = error;
        abVariable.registerError(
          errorMetadata.entity,
          error.field,
          [errorMetadata],
          error.variableId,
          error.abGroup,
          error.entityBuildId
        );
      }
    },
    isMinimumSetupDone() {
      const abVariable = useABVariableStore();
      const accounts = this.getField('account_id').value;
      if (!accounts || !accounts.length) {
        abVariable.registerError(SupportedEntities.campaign, 'account_id', [
          {
            message: 'Account ID is required',
            entity: SupportedEntities.campaign,
            field: 'account_id',
            type: ErrorType.MISSING,
          },
        ]);
        return false;
      }
      return true;
    },
    applyTemplate(id: string) {
      const template = this.templates.find((t) => t._id === id);
      this.currentTemplate.id = id;
      const editDate = template?.updatedAt || template?.createdAt || '';
      this.currentTemplate.lastSaved = editDate ? new Date(editDate).getTime() : 0;
      this.currentTemplate.name = template?.name || `New Template ${moment().format('DD/MM/YYYY')}`;
      if (!template) {
        console.log('Template not found');
        return;
      }
      api.campaignCreator.incrementUsage(id).catch((e) => {
        console.log(`Failed increment usage`, e);
      });
      const currentCampaigns = Object.values(this.campaigns);
      const navigation = useNavigationStore();
      navigation.setCurrentLevel(Level.Campaign);
      navigation.setCampaignInternalId(navigation.campaignInternalId);
      navigation.setAdSetInternalId(undefined);
      navigation.setAdInternalId(undefined);
      const stores = [
        useAbGroups(),
        useABVariableStore(),
        useCampaignStore(),
        useAdSetStore(),
        useAdStore(),
        useTargetingStore(),
        useCreativeStore(),
        useNavigationStore(),
      ];

      for (const store of stores) {
        store.loadState(template?.state);
      }
      for (const campaign of currentCampaigns) {
        this.deleteCampaign(campaign.internalId);
      }
      this.updateAbGroups();
      useAdAccountStore().prepareAccount();
    },
    updateTemplateName(name: string) {
      this.currentTemplate.name = name;
    },
    async saveStateTemplate() {
      const existingTemplate = this.templates.find((t) => t._id === this.currentTemplate.id);
      if (!this.currentTemplate.id || !existingTemplate) {
        this.saveAsTemplate(true);
      }
      if (existingTemplate) {
        const tasks = this.getTasksForCreation();
        const metrics = tasks.map((task) => task.getAllMetrics()).flat();
        const state = this.saveState();
        existingTemplate.state = state;
        existingTemplate.metadata = CampaignDTO.reduceMetrics(metrics) as any;
        existingTemplate.name = this.currentTemplate.name;
        await api.campaignCreator.updateTemplate(existingTemplate);
        this.currentTemplate.lastSaved = Date.now();
      }
    },
    async saveAsTemplate(publicTemplate: boolean, name?: string) {
      const tasks = this.getTasksForCreation();
      const state = this.saveState();
      const metrics = tasks.map((task) => task.getAllMetrics()).flat();
      const metadata = CampaignDTO.reduceMetrics(metrics);
      const savedTemplate = {
        name: name || this.currentTemplate.name || `Internal Template ${new Date().toISOString()}`,
        state,
        public: publicTemplate,
        createdAt: new Date(),
        version: '1.0',
        metadata,
        usage: 1,
      };
      const newTemplate = await this.saveTemplate(savedTemplate);
      this.currentTemplate.id = newTemplate._id;
      this.templates = [newTemplate, ...this.templates];
      this.currentTemplate.lastSaved = Date.now();
    },
  },
});

export default useCampaignStore;
