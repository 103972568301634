<template>
  <div class="content-wrapper smart-list-container has-fixed-footer">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">
          <template v-if="mode === 'edit'">Edit Smart List</template>
          <template v-else-if="mode === 'clone'">Clone Smart List</template>
          <template v-else-if="mode === 'create'">Create Smart List</template>
        </h1>
      </div>
    </div>

    <div class="smart-list-container__form">
      <smart-list
        ref="smartListRef"
        :mode="mode"
        :after-create="() => { $router.push({name: 'Smart Lists'}) }"
      />
    </div>

    <!-- Footer -->
    <div class="fixed-footer">
      <div class="fixed-footer__actions d-flex justify-content-end">
        <b-btn class="primary-button" @click="$refs.smartListRef.submit()">Save</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SmartList from '@/components/smartlist/SmartList';

export default {
  name: 'SmartListPage',
  components: { SmartList },
  props: {
    mode: { type: String, required: true },
  },
};
</script>

<style lang="scss">
  .smart-list-container {
    .section-header {
      margin-bottom: 1.5rem;
    }
  }
</style>
