/* eslint-disable import/no-cycle */
import { ABBase } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { ABRegistry } from '@/views/Automation/CampaignCreatorV2/ab/core/ABRegistry';
import { GroupABTest } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABTest';
import { calculateName, NameType } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import * as ABTest from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { ABGroupMetadata, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { EntityTree } from './GroupABEntityCombiner';

export interface EntityGroupPreview<Value> {
  type: string;
  entityId: string;
  internalEntityId: string;
  variations: Array<ABTest.AbTestPreview<Value>>;
  children: Array<EntityGroupPreview<Value>>;
}
export interface EntityForCreation<Value> {
  status: boolean;
  type: string;
  entityId: string;
  variables: ABTest.ABDependency<Value>[];
  children: EntityForCreation<Value>[];
  movingVariables: string[];
  macros: ABTest.Macro[];
  variableUsage: Record<string, number>;
}

// This group is used to present an entity. An entity is a group of variables that needs to be tested together.
// This serves as a container for all the variables and groups that are part of the entity and also identifier for the entity boundary.
// Main reason for having this is to provide an identifier for entity level, also it will be the way to traverse information between child and parent entity
export class GroupABEntity<Value extends ABTest.ABValue<unknown>[], E extends SupportedEntities> extends GroupABTest<
  Value,
  E
> {
  constructor(
    _id: string,
    group_name: string,
    imetadata: ABGroupMetadata<E>,
    public internalEntityId: string,
    registry: ABRegistry
  ) {
    super(_id, group_name, imetadata, registry);
  }

  public get name(): string {
    const nameField = (this.possibleOptions[0]?.find((v) => v.field === 'name')?.value || { name: '' }) as {
      name: string;
    };
    return nameField.name;
  }

  protected get abTests(): Array<ABBase<Value>> {
    const abValues = this.children.map((child) => {
      const group = this.registry.getAbGroup<Value>(child, this.entityId);
      const variable = this.registry.getVariable(child, this.entityId);
      if (!group && !variable) {
        throw new Error(`Group or variable not found ${child}`);
      }
      if (group && variable) {
        throw new Error('Group and variable found');
      }
      return group || variable;
    });
    return abValues;
  }

  public buildEntityForCreation(
    parentVariables: ABTest.ABDependency<Value>[] = [],
    parentMovingVariables: string[] = [],
    parentMacros: ABTest.Macro[] = []
  ): EntityForCreation<Value>[] {
    return this.possibleOptions.map((variation) => {
      const entityIdVariation: ABTest.ABDependency<Value> = this.getEntityUniqueIdentifier();
      const macros = [...this.macros, ...parentMacros];
      // TODO:This is not intended to be here, Entity DTO, has capability to calculate name giving template and macros
      const status = this.variationStatus([...variation, entityIdVariation, ...parentVariables]);
      const movingVariables = variation
        .filter((e) => this.variableUsage[e.field] > 1)
        .map((e) => e.variableUniquePath)
        .filter(Boolean) as string[];
      return {
        macros,
        variableUsage: this.variableUsage,
        entityId: this.entityId,
        movingVariables: [entityIdVariation.variableUniquePath!, ...movingVariables, ...parentMovingVariables],
        variables: [...variation, entityIdVariation],
        status,
        type: this.type,
        children: [],
      };
    });
  }

  public getEntityTree(
    parentVariables?: ABTest.ABDependency<Value>[],
    movingVariables?: string[],
    parentMacros?: ABTest.Macro[]
  ): EntityTree {
    const parentVariations = this.buildEntityForCreation(parentVariables, movingVariables, parentMacros);
    return {
      entityId: this.entityId,
      type: this.type,
      internalEntityId: this.internalEntityId,
      abVariationGenerated: parentVariations.map((variation) => ({
        entityVariations: [variation],
        children: [],
      })),
    };
  }

  public get previewVariations(): Array<ABTest.AbTestPreview<Value>> {
    const foundMacroValues = this.macros;
    const usageByField = this.variableUsage;
    return this.possibleOptions.map((variation) => {
      const entityIdVariation: ABTest.ABDependency<Value> = this.getEntityUniqueIdentifier();
      // TODO: Create a different type for Name Variable
      const movingVariables = variation
        .filter((v) => usageByField[v.field] > 1)
        .map((e) => e.variableUniquePath)
        .filter(Boolean) as string[];
      const nameField = variation.find((v) => v.field === 'name')?.value || { name: '' };
      const newName = calculateName(nameField as NameType, foundMacroValues, variation);
      const status = this.variationStatus([...variation, entityIdVariation]);
      return {
        name: newName || '',
        status,
        entityId: this.entityId,
        movingVariables: [entityIdVariation.variableUniquePath!, ...movingVariables],
        variables: variation,
      };
    });
  }

  private getEntityUniqueIdentifier(): ABTest.ABDependency<Value> {
    return {
      field: `uniqueIdentifierForEntity${this.entityId}`,
      type: 'internal',
      value: this.entityId as unknown as Value,
      abId: this.entityId,
      variableUniquePath: `uniqueIdentifierForEntity${this.entityId}`,
    };
  }

  public get type(): ABTest.ABTestType {
    return 'ENTITY';
  }
}
