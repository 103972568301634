import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';

const infoText = {
  epc: 'Epc is calculated as (tracker revenue)/(traffic source clicks)',
  ts_epc: 'Traffic Source Epc is calculated as (traffic source revenue)/(traffic source clicks). Use only if you are posting revenues to the traffic source.',
  tr_epc: 'Tracker Epc is calculated as (tracker revenue)/(tracker clicks)',
  eps: 'EPS is calculated as Revenue/GA Sessions (edited)',
  epv: 'EPV is calculated as Revenue/GA Page Views',
};

const generateBidCadOptions = (device = '') => [
  { value: 'set', text: `Set ${device} Bid To` },
  { value: 'increase', text: `Increase ${device} Bid By` },
  { value: 'decrease', text: `Decrease ${device} Bid By` },
];

const bidCadActionOptions = generateBidCadOptions();

const bidCadUnitOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const bidCadToOptions = [
  {
    value: 'bid',
    text: 'of Current Bid',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'epc',
    text: 'of EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'ts_epc',
    text: 'of Traffic Source EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'tr_epc',
    text: 'of Tracker EPC',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'eps',
    text: 'of EPS',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'epv',
    text: 'of EPV',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'coefficient',
    text: 'of Current Coefficient',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const bidCadModelTemplates = {
  classic: {
    device: {
      desktop: {
        action: {
          set: {
            unit: {
              static: {},
            },
          },
          increase: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                },
              },
            },
          },
          decrease: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                },
              },
            },
          },
        },
      },
      // 'tablet': {
      //   action: {
      //     'set': {
      //       unit: {
      //         'static': {}
      //       }
      //     },
      //     'increase': {
      //       unit: {
      //         'static': {},
      //         'percentage': {
      //           to: {
      //             'bid': {}
      //           }
      //         }
      //       }
      //     },
      //     'decrease': {
      //       unit: {
      //         'static': {},
      //         'percentage': {
      //           to: {
      //             'bid': {}
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      mobile: {
        action: {
          set: {
            unit: {
              static: {},
            },
          },
          increase: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                },
              },
            },
          },
          decrease: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                },
              },
            },
          },
        },
      },
    },
  },
  widget: {
    device: {
      desktop: {
        action: {
          set: {
            unit: {
              static: {},
              percentage: {
                to: {
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
          increase: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
          decrease: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
        },
      },
      // 'tablet': {
      //   action: {
      //     'set': {
      //       unit: {
      //         'static': {},
      //         'percentage': {
      //           to: {
      //             'epc': { info: infoText.epc },
      //             'ts_epc': { info: infoText.ts_epc },
      //             'tr_epc': { info: infoText.tr_epc },
      //             'eps': { info: infoText.eps },
      //             'epv': { info: infoText.epv }
      //           }
      //         }
      //       }
      //     },
      //     'increase': {
      //       unit: {
      //         'static': {},
      //         'percentage': {
      //           to: {
      //             'bid': {},
      //             'epc': { info: infoText.epc },
      //             'ts_epc': { info: infoText.ts_epc },
      //             'tr_epc': { info: infoText.tr_epc },
      //             'eps': { info: infoText.eps },
      //             'epv': { info: infoText.epv }
      //           }
      //         }
      //       }
      //     },
      //     'decrease': {
      //       unit: {
      //         'static': {},
      //         'percentage': {
      //           to: {
      //             'bid': {},
      //             'epc': { info: infoText.epc },
      //             'ts_epc': { info: infoText.ts_epc },
      //             'tr_epc': { info: infoText.tr_epc },
      //             'eps': { info: infoText.eps },
      //             'epv': { info: infoText.epv }
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      mobile: {
        action: {
          set: {
            unit: {
              static: {},
              percentage: {
                to: {
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
          increase: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
          decrease: {
            unit: {
              static: {},
              percentage: {
                to: {
                  bid: {},
                  epc: { info: infoText.epc },
                  ts_epc: { info: infoText.ts_epc },
                  tr_epc: { info: infoText.tr_epc },
                  eps: { info: infoText.eps },
                  epv: { info: infoText.epv },
                },
              },
            },
          },
        },
      },
    },
  },
};

const bidToOptionsCM = [];
const loadCmOptions = async () => {
  if (!bidToOptionsCM.length) {
    const response = await apiStore.configuration.cmFields();
    if (response && response.fields) {
      response.fields.forEach((cmItem) => {
        if (cmItem.customMetric) {
          bidToOptionsCM.push({
            value: cmItem.item.key,
            text: `of ${getFieldTextAndDescription(cmItem).text || cmItem.header.content}`,
            baseType: RuleTypes.Conditions.BaseFieldType.Metric,
            type: RuleTypes.Conditions.ValueType.Number,
          });
        }
      });
    }
  }
};

export default {
  async getData() {
    const bidCadModelTemplatesClone = JSON.parse(JSON.stringify(bidCadModelTemplates));
    const bidCadToOptionsClone = JSON.parse(JSON.stringify(bidCadToOptions));

    try {
      await loadCmOptions();
      if (bidToOptionsCM.length) {
        bidCadToOptionsClone.push(...bidToOptionsCM);
        bidToOptionsCM.forEach((cmItem) => {
          // Classic
          bidCadModelTemplatesClone.classic.device.desktop.action.increase.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.classic.device.desktop.action.decrease.unit.percentage.to[cmItem.value] = {};

          bidCadModelTemplatesClone.classic.device.mobile.action.increase.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.classic.device.mobile.action.decrease.unit.percentage.to[cmItem.value] = {};

          // Widget
          bidCadModelTemplatesClone.widget.device.desktop.action.set.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.widget.device.desktop.action.increase.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.widget.device.desktop.action.decrease.unit.percentage.to[cmItem.value] = {};

          bidCadModelTemplatesClone.widget.device.mobile.action.set.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.widget.device.mobile.action.increase.unit.percentage.to[cmItem.value] = {};
          bidCadModelTemplatesClone.widget.device.mobile.action.decrease.unit.percentage.to[cmItem.value] = {};
        });
      }
    } catch (error) {
      throw Error('Fail loading dynamics fields custom-metrics cad');
    }
    return {
      bidCadActionOptions, bidCadUnitOptions, bidCadToOptions: bidCadToOptionsClone, bidCadModelTemplates: bidCadModelTemplatesClone, generateBidCadOptions,
    };
  },
};
