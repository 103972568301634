import api from './api';
import { model } from './model';

export default {
  async getConfig() {
    return {
      api,
      model,
    };
  },
};
