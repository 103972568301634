import { Icons } from '@sh/components/Utils/icons';
import { TrafficSource, VueOptiTable } from '@sh/types';

export interface ReportOption {
  fieldsDomain: string;
  reportType: string;
  trafficSources?: (TrafficSource | 'ALL')[];
  uiName?: string;
}

export interface ReportOptionsResponse {
  globalReportOptions: MakeKeyRequired<ReportOption, 'uiName'>[];
  reportOptions: MakeKeyRequired<ReportOption, 'trafficSources'>[];
}

export interface ReportingRequest {
  startDate: string;
  endDate: string;
  reportType: ReportType;
  trafficSourceAccountIds: number[];
  pagination: Pagination;
  reportFields: VueOptiTable.Field[];
  conditions?: Array<ColumnCondition>;
  search?: string;
  sort?: string;

  // Normal Report
  typeName: TrafficSource | null;
  dateType: 'day' | 'month' | 'year' | null;
  campaignIds?: string[];

  // Search Feed Report
  selectedUserId?: number;
  clientEmails?: string[];
  trafficSourceTypes?: TrafficSource[];
}

export type ReportingResponse = Table | { type: string; message: string };

export interface ColumnCondition {
  field: string;
  operator: string;
  value: string | number;
}

export interface Pagination {
  page?: number;
  pageSize?: number;
}

export enum ReportType {
  Account = 'Account',
  UniqueName = 'UniqueName',
  Campaign = 'Campaign',
  Section = 'Section',
  Target = 'Target',
  Content = 'Content',
  Widget = 'Widget',
  AdGroup = 'AdGroup',
  Domain = 'Domain',
  Spot = 'Spot',
  Exchange = 'Exchange',
  AccountCompliance = 'AccountCompliance',
  ContentCompliance = 'ContentCompliance',
  ClientCompliance = 'ClientCompliance',
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface PaginationInfo {
  total: number;
  pageSize: number;
  lastPage: number;
  currentPage: number;
}

export interface Table {
  items: Array<Record<string, unknown>>;
  totals: Record<string, string>;
  pagination: PaginationInfo;
}

export interface TableSort {
  key: string;
  order: string;
  field?: string;
}

export interface TableItem {
  image_url: string;
  video_url: string;
  hasVideoError: boolean;
}

export interface TableModel {
  selectedRows: unknown[];
  displayColumns: unknown[];
  columnsOrder: unknown[];
}

export enum SavedReportStatus {
  Failed,
  Uploading,
  Uploaded,
}

export interface ReportOptions {
  reportOptions: ReportOption[];
  advancedReportOptions: ReportOption[];
}

export interface ReportTab {
  type: ReportType | string;
  name: string;
  icon?: Icons;
}

export interface ReportQueryStringDto {
  payload: ReportingRequest;
  tab: string;
}
