/* eslint-disable import/no-cycle */
import { ABRegistry, ABRegistryInstance } from '@/views/Automation/CampaignCreatorV2/ab';
import { getAllMacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { MacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as AdSetMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import { ConversionGoalsMetadata } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import * as CampaignMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { Icons } from '@/views/Automation/CampaignCreatorV2/helpers/icons';
import useAbGroups, { AbGroupForEntity } from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useABVariableStore, {
  AdSetAbVariables,
  ErrorData,
  TargetingAbVariables,
} from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import useAdStore from '@/views/Automation/CampaignCreatorV2/store/Ad';
import useAdAccountStore from '@/views/Automation/CampaignCreatorV2/store/AdAccount';
import useCampaignStore from '@/views/Automation/CampaignCreatorV2/store/Campaign';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import useTargetingStore from '@/views/Automation/CampaignCreatorV2/store/Targeting';
import {
  ErrorType,
  FieldError,
  getIdForEntity,
  InternalABVariable,
  SupportedEntities,
} from '@/views/Automation/CampaignCreatorV2/store/Types';
import {
  Level,
  NavigationAction,
  NavigationActionEvent,
  NavigationActionPayload,
  NavigationMenu,
} from '@/views/Automation/CampaignCreatorV2/types';
import {
  AdSetAbGroups,
  AdSetABGroupsPublic,
  AdSetFlat,
  AdSetValidation,
  TargetingValidation,
} from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import { AdSetDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdSetDTO';
import { FacebookAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/FacebookAbGroups';
import { AdSetEntityMacros } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import { validateData } from '@sh/helpers';
import { api } from '@sh/services/api';
import { cloneDeep } from 'lodash';
import objectHash from 'object-hash';
import { defineStore } from 'pinia';
import uid from 'uid';

type AdSet = Pick<AdSetFlat, keyof typeof AdSetValidation>;

interface InternalAdSetMetadata {
  ads: string[];
  internalId: string;
  campaignInternalId: string;
  enableDailySpendCap?: boolean;
  enableEndDateTime?: boolean;
}

type AdSetState = InternalAdSetMetadata;
type SuggestedVariables = Record<string, InternalABVariable<any>>;
type Suggestions = {
  name: string;
  id: string;
  variables: SuggestedVariables;
  counter: number;
};

interface AdSetStore {
  globalErrors: FieldError[];
  adAccountId: string;
  suggestions: Suggestions[];
  isSuggestionsLoading: boolean;
  adsSets: {
    [campaignAdsetId: string]: AdSetState;
  };
}

function registerAdSetVariables(
  registry: ABRegistry,
  adsets: AdSetStore['adsSets'],
  abVariables: Record<string, AdSetAbVariables>,
  target: Record<string, TargetingAbVariables>,
  allAdsetAbGroups: Record<string, AbGroupForEntity<SupportedEntities>>,
  allTargetingAbGroups: Record<string, AbGroupForEntity<SupportedEntities>>,
  globalConstraintById: Array<string[]>,
  fullTree = true,
  spreadFactor = 999
) {
  for (const adsetMeta of Object.values(adsets)) {
    const adsetBuildId = getIdForEntity(SupportedEntities.adset, [adsetMeta.campaignInternalId, adsetMeta.internalId]);
    const adset = {
      ...adsetMeta,
      ...abVariables[adsetBuildId],
    };
    const {
      groups: abGroups,
      mainAbGroup: mainGroupId,
      defaultAbGroup,
      constraintById,
    } = allAdsetAbGroups[adsetBuildId];
    const { groups: targetingAbGroups = {}, constraintById: constraintByIdTarget } =
      allTargetingAbGroups[adsetBuildId] || {};
    registry.registerAbGroups(
      adset.internalId,
      mainGroupId,
      defaultAbGroup,
      { ...abGroups, ...targetingAbGroups },
      adsetBuildId,
      constraintById.concat(constraintByIdTarget).concat(globalConstraintById),
      SupportedEntities.adset,
      adset.campaignInternalId,
      adset.internalId,
      fullTree,
      spreadFactor
    );

    for (const field of Object.keys(adset)) {
      const fieldMeta = AdSetValidation[field as keyof typeof AdSetValidation];
      const variables = adset[field as 'daily_budget']?.value || [];

      if (fieldMeta || variables.length) {
        registry.registerVariables(variables, fieldMeta, adsetBuildId);
      }
    }

    const targeting = target[adsetBuildId];

    for (const field of Object.keys(targeting)) {
      const fieldMeta = TargetingValidation[field as keyof typeof TargetingValidation];
      const variables = targeting[field as 'geo_locations']?.value || [];

      if (fieldMeta || variables.length) {
        registry.registerVariables(variables, fieldMeta, adsetBuildId);
      }
    }
  }
}

// Create a campaign with ___ AdSet Variations and ___ Ad Variations for each AdSet

const useAdSetStore = defineStore(SupportedEntities.adset, {
  state: (): AdSetStore => ({
    adsSets: {} as any,
    globalErrors: [],
    adAccountId: '',
    suggestions: [],
    isSuggestionsLoading: false,
  }),
  getters: {
    performanceGoalOptions: (state) => {
      const campaignStore = useCampaignStore();
      const campaignObjective = campaignStore.getField('objective')?.value?.[0]?.value;
      if (!campaignObjective) {
        console.log('Campaign Objective not found');
        return;
      }
      const abVariable = useABVariableStore();
      const currentAdsetDestinationType = abVariable.getField(SupportedEntities.adset, 'destination_type')?.value?.[0]
        ?.value;
      if (!currentAdsetDestinationType) {
        console.log('Destination Type not found');
      }
      const supportedOptions =
        AdSetMeta.supportMapObjectiveConversionLocationPerformanceGoal[
          campaignObjective as CampaignMeta.Objective.OUTCOME_TRAFFIC
        ][currentAdsetDestinationType as AdSetMeta.DestinationType.WEBSITE] || [];

      const goals = AdSetMeta.performanceGoalOptions.filter((goal) =>
        supportedOptions.includes(goal.id)
      ) as typeof AdSetMeta.performanceGoalOptions;
      return goals;
    },
    getMacroOptions(): Array<MacroOptions> {
      return getAllMacroOptions({ ...AdSetValidation, ...TargetingValidation }, AdSetABGroupsPublic, AdSetEntityMacros);
    },
    possibleConversionLocations() {
      const campaignStore = useCampaignStore();
      const campaignObjective = campaignStore.getField('objective')?.value?.[0]?.value;
      if (!campaignObjective) {
        console.log('Campaign Objective not found');
        return;
      }
      const supported =
        AdSetMeta.supportMapCampaignObjectiveToConversionLocation[
          campaignObjective as CampaignMeta.Objective.OUTCOME_TRAFFIC
        ];
      const current = supported
        ?.map((e) => ConversionGoalsMetadata.find((a) => a.id === e))
        ?.filter(Boolean) as unknown as (typeof ConversionGoalsMetadata)[0][];
      return current;
    },
    isEnabledPixelSelection(): boolean {
      const campaignStore = useCampaignStore();
      const campaignObjective = campaignStore.getField('objective')?.value?.[0]?.value;
      const abVariable = useABVariableStore();
      const optimizationGoal = abVariable.getField(SupportedEntities.adset, 'optimization_goal')?.value?.[0]?.value;
      if (!campaignObjective) {
        console.log('Campaign Objective not found');
        return false;
      }
      const isPerformanceGoals = [
        AdSetMeta.OptimizationGoal.OFFSITE_CONVERSIONS,
        AdSetMeta.OptimizationGoal.VALUE,
      ].includes(optimizationGoal);
      const isObjective = [CampaignMeta.Objective.OUTCOME_SALES, CampaignMeta.Objective.OUTCOME_LEADS].includes(
        campaignObjective
      );
      return isPerformanceGoals && isObjective;
    },
    isSpendCapEnabled(): boolean {
      const currentDailyCap = this.getField('daily_spend_cap').value?.[0]?.value;
      const navigation = useNavigationStore();
      const enableDailySpendCap = this.adsSets[navigation.getAdsetBuildId!]?.enableDailySpendCap;
      return typeof enableDailySpendCap === 'boolean' ? enableDailySpendCap : !!currentDailyCap;
    },
    getResults() {
      const navigation = useNavigationStore();
      const abVariable = useABVariableStore();
      const currentAdsetId = navigation.getAdsetBuildId;
      const abGroupStore = useAbGroups();
      const allAdsetAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.adset);
      const allTargetingAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.targeting);
      const tempRegistry = new ABRegistry();
      const adSetVariables = abVariable.getEntity(SupportedEntities.adset);
      const targetingVariables = abVariable.getEntity(SupportedEntities.targeting);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      registerAdSetVariables(
        tempRegistry,
        this.adsSets,
        adSetVariables,
        targetingVariables,
        allAdsetAbGroups,
        allTargetingAbGroups,
        [],
        false
      );

      const previews = tempRegistry.getMainGroup(currentAdsetId)?.buildEntityForCreation() || [];
      return previews;
    },
    getItemsForNavigation(): Array<NavigationMenu> {
      const abVariables = useABVariableStore();
      const abVariableStore = useABVariableStore();
      const getPayload = (adSet: AdSetState): Partial<NavigationActionPayload> => ({
        adSetId: adSet.internalId,
        campaignId: adSet.campaignInternalId,
      });

      return Object.keys(this.adsSets)
        .map((buildId) => {
          const adset = this.adsSets[buildId];
          const adErrors = abVariableStore.getEntityErrors(SupportedEntities.adset, buildId);
          const creativeErrors = abVariableStore.getEntityErrors(SupportedEntities.targeting, buildId);
          const errors: Partial<Record<ErrorType, ErrorData<any, any, any>[]>> = {
            [ErrorType.MISSING]: [
              ...(adErrors?.[ErrorType.MISSING] || []),
              ...(creativeErrors[ErrorType.MISSING] || []),
            ],
            [ErrorType.BLOCKING]: [
              ...(adErrors?.[ErrorType.BLOCKING] || []),
              ...(creativeErrors[ErrorType.BLOCKING] || []),
            ],
          };
          return {
            name:
              abVariables.getFieldForEntity(SupportedEntities.adset, 'name', buildId)?.value?.[0]?.value?.name || '',
            id: adset.internalId,
            campaignId: adset.campaignInternalId,
            type: SupportedEntities.adset,
            icon: 'widgets' as Icons,
            actions: [
              [
                {
                  name: 'Delete',
                  type: 'delete-adset',
                  event: NavigationActionEvent.DELETE_ADSET,
                  payload: getPayload(adset),
                },
              ],
              [
                {
                  name: 'Create Ad',
                  type: 'create-ad',
                  event: NavigationActionEvent.CREATE_AD,
                  payload: getPayload(adset),
                },
                {
                  name: 'Clone Ad Set',
                  type: 'clone-adset',
                  event: NavigationActionEvent.CLONE_ADSET,
                  payload: getPayload(adset),
                },
              ],
              [
                {
                  name: `ID: ${adset.internalId}`,
                  description: 'Copy',
                  type: 'copy-id',
                  event: NavigationActionEvent.COPY_ID,
                  payload: getPayload(adset),
                },
              ],
            ],
            ...(errors.MISSING?.length && {
              message: {
                content: errors.MISSING,
                icon: {
                  type: 'uncompletedStatus' as Icons,
                  styles: {
                    color: 'var(--cc-blue-500)',
                  },
                },
              },
            }),
            ...(errors.BLOCKING?.length && {
              message: {
                content: errors.BLOCKING,
                icon: {
                  type: 'errorCircle' as Icons,
                  styles: {
                    color: 'var(--cc-error-color)',
                  },
                },
              },
            }),
          };
        })
        .flat();
    },
    isFieldActive: (store: AdSetStore) => (field: keyof typeof AdSetValidation) => {
      const active = AdSetValidation[field].isActive;
      return !!active;
    },
    getSuggestions(store) {
      return store.suggestions;
    },
    getField:
      (state) =>
      <K extends keyof Omit<AdSet, 'internalId'>>(key: K) => {
        const abVariable = useABVariableStore();
        return abVariable.getField(SupportedEntities.adset, key);
      },
    getInternalMetadata:
      (state) =>
      <K extends keyof InternalAdSetMetadata>(field: K): InternalAdSetMetadata[K] => {
        const navigation = useNavigationStore();
        return state.adsSets[navigation.getAdsetBuildId][field];
      },
  },
  actions: {
    saveState() {
      return { adset: { adsSets: this.$state.adsSets } };
    },
    loadState(template: any) {
      const state = template.adset as AdSetStore;
      if (state) {
        this.adsSets = { ...this.adsSets, ...state.adsSets };
      }
    },
    resetField<K extends keyof Omit<AdSet, 'internalId'>>(field: K) {
      useABVariableStore().resetField(SupportedEntities.adset, field);
    },
    switchCampaignBudgetMode(enabledInCampaingLevel: boolean) {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignInternalId;
      const abGroups = useAbGroups();
      const abVariable = useABVariableStore();

      // eslint-disable-next-line guard-for-in
      for (const adsetBuildID in this.adsSets) {
        const adset = this.adsSets[adsetBuildID];
        if (adset.campaignInternalId !== campaignId) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (enabledInCampaingLevel) {
          abVariable.resetField(SupportedEntities.adset, 'daily_budget', adsetBuildID);
          abVariable.resetField(SupportedEntities.adset, 'lifetime_budget', adsetBuildID);
        } else {
          const currentAdsetDailyBudgetGroups = abGroups.getAllAbGroupsForEntity(SupportedEntities.adset);
          const adsetBuildID = getIdForEntity(SupportedEntities.adset, [campaignId, adset.internalId]);
          const currentAdsetDailyBudgetGroup = currentAdsetDailyBudgetGroups[adsetBuildID].groups;
          let currentAbGroup = Object.values(currentAdsetDailyBudgetGroup).filter(
            (group) => group.typeId === AdSetAbGroups.BUDGET
          );
          if (!currentAbGroup.length) {
            abGroups.addAbGroup(SupportedEntities.adset, {
              entity: SupportedEntities.adset,
              typeId: AdSetAbGroups.BUDGET,
              name: 'Budget',
              description: 'Budget',
            });
            const currentAdsetDailyBudgetGroups = abGroups.getAllAbGroupsForEntity(SupportedEntities.adset);
            const adsetBuildID = getIdForEntity(SupportedEntities.adset, [campaignId, adset.internalId]);
            const currentAdsetDailyBudgetGroup = currentAdsetDailyBudgetGroups[adsetBuildID].groups;
            currentAbGroup = Object.values(currentAdsetDailyBudgetGroup).filter(
              (group) => group.typeId === AdSetAbGroups.BUDGET
            );
          }
          for (const group of currentAbGroup) {
            this.addVariable(
              'daily_budget',
              {
                field: 'daily_budget',
                value: 100,
                variableId: uid(),
                abGroup: group.id,
              },
              adsetBuildID
            );
          }
        }
      }
    },
    addVariable<K extends keyof AdSet>(
      field: K,
      value: Omit<InternalABVariable<AdSet[K]>, 'variableId'> & { variableId?: string },
      overrideBuildId?: string
    ) {
      const abVariable = useABVariableStore();
      abVariable.addVariable(SupportedEntities.adset, field, value, overrideBuildId);
    },
    removeVariable<K extends keyof AdSet>(field: K, key: string) {
      const abVariable = useABVariableStore();
      abVariable.removeVariable(SupportedEntities.adset, field, key);
    },
    editVariable<K extends keyof AdSet>(field: K, key: string, value: InternalABVariable<AdSet[K]>) {
      const abVariable = useABVariableStore();
      abVariable.editVariable(SupportedEntities.adset, field, key, value);
    },
    addOrEditVariableName<K extends keyof AdSet>(field: K, abGroupId: string, variableId: string, newName: string) {
      const abVariable = useABVariableStore();
      const adsetBuildID = useNavigationStore().getAdsetBuildId;
      abVariable.renameVariable(SupportedEntities.adset, adsetBuildID, abGroupId, field, variableId, newName);
    },
    addOrEditVariable<K extends keyof AdSet>(field: K, value: InternalABVariable<AdSet[K]>) {
      const abVariable = useABVariableStore();
      abVariable.addOrEditVariable(SupportedEntities.adset, field, value);
    },
    updateInternalMetadata<K extends keyof InternalAdSetMetadata>(field: K, value: InternalAdSetMetadata[K]) {
      const navigation = useNavigationStore();
      this.adsSets = {
        ...this.adsSets,
        [navigation.getAdsetBuildId]: {
          ...this.adsSets[navigation.getAdsetBuildId],
          [field]: value,
        },
      };
    },

    addNewAdSet(campaignId: string) {
      const internalId = uid(8);
      const navigationStore = useNavigationStore();
      // TODO: Add Adset To Campaign
      navigationStore.setAdSetInternalId(internalId);
      const abGroupStore = useAbGroups();
      abGroupStore.initAbGroup(SupportedEntities.adset);
      abGroupStore.initAbGroup(SupportedEntities.targeting);
      const abGroup = abGroupStore.getDefaultAbGroupForEntity(SupportedEntities.adset);
      const targetingStore = useTargetingStore();
      const abVariable = useABVariableStore();
      const adset = {
        bid_amount: {
          value: [],
        },
        bid_strategy: {
          value: [],
        },
        billing_event: {
          value: [
            {
              variableId: 'billing_event',
              value: AdSetMeta.BillingEvent.IMPRESSIONS,
              field: 'billing_event',
              abGroup,
            },
          ],
        },
        daily_budget: {
          value: [],
        },
        daily_imps: {
          value: [],
        },
        daily_min_spend_target: {
          value: [],
        },
        daily_spend_cap: {
          value: [],
        },

        end_time: {
          value: [],
        },
        lifetime_budget: {
          value: [],
        },
        lifetime_imps: {
          value: [],
        },
        lifetime_min_spend_target: {
          value: [],
        },
        lifetime_spend_cap: {
          value: [],
        },
        name: {
          value: [
            {
              variableId: 'name',
              value: {
                name: 'New Ad Set',
              },
              field: 'name',
              abGroup,
            },
          ],
        },
        optimization_goal: {
          value: [
            {
              variableId: 'optimization_goal',
              value: AdSetMeta.OptimizationGoal.LINK_CLICKS,
              field: 'optimization_goal',
              abGroup,
            },
          ],
        },
        destination_type: {
          value: [
            {
              variableId: 'destination_type',
              value: AdSetMeta.DestinationType.WEBSITE,
              field: 'destination_type',
              abGroup,
            },
          ],
        },
        promoted_object: {
          value: [],
        },
        start_time: {
          value: [],
        },
        status: {
          value: [],
        },
        attribution_spec: {
          value: [],
        },
      };
      const buildId = getIdForEntity(SupportedEntities.adset, [campaignId, internalId]);
      abVariable.initEntity(SupportedEntities.adset, buildId, adset);
      this.adsSets = {
        ...this.adsSets,
        [buildId]: {
          ads: [],
          internalId,
          campaignInternalId: campaignId,
        },
      };

      targetingStore.addNewTargeting();
      const adStore = useAdStore();
      adStore.addNewAd(campaignId, internalId);
    },
    cloneAdSet({
      currentCampaignId,
      newCampaignId,
      adsetId,
      directActionOnEntity,
    }: {
      currentCampaignId: string;
      newCampaignId: string;
      adsetId?: string;
      directActionOnEntity?: boolean;
    }) {
      const navigationStore = useNavigationStore();
      const abGroupStore = useAbGroups();
      const targetingStore = useTargetingStore();
      const adStore = useAdStore();
      const abVariable = useABVariableStore();
      // eslint-disable-next-line guard-for-in
      for (const adsetIdKey in this.adsSets) {
        const currentAdset = this.adsSets[adsetIdKey];
        if (
          currentAdset.campaignInternalId === currentCampaignId &&
          (!adsetId || currentAdset.internalId === adsetId)
        ) {
          const newAdsetId = uid(8);
          const currentBuildId = getIdForEntity(SupportedEntities.adset, [currentCampaignId, currentAdset.internalId]);
          const newBuildId = getIdForEntity(SupportedEntities.adset, [newCampaignId, newAdsetId]);
          const newAdset = { ...currentAdset, internalId: newAdsetId, campaignInternalId: newCampaignId };
          this.adsSets = { ...this.adsSets, [newBuildId]: cloneDeep(newAdset) };
          abVariable.cloneEntity(SupportedEntities.adset, currentBuildId, newBuildId);
          navigationStore.cloneNavigation(SupportedEntities.adset, { currentBuildId, newBuildId });
          abGroupStore.clone(SupportedEntities.adset, { currentBuildId, newBuildId });
          if (directActionOnEntity) {
            const name = abVariable.getFieldForEntity(SupportedEntities.adset, 'name', newBuildId)?.value?.[0];
            name.value.name = `${name.value.name} - Copy`;
            abVariable.editVariable(SupportedEntities.adset, 'name', name.variableId, name, newBuildId);
          }
          targetingStore.cloneTargeting({ currentAdsetBuildId: currentBuildId, newAdsetBuildId: newBuildId });
          adStore.cloneAds({ currentAdsetId: currentAdset.internalId, newAdsetId, newCampaignId });
        }
      }
    },
    delete({ campaignId, adsetId }: { campaignId: string; adsetId?: string }) {
      const navigationStore = useNavigationStore();
      const abGroupStore = useAbGroups();
      const targetingStore = useTargetingStore();
      const abVariable = useABVariableStore();
      const adStore = useAdStore();
      if (adsetId) {
        navigationStore.setAdInternalId();
        navigationStore.setAdSetInternalId();
        navigationStore.setCurrentLevel(Level.Campaign);
      }
      const currentState = this.adsSets;
      if (adsetId) {
        if (!campaignId) throw new Error('Campaign ID not found');
        const adsetOwnedByCampaign = Object.values(currentState).filter(
          (adset) => adset.campaignInternalId === campaignId
        );
        if (adsetOwnedByCampaign.length === 1) {
          console.log('Unable to delete AdSet, only one AdSet found in Campaign');
          return;
        }
      }
      for (const adsetBuildID in currentState) {
        if (
          currentState[adsetBuildID].campaignInternalId === campaignId &&
          (currentState[adsetBuildID].internalId === adsetId || !adsetId)
        ) {
          const buildId = getIdForEntity(SupportedEntities.adset, [campaignId, currentState[adsetBuildID].internalId]);
          ABRegistryInstance.deleteEntity(buildId);
          abVariable.removeEntity(SupportedEntities.adset, buildId);
          navigationStore.delete(SupportedEntities.adset, buildId);
          abGroupStore.delete(SupportedEntities.adset, buildId);
          targetingStore.delete(buildId);
          adStore.delete({ adSetId: currentState[adsetBuildID].internalId, campaignId, adId: undefined! });
          delete currentState[adsetBuildID];
        }
      }
      this.adsSets = { ...currentState };
    },
    dispatch(action: NavigationAction) {
      const adStore = useAdStore();

      switch (action.event) {
        case NavigationActionEvent.DELETE_ADSET:
          validateData(action.payload, { campaignId: true, adSetId: true });
          this.delete({ adsetId: action.payload.adSetId, campaignId: action.payload.campaignId });
          break;
        case NavigationActionEvent.CREATE_AD:
          validateData(action.payload, { campaignId: true, adSetId: true });
          adStore.addNewAd(action.payload.campaignId, action.payload.adSetId);
          break;
        case NavigationActionEvent.CLONE_ADSET:
          validateData(action.payload, { campaignId: true, adSetId: true });
          this.cloneAdSet({
            adsetId: action.payload.adSetId,
            currentCampaignId: action.payload.campaignId,
            newCampaignId: action.payload.campaignId,
            directActionOnEntity: true,
          });
          break;
        case NavigationActionEvent.COPY_ID:
          validateData(action.payload, { adSetId: true });
          navigator.clipboard.writeText(action.payload.adSetId);
          break;
        default:
          throw new Error(`Action ${action.event} not found`);
      }
    },
    updateAbGroups(forCreation: boolean) {
      const abGroupStore = useAbGroups();
      const abVariables = useABVariableStore();
      const allAdsetAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.adset);
      const allTargetingAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.targeting);
      const adsetVariables = abVariables.getEntity(SupportedEntities.adset);
      const targetingVariables = abVariables.getEntity(SupportedEntities.targeting);
      const spreadFactor = forCreation ? abGroupStore.getSpreadFactor[SupportedEntities.ad] : 9999;

      registerAdSetVariables(
        ABRegistryInstance,
        this.adsSets,
        adsetVariables,
        targetingVariables,
        allAdsetAbGroups,
        allTargetingAbGroups,
        abGroupStore.getGlobalConstraints,
        true,
        spreadFactor
      );
      const adsStore = useAdStore();
      adsStore.updateAbGroups();
    },
    deleteABGroup(groupId: string) {
      const abVariable = useABVariableStore();
      const adsetBuildId = useNavigationStore().getAdsetBuildId;
      abVariable.removeAbGroup(SupportedEntities.adset, adsetBuildId, groupId);
    },
    // async updateReachEstimation() {
    //   // const adAccount = useAdAccountStore();
    //   // const { buildId, internalId } = useNavigationStore().getCurrentNavigation;
    //   // const adsets = [this.adsSets[buildId]];
    //   return this.getSuggestedVariables();
    //   // if (adsets.length) {
    //   //   const campaignForCreation = ABRegistryInstance.getParentEntityCombiner(internalId).buildEntityForCreation();
    //   //   const adsetDTO = new AdSetDTO(campaignForCreation[0].variables, buildId).setName('test');
    //   //   const payload = adsetDTO.buildAdSet();
    //   //   const {
    //   //     targeting: {
    //   //       geo_locations: { country_group: removedcc, ...geo_locations },
    //   //       excluded_geo_locations: { country_group: removed, ...excluded_geo_locations },
    //   //     },
    //   //   } = payload;
    //   //   return adAccount.getEstimatedReach(
    //   //     { ...payload.targeting, geo_locations, excluded_geo_locations },
    //   //     'IMPRESSIONS'
    //   //   );
    //   // }
    // },
    cloneABGroup(currentGroupId: string) {
      const adsetBuildId = useNavigationStore().getAdsetBuildId;
      const currentAdset = this.adsSets[adsetBuildId];
      if (!currentAdset) {
        console.log('Current Adset not found');
        return;
      }
      const newGroupId = uid(8);
      useAbGroups().cloneGroup(SupportedEntities.adset, { groupId: currentGroupId, newGroupId });
      useABVariableStore().cloneAbGroup(SupportedEntities.adset, adsetBuildId, currentGroupId, newGroupId);
    },
    async getSuggestedVariables() {
      const adAccount = useAdAccountStore();
      const {
        credentials: { accessToken: access_token, adAccountId },
      } = adAccount.currentAdAccount!;

      if (!this.suggestions.length) {
        this.isSuggestionsLoading = true;
        try {
          const values = await api.facebook.rawGet<AdSetMeta.TargetingObject[]>(`${adAccountId}/adsets`, {
            fields: 'targeting',
            access_token,
            limit: 500,
          });
          type ACC = {
            hash: Record<string, any>;
            counter: Record<string, number>;
            variables: Record<string, SuggestedVariables>;
          };
          const mostUsed = (values.data as any[]).reduce<ACC>(
            (acc: ACC, c: any) => {
              const hashId = objectHash(c.targeting);
              acc.variables[hashId] = AdSetDTO.fromApiToVariables(c.targeting);
              acc.hash[hashId] = c.targeting;
              if (!acc.counter[hashId]) {
                acc.counter[hashId] = 1;
              } else {
                acc.counter[hashId]++;
              }
              return acc;
            },
            { hash: {}, counter: {}, variables: {} }
          );
          const suggestions: Array<Suggestions> = [];
          for (const uniqueHas of Object.keys(mostUsed.hash)) {
            const variables = mostUsed.variables[uniqueHas];
            const cleanVariables = Object.keys(variables).reduce<SuggestedVariables>((acc, v) => {
              const variable = variables[v];
              if (variable.value) {
                acc[v] = variable;
              }
              return acc;
            }, {});
            const counter = mostUsed.counter[uniqueHas];
            const readableName = `Targeting used (${counter}) times, over (${values.data.length}) AdSets`;
            suggestions.push({ name: readableName, variables: cleanVariables, counter, id: uniqueHas });
          }
          this.suggestions = suggestions.sort((a, b) => b.counter - a.counter);
        } catch (error) {
          console.log('Error fetching suggestions', error);
        }
        this.isSuggestionsLoading = false;
      }
    },
    switchToSpecialAdCategories(countries: Array<{ id: string; type: string; label: string }>) {
      const currentCampaignId = useNavigationStore().getCampaignInternalId;
      // eslint-disable-next-line guard-for-in
      for (const adsetBuildId in this.adsSets) {
        const adset = this.adsSets[adsetBuildId];
        if (adset.campaignInternalId === currentCampaignId) {
          // By default for this AdSet reset age and gender
          useABVariableStore().resetField(SupportedEntities.targeting, 'age_max', adsetBuildId);
          useABVariableStore().resetField(SupportedEntities.targeting, 'age_min', adsetBuildId);
          useABVariableStore().resetField(SupportedEntities.targeting, 'genders', adsetBuildId);
          useABVariableStore().resetField(SupportedEntities.targeting, 'locales', adsetBuildId);
          // For each targeting group
          // set geo location to special categories values;
          const geoLocations = useABVariableStore().getFieldForEntity(
            SupportedEntities.targeting,
            'geo_locations',
            adsetBuildId
          );
          for (const variable of geoLocations?.value || []) {
            const patch: (typeof geoLocations)['value'][number]['value'] = {
              countries: countries as any,
              cities: [],
              regions: [],
              zips: [],
              country_group: [],
            };
            useABVariableStore().addOrEditVariable(
              SupportedEntities.targeting,
              'geo_locations',
              {
                ...variable,
                value: patch,
              },
              adsetBuildId
            );
          }
        }
      }
    },
    applySuggestions(id: string) {
      const abVariable = useABVariableStore();
      const abGroups = useAbGroups();
      const newGroups: Record<string, string> = {};
      const variables = this.suggestions.find((s) => s.id === id)?.variables;
      if (variables) {
        for (const field of Object.keys(variables)) {
          // Get group metadata
          const value = variables[field];
          let fieldConfig;
          try {
            fieldConfig = AdSetDTO.getFieldConfiguration(value.field);
          } catch (e) {
            try {
              fieldConfig = AdSetDTO.getFieldConfigurationTargeting(value.field);
            } catch (e) {
              console.log('Field not found');
              // eslint-disable-next-line no-continue
            }
          }
          if (!fieldConfig) {
            console.log('Field not found');
            // eslint-disable-next-line no-continue
            continue;
          }
          // Create Groups and save current group.
          const group = FacebookAbGroups[fieldConfig.fieldGroup! as keyof typeof FacebookAbGroups];
          if (!group) {
            console.log('Group not found');
            // eslint-disable-next-line no-continue
            continue;
          }
          if (!newGroups[fieldConfig.fieldGroup!]) {
            newGroups[fieldConfig.fieldGroup!] = uid(8);
            abGroups.addAbGroup(group.entity, {
              typeId: fieldConfig.fieldGroup! as 'DEFAULT',
              entity: SupportedEntities.targeting,
              description: group.readableName,
              name: `${group.readableName}  Suggestion`,
              id: newGroups[fieldConfig.fieldGroup!],
            });
          }
          abVariable.addOrEditVariable(group.entity as SupportedEntities.adset, field as keyof AdSet, {
            ...value,
            abGroup: newGroups[fieldConfig.fieldGroup!],
            variableId: uid(8),
          });
        }
      }
    },
  },
});

export default useAdSetStore;
