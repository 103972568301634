export default [
  {
    name: 'Google Chrome',
    code: 'chrome',
  },
  {
    name: 'Safari',
    code: 'safari',
  },
  {
    name: 'Opera Mini',
    code: 'operamini',
  },
  {
    name: 'Opera Mobile',
    code: 'operamobile',
  },
  {
    name: 'Opera',
    code: 'opera',
  },
  {
    name: 'Firefox',
    code: 'firefox',
  },
  {
    name: 'Internet Explorer',
    code: 'msie',
  },
  {
    name: 'Facebook',
    code: 'facebook',
  },
  {
    name: 'WebView',
    code: 'webview',
  },
  {
    name: 'Yandex Browser',
    code: 'yandex',
  },
  {
    name: 'Microsoft Edge',
    code: 'edge',
  },
  {
    name: 'UC Browser',
    code: 'ucbrowser',
  },
  {
    name: 'Pinterest App',
    code: 'pinterest',
  },
  {
    name: 'Mobile Samsung Browser',
    code: 'mobilesamsungbrowser',
  },
  {
    name: 'Google Search App',
    code: 'googlesearchapp',
  },
  {
    name: 'Others',
    code: 'others',
  },
];
