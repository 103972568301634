<template>
  <div class="rule-bid-cap-wrapper">
    <RuleBidOptions
      :template="config.bidCapConfig.bidCapModelTemplates[ruleModel.rule.components.bidCap.template]"
      :action-options="config.bidCapConfig.bidCapActionOptions"
      :to-options="config.bidCapConfig.bidCapToOptions"
      :unit-options="config.bidCapConfig.bidCapUnitOptions"
      v-model="form"
      :bid="ruleModel.rule.components.bidCap"
      :ruleLevel="ruleModel.rule.level"
      :excludeCampaignLevelFields="excludeCampaignLevelFields"
      @max-limit-exhausted="$_maxLimitExhausted"
      name="BidCap"
      ref="ruleBidOptions"
    />
    <RuleMinMax v-if="$c_showMinMax"
                name="Bid Cap"
                :min="form.min"
                :minObject="form.minObject"
                @min="$_handleMin"
                @minObject="$_handleMinObject"
                :max="form.max"
                :maxObject="form.maxObject"
                @max="$_handleMax"
                @maxObject="$_handleMaxObject"
                :unit="ruleModel.rule.rule_action"
                :limits="ruleModel.rule.components.bidCap.limits"
                :options="config.bidCapConfig.bidCapMinMaxOptions"
                :to-options="$_handleToOptionsMinMax"
                :template="config.bidCapConfig.bidCapModelTemplates[ruleModel.rule.components.bidCap.template]"
                ref="ruleMinMax"
    />
    <div v-if="showResetStrategy" class="row col-md-12 switch-wrapper">
      <switch-button
        :checked="form.reset_strategy"
        :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
        :update="(value) => $_updateResetStrategy(value)"
      />
      <div class="text-with-info">Automatically set a <strong>BID CAP</strong> even if currently not set for a campaign <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.right title="By enabling this option the rule will also set a Bid Cap for campaigns which currently do not have e Bid CAP set, if they fulfill the rule conditions." />
      </div>
    </div>
  </div>
</template>

<script>
import RuleBidOptions from './subcomponents/RuleBidOptions.vue';
import RuleMinMax from './subcomponents/RuleMinMax.vue';
import component from '../mixins/component';

export default {
  name: 'RuleBidCap',
  components: { RuleBidOptions, RuleMinMax },
  mixins: [component],
  props: {
    value: {
      type: Object,
      default: () => ({
        action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
      }),
    },
    showResetStrategy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      excludeCampaignLevelFields: ['campaign_bid'],
    };
  },
  computed: {
    $c_showMinMax() {
      return !(this.form.action === 'set' && this.form.unit === 'static');
    },
    $_handleToOptionsMinMax() {
      const { bidCapToOptions } = this.config.bidCapConfig;
      if (bidCapToOptions && bidCapToOptions.length) {
        if (this.ruleModel.rule.level.toLowerCase().includes('campaign')) {
          return bidCapToOptions.filter((field) => !this.excludeCampaignLevelFields.includes(field.value));
        }
        return bidCapToOptions;
      }
      return [];
    },
  },
  watch: {
    form: {
      handler() {
        if (!this.$c_showMinMax) {
          this.form.minObject.value = this.form.value;
          this.form.maxObject.value = this.form.value;
          this.form.min = this.form.value;
          this.form.max = this.form.value;
        }
        this.$_emit('form');
      },
      deep: true,
    },
    $c_showMinMax() {
      this.form.minObject = { unit: 'static', to: '', value: '' };
      this.form.maxObject = { unit: 'static', to: '', value: '' };
      this.form.min = '';
      this.form.max = '';
    },
  },
  created() {
    this.form = this.value;
    if (this.showResetStrategy) {
      this.form.reset_strategy = this.value.reset_strategy || false;
    }
  },
  methods: {
    async validate() {
      try {
        let subcomponentsStatus;
        if (this.$refs.ruleMinMax) {
          subcomponentsStatus = await Promise.all([this.$refs.ruleBidOptions.validate(), this.$refs.ruleMinMax.validate()]);
          if (subcomponentsStatus.includes(false)) {
            return false;
          }
          return true;
        }
        subcomponentsStatus = await this.$refs.ruleBidOptions.validate();
        return subcomponentsStatus;
      } catch (error) {
        return false;
      }
    },
    async $_maxLimitExhausted() {
      const swal = await this.$swal({
        title: 'Bid Cap seems unusually high!',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Keep Anyway',
        cancelButtonText: 'Reset',
      });
      if (swal.value !== true) this.form.value = '';
    },
    $_updateResetStrategy(value) {
      this.form.reset_strategy = value;
    },
    $_handleMax(value) {
      this.form.max = value;
    },
    $_handleMaxObject(objectValue) {
      if (this.form.maxObject) {
        this.form = { ...this.form, maxObject: objectValue };
      }
    },
    $_handleMin(value) {
      this.form.min = value;
    },
    $_handleMinObject(objectValue) {
      if (this.form.minObject) {
        this.form = { ...this.form, minObject: objectValue };
      }
    },
  },
};
</script>

  <style lang="scss">
  .rule-bid-cap-wrapper {

  }
  .text-with-info {
    margin-top: .2rem;
    i {
      color: gray;
    }
  }
  .switch-wrapper {
    margin-top: 1.5rem;
  }

  // .rule-bid-cap-wrapper {
  //   .least-one-checkbox-message {
  //     position: relative;
  //     top: -17px;
  //   }
  //   .limit-input-wrapper {
  //     width: 500px;
  //     .state-addon {
  //       width: 320px;
  //       @media only screen and (max-width : 575px) {
  //         width: 150px;
  //       }
  //     }
  //     input.form-control {
  //       text-align: center;
  //     }
  //     .input-group-addon {
  //       &:last-child {
  //         text-align: center;
  //         display: inline-block;
  //       }
  //     }
  //   }
  // }
</style>
