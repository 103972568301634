export default (F) => ({
  traffic_source_target_id: F.create({
    header: { content: 'TAG ID' },
    item: {
      key: 'traffic_source_target_id',
      sortable: true,
      total: null,
    },
  }),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
    colClass: 'sticky sticky--name',
  }),
});
