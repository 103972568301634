
import ExistingContentsModal from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/ExistingContentsModal.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { Input } from '@sh/types/CampaignCreator';
import { cloneDeep } from 'lodash';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  inputs: Input[];
  selected: string[];
  headlinesTextarea: string;
  isHeadlinesModal: boolean;
  isExistingContentsModal: boolean;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: { ExistingContentsModal, OptimizerIcon },
  mixins: [notifications],
  props: {
    maxHeadlines: {
      type: Number,
      default: 10,
    },
    maxCharacter: {
      type: Number,
      default: 80,
    },
    labelCols: {
      type: Number,
      default: 3,
    },
    tokens: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    defaults: {
      type: Array as PropType<Input[]>,
      default: () => [],
    },
    trafficSourceType: {
      type: Object as PropType<MediaManager.TrafficSource>,
      required: false,
    },
    adDescription: {
      type: Object as PropType<{ maxCharacter: number }>,
      required: false,
    },
    ctaButton: {
      type: Object as PropType<{ options: string[] }>,
      required: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      inputs: [],
      selected: [],
      headlinesTextarea: '',
      isHeadlinesModal: false,
      isExistingContentsModal: false,
    };
  },
  computed: {
    characterLeft(): number[] {
      return this.inputs.map((value) => this.maxCharacter - value.text.length);
    },
    textareaHeadlines(): string[] {
      const text = this.headlinesTextarea.replace(/(\r\n|\n|\r)/gm, '\n');
      const rows = text.split('\n').filter((text) => text !== '');
      return rows;
    },
    availableSlots(): number {
      return this.maxHeadlines - this.inputs.filter((headline) => !!headline.text).length;
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    adDescriptionCharacterLeft(): number[] {
      return this.inputs.map(
        (value) => (this.adDescription?.maxCharacter ?? 0) - (value.adDescription ? value.adDescription.length : 0)
      );
    },
    isCtaField(): boolean[] {
      return this.inputs.map(
        (input, index) =>
          this.selected.includes(`Cta Button${index + 1}`) || !!input.ctaButton || (this.isEditMode && !!this.ctaButton)
      );
    },
    isDescriptionField(): boolean[] {
      return this.inputs.map(
        (input, index) =>
          this.selected.includes(`Description${index + 1}`) ||
          !!input.adDescription ||
          (this.isEditMode && !!this.adDescription)
      );
    },
  },
  watch: {
    inputs: {
      handler(values) {
        if (values[values.length - 1] && values[values.length - 1].text !== '' && values.length < this.maxHeadlines) {
          this.inputs.push({ text: '', ctaButton: '', adDescription: '' });
        }

        this.$emit(
          'input',
          this.inputs.filter((value) => value.text.length > 0)
        );
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.defaults.length > 0) {
      this.inputs = cloneDeep(this.defaults);
    } else {
      this.inputs.push({ text: '', ctaButton: '', adDescription: '' });
    }
  },
  methods: {
    headlineUID(index: number) {
      return `headlineInput-${index}`;
    },
    removeItem(index: number) {
      if (this.inputs.length !== 1) {
        this.inputs = this.inputs.filter((_, _index) => index !== _index);
      }
    },
    addHeadlinesBatch() {
      const headlines = this.textareaHeadlines.slice(0, this.maxHeadlines);
      const newInputs: Input[] = [];
      headlines.forEach((headline) => {
        const data = { text: headline, ctaButton: '', adDescription: '' };
        newInputs.push(data);
      });

      this.inputs = newInputs;
      this.isHeadlinesModal = false;
    },
    addExistingHeadlines(headlines: string[]) {
      this.inputs = this.inputs.filter((headline) => !!headline.text);
      let addedHeadlines = 0;
      const slotsRemaining = this.maxHeadlines - this.inputs.length;
      const existingHeadlines = headlines.reduce((filtered: Input[], headline) => {
        if (addedHeadlines < slotsRemaining && headline.length <= this.maxCharacter) {
          addedHeadlines++;
          filtered.push({ text: headline });
        }
        return filtered;
      }, []);

      if (existingHeadlines.length !== headlines.length) {
        this.$n_failNotification({
          title: `${headlines.length - existingHeadlines.length} headlines could not be inserted.`,
        });
      }
      this.inputs.push(...existingHeadlines);
      this.isHeadlinesModal = false;
    },
    hiddenHeadlinesModal() {
      this.headlinesTextarea = '';
    },
    showHeadlinesModal() {
      const headlines: string[] = [];
      this.inputs.forEach((input) => headlines.push(input.text));
      this.headlinesTextarea = headlines.join('\n');
    },
    async validateAll() {
      const validation = await this.$validator.validateAll();
      return validation;
    },
  },
});
