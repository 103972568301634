<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table sections-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getSectionsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getSectionsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getSectionsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getSectionsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="sections-wrapper"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true"
            select-label="Sections"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasGroups="true"
            :hasComparisonColumns="true"
            :showHeaderPopover="true"
            :updateComparisonColumns="updateComparisonColumns"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <div class="d-flex flex-grow-1">
                <vue-opti-select-light
                  :class="[ tableModel.selectedRows.length ? 'active-select' : '', 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                  :options="bulkActionOptions"
                  :unique-key="({ value: { value } }) => value"
                  label-key="content"
                  button-type="static"
                  @change="({ value }) => { $_bulkAction(value) }"
                  single
                  :disabled="$c_disableHeaderButtons"
                >
                  <template #BUTTON_PLACEHOLDER>
                    <span class="button-placehoder-filter">
                      <i class="fa fa-ellipsis-h"></i>
                      <span>Actions</span>
                    </span>
                  </template>
                </vue-opti-select-light>

                <div class="col-md-auto mb-2 mb-md-0 px-0">
                  <bulk-action
                    level="section"
                    :items="tableModel.selectedRows"
                    :bulkConfig="trafficSources[$c_trafficSourceType]"
                    :bulkAction="bulkAction"
                    :showApplyChangesButton="showApplyChangesButton"
                    :selectedRows="tableModel.selectedRows"
                    :cancelHandler="$_cancelBulkOperation"
                    :table="table"
                    :toBeUpdatedItems="toBeUpdatedItems"
                    :clearPreview="$_clearPreviewRows"
                    :setFailedItems="$_setFailedItems"
                    :switchApplyButton="$_switchApplyButton"
                    :switchDisableActions="$_switchDisableActions"
                    :removeStyles="$_removeStyles"
                    :failedItems="failedItems"
                    :setItems="$_setItems"
                  />
                </div>

                <vue-opti-select-light
                  class="optimizer-select icon-select pl-2 pr-2"
                  :options="statusOptions"
                  unique-key="content"
                  label-key="content"
                  option-type="checkbox"
                  :groups="[{ value: 'status', content: 'Status'}]"
                  button-type="static"
                  v-model="statusFilter"
                >
                  <template #BUTTON_PLACEHOLDER="{ options }">
                    <span class="button-placehoder-filter">
                      <i class="fa fa-filter"></i>
                      <span>Filters</span>
                      <b-badge v-show="options.length" pill variant="info">{{ options.length }}</b-badge>
                    </span>
                  </template>
                </vue-opti-select-light>
                <div class="col-md-auto mb-2 mb-md-0 pl-0 pr-2">
                  <b-btn
                    class="secondary-button h-100"
                    block
                    v-clipboard="$_copyToClipboard('traffic_source_section_id')"
                    @success="$_clipboardSuccessHandler('section')"
                    @error="$_clipboardErrorHandler"
                  >
                    <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected sections id's to clipboard." />
                  </b-btn>
                </div>
                <div class="col-md-auto mb-2 mb-md-0 pl-0">
                  <b-btn
                    v-show="['Outbrain'].includes(account.type.uniqueName)"
                    class="secondary-button h-100"
                    block
                    v-clipboard="$_copyToClipboard('traffic_source_section_id','cpc')"
                    @success="$_clipboardSuccessHandler('section')"
                    @error="$_clipboardErrorHandler"
                  >
                    <i class="fa fa-clipboard" /> Copy With Bids<i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected section ids with their respective bids to clipboard. This data can then be used with the “Upload Bids” action to bulk upload the same bids on another campaign.." />
                  </b-btn>
                </div>
                <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                  <i class="fa fa-spinner fa-spin" /> Processing...
                </div>
                <loadizer :loading="ready.pagination" />
              </div>

              <div class="refresh-button" v-b-tooltip title="Refresh Table">
                <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
                  <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
                </b-btn>
              </div>
            </template>
            <template slot="cpc" slot-scope="props">
              <live-edit
                :ref="`bidLiveEdit-${props.i}`"
                :key="`bidLiveEdit-${props.item.id}`"
                v-model="props.item.cpc"
                :live-state="props.item.bidLiveEditState"
                prefix="$"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBid(props.item, value)"
                :preview="$_findPreviewRow(props.item.traffic_source_section_id, 'bid', props.item.traffic_source_currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid')"
                :failedItemKey="props.item.traffic_source_section_id"
                @up="$_inlineEditArrayPress('bidLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('bidLiveEdit', props.i, 'down')"
              />
            </template>
            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <switch-button
                  :loading="item.statusProcessing"
                  :checked="item.status === 'RUNNING'"
                  :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                  :update="(value) => $_updateStatus(item, value)"
                />
                <action-btn type="breakdown" title="Performance Breakdown" :click="() => $_showPerformanceModal(item)" />
              </div>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
    <bulk-modal
      title="Change Bid"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkModal"
      :items="tableModel.selectedRows"
      :min="0.01"
      :step="0.01"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="$_bulkUpdateBid"
    />
    <batch-modal
      title="Upload Bids"
      placeholder="Correct format: <section_id>,<bid>"
      field-name="Sections"
      ref="batchModal"
      :format="{split: ',', colums: 2}"
      :handler="$_batchUpdateBid"
    />
    <performance-modal
      ref="performanceModal"
      :type="type"
      :trafficSource="account"
      :itemId="currentContentPerformance.traffic_source_section_id"
      :id="currentContentPerformance.id || ''"
      :name="currentContentPerformance.name || currentContentPerformance.traffic_source_section_id || ''"
      :timezone="timezone"
      :dateRange="dateRange"
    ></performance-modal>

    <rule-bulk-modal
      v-if="$c_trafficSourceType"
      ref="ruleBulkModal"
      level="section"
      :options="$_numericActiveColumns(table.fields, bulkAction)"
      :items="tableModel.selectedRows"
      :bulkConfig="trafficSources[$c_trafficSourceType]"
      :bulkAction="bulkAction"
      :handler="(value) => $_setItemsThatWillBeUpdated(value, table.items)"
    />
  </div>
</template>

<script>
import tableFields from '@/helpers/fields/index';
import helperMixins from './mixins/helperMixins';
import BatchModal from '@sh/components/Utils/BatchModal';
import tableMixins from '@sh/mixins/table';
import symbols from '@sh/helpers/symbols';
import PerformanceModal from '@sh/components/Performance/PerformanceModal.vue';
import BulkAction from '@sh/components/Utils/BulkAction.ts.vue';
import RuleBulkModal from '@sh/components/Utils/RuleBulkModal.ts.vue';
import config from '../../Campaigns/bulkOperationsConfig.ts';
import { TrafficSource } from '@sh/types';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import { usePresetStore } from '@/stores/presets';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'Sections',
  components: {
    PerformanceModal,
    BatchModal,
    BulkAction,
    RuleBulkModal,
  },
  mixins: [helperMixins, tableMixins, config, columnsMixin, presetHelper],
  props: {
    type: { type: Object },
  },
  data() {
    const options = {
      bulkActionOptions: [
        { value: { value: 'start', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Enable' },
        { value: { value: 'pause', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-stop\'></i>&nbsp; Disable' },
        // { value: { value: 'bid', fn: this.$_showBulkModal }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Bid' },
      ],
      statusFilter: [],
      statusOptions: [
        { value: { status: 'PAUSED' }, content: 'PAUSED', group: 'status' },
        { value: { status: 'RUNNING' }, content: 'RUNNING', group: 'status' },
        { value: { status: 'APPROVED' }, content: 'APPROVED', group: 'status' },
        { value: { status: 'PENDING' }, content: 'PENDING', group: 'status' },
        { value: { status: 'REJECTED' }, content: 'REJECTED', group: 'status' },
        { value: { status: 'OTHER' }, content: 'OTHER', group: 'status' },
      ],
      contents: [],
      symbols,
      currentContentPerformance: {},
      isRefreshLoading: false,
    };
    if ([TrafficSource.Outbrain].indexOf(this.account.type.uniqueName) > -1) {
      options.bulkActionOptions.push({ value: { value: 'change_bid_bulk', fn: this.$_showRuleModal },
        content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change CPCs' });
      options.bulkActionOptions.push({ value: { value: 'batchModalSection', fn: this.$_showBatchModal, force: true }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Upload Bids' });
      options.bulkActionOptions.push({ value: { value: 'resetBidBulk', fn: this.$_bulkResetBid }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Reset Bid To Default' });
    }
    return options;
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_tableName() {
      return `${this.account.type.name}SectionsTable`;
    },
    $c_exportLabel() {
      return `${this.account.type.name}_sections_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $c_trafficSourceType() {
      if (this.account?.type && this.$c_availableTrafficSourcesSectionLevel.includes(this.account.type.uniqueName)) {
        return this.account?.type?.uniqueName;
      }
      return null;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  watch: {
    statusFilter() {
      this.$_getSectionsPaginated();
    },
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('sections', this.campaign.traffic_source_unique_name);
      /** ************************************************************************* */

      try {
        await this.getFilteredPresets();
        await this.$_getSectionsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.preloader = false;
      this.debouncedGetActivityLogs = this.debounce(this.$_getSectionsPaginated, 1000);
    },
    $_showPerformanceModal(item) {
      this.currentContentPerformance = item;
      this.$refs.performanceModal.showModal();
    },
    async $_bulkResetBid() {
      try {
        const swal = await this.$swal({
          title: '<small>This action will reset section bids to campaign default bid.</small>',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        });
        if (swal.value === true) this.$_bulkUpdateBid(this.campaign.additional_fields.bid, true);
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    $_showBatchModal() {
      this.$refs.batchModal.show();
    },
    $_showRuleModal() {
      this.bulkAction = 'change_bid_bulk';
      this.$refs.ruleBulkModal.show();
    },
    async $_batchUpdateBid(data) {
      if (data.length > 0) {
        const task = this.userAction.task.create();
        const payload = data.map((item) => ({ traffic_source_section_id: item[0], bid: item[1], reset: false }));
        try {
          await this.$_userActionHandler({
            request: this.$api.sections.changeBidBulk(this.campaign.id, payload),
          });
          this.preloader = true;
          await this.$_init();
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
        task.finish();
      }
    },
    async $_bulkUpdateBid(value, reset) {
      const payload = [];
      if (reset !== true) reset = false;
      this.tableModel.selectedRows.forEach((row) => {
        payload.push({ traffic_source_section_id: row.traffic_source_section_id, bid: value, reset });
        row.bidLiveEditState.state = 'BUSY';
      });
      if (payload.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.sections.changeBidBulk(this.campaign.id, payload),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.find((item) => item.traffic_source_section_id === row.traffic_source_section_id)) row.cpc = parseFloat(value);
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payload.find((item) => item.traffic_source_section_id === row.traffic_source_section_id)) {
            row.bidLiveEditState.state = 'READY';
          }
        });
        task.finish();
      }
    },
    async $_getSectionsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        status: [],
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        typeName: this.account.type.uniqueName,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.search !== '') {
        filter.search = this.search;
      }
      if (this.statusFilter.length > 0) {
        this.statusFilter
          .forEach((selected) => {
            if (selected.value.status) {
              filter.status.push(selected.value.status);
            }
          });
      }
      if (!filter.status.length) delete filter.status;
      let sections = [];
      try {
        sections = await this.$api.sections.performance(this.campaign.id, filter);
        sections.items = sections.items.map((item) => {
          item.traffic_source_currency = this.campaign.traffic_source_currency;
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.ready.pagination = false;
      }
      if (sections.pagination.lastPage) {
        this.pages = sections.pagination.lastPage;
        this.defaultRows = sections.pagination.pageSize;
      }
      if (!sections.items.length) {
        this.page = 1;
      }
      if (Object.keys(sections).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('SECTIONS', { name: 'actions' }));
        sections.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('SECTIONS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        this.table.items = sections.items.map((item) => {
          item.statusProcessing = false;
          item.bidLiveEditState = { state: 'READY' };
          return item;
        });
        sections.totals.totalElements = sections.pagination.total;
        this.table.totals = sections.totals;
      }
      this.preloader = false;
      this.ready.pagination = false;
      return sections;
    },
    async $_bulkUpdateStatus(value) {
      const localValue = value === 'start' ? 'RUNNING' : 'PAUSED';
      const rowsId = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          rowsId.push(row.traffic_source_section_id);
          row.statusProcessing = true;
        }
      });
      if (rowsId.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.sections.changeStatus(this.campaign.id, value, rowsId),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_section_id) > -1) row.status = localValue;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (rowsId.indexOf(row.traffic_source_section_id) > -1) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_updateStatus(item, value) {
      try {
        const result = await this.$_userActionHandler({
          request: this.$api.sections.changeStatus(item.campaign_id, value ? 'start' : 'pause', [item.traffic_source_section_id]),
        });
        if (result.success.length > 0) item.status = value ? 'RUNNING' : 'PAUSED';
      } catch (error) { /** * Do nothing ** */ }
    },
    async $_updateBid(item, value) {
      const task = this.userAction.task.create();
      try {
        await this.$api.sections.changeBid(this.campaign.id, value, [item.traffic_source_section_id]);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'SECTION', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getSectionsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('sections', this.campaign.traffic_source_unique_name, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      return this.$_exportCsv({ level: 'sections', campaignId: this.campaign.id, filter });
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getSectionsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'SECTION');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getSectionsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getSectionsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getSectionsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
  },
};
</script>

