/* eslint-disable import/no-cycle */
import * as AdTypes from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import useAbGroups from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import {
  FieldError,
  getIdForEntity,
  InternalABVariable,
  SupportedEntities,
} from '@/views/Automation/CampaignCreatorV2/store/Types';
import { CreativeValidation } from '@/views/Automation/CampaignCreatorV2/validation/Creative';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import uid from 'uid';
import useABVariableStore from './AbVariables';

type Creative = Pick<AdTypes.AdCreative, keyof typeof CreativeValidation>;
interface InternalCreativeMetadata {
  adId: string;
  internalId: string;
  campaignInternalId: string;
  adsetInternalId: string;
  website_destination_type: boolean;
}

type CreativeState = InternalCreativeMetadata;

export interface CreativeStore {
  globalErrors: FieldError[];
  adAccountId: string;
  creative: {
    [campaignAdId: string]: CreativeState;
  };
}

const useCreativeStore = defineStore(SupportedEntities.creative, {
  state: (): CreativeStore => ({
    creative: {},
    globalErrors: [],
    adAccountId: '',
  }),
  getters: {
    getField:
      (state) =>
      <K extends keyof Creative>(key: K) =>
        useABVariableStore().getField(SupportedEntities.creative, key),
    getInternalMetadata:
      (state) =>
      <K extends keyof InternalCreativeMetadata>(field: K): InternalCreativeMetadata[K] => {
        const navigation = useNavigationStore();
        return state.creative[navigation.getAdBuildId]?.[field];
      },
  },
  actions: {
    saveState() {
      return { creative: this.$state };
    },
    loadState(template: any) {
      const parsedState = template.creative as CreativeStore;
      if (parsedState) {
        this.creative = { ...this.creative, ...parsedState.creative };
      }
    },
    addVariable<K extends keyof Creative>(field: K, value: InternalABVariable<Creative[K]>) {
      useABVariableStore().addVariable(SupportedEntities.creative, field, value);
    },
    removeVariable<K extends keyof Creative>(field: K, key: string) {
      useABVariableStore().removeVariable(SupportedEntities.creative, field, key);
    },
    editVariable<K extends keyof Creative>(field: K, key: string, value: InternalABVariable<Creative[K]>) {
      useABVariableStore().editVariable(SupportedEntities.creative, field, key, value);
    },
    addOrEditVariableName<K extends keyof Creative>(field: K, abGroupId: string, variableId: string, newName: string) {
      const buildAdId = useNavigationStore().getAdBuildId;
      useABVariableStore().renameVariable(SupportedEntities.creative, buildAdId, abGroupId, field, variableId, newName);
    },
    addOrEditVariable<K extends keyof Creative>(field: K, value: InternalABVariable<Creative[K]>) {
      useABVariableStore().addOrEditVariable(SupportedEntities.creative, field, value);
    },

    updateInternalMetadata<K extends keyof InternalCreativeMetadata>(field: K, value: InternalCreativeMetadata[K]) {
      const navigation = useNavigationStore();
      this.creative = {
        ...this.creative,
        [navigation.getAdBuildId]: {
          ...this.creative[navigation.getAdBuildId],
          [field]: value,
        },
      };
    },
    addNewAdCreative(campaignId: string, adsetId: string, adId: string) {
      const internalId = uid();
      const navigation = useNavigationStore();
      navigation.setCreativeInternalId(adId);
      const abGroupStore = useAbGroups();
      abGroupStore.initAbGroup(SupportedEntities.creative);
      useABVariableStore().initEntity(SupportedEntities.creative, navigation.getAdBuildId, {} as any);
      // const abGroup = abGroupStore.getDefaultAbGroupForEntity(SupportedEntities.creative);
      this.creative = {
        ...this.creative,
        [navigation.getAdBuildId]: {
          internalId,
          adId,
          campaignInternalId: campaignId,
          adsetInternalId: adsetId,
          website_destination_type: true,
        } as any,
      };
    },
    cloneCreative({
      currentAdId,
      newAdId,
      newAdsetId,
      newCampaignId,
    }: {
      currentAdId: string;
      newAdsetId: string;
      newAdId: string;
      newCampaignId: string;
    }) {
      const newId = uid();
      const navigationStore = useNavigationStore();
      const abGroupStore = useAbGroups();
      const buildAdId = getIdForEntity(SupportedEntities.ad, [newCampaignId, newAdId]);
      // eslint-disable-next-line guard-for-in
      for (const creativeId in this.creative) {
        const creative = cloneDeep(this.creative[creativeId]);
        const currentBuildId = getIdForEntity(SupportedEntities.ad, [creative.campaignInternalId, creative.adId]);
        if (creative.adId === currentAdId) {
          useABVariableStore().cloneEntity(SupportedEntities.creative, creativeId, buildAdId);

          this.creative = {
            ...this.creative,
            [buildAdId]: {
              ...creative,
              adId: newAdId,
              internalId: newId,
              campaignInternalId: newCampaignId,
              adsetInternalId: newAdsetId,
            },
          };
          navigationStore.cloneNavigation(SupportedEntities.creative, { currentBuildId, newBuildId: buildAdId });
          abGroupStore.clone(SupportedEntities.creative, { currentBuildId, newBuildId: buildAdId });
        }
      }
    },
    delete(adBuildId: string) {
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      navigation.delete(SupportedEntities.creative, adBuildId);
      abGroupStore.delete(SupportedEntities.creative, adBuildId);
      useABVariableStore().removeEntity(SupportedEntities.creative, adBuildId);

      delete this.creative[adBuildId];
      this.creative = {
        ...this.creative,
      };
    },
    deleteABGroup(groupId: string) {
      const adBuildId = useNavigationStore().getAdBuildId;
      useABVariableStore().removeAbGroup(SupportedEntities.creative, adBuildId, groupId);
    },
    cloneABGroup(currentGroupId: string) {
      const adBuildId = useNavigationStore().getAdBuildId;
      const currentCreative = this.creative[adBuildId];
      if (!currentCreative) {
        return;
      }
      const newGroupId = uid(8);
      useAbGroups().cloneGroup(SupportedEntities.creative, { groupId: currentGroupId, newGroupId });
      useABVariableStore().cloneAbGroup(SupportedEntities.creative, adBuildId, currentGroupId, newGroupId);
    },
  },
});

export default useCreativeStore;
