<template>
  <div>
    <div class="alert alert-log">
      <div class="row">
        <div class="col-md-6">
          <p><strong>Date:</strong> {{ selectedItem.formated_date }}</p>
          <p><strong>Name:</strong> {{ campaignName }}</p>
          <p><strong>Status:</strong> <span v-html="htmlStatusFn(selectedItem.status)"></span></p>
          <p><strong>Account:</strong> <span v-html="htmlAccountNameFn(selectedItem)"></span></p>
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-end">
          <b-btn class="details-table__alert-log__btn" v-if="$_needsRetry" :disabled="isRetrying" size="md" variant="outline-info" @click="$_retry(selectedItem)">
            <i class="fa fa-repeat" aria-hidden="true"></i>
            {{ isRetrying ? 'Retrying...' : 'Retry Failed Items' }}
          </b-btn>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div v-if="selectedItem.task_results && selectedItem.task_results.length" class="details-view-table">
      <b-table hover :items="selectedItem.task_results" :fields="detailViewFields">
        <template slot="status" slot-scope="props">
          <span v-if="props.item.status === 'SUCCESSFUL'" class="text-success">FINISHED</span>
          <span v-else-if="props.item.status === 'FAILED'" class="text-danger">FAILED</span>
          <span v-else class="text-warning">PENDING</span>
          <b-alert v-if="props.item.error_message" class="mb-0 mt-2 px-3" show variant="danger">{{
            props.item.error_message
          }}
          </b-alert>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import helpers from '../mixins/helpers';
import { api } from '@sh/services/api';

export default {
  name: 'DetailsTableV2',
  mixins: [helpers],
  props: {
    campaignRetryLoading: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    htmlStatusFn: {
      type: Function,
      default: () => '',
    },
    htmlAccountNameFn: {
      type: Function,
      default: () => '',
    },
    campaignName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isRetrying: false,
      detailViewFields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        { key: 'resultId', label: 'Result ID' },
        { key: 'type', label: 'Type' },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'errorMessage',
          label: 'Error',
        },
      ],
    };
  },
  computed: {
    $_needsRetry() {
      return this.selectedItem.status === 'FAILED' || this.selectedItem.status === 'PARTIALLY FINISHED';
    },
  },
  methods: {
    async $_retry(item) {
      this.isRetrying = true;
      try {
        await api.campaignCreator.retryJob({ id: item.metadata.taskId });
      } catch (error) {
        console.log('retry failed with error: ', error);
      }
    },
  },
};
</script>
<style lang="scss">
 .details-table__alert-log__btn {
   &:hover:disabled {
     background-color: transparent !important;
     color: $color-light-blue !important;
   }
 }
</style>
