<template>
  <b-form :ref="$_getScope()" :data-vv-scope="$_getScope()" @submit.prevent @keyup.enter="$_handleOk">
    <b-modal
      id="bulkModalCAD"
      @ok="$_handleOk"
      @show="$_clear"
      ref="bulkModal"
    >
      <h5 slot="modal-title">
        {{ title }} <small class="text-muted">( {{ items.length }} items will be affected )</small>
      </h5>
      <b-form-group v-for="device in ['desktop', 'mobile']" :key="device" :state="$_hasError(device)">
        <b-input-group>
          <b-input-group-addon class="state-addon">
            <b-form-checkbox v-model="form[`cpc_${device}`].status" @change="$_removeError(device)" />
          </b-input-group-addon>
          <b-input-group-addon v-if="left" class="text-center" slot="left" v-html="left" />
          <b-form-input
            :data-vv-as="`${device} ${fieldName}`"
            v-validate="'required'"
            data-vv-validate-on="input"
            autocomplete="false"
            type="number"
            v-model.number="form[`cpc_${device}`].value"
            :name="device"
            :min="min"
            :max="max"
            :disabled="!form[`cpc_${device}`].status"
            :step="step"
            :placeholder="`Enter ${device} bid`"
          />
          <b-input-group-addon v-if="right" class="text-center" slot="right" v-html="right" />
        </b-input-group>
        <small slot="invalid-feedback">{{ $_getError(device) }}</small>
      </b-form-group>
      <small v-if="!$_activeItems" class="invalid-feedback d-inline">Please select at least one bid to change!</small>
    </b-modal>
  </b-form>
</template>

<script>
/* eslint-disable */
const scope = 'bulkModalCAD';
export default {
  name: 'BulkModalCad',
  props: {
    title: { type: String, default: 'Bulk Edit' },
    placeholder: { type: String, default: 'Enter Value' },
    fieldName: { type: String, default: 'Input' },
    items: { type: Array, default: () => [] },
    itemKeyName: { type: String, default: 'id' },
    min: { type: Number, default: 0 },
    max: { type: [Number, Boolean], default: false },
    step: { type: Number, default: 1 },
    left: { type: [String, null], default: null },
    right: { type: [String, null], default: null },
    maxLimitWarning: { type: [Number, null], default: null },
    handler: { type: Function, default: async () => false },
  },
  data() {
    return {
      form: {
        cpc_desktop: {
          status: true,
          value: null,
        },
        // cpc_tablet: {
        //   status: true,
        //   value: null
        // },
        cpc_mobile: {
          status: true,
          value: null,
        },
      },
    };
  },
  computed: {
    $_activeItems() {
      return Object.values(this.form).map((item) => item.status).reduce((previousStatus, currentStatus) => previousStatus || currentStatus, false);
    },
  },
  methods: {
    show(props) {
      this.$refs.bulkModal.show();
    },
    hide() {
      this.$refs.bulkModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.$_getScope());
      if (isValid && this.$_activeItems) {
        const acceptMaxLimit = await this.$_validateLimits();
        if (acceptMaxLimit) {
          const payload = {};
          Object.entries(this.form).forEach(([key, item]) => {
            if (item.status) payload[key] = item.value;
          });
          this.handler(payload, this.items);
          this.$_clear();
          this.hide();
        }
      }
    },
    async $_validateLimits() {
      const max = Math.max(...Object.values(this.form).filter(({ value, status }) => status).map(({ value }) => value || 0));
      if (this.maxLimitWarning && max >= this.maxLimitWarning) {
        const swal = await this.$swal({
          title: 'Bid seems unusually high!',
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Keep Anyway',
          cancelButtonText: 'Reset',
        });
        if (swal.value !== true) {
          Object.values(this.form).forEach((item) => {
            if (item.status) item.value = null;
          });
        }
        return swal.value;
      }
      return true;
    },
    $_clear() {
      Object.values(this.form).forEach((item) => {
        item.value = null;
        item.status = true;
      });
      this.$validator.reset(this.$_getScope());
    },
    $_removeError(name) {
      this.$validator.errors.remove(`${scope}.${name}`);
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss">
#bulkModalCAD {
  .input-group-addon, .input-group-btn {
    min-width: 20px !important;
    &.state-addon label {
      margin: 0;
      height: 16px;
      padding-left: 16px;
    }
  }
}
@media (min-width: 576px) {
  #bulkModalCAD .modal-dialog {
        max-width: 500px !important;
  }
}
</style>
