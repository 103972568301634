<template>
  <div class="change-password-container d-flex justify-content-center align-items-center">
    <div class="change-password-wrapper">
      <div class="optimizer-logo-container">
        <img
          class="logo-white"
          :src="`sh_static/header/optimizer-${$platform}-logo.svg`"
          alt="Logo"
        >
      </div>

      <div class="optimizer-card">
        <h5 class="change-password-title">Change Subuser Password</h5>
        <hr class="m-0">
        <b-form class="change-password-form" @submit.prevent="$_submit">
          <!-- Password -->
          <b-form-group
            class="optimizer-form-group mb-4"
            label="Email"
            :invalid-feedback="$_getError('email')"
            :state="$_hasError('email')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Email"
              :state="$_hasError('email')"
              v-validate="'required'"
              type="email"
              v-model="model.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>

          <!-- Password -->
          <b-form-group
            class="optimizer-form-group mb-4"
            label="Password"
            :invalid-feedback="$_getError('password')"
            :state="$_hasError('password')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Password"
              :state="$_hasError('password')"
              v-validate="'required|min:6'"
              type="password"
              v-model="model.password"
              name="password"
              placeholder="Password"
              ref="password"
            />
          </b-form-group>

          <!-- Confirm Password -->
          <b-form-group
            class="optimizer-form-group mb-4"
            label="Confirm Password"
            :invalid-feedback="$_getError('confirmPassword')"
            :state="$_hasError('confirmPassword')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Confirm Password"
              :state="$_hasError('confirmPassword')"
              v-validate="'required|min:6|confirmed:password'"
              type="password"
              v-model="model.confirmPassword"
              name="confirmPassword"
              placeholder="Password"
            />
          </b-form-group>

          <div class="submit-btn-container">
            <b-button type="submit" class="w-100 primary-button" :disabled="loading">
              <span v-if="loading" class="mr-2"><i class="fa fa-spinner fa-spin"></i>  </span>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>

</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'SubuserChangePassword',
  mixins: [notifications],
  data: () => ({
    model: {
      token: '',
      id: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    loading: false,
  }),
  created() {
    const { token, email, id } = this.$route.query;
    if (!token || !email || !id) {
      this.$n_failNotification({ title: 'Invalid form' });
    }
    this.model.token = token;
    this.model.email = email;
    this.model.id = id;
  },
  methods: {
    async $_submit() {
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          this.loading = true;
          // Submit
          const modelCopy = { ...this.model };
          delete modelCopy.confirmPassword;
          try {
            await this.$api.subusers.changeSubuserPassword(modelCopy);
            this.$n_successNotification({ title: `"${modelCopy.email}" password successfully changed!` });
            if (this.$session.isAuthenticated()) this.$session.removeToken();
            this.$router.push({ name: 'Login' });
          } catch (error) {
            try {
              this.$n_failNotification({ title: error.response.data.message });
            } catch (err) {
              this.$n_failNotification({ title: 'An error occurred' });
            }
          }
          this.loading = false;
        } else {
          this.$n_warnNotification({ title: 'Invalid form' });
        }
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
  },
};
</script>

<style lang="scss">
  .change-password-container {
    min-height: 100vh;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .optimizer-logo-container {
      text-align: center;
      margin-bottom: 3rem;
      img {
        width: 17rem;
      }
    }

    .optimizer-card {
      max-width: calc(100vw - 4rem);
      width: 50rem;

      .change-password-title {
        font-size: 1.8rem;
        padding: 1.5rem;
        text-align: center;
      }

      .change-password-form {
        padding: 2rem;
      }
    }
  }
</style>
