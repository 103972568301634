import { api, apiStore } from '@sh/services/api';
import _ from 'lodash';

const notificationFieldsMap = {
  actions: {
    header: { content: 'Action', style: 'width: 1%' },
    item: {
      key: 'actions',
      slot: 'actions',
      style: { textAlign: 'center', padding: '.5rem 1.5rem' },
    },
  },
  email: {
    header: { content: 'Email', style: '' },
    item: {
      key: 'email',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
  name: {
    header: { content: 'Name', style: '' },
    item: {
      key: 'name',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
  team_name: {
    header: { content: 'Team', style: '' },
    item: {
      key: 'team_name',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
  channel: {
    header: { content: 'Channel', style: '' },
    item: {
      key: 'channel',
      content: (item) => item.incoming_webhook.channel,
      style: { textAlign: 'center' },
    },
  },
  first_name: {
    header: { content: 'First Name', style: '' },
    item: {
      key: 'first_name',
      style: { textAlign: 'center' },
    },
  },
  last_name: {
    header: { content: 'Last Name', style: '' },
    item: {
      key: 'last_name',
      style: { textAlign: 'center' },
    },
  },
  RE: {
    header: { content: 'Rule Executed' },
    item: {
      key: 'notification.RE',
      slot: 'RE',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
  AI: {
    header: { content: 'Account Issues' },
    item: {
      key: 'notification.AI',
      slot: 'AI',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
  AN: {
    header: { content: 'Smart Notifications' },
    item: {
      key: 'notification.AN',
      slot: 'AN',
      sortable: true,
      style: { textAlign: 'center' },
    },
  },
};

const notificationFieldsInfoMap = {
  RE: (label) => `Get notified via <b>${label}</b> every time a rule is executed.`,
  AI: () => `Get notified about:<br/>
      - Wrong API info or Username/Pass: you will get notified if your account stops working because something changed.<br/>
      - Missing Tracking Tokens: you will get notified if your tracking is not setup properly and might cause missing statistics.`,
  AN: () => 'System will periodically generate Smart Notifications which will include optimization suggestions and alerts about bad or good performing campaigns. As well as new features and improvements.',
};

export default class NotificationModel {
  constructor() {
    this.settings = null;
    this.settingsModel = null;
    this.notificationFieldsInfoMap = notificationFieldsInfoMap;
  }

  async getSettings() {
    const settings = await apiStore.notification.getSettings();
    this.settings = _.cloneDeep(settings);
    this.settingsModel = _.cloneDeep(settings);
    return this.settingsModel;
  }

  async updateSettings() {
    const settings = await api.notification.updateSettings(this.settingsModel);
    this.settings = _.cloneDeep(settings);
    this.settingsModel = _.cloneDeep(settings);
  }

  resetSettings() {
    this.settingsModel = _.cloneDeep(this.settings);
  }

  deleteAccount(channelKey, account) {
    const channel = this.settingsModel.channels[channelKey];
    account.__delete = true;
    channel.accounts = channel.accounts.filter(({ __delete }) => !__delete);
  }

  hasChanges() {
    return JSON.stringify(this.settingsModel) !== JSON.stringify(this.settings);
  }

  getTableHeaderField(key, label = '') {
    let field = null;
    if (notificationFieldsMap[key]) {
      field = _.cloneDeep(notificationFieldsMap[key]);
      if (notificationFieldsInfoMap[key]) field.header.info = notificationFieldsInfoMap[key](label);
    }
    return field;
  }

  getDefaultNotification() {
    return {
      AI: false,
      AN: false,
      BCP: false,
      DA: false,
      GCP: false,
      LI: false,
      MAT: false,
      NRC: false,
      RE: false,
    };
  }
}
