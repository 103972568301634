const tsUniqueName = {
  revcontent: 'RevContent',
  taboola: 'Taboola',
  contentad: 'ContentAd',
  mgid: 'Mgid',
  outbrain: 'Outbrain',
  adskeeper: 'Adskeeper',
  activerevenue: 'ActiveRevenue',
  gemini: 'Gemini',
  voluumdsp: 'VoluumDSP',
  runative: 'Runative',
  amazondsp: 'AmazonDSP',
  facebook: 'Facebook',
  mediago: 'MediaGo',
  tiktok: 'TikTok',
  yahoodsp: 'YahooDSP',
  googleAds: 'GoogleAds',
};

const limits = {
  min_bid: 0,
  max_bid: 0.5,
  min_budget: 0,
  max_budget: 500,
  min_coefficient: 0,
  max_coefficient: 3,
  max_bid_percent: 500,
};

const route = {
  name: 'New Rule',
};

// Read only
const ruleTypes = {
  widget_pause: {
    level: 'Widget',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'widget_pause' } },
    content: 'Block Widgets/Publishers',
    ts_types: [tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.gemini, tsUniqueName.runative],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_start: {
    level: 'Widget',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'widget_start' } },
    content: 'Un-Block Widgets/Publishers',
    ts_types: [tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.gemini, tsUniqueName.runative],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_change_budget: {
    level: 'Widget',
    rule_action: 'change_budget',
    to: { name: route.name, query: { type: 'widget_change_budget' } },
    content: 'Change Package Budget',
    ts_types: [tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          // dailyBudget: true,
        },
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  widget_pause_googleads: {
    level: 'Widget',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'widget_pause_googleads' } },
    rule_action_alias: 'pause_googleads',
    content: 'Disable Keywords',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_start_googleads: {
    level: 'Widget',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'widget_start_googleads' } },
    rule_action_alias: 'start_googleads',
    content: 'Enable Keywords',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_pause_fb: {
    level: 'Widget',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'widget_pause_fb' } },
    rule_action_alias: 'pause_fb',
    content: 'Pause Placement',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Placement' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_start_fb: {
    level: 'Widget',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'widget_start_fb' } },
    rule_action_alias: 'start_fb',
    content: 'Start Placement',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Placement' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_start_media_go: {
    level: 'Widget',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'widget_start_media_go' } },
    rule_action_alias: 'start_media_go',
    content: 'Start Site',
    ts_types: [tsUniqueName.mediago],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  widget_pause_media_go: {
    level: 'Widget',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'widget_pause_media_go' } },
    rule_action_alias: 'pause_media_go',
    content: 'Stop Site',
    ts_types: [tsUniqueName.mediago],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  app_pause: {
    level: 'App',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'app_pause' } },
    content: 'Block Apps',
    ts_types: [tsUniqueName.activerevenue],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  app_start: {
    level: 'App',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'app_start' } },
    content: 'Un-Block Apps',
    ts_types: [tsUniqueName.activerevenue],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  campaign_pause: {
    level: 'Campaign',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'campaign_pause' } },
    content: 'Pause Campaign',
    ts_types: [tsUniqueName.mediago, tsUniqueName.amazondsp, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue, tsUniqueName.gemini, tsUniqueName.runative, tsUniqueName.facebook, tsUniqueName.tiktok, tsUniqueName.yahoodsp, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  campaign_start: {
    level: 'Campaign',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'campaign_start' } },
    content: 'Activate Campaign',
    ts_types: [tsUniqueName.mediago, tsUniqueName.amazondsp, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue, tsUniqueName.gemini, tsUniqueName.runative, tsUniqueName.facebook, tsUniqueName.tiktok, tsUniqueName.yahoodsp, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  content_pause: {
    level: 'Content',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'content_pause' } },
    content: 'Pause Contents',
    ts_types: [tsUniqueName.mediago, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue, tsUniqueName.gemini, tsUniqueName.runative, tsUniqueName.googleAds, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid', 'name'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_start: {
    level: 'Content',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'content_start' } },
    content: 'Activate Contents',
    ts_types: [tsUniqueName.mediago, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue, tsUniqueName.gemini, tsUniqueName.runative, tsUniqueName.googleAds, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid', 'name'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_pause_tiktok: {
    level: 'Content',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'content_pause_tiktok' } },
    content: 'Pause Ads TikTok',
    rule_action_alias: 'pause_tiktok',
    ts_types: [tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_start_tiktok: {
    level: 'Content',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'content_start_tiktok' } },
    rule_action_alias: 'start_tiktok',
    content: 'Activate Ads TikTok',
    ts_types: [tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_pause_facebook: {
    level: 'Content',
    rule_action: 'pause_facebook',
    to: { name: route.name, query: { type: 'content_pause_facebook' } },
    content: 'Pause Ads Facebook',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_start_facebook: {
    level: 'Content',
    rule_action: 'start_facebook',
    to: { name: route.name, query: { type: 'content_start_facebook' } },
    content: 'Activate Ads Facebook',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_pause_amazon: {
    level: 'Content',
    rule_action: 'pause_amazon',
    to: { name: route.name, query: { type: 'content_pause_amazon' } },
    content: 'Pause Creatives',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid', 'name'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  content_start_amazon: {
    level: 'Content',
    rule_action: 'start_amazon',
    to: { name: route.name, query: { type: 'content_start_amazon' } },
    content: 'Activate Creatives',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid', 'name'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  campaign_change_bid: {
    level: 'Campaign',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'campaign_change_bid' } },
    content: 'Change Campaign Bid',
    ts_types: [tsUniqueName.mediago, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.outbrain, tsUniqueName.runative, tsUniqueName.activerevenue, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  campaign_change_bid_cad: {
    level: 'Campaign',
    rule_action: 'change_bid_cad',
    to: { name: route.name, query: { type: 'campaign_change_bid_cad' } },
    content: 'Change Campaign Bid (CAD only)',
    ts_types: [tsUniqueName.contentad],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      bidCad: {
        active: true,
        template: 'classic',
        value: {
          desktop: {
            status: true, action: 'set', unit: 'static', to: '', value: '',
          },
          // 'tablet': { status: true, action: 'set', unit: 'static', to: '', value: '' },
          mobile: {
            status: true, action: 'set', unit: 'static', to: '', value: '',
          },
          min: 0,
          max: 0,
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  campaign_change_budget: {
    level: 'Campaign',
    rule_action: 'change_budget',
    to: { name: route.name, query: { type: 'campaign_change_budget' } },
    content: 'Change Campaign Budget',
    ts_types: [tsUniqueName.mediago, tsUniqueName.amazondsp, tsUniqueName.revcontent, tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue, tsUniqueName.gemini, tsUniqueName.runative, tsUniqueName.facebook, tsUniqueName.tiktok, tsUniqueName.yahoodsp, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          dailyBudget: true, // (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
        },
        // filter: (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  campaign_change_target_cpa: {
    level: 'Campaign',
    rule_action: 'change_target_cpa',
    to: { name: route.name, query: { type: 'campaign_change_target_cpa' } },
    content: 'Change Target CPA',
    ts_types: [tsUniqueName.taboola, tsUniqueName.outbrain, tsUniqueName.mediago],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      targetCpa: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.001',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  adgroup_change_target_roas: {
    level: 'AdGroup',
    rule_action: 'change_target_roas',
    to: { name: route.name, query: { type: 'adgroup_change_target_roas' } },
    content: 'Change Ad Group Target Roas',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      ruleWithPausedCampaigns: { active: true, value: false },
      targetRoas: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.001',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  campaign_change_target_cpa_googleads: {
    level: 'Campaign',
    rule_action: 'change_target_cpa',
    to: { name: route.name, query: { type: 'campaign_change_target_cpa_googleads' } },
    content: 'Change Campaign Target Roas',
    rule_action_alias: 'change_target_cpa_googleads',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      ruleWithPausedCampaigns: { active: true, value: false },
      targetRoas: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: 1,
          max: 100000,
        },
      },
    },
  },
  campaign_change_bid_cap: {
    level: 'Campaign',
    rule_action: 'change_bid_cap',
    to: { name: route.name, query: { type: 'campaign_change_bid_cap' } },
    content: 'Change Bid Cap',
    ts_types: [tsUniqueName.outbrain, tsUniqueName.taboola],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      bidCap: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  campaign_copy: {
    level: 'Campaign',
    indefinite_level_rename: 'a Campaign',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'campaign_copy' } },
    content: 'Clone Campaign',
    ts_types: [tsUniqueName.facebook, tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  campaign_copy_budget: {
    level: 'Campaign',
    indefinite_level_rename: 'a Campaign',
    rule_action: 'copy_budget',
    to: { name: route.name, query: { type: 'campaign_copy_budget' } },
    content: 'Clone Campaign',
    ts_types: [tsUniqueName.tiktok, tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      numberOfCopies: {
        title: 'Copies and Custom Budget',
        active: true,
        value: 1,
        budget: {
          active: true,
          template: 'classic',
          value: {
            action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
          },
          adset: {
            action: 'set', unit: 'static', to: '', value: '', min: '', max: '', spread: '',
          },
          limits: {
            step: '0.01',
            min: limits.min_budget,
            max: limits.max_budget,
          },
          isOptional: true,
          enableBudget: false,
          enableAdSetBudget: false,
          info: '',
        },
        adsetBudget: {
          active: true,
          template: 'classic',
          value: {
            action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
          },
          adset: {
            action: 'set', unit: 'static', to: '', value: '', min: '', max: '', spread: '',
          },
          limits: {
            step: '0.01',
            min: limits.min_budget,
            max: limits.max_budget,
          },
          isOptional: true,
          enableBudget: false,
          enableAdSetBudget: false,
          info: '',
        },
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  target_pause: {
    level: 'Target',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'target_pause' } },
    content: 'Exclude Search Terms',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  target_pause_amazon: {
    level: 'Target',
    rule_action: 'pause_amazon',
    to: { name: route.name, query: { type: 'target_pause_amazon' } },
    content: 'Pause Deals',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  site_start: {
    level: 'Site',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'site_start' } },
    content: 'Activate Site',
    ts_types: [tsUniqueName.activerevenue],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  site_pause: {
    level: 'Site',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'site_pause' } },
    content: 'Pause Site',
    ts_types: [tsUniqueName.activerevenue],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_start: {
    level: 'Exchange',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'exchange_start' } },
    content: 'Activate Exchange',
    ts_types: [tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_pause: {
    level: 'Exchange',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'exchange_pause' } },
    content: 'Pause Exchange',
    ts_types: [tsUniqueName.voluumdsp, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_start_amazon: {
    level: 'Exchange',
    rule_action: 'start_amazon',
    to: { name: route.name, query: { type: 'exchange_start_amazon' } },
    content: 'Activate Supply Sources',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_pause_amazon: {
    level: 'Exchange',
    rule_action: 'pause_amazon',
    to: { name: route.name, query: { type: 'exchange_pause_amazon' } },
    content: 'Pause Supply Sources',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_start_geo: {
    level: 'Exchange',
    rule_action: 'start_facebook',
    to: { name: route.name, query: { type: 'exchange_start_geo' } },
    content: 'Start Geos',
    rule_action_alias: 'start_geo',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  exchange_pause_geo: {
    level: 'Exchange',
    rule_action: 'pause_facebook',
    to: { name: route.name, query: { type: 'exchange_pause_geo' } },
    content: 'Pause Geos',
    rule_action_alias: 'pause_geo',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  target_start: {
    level: 'Target',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'target_start' } },
    content: 'Search Term to Keyword',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  target_start_amazon: {
    level: 'Target',
    rule_action: 'start_amazon',
    to: { name: route.name, query: { type: 'target_start_amazon' } },
    content: 'Activate Deals',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      parentWhitelist: { active: true, value: { parent_level: 'AdGroup', entities: [], type: null }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  section_pause: {
    level: 'Section',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'section_pause' } },
    content: 'Pause Sections',
    ts_types: [tsUniqueName.outbrain],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Section' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  section_start: {
    level: 'Section',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'section_start' } },
    content: 'Activate Sections',
    ts_types: [tsUniqueName.outbrain],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Section' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  section_change_bid: {
    level: 'Section',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'section_change_bid' } },
    content: 'Change Section Bid',
    ts_types: [tsUniqueName.outbrain],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Section' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.01',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  widget_change_bid: {
    level: 'Widget',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'widget_change_bid' } },
    content: 'Change Site Bid',
    ts_types: [tsUniqueName.taboola, tsUniqueName.gemini, tsUniqueName.mediago],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      // flags: {
      //   active: true,
      //   value: [],
      //   options: [
      //     { text: 'Execute Taboola Smart Bid', value: { taboola_execute_smart_bid: true } }
      //   ]
      // },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  widget_change_bid_googleads: {
    level: 'Widget',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'widget_change_bid_googleads' } },
    rule_action_alias: 'change_bid_googleads',
    content: 'Change Keyword Bid',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  widget_change_bid_voluumdsp: {
    level: 'Widget',
    rule_action: 'change_bid_voluumdsp',
    to: { name: route.name, query: { type: 'widget_change_bid_voluumdsp' } },
    content: 'Change Widget Bid',
    ts_types: [tsUniqueName.voluumdsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  widget_change_bid_cad: {
    level: 'Widget',
    rule_action: 'change_bid_cad',
    to: { name: route.name, query: { type: 'widget_change_bid_cad' } },
    content: 'Change Widget Bid  (CAD only)',
    ts_types: [tsUniqueName.contentad],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bidCad: {
        active: true,
        template: 'widget',
        value: {
          desktop: {
            status: true, action: 'set', unit: 'static', to: '', value: '',
          },
          // 'tablet': { status: true, action: 'set', unit: 'static', to: '', value: '' },
          mobile: {
            status: true, action: 'set', unit: 'static', to: '', value: '',
          },
          min: 0,
          max: 0,
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  site_change_bid: {
    level: 'Site',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'site_change_bid' } },
    content: 'Change Site Bid',
    ts_types: [tsUniqueName.voluumdsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  exchange_change_bid: {
    level: 'Exchange',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'exchange_change_bid' } },
    content: 'Change Exchange Bid',
    ts_types: [tsUniqueName.voluumdsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  widget_change_bid_gemini: {
    level: 'Widget',
    rule_action: 'change_bid_gemini',
    to: { name: route.name, query: { type: 'widget_change_bid_gemini' } },
    content: 'Change Site Bid Modifier',
    ts_types: [tsUniqueName.gemini],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bidModifier: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '1',
          min: '-100',
          max: '500',
          'v-validate': {
            min: ['between:-80,800'],
            max: ['between:-80,800'],
          },
        },
      },
    },
  },
  widget_change_bid_revcontent: {
    level: 'Widget',
    rule_action: 'change_bid_revcontent',
    to: { name: route.name, query: { type: 'widget_change_bid_revcontent' } },
    content: 'Change Widget Bid',
    ts_types: [tsUniqueName.revcontent],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      widget: { active: true, value: null },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  adgroup_change_bid_adgroup: {
    level: 'AdGroup',
    rule_action: 'change_bid_adgroup',
    to: { name: route.name, query: { type: 'adgroup_change_bid_adgroup' } },
    content: 'Change AdGroup Bid',
    ts_types: [tsUniqueName.gemini, tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      // adgroups: { active: true, value: [] },
      ruleWithPausedCampaigns: { active: true, value: false },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      adgroup: { active: true, value: '' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  adgroup_change_bid_facebook: {
    level: 'AdGroup',
    rule_action: 'change_bid_facebook',
    to: { name: route.name, query: { type: 'adgroup_change_bid_facebook' } },
    content: 'Change AdSets Bid Facebook',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  adgroup_change_bid_amazon: {
    level: 'AdGroup',
    rule_action: 'change_bid_amazon',
    to: { name: route.name, query: { type: 'adgroup_change_bid_amazon' } },
    content: 'Change Line Item Bid',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      // entitySelector: { active: true, value: { type: null, entities: [] }, label: 'Line Item' },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect Base Supply Bid!',
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  adgroup_change_budget_amazon: {
    level: 'AdGroup',
    rule_action: 'change_budget_amazon',
    to: { name: route.name, query: { type: 'adgroup_change_budget_amazon' } },
    content: 'Change Line Item Daily Budget',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      // entitySelector: { active: true, value: { type: null, entities: [] }, label: 'Line Item' },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          // dailyBudget: true, // (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
        },
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  adgroup_change_budget: {
    level: 'AdGroup',
    rule_action: 'change_budget',
    to: { name: route.name, query: { type: 'adgroup_change_budget' } },
    content: 'Change AdSets Daily Budget',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          // dailyBudget: true, // (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
        },
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  adgroup_change_budget_tiktok: {
    level: 'AdGroup',
    rule_action: 'change_budget',
    to: { name: route.name, query: { type: 'adgroup_change_budget_tiktok' } },
    rule_action_alias: 'change_budget_tiktok',
    content: 'Change AdGroup Daily Budget',
    ts_types: [tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdGroup' },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          // dailyBudget: true, // (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
        },
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      ruleWithScheduledAdGroup: { active: true, value: false },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  adgroup_change_budget_yahoodsp: {
    level: 'AdGroup',
    rule_action: 'change_budget',
    to: { name: route.name, query: { type: 'adgroup_change_budget_yahoodsp' } },
    rule_action_alias: 'change_budget_yahoodsp',
    content: 'Change Line Item Daily Budget',
    ts_types: [tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: { active: true, value: [] },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'Line Item' },
      campaigns: {
        active: true,
        value: [],
        info: '<b>Note:</b> This rule will only affect daily budgets (not total or monthly budgets) !',
        filter: {
          // dailyBudget: true, // (item) => item.traffic_source_unique_name !== tsUniqueName.taboola || typeof item.daily_budget !== 'undefined',
        },
      },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      budget: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.01',
          min: limits.min_budget,
          max: limits.max_budget,
        },
      },
    },
  },
  adgroup_pause: {
    level: 'AdGroup',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'adgroup_pause' } },
    content: 'Pause AdGroup',
    ts_types: [tsUniqueName.gemini, tsUniqueName.tiktok, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_pause_yahoodsp: {
    level: 'AdGroup',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'adgroup_pause_yahoodsp' } },
    rule_action_alias: 'adgroup_pause_yahoodsp',
    content: 'Pause Line Item',
    ts_types: [tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_copy_googleads: {
    level: 'AdGroup',
    indefinite_level_rename: 'an AdGroup',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'adgroup_copy_googleads' } },
    rule_action_alias: 'copy_googleads',
    content: 'Clone AdGroup',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdGroup' },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_copy: {
    level: 'AdGroup',
    indefinite_level_rename: 'an AdSet',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'adgroup_copy' } },
    content: 'Clone AdSet',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_copy: {
    level: 'Content',
    indefinite_level_rename: 'an Ad',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'content_copy' } },
    content: 'Clone Ad',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  content_copy_tiktok: {
    level: 'Content',
    indefinite_level_rename: 'an Ad',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'content_copy_tiktok' } },
    content: 'Clone Ad',
    ts_types: [tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_copy_tiktok: {
    level: 'AdGroup',
    indefinite_level_rename: 'an AdGroup',
    rule_action: 'copy',
    to: { name: route.name, query: { type: 'adgroup_copy_tiktok' } },
    rule_action_alias: 'copy_tiktok',
    content: 'Clone AdGroup',
    ts_types: [tsUniqueName.tiktok],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdGroup' },
      numberOfCopies: { active: true, value: 1 },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_start: {
    level: 'AdGroup',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'adgroup_start' } },
    content: 'Activate AdGroup',
    ts_types: [tsUniqueName.gemini, tsUniqueName.tiktok, tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_start_yahoodsp: {
    level: 'AdGroup',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'adgroup_start_yahoodsp' } },
    rule_action_alias: 'adgroup_start_yahoodsp',
    content: 'Activate Line Item',
    ts_types: [tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_pause_facebook: {
    level: 'AdGroup',
    rule_action: 'pause_facebook',
    to: { name: route.name, query: { type: 'adgroup_pause_facebook' } },
    content: 'Pause AdSets Facebook',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_start_facebook: {
    level: 'AdGroup',
    rule_action: 'start_facebook',
    to: { name: route.name, query: { type: 'adgroup_start_facebook' } },
    content: 'Activate AdSets Facebook',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      entitySelector: { active: true, value: { type: null, entities: [] }, label: 'AdSet' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_pause_amazon: {
    level: 'AdGroup',
    rule_action: 'pause_amazon',
    to: { name: route.name, query: { type: 'adgroup_pause_amazon' } },
    content: 'Pause Line Items',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      // entitySelector: { active: true, value: { type: null, entities: [] }, label: 'Line Item' },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  adgroup_start_amazon: {
    level: 'AdGroup',
    rule_action: 'start_amazon',
    to: { name: route.name, query: { type: 'adgroup_start_amazon' } },
    content: 'Activate Line Items',
    ts_types: [tsUniqueName.amazondsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['live_spent', 'bid'],
      },
      // entitySelector: { active: true, value: { type: null, entities: [] }, label: 'Line Item' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  domain_pause: {
    level: 'Domain',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'domain_pause' } },
    content: 'Pause Domain',
    ts_types: [tsUniqueName.amazondsp, tsUniqueName.gemini, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  domain_start: {
    level: 'Domain',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'domain_start' } },
    content: 'Activate Domain',
    ts_types: [tsUniqueName.amazondsp, tsUniqueName.gemini, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  domain_pause_device: {
    level: 'Domain',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'domain_pause_device' } },
    content: 'Pause Device',
    rule_action_alias: 'pause_device',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  domain_start_device: {
    level: 'Domain',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'domain_start_device' } },
    content: 'Activate Device',
    rule_action_alias: 'start_device',
    ts_types: [tsUniqueName.facebook],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  domain_pause_placement: {
    level: 'Domain',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'domain_pause_placement' } },
    content: 'Exclude Placement',
    rule_action_alias: 'pause_placement',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  domain_start_placement: {
    level: 'Domain',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'domain_start_placement' } },
    content: 'Allow Placement',
    rule_action_alias: 'start_placement',
    ts_types: [tsUniqueName.googleAds],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
    },
  },
  spot_pause: {
    level: 'Spot',
    rule_action: 'pause',
    to: { name: route.name, query: { type: 'spot_pause' } },
    content: 'Pause Spot',
    ts_types: [tsUniqueName.runative],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  spot_start: {
    level: 'Spot',
    rule_action: 'start',
    to: { name: route.name, query: { type: 'spot_start' } },
    content: 'Activate Spot',
    ts_types: [tsUniqueName.runative],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
    },
  },
  target_change_bid: {
    level: 'Target',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'target_change_bid' } },
    content: 'Change Target Bid',
    ts_types: [tsUniqueName.revcontent],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      ruleWithPausedCampaigns: { active: true, value: false },
      alert: { active: true, value: '2' },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.01',
          min: limits.min_bid,
          max: limits.max_bid,
        },
      },
    },
  },
  content_change_bid: {
    level: 'Content',
    rule_action: 'change_bid',
    to: { name: route.name, query: { type: 'content_change_bid' } },
    content: 'Change Content Bid',
    ts_types: [tsUniqueName.mgid, tsUniqueName.adskeeper, tsUniqueName.outbrain],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid', 'name'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      ruleWithPausedCampaigns: { active: true, value: false },
      bid: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
        },
        limits: {
          step: '0.001',
          min: limits.min_bid,
          max: limits.max_bid,
        },
        show_cent_hint: true,
      },
    },
  },
  widget_change_coefficient: {
    level: 'Widget',
    rule_action: 'change_coefficient',
    to: { name: route.name, query: { type: 'widget_change_coefficient' } },
    content: 'Change Widget Coefficient',
    ts_types: [tsUniqueName.mgid, tsUniqueName.adskeeper],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      interval: { active: true, value: { input: '', interval_exclude: '0' } },
      conditions: {
        active: true, value: [], 'v-validate': { no_alone_scheduler: true }, except: ['budget', 'daily_budget', 'live_spent', 'bid'],
      },
      widgetsExclude: { active: true, value: { type: null, widgets: [] }, label: 'Widget' },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      rotation: { active: true, value: '' },
      alert: { active: true, value: '2' },
      ruleWithPausedCampaigns: { active: true, value: false },
      coefficient: {
        active: true,
        template: 'classic',
        value: {
          action: 'set', unit: 'static', to: '', value: '', min: '', max: '',
        },
        limits: {
          step: '0.1',
          min: limits.min_coefficient,
          max: limits.max_coefficient,
        },
      },
    },
  },
  campaign_day_parting: {
    level: 'Campaign',
    rule_action: 'day_parting',
    to: { name: route.name, query: { type: 'campaign_day_parting' } },
    content: 'Day Parting',
    ts_types: [tsUniqueName.mediago, tsUniqueName.facebook, tsUniqueName.amazondsp, tsUniqueName.revcontent,
      tsUniqueName.taboola, tsUniqueName.mgid, tsUniqueName.outbrain, tsUniqueName.adskeeper, tsUniqueName.activerevenue,
      tsUniqueName.gemini, tsUniqueName.tiktok, tsUniqueName.googleAds, tsUniqueName.yahoodsp],
    components: {
      name: { active: true, value: '' },
      groups: { active: true, value: [] },
      conditions: {
        active: true, value: [{ hour_of_day: { condition: '', value: '' } }], hour_of_day: true, disable_buttons: true, filter_fields: ['hour_of_day'],
      },
      campaigns: { active: true, value: [] },
      accounts: { active: true, value: [] },
      ruleWithPausedCampaigns: { active: true, value: false },
      rotation: {
        active: true, value: '0.16', disabled: true, hide: true,
      },
      alert: { active: true, value: '2' },
      campaign_action: { active: true, value: { valid: 'start', otherwise: 'pause' } },
    },
  },
};

const enabledTypes = [
  ruleTypes.widget_pause,
  ruleTypes.widget_start,
  ruleTypes.widget_pause_googleads,
  ruleTypes.widget_start_googleads,
  ruleTypes.widget_start_media_go,
  ruleTypes.widget_pause_media_go,
  ruleTypes.app_pause,
  ruleTypes.app_start,
  ruleTypes.campaign_pause,
  ruleTypes.campaign_start,
  ruleTypes.content_pause,
  ruleTypes.content_start,
  ruleTypes.campaign_change_bid,
  ruleTypes.widget_pause_fb,
  ruleTypes.widget_start_fb,
  // ruleTypes.campaign_change_bid_cad,
  ruleTypes.campaign_change_budget,
  ruleTypes.campaign_change_target_cpa,
  ruleTypes.target_pause,
  ruleTypes.target_start,
  ruleTypes.site_pause,
  ruleTypes.site_start,
  ruleTypes.exchange_pause,
  ruleTypes.exchange_start,
  ruleTypes.section_pause,
  ruleTypes.section_start,
  ruleTypes.exchange_start_geo,
  ruleTypes.exchange_pause_geo,
  ruleTypes.adgroup_pause,
  ruleTypes.adgroup_start,
  ruleTypes.domain_pause,
  ruleTypes.domain_start,
  ruleTypes.spot_pause,
  ruleTypes.spot_start,
  ruleTypes.domain_pause_device,
  ruleTypes.domain_start_device,
  ruleTypes.domain_pause_placement,
  ruleTypes.domain_start_placement,
  // ruleTypes.target_change_bid,
  ruleTypes.content_change_bid,
  ruleTypes.section_change_bid,
  ruleTypes.widget_change_coefficient,
  ruleTypes.widget_change_bid,
  ruleTypes.widget_change_bid_revcontent,
  ruleTypes.widget_change_bid_googleads,
  ruleTypes.widget_change_budget,
  // ruleTypes.widget_change_bid_voluumdsp,
  // ruleTypes.widget_change_bid_cad,
  // ruleTypes.site_change_bid,
  // ruleTypes.exchange_change_bid,
  ruleTypes.widget_change_bid_gemini,
  ruleTypes.adgroup_change_bid_adgroup,
  ruleTypes.campaign_day_parting,
  ruleTypes.content_pause_facebook,
  ruleTypes.content_start_facebook,
  ruleTypes.content_pause_tiktok,
  ruleTypes.content_start_tiktok,
  ruleTypes.adgroup_change_bid_facebook,
  ruleTypes.adgroup_change_budget,
  ruleTypes.adgroup_change_budget_tiktok,
  ruleTypes.adgroup_change_budget_yahoodsp,
  ruleTypes.adgroup_pause_yahoodsp,
  ruleTypes.adgroup_start_yahoodsp,
  ruleTypes.adgroup_start_facebook,
  ruleTypes.adgroup_pause_facebook,
  ruleTypes.content_pause_amazon,
  ruleTypes.content_start_amazon,
  ruleTypes.adgroup_change_bid_amazon,
  ruleTypes.adgroup_change_budget_amazon,
  ruleTypes.adgroup_change_target_roas,
  ruleTypes.adgroup_pause_amazon,
  ruleTypes.adgroup_start_amazon,
  ruleTypes.exchange_pause_amazon,
  ruleTypes.exchange_start_amazon,
  ruleTypes.target_pause_amazon,
  ruleTypes.target_start_amazon,
  ruleTypes.adgroup_copy,
  ruleTypes.content_copy,
  ruleTypes.content_copy_tiktok,
  ruleTypes.adgroup_copy_tiktok,
  ruleTypes.adgroup_copy_googleads,
  ruleTypes.campaign_copy_budget,
  ruleTypes.campaign_change_target_cpa_googleads,
  ruleTypes.campaign_change_bid_cap,
];

module.exports = { ruleTypes, enabledTypes };
