import sections from './sections';
import report from './report';

export default (F) => ({
  ...sections(F),
  ...report(F),
  widget_name: F.create({
    header: { content: 'Widget Name' },
    item: {
      key: 'widget_name',
      sortable: true,
      total: null,
    },
  }),
});
