import { api } from '@sh/services/api';
import CampaignModel from '../../lib/CampaignModel';
import languageData from './languageData';
import countryData from './countryData';
import browserData from './browserData';
import desktopOsData from './desktopOSData';
import mobileOsData from './mobileOSData';
import tabletOsData from './tabletOSData';
import categoriesData from './categoriesData';

/**
 * API Model: {
 *  name: <String>,
 *  campaignType: <String|product|content|push>,
 *  language: <String>,
 *  startDate: <String|optional|y-m-d>,
 *  enabledGeoTargetingFlag: 1,
 *  geoTargets:{
 *    method: 'set',
 *    countries: <Array|[<String>]>,
 *    cities: <Array|[<String>]>
 *  },
 *  browserTargets:{
 *    enabledFlag: 1,
 *    targets: <String>
 *  }
 *  osTargets:{
 *    enabledFlag: 1,
 *    targets: <String>
 *  },
 *  limitType: <String|clicks_limits|budget_limits>,
 *  dailyLimit: <String>,
 *  overallLimit: <String>,
 *  utm_source: <String>,
 *  utm_medium: <String>,
 *  utm_campaign: <String>,
 *  utm_custom: <String>
 *  splitDailyLimitEvenly: <Number>
 *  categoryId: <Number>,
 *  advertiserName: <String>,
 * }
 */
export default class MgidCampaignModel extends CampaignModel {
  async setModel(form, nextTick, mode) {
    this.components.name.value = mode === 'clone' ? `${form.name} CLONE` : form.name;
    this.components.campaignType.value = this.components.campaignType.options.find((item) => item.value === form.campaignType);
    if (form.campaignType.value === 'search_feed') {
      this.components.keyword.active = true;
      this.components.keyword.value = form.value;
    }
    this.components.language.value = this.components.language.options.find((item) => item.id === form.language);
    if (form.startDate) {
      this.components.start_date_model.value = 'specific';
      this.components.start_date.value = form.startDate;
    }
    // Countries
    if (form.geoTargets.countries.length) {
      const countriesMap = {};
      form.geoTargets.countries.forEach((code) => { countriesMap[code] = true; });
      this.components.countries.value = this.components.countries.options.filter((item) => countriesMap[item.code]);
    }
    // Cities
    if (form.geoTargets.cities.length) {
      nextTick(() => {
        this.components.cities.once('ready', () => {
          const citiesMap = {};
          form.geoTargets.cities.forEach((id) => { citiesMap[id] = true; });
          this.components.cities.value = this.components.cities.options.filter((item) => citiesMap[item.id]);
        });
      });
    }
    // Browsers
    if (form.browserTargets.targets.length) {
      const browsersMap = {};
      form.browserTargets.targets.split(',').forEach((code) => { browsersMap[code] = true; });
      this.components.browsers.value = this.components.browsers.options.filter((item) => browsersMap[item.code]);
    }
    // OS
    if (form.osTargets.targets.length) {
      const osMap = {};
      form.osTargets.targets.split(',').forEach((code) => { osMap[code] = true; });
      ['desktopOs', 'tabletOs', 'mobileOs'].forEach((osDevice) => {
        const osValue = this.components[osDevice].options.filter((item) => osMap[item.code]);
        if (osValue.length) {
          this.components[osDevice].value = this.components[osDevice].options.length - 2 === osValue.length ? [] : osValue;
        } else {
          this.components[osDevice].value = this.components[osDevice].options.filter(({ code }) => code === false);
        }
      });
    }
    this.components.limitType.value = form.limitType;
    this.components.dailyLimit.value = form.dailyLimit;
    this.components.overallLimit.value = form.overallLimit;
    if (form.utm_source + form.utm_medium + form.utm_campaign) {
      this.components.utm_tagging_model.value = [true];
      this.components.utm_source.value = form.utm_source;
      this.components.utm_medium.value = form.utm_medium;
      this.components.utm_campaign.value = form.utm_campaign;
    }
    this.components.utm_custom.value = form.utm_custom;
    this.components.splitDailyLimitEvenly.value = [];
    this.components.splitDailyLimitEvenly.value.push(form.splitDailyLimitEvenly);
    this.components.categoryId.value = [];
    this.components.categoryId.value.push(form.categoryId);
    this.components.geo_Target.value.push(form.geo_Target);
    this.components.browser_Target.value.push(form.browser_Target);
    this.components.os_Target.value.push(form.os_Target);
    this.components.advertiserName = form.advertiserName;
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const form = {
      // campaignCreatorV2: true,
      name: modelForm.name,
      campaignType: modelForm.campaignType.value,
      language: modelForm.language.id,
      enabledGeoTargetingFlag: 1,
      geoTargets: {
        method: 'set',
        countries: '',
        cities: '',
      },
      browserTargets: {
        enabledFlag: 1,
        targets: '',
      },
      osTargets: {
        enabledFlag: 1,
        targets: '',
      },
      limitType: modelForm.limitType,
      dailyLimit: modelForm.dailyLimit,
      overallLimit: modelForm.overallLimit,
      utm_source: modelForm.utm_source || '',
      utm_medium: modelForm.utm_medium || '',
      utm_campaign: modelForm.utm_campaign || '',
      utm_custom: modelForm.utm_custom || '',
      advertiserName: modelForm.advertiserName || '',
    };
    if (modelForm.campaignType.value === 'search_feed') {
      form.keyword = modelForm.keyword;
    }
    if (modelForm.start_date_model === 'specific') {
      form.startDate = modelForm.start_date;
    }
    if (modelForm.countries.length) {
      const includedCountries = modelForm.countries;
      const includedCities = modelForm.cities;
      const exclude = modelForm.geo_Target === 'EXCLUDE';
      if (exclude) {
        // all except included
        const includeAllCountriesExcept = this.components.countries.options.filter((item) => !includedCountries.includes(item));
        const includeAllCitiesExcept = this.components.cities.options.filter((item) => !includedCities.includes(item));
        form.geoTargets.cities = includeAllCitiesExcept.map((item) => item.id);
        form.geoTargets.countries = modelForm.cities.length > 0 ? '' : includeAllCountriesExcept.map((item) => item.code);
      } else {
        form.geoTargets.countries = modelForm.cities.length > 0 ? '' : includedCountries.map((item) => item.code);
        form.geoTargets.cities = includedCities.map((item) => item.id);
      }
    }
    if (modelForm.browsers.length) {
      const included = modelForm.browsers;
      const exclude = modelForm.browser_Target === 'EXCLUDE';
      if (exclude) {
        // all except included
        const includeAllExcept = this.components.browsers.options.filter((item) => !included.includes(item));
        form.browserTargets.targets = includeAllExcept.map((item) => item.code).join(',');
      } else {
        form.browserTargets.targets = included.map((item) => item.code).join(',');
      }
    }
    if (modelForm.desktopOs.length + modelForm.mobileOs.length + modelForm.tabletOs.length) {
      const included = [];
      const exclude = modelForm.os_Target === 'EXCLUDE';
      ['desktopOs', 'mobileOs', 'tabletOs'].forEach((os) => {
        if (modelForm[os].length) {
          if (modelForm[os][0].code === true) {
            this.components[os].options.forEach((item) => { if (!item.reset) included.push(item.code); });
          } else if (modelForm[os][0].code !== false) {
            included.push(...modelForm[os].map((item) => item.code));
          }
        } else {
          this.components[os].options.forEach((item) => { if (!item.reset) included.push(item.code); });
          // included.push(...this.components[os].options.map(item => item.code))
        }
      });
      if (exclude) {
        // include all os except these
        const includeAllExcept = [];
        ['desktopOs', 'mobileOs', 'tabletOs'].forEach((os) => {
          this.components[os].options.forEach((item) => { if (!included.includes(item.code)) includeAllExcept.push(item.code); });
        });
        form.osTargets.targets = includeAllExcept.join(',');
      } else {
        form.osTargets.targets = included.join(',');
      }
    }
    if (modelForm.splitDailyLimitEvenly) {
      form.splitDailyLimitEvenly = modelForm.splitDailyLimitEvenly[0] === 0 ? 0 : 1;
    }
    [form.categoryId] = modelForm.categoryId;

    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      campaignTypeOptions: [
        { text: 'PRODUCT PROMOTIONS', value: 'product' },
        { text: 'CONTENT PROMOTIONS', value: 'content' },
        { text: 'PUSH', value: 'push' },
        { text: 'SEARCH FEED', value: 'search_feed' },
      ],
      languageOptions: languageData,
      startDateOptions: [
        { text: 'As soon as reviewed', value: 'asap' },
        { text: 'Specific Date', value: 'specific' },
      ],
      countryTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      countryData,
      getCities: async () => {
        if (this.components.countries.value.length) {
          const countryCodes = this.components.countries.value.map((item) => item.code);
          const data = await api.campaignCreator.searchMgidCities(this.account.value.id, countryCodes);
          return data;
        }
        return [];
      },
      browserTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      browsersOptions: browserData,
      desktopTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      desktopOsOptions: desktopOsData,
      mobileTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      mobileOsOptions: mobileOsData,
      tabletTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      tabletOsOptions: tabletOsData,
      cityTypeOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      limitTypeOptions: [
        { text: 'Click Limit', value: 'clicks_limits' },
        { text: 'Budget Limit', value: 'budget_limits' },
      ],
      splitDailyLimitEvenlyOptions: [
        { text: 'Split the budget evenly throughout the day ', value: 0 },
      ],
      categoriesData,
    };
  }

  __generatePopOver() {
    return {
      namePopover: {
        content: 'Campaign name for its further identification. The name must be unique within your account',
        html: true,
      },
      typePopover: {
        content: 'You need to select the type of website you\'d like to promote. If you want to advertise any service or product, you need to select Product promotions. If you want to advertise websites that contains articles, please select Content promotions.',
        html: true,
      },
      languagePopover: {
        content: 'Please, choose a campaign language and use it while creating your ads. You won’t be able to change the language afterwards. All creatives in other languages will be rejected',
        html: true,
      },
      limitTypePopover: {
        content: 'You can set daily and total campaign caps here.',
        html: true,
      },
      utmTaggingPopover: {
        content: `Choose this in case you are using Google Analytics to track the performance
        <strong>utm_content</strong> tag value is automatically filled with teaser ID
        <strong>utm_term</strong> tag value is automatically filled with widget ID`,
        html: true,
      },
      keywordPopover: {
        content: 'You need to provide a keyword for your search-feed. Keywords are collected to further optimize campaigns. You won’t be able to change the keyword afterwards.',
        html: true,
      },
      splitBudgetPopover: {
        content: 'You will get the same amount of traffic every hour (campaign schedule is taken into account). We recommend you to use this option with a daily limit starting from 500 clicks or equivalent in a budget\'s limit.',
        html: true,
      },
      advertiserNamePopover: {
        content: 'Select the name of your product, brand or website to appear in your ad below the title. You won’t be able to change it after your campaigns go live.',
        html: true,
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      name: {
        active: true, component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required', popover: this.popover.namePopover,
      },
      campaignType: {
        active: true, component: 'cc-multi-select', value: null, label: 'Campaign Type', 'v-validate': 'required', options: this.options.campaignTypeOptions, optionLabel: 'text', popover: this.popover.typePopover, placeholder: 'Select Type', multiple: false,
      },
      keyword: {
        active: false, component: 'cc-input', value: '', label: 'Keyword', 'v-validate': { required: true }, popover: this.popover.keywordPopover,
      },
      language: {
        active: true, component: 'cc-multi-select', value: null, label: 'Campaign Language', 'v-validate': 'required', options: this.options.languageOptions, optionLabel: 'name', popover: this.popover.languagePopover, placeholder: 'Select Language', multiple: false,
      },
      start_date_model: {
        active: true, component: 'cc-radio-button', value: 'asap', label: 'Start Date', 'v-validate': 'required', options: this.options.startDateOptions,
      },
      start_date: {
        active: false, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date',
      },
      countries: {
        active: true, component: 'cc-multi-select', value: [], label: 'Countries', options: this.options.countryData, optionLabel: 'name', flagLabel: 'code', exceptFlag: ['O1'], placeholder: 'All Countries', type: 'countries',
      },
      cities: {
        active: true, component: 'cc-multi-select', value: [], label: 'Cities', options: [], getOptions: this.options.getCities, optionLabel: 'name', placeholder: 'All Cities', key: 'cities',
      },
      browsers: {
        active: true, component: 'cc-multi-select', value: [], label: 'Browsers', options: this.options.browsersOptions, optionLabel: 'name', placeholder: 'All Browsers',
      },
      desktopOs: {
        active: true, component: 'cc-multi-select', value: [], label: 'Desktop OS', options: this.options.desktopOsOptions, optionLabel: 'name', placeholder: 'All Desktop OS',
      },
      mobileOs: {
        active: true, component: 'cc-multi-select', value: [], label: 'Mobile OS', options: this.options.mobileOsOptions, optionLabel: 'name', placeholder: 'All Mobile OS',
      },
      tabletOs: {
        active: true, component: 'cc-multi-select', value: [], label: 'Tablet OS', options: this.options.tabletOsOptions, optionLabel: 'name', placeholder: 'All Tablet OS',
      },
      limitType: {
        active: true, component: 'cc-radio-button', value: 'budget_limits', label: 'Limit Type', 'v-validate': 'required', options: this.options.limitTypeOptions, popover: this.popover.limitTypePopover,
      },
      dailyLimit: {
        active: true, component: 'cc-input', value: '', label: 'Daily Limit', 'v-validate': { required: true, min_value: 50, decimal: 2 }, key: 'daily-limit-budget_limits', left: '$',
      },
      overallLimit: {
        active: true, component: 'cc-input', value: '', label: 'Overall Limit', 'v-validate': { required: true, min_value: 50, decimal: 2 }, key: 'overall-limit-budget_limits', left: '$',
      },
      utm_tagging_model: {
        active: true, component: 'cc-checkbox', value: [], label: 'UTM Tagging', options: [{ text: 'Enable', value: true }], popover: this.popover.utmTaggingPopover,
      },
      utm_source: {
        active: false, component: 'cc-input', value: '', label: 'Utm Source', 'v-validate': 'required|max:200',
      },
      utm_medium: {
        active: false, component: 'cc-input', value: '', label: 'Utm Medium', 'v-validate': 'required|max:200',
      },
      utm_campaign: {
        active: false, component: 'cc-input', value: '', label: 'Utm Campaign', 'v-validate': 'required|max:200',
      },
      utm_custom: {
        active: true, component: 'cc-input', value: '', label: 'Utm Custom', 'v-validate': 'max:200',
      },
      splitDailyLimitEvenly: {
        active: false, component: 'cc-checkbox', value: [], label: 'Split Budget', options: this.options.splitDailyLimitEvenlyOptions, popover: this.popover.splitBudgetPopover,
      },
      categoryId: {
        active: false, component: 'cc-nested-select', value: [], label: 'Campaign Category', 'v-validate': 'required', options: this.options.categoriesData,
      },
      browser_Target: {
        active: true, component: 'cc-radio-button', value: 'INCLUDE', label: 'Browsers', 'v-validate': 'required', options: this.options.browserTypeOptions,
      },
      os_Target: {
        active: true, component: 'cc-radio-button', value: 'INCLUDE', label: 'Desktop OS', 'v-validate': 'required', options: this.options.desktopTypeOptions,
      },
      geo_Target: {
        active: true, component: 'cc-radio-button', value: 'INCLUDE', label: 'Countries', 'v-validate': 'required', options: this.options.countryTypeOptions,
      },
      advertiserName: {
        active: true, component: 'cc-input', value: '', label: 'Advertiser name', placeholder: 'Advertiser name', 'v-validate': 'required', popover: this.popover.advertiserNamePopover,
      },
      // isTemplate: { active: true, component: 'cc-checkbox', label: 'Template', 'v-validate': '', value: [true], options: { value: true, text: 'Save also as template' } },
    };

    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      start_date_model: {
        fn: (value) => {
          if (value === 'asap') {
            _this.components.start_date.active = false;
            _this.components.start_date.value = '';
            _this.components.start_date.disabledDate.to = _this.data.today;
          } else if (value === 'specific') {
            _this.components.start_date.active = true;
          }
        },
      },
      campaignType: {
        fn: (value) => {
          _this.components.categoryId.active = false;
          if (value && value.value === 'search_feed') {
            _this.components.keyword.active = true;
            _this.components.advertiserName.active = false;
          } else {
            _this.components.advertiserName.active = true;
            _this.components.keyword.active = false;
          }
          if (value) {
            _this.components.categoryId.options = categoriesData.filter(
              (category) => category.campaing_types && category.campaing_types.includes(value.value),
            );
            _this.components.categoryId.active = true;
          }
        },
      },
      countries: {
        fn: (value) => {
          const codes = value.map((item) => item.code);
          _this.components.cities.options = [];
          _this.components.cities.value = [];
          _this.components.cities.key = codes.length ? `cities-${codes.join(':')}` : 'cities';
        },
      },
      os: {
        trigger() {
          return {
            desktopOs: _this.components.desktopOs.value[0],
            tabletOs: _this.components.tabletOs.value[0],
            mobileOs: _this.components.mobileOs.value[0],
          };
        },
        fn(osDevices) {
          const excludeAllDevices = Object.values(osDevices).reduce((sum, val) => sum && !!val && val.code === false, true);
          if (excludeAllDevices) Object.keys(osDevices).forEach((osDevice) => { _this.components[osDevice].value = []; });
        },
      },
      limitType: {
        fn: (value) => {
          _this.components.dailyLimit.key = `daily-limit-${value}`;
          _this.components.overallLimit.key = `overall-limit-${value}`;
          _this.components.splitDailyLimitEvenly.active = false;
          if (value === 'budget_limits') {
            _this.components.dailyLimit.left = '$';
            _this.components.overallLimit.left = '$';
            _this.components.dailyLimit['v-validate'].decimal = 2;
            _this.components.overallLimit['v-validate'].decimal = 2;
            _this.components.dailyLimit['v-validate'].min_value = 50;
            _this.components.overallLimit['v-validate'].min_value = !isNaN(Number(_this.components.dailyLimit.value)) && Number(_this.components.dailyLimit.value) > 50 ? Number(_this.components.dailyLimit.value) + 1 : 50;
            delete _this.components.dailyLimit['v-validate'].integer;
            delete _this.components.overallLimit['v-validate'].integer;
          } else if (value === 'clicks_limits') {
            _this.components.dailyLimit.left = null;
            _this.components.overallLimit.left = null;
            delete _this.components.dailyLimit['v-validate'].decimal;
            delete _this.components.overallLimit['v-validate'].decimal;
            _this.components.dailyLimit['v-validate'].min_value = 500;
            _this.components.overallLimit['v-validate'].min_value = !isNaN(Number(_this.components.dailyLimit.value)) && Number(_this.components.dailyLimit.value) > 500 ? Number(_this.components.dailyLimit.value) : 500;
            _this.components.dailyLimit['v-validate'].integer = true;
            _this.components.overallLimit['v-validate'].integer = true;
            _this.components.splitDailyLimitEvenly.active = true;
          }
        },
      },
      dailyLimit: {
        fn: (value) => {
          const val = Number(value);
          const defaultValue = _this.components.dailyLimit['v-validate'].min_value;
          _this.components.overallLimit['v-validate'].min_value = !isNaN(val) ? val + 1 : defaultValue;
          _this.components.overallLimit.key = `overall-limit-${value}`;
        },
      },
      utm_tagging_model: {
        fn: (value) => {
          ['utm_source', 'utm_medium', 'utm_campaign'].forEach((key) => {
            _this.components[key].active = !!value.length;
          });
        },
      },
    };
  }
}

