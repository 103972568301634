import moment from 'moment';
import { api } from '@sh/services/api';
import CampaignModel from '../../lib/CampaignModel';

/**
 * API Model: {
 *  advertiserId: <Number>
 *  name: <String>,
 *  channel: <String>,
 *  language: <String>,
 *  targeting_attribute: [
 *    {
 *      type: <String: WOEID|GENDER|AGE|DEVICE>,
 *      value: <Number|String>
 *    }
 *  ],
 *  budget: <Number>,
 *  budgetType: <String>,
 *  biddingStrategy: <String|DEFAULT>,
 *  conversionRuleConfig: {
 *    conversionCounting: <String>,
 *  }
 *  ad_group: {
 *    traffic_source_adgroup_id: <Number> ,// (Optional only on Edit mode)
 *    name: <String>,
 *    bid: <Number>,
 *    startDate: <String: y-m-d|null>,
 *    endDate: <String: y-m-d|null>
 *  }
 * }
 */
export default class GeminiCampaignModel extends CampaignModel {
  constructor(account) {
    super(account);
    this.campaignPayload = null; // Campaign when clone or edit campaign
    this.campaigns = [];
  }

  async onInit({ mode, retry, account }) {
    if (!retry && ['create', 'clone'].includes(mode)) {
      this.campaigns = await api.campaigns.all({ trafficSourceAccountIds: account.id });
    } else {
      this.campaigns = [];
    }
    super.onInit({ mode, retry, account });
    this.components.advertiser.value = null;
    this.components.advertiser.options = [];
  }

  async setModel(form, nextTick, mode) {
    this.campaignPayload = form;
    this.components.name.value = mode === 'clone' ? `${form.name} CLONE` : form.name;
    nextTick(() => {
      this.components.advertiser.once('ready', () => {
        this.components.advertiser.value = this.components.advertiser.options.find(({ id }) => id === form.advertiserId);
      });
    });
    this.components.channel.value = form.channel;
    this.components.language.value = this.components.language.options.find(({ value }) => value === form.language);
    this.components.locations.value = [];
    //  WOEID, GENDER, AGE, DEVICE
    if (form.targeting_attribute && form.targeting_attribute.length) {
      const WOEID = [];
      form.targeting_attribute.forEach(({ type, value }) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'WOEID':
            WOEID.push(value);
            break;
          case 'GENDER':
            this.components.gender.value = this.components.gender.options.find(({ value: val }) => val === value) || null;
            break;
          case 'AGE':
            this.components.age.value = this.components.age.options.filter(({ value: val }) => val === value);
            break;
          case 'DEVICE':
            this.components.device.value = this.components.device.options.find(({ value: val }) => val === value) || null;
            break;
        }
      });
      if (WOEID.length) {
        const trafficSourceAccountId = this.account.value.id;
        const locations = await api.campaignCreator.getGeminiLocations(trafficSourceAccountId, WOEID);
        const options = locations.map(({ woeid, name }) => ({ name, value: `${woeid}` }));
        this.components.locations.options = [...options];
        this.components.locations.value = options;
      }
    }
    if (mode === 'edit') {
      this.components.locations.disabled = true;
      this.components.gender.disabled = true;
      this.components.age.disabled = true;
      this.components.device.disabled = true;
    }
    this.components.budget.value = form.budget;
    this.components.budgetType.value = form.budgetType;
    this.components.biddingStrategy.value = this.components.biddingStrategy.options.find(({ value }) => value === form.biddingStrategy);
    if (form.conversionRuleConfig && form.conversionRuleConfig.conversionCounting) {
      this.components.conversionCounting.value = this.components.conversionCounting.options.find(({ value }) => value === form.conversionRuleConfig.conversionCounting);
    }
    if (form.ad_group) {
      this.components.adGroupName.value = form.ad_group.name || '';
      this.components.adGroupcpc.value = form.ad_group.bid || '';
      if (form.ad_group.startDate) {
        this.components.adGroupSchedule.value = 'CUSTOM';
        this.components.startDateStr.value = form.ad_group.startDate;
      }
      if (form.ad_group.endDate) this.components.endDateStr.value = form.ad_group.endDate;
    }
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const form = {
      advertiserId: Number(modelForm.advertiser.id),
      name: modelForm.name,
      channel: modelForm.channel,
      language: modelForm.language.value,
      targeting_attribute: [
        { type: 'GENDER', value: modelForm.gender.value },
        { type: 'DEVICE', value: modelForm.device.value },
      ],
      budget: Number(modelForm.budget),
      budgetType: modelForm.budgetType,
      biddingStrategy: 'DEFAULT',
      conversionRuleConfig: {
        conversionCounting: modelForm.conversionCounting.value,
      },
      ad_group: {
        name: modelForm.adGroupName,
        bid: Number(modelForm.adGroupcpc),
        startDate: modelForm.startDateStr || null,
        endDate: modelForm.endDateStr || null,
      },
    };
    if (this.mode === 'edit' && this.campaignPayload.ad_group && this.campaignPayload.ad_group.traffic_source_adgroup_id) {
      form.ad_group.traffic_source_adgroup_id = this.campaignPayload.ad_group.traffic_source_adgroup_id;
    }
    if (modelForm.biddingStrategy) form.biddingStrategy = modelForm.biddingStrategy.value;
    modelForm.age.forEach(({ value }) => {
      form.targeting_attribute.push({ type: 'AGE', value });
    });
    modelForm.locations.forEach(({ value }) => {
      form.targeting_attribute.push({ type: 'WOEID', value });
    });
    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      getAdvertisers: async () => api.campaignCreator.getGeminiAdvertisers(this.account.value.id),
      channelOptions: [
        { text: 'Native Only', value: 'NATIVE' },
        { text: 'Search Only', value: 'SEARCH' },
        { text: 'Search and Native', value: 'SEARCH_AND_NATIVE' },
      ],
      languageOptions: [
        { name: 'English', value: 'en' },
        { name: 'Chinese (Simplified)', value: 'zh_CN' },
        { name: 'Chinese (Traditional)', value: 'zh_TW' },
        { name: 'French', value: 'fr' },
        { name: 'Greek', value: 'el' },
        { name: 'Italian', value: 'it' },
        { name: 'Indonesian', value: 'id' },
        { name: 'Japanese', value: 'ja' },
        { name: 'Romanian', value: 'ro' },
        { name: 'Malaysian', value: 'ms' },
        { name: 'Vietnamese', value: 'vi' },
        { name: 'Swedish', value: 'sv' },
        { name: 'Dutch', value: 'nl' },
        { name: 'Arabic', value: 'ar' },
        { name: 'Portugese', value: 'pt' },
        { name: 'German', value: 'de' },
        { name: 'Spanish', value: 'es' },
        { name: 'Russian', value: 'ru' },
        { name: 'Turkish', value: 'tr' },
      ],
      locationsOptions: [
        { name: 'United States', value: '23424977' },
      ],
      searchLocations: async (term) => {
        if (term.length < 2) return [];
        const result = await api.campaignCreator.searchGeminiLocations(this.account.value.id, term);
        return result.map(({ id, name }) => ({ name, value: id }));
      },
      genderOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'Male', value: 'MALE' },
        { name: 'Female', value: 'FEMALE' },
      ],
      ageOptions: [
        { name: 'All', value: 'ALL' },
        { name: '18-24', value: '18-24' },
        { name: '25-34', value: '25-34' },
        { name: '35-44', value: '35-44' },
        { name: '45-54', value: '45-54' },
        { name: '55-64', value: '55-64' },
        { name: '65+', value: '65-120' },
      ],
      deviceOptions: [
        { name: 'All computers and devices', value: 'ALL' },
        { name: 'Mobile devices only', value: 'MOBILE' },
      ],
      budgetTypeOptions: [
        { text: 'Per day', value: 'DAILY' },
        { text: 'Per month', value: 'MONTHLY' },
        { text: 'In total', value: 'LIFETIME' },
      ],
      biddingStrategyOptions: [
        { name: 'Enhanced CPC', value: 'OPT_ENHANCED_CPC' },
        { name: 'Manual CPC (Focus on clicks)', value: 'OPT_CLICK' },
        { name: 'Target CPA (Focus on conversions)', value: 'OPT_CONVERSION' },
        { name: 'Maximize conversions', value: 'MAX_OPT_CONVERSION' },
      ],
      conversionCountingOptions: [
        { name: 'All per interaction', value: 'ALL_PER_INTERACTION' },
        { name: 'One per interaction', value: 'ONE_PER_INTERACTION' },
      ],
      adGroupScheduleOptions: [
        { text: 'Start running ads immediately', value: 'IMMEDIATELY' },
        { text: 'Set a start and end date', value: 'CUSTOM' },
      ],
    };
  }

  __generatePopOver() {
    return {
      channelPopover: {
        content: 'You can only have one type of content per campaign. If you have mixed content, you\'ll need to break it out by campaign.',
      },
      languagePopover: {
        content: 'Select the language that matches your ad\'s text. Depending on your selection, some audience targeting features may not be available.',
      },
      budgetPopover: {
        content: 'Since traffic can fluctuate, up to 120% of your daily campaign budget may be user to show your ads on certain days where traffic is higher. Daily spend should balance out over the course of the month.',
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      advertiser: {
        active: true, component: 'cc-multi-select', value: null, label: 'Advertiser', 'v-validate': 'required', options: [], getOptions: this.options.getAdvertisers, optionLabel: 'name', placeholder: 'Select Advertiser', key: 'id', multiple: false,
      },
      name: {
        active: true, component: 'cc-input', value: '', label: 'Name', 'v-validate': { required: true, max: 255, excluded: this.campaigns.map(({ name }) => name) },
      },
      channel: {
        active: true, component: 'cc-radio-button', value: 'NATIVE', label: 'Type', 'v-validate': 'required', options: this.options.channelOptions, popover: this.popover.channelPopover,
      },
      language: {
        active: true, component: 'cc-multi-select', value: this.options.languageOptions.find(({ value }) => value === 'en'), label: 'Language', 'v-validate': 'required', options: this.options.languageOptions, placeholder: 'Select Language', multiple: false, popover: this.popover.languagePopover,
      },
      locations: {
        active: true, component: 'cc-multi-select', value: [{ name: 'United States', value: '23424977' }], label: 'Location', options: [], getSearchOptions: this.options.searchLocations, optionLabel: 'name', placeholder: 'Select Locations',
      },
      gender: {
        active: true, component: 'cc-multi-select', value: this.options.genderOptions.find(({ value }) => value === 'ALL'), label: 'Gender', 'v-validate': 'required', options: this.options.genderOptions, placeholder: 'Select Gender', multiple: false,
      },
      age: {
        active: true, component: 'cc-multi-select', value: [this.options.ageOptions.find(({ value }) => value === 'ALL')], label: 'Age', 'v-validate': 'required', options: this.options.ageOptions, placeholder: 'Select Age',
      },
      device: {
        active: true, component: 'cc-multi-select', value: this.options.deviceOptions.find(({ value }) => value === 'ALL'), label: 'Device', 'v-validate': 'required', options: this.options.deviceOptions, placeholder: 'Select Device', multiple: false,
      },
      budget: {
        active: true, component: 'cc-input', value: null, label: 'Budget', 'v-validate': { required: true, min_value: 10, decimal: 2 }, left: '$', popover: this.popover.budgetPopover,
      },
      budgetType: {
        active: true, component: 'cc-radio-button', value: 'DAILY', label: 'Budget Type', disableLabel: true, 'v-validate': 'required', options: this.options.budgetTypeOptions,
      },
      biddingStrategy: {
        active: true, component: 'cc-multi-select', value: this.options.biddingStrategyOptions.find(({ value }) => value === 'OPT_ENHANCED_CPC'), label: 'Bid strategy', options: this.options.biddingStrategyOptions, placeholder: 'Select Bid strategy', multiple: false,
      },
      conversionCounting: {
        active: true, component: 'cc-multi-select', value: this.options.conversionCountingOptions.find(({ value }) => value === 'ALL_PER_INTERACTION'), label: 'Conversion counting', 'v-validate': 'required', options: this.options.conversionCountingOptions, placeholder: 'Select Conversion counting', multiple: false,
      },
      adGroupName: {
        active: true, component: 'cc-input', value: '', label: 'Ad group name', 'v-validate': 'required|max:255',
      },
      adGroupcpc: {
        active: true, component: 'cc-input', value: 0.05, label: 'Bid (CPC)', 'v-validate': 'required|min_value:0', left: '$',
      },
      adGroupSchedule: {
        active: true, component: 'cc-radio-button', value: 'IMMEDIATELY', label: 'Schedule', 'v-validate': 'required', options: this.options.adGroupScheduleOptions,
      },
      startDateStr: {
        active: true, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date',
      },
      endDateStr: {
        active: true, component: 'cc-date-picker', value: '', label: 'End Date', disableLabel: true, disabledDate: { to: this.data.today }, placeholder: 'Select End Date',
      },
    };

    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      budgetType: {
        fn: (value) => {
          if (value === 'MONTHLY') {
            _this.components.budget['v-validate'] = { ..._this.components.budget['v-validate'], min_value: 240 };
          } else {
            _this.components.budget['v-validate'] = { ..._this.components.budget['v-validate'], min_value: 10 };
          }
        },
      },
      age: {
        fn: (value, oldValue) => {
          if (value.length > 1) {
            const valueAll = value ? value.find(({ value }) => value === 'ALL') : null;
            const oldValueAll = oldValue ? oldValue.find(({ value }) => value === 'ALL') : null;
            if (valueAll && oldValueAll) {
              // Remove option All
              _this.components.age.value = value.filter(({ value }) => value !== 'ALL');
            } else if (valueAll && !oldValueAll) {
              // Set only option All
              _this.components.age.value = [valueAll];
            }
          }
        },
      },
      adGroupSchedule: {
        fn: (value) => {
          if (value === 'IMMEDIATELY') {
            _this.components.startDateStr.active = false;
            _this.components.startDateStr.value = '';
            _this.components.endDateStr.active = false;
            _this.components.endDateStr.value = '';
            _this.components.endDateStr.disabledDate.to = _this.data.today;
          } else if (value === 'CUSTOM') {
            _this.components.startDateStr.active = true;
            _this.components.endDateStr.active = true;
          }
        },
      },
      // Start Date <=> End Date // Logic
      startDateStr: {
        fn: (value) => {
          const startDate = value;
          const endDate = _this.components.endDateStr.value;
          if (startDate) {
            _this.components.endDateStr.disabledDate.to = new Date(startDate);
            if (endDate && moment(startDate) > moment(endDate)) {
              _this.components.endDateStr.value = '';
            }
          } else {
            _this.components.endDateStr.disabledDate.to = _this.data.today;
          }
        },
      },
    };
  }
}
