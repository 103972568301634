
import { useTrafficSourcesStore } from '@/stores/traffic-sources';
import CampaignsFilter from '@/views/Tools/Reporting/components/CampaignsFilter.ts.vue';
import { ValidationKeys } from '@/views/Tools/Reporting/core/Filters';
import { useReportingStore } from '@/views/Tools/Reporting/store';
import { ReportType } from '@/views/Tools/Reporting/types';
import { FilterType } from '@/views/Tools/Reporting/types/Filter';
import AccountsFooter from '@sh/components/Utils/AccountsFilters/AccountsFooter.ts.vue';
import Checkbox from '@sh/components/Utils/Checkbox.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { getTrafficSourceLogo } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import { Account, SearchFeed, TrafficSource, VueOptiTable } from '@sh/types';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  dateRangeField?: VueOptiTable.Field;
  COMPONENT_ID: string;
  TAGS_LIMIT: number;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: { Checkbox, AccountsFooter, CampaignsFilter, OptimizerIcon },
  mixins: [notifications],
  data(): ComponentData {
    return {
      COMPONENT_ID: 'ReportingFilters',
      TAGS_LIMIT: 4,
      dateRangeField: undefined,
    };
  },
  computed: {
    selectState(): { indeterminate: boolean; allAccountsSelected: boolean } {
      return this.accountFilter?.selectState as { indeterminate: boolean; allAccountsSelected: boolean };
    },
    FilterType() {
      return FilterType;
    },
    filteredTagsBasedOnTs(): string[] {
      return this.accountFilter?.trafficSource
        ? this.getAllAvailableTagsForAccounts([this.accountFilter?.trafficSource])
        : this.getTags;
    },
    tagsLength(): boolean {
      const totalLength = this.filteredTagsBasedOnTs.join('').length;
      return totalLength > 55 && this.filteredTagsBasedOnTs.length > this.TAGS_LIMIT;
    },
    accountFilterItemSlot(): string {
      return `ITEM_BEFORE_${this.accountFilter?.options[0].id}`;
    },
    ...mapState(useReportingStore, [
      'userFilter',
      'trafficSourceComplianceFilter',
      'trafficSourceFilter',
      'reportTypeFilter',
      'accountFilter',
      'campaignFilter',
      'filterOptions',
      'isTableShown',
    ]),
    ...mapWritableState(useReportingStore, ['currentReport']),
    ...mapState(useTrafficSourcesStore, [
      'getAllAvailableTagsForAccounts',
      'getTags',
      'filterState',
      'filterTrafficSourceAccounts',
    ]),
  },
  methods: {
    handleSelectAllAccount(checked: boolean) {
      if (this.accountFilter) {
        this.accountFilter.handleSelectAllAccount(checked);
        this.accountFilter.selectState = { indeterminate: false, allAccountsSelected: checked };
      }
    },
    ...mapActions(useReportingStore, [
      'getCampaigns',
      'getComplianceData',
      'setReportTableColumns',
      'onGenerateReport',
    ]),
    getTrafficSourceLogo,
    hasError(filterType: FilterType, isFormTouched = false) {
      const validationErrorKeys =
        this.currentReport.validationData.error?.details.map((error) => error.context?.key) ?? [];
      return validationErrorKeys.includes(ValidationKeys[filterType]) && isFormTouched;
    },
    async onTrafficSourceTypeChange(option: { uniqueName: TrafficSource | undefined }) {
      if (option.uniqueName !== this.trafficSourceFilter?.value) {
        this.filterState[this.COMPONENT_ID].filterSession.tags = [];
        this.setReportTableColumns(this.currentReport);

        if (this.trafficSourceFilter) {
          this.trafficSourceFilter.value = option.uniqueName ?? null;
        }
        if (this.accountFilter) {
          this.accountFilter.trafficSource = option.uniqueName;
          this.accountFilter.selectState = { indeterminate: false, allAccountsSelected: false };
          this.accountFilter.value = [];
        }
        if (this.reportTypeFilter) {
          this.reportTypeFilter.trafficSource = option.uniqueName;
          this.reportTypeFilter.value = null;
        }
        if (this.campaignFilter) {
          this.campaignFilter.trafficSource = option.uniqueName;
          this.campaignFilter.accountIds = undefined;
          this.campaignFilter.value = [];
        }

        if (option.uniqueName) {
          this.getCampaigns(option.uniqueName, this.currentReport);
        }

        this.currentReport.resetTable();
      }
    },
    onReportTypeChange(option: SelectOption<ReportType>) {
      if (this.reportTypeFilter && this.reportTypeFilter.value !== option.value) {
        this.reportTypeFilter.value = option.value;
        this.setReportTableColumns(this.currentReport);
        this.currentReport.resetTable();
      }
    },
    onAccountsUpdate(selected: Account, checked: boolean) {
      if (this.accountFilter) {
        this.accountFilter.setAccountValuesSelection(selected, checked);
      }
    },
    onAccountsChange() {
      if (this.campaignFilter && this.accountFilter) {
        this.campaignFilter.accountIds = this.accountFilter.value;
        this.campaignFilter.value = [];
      }

      this.currentReport.resetTable();
    },
    async onUserChange(option: Partial<SearchFeed.User>) {
      if (this.userFilter && option.email !== this.userFilter.value) {
        this.userFilter.value = option.email ?? null;

        if (this.userFilter.selectedOption !== null) {
          await this.getComplianceData(
            { id: this.userFilter.userIds?.join(',') ?? '', email: this.userFilter.value ?? '' },
            this.currentReport
          );
        }

        if (this.trafficSourceComplianceFilter) {
          this.trafficSourceComplianceFilter.user = this.userFilter.selectedData ?? null;
          this.trafficSourceComplianceFilter.value = [];
        }

        this.currentReport.resetTable();
      }
    },
    async onTrafficSourceComplianceChange(options: SearchFeed.ComplianceAccount[]) {
      if (this.trafficSourceComplianceFilter) {
        const selected = options.map((option) => option.traffic_source_type);
        this.trafficSourceComplianceFilter.value = selected;
      }
      this.currentReport.resetTable();
    },
    isTagSelected(tag: string) {
      return this.filterState[this.COMPONENT_ID].filterSession.tags?.includes(tag);
    },
    toggleTagsSelection(tag: string) {
      const index = this.filterState[this.COMPONENT_ID].filterSession.tags.indexOf(tag);
      if (index === -1) {
        this.filterState[this.COMPONENT_ID].filterSession.tags.push(tag);
      } else if (index !== -1) {
        this.filterState[this.COMPONENT_ID].filterSession.tags.splice(index, 1);
      }
      if (this.accountFilter) {
        this.accountFilter.selectedAccountsOptions(this.filterState[this.COMPONENT_ID].filterSession.tags);
      }
    },
    ...mapActions(useTrafficSourcesStore, ['registerAccounts', 'initFilters']),
  },
  created() {
    this.initFilters(this.COMPONENT_ID);
    this.registerAccounts();
  },
});
