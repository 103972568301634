
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { timeout } from '@sh/helpers';
import { RuleTypes } from '@sh/types';
import { isEqual, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';

interface ComponentData {
  isDropdownShown: boolean;
}

enum Placeholder {
  CustomInterval = 'Custom Interval Selected',
  Default = 'Select Date Interval',
}

export default defineComponent({
  components: { OptimizerIcon },
  props: {
    options: {
      type: Array as PropType<RuleTypes.ConditionsV2.IntervalPreset[]>,
      required: true,
    },
    interval: {
      type: Array as PropType<RuleTypes.ConditionsV2.DateOperations[]>,
      required: true,
    },
    defaultPlaceholder: String,
  },
  data(): ComponentData {
    return {
      isDropdownShown: false,
    };
  },
  computed: {
    placeholder(): string {
      if (!this.interval.length) {
        return this.defaultPlaceholder ?? Placeholder.Default;
      }

      const selectedOption = this.options.find((option) => isEqual(option.value, this.interval));

      if (selectedOption) {
        return `Considering data from ${selectedOption.text}`;
      }

      return Placeholder.CustomInterval;
    },
  },
  mounted() {
    this.onBackgroundClick = throttle(this.onBackgroundClick, timeout);
    document.addEventListener('mouseup', this.onBackgroundClick);
  },
  destroyed() {
    document.removeEventListener('mouseup', this.onBackgroundClick);
  },
  methods: {
    isEqual,
    onBackgroundClick(event: Event) {
      if (this.isDropdownShown && !(this.$refs?.dropdown as HTMLDivElement)?.contains(event.target as Node)) {
        this.onDropdownShow(false);
      }
    },
    onDropdownShow(value: boolean) {
      this.isDropdownShown = value;
    },
    onIntervalChange(value: RuleTypes.ConditionsV2.DateOperations[]) {
      this.$emit('onIntervalChange', value);
      this.onDropdownShow(false);
    },
  },
});
