export default (F) => ({
  actions: F.create({
    header: { content: 'Actions' },
    item: {
      key: 'actions',
      slot: 'actions',
      sortable: false,
      total: null,
    },
  }),
  'additional_fields.budget': F.create({
    header: { content: 'Total Budget' },
    item: {
      slot: 'additional_fields.budget',
      key: 'additional_fields.budget',
    },
  }),
  'additional_fields.daily_budget': F.create({
    header: { content: 'Daily Budget' },
    item: {
      slot: 'additional_fields.daily_budget',
      key: 'additional_fields.daily_budget',
    },
  }),
  traffic_source_account_name: F.create({
    header: { content: 'Account Name' },
    item: {
      key: 'traffic_source_account_name',
      sortable: true,
      total: null,
    },
  }),
  ts_epc: F.create({
    header: { content: 'TS EPC' },
    item: {
      key: 'ts_epc',
      sortable: true,
    },
  }),
  tr_epc: F.create({
    header: { content: 'TR EPC' },
    item: {
      key: 'tr_epc',
      sortable: true,
    },
  }),
  epc: F.create({
    header: { content: 'EPC' },
    item: {
      key: 'epc',
      sortable: true,
    },
  }),
  'additional_fields.bid': F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'additional_fields.bid',
      key: 'additional_fields.bid',
      sortable: true,
    },
  }),
  'additional_fields.target_cpa': F.create({
    header: { content: 'Target CPA' },
    item: {
      slot: 'additional_fields.target_cpa',
      key: 'additional_fields.target_cpa',
      sortable: true,
    },
  }),
  cpc: F.create({
    header: { content: 'CPC' },
    item: {
      slot: 'cpc',
      key: 'cpc',
      sortable: true,
    },
  }),
  'additional_fields.cpc_desktop': F.create({
    header: { content: 'CPC Desk.' },
    item: {
      slot: 'additional_fields.cpc_desktop',
      key: 'additional_fields.cpc_desktop',
      sortable: true,
    },
  }),
  'additional_fields.cpc_tablet': F.create({
    header: { content: 'CPC Tab.' },
    item: {
      slot: 'additional_fields.cpc_tablet',
      key: 'additional_fields.cpc_tablet',
      sortable: true,
    },
  }),
  'additional_fields.cpc_mobile': F.create({
    header: { content: 'CPC Mob.' },
    item: {
      slot: 'additional_fields.cpc_mobile',
      key: 'additional_fields.cpc_mobile',
      sortable: true,
    },
  }),
  cpc_desktop: F.create({
    header: { content: 'CPC Desk.' },
    item: {
      slot: 'cpc_desktop',
      key: 'cpc_desktop',
      sortable: true,
    },
  }),
  cpc_tablet: F.create({
    header: { content: 'CPC Tab.' },
    item: {
      slot: 'cpc_tablet',
      key: 'cpc_tablet',
      sortable: true,
    },
  }),
  cpc_mobile: F.create({
    header: { content: 'CPC Mob.' },
    item: {
      slot: 'cpc_mobile',
      key: 'cpc_mobile',
      sortable: true,
    },
  }),
  avg_cpc: F.create({
    header: { content: 'Avg. CPC' },
    item: {
      key: 'avg_cpc',
      sortable: true,
    },
  }),
  ctr: F.create({
    header: { content: 'CTR' },
    item: {
      key: 'ctr',
      sortable: true,
    },
  }),
  lp_ctr: F.create({
    header: { content: 'LP CTR' },
    item: {
      key: 'lp_ctr',
      sortable: true,
    },
  }),
  ts_cvr: F.create({
    header: { content: 'TS CVR' },
    item: {
      key: 'ts_cvr',
      sortable: true,
    },
  }),
  tr_cvr: F.create({
    header: { content: 'TR CVR' },
    item: {
      key: 'tr_cvr',
      sortable: true,
    },
  }),
  ts_conversions: F.create({
    header: { content: 'TS Conv.' },
    item: {
      key: 'ts_conversions',
      sortable: true,
    },
  }),
  tr_conversions: F.create({
    header: { content: 'TR Conv.' },
    item: {
      key: 'tr_conversions',
      sortable: true,
    },
  }),
  cost: F.create({
    header: { content: 'Cost' },
    item: {
      key: 'cost',
      sortable: true,
    },
  }),
  est_cost: F.create({
    header: { content: 'Est. Cost' },
    item: {
      key: 'est_cost',
      sortable: true,
    },
  }),
  est_net: F.create({
    header: { content: 'Est. Net' },
    item: {
      key: 'est_net',
      sortable: true,
    },
  }),
  est_roi: F.create({
    header: { content: 'Est. Roi' },
    item: {
      key: 'est_roi',
      sortable: true,
    },
  }),
  ts_revenue: F.create({
    header: { content: 'TS Rev.' },
    item: {
      key: 'ts_revenue',
      sortable: true,
    },
  }),
  tr_revenue: F.create({
    header: { content: 'TS Rev.' },
    item: {
      key: 'tr_revenue',
      sortable: true,
    },
  }),
  ts_net: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'ts_net',
      sortable: true,
    },
  }),
  tr_net: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'tr_net',
      sortable: true,
    },
  }),
  ts_roi: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'ts_roi',
      sortable: true,
    },
  }),
  tr_roi: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'tr_roi',
      sortable: true,
    },
  }),
  ts_cpa: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'ts_cpa',
      sortable: true,
    },
  }),
  tr_cpa: F.create({
    header: { content: 'TS Net.' },
    item: {
      key: 'tr_cpa',
      sortable: true,
    },
  }),
  impressions: F.create({
    header: { content: 'Imp.' },
    item: {
      key: 'impressions',
      sortable: true,
    },
  }),
  ts_clicks: F.create({
    header: { content: 'TS Clicks' },
    item: {
      key: 'ts_clicks',
      sortable: true,
    },
  }),
  tr_clicks: F.create({
    header: { content: 'TS Clicks' },
    item: {
      key: 'tr_clicks',
      sortable: true,
    },
  }),
  lp_clicks: F.create({
    header: { content: 'TS Clicks' },
    item: {
      key: 'lp_clicks',
      sortable: true,
    },
  }),
  'ga:sessions': F.create({
    header: { content: 'Sessions' },
    item: {
      key: 'ga:sessions',
      sortable: true,
    },
  }),
  'ga:bounces': F.create({
    header: { content: 'Bounces' },
    item: {
      key: 'ga:bounces',
      sortable: true,
    },
  }),
  'ga:timeOnPage': F.create({
    header: { content: 'Time on Page' },
    item: {
      key: 'ga:timeOnPage',
      sortable: true,
      total: {
        parse: (value) => {
          const sessions = parseInt(value, 10);
          return sessions > 0 ? sessions : 0;
        },
      },
    },
  }),
  'ga:users': F.create({
    header: { content: 'Users' },
    item: {
      key: 'ga:users',
      sortable: true,
    },
  }),
  'ga:newUsers': F.create({
    header: { content: 'New Users' },
    item: {
      key: 'ga:newUsers',
      sortable: true,
    },
  }),
  'ga:sessionDuration': F.create({
    header: { content: 'Session Duration', style: '' },
    item: {
      key: 'ga:sessionDuration',
      sortable: true,
      total: {
        parse: (value) => {
          const sessions = parseInt(value, 10);
          return sessions > 0 ? sessions : 0;
        },
      },
    },
  }),
  'ga:adsenseRevenue': F.create({
    header: { content: 'AdSense Revenue' },
    item: {
      key: 'ga:adsenseRevenue',
      sortable: true,
    },
  }),
  'ga:adsenseImpressions': F.create({
    header: { content: 'AdSense Impressions' },
    item: {
      key: 'ga:adsenseImpressions',
      sortable: true,
    },
  }),
  'ga:adsensePageImpressions': F.create({
    header: { content: 'AdSense Page Impressions' },
    item: {
      key: 'ga:adsensePageImpressions',
      sortable: true,
    },
  }),
  'ga:adsenseAdUnitsViewed': F.create({
    header: { content: 'AdSense Ad Units Viewed' },
    item: {
      key: 'ga:adsenseAdUnitsViewed',
      sortable: true,
    },
  }),
  'ga:adsenseAdsClicks': F.create({
    header: { content: 'AdSense Ads Clicks' },
    item: {
      key: 'ga:adsenseAdsClicks',
      sortable: true,
    },
  }),
  'ga:entrances': F.create({
    header: { content: 'Entrances' },
    item: {
      key: 'ga:entrances',
      sortable: true,
    },
  }),
  'ga:bounceRate': F.create({
    header: { content: 'Bounce Rate' },
    item: {
      key: 'ga:bounceRate',
      sortable: true,
    },
  }),
  'ga:avgTimeOnPage': F.create({
    header: { content: 'Avg. Time on Page' },
    item: {
      key: 'ga:avgTimeOnPage',
      sortable: true,
      total: {
        parse: (value) => {
          const sessions = parseInt(value, 10);
          return sessions > 0 ? sessions : 0;
        },
      },
    },
  }),
  epac: F.create({
    header: { content: 'EPAC' },
    item: {
      key: 'epac',
      sortable: true,
    },
  }),
  eps: F.create({
    header: { content: 'EPS' },
    item: {
      key: 'eps',
      sortable: true,
    },
  }),
  epv: F.create({
    header: { content: 'EPV' },
    item: {
      key: 'epv',
      sortable: true,
    },
  }),
  custom_metric_1: F.create({
    header: { content: 'Custom Metric 1' },
    display: false,
    item: {
      key: 'custom_metric_1',
      sortable: false,
    },
    customMetric: '',
  }),
  custom_metric_2: F.create({
    header: { content: 'Custom Metric 2' },
    display: false,
    item: {
      key: 'custom_metric_2',
      sortable: false,
    },
    customMetric: '',
  }),
  custom_metric_3: F.create({
    header: { content: 'Custom Metric 3' },
    display: false,
    item: {
      key: 'custom_metric_3',
      sortable: false,
    },
    customMetric: '',
  }),
  custom_metric_4: F.create({
    header: { content: 'Custom Metric 4' },
    display: false,
    item: {
      key: 'custom_metric_4',
      sortable: false,
    },
    customMetric: '',
  }),
  custom_metric_5: F.create({
    header: { content: 'Custom Metric 5' },
    display: false,
    item: {
      key: 'custom_metric_5',
      sortable: false,
    },
    customMetric: '',
  }),
});
