<template>
  <div class="fixed-footer channel-action-bar">
    <b-btn type="primary" class="primary-button" :disabled="loading" @click="$emit('update')">Update</b-btn>
  </div>
</template>

<script>
export default {
  name: 'ChannelActionBar',
  props: {
    loading: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.channel-action-bar {

}
</style>
