import { VueSlider } from '@sh/types';

export const defaultSliderProps: Partial<VueSlider.Component> = {
  dotSize: 14,
  width: 'auto',
  height: 4,
  contained: true,
  direction: 'ltr',
  dataLabel: 'label',
  dataValue: 'value',
  interval: 0.1, // step when moving pointer in ui
  disabled: false,
  clickable: true,
  duration: 0.5,
  adsorb: true,
  lazy: false,
  useKeyboard: true,
  enableCross: true,
  fixed: false,
  order: true,
  tooltip: 'none',
};

// these are slider min and max values used for calculations below
// slider component min and max value may be [-100, 0] for UI purposes
// the calculations below are still valid in this case
// we need only to convert the params to positive numbers when using functions below
// any other values will require adjustments, to not reference variables below directly inside calculation functions
export const min = 0;
export const max = 100;

// marks are not linearly distributed, they will have the same distance between them
export const marks = [0, 3, 7, 14, 30, 45, 60, 90];

// marksInterval represents the distance between marks
export const marksInterval = (max - min) / (marks.length - 1);

export function sliderValueToActualValue(sliderValue: number) {
  const index = Math.floor(sliderValue / marksInterval);

  if (index >= marks.length - 1) {
    return marks[marks.length - 1];
  }

  const valueInterval = marks[index + 1] - marks[index];
  const remainder = sliderValue / marksInterval - index;

  return Math.round(marks[index] + valueInterval * remainder);
}

export function actualValueToSliderValue(actualValue: number) {
  let index = marks.findIndex((mark) => mark > actualValue);

  if (index === -1) {
    return max;
  }

  if (index > 0) {
    index--;
  }

  const valueInterval = marks[index + 1] - marks[index];
  const remainder = (actualValue - marks[index]) / valueInterval;

  return marksInterval * (index + remainder);
}
