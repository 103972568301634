export default {
  chart: {
    theme: 'fusion',
    legendIconSides: '2',
    palettecolors: ['#f8b94c', '#FC5759', '#20a8d8', '#7ac151', '#58EBCA'],
    legendItemFontSize: 13,
    alternateHGridColor: '#00FFAA',
    alternateHGridAlpha: '20',
  },
  tooltip: {
    outputtimeformat: {
      year: '%Y', // %Y - 2018
      month: '%B, %Y', // %B, %Y - September, 2018
      day: '%b %-d, %Y', // %B %-d, %Y - November 11, 2018
      hour: '%b %-d, %Y, %H:%M', // %b %-d, %Y, %-I %p - Nov 5, 2018, 4 PM
      minute: '%b %-d, %Y, %H:%M', // %b %-d, %Y, %-I:%-M %p - Nov 5, 2018, 4:24 PM
      second: '%b %-d, %Y, %H:%M:%S', // %b %-d, %Y, %-I:%-M:%-S %p - Nov 5, 2018, 4:25:30 PM
      millisecond: '%b %-d, %Y, %-I %H:%M:%S:%L', // %b %-d, %Y, %-I:%-M:%-S:%-L %p- Nov 29, 2017, 4:29:12.075 PM
    },
    format: {
      round: 1,
    },
    style: {
      container: {
        'background-color': '#FFFFFF',
        opacity: 0.95,
        border: '2px solid #E1E1E1',
        'border-radius': '2px',
        padding: '6px',
      },
      text: {
        'font-size': '13px',
        color: '#5A5A5A',
      },
      header: {
        color: '#5A5A5A',
        'font-family': 'Source Sans Pro SemiBold',
        padding: '0px',
      },
      body: {
        padding: '0px',
      },
    },
  },
  styledefinition: {
    clicksplot: {
      'stroke-opacity': 0.95,
      'fill-opacity': 0.1,
      stroke: '#f8b94c',
    },
    costplot: {
      'stroke-opacity': 0.95,
      'fill-opacity': 0.1,
      stroke: '#FC5759',
    },
    conversionsplot: {
      'stroke-opacity': 0.95,
      'fill-opacity': 0.1,
      stroke: '#20a8d8',
    },
    revenueplot: {
      'stroke-opacity': 0.95,
      'fill-opacity': 0.1,
      stroke: '#58EBCA',
    },
    eventsplot: {
      'stroke-opacity': 0.95,
      'fill-opacity': 0.1,
      stroke: '#58EBCA',
    },
  },
  navigator: {
    enabled: '0',
  },
  extensions: {
    standardRangeSelector: {
      enabled: '0',
    },
    customRangeSelector: {
      enabled: '0',
    },
  },
  xaxis: {
    plot: 'Performance',
    timemarker: [],
    binning: {
      hour: [1],
      minute: [],
      second: [],
      millisecond: [],
    },
    // outputtimeformat: {
    // year: '%Y', // %Y - 2018
    // month: '%B, %Y', // %B, %Y - September, 2018
    // day: '%B %-d, %Y', // %B %-d, %Y - November 11, 2018
    // hour: '%b %-d, %Y, %-I %H:%M', // %b %-d, %Y, %-I %p - Nov 5, 2018, 4 PM
    // minute: '%b %-d, %Y, %-I %H:%M', // %b %-d, %Y, %-I:%-M %p - Nov 5, 2018, 4:24 PM
    // second: '%b %-d, %Y, %-I %H:%M:%S', // %b %-d, %Y, %-I:%-M:%-S %p - Nov 5, 2018, 4:25:30 PM
    // millisecond: '%b %-d, %Y, %-I %H:%M:%S:%L', // %b %-d, %Y, %-I:%-M:%-S:%-L %p- Nov 29, 2017, 4:29:12.075 PM
    // },
  },
  legend: {
    enabled: '1',
    position: 'bottom-center',
    style: {
      'text-anchor': 'middle',
    },
    item: [{
      value: 'Clicks',
      initiallyhidden: 1,
    }, {
      value: 'Cost',
      initiallyhidden: 0,
    }, {
      value: 'Conversions',
      initiallyhidden: 1,
    }, {
      value: 'Revenue',
      initiallyhidden: 0,
    }, {
      value: 'Events',
      initiallyhidden: 0,
    }],
  },
  yaxis: [
    {
      plot: [
        {
          value: 'Clicks',
          type: 'smooth-area',
          style: {
            area: 'clicksplot',
            'plot.null': { },
            'line.null': {
              stroke: '#f8b94c',
            },
            'area.null': {
              fill: '#f8b94c',
              'fill-opacity': '0.1',
            },
          },
          connectNullData: 1,
        },
        {
          value: 'Cost',
          type: 'smooth-area',
          style: {
            area: 'costplot',
            'area.null': {
              fill: '#FC5759',
              'fill-opacity': '0.1',
            },
          },
          connectNullData: 1,
        },
        {
          value: 'Conversions',
          type: 'smooth-area',
          style: {
            area: 'conversionsplot',
            'area.null': {
              fill: '#20a8d8',
              'fill-opacity': '0.1',
            },
          },
          connectNullData: 1,
        },
        {
          value: 'Revenue',
          type: 'smooth-area',
          style: {
            area: 'revenueplot',
            'area.null': {
              fill: '#58EBCA',
              'fill-opacity': '0.1',
            },
          },
          connectNullData: 1,
        },
        {
          value: 'Events',
          type: 'smooth-area',
          style: {
            area: 'eventsplot',
            'area.null': {
              fill: '#58EBCA',
              'fill-opacity': '0.1',
            },
          },
          connectNullData: 1,
          format: {
            round: '1',
            prefix: '$',
          },
        },
      ],
      type: 'smooth-line',
      title: 'Performance',
      forceDecimals: '1',
    },
  ],
  plotConfig: {

  },
};
