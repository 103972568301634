import { CategoryType, Image } from '@/views/Automation/CampaignCreatorV2/components/Modals/CcAddImageModal/types';
import { api } from '@sh/services/api';
import { MediaManager } from '@sh/types';
import moment from 'moment';

export class Filter {
  isLoading = false;
  totalItems = 0;
  images: Image[] = [];
  payload: MediaManager.RequestTypes.Filters = {
    order: [
      {
        name: 'Newest',
        column: 'updated_at',
        type: 'DESC',
      },
    ],
    customDateRange: {
      startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    page: 1,
    pageSize: 48,
  };

  constructor(public category: CategoryType, data: MediaManager.RequestTypes.Filters, public visible = false) {
    this.payload = {
      ...this.payload,
      ...data,
    };
  }

  async getImages(): Promise<{ images: Image[]; total: number }> {
    const data = await api.mediaManager.getData(MediaManager.Mode.IMAGES, { filters: this.payload });

    return {
      images: data.contents
        .filter((img) => !img?.image_format?.includes('video'))
        .map((image) => ({
          image_url: image.image_url,
          width: image.image_width,
          height: image.image_height,
          size: image.image_size,
          category: this.category,
          selected: false,
          id: image.id ?? image.image_url,
        })),
      total: data.total_items,
    };
  }

  async fetchImages() {
    this.isLoading = true;
    const data = await this.getImages();
    this.images = data.images;
    this.totalItems = data.total;
    this.isLoading = false;
  }
}
