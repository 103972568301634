import { DropDown, Metrics } from '@sh/types/MediaManager/MetricFilter';

export interface Settings {
  uniqueTrafficSources: TrafficSource[];
  accounts: Account[];
  uniqueTags: string[];
  metricsDropdown: DropDown[];
  orderDropDown: OrderDropDown[];
  orderDropDownInitial: OrderDropDown[];
}

export interface OrderDropDown {
  name: string;
  column: string;
  type: string;
}

export interface TrafficSource {
  name: string;
  imagePath: string;
}

export interface Account {
  name: string;
  value: number;
  imagePath: string;
  traffic_source_unique_name: string;
}

export interface Content extends Metrics {
  traffic_source_item_id: string;
  id: string;
  metadata: string;
  traffic_source_unique_name: string;
  traffic_source_item_type: string;
  status?: number;
  headline: string;
  image_url: string;
  image_format: string;
  image_width: number;
  image_height: number;
  image_size: number;
  tags: string[];
  is_editable: number;
  variations: string;
  usedOn: number;
}

export interface Insert {
  data: Content;
  status: StatusMessage;
  message: StatusMessage;
}

export enum StatusMessage {
  SUCCESS = 'Success',
  SUCCESS_MESSAGE = 'Content is upserted successfully',
  UPDATE_INITIALIZE = 'Initialize of upsert has been started',
  FAIL = 'FAIL',
  FAIL_MESSAGE = 'Content is not upserted successfully',
  NOT_FOUND = 'NO CONTENT FOUNDED WITH THOSE PARAMS',
  REFERENCED_ERROR = 'preview of an existing ad',
}

export interface CampaignData {
  campaing_name: string;
  campaing_id: string;
  campaing_url: string;
}

export interface Preview {
  iframe: string;
  preview_link?: string;
}
