<template>
  <div v-if="!preloader" class="traffic-source-wraper">
    <!-- Alert Message -->
    <b-alert
      :show="!!alert.message"
      :variant="alert.type"
      dismissible
      @dismissed="$_dismissAlert()"
    >
      <div v-html="alert.message"></div>
    </b-alert>

    <div class="row">
      <div class="col-md-6">
        <!-- Type Select -->

        <b-form-group label="Traffic Source Type" class="optimizer-form-group">
          <vue-opti-select-light
            class="optimizer-select w-100 mb-3"
            name="type"
            :disabled="mode.action !== 'create'"
            v-model="form.type"
            :options="$c_trafficSourceTypes"
            unique-key="id"
            label-key="name"
            single
            button-block
            button-placeholder="Select Traffic Source Type"
            @change="(item) => $_render(item.uniqueName || null)"
          >
            <template v-for="item in $c_disabledTsTypes" v-slot:[`ITEM_BEFORE_${item.id}`]>
              <span
                :key="item.id"
                class="option-tooltip not-allowed"
                v-b-tooltip.hover.bottom
                title="To use this integration please upgrade your plan"
              />
            </template>
            <template #ITEM="{ option }">
              <img
                class="m-0 new-icon"
                :src="getTrafficSourceLogo(option.uniqueName)"
                alt="traffic-source-icon"
              />
              <div class="ml-8 option-text">
                {{ option.name }}
              </div>
            </template>
          </vue-opti-select-light>
        </b-form-group>
      </div>
      <template v-if="$_formModel">
        <div class="col-md-6" v-if="!dontShowNicknameTs.includes(form.type.uniqueName)">
          <!-- Account Name -->
          <b-form-group
            class="optimizer-form-group form-group-info-link"
            :invalid-feedback="$_getError('name')"
            :state="$_hasError('name')"
          >
            <template slot="label">
              Nickname
              <a
                v-if="$_formModel.infoLink"
                :href="$_formModel.infoLink"
                target="_blank"
                class="info-link"
              >How to properly setup {{ form.type.name }} ?</a>
            </template>

            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Account Nickname"
              :state="$_hasError('name')"
              v-validate="'required'"
              type="text"
              v-model="form.name"
              name="name"
              key="name"
              placeholder="Account Nickname"
            />
          </b-form-group>
        </div>

        <div
          class="col-md-6"
          v-for="component in $_formModel.components"
          :key="`${form.type.uniqueName}-${component.name}`"
        >

          <template
            v-if="
              component.type === 'text' ||
                component.type === 'password' ||
                component.type === 'number'
            "
          >
            <!-- Input Text -->
            <b-form-group
              class="optimizer-form-group"
              :description="component.description || null"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <b-form-input
                class="optimizer-form-input"
                :data-vv-as="component.label"
                :state="$_hasError(component.name)"
                v-validate="component.validate"
                :type="component.type"
                v-model="form.credentials[component.name]"
                :name="component.name"
                :placeholder="`Insert ${component.label}`"
                autocomplete="new-password"
              />
            </b-form-group>
          </template>

          <template v-else-if="component.type === 'select'">
            <!-- Input Select -->
            <b-form-group
              class="optimizer-form-group"
              :description="component.description || null"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <b-form-select
                :data-vv-as="component.label"
                :state="$_hasError(component.name)"
                v-validate="component.validate"
                v-model="form.credentials[component.name]"
                :name="component.name"
                :options="component.options"
              />
            </b-form-group>
          </template>

          <template v-else-if="component.type === 'timezone'">
            <!-- Input Timezone -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :name="component.name"
                :value="form.settings[component.name]"
                @change="
                  (option) => {
                    form.settings[component.name] = option.value;
                  }
                "
                :options="$c_timezones"
                button-placeholder="Select timezone"
                single
                searchable
                button-block
              />
            </b-form-group>
          </template>

          <template v-else-if="component.type === 'amazonRegion'">
            <!-- Input Timezone -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :name="component.name"
                :value="form.settings[component.name]"
                @change="
                  (option) => {
                    form.settings[component.name] = option.value;
                  }
                "
                :options="component.options"
                button-placeholder="Select your region"
                single
                button-block
              />
            </b-form-group>
          </template>

          <template v-if="component.name === 'MediaGoTimezone'">
            <!-- MediaGo Input Timezone -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :name="component.name"
                :value="form.settings['timezone']"
                @change="
                  (option) => {
                    form.settings['timezone'] = option.value;
                  }
                "
                :options="component.options"
                button-placeholder="Select timezone"
                single
                button-block
              />
            </b-form-group>
          </template>

          <template v-else-if="component.type === 'currency'">
            <!-- Input Currency -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                unique-key="code"
                label-key="currency"
                :name="component.name"
                :value="form.settings[component.name]"
                @change="
                  (option) => {
                    form.settings[component.name] = option.code;
                  }
                "
                :options="rawData.currencies"
                :search-fields="['code', 'currency']"
                button-placeholder="Select currency"
                single
                searchable
                button-block
              />
            </b-form-group>
          </template>

          <template v-else-if="component.type === 'account'">
            <!-- Input Account -->
            <b-form-group
              class="optimizer-form-group"
              v-if="
                mode.action === 'create' &&
                  $_formModel.accountCredentials.reduce(
                    (total, key) => total && !!form.credentials[key],
                    true
                  )
              "
              :description="component.description || null"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <b-btn
                :disabled="accountLoading"
                v-if="rawData.sourceAccounts.length === 0"
                block
                class="secondary-button"
                @click="$_loadAccounts"
              >
                <i :class="[{ 'fa-spin': accountLoading }, 'fa fa-refresh']" aria-hidden="true" />
                Load Accounts
              </b-btn>
              <vue-opti-select-light
                v-else
                class="optimizer-select w-100"
                option-type="checkbox"
                v-model="form.credentials[component.name]"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                :data-vv-as="component.label"
                :unique-key="$_formModel.accountKey || 'value'"
                :name="component.name"
                :options="rawData.allAccounts"
                :search-fields="['name', 'value']"
                labelKey="name"
                button-placeholder="Select account"
                debounce
                searchable
                button-block
                lazy
                lazyRender
                @change="(account) => $_selectedAccount(account)"
              />
            </b-form-group>

            <b-form-group v-else-if="mode.action === 'edit'" class="optimizer-form-group">
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <b-form-input
                class="optimizer-form-input"
                disabled
                readonly
                type="text"
                :value="form.credentials[component.name].name || ''"
              />
            </b-form-group>
          </template>

          <template v-if="component.name === 'detectedCurrency' && $c_isEditMode">
            <!-- Detected Currency -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                :disabled="$c_disableCurrency"
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                unique-key="code"
                label-key="currency"
                v-model="form.settings['currency']"
                :options="rawData.currencies"
                :search-fields="['code', 'currency']"
                button-placeholder="Select currency"
                single
                searchable
                button-block
              />
            </b-form-group>
          </template>

          <template v-if="component.name === 'detectedTimezone' && $c_isEditMode">
            <!-- Detected Timezone -->
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :name="component.name"
                v-model="form.settings['timezone']"
                :options="$c_timezones"
                button-placeholder="Select timezone"
                single
                searchable
                button-block
              />
            </b-form-group>
          </template>

          <template v-if="component.name === 'detectedTsName'">
            <!-- Detected Account Name -->
            <b-form-group
              class="optimizer-form-group form-group-info-link"
              :invalid-feedback="$_getError('name')"
              :state="$_hasError('name')"
            >
              <template slot="label">
                Nickname
                <a
                  v-if="$_formModel.infoLink"
                  :href="$_formModel.infoLink"
                  target="_blank"
                  class="info-link"
                >How to properly setup {{ form.type.name }} ?</a>
              </template>

              <b-form-input
                class="optimizer-form-input"
                data-vv-as="Account Nickname"
                :disabled="!$c_isEditMode"
                :state="$_hasError('name')"
                v-validate="'required'"
                type="text"
                v-model="form.name"
                name="name"
                key="name"
                placeholder="Account Nickname"
              />
            </b-form-group>
          </template>

          <template
            v-if="
              component.name === 'outbrainTimezone' &&
                (($c_hasAccountSelected && rawData.sourceAccounts.length > 0) || $c_isEditMode)
            "
          >
            <!-- Outbrain Timezone -->
            <b-form-group
              class="optimizer-form-group"
              :description="!$c_isEditMode ? component.description : ''"
              :invalid-feedback="$_getError(component.name)"
              :state="$_hasError(component.name)"
            >
              <template slot="label">
                {{ component.title || component.label
                }}<i
                  v-if="component.info"
                  class="fa fa-info-circle field-info"
                  v-b-tooltip.hover
                  :title="component.info"
                />
              </template>
              <vue-opti-select-light
                class="optimizer-select w-100 mb-0"
                :data-vv-as="component.label"
                v-validate="component.validate"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :name="component.name"
                v-model="form.settings['timezone']"
                :options="$c_timezones"
                button-placeholder="Select timezone"
                single
                searchable
                button-block
              />
            </b-form-group>
          </template>
        </div>
        <div class="col-12 linked-trackers" v-if="$c_isEditMode">
          <hr />
          <div class="linked-trackers-header" v-if="rawData.account.linkedTrackers.length > 0">
            <h3>Linked Trackers</h3>
          </div>
          <span v-else><i class="text-muted">( No linked trackers )</i></span>
          <div
            class="container"
            v-for="(item, index) in $c_linkedTrackers"
            :key="index"
          >
            <div class="linked-trackers-buttons">
              <b-button
                :to="
                  '/account-wizard?step=2&ts=' +
                    rawData.account.id +
                    '&tr=' +
                    item.id +
                    '&id=' +
                    item.sourceIdOnTracker
                "
                class="btn btn-secondary actions-icon-container edit-icon"
              ><i class="fa fa-wrench" /></b-button>
              <button
                class="btn btn-secondary actions-icon-container edit-icon"
                @click="$_unlinkTracker(item, index)"
              >
                <i class="fa fa-trash" />
              </button>
            </div>
            <div class="tracker-name optimizer-form-input px-10">
              <div class="text-nowrap overflow-auto">
                {{ item.displayName }}
              </div>
              <img :src="'/sh_static/accounts/' + item.uniqueName + '.png'" alt="" />
            </div>
            <div class="tracker-tokens">
              <b-dropdown
                v-if="!specificTrackers.includes(item.uniqueName)"
                id="dropdown-1"
                text="Token mapping"
                size="lg"
                variant="outline-dark"
              >
                <span v-for="(token, tokenIndex) in item.variables[0]" :key="tokenIndex">
                  <b-dropdown-item>
                    <p>
                      <span class="green" v-html="reverseTokens.includes(item.uniqueName) ? `${tokenIndex}: ` : `${token}: `"></span>
                      <span v-html="reverseTokens.includes(item.uniqueName) ? token : tokenIndex"></span>
                    </p>
                  </b-dropdown-item>
                </span>
              </b-dropdown>
              <b-dropdown
                v-if="specificTrackers.includes(item.uniqueName)"
                id="dropdown-1"
                text="Token mapping"
                size="lg"
                variant="outline-dark"
              >
                <span v-for="(token, tokenIndex) in item.variables[0]" :key="tokenIndex">
                  <b-dropdown-item v-if="trafficSourceTokens.includes(tokenIndex)">
                    <p>
                      <span class="green">{{ tokenIndex }}</span> :
                      <span>{{ token }}</span>
                    </p>
                  </b-dropdown-item>
                </span>
              </b-dropdown>
            </div>
            <SearchFeedTrackingUrl v-if="searchFeeds.includes(item.uniqueName)" :item="item" mode="TrafficSources" />
            <TrackingUrl v-else-if="specificTrackers.includes(item.uniqueName)" :trackers="$c_linkedTrackers" :item="item" mode="TrafficSources" type="Tracker" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import notifications from "@sh/mixins/notifications";
import configFile from "./config";
import moment from "moment-timezone";
import currencies from "currency-codes/data";
import each from 'each.js';
import { canAccess, getTrafficSourceLogo } from '@sh/helpers';
import TrackingUrl from "./TrackingUrl";
import SearchFeedTrackingUrl from './SearchFeedTrackingUrl';

export default {
  name: "TrafficSource",
  components: {
    TrackingUrl,
    SearchFeedTrackingUrl,
  },
  mixins: [notifications],
  props: {
    mode: { type: Object, default: () => ({ action: "create", accountId: null, account: null }) }, // action: create|edit, accountId: null|Number
    submitBtn: { type: Boolean, default: false },
    trafficSourceTokens: { type: Array, default: () => [] },
  },
  data() {
    return {
      preloader: true,
      config: null,
      rawData: {
        types: [],
        account: null,
        timezones: moment.tz.names(),
        currencies,
        sourceAccounts: [],
      },
      form: {
        type: null,
        name: "",
        credentials: {},
        settings: {},
      },
      alert: {
        type: "danger",
        message: "",
      },
      watchList: {},
      accountLoading: false,
      searchFeeds: ["Tonic", "Sedo", "Bodis", "DomainActive", "System1", "AssertiveYield", "GoogleAnalytics", 'GoogleAnalytics4', 'Ads', 'Media'],
      reverseTokens: ["GoogleAnalytics"],
      dontShowNicknameTs: ["Taboola", "Outbrain", "YahooDSP"],
      specificTrackers: ['ClickFlare', 'Voluum'],
    };
  },
  computed: {
    $c_trafficSourceTypes() {
      return this.rawData.types.sort((first, second) => first.name.localeCompare(second.name));
    },
    $c_timezones() {
      return this.rawData.timezones.map((value) => ({ value, content: value }));
    },
    $_formModel() {
      try {
        return this.config.model[this.form.type.uniqueName] || null;
      } catch (err) {
        return null;
      }
    },
    $_accountOptions() {
      return this.rawData.sourceAccounts;
    },
    $c_disabledTsTypes() {
      return this.rawData.types.filter((item) => item.disabled);
    },
    $c_hasAccountSelected() {
      return this.form.credentials["account"];
    },
    $c_isEditMode() {
      return this.mode.action === "edit";
    },
    $c_disableCurrency() {
      if (this.mode.action === "edit" && this.form.settings["currency"] !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    $c_isOutbrain() {
      if (
        this.mode.action === "create" &&
        this.form.name === "Outbrain" &&
        $c_hasAccountSelected &&
        rawData.sourceAccounts.length > 0
      ) {
        return true;
      }
    },
    $c_linkedTrackers() {
      return this.rawData.account?.linkedTrackers ?? [];
    },
  },
  async created() {
    this.preloader = true;
    try {
      await this.$_init();
    } catch (error) {
      console.log(error);
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: "An error occurred" });
      }
    }
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    async $_init() {
      /** ***************** Load Config **************** */
      this.config = await configFile.getConfig();
      /** *********************************************** */


      /** ******** Resolve Traffic Source Type ********* */
      if (this.mode.action === "create") {
        this.rawData.types = await this.config.api.trafficSources.types();
        this.rawData.types = this.rawData.types.filter((item) => item.status);
        const tsTypes = this.$session.getUser().allowed_ts_types;
        //TEMPORARY FOR BETA TESTING
        // const userBeta = [2, 112479, 176874995];
        // const tsUserId = this.$session.getUser().user_id;
        // if (!userBeta.includes(tsUserId)) {
        //   this.rawData.types = this.rawData.types.filter((item) => {
        //     return item.uniqueName != "GoogleAds";
        //   });
        // }
        this.$_trafficSourcePermission();

        const tsTypesArr = tsTypes
          .split(",")
          .map((x) => x.trim())
          .filter((x) => x.length);
        if (tsTypes && tsTypesArr.length > 0) {
          this.rawData.types.forEach(
            (item) => (item.disabled = !tsTypesArr.includes(item.uniqueName))
          );
          this.rawData.types.sort((b, a) => Number(!a.disabled) - Number(!b.disabled));
        }
      } else {
        const resolveAccount = () =>
          this.mode.account || this.config.api.trafficSources.account(this.mode.accountId);
        [this.rawData.types, this.rawData.account] = await Promise.all([
          this.config.api.trafficSources.types(),
          resolveAccount(),
        ]);
      }
      /** *********************************************** */

      /** ****************** Render ******************** */
      if (this.rawData.account) {
        this.rawData.account.linkedTrackers = this.rawData.account.linkedTrackers.map((item) => ({
          ...item,
          displayName: this.getLinkedTrackerDisplayName(item),
          isSearchFeed: this.searchFeeds.includes(item.uniqueName) ? 1 : 0,
        })).sort((a, b) => a.isSearchFeed - b.isSearchFeed) ?? [];

        this.form.type = this.rawData.account.type;
        this.form.name = this.rawData.account.name;
        this.$_render(
          this.form.type.uniqueName,
          this.rawData.account.credentials,
          this.rawData.account.settings
        );
      } else {
        this.form.type = this.rawData.types[0];
        this.$_render(this.form.type.uniqueName);
      }
      /** *********************************************** */

      this.$emit("ready");
    },
    $_render(uniqueName, credentials = null, settings = null) {
      const model = this.config.model[uniqueName];

      /** ******************* Reset ******************** */
      this.form.credentials = {};
      this.form.settings = {};
      model.components.forEach((component) => {
        if (
          component.type === "text" ||
          component.type === "password" ||
          component.type === "select" ||
          component.type === "number"
        ) {
          this.$set(this.form.credentials, component.name, component.value);
        } else if (component.type === "currency" || component.type === "timezone") {
          this.$set(this.form.settings, component.name, component.value);
        } else if (component.type === "account") {
          this.$set(this.form.credentials, component.name, component.value);
        } else if (component.type === "mediaGoTimezone") {
          this.$set(this.form.settings, "timezone", component.value);
        } else if (component.type === "detectedTimezone" || component.type === "detectedCurrency") {
          this.$_selectedAccount(settings);
        }
      });
      /** *********************************************** */

      /** **************** Set Values ****************** */
      if (credentials) {
        // Set Credentials
        Object.keys(this.form.credentials).forEach((key) => {
          if (typeof credentials[key] !== "undefined")
            this.form.credentials[key] = credentials[key];
        });
      }
      if (settings) {
        // Set Settings
        Object.keys(this.form.settings).forEach((key) => {
          if (typeof settings[key] !== "undefined")
            this.form.settings[key] = this.form.settings[key] || settings[key];
        });
      }

      // Set Defaults
      if (!this.form.settings.timezone) this.form.settings.timezone = this.form.type.timezone;
      /** *********************************************** */

      /** ************** Bind Account ****************** */
      if (this.mode.action === "create") {
        // Reset watch List
        Object.entries(this.watchList).forEach(([key, unWatch]) => {
          unWatch();
          delete this.watchList[key];
        });
        // Watch reset account
        if (model.accountCredentials) {
          model.accountCredentials.forEach((key) => {
            this.watchList[key] = this.$watch(`form.credentials.${key}`, (val) => {
              // Reset accounts
              this.form.credentials.account = null;
              this.rawData.sourceAccounts = [];
            });
          });
        }
      }
      /** *********************************************** */
      this.$emit("changeTrafficSourceType", this.form.type.uniqueName);
    },
    getLinkedTrackerDisplayName(item) {
      const name = item.name.trim();
      const propertyName = item.metrics?.propertyName?.trim();

      if (propertyName && item.isPropertyName) {
        return `${name} (${propertyName})`;
      }
      return name;
    },
    async $_loadAccounts() {
      this.accountLoading = true;
      /** **************** Payload ********************* */
      const typeName = this.form.type.uniqueName;
      const credentials = {};
      this.$_formModel.accountCredentials.forEach((key) => {
        credentials[key] = this.form.credentials[key];
      });
      const payload = { typeName, credentials };
      /** *********************************************** */
      try {
        const accounts = await this.config.api.trafficSources.accountsName(payload);
        accounts.forEach((account) => {
          if (account.type === 'NETWORK') {
            account.disabled = true;
          } else {
            account.disabled = false;
          }
        })
        this.rawData.allAccounts=accounts;
        this.rawData.sourceAccounts = accounts;
        this.$_dismissAlert();
      } catch (error) {
        try {
          if (error.response.data.statusCode !== 500) {
            // Wrong Credentials
            if (this.$_formModel.accountError) {
              if (this.$_formModel.accountError[error.response.data.statusCode]) {
                this.$_showAlert(
                  "danger",
                  this.$_formModel.accountError[error.response.data.statusCode]
                );
              } else {
                this.$_showAlert("danger", this.$_formModel.accountError.default);
              }
            } else {
              this.$_showAlert("danger", error.response.data.message);
            }
          } else {
            this.$_showAlert("danger", "Failed to load accounts");
          }
        } catch (err) {
          this.$_showAlert("danger", "Failed to load accounts");
        }
      }
      this.accountLoading = false;
    },
  async $_trafficSourcePermission() {
      const tsUnleash = ["YahooDSP", "GoogleAds"];
      const tsPermissionFalse = [];
      for (const ts of tsUnleash) {
        if (!canAccess(ts)) {
          tsPermissionFalse.push(ts);
        }
      }
      this.rawData.types = this.rawData.types.filter(
        (item) => !tsPermissionFalse.includes(item.uniqueName)
      );
    },
    $_selectedAccount(options) {
      if (options?.timezone) {
        this.form.settings["timezone"] = { label: options.timezone, value: options.timezone };
      } else {
        this.form.settings["timezone"] = { label: "America/New_York", value: "America/New_York" };
      }
      if (options?.currency) {
        const predefinedCurrency = currencies.find((c) => c.code === options.currency);
        if (predefinedCurrency) this.form.settings["currency"] = predefinedCurrency;
      }
    },
    async save() {
      this.$_dismissAlert();
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          if (this.$_formModel.submitUrl) {
            // Redirect
            if (this.mode.action === "create") {
              window.location.href = this.$_formModel.submitUrl.create(this.form);
            } else {
              // encode account name making sure special parameters like &=.. are escaped
              const formData = this.form;
              const name = encodeURIComponent(formData.name);
              window.location.href = this.$_formModel.submitUrl.update(
                { ...formData, name },
                this.rawData.account
              );
            }
            return true;
          }

          try {
            const multiCreateAccountTrafficSources = ["Taboola", "Outbrain","YahooDSP"];
            const createdAccounts = []; // Only for taboola and outbrain

            const payload = {
              name: this.form.name,
              credentials: this.form.credentials,
              settings: this.form.settings,
            };
            let multiCreateAccountTrafficSourcesPayload = {}; // payload only for create traffic source

            let request = null;
            if (this.mode.action === "create") {
              // Create
              payload.typeName = this.form.type.uniqueName;
              payload.typeId = this.form.type.id.toString();
              if (this.$_formModel.onSubmitTransform) {
                payload.credentials = this.$_formModel.onSubmitTransform(
                  payload.credentials,
                  "create"
                );
              }
              if (multiCreateAccountTrafficSources.includes(this.form.type.uniqueName)) {
                await each.parallel(this.form.credentials.account, async (acc) => {
                  multiCreateAccountTrafficSourcesPayload = {
                    name: acc.name,
                    credentials: {
                      ...this.form.credentials,
                      account: { id: acc.id, name: acc.name, value: acc.value },
                    },
                    settings: {
                      timezone:
                        this.form.type.uniqueName === "Outbrain"
                          ? this.form.settings.timezone.value
                          : acc.timezone,
                      currency: acc.currency,
                    },
                    typeName: this.form.type.uniqueName,
                    typeId: this.form.type.id.toString(),
                  };
                  request = await this.config.api.trafficSources.create(multiCreateAccountTrafficSourcesPayload);
                  createdAccounts.push(request);
                })
              } else {
                request = await this.config.api.trafficSources.create(payload);
                this.$_showAlert("success", "Account successfully created !");
              }
            } else {
              // Update
              // if (this.rawData.account.settings) payload.settings = this.rawData.account.settings
              const multiCreateAccountTrafficSourcesPayloadForUpdate = {
                  name: this.form.name,
                  credentials: {
                    ...this.form.credentials,
                  },
                  settings: {
                    timezone: this.form.settings.timezone.value,
                    currency: this.form.settings.currency.code,
                  },
                  typeName: this.form.type.uniqueName,
                  typeId: this.form.type.id.toString(),
              } // payload only when updating a ts
              if (this.$_formModel.onSubmitTransform) {
                payload.credentials = this.$_formModel.onSubmitTransform(
                  payload.credentials,
                  "update"
                );
                multiCreateAccountTrafficSourcesPayloadForUpdate.credentials = this.$_formModel.onSubmitTransform(
                  multiCreateAccountTrafficSourcesPayloadForUpdate.credentials,
                  "update"
                );
              }
              if (multiCreateAccountTrafficSources.includes(this.form.type.uniqueName)) {
                request = await this.config.api.trafficSources.update(this.rawData.account.id, multiCreateAccountTrafficSourcesPayloadForUpdate);
                this.$_showAlert("success", "Account successfully updated !");
              } else {
                request = await this.config.api.trafficSources.update(
                  this.rawData.account.id,
                  payload
                );
                this.$_showAlert("success", "Account successfully updated !");
              }
            }

            if (multiCreateAccountTrafficSources.includes(this.form.type.uniqueName) && this.mode.action === "create") {
             this.$emit("save", createdAccounts);
              return createdAccounts;
            } else {
              this.$emit("save", request);
              return request;
            }
          } catch (error) {
            console.log({ error });
            try {
              this.$_showAlert("danger", error.response.data.message);
            } catch (err) {
              this.$_showAlert("danger", "An error occurred");
            }
            return false;
          }
        } else {
          this.$_showAlert("warning", "Validation fail");
          return false;
        }
      } catch (error) {
        this.$_showAlert("danger", "An error occurred");
        return false;
      }
    },
    async delete() {
      try {
        const response = await this.config.api.trafficSources.delete(this.rawData.account.id);
        if (response) {
          this.$_showAlert("success", "Account successfully deleted !");
          return true;
        }
        this.$_showAlert("danger", "Deleting account failed !");
        return false;
      } catch (error) {
        this.$_showAlert("danger", "An error occurred");
        return false;
      }
    },
    async bulkCreateTrafficSources(payload) {
      const request = await this.config.api.trafficSources.create(payload);
      return request;
    },
    reset() {
      this.form.name = "";
      this.$_render(this.form.type.uniqueName);
      this.$_dismissAlert();
      this.$validator.reset();
    },
    async $_unlinkTracker(item, index) {
      try {
        const swal = await this.$swal({
          title: `Unlink "${item.name}" tracker ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unlink it!",
          cancelButtonText: "No, keep it",
        });
        if (swal.value === true) {
          try {
            const response = await this.config.api.trafficSources.unlinkTracker(
              this.rawData.account.id.toString(),
              item.id.toString(),
              item.sourceIdOnTracker
            );
            if (response.status === 1) {
              this.rawData.account.linkedTrackers.splice(index, 1);
              this.onTableUpdate();
              this.$n_successNotification({ title: response.message });
            } else {
              this.$n_failNotification({ title: response.message });
            }
          } catch {
            this.$n_failNotification({ title: "Unlink Tracker failed" });
          }
        }
      } catch (value) {}
    },
    $_showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
    },
    $_dismissAlert() {
      this.alert.message = "";
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_hasAccountSelected(account) {
      return !!account;
    },
    onTableUpdate() {
      this.$apiStore.trafficSources.accounts.clearCache();
      this.$apiStore.trafficSources.accounts();
      this.$emit("onTableUpdate");
    }
  },
};
</script>

<style lang="scss">
.traffic-source-wraper {
  .alert {
    margin-bottom: 2.5rem;

    .close {
      font-size: 2.5rem;
    }
  }

  .optimizer-form-group {
    margin-top: 2.5rem;
    margin-bottom: 0;
  }

  .row {
    [class^="col-"] {
      &:nth-of-type(-n + 2) {
        .optimizer-form-group {
          margin-top: 0;
        }
      }
    }
  }

  .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px;
  }
  .row-footer {
    margin-bottom: 20px;
  }
  .v-select {
    .vs__dropdown-menu {
      z-index: 9999999;
    }
  }
  .form-group {
    .field-info {
      margin-left: 5px;
    }
  }

  .linked-tracker-dropdown {
    &:first-of-type {
      margin-left: 0 !important;
    }

    .btn.dropdown-toggle {
      background: $color-white;
      border: 0.1rem solid #e5e5e5;
      border-radius: 0.6rem;
      color: $black-500;
      font-size: 1.4rem;
      padding: 0.7rem 1.2rem;
      min-width: 10rem;
    }

    .dropdown-menu {
      padding: 1rem;
      background: $color-white;
      border: none;
      box-shadow: $bs-lg;
      border-radius: 0.6rem;
      color: $color-dark;

      li {
        a {
          display: flex;
          align-items: center;
          padding: 0.6rem 1.5rem 0.6rem 1.2rem;
          min-width: 12rem;
          border-radius: 0.4rem;
          color: $black-500;
          font-size: 1.4rem;

          &:active,
          &:focus {
            background: $color-light;
          }

          &:hover {
            color: $color-dark;
            background: $color-light;
          }

          i {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .linked-trackers {
    &-header {
      margin: 1rem;
    }

    .container {
      display: flex;
      align-items: center;
      gap: 1rem;

      .tracker-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.6rem;
        width: 200px;

        img {
          width: 2rem;
          height: 2rem;
        }
      }

      .clipboard-container {
        width: calc(100% - 41rem);
      }

      .linked-trackers-buttons {
        display: flex;
        gap: 0.3rem;

        i {
          color: $color-black;
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
