/* eslint-disable import/no-cycle */
import {
  ABGroupMetadata,
  InternalABVariable,
  SupportedEntities,
} from '@/views/Automation/CampaignCreatorV2//store/Types';
import { FieldConfiguration } from '@/views/Automation/CampaignCreatorV2//validation/ValidationTypes';
import { ABBase, AbTestConstraintFn } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { AbVariable } from '@/views/Automation/CampaignCreatorV2/ab/core/AbVariable';
import { GroupABEntity } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABEntity';
import { GroupABEntityCombiner } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABEntityCombiner';
import { GroupABTest } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABTest';
import { GroupABTestJoiner } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABTestJoiner';
import { ABValue } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import uid from 'uid';

function getConstraintByIdFunction(constraintById: string[][]): AbTestConstraintFn {
  return (abVariables) => {
    if (!constraintById.length) return false;
    return constraintById.some((idGroup) =>
      idGroup?.every((id) => abVariables.some((abVariable) => abVariable.variableUniquePath === id))
    );
  };
}
export class ABRegistry {
  private AB_GROUP_REGISTRY: Record<string, Map<string, ABBase<any>>> = {};
  private SECOND_LAYER_GROUPS: Record<string, Map<string, GroupABTestJoiner<any, SupportedEntities>>> = {};

  private AB_VARIABLE_REGISTRY: Record<string, Map<string, AbVariable<any>>> = {};

  private MAIN_GROUP_ID: Record<string, string> = {};

  private DEFAULT_GROUP_ID: Record<string, string> = {};

  private HIDDEN_GROUP_REGISTRY: Record<string, Record<string, string>> = {};

  private ENTITY_ADDRESS: Record<string, { entityId: string; groupId: string }> = {};

  private readonly ENTITY_COMBINER_PROPERTY = 'ENTITY-COMBINER';

  private clearEntity(entityId: string) {
    delete this.AB_GROUP_REGISTRY[entityId];
    delete this.AB_VARIABLE_REGISTRY[entityId];
    delete this.SECOND_LAYER_GROUPS[entityId];
    delete this.MAIN_GROUP_ID[entityId];
    delete this.DEFAULT_GROUP_ID[entityId];
    delete this.HIDDEN_GROUP_REGISTRY[entityId];
    const allParentKeys = this.AB_GROUP_REGISTRY[this.ENTITY_COMBINER_PROPERTY]?.keys();
    for (const key of allParentKeys || []) {
      if (key.includes(entityId)) {
        delete this.ENTITY_ADDRESS[key];
        this.AB_GROUP_REGISTRY[this.ENTITY_COMBINER_PROPERTY].delete(key);
      }
    }
  }

  public deleteEntity(entityId: string) {
    this.clearEntity(entityId);
  }

  private registerGroupJoiner(entityKey: string, group: GroupABTestJoiner<any, SupportedEntities>, entityId: string) {
    if (!this.SECOND_LAYER_GROUPS[entityId]) {
      this.SECOND_LAYER_GROUPS[entityId] = new Map();
    }
    const entityMap = this.SECOND_LAYER_GROUPS[entityId];
    if (!entityMap) {
      throw new Error('Entity not found');
    }
    entityMap.set(entityKey, group);
    this.registerGroupInRegistry(group.id, group, entityId);
  }

  private getGroupJoiner(typeId: string, entityId: string) {
    return this.SECOND_LAYER_GROUPS[entityId]?.get(typeId);
  }

  private registerDefaultGroupId(id: string, entityId: string) {
    this.DEFAULT_GROUP_ID[entityId] = id;
  }

  private getDefaultGroupId(entityId: string) {
    return this.DEFAULT_GROUP_ID[entityId];
  }

  private registerMainGroupId(id: string, entityId: string) {
    this.MAIN_GROUP_ID[entityId] = id;
  }

  private getMainGroupId(entityId: string) {
    return this.MAIN_GROUP_ID[entityId];
  }

  private registerVariableInRegistry(variableUniquePath: string, variable: AbVariable<any>, entityId: string) {
    if (!this.AB_VARIABLE_REGISTRY[entityId]) {
      this.AB_VARIABLE_REGISTRY[entityId] = new Map();
    }
    const entityMap = this.AB_VARIABLE_REGISTRY[entityId];
    if (!entityMap) {
      throw new Error('Entity not found');
    }
    variable.entityId = entityId;
    entityMap.set(variableUniquePath, variable);
    this.AB_VARIABLE_REGISTRY[entityId] = entityMap;
  }

  private registerGroupInRegistry(groupId: string, group: ABBase<any>, entityId: string) {
    if (!this.AB_GROUP_REGISTRY[entityId]) {
      this.AB_GROUP_REGISTRY[entityId] = new Map();
    }
    const entityMap = this.AB_GROUP_REGISTRY[entityId];
    if (!entityMap) {
      throw new Error('Entity not found');
    }
    group.entityId = entityId;
    entityMap.set(groupId, group);

    this.AB_GROUP_REGISTRY[entityId] = entityMap;
  }

  private registerMainGroup(
    group: ABGroupMetadata<SupportedEntities>,
    entityBuildId: string,
    internalEntityId: string,
    constraintById: Array<string[]>
  ) {
    this.registerMainGroupId(group.id, entityBuildId);
    const groupClass = new GroupABEntity(group.id, group.name, group, internalEntityId, this);
    const constraint: AbTestConstraintFn = getConstraintByIdFunction(constraintById);
    groupClass.addConstraints([constraint]);
    const newId = `${entityBuildId}_${group.id}`;
    if (this.ENTITY_ADDRESS[newId] && this.ENTITY_ADDRESS[newId].entityId !== entityBuildId) {
      throw new Error('Id Collision id id groups');
    }
    this.ENTITY_ADDRESS[newId] = { entityId: entityBuildId, groupId: group.id };
    this.registerGroupInRegistry(group.id, groupClass, entityBuildId);
  }

  public getEntityGroupWithGroupId(groupId: string) {
    const entityId = this.ENTITY_ADDRESS[groupId];
    if (!entityId) {
      throw new Error('Entity not found');
    }
    return this.getGroupFromRegistry(entityId.groupId, entityId.entityId) as GroupABEntity<any, SupportedEntities>;
  }

  private registerGroup(group: ABGroupMetadata<SupportedEntities>, entityId: string) {
    if (!this.getMainGroupId(entityId)) {
      throw new Error('Main group must be registered before other groups');
    }
    this.registerGroupJoinerIfNotExists(group, entityId);
    const parentGroupJoiner = this.getGroupJoiner(group.typeId, entityId);
    if (!parentGroupJoiner) {
      throw new Error('Main group not found');
    }
    const groupClass = new GroupABTest(group.id, group.name, group, this);
    groupClass.setParentId(parentGroupJoiner.id);
    parentGroupJoiner.registerChild(group.id);
    this.registerGroupInRegistry(group.id, groupClass, entityId);
  }

  private getGroupFromRegistry(id: string, entityId: string) {
    return this.AB_GROUP_REGISTRY[entityId]?.get(id);
  }

  public getMainGroup(entityId: string) {
    return this.getGroupFromRegistry(this.getMainGroupId(entityId), entityId) as GroupABEntity<
      ABValue<unknown>[],
      SupportedEntities
    >;
  }

  public getEntityCombiner(campaignId: string) {
    return this.getGroupFromRegistry(`parent-${campaignId}`, this.ENTITY_COMBINER_PROPERTY) as GroupABEntityCombiner<
      ABValue<unknown>[],
      SupportedEntities
    >;
  }

  public getAbGroup<V extends ABValue<unknown>[]>(id: string, entityId: string) {
    return (this.getGroupFromRegistry(id, entityId) || this.getGroupJoiner(id, entityId)) as GroupABTest<
      V,
      SupportedEntities
    >;
  }

  public getVariable<V extends Array<ABValue<unknown> & { variableId: string }>>(id: string, entityId: string) {
    return this.AB_VARIABLE_REGISTRY[entityId]?.get(id) as AbVariable<V>;
  }

  private registerGroupJoinerIfNotExists(group: ABGroupMetadata<SupportedEntities>, entityId: string) {
    const mainGroup = this.getGroupFromRegistry(this.getMainGroupId(entityId), entityId);
    if (!mainGroup) {
      throw new Error('Main group must be registered before other groups');
    }
    if (!this.getGroupJoiner(group.typeId, entityId)) {
      const id = uid();
      const groupClass = new GroupABTestJoiner(
        id,
        `Parent group  Joiner for ${group.typeId}`,
        {
          description: `Parent Group Joiner for ${group.typeId}`,
          entity: group.entity,
          name: `Parent Group Joiner for ${group.typeId}`,
          id,
          typeId: group.typeId as 'DEFAULT',
        },
        this
      );
      groupClass.setParentId(mainGroup.id);
      mainGroup.registerChild(id);
      this.registerGroupInRegistry(id, groupClass, entityId);
      this.registerGroupJoiner(group.typeId, groupClass, entityId);
    }
  }

  public registerVariables(variable: InternalABVariable<any>[], metadata: FieldConfiguration, entityId: string) {
    const defaultGroupId = this.getDefaultGroupId(entityId);
    if (!defaultGroupId || !this.getGroupFromRegistry(defaultGroupId, entityId)) {
      throw new Error('Default group must be registered before variables');
    }
    const groupVariablesByAbGroup = variable.reduce((acc, v) => {
      const group = v.abGroup || 'default';
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(v);
      return acc;
    }, {} as Record<string, InternalABVariable<any>[]>);

    const groups = Object.values(groupVariablesByAbGroup);
    for (const groupOfVariables of groups) {
      const hiddenGroup = this.createOrGetGroupJoiner(
        entityId,
        groupOfVariables[0].abGroup!,
        metadata,
        groupOfVariables[0].field
      );
      for (const variable of groupOfVariables) {
        const variableUniquePath = [groupOfVariables[0].field, groupOfVariables[0].abGroup!, variable.variableId].join(
          '-'
        );
        if (this.getVariable(variableUniquePath, entityId)) {
          console.log(`Variable already registered, ${variableUniquePath}`, {
            current: this.getVariable(variableUniquePath, entityId),
            variable,
            metadata,
          });
          throw new Error('Variable already registered');
        }
        const abVariable = new AbVariable([{ ...variable, variableUniquePath }], metadata);
        hiddenGroup.registerChild(abVariable.id);
        this.registerVariableInRegistry(variableUniquePath, abVariable, entityId);
      }
    }
  }

  private createOrGetGroupJoiner(
    entityId: string,
    abGroupId: string,
    fieldMetadata: FieldConfiguration,
    fieldKey: string
  ) {
    const hiddenGroupId = uid();
    const groupId = abGroupId || this.getDefaultGroupId(entityId)!;
    const group = this.getGroupFromRegistry(groupId || this.getDefaultGroupId(entityId)!, entityId);
    if (!group) {
      console.log('GROUPS', {
        GROUPS: this.AB_GROUP_REGISTRY,
        entityId,
        groupId,
        DEFAULT: this.getDefaultGroupId(entityId),
        fieldMetadata,
      });
      throw new Error(
        `Group not found, make sure to register groups before variables ${
          groupId || this.getDefaultGroupId(entityId)
        } ${fieldMetadata.key}`
      );
    }

    if (!this.HIDDEN_GROUP_REGISTRY[entityId]) {
      this.HIDDEN_GROUP_REGISTRY[entityId] = {};
    }
    const hiddenGroupIdentifier = [
      groupId,
      fieldMetadata.fieldGroup || 'DEFAULT',
      fieldMetadata.interchangeableGroup || fieldKey,
    ]
      .filter(Boolean)
      .join('-');
    const existingGroup = this.HIDDEN_GROUP_REGISTRY[entityId][hiddenGroupIdentifier];
    if (existingGroup) {
      return this.getGroupFromRegistry(existingGroup, entityId) as GroupABTestJoiner<any, SupportedEntities>;
    }

    // If we have more than 1 variable for same field, than we should group them together (Cartesian product will be calculated for each of them)
    // This is done to avoid producing cartesian product between same field values
    const hiddenGroup = new GroupABTestJoiner(
      hiddenGroupId,
      `Field group Joiner for ${fieldMetadata.fieldGroup}`,
      {
        description: `Field group Joiner for ${fieldMetadata.fieldGroup}`,
        entity: SupportedEntities.campaign,
        name: `Field group Joiner for ${fieldMetadata.fieldGroup}`,
        id: hiddenGroupId,
        typeId: 'DEFAULT',
      },
      this
    );

    hiddenGroup.setParentId(group.id);
    group.registerChild(hiddenGroupId);
    this.registerGroupInRegistry(hiddenGroupId, hiddenGroup, entityId);
    this.HIDDEN_GROUP_REGISTRY[entityId][hiddenGroupIdentifier] = hiddenGroupId;
    return hiddenGroup;
  }

  public getParentEntityCombiner(entityId: string) {
    return this.getGroupFromRegistry(
      this.getKeyBuildForParent(entityId),
      this.ENTITY_COMBINER_PROPERTY
    ) as GroupABEntityCombiner<ABValue<unknown>[], SupportedEntities>;
  }

  private getKeyBuildForParent(parentInternalId: string) {
    return `parent-${parentInternalId}`;
  }

  public registerAbGroups(
    internalEntityId: string,
    mainGroupId: string,
    defaultGroupId: string,
    groups: Record<string, ABGroupMetadata<SupportedEntities>>,
    entityBuildId: string,
    constraintById: Array<string[]>,
    entity: 'campaign' | 'ad' | 'adset',
    parentId: string,
    rootEntityID?: string,
    fullTree = false,
    perChildCoefficient = 999
  ) {
    this.clearEntity(entityBuildId);
    this.registerMainGroup(groups[mainGroupId], entityBuildId, internalEntityId, constraintById);
    const mainGroup = this.getMainGroup(entityBuildId) as unknown as GroupABEntity<any, SupportedEntities.campaign>;
    // if parent id.
    if (fullTree) {
      // We find the parent group and register the child group
      const buildIdForMainGroup = `${entityBuildId}_${mainGroupId}`;

      const parentID = this.getKeyBuildForParent(parentId);
      const rootParentId = this.getKeyBuildForParent(rootEntityID!);
      // Entity Is root and can combine other entities
      if (rootEntityID) {
        const entityCombiner = new GroupABEntityCombiner(rootParentId, mainGroup, entity!, this, perChildCoefficient);
        entityCombiner.addConstraints([getConstraintByIdFunction(constraintById)]);
        this.registerGroupInRegistry(rootParentId, entityCombiner, this.ENTITY_COMBINER_PROPERTY);
      }
      if (rootEntityID !== parentId) {
        const entityCombinerGroup = this.getGroupFromRegistry(
          parentID,
          this.ENTITY_COMBINER_PROPERTY
        ) as GroupABEntityCombiner<any, SupportedEntities.campaign>;
        if (!entityCombinerGroup) {
          throw new Error('Entity combiner group not found, make sure to register parent first');
        }
        entityCombinerGroup.registerChild(rootEntityID ? rootParentId : buildIdForMainGroup);
      }
    }

    // Register groups ands variables
    this.registerDefaultGroupId(defaultGroupId, entityBuildId);
    Object.values(groups).forEach((group) => {
      if (group.id !== mainGroupId) {
        this.registerGroup(group, entityBuildId);
      }
    });
  }
}
