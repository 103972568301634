<template>
  <div class="brandname-wrapper">
    <b-form-group
      class="optimizer-form-group form-group-info-link"
    >
      <template slot="label" class="d-flex">
        <div class="d-flex justify-content-between align-items-center w-100">
          <span>Advert Text: <span class="text-danger"> {{ isRequired ? '*' : '' }}</span></span>
          <small class="small-info-text">Character left: <b>{{ maxCharacter - input.length }}</b></small>
        </div>
      </template>
      <b-form-input
        class="optimizer-form-input"
        placeholder="Enter Advert Text"
        :id="id"
        size="sm"
        v-model="input"
        :maxlength="maxCharacter"
        :name="id"
        :v-validate="isRequired ? 'required' : ''"
      >
      </b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import uid from 'uid';

export default {
  name: 'AdvertText',
  props: {
    isRequired: { type: Boolean, default: false },
    maxCharacter: { type: Number, default: 30 },
    labelCols: { type: Number, default: 3 },
    breakpoint: { type: String, default: 'md' },
    labelClass: { type: String, default: 'text-md-right' },
    size: { type: String, default: 'sm' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      input: '',
      id: `advertText-${uid(10)}`,
    };
  },
  watch: {
    input() {
      this.$emit('input', this.input);
    },
  },
  created() {
    this.input = this.value;
  },
  methods: {
    $_hasError() {
      return this.input === '';
    },
  },
};
</script>
