/* eslint-disable import/no-cycle */
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import { ABGroupMetadata, getIdForEntity, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { CampaignAbGroupTypes } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import uuid from 'uid';

export interface AbGroupForEntity<E extends SupportedEntities> {
  groups: Record<string, ABGroupMetadata<E>>;
  defaultAbGroup: string; // If no group is selected, this will be the default
  mainAbGroup: string; // This is the main group for the campaign
  constraintById: Array<string[]>; // If all those ids are found, group will be disabled
}

export interface ABStore {
  campaign: {
    [campaignId: string]: {
      groups: Record<string, ABGroupMetadata<SupportedEntities.campaign>>;
      defaultAbGroup: string; // If no group is selected, this will be the default
      mainAbGroup: string; // This is the main group for the campaign
      constraintById: Array<string[]>; // If all those ids are found, group will be disabled
    };
  };
  ad: {
    [adsId: string]: {
      groups: Record<string, ABGroupMetadata<SupportedEntities.ad>>;
      defaultAbGroup: string;
      mainAbGroup: string;
      constraintById: Array<string[]>; // If all those ids are found, group will be disabled
    };
  };
  adset: {
    [adsetId: string]: {
      groups: Record<string, ABGroupMetadata<SupportedEntities.adset>>;
      defaultAbGroup: string;
      mainAbGroup: string;
      constraintById: Array<string[]>; // If all those ids are found, group will be disabled
    };
  };
  creative: {
    [creativeId: string]: {
      groups: Record<string, ABGroupMetadata<SupportedEntities.creative>>;
      defaultAbGroup: string;
      mainAbGroup: string;
      constraintById: Array<string[]>; // If all those ids are found, group will be disabled
    };
  };
  targeting: {
    [adsetId: string]: {
      groups: Record<string, ABGroupMetadata<SupportedEntities.targeting>>;
      defaultAbGroup: string;
      mainAbGroup: string;
      constraintById: Array<string[]>; // If all those ids are found, group will be disabled
    };
  };
  globalConstraintById: Array<string[]>; // If all those ids are found, group will be disabled
  spreadFactor: Partial<Record<SupportedEntities.ad | SupportedEntities.adset, number>>;
}

function getDefaultAbGroup<E extends SupportedEntities>(entity: E) {
  const defaultAbId = uuid();
  const defaultAB: ABGroupMetadata<E> = {
    name: `Default Ab ${entity.toUpperCase()} Group`,
    entity,
    typeId: CampaignAbGroupTypes.DEFAULT,
    dependencies: [],
    description: `Default Ab ${entity.toUpperCase()} Group`,
    id: defaultAbId,
  };
  const mainId = uuid();
  const mainAbGroup = {
    name: `Main Ab ${entity.toUpperCase()} Group`,
    entity,
    typeId: 'MAIN' as const,
    dependencies: [],
    description: `Main Ab ${entity.toUpperCase()} Group`,
    id: mainId,
  };
  return {
    groups: {
      [defaultAbId]: defaultAB,
      [mainId]: mainAbGroup,
    },
    defaultAbGroup: defaultAbId,
    mainAbGroup: mainId,
    constraintById: [],
  };
}

const useAbGroups = defineStore('abGroups', {
  state: (): ABStore => ({
    campaign: {},
    ad: {},
    adset: {},
    creative: {},
    targeting: {},
    globalConstraintById: [],
    spreadFactor: {},
  }),
  getters: {
    getAbGroup: (store) => (abGroupId: string, entity: SupportedEntities, overrideBuildId?: string) => {
      const navigation = useNavigationStore();
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      if ((adId || overrideBuildId) && entity === SupportedEntities.creative) {
        const id = overrideBuildId || navigation.getAdBuildId;
        return store.creative[id]?.groups?.[abGroupId];
      }
      if ((adId || overrideBuildId) && entity === SupportedEntities.ad) {
        const id = overrideBuildId || navigation.getAdBuildId;
        return store.ad[id]?.groups?.[abGroupId];
      }
      if ((adsetId || overrideBuildId) && entity === SupportedEntities.adset) {
        const id = overrideBuildId || navigation.getAdsetBuildId;
        return store.adset[id]?.groups?.[abGroupId];
      }
      if ((adsetId || overrideBuildId) && entity === SupportedEntities.targeting) {
        const id = overrideBuildId || navigation.getAdsetBuildId;
        return store.targeting[id]?.groups?.[abGroupId];
      }
      if ((entity || overrideBuildId) === SupportedEntities.campaign) {
        const id = overrideBuildId || navigation.getCampaignBuildId;
        return store.campaign[id]?.groups?.[abGroupId];
      }
      throw new Error('Entity not found');
    },
    getGlobalConstraints(store) {
      return store.globalConstraintById;
    },
    getAllAbGroupsForEntity: (store) => (entity: SupportedEntities) => {
      if (entity === SupportedEntities.campaign) {
        return store.campaign;
      }
      if (entity === SupportedEntities.ad) {
        return store.ad;
      }
      if (entity === SupportedEntities.adset) {
        return store.adset;
      }
      if (entity === SupportedEntities.creative) {
        return store.creative;
      }
      if (entity === SupportedEntities.targeting) {
        return store.targeting;
      }
      throw new Error('Entity not found');
    },
    getAbGroupForEntity: (store) => (entity: SupportedEntities) => {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;

      if (adId && entity === SupportedEntities.creative) {
        const id = navigation.getAdBuildId;
        return store.creative?.[id]?.groups;
      }
      if (adId && entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [campaignId, adId]);
        return store.ad?.[id]?.groups;
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        return store.adset?.[id]?.groups;
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        return store.targeting?.[id]?.groups;
      }
      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [campaignId]);
        return store.campaign?.[id]?.groups;
      }
    },

    getDefaultAbGroupForEntity: (store) => (entity: SupportedEntities, idEntry?: string) => {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      if (adId && entity === SupportedEntities.creative) {
        const id = navigation.getAdBuildId;
        return store.creative?.[id]?.defaultAbGroup;
      }
      if (adId && entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [campaignId, idEntry || adId]);
        return store.ad?.[id]?.defaultAbGroup;
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, idEntry || adsetId]);
        return store.adset?.[id]?.defaultAbGroup;
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, idEntry || adsetId]);
        return store.targeting?.[id]?.defaultAbGroup;
      }
      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [idEntry || campaignId]);
        return store.campaign?.[id]?.defaultAbGroup;
      }
      throw new Error('Default AB or Entity not found');
    },
    getSpreadFactor(): Record<string, number> {
      return this.spreadFactor;
    },
  },
  actions: {
    saveState() {
      return { abGroups: this.$state };
    },
    loadState(template: any) {
      const parsedState = template.abGroups as ABStore;
      if (parsedState) {
        this.campaign = { ...this.campaign, ...parsedState.campaign };
        this.ad = { ...this.ad, ...parsedState.ad };
        this.adset = { ...this.adset, ...parsedState.adset };
        this.creative = { ...this.creative, ...parsedState.creative };
        this.targeting = { ...this.targeting, ...parsedState.targeting };
        this.globalConstraintById = [...this.globalConstraintById, ...parsedState.globalConstraintById];
        this.spreadFactor = { ...this.spreadFactor, ...parsedState.spreadFactor };
      }
    },
    setSpreadFactor(entity: SupportedEntities.ad | SupportedEntities.adset, factor: number) {
      this.spreadFactor = { ...this.spreadFactor, [entity]: factor };
    },
    resetSpreadFactor() {
      this.spreadFactor = {};
    },
    initAbGroup(entity: SupportedEntities, idEntry?: string) {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      if (adId && entity === SupportedEntities.creative) {
        const id = navigation.getAdBuildId;
        if (!this.creative[id]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.creative);
          this.creative[id] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
      }
      if (adId && entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [campaignId, idEntry || adId]);
        if (!this.ad[id]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.ad);
          this.ad[id] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, idEntry || adsetId]);
        if (!this.adset[id]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.adset);
          this.adset[id] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, idEntry || adsetId]);
        if (!this.targeting[id]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.targeting);
          this.targeting[id] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
      }
      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [idEntry || campaignId]);
        if (!this.campaign[id]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.campaign);
          this.campaign[id] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
      }
    },
    pauseAbVariation(entity: SupportedEntities, constraint: string[]) {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      if (adId && entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [campaignId, adId]);
        this.ad[id].constraintById.push(constraint);
        this.ad = { ...this.ad };
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        this.adset[id].constraintById.push(constraint);
        this.adset = { ...this.adset };
      }

      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [campaignId]);
        this.campaign[id].constraintById.push(constraint);
        this.campaign = { ...this.campaign };
      }
    },
    pauseAbVariantGlobal(status: boolean, constraint: string[]) {
      if (!status) {
        this.globalConstraintById.push(constraint);
      } else {
        this.globalConstraintById = this.globalConstraintById.filter(
          (c) =>
            // If all the ids are found, we remove the constraint
            !constraint.every((id) => c.includes(id))
        );
      }
    },
    startAbVariation(
      entity: SupportedEntities.campaign | SupportedEntities.adset | SupportedEntities.ad,
      constraint: string[]
    ) {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      if (adId && entity === SupportedEntities.ad) {
        const id = getIdForEntity(SupportedEntities.ad, [campaignId, adId]);
        this.ad[id].constraintById = this.ad[id].constraintById.filter(
          (c) => !constraint.every((id) => c.includes(id))
        );
        this.ad = { ...this.ad };
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const id = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        this.adset[id].constraintById = this.adset[id].constraintById.filter(
          (c) => !constraint.every((id) => c.includes(id))
        );
        this.adset = { ...this.adset };
      }
      if (entity === SupportedEntities.campaign) {
        const id = getIdForEntity(SupportedEntities.campaign, [campaignId]);
        this.campaign[id].constraintById = this.campaign[id].constraintById.filter(
          (c) => !constraint.every((id) => c.includes(id))
        );
        this.campaign = { ...this.campaign };
      }
    },
    addAbGroup<E extends SupportedEntities>(entity: E, group: Omit<ABGroupMetadata<E>, 'id'> & { id?: string }) {
      const navigation = useNavigationStore();
      const campaignId = navigation.campaignInternalId;
      const adsetId = navigation.adSetInternalId;
      const adId = navigation.adInternalId;
      const campaignEntityId = getIdForEntity(SupportedEntities.campaign, [campaignId]);
      // Dependencies cannot be part of more than one group type id
      const allExistingGroups = Object.values(this.getAbGroupForEntity(entity) || {});
      if (
        group?.dependencies?.some((dep) =>
          allExistingGroups.some((g) => g.typeId !== group.typeId && g.dependencies?.includes(dep))
        )
      ) {
        throw new Error('Dependency already exists in another group, of different type');
      }
      const newGroup: ABGroupMetadata<E> = {
        ...group,
        id: group.id || uuid(),
      };
      if (entity === SupportedEntities.campaign) {
        const defaultGroup = getDefaultAbGroup(SupportedEntities.campaign);
        if (!this.campaign[campaignEntityId]) {
          this.campaign[campaignEntityId] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
        this.campaign[campaignEntityId].groups[newGroup.id] =
          newGroup as unknown as ABGroupMetadata<SupportedEntities.campaign>;
        this.campaign = { ...this.campaign };
      }
      if (adId && entity === SupportedEntities.creative) {
        const creativeId = navigation.getAdBuildId;
        if (!this.creative[creativeId]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.creative);
          this.creative[creativeId] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
        this.creative[creativeId].groups[newGroup.id] = newGroup as any;
        this.creative = { ...this.creative };
      }
      if (adId && entity === SupportedEntities.ad) {
        const adIdInternal = getIdForEntity(SupportedEntities.ad, [campaignId, adId]);
        if (!this.ad[adIdInternal]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.ad);
          this.ad[adIdInternal] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
        this.ad[adIdInternal].groups[newGroup.id] = newGroup as any;
        this.ad = { ...this.ad };
      }
      if (adsetId && entity === SupportedEntities.adset) {
        const adSetInternalId = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        if (!this.adset[adSetInternalId]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.adset);
          this.adset[adSetInternalId] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
        this.adset[adSetInternalId].groups[newGroup.id] = newGroup as any;
        this.adset = { ...this.adset };
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        const adSetInternalId = getIdForEntity(SupportedEntities.adset, [campaignId, adsetId]);
        if (!this.targeting[adSetInternalId]) {
          const defaultGroup = getDefaultAbGroup(SupportedEntities.targeting);
          this.targeting[adSetInternalId] = defaultGroup;
          navigation.setInternalNavigation(entity, CampaignAbGroupTypes.DEFAULT, defaultGroup.defaultAbGroup);
        }
        this.targeting[adSetInternalId].groups[newGroup.id] = newGroup as any;
        this.targeting = { ...this.targeting };
      }

      navigation.setInternalNavigation(entity, group.typeId, newGroup.id);
    },
    renameGroup<E extends SupportedEntities>(entity: E, { groupId, name }: { groupId: string; name: string }) {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignBuildId;

      if (entity === SupportedEntities.campaign) {
        this.campaign[campaignId].groups[groupId].name = name;
        this.campaign = { ...this.campaign };
        return;
      }
      const adsetId = navigation.getAdsetBuildId;

      if (adsetId && entity === SupportedEntities.adset) {
        this.adset[adsetId].groups[groupId].name = name;
        this.adset = { ...this.adset };
        return;
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        this.targeting[adsetId].groups[groupId].name = name;
        this.targeting = { ...this.targeting };
        return;
      }
      const adId = navigation.getAdBuildId;
      if (adId && entity === SupportedEntities.creative) {
        this.creative[adId].groups[groupId].name = name;
        this.creative = { ...this.creative };
      }
      if (adId && entity === SupportedEntities.ad) {
        this.ad[adId].groups[groupId].name = name;
        this.ad = { ...this.ad };
      }
    },
    deleteGroup<E extends SupportedEntities>(entity: E, groupId: string) {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignBuildId;
      if (entity === SupportedEntities.campaign) {
        delete this.campaign[campaignId].groups[groupId];
        this.campaign = { ...this.campaign };
        return;
      }
      const adsetId = navigation.getAdsetBuildId;
      if (adsetId && entity === SupportedEntities.adset) {
        delete this.adset[adsetId].groups[groupId];
        this.adset = { ...this.adset };
        return;
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        delete this.targeting[adsetId].groups[groupId];
        this.targeting = { ...this.targeting };
        return;
      }
      const adId = navigation.getAdBuildId;
      if (adId && entity === SupportedEntities.creative) {
        delete this.creative[adId].groups[groupId];
        this.creative = { ...this.creative };
      }
      if (adId && entity === SupportedEntities.ad) {
        delete this.ad[adId].groups[groupId];
        this.ad = { ...this.ad };
      }
    },
    cloneGroup<E extends SupportedEntities>(
      entity: E,
      { groupId, newGroupId }: { groupId: string; newGroupId: string }
    ) {
      const navigation = useNavigationStore();
      const campaignId = navigation.getCampaignBuildId;
      if (entity === SupportedEntities.campaign) {
        const currentGroup = this.campaign[campaignId].groups[groupId];
        const newGroup = { ...currentGroup, id: newGroupId, name: `${currentGroup.name} - Copy` };
        this.campaign[campaignId].groups[newGroupId] = newGroup;
        this.campaign = { ...this.campaign };
        return;
      }
      const adsetId = navigation.getAdsetBuildId;
      if (adsetId && entity === SupportedEntities.adset) {
        const currentGroup = this.adset[adsetId].groups[groupId];
        const newGroup = { ...currentGroup, id: newGroupId, name: `${currentGroup.name} - Copy` };
        this.adset[adsetId].groups[newGroupId] = newGroup;
        this.adset = { ...this.adset };
        return;
      }
      if (adsetId && entity === SupportedEntities.targeting) {
        const currentGroup = this.targeting[adsetId].groups[groupId];
        const newGroup = { ...currentGroup, id: newGroupId, name: `${currentGroup.name} - Copy` };
        this.targeting[adsetId].groups[newGroupId] = newGroup;
        this.targeting = { ...this.targeting };
        return;
      }
      const adId = navigation.getAdBuildId;
      if (adId && entity === SupportedEntities.creative) {
        const currentGroup = this.creative[adId].groups[groupId];
        const newGroup = { ...currentGroup, id: newGroupId, name: `${currentGroup.name} - Copy` };
        this.creative[adId].groups[newGroupId] = newGroup;
        this.creative = { ...this.creative };
      }
      if (adId && entity === SupportedEntities.ad) {
        const currentGroup = this.ad[adId].groups[groupId];
        const newGroup = { ...currentGroup, id: newGroupId, name: `${currentGroup.name} - Copy` };
        this.ad[adId].groups[newGroupId] = newGroup;
        this.ad = { ...this.ad };
      }
    },

    clone(entity: SupportedEntities, { currentBuildId, newBuildId }: { currentBuildId: string; newBuildId: string }) {
      if (entity === SupportedEntities.campaign) {
        this.campaign[newBuildId] = cloneDeep(this.campaign[currentBuildId]);
        this.campaign = { ...this.campaign };
      }
      if (entity === SupportedEntities.ad) {
        this.ad[newBuildId] = cloneDeep(this.ad[currentBuildId]);
        this.ad = { ...this.ad };
      }
      if (entity === SupportedEntities.adset) {
        this.adset[newBuildId] = cloneDeep(this.adset[currentBuildId]);
        this.adset = { ...this.adset };
      }
      if (entity === SupportedEntities.creative) {
        this.creative[newBuildId] = cloneDeep(this.creative[currentBuildId]);
        this.creative = { ...this.creative };
      }
      if (entity === SupportedEntities.targeting) {
        this.targeting[newBuildId] = cloneDeep(this.targeting[currentBuildId]);
        this.targeting = { ...this.targeting };
      }
    },
    delete(entity: string, buildId: string) {
      if (entity === SupportedEntities.campaign) {
        const allCmp = this.campaign;
        delete allCmp[buildId];
        this.campaign = {
          ...allCmp,
        };
      }
      if (entity === SupportedEntities.ad) {
        const allAd = this.ad;
        delete allAd[buildId];
        this.ad = {
          ...allAd,
        };
      }
      if (entity === SupportedEntities.adset) {
        const allAdset = this.adset;
        delete allAdset[buildId];
        this.adset = {
          ...allAdset,
        };
      }
      if (entity === SupportedEntities.targeting) {
        const allTargeting = this.targeting;
        delete allTargeting[buildId];
        this.targeting = {
          ...allTargeting,
        };
      }
      if (entity === SupportedEntities.creative) {
        const allCreative = this.creative;
        delete allCreative[buildId];
        this.creative = {
          ...allCreative,
        };
      }
    },
  },
});

export default useAbGroups;
