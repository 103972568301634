export enum BaseFieldType {
  Date = 'Date',
  Metric = 'Metric',
  Entity = 'Entity',
  Constant = 'Constant',
}

export enum ValueType {
  Now = 'Now',
  DateString = 'DateString',
  Number = 'Number',
  String = 'String',
  Status = 'Status',
  TABOOLA_LEARNING_STATE = 'TaboolaLearningStateField',
  HOUR_OF_DAY = 'HourOfDayField',
  DateField = 'DateField',
}

export enum UnitValue {
  Static = 'static',
  Self = 'self',
  Percentage = 'percentage',
}

export interface Value {
  behind: string;
  placeholder: string;
  placeholderHtml: string;
  placeholderPosition: string;
  step: string;
  key: StringSearchOperatorValue | NumericOperatorValue;
}

export interface FieldValue {
  key: string;
}

export interface Option<T = Value> {
  key: string;
  group: string;
  type: ValueType;
  baseType: BaseFieldType;
  value: T;
  text: string;
  class: string;
  inputGroup: string;
  disabled?: boolean;
  tooltip?: string;
  description?: string;
}

export interface Group {
  value: string;
  group: string;
  useInCustomMetric?: boolean;
  priority: number;
  label: string;
  content: string;
  trackers?: string[];
  trafficSource?: string[];
}

export interface Field3 {
  to?: Option<FieldValue>;
  to_self?: Option<FieldValue>;
  unit: UnitValue;
  value: string | number | { key: string }; // recheck if there are other types
  baseModel: string;
}

export interface Row {
  field1: Option<FieldValue>;
  field2: Partial<Option>;
  field3: Field3;
}

export type StringSearchOperatorValue =
  | 'contains'
  | 'starts_with'
  | 'ends_with'
  | 'not_contains'
  | 'not_starts_with'
  | 'not_ends_with'
  | 'equals';

export type NumericOperatorValue = 'greater' | 'less' | 'less_equal' | 'greater_equal';

export enum ConditionTextMap {
  greater = '>',
  less = '<',
  greater_equal = '>=',
  less_equal = '<=',
}

export type ConditionMap = {
  [K in NumericOperatorValue]: {
    value: {
      key: K;
      behind: string;
    };
    text: ConditionTextMap;
  };
};
