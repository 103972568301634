<template>
  <b-form-group class="col-sm-12 field3 optimizer-form-group">
    <b-form-group :state="$_hasError('numberOfCopies')" class="h-display-sm campaign-form optimizer-form-group">
      <template slot="label">
        Number of Copies
        <i class="fa fa-info-circle ml-1" v-b-tooltip.hover.top :title="$_getTooltipText()"></i>
      </template>
      <b-input-group>
        <template>
          <b-form-input
            class="field3-input optimizer-form-input"
            type="number"
            v-model="numberOfCopies"
            name="numberOfCopies"
            v-validate="'required'"
            :step="1"
            min="0"
          >
          </b-form-input>
        </template>
      </b-input-group>
    </b-form-group>
    <div>
      <div class="d-flex align-items-center mb-3 mt-5" v-if="$c_optionalBudget">
        <switch-button
          class="min-max-switch ml-0 mb-1"
          :checked="$c_enabledBudget"
          :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
          :update="(value) => $_switchToggle(value)"
        />
        <p>Adjust campaign budget before cloning</p><i v-b-tooltip.hover.top.html="customBudgetTitle" class="fa fa-info-circle  ml-2" />
      </div>
      <RuleBudget
        v-if="$c_enabledBudget"
        ref="ruleBudget"
        v-model="ruleModel.rule.components.numberOfCopies.budget.value"
        :config="config"
        :mode="mode"
        :ruleModel="ruleModelForBudget"
        :type="ruleModel.type"
        :actionOptions="ruleModel.type=== 'campaign_copy_budget' ? config.budgetConfig.budgetActionOptions : config.budgetConfig.otherBudgetActionOptions"
      />
    </div>
    <div>
      <div class="d-flex align-items-center mb-3 mt-4" v-if="$c_optionalAdsetBudget">
        <switch-button
          class="min-max-switch ml-0 mb-1"
          :checked="$c_enabledAdsetBudget"
          :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
          :update="(value) => $_switchAdSetToggle(value)"
        />
        <p>Adjust AdSet budget before cloning</p><i v-b-tooltip.hover.top.html="customBudgetAdSetTitle" class="fa fa-info-circle info-icon ml-2" />
      </div>
      <RuleBudget
        v-if="$c_enabledAdsetBudget"
        ref="ruleAdsetBudget"
        v-model="ruleModel.rule.components.numberOfCopies.adsetBudget.value"
        :config="config"
        :mode="mode"
        :ruleModel="ruleModelForAdsetBudget"
        :type="ruleModel.type"
        :actionOptions="config.budgetConfig.adSetBudgetActionOptions"
      />
    </div>
  </b-form-group>
</template>

<script>
import component from '../mixins/component';
import RuleBudget from './RuleBudget.vue';
import { budgetConfigValid } from '../../../configurations/fields/helpers';

export default {
  name: 'RuleNumberOfCopies',
  components: { RuleBudget },
  mixins: [component],
  props: {
    value: { type: Number, default: 1 },
    ruleModel: { type: Object, required: true },
    config: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  data() {
    return {
      numberOfCopies: 1,
      ruleModelForBudget: {
        rule: {
          components: {
            budget: this.ruleModel.rule.components.numberOfCopies?.budget,
          },
        },
      },
      ruleModelForAdsetBudget: {
        rule: {
          components: {
            budget: this.ruleModel.rule.components.numberOfCopies?.adsetBudget,
          },
        },
      },
      customBudgetTitle: 'This budget adjustment will only be applied to campaign level budgets. Campaigns managing budgets on Ad Set level will not be affected. ',
      customBudgetAdSetTitle: 'This budget adjustment will only be applied to Ad Set level budgets. Campaigns managing budgets on campaign level will not be affected.',
    };
  },
  computed: {
    $c_optionalBudget() {
      const supportedTs = ['TikTok', 'Facebook'];
      const tsTypes = this.ruleModel.rule.ts_types || [];
      const allowBudget = tsTypes.some((ts) => supportedTs.includes(ts));
      if (!allowBudget) return false;
      return this.ruleModelForBudget.rule.components.budget?.isOptional || false;
    },
    $c_optionalAdsetBudget() {
      if (this.ruleModel.type !== 'campaign_copy_budget') return false;
      return this.ruleModelForAdsetBudget.rule.components.budget?.isOptional || false;
    },
    $c_enabledBudget() {
      const hasOptionalBudget = this.$c_optionalBudget;
      if (hasOptionalBudget) {
        return this.ruleModelForBudget.rule.components.budget.enableBudget;
      }
      return false;
    },
    $c_enabledAdsetBudget() {
      const hasOptionalBudget = this.$c_optionalAdsetBudget;
      if (hasOptionalBudget) {
        return this.ruleModelForAdsetBudget.rule.components.budget?.enableAdSetBudget;
      }
      return false;
    },
  },
  watch: {
    numberOfCopies: {
      immediate: true,
      handler() {
        this.$_emit('numberOfCopies');
      },
      deep: true,
    },
  },
  created() {
    this.numberOfCopies = this.value;
  },
  methods: {
    $_getTooltipText() {
      const entity = this.ruleModel.rule?.indefinite_level_rename || this.ruleModel.rule.level;
      const entityName = entity.replace('an ', '').replace('a ', '');
      const text = 'Specify the number of cloned copies for each {entity}. Example: 5 means that each {entity} will be cloned 5 times.';
      return text.replaceAll('{entity}', entityName.toLowerCase());
    },
    $_switchToggle(value) {
      this.ruleModelForBudget.rule.components.budget.enableBudget = value;
    },
    $_switchAdSetToggle(value) {
      this.ruleModelForAdsetBudget.rule.components.budget.enableAdSetBudget = value;
    },
    validateRuleBudget() {
      try {
        if (this.$c_enabledBudget) {
          const validation = this.$refs?.ruleBudget?.$_isValid();
          const { valid, where } = validation || {};

          this.$refs?.ruleBudget?.$_dismissAlert();

          if (!valid) {
            const errorMessage = `Please fill ${where}`;
            this.$refs?.ruleBudget?.$_showInlineError('danger', errorMessage);
            return false;
          }
          const { min, max, value } = this.$refs?.ruleBudget?.form;

          if (budgetConfigValid(min, max, value)) {
            const budgetErrorMessage = 'Please check the value for the budgets. Min budget cannot be greater or equal than max budget.';
            this.$refs.ruleBudget.$_showInlineError('danger', budgetErrorMessage);
            return false;
          }
          return true;
        }
      } catch (err) {
        return true;
      }
    },
    validateRuleAdsetBudget() {
      try {
        if (this.$c_enabledAdsetBudget) {
          const validation = this.$refs?.ruleAdsetBudget?.$_isValid();
          const { valid, where } = validation || {};

          this.$refs?.ruleAdsetBudget?.$_dismissAlert();

          if (!valid) {
            const errorMessage = `Please fill ${where}`;
            this.$refs?.ruleAdsetBudget?.$_showInlineError('danger', errorMessage);
            return false;
          }
          const { min, max, value } = this.$refs?.ruleAdsetBudget?.form;

          if (
            budgetConfigValid(min, max, value)) {
            const budgetErrorMessage = 'Please check the value for the budgets. Min budget cannot be greater or equal than max budget.';
            this.$refs.ruleAdsetBudget.$_showInlineError('danger', budgetErrorMessage);
            return false;
          }
          return true;
        }
      } catch (err) {
        return false;
      }
    },
  },
};
</script>
