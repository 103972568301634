
import { defineComponent } from 'vue';

interface ComponentData {
  params: any;
  hasColumns: boolean;
}

export default defineComponent({
  props: {
    noSpace: {
      type: Boolean,
      default: true,
    },
    checkColumns: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      params: undefined,
      hasColumns: true,
    };
  },
  beforeMount() {
    // this.params contains the cell and row information and is made available to this component at creation time
    // see ICellRendererParams for more details
    if (this.params) {
      this.hasColumns = this.params.hasColumns;
    }
  },
});
