var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ad-card"},[_c('div',{staticClass:"ad-card__header"},[_c('div',{staticClass:"ad-card__user"},[_c(_setup.CcIcon,{staticClass:"ad-card__user__img",attrs:{"type":"facebookAvatarIcon"}}),_c('div',{staticClass:"ad-card__user__info"},[_c('p',{staticClass:"ad-card__user__info__name"},[_vm._v("Page Name")]),_c('div',{staticClass:"ad-card__user__info__sponsored"},[_vm._v("Sponsored "),_c(_setup.CcIcon,{attrs:{"type":"facebookGlobeIcon"}})],1)])],1),_c('button',{staticClass:"cc-btn cc-btn--secondary",on:{"click":function($event){return _setup.emits('apply')}}},[_c(_setup.CcIcon,{attrs:{"type":"checkCircle"}}),_vm._v(" Use Ad ")],1)]),_c('p',{staticClass:"ad-card__primary-text"},[_vm._v(_vm._s(_vm.title))]),_c('img',{staticClass:"ad-card__ad-preview",attrs:{"src":_vm.image_url,"alt":"Ad Preview"}}),_c('div',{staticClass:"ad-card__info"},[_c('div',{staticClass:"ad-card__info__wrapper"},[_c('p',{staticClass:"ad-card__info__wrapper__display-link"},[_vm._v(_vm._s(_vm.displayLink))]),_c('p',{staticClass:"ad-card__info__wrapper__header"},[_vm._v(_vm._s(_vm.headline))]),_c('p',{staticClass:"ad-card__info__wrapper__description"},[_vm._v(" "+_vm._s(_vm.body)+" ")])]),_c('div',{staticClass:"ad-card__info__cta"},[_vm._v(_vm._s(_vm.call_to_action_type))])]),_c('div',{staticClass:"ad-card__data"},_vm._l((_vm.metrics),function(metric){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
        customClass: 'custom-tooltip',
        title: `${metric.name} : ${metric.value}`,
        boundary: 'viewport',
        'boundary-padding': 10,
        delay: { show: 500, hide: 0 },
      }),expression:"{\n        customClass: 'custom-tooltip',\n        title: `${metric.name} : ${metric.value}`,\n        boundary: 'viewport',\n        'boundary-padding': 10,\n        delay: { show: 500, hide: 0 },\n      }",modifiers:{"hover":true,"top":true}}],key:metric.name},[_vm._v(" "+_vm._s(`${metric.name} : ${metric.value}`)+" ")])}),0),_c('div',{staticClass:"ad-card__footer"},[_c('div',{staticClass:"ad-card__footer__card"},[_c(_setup.CcIcon,{attrs:{"type":"facebookLikeIcon"}})],1),_c('div',{staticClass:"ad-card__footer__card"},[_c(_setup.CcIcon,{attrs:{"type":"facebookCommentIcon"}})],1),_c('div',{staticClass:"ad-card__footer__card"},[_c(_setup.CcIcon,{attrs:{"type":"facebookShareIcon"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }