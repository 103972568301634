<template>
  <div class="thrive-tracker-view">
    <b-card v-for="(rotation, index) in performance.rotations" :key="index">
      <template slot="header">
        <h5 class="m-0">
          {{ rotation.info.name }}
        </h5>
      </template>
      <TrackerViewTable
        v-if="rotation.direct.rows.length"
        :fields="directFields"
        :items="rotation.direct.rows"
        :totals="rotation.direct.totals"
        title="Offers (Direct Linked Only)"
      >
        <template slot="name" slot-scope="props">
          <a :href="props.item.url" target="_blank">{{ props.item.name }}</a>
        </template>
        <template slot="weight" slot-scope="props">
          <live-edit
            :ref="`weightDirectLiveEdit-${index}-${props.i}`"
            :key="`weightDirectLiveEdit-${index}-${props.i}`"
            v-model="props.item.weight"
            :format="parseInt"
            field-name="Weight"
            :before-blur="value => $_updateWeight('direct', props.item, value)"
            @up="$_inlineEditArrayPress(`weightDirectLiveEdit-${index}`, props.i, 'up')"
            @down="$_inlineEditArrayPress(`weightDirectLiveEdit-${index}`, props.i, 'down')"
          /> - {{ props.item.percent }}%
        </template>
      </TrackerViewTable>
      <TrackerViewTable
        v-if="rotation.landings.rows.length"
        :fields="landingsFields"
        :items="rotation.landings.rows"
        :totals="rotation.landings.totals"
        title="Landing Pages"
        nested-key="offers"
      >
        <template slot="name" slot-scope="props">
          <a :href="props.item.url" target="_blank">{{ props.item.name }}</a>
        </template>
        <template slot="weight" slot-scope="props">
          <live-edit
            :ref="`weightLandingsLiveEdit-${index}-${props.i}`"
            :key="`weightLandingsLiveEdit-${index}-${props.i}`"
            v-model="props.item.weight"
            :format="parseInt"
            field-name="Weight"
            :before-blur="value => $_updateWeight('landings', props.item, value)"
            @up="$_inlineEditArrayPress(`weightLandingsLiveEdit-${index}`, props.i, 'up')"
            @down="$_inlineEditArrayPress(`weightLandingsLiveEdit-${index}`, props.i, 'down')"
          /> - {{ props.item.percent }}%
        </template>
      </TrackerViewTable>
    </b-card>
  </div>
</template>

<script>
import mixins from './mixins';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'ThriveTrackerView',
  mixins: [mixins, notifications],
  data() {
    return {
      directFields: [
        this.$_getField('name'),
        this.$_getField('network'),
        this.$_getField('status'),
        this.$_getField('payout'),
        this.$_getField('weight'),
        this.$_getField('clicks'),
        this.$_getField('conversions'),
        this.$_getField('cr'),
        this.$_getField('revenue'),
        this.$_getField('epc'),
      ],
      landingsFields: [
        this.$_getField('name'),
        this.$_getField('network'),
        this.$_getField('status'),
        this.$_getField('payout'),
        this.$_getField('weight'),
        this.$_getField('clicks'),
        this.$_getField('lp_clicks'),
        this.$_getField('lp_ctr'),
        this.$_getField('lp_cr'),
        this.$_getField('conversions'),
        this.$_getField('cr'),
        this.$_getField('revenue'),
        this.$_getField('epc'),
      ],
    };
  },
  methods: {
    async $_updateWeight(type, item, value) {
      const data = { template_id: this.performance.campaign.rotTemplateId };
      if (type === 'landings') {
        if (item.landingId && item.offerId) {
          data.type = 'under';
          data.landing_id = item.landingId;
          data.offer_id = item.offerId;
        } else if (item.landingId) {
          data.type = 'landing';
          data.landing_id = item.landingId;
        }
      } else if (type === 'direct') {
        data.type = 'direct';
        data.offer_id = item.offerId;
      }
      try {
        const { message, error } = await this.$api.trackers.updateWeight(this.tracker.id, data, value);
        if (error) {
          this.$n_failNotification({ title: 'An error occurred' });
          return false;
        }
        this.$n_successNotification({ title: message || 'Weight successfully updated' });
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.thrive-tracker-view {
  .tracker-view-table table {
    td.weight {
      width: 1%;
      .live-edit-wrapper {
        display: inline-block;
      }
    }
  }
}
</style>
