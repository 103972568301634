var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"alert alert-log"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('p',[_c('strong',[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm.selectedItem.formated_date))]),_c('p',[_c('strong',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.campaignName))]),_c('p',[_c('strong',[_vm._v("Status:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.htmlStatusFn(_vm.selectedItem.status))}})]),_c('p',[_c('strong',[_vm._v("Account:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.htmlAccountNameFn(_vm.selectedItem))}})])]),_c('div',{staticClass:"col-md-6 d-flex flex-column justify-content-center align-items-end"},[(_vm.$c_retryContent)?_c('b-btn',{attrs:{"size":"md","variant":"outline-info"},on:{"click":function($event){return _vm.$_retryContents(_vm.selectedItem)}}},[_c('i',{staticClass:"fa fa-repeat",attrs:{"aria-hidden":"true"}}),_vm._v(" Retry Failed Contents ")]):_vm._e(),(_vm.selectedItem.status === 'FAILED' && _vm.selectedItem.action !== 'CREATE_CONTENTS')?_c('b-btn',{attrs:{"disabled":_vm.campaignRetryLoading,"size":"md","variant":"outline-info","to":{
            name: 'CampaignCreator',
            query: {
              account: _vm.selectedItem.traffic_source_account_id,
              retry: _vm.selectedItem._id,
              mode: _vm.$_getMode(_vm.selectedItem),
            },
          }}},[_c('i',{class:[{ 'fa-spin': _vm.campaignRetryLoading }, 'fa fa-repeat'],attrs:{"aria-hidden":"true"}}),_vm._v(" Retry Failed Campaign ")]):_vm._e()],1),(_vm.selectedItem.campaign && _vm.selectedItem.campaign.error_message)?_c('b-alert',{staticClass:"error-alert",attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.selectedItem.campaign.error_message)+" ")]):_vm._e()],1)]),(_vm.selectedItem.contents && _vm.selectedItem.contents.length)?_c('div',{staticClass:"details-view-table"},[_c('b-table',{attrs:{"hover":"","items":_vm.selectedItem.contents,"fields":_vm.detailViewFields},scopedSlots:_vm._u([{key:"status",fn:function(props){return [(props.item.status === 'SUCCESSFUL')?_c('span',{staticClass:"text-success"},[_vm._v("FINISHED")]):(props.item.status === 'FAILED')?_c('span',{staticClass:"text-danger"},[_vm._v("FAILED")]):_c('span',{staticClass:"text-warning"},[_vm._v("PENDING")]),(props.item.error_message)?_c('b-alert',{staticClass:"mb-0 mt-2 px-3",attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(props.item.error_message)+" ")]):_vm._e()]}},{key:"cell(imageUrl)",fn:function(data){return [_c('img',{staticClass:"image-thumb",attrs:{"src":data.item.imageUrl}})]}}],null,false,3562863943)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }