import Vue from 'vue';
import { permissionManager } from '@paperclicks/unleash-client';

permissionManager.configure(`${process.env.VUE_APP_BASE_URL_V2}unleashproxy/features`);
permissionManager.setAuth(() => localStorage.getItem('jwt-token'));

function extractFeatureAndDefault(binding) {
  const bindingExp = binding.expression;
  const cleanExp = bindingExp.replace(/'/g, '')
    .replace(/"/g, '');
  const data = cleanExp.split(',');
  const featureName = data[0];
  const defaultValue = (data[1] && data[1] === 'true');
  return {
    featureName,
    defaultValue,
  };
}

// create v-can directive
Vue.directive('can', {
  bind(el, binding, vnode) {
    try {
      const { featureName, defaultValue } = extractFeatureAndDefault(binding);
      permissionManager.check(featureName, {}, defaultValue)
        .then((res) => {
          const { hasPermission } = res;
          if (!hasPermission) {
            vnode.elm.remove();
          }
        });
    } catch (err) {
      console.log('v-can directive error: ', err);
      vnode.elm.remove();
    }
  },
});

// create v-can-tooltip directive
Vue.directive('can-tooltip', {
  bind(el, binding, vnode) {
    try {
      const { featureName, defaultValue } = extractFeatureAndDefault(binding);
      permissionManager.check(featureName, {}, defaultValue)
        .then((res) => {
          const { hasPermission } = res;
          if (!hasPermission) {
            vnode.elm.firstChild.classList.add('no-action');
            vnode.elm.classList.add('disabled-feature');
          }
        });
    } catch (err) {
      console.log('v-can-tooltip directive error: ', err);
      vnode.elm.classList.add('disabled-feature');
    }
  },
});
