import axios from 'axios';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [],
  data() {
    return {
      resizedColumns: {},
      workbook: null,
    };
  },
  methods: {
    generateColumNames(fields) {
      const columnNames = [];
      fields.forEach((field) => {
        columnNames.push({
          header: field.header?.content,
          key: field.item?.key,
          width: field.item?.key === 'headline' ? 60 : 20,
        });
      });
      if (columnNames.find((column) => column.key === 'headline')) {
        columnNames.splice(1, 0, {
          header: 'Image',
          key: 'image_url',
          width: 30,
        });
      }
      return columnNames;
    },
    async mapRow(columns, row) {
      const rowData = {};
      columns.forEach((column) => {
        rowData[column.key] = row[column.key];
      });

      if (rowData.headline) {
        try {
          const res = await this.getBase64forImageUrl(row.image_url);
          const imgReference = this.workbook.addImage(res);
          rowData.image_url = imgReference;
        } catch (err) {
          console.log('Error while adding image to workbook', err);
        }
      }
      return rowData;
    },
    async getBase64forImageUrl(url) {
      const response = { base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=', extension: 'png' };
      if (url.length > 30) {
        const [extension] = url.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)/g) || [];
        try {
          const res = await axios.get(url, {
            responseType: 'arraybuffer',
          });
          if (extension) {
            response.base64 = `data:image/${extension};base64,${Buffer.from(res.data, 'binary').toString('base64')}`;
            response.extension = extension;
          }
        } catch (err) {
          // default image base64
          console.log('Downloading image Error', { message: err?.message, url });
        }
      }
      // Return default
      return response;
    },
    async buildXls(columns, data, worksheet = 'Contents') {
      this.workbook.getWorksheet(worksheet).columns = columns;
      const rowPromises = data.map(async (row) => this.mapRow(columns, row));
      const allRows = await Promise.all(rowPromises);
      const imageColumnIndex = columns.findIndex((column) => column.key === 'image_url');
      allRows.forEach((row, rowIndex) => {
        if (row.image_url >= 0) {
          this.workbook.getWorksheet(worksheet).addImage(row.image_url, {
            tl: { col: imageColumnIndex, row: rowIndex + 1 },
            ext: { width: 100, height: 100 },
            hyperlinks: {
              hyperlink: row.url,
              tooltip: row.headline,
            },
          });
          row.image_url = null;
        }
        this.workbook.getWorksheet(worksheet).addRow(row);
      });
    },

    async $createWorkbook({ level, campaignId, filter, fileName }) {
      const result = campaignId ? await this.$api[level].performance(campaignId, filter) : await this.$api[level].performance(filter);
      this.workbook = new ExcelJS.Workbook();
      this.worksheet = this.workbook.addWorksheet(level.toUpperCase());
      this.worksheet.properties.defaultRowHeight = 100;
      const columns = this.generateColumNames(result.fields);
      await this.buildXls(columns, result.items, level.toUpperCase());
      const xls64 = await this.workbook.xlsx.writeBuffer();
      saveAs(new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${fileName}.xlsx`);
    },
  },
});
