import '@/loaders/components';
import ActionBtn from '@sh/components/Utils/ActionBtn';
import CampaignSelect from '@sh/components/Utils/CampaignSelect/CampaignSelect.vue';
import DateRange from '@sh/components/Utils/DateRange/DateRange.vue';
import Loadizer from '@sh/components/Utils/Loadizer.vue';
import Preloader from '@sh/components/Utils/Preloader.vue';
import SingleSelect from '@sh/components/Utils/Select/Single.vue';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import SwitchButton from '@sh/components/Utils/SwitchButton';
import Vue from 'vue';
import VueSelect from 'vue-select';
import VueSlider from 'vue-slider-component';

Vue.component('date-range', DateRange);
Vue.component('single-select', SingleSelect);
Vue.component('preloader', Preloader);
Vue.component('v-select', VueSelect);
Vue.component('campaign-select', CampaignSelect);
Vue.component('switch-button', SwitchButton);
Vue.component('action-btn', ActionBtn);
Vue.component('loading-skeleton', LoadingSkeleton);
Vue.component('loadizer', Loadizer);
Vue.component('VueSlider', VueSlider);
