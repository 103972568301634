<template>
  <div class="live-edit-wrapper">
    <div
      :id="`hint-${uniqueId}`"
      :class="[{busy: (state === 'BUSY')},'live-edit']"
    >
      <b-form-input
        v-if="state === 'ACTIVE'"
        ref="input"
        v-model="localValue"
        @blur.native="blur"
        @keyup.enter.native="blur"
        @keyup.esc.native="discard"
        @keyup.down.native="$_keyArrayPress('down')"
        @keyup.up.native="$_keyArrayPress('up')"
      />
      <span
        v-else
        class="value"
        @click="focus"
      >
        <span
          v-if="prefix"
          class="prefix"
          v-html="prefix"
        /><span class="value-text">
          <slot :value="localValue">{{ localValue }}</slot>
        </span>
        <span
          v-if="suffix"
          class="suffix"
          v-html="suffix"
        />
        <span
          v-if="preview"
          :class="previewCustomStyle"
          v-html="preview"
        />
        <span v-if="errorObject.state" class="error" v-b-tooltip.hover.bottomleft :title="errorObject.message">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </span>
      </span>
    </div>
    <b-popover
      v-if="limitHint"
      :show.sync="showHint"
      triggers=""
      :target="`hint-${uniqueId}`"
    >
      <template slot="title">
        {{ limitHint.title }}
      </template>
      <p class="mb-1">
        Min: {{ limitHint.prefix || '' }}{{ limitHint.min }}{{ limitHint.suffix || '' }}
      </p>
      <p class="mb-1">
        Max: {{ limitHint.prefix || '' }}{{ limitHint.max }}{{ limitHint.suffix || '' }}
      </p>
    </b-popover>
  </div>
</template>

<script>
/* eslint-disable vue/no-v-html */
import uid from 'uid';

export default {
  name: 'LiveEdit',
  props: {
    value: { type: [String, Number] },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    preview: { type: String, default: '' },
    previewCustomStyle: { type: String, default: 'suffix' },
    format: { type: Function, default: (number) => number },
    selectOnFocus: { type: Boolean, default: false },
    beforeBlur: { type: Function, default: () => true },
    liveState: { type: Object, default: () => ({ state: 'READY' }) },
    fieldName: { type: String, default: 'Input' },
    maxLimitWarning: { type: [Number, Function, null], default: null },
    limitHint: { type: [Object, null], default: null },
    inlineEditable: { type: Boolean, default: true },
    failedItems: { type: Array, default: () => [] },
    failedItemKey: { type: [String, Number], default: '' },
  },
  data() {
    return {
      state: 'READY',
      localValue: '',
      uniqueId: uid(10),
      showHint: false,
      errorObject: {
        state: false,
        message: '',
      },
    };
  },
  computed: {
    $c_value() {
      return this.format(this.value);
    },
  },
  watch: {
    value: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(value) {
        this.localValue = this.$c_value;
      },
    },
    state: {
      immediate: true,
      handler(value) {
        this.liveState.state = value;
        if (this.limitHint) {
          if (value === 'ACTIVE' && !this.showHint) {
            this.showHint = true;
          } else if (value !== 'ACTIVE' && this.showHint) {
            this.showHint = false;
          }
        }
      },
    },
    'liveState.state': {
      handler(value) {
        this.state = value;
      },
    },
    failedItems: {
      handler(values) {
        if (values.length === 0) {
          this.errorObject = {
            state: false,
            message: '',
          };
          return;
        }
        const failed = values.find((v) => v.id === this.failedItemKey);
        if (failed) {
          this.errorObject = {
            state: true,
            message: failed.message,
          };
        }
      },
    },
  },
  methods: {
    focus() {
      if (this.state === 'READY') {
        if (this.inlineEditable) {
          this.state = 'ACTIVE';
          this.$nextTick(() => {
            this.$refs.input.$el.focus();
            if (this.selectOnFocus) this.$refs.input.$el.select();
          });
        } else {
          this.$emit('changeValue');
        }
      }
    },
    async blur() {
      if (this.state === 'ACTIVE') {
        // Has Change
        // eslint-disable-next-line eqeqeq
        if (this.localValue != this.$c_value && this.localValue.trim() !== '') {
          // Show Prompt
          const acceptMaxLimit = await this.$_validateLimits();
          if (!acceptMaxLimit || !this.$_validateLimitHint()) {
            this.localValue = this.$c_value;
            this.$refs.input.$el.focus();
            if (this.selectOnFocus) this.$refs.input.$el.select();
            return;
          }
          this.state = 'BUSY';
          const result = !!await this.beforeBlur(this.localValue);
          if (result) {
            this.$emit('input', this.format(this.localValue));
            this.localValue = this.format(this.localValue);
          } else {
            this.localValue = this.$c_value;
          }
        } else {
          this.localValue = this.$c_value;
        }
        this.state = 'READY';
      }
    },
    discard() {
      if (this.state === 'ACTIVE') {
        this.localValue = this.$c_value;
        this.state = 'READY';
      }
    },
    getState() {
      return this.state;
    },
    $_keyArrayPress(direction) {
      this.blur();
      this.$emit(direction);
    },
    async $_validateLimits() {
      const maxLimitWarning = typeof this.maxLimitWarning === 'function' ? this.maxLimitWarning() : this.maxLimitWarning;
      if (maxLimitWarning && parseFloat(this.localValue) >= maxLimitWarning) {
        const swal = await this.$swal({
          title: `${this.fieldName} seems unusually high!`,
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Keep Anyway',
          cancelButtonText: 'Reset',
        });
        // if (swal.value !== true) this.localValue = this.$c_value
        return swal.value;
      }
      return true;
    },
    $_validateLimitHint() {
      const value = parseFloat(this.localValue);
      if (this.limitHint && (value > this.limitHint.max || value < this.limitHint.min)) {
        if (this.limitHint.invalid) this.limitHint.invalid(this.limitHint);
        return false;
      }
      return true;
    },
    $_slot(key) {
      return !!this.$slots[key] || !!this.$scopedSlots[key];
    },
  },
};
</script>

<style lang="scss">
.live-edit {
  .value {
    display: inline-block;
    width: 100%;
    color: #36a8cc;
    cursor: pointer;
    .value-text {
        border-bottom: 1px dashed;
    }
   .suffix {
      font-size: 0.8em;
      color: #999;
    }
    .preview-suffix {
      font-size: 1.3rem;
      color: $color-white;
      background-color: $color-green;
      border: 1px solid $color-white;
      border-radius: 5px;
      margin-left: 1rem;
      padding: 0.3rem .5rem;
    }
    .error {
      color: $color-yellow;
      margin-left: 1rem;
      font-size: 1.7rem;
    }
  }
  &.busy {
    width: 100%;
    background-image: repeating-linear-gradient(-45deg, rgba(80, 169, 224, 0.25), rgba(80, 169, 224, 0.25) 11px, #fff 10px, #fff 20px);
    animation: move .7s linear infinite;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }
}
</style>
