<template>
  <div>
    <b-form-group
      :state="$_hasError('rotation')"
      class="rotation optimizer-form-group"
      v-show="!cmp_config.hide"
    >
      <template slot="label">
        Run this rule
        <i class="fa fa-info-circle ml-1" v-if="rotation === 'D'" v-b-tooltip.hover.top title="Rule will be executed at 12:00 UTC"></i>
        <template v-else>
          every
        </template>
      </template>
      <b-input-group>
        <vue-opti-select-light
          class="optimizer-select w-100"
          name="rotation"
          data-vv-as="Frequency"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          :value="rotation"
          @change="(option) => { rotation = option.value }"
          :options="rotationOptions"
          label-key="text"
          :disabled="cmp_config.disabled"
          single
          button-block
        >
          <template v-for="item in disabledOptions" v-slot:[`ITEM_BEFORE_${item.value}`]>
            <span
              :key="item.value"
              class="option-tooltip not-allowed"
              v-b-tooltip.hover.top="{
                customClass: 'custom-tooltip max-width-250',
              }"
              :title="'This option is not available on your current plan. Please contact support for upgrade options.'"
            />
          </template>
        </vue-opti-select-light>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('rotation') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '@sh/views/Rules/mixins/component';
import { useRuleStore } from '@sh/views/Rules/store';
import { mapState } from 'pinia';

export default {
  name: 'RuleRotation',
  mixins: [component],
  props: {
    value: { type: String, default: '' },
    isCloneRule: { type: Boolean, default: false },
  },
  data() {
    return {
      rotation: '',
      options: {
        rotations: [],
      },
      cmp_config: this.ruleModel.rule.components.rotation,
    };
  },
  computed: {
    rotationOptions() {
      return this.options.rotations.map((item) => ({
        ...item,
        disabled: (parseFloat(item.value) < 1 || item.value === 'DO') && this.noAccessRuleFrequency && this.hasCustomIntervalSelected,
      }));
    },
    disabledOptions() {
      return this.rotationOptions.filter((item) => item.disabled);
    },
    ...mapState(useRuleStore, ['hasCustomIntervalSelected', 'noAccessRuleFrequency']),
  },
  watch: {
    rotation: {
      immediate: true,
      handler() {
        this.$_emit('rotation');
      },
      deep: true,
    },
    value: {
      immediate: true,
      handler() {
        this.rotation = this.value;
      },
    },
    hasCustomIntervalSelected() {
      const selected = this.rotationOptions.find((item) => item.value === this.rotation);

      if (selected?.disabled) {
        this.rotation = '';
      }
    },
  },
  created() {
    let rotationOptions = this.config.rotationConfig.rotation;
    if (!this.isCloneRule) {
      rotationOptions = rotationOptions.filter((opt) => opt.value !== 'COO'); // COO only for clone rules
    }

    // replace placeholders
    const indefiniteEntityName = this.ruleModel.rule?.indefinite_level_rename || `a ${this.ruleModel.rule.level}`;
    rotationOptions = rotationOptions.map((rotation) => ({ ...rotation, text: rotation.text.replace('entity', indefiniteEntityName) }));

    this.options.rotations = rotationOptions;
  },
};
</script>

<style lang="scss">
.rotation .dropdown-menu {
  max-width: 36rem;
}
</style>
