import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      columnsToBeCompared: [],
    };
  },
  methods: {
    $_filterNativeFields(tsUniqueName, fields, fieldsGroup) {
      let hasGA = false;
      let hasAY = false;
      let hasGA4 = false;
      let hasCampaignMetrics = false;
      let hasCustomMetrics = false;
      let hasCustomConversions = false;
      let hasOtherMetrics = false;
      const isTikTok = tsUniqueName === 'TikTok';
      const isFacebook = tsUniqueName === 'Facebook';
      const isAmazon = tsUniqueName === 'AmazonDSP';
      const isGoogleAds = tsUniqueName === 'GoogleAds';
      const isYahooDSP = tsUniqueName === 'YahooDSP';
      fields.forEach((f) => {
        if (f.item.group === 'ga') {
          hasGA = true;
        }
        if (f.item.group === 'ga4') {
          hasGA4 = true;
        }
        if (f.item.group === 'ayf') {
          hasAY = true;
        }
        if (f.item.group === 'campaign') {
          hasCampaignMetrics = true;
        }
        if (f.item.group === 'cm') {
          hasCustomMetrics = true;
        }
        if (f.item.group === 'cc') {
          hasCustomConversions = true;
        }
        if (f.item.group === 'other') {
          hasOtherMetrics = true;
        }
      });
      const nativeFields = fieldsGroup.map((f) => ({ ...f, display: true })).map((f) => {
        if (f.value === 'ayf' && !hasAY) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'ga' && !hasGA) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'ga4' && !hasGA4) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'tiktok' && !isTikTok) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'facebook' && !isFacebook) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'amazondsp' && !isAmazon) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'googleads' && !isGoogleAds) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'yahoodsp' && !isYahooDSP) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'campaign' && !hasCampaignMetrics) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'cm' && !hasCustomMetrics) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'cc' && !hasCustomConversions) {
          return {
            ...f,
            display: false,
          };
        }
        if (f.value === 'other' && !hasOtherMetrics) {
          return {
            ...f,
            display: false,
          };
        }
        return f;
      });
      return nativeFields;
    },
    updateComparisonColumns(col, checked) {
      const existingItemIndex = this.columnsToBeCompared.findIndex((i) => i.key === col.item.key);

      if (existingItemIndex !== -1) {
        // Item exists, update it
        this.columnsToBeCompared[existingItemIndex].comparable = checked;
      } else {
        // Item does not exist, add it
        this.columnsToBeCompared.push({
          key: col.item.key,
          comparable: checked,
        });
      }
    },
    mapFields(fields) {
      const allFields = fields.map((field) => {
        const checkComparableField = this.columnsToBeCompared.find((i) => i.key === field.item.key);
        if (checkComparableField) {
          field.comparable = checkComparableField.comparable;
        }
        return field;
      }).filter((f) => !f.item.key.includes('comparable')).filter((f) => f.options);
      this.columnsToBeCompared = [];
      return allFields;
    },
  },
});
