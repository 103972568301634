import moment from 'moment';

export default {
  // getLabel(startDate, endDate) {
  //   const FORMAT = 'YYYY-MM-DD';
  //   const ranges = {};
  //   ranges[`${moment().startOf('day').format(FORMAT)}-${moment().endOf('day').format(FORMAT)}`] = 'Today';
  //   ranges[`${moment().subtract(1, 'days').startOf('day').format(FORMAT)}-${moment().subtract(1, 'days').endOf('day').format(FORMAT)}`] = 'Yesterday';
  //   ranges[`${moment().subtract(2, 'days').startOf('day').format(FORMAT)}-${moment().endOf('day').format(FORMAT)}`] = 'Last 3 days';
  //   ranges[`${moment().subtract(6, 'days').startOf('day').format(FORMAT)}-${moment().endOf('day').format(FORMAT)}`] = 'Last 7 days';
  //   ranges[`${moment().subtract(13, 'days').startOf('day').format(FORMAT)}-${moment().endOf('day').format(FORMAT)}`] = 'Last 14 days';
  //   ranges[`${moment().subtract(29, 'days').startOf('day').format(FORMAT)}-${moment().endOf('day').format(FORMAT)}`] = 'Last 30 days';
  //   ranges[`${moment().startOf('month').format(FORMAT)}-${moment().endOf('month').format(FORMAT)}`] = 'This month';
  //   ranges[`${moment().subtract(1, 'month').startOf('month').format(FORMAT)}-${moment().subtract(1, 'month').endOf('month').format(FORMAT)}`] = 'Last Month';
  //   ranges[`${moment().subtract(5, 'month').startOf('month').format(FORMAT)}-${moment().endOf('month').format(FORMAT)}`] = 'Last 6 months';
  //   const startDateFormated = moment(startDate).format(FORMAT);
  //   const endDateFormated = moment(endDate).format(FORMAT);
  //   if (ranges[`${startDateFormated}-${endDateFormated}`]) {
  //     return ranges[`${startDateFormated}-${endDateFormated}`];
  //   }
  //   return `${moment(startDate).format('MMM, D YYYY')} - ${moment(endDate).format('MMM, D YYYY')}`;
  // },
  getLabel(startDate, endDate) {
    const FORMAT = 'YYYY-MM-DD';
    const ranges = {
      Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      Yesterday: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()],
      'Last 3 days': [moment().subtract(2, 'days').startOf('day').toDate(), moment().endOf('day').toDate()],
      'Last 7 days': [moment().subtract(6, 'days').startOf('day').toDate(), moment().endOf('day').toDate()],
      'Last 14 days': [moment().subtract(13, 'days').startOf('day').toDate(), moment().endOf('day').toDate()],
      'Last 30 days': [moment().subtract(29, 'days').startOf('day').toDate(), moment().endOf('day').toDate()],
      'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
      'Last 90 days': [moment().subtract(89, 'days').startOf('day').toDate(), moment().endOf('day').toDate()],
      'Last 6 months': [moment().subtract(5, 'month').startOf('month').toDate(), moment().endOf('month').toDate()],
    };

    if (startDate && endDate) {
      let dateText = '';
      for (const key in ranges) {
        if (moment(ranges[key][0]).format(FORMAT) === startDate && moment(ranges[key][1]).format(FORMAT) === endDate) {
          if (key === 'Today') {
            dateText = `Today ( ${moment().startOf('day').format('MMM, D YYYY')} )`;
          } else if (key === 'Yesterday') {
            dateText = `Yesterday ( ${moment().subtract(1, 'days').startOf('day').format('MMM, D YYYY')} )`;
          } else {
            dateText = key;
          }
          break;
        }
      }
      return dateText.length > 0 ? dateText : `${moment(startDate).format('MMM, D YYYY')} - ${moment(endDate).format('MMM, D YYYY')}`;
    }
    return '';
  },
};
