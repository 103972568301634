const affectedOptions = [
  { value: 'campaigns', content: 'Campaigns', group: 'affected' },
  { value: 'widgets', content: 'Widgets', group: 'affected' },
  { value: 'contents', content: 'Contents', group: 'affected' },
  { value: 'targets', content: 'Targets', group: 'affected' },
  { value: 'sections', content: 'Sections', group: 'affected' },
  { value: 'adgroups', content: 'AdGroups', group: 'affected' },
  { value: 'domains', content: 'Domains', group: 'affected' },
  { value: 'spots', content: 'Spots', group: 'affected' },
];

const typeOptions = [
  { value: 'rule', content: 'Rule', group: 'type' },
  // {value: 'rollback', content: `Rollback`},
  { value: 'manual', content: 'Manual', group: 'type' },
  { value: 'list', content: 'List', group: 'type' },
];

const errorOptions = [
  { value: 'completed', content: 'Completed', group: 'status' },
  { value: 'failed', content: 'Failed', group: 'status' },
  { value: 'nochanges', content: 'No Changes', group: 'status' },
];

const logOptions = [
  { value: 'all', content: 'Show Info Logs', group: 'log' },
  { value: 'false', content: 'Hide Info Logs', group: 'log' },
];

export { affectedOptions, typeOptions, errorOptions, logOptions };
