<template>
  <div class="rule-bidCad-wrapper">
    <b-form-group :state="$_hasError('actionInput')" class="checkbox-group-wrapper">
      <template v-for="device in cadDevices">
        <RuleBidOptions
          :disabled="!form[device].status"
          :template="$c_bidCadTemplate(device)"
          :action-options="config.bidCadConfig.generateBidCadOptions(device)"
          :to-options="config.bidCadConfig.bidCadToOptions"
          :unit-options="config.bidCadConfig.bidCadUnitOptions"
          v-model="form[device]"
          :bid="ruleModel.rule.components.bidCad"
          @max-limit-exhausted="$_maxLimitExhausted(device)"
          name="Bid"
          :checkbox="true"
          :key="device"
          ref="ruleBidOptions"
        />
      </template>
      <RuleMinMax v-if="$c_showMinMax"
                  name="Bid"
                  :min="form.min"
                  @min="$_handleMin"
                  @minObject="$_handleMinObject"
                  :max="form.max"
                  @max="$_handleMax"
                  @maxObject="$_handleMaxObject"
                  :unit="ruleModel.rule.rule_action"
                  :limits="ruleModel.rule.components.bidCad.limits"
                  ref="ruleMinMax"
      />
      <small class="text-danger least-one-checkbox-message" v-if="!$c_checkboxStatus">
        Please select at least one bid to change!
      </small>
    </b-form-group>
  </div>
</template>

<script>
import RuleBidOptions from './subcomponents/RuleBidOptions.vue';
import RuleMinMax from './subcomponents/RuleMinMax.vue';
import component from '../mixins/component';

export default {
  name: 'RuleBidCad',
  components: { RuleBidOptions, RuleMinMax },
  mixins: [component],
  props: {
    value: {
      type: Object,
      default: () => ({
        desktop: {
          status: true, action: 'set', unit: 'static', to: '', value: '',
        },
        // tablet: { status: true, action: 'set', unit: 'static', to: '', value: '' },
        mobile: {
          status: true, action: 'set', unit: 'static', to: '', value: '',
        },
        min: 0,
        max: 0,
      }),
    },
  },
  data() {
    return {
      form: {},
      // cadDevices: ['desktop', 'tablet', 'mobile'],
      cadDevices: ['desktop', 'mobile'],
    };
  },
  computed: {
    $c_showMinMax() {
      try {
        return this.cadDevices.reduce((total, device) => total || ((this.form[device].action !== 'set' || this.form[device].unit === 'percentage') && this.form[device].status), false);
      } catch (error) {
        return false;
      }
    },
    $c_bidCadModel() {
      return (device) => this.ruleModel.rule.components.bidCad.value[device];
    },
    $c_bidCadTemplate() {
      return (device) => this.config.bidCadConfig.bidCadModelTemplates[this.ruleModel.rule.components.bidCad.template].device[device];
    },
    $c_checkboxStatus() {
      // eslint-disable-next-line no-restricted-syntax
      for (const device in this.form) {
        if (this.form[device] && this.form[device].status) return true;
      }
      return false;
    },
  },
  watch: {
    form: {
      handler(form) {
        if (!this.$c_showMinMax) {
          const values = Object.values(form).filter((item) => item.status && item.value !== '').map((item) => item.value);
          if (values.length) {
            this.form.min = Math.min(...values);
            this.form.max = Math.max(...values);
          }
        }
        this.$_emit('form');
      },
      deep: true,
    },
    $c_showMinMax() {
      this.form.min = '';
      this.form.max = '';
    },
  },
  created() {
    this.form = this.value;
  },
  methods: {
    async validate() {
      try {
        if (!this.$c_checkboxStatus) return false;
        let subcomponentsStatus;
        if (this.$refs.ruleMinMax) {
          subcomponentsStatus = await Promise.all([...this.$refs.ruleBidOptions.map((ref) => ref.validate()), this.$refs.ruleMinMax.validate()]);
        } else {
          subcomponentsStatus = await Promise.all(this.$refs.ruleBidOptions.map((ref) => ref.validate()));
        }
        if (subcomponentsStatus.includes(false)) {
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    async $_maxLimitExhausted(device) {
      const swal = await this.$swal({
        title: 'Bid seems unusually high!',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Keep Anyway',
        cancelButtonText: 'Reset',
      });
      if (swal.value !== true) this.form[device].value = '';
    },
    $_handleMax(value) {
      this.form.max = value;
    },
    $_handleMaxObject(objectValue) {
      if (this.form.maxObject) {
        this.form = { ...this.form, maxObject: objectValue };
      }
    },
    $_handleMin(value) {
      this.form.min = value;
    },
    $_handleMinObject(objectValue) {
      if (this.form.minObject) {
        this.form = { ...this.form, minObject: objectValue };
      }
    },
  },
};
</script>

<style lang="scss">
.rule-bidCad-wrapper {
  .rule-bid-options-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 0;

    &:first-of-type {
      margin-top: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  // .checkbox-group-wrapper {
  //   .custom-checkbox {
  //     padding: 0px 10px 0px 6px;
  //     margin: -10px 0 0 0;
  //     display: inline-block;
  //     min-height: 16px;
  //   }

  //   input.form-control {
  //     text-align: center;
  //     border-right-width: 0 !important;
  //     border-left-width: 0 !important;
  //     &[disabled] {
  //       background-color: #f5f5f5 !important;
  //       cursor: not-allowed;
  //     }
  //   }
  //   .hint-text {
  //     color: #3c3c3c;
  //     font-size: 90%;
  //   }
  // }

  // .least-one-checkbox-message {
  //   position: relative;
  //   top: -17px;
  // }
  // .limit-input-wrapper {
  //   width: 500px;
  //   .state-addon {
  //     width: 320px;
  //     @media only screen and (max-width : 575px) {
  //       width: 150px;
  //     }
  //   }
  //   input.form-control {
  //     text-align: center;
  //   }
  //   .input-group-addon {
  //     &:last-child {
  //       text-align: center;
  //       display: inline-block;
  //     }
  //   }
  // }
}
</style>
