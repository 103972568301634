import config from '@/views/Tools/Reporting/config';
import { ReportOptions } from '@/views/Tools/Reporting/types';
import { TrafficSource } from '@sh/types';

export default class ReportTypeFilter {
  constructor(
    public availableOptions?: ReportOptions,
    public trafficSource?: TrafficSource,
    private selected: string | null = null
  ) {}

  get options(): SelectOption<string>[] {
    const options = this.availableOptions?.advancedReportOptions ?? [];

    return options.reduce((filteredOptions: SelectOption<string>[], option) => {
      if (option.trafficSources?.includes(this.trafficSource ?? 'ALL')) {
        const optionId = option.reportType.toLowerCase();
        const content = config.model.getTabRenaming(`${optionId}s`, this.trafficSource ?? 'ALL') || option.reportType;

        filteredOptions.push({
          content: `${content} Report`,
          value: option.reportType,
        });
      }
      return filteredOptions;
    }, []);
  }

  get selectedOption(): SelectOption<string> | null {
    return this.options.find((option) => this.value === option.value) ?? null;
  }

  set selectedOption(option: SelectOption<string> | null) {
    this.selected = option?.value ?? null;
  }

  get value(): string | null {
    return this.selected;
  }

  set value(value: string | null) {
    this.selected = value;
  }

  get disabledText(): string {
    if (this.options.length === 0) {
      if (this.trafficSource) {
        return 'There are no report types available for selected traffic source.';
      }
      return 'Please start by choosing the traffic source type.';
    }
    return '';
  }
}
