/* eslint-disable */
// import qs from 'querystring';
const inputTypes = {
  inputText: {
    type: 'text', name: 'text', label: 'Input Text', validate: 'required', value: '',
  },
  inputNumber: {
    type: 'number', name: 'number', label: 'Input Number', validate: 'required|numeric|min_value:1', value: 0,
  },
  inputPassword: {
    type: 'password', name: 'password', label: 'Input Password', validate: 'required', value: '',
  },
  inputEmail: {
    type: 'text', name: 'email', label: 'Input Email', validate: 'email', value: '',
  },
  inputSelect: {
    type: 'select', name: 'select', label: 'Input Select', validate: 'required', value: null,
  },
  inputTimezone: {
    type: 'timezone', name: 'timezone', label: 'Timezone', title: 'Select the timezone of your traffic source account', validate: 'required', value: '',
  },
  inputCurrency: {
    type: 'currency', name: 'currency', label: 'Currency', title: 'Select the currency of your traffic source account', validate: 'required', value: 'USD',
  },
  inputAccount: {
    type: 'account', name: 'account', label: 'Account', description: 'Select Account', validate: 'required', value: null,
  },
  amazonRegion: {
    type: 'amazonRegion', name: 'amazonRegion', label: 'Amazon Region', description: 'Select Region', validate: 'required', value: null,
  },
  customizedInputTimezone: {
    type: 'mediaGoTimezone', name: 'MediaGoTimezone', label: 'MediaGo Timezone', title: 'Select the timezone', validate: 'required', value: ''
  },
  detectedTimezone: {
    type: 'detectedTimezone', name: 'detectedTimezone', label: 'Timezone', value: '', validate: 'required'
  },
  detectedCurrency: {
    type: 'detectedCurrency', name: 'detectedCurrency', label: 'Currency', value: '', validate: 'required'
  },
  detectedTsName: {
    type: 'detectedTsName', name: 'detectedTsName', label: 'Nickname', value: '',
  },
  outbrainTimezone: {
    type: 'outbrainTimezone', name: 'outbrainTimezone', label: 'Outbrain Timezone', value: '', validate: 'required',
  }
};

const objAssign = (obj, defaultObj) => ({ ...defaultObj, ...obj });
const model = {
  RevContent: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/revcontent-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'client_id', label: 'Client ID' }, inputTypes.inputPassword),
      objAssign({ name: 'client_secret', label: 'Client Secret' }, inputTypes.inputPassword),
    ],
  },
  Taboola: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/taboola-setup-guide',
    accountCredentials: ['client_id', 'client_secret'],
    accountKey: 'id',
    components: [
      objAssign({}, inputTypes.detectedTsName),
      objAssign({ name: 'client_id', label: 'Client ID' }, inputTypes.inputText),
      objAssign({ name: 'client_secret', label: 'Client Secret' }, inputTypes.inputPassword),
      objAssign({
        name: 'widget_limit', label: 'Max CPC changes per campaign', value: 200, info: 'Taboola has a default limit of max 200 widget CPC changes per campaign. If you have a custom limit please enter the value here so that you will not be limited to 200 CPC changes.',
      }, inputTypes.inputNumber),
      // objAssign({
        //   name: 'widget_blocklist_limit', label: 'Max Site block limit', value: 1500, info: 'Max number of sites you can block per campaign. Default limit is 1500.',
        // }, inputTypes.inputNumber),
      objAssign({ label: 'Taboola Account', description: '' }, inputTypes.inputAccount),
      objAssign({}, inputTypes.detectedTimezone),
      objAssign({}, inputTypes.detectedCurrency),
      ],
  },
  Outbrain: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/outbrain-setup-guide/',
    accountCredentials: ['email', 'password'],
    accountKey: 'value',
    accountError: {
      403: `<b>Your Outbrain API is not active</b><p class="mb-0">Please note that Outbrain accounts do not have API access enabled by default.
      In case you haven’t previously applied for Outbrain API access, please contact your account manager or click in the link below to apply.
      Outbrain Amplify API Access Application Form -> <a href="https://lp.outbrain.com/partner-api-form/" target="_blank">https://lp.outbrain.com/partner-api-form/</a></p>`,
      default: 'Wrong credentials or Outbrain API is not active for your account. Please contact Outbrain support (or your rep) to activate the API.',
    },
    components: [
      objAssign({}, inputTypes.detectedTsName),
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({
        name: 'section_limit', label: 'Max section blacklist', value: 1500, info: 'Outbrain has a default limit of max 1500 blacklisted sections per campaign. If you have a custom limit please enter the value here.',
      }, inputTypes.inputNumber),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ label: 'Outbrain Account', description: '' }, inputTypes.inputAccount),
      objAssign({ description: 'The selected timezone here will be the same for all the accounts created.' }, inputTypes.outbrainTimezone),
      objAssign({}, inputTypes.detectedCurrency),
    ],
  },
  YahooDSP: {
    accountCredentials: ['client_id', 'client_secret'],
    components: [
      objAssign({}, inputTypes.detectedTsName),
      objAssign({ name: 'client_id', label: 'Client ID' }, inputTypes.inputPassword),
      objAssign({ name: 'client_secret', label: 'Client Secret' }, inputTypes.inputPassword),
      // Object.assign({
      //   options: [
      //     { value: 'America/Los_Angeles', content: 'America/Los_Angeles' },
      //     { value: 'America/Denver', content: 'America/Denver' },
      //     { value: 'America/Chicago', content: 'America/Chicago' },
      //     { value: 'America/El_Salvador', content: 'America/El_Salvador' },
      //     { value: 'America/Mexico_City', content: 'America/Mexico_City' },
      //     { value: 'America/New_York', content: 'America/New_York' },
      //     { value: 'America/Bogota', content: 'America/Bogota' },
      //     { value: 'America/Lima', content: 'America/Lima' },
      //     { value: 'America/Halifax', content: 'America/Halifax' },
      //     { value: 'America/Puerto_Rico', content: 'America/Puerto_Rico' },
      //     { value: 'America/Argentina/Buenos_Aires', content: 'America/Argentina/Buenos_Aires' },
      //     { value: 'America/Santiago', content: 'America/Santiago' },
      //     { value: 'America/Sao_Paulo', content: 'America/Sao_Paulo' },
      //     { value: 'Etc/GMT', content: 'Etc/GMT' },
      //     { value: 'Europe/London', content: 'Europe/London' },
      //     { value: 'Europe/Paris', content: 'Europe/Paris' },
      //     { value: 'Asia/Jerusalem', content: 'Asia/Jerusalem' },
      //     { value: 'Asia/Dubai', content: 'Asia/Dubai' },
      //     { value: 'Asia/Karachi', content: 'Asia/Karachi' },
      //     { value: 'Asia/Dhaka', content: 'Asia/Dhaka' },
      //     { value: 'Asia/Jakarta', content: 'Asia/Jakarta' },
      //     { value: 'Australia/Perth', content: 'Australia/Perth' },
      //     { value: 'Asia/Shanghai', content: 'Asia/Shanghai' },
      //     { value: 'Asia/Tokyo', content: 'Asia/Tokyo' },
      //     { value: 'Australia/Sydney', content: 'Australia/Sydney' },
      //     { value: 'Pacific/Auckland', content: 'Pacific/Auckland' }]
      // }, inputTypes.customizedInputTimezone),
      objAssign({ label: 'Yahoo Account', description: '' }, inputTypes.inputAccount),
      objAssign({}, inputTypes.detectedTimezone),

    ],
  },
  ContentAd: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/content-ad-setup-guide/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'api_key', label: 'API Key' }, inputTypes.inputPassword),
      objAssign({ name: 'password', label: 'API Password' }, inputTypes.inputPassword),
      objAssign({ name: 'web_username', label: 'Username', validate: 'email' }, inputTypes.inputEmail),
      objAssign({ name: 'web_password', label: 'Password', validate: '', }, inputTypes.inputPassword),
    ],
    onSubmitTransform(credentials) {
      if (!credentials.web_username || !credentials.web_password) {
        const copyCredentials = { ...credentials };
        delete copyCredentials.web_username;
        delete copyCredentials.web_password;
        return copyCredentials;
      }
      return credentials;
    }
  },
  Mgid: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/mgid-setup-guide/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'client_id', label: 'Client ID', info: 'Client Id can be generated from MGID Dashboard' }, inputTypes.inputText),
      objAssign({ name: 'access_token', label: 'Access Token', info: 'Access Token can be generated from MGID Dashboard' }, inputTypes.inputPassword),
    ],
  },
  Adskeeper: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/adskeeper-setup-guide/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'client_id', label: 'Client ID', info: 'Client Id can be generated from Adskeeper Dashboard' }, inputTypes.inputText),
      objAssign({ name: 'access_token', label: 'Access Token', info: 'Access Token can be generated from Adskeeper Dashboard' }, inputTypes.inputPassword),
    ],
  },
  VoluumDSP: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/voluumdsp-setup/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'accessId', label: 'Access ID' }, inputTypes.inputPassword),
      objAssign({ name: 'accessKey', label: 'Access Key' }, inputTypes.inputPassword),
    ],
  },
  Gemini: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/yahoo-gemini-verizon-media-setup/',
    submitUrl: {
      create(form) {
        if (!form.name) return 'javascript:void(0)';
        const data = {
          url: `${location.origin}/#/account-wizard`,
          params: {
            step: 0,
            account_name: form.name,
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            settings: JSON.stringify(form.settings),
          },
        };
        return `https://api.login.yahoo.com/oauth2/request_auth?client_id=dj0yJmk9V01yY29mc0hVYzhNJmQ9WVdrOVdrbGhZM2hrTkc4bWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmeD0xNw--&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/yahoogemini/callback/?data=${btoa(JSON.stringify(data))}`;
      },
      update(form, account) {
        if (!form.name || !(account && account.id)) return 'javascript:void(0)';
        const data = {
          url: `${location.origin}/#traffic-sources`,
          params: {
            account_id: account.id,
            account_name: form.name,
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            settings: JSON.stringify(form.settings),
          },
        };
        return `https://api.login.yahoo.com/oauth2/request_auth?client_id=dj0yJmk9V01yY29mc0hVYzhNJmQ9WVdrOVdrbGhZM2hrTkc4bWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmeD0xNw--&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/yahoogemini/callback/?data=${btoa(JSON.stringify(data))}`;
      },
    },
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
    ],
  },
  Engageya: {
    infoLink: 'https://support.theoptimizer.io/204041-Engageya-Setup-Guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
    ],
  },
  Runative: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/runative-setup-guide/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'client_id', label: 'Client ID' }, inputTypes.inputText),
      objAssign({ name: 'client_secret', label: 'Client Secret' }, inputTypes.inputPassword),
    ],
  },
  ActiveRevenue: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/activerevenue-setup-guide/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
    ],
  },
  ZeroPark: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679282-zeropark-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  PushGround: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679275-pushground-setup-guide',
    components: [
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
    ],
  },
  PopCash: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679624-popcash-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  PopAds: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679610-popads-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  Propeller: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679278-propellerads-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  Kadam: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679259-kadam',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'app_id', label: 'App ID' }, inputTypes.inputText),
      objAssign({ name: 'client_id', label: 'Client ID' }, inputTypes.inputText),
      objAssign({ name: 'secret_key', label: 'Secret Key' }, inputTypes.inputPassword),
    ],
  },
  BidVertiser: {
    //infoLink: 'https://support.theoptimizer.io/',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Email' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'api_key', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  PlugRush: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679630-plugrush-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({ name: 'apiKeyV1', label: 'API Token Version 1' }, inputTypes.inputPassword),
      objAssign({ name: 'apiKeyV2', label: 'API Token Version 2' }, inputTypes.inputPassword),
    ],
  },
  ExoClick: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679597-exoclick-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  ClickAdu: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679596-clickadu-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Email' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  HillTopAds: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679593-hilltopads-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'email', label: 'Email' }, inputTypes.inputEmail),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  RtxPops: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679300-rtx-platform-pops-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
      objAssign({ name: 'secretKey', label: 'Secret Key' }, inputTypes.inputPassword),
    ],
  },
  MegaPush: {
    infoLink: 'https://support.theoptimizer.io/318277-Megapush-Setup-Guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  RichPush: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679290-richpush-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  ActiveRevenueMobile: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679584-activerevenue-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
    ],
  },
  Evadav: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679362-evadav-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  Adsterra: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679297-adsterra-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  EzMob: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679264-ezmob-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'API Key' }, inputTypes.inputPassword)
    ]
  },
  AdMaven: {
    infoLink: 'https://support.theoptimizer.io/en/articles/5679267-admaven-setup-guide',
    components: [
      objAssign({}, inputTypes.inputTimezone),
      objAssign({}, inputTypes.inputCurrency),
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword)
    ]
  },
  MediaGo: {
    infoLink: 'https://support.theoptimizer.io/en/articles/6205300-mediago-setup-guide',
    components: [
      Object.assign({ options: [{ value: 'EST', content: 'EST' }, { value: 'UTC', content: 'UTC0' }, { value: 'US/Pacific', content: 'UTC8' }] }, inputTypes.customizedInputTimezone),
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
      objAssign({}, inputTypes.inputCurrency),
    ]
  },
  ExoClickAdult: {
    infoLink: 'https://support.theoptimizer.io/755603-Connecting-ExoClick',
    components: [
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  TrafficForce: {
    infoLink: 'https://support.theoptimizer.io/120180-Connecting-TrafficForce',
    components: [
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  TrafficJunky: {
    infoLink: 'https://support.theoptimizer.io/870244-Connecting-TrafficJunky',
    components: [
      objAssign({ name: 'apiToken', label: 'Api Token' }, inputTypes.inputPassword),
    ],
  },
  TrafficStars: {
    infoLink: 'https://support.theoptimizer.io/276453-Connecting-TrafficStars',
    components: [
      objAssign({ name: 'username', label: 'Username' }, inputTypes.inputText),
      objAssign({ name: 'password', label: 'Password' }, inputTypes.inputPassword),
      objAssign({ name: 'clientId', label: 'Client ID' }, inputTypes.inputText),
      objAssign({ name: 'clientSecret', label: 'Client Secret' }, inputTypes.inputPassword),
    ],
  },
  Facebook: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/facebook-setup-guide/',
    submitUrl: {
      create(form) {
        if (!form.name) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#/account-wizard`,
          params: {
            step: 0,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            scopes: ['ads_management','ads_read','pages_show_list','pages_read_engagement','business_management']
          }
        };
        const appId = process.env.VUE_APP_FACEBOOK_APP_ID;
        const redirectUrl = `${process.env.VUE_APP_FACEBOOK_REDIRECT_URL}/facebook/callback/`;
        return `https://www.facebook.com/v10.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUrl}&state=${JSON.stringify(
          data.params
        )}&scope=ads_management,ads_read,pages_show_list,pages_read_engagement,business_management`;
      },
      update(form, account) {
        if (!form.name || !(account && account.id)) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#traffic-sources`,
          params: {
            account_id: account.id,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            updateAllCredentials: form.updateAllCredentials,
            scope: ['ads_management','ads_read','pages_show_list','pages_read_engagement','business_management']
          }
        };
        const appId = process.env.VUE_APP_FACEBOOK_APP_ID;
        const redirectUrl = `${process.env.VUE_APP_FACEBOOK_REDIRECT_URL}/facebook/callback/`;
        return `https://www.facebook.com/v10.0/dialog/oauth?state=${JSON.stringify(
          data.params
        )}&client_id=${appId}&redirect_uri=${redirectUrl}&scope=ads_management,ads_read,pages_show_list,pages_read_engagement,business_management`;
      }
    },
    components: []
  },
  // AmazonDisplay: {
  //   submitUrl: {
  //     create(form) {
  //       if (!form.name) return "javascript:void(0)";
  //       const data = {
  //         url: `${location.origin}/#/account-wizard`,
  //         params: {
  //           step: 0,
  //           account_name: form.name,
  //           account_unique_name: form.type.uniqueName,
  //           account_type_id: form.type.id,
  //           loginUrl: form.settings.amazonRegion,
  //         }
  //       };
  //       const appId = process.env.VUE_APP_AMAZON_APP_ID;
  //       return `${form.settings.amazonRegion}?client_id=${appId}&state=${btoa(JSON.stringify(data.params))}&scope=advertising::campaign_management&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/amazon/callback/`
  //     },
  //     update(form, account) {
  //       if (!form.name || !(account && account.id)) return "javascript:void(0)";
  //       const data = {
  //         url: `${location.origin}/#traffic-sources`,
  //         params: {
  //           account_id: account.id,
  //           account_name: form.name,
  //           account_unique_name: form.type.uniqueName,
  //           account_type_id: form.type.id,
  //           loginUrl: form.settings.amazonRegion,

  //         }
  //       };
  //       const appId = process.env.VUE_APP_AMAZON_APP_ID;
  //       return `${form.settings.amazonRegion}?client_id=${appId}&state=${btoa(JSON.stringify(data.params))}&scope=advertising::campaign_management&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/amazon/callback/`
  //     }
  //   },
  //   components: [Object.assign({options: [{value: "https://www.amazon.com/ap/oa", content: "North America (NA)"}, {value: "https://eu.account.amazon.com/ap/oa", content: "Europe (EU)"},{value: "https://apac.account.amazon.com/ap/oa", content: "Far East (FE)"}]}, inputTypes.amazonRegion)]
  // },
  AmazonDSP: {
    submitUrl: {
      create(form) {
        if (!form.name) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#/account-wizard`,
          params: {
            step: 0,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            loginUrl: form.settings.amazonRegion,
          }
        };
        const appId = process.env.VUE_APP_AMAZON_APP_ID;
        return `${form.settings.amazonRegion}?client_id=${appId}&state=${btoa(JSON.stringify(data.params))}&scope=advertising::campaign_management&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/amazon/callback/`
      },
      update(form, account) {
        if (!form.name || !(account && account.id)) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#traffic-sources`,
          params: {
            account_id: account.id,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            loginUrl: form.settings.amazonRegion,

          }
        };
        const appId = process.env.VUE_APP_AMAZON_APP_ID;
        return `${form.settings.amazonRegion}?client_id=${appId}&state=${btoa(JSON.stringify(data.params))}&scope=advertising::campaign_management&response_type=code&redirect_uri=https://user-manager.theoptimizer.io/amazon/callback/`
      }
    },
    components: [Object.assign({ options: [{ value: "https://www.amazon.com/ap/oa", content: "North America (NA)" }, { value: "https://eu.account.amazon.com/ap/oa", content: "Europe (EU)" }, { value: "https://apac.account.amazon.com/ap/oa", content: "Far East (FE)" }] }, inputTypes.amazonRegion)]
  },
  TikTok: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/tiktok-setup-guide/',
    submitUrl: {
      create(form) {
        if (!form.name) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#/account-wizard`,
          params: {
            step: 0,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
          }
        };
        return `https://ads.tiktok.com/marketing_api/auth?app_id=${process.env.VUE_APP_TIKTOK_APP_ID}&state=${encodeURIComponent(JSON.stringify(data.params))}&redirect_uri=${process.env.VUE_APP_TIKTOK_REDIRECT_URI}`
      },
      update(form, account) {
        if (!form.name || !(account && account.id)) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#traffic-sources`,
          params: {
            account_id: account.id,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
          }
        };
        const appId = process.env.VUE_APP_TIKTOK_APP_ID;
        const redirectUrl = `${process.env.VUE_APP_TIKTOK_REDIRECT_URI}`;
        return `https://ads.tiktok.com/marketing_api/auth?app_id=${appId}&state=${JSON.stringify(data.params)}&redirect_uri=${redirectUrl}`
      }
    },
    components: []
  },
  GoogleAds: {
    infoLink: 'https://theoptimizer.io/help/knowledge-base/google-ads-setup-gude/',
    submitUrl: {
      create(form) {
        if (!form.name) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#/account-wizard`,
          params: {
            step: 0,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            path: 'account-wizard'
          }
        };
        const appId = `${process.env.VUE_APP_GOOGLEADS_CLIENT_ID}`;
        const redirectUrl = `${process.env.VUE_APP_GOOGLEADS_REDIRECT_URI}`;
        return `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${redirectUrl}&client_id=${appId}&fetch_basic_profile=true&state=${JSON.stringify(data.params)}`
      },
      update(form, account) {
        if (!form.name || !(account && account.id)) return "javascript:void(0)";
        const data = {
          url: `${location.origin}/#/traffic-sources`,
          params: {
            account_id: account.id,
            account_name: encodeURIComponent(form.name),
            account_unique_name: form.type.uniqueName,
            account_type_id: form.type.id,
            path: 'traffic-sources'
          }
        };
        const appId = process.env.VUE_APP_GOOGLEADS_CLIENT_ID;
        const redirectUrl = `${process.env.VUE_APP_GOOGLEADS_REDIRECT_URI}`;
        return `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${redirectUrl}&client_id=${appId}&fetch_basic_profile=true&state=${JSON.stringify(data.params)}`
      }
    },
    components: []
  },
};


module.exports = { model };

