import iconsV2 from '@sh/components/Utils/iconsV2';

export enum TrafficSource {
  ActiveRevenue = 'ActiveRevenue',
  Adskeeper = 'Adskeeper',
  AmazonDisplay = 'AmazonDisplay',
  AmazonDSP = 'AmazonDSP',
  ContentAd = 'ContentAd',
  Facebook = 'Facebook',
  Gemini = 'Gemini',
  GoogleAds = 'GoogleAds',
  MediaGo = 'MediaGo',
  Mgid = 'Mgid',
  Outbrain = 'Outbrain',
  RevContent = 'RevContent',
  Runative = 'Runative',
  Taboola = 'Taboola',
  TikTok = 'TikTok',
  VoluumDSP = 'VoluumDSP',
  YahooDSP = 'YahooDSP',
}

export enum Tracker {
  Ads = 'Ads',
  AdsBridge = 'AdsBridge',
  AssertiveYield = 'AssertiveYield',
  Bemob = 'Bemob',
  Binom = 'Binom',
  Bodis = 'Bodis',
  ClickFlare = 'ClickFlare',
  CloudThrive = 'CloudThrive',
  CPVLab = 'CPVLab',
  DomainActive = 'DomainActive',
  Everflow = 'Everflow',
  FunnelFlux = 'FunnelFlux',
  FunnelFluxPro = 'FunnelFluxPro',
  GoogleAnalytics = 'GoogleAnalytics',
  GoogleAnalytics4 = 'GoogleAnalytics4',
  Kintura = 'Kintura',
  RedTrack = 'RedTrack',
  Sedo = 'Sedo',
  System1 = 'System1',
  Thrive = 'Thrive',
  Tonic = 'Tonic',
  Voluum = 'Voluum',
}

export interface Campaign {
  id: string;
  traffic_source_unique_name: string;
  traffic_source_account_id: number;
  traffic_source_campaign_id: string;
  name: string;
  enabled: boolean;
  status: string;
  managed: boolean;
  archived: boolean;
  daily_budget?: number;
  tracker_account_id: number;
  additional_fields: Record<string, any>;
}

export interface FilterDto {
  value: {
    status: string;
  };
  content: string;
  group: string;
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  enabled: boolean;
  amemberUserId: number;
  nativeAccess: boolean;
  mobileAccess: boolean;
  adultAccess: boolean;
  trackerAccess: boolean;
  roles?: string[];
}

export interface SubUser extends User {
  user_id: number;
}

export interface TrafficSourceType {
  id: number;
  name: string;
  timezone: string;
  status?: boolean;
  platform?: string;
  created_at: string;
  updated_at: string;
  uniqueName: string;
  logo?: string;
}

export interface LinkedTracker<Credentials, Variable> {
  status: number;
  id: number;
  name: string;
  credentials: Credentials;
  settings: any;
  variables: Variable[];
  sourceIdOnTracker: string;
  created_at: string;
  updated_at: string;
  trackerTypeId: number;
  uniqueName: string;
}

export interface Account<C = AccountCredentials> {
  status: number;
  id: number;
  name: string;
  credentials: C;
  created_at: string;
  settings: AccountSettings;
  type: TrafficSourceType;
  user: User;
  tags?: Array<string>;
  linkedTrackers: LinkedTracker<LinkedTrackerCredentials, Record<string, NullableType<string>>>[];
  trafficSourceType: TrafficSourceType;
}

export interface AccountCredentials {
  password: string;
  username: string;
}

export interface LinkedTrackerCredentials {
  api_key?: string;
  apiToken?: string;
  url?: string;
}

export interface ComplianceProgram {
  email: string;
  is_complianced: boolean;
  type: string;
}

export interface AccountSettings {
  currency: string;
  timezone: string;
  complianceProgram?: ComplianceProgram;
}

export type AccountTagReqType = 'add' | 'remove';

export interface AccountsTagUpdate {
  id: string[];
  tags: string[];
  type: AccountTagReqType;
}

export interface AccountTagResponse {
  [k: string]: TagUpdatePayload;
}

export interface TagUpdatePayload {
  tags: Array<string>;
  action: AccountTagReqType;
}
export interface CampaignsTagsUpdate extends TagUpdatePayload {
  campaign_ids: Array<string>;
  tsType: string;
}
export interface ContentTagsUpdate extends TagUpdatePayload {
  contentIds: Array<string>;
  tsType: string;
}

export interface Tag {
  name: string;
  selected: boolean;
}

export type IconsV2 = keyof typeof iconsV2;
