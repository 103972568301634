import notifications from '@sh/mixins/notifications';
import ui from '@sh/helpers/ui';
import { getCustomMetric, getNativeCustomMetrics } from '@sh/helpers/customMetric';
import { getFieldsWithDescription } from '@sh/configurations/fields/parser';
import { defineComponent } from 'vue';
import { AccountNameKey } from '@sh/helpers';
import { fieldsGroup } from '@sh/configurations/fields/nativeFields';

export default defineComponent({
  mixins: [notifications],
  data() {
    return {
      resizedColumns: {},
    };
  },
  async created() {
    this.$options.customMetricOptions = [];
    this.$options.metricGroupOptions = [
      { value: 'tr', content: 'Tracker Fields' },
      { value: 'ts', content: 'Traffic Sources Fields' },
      { value: 'ga', content: 'Google Analytics Fields' },
      { value: 'cc', content: 'Custom Conversions Fields' },
    ];
    this.$options.fieldsGroup = fieldsGroup;

    if (this.$platform === 'native') {
      const { fields, groups } = await getNativeCustomMetrics();
      this.$options.customMetricOptions = fields;
      this.$options.metricGroupOptions = groups;
    } else {
      this.$options.customMetricOptions = await getCustomMetric();
    }
    const { fields, groups } = await getNativeCustomMetrics();
    this.$options.customMetricOptions = fields;
    this.$options.metricGroupOptions = groups;
  },
  methods: {
    async $_saveSettingsOptions(fields, options) {
      const { type, level, preset_id } = options;
      if (fields[0].item.key === 'actions') fields.shift();
      try {
        await this.$api.configuration.updateUserTable(type, level, fields, preset_id);
        this.$n_successNotification({ title: 'Settings saved successfully' });
      } catch (error) {
        if (error.response.data.message.includes('Table is not created yet')) {
          this.$n_failNotification({ title: 'Table is not created yet' });
        } else {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    async $_saveSettingsOptionsReporting(fields, options, isReporting, preset_id) {
      const { type, level } = options;
      if (fields[0].item.key === 'actions') fields.shift();
      try {
        await this.$api.configuration.updateUserTable(type, level, fields, preset_id);
        this.$n_successNotification({ title: 'Settings saved successfully' });
      } catch (error) {
        if (error.response.data.message.includes('Table is not created yet')) {
          this.$n_failNotification({ title: 'Table is not created yet' });
        } else {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    async $_exportCsv({ level = '', campaignId = null, filter = {} }) {
      try {
        const result = campaignId
          ? await this.$api[level].performance(campaignId, filter)
          : await this.$api[level].performance(filter);
        if (result?.fields?.find((field) => field.item.key === AccountNameKey && field.display === true)) {
          result.items.forEach((item) => {
            item[AccountNameKey] = this.trafficSourceAccounts.find((account) => account.id === item.traffic_source_account_id)?.name || '';
          });
        }
        return result.items;
      } catch (error) {
        this.$n_failNotification({ title: 'Download error' });
      }
    },
    $_cpcSlotNumFormat(options) {
      if (options && typeof options.precision !== 'undefined') {
        return (num) => ui.toFixed(num, options.precision);
      }
      return ui.numFormat;
    },
    $_getFieldInfo(fields) {
      return getFieldsWithDescription(fields);
    },
    async $_updateCustomMetric(metric) {
      await this.$api.configuration.updateCustomMetric(metric);
    },
  },
});
