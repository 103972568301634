<template>
  <div class="tracker-view-wrapper">
    <preloader margin="30px" v-if="preloader" />
    <div class="animated fadeIn" v-else-if="Object.keys(trackerData).length > 0">
      <VoluumTrackerView v-if="tracker.type.uniqueName === 'Voluum'" :account="account" :tracker="tracker" :performance="trackerData" />
      <ThriveTrackerView v-else-if="tracker.type.uniqueName === 'Thrive' || tracker.type.uniqueName === 'CloudThrive'" :account="account" :tracker="tracker" :performance="trackerData" />
      <BinomTrackerView v-else-if="tracker.type.uniqueName === 'Binom'" :account="account" :tracker="tracker" :performance="trackerData" />
      <BemobTrackerView v-else-if="tracker.type.uniqueName === 'Bemob'" :account="account" :tracker="tracker" :performance="trackerData" />
    </div>
    <h5 v-else class="text-center">
      No Results.
    </h5>
  </div>
</template>

<script>
/* eslint-disable */
import helperMixins from './mixins/helperMixins';
import VoluumTrackerView from './trackerView/Voluum';
import ThriveTrackerView from './trackerView/Thrive';
import BinomTrackerView from './trackerView/Binom';
import BemobTrackerView from './trackerView/Bemob';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'TrackerView',
  components: {
    VoluumTrackerView,
    ThriveTrackerView,
    BinomTrackerView,
    BemobTrackerView,
  },
  mixins: [helperMixins, notifications],
  data() {
    return {
      trackerData: {},
    };
  },
  methods: {
    async $_init() {
      try {
        this.trackerData = await this.$api.campaigns.trackerView(this.campaign.id, this.dateRange.startDate, this.dateRange.endDate);
      } catch (error) {
        this.trackerData = {};
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.preloader = false;
    },
  },
};
</script>

<style lang="scss">
.tracker-view-wrapper {
  .totals-row {
    background-color: #fffeed;
    th {
        padding: 0.45rem 0.75rem;
    }
  }
}
</style>
