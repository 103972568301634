<template>
  <div class="rule-flags-wrapper">
    <b-form-group
      :state="$_hasError('optionInput')"
      class="optimizer-form-group"
      :invalid-feedback="$_getError('optionInput')"
    >

      <b-form-checkbox-group
        v-model="selected"
        :options="ruleModel.rule.components.flags.options"
        stacked
        name="optionInput"
        data-vv-as="Option"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleFlags',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: [],
      options: [
        { text: 'Apply to active widgets only', value: true },
        { text: 'Apply to all widgets (Paused widgets will be activated)', value: false },
      ],
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.$_emit('selected');
      },
      deep: true,
    },
  },
  created() {
    this.selected = this.value;
  },
  methods: {
  },
};
</script>

<style lang="scss">
.rule-flags-wrapper {
  margin-top: 1.5rem;

  .custom-checkbox {
    .custom-control-label {
      padding-left: .7rem;
      padding-top: .7rem;

      &::before {
        height: 1.5rem;
        width: 1.5rem;
        top: 1rem;
        box-shadow: none;
      }

      &::after {
        height: 1.5rem;
        width: 1.5rem;
        top: 1rem;
      }
    }
  }
}
</style>
