import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import moment from 'moment';

class Session {
  constructor() {
    this._token = null;
    this._user = null;
    this._cb = [];
    this._cb_logOut = [];
    this._cb_once = [];
    this._cb_logOut_once = [];
    this.loadToken();
  }

  get isSearchFeed() {
    return this._user?.roles?.includes('ROLE_USER_COMPLIANCE');
  }

  get hasIntegration() {
    return isUndefined(this._user?.trafficSources) || this._user?.trafficSources?.length;
  }

  /**
   * Load token
   */
  loadToken() {
    const token = localStorage.getItem('jwt-token');
    if (token) {
      this.setToken(token);
      if (!this.getToken()) {
        localStorage.removeItem('jwt-token');
      }
    }
  }

  /**
   * Check if user is authenticated
   * @returns {boolean}
   */
  isAuthenticated() {
    try {
      const jwt = localStorage.getItem('jwt-token') || '';
      const decodedToken = jwtDecode(jwt);
      const currentTime = moment().utc().unix();
      return !!jwt && !!decodedToken.exp && decodedToken.exp > currentTime;
    } catch (e) {
      return false;
    }
  }

  getUser() {
    return this._user;
  }

  /**
   * Set user's token
   * @param token
   */
  setToken(token) {
    try {
      this._token = token;
      this._user = jwtDecode(token);
      localStorage.setItem('jwt-token', token);
      this._cb.forEach((cbFunc) => (cbFunc(this)));
      this._cb_once.forEach((cbFunc) => (cbFunc(this)));
      this._cb_once = [];
    } catch (err) {
      console.log(err);
      this._user = null;
      this._token = null;
    }
  }

  /**
   * Get user's token
   * @returns {null|*}
   */
  getToken() {
    return this._token;
  }

  /**
   * Remove user's token
   */
  removeToken() {
    this._token = null;
    this._user = null;
    localStorage.removeItem('jwt-token');
    this._cb_logOut.forEach((cbFunc) => (cbFunc(this)));
    this._cb_logOut_once.forEach((cbFunc) => (cbFunc(this)));
    this._cb_logOut_once = [];
  }

  /**
   * Set after authenticated callback
   * @param cb
   */
  afterAuthentication(cb, mode = '') {
    mode === 'once' ? this._cb_once.push(cb) : this._cb.push(cb);
  }

  /**
   * Set after logout callback
   * @param cb
   */
  afterLogout(cb, mode = '') {
    mode === 'once' ? this._cb_logOut_once.push(cb) : this._cb_logOut.push(cb);
  }

  setTrafficSources(trafficSources) {
    this._user.trafficSources = trafficSources ?? [];
  }

  setTrackers(trackers) {
    this._user.trackers = trackers;
  }
}

const session = new Session();
session.loadToken();

export default session;
