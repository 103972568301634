<template>
  <div class="campaign-creator-form">

    <!-- BASIC INFO SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>Basic Info</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Objective -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('objective')" v-bind="$_getLabelProps('objective')">
              <cc-radio-button v-bind="$_getProps('objective')" v-on="$_getEvents('objective')" v-model="$c_components['objective'].value" />
            </component-wrapper>
          </div>

          <!-- STATUS SECTION -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('enabled')" v-bind="$_getLabelProps('enabled')">
              <cc-radio-button v-bind="$_getProps('enabled')" v-on="$_getEvents('enabled')" v-model="$c_components['enabled'].value" />
            </component-wrapper>
          </div>

          <!-- Optimization Type -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('optimizationType')" v-bind="$_getLabelProps('optimizationType')">
              <cc-radio-button v-bind="$_getProps('optimizationType')" v-on="$_getEvents('optimizationType')" v-model="$c_components['optimizationType'].value" />
            </component-wrapper>
          </div>

          <!-- MAX CPC BID PERCENTAGE IF OPTIMIZATIONTYPE == CPC -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('maxCpcBidPercentage')" v-bind="$_getLabelProps('maxCpcBidPercentage')">
              <cc-input v-bind="$_getProps('maxCpcBidPercentage')" v-on="$_getEvents('maxCpcBidPercentage')" v-model="$c_components['maxCpcBidPercentage'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- CREATIVE FORMAT -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Creative format</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('creativeFormat')" v-bind="$_getLabelProps('creativeFormat')">
              <cc-radio-button v-bind="$_getProps('creativeFormat')" v-on="$_getEvents('creativeFormat')" v-model="$c_components['creativeFormat'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>
    <!-- SCHEDULING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Scheduling</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Date Scheduling -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('runForever')" v-bind="$_getLabelProps('runForever')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('runForever')" v-on="$_getEvents('runForever')" v-model="$c_components['runForever'].value" />
                </div>

                <div class="col-md-12 d-flex mt-3">
                  <cc-date-picker v-if="$_isActive('startDate')" v-bind="$_getProps('startDate')" v-on="$_getEvents('startDate')" v-model="$c_components['startDate'].value" />

                  <!-- Time Scheduling -->
                  <component-wrapper class="outbrain-start-time ml-3 mr-4" v-if="$_isActive('startHour')" v-bind="$_getLabelProps('startHour')">
                    <cc-time-picker v-bind="$_getProps('startHour')" v-on="$_getEvents('startHour')" v-model="$c_components['startHour'].value" />
                  </component-wrapper>

                  <cc-date-picker v-if="$_isActive('endDate')" v-bind="$_getProps('endDate')" v-on="$_getEvents('endDate')" v-model="$c_components['endDate'].value" class="ml-3" />
                </div>
              </div>

            </component-wrapper>
          </div>

          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('onAirType')" v-bind="$_getLabelProps('onAirType')">
              <cc-checkbox v-bind="$_getProps('onAirType')" v-on="$_getEvents('onAirType')" v-model="$c_components['onAirType'].value" />
            </component-wrapper>
          </div>
          <!-- Day Scheduling -->
          <div class="col-md-12 col-xl-10">
            <component-wrapper v-if="$_isActive('activityScheduleRules')" v-bind="$_getLabelProps('activityScheduleRules')">
              <cc-activity-schedule v-bind="$_getProps('activityScheduleRules')" v-on="$_getEvents('activityScheduleRules')" v-model="$c_components['activityScheduleRules'].value" :showTimeZones="false" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>
    <!-- Conversion bid strategy -->
    <div class="form-section" v-if="$_isActive('conversionBidStrategySection')">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Conversion bid strategy</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('campaignOptimizationType')" v-bind="$_getLabelProps('campaignOptimizationType')">
              <cc-radio-button v-bind="$_getProps('campaignOptimizationType')" v-on="$_getEvents('campaignOptimizationType')" v-model="$c_components['campaignOptimizationType'].value" />
            </component-wrapper>
          </div>

          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('conversions')" v-bind="$_getLabelProps('conversions')">
              <cc-multi-select v-if="$_isActive('conversions')" v-bind="$_getProps('conversions')" v-on="$_getEvents('conversions')" v-model="$c_components['conversions'].value" />
            </component-wrapper>
          </div>
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('softConversions')" v-bind="$_getLabelProps('softConversions')">
              <cc-multi-select v-if="$_isActive('softConversions')" v-bind="$_getProps('softConversions')" v-on="$_getEvents('softConversions')" v-model="$c_components['softConversions'].value" />
            </component-wrapper>
          </div>
          <!-- Target ROAS -->
          <div class="col-md-6" v-if="$_isActive('targetROAS')">
            <component-wrapper v-bind="$_getLabelProps('targetROAS')">
              <cc-input v-bind="$_getProps('targetROAS')" v-on="$_getEvents('targetROAS')" v-model="$c_components['targetROAS'].value" />
            </component-wrapper>
          </div>
          <!-- Target CPA -->
          <div class="col-md-6" v-if="$_isActive('targetCPA')">
            <component-wrapper v-bind="$_getLabelProps('targetCPA')">
              <cc-input v-bind="$_getProps('targetCPA')" v-on="$_getEvents('targetCPA')" v-model="$c_components['targetCPA'].value" />
            </component-wrapper>
          </div>
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('semiManualOptimization')" v-bind="$_getLabelProps('semiManualOptimization')">
              <cc-radio-button v-bind="$_getProps('semiManualOptimization')" v-on="$_getEvents('semiManualOptimization')" v-model="$c_components['semiManualOptimization'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- BUDGETING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">{{ $_calculateBudgetSectionIndex() }}</span>
        <h1>Budgeting & Bidding</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <!-- Cpc -->
          <div class="col-md-6" v-if="$_isActive('cpc')">
            <component-wrapper v-bind="$_getLabelProps('cpc')">
              <cc-input v-bind="$_getProps('cpc')" v-on="$_getEvents('cpc')" v-model="$c_components['cpc'].value" />
            </component-wrapper>
          </div>
          <!-- Budget Amount -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('amount')" v-bind="$_getLabelProps('amount')">
              <div class="row">
                <div class="col">
                  <cc-input v-bind="$_getProps('amount')" v-on="$_getEvents('amount')" v-model="$c_components['amount'].value" />
                </div>
                <div class="col-auto" v-if="$_isActive('budgetType')">
                  <cc-radio-button v-bind="$_getProps('budgetType')" v-on="$_getEvents('budgetType')" v-model="$c_components['budgetType'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Pacing -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('pacing')" v-bind="$_getLabelProps('pacing')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('pacing')" v-on="$_getEvents('pacing')" v-model="$c_components['pacing'].value" />
                </div>
                <div class="col-md-6" v-if="$_isActive('dailyTarget')">
                  <cc-input v-bind="$_getProps('dailyTarget')" v-on="$_getEvents('dailyTarget')" v-model="$c_components['dailyTarget'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Bid Cap -->
          <div class="col-md-6">
            <div v-if="$_isActive('automaticModeMaxCpc')" class="flex-container">
              <component-wrapper v-bind="$_getLabelProps('bidCap')" class="component-wrapper-margin"></component-wrapper>
              <switch-button v-model="$c_components['automaticModeMaxCpc'].switchButtonChecked" class="switch-button-margin"></switch-button>
            </div>
            <component-wrapper v-if="$c_components['automaticModeMaxCpc'].switchButtonChecked" v-bind="$_getLabelProps('automaticModeMaxCpc')">
              <cc-input v-bind="$_getProps('automaticModeMaxCpc')" v-on="$_getEvents('automaticModeMaxCpc')" v-model="$c_components['automaticModeMaxCpc'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TARGETING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">6</span>
        <h1>Targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('includeLocation')" v-bind="$_getLabelProps('includeLocation')">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <cc-radio-button v-bind="$_getProps('includeLocation')" v-on="$_getEvents('includeLocation')" v-model="$c_components['includeLocation'].value" />
                </div>
                <div class="col-md-5">
                  <cc-multi-select v-if="$_isActive('locations')" v-bind="$_getProps('locations')" v-on="$_getEvents('locations')" v-model="$c_components['locations'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('excludeLocation')" v-bind="$_getLabelProps('excludeLocation')">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <cc-radio-button v-bind="$_getProps('excludeLocation')" v-on="$_getEvents('excludeLocation')" v-model="$c_components['excludeLocation'].value" />
                </div>
                <div class="col-md-5">
                  <cc-multi-select v-if="$_isActive('excludedLocations')" v-bind="$_getProps('excludedLocations')" v-on="$_getEvents('excludedLocations')" v-model="$c_components['excludedLocations'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Language-->
          <div class="col-md-3">
            <component-wrapper v-if="$_isActive('language')" v-bind="$_getLabelProps('language')">
              <cc-multi-select v-bind="$_getProps('language', mode === 'edit')" v-on="$_getEvents('language')" v-model="$c_components['language'].value" />
            </component-wrapper>
          </div>

          <!-- Platform -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('platform')" v-bind="$_getLabelProps('platform')">
              <cc-checkbox v-bind="$_getProps('platform')" v-on="$_getEvents('platform')" v-model="$c_components['platform'].value" />
            </component-wrapper>
          </div>

          <!-- Operating Systems -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('operatingSystems')" v-bind="$_getLabelProps('operatingSystems')">
              <cc-checkbox v-bind="$_getProps('operatingSystems')" v-on="$_getEvents('operatingSystems')" v-model="$c_components['operatingSystems'].value" />
            </component-wrapper>
          </div>

          <!-- Browsers -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('browsers')" v-bind="$_getLabelProps('browsers')">
              <cc-checkbox v-bind="$_getProps('browsers')" v-on="$_getEvents('browsers')" v-model="$c_components['browsers'].value" />
            </component-wrapper>
          </div>

          <!-- Outbrain Extended Network -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('extendedNetworkTraffic')" v-bind="$_getLabelProps('extendedNetworkTraffic')">
              <cc-checkbox v-bind="$_getProps('extendedNetworkTraffic')" v-on="$_getEvents('extendedNetworkTraffic')" v-model="$c_components['extendedNetworkTraffic'].value" />
            </component-wrapper>
          </div>

          <!-- AdBlock -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('excludeAdBlockUsers')" v-bind="$_getLabelProps('excludeAdBlockUsers')">
              <cc-checkbox v-bind="$_getProps('excludeAdBlockUsers')" v-on="$_getEvents('excludeAdBlockUsers')" v-model="$c_components['excludeAdBlockUsers'].value" />
            </component-wrapper>
          </div>

          <!-- NetworkMSN -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('networkMSN')" v-bind="$_getLabelProps('networkMSN')">
              <cc-checkbox v-bind="$_getProps('networkMSN')" v-on="$_getEvents('networkMSN')" v-model="$c_components['networkMSN'].value" />
            </component-wrapper>
          </div>

          <!-- Native Placements -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('nativePlacementsEnabled')" v-bind="$_getLabelProps('nativePlacementsEnabled')">
              <cc-checkbox v-bind="$_getProps('nativePlacementsEnabled')" v-on="$_getEvents('nativePlacementsEnabled')" v-model="$c_components['nativePlacementsEnabled'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- Audience Targeting -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">7</span>
        <h1>Audience targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('audienceTargeting')" v-bind="$_getLabelProps('audienceTargeting')">
              <div class="row mb-2">
                <div class="col-md-12 mb-5">
                  <cc-radio-button v-bind="$_getProps('audienceTargeting')" v-on="$_getEvents('audienceTargeting')" v-model="$c_components['audienceTargeting'].value" />
                </div>
              </div>
              <div class="row">
                <div v-if="$_isActive('iabCategories') || $_isActive('interests')" class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('addOrRemoveOptions')" v-on="$_getEvents('addOrRemoveOptions')" v-model="$c_components['addOrRemoveOptions'].value" />
                </div>
                <div v-if="$_isActive('iabCategories')" class="col-md-6">
                  <cc-targeting-categories labelText="+ Add Categories" v-bind="$_getProps('iabCategories')" v-on="$_getEvents('iabCategories')" v-model="$c_components['iabCategories'].value" />
                </div>
                <div v-if="$_isActive('interests')" class="col-md-6">
                  <cc-targeting-categories labelText="+ Add Interests" v-bind="$_getProps('interests')" v-on="$_getEvents('interests')" v-model="$c_components['interests'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TRACKING -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">8</span>
        <h1>Tracking</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Tracking Code -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('suffixTrackingCode')" v-bind="$_getLabelProps('suffixTrackingCode')">
              <cc-textarea v-bind="$_getProps('suffixTrackingCode')" v-on="$_getEvents('suffixTrackingCode')" v-model="$c_components['suffixTrackingCode'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'OutbrainForm',
  mixins: [formMixin],
  methods: {
    $_calculateBudgetSectionIndex() {
      if (this.$_isActive('conversionBidStrategySection')) {
        return 6;
      }
      return 5;
    },
  },
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;

      .outbrain-start-time .optimizer-form-group {
        margin-bottom: 0;

        .btn.dropdown-toggle.btn-secondary {
          height: 3.7rem;
        }
      }

      .flex-container {
        display: inline-flex;
      }

      .component-wrapper-margin {
        margin-right: 0.5rem;
      }

      .switch-button-margin {
        margin-top: -0.38rem;
      }
    }
  }
</style>
