/* eslint-disable import/no-cycle */
import * as ABTypes from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as Campaign from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { AdABGroups } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import { AdSetAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import { CampaignAbGroupTypes, CampaignValidation } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import { FacebookDTOMetrics } from '../validation/CampaignDTO';
import { ErrorData } from './AbVariables';

export enum ErrorType {
  BLOCKING = 'BLOCKING', // Prevents user to continue, eg. invalid input
  MISSING = 'MISSING', // There is a missing part from the payload (eg. user selects something that enables sth else to be required), prevents user from continuing
  SILENT = 'SILENT', // Generated variation has issues but user can continue
}

export type InternalABVariable<T> = ABTypes.ABValue<T> & {
  abGroup?: string;
  variableId: string;
  errors?: ErrorData<any, any, any>[];
  name?: string;
};

export type Field<V> = {
  value: Array<InternalABVariable<V>>;
  fieldGroup?: string;
  key?: string;
  ab?: boolean;
};

export type ICampaignCreate = Pick<Campaign.Create, keyof typeof CampaignValidation>;
interface InternalCampaignMetadata {
  enable_budget_for_campaign?: boolean;
  adsets: string[];
  internalId: string;
}
export type ABTestableFields = Omit<ICampaignCreate, 'internalId'>;
export type CampaignState = InternalCampaignMetadata;

export interface FieldError {
  errorId: string;
  message: string;
  description: string;
  field?: string;
  requiresAction: boolean;
}

export enum SupportedEntities {
  campaign = 'campaign',
  adset = 'adset',
  targeting = 'targeting',
  ad = 'ad',
  creative = 'creative',
}

export type MainEntities = SupportedEntities.campaign | SupportedEntities.adset | SupportedEntities.ad;

export type AbTypesByEntity = {
  [SupportedEntities.campaign]: CampaignAbGroupTypes | 'DEFAULT' | 'MAIN';
  [SupportedEntities.adset]: AdSetAbGroups | 'DEFAULT' | 'MAIN';
  [SupportedEntities.ad]: AdABGroups | 'DEFAULT' | 'MAIN';
  [SupportedEntities.creative]: CampaignAbGroupTypes.DEFAULT | 'DEFAULT' | 'MAIN';
  [SupportedEntities.targeting]: CampaignAbGroupTypes.DEFAULT | 'DEFAULT' | 'MAIN';
};

export function getIdForEntity(entity: SupportedEntities, internalIds: string[]) {
  return `${entity}-${internalIds.join('_')}`;
}
export interface ABGroupMetadata<E extends SupportedEntities> {
  name: string;
  entity: SupportedEntities;
  id: string; // Unique identifier for the AB group
  typeId: AbTypesByEntity[E]; // ABTest BudgetGroup (All Ab groups that work with budget will use same typeId)
  description: string;
  dependencies?: string[];
}

export interface CampaignStore {
  globalErrors: FieldError[];
  adAccountId: string;
  campaigns: Record<string, CampaignState>;
  abUpdated?: Date;
  ruleIds: string[];
  templates: Array<{
    metadata: FacebookDTOMetrics;
    name: string;
    _id: string;
    public: boolean;
    state: Record<string, any>;
    updatedAt?: string;
    createdAt?: string;
  }>;
  currentTemplate: {
    name: string;
    id: string;
    lastSaved: number;
  };
  templatesLoading: boolean;
}
