<template>
  <b-modal
    modal-class="optimizer-modal invite-modal"
    size="lg"
    v-model="modal"
    centered
    @hidden="$_resetForm"
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Invite User</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>


    <div class="invite-modal-content" v-if="!preloader">
      <b-alert show class="subusers-settings-wrapper__alert">
        Please enter the email address of the user you want to invite, and select the accounts you want to give access to.
        <br />
        You can modify the account access at any moment later.
        <br />
        They will receive an email with the invitation.
      </b-alert>

      <!-- Email -->
      <b-form-group
        class="optimizer-form-group"
        label="Email:"
        :invalid-feedback="$_getError('email')"
        :state="$_hasError('name')"
      >
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Email"
          :state="$_hasError('email')"
          v-validate="{ required: true, email: true }"
          type="text"
          v-model="form.email"
          name="email"
          key="email"
          placeholder="Email"
        />
      </b-form-group>

      <!-- Accounts -->
      <b-form-group class="optimizer-form-group" label="Accounts:" :state="$_hasError('accounts')">
        <vue-opti-select-light
          class="optimizer-select"
          :options="$options.trafficSourceAccounts"
          :groups="$options.trafficSourceTypesGroup"
          unique-key="id"
          label-key="name"
          option-type="checkbox"
          searchable
          :search-fields="['name']"
          @change="(options) => {form.accounts = options.map(({id}) => id); }"
          :value="form.accounts"
          name="accounts"
          data-vv-as="Accounts"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          button-placeholder="Select Accounts"
          button-type="tag"
          button-block
        >
          <template #ITEM="{ option }">
            <img class="acc-item-icon" :src="getTrafficSourceLogo(option.trafficSourceType.uniqueName)" :alt="option.trafficSourceType.uniqueName" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
          <template #TAG_LABEL="{ option }">
            <img class="acc-item-icon" :src="getTrafficSourceLogo(option.trafficSourceType.uniqueName)" :alt="option.trafficSourceType.uniqueName" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
        </vue-opti-select-light>
        <small slot="invalid-feedback">{{ $_getError('accounts') }}</small>
      </b-form-group>
    </div>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn class="primary-button" @click="$_addAccount">Invite</b-btn>
    </template>
  </b-modal>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'InviteModal',
  mixins: [notifications],
  data() {
    return {
      modal: false,
      preloader: false,
      form: {
        email: '',
        accounts: [],
      },
    };
  },
  async created() {
    this.preloader = true;
    const [trafficSourceTypes, trafficSourceAccounts] = await Promise.all([this.$apiStore.trafficSources.types(), this.$apiStore.trafficSources.accounts()]);
    this.$options.trafficSourceTypesGroup = trafficSourceTypes.map((type) => ({ value: type.uniqueName, content: type.name }));
    this.$options.trafficSourceAccounts = trafficSourceAccounts.map((account) => ({ ...account, group: account.trafficSourceType.uniqueName }));
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    async $_addAccount(event) {
      event.preventDefault();
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          // Success, Validation pass
          this.$emit('add', JSON.parse(JSON.stringify(this.form)));
          this.hide();
        } else {
          this.$n_warnNotification({ title: 'Invalid form' });
        }
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    $_resetForm() {
      this.form = {
        email: '',
        accounts: [],
      };
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
  },
};
</script>

<style lang="scss">
.invite-modal-content {
  .optimizer-select {
    .button-placehoder-tag {
      .tag-item {
        .acc-item-icon {
          height: 25px;
          margin-right: 5px;
          display: inline-block;
        }
        .acc-item-name {
          display: inline-block;
        }
      }
    }
  }
}
</style>
