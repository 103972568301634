import { apiStore } from '@sh/services/api';
import { defineComponent } from 'vue';

const presetHelper = {
  data() {
    return {
      trackerTypesLinkedToTrafficSource: [],
    };
  },
  methods: {
    async getTrackerTypesLinkedToTrafficSource(currentTrafficSource: string): Promise<string[]> {
      let accounts = await apiStore.trafficSources.accounts();
      accounts = accounts.filter((a) => a.type.uniqueName === currentTrafficSource);
      return Array.from(new Set(accounts.map((a) => a.linkedTrackers.map((lt) => lt.uniqueName)).flat()));
    },
  },
};

export default defineComponent(presetHelper);
