import PresetLocalStorage from '@sh/lib/Presets.class';
import { api, apiStore } from '@sh/services/api';
import session from '@sh/services/session';
import { Presets, User } from '@sh/types';
import { defineStore } from 'pinia';

interface Settings {
  getPresetByTrafficSource: (storageKey: string) => string;
  setPresetByTrafficSource: (storageKey: string, value: string) => void;
}

interface Filter {
  type: {
    uniqueName: string;
  };
  trackers?: Array<{ uniqueName: string }>;
  linkedTrackers?: Array<{ uniqueName: string }>;
  scope?: Presets.PresetScope;
}

interface PresetStore {
  presetList: Presets.PresetList;
  selectedPreset: Presets.Preset;
  settings: Settings;
  filter: Filter;
  linkedTrackers?: string[];
  user: User;
  makePresetPublicAfterCreation?: boolean;
}

type Response = Presets.Preset;

export const usePresetStore = defineStore('presets', {
  state: (): PresetStore => ({
    presetList: {} as Presets.PresetList,
    selectedPreset: {} as Presets.Preset,
    settings: new PresetLocalStorage(),
    filter: {
      type: {
        uniqueName: '',
      },
    },
    linkedTrackers: [],
    user: session.getUser(),
    makePresetPublicAfterCreation: false,
  }),
  getters: {
    presets(state) {
      return state.presetList;
    },
    getUserConfig(state) {
      const currentTsType = state.filter.type.uniqueName;
      const trackers = state.filter?.trackers || state.filter?.linkedTrackers;
      const selectedTrackerTypes = trackers?.map((tracker: any) => tracker.uniqueName);
      const currentTrTypes = selectedTrackerTypes?.length ? selectedTrackerTypes : state.linkedTrackers;

      return { currentTrTypes, currentTsType };
    },
    getCurrentTrafficSourceType(state) {
      return state.filter.type.uniqueName;
    },
    getColumnPreset(state) {
      return state.settings.getPresetByTrafficSource(state.filter.type.uniqueName);
    },
    hasSubuserFeatureAccess(state): boolean {
      return (state.user.roles?.includes('ROLE_ALLOW_MULTIUSER') && !this.selectedPreset?.suggestedPreset) || false;
    },
  },
  actions: {
    async createPreset(preset: Presets.Preset, level: string) {
      const payload = this.buildPresetPayload(level, preset);
      const response: Response = (await api.presets.createPreset(payload)) as unknown as Response;

      if (response) {
        const preset = {
          _id: response._id,
          name: response.name,
          scopes: response.scopes,
          created_at: response.created_at,
          fields: response.fields,
        };
        this.settings.setPresetByTrafficSource(this.filter.type.uniqueName, response._id);
        this.updateSelectedPreset(preset);
      }
      if (this.makePresetPublicAfterCreation) {
        const subusers = await api.subusers.getAllSubusers();
        const subuserIds = subusers.map((s) => s.user_id);
        await api.presets.addSubUsersToPreset(this.selectedPreset, subuserIds);
        this.makePresetPublicAfterCreation = false;
      }
      apiStore.presets.getPresets.clearCache();
      apiStore.presets.getPresets();
      await this.getFilteredPresets();
    },
    async getPresets() {
      return api.presets.getPresets();
    },
    async deletePreset(preset: Presets.Preset) {
      await api.presets.deletePreset(preset._id);
      if (preset._id === this.selectedPreset._id) {
        this.updateSelectedPreset({} as Presets.Preset);
      }
      await this.getFilteredPresets();
    },
    async editPresetName(preset: Presets.Preset) {
      await api.presets.editPresetName(this.selectedPreset._id, preset.name);
      await this.getFilteredPresets();
    },
    async getFilteredPresets() {
      const presets = await apiStore.presets.getPresets();
      if (!presets) return;
      this.filterPresets(presets);
      this.updateLocalStorage();

      this.presetList = {
        user_presets: this.sortPresetsBySelected(this.presetList.user_presets),
        suggested_presets: this.sortPresetsBySelected(this.presetList.suggested_presets),
      };
    },
    async changePreset(preset: Partial<Presets.Preset>) {
      const suggestedPreset = this.presetList.suggested_presets.find((p) => p._id === preset._id);
      const userPreset = this.presetList.user_presets.find((p) => p._id === preset._id);
      if (suggestedPreset) this.selectedPreset = suggestedPreset;
      if (userPreset) this.selectedPreset = userPreset;
      this.settings.setPresetByTrafficSource(this.filter.type.uniqueName, this.selectedPreset._id);

      this.presetList = {
        user_presets: this.sortPresetsBySelected(this.presetList.user_presets),
        suggested_presets: this.sortPresetsBySelected(this.presetList.suggested_presets),
      };
    },
    updateSelectedPreset(preset: Presets.Preset) {
      this.selectedPreset = preset;
    },
    filterPresets(presets: Presets.PresetList) {
      const { currentTsType, currentTrTypes } = this.getUserConfig;

      const globalUserPresets = presets.user_presets.filter((preset) => {
        for (const scope of preset.scopes) {
          if (scope.constraint === Presets.ConstraintEnum.AllTrackers) return preset;
          if (scope.constraint === Presets.ConstraintEnum.AllTrafficSources) return preset;
        }
        return false;
      });
      const userFilteredPresets: Presets.Preset[] = [];

      presets.user_presets.forEach((preset) => {
        for (const scope of preset.scopes) {
          const { constraint } = scope;
          if (constraint === Presets.ConstraintEnum.TrafficSourceUniqueName) {
            if (scope.values?.availableTsTypes?.includes(currentTsType)) {
              userFilteredPresets.push(preset);
            }
          }

          if (constraint === Presets.ConstraintEnum.TrackerUniqueName) {
            if (scope.values?.availableTrTypes?.some((tr) => currentTrTypes?.includes(tr))) {
              userFilteredPresets.push(preset);
            } else {
              // if preset has passed the TrafficSourceUniqueName filter, but hasn't passed the TrackerUniqueName filter it should be removed from the list
              const index = userFilteredPresets.findIndex((p) => p._id === preset._id);
              if (index !== -1) userFilteredPresets.splice(index, 1);
            }
          }
        }
      });

      const globalSuggestedPresets = presets.suggested_presets.filter((preset) => {
        for (const scope of preset.scopes) {
          if (scope.constraint === Presets.ConstraintEnum.AllTrackers) return preset;
          if (scope.constraint === Presets.ConstraintEnum.AllTrafficSources) return preset;
        }
        return false;
      });

      const suggestedFilteredPresets: Presets.Preset[] = [];

      presets.suggested_presets.forEach((preset) => {
        for (const scope of preset.scopes) {
          const { constraint } = scope;
          if (constraint === Presets.ConstraintEnum.TrafficSourceUniqueName) {
            if (scope.values?.availableTsTypes?.includes(currentTsType)) {
              const index = suggestedFilteredPresets.findIndex((p) => p._id === preset._id);
              if (index === -1) suggestedFilteredPresets.push(preset);
              // if no ts types are available, filtering process should be skipped
            } else if (scope.values?.availableTsTypes?.length > 0) return;
          }

          if (constraint === Presets.ConstraintEnum.TrackerUniqueName) {
            if (scope.values?.availableTrTypes?.some((tr) => currentTrTypes?.includes(tr))) {
              const index = suggestedFilteredPresets.findIndex((p) => p._id === preset._id);
              if (index === -1) suggestedFilteredPresets.push(preset);
            } else {
              // if preset has passed the TrafficSourceUniqueName filter, but hasn't passed the TrackerUniqueName filter it should be removed from the list
              const index = suggestedFilteredPresets.findIndex((p) => p._id === preset._id);
              if (index !== -1) suggestedFilteredPresets.splice(index, 1);
            }
          }
        }
      });

      this.presetList = {
        user_presets: userFilteredPresets.concat(globalUserPresets),
        suggested_presets: suggestedFilteredPresets.concat(globalSuggestedPresets),
      };
    },
    sortPresetsBySelected(presets: Presets.Preset[]) {
      presets.forEach((preset, index) => {
        if (preset._id === this.selectedPreset._id) {
          const firstInList = presets[0];
          presets[0] = preset;
          presets[index] = firstInList;
        }
      });
      return presets;
    },
    sortPresetsByDate(presets: Presets.Preset[]) {
      return presets.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    },
    buildPresetPayload(level: string, preset: Presets.Preset) {
      const scopes: Presets.PresetScope = [
        {
          constraint: Presets.ConstraintEnum.TrafficSourceUniqueName,
          values: {
            availableTsTypes: [this.getCurrentTrafficSourceType],
          },
        },
      ];
      return {
        ...preset,
        current_preset_id: this.selectedPreset._id,
        level,
        traffic_source_name: this.getCurrentTrafficSourceType,
        platform: 'native',
        fields: preset.fields.filter((t) => Object.keys(t?.options || {})?.length),
        scopes: this.filter.scope || scopes,
      };
    },
    setSpecificScope(scope: Presets.PresetScope) {
      this.filter.scope = scope;
    },
    updateLocalStorage() {
      const localStoragePreset = this.settings.getPresetByTrafficSource(this.filter.type.uniqueName);
      if (localStoragePreset) {
        const preset: Presets.Preset = [...this.presets.user_presets, ...this.presets.suggested_presets].find(
          (p) => p._id === localStoragePreset
        ) as Presets.Preset;
        this.updateSelectedPreset(preset);
      }
      const findInUserPresets = this.presets.user_presets.find((p) => p._id === this.selectedPreset?._id);
      const findInSuggestedPresets = this.presets.suggested_presets.find((p) => p._id === this.selectedPreset?._id);
      if (!(findInUserPresets || findInSuggestedPresets)) {
        const randomPreset = this.presets.user_presets[0] || this.presets.suggested_presets[0];
        this.updateSelectedPreset(randomPreset);
      }
      this.settings.setPresetByTrafficSource(this.filter.type.uniqueName, this.selectedPreset._id);
    },
    hasSubUserRoleAccess(): boolean {
      const user = session.getUser();
      if (user?.roles.includes('ROLE_ALLOW_MULTIUSER') && !this.selectedPreset?.suggestedPreset) {
        return true;
      }
      return false;
    },
    async switchPresetAccess(value: boolean, mode: Presets.SubUserAccessMode) {
      if (mode === Presets.SubUserAccessMode.newPreset && value) {
        this.makePresetPublicAfterCreation = true;
        return;
      }
      if (value) {
        const subusers = await api?.subusers.getAllSubusers();
        const subuserIds = subusers.map((s) => s.user_id);
        await api.presets.addSubUsersToPreset(this.selectedPreset, subuserIds);
      } else {
        await api.presets.removeSubUsersFromPreset(this.selectedPreset);
      }
      apiStore?.presets.getPresets.clearCache();
      apiStore?.presets.getPresets();
      await this.getFilteredPresets();
    },
    async getTrackerTypesLinkedToTrafficSource() {
      let accounts = await apiStore.trafficSources.accounts();
      accounts = accounts.filter((a) => a.type.uniqueName === this.filter.type.uniqueName);
      return Array.from(new Set(accounts.map((a) => a.linkedTrackers.map((lt) => lt.uniqueName)).flat()));
    },
    async setLinkedTrackers(trackers: string[]) {
      this.linkedTrackers = trackers;
    },
    setFilter(filter: Filter) {
      this.filter = filter;
    },
  },
});
