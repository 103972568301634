import { LoadingMessage } from '@sh/helpers';
import { SearchFeed, TrafficSource } from '@sh/types';
import { cloneDeep, uniqBy } from 'lodash';

export default class TrafficSourceComplianceFilter {
  isLoading = false;

  constructor(
    public availableOptions?: Record<string, Record<string, SearchFeed.ComplianceAccount[]>>,
    private selected: TrafficSource[] = [],
    public user: Record<'email' | 'id', string> | null = null
  ) {}

  get options(): SearchFeed.ComplianceAccount[] {
    if (this.isLoading) {
      return [];
    }

    const options = cloneDeep(this.availableOptions?.[this.user?.id ?? '']?.[this.user?.email ?? ''] ?? []);

    options.sort((first, second) => first.traffic_source_type.localeCompare(second.traffic_source_type));

    return uniqBy(options, 'traffic_source_type');
  }

  get selectedOptions(): SearchFeed.ComplianceAccount[] {
    const options = this.options.filter((option) => this.value.includes(option.traffic_source_type));

    if (options.length !== this.options.length) {
      return options;
    }

    return [];
  }

  set selectedOptions(options: SearchFeed.ComplianceAccount[]) {
    this.selected = options.map((option) => option.traffic_source_type);
  }

  get value(): TrafficSource[] {
    if (this.selected?.length) {
      return this.selected;
    }

    if (this.options?.length) {
      return this.options.map(({ traffic_source_type }) => traffic_source_type);
    }

    return [];
  }

  set value(value: TrafficSource[]) {
    this.selected = value;
  }

  get disabledText(): string {
    if (this.isLoading) {
      return LoadingMessage;
    }

    if (this.user === null) {
      return 'Please start by choosing the client email.';
    }

    if (this.options?.length === 0) {
      return 'There are no traffic sources available for selected client email.';
    }

    return '';
  }

  getSelectedText(totalSelected: number): number | 'All' {
    if (!totalSelected || totalSelected === this.options?.length) {
      return 'All';
    }
    return totalSelected;
  }
}
