export interface Campaign {
  id: string;
  traffic_source_unique_name: string;
  traffic_source_account_id: number;
  tracker_account_id: null;
  tracker_unique_name: null;
  traffic_source_campaign_id: string;
  tracker_campaign_id: null;
  name: string;
  enabled: boolean;
  status: string;
  managed: boolean;
  archived: boolean;
  additional_fields: AdditionalFields;
  value: string;
  uniqueName: string;
  label: string;
  campaign_id: string;
  tags: string;
}

export interface AdditionalFields {
  daily_budget: null;
}

export enum CampaignFilterAction {
  ALL = 'All Campaigns',
  ACTIVE = 'Active Campaigns',
  DISABLED = 'Disabled Campaigns',
  NONE = 'Clear',
}
