<template>
  <b-modal
    id="clone-modal"
    ref="cloneModal"
    v-model="modal"
    modal-class="optimizer-modal"
    no-close-on-backdrop
    size="lg"
    centered
    @ok="submit"
  >
    <template slot="modal-header">
      <h2 :title="$c_modalName" class="modal-header__title fs-16 fw-700 text-start  ellipsis">
        Cloning {{ $c_entityName }}:  {{ $c_nameValue }}
      </h2>
      <div class="close-icon pointer" @click="hideModal">
        <OptimizerIcon type="close" />
      </div>
    </template>

    <Loading class="blue-loader" :loading="isLoading">
      <div class="row">
        <div class="col-md-12">
          <b-form
            :ref="$_getScope()"
            @submit.prevent="submit"
            :data-vv-scope="$_getScope()"
          >
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError('name')"
              :state="$_hasError('name')"
            >
              <template slot="label">
                Name <i v-if="!isMultiClone" v-b-tooltip.hover.html="$c_nameTitle" class="fa fa-info-circle info-icon" />
              </template>
              <b-form-input
                :class="['optimizer-form-input', isMultiClone ? 'plain-text' : '']"
                data-vv-as="Name"
                :state="$_hasError('name')"
                v-validate="'required|customRule'"
                data-vv-validate-on="input"
                type="text"
                :value="$c_nameValue"
                :plaintext="isMultiClone"
                :placeholder="`{Prefix} ${$c_modalName} {Sufix}`"
                @change="getNameData"
                name="name"
                key="name"
                :title="titleName"
                v-b-tooltip.hover.top
              />
            </b-form-group>
            <b-form-group
              class="optimizer-form-group"
              :invalid-feedback="$_getError('nrOfCopies')"
              :state="$_hasError('nrOfCopies')"
            >
              <template slot="label"> Number of copies <i v-b-tooltip.hover.top :title="$c_copiesTitle" class="fa fa-info-circle info-icon" /> </template>

              <b-form-input
                class="optimizer-form-input"
                data-vv-as=" Number of copies"
                :state="$_hasError('nrOfCopies')"
                v-validate="'required'"
                type="number"
                v-model="nrOfCopies"
                name="nrOfCopies"
                key="nrOfCopies"
                min="1"
                placeholder="Number of copies"
              />
            </b-form-group>
            <b-form-group
              v-if="trafficSource.type.uniqueName==='Facebook'&&$c_entity==='AdSets'"
              class="optimizer-form-group mb-4"
              id="input-group-3"
              :invalid-feedback="$_getError('campaigns')"
              :state="$_hasError('campaigns')"
            >
              <template slot="label">Destination Campaign  <i v-if="!isMultiClone" v-b-tooltip.hover.top :title="$c_destinationCampaignTitle" class="fa fa-info-circle info-icon" /></template>
              <vue-opti-select-light
                :class="[
                  { 'has-error': $_hasError('campaigns') === false },
                  'optimizer-select icon-select pl-md-0 mb-2 mb-md-0',
                ]"
                :options="$_options"
                unique-key="id"
                label-key="name"
                button-type="static"
                data-vv-as="Campaigns"
                v-validate="'required'"
                name="campaigns"
                v-model="campaignData"
                searchable
                :search-fields="['content', 'value']"
                lazyRender
                :option-type="$c_optionType"
                :state="$_hasError('campaigns')"
                filteredKey="campaigns"
                :single="true"
                @change="(value) => { $emit('input', value) }"
                debounce
                :disabled="isMultiClone"
              >
                <template #BUTTON_PLACEHOLDER>
                  <span v-if="campaignData.content" class="button-placehoder-filter">
                    <i class="fa fa-inbox"></i>
                    {{ campaignData.content }}
                  </span>
                  <span v-else class="button-placehoder-filter ">
                    <i class="fa fa-inbox"></i>
                    {{ campaignData.name }}
                  </span>
                </template>
              </vue-opti-select-light>
            </b-form-group>
            <b-form-group
              v-else-if="trafficSource.type.uniqueName==='TikTok'&&$c_entity==='AdGroups'"
              class="optimizer-form-group"
              :invalid-feedback="$_getError('campaigns')"
              :state="$_hasError('campaigns')"
            >
              <template slot="label">
                Destination Campaign <i v-if="!isMultiClone" v-b-tooltip.hover.top :title="$c_destinationCampaignTikTokTitle" class="fa fa-info-circle info-icon" />
              </template>

              <b-form-input
                :class="['optimizer-form-input', isMultiClone ? 'plain-text' : '']"
                data-vv-as="Campaigns"
                :state="$_hasError('campaigns')"
                v-validate="'required'"
                type="text"
                v-model="campaign.name"
                name="name"
                key="name"
                placeholder="Campaign"
                plaintext
                :title="campaign.name"
                v-b-tooltip.hover.top
              />
            </b-form-group>
            <b-form-group
              v-if="$c_entity === 'Ads'&& trafficSource.type.uniqueName==='Facebook'"
              class="optimizer-form-group"
              id="input-group-4"
              :invalid-feedback="$_getError('adGroups')"
              :state="$_hasError('adGroups')"
            >
              <template slot="label">Destination {{ $c_entityDestination }} <i v-if="!isMultiClone" v-b-tooltip.hover.top :title="$c_destinationTitle" class="fa fa-info-circle info-icon" /> </template>

              <vue-opti-select-light
                :class="[
                  { 'has-error': $_hasError('adGroups') === false },
                  'optimizer-select pl-md-0 mb-2 mb-md-0',
                  { 'custom-disabled-class': isMultiClone },
                ]"
                :options="adgroups"
                unique-key="value"
                label-key="content"
                button-type="static"
                data-vv-as="AdGroups"
                v-validate="'required'"
                name="adGroups"
                v-model="adGroup"
                searchable
                :search-fields="['content', 'value']"
                lazyRender
                :option-type="$c_optionType"
                :state="$_hasError('adGroups')"
                filteredKey="adGroups"
                :single="true"
                :disabled="isMultiClone"
              >
                <template #BUTTON_PLACEHOLDER="{ options }">
                  <span v-if="contentData.length>1">
                    Each Ad will be cloned to its parent {{ $c_entityDestination }}!
                  </span>
                  <span v-else-if="options.length === 1" class="dropdown-item-text">
                    {{ options[0].content }}
                  </span>
                  <span v-else class="button-placehoder-filter">
                    <i class="fa fa-inbox"></i>
                    <span class="ml-8">AdGroup</span>
                    <b-badge v-show="options.length" pill variant="info">{{
                      options.length
                    }}</b-badge>
                  </span>
                </template>
              </vue-opti-select-light>
            </b-form-group>
            <b-form-group
              v-else-if="$c_entity === 'Ads'&& trafficSource.type.uniqueName==='TikTok'"
              class="optimizer-form-group"
              :invalid-feedback="$_getError('adGroups')"
              :state="$_hasError('adGroups')"
            >
              <template slot="label">
                Destination {{ $c_entityDestination }} <i v-if="!isMultiClone" v-b-tooltip.hover.top :title="$c_destinationTikTokTitle" class="fa fa-info-circle info-icon" />
              </template>

              <b-form-input
                v-if="contentData[0].additional_fields"
                :class="['optimizer-form-input', isMultiClone ? 'plain-text' : '']"
                data-vv-as="AdGroups"
                :state="$_hasError('adGroups')"
                v-validate="'required'"
                type="text"
                :value="$c_adgroupValue"
                name="name"
                key="name"
                placeholder="AdGroup"
                plaintext
                v-b-tooltip.hover.top
                :title="isMultiClone? '' :contentData[0].additional_fields.adgroup_name"
              />
            </b-form-group>
          </b-form>
        </div>
        <div v-if="errorMessage" class="text-danger">
          {{ errorMessage }}
        </div>
      </div>
    </Loading>
    <template slot="modal-footer">
      <div class="d-flex justify-content-end w-100 action-container m-0 mt-16">
        <template>
          <b-btn
            class="secondary-button fs-14 fw-700 lh-16 mr-8 py-9 px-24"
            :disabled="isLoading"
            @click="hideModal"
          >
            Cancel
          </b-btn>
          <b-btn
            class="primary-button fs-14 fw-700 lh-16 py-9 px-24"
            :disabled="isLoading"
            @click="submit"
          >
            Save
          </b-btn>
        </template>
      </div>
    </template>
  </b-modal>
</template>
<script>
import notifications from '@sh/mixins/notifications';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import Loading from '@sh/components/Utils/Loading.vue';
import { useSharedStore } from '@sh/stores';
import { mapState } from 'pinia';

const scope = 'clone';

export default {
  name: 'CloneModal',
  components: {
    OptimizerIcon,
    Loading,
  },
  mixins: [notifications],

  props: {
    trafficSource: { type: Object },
    campaign: { type: Object },
    adGroupData: { type: Array },
    contentData: { type: Array },
  },
  data() {
    return {
      modal: false,
      defaultName: '',
      name: '',
      titleName: '',
      prefix: '',
      sufix: '',
      nrOfCopies: 1,
      adgroups: {},
      adGroup: {},
      campaigns: {},
      isLoading: false,
      campaignData: {},
      errorMessage: '',
    };
  },
  computed: {
    $c_entity() {
      if (this.$route.query.tab === 'adgroups') {
        if (this.trafficSource.type.uniqueName === 'Facebook') {
          return 'AdSets';
        }
        if (['TikTok', 'GoogleAds'].includes(this.trafficSource.type.uniqueName)) {
          return 'AdGroups';
        }
      }
      if (this.$route.query.tab === 'contents') {
        return 'Ads';
      }
    },
    $c_entityName() {
      if (this.$c_entity) {
        return this.$c_entity.slice(0, -1);
      }
    },
    $c_modalName() {
      return this.adGroupData
        ? this.adGroupData.map((item) => item.name).join(', ')
        : this.contentData.map((item) => item.name).join(', ');
    },
    $c_optionType() {
      if (this.trafficSource.type.uniqueName === 'TikTok' || this.$c_entity === 'Ads') {
        return 'text';
      }
      return 'checkbox';
    },
    isMultiClone() {
      const isAdEntity = this.$c_entity === 'Ads';
      const isAdGroupEntity = this.$c_entity === 'AdGroups' || this.$c_entity === 'AdSets';

      if (isAdEntity) {
        return this.contentData?.length > 1;
      }

      if (isAdGroupEntity) {
        return this.adGroupData?.length > 1;
      }

      return false;
    },
    $c_nameTitle() {
      return ` <div>The name can be edited and customized. TheOptimizer will automatically add 1 "-Copy" suffix to the name for each cloned copy.</div>
      <div>Example:</div>
      <div>1 copy: "${this.$c_entityName} name - Copy"</div>
      <div>2 copies: "${this.$c_entityName}  name -Copy-Copy" etc.</div>
      `;
    },
    $c_descriptionMessage() {
      return 'Each Ad will be cloned to its parent AdGroup!';
    },
    $c_nameDescription() {
      return `Multiple ${this.$c_entity} selected!`;
    },
    $c_nameValue() {
      if (this.$c_entity === 'Ads') {
        if (this.contentData.length > 1) {
          return this.$c_nameDescription;
        }
        return this.name;
      }

      if (this.adGroupData.length > 1) {
        return this.$c_nameDescription;
      }
      return this.name;
    },
    $c_adgroupValue() {
      if (this.$c_entity === 'Ads') {
        if (this.trafficSource.type.uniqueName === 'TikTok' && this.contentData.length > 1) {
          return this.$c_descriptionMessage;
        }
        return this.contentData[0].additional_fields?.adgroup_name;
      }
      return '';
    },
    $c_copiesTitle() {
      return `Specifies the number of times this ${this.$c_entityName} will be cloned.
      A value of 3 for example, means that the ${this.$c_entityName} will be cloned 3 times.`;
    },
    $c_destinationCampaignTitle() {
      return `The destination campaign is the campaign where ${this.$c_entityName} will be cloned`;
    },
    $c_destinationCampaignTikTokTitle() {
      return 'AdGroups can only be cloned on the same Campaign.';
    },
    $c_destinationTitle() {
      return `The destination ${this.$c_entityDestination} is the ${this.$c_entityDestination} where the Ad will be cloned`;
    },
    $c_destinationTikTokTitle() {
      return 'Ads can only be cloned on the same AgGroup.';
    },
    $c_entityDestination() {
      return this.trafficSource.type.uniqueName === 'TikTok' ? 'AdGroup' : 'AdSet';
    },
    ...mapState(useSharedStore, ['globalSearchCampaigns']),
  },
  watch: {
    adGroupData: {
      handler(item) {
        if (this.trafficSource.type.uniqueName === 'Facebook') {
          this.setName(item[0].name);
          this.campaignData = this.campaigns.find((campaign) => campaign.value === item[0].traffic_source_campaign_id);
          if (!this.campaignData) {
            this.campaignData = { value: this.campaign.traffic_source_campaign_id, content: this.$c_nameValue };
          }
        }
      },
    },
    contentData: {
      handler(item) {
        if (this.trafficSource.type.uniqueName === 'Facebook') {
          this.setName(item.name);
          this.adGroup = this.adgroups.find((adGroup) => adGroup.value === item[0].adset_id);
        }
      },
    },
    $c_modalName: {
      handler(item) {
        this.titleName = item;
        this.setName(item);
        this.defaultName = item;
      },
    },
    $c_nameValue: {
      handler(item) {
        if (item === this.$c_nameDescription) {
          this.name = this.$c_nameDescription;
        }
        this.name = item;
      },
    },
  },
  async created() {
    this.isLoading = true;
    this.$validator.extend('customRule', {
      getMessage: (field, params, data) => (data?.message) || 'Something went wrong',
      validate: (value) => {
        let valid = true;
        const data = { message: '' };
        const name = this.$c_modalName;

        if (!value.includes(name)) {
          if (this.$c_entity === 'Ads' && this.contentData.length <= 1) {
            valid = false;
            data.message = 'The original name can not be changed. You can only add a prefix or suffix.';
          }
          if (this.$c_entity === 'AdGroups' || this.$c_entity === 'AdSets') {
            if (this.adGroupData.length <= 1) {
              valid = false;
              data.message = 'The original name can not be changed. You can only add a prefix or suffix.';
            }
          }
        }

        return Promise.resolve({
          valid,
          data,
        });
      },
    });
    if (this.$c_entity === 'Ads' && this.trafficSource.type.uniqueName === 'Facebook') {
      const adgroups = await this.$api.entityFilter.getByStatus(
        {
          model: 'AdGroup',
          typeName: [
            this.trafficSource.type.uniqueName,
          ],
          campaignIds: [this.campaign.id],
        },

      );
      this.adgroups = adgroups.map((item) => ({ value: item.traffic_source_adgroup_id, content: item.name }));
      this.isLoading = false;
    }
    if (this.$c_entity === 'AdSets' && this.trafficSource.type.uniqueName === 'Facebook') {
      const campaigns = await this.$api.campaigns.getByFilters({
        typeName: [this.trafficSource.type.uniqueName],
        trafficSourceAccountIds: [this.campaign.traffic_source_account_id],
      });
      this.campaigns = campaigns.data.filter((obj) => obj.objective === this.campaign.additional_fields.objective).map((item) => ({ value: item.traffic_source_campaign_id, content: item.name }));
      this.isLoading = false;
    }
    this.isLoading = false;
  },
  mounted() {
    this.setName(this.$c_modalName);
  },
  methods: {
    async $_options(page = 1, search = '') {
      const payload = { page, search };
      const { data } = await this.$api.campaigns.getByFilters(payload);
      return data;
    },
    formatTooltipContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    showModal() {
      this.modal = true;
    },
    setName(value) {
      if (this.$c_adgroupValue !== this.$c_descriptionMessage || this.$c_nameValue !== this.$c_nameDescription) {
        this.name = value;
      }
    },
    hideModal() {
      this.modal = false;
      this.errorMessage = '';
    },
    shouldDisableInput() {
      if (this.$c_entity === 'Ads') {
        return this.contentData.length > 1;
      }
    },
    async submit() {
      if (await this.$_validateForm()) {
        this.isLoading = true;
        this.errorMessage = '';
        let success = '';
        let errorMessage = '';
        try {
          if (this.trafficSource.type.uniqueName === 'Facebook') {
            if (this.$c_entity === 'AdSets') {
              if (this.adGroupData.length > 1) {
                const adgroupIds = this.adGroupData.map((item) => item.traffic_source_adgroup_id);
                const { successMessage, error } = await this.$api.adgroups.clone(this.campaign.id, adgroupIds, this.nrOfCopies);
                success = successMessage;
                errorMessage = error;
              } else {
                const { successMessage, error } = await this.$api.adgroups.clone(this.campaign.id, [this.adGroupData[0].traffic_source_adgroup_id], this.nrOfCopies, this.campaignData.value, this.sufix, this.prefix);
                success = successMessage;
                errorMessage = error;
              }
            } else if (this.$c_entity === 'Ads') {
              if (this.contentData.length > 1) {
                const adIds = this.contentData.map((item) => item.traffic_source_content_id);
                const { successMessage, error } = await this.$api.contents.clone(this.campaign.id, adIds, this.nrOfCopies);
                success = successMessage;
                errorMessage = error;
              } else {
                const { successMessage, error } = await this.$api.contents.clone(this.campaign.id, [this.contentData[0].traffic_source_content_id], this.nrOfCopies, this.adGroup.value, this.sufix, this.prefix);
                success = successMessage;
                errorMessage = error;
              }
            }
          } else if (this.trafficSource.type.uniqueName === 'TikTok') {
            if (this.$c_entity === 'AdGroups') {
              if (this.adGroupData.length > 1) {
                const adgroupIds = this.adGroupData.map((item) => item.traffic_source_adgroup_id.toString());
                const { successMessage, error } = await this.$api.adgroups.clone(this.campaign.id, adgroupIds, this.nrOfCopies);
                success = successMessage;
                errorMessage = error;
              } else {
                const { successMessage, error } = await this.$api.adgroups.clone(this.campaign.id, [this.adGroupData[0].traffic_source_adgroup_id], this.nrOfCopies, this.campaign.traffic_source_campaign_id, this.sufix, this.prefix);
                success = successMessage;
                errorMessage = error;
              }
            } else if (this.$c_entity === 'Ads') {
              if (this.contentData.length > 1) {
                const adIds = this.contentData.map((item) => item.traffic_source_content_id.toString());
                const { successMessage, error } = await this.$api.contents.clone(this.campaign.id, adIds, this.nrOfCopies);
                success = successMessage;
                errorMessage = error;
              } else {
                const { successMessage, error } = await this.$api.contents.clone(this.campaign.id, [String(this.contentData[0].traffic_source_content_id)], this.nrOfCopies, String(this.contentData[0].additional_fields.adgroup_id), this.sufix, this.prefix);
                success = successMessage;
                errorMessage = error;
              }
            }
          }
          if (success) {
            await this.$swal({
              text: success,
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                cancelButton: 'hide-cancel-btn',
                title: 'swal-title',
              },
            });
            this.hideModal();
          } else if (errorMessage) {
            await this.$swal({
              text: errorMessage,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                container: 'confirm-delete-swal larger box',
                confirmButton: 'primary-button primary-button--danger',
                cancelButton: 'hide-cancel-btn',
              },
            });
          }
        } catch {
          this.$n_failNotification({ title: 'An error occurred, please contact support!' });
        }
        this.isLoading = false;
      }
      this.hideModal();
      this.isLoading = false;
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
    async $_validateForm() {
      try {
        const validation = await this.$validator.validateAll(this.$_getScope());
        return validation;
      } catch (error) {
        return false;
      }
    },
    getNameData(value) {
      const startIndex = value.indexOf(this.defaultName);
      const endIndex = startIndex + this.defaultName.length;
      this.prefix = value.substring(0, startIndex);
      this.sufix = value.substring(endIndex);
    },
  },
};
</script>
<style lang="scss">
#clone-modal {
  .modal-content {
    padding-block: 2.4rem 1.6rem;
    padding-inline: 3.2rem;

    .modal-header {
      padding-block: 0 2.4rem;
      padding-inline: 3.2rem;
      margin-inline: -3.2rem;

      .modal-header__title {
        color: #525869;
        text-align: start;
      }

      .close-icon {
        height: 1.4rem;
        margin-top: -0.4rem;
      }
      .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  .modal-body {
    padding-inline: 0;

    .loading-wrapper {
      overflow: visible;
    }
    .tag-container-wrapper {
      min-height: 14rem;
      max-height: 14rem;
    }

    .enter-icon {
      top: 1.5rem;
      right: 0.8rem;
    }

    .vue-tags-input {
      &:not(.ti-focus),
      &.empty-autocomplete {
        .ti-autocomplete {
          display: none;
        }
      }

      .ti-input {
        background-color: $color-white;
        color: $black-500;
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 0.4rem;
        min-height: 4.2rem;
        border: 0.1rem solid $color-light-gray;
        border-radius: 0.6rem;

        .ti-new-tag-input {
          font-size: 1.4rem;
          padding-right: 2rem;
        }
      }

      .ti-autocomplete {
        max-height: 10rem;
        overflow: auto;
      }
    }
    .custom-disabled-class {
      color:black;
    }
    .plain-text{
        color: gray;
    }
  }

  .modal-footer {
    padding: 0;
    margin-inline: -3.2rem;
    padding-inline: 3.2rem;
  }
  .btn.dropdown-toggle {
    .button-placehoder-filter {
      display: flex !important;
      align-items: center !important;
      margin-right: 2.5rem !important;
      width: -moz-available !important;
      width: -webkit-fill-available !important;

      .badge {
        top: -1.7rem !important;
        right: -6.2rem !important;
        background: $color-light-blue;
        padding: 0.4rem 0.7rem 0.3rem !important;
      }
    }
  }
}
</style>
