import { api } from '@sh/services/api';
import { FilterDto, MediaManager } from '@sh/types';
import { defineStore } from 'pinia';

export interface CampaignsStoreProps {
  filterState: AppliedFilters;
  tags: Array<string>;
}

export interface CampaignsFiltersProps {
  status: Array<FilterDto>;
  managed: Array<FilterDto>;
  archived: Array<FilterDto>;
  tags: Array<string>;
}

type AppliedFilters = {
  savedFilters: CampaignsFiltersProps;
  filterSession: CampaignsFiltersProps;
};

type TableProps = Record<string, unknown> & { tags: Array<string> };

export const useCampaignsStore = defineStore('campaigns-store', {
  state: (): CampaignsStoreProps => ({
    filterState: {} as AppliedFilters,
    tags: [],
  }),
  actions: {
    initialFilters() {
      this.filterState = {
        savedFilters: {} as CampaignsFiltersProps,
        filterSession: {
          status: [],
          tags: [],
          managed: [],
          archived: [],
        },
      };
    },
    async getAllAvailableTagsForCampaign(level: string, tsName: string) {
      const { tags: getAllTags } = await api.tags.getAllUniqueTags(level, tsName);
      this.tags = getAllTags;
      return getAllTags;
    },
    addNewTags(newTags: Array<string>) {
      const uniqueNewTags = newTags.filter((tag) => !this.tags.includes(tag));
      this.tags.push(...uniqueNewTags);
    },
    onBulkTagInsert(items: Array<TableProps>, deletedTags: Array<string>) {
      return items.map((el) => {
        const isTagDeleted = deletedTags.find((item) => el.tags.includes(item));
        if (isTagDeleted) {
          return {
            ...el,
            tags: el.tags.filter((tag) => !deletedTags.includes(tag)),
          };
        }
        return el;
      });
    },
  },
  getters: {
    tagAutocompleteItems: (state): MediaManager.Tag[] => {
      return (state.tags ?? [])?.map((text) => ({ text })).sort((a, b) => a.text.localeCompare(b.text));
    },
    getAllTags: (state) => state.tags,
  },
});
