/* eslint-disable import/no-cycle */
import { EntityForCreation } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABEntity';
import { ABDependency, Macro } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as AdSetMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import { TargetingObjectAbVariables } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import * as CampaignMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { country_group } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/country_group';
import { ErrorType, InternalABVariable, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { AdSetValidation, TargetingValidation } from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import {
  CampaignCreatorTask,
  TaskCategory,
  TaskType,
} from '@/views/Automation/CampaignCreatorV2/validation/CampaignCreatorTask';
import {
  AbVariableErrorDTO,
  CampaignDTO,
  FacebookDTOMetrics,
} from '@/views/Automation/CampaignCreatorV2/validation/CampaignDTO';
import { FacebookAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/FacebookAbGroups';
import { FieldConfiguration, MacroType } from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import moment from 'moment';

export class AdSetDTO extends CampaignCreatorTask {
  private campaignTask?: CampaignMeta.CreateApi & { internalId: string };

  constructor(
    private variableList: ABDependency<any>[],
    private entityBuildId: string,
    private macros: Macro[],
    private pixelList?: Array<{ id: string; name: string }>
  ) {
    super({ taskType: TaskType.ADSET, taskCategory: TaskCategory.Facebook });
    this.calculateName();
    this.macros;
  }

  public calculateName() {
    const nameVariable = this.findVariable('name')?.value!;
    const flatMacros = this.macros.map((m) => [m, ...m.nestedMacros]).flat();
    const usedAbGroups = this.variableList.map((e) => [e.variableUniquePath, e.abGroup]).flat();
    // const newName = calculateName(nameVariable, this.macros, this.variableList);
    const macrosInNameValues = nameVariable.macros?.map((m) => {
      const fieldConfig = AdSetDTO.findFieldConfig(m.field!);
      const variable = this.findVariableTargeting(m.field as 'geo_locations') || this.findVariable(m.field as 'name');
      if (m.type === MacroType.Value) {
        if (fieldConfig?.renderFunction && variable) {
          return fieldConfig.renderFunction(variable?.value);
        }
        return variable?.value?.toString();
      }
      if (m.type === MacroType.VariableName) {
        return variable?.name;
      }
      if (m.type === MacroType.GroupName) {
        return flatMacros.find((e) => e.name === m.value && usedAbGroups.includes(e.value.variableUniquePath))?.value
          ?.value;
      }
      if (m.type === MacroType.TextValue) {
        return m.value;
      }
      if (m.type === MacroType.CurrentDate) {
        return moment().format('YYYY-MM-DD');
      }
      if (m.type === MacroType.CurrentTime) {
        return moment().format('HH:mm');
      }
      if (m.type === MacroType.SubEntityName) {
        return (
          this.getSubTaskList()
            .map((e) => {
              (e as AdSetDTO).calculateName();
              return e.getName();
            })
            .filter(Boolean)
            .join(nameVariable.itemSeparator) || '#AD_NAME#'
        );
      }
      if (m.type === MacroType.SubEntityNumber) {
        return this.getSubTaskList().length || '#NUMBER_OF_ADS#';
      }
    });
    const calculatedName = nameVariable.name + (macrosInNameValues?.join(nameVariable.fieldSeparator) || '');
    this.setName(calculatedName);
  }

  public itemMetrics(): FacebookDTOMetrics {
    return {
      meta: {
        adsetNumber: { name: 'Number of Ad Sets', key: 'adsetNumber', entity: 'AdSet', suffix: 'ad set created' },
        adsetDailyBudget: {
          name: 'Ad Set Daily Budget',
          entity: 'AdSet',
          key: 'adsetDailyBudget',
          suffix: '$ Allocated daily budget',
        },
        adsetVariations: {
          name: 'Ad Set Variations',
          entity: 'AdSet',
          key: 'adsetVariations',
          suffix: 'ad set variations',
        },
        adsetLifetimeBudget: {
          name: 'Ad Set Lifetime Budget',
          entity: 'AdSet',
          key: 'adsetLifetimeBudget',
          suffix: '$ Allocated lifetime budget',
        },
        audienceTargeting: {
          name: 'Audience Targeting',
          entity: 'AdSet',
          key: 'audienceTargeting',
          suffix: 'audience variations',
        },
        placementTargeting: {
          name: 'Placement Targeting',
          entity: 'AdSet',
          key: 'placementTargeting',
          suffix: 'placement variations',
        },
      },
      metrics: {
        adsetNumber: 1,
        audienceTargeting: [...(this.findVariableTargeting('geo_locations')?.abGroups || [])],
        adsetDailyBudget: this.getPayload().daily_budget / 100 || 0,
        adsetLifetimeBudget: this.getPayload().lifetime_budget / 100 || 0,
        placementTargeting: [...(this.findVariableTargeting('device_platforms')?.abGroups || [])],
        adsetVariations: [
          this.variableList
            .map((e) => [e.abId, ...(e.abGroups || [])]?.join(','))
            .sort()
            .join(','),
        ],
      },
    };
  }

  public setCampaignTask(campaignTask: CampaignDTO) {
    this.campaignTask = { ...campaignTask.getPayload(), internalId: campaignTask.getInternalId() };
    return this;
  }

  private findVariable<
    M extends typeof AdSetValidation,
    K extends keyof M,
    V = K extends keyof AdSetMeta.Create ? AdSetMeta.Create[K] : never
  >(fieldName: K): ABDependency<V> | undefined {
    return this.variableList.find((variable) => variable.field === (fieldName as string));
  }

  private findVariableTargeting<
    M extends typeof TargetingValidation,
    K extends keyof M,
    V = K extends keyof AdSetMeta.TargetingObjectAbVariables ? AdSetMeta.TargetingObjectAbVariables[K] : never
  >(fieldName: K): ABDependency<V> | undefined {
    return this.variableList.find((variable) => variable.field === (fieldName as string));
  }

  public static validateField<M extends typeof AdSetValidation, F extends keyof typeof AdSetValidation>(
    field: F,
    value: M[F]
  ) {
    const fieldConfig = AdSetValidation[field];

    if (fieldConfig.validation) {
      const result = fieldConfig.validation.validate(value);

      if (result.error) {
        return result.error;
      }
    }

    return null;
  }

  public static getFieldConfiguration(field: string) {
    const config = AdSetValidation[field as 'name'];

    if (!config) {
      throw new Error(
        `Field ${field} does not have a configuration, please make sure to add metadata before using it as AB variable`
      );
    }

    return config;
  }

  public static getFieldConfigurationTargeting(field: string) {
    const config = TargetingValidation[field as 'geo_locations'];

    if (!config) {
      throw new Error(
        `Field ${field} does not have a configuration, please make sure to add metadata before using it as AB variable`
      );
    }

    return config;
  }

  public static validateTargetingField<
    M extends typeof TargetingValidation,
    F extends keyof typeof TargetingValidation
  >(field: F, value: M[F]) {
    const fieldConfig = TargetingValidation[field];

    if (fieldConfig.validation) {
      const result = fieldConfig.validation.validate(value);

      if (result.error) {
        return result.error;
      }
    }

    return null;
  }

  public validate(payload: AdSetMeta.CreateApi) {
    const errors: AbVariableErrorDTO[] = [];

    // Custom validation logic here
    const campaign = this.campaignTask;

    if (payload.bid_amount && payload.bid_strategy === CampaignMeta.BidStrategy.LOWEST_COST_WITHOUT_CAP) {
      const bidAmountVariable = this.variableList.find((e) => e.field === 'bid_amount');
      errors.push({
        message: 'Please select a bid strategy',
        field: 'bid_strategy',
        entity: SupportedEntities.adset,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: bidAmountVariable?.abGroup,
        variableId: bidAmountVariable?.abId,
        itemEntity: SupportedEntities.adset,
      });
    }
    if (
      !payload.bid_amount &&
      [CampaignMeta.BidStrategy.LOWEST_COST_WITH_BID_CAP, CampaignMeta.BidStrategy.COST_CAP].includes(
        payload?.bid_strategy || campaign?.bid_strategy
      )
    ) {
      const bidAmountVariable = this.variableList.find((e) => e.field === 'bid_amount');
      errors.push({
        message: 'Current Bidding Strategy requires a bidding value',
        field: 'bid_amount',
        entity: SupportedEntities.adset,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: bidAmountVariable?.abGroup,
        variableId: bidAmountVariable?.abId,
        itemEntity: SupportedEntities.adset,
      });
    }
    // Move to FieldConfig -> isActive
    const { optimization_goal } = payload;
    const { objective } = campaign || {};
    const isPerformanceGoals = [
      AdSetMeta.OptimizationGoal.OFFSITE_CONVERSIONS,
      AdSetMeta.OptimizationGoal.VALUE,
    ].includes(optimization_goal);
    const isObjective = [CampaignMeta.Objective.OUTCOME_SALES, CampaignMeta.Objective.OUTCOME_LEADS].includes(
      objective!
    );

    if (!payload.promoted_object?.pixel_id && isPerformanceGoals && isObjective) {
      const promoted_object = this.findVariable('promoted_object');
      errors.push({
        message: 'Please specify your Pixel and/or conversion objective',
        field: 'promoted_object',
        entity: SupportedEntities.adset,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: promoted_object?.abGroup,
        variableId: promoted_object?.abId,
        itemEntity: SupportedEntities.adset,
      });
    }
    if (
      payload.promoted_object?.pixel_id &&
      !this.pixelList?.find((e) => e.id === payload.promoted_object?.pixel_id) &&
      this.pixelList?.length
    ) {
      const pixelVariable = this.variableList.find((e) => e.field === 'promoted_object');
      errors.push({
        message: 'Please select a pixel that is available for selected Ad Account',
        field: 'custom_event_type',
        entity: SupportedEntities.adset,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: pixelVariable?.abGroup,
        variableId: pixelVariable?.abId,
        itemEntity: SupportedEntities.adset,
      });
    }

    if (campaign?.special_ad_categories?.filter(Boolean)?.length) {
      if (payload.targeting.geo_locations?.regions?.length || payload.targeting.geo_locations?.zips?.length) {
        const geoLocations = this.findVariableTargeting('geo_locations');
        errors.push({
          message: 'On Special Ad categories you cannot select regions or zip codes',
          field: 'geo_locations',
          entity: SupportedEntities.targeting,
          type: ErrorType.BLOCKING,
          entityBuildId: this.entityBuildId,
          abGroup: geoLocations?.abGroup,
          variableId: geoLocations?.abId,
          itemEntity: SupportedEntities.targeting,
        });
      }
      if (payload.targeting.targeting_automation?.advantage_audience) {
        const placement = this.findVariableTargeting('targeting_automation');
        errors.push({
          message: 'On Special Ad categories you cannot use advantage + placements',
          field: 'targeting_automation',
          entity: SupportedEntities.targeting,
          type: ErrorType.BLOCKING,
          entityBuildId: this.entityBuildId,
          abGroup: placement?.abGroup,
          variableId: placement?.abId,
          itemEntity: SupportedEntities.targeting,
        });
      }
      if (payload.targeting.geo_locations?.regions?.length || payload.targeting.geo_locations?.zips?.length) {
        const geoLocations = this.findVariableTargeting('geo_locations');
        errors.push({
          message: 'On Special Ad categories you cannot select regions or zip codes',
          field: 'geo_locations',
          entity: SupportedEntities.targeting,
          type: ErrorType.BLOCKING,
          entityBuildId: this.entityBuildId,
          abGroup: geoLocations?.abGroup,
          variableId: geoLocations?.abId,
          itemEntity: SupportedEntities.targeting,
        });
      }
    }

    // Check if at least one Device, platform or placement is selected
    if (
      !payload.targeting.targeting_automation?.advantage_audience &&
      (!payload.targeting.device_platforms?.length ||
        !payload.targeting.publisher_platforms?.length ||
        !(
          payload.targeting.facebook_positions?.length ||
          payload.targeting.instagram_positions?.length ||
          payload.targeting.messenger_positions?.length ||
          payload.targeting.audience_network_positions?.length
        ))
    ) {
      errors.push({
        message: 'At least one device, platform and placement must be selected',
        field: 'publisher_platforms',
        entity: SupportedEntities.targeting,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: this.findVariableTargeting('device_platforms')?.abGroup,
        variableId: this.findVariableTargeting('device_platforms')?.abId,
        itemEntity: SupportedEntities.targeting,
      });
    }

    // Has at least one geolocation targeting
    const hasLocationTargeting =
      payload.targeting.geo_locations?.countries?.length ||
      payload.targeting.geo_locations?.cities?.length ||
      payload.targeting.geo_locations?.regions?.length ||
      payload.targeting.geo_locations?.zips?.length ||
      payload.targeting.geo_locations?.country_group?.length;
    if (!hasLocationTargeting) {
      errors.push({
        message: 'At least one location must be selected',
        field: 'geo_locations',
        entity: SupportedEntities.targeting,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: this.findVariableTargeting('geo_locations')?.abGroup,
        variableId: this.findVariableTargeting('geo_locations')?.abId,
        itemEntity: SupportedEntities.targeting,
      });
    }

    // If Pixel is selected, a custom conversion must be selected
    if (
      (payload.optimization_goal === AdSetMeta.OptimizationGoal.VALUE && !payload.promoted_object?.custom_event_type) ||
      (payload.promoted_object?.pixel_id && !payload.promoted_object?.custom_event_type)
    ) {
      errors.push({
        message: 'A custom conversion must be selected',
        field: 'promoted_object',
        entity: SupportedEntities.adset,
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        abGroup: this.findVariable('promoted_object')?.abGroup,
        variableId: this.findVariable('promoted_object')?.abId,
        itemEntity: SupportedEntities.adset,
      });
    }

    return errors;
  }

  static fromApiToVariables(
    targeting: AdSetMeta.TargetingObject
  ): Record<string, InternalABVariable<any> & { fieldGroup: { readableName: string } }> {
    const genders = targeting.genders?.filter((e) => !Number.isNaN(e));
    const genderValue = genders?.length ? genders : [0];
    const variables: Partial<{
      [k in keyof TargetingObjectAbVariables]: Omit<InternalABVariable<TargetingObjectAbVariables[k]>, 'variableId'> & {
        fieldGroup: { readableName: string };
      };
    }> = {
      age_max: { field: 'age_max', value: targeting.age_max, fieldGroup: FacebookAbGroups.TARGETING },
      age_min: { field: 'age_min', value: targeting.age_min, fieldGroup: FacebookAbGroups.TARGETING },
      genders: {
        field: 'genders',
        value: genderValue,
        fieldGroup: FacebookAbGroups.TARGETING,
      },
      audience_network_positions: {
        field: 'audience_network_positions',
        value: targeting.audience_network_positions,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      device_platforms: {
        field: 'device_platforms',
        value: targeting.device_platforms,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      facebook_positions: {
        field: 'facebook_positions',
        value: targeting.facebook_positions,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      instagram_positions: {
        field: 'instagram_positions',
        value: targeting.instagram_positions,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      messenger_positions: {
        field: 'messenger_positions',
        value: targeting.messenger_positions,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      publisher_platforms: {
        field: 'publisher_platforms',
        value: targeting.publisher_platforms,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
      targeting_automation: {
        field: 'targeting_automation',
        value: targeting.targeting_automation,
        fieldGroup: FacebookAbGroups.PLACEMENT,
      },
    };
    variables.geo_locations = {
      field: 'geo_locations',
      value: {
        countries:
          targeting.geo_locations?.countries?.map((e) => ({
            key: e,
            name: e,
            type: 'country',
          })) || ([] as any),
        cities: targeting.geo_locations?.cities! || [],
        country_group:
          targeting.geo_locations?.country_group?.map((e) => ({
            key: e,
            name: e,
            type: 'country_group',
          })) || ([] as any),
        regions: targeting.geo_locations?.regions! || [],
        zips: targeting.geo_locations?.zips! || [],
      },
      fieldGroup: FacebookAbGroups.TARGETING,
    };
    variables.excluded_geo_locations = {
      field: 'excluded_geo_locations',
      value: {
        countries:
          targeting.excluded_geo_locations?.countries?.map((e) => ({
            key: e,
            name: e,
            type: 'country',
          })) || ([] as any),
        cities: targeting.excluded_geo_locations?.cities! || [],
        country_group:
          targeting.excluded_geo_locations?.country_group?.map((e) => ({
            key: e,
            name: e,
            type: 'country_group',
          })) || ([] as any),
        regions: targeting.excluded_geo_locations?.regions! || [],
        zips: targeting.excluded_geo_locations?.zips! || [],
      },
      fieldGroup: FacebookAbGroups.TARGETING,
    };

    return variables as any;
  }

  public static isAttributionSettingsEnabled(currentObjective: string, performanceGoal: string) {
    if (
      [CampaignMeta.Objective.OUTCOME_LEADS, CampaignMeta.Objective.OUTCOME_SALES].includes(currentObjective as any)
    ) {
      if (
        [AdSetMeta.OptimizationGoal.VALUE, AdSetMeta.OptimizationGoal.OFFSITE_CONVERSIONS].includes(
          performanceGoal as any
        )
      ) {
        return true;
      }
    }
    return false;
  }

  public buildAdSet() {
    if (!this.getName()) {
      throw new Error('Please set name for campaign.');
    }

    const geoLocationVariables = this.findVariableTargeting('geo_locations')?.value!;
    const geo_locations: AdSetMeta.TargetingObject['geo_locations'] =
      this.getGeoLocationTargeting(geoLocationVariables);

    const excludeGeoLocationVariables = this.findVariableTargeting('excluded_geo_locations')?.value!;
    const excluded_geo_locations: AdSetMeta.TargetingObject['excluded_geo_locations'] =
      this.getGeoLocationTargeting(excludeGeoLocationVariables);
    const attribution_spec = Object.entries(this.findVariable('attribution_spec')?.value! || {})?.map(
      ([event_type, window_days]: any) => ({
        event_type,
        window_days,
      })
    );
    const optimization_goal = this.findVariable('optimization_goal')?.value!;

    const isAttributionSettingsEnabled = AdSetDTO.isAttributionSettingsEnabled(
      this.campaignTask?.objective as string,
      optimization_goal
    );
    const daily_budget = this.findVariable('daily_budget')?.value!;
    const lifetime_budget = this.findVariable('lifetime_budget')?.value!;
    let bid_strategy: CampaignMeta.BidStrategy | undefined;
    if (daily_budget || lifetime_budget) {
      bid_strategy = CampaignMeta.BidStrategy.LOWEST_COST_WITHOUT_CAP;
      if (this.findVariable('bid_amount')?.value!) {
        bid_strategy = AdSetMeta.BidStrategy.COST_CAP;
      }
      if (this.findVariable('bid_strategy')?.value!) {
        bid_strategy = this.findVariable('bid_strategy')?.value!;
      }
    }

    const createdAdset: AdSetMeta.CreateApi = {
      campaign_id: this.getAnonymousVariable(this.campaignTask?.internalId!),
      bid_amount: this.findVariable('bid_amount')?.value!,
      attribution_spec: isAttributionSettingsEnabled ? attribution_spec : undefined,
      // is_dynamic_creative: true,
      bid_strategy: bid_strategy!,
      billing_event: this.findVariable('billing_event')?.value!,

      daily_imps: this.findVariable('daily_imps')?.value!,
      daily_min_spend_target: this.findVariable('daily_min_spend_target')?.value!,
      daily_spend_cap: this.findVariable('daily_spend_cap')?.value!,
      end_time: this.findVariable('end_time')?.value!,
      lifetime_imps: this.findVariable('lifetime_imps')?.value!,
      lifetime_min_spend_target: this.findVariable('lifetime_min_spend_target')?.value!,
      lifetime_spend_cap: this.findVariable('lifetime_spend_cap')?.value!,
      name: this.getName(),
      daily_budget,
      lifetime_budget,
      optimization_goal,
      destination_type: this.findVariable('destination_type')?.value!,
      promoted_object: this.findVariable('promoted_object')?.value!,
      start_time: this.findVariable('start_time')?.value!,
      status: this.findVariable('status')?.value!,
      targeting: {
        geo_locations,
        age_min: this.findVariableTargeting('age_min')?.value!,
        age_max: this.findVariableTargeting('age_max')?.value!,
        audience_network_positions: this.findVariableTargeting('audience_network_positions')?.value!,
        device_platforms: this.findVariableTargeting('device_platforms')?.value!,
        facebook_positions: this.findVariableTargeting('facebook_positions')?.value!,
        instagram_positions: this.findVariableTargeting('instagram_positions')?.value!,
        messenger_positions: this.findVariableTargeting('messenger_positions')?.value!,
        publisher_platforms: this.findVariableTargeting('publisher_platforms')?.value!,
        excluded_geo_locations,
        locales: this.findVariableTargeting('locales')?.value!,
        genders: this.findVariableTargeting('genders')?.value!,
        targeting_automation: this.findVariableTargeting('device_platforms')?.value
          ? (undefined as any)
          : this.findVariableTargeting('targeting_automation')?.value!,
      },
    };
    return createdAdset;
  }

  private getGeoLocationTargeting(excludeGeoLocationVariables: AdSetMeta.TargetingObjectAbVariables['geo_locations']) {
    const cities = excludeGeoLocationVariables?.cities;
    const regions = excludeGeoLocationVariables?.regions;
    const countrygroup = excludeGeoLocationVariables?.country_group.map((c) => c.key);
    const countriesFromCountyGroup = country_group
      .filter((c) => countrygroup.includes(c.key))
      .map((e) => e.country_codes)
      .flat();
    const countries = (excludeGeoLocationVariables?.countries || []).map((c) => c.country_code);
    const test = [...new Set([...countriesFromCountyGroup, ...countries])];
    const excluded_geo_locations: AdSetMeta.TargetingObject['excluded_geo_locations'] = {
      countries: test.length ? test : undefined,
      cities: cities.length ? cities : undefined,
      regions: regions.length ? regions : undefined,
      zips: excludeGeoLocationVariables?.zips,
    };
    return excluded_geo_locations;
  }

  public static variationPreview(preview: EntityForCreation<unknown>) {
    const id = preview.entityId + preview.variables.map((e) => e.abId).join('-');
    const dto = new AdSetDTO(preview.variables, preview.entityId, preview.macros);
    return {
      id,
      title: dto.getName(),
      name: dto.getName(),
      status: preview.status,
      movingVariables: preview.movingVariables,
      variables: preview.variables
        .filter((e) => e.type !== 'internal')
        .map((item) => {
          return AdSetDTO.variablePreview(item);
        }),
      active: preview.status,
      entity: 'adset',
    };
  }

  public static variablePreview<T = unknown>(item: ABDependency<T>) {
    const fieldConfig: Partial<FieldConfiguration> = AdSetDTO.findFieldConfig<T>(item.field);
    const group = FacebookAbGroups[fieldConfig.fieldGroup! as 'TARGETING'];

    return {
      field: fieldConfig.readableName ?? item.field,
      name: fieldConfig.readableName ?? item.field,
      groupName: group?.readableName,
      value: fieldConfig.renderFunction ? fieldConfig.renderFunction(item.value) : item.value,
    };
  }

  private static findFieldConfig<T = unknown>(field: string) {
    let fieldConfig: Partial<FieldConfiguration> = {} as any;

    try {
      fieldConfig = AdSetDTO.getFieldConfiguration(field);
    } catch (e) {
      try {
        fieldConfig = AdSetDTO.getFieldConfigurationTargeting(field);
      } catch (e) {
        fieldConfig = { readableName: field, fieldGroup: 'TARGETING' };
      }
    }
    return fieldConfig;
  }

  public setupTask(): AbVariableErrorDTO[] {
    this.calculateName();

    const adset = this.buildAdSet();
    this.setPayload(adset);
    return this.validate(adset);
  }
}
