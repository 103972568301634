import * as ABTest from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';

export type AbTestConstraintFn = (fields: ABTest.ABDependency<unknown>[]) => boolean;

export abstract class ABBase<Value extends ABTest.ABValue<unknown>[]>
  implements ABTest.BaseABTest<ABTest.GroupOfVariables<Value[number]>>
{
  get metadata(): ABTest.ABVariableMetaData {
    throw new Error('Method not implemented.');
  }

  private _entityId!: string;

  public get entityId(): string {
    return this._entityId;
  }

  public get variableUsage(): Record<string, number> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public set entityId(value: string) {
    this._entityId = value;
  }

  get uniqueIdentifier(): string {
    throw new Error('Method not implemented.');
  }

  get values(): ABTest.ABValue<ABTest.GroupOfVariables<Value[number]>>[][] {
    throw new Error('Method not implemented.');
  }

  get tags(): string[] {
    throw new Error('Method not implemented.');
  }

  get macros(): ABTest.Macro[] {
    throw new Error('Method not implemented.');
  }

  public abstract get dependencies(): ABTest.ABDependency[];

  public abstract get name(): string;

  public abstract get description(): string;

  public get id(): string {
    return '';
  }

  private constraints: Array<(fields: ABTest.ABDependency<unknown>[]) => boolean> = [];

  public addConstraints(fns: Array<AbTestConstraintFn>): void {
    this.constraints.push(...fns);
  }

  public abstract get possibleOptions(): Array<ABTest.ABDependency<Value>[]>;

  protected variationStatus(variation: ABTest.ABDependency<Value>[]) {
    if (!this.constraints.length) {
      return true;
    }
    return !this.constraints.every((constraint) => constraint(variation));
  }

  public get variations(): Array<ABTest.ABDependency<Value>[]> {
    return this.possibleOptions.filter(this.variationStatus);
  }

  private _children: Set<string> = new Set();

  public get children(): string[] {
    return Array.from(this._children);
  }

  public registerChild(id: string) {
    this._children.add(id);
  }

  protected parentId?: string;

  setParentId(id: string) {
    this.parentId = id;
  }

  public abstract get type(): ABTest.ABTestType;
}
