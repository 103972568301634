
import notifications from '@sh/mixins/notifications';
import { RuleTypes } from '@sh/types';
import each from 'each.js';
import Vue, { PropType, VueConstructor } from 'vue';

const VueComponent = Vue as VueConstructor<Vue & RuleTypes.Bulk.BulkAction & NotificationMixin>;

export default VueComponent.extend({
  name: 'BulkAction',
  mixins: [notifications],
  props: {
    items: { type: Array as PropType<RuleTypes.Bulk.Item[]>, required: true },
    level: { type: String as PropType<RuleTypes.Bulk.Levels>, required: true },
    showApplyChangesButton: { type: Boolean, required: true },
    selectedRows: { type: Array as PropType<RuleTypes.Bulk.Item[]>, required: true },
    cancelHandler: { type: Function, required: true },
    table: { type: Object, required: true },
    toBeUpdatedItems: { type: Array as PropType<RuleTypes.Bulk.FinalValues[]>, required: true },
    clearPreview: { type: Function, required: true },
    setFailedItems: { type: Function, required: true },
    failedItems: { type: Array, required: true },
    switchApplyButton: { type: Function, required: true },
    switchDisableActions: { type: Function, required: true },
    bulkConfig: { type: (Object as PropType<RuleTypes.Bulk.BulkConfig>) || undefined, required: false },
    bulkAction: { type: String, required: true },
    removeStyles: { type: Function, required: true },
    setItems: { type: Function, required: true },
  },
  data(): any {
    return {
      bulkOperationFinished: true,
      disableCancelButton: false,
      itemsInQueue: [],
      operationStarted: false,
      closeModal: false,
      numberOfActionsThatHaveSucceeded: 0,
      hasErrors: [],
    };
  },
  computed: {
    $c_itemsCounter(): number {
      return this.itemsInQueue.length;
    },
    $c_max(): number {
      return this.toBeUpdatedItems.length;
    },
    $c_fieldName(): string {
      return this.bulkConfig?.entities[this.level]?.bulkActions[this.bulkAction]?.columnName;
    },
    $c_hasFailedItems(): boolean {
      return this.hasErrors?.length > 0;
    },
  },
  watch: {
    items: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(values) {
        if (this.showApplyChangesButton && values.length === 0) {
          this.removeStyles();
        }
        if (this.showApplyChangesButton) {
          const key = `traffic_source_${this.level}_id`;
          // eslint-disable-next-line array-callback-return
          const updated = this.toBeUpdatedItems.filter((i: RuleTypes.Bulk.FinalValues) => {
            const findItem = values.find((v: any) => v[key] === i[key as 'traffic_source_campaign_id']);
            if (findItem) {
              return true;
            }
          });
          this.setItems(updated);
        }
      },
    },
    hasErrors: {
      immediate: true,
      handler(newErrors) {
        if (newErrors.length > 0) {
          this.switchDisableActions(true);
        }
      },
    },
  },
  methods: {
    async $_bulkChangeByRule() {
      // clear error messages in next action
      let CHUNKS: number | null = 5;
      this.setFailedItems([]);
      this.hasErrors = [];
      this.numberOfActionsThatHaveSucceeded = 0;
      this.operationStarted = true;
      let { method, field } = this.bulkConfig.entities[this.level].bulkActions[this.bulkAction];
      const { stateKey, isAdditionalField, hasConditionalMethod, hasConditionalField, conditionalField } =
        this.bulkConfig.entities[this.level].bulkActions[this.bulkAction];
      const key = `traffic_source_${this.level}_id`;

      const itemsThatFailed: RuleTypes.Bulk.FailedItems[] = [];
      const itemsThatSucceeded: string[] = [];
      if (this.toBeUpdatedItems.length > 0) {
        this.numberOfActionsThatHaveSucceeded = this.toBeUpdatedItems.length;
        const { type } = this.toBeUpdatedItems[0];
        this.bulkOperationFinished = false;
        this.selectedRows.forEach((row: any) => {
          row[stateKey].state = 'BUSY';
        });
        CHUNKS = 5;
        if (this.toBeUpdatedItems.length < 8) {
          CHUNKS = 1;
        }
        await each.concurrent(
          this.toBeUpdatedItems,
          async (item: any) => {
            this.disableCancelButton = true;
            try {
              const payload: any =
                this.bulkConfig.entities[this.level].bulkActions[this.bulkAction].payloadHandler(item);
              const level = `${this.level}s`;
              // do ndryshoj kur te kaloj custom field :)
              if (hasConditionalMethod) {
                method = this.bulkConfig.entities[this.level].bulkActions[this.bulkAction].getMethod(item);
              }
              // eslint-disable-next-line no-await-in-loop
              if (method) {
                this.itemsInQueue.push(item.id);
                await this.$api[level][method](...Object.values(payload));
                this.table.items.forEach((row: RuleTypes.Bulk.Item) => {
                  if (item[key as 'traffic_source_campaign_id'] === row[key]) {
                    if (hasConditionalField) {
                      field = conditionalField(item);
                    }
                    if (isAdditionalField) {
                      row.additional_fields[field as 'bid'] = Number(item.value);
                    } else {
                      row[field as 'bid'] = Number(item.value);
                    }
                  }
                });
                itemsThatSucceeded.push(item[key as 'traffic_source_campaign_id']);
              } else {
                itemsThatFailed.push({
                  id: item[key as 'traffic_source_campaign_id'],
                  message: 'Cannot update this field',
                  type,
                  current: item.current,
                  incomingValue: item.value,
                  columnName: item.columnName,
                });
              }
            } catch (error: any) {
              const title =
                error.response?.data?.message &&
                !error.response?.data?.message.includes('An internal server error occurred')
                  ? error.response?.data?.message
                  : 'Something went wrong';
              itemsThatFailed.push({
                id: item[key as 'traffic_source_campaign_id'],
                message: title,
                type,
                current: item.current,
                incomingValue: item.value,
                columnName: item.columnName,
              });
            }
          },
          CHUNKS
        );

        if (itemsThatSucceeded.length > 0 && itemsThatFailed.length === 0) {
          this.$n_successNotification({ title: 'Successfully updated' });
        }
        if (itemsThatFailed.length > 0) {
          this.hasErrors = itemsThatFailed;
        }
        this.setFailedItems(itemsThatFailed);
        this.switchApplyButton(false);
        this.bulkOperationFinished = true;
        this.clearPreview();
        this.selectedRows.forEach((row: any) => {
          row[stateKey].state = 'READY';
        });
        this.removeStyles();
        this.disableCancelButton = false;
        this.itemsInQueue = [];
        this.operationStarted = false;
      }
    },
    $_switchModal() {
      this.hasErrors = [];
      this.switchDisableActions(false);
    },
    async $_handleOk(bvModalEvt: any) {
      await this.$_submit(bvModalEvt);
    },
    showModal() {
      this.$refs.createTableModal.show();
    },
    $_handleClose() {
      Object.keys(this.localProps).forEach((key) => {
        this.localProps[key as 'title'] = null;
      });
      this.$refs?.createTableModal?.$_clear();
    },
    $_mapFailedItemsForTable(items: RuleTypes.Bulk.FailedItems[]) {
      return items.map((item) => ({
        ID: item.id,
        'Current Val.': `$ ${item.current}`,
        'Calculated Val.': `$ ${item.incomingValue}`,
        Field: item.columnName,
        Message: item.message,
        _cellVariants: {
          Message: 'danger',
          'Calculated Val.': 'info',
          'Current Val.': 'info',
        },
      }));
    },
  },
});
