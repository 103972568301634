<template>
  <div v-if="fields.length" class="tracker-view-table">
    <span v-if="title" class="title">{{ title }}</span>
    <table class="table table-bordered table-sm table-hover">
      <thead>
        <tr>
          <td scope="col" v-for="(field, index) in fields" :key="index" :class="field.header.class || ''">{{ field.header.content }}</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in items">
          <tr :key="index" :rowIndex="$_incrementIndex()">
            <td v-for="(field, _index) in fields" :key="_index" :class="field.item.class || ''">
              <slot :name="field.item.key" :item="item" :i="$_getIndex()">
                <div v-html="field.item.content ? field.item.content(item) : typeof item[field.item.key] === 'undefined' ? '' : item[field.item.key]"></div>
              </slot>
            </td>
          </tr>
          <template v-if="nestedKey && item[nestedKey]">
            <tr v-for="(offer, i) in item[nestedKey]" :key="`offer-${i}-${index}`" class="nested-tr" :rowIndex="$_incrementIndex()">
              <td v-for="(field, _index) in fields" :key="_index" :class="field.item.class || ''">
                <span v-if="_index === 0" class="td-space">-</span>
                <slot :name="field.item.key" :item="offer" :i="$_getIndex()">
                  <div v-html="field.item.content ? field.item.content(offer) : typeof offer[field.item.key] === 'undefined' ? '' : offer[field.item.key]"></div>
                </slot>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
      <tfoot v-if="totals">
        <tr>
          <td scope="col" v-for="(field, index) in fields" :key="index">{{ field.item.total && field.item.total.content ? field.item.total.content(totals, items) : '' }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
let counter = null;
export default {
  name: 'TrackerViewTable',
  props: {
    title: { type: String, default: '' },
    fields: { type: Array, required: true },
    items: { type: Array, required: true },
    totals: { type: [Object, null], default: null },
    nestedKey: { type: [String, null], default: null },
  },
  created() {
    counter = -1;
  },
  methods: {
    $_incrementIndex() {
      return ++counter;
    },
    $_getIndex() {
      return counter;
    },
  },
};
</script>

<style lang="scss">
.tracker-view-table {
  .title {
    display: inline-block;
    padding: 0.25em 0.4em;
    white-space: nowrap;
    font-size: 15px;
    border: 1px solid #e1e6ef;
    border-bottom: 0;
    border-top-right-radius: 7px;
  }
  table {
    text-align: center;
    td {
      border-bottom-width: 1px;
      padding: 0.3rem 0.45rem;
      &.weight {
        white-space: nowrap;
        .live-edit {
          display: inline-block;
          &.busy {
            width: 30px;
          }
          .form-control {
            width: 30px;
            padding: 0px;
            text-align: center;
          }
        }
      }
    }
    .nested-tr {
      td:first-child {
        color: #246f86;
        * {
          color: #246f86;
        }
      }
    }
    .td-space {
      margin: 0 15px;
    }
    tfoot {
      td {
        background: rgb(255, 253, 245);
        font-weight: 600;
        color: #3b4043;
      }
    }
  }
}
</style>
