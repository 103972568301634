<template>
  <div :class="`cc-time-picker-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('time')" class="mb-0">
      <b-input-group :state="$_hasError('time')">
        <div class="d-flex align-items-center">
          <div class="time-select">
            <vue-opti-select-light
              :class="[{ 'has-error': $_hasError('time') === false }, 'optimizer-select w-100 time']"
              name="time"
              v-validate="model['v-validate']"
              :data-vv-as="model.label"
              data-vv-validate-on="input|update"
              data-vv-value-path="$c_model"
              v-model="time"
              @input="$_emit('time')"
              :options="$options.timeOptions"
              label-key="text"
              single
              button-block
            >
              <template v-if="model.placeholder" #BUTTON_PLACEHOLDER="{ option }">
                <span class="button-placehoder-filter">
                  <template v-if="option && option.text">
                    {{ option.text }}
                  </template>
                  <template v-else>
                    {{ model.placeholder }}
                  </template>
                </span>
              </template>
            </vue-opti-select-light>
          </div>
          <div class="time-text pl-2 pt-1" v-if="model.textRight">
            <span v-html="model.textRight"></span>
          </div>
        </div>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('time') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcTimePicker',
  mixins: [component],
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      time: '',
    };
  },
  created() {
    // Options
    this.$options.timeOptions = [
      { value: '', text: '', disabled: true },
      { value: '12:00 AM', text: '00:00' },
      { value: '01:00 AM', text: '01:00' },
      { value: '02:00 AM', text: '02:00' },
      { value: '03:00 AM', text: '03:00' },
      { value: '04:00 AM', text: '04:00' },
      { value: '05:00 AM', text: '05:00' },
      { value: '06:00 AM', text: '06:00' },
      { value: '07:00 AM', text: '07:00' },
      { value: '08:00 AM', text: '08:00' },
      { value: '09:00 AM', text: '09:00' },
      { value: '10:00 AM', text: '10:00' },
      { value: '11:00 AM', text: '11:00' },
      { value: '12:00 PM', text: '12:00' },
      { value: '01:00 PM', text: '13:00' },
      { value: '02:00 PM', text: '14:00' },
      { value: '03:00 PM', text: '15:00' },
      { value: '04:00 PM', text: '16:00' },
      { value: '05:00 PM', text: '17:00' },
      { value: '06:00 PM', text: '18:00' },
      { value: '07:00 PM', text: '19:00' },
      { value: '08:00 PM', text: '20:00' },
      { value: '09:00 PM', text: '21:00' },
      { value: '10:00 PM', text: '22:00' },
      { value: '11:00 PM', text: '23:00' },
    ];
    // Bind Model
    this.$watch('value', (value) => {
      const selected = this.$options.timeOptions.find((item) => item.value === value || item.value === value?.value);
      this.time = selected?.value ? selected : null;
    }, { immediate: true });
  },
};
</script>

<style lang="scss">
.cc-time-picker-wrapper {
  .time {
    width: 110px !important;
  }
}
</style>
