<template>
  <div class="component-wrapper">
    <!-- <div v-if="$slots.legend || legend" class="col-sm-12 mb-4">
      <slot v-if="$slots.legend" name="legend"></slot>
      <legend v-else-if="legend" class="header-title">{{ legend }}</legend>
    </div> -->
    <b-form-group
      class="optimizer-form-group"
    >
      <template slot="label">
        <slot name="label"></slot>
        <template v-if="!$slots.label && label && !disableLabel">
          {{ label }}
          <i v-if="popover" class="fa fa-question-circle" v-b-popover.hover="popover"></i>
        </template>
      </template>

      <slot></slot>
    </b-form-group>
    <!-- <div class="col-md-10 mb-3 mx-auto">
      <div class="row">
        <label class="text-right col-sm-3">
          <slot name="label"></slot>
          <strong v-if="!$slots.label && label && !disableLabel">
            {{ label }}
            <i v-if="popover" class="fa fa-question-circle" v-b-popover.hover="popover"></i>
          </strong>
        </label>
        <div class="col-sm-7">
          <slot></slot>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ComponentWrapper',
  props: {
    legend: { type: String, default: '' },
    label: { type: String, default: '' },
    disableLabel: { type: Boolean, default: false },
    popover: { type: [Object, null], default: null },
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  .header-title {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
