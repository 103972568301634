<template>
  <div class="traffic-sources-step account-wizard-step">
    <div class="account-wizard-step__content">
      <h4 class="lead" v-if="!accounts.length">To get started integrate your first Traffic Source and/or Tracker Account. Your campaigns will show up in max 15 minutes.</h4>
      <search v-else v-model="searchAccountsMap" ref="refSearch" :items="accounts" :map-key="item => item.id" :map-value="item => `${item.type.uniqueName}:${item.name}`" />
      <b-alert ref="alert" :show="alert" variant="danger" dismissible @dismissed="alert=false">{{ alert_message }}</b-alert>
      <template>
        <div class="sort-ts">
          <vue-opti-select-light
            class="optimizer-select"
            :options="sortOptions"
            unique-key="id"
            label-key="name"
            button-placeholder="Sort By"
            button-block
            @change="(item) => { sortAccounts(item.id) } "
            single
          />
          <div v-if="$_showSelectAll" class="select-multiple-container">
            <div v-for="(type, key) in $_filterBySearchTermTsType" :key="key" :class="[selectedTrafficSourceTypes.includes(type) ? 'selected select-all' : 'select-all']">
              <span @click="checked(type)" :class="[selectedTrafficSourceTypes.length > 0 &&!selectedTrafficSourceTypes.includes(type) ? 'disabled' : 'enabled']">
                Select all: <span :title="searchTerm" class="mark">{{ searchTerm.length > 7 ? searchTerm.slice(0, 7) + '...' : searchTerm }}
                  <img :src="'/sh_static/accounts/' + type + '.png'" alt="" /></span>
              </span>
              <span v-if="selectedTrafficSourceTypes.includes(type)" class="remove-selection" @click="clearSelectedTs(type)">
                <i class="fa fa-times-circle" style="background: white; borderRadius: 100%" aria-hidden="true"></i>
              </span>
              <span v-if="selectedTrafficSourceTypes.includes(type)" class="accounts-selected badge badge-info badge-pill">
                {{ selectedIds.length }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <div class="account-wizard-step__content__cards">
        <div class="account-wizard-card account-new item" @click="showModal">
          <div class="account-wizard-card__image">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="30" cy="30" r="29" stroke="#7ABDA5" stroke-width="2" />
              <rect x="28.75" y="20" width="2.5" height="20" fill="#7ABDA5" />
              <rect x="40" y="28.75" width="2.5" height="20" transform="rotate(90 40 28.75)" fill="#7ABDA5" />
            </svg>
          </div>
          <div class="caption">
            <h4>Add new</h4>
          </div>
        </div>
        <div v-for="account in $_accounts" :key="account.id" :class="['account-wizard-card', 'item', 'type-'+account.type.uniqueName, {selected: selectedIds.includes(account.id)}]" @click="select(account.id)">
          <div class="account-wizard-card__name">
            <h5>{{ account.name }}</h5>
          </div>
          <div class="account-wizard-card__image">
            <img :src="account.type.logo" />
          </div>
          <div class="account-wizard-card__type">
            <span>{{ account.type.uniqueName }}</span>
          </div>
          <span class="account-wizard-card__selected-icon">
            <i class="fa fa-check"></i>
          </span>
        </div>
      </div>
    </div>
    <TrafficSourceModal ref="refTrafficSourceModal" @submit="saveAccount" />
    <MultiAccountModal ref="refMultiAccountModal" @submit="saveAccount" />

  </div>
</template>

<script>
/* eslint-disable */
import TrafficSourceModal from '@sh/components/TrafficSources/TrafficSourceModal';
import configFile from './config';
import Search from './components/Search';
import MultiAccountModal from '@sh/components/TrafficSources/MultiAccountModal';
export default {
  name: 'AccountWizardTrafficSources',
  components: {
    TrafficSourceModal,
    MultiAccountModal,
    Search,
  },
  data() {
    return {
      config: null,
      alert: false,
      alert_message: '',
      accounts: [],
      accountsMap: {},
      accountSelected: [],
      accountsSelected: [],
      selectedIds: [],
      types: [],
      typesMap: {},
      searchAccountsMap: null,
      selected: null,
      sortOptions: [
        { id: 2, name: 'Newest' },
        { id: 3, name: 'Oldest' },
        { id: 4, name: 'Type' },
      ],
      searchTerm: '',
      selectedTrafficSourceTypes: [],
      allTrafficSourceTypes: [],
    };
  },
  computed: {
    $_accounts() {
      if (this.searchAccountsMap) {
        return this.accounts.filter((item) => typeof this.searchAccountsMap[item.id] !== 'undefined');
      }
      return this.accounts;
    },
    $_showSelectAll() {
      return this.searchTerm.length > 0;
    },
    $_filterBySearchTermTsType() {
      // if the search terms is equal to a traffic source type we return only one item
      if (this.allTrafficSourceTypes.includes(this.searchTerm.toLowerCase())) {
        return [this.searchTerm.toLowerCase()];
      }
      return Array.from(new Set(this.accounts.filter((acc) => acc.name.toLowerCase().includes(this.searchTerm)).map((acc) => acc.trafficSourceType.uniqueName))).slice(0, 5);
    },
  },
  async created() {
    this.config = await configFile.getConfig();
  },
  watch: {
    searchAccountsMap: {
      handler() {
        this.searchTerm = this.$refs.refSearch.localValue;
      },
    }
  },
  methods: {
    async load(forceAccounts = false, forceTypes = false) {
      this.$_hideAlert();
      if (this.types.length === 0 || forceTypes) {
        let types = [];
        types = await this.config.api.accountWizard.loadTypes();
        types = JSON.parse(JSON.stringify(types));
        types.forEach((item, index) => {
          // Set Types obj
          item.logo = this.config.model.images[item.uniqueName.toLowerCase()];
          this.typesMap[item.id] = item;
        });
        // Set Types
        this.types = types;
      }

      if (this.accounts.length === 0 || forceAccounts) {
        let accounts = [];
        accounts = await this.config.api.accountWizard.loadAccounts();
        accounts.forEach((item, index) => {
          item.type.logo = this.typesMap[item.type.id].logo;
          this.accountsMap[item.id] = item;
        });
        // Set Accounts
        this.accounts = accounts;
        this.allTrafficSourceTypes = Array.from(new Set(this.accounts.map((acc) => acc.trafficSourceType.uniqueName.toLowerCase())))
      }
    },
    select(id) {
      this.accounts.forEach((item, index) => {
        if (item.id === id) {
          if (this.selectedIds.includes(item.id)) {
            this.accountsSelected = this.accountsSelected.filter((a) => a.id !== item.id);
            this.selectedIds = this.selectedIds.filter(id => id !== item.id);
          } else {
            this.selectedIds.push(item.id);
            this.accountsSelected.push(item);
          }
          this.$emit('on-select', this.accountsSelected);
        }
      });
    },
    showModal() {
      this.$refs.refTrafficSourceModal.showModal();
    },
    showMultiModal(accounts, trafficSourceName, accountAlias){
      this.$refs.refMultiAccountModal.showModal(accounts, trafficSourceName, accountAlias);
    },
    $_showAlert(msg) {
      this.alert_message = msg;
      this.alert = true;
    },
    $_hideAlert() {
      this.alert_message = '';
      this.alert = false;
    },
    isCompleted() {
      const isAccountSelected = Object.keys(this.selectedIds).length > 0;

      if (!isAccountSelected) {
        this.$_showAlert('Please select at least one account.');
        return false;
      }

      const areAccountsSameType = this.accountsSelected.filter((acc) => acc.type.uniqueName !== this.accountsSelected[0].type.uniqueName).length === 0;
      if (areAccountsSameType) {
        this.$_hideAlert();
        return true;
      }
      this.$_showAlert('Please only select traffic sources of the same type.');
      return false;
    },
    getAccount() {
      return this.accountsSelected;
    },
    getSelectedId() {
      return this.accountSelected.id;
    },
    sortAccounts(id) {
      if (id === 2) {
        this.accounts = this.accounts.sort((a, b) => {
          return b.id - a.id;
        });
      } else if (id === 3) {
        this.accounts = this.accounts.sort((a, b) => {
          return a.id - b.id;
        });
      } else if (id === 4) {
        this.accounts = this.accounts.sort((a, b) => {
          return  a.type.uniqueName === b.type.uniqueName ? 0 : a.type.uniqueName < b.type.uniqueName ? 1 : -1;
        });
      }
    },
    async saveAccount(account) {
      if (account !== false && (account.type || account[0].type)) {
        if(account.length) {
          account.forEach(acc=> acc.type.logo = this.typesMap[acc.type.id].logo)
          this.accounts = [...this.accounts, ...account];
          this.accounts.forEach(el => this.accountsMap[el.id]= el)
          this.accountSelected = account;
          account.forEach((acc) => {
            this.selectedIds.push(acc.id);
            this.accountsSelected.push(acc);  
          });
        }else{
          account.type.logo = this.typesMap[account.type.id].logo;
          this.accounts= [...this.accounts, account]
          this.accountsMap[account.id] = account;
          this.selectedIds.push(account.id);
          this.accountsSelected.push(account);
        }
      }
    },
    checked(tsType) {
      if (this.selectedTrafficSourceTypes[0] === tsType) {
        this.selectedTrafficSourceTypes = [];
      }
      if (this.selectedTrafficSourceTypes[0] !== tsType) {
        this.selectedTrafficSourceTypes = [];
        this.selectedTrafficSourceTypes.push(tsType);
      }
      let accountsOfSameType = [];
      if (this.selectedTrafficSourceTypes.length > 0) {
        accountsOfSameType = this.accounts.filter((acc) => acc.trafficSourceType.uniqueName === tsType & acc.name.toLowerCase().includes(this.searchTerm)).filter((acc) => !this.selectedIds.includes(acc.id));
      }
      if (this.allTrafficSourceTypes.includes(this.searchTerm.toLowerCase())) {
        accountsOfSameType = this.accounts.filter((acc) => acc.trafficSourceType.uniqueName.toLowerCase().includes(this.searchTerm.toLowerCase())).filter((acc)=> !this.selectedIds.includes(acc.id))
      }
      accountsOfSameType.forEach((acc) => {
        this.select(acc.id);
      });
    },
    clearSelectedTs(tsType) {
      this.selectedTrafficSourceTypes = [];
      if (this.allTrafficSourceTypes.includes(this.searchTerm.toLowerCase())) {
        this.accounts.filter((acc) => acc.trafficSourceType.uniqueName.toLowerCase().includes(this.searchTerm.toLowerCase())).forEach((acc) => {
          this.select(acc.id);
        });
      }
      this.accounts.filter((acc) => acc.name.toLowerCase().includes(this.searchTerm) && acc.trafficSourceType.uniqueName === tsType).forEach((acc) => {
        this.select(acc.id);
      });
    },
  },
};
</script>

<style lang="scss">
  .account-wizard-step {
    .sort-ts {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
  
      .optimizer-select {
        width: 150px !important;
      }

      .select-multiple-container {
        display: flex;
        gap: 1rem;

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      
        .enabled {
          pointer-events: auto;
          opacity: 1;
        }
      
        .remove-selection {
          position: absolute;
          bottom: 2rem;
          left: -7px;
        }
      
        .accounts-selected {
          position: absolute;
          bottom: 2.5rem;
          right: -7px;
        }

        .select-all {
          display: flex;
          align-items: center;
          gap: 1rem;
          border: .2rem solid transparent;
          background-color: white;
          padding: .4rem 1rem;
          border-radius: 6px;
          position: relative;
          cursor: pointer;
  
          .mark {
            margin: 0 -0.4em;
            padding: 0.1em 0.4em;
            border-radius: 0.8em 0.3em;
            background: transparent;
            background-image: linear-gradient(
            to right,
            rgba(255, 225, 0, 0.1),
            rgba(255, 225, 0, 0.7) 4%,
            rgba(255, 225, 0, 0.3)
            );
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
          }
        }
    
        .selected {
          border-color: #4aa7ff !important;
        } 
    
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
    }

    &__content {
      .alert {
        width: calc(100% - 2rem);
        margin: 1rem 2rem 0 0;
      }

      &__cards {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr) ) ;
        row-gap: 2rem;
        column-gap: 2rem;

        /* @media screen and (max-width: 1600px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (max-width: 1400px) {
          grid-template-columns: repeat(5, 1fr);
        }

        @media screen and (max-width: 1200px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (max-width: 992px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }*/
      }
    }
  }

  .account-wizard-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    background: $color-white;
    padding: .5rem 1.5rem;
    min-height: 18rem;
    border: $border;
    text-align: center;
    transition: borderColor .25s ease-in-out;
    border-radius: .6rem;
    cursor: pointer;

    &.selected {
      border-color: #4aa7ff;

      .account-wizard-card__selected-icon {
        opacity: 1;
      }
    }

    &__name {
      word-wrap: break-word;
      overflow-wrap: break-word;

      h5 {
        font-size: 1.4rem;
        color: $text-primary;
        font-weight: bold;
      }
    }

    &__image {
      // margin-bottom: 2.5rem;

      img {
        width: 12rem;
      }
    }

    &__type {
      display: none;
      font-size: 1.4rem;
      padding: .3rem .3rem 0 .3rem;
      width: 100%;
      // background: #f7f7f7;
      font-weight: 500;
      margin-bottom: 0;
      color: $text-primary;
    }

    &__selected-icon {
      position: absolute;
      top: -.8rem;
      right: -.8rem;
      background: #188cf8;
      height: 2.1rem;
      width: 2.1rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      opacity: 0;
      transition: opacity .25s ease-in-out;

      i {
        font-size: 1.5rem;
        display: block;
      }
    }

    &.account-new {
      justify-content: center;

      .account-wizard-card__image {
        margin-bottom: 2rem;
      }
    }
  }
</style>