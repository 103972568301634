
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { pluralize, RuleHelpers } from '@sh/helpers';
import { RuleTypes, VueSlider } from '@sh/types';
import RuleInterval from '@sh/views/Rules/components/subcomponents/CustomInterval/IntervalPresets.ts.vue';
import { useRuleStore } from '@sh/views/Rules/store';
import { isEqual } from 'lodash';
import { mapState } from 'pinia';
import { defineComponent, PropType } from 'vue';

interface ComponentData {
  intervalText: string;
  daysInputChange: number;
  isDragSlider: boolean;
}

export default defineComponent({
  components: { RuleInterval, OptimizerIcon },
  model: {
    prop: 'value',
    event: 'onCustomIntervalChange',
  },
  props: {
    isDateIntervalSwitch: Boolean,
    isCustomDateInterval: Boolean,
    selectedFieldName: String,
    value: {
      type: Array as PropType<RuleTypes.ConditionsV2.DateOperations[]>,
      required: true,
    },
    excludedTooltip: String,
  },
  data(): ComponentData {
    return {
      intervalText: '',
      daysInputChange: 0,
      isDragSlider: false,
    };
  },
  computed: {
    sliderOptions(): Partial<VueSlider.Component> {
      const marks = RuleHelpers.DateConditionSlider.marks.reduce((acc: VueSlider.Marks, mark, index) => {
        const value = -RuleHelpers.DateConditionSlider.actualValueToSliderValue(mark);

        acc[value] = {
          label: mark.toString(),
          style: {
            width: '2px',
            display: 'block',
            borderRadius: '0',
            backgroundColor: '#e8e8e9',
            transition: '0.3s',
            height: '4px',
            transform: 'translate(1px, 6px)',
            ...((index === 0 || index === RuleHelpers.DateConditionSlider.marks.length - 1) && {
              transform: 'translate(1px, 1px)',
              height: '9px',
            }),
          },
          labelStyle: {
            color: '#929294',
            fontSize: '12px',
            cursor: 'pointer',
          },
        };
        return acc;
      }, {});

      return {
        ...RuleHelpers.DateConditionSlider.defaultSliderProps,
        max: -RuleHelpers.DateConditionSlider.min,
        min: -RuleHelpers.DateConditionSlider.max,
        marks,
      };
    },
    ruleIntervalPlaceholder(): string | undefined {
      if (this.selectedFieldName) {
        return `Select Date Interval for ${this.selectedFieldName}`;
      }
      return undefined;
    },
    inputValue(): number[] {
      const value = this.customInterval.map((item) => -item);
      value.sort((a, b) => b - a);
      return value;
    },
    sliderValue(): number[] {
      const value = this.customInterval.map((item) => -RuleHelpers.DateConditionSlider.actualValueToSliderValue(-item));
      return value?.length ? value : [0, 0];
    },
    customInterval(): number[] {
      try {
        return this.value.map((item) => RuleHelpers.ConditionsV2.getDateOperationValue(item));
      } catch {
        return [];
      }
    },
    optionSelected(): RuleTypes.ConditionsV2.IntervalPreset | undefined {
      return this.customIntervalPresets.find((option) => isEqual(option.value, this.value));
    },
    ...mapState(useRuleStore, [
      'config',
      'customIntervalPresets',
      'canAccessCustomInterval',
      'customDataDefaultInterval',
    ]),
  },
  created() {
    this.$root.$on('bv::dropdown::hide', this.onDropdownHide);
  },
  beforeDestroy() {
    this.$root.$off('bv::dropdown::hide', this.onDropdownHide);
  },
  methods: {
    pluralize,
    onDropdownHide(bvEvent: Event) {
      if (this.isDragSlider) {
        bvEvent.preventDefault();
      }
    },
    onDragEnd() {
      setTimeout(() => {
        this.isDragSlider = false;
      }, 0);
    },
    updateInterval(value: RuleTypes.ConditionsV2.DateOperations[]) {
      this.$emit('onCustomIntervalChange', value);
    },
    onCustomDateIntervalSwitchChange(value: boolean) {
      this.$emit('isCustomDateInterval', value);

      if (value) {
        this.updateInterval(this.customDataDefaultInterval);
      }
    },
    onDaysInput(index: number, days: string) {
      this.daysInputChange++;
      const value = parseInt(days, 10);
      const dateConditions = this.inputValue.map((item) => -item);
      const validValue = !value || isNaN(value) || value > 90 || value < 0 ? 0 : -value;
      dateConditions[index] = validValue;
      this.$emit('onCustomIntervalChange', RuleHelpers.ConditionsV2.getDateOperations(dateConditions));
    },
    onSliderChange(slider: number[]) {
      const dateConditions = slider.map((item) => -RuleHelpers.DateConditionSlider.sliderValueToActualValue(-item));
      this.updateInterval(RuleHelpers.ConditionsV2.getDateOperations(dateConditions));
    },
    onRelativeIntervalClear(event: Event) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.updateInterval(RuleHelpers.ConditionsV2.getDateOperations([-29, 0]));
    },
  },
});
