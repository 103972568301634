<template>
  <div class="variations-form-wrapper">
    <!-- <div slot="header">
      <h5 class="m-0 p-0 text-uppercase"><i class="fa fa-object-ungroup"></i> GENERATE VARIATIONS</h5>
    </div> -->
    <div class="variations-container">
      <div v-for="(v, index) in variations" :key="index" class="variation">
        <!-- <component-wrapper> -->
        <!-- <template slot="legend"> -->
        <div class="variation-title">
          <h3>{{ v.label || `${capitalizeFirstLetter(stringifyNumber(index + 1))} Variation` }}</h3>
          <b-btn v-if="index > 0" class="remove-button" v-b-tooltip.hover title="Remove Variation" @click.prevent="$_removeVariation(index)">
            <i class="fa fa-times"></i>
          </b-btn>
        </div>
        <!-- </template> -->
        <!-- </component-wrapper> -->
        <div class="variation-card optimizer-card">
          <div class="row">
            <template v-for="(component, cIndex) in v.components">
              <div :class="[ component.component === 'cc-rule-select' ? 'col-md-12' : 'col-md-6']" :key="cIndex">
                <component-wrapper v-if="component.active" v-bind="$_getLabelProps(component)" :key="`wrapper-${component.name}__${index}`">
                  <component :is="component.component" v-bind="$_getProps(component, index, cIndex)" v-on="$_getEvents(component, index, cIndex)" v-model="component.value" />
                </component-wrapper>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- <component-wrapper> -->
      <!-- <template slot="label"> -->
      <!-- <b-btn variant="primary" @click="$_addNewVariation()"><i class="fa fa-plus"></i> Add New Variation</b-btn> -->
      <!-- </template> -->
      <!-- </component-wrapper> -->
      <div class="add-new-variation-container">
        <b-btn class="add-new-btn" @click="$_addNewVariation()">
          <i class="fa fa-plus"></i> Add New Variation
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ccCheckbox from '@/views/Automation/CampaignCreator/components/ccCheckbox';
import ccInput from '@/views/Automation/CampaignCreator/components/ccInput';
import ccMultiSelect from '@/views/Automation/CampaignCreator/components/ccMultiSelect';
import ccRadioButton from '@/views/Automation/CampaignCreator/components/ccRadioButton';
import ccRuleSelect from '@/views/Automation/CampaignCreator/components/ccRuleSelect';
import componentWrapper from '@/views/Automation/CampaignCreator/components/ComponentWrapper';
import helpers from '@/views/Automation/CampaignCreator/mixins/helpers';
import props from '@/views/Automation/CampaignCreator/mixins/props';
import { cloneDeep } from 'lodash';

export default {
  name: 'VariationsForm',
  components: {
    componentWrapper,
    ccInput,
    ccRadioButton,
    ccCheckbox,
    ccRuleSelect,
    ccMultiSelect,
  },
  mixins: [props, helpers],
  props: {
    model: { type: [Object, null], default: null },
    campaign: { type: Object },
    contents: { type: Object },
    payload: { type: Object },
  },
  data() {
    return {
      componentsCopy: [],
      variations: [],
    };
  },
  created() {
    this.$watch(() => {
      const campaing = { ...this.campaign };
      if (this.contents) {
        if (this.contents.targetUrl) campaing.target_url = this.contents.targetUrl;
        if (typeof campaing.cpc === 'undefined' && this.contents.cpc !== 'undefined') campaing.cpc = this.contents.cpc;
      }
      return campaing;
    }, (campaign) => {
      this.variations = [];
      if (campaign) {
        const variationsConfig = this.config.Variations[campaign.traffic_source_unique_name];
        if (variationsConfig) {
          // Clone model
          const components = cloneDeep(variationsConfig.components);

          // I am sorry for this
          this.componentsCopy = components.filter((cmp) => {
            if (cmp.name === 'cpc') {
              if (campaign.traffic_source_unique_name === 'Outbrain' && campaign.campaignOptimizationType === 'MAX_CONVERSION_FULLY_AUTOMATED') {
                return false;
              }
            }
            return true;
          });

          // Set Values
          this.componentsCopy.forEach((cmp) => {
            if (typeof campaign[cmp.name] !== 'undefined') {
              cmp.value = campaign[cmp.name];
            } else if (cmp.name === 'target_url') {
              // UnRequire target_url
              delete cmp['v-validate'].required;
            } else if (cmp.name === 'rules' && this.payload.rules) {
              // Default Rules
              cmp.value = this.payload.rules;
            }
          });
          // Set Main Variation
          const main = cloneDeep(this.componentsCopy);
          main.forEach((cmp) => {
            if (['name', 'rules'].indexOf(cmp.name) === -1) cmp.disabled = true;
            // Execute on created
            if (cmp.init) cmp.init({ components: main, component: cmp });
          });
          this.variations.push({
            label: 'Main Variation',
            components: main,
          });
        }
      }
    }, { deep: true, immediate: true });

    // Emit
    this.$watch('variations', (variations) => {
      const variationsConfig = this.config.Variations[this.campaign.traffic_source_unique_name];
      const variationsData = [];
      variations.forEach((variation) => {
        const form = variationsConfig.getForm(variation.components);
        variationsData.push(form);
      });
      this.$emit('input', { model: variations, data: variationsData });
    }, { deep: true });
  },
  methods: {
    async validate() {
      const components = [];
      Object.entries(this.$refs).forEach(([nameIndex, component]) => {
        const spl = nameIndex.split('__');
        const index = Number(spl[1]);
        const cIndex = Number(spl[2]);
        if (this.variations[index] && this.variations[index].components[cIndex] && this.variations[index].components[cIndex].active) {
          if (component !== undefined) {
            components.push(component);
          }
        }
      });
      try {
        const resultArr = await Promise.all(components.map((cmp) => cmp.validate()));
        return resultArr.reduce((total, value) => total && value, true);
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    $_getLabelProps(component) {
      return { label: component.label, popover: component.popover, disableLabel: !!component.disableLabel };
    },
    $_getProps(component, index, cIndex) {
      return {
        ref: `${component.name}__${index}__${cIndex}`, mode: this.mode, retry: this.retry, config: this.config, model: component, key: `${component.name}__${index}__${cIndex}`,
      };
    },
    $_getEvents(component, index) {
      const events = {};
      if (component.events) {
        Object.entries(component.events).forEach(([event, fn]) => {
          const { components } = this.variations[index];
          events[event] = function () {
            // eslint-disable-next-line prefer-rest-params
            fn({ components, component }, ...arguments);
          };
        });
      }
      return events;
    },
    $_addNewVariation() {
      const components = cloneDeep(this.componentsCopy).map((cmp) => {
        if (cmp.manageState) {
          cmp.manageState({ component: cmp }, this.campaign);
        }
        return cmp;
      });

      const nameComponent = components.find((item) => item.name === 'name');
      nameComponent.value = `${this.campaign.name}_var_${this.variations.length + 1}`;
      this.variations.push({
        components,
      });
      // Execute on created
      components.forEach((component) => {
        if (component.init) component.init({ components, component });
      });
    },
    $_removeVariation(index) {
      this.variations.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.variations-form-wrapper {
  .variations-container {
    &:first-child {
      margin-bottom: 0;
    }

    .variation {
      margin-top: 4rem;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;

        h3 {
          font-size: 2.1rem;
          font-weight: bold;
          color: $text-primary;
        }

        .remove-button {
          font-size: 2rem;
          width: 3.2rem;
          height: 3.2rem;
          background: #E4EAEE;
          border-radius: .6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          color: #546582;
        }
      }

      &-card {
        padding: 1.5rem 2.5rem;
      }
    }

    .add-new-variation-container {
      margin-top: 2rem;

      .add-new-btn {
        display: inline-block;
        background: #68B2F7;
        border-color: #68B2F7;
        border-radius: .6rem;
        font-size: 1.4rem;
        padding: .6rem 1.2rem;

        &:hover,
        &:active {
          background: #4e9de7;
          border-color: #4e9de7;
        }
      }
    }
  }

  // .btn.remove-button {
  //   position: relative;
  //   bottom: 5px;
  //   padding-top: 3px;
  //   padding-bottom: 3px;
  //   margin-left: 10px;
  // }
}
</style>
