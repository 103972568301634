import { api } from '@sh/services/api';

export default {
  // Campaign Change Status
  'Campaign Change Status': async (item) => api.campaigns.changeStatus(item.campaignId, JSON.parse(item.itemAffectedFields).enabled.before),

  // Campaign Change Bid
  'Campaign Change Bid': async (item) => api.campaigns.changeBid(item.campaignId, JSON.parse(item.itemAffectedFields)['additional_fields:bid'].before),

  // Campaign Change Bid Gemini
  //

  // Campaign Change Budget
  'Campaign Change Budget': async (item) => api.campaigns.changeBudget(
    item.campaignId,
    JSON.parse(item.itemAffectedFields)['additional_fields:budget'].before,
    JSON.parse(item.itemDetails).additional_fields.budget_type,
  ),

  // Campaign Change Daily Budget
  'Campaign Change Daily Budget': async (item) => api.campaigns.changeDailyBudget(item.campaignId, JSON.parse(item.itemAffectedFields)['additional_fields:daily_budget'].before),

  // Widget Change Status
  'Widget Change Status': async (item) => api.widgets.changeStatus(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).status.before === 'RUNNING' ? 'start' : 'pause',
    [{
      name: item.itemName,
      publisher_id: JSON.parse(item.itemDetails).additional_fields.publisher_id,
      traffic_source_widget_id: JSON.parse(item.itemDetails).traffic_source_widget_id,
    }],
  ),

  // Widget Change Budget
  'Widget Change Budget': async (item) => api.campaigns.changeBudget(
    item.campaignId,
    JSON.parse(item.itemAffectedFields)['additional_fields:budget'].before,
    JSON.parse(item.itemDetails).additional_fields.budget_type,
  ),
  // Widget Change Daily Budget
  'Widget Change Daily Budget': async (item) => api.campaigns.changeDailyBudget(
    item.campaignId,
    JSON.parse(item.itemAffectedFields)['additional_fields:daily_budget'].before,
  ),

  // Widget Change Bid
  'Widget Change Bid': async (item) => api.widgets.changeBid(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).cpc.before,
    [{
      name: item.itemName,
      publisher_id: JSON.parse(item.itemDetails).additional_fields.publisher_id,
      traffic_source_widget_id: JSON.parse(item.itemDetails).traffic_source_widget_id,
    }],
  ),


  // Widget Change Bid ContentAd
  //


  // Widget Change Strategy
  //

  // AdGroup Change Status
  'AdGroup Change Status': async (item) => api.adgroups.changeStatus(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).enabled.before,
    [item.itemId],
  ),

  // AdGroup Change Native Bid
  'AdGroup Change Native Bid': async (item) => api.adgroups.changeNativeBid(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).cpc_native.before,
    JSON.parse(item.itemDetails).cpc_search,
    [item.itemId],
  ),

  // AdGroup Change Search Bid
  'AdGroup Change Search Bid': async (item) => api.adgroups.changeSearchBid(
    item.campaignId,
    JSON.parse(item.itemDetails).cpc_native,
    JSON.parse(item.itemAffectedFields).cpc_search.before,
    [item.itemId],
  ),

  // AdGroup Change Daily Budget
  'AdGroup Change Daily Budget': async (item) => api.adgroups.changeAdGroupDailyBudget(
    item.campaignId,
    [item.itemId],
    JSON.parse(item.itemAffectedFields)['additional_fields:daily_budget'].before,
    'limited',
  ),

  // AdGroup Change Budget
  'AdGroup Change Budget': async (item) => api.adgroups.changeAdGroupBudget(
    item.campaignId,
    [item.itemId],
    JSON.parse(item.itemAffectedFields)['additional_fields:budget'].before,
    'limited',
  ),

  // Content Change Status
  'Content Change Status': async (item) => api.contents.changeStatus(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).enabled.before,
    [item.itemId],
  ),

  // Content Change Bid
  'Content Change Bid': async (item) => api.contents.changeBid(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).cpc.before,
    [item.itemId],
  ),

  // Domain Change Status
  // 'Domain Change Status': async (item) => api.domains.changeStatus(
  //   item.campaignId,
  //   JSON.parse(item.itemAffectedFields).status.before === 'RUNNING' ? 'start' : 'pause',
  //   [item.itemId],
  // ),

  // Exchange Change Status
  // 'Exchange Change Status': async (item) => api.exchanges.changeStatus(
  //   item.campaignId,
  //   JSON.parse(item.itemAffectedFields).enabled.before,
  //   [item.itemId],
  // ),

  // Section Change Status
  'Section Change Status': async (item) => api.sections.changeStatus(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).status.before === 'RUNNING' ? 'start' : 'pause',
    [item.itemId],
  ),

  // Section Change Bid
  'Section Change Bid': async (item) => api.sections.changeBid(
    item.campaignId,
    JSON.parse(item.itemAffectedFields).cpc.before,
    [item.itemId],
  ),

  // Site Change Status
  // 'Site Change Status': async (item) => api.sites.changeStatus(
  //   item.campaignId,
  //   JSON.parse(item.itemAffectedFields).enabled.before,
  //   [item.itemId],
  // ),

  // Site Change Bid
  // 'Site Change Bid': async (item) => api.sites.changeBid(
  //   item.campaignId,
  //   item.itemAffectedFields.cpc.before,
  //   [item.itemId],
  // ),
};
