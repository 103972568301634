<template>
  <!-- Modal Component -->
  <b-modal
    ref="performanceModal"
    id="performance-modal"
    modal-class="optimizer-modal performance-modal"
    no-close-on-backdrop
    size="xl"
    centered
    scrollable
    hide-footer
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Performance for {{ $c_entity }} {{ type === 'content' ? 'with id:' : ':' }} {{ name }} ({{ itemId }})</h2>

      <svg class="modal-header__close-icon" @click="hideModal" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <!-- Modal Body -->
    <FacebookBreakdownPerformance v-if="$c_showFacebookPerformance" ref="facebookPerformance" :id="id" :itemId="itemId" :type="$c_type" :modal="true" :trafficSource="trafficSource" :dateRange="dateRange" />
    <PerformanceStats v-else ref="performance" :type="$c_type" :id="id" :modal="true" :timezone="timezone" :dateRange="dateRange" />
  </b-modal>
</template>

<style scoped>

</style>

<script>
import PerformanceStats from '@sh/components/Performance/PerformanceStats';
import FacebookBreakdownPerformance from './FacebookBreakdownStats.vue';

export default {
  name: 'PerformanceModal',
  components: {
    PerformanceStats,
    FacebookBreakdownPerformance,
  },
  props: {
    itemId: { type: String },
    trafficSource: { type: Object },
    type: { type: Object },
    id: { type: String },
    name: { type: [String, Number] },
    timezone: { type: String },
    dateRange: Object,
  },
  computed: {
    $c_showFacebookPerformance() {
      return (this.type.id === 'adgroups' && this.trafficSource && this.trafficSource.type.uniqueName === 'Facebook');
    },
    $c_type() {
      if (this.type.id === 'adgroups') {
        return 'adGroup';
      }
      return this.type.id.substring(0, this.type.id.length - 1);
    },
    $c_entity() {
      const lastCharacter = this.type.name.charAt(this.type.name.length - 1);

      if (lastCharacter === 's') {
        return this.type.name.substring(0, this.type.name.length - 1);
      }
      return this.type.name;
    },

  },
  created() {
    // console.info('Created: TrackerModal')
  },
  methods: {
    hideModal() {
      this.$refs.performanceModal.hide();
    },
    showModal() {
      this.$refs.performanceModal.show();
    },
  },
};
</script>

<style lang="scss">
  .optimizer-modal {
    &.performance-modal {
      .modal-dialog {
        max-width: calc(100vw - 33rem);

        .modal-body {
          padding: 0;

          .performance-table {
            .stickyHeader {
              top: -.1rem;
            }

            .row.header,
            .row.row.footer {
              margin-left: 0;
              margin-right: 0;
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
</style>
