<template>
  <b-input-group class="campaign-select-container">
    <v-select
      ref="select"
      v-model="model"
      @input="$emit('input', model)"
      label="label"
      :max-height="maxHeight"
      :placeholder="placeholder"
      class="campaign-select-wrapper form-control multiselect"
      :close-on-select="false"
      :clear-search-on-select="false"
      :multiple="multiple"
      :disabled="disabled"
      :options="displayOptions"
      @search="$_onSearch"
      :filterable="false"
      @search:blur="$_onBlur"
      @search:focus="$_onFocus"
    >
      <template slot="option" slot-scope="option">
        <span v-html="$_getOptionLabel(option)"></span>
      </template>
      <template slot="selected-option" slot-scope="option">
        <span v-html="$_getOptionLabel(option)"></span>
      </template>
    </v-select>
    <b-dropdown v-if="enableFilter" slot="append" :no-flip="true" @hidden="$_dropdownHidden" right>
      <template #button-content>
        <i class="fa fa-filter"></i> Add Campaigns
      </template>
      <div class="searchCampaignItem">
        <b-input v-model="searchModel" placeholder="Search..." autocomplete="off" class="searchCampaignInput w-100" />
        <div class="searchInfo d-flex justify-content-between">
          <span class="count-display">Found: {{ $c_searchResultByDimension.search.length }}</span>
          <b-btn class="add-btn" size="sm" @click="$_addSearchCampaigns">
            Add
          </b-btn>
        </div>
      </div>
      <b-dropdown-header v-for="(item, i) in $c_filter" :key="i">
        <b-form-checkbox v-model="item.selected" @change="(value) => {$_selectItem(item, value)}">
          <div class="d-inline-block" v-html="item.content"></div>
          <div class="d-inline-block counter">
            (<template v-if="typeof item.type !== 'undefined' && typeof item.enabled !== 'undefined'">
              <template v-if="$c_searchResultByDimension[item.type]">
                {{ $c_searchResultByDimension[item.type][`${item.enabled}`].length }}
              </template>
              <template v-else>
                0
              </template>
            </template>
            <template v-else-if="typeof item.type !== 'undefined' && typeof item.enabled === 'undefined'">
              <template v-if="$c_searchResultByDimension[item.type]">
                {{ $c_searchResultByDimension[item.type].all.length }}
              </template>
              <template v-else>
                0
              </template>
            </template>
            <template v-else-if="typeof item.type === 'undefined' && typeof item.enabled === 'undefined'">
              {{ $c_searchResultByDimension.all.length }}
            </template>
            <template v-else-if="typeof item.type === 'undefined' && typeof item.enabled !== 'undefined'">
              <template v-if="$c_searchResultByDimension[`${item.enabled}`]">
                {{ $c_searchResultByDimension[`${item.enabled}`].length }}
              </template>
              <template v-else>
                0
              </template>
            </template>)
          </div>
        </b-form-checkbox>
      </b-dropdown-header>
      <b-dropdown-item @click="$_clearForm" class="clear-form-item">
        <i class="fa fa-times text-danger"></i> Clear
      </b-dropdown-item>
    </b-dropdown>
  </b-input-group>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import { getTrafficSourceLogo } from '@sh/helpers';
import { TrafficSource } from '@sh/types';

export default {
  name: 'CampaignSelect',
  props: {
    value: { type: [Array, Object], default: () => [] },
    options: { type: Array, required: true },
    uniqueName: { type: String, default: 'uniqueName' },
    multiple: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Search Campaign...' },
    maxHeight: { type: String, default: '400px !important' },
    enableFilter: { type: Boolean, default: true },
    trafficSourceFilter: { type: Array, default: () => [] },
    filterCampaigns: { type: [Object, null], default: null },
    enableFilterAllByType: { type: Boolean, default: false },
    statusConfig: {
      type: Object,
      default: () => ({
        field: 'status',
        values: {
          RUNNING: true, running: true, PAUSED: false, paused: false,
        },
      }),
    },
  },
  data() {
    return {
      model: [],
      emit: false, // Skip first input emit
      localOptions: [],
      filteredOptions: [],
      displayOptions: [],
      searchModel: '',
    };
  },
  computed: {
    $c_platformFilter() {
      const filter = [];
      let trafficSources = [TrafficSource.RevContent, TrafficSource.Taboola, TrafficSource.Outbrain, TrafficSource.Mgid, TrafficSource.ContentAd, TrafficSource.Adskeeper, TrafficSource.Gemini, TrafficSource.VoluumDSP, TrafficSource.ActiveRevenue, TrafficSource.Runative, TrafficSource.Facebook, TrafficSource.AmazonDSP];
      trafficSources.forEach((type) => {
        if (this.enableFilterAllByType) filter.push({type, content: this.$_getItemContent('Add All', type), selected: false});
        filter.push(
          {
            type, enabled: true, content: this.$_getItemContent('Add Active', type), selected: false,
          },
          {
            type, enabled: false, content: this.$_getItemContent('Add Paused', type), selected: false,
          },
        );
      });
      return filter;
    },
    $c_filter() {
      if (this.trafficSourceFilter.length === 0) {
        return this.$c_platformFilter;
      }
      if (this.trafficSourceFilter.length === 1) {
        return this.$c_platformFilter.filter((item) => item.type === this.trafficSourceFilter[0]);
      }
      return this.$c_platformFilter.filter((item) => this.trafficSourceFilter.indexOf(item.type) > -1 || typeof item.type === 'undefined');
    },
    $c_searchResultByDimension() {
      const dimensions = { all: [], search: [] };
      try {
        const search = this.searchModel.toLowerCase().trim();
        this.localOptions.forEach((item) => {
          const searchResult = search === '' || item.name.toLowerCase().indexOf(search) > -1;
          if (!searchResult) return;
          const enabled = this.$_enabled(item);
          if (!dimensions[item.traffic_source_unique_name]) dimensions[item.traffic_source_unique_name] = { all: [], true: [], false: [] };
          if (!dimensions[`${enabled}`]) dimensions[`${enabled}`] = [];

          dimensions[`${enabled}`].push(item);
          dimensions[item.traffic_source_unique_name].all.push(item);
          if (dimensions[item.traffic_source_unique_name][`${enabled}`]) {
            dimensions[item.traffic_source_unique_name][`${enabled}`].push(item);
          }
          if (search !== '') dimensions.search.push(item);
          dimensions.all.push(item);
        });
      } catch (err) {
        console.log(err);
      }
      return dimensions;
    },
  },
  created() {
    this.$watch('options', (options) => {
      /** ************** Load Options *************** */
      if (this.filterCampaigns === null) {
        this.localOptions = options;
      } else {
        this.localOptions = options.filter((item) => {
          let valid = true;
          Object.entries(this.filterCampaigns).forEach(([key, value]) => {
            if (valid && item[key] !== value) valid = false;
          });
          return valid;
        });
      }
      this.localOptions = this.localOptions.map((item) => {
        item.label = `${item.name}::${item.id}`;
        return item;
      });
      this.displayOptions = [];
      this.filteredOptions = this.localOptions;
      this.model = this.value;
      /** ************** ************ *************** */
    }, { immediate: true });

    this.$watch('value', (value) => {
      this.model = value;
    }, { immediate: true })
  },
  methods: {
    getTrafficSourceLogo,
    $_onBlur() {
      this.$emit('blur');
    },
    $_onSearch(search, loading) {
      this.$_search(search, this);
    },
    $_search: debounce((search, vm) => {
      search = search.toLowerCase();
      if (search === '') {
        vm.filteredOptions = vm.localOptions;
      } else {
        vm.filteredOptions = vm.localOptions.filter((item) => item.name.toLowerCase().indexOf(search) > -1);
      }
      vm.$_dropdownRenderItems(true);
    }, 500),
    $_onFocus() {
      this.$nextTick(() => {
        const ul = this.$refs.select.$el.querySelector('.vs__dropdown-menu');
        ul.onscroll = () => {
          this.$_dropdownRenderItems();
        };
        this.$_dropdownRenderItems();
      });
    },
    $_getOptionLabel(option) {
      return `<div class="iOption ${this.$_enabled(option) ? '' : 'disabled'}"><img src="${getTrafficSourceLogo(option[this.uniqueName])}" title="${option[this.uniqueName]}" /><span class="option-label">${option.name}</span></div>`;
    },
    // $_selectItem_OLD (item, value) {
    //   if (typeof item.type !== 'undefined' && typeof item.enabled !== 'undefined') {
    //     // Add Enabled or Disabled by SourceType
    //     this.model = this.model.filter(row => this.$_enabled(row) !== item.enabled || row.traffic_source_unique_name !== item.type)
    //     if (value) this.model.push(...this.localOptions.filter(row => this.$_enabled(row) === item.enabled && row.traffic_source_unique_name === item.type))
    //   } else if (typeof item.type !== 'undefined' && typeof item.enabled === 'undefined') {
    //     // Add All by SourceType
    //     this.model = this.model.filter(row => row.traffic_source_unique_name !== item.type)
    //     if (value) this.model.push(...this.localOptions.filter(row => row.traffic_source_unique_name === item.type))
    //   } else if (typeof item.type === 'undefined' && typeof item.enabled === 'undefined') {
    //     // Add All
    //     value ? this.model = this.localOptions : this.model = []
    //   } else if (typeof item.type === 'undefined' && typeof item.enabled !== 'undefined') {
    //     // Add All Enabled or Disabled
    //     this.model = this.model.filter(row => this.$_enabled(row) !== item.enabled)
    //     if (value) this.model.push(...this.localOptions.filter(row => this.$_enabled(row) === item.enabled))
    //   }
    // },
    $_selectItem(item, value) {
      if (typeof item.type !== 'undefined' && typeof item.enabled !== 'undefined') {
        // Add Enabled or Disabled by SourceType
        this.model = this.model.filter((row) => this.$_enabled(row) !== item.enabled || row.traffic_source_unique_name !== item.type);
        try {
          if (value) this.model.push(...this.$c_searchResultByDimension[item.type][`${item.enabled}`]);
        } catch (err) {}
      } else if (typeof item.type !== 'undefined' && typeof item.enabled === 'undefined') {
        // Add All by SourceType
        this.model = this.model.filter((row) => row.traffic_source_unique_name !== item.type);
        try {
          if (value) this.model.push(...this.$c_searchResultByDimension[item.type].all);
        } catch (err) {}
      } else if (typeof item.type === 'undefined' && typeof item.enabled === 'undefined') {
        // Add All
        value ? this.model = this.localOptions : this.model = [];
      } else if (typeof item.type === 'undefined' && typeof item.enabled !== 'undefined') {
        // Add All Enabled or Disabled
        this.model = this.model.filter((row) => this.$_enabled(row) !== item.enabled);
        try {
          if (value) this.model.push(...this.$c_searchResultByDimension[`${item.enabled}`]);
        } catch (err) {}
      }
    },
    $_getItemContent(text, type) {
      return type ? `<span class="filter-item"><img src="${getTrafficSourceLogo(type)}" title="${type}" /> ${text}</span>` : `<span class="filter-item">${text}</span>`;
    },
    $_clearForm() {
      this.model = [];
      this.$c_platformFilter.forEach((item) => { item.selected = false; });
    },
    $_addSearchCampaigns() {
      const modelMap = {};
      this.model.forEach((item) => { modelMap[item.id] = item; });
      this.$c_searchResultByDimension.search.forEach((item) => {
        if (!modelMap[item.id]) this.model.push(item);
      });
    },
    $_dropdownHidden() {
      this.searchModel = '';
    },
    $_dropdownRenderItems(reset = false) {
      const ul = this.$refs.select.$el.querySelector('.vs__dropdown-menu');
      const { scrollTop } = ul;
      if (reset) this.displayOptions = [];
      if (!this.displayOptions.length || (ul.scrollHeight - 30 <= ul.clientHeight + scrollTop)) {
        if (this.displayOptions.length < this.filteredOptions.length) {
          // Load items
          this.displayOptions.push(...this.filteredOptions.slice(this.displayOptions.length, this.displayOptions.length + 30));
          this.$nextTick(() => {
            ul.scrollTo(0, scrollTop);
          });
        }
      }
    },
    $_enabled(row) {
      try {
        const value = this.statusConfig.values[row[this.statusConfig.field]];
        return typeof value === 'boolean' ? value : null;
      } catch (err) {
        return null;
      }
    },
  },
  watch: {
    model(value) {
      if (this.emit) {
        this.$emit('input', value);
      } else {
        this.emit = true;
      }
    },
  },
};
</script>

<style lang="scss">
.campaign-select-form{
  .campaign-select-wrapper {
    // border-right: 0;
    .iOption {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      vertical-align: middle;
      &.disabled {
        color: #7c7c7c;
      }
      img {
        height: 2rem;
        display: inline;
        margin-right: 5px;
        line-height: 10px;
        margin-bottom: 1px;
      }
      .option-label {
        word-break: break-all;
      }
    }
    &.v-select {
      .selected-tag {
        padding: 2px 20px 2px 5px;
        height: 100%;
        position: relative;
        margin: 2px 1px 2px 3px;
        > span {
          display: inline-block;
        }
        > .close {
          position: absolute;
          top: calc(50% - 6px);
          right: 3px;
          line-height: 11px;
        }
      }
    }
  }
  .b-dropdown {
    border: 0px;
    height: 2.6em;
    margin-left: -1px;
    .dropdown-toggle {
      width: 100%;
    }
    .dropdown-menu {
      min-width: 13rem;
      max-height: 400px;
      z-index: 99999;
      overflow-y: scroll;
      .searchCampaignItem {
        .searchCampaignInput {
          border-top: 0;
          border-bottom: 1px dashed #e1e6ef;
          border-right: 0;
          border-left: 0;
        }
        .searchInfo {
          padding: 5px 10px;
          border-bottom: 1px solid #e1e6ef;
          .count-display {
            padding-top: 5px;
          }
        }
      }
      .dropdown-header {
        color: #151b1e;
        background-color: #FFF;
        padding: 5px 10px;
        label.custom-checkbox {
          margin-bottom: 0;
          width: calc(100% - 10px);
          .custom-control-description {
            line-height: 24px;
          }
          .filter-item {
            word-break: break-all;
            img {
              height: 2rem;
              display: inline;
              margin-right: 5px;
              line-height: 10px;
              margin-bottom: 1px;
            }
          }
        }
      }
      .clear-form-item {
        padding: 5px 20px;
        i {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
