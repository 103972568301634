<template>
  <div class="field-tooltip w-100 d-flex" :class="`justify-content-${position}`">
    <div
      class="pr-12"
      v-b-tooltip.hover.right.html="{
        customClass: 'field-tooltip custom-tooltip max-width-190 default-cursor',
        title: info,
        fallbackPlacement: 'counterclockwise',
        boundary: 'viewport',
      }"
    >
      {{ option.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldTooltip',
  props: {
    info: { type: String, default: '' },
    option: { type: Object, default: () => ({}) },
    position: { type: String, default: 'between' },
  },
};
</script>
