
import { useMediaManagerStore } from '@/stores/media-manager';
import AdContainer from '@/views/Automation/MediaManager/AdContainer.ts.vue';
import AdModal from '@/views/Automation/MediaManager/AdModal.ts.vue';
import { AbortController, addImageEditorScript } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  storeSubscription?: Function;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    AdContainer,
    AdModal,
  },
  props: {
    mode: {
      type: String as PropType<MediaManager.Mode>,
      required: true,
    },
    isCampaignCreator: {
      type: Boolean,
      default: false,
    },
    trafficSource: {
      type: String,
      required: false,
    },
    isTableView: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      storeSubscription: undefined,
    };
  },
  computed: {
    ...mapState(useMediaManagerStore, ['settings']),
    ...mapWritableState(useMediaManagerStore, ['errorMessage']),
  },
  async created() {
    addImageEditorScript();
    useMediaManagerStore().$reset();
    useMediaManagerStore().$patch({
      mode: this.mode,
      isCampaignCreator: this.isCampaignCreator,
      isTableView: this.isTableView,
      mediaManagerAPI: this.$api.mediaManager,
    });
    this.storeSubscription = useMediaManagerStore().$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateSettings') {
          const filters: MediaManager.RequestTypes.Filters = {};

          if (this.settings.orderDropDown.length) {
            filters.order = [this.settings.orderDropDown[0]];
          }

          if (this.isCampaignCreator && this.trafficSource) {
            filters.trafficsource = this.getUploadedTrafficSourcesFilter([this.trafficSource]);
          } else {
            filters.trafficsource = [];
          }

          this.updateFilters(filters);
        }
      });
    });
    this.getSettings();
  },
  destroyed() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions(useMediaManagerStore, [
      'updateFilters',
      'updateSettings',
      'reset',
      'getUploadedTrafficSourcesFilter',
    ]),
    async getSettings() {
      try {
        const settings = await this.$api.mediaManager.getSettings(
          { category: this.mode },
          `media-manager-${this.mode}-settings`
        );
        this.updateSettings({
          ...settings,
          accounts: settings.accounts.filter(
            (account) =>
              !process.env.VUE_APP_CREATIVE_LIBRARY_NOT_ALLOWED_TS?.includes(account.traffic_source_unique_name)
          ),
          uniqueTrafficSources: settings.uniqueTrafficSources.filter(
            (trafficSource) => !process.env.VUE_APP_CREATIVE_LIBRARY_NOT_ALLOWED_TS?.includes(trafficSource.name)
          ),
          orderDropDown: settings.orderDropDown,
        });
      } catch (error: any) {
        if (!AbortController.isAbortError(error)) {
          this.reset();
          this.errorMessage = 'We could not get user settings. Please try again in a few moments or contact support.';
        }
      }
    },
  },
});
