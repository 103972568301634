import userLocalStorage from '@sh/services/userLocalStorage';

const storageKey = 'resized-columns';

export default class ResizedColumns {
  getResizedColumns(type, name) {
    let data = this._getData();
    if (type) {
      if (name) {
        data && data[type] && data[type][name] ? (data = data[type][name]) : (data = {});
      } else {
        data && data[type] ? (data = data[type]) : (data = {});
      }
    }
    return data || {};
  }

  /**
   * Save Resized Columns to Local Storage
   * @param {String} type Level Type (campaigns, widget etc.)
   * @param {String} name Traffic Source Unique Name
   * @param {Object} value Resized Columns Object
   * */
  setResizedColumns(type, name, value) {
    if (!value) return;
    const data = this._getData() || {};
    if (name) {
      if (data[type]) data[type][name] = value;
      else data[type] = { [name]: value };
    } else {
      data[type] = value;
    }
    this._setData(data);
  }

  _getData() {
    return userLocalStorage.getSettings(storageKey);
  }

  _setData(payload) {
    userLocalStorage.setSettings(storageKey, payload);
  }
}
