import { ImageType } from '@sh/types/ImageTypes';
import * as MetricFilterTypes from '@sh/types/MediaManager/MetricFilter';
import * as RequestTypes from '@sh/types/MediaManager/Request';
import * as ResponseTypes from '@sh/types/MediaManager/Response';

export { MetricFilterTypes, ResponseTypes, RequestTypes };

export enum Mode {
  ADS = 'ad',
  IMAGES = 'image',
  HEADLINES = 'headline',
}

export enum ActionType {
  EDIT = 'editAction',
  DELETE = 'deleteAction',
  CLONE = 'cloneAction',
}

export class AdSelectedForEdit {
  isImageSelected = false;
  isHeadlineSelected = false;
  areTagsSelected = false;
}

export class AdCard {
  id!: string;
  image?: ImageType;
  trafficSource?: ResponseTypes.TrafficSource;
  headline?: string;
  tags!: string[];
  metrics!: MetricDto[];
  usedOn!: number;
  isEditable!: boolean;
  selected!: boolean;
  selectedForEdit?: AdSelectedForEdit;
  description?: string;

  constructor(isGeneralEdit = true) {
    this.image = {};
    this.headline = '';
    this.tags = [];

    if (isGeneralEdit) {
      this.selectedForEdit = new AdSelectedForEdit();
    }
  }
}

export interface MetricDto extends MetricFilterTypes.DropDown {
  value: number | string;
}

export enum errorFields {
  image = 'image',
  headline = 'headline',
  tags = 'tags',
  undefined = 'undefined',
}

export interface TableTagsContainer {
  tags: string[];
}

export interface TableParams {
  data: AdCard;
}

export interface AddTag {
  tag: Tag;
  addTag: Function;
}
export interface Tag {
  id?: string;
  text: string;
}

export interface TrafficSource {
  uniqueName: string;
  name: string;
}

export interface ActiveAd extends AdCard {
  campaignsList: ResponseTypes.CampaignData[];
  errorMessage: string;
  isCampaignListLoading: boolean;
}

export interface API {
  getSettings: (params: { category: Mode }, signalId?: string) => Promise<ResponseTypes.Settings>;
  getData: (
    mode: Mode,
    params: {
      filters: RequestTypes.Filters;
    },
    signalId?: string
  ) => Promise<{ contents: ResponseTypes.Content[]; total_pages: number; total_items: number }>;
  editData: (mode: Mode, params: RequestTypes.UpdateContentGenerally) => Promise<ResponseTypes.Insert>;
  createNewData: (mode: Mode, params: RequestTypes.UpdateContentGenerally) => Promise<ResponseTypes.Insert>;
  deleteData: (mode: Mode, params: RequestTypes.StatusUpdate) => Promise<ResponseTypes.Insert>;
  getCampaignsList: (
    mode: Mode,
    id: string,
    params: {
      filters: RequestTypes.Filters;
    },
    signalId?: string
  ) => Promise<ResponseTypes.CampaignData[]>;
  cloneContent: (mode: Mode, params: RequestTypes.UpdateContentGenerally) => Promise<ResponseTypes.Insert>;
  editTags: (mode: Mode, params: RequestTypes.BulkTagUpdate) => Promise<boolean>;
  removeTags: (mode: Mode, params: { tags: string[] }) => Promise<boolean>;
}

export interface BulkTagsParams {
  id: Array<string>;
  tags: Array<string>;
  type: string;
}

export interface BulkTagSubmit {
  mode: Mode;
  addedTags: BulkTagsParams;
  deletedTags: BulkTagsParams;
}
