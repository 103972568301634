<template>
  <div>
    <div class="header">
      <div class="row my-4">
        <div class="col-3"> Day </div>
        <div class="col-3"> Start Date </div>
        <div class="col-3"> End Date </div>
      </div>
    </div>
    <div v-for="(schedule) in selected.rules" :key="schedule.day" class="row my-4 ">
      <div class="col-3 form-element">
        <div :class="`cc-checkbox-wrapper component-${model.name}`">
          <b-form-group :state="$_hasError('input')" class="mb-0">
            <b-form-checkbox-group
              class="optimizer-checkbox-form-group"
              @input="$_emit('selected')"
              v-model="schedule.type"
              :options="[schedule.day]"
              :disabled="model.disabled"
              name="input"
              :data-vv-as="model.label"
              :state="$_hasError('input')"
              v-validate="model['v-validate']"
            />
            <small slot="invalid-feedback">{{ $_getError("input") }}</small>
          </b-form-group>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 form-element">
        <span class="title">Start Date</span>
        <b-form-group class="mb-0 optimizer-form-group">
          <vue-opti-select-light
            class="optimizer-select"
            name="time"
            data-vv-validate-on="input|update"
            data-vv-value-path="$c_model"
            :value="schedule.from_hour"
            :options="$c_timeOptions"
            @change="$_handleStartDateChange($event,schedule)"
            :disabled="!schedule.type || !schedule.type.length"
            label-key="text"
            single
            button-block
          />
        </b-form-group>
      </div>
      <div class="col-xs-12 col-sm-3 form-element">
        <span class="title">End Date</span>
        <b-form-group class="mb-0 optimizer-form-group">
          <vue-opti-select-light
            class="optimizer-select"
            name="time"
            data-vv-validate-on="input|update"
            data-vv-value-path="$c_model"
            :value="schedule.until_hour"
            :options="$_generateEndTimeOptions(schedule.from_hour)"
            @change="$_handleEndDateChange($event,schedule)"
            :disabled="!schedule.type || !schedule.type.length"
            label-key="text"
            single
            button-block
          />
        </b-form-group>
      </div>
      <span v-if="allowCopyTimeFrame" class="copy-text" @click="$_copyTimeFrame(schedule)">
        <img src="sh_static/other/replace-icon.svg">
        Apply this timeframe to all</span>
    </div>
    <div v-if="showTimeZones" class="col-xs-10 col-sm-6 p-0 font-weight-bold my-5"> Time Zone
      <b-form-group class="optimizer-form-group">
        <vue-opti-select-light
          class="optimizer-select"
          data-vv-as="Timezone"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          label-key="text"
          unique-key="text"
          :value="value.time_zone"
          @change="({ text }) => { value.time_zone = text }"
          :options="$c_timeZones"
          :search-fields="['text', 'value']"
          button-placeholder="Select timezone"
          searchable
          single
          button-block
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import component from '../mixins/component';
import { timeValues, timeZones } from '../config/consts';

export default {
  name: 'CcActivitySchedule',
  mixins: [component],
  props: {
    value: { type: Object, default: null },
    showTimeZones: { type: Boolean, default: true },
    allowCopyTimeFrame: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    $c_timeOptions() {
      return timeValues;
    },
    $c_timeZones() {
      return timeZones;
    },
  },
  created() {
    this.$watch('value', (value) => {
      this.selected = value;
    }, { immediate: true });
  },
  methods: {
    $_generateEndTimeOptions(startDate) {
      const start_date = timeValues.find((date) => date.value === startDate);
      const index = timeValues.indexOf(start_date) + 1;
      const endTimeOptions = timeValues.slice(index);
      endTimeOptions.push({ text: '12:00 AM', value: '24' });
      return endTimeOptions;
    },
    $_handleStartDateChange(event, schedule) {
      schedule.from_hour = event.value;
      this.$_handleEndDateChange(schedule.from_hour, schedule);
    },
    $_handleEndDateChange(event, schedule) {
      if (Number(schedule.from_hour) >= Number(schedule.until_hour)) {
        schedule.until_hour = (Number(schedule.from_hour) + 1).toString();
      } else if (event.value) {
        schedule.until_hour = event.value;
      }
      this.$emit('selected');
    },
    $_copyTimeFrame(schedule) {
      this.selected.rules.forEach((rule) => {
        rule.from_hour = schedule.from_hour;
        rule.until_hour = schedule.until_hour;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    display: none;
    @media screen and (max-width:580px){
      display: block;
    }
  }

  .header {
    display: block;

    @media screen and (max-width:580px){
      display: none;
    }
  }

  .copy-text{
    color: #0095ff;
    cursor: pointer;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    @media screen and (max-width:580px){
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 5px;
    }
  }

  .form-element {
    &:hover ~ .copy-text {
      opacity: 1;
    }
  }
</style>
