/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */
import { ABRegistry, ABRegistryInstance } from '@/views/Automation/CampaignCreatorV2/ab';
import { getAllMacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { MacroOptions } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as AdMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Ad';
import { Icons } from '@/views/Automation/CampaignCreatorV2/helpers/icons';
import useAbGroups, { AbGroupForEntity } from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useABVariableStore, {
  AdsAbVariables,
  CreativeAbVariables,
  ErrorData,
} from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import useCreativeStore from '@/views/Automation/CampaignCreatorV2/store/Creative';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import {
  ErrorType,
  FieldError,
  getIdForEntity,
  InternalABVariable,
  SupportedEntities,
} from '@/views/Automation/CampaignCreatorV2/store/Types';
import {
  Level,
  NavigationAction,
  NavigationActionEvent,
  NavigationActionPayload,
  NavigationMenu,
} from '@/views/Automation/CampaignCreatorV2/types';
import { AdABGroupPublic, AdValidation } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import { CreativeValidation } from '@/views/Automation/CampaignCreatorV2/validation/Creative';
import { currencyFormatter, validateData } from '@sh/helpers';
import { apiStore } from '@sh/services/api';
import { Mode } from '@sh/types/MediaManager';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import objectHash from 'object-hash';
import { defineStore } from 'pinia';
import uid from 'uid';
import { AdDTO } from '../validation/AdDTO';
import { FacebookAbGroups } from '../validation/FacebookAbGroups';
import { EntityMacros } from '../validation/ValidationTypes';

type Ad = Pick<AdMeta.Create, keyof typeof AdValidation>;

interface InternalAdMetadata {
  ads: string[];
  internalId: string;
  campaignInternalId: string;
  adsetInternalId: string;
  ad_setup: string;
  creative_source: string;
  ad_format: string;
  multi_advertiser_ads: boolean;
}

type AdState = InternalAdMetadata;

type SuggestedVariables = Record<string, InternalABVariable<any>>;

type Suggestions = {
  name: string;
  id: string;
  variables: SuggestedVariables;
  counter: number;
  metrics: Array<{ name: string; value: string }>;
};
interface AdStore {
  globalErrors: FieldError[];
  adAccountId: string;
  suggestions: Array<Suggestions>;
  isSuggestionsLoading: boolean;
  ads: {
    [campaignAdId: string]: AdState;
  };
}

const useAdStore = defineStore(SupportedEntities.ad, {
  state: (): AdStore => ({
    ads: {},
    globalErrors: [],
    adAccountId: '',
    suggestions: [],
    isSuggestionsLoading: false,
  }),
  getters: {
    getItemsForNavigation(): Array<NavigationMenu> {
      const abVariableStore = useABVariableStore();
      const getPayload = (ad: AdState): NavigationActionPayload => ({
        campaignId: ad.campaignInternalId,
        adSetId: ad.adsetInternalId,
        adId: ad.internalId,
      });

      return Object.keys(this.ads)
        .map((buildId) => {
          const ad = this.ads[buildId];
          const adErrors = abVariableStore.getEntityErrors(SupportedEntities.ad, buildId);
          const creativeErrors = abVariableStore.getEntityErrors(SupportedEntities.creative, buildId);
          const errors: Partial<Record<ErrorType, ErrorData<any, any, any>[]>> = {
            [ErrorType.MISSING]: [
              ...(adErrors?.[ErrorType.MISSING] || []),
              ...(creativeErrors[ErrorType.MISSING] || []),
            ],
            [ErrorType.BLOCKING]: [
              ...(adErrors?.[ErrorType.BLOCKING] || []),
              ...(creativeErrors[ErrorType.BLOCKING] || []),
            ],
          };
          return {
            name:
              abVariableStore.getFieldForEntity(SupportedEntities.ad, 'name', buildId)?.value?.[0]?.value?.name || '',
            id: ad.internalId,
            adSetId: ad.adsetInternalId,
            campaignId: ad.campaignInternalId,
            type: SupportedEntities.ad,
            icon: 'ad' as Icons,
            actions: [
              [
                {
                  name: 'Delete',
                  type: 'delete-ad',
                  event: NavigationActionEvent.DELETE_AD,
                  payload: getPayload(ad),
                },
              ],
              [
                {
                  name: 'Clone Ad',
                  type: 'clone-ad',
                  event: NavigationActionEvent.CLONE_AD,
                  payload: getPayload(ad),
                },
              ],
              [
                {
                  name: `ID: ${ad.internalId}`,
                  description: 'Copy',
                  type: 'copy-id',
                  event: NavigationActionEvent.COPY_ID,
                  payload: getPayload(ad),
                },
              ],
            ],
            ...(errors.MISSING?.length && {
              message: {
                content: errors.MISSING,
                icon: {
                  type: 'uncompletedStatus' as Icons,
                  styles: {
                    color: 'var(--cc-blue-500)',
                  },
                },
              },
            }),
            ...(errors.BLOCKING?.length && {
              message: {
                content: errors.BLOCKING,
                icon: {
                  type: 'errorCircle' as Icons,
                  styles: {
                    color: 'var(--cc-error-color)',
                  },
                },
              },
            }),
          };
        })
        .flat();
    },
    getMacroOptions(): Array<MacroOptions> {
      return getAllMacroOptions(CreativeValidation, AdABGroupPublic, EntityMacros);
    },
    getField:
      (state) =>
      <K extends keyof Ad>(key: K) =>
        useABVariableStore().getField(SupportedEntities.ad, key),
    getInternalMetadata:
      (state) =>
      <K extends keyof InternalAdMetadata>(field: K): InternalAdMetadata[K] => {
        const navigation = useNavigationStore();
        return state.ads[navigation.getAdBuildId]?.[field];
      },
    getResults() {
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const allAdAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.ad);
      const allCreativeAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.creative);
      const tempRegistry = new ABRegistry();
      const creativeAbVariables = useABVariableStore().getEntity(SupportedEntities.creative);
      const adAbVariables = useABVariableStore().getEntity(SupportedEntities.ad);

      updateAbGroups(
        tempRegistry,
        this.ads,
        adAbVariables,
        creativeAbVariables,
        allAdAbGroups,
        allCreativeAbGroups,
        abGroupStore.getGlobalConstraints,
        false
      );

      const currentAdId = navigation.getAdBuildId;
      const previews = tempRegistry.getMainGroup(currentAdId)?.buildEntityForCreation() || [];
      return previews;
    },
    getSuggestions(): Array<Suggestions> {
      return this.suggestions;
    },
  },
  actions: {
    saveState() {
      return { adStore: this.$state };
    },
    loadState(template: any) {
      const parsedState = template.adStore as AdStore;
      if (parsedState) {
        this.ads = { ...this.ads, ...parsedState.ads };
      }
    },
    addVariable<K extends keyof Ad>(field: K, value: InternalABVariable<Ad[K]>) {
      useABVariableStore().addVariable(SupportedEntities.ad, field, value);
    },
    removeVariable<K extends keyof Ad>(field: K, key: string) {
      useABVariableStore().removeVariable(SupportedEntities.ad, field, key);
    },
    editVariable<K extends keyof Ad>(field: K, key: string, value: InternalABVariable<Ad[K]>) {
      useABVariableStore().editVariable(SupportedEntities.ad, field, key, value);
    },
    addOrEditVariable<K extends keyof Ad>(field: K, value: InternalABVariable<Ad[K]>) {
      useABVariableStore().addOrEditVariable(SupportedEntities.ad, field, value);
    },

    updateInternalMetadata<K extends keyof InternalAdMetadata>(field: K, value: InternalAdMetadata[K]) {
      const navigation = useNavigationStore();
      this.ads = {
        ...this.ads,
        [navigation.getAdBuildId]: {
          ...this.ads[navigation.getAdBuildId],
          [field]: value,
        },
      };
    },
    addNewAd(campaignId: string, adsetId: string) {
      const internalId = Math.random().toString(36).substring(7);
      const buildIdAd = getIdForEntity(SupportedEntities.ad, [campaignId, internalId]);
      const navigationStore = useNavigationStore();

      navigationStore.setAdInternalId(internalId);

      const abGroupStore = useAbGroups();
      abGroupStore.initAbGroup(SupportedEntities.ad);
      const abGroup = abGroupStore.getDefaultAbGroupForEntity(SupportedEntities.ad);
      const variables = {
        name: { value: [{ field: 'name', value: { name: 'New Ad' }, abGroup, variableId: 'name' }] },
        status: { value: [] },
        adset_id: { value: [] }, // TODO: fix
      };
      useABVariableStore().initEntity(SupportedEntities.ad, buildIdAd, variables);
      this.ads = {
        ...this.ads,
        [buildIdAd]: {
          internalId,
          campaignInternalId: campaignId,
          adsetInternalId: adsetId,

          ads: [],
          ad_setup: 'CREATE_AD',
          creative_source: 'MANUAL_PLACEMENTS',
          ad_format: 'SINGLE_IMAGE',
          multi_advertiser_ads: false,
        },
      };
      const creativeStore = useCreativeStore();
      creativeStore.addNewAdCreative(campaignId, adsetId, internalId);
    },
    cloneAds({
      currentAdsetId,
      newAdsetId,
      newCampaignId,
      adId,
      directActionOnEntity,
    }: {
      currentAdsetId: string;
      newAdsetId: string;
      newCampaignId: string;
      adId?: string;
      directActionOnEntity?: boolean;
    }) {
      const navigationStore = useNavigationStore();
      const abStore = useAbGroups();
      const creativeStore = useCreativeStore();
      const abVariableStore = useABVariableStore();
      // eslint-disable-next-line guard-for-in
      for (const adKeyId in this.ads) {
        const ad = cloneDeep(this.ads[adKeyId]);
        if (ad.adsetInternalId === currentAdsetId && (!adId || adId === ad.internalId)) {
          const newId = uid();
          const newAdBuildId = getIdForEntity(SupportedEntities.ad, [newCampaignId, newId]);
          const currentBuildId = getIdForEntity(SupportedEntities.ad, [ad.campaignInternalId, ad.internalId]);
          abVariableStore.cloneEntity(SupportedEntities.ad, currentBuildId, newAdBuildId);
          this.ads = {
            ...this.ads,
            [newAdBuildId]: {
              ...ad,
              campaignInternalId: newCampaignId,
              adsetInternalId: newAdsetId,
              internalId: newId,
            },
          };
          navigationStore.cloneNavigation(SupportedEntities.ad, { currentBuildId, newBuildId: newAdBuildId });
          abStore.clone(SupportedEntities.ad, { currentBuildId, newBuildId: newAdBuildId });
          if (directActionOnEntity) {
            const name = abVariableStore.getFieldForEntity(SupportedEntities.ad, 'name', newAdBuildId)?.value?.[0];
            name.value.name = `${name.value.name} - Copy`;
            abVariableStore.editVariable(SupportedEntities.ad, 'name', name.variableId, name, newAdBuildId);
          }
          creativeStore.cloneCreative({
            currentAdId: this.ads[adKeyId].internalId,
            newAdsetId,
            newAdId: newId,
            newCampaignId,
          });
        }
      }
    },
    dispatch(action: NavigationAction) {
      switch (action.event) {
        case NavigationActionEvent.DELETE_AD:
          validateData(action.payload, { campaignId: true, adSetId: true, adId: true });
          this.delete(action.payload);
          break;
        case NavigationActionEvent.CLONE_AD:
          validateData(action.payload, { campaignId: true, adSetId: true, adId: true });
          this.cloneAds({
            currentAdsetId: action.payload.adSetId,
            newAdsetId: action.payload.adSetId,
            newCampaignId: action.payload.campaignId,
            adId: action.payload.adId,
            directActionOnEntity: true,
          });
          break;
        case NavigationActionEvent.COPY_ID:
          validateData(action.payload, { adId: true });
          navigator.clipboard.writeText(action.payload.adId);
          break;
        default:
          throw new Error(`Action ${action.event} not found`);
      }
    },
    delete({ adId, adSetId, campaignId }: NavigationActionPayload) {
      const navigation = useNavigationStore();
      const abGroupStore = useAbGroups();
      const creativeStore = useCreativeStore();
      if (adId) {
        navigation.setCurrentLevel(Level.AdSet);
        navigation.setAdInternalId();
        const adsOwnedByAdSet = Object.values(this.ads).filter((ad) => ad.adsetInternalId === adSetId);
        if (adsOwnedByAdSet.length === 1) {
          console.log('Unable to delete Ad, Adset must have at least one Ad');
          return;
        }
      }
      const currentState = { ...this.ads };

      for (const adBuildId of Object.keys(currentState)) {
        const ad = currentState[adBuildId];
        if (
          (ad && ad.internalId === adId) ||
          (!adId && ((adSetId && ad.adsetInternalId === adSetId) || (!adSetId && ad.campaignInternalId === campaignId)))
        ) {
          useABVariableStore().removeEntity(SupportedEntities.ad, adBuildId);
          delete currentState[adBuildId];
          const buildId = getIdForEntity(SupportedEntities.ad, [ad.campaignInternalId, ad.internalId]);
          ABRegistryInstance.deleteEntity(buildId);
          navigation.delete(SupportedEntities.ad, buildId);
          abGroupStore.delete(SupportedEntities.ad, buildId);
          creativeStore.delete(buildId);
        }
      }
      this.ads = { ...currentState };
    },
    updateAbGroups() {
      const abGroupStore = useAbGroups();
      const allAdAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.ad);
      const allCreativeAbGroups = abGroupStore.getAllAbGroupsForEntity(SupportedEntities.creative);
      const creativeAbVariables = useABVariableStore().getEntity(SupportedEntities.creative);
      const adAbVariables = useABVariableStore().getEntity(SupportedEntities.ad);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateAbGroups(
        ABRegistryInstance,
        this.ads,
        adAbVariables,
        creativeAbVariables,
        allAdAbGroups,
        allCreativeAbGroups,
        abGroupStore.getGlobalConstraints
      );
    },
    deleteABGroup(groupId: string) {
      const navigation = useNavigationStore();
      const adBuildId = navigation.getAdBuildId;
      useABVariableStore().removeAbGroup(SupportedEntities.ad, adBuildId, groupId);
    },
    async getSuggestedVariables() {
      if (this.suggestions.length) {
        return;
      }
      this.isSuggestionsLoading = true;
      try {
        const data = await apiStore.mediaManager.getData(Mode.ADS, {
          filters: {
            order: [
              {
                name: 'EPC Highest',
                column: 'traffic_source_item_epc',
                type: 'DESC',
              },
            ],
            trafficsource: ['Facebook'],
            customDateRange: {
              startDate: moment().subtract(40, 'd').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
            },
            page: 1,
            pageSize: 60,
          },
        });
        const parsedInfo = data.contents.map((e) => {
          let metadata: AdMeta.CreateApi = {} as any;
          let variations: Array<{
            call_to_action_type: string;
            body: string;
            headline: string;
            image_url: string;
            description: string;
          }> = [];
          try {
            variations = JSON.parse(e.variations);
          } catch (er) {
            variations = [];
          }
          try {
            metadata = JSON.parse(e.metadata);
          } catch (err) {
            console.log('failed', { e, err });
          }
          return { ...e, metadata, variations };
        });

        type ACC = {
          hash: Record<string, any>;
          counter: Record<string, number>;
          variables: Record<string, SuggestedVariables>;
        };

        const allImages = Object.values(parsedInfo)
          .map((e) => e.variations)
          .flat()
          .map((e) => e.image_url);
        const uniqueAllImages = [...new Set(allImages)];
        const images = await apiStore.mediaManager.getData(Mode.IMAGES, {
          filters: { tsItemIds: uniqueAllImages },
        });
        const mostUsed = parsedInfo.reduce<ACC>(
          (acc: ACC, c) => {
            for (const variation of c.variations) {
              const image =
                images.contents.find((e) => e.traffic_source_item_id === variation.image_url) || images.contents[0];

              variation.image_url = image?.image_url;

              if (variation.image_url) {
                const hashId = objectHash(variation);
                const metrics = [
                  {
                    name: 'EPC',
                    value: currencyFormatter(c.traffic_source_item_epc),
                  },
                  {
                    name: 'REVENUE',
                    value: currencyFormatter(c.traffic_source_item_revenue),
                  },
                  {
                    name: 'SPEND',
                    value: currencyFormatter(c.traffic_source_item_spend),
                  },
                ];

                acc.variables[hashId] = AdDTO.fromApiToVariables(variation, image);
                acc.hash[hashId] = { ...variation, metrics };

                if (acc.counter[hashId]) {
                  acc.counter[hashId]++;
                } else {
                  acc.counter[hashId] = 1;
                }
              }
            }
            return acc;
          },
          { hash: {}, counter: {}, variables: {} }
        );
        const suggestions: Array<Suggestions> = [];
        for (const uniqueHas of Object.keys(mostUsed.hash)) {
          const variables = mostUsed.variables[uniqueHas];
          const cleanVariables = Object.keys(variables).reduce<SuggestedVariables>((acc, v) => {
            const variable = variables[v];
            if (variable.value) {
              acc[v] = variable;
            }
            return acc;
          }, {});
          const counter = mostUsed.counter[uniqueHas];
          const raw = mostUsed.hash[uniqueHas];
          const readableName = `Targeting used (${counter}) times, based over (${12}) Ad Sets`;
          suggestions.push({
            name: readableName,
            variables: cleanVariables,
            counter,
            id: uniqueHas,
            metrics: [...raw.metrics, { name: 'USED', value: `${counter}` }],
          });
        }
        this.suggestions = suggestions;
      } catch (error) {
        console.log(error);
      }
      this.isSuggestionsLoading = false;
    },
    applySuggestions(id: string) {
      const abVariable = useABVariableStore();
      const abGroups = useAbGroups();
      const newGroups: Record<string, string> = {};
      const variables = this.suggestions.find((s) => s.id === id)?.variables;

      if (variables) {
        for (const field of Object.keys(variables)) {
          // Get group metadata
          const value = variables[field];
          let fieldConfig;

          try {
            fieldConfig = AdDTO.getFieldConfiguration(value.field);
          } catch (e) {
            try {
              fieldConfig = AdDTO.getFieldConfigurationCreative(value.field);
            } catch (e) {
              console.log('Field not found');
              // eslint-disable-next-line no-continue
            }
          }

          if (!fieldConfig) {
            console.log('Field not found');
            // eslint-disable-next-line no-continue
            continue;
          }

          // Create Groups and save current group.
          const group = FacebookAbGroups[fieldConfig.fieldGroup! as keyof typeof FacebookAbGroups];

          if (!group) {
            console.log('Group not found');
            // eslint-disable-next-line no-continue
            continue;
          }

          if (!newGroups[fieldConfig.fieldGroup!]) {
            newGroups[fieldConfig.fieldGroup!] = uid(8);
            abGroups.addAbGroup(group.entity, {
              typeId: fieldConfig.fieldGroup! as 'DEFAULT',
              entity: SupportedEntities.targeting,
              description: group.readableName,
              name: `${group.readableName}  Suggestion`,
              id: newGroups[fieldConfig.fieldGroup!],
            });
          }

          // TODO: this is a temporary solution, I think we need to place this code somewhere else
          const variableId = uid(8);
          const data = {
            ...value,
            abGroup: newGroups[fieldConfig.fieldGroup!],
            variableId,
          };

          if (field === 'image') {
            data.value = {
              ...data.value,
              id: variableId,
            };
          }

          // TODO: remove type assertions if possible
          abVariable.addOrEditVariable(
            group.entity as SupportedEntities.creative,
            field as keyof CreativeAbVariables,
            data
          );
        }
      }
    },
    cloneABGroup(currentGroupId: string) {
      const navigation = useNavigationStore();
      const adBuildId = navigation.getAdBuildId;
      const currentAd = this.ads[adBuildId];
      if (!currentAd) {
        console.log('Ad not found');
        return;
      }
      const newGroupId = uid(8);
      useAbGroups().cloneGroup(SupportedEntities.ad, { groupId: currentGroupId, newGroupId });
      useABVariableStore().cloneAbGroup(SupportedEntities.ad, adBuildId, currentGroupId, newGroupId);
    },
  },
});

function updateAbGroups(
  abRegistry: ABRegistry,
  ads: AdStore['ads'],
  adAbVariables: Record<string, AdsAbVariables>,
  creative: Record<string, CreativeAbVariables>,
  allAdAbGroups: Record<string, AbGroupForEntity<SupportedEntities>>,
  allCreativeAbGroups: Record<string, AbGroupForEntity<SupportedEntities>>,
  globalConstrains: string[][],
  fullTree = true
) {
  // eslint-disable-next-line guard-for-in
  for (const adMeta of Object.values(ads)) {
    const adBuildId = getIdForEntity(SupportedEntities.ad, [adMeta.campaignInternalId, adMeta.internalId]);
    const ad = { ...adMeta, ...adAbVariables[adBuildId] };
    const { groups: abGroups, mainAbGroup: mainGroupId, defaultAbGroup, constraintById } = allAdAbGroups[adBuildId];
    const {
      groups: targetingAbGroups = {},
      constraintById: constraintByIdTarget,
      defaultAbGroup: defaultAbGroupCreative,
    } = allCreativeAbGroups[adBuildId] || {};
    abRegistry.registerAbGroups(
      ad.internalId,
      mainGroupId,
      defaultAbGroup,
      { ...abGroups, ...targetingAbGroups },
      adBuildId,
      constraintById.concat([...constraintByIdTarget, ...globalConstrains]),
      SupportedEntities.ad,
      ad.adsetInternalId,
      undefined,
      fullTree
    );

    // eslint-disable-next-line guard-for-in
    for (const field in ad) {
      const fieldMeta = AdValidation[field as keyof typeof AdValidation];
      const variables = ad[field as 'name']?.value || [];
      if (!fieldMeta && !variables.length) {
        // eslint-disable-next-line no-continue
        continue;
      }
      abRegistry.registerVariables(variables, fieldMeta, adBuildId);
    }
    const creativeItem = creative[adBuildId];
    // eslint-disable-next-line guard-for-in
    for (const field in creativeItem) {
      const fieldMeta = CreativeValidation[field as keyof typeof CreativeValidation];
      // TODO: This is a temp solution, Creative And Ad should be combined.
      // Having two separate entities (default/groups) goes against the idea of ab testing.
      // The example is having two "default" groups for each entity. In this case  both those groups are treated as separate group variables
      const variables = (creativeItem[field as 'headline']?.value || []).map((e) => ({
        ...e,
        abGroup: (e.abGroup as string).replace(defaultAbGroupCreative, defaultAbGroup),
      }));
      if (!fieldMeta && !variables.length) {
        // eslint-disable-next-line no-continue
        continue;
      }
      abRegistry.registerVariables(variables, fieldMeta, adBuildId);
    }
  }
}
export default useAdStore;
