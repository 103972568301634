<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table widgets-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getWidgetsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getWidgetsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getWidgetsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getWidgetsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="widgets-wrapper"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true"
            select-label="Widgets"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasComparisonColumns="true"
            :showHeaderPopover="true"
            :updateComparisonColumns="updateComparisonColumns"
            :hasGroups="true"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <div class="d-flex flex-grow-1">
                <vue-opti-select-light
                  :class="[ { 'active-select': tableModel.selectedRows.length }, 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                  :options="$c_bulkActionOptions"
                  :unique-key="({ value: { value } }) => value"
                  button-type="static"
                  @change="({ value }) => { $_bulkAction(value) }"
                  single
                  :disabled="$c_disableHeaderButtons"
                >
                  <template #BUTTON_PLACEHOLDER>
                    <span class="button-placehoder-filter">
                      <i class="fa fa-ellipsis-h" />
                      <span>Actions</span>
                    </span>
                  </template>
                </vue-opti-select-light>

                <vue-opti-select-light
                  class="optimizer-select icon-select pr-2"
                  :options="widgetsFilter.options"
                  option-type="radio"
                  :groups="[{ value: 'type', content: 'Type'}]"
                  button-type="static"
                  :value="widgetsFilter.model"
                  @change="onFiltersChange"
                  single
                >
                  <template #BUTTON_PLACEHOLDER="{ options, option }">
                    <span class="button-placehoder-filter">
                      <i class="fa fa-filter" />
                      <span>Filters</span>
                      <b-badge v-show="option && option.value !== 'all'" pill variant="info">{{ options.length }}</b-badge>
                    </span>
                  </template>
                </vue-opti-select-light>

                <template slot="placeholder" slot-scope="props">
                  <i class="fa fa-filter" />&nbsp;
                  <template v-if="props.selectedItem.value.type === 'algo'">
                  </template>
                  <template v-else>
                    {{ props.selectedItem.content }}
                  </template>
                </template>

                <template slot-scope="props">
                  <template v-if="props.item.value.type === 'algo'">
                  </template>
                  <span v-else v-html="props.item.content" />
                </template>

                <b-btn v-if="$c_showAddSmartList" class="secondary-button mr-2" @click="$_createSmartList">
                  Create Smart List <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top :title="`Select 1 or more ${$_toolTipMessage(true)}s to create a Smart List from them.`" />
                </b-btn>

                <b-btn
                  class="secondary-button mr-2"
                  v-clipboard="$_copyToClipboard('traffic_source_widget_id')"
                  @success="$_clipboardSuccessHandler($_toolTipMessage(false))"
                  @error="$_clipboardErrorHandler"
                >
                  <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top :title="`Copy selected ${$_toolTipMessage(true)}/ keywords id's to clipboard`" />                </b-btn>

                <b-btn
                  v-show="['RevContent', 'Taboola', 'Adskeeper', 'Gemini', 'Mgid', 'ContentAd'].includes(account.type.uniqueName)"
                  class="secondary-button"
                  v-clipboard="$_copyToClipboard('traffic_source_widget_id', bidType || 'cpc')"
                  @success="$_clipboardSuccessHandler('widgets with bids')"
                  @error="$_clipboardErrorHandler"
                >
                  <i class="fa fa-clipboard" /> Copy With Bids <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top title="Copy selected widget/keywords ids with their respective bids to clipboard. This data can then be used with the “Upload Bids” action to bulk upload the same bids on another campaign." />
                </b-btn>

                <div class="col-md-auto mb-2 mb-md-0">
                  <bulk-action
                    level="widget"
                    :items="tableModel.selectedRows"
                    :bulkConfig="trafficSources[$c_trafficSourceType]"
                    :bulkAction="bulkAction"
                    :failedItems="failedItems"
                    :showApplyChangesButton="showApplyChangesButton"
                    :selectedRows="tableModel.selectedRows"
                    :cancelHandler="$_cancelBulkOperation"
                    :table="table"
                    :toBeUpdatedItems="toBeUpdatedItems"
                    :clearPreview="$_clearPreviewRows"
                    :setFailedItems="$_setFailedItems"
                    :switchApplyButton="$_switchApplyButton"
                    :switchDisableActions="$_switchDisableActions"
                    :removeStyles="$_removeStyles"
                    :setItems="$_setItems"
                  />
                </div>

                <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                  <i class="fa fa-spinner fa-spin" /> Processing...
                </div>

                <loadizer :loading="ready.pagination" />
              </div>

              <div class="refresh-button" v-b-tooltip title="Refresh Table">
                <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
                  <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
                </b-btn>
              </div>
            </template>
            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <switch-button
                  :loading="item.statusProcessing"
                  :checked="item.status === 'RUNNING'"
                  :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                  :update="(value) => $_updateStatus(item, value)"
                />

                <action-btn type="performance" title="Performance Breakdown " :click="() => $_showPerformanceModal(item)" />
              </div>
            </template>
            <template slot="coefficient" slot-scope="props">
              <live-edit
                :ref="`coefficientLiveEdit-${props.i}`"
                :key="`coefficientLiveEdit-${props.item.id}`"
                v-model="props.item.coefficient"
                :before-blur="value => $_updateBid(props.item, value, 'Coefficient')"
                @up="$_inlineEditArrayPress('coefficientLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('coefficientLiveEdit', props.i, 'down')"
              />
            </template>
            <template
              v-if="campaign.traffic_source_unique_name === 'YahooDSP'"
              slot="additional_fields.budget"
              slot-scope="props"
            >
              <span v-if="props.item.additional_fields.budget === null">-</span>
              <span v-else-if="props.item.additional_fields.budget === -1">Unlimited</span>
              <live-edit
                v-else
                :ref="`budgetLiveEdit-${props.i}`"
                :key="`budgetLiveEdit-${props.item.id}`"
                :live-state="props.item.budgetLiveEditState"
                v-model="props.item.additional_fields.budget"
                :prefix="props.item.additional_fields.budget === -1 ? '' : '$'"
                :format="ui.numFormat"
                field-name="Lifetime Budget"
                :before-blur="value => $_updateBudget(campaign,props.item, value)"
              />
            </template>
            <template
              v-if="campaign.traffic_source_unique_name === 'YahooDSP'"
              slot="additional_fields.daily_budget"
              slot-scope="props"
            >
              <span v-if="props.item.additional_fields.daily_budget === null">-</span>
              <span v-else-if="props.item.additional_fields.daily_budget === -1">Unlimited</span>
              <live-edit
                v-else
                :ref="`dailyBudgetLiveEdit-${props.i}`"
                :key="`dailyBudgetLiveEdit-${props.item.id}`"
                v-model="props.item.additional_fields.daily_budget"
                :prefix="props.item.additional_fields.daily_budget === -1 ? '' : '$'"
                :format="ui.numFormat"
                field-name="Daily Budget"
                :before-blur="value => $_updateDailyBudget(campaign,props.item, value)"
              />
            </template>
            <template slot="optimization_action" slot-scope="props">
              <template v-if="props.item.optimization_action">
              <!-- <algo-icon
                :action="props.item.optimization_action"
                :reason="props.item.optimization_reason"
                :message="props.item.optimization_message"
                :description="props.item.optimization_tooltip || props.item.optimization_message"
                :text="false"
              /> -->
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template slot="cpc" slot-scope="props">
              <span v-if="props.item.cpc === null">- <span v-if="$_findPreviewRow(props.item.traffic_source_adgroup_id, 'error', props.item.traffic_source_currency)" class="error" v-b-tooltip.hover.bottomleft title="Unable to update field, the action will be skipped!">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </span></span>
              <span v-else-if="campaign.traffic_source_unique_name === 'ActiveRevenue'">${{ props.item.cpc }}</span>
              <template v-else-if="campaign.traffic_source_unique_name === 'Gemini'">
                <span v-if="!props.item.additional_fields.bidding">{{ props.item.cpcPercent }}%</span>
                <live-edit
                  v-else
                  :ref="`cpcLiveEdit-${props.i}`"
                  :key="`cpcLiveEdit-${props.item.id}`"
                  :live-state="props.item.bidLiveEditState"
                  v-model="props.item.cpcPercent"
                  suffix="%"
                  :format="$_cpcSlotNumFormat(props.field.options)"
                  field-name="Bid"
                  :max-limit-warning="400"
                  :before-blur="value => $_updateBid(props.item, value)"
                  @up="$_inlineEditArrayPress('cpcLiveEdit', props.i, 'up')"
                  @down="$_inlineEditArrayPress('cpcLiveEdit', props.i, 'down')"
                />
              </template>
              <live-edit
                v-else
                :ref="`cpcLiveEdit-${props.i}`"
                :key="`cpcLiveEdit-${props.item.id}`"
                :live-state="props.item.bidLiveEditState"
                v-model="props.item.cpc"
                :prefix="symbols.get(account.settings.currency) || '$'"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBid(props.item, value)"
                @up="$_inlineEditArrayPress('cpcLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('cpcLiveEdit', props.i, 'down')"
                :limit-hint="account.type.uniqueName === 'Taboola' && campaign.additional_fields.bid ? {
                  title: 'Limits',
                  min: $c_taboolaCpcLimits.min,
                  max: $c_taboolaCpcLimits.max,
                  prefix: symbols.get(account.settings.currency) || '$',
                  invalid: $_taboolaInvalidCpcLimit
                } : null"
                :preview="$_findPreviewRow(props.item.traffic_source_widget_id, 'bid', account.settings.currency)"
                previewCustomStyle="preview-suffix"
                :failedItems="$_filterItemsToCorrespondingField(failedItems, 'bid')"
                :failedItemKey="props.item.traffic_source_widget_id"
              />
            </template>
            <template slot="calculated_cpc" slot-scope="props">
              <span v-if="props.item.additional_fields.calculated_cpc === null">-</span>
              <span v-else-if="!props.item.additional_fields.bidding">${{ props.item.additional_fields.calculated_cpc }}</span>
              <live-edit
                v-else
                :ref="`cpcCalculatedLiveEdit-${props.i}`"
                :key="`cpcCalculatedLiveEdit-${props.item.id}`"
                :live-state="props.item.bidCalculatedLiveEditState"
                v-model="props.item.additional_fields.calculated_cpc"
                prefix="$"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Bid"
                :max-limit-warning="2"
                :before-blur="value => $_updateGeminiCalculatedBid(props.item, value)"
                @up="$_inlineEditArrayPress('cpcCalculatedLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('cpcCalculatedLiveEdit', props.i, 'down')"
              />
            </template>
            <template slot="cpc_desktop" slot-scope="props">
              <live-edit
                :ref="`cpcDesktopLiveEdit-${props.i}`"
                :key="`cpcDesktopLiveEdit-${props.item.id}`"
                v-model="props.item.cpc_desktop"
                :prefix="$_getCurrency()"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Desktop Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBidCad({cpc_desktop: value, widgetIds: [props.item.traffic_source_widget_id]})"
                @up="$_inlineEditArrayPress('cpcDesktopLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('cpcDesktopLiveEdit', props.i, 'down')"
              />
            </template>
            <template slot="cpc_tablet" slot-scope="props">
              <live-edit
                :ref="`cpcTabletLiveEdit-${props.i}`"
                :key="`cpcTabletLiveEdit-${props.item.id}`"
                v-model="props.item.cpc_tablet"
                :prefix="$_getCurrency()"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Tablet Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBidCad({cpc_tablet: value, widgetIds: [props.item.traffic_source_widget_id]})"
                @up="$_inlineEditArrayPress('cpcTabletLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('cpcTabletLiveEdit', props.i, 'down')"
              />
            </template>
            <template slot="cpc_mobile" slot-scope="props">
              <live-edit
                :ref="`cpcMobileLiveEdit-${props.i}`"
                :key="`cpcMobileLiveEdit-${props.item.id}`"
                v-model="props.item.cpc_mobile"
                :prefix="$_getCurrency()"
                :format="$_cpcSlotNumFormat(props.field.options)"
                field-name="Mobile Bid"
                :max-limit-warning="maxCpcLimit"
                :before-blur="value => $_updateBidCad({cpc_mobile: value, widgetIds: [props.item.traffic_source_widget_id]})"
                @up="$_inlineEditArrayPress('cpcMobileLiveEdit', props.i, 'up')"
                @down="$_inlineEditArrayPress('cpcMobileLiveEdit', props.i, 'down')"
              />
            </template>
            <template slot="bid_strategy" slot-scope="props">
              <bid-strategy
                :key="`bid-strategy-${props.item.id}`"
                v-if="props.item.additional_fields && props.item.additional_fields.bid_strategy"
                v-model="props.item.additional_fields.bid_strategy"
                :on-update="(value) => $_updateBidStrategy(value, props.item)"
              />
              <template v-else>
                -
              </template>
            </template>
            <template v-if="campaign.traffic_source_unique_name === 'GoogleAds'" slot="negative" slot-scope="props">
              <span v-if="props.item.additional_fields.negative === true">YES</span>
              <span v-else-if="props.item.additional_fields.negative === false">NO</span>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
    <bulk-modal
      title="Change Bid"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkModal"
      :items="tableModel.selectedRows"
      :min="0.001"
      :step="0.001"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="$_bulkUpdateBid"
    />
    <bulk-modal
      title="Change Bid"
      placeholder="Enter New Bid"
      field-name="Bid"
      ref="bulkModalCalcGemini"
      :items="tableModel.selectedRows"
      :min="0.001"
      :step="0.001"
      right="<i class='fa fa-dollar'></i>"
      :max-limit-warning="maxCpcLimit"
      :handler="$_bulkUpdateCalcBidGemini"
    />
    <bulk-modal-cad
      title="Change Bid"
      placeholder="Enter New Bid"
      field-name="bid"
      ref="bulkModalCAD"
      :items="tableModel.selectedRows"
      :min="0.001"
      :step="0.001"
      :right="$_getCurrency()"
      :max-limit-warning="maxCpcLimit"
      :handler="$_bulkUpdateBidCAD"
    />
    <batch-modal
      :title="$_uploadBidTitle()"
      placeholder="Correct format: <widget_id>,<bid>"
      field-name="Widgets"
      ref="batchModal"
      :format="{split: ',', colums: 2}"
      :handler="$_batchUpdateBid"
    />
    <smart-list-modal
      ref="smartListModal"
      mode="create"
      :default-campaigns-id="[campaign.id]"
      :default-traffic-source-type="campaign.traffic_source_unique_name"
      :default-widgets-id="$c_selectedWidgetsId"
    />
    <performance-modal
      ref="performanceModal"
      :type="type"
      :itemId="currentWidgetPerformance.traffic_source_widget_id"
      :id="currentWidgetPerformance.id || ''"
      :name="currentWidgetPerformance.name || currentWidgetPerformance.traffic_source_widget_id || ''"
      :timezone="timezone"
      :dateRange="dateRange"
    ></performance-modal>

    <rule-bulk-modal
      v-if="$c_trafficSourceType"
      ref="ruleBulkModal"
      level="widget"
      :options="$_numericActiveColumns(table.fields, bulkAction)"
      :items="tableModel.selectedRows"
      :bulkConfig="trafficSources[$c_trafficSourceType]"
      :bulkAction="bulkAction"
      :handler="(value) => $_setItemsThatWillBeUpdated(value, table.items)"
    />
  </div>
</template>

<script>
import tableFields from '@/helpers/fields/index';
import BatchModal from '@sh/components/Utils/BatchModal';
import BulkModal from '@sh/components/Utils/BulkModal';
import RuleBulkModal from '@sh/components/Utils/RuleBulkModal.ts.vue';
import config from '@/views/Campaigns/bulkOperationsConfig';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import LiveEdit from '@sh/components/Utils/LiveEdit';
import SmartListModal from '@/components/smartlist/SmartListModal';
import tableMixins from '@sh/mixins/table';
import helperMixins from './mixins/helperMixins';
import BidStrategy from './components/BidStrategy';
import BulkModalCad from './components/BulkModalCAD';
import PerformanceModal from '@sh/components/Performance/PerformanceModal';
import { min_cpc_per_currency } from '../../../helpers/other/Taboola/min_cpc_per_currency';
import symbols from '@sh/helpers/symbols';
import { useAppStore } from '@/stores';
import { mapWritableState, mapActions, mapState } from 'pinia';
import BulkAction from '@sh/components/Utils/BulkAction.ts.vue';
import { TrafficSource } from '@sh/types';
import { getTabRenaming } from '../config';
import { upperFirst } from 'lodash';
import { usePresetStore } from '@/stores/presets';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';

export default {
  name: 'Widgets',
  components: {
    SmartListModal, BidStrategy, BulkModalCad, BulkModal, BatchModal, LiveEdit, RuleBulkModal, BulkAction, PerformanceModal,
  },
  mixins: [helperMixins, tableMixins, config, columnsMixin, presetHelper],
  props: {
    type: { type: Object },
  },
  data() {
    const accountUniqueName = this.account.type.uniqueName;
    let filtersOptions = [
      { value: 'all', content: 'All Widgets', group: 'type' },
      { value: 'blocked', content: 'Blocked Widgets', group: 'type' },
      { value: 'active', content: 'Active Widgets', group: 'type' },
    ];
    if (accountUniqueName === TrafficSource.Outbrain || TrafficSource.Facebook || TrafficSource.GoogleAds) {
      filtersOptions = filtersOptions.map((option) => ({
        ...option,
        content: `${upperFirst(option.value)} ${this.$_toolTipMessage(true)}s`,
      }));
    }

    return {
      rawResponse: [],
      symbols,
      cpcList: {},
      bidType: null,
      widgetsFilter: {
        model: 'all',
        options: filtersOptions,
      },
      currentWidgetPerformance: {},
      isRefreshLoading: false,
    };
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_tableName() {
      return `${this.account.type.name}WidgetsTable`;
    },
    $c_exportLabel() {
      return `${this.account.type.name}_widgets_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $c_showAddSmartList() {
      return ![TrafficSource.GoogleAds, TrafficSource.Facebook].includes(this.account.type.uniqueName);
    },
    $c_bulkActionOptions() {
      let options = [
        { value: { value: 'pause', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Deactivate' },
        { value: { value: 'start', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Activate' },
      ];
      if (this.widgetsFilter.model === 'blocked') options = options.filter((item) => item.value.value === 'start');
      if (this.widgetsFilter.model === 'active') options = options.filter((item) => item.value.value === 'pause');
      if (['VoluumDSP'].indexOf(this.account.type.uniqueName) > -1) {
        options.push({ value: { value: 'bid', fn: this.$_showBulkModal }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Bid' });
      }
      if ([TrafficSource.Taboola, TrafficSource.RevContent, TrafficSource.MediaGo, TrafficSource.GoogleAds].indexOf(this.account.type.uniqueName) > -1) {
        const { action_name } = this.trafficSources[this.$c_trafficSourceType].entities.widget.bulkActions.change_bid_bulk;
        options.push({ value: { value: 'change bid bulk',
          fn: () => {
            this.bulkAction = 'change_bid_bulk';
            this.$refs.ruleBulkModal.show();
          } },
        content: `<i class="fa fa-wrench"></i>&nbsp; ${action_name}` });
      }
      if ([TrafficSource.GoogleAds].includes(this.account.type.uniqueName)) {
        // options.push({ value: { value: 'cpc', fn: this.$_showBulkModal }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Bid' });
        options.push({ value: { value: 'true', fn: this.$_bulkUpdateStatusKeyword }, content: '<i class=\'fa fa-minus\'></i>&nbsp; Add to Negative Keyword List' });
        options.push({ value: { value: 'false', fn: this.$_bulkUpdateStatusKeyword }, content: '<i class=\'fa fa-plus\'></i>&nbsp; Add to Keyword List' });
      }
      if ([TrafficSource.ContentAd].includes(this.account.type.uniqueName)) {
        options.push({ value: { value: 'bid', fn: this.$_showBulkModalCAD }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Bid' });
      }
      if ([TrafficSource.Mgid, TrafficSource.Adskeeper].includes(this.account.type.uniqueName)) {
        const unitHtml = '<b style="width: 19px; display: inline-block; color: black;" >C</b>';
        options.push({
          value: {
            value: 'bid',
            fn: () => this.$_showBulkModal({
              right: unitHtml,
              title: 'Change Coefficient',
              placeholder: 'Enter New Coefficient',
              fieldName: 'Coefficient',
              maxLimitWarning: 2,
            }),
          },
          content: `${unitHtml} Change Coefficient`,
        });
      }
      if ([TrafficSource.Gemini].includes(this.account.type.uniqueName)) {
        options.push({
          value: {
            value: 'bid',
            fn: () => this.$_showBulkModal({
              right: '<i class="fa fa-percent"></i>',
              title: 'Change Bid Modifier',
              placeholder: 'Enter New Bid Modifier',
              fieldName: 'Modifier',
              maxLimitWarning: 200,
            }),
          },
          content: '<i class=\'fa fa-percent\'></i>&nbsp; Change Bid Modifier',
        });
        options.push({
          value: {
            value: 'bid',
            fn: () => this.$refs.bulkModalCalcGemini.show({
              right: '<i class="fa fa-dollar"></i>',
              title: 'Change Calc. Cpc',
              placeholder: 'Enter New Bid',
              fieldName: 'Bid',
              maxLimitWarning: 10,
            }),
          },
          content: '<i class=\'fa fa-dollar\'></i>&nbsp; Change Calc. Cpc',
        });
      }
      if ([TrafficSource.Mgid].includes(this.account.type.uniqueName)) {
        options.push({ value: { value: '', fn: this.$_showBatchModal, force: true }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Upload Coefficients' });
      }
      if ([TrafficSource.RevContent, TrafficSource.Taboola, TrafficSource.Adskeeper, TrafficSource.Gemini].includes(this.account.type.uniqueName)) {
        options.push({ value: { value: '', fn: this.$_showBatchModal, force: true }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Upload Bids' });
      }
      if ([TrafficSource.ContentAd].includes(this.account.type.uniqueName)) {
        options.push({ value: { value: 'cpc_desktop', fn: this.$_showBatchModal, force: true }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Upload Desktop Bids' });
        options.push({ value: { value: 'cpc_mobile', fn: this.$_showBatchModal, force: true }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Upload Mobile Bids' });
      }
      if ([TrafficSource.Taboola, TrafficSource.RevContent].includes(this.account.type.uniqueName)) {
        options.push({ value: { value: 'resetBidBulk', fn: this.$_bulkResetBid }, content: '<i class=\'fa fa-dollar\'></i>&nbsp; Reset Bid To Default' });
      }
      return options;
    },
    $c_taboolaCpcLimits() {
      if (this.account.type.uniqueName === 'Taboola' && this.campaign.additional_fields.bid) {
        const accountCurrency = this.account.settings.currency || 'USD';
        const minCpc = this.cpcList[accountCurrency];
        return {
          min: minCpc,
          max: this.ui.toFixed(parseFloat(this.campaign.additional_fields.bid) * 2, 5),
        };
      }
      return null;
    },
    $c_selectedWidgetsId() {
      return this.tableModel.selectedRows.map((widget) => widget.traffic_source_widget_id);
    },
    $c_trafficSourceType() {
      if (this.account?.type && this.$c_availableTrafficSourcesWidgetLevel.includes(this.account.type.uniqueName)) {
        return this.account?.type?.uniqueName;
      }
      return null;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapWritableState(useAppStore, ['widgetsModelFilter']),
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  created() {
    if (this.account.type.uniqueName === 'Taboola') {
      this.$_getCpcList(this.account.id);
    }
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      this.widgetsFilter.model = this.widgetsModelFilter;
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('widgets', this.campaign.traffic_source_unique_name);

      try {
        await this.getFilteredPresets();
        await this.$_getWidgetsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.debouncedGetActivityLogs = this.debounce(this.$_getWidgetsPaginated, 1000);
    },
    async $_getCpcList(accountId) {
      try {
        const minCPCList = await this.$api.widgets.getAccountMinCPC(accountId);
        this.cpcList = minCPCList.results.reduce((obj, { currency, minimum_cpc }) => {
          obj[currency] = parseFloat(minimum_cpc);
          return obj;
        }, {});
      } catch (e) {
        this.cpcList = min_cpc_per_currency.reduce((obj, { currency, minimum_cpc }) => {
          obj[currency] = parseFloat(minimum_cpc);
          return obj;
        }, {});
      }
    },
    async $_getWidgetsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        typeName: this.account.type.uniqueName,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.search !== '') {
        filter.search = this.search;
      }
      if (this.widgetsFilter.model !== 'all') {
        if (this.widgetsFilter.model === 'active') {
          filter.status = 'RUNNING';
        }
        if (this.widgetsFilter.model === 'blocked') {
          filter.status = 'PAUSED';
        }
      }
      let response = {};
      try {
        response = await this.$api.widgets.performance(this.campaign.id, filter);
        response.items = response.items.map((item) => {
          item.statusProcessing = false;
          item.traffic_source_currency = this.campaign.traffic_source_currency;
          item.bidLiveEditState = { state: 'READY' };
          item.bidCalculatedLiveEditState = { state: 'READY' };
          item.cpcPercent = this.ui.getGeminiBidModifierToPercentage(item.cpc);
          if (this.campaign.traffic_source_unique_name === 'Taboola' && item.cpc === null) item.cpc = 0;
          return item;
        });
        this.rawResponse = response;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.ready.pagination = false;
      }
      if (response.pagination.lastPage) {
        this.pages = response.pagination.lastPage;
        this.defaultRows = response.pagination.pageSize;
      }
      if (!response.items.length) {
        this.page = 1;
      }
      if (Object.keys(this.rawResponse).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('WIDGETS', { name: 'actions' }));
        this.rawResponse.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('WIDGETS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        this.table.items = response.items;
        response.totals.totalElements = response.pagination.total;
        this.table.totals = response.totals;
      }
      this.preloader = false;
      this.ready.pagination = false;
      return response;
    },
    $_uploadBidTitle() {
      if (this.account.type.name === 'Mgid') {
        return 'Upload Coefficients';
      }
      switch (this.bidType) {
        case 'cpc_desktop':
          return 'Upload Desktop Bids';
        case 'cpc_mobile':
          return 'Upload Mobile Bids';
        default:
          return 'Upload Bids';
      }
    },
    $_showPerformanceModal(item) {
      this.currentWidgetPerformance = item;
      this.$refs.performanceModal.showModal();
    },
    async $_bulkUpdateStatus(value) {
      const localValue = value === 'start' ? 'RUNNING' : 'PAUSED';
      const payload = [];
      const payloadIdsMap = {};
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          payloadIdsMap[row.traffic_source_widget_id] = true;
          const payloadItem = { traffic_source_widget_id: row.traffic_source_widget_id };
          if (this.campaign.traffic_source_unique_name === 'Taboola') {
            payloadItem.publisher_id = row.additional_fields.publisher_id;
            payloadItem.name = row.name;
          }
          payload.push(payloadItem);
          row.statusProcessing = true;
        }
      });
      if (payload.length > 0) {
        let listId = null;
        if (this.campaign.traffic_source_unique_name === 'ContentAd' && this.campaign.additional_fields.contentad_list) listId = this.campaign.additional_fields.contentad_list.listId.toString();
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.widgets.changeStatus(this.campaign.id, value, payload, listId),
            ts: this.campaign.traffic_source_unique_name,
            entityToReplace: 'Widgets',
            newEntityName: 'Placements',
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_widget_id) > -1) row.status = localValue;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payloadIdsMap[row.traffic_source_widget_id]) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_bulkUpdateStatusKeyword(value) {
      const localValue = value === 'start' ? 'RUNNING' : 'PAUSED';
      const payload = [];
      const payloadIdsMap = {};
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          payloadIdsMap[row.traffic_source_widget_id] = true;
          const payloadItem = { traffic_source_widget_id: row.traffic_source_widget_id };
          payload.push(payloadItem);
          row.statusProcessing = true;
        }
      });
      if (payload.length > 0) {
        const listId = null;
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.widgets.changeStatusKeyword(this.campaign.id, value, payload, listId),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_widget_id) > -1) row.status = localValue;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payloadIdsMap[row.traffic_source_widget_id]) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_updateStatus(item, value) {
      try {
        const payload = { traffic_source_widget_id: item.traffic_source_widget_id };
        if (this.campaign.traffic_source_unique_name === 'Taboola') {
          payload.publisher_id = item.additional_fields.publisher_id;
          payload.name = item.name;
        }
        const listId = null;
        const result = await this.$_userActionHandler({
          request: this.$api.widgets.changeStatus(item.campaign_id, value ? 'start' : 'pause', [payload], listId),
          ts: this.campaign.traffic_source_unique_name,
          entityToReplace: 'Widgets',
          newEntityName: 'Placement',
        });
        if (result.success.length > 0) item.status = value ? 'RUNNING' : 'PAUSED';
      } catch (error) { /** * Do nothing ** */ }
    },
    async $_updateBudget(campaign, widget, value, type = 'limited') {
      const task = this.userAction.task.create();
      try {
        const budgetType = value ? 'limited' : type;
        const response = await this.$api.widgets.changeWidgetBudget(campaign.id, [widget.traffic_source_widget_id], value, budgetType);
        this.$n_successNotification({ title: response.message || 'Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateDailyBudget(campaign, widget, value, type = 'limited') {
      const task = this.userAction.task.create();
      try {
        const dailyBudgetType = value ? 'limited' : type;
        const response = await this.$api.widgets.changeWidgetDailyBudget(campaign.id, [widget.traffic_source_widget_id], value, dailyBudgetType);
        this.$n_successNotification({ title: response.message || 'Daily Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBid(item, value, name = 'Bid') {
      const task = this.userAction.task.create();
      try {
        const payload = { traffic_source_widget_id: item.traffic_source_widget_id };
        if (this.campaign.traffic_source_unique_name === 'Taboola') {
          payload.publisher_id = item.additional_fields.publisher_id;
          payload.name = item.name;
        } else if (this.campaign.traffic_source_unique_name === 'Gemini') {
          payload.advertiser_id = this.campaign.additional_fields.advertiser_id;
          value = this.ui.getGeminiPercentageToBidModifier(value);
        }
        await this.$api.widgets.changeBid(this.campaign.id, value, [payload]);
        if (this.campaign.traffic_source_unique_name === 'Gemini') {
          item.cpc = value;
          item.additional_fields.calculated_cpc = this.ui.toFixed(value * this.campaign.additional_fields.adgroup_cpc, 3);
        }
        this.$n_successNotification({ title: `${name} successfully updated` });
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_updateGeminiCalculatedBid(item, value, name = 'Bid') {
      const bidModifier = this.ui.toFixed(value / this.campaign.additional_fields.adgroup_cpc, 2);
      const task = this.userAction.task.create();
      try {
        const payload = { traffic_source_widget_id: item.traffic_source_widget_id, advertiser_id: this.campaign.additional_fields.advertiser_id };
        await this.$api.widgets.changeBid(this.campaign.id, bidModifier, [payload]);
        item.cpc = bidModifier;
        item.cpcPercent = this.ui.getGeminiBidModifierToPercentage(bidModifier);
        this.$n_successNotification({ title: `${name} successfully updated` });
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_bulkUpdateCalcBidGemini(value) {
      const bidModifier = this.ui.toFixed(value / this.campaign.additional_fields.adgroup_cpc, 2);
      const payload = [];
      this.tableModel.selectedRows.forEach((row) => {
        if (row.additional_fields.bidding) {
          payload.push({ traffic_source_widget_id: row.traffic_source_widget_id, advertiser_id: this.campaign.additional_fields.advertiser_id });
          row.bidLiveEditState.state = 'BUSY';
          row.bidCalculatedLiveEditState.state = 'BUSY';
        }
      });
      if (payload.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.widgets.changeBid(this.campaign.id, bidModifier, payload),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id)) {
                row.cpc = parseFloat(bidModifier);
                row.cpcPercent = this.ui.getGeminiBidModifierToPercentage(row.cpc);
                row.additional_fields.calculated_cpc = bidModifier;
              }
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payload.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id)) {
            row.bidLiveEditState.state = 'READY';
            row.bidCalculatedLiveEditState.state = 'READY';
          }
        });
        task.finish();
      }
    },
    async $_bulkUpdateBid(value) {
      const payload = [];
      if (this.campaign.traffic_source_unique_name === 'Gemini') value = this.ui.getGeminiPercentageToBidModifier(value);
      this.tableModel.selectedRows.forEach((row) => {
        if (this.campaign.traffic_source_unique_name === 'Gemini') {
          if (row.additional_fields.bidding) {
            payload.push({ traffic_source_widget_id: row.traffic_source_widget_id, advertiser_id: this.campaign.additional_fields.advertiser_id });
            row.bidLiveEditState.state = 'BUSY';
            row.bidCalculatedLiveEditState.state = 'BUSY';
          }
        } else {
          payload.push({ traffic_source_widget_id: row.traffic_source_widget_id });
          row.bidLiveEditState.state = 'BUSY';
        }
      });
      if (payload.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.widgets.changeBid(this.campaign.id, value, payload),
          });
          if (result.success.length > 0) {
            if (this.campaign.traffic_source_unique_name === 'Gemini') {
              this.table.items.forEach((row) => {
                if (result.success.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id)) {
                  row.cpc = parseFloat(value);
                  row.cpcPercent = this.ui.getGeminiBidModifierToPercentage(row.cpc);
                  row.additional_fields.calculated_cpc = this.ui.toFixed(value * this.campaign.additional_fields.adgroup_cpc, 3);
                }
              });
            } else {
              this.table.items.forEach((row) => {
                if (result.success.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id)) row.cpc = parseFloat(value);
              });
            }
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payload.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id)) {
            row.bidLiveEditState.state = 'READY';
            if (this.campaign.traffic_source_unique_name === 'Gemini') row.bidCalculatedLiveEditState.state = 'READY';
          }
        });
        task.finish();
      }
    },
    async $_updateBidCad(payload) {
      const task = this.userAction.task.create();
      try {
        await this.$api.widgets.changeBidCad(this.campaign.id, payload);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      task.finish();
      return false;
    },
    async $_bulkUpdateBidCAD(data) {
      const payload = { widgetIds: [], ...data };
      this.tableModel.selectedRows.forEach((row) => {
        payload.widgetIds.push(row.traffic_source_widget_id);
        // row.bidLiveEditState.state = 'BUSY'
      });
      if (payload.widgetIds.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.widgets.changeBidCad(this.campaign.id, payload),
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              const item = result.success.find((item) => item.traffic_source_widget_id === row.traffic_source_widget_id);
              if (item) {
                ['cpc_desktop', 'cpc_mobile'].forEach((device) => {
                  if (typeof item[device] !== 'undefined') row[device] = parseFloat(item[device]);
                });
              }
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        // this.table.items.forEach((row) => {
        //   if (payload.find(item => item.traffic_source_widget_id === row.traffic_source_widget_id)) row.bidLiveEditState.state = 'READY'
        // })
        task.finish();
      }
    },
    $_taboolaInvalidCpcLimit(data) {
      this.$n_failNotification({ title: `You are trying to set a cpc out of the allowed range [${data.prefix}${data.min} - ${data.prefix}${data.max}]` });
    },
    $_showBatchModal(value) {
      if (value) {
        this.bidType = value;
      }
      this.$refs.batchModal.show();
    },
    $_toolTipMessage(capitalize) {
      const accountName = this.account.type.uniqueName;
      const entityNameMatch = getTabRenaming('widget', accountName);

      if (entityNameMatch) {
        return capitalize ? entityNameMatch.upper : entityNameMatch.lower;
      } return capitalize ? 'Widget' : 'widget';
    },
    async $_batchUpdateBid(data) {
      if (data.length > 0) {
        const key = this.bidType || 'bid';
        const task = this.userAction.task.create();
        const payload = data.map((item) => ({ traffic_source_widget_id: item[0], [key]: item[1] }));
        try {
          await this.$_userActionHandler({
            request: this.$api.widgets.changeBidBulk(this.campaign.id, payload),
          });
          this.preloader = true;
          await this.$_init();
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
        task.finish();
      }
    },
    async $_updateBidStrategy(value, row) {
      const task = this.userAction.task.create();
      try {
        const payload = [{ bidStrategy: value, traffic_source_widget_id: row.traffic_source_widget_id }];
        await this.$api.widgets.changeStrategy(this.campaign.id, payload);
        this.$n_successNotification({ title: 'Bid Strategy successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (error) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'WIDGET', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getWidgetsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('widgets', this.campaign.traffic_source_unique_name, payload);
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      if (this.widgetsFilter.model !== 'all') {
        if (this.widgetsFilter.model === 'active') {
          filter.status = 'RUNNING';
        }
        if (this.widgetsFilter.model === 'blocked') {
          filter.status = 'PAUSED';
        }
      }
      return this.$_exportCsv({ level: 'widgets', campaignId: this.campaign.id, filter });
    },
    $_createSmartList() {
      if (this.tableModel.selectedRows.length) {
        this.$refs.smartListModal.show();
      } else {
        this.$n_failNotification({ title: 'Select at least 1 row' });
      }
    },
    async $_bulkResetBid() {
      try {
        const swal = await this.$swal({
          title: '<small>This action will reset widget bids to campaign default bid.</small>',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        });
        if (swal.value === true) this.$_bulkUpdateBid(this.campaign.additional_fields.bid);
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    onFiltersChange({ value }) {
      this.widgetsFilter.model = value;
      this.widgetsModelFilter = value;
      this.tableModel.selectedRows = [];
      this.$_getWidgetsPaginated();
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getWidgetsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'WIDGET');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getWidgetsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getWidgetsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getWidgetsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
  },
};
</script>

<style lang="scss">
.widgets-wrapper {
  .dt-action-checkbox.custom-checkbox {
    margin-top: 4px;
    display: inline-block;
    margin-bottom: 0px;
    .custom-control-description {
      line-height: 25px;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none;
    }
  }
}
</style>
