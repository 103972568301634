<template>
  <div class="rule-group-wrapper">
    <b-form-group
      class="optimizer-form-group"
      label="Rule Group"
      :state="$_hasError('groups')"
      :invalid-feedback="$_getError('groups')"
    >
      <b-input-group>
        <v-select
          id="ruleGroup"
          placeholder="Search Groups..."
          class="form-control multiselect rule-v-select"
          :close-on-select="false"
          multiple
          :options="options.groups"
          v-model="groups"
          name="groups"
        >
        </v-select>
        <template slot="append">
          <b-btn @click="$_openModal" class="secondary-button add-group-button">
            <i class="fa fa-plus"></i> New Group
          </b-btn>
        </template>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleGroup',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    groupOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      groups: [],
      options: {
        groups: [],
      },
    };
  },
  watch: {
    groups: {
      immediate: true,
      handler() {
        this.$_emit('groups');
      },
      deep: true,
    },
  },
  async created() {
    this.groups = this.value;
    this.options.groups = this.groupOptions;
  },
  methods: {
    $_openModal() {
      this.$emit('openModal');
    },
  },
};
</script>

<style lang="scss">
  .rule-group-wrapper {
    .input-group {
      .v-select.rule-v-select {
        border-radius: .6rem;
      }
    }
  }

      .v-select.rule-v-select {
        margin-right: 1rem;
        border: .1rem solid #e5e5e5;
        height: auto;
        border-radius: .6rem;
        font-size: 1.4rem;
        color: $text-secondary;

        &.single-select {
          width: auto;
          .vs__dropdown-toggle {
            .vs__selected-options {
              .vs__selected {
                font-size: 1.5rem;
                padding: .1rem .5rem;
                background: transparent  !important;
                color: $text-primary;
              }
            }
          }
        }

        .vs__dropdown-toggle {
          margin-top: -.2rem;
          border: none;

          .vs__selected-options {

            .vs__search {
              color: $text-primary;
              &::placeholder {
                color: $black-300;
              }
            }

            .vs__selected {
              background: #E4EAEE;
              color: $text-primary;
              border-radius: .6rem;
              font-size: 1.3rem;
              padding: .15rem 1.3rem;
              border: none;

              svg {
                fill: #546582;
                margin-left: .3rem;
              }
            }
          }

          .vs__open-indicator {
            fill: $black-300;
          }
        }

        .vs__dropdown-menu {
          padding: 1rem;
          background: $color-white;
          border: none;
          box-shadow: $bs-lg;
          border-radius: .6rem;
          color: $color-dark;
          margin-top: .3rem;

          .vs__dropdown-option {
            display: flex;
            align-items: center;
            padding: .7rem 1.2rem .7rem 1.2rem;
            color: $black-600;
            font-size: 1.4rem;
            background: $color-white;
            border-radius: .4rem;

            &--selected {
              background: $color-light;

              &::after {
                font-family: 'FontAwesome';
                content: '\f00c';
                margin-left: auto;
                color: $black-600;
              }
            }

            &:hover {
              background: $color-light;
            }
          }
        }
      }

      .add-group-button {
        border-top-left-radius: .6rem !important;
        border-bottom-left-radius: .6rem !important;
      }

      // .add-group-button {
      //   color: $color-white;
      //   background: $color-blue;
      //   font-size: 1.4rem;
      //   padding: .65rem 1.3rem;
      //   border: none;
      //   border-radius: .6rem;
      //   border-top-left-radius: .6rem !important;
      //   border-bottom-left-radius: .6rem !important;
      //   // height: 3.4rem;
      //   display: flex;
      //   align-items: center;

      //   &:hover,
      //   &:active,
      //   &:focus {
      //     background: $color-dark-blue;
      //   }

      //   i {
      //     margin-right: 1rem;
      //   }
      // }
    // }
  // }
</style>
