import props from './props';

export default {
  mixins: [props],
  methods: {
    async validate() {
      try {
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
    $_emit(attribute) {
      this.$emit('input', this[attribute]);
    },
  },
};
