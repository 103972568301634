import * as VueOptiTable from '@sh/types/Table';

export interface PresetData {
  presetList: PresetList;
  selectedPreset: Preset;
}

export type ConstraintKeys =
  | 'TrafficSourceUniqueName'
  | 'TrackerUniqueName'
  | 'SubUser'
  | 'AllTrafficSources'
  | 'AllTrackers';

export enum ConstraintEnum {
  TrafficSourceUniqueName = 'TrafficSourceUniqueName',
  TrackerUniqueName = 'TrackerUniqueName',
  SubUser = 'SubUser',
  AllTrafficSources = 'AllTrafficSources',
  AllTrackers = 'AllTrackers',
}

export type ConstraintToAllowedKeysMapping = {
  TrafficSourceUniqueName: 'availableTsTypes';
  TrackerUniqueName: 'availableTrTypes';
  SubUser: 'availableSubUsers';
  AllTrafficSources: 'availableTsTypes';
  AllTrackers: 'availableTrTypes';
};

export type AllowedKeys = 'availableTsTypes' | 'availableTrTypes' | 'availableSubUsers';

type ConstraintMapping<Constraint extends keyof typeof ConstraintEnum> = {
  [K in Constraint]: {
    constraint: K;
    values: {
      [P in ConstraintToAllowedKeysMapping[K]]: string[];
    };
  };
}[Constraint];

type AllConstraintMappings = {
  [K in keyof typeof ConstraintEnum]: ConstraintMapping<K>;
}[keyof typeof ConstraintEnum];

export type PresetScope = AllConstraintMappings[];

export interface Preset {
  name: string;
  description?: string;
  _id: string;
  scopes: PresetScope;
  created_at: Date;
  suggestedPreset?: boolean;
  fields: VueOptiTable.Field[];
}

export interface PresetList {
  user_presets: Preset[];
  suggested_presets: Preset[];
}

export interface API {
  getPresets: () => Promise<PresetList>;
  deletePreset: (id: string) => Promise<{ message: string }>;
  editPresetName: (id: string, name: string) => Promise<{ message: string }>;
  createPreset: (preset: Preset) => Promise<Preset | undefined>;
  addSubUsersToPreset: (preset: Preset, subUsers: number[]) => Promise<Document>;
  removeSubUsersFromPreset: (preset: Preset) => Promise<Document>;
}

export enum SubUserAccessMode {
  newPreset = 'newPreset',
  currentPreset = 'currentPreset',
}
