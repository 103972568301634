/* eslint-disable indent */
import moment from 'moment';
import countryData from './countryData';
import browserData from './browserData';
import { api } from '@sh/services/api';
import CampaignModel from '../../lib/CampaignModel';
import { doubleVerifyOptions, iasOptions, bidStrategyTypes } from './preBidSafetyData';

/**
 * API Model: {
 *  name: <String>,
 *  branding_text: <String>,
 *  marketing_objective: <String>,
 *  start_date: <String|y-m-d|0>
 *  end_date: <String|y-m-d|9999-12-31>,
 *  country_targeting: { type: <String|ALL|INCLUDE|EXCLUDE>, value: <Array|[<String>]|null> },
 *  region_country_targeting: { type: <String|ALL|INCLUDE|EXCLUDE>, value: <Array|[<String>]|null> }, // Optional
 *  dma_country_targeting: { type: <String|ALL|INCLUDE|EXCLUDE>, value: <Array|[<String>]|null> }, // Optional
 *  platform_targeting: { type: <String|INCLUDE>, value: <Array|[<String>]> },
 *  os_targeting: { type: <String|ALL|INCLUDE|EXCLUDE>, value: <Array|[<Object>]> },
 *  browser_targeting: { type: <String|ALL|INCLUDE|EXCLUDE>, value: <Array|[<String>]> },
 *  connection_type_targeting: { type: <String|ALL|INCLUDE>, value: <Array|[<String>]> },
 *  cpc: <String>,
 *  bid_strategy: <String|MAX_CONVERSIONS|TARGET_CPA>
 *  conversion_rules: { type: <Object> }
 *  cpc_type: <String|FIXED|SMART>,
 *  bid_type: <String|FIXED|OPTIMIZED_CONVERSIONS>,
 *  spending_limit: <String>,
 *  spending_limit_model: <String|MONTHLY|ENTIRE>
 *  daily_ad_delivery_model: <String|BALANCED|ACCELERATED|STRICT>
 *  daily_cap: <String>, // Optional
 *  traffic_allocation_mode: <String|OPTIMIZED|EVEN>,
 *  tracking_code: <String>,
 *  comments: <String>
 *  is_active: <String>
 *  cpa_goal: <String>, // Optional
 *  activity_schedule: { <Object> },
 *  external_brand_safety: <Object>,
 *  publisher_targeting: <Object>
 * }
 */
export default class TaboolaCampaignModel extends CampaignModel {
  constructor(account) {
    super(account);
    this.campaignPayload = null; // Campaign when clone or edit campaign
  }

  async setModel(form, nextTick, mode) {
    this.campaignPayload = form;
    this.setBasicInfo(mode, form.name, form.branding_text, form.marketing_objective)
    .setSchedule(mode, form.start_date, form.end_date, form.is_active, form.activity_schedule)
    .setTargeting(form, nextTick)
    .setBidding(form.cpc, form.bid_strategy, form.cpa_goal)
    .setBudget(form.daily_ad_delivery_model, form.daily_cap, form.spending_limit_model, form.spending_limit, form.traffic_allocation_mode, form.traffic_allocation_ab_test_end_date)
    .setConversionRules(form.conversion_rules)
    .setTrackingAndComments(form.tracking_code, form.comments);
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const form = {};
    form.name = modelForm.name;
    form.branding_text = modelForm.branding_text;
    form.marketing_objective = modelForm.marketing_objective;
    if (modelForm.comversion_rules) {
      form.conversion_rules = {
        rules: [
          {
            id: modelForm.conversion_rules.value,
          },
        ],
      };
    }
    form.start_date = modelForm.start_date || 0;
    form.end_date = modelForm.end_date || '9999-12-31';
    // Set Targeting
    form.country_targeting = { type: 'ALL', value: null };
    if (modelForm.targeting_type !== 'ALL') {
      form.country_targeting = {
        type: modelForm.targeting_type,
        value: modelForm.countries.map((item) => item.value),
      };
      if (modelForm.location_type === 'REGION') {
        form.region_country_targeting = {
          type: modelForm.targeting_type,
          value: modelForm.regions.map((item) => item.value),
        };
      }
      if (modelForm.location_type === 'DMA') {
        form.dma_country_targeting = {
          type: modelForm.targeting_type,
          value: modelForm.dma.map((item) => item.value),
        };
      }
    }
    form.platform_targeting = { type: 'INCLUDE', value: modelForm.platform_targeting };
    form.os_targeting = { type: 'ALL', value: null };
    if (modelForm.os_targeting !== 'ALL') {
      form.os_targeting = {
        type: modelForm.os_targeting,
        value: modelForm.os,
      };
    }
    form.browser_targeting = { type: 'ALL', value: null };
    if (modelForm.browser_targeting !== 'ALL') {
      form.browser_targeting = {
        type: modelForm.browser_targeting,
        value: modelForm.browser.map((item) => item.value),
      };
    }
    if (modelForm.connection_type === 'ALL') {
      form.connection_type_targeting = {
        type: 'ALL',
        value: [],
      };
    } else if (modelForm.connection_type === 'WIFI') {
      form.connection_type_targeting = {
        type: 'INCLUDE',
        value: ['WIFI'],
      };
    }
    form.cpc = modelForm.cpc;
    form.bid_strategy = modelForm.bid_strategy.value !== 'BID_CONTROL' ? modelForm.bid_strategy.value : modelForm.cpc_type;
    form.spending_limit_model = modelForm.spending_limit_model;
    form.spending_limit = modelForm.spending_limit_model === 'NONE' ? null : modelForm.spending_limit;
    form.daily_ad_delivery_model = modelForm.daily_ad_delivery_model;
    if (modelForm.daily_cap) form.daily_cap = modelForm.daily_cap;
    form.traffic_allocation_mode = modelForm.traffic_allocation_mode;
    if (modelForm.traffic_allocation_mode === 'EVEN' && modelForm.traffic_allocation_ab_test_end_date) {
      form.traffic_allocation_ab_test_end_date = modelForm.traffic_allocation_ab_test_end_date;
    }
    form.tracking_code = modelForm.tracking_code;
    form.comments = modelForm.comments;
    form.activity_schedule = {};
    if (modelForm.activity_schedule_switch === 'ALWAYS') {
      form.activity_schedule.mode = 'ALWAYS';
      form.activity_schedule.rules = [];
    } else {
      form.activity_schedule.mode = 'CUSTOM';
      form.activity_schedule.rules = modelForm.activity_schedule_rules.rules.map((rule) => {
        rule.type.length ? rule.type = 'INCLUDE' : rule.type = 'EXCLUDE';
        return rule;
      });

      const hasDaySelected = form.activity_schedule.rules.every((item) => item.type === 'EXCLUDE');
      if (hasDaySelected) {
        form.activity_schedule.mode = 'ALWAYS';
        form.activity_schedule.rules = [];
      }
    }
    form.activity_schedule.time_zone = modelForm.activity_schedule_rules.time_zone;
    form.is_active = modelForm.is_active;
    form.cpa_goal = modelForm.cpa_goal;
    form.publisher_targeting = { type: 'ALL', value: null, href: null };
    const value = modelForm.publisher_targeting.map((publisher) => publisher.account_id || publisher);
    if (value.length) {
      form.publisher_targeting = { type: 'EXCLUDE', value, href: null };
    }
    // form.isTemplate = modelForm.isTemplate[0] || false;

    if (this.mode === 'clone' && this.campaignPayload && this.campaignPayload.id) {
      form.cloneCampaignId = this.campaignPayload.id;
      form.destination_account = this.account.destinationAccount;
    }

    form.external_brand_safety = { type: 'NONE', values: [] };
    if (modelForm.external_brand_safety.length) {
      form.external_brand_safety = {
        type: modelForm.external_brand_safety_switch,
        values: modelForm.external_brand_safety.map((item) => ({ category_name: item.value, risk_level: 'HIGH' })),
      };
    }
    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      marketingObjectiveOptions: [
        { text: 'Lead Generation', value: 'LEADS_GENERATION', icon: 'fa fa-envelope-o' },
        { text: 'Online Purchases', value: 'ONLINE_PURCHASES', icon: 'fa fa-shopping-cart' },
        { text: 'Brand Awareness', value: 'BRAND_AWARENESS', icon: 'fa fa-bullhorn' },
        { text: 'Web Engagement', value: 'DRIVE_WEBSITE_TRAFFIC', icon: 'fa fa-eye' },
        { text: 'Mobile App Installs', value: 'MOBILE_APP_INSTALL', icon: 'fa fa-mobile' },
      ],
      targetingTypeOptions: [
        { text: 'Target Locations', value: 'INCLUDE' },
        { text: 'Block Locations', value: 'EXCLUDE' },
        { text: 'All Locations', value: 'ALL' },
      ],
      locationTypeOptions: [
        { text: 'Entire Country', value: 'ALL', disabled: false },
        { text: 'Region/State', value: 'REGION', disabled: true },
        // { text: 'Postal/Zip Code', value: 'POSTAL', disabled: true },
        { text: 'DMA', value: 'DMA', disabled: true },
      ],
      platforms: [
        { text: 'Desktop', value: 'DESK' },
        { text: 'Tablet', value: 'TBLT' },
        { text: 'Mobile', value: 'PHON' },
      ],
      browserTypeOptions: [
        { text: 'All Browsers', value: 'ALL' },
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      osTypeOptions: [
        { text: 'Target All', value: 'ALL' },
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exclude', value: 'EXCLUDE' },
      ],
      osOptions: [
        { text: 'Mac OS X', value: [] },
        { text: 'Linux', value: [] },
        { text: 'Windows', value: ['Windows 11', 'Windows 10', 'Windows 8.1', 'Windows 8', 'Windows 7', 'Windows Vista', 'Windows XP'].map((os) => ({ text: os, value: os.replace(' ', '_') })) },
        { text: 'iOS', value: ['iOS 17', 'iOS 16', 'iOS 15', 'iOS 14', 'iOS 13', 'iOS 12', 'iOS 11', 'iOS 10', 'iOS 9', 'iOS 8.4', 'iOS 8.3', 'iOS 8.2', 'iOS 8.1', 'iOS 8', 'iOS 7', 'iOS 6', 'iOS 5', 'iOS 4'].map((os) => ({ text: os, value: os.replace(' ', '_') })) },
        { text: 'iPadOS', value: ['iPadOS 17', 'iPadOS 16', 'iPadOS 15', 'iPadOS 14', 'iPadOS 13'].map((os) => ({ text: os, value: os.replace(' ', '_') })) },
        { text: 'Android', value: ['Android 14', 'Android 13', 'Android 12', 'Android 11', 'Android 10', 'Android 9', 'Android 8', 'Android 7', 'Android 6', 'Android 5', 'Android 4', 'Android 3', 'Android 2', 'Android 1'].map((os) => ({ text: os, value: os.replace(' ', '_') })) },
      ],
      connectionOptions: [
        { text: 'Target All', value: 'ALL' },
        { text: 'Wi-Fi Only', value: 'WIFI' },
      ],
      spendingModelOptions: [
        { text: 'None', value: 'NONE' },
        { text: 'Entire Campaign', value: 'ENTIRE' },
        { text: 'Monthly Flight', value: 'MONTHLY' },
      ],
      dailyAdOptions: [
        { text: 'Balanced', value: 'BALANCED' },
        { text: 'Strict', value: 'STRICT' },
      ],
      trafficAllocationOptions: [
        { text: 'Optimized', value: 'OPTIMIZED' },
        { text: 'A/B Testing', value: 'EVEN' },
      ],
      bidStrategyOptions: [
        { text: 'Maximize Conversions (CPA)', value: 'MAX_CONVERSIONS' },
        { text: 'Bid Control (CPC/CPM)', value: 'BID_CONTROL' },
        { text: 'Target CPA', value: 'TARGET_CPA' },
      ],
      bidTypeOptions: [
        { text: 'Fixed Bid', value: 'FIXED' },
        { text: 'Enhanced Bid', value: 'SMART' },
      ],
      campaingStatusOptions: [
        { text: 'Active', value: true },
        { text: 'Paused', value: false },
      ],
      scheduleOptions: {
        time_zone: 'US/Eastern',
        rules: [
          {
            type: ['MONDAY'],
            day: 'MONDAY',
            from_hour: '0',
            until_hour: '24',

          },
          {
            type: ['TUESDAY'],
            day: 'TUESDAY',
            from_hour: '0',
            until_hour: '24',

          },
          {
            type: ['WEDNESDAY'],
            day: 'WEDNESDAY',
            from_hour: '0',
            until_hour: '24',
          },
          {
            type: ['THURSDAY'],
            day: 'THURSDAY',
            from_hour: '0',
            until_hour: '24',
          },
          {
            type: ['FRIDAY'],
            day: 'FRIDAY',
            from_hour: '0',
            until_hour: '24',
          },
          {
            type: ['SATURDAY'],
            day: 'SATURDAY',
            from_hour: '0',
            until_hour: '24',
          },
          {
            type: ['SUNDAY'],
            day: 'SUNDAY',
            from_hour: '0',
            until_hour: '24',
          },
        ],
      },
      publisherTargetingOptions: async () => api.campaignCreator.getTaboolaPublishers(this.account.value.id),
      countryData,
      browserData,
      getRegions: async () => {
        if (this.components.countries.value.length === 1) {
          const trafficSourceAccountId = this.account.value.id;
          const countryCode = this.components.countries.value[0].value;
          const result = await api.campaignCreator.getTaboolaRegions(trafficSourceAccountId, countryCode);
          return result.map((item) => ({ name: item.value, value: item.name }));
        }
      },
      // getPostalCodes: async () => {
      //   if (this.components.countries.value.length === 1) {
      //     const trafficSourceAccountId = this.account.value.id
      //     const countryCode = this.components.countries.value[0].value
      //     const result = await api.campaignCreator.getTaboolaPostalCodes(trafficSourceAccountId, countryCode)
      //     return result.map(item => { return { name: item.value, value: item.name } })
      //   }
      // },
      getDMA: async () => {
        if (this.components.countries.value.length === 1 && this.components.countries.value[0].value === 'US') {
          const trafficSourceAccountId = this.account.value.id;
          const result = await api.campaignCreator.getTaboolaDMA(trafficSourceAccountId);
          return result.map((item) => ({ name: item.value, value: item.name }));
        }
      },
      getConversionRules: async () => {
        const conversions = await api.campaignCreator.getTaboolaConversionRules(this.account.value.id);
        return conversions.map((c) => ({ name: c.rule_name, value: c.rule_id }));
      },
    };
  }

  __generatePopOver() {
    return {
      dailyAdPopover: {
        title: 'Daily Ad Delivery',
        content: `<ul>
                    <li>Balanced delivery: A good fit if you would like to spread your budget evenly across the campaign's timeframe. Recommended option.</li>
                    <li>Strict Daily: Set a daily cap.</li>
                  </ul>`,
        html: true,
      },
      dailySpendPopover: {
        title: 'Daily Spend',
        content: 'You will not be charged more than your monthly or overall campaign budget.',
        html: true,
      },
      brandingPopover: {
        content: 'This text will appear below the title of each of your items.',
      },
      trafficAllocationPopover: {
        title: 'Traffic Allocation',
        content: `<ul>
                    <li>Optimized - (Recommended) the exposure of the creatives in your campaign will be determined by our algorithm, and your most engaging creatives will be served more than others.</li>
                    <li>A/B Test - Ads will receive equal opportunities across Taboola's network by allocating impressions more evenly than Optimized, regardless of performance. When the A/B test reaches the end date, the campaign will switch to Optimized ad delivery.</li>
                  </ul>`,
        html: true,
      },
      bidTypePopover: {
        content: '<ul class="m-1 pl-3"><li><b>Smart Bid: </b>Use if you want Taboola to help maximize campaign performance and scale automatically. The actual cost per click might be higher for some impressions and lower for others. The maximum cost per click over each site won\'t be higher than 100% over the baseline bid amount.</br></br></li><li><b>Fixed Bid: </b>Use if you want to maintain control over your baseline bid. We recommend starting with a higher bid and adjusting depending on campaign performance. </li></ul>',
        html: true,
      },
      bidStrategyPopover: {
        content: '<ul class="m-1 pl-3"><li><b>Maximize Conversions (CPA): </b>Bids are automatically optimized to maximize conversions within the campaign budget specified.</br></br></li><li><b>Bid Control (CPC/CPM): </b>Bids are automatically optimized to maximize conversions within the campaign budget specified. <br></br></li></ul>',
        html: true,
      },
      enhancedCpcPopover: {
        content: 'Automatically optimize baseline bid to maximize the performance (Smartbid)',
        html: true,
      },
      fixedCpcPopover: {
        content: 'Manually control your bid, baseline will be fixed and not optimized',
        html: true,
      },
      statusPopover: {
        content: 'By default, your Campaign is set to Active and delivery will begin on the start date, once it will be approved. In case you do not want the campaign to automatically start running as soon as approved you can set the campaign status to Paused and it will start only when you enable it on the Campaigns report.',
        html: true,
      },
      activitySchedulePopover: {
        content: 'You can schedule your campaign to run on specific hours and days of the week.Select Set Specific Days or Hours to set the days and hours that your campaign will run on. You can only set one specific time period per day. To add an additional time period, duplicate this campaign and create another scheduling rule.Selecting 24/7 will set the campaign to run twenty-four hours a day, seven days a week.',
        html: true,
      },
      cpaGoalPopover: {
        content: 'When a user takes a desired action on your landing page, it is called a conversion.This conversion could be a product purchase, a pageview, an email signup or any action that is valuable to your business.Your Cost-Per-Action or CPA, is the amount you pay for each of these actions.<a href="https://help.taboola.com/hc/en-us/articles/115006602167-Overview-Your-Cost-Per-Action-CPA-" target="_blank">Learn More</a>',
        html: true,

      },
      publisherTargetingPopover: {
        content: 'Block sites based on performance or brand safety. This means your campaign will not appear on that site until you undo the block. Even if your campaign is performing below average on a particular site, it’s recommended that you lower the CPC for that site instead of removing it completely. This way, you are still getting traffic that helps you hit your campaign goals instead of cutting off a site completely.',
        html: true,
      },
      externalBrandSafetyPopover: {
        content: 'Taboola has partnered with DoubleVerify (DV) and Integral Ad Science (IAS) to offer marketers brand safety pre-bid targeting.These third parties scan Taboolas network of publisher sites and apps, assigning brand safety risk tiers across a variety of topics. By applying brand safety pre-bid targeting, Taboola will prevent your ad from serving on all sites and apps which fail to meet the brand safety and suitability criteria you selected.',
        html: true,
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      name: {
        active: true, component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required',
      },
      branding_text: {
        active: true, component: 'cc-input', value: '', label: 'Branding Text', 'v-validate': 'required', popover: this.popover.brandingPopover,
      },
      marketing_objective: {
        active: true, component: 'cc-radio-button', value: 'LEADS_GENERATION', label: 'Marketing Objective', 'v-validate': 'required', options: this.options.marketingObjectiveOptions, gap: true,
      },
      start_date_switch: {
        active: true, component: 'cc-radio-button', value: 'REVIEWED', label: 'Start Date', 'v-validate': 'required', options: [{ value: 'REVIEWED', text: 'As soon as reviewed' }, { value: 'CUSTOM', text: 'Specific Date' }],
      },
      start_date: {
        active: true, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date',
      },
      end_date_switch: {
        active: true, component: 'cc-radio-button', value: 'STOP', label: 'End Date', 'v-validate': 'required', options: [{ value: 'STOP', text: 'When I Stop it' }, { value: 'CUSTOM', text: 'Specific Date' }],
      },
      end_date: {
        active: true, component: 'cc-date-picker', value: '', label: 'End Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select End Date',
      },
      activity_schedule_switch: {
        active: true, component: 'cc-radio-button', value: 'ALWAYS', label: 'Days and Hours', 'v-validate': 'required', options: [{ value: 'ALWAYS', text: '24/7' }, { value: 'CUSTOM', text: 'Set Specific Days or Hours' }], popover: this.popover.activitySchedulePopover,
      },
      activity_schedule_rules: {
        active: true, component: 'cc-activity-schedule', value: this.options.scheduleOptions, label: '', options: this.options.scheduleOptions,
      },
      targeting_type: {
        active: true, component: 'cc-radio-button', value: 'ALL', label: 'Location', 'v-validate': 'required', options: this.options.targetingTypeOptions,
      },
      countries: {
        active: false, component: 'cc-multi-select', value: [], label: 'Country', disableLabel: true, 'v-validate': 'required', options: this.options.countryData, optionLabel: 'name', placeholder: 'Select Countries', type: 'countries',
      },
      location_type: {
        active: false, component: 'cc-radio-button', value: 'ALL', label: 'Location', disableLabel: true, 'v-validate': 'required', options: this.options.locationTypeOptions, textTop: '', textBottom: '',
      },
      regions: {
        active: false, component: 'cc-multi-select', value: [], label: 'Region', disableLabel: true, 'v-validate': 'required', options: [], getOptions: this.options.getRegions, optionLabel: 'name', placeholder: 'Select Regions',
      },
      // postal_codes: { active: false, component: 'cc-multi-select', value: [], label: 'Postal Codes', disableLabel: true, 'v-validate': 'required', options: [], getOptions: this.options.getPostalCodes, optionLabel: 'name', placeholder: 'Select Postal Codes' },
      dma: {
        active: false, component: 'cc-multi-select', value: [], label: 'USA DMA', disableLabel: true, 'v-validate': 'required', options: [], getOptions: this.options.getDMA, optionLabel: 'name', placeholder: 'Select USA DMA',
      },
      platform_targeting: {
        active: true, component: 'cc-checkbox', value: ['DESK', 'TBLT', 'PHON'], label: 'Platform', 'v-validate': 'required', options: this.options.platforms,
      },
      os_targeting: {
        active: true, component: 'cc-radio-button', value: 'ALL', label: 'Operating System', 'v-validate': 'required', options: this.options.osTypeOptions,
      },
      os: {
        active: false, component: 'cc-os-targeting-select', value: [], label: 'OS', disableLabel: true, 'v-validate': 'required', options: this.options.osOptions,
      },
      browser_targeting: {
        active: true, component: 'cc-radio-button', value: 'ALL', label: 'Browser', 'v-validate': 'required', options: this.options.browserTypeOptions,
      },
      browser: {
        active: false, component: 'cc-multi-select', value: [], label: 'Browser', disableLabel: true, 'v-validate': 'required', options: this.options.browserData, optionLabel: 'name', placeholder: 'Select Browsers',
      },
      connection_type: {
        active: true, component: 'cc-radio-button', value: 'ALL', label: 'Connection Type', 'v-validate': 'required', options: this.options.connectionOptions,
      },
      cpc: {
        active: true, component: 'cc-input', value: '', label: 'CPC', 'v-validate': 'required|min_value:0|excluded:0', left: 'USD', right: 'per click',
      },
      bid_strategy: {
        active: true, component: 'cc-multi-select', value: [], label: 'Bid Strategy', options: this.options.bidStrategyOptions, placeholder: 'Select Bid Strategy', multiple: false, optionLabel: 'text', popover: this.popover.bidStrategyPopover,
      },
      conversion_rules: {
        active: true, component: 'cc-multi-select', value: [], label: 'Conversion Event', options: [], getOptions: this.options.getConversionRules, optionLabel: 'name', placeholder: 'Select a conversion event', multiple: false,
      },
      cpc_type: {
        active: true, component: 'cc-radio-button', value: 'FIXED', label: 'Bid Type', options: this.options.bidTypeOptions,
      },
      cpa_goal: {
        active: true, component: 'cc-input', value: '', label: 'CPA Goal', 'v-validate': 'required|min_value:0|excluded:0', left: 'USD', popover: this.popover.cpaGoalPopover,
      },
      spending_limit: {
        active: true, component: 'cc-input', value: '', label: 'Spending Limit', 'v-validate': 'required|min_value:0|excluded:0', left: 'USD',
      },
      spending_limit_model: {
        active: true, component: 'cc-radio-button', value: 'MONTHLY', label: '', 'v-validate': 'required', options: this.options.spendingModelOptions,
      },
      daily_ad_delivery_model: {
        active: true, component: 'cc-radio-button', value: 'STRICT', label: 'Daily Ad Delivery', 'v-validate': 'required', options: this.options.dailyAdOptions, popover: this.popover.dailyAdPopover,
      },
      daily_cap: {
        active: false, component: 'cc-input', value: '', label: 'Daily Cap', 'v-validate': 'required|min_value:0|excluded:0', left: 'USD', popover: this.popover.dailySpendPopover,
      },
      traffic_allocation_mode: {
        active: true, component: 'cc-radio-button', value: 'OPTIMIZED', label: 'Creative Traffic Allocation', 'v-validate': 'required', options: this.options.trafficAllocationOptions, popover: this.popover.trafficAllocationPopover,
      },
      traffic_allocation_ab_test_end_date: {
        active: false, component: 'cc-date-picker', value: moment().add(14, 'days').format('YYYY-MM-DD'), label: 'End Date', disabledDate: { to: moment().add(2, 'days').toDate(), from: moment().add(30, 'days').toDate() }, placeholder: 'Select End Date',
      },
      tracking_code: {
        active: true, component: 'cc-textarea', value: '', label: 'Tracking Code', placeholder: 'Tracking Code', rows: 3,
      },
      comments: {
        active: true, component: 'cc-textarea', value: '', label: 'Additional Requests', placeholder: 'Additional Requests', rows: 3,
      },
      is_active: {
        active: true, component: 'cc-radio-button', value: true, label: 'Status', 'v-validate': 'required', options: this.options.campaingStatusOptions, popover: this.popover.statusPopover, gap: true,
      },
      schedule: {
        active: false, component: 'cc-radio-button', value: 'ALWAYS', label: '', rows: 3, options: this.options.campaignScheduleOptions, gap: true,
      },
      publisher_targeting_switch: {
        active: true, component: 'cc-radio-button', value: 'EXCLUDE', label: 'Block Sites', options: [{ value: 'EXCLUDE', text: 'Exclude' }], popover: this.popover.publisherTargetingPopover,
      },
      publisher_targeting: {
        active: true, component: 'cc-blocked-sites', value: [], label: 'Block Sites', disableLabel: true, options: [], placeholder: 'Select sites to block (optional)', getOptions: this.options.publisherTargetingOptions,
      },
      external_brand_safety_switch: {
        active: true, component: 'cc-radio-button', value: 'DV', label: 'Brand Safety Pre-Bid', options: [{ value: 'DV', text: 'DoubleVerify' }, { value: 'IAS', text: 'IAS' }], popover: this.popover.externalBrandSafetyPopover, disabled: false,
      },
      external_brand_safety: {
        active: true,
        component: 'cc-multi-select',
        value: [],
        label: 'Brand Safety Pre-Bid',
        disableLabel: true,
        options: doubleVerifyOptions,
        placeholder: 'Select Brand Safety Segments (optional)...',
      },
      // isTemplate: {
      //   active: true, component: 'cc-checkbox', label: 'Template', value: [], options: [{ value: true, text: 'Save also as template' }],
      // },
    };

    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      start_date_switch: {
        fn: (value) => {
          if (value === 'REVIEWED') {
            _this.components.start_date.active = false;
            _this.components.start_date.value = '';
            _this.components.start_date.disabledDate.to = _this.data.today;
          } else if (value === 'CUSTOM') {
            _this.components.start_date.active = true;
          }
        },
      },
      end_date_switch: {
        fn: (value) => {
          if (value === 'STOP') {
            _this.components.end_date.active = false;
            _this.components.end_date.value = '';
            _this.components.end_date.disabledDate.to = _this.data.today;
          } else if (value === 'CUSTOM') {
            _this.components.end_date.active = true;
          }
        },
      },
      // Start Date <=> End Date // Logic
      start_date: {
        fn: (value) => {
          const startDate = value;
          const endDate = _this.components.end_date.value;
          if (startDate) {
            _this.components.end_date.disabledDate.to = new Date(startDate);
            if (endDate && moment(startDate) > moment(endDate)) {
              _this.components.end_date.value = '';
            }
          } else {
            _this.components.end_date.disabledDate.to = _this.data.today;
          }
        },
      },
      targeting_type: {
        fn: (value) => {
          if (value === 'ALL') {
            _this.resetComponent('countries');
            _this.resetComponent('location_type');
          } else {
            if (value === 'EXCLUDE') {
              _this.components.location_type.textTop = 'Locations to be excluded:';
            } else if (value === 'INCLUDE') {
              _this.components.location_type.textTop = 'Locations to be included:';
            }
            _this.components.countries.active = true;
            _this.components.location_type.active = true;
          }
        },
      },
      // Enable DMA only on (INCLUDE) AND (US)
      dma_location_type: {
        trigger() {
          return {
            targeting_type: _this.components.targeting_type.value,
            countries: _this.components.countries.value,
          };
        },
        fn(valueObj) {
          const optIndex = _this.components.location_type.options.findIndex((opt) => opt.value === 'DMA');
          if (valueObj.targeting_type === 'INCLUDE' && valueObj.countries.length === 1 && valueObj.countries[0].value === 'US') {
            _this.components.location_type.options[optIndex].disabled = false;
          } else {
            _this.components.location_type.options[optIndex].disabled = true;
            _this.components.location_type.value = 'ALL';
          }
        },
      },
      countries: {
        fn: (value) => {
          _this.components.location_type.value = 'ALL';
          _this.components.location_type.textBottom = '';
          if (value.length === 1) {
            _this.components.location_type.options.forEach((option) => {
              if (option.value !== 'DMA') {
                option.disabled = false;
              }
              if (_this.components.targeting_type.value === 'EXCLUDE' && option.value === 'REGION') {
                _this.components.targeting_type.textBottom = '<b>Notice:</b> Region targeting is not available when choosing to block a location';
                option.disabled = true;
              }
            });
          } else {
            _this.resetComponentOptions('location_type');
            if (value.length > 1) {
              _this.components.location_type.textBottom = '<b>Notice:</b> Sub-Country targeting is not available for multiple countries within a single campaign';
            }
          }
          _this.resetComponent('regions');
          // _this.resetComponent('postal_codes')
          _this.resetComponent('dma');
        },
      },
      location_type: {
        fn: (value) => {
          _this.components.regions.active = value === 'REGION';
          // _this.components.postal_codes.active = value === 'POSTAL'
          _this.components.dma.active = value === 'DMA';
        },
      },
      os_targeting: {
        fn: (value) => {
          if (value === 'ALL') {
            _this.resetComponent('os');
          } else {
            _this.components.os.active = true;
          }
        },
      },
      browser_targeting: {
        fn: (value) => {
          if (value === 'ALL') {
            _this.resetComponent('browser');
          } else {
            _this.components.browser.active = true;
          }
        },
      },
      traffic_allocation_mode: {
        fn: (value) => {
          if (value === 'EVEN') {
            _this.components.traffic_allocation_ab_test_end_date.active = true;
          } else {
            _this.components.traffic_allocation_ab_test_end_date.active = false;
          }
        },
      },
      daily_ad_delivery_model: {
        fn: (value) => {
          if (value === 'STRICT') {
            _this.components.daily_cap.active = true;
          } else {
            _this.resetComponent('daily_cap');
          }
        },
      },
      activity_schedule_switch: {
        fn: (value) => {
          if (value === 'CUSTOM') {
            _this.components.schedule.active = true;
          } else {
            _this.components.schedule.active = false;
          }
        },
      },
      external_brand_safety_switch: {
        fn: (value) => {
          if (value === 'IAS') {
            _this.components.external_brand_safety.options = iasOptions;
          } else if (value === 'DV') {
            _this.components.external_brand_safety.options = doubleVerifyOptions;
          }
        },
      },
      spending_limit_model: {
        fn: (value) => {
          _this.components.spending_limit.active = true;
          if (value === 'NONE') {
            _this.components.spending_limit.active = false;
          }
        },
      },
      bid_strategy: {
        fn: (item) => {
          if (item.value === 'MAX_CONVERSIONS') {
            _this.components.cpc.value = undefined;
          }
        },
      },
    };
  }

  setBasicInfo(mode, name, branding_text, marketing_objective) {
    this.components.name.value = mode === 'clone' ? `${name} CLONE` : name;
    this.components.branding_text.value = branding_text;
    if (this.components.marketing_objective.options.map((item) => item.value).indexOf(marketing_objective) > -1) {
      this.components.marketing_objective.value = marketing_objective;
    } else {
      this.components.marketing_objective.value = '';
    }
    return this;
  }

  setSchedule(mode, start_date, end_date, is_active, activity_schedule) {
    if (mode !== 'clone') {
      if (start_date) {
        this.components.start_date_switch.value = 'CUSTOM';
        this.components.start_date.value = start_date;
      }
      if (end_date && end_date !== '9999-12-31') {
        this.components.end_date_switch.value = 'CUSTOM';
        this.components.end_date.value = end_date;
      }
    }
    this.components.is_active.value = is_active;

    // Activity Schedule
    this.components.activity_schedule_switch.value = activity_schedule.mode;
    if (this.components.activity_schedule_switch.value === 'CUSTOM') {
      this.components.activity_schedule_rules.value.rules = [];
      activity_schedule.rules.forEach((rule) => {
        rule.type === 'INCLUDE' ? rule.type = [rule.day] : rule.type = [];
        this.components.activity_schedule_rules.value.rules.push(rule);
      });
    }
    this.components.activity_schedule_rules.value.time_zone = activity_schedule.time_zone;
    return this;
  }

  setTargeting(form, nextTick) {
    // Platform Targeting
    if (form.platform_targeting.type === 'ALL') {
      this.components.platform_targeting.value = ['DESK', 'TBLT', 'PHON'];
    } else {
      this.components.platform_targeting.value = form.platform_targeting.value;
    }

    // OS Targeting
    this.components.os_targeting.value = form.os_targeting.type;
    if (['INCLUDE', 'EXCLUDE'].indexOf(form.os_targeting.type) > -1) {
      this.components.os.value = form.os_targeting.value;
    }

    // Browser Targeting
    this.components.browser_targeting.value = form.browser_targeting.type;
    if (['INCLUDE', 'EXCLUDE'].indexOf(form.browser_targeting.type) > -1) {
      this.__helperSetMultiSelectValue({
        component: this.components.browser,
        values: form.browser_targeting.value,
        getValueKey: (item) => item,
        getOptionKey: (option) => option.value,
      });
    }

    // Connection Targeting
    if (['INCLUDE', 'EXCLUDE'].indexOf(form.connection_type_targeting.type) > -1) {
      try {
        // eslint-disable-next-line prefer-destructuring
        this.components.connection_type.value = form.connection_type_targeting.value[0];
      } catch (err) {
        console.error(err);
      }
    }

    // Country & Region Targeting
    if (form.country_targeting && ['INCLUDE', 'EXCLUDE'].indexOf(form.country_targeting.type) > -1) {
      this.components.targeting_type.value = form.country_targeting.type;
      // Set Country
      const valueMap = {};
      form.country_targeting.value.forEach((code) => { valueMap[code] = code; });
      this.components.countries.options.forEach((option) => {
        if (valueMap[option.value]) this.components.countries.value.push(option);
      });

      // Set SubCountries
      [
        { name: 'region_country_targeting', component: 'regions', value: 'REGION' },
        // { name: 'postal_code_targeting', component: 'postal_codes', value: 'POSTAL' },
        { name: 'dma_country_targeting', component: 'dma', value: 'DMA' },
      ].forEach(({ name, component, value }) => {
        if (form[name] && ['INCLUDE', 'EXCLUDE'].indexOf(form[name].type) > -1) {
          nextTick(() => {
            this.components.location_type.value = value;
            this.components[component].once('ready', () => {
              this.__helperSetMultiSelectValue({
                component: this.components[component],
                values: form[name].value,
                getValueKey: (item) => item,
                getOptionKey: (option) => option.value,
              });
            });
          });
        }
      });
    }

    // Publisher Targeting
    if (form.publisher_targeting.value.length) {
      nextTick(() => {
        this.components.publisher_targeting.once('ready', () => {
          this.__helperSetMultiSelectValue({
            component: this.components.publisher_targeting,
            values: form.publisher_targeting.value,
            getValueKey: (item) => item,
            getOptionKey: (option) => option.account_id,
          });
        });
      });
    }

    // External Brand Safety
    this.components.external_brand_safety_switch.value = form.external_brand_safety.type === 'NONE' ? 'DV' : form.external_brand_safety.type;
    if (form.external_brand_safety.values.length) {
      const allFoundValues = [];
      form.external_brand_safety.values.forEach((category) => {
        if (form.external_brand_safety.type === 'DV') {
          const currentValue = doubleVerifyOptions.find((option) => option.value === category.category_name);
          if (currentValue) {
            allFoundValues.push(currentValue);
          }
        } else if (form.external_brand_safety.type === 'IAS') {
          const currentValue = iasOptions.find((option) => option.value === category.category_name);
          if (currentValue) {
            allFoundValues.push(currentValue);
          }
        }
      });
      this.components.external_brand_safety.value.push(...allFoundValues);
    }
    return this;
  }

  setBidding(cpc, bid_strategy, cpa_goal) {
    this.components.cpc.value = cpc;
    this.components.cpc_type.value = bid_strategy;
    if (bid_strategy === 'FIXED' || bid_strategy === 'SMART') {
      const bidStrategyValue = bidStrategyTypes.find((o) => o.value === 'BID_CONTROL');
      this.components.bid_strategy.value = bidStrategyValue;
    } else {
      const bidStrategyValue = bidStrategyTypes.find((o) => o.value === bid_strategy);
      this.components.bid_strategy.value = bidStrategyValue;
    }
    this.components.cpa_goal.value = cpa_goal;
    return this;
  }

  setBudget(daily_ad_delivery_model, daily_cap, spending_limit_model, spending_limit, traffic_allocation_mode, traffic_allocation_ab_test_end_date) {
    // this.components.spending_limit_model.value = spending_limit_model;
    // Budget
    if (daily_ad_delivery_model === 'STRICT') {
      this.components.daily_cap.value = daily_cap;
    }

    // Spending Limit Model
    this.components.spending_limit_model.value = spending_limit_model;
    this.components.spending_limit.value = spending_limit;

    this.components.daily_ad_delivery_model.value = daily_ad_delivery_model;

    // Traffic Allocation
    this.components.traffic_allocation_mode.value = traffic_allocation_mode;
    if (traffic_allocation_mode === 'EVEN') {
      this.components.traffic_allocation_ab_test_end_date.value = traffic_allocation_ab_test_end_date;
    }
    return this;
  }

  /**
   * @param {*} conversion_rules
   * {
    "rules": [
          {
              "id": 1142051,
              "display_name": "myconversion",
              "status": "ACTIVE",
              "include_in_total_conversions": true
          }
        ]
      }
   */
  setConversionRules(conversion_rules) {
    // since a campaign can only have on conversion rule
    if (conversion_rules) {
      this.components.conversion_rules.value = conversion_rules.rules[0].id;
      this.components.conversion_rules.value = conversion_rules.rules[0].display_name;
    }
  }

  setTrackingAndComments(tracking_code, comments) {
    this.components.tracking_code.value = tracking_code;
    this.components.comments.value = comments;
    return this;
  }
}
