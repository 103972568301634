import _ from 'lodash';
import session from '@sh/services/session';

const getNestedValue = (key, obj) => {
  try {
    const keys = key.split('.');
    let pointerValue = obj;
    keys.forEach((k) => { pointerValue = pointerValue[k]; });
    return pointerValue;
  } catch (error) {
    return undefined;
  }
};

const setNestedValue = (key, obj, newObj, merge = false) => {
  try {
    const keys = key.split('.');
    const lastKey = keys.pop();
    let pointer = obj;
    keys.forEach((k) => { pointer = pointer[k]; });
    if (merge && typeof pointer[lastKey] === 'object' && typeof newObj === 'object') {
      _.merge(pointer[lastKey], newObj);
    } else {
      pointer[lastKey] = newObj;
    }
  } catch (error) {
    // Do nothing
  }
};

export default class UserLocalStorage {
  constructor() {
    /* ************ Auto Runner ************* */
    session.afterAuthentication(() => {
      this._setLocalStorageKey(session.getUser().user_id);
    });
    session.afterLogout(() => {
      this._setLocalStorageKey(null);
    });
    if (session.isAuthenticated()) {
      this._setLocalStorageKey(session.getUser().user_id);
    } else {
      this._setLocalStorageKey(null);
    }
    /* ************************************** */
  }

  /**
   * Replace settings
   * @param String key, (support nested)
   * @param Any value
   */
  setSettings(key, value) {
    if (key) {
      const allSettings = this._storeGetSettings() || {};
      setNestedValue(key, allSettings, value);
      this._storeSetSettings(allSettings);
    }
  }

  /**
   * Merge settings
   * @param String key, (support nested)
   * @param Any value
   */
  updateSettings(key, value) {
    if (key) {
      const allSettings = this._storeGetSettings() || {};
      setNestedValue(key, allSettings, value, true);
      this._storeSetSettings(allSettings);
    }
  }

  /**
   * @param String key, (support nested)
   * @param Any value
   */
  getSettings(key) {
    const allSettings = this._storeGetSettings();
    return key ? getNestedValue(key, allSettings) : allSettings;
  }

  _storeGetSettings() {
    if (!this.localStorageKey) return;
    try {
      return JSON.parse(localStorage.getItem(this.localStorageKey));
    } catch (error) {
      console.log(error);
      // Delete if error
      localStorage.removeItem(this.localStorageKey);
      return undefined;
    }
  }

  _storeSetSettings(payload) {
    if (!this.localStorageKey) return;
    try {
      localStorage.setItem(this.localStorageKey, JSON.stringify(payload));
    } catch (error) {
      console.log(error);
    }
  }

  _setLocalStorageKey(userId) {
    this.userId = userId;
    this.localStorageKey = userId ? `userSettings-${this.userId}` : null;
  }
}
