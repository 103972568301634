import { Field } from '@sh/configurations/fields/Field';
import { RuleTypes } from '@sh/types';

export const mapCampaignField = (field: Field): Field => ({
  ...field,
  group: 'campaign',
  baseModel: RuleTypes.ConditionsV2.BaseModel.Campaign,
  text: `Campaign - ${field.text}`,
  availableOnLevel: field.availableOnLevel || [
    'Widget',
    'App',
    'Content',
    'AdGroup',
    'Target',
    'Site',
    'Exchange',
    'Section',
    'Domain',
    'Spot',
  ],
  key: `campaign_${field.key}`,
  value: { ...field.value, key: `campaign_${field.value.key}` },
});

export const budgetConfigValid = (min: string | number, max: string | number, value: string | number): boolean => {
  const parsedMin = typeof min === 'string' ? parseFloat(min) : min;
  const parsedMax = typeof max === 'string' ? parseFloat(max) : max;
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;

  return parsedMin > parsedMax || (parsedMin === parsedMax && parsedValue !== parsedMin);
};
