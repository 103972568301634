<template>
  <div class="content-wrapper manual-stats-update-container has-fixed-footer">

    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Stats Update</h1>
        <sub v-if="currentStep === 0" v-b-tooltip.hover title="Timezones are in UTC">(UTC) <i class="fa fa-info-circle"></i></sub>
        <p v-else class="section-header__content__subtitle">
          <a :href="sectionLink" target="_blank">Learn More <i class="fa fa-angle-right ml-1"></i></a>
        </p>
      </div>
    </div>

    <b-tabs v-model="currentStep" class="manual-stats-update-container__tabs">

      <!-- Automated Stats Update -->
      <b-tab title="Scheduled Stats Update" active>
        <custom-stats-update></custom-stats-update>
      </b-tab>

      <!-- Manual Stats Update -->
      <b-tab title="Manual Stats Update">
        <manual-stats-update></manual-stats-update>
      </b-tab>

      <!-- Upload Csv -->
      <b-tab title="CSV Stats Update">
        <upload-csv></upload-csv>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import UploadCsv from '@sh/components/ManualStatsUpdate/UploadCsv';
import CustomStatsUpdate from '@sh/components/ManualStatsUpdate/CustomStatsUpdate';
import ManualStatsUpdate from '@sh/components/ManualStatsUpdate/ManualStatsUpdate';

export default {
  name: 'ManualStats',
  components: { UploadCsv, CustomStatsUpdate, ManualStatsUpdate },
  data() {
    return {
      currentStep: 0,
    };
  },
  computed: {
    sectionLink() {
      if (this.currentStep === 1) {
        return 'https://theoptimizer.io/help/knowledge-base/using-manual-stats-update';
      } if (this.currentStep === 2) {
        return 'https://theoptimizer.io/help/knowledge-base/how-to-manually-upload-data-using-a-csv-file';
      }
    },
  },
};
</script>

<style lang="scss">
.manual-stats-update-container {
  &.has-fixed-footer {
    padding-bottom: 6.4rem;
  }

  .section-header {
    margin-bottom: 3rem;
    sub {
      margin-top: 1rem;
      margin-left: 1rem;
      color: $black-500;
    }
  }

  &__tabs {
    .nav-tabs {
      margin-bottom: 2.5rem;
      border: none;

      &:first-of-type {
        .nav-link {
          padding-left: 0;
        }
      }

      .nav-link {
        background: none;
        border: none;
        margin-right: 2rem;
        padding: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: $text-primary;
        border-bottom: .2rem solid transparent;

        &.active {
          color: $color-blue;
          border-bottom: .2rem solid $color-blue;
        }
      }
    }
  }
}
</style>
