import { SearchFeed } from '@sh/types';

export default class UserFilter {
  constructor(public availableOptions?: SearchFeed.User[], private selected: string | null = null) {}

  get options(): SearchFeed.User[] {
    return this.availableOptions ?? [];
  }

  get selectedOption(): SearchFeed.User | null {
    return this.options.find((option) => this.value === option.email) ?? null;
  }

  set selectedOption(option: SearchFeed.User | null) {
    this.selected = option?.email ?? null;
  }

  get value(): string | null {
    return this.selected;
  }

  set value(value: string | null) {
    this.selected = value;
  }

  get selectedData(): Record<'email' | 'id', string> {
    return {
      id: this.userIds?.join(',') ?? '',
      email: this.value ?? '',
    };
  }

  get userIds(): number[] | null {
    return this.options.filter((item) => item.email === this.value).map((item) => item.id) ?? null;
  }
}
