
import RuleMetricCalculation from '@sh/components/Utils/RuleMetricCalculation.ts.vue';
import { budgetConfigValid } from '@sh/configurations/fields/helpers';
import notifications from '@sh/mixins/notifications';
import { RuleTypes } from '@sh/types';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  localProps: { title: string | null };
  calculatedMinVal: string;
  calculatedMaxVal: string;
}

const VueComponent = Vue as VueConstructor<Vue & RuleTypes.Bulk.RuleBulkModalRefs>;

export default VueComponent.extend({
  name: 'RuleBulkModal',
  components: {
    RuleMetricCalculation,
  },
  mixins: [notifications],
  props: {
    items: { type: Array as PropType<RuleTypes.Bulk.Item[]>, required: true },
    handler: { type: Function, required: true },
    options: { type: Array, default: () => [] },
    level: { type: String as PropType<RuleTypes.Bulk.Levels>, required: true },
    bulkConfig: { type: Object as PropType<RuleTypes.Bulk.BulkConfig>, required: true },
    bulkAction: { type: String, required: true },
  },
  data(): ComponentData {
    return {
      localProps: {
        title: '',
      },
      calculatedMinVal: '',
      calculatedMaxVal: '',
    };
  },
  computed: {
    $c_getTitle(): string {
      const title = this.bulkConfig?.entities[this.level]?.bulkActions[this.bulkAction]?.title || 'Bulk Update';
      return `${title} ( ${this.items.length} items will be affected )` || 'Bulk Update';
    },
  },
  methods: {
    validateMinMax(form: any) {
      const checkToField = !['budget', 'daily_budget'].includes(
        this.bulkConfig?.entities?.[this.level]?.bulkActions?.[this.bulkAction]?.type
      );

      if (form.unit === 'percentage' || form.unit === 'static') {
        if (form.action === 'set') {
          if (form.unit === 'percentage' && checkToField) {
            return !!form.to.length;
          }
          return true;
        }
        if (form.action === 'increase' || form.action === 'decrease') {
          let valid = true;
          if (form.unit === 'percentage' && checkToField) {
            valid = !!form.to.length;
          }
          if (
            (form.minObject.to.length === 0 && form.minObject.unit === 'percentage') ||
            (form.maxObject.to.length === 0 && form.maxObject.unit === 'percentage')
          ) {
            return false;
          }
          return valid;
        }

        return true;
      }
      return true;
    },
    async $_submit(bvModalEvt: any) {
      const field = this.$refs?.ruleMetricCalculation?.$c_getField;
      (this.$refs?.ruleMetricCalculation as any)?.$_dismissAlert();
      const form = this.$refs?.ruleMetricCalculation?.form;
      const validMinMax = this.validateMinMax(form);
      const { valid, where } = this.$refs.ruleMetricCalculation.$_isValid();
      if (!validMinMax) {
        bvModalEvt.preventDefault();
        this.$refs?.ruleMetricCalculation?.$_showInlineError('danger', 'Please select all options');
        return;
      }

      if (!valid) {
        bvModalEvt.preventDefault();
        this.$refs?.ruleMetricCalculation?.$_showInlineError('danger', `Please fill ${where}`);
      } else {
        const finalValues = this.$_calculateFinalValue();
        if (this.calculatedMinVal && this.calculatedMaxVal) {
          if (budgetConfigValid(this.calculatedMinVal, this.calculatedMaxVal, form.value)) {
            bvModalEvt.preventDefault();
            this.$refs?.ruleMetricCalculation?.$_showInlineError(
              'danger',
              'Please check the value for the budgets. Min budget cannot be greater or equal than max budget.'
            );
            return;
          }
        }

        if (finalValues.length > 0) {
          this.handler(finalValues, field);
        }
        this.$refs.ruleMetricCalculation.alert.message = '';
        this.$refs.ruleMetricCalculation.$_clear();
        this.hide();
      }
    },
    $_calculateFinalValue(): Array<RuleTypes.Bulk.FinalValues> {
      const finalValues: Array<RuleTypes.Bulk.FinalValues> = [];
      this.items.forEach((item: any) => {
        const skipMin = this.$refs.ruleMetricCalculation.$c_skipMin;
        const { calculatedMetric, calculatedMinVal, calculatedMaxVal, isNullCurrentField, current, columnName } =
          this.$refs.ruleMetricCalculation.$_getCalculatedMetric(item);
        this.calculatedMaxVal = calculatedMaxVal;
        this.calculatedMinVal = calculatedMinVal;
        const key = `traffic_source_${this.level.toLowerCase()}_id`;
        if (isNullCurrentField) {
          finalValues.push({
            // md5
            id: item.id,
            traffic_source_type: this.bulkConfig.name,
            campaign_id: item.campaign_id,
            [key]: item[key as 'traffic_source_campaign_id'],
            value: skipMin ? calculatedMinVal : calculatedMetric || 0,
            action: this.bulkAction,
            level: this.level,
            name: item.name,
            budget: item.budget,
            type: 'error',
            current,
            columnName,
            daily_budget: item.daily_budget,
            searchBid: item?.cpc_search || 0,
            budget_type: item?.budget_type || '',
            publisher_id: item?.publisher_id,
          });
        } else {
          finalValues.push({
            // md5
            id: item.id,
            traffic_source_type: this.bulkConfig.name,
            campaign_id: item.campaign_id,
            [key]: item[key as 'traffic_source_campaign_id'],
            value: calculatedMetric,
            action: this.bulkAction,
            level: this.level,
            name: item.name,
            current,
            columnName,
            budget: item.budget,
            type: this.$refs.ruleMetricCalculation.$c_filterBy,
            daily_budget: item.daily_budget,
            searchBid: item?.cpc_search || 0,
            budget_type: item?.budget_type || '',
            publisher_id: item?.publisher_id,
          });
        }
      });
      return finalValues;
    },
    show(props: any) {
      if (props)
        Object.keys(this.localProps).forEach((key) => {
          if (props[key]) this.localProps[key as 'title'] = props[key];
        });
      this.$refs.createBulkModal.show();
    },
    $_getProp(name: string) {
      return this.localProps[name as 'title'] || this.bulkConfig[name as 'name'];
    },
    async $_handleOk(bvModalEvt: any) {
      await this.$_submit(bvModalEvt);
    },
    $_handleClose() {
      Object.keys(this.localProps).forEach((key) => {
        this.localProps[key as 'title'] = null;
      });
      (this.$refs as any)?.ruleMetricCalculation?.$_clear();
    },
    hide() {
      this.$refs.createBulkModal.hide();
    },
  },
});
