
import FieldTooltip from '@sh/components/Utils/FieldTooltip.vue';
import { pluralize, RuleHelpers } from '@sh/helpers';
import { RuleTypes } from '@sh/types';
import CustomInterval from '@sh/views/Rules/components/subcomponents/CustomInterval/CustomInterval.ts.vue';
import { useRuleStore } from '@sh/views/Rules/store';
import { cloneDeep, isEqual, uniqBy } from 'lodash';
import moment from 'moment';
import { mapState } from 'pinia';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  selected: RuleTypes.ConditionsV2.Option[];
  isCustomDateInterval: boolean;
  touched: boolean;
  dateOperations: RuleTypes.ConditionsV2.DateOperations[];
}

interface ComponentRefs {
  $refs: {
    dateConditionField: VueOptiSelect;
  };
}

type ComponentTypes = VueConstructor<Vue & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  model: {
    prop: 'value',
    event: 'onChange',
  },
  components: {
    FieldTooltip,
    CustomInterval,
  },
  props: {
    options: {
      type: Array as PropType<RuleTypes.Conditions.Option[]>,
      required: true,
    },
    groups: {
      type: Array as PropType<RuleTypes.Conditions.Group[]>,
      default: () => [],
    },
    value: Object as PropType<NullableType<RuleTypes.ConditionsV2.Option>>,
    name: String,
    preventOverflowRight: Boolean,
    preventOverflowTop: {
      type: Boolean,
      default: true,
    },
    validateType: {
      type: String,
      default: 'Type',
    },
    validatePath: String,
    emptyButtonPlaceholder: {
      type: String,
      default: 'Select Option',
    },
    watchValue: Boolean,
  },
  computed: {
    isDateIntervalSwitch(): boolean {
      return !!this.selected.find((data) => data?.baseType === RuleTypes.Conditions.BaseFieldType.Metric);
    },
    dataIntervalDisabledText(): string {
      if (this.canAccessCustomInterval) {
        return this.dataIntervalMetricFieldDisabledText;
      }
      return 'This option is not available on your current plan. Please contact support for upgrade options.';
    },
    dataIntervalMetricFieldDisabledText(): string {
      if (this.isDateIntervalSwitch) {
        return '';
      }
      return 'Date intervals are available when a metric type field is selected.';
    },
    intervalText(): string {
      const selectedOption = this.customIntervalPresets.find((option) => isEqual(option.value, this.dateOperations));

      if (selectedOption) {
        return selectedOption.text;
      }

      if (this.dateOperations[0] && this.dateConditionLabels[0] === this.dateConditionLabels[1]) {
        return `From ${this.dateOperations[0].value} days ago`;
      }

      return `Between ${this.dateConditionLabels.join(' and ')}`;
    },
    selectedFieldName(): string {
      return this.selected?.[0]?.text ?? '';
    },
    radioTypeOptions(): (RuleTypes.Conditions.Option & { inputType: string })[] {
      return this.options.map((item) => ({ ...item, inputType: 'radio' }));
    },
    dateConditionLabels(): string[] {
      return this.dateOperations.map((item) => `${item.value} ${pluralize(item.value, 'day')} ago`);
    },
    errorText(): string | undefined {
      return this.touched ? this.$validator.errors.first(this.name) : undefined;
    },
    disabledOptions(): RuleTypes.Conditions.Option[] {
      return this.options.filter((item) => item.disabled);
    },
    uniqueKeyFunction() {
      return RuleHelpers.ConditionsV2.getOptionValue;
    },
    buttonPlaceholderTooltip(): string {
      try {
        const dateConditionsText = this.value?.interval
          ?.map((item) => RuleHelpers.ConditionsV2.getDateOperationValue(item))
          .map((item) => moment().subtract(-item, 'days').format('DD-MM-YYYY'))
          .join(' to ');

        if (dateConditionsText) {
          return `Example: <br> If rule is being executed now, Impressions will be calculated from: <div>${dateConditionsText}.</div>`;
        }
      } catch {
        /* Do nothing */
      }

      return '';
    },
    ...mapState(useRuleStore, [
      'customDataDefaultInterval',
      'canAccessCustomInterval',
      'config',
      'customIntervalPresets',
    ]),
  },
  watch: {
    errorText() {
      this.$emit('onErrorUpdate', { [this.name]: this.errorText });
    },
    value() {
      if (this.watchValue) {
        this.onReset();
      }
    },
  },
  data(): ComponentData {
    return {
      selected: [],
      isCustomDateInterval: false,
      touched: false,
      dateOperations: [],
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    async validate() {
      try {
        this.touched = true;
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    onReset() {
      this.isCustomDateInterval = !!this.value?.interval;
      this.dateOperations = cloneDeep(this.value?.interval ?? this.customDataDefaultInterval);
      this.selected = this.options.filter(
        (item) => this.value && this.uniqueKeyFunction(item) === this.uniqueKeyFunction(this.value)
      );

      if (this.groups.length) {
        const uniqOptionsByGroup = uniqBy(this.options, 'group');

        if (uniqOptionsByGroup.length === 1) {
          if (
            this.$refs.dateConditionField &&
            !this.$refs.dateConditionField.groupsVisibleState[uniqOptionsByGroup[0].group]
          ) {
            this.$refs.dateConditionField.$_collapseGroup(uniqOptionsByGroup[0].group);
          }
          return;
        }

        this.selected.forEach((selected) => {
          if (this.$refs.dateConditionField && !this.$refs.dateConditionField.groupsVisibleState[selected.group]) {
            this.$refs.dateConditionField.$_collapseGroup(selected.group);
          }
        });
      }
    },
    async onDropdownShow() {
      this.onReset();
      await this.$nextTick();
      document.querySelector('.date-condition-field .options-list')?.scrollTo({ top: 0 });
    },
    onSaveClick() {
      this.touched = true;

      if (this.selected.length) {
        const [data] = cloneDeep(this.selected);

        if (this.isCustomDateInterval && this.canAccessCustomInterval) {
          data.interval = this.dateOperations;
        }
        this.$emit('onChange', data);
      }
    },
    onItemChange() {
      this.isCustomDateInterval = this.isCustomDateInterval && this.isDateIntervalSwitch;
    },
  },
});
