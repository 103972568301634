import { RuleTypes } from '@sh/types';

const targetRoasActionOptions = [
  { value: 'set', text: 'Set Target Roas To' },
  { value: 'increase', text: 'Increase Target Roas By' },
  { value: 'decrease', text: 'Decrease Target Roas By' },
];

const targetRoasUnitOptions = [
  { value: 'static', text: '%' },
  { value: 'percentage', text: '% of' },
];

const targetRoasToOptions = [
  {
    value: 'target_cpa',
    text: 'of Current Target Roas (Campaign)',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'roas_bid',
    text: 'of Current Target Roas (Adgroup)',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  // { value: 'ts_cpa', text: 'of CPA' },
  // { value: 'payout', text: 'of Payout' },
];

const targetRoasModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              roas_bid: {},
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              roas_bid: {},
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              roas_bid: {},
            },
          },
        },
      },
    },
  },
};



export default {
  async getData() {
    const targetRoasModelTemplatesClone = JSON.parse(JSON.stringify(targetRoasModelTemplates));
    const targetRoasToOptionsClone = JSON.parse(JSON.stringify(targetRoasToOptions));
    return {
      targetRoasActionOptions,
      targetRoasUnitOptions,
      targetRoasToOptions: targetRoasToOptionsClone,
      targetRoasModelTemplates: targetRoasModelTemplatesClone,
    };
  },
};
