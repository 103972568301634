<template>
  <div class="register-subuser-container d-flex justify-content-center align-items-center">
    <div class="register-subuser-wrapper">
      <div class="optimizer-logo-container">
        <img
          class="logo-white"
          :src="`sh_static/header/optimizer-${$platform}-logo.svg`"
          alt="Logo"
        >
      </div>
      <div class="optimizer-card">
        <h5 class="register-subuser-title">Subuser Register</h5>
        <hr class="m-0">
        <b-form class="register-subuser-form" @submit.prevent="$_submit">
          <!-- Email -->
          <b-form-group
            label="Email"
            class="optimizer-form-group mb-3"
            :invalid-feedback="$_getError('email')"
            :state="$_hasError('email')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Email"
              :state="$_hasError('email')"
              v-validate="'required'"
              type="email"
              v-model="model.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>

          <!-- Username -->
          <b-form-group
            label="Username"
            class="optimizer-form-group mb-3"
            :invalid-feedback="$_getError('username')"
            :state="$_hasError('username')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Username"
              :state="$_hasError('username')"
              v-validate="'required'"
              type="text"
              v-model="model.username"
              name="username"
              placeholder="Username"
            />
          </b-form-group>

          <!-- First Name -->
          <b-form-group
            label="First Name"
            class="optimizer-form-group mb-3"
            :invalid-feedback="$_getError('firstname')"
            :state="$_hasError('firstname')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="First name"
              :state="$_hasError('firstname')"
              v-validate="'required'"
              type="text"
              v-model="model.firstname"
              name="firstname"
              placeholder="First Name"
            />
          </b-form-group>

          <!-- Last Name -->
          <b-form-group
            label="Last Name"
            class="optimizer-form-group mb-3"
            :invalid-feedback="$_getError('lastname')"
            :state="$_hasError('lastname')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Last Name"
              :state="$_hasError('lastname')"
              v-validate="'required'"
              type="text"
              v-model="model.lastname"
              name="lastname"
              placeholder="Last Name"
            />
          </b-form-group>

          <!-- Password -->
          <b-form-group
            label="Password"
            class="optimizer-form-group mb-3"
            :invalid-feedback="$_getError('password')"
            :state="$_hasError('password')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Password"
              :state="$_hasError('password')"
              v-validate="'required|min:6'"
              type="password"
              v-model="model.password"
              name="password"
              placeholder="Password"
              ref="password"
            />
          </b-form-group>

          <!-- Confirm Password -->
          <b-form-group
            label="Confirm Password"
            class="optimizer-form-group mb-4"
            :invalid-feedback="$_getError('confirmPassword')"
            :state="$_hasError('confirmPassword')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Confirm Password"
              :state="$_hasError('confirmPassword')"
              v-validate="'required|min:6|confirmed:password'"
              type="password"
              v-model="model.confirmPassword"
              name="confirmPassword"
              placeholder="Password"
            />
          </b-form-group>

          <!-- <b-form-input v-model="model.email" disabled class="mt-2"></b-form-input> -->
          <!-- <b-form-input v-model="model.username" placeholder="Username" class="mt-2"></b-form-input> -->
          <!-- <b-form-input v-model="model.firstname" placeholder="First Name" class="mt-2"></b-form-input>
                  <b-form-input v-model="model.lastname" placeholder="Last Name" class="mt-2"></b-form-input> -->
          <!-- <b-form-input v-model="model.password" type="password" placeholder="Password" class="mt-2"></b-form-input>
                  <b-form-input v-model="model.confirmPassword" type="password" placeholder="Confirm Password" class="mt-2"></b-form-input> -->

          <div class="submit-btn-container">
            <b-button type="submit" class="w-100 primary-button" :disabled="loading">
              <span v-if="loading" class="mr-2"><i class="fa fa-spinner fa-spin"></i>  </span>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>

</template>
<style .scoped>
    .bg-blue {
        color: white;
        background: #2CA8D6 !important;
    }
</style>
<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'SubuserRegister',
  mixins: [notifications],
  data: () => ({
    model: {
      token: '',
      email: '',
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      confirmPassword: '',
    },
    loading: false,
  }),
  // watch: {
  //   '$route.query': (params) => {
  //     this.model.token = params.token;
  //     this.model.email = params.email;
  //   },
  // },
  created() {
    const { token, email } = this.$route.query;
    if (!token || !email) {
      this.$n_failNotification({ title: 'Invalid form' });
    }
    this.model.token = token;
    this.model.email = email;
  },
  methods: {
    async $_submit() {
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          this.loading = true;
          // Submit
          const modelCopy = { ...this.model };
          delete modelCopy.confirmPassword;
          try {
            await this.$api.invitation.registerFromInvitation(modelCopy);
            this.$n_successNotification({ title: `"${modelCopy.email}" successfully registered!` });
            if (this.$session.isAuthenticated()) this.$session.removeToken();
            this.$router.push({ name: 'Login' });
          } catch (error) {
            try {
              this.$n_failNotification({ title: error.response.data.message });
            } catch (err) {
              this.$n_failNotification({ title: 'An error occurred' });
            }
          }
          this.loading = false;
        } else {
          this.$n_warnNotification({ title: 'Invalid form' });
        }
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
  },
};
</script>
<style lang="scss">
.register-subuser-container {
  min-height: 100vh;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  .optimizer-logo-container {
    text-align: center;
    margin-bottom: 3rem;
    img {
      width: 17rem;
    }
  }

  .optimizer-card {
    max-width: calc(100vw - 4rem);
    width: 50rem;

    .register-subuser-title {
      font-size: 1.8rem;
      padding: 1.5rem;
      text-align: center;
    }

    .register-subuser-form {
      padding: 2rem;
    }
  }
}
</style>
