<template>
  <div>
    <b-form-group :state="$_hasError('applyToCountryBids')" class="h-display-sm campaign-form">
      <b-input-group>
        <b-input-group-addon class="spaced-label">
          Apply rule to country bids for:
        </b-input-group-addon>
        <traffic-source-select
          v-model="applyToCountryBids"
          :options="options.applyToCountryBids"
          name="applyToCountryBids"
          max-height="200px !important"
          data-vv-as="applyToCountryBids"
          unique-name="traffic_source_unique_name"
          @getTSList="$_getValidTSList"
        /> <!-- -->
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('applyToCountryBids') }}</small>
      <span slot="description" v-if="cmp_config.info" class="text-info" v-html="cmp_config.info"></span>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import TrafficSourceSelect from '@sh/components/Utils/trafficsourceselect/TrafficSourceSelect';

export default {
  name: 'RuleCampaigns',
  components: { TrafficSourceSelect },
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    applyToCountryBidOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      applyToCountryBids: [],
      options: {
        applyToCountryBids: [],
      },
      ts_types: [],
      cmp_config: this.ruleModel.rule.components.applyToCountryBids,
    };
  },
  // computed: {
  //   getValidTSList() {
  //     const countryBidTS = ['Evadav'];
  //     const campaignTSList = this.ruleModel.rule.components.campaigns.value || [];
  //     const validTS = campaignTSList.map((campaign) => campaign.ts_type);
  //     this.options.applyToCountryBids = countryBidTS.filter((item) => validTS.includes(item));
  //   },
  // },
  watch: {
    applyToCountryBids: {
      immediate: true,
      handler() {
        this.$_emit('applyToCountryBids');
      },
      deep: true,
    },
  },
  created() {
    this.applyToCountryBids = this.value;
    this.options.applyToCountryBids = this.$_getValidTSList();
  },
  methods: {
    $_getValidTSList() {
      const countryBidTS = ['Evadav', 'Kadam'];
      const campaignTSList = this.ruleModel.rule.components.campaigns.value || [];
      const validTS = campaignTSList.map((campaign) => campaign.ts_type);
      this.options.applyToCountryBids = countryBidTS.filter((item) => validTS.includes(item));
    },
  },
};
</script>

<style lang="scss">
  .spaced-label {
    min-width: 205px !important;
  }
</style>
