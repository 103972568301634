import userLocalStorage from '@sh/services/userLocalStorage';
import { getDateRangePresetsInstance } from '@sh/components/Utils/DateRange/DateRangePresets';

const storageKey = 'tracker-daterange';

export default class TrackerPerformanceFilter {
  getDateRange() {
    const dateRange = this._getData();
    if (!dateRange) {
      return null;
    }
    if (dateRange.startDate && dateRange.endDate) {
      return dateRange;
    }
    return null;
  }

  setDateRange(payload) {
    // Validate before saving
    if (payload.startDate && payload.endDate) {
      this._setData(payload);
    }
  }

  _getData() {
    const data = userLocalStorage.getSettings(storageKey) || {};
    try {
      const dateRangePresets = getDateRangePresetsInstance();
      const newData = { dateRange: data };
      dateRangePresets.convertFromLocalStorage(newData);
      return newData.dateRange;
    } catch { /* Do Nothing */ }
    return data;
  }

  _setData(payload) {
    const payloadClone = { dateRange: JSON.parse(JSON.stringify(payload)) };
    try {
      const dateRangePresets = getDateRangePresetsInstance();
      dateRangePresets.convertToLocalStorage(payloadClone);
    } catch { /* Do Nothing */ }
    userLocalStorage.setSettings(storageKey, payloadClone.dateRange);
  }
}
