<template>
  <div class="apply-rule-to-wrapper">
    <b-form-group
      :label="$c_label"
      :state="$_hasError('entitySelector')"
      :class="`h-display-sm form optimizer-form-group ${validationError ? 'is-invalid' : ''}`"
    >
      <b-input-group>
        <ad-group-select-light
          v-model="entitySelector"
          class="optimizer-select"
          name="entitySelector"
          v-validate="'required'"
          data-vv-as="EntityIds"
          :traffic-source-filter="ts_types"
          :custom-filter="$c_customFilter"
          :archived="false"
          :label="label"
          managed
          @input="$_processInput"
        />
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('entitySelector') }}</small>
      <small v-if="validationError" class="d-block invalid-feedback">The {{ label }}s field is required</small>
      <span slot="description" v-if="cmp_config.info" class="text-info" v-html="cmp_config.info"></span>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import AdGroupSelectLight from '../../../components/Utils/EntitySelect/AdGroupSelectLight.vue';

export default {
  name: 'AdGroupSelector',
  components: { AdGroupSelectLight },
  mixins: [component],
  props: {
    // value: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({ entities: [], type: { value: null, content: 'Select Filter' } }) },
    filter: { type: Object, default: () => ({ trackerId: null }) },
    label: { type: String, default: () => 'AdGroup' },
    isError: { type: Boolean, default: () => false },
  },
  data() {
    return {
      entitySelector: { entities: [], type: null },
      ts_types: [],
      cmp_config: this.ruleModel.rule.components.entitySelector,
      validationError: this.isError,
    };
  },
  computed: {
    $c_customFilter() {
      return this.cmp_config.filter || null;
    },
    $c_label() {
      return `Apply Rule to ${this.label}s`;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        if (filter.trackerId) {
          this.entitySelector = this.entitySelector.filter((item) => `${item.tracker_account_id}` === `${filter.trackerId}`);
          this.$_emit('widgetsExclude');
        }
      },
    },
  },
  created() {
    this.entitySelector = this.value;
    this.ts_types = this.ruleModel.rule.ts_types;
    this.$_emit('widgetsExclude');
  },
  methods: {
    $_processInput(input) {
      try {
        this.validationError = input.type.value !== null && input.entities.length === 0;
        this.$_emit('entitySelector');
      } catch (error) { /* Do Nothing */ }
    },
  },
};
</script>

<style lang="scss">
.apply-rule-to-wrapper {
  .campaign-form {
    margin-bottom: 0;
  }
  .optimizer-select-type {
    min-width: 15rem;
    margin-right: 1.5rem;
    padding-right: 0;
  }
  .input-group-append {
    position: relative;

    .dropdown {
      height: 3.7rem;

      .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          margin-right: .5rem;
        }

        &::after {
          margin-left: .7rem;
        }
      }

      .dropdown-menu {
        top: unset !important;
        bottom: -6rem !important;
      }
    }
  }

  .dropdown-toggle {
    min-height: 3.7rem;
    border-top-left-radius: .6rem !important;
    border-bottom-left-radius: .6rem !important;
  }
}
</style>
