<template>
  <div class="preview-form-wrapper">
    <div class="preview-form-container">
      <div class="preview-variations-container">
        <div class="variations-title">
          <h3>A Total of {{ localVariations.length }} {{ localVariations.length ? 'Campaigns' : 'Campaign' }} Will Be Created!</h3>
        </div>

        <div v-for="(v, index) in localVariations" :key="index" class="variation">
          <div class="variation-title">
            <h3>{{ v.label || `${capitalizeFirstLetter(stringifyNumber(index + 1))} Variation` }}</h3>
          </div>
          <div class="variation-card optimizer-card">
            <div class="row">
              <template v-for="(component, cIndex) in v.components">
                <div :class="[ component.component === 'cc-rule-select' ? 'col-md-12' : 'col-md-6']" :key="cIndex">
                  <component-wrapper v-if="component.active" v-bind="$_getLabelProps(component)" :key="component.name">
                    <component :is="component.component" v-bind="$_getProps(component)" v-model="component.value" />
                  </component-wrapper>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import props from '../../mixins/props';
import helpers from '../../mixins/helpers';
import _ from 'lodash';
import componentWrapper from '../../components/ComponentWrapper';
import ccInput from '../../components/ccInput';
import ccRadioButton from '../../components/ccRadioButton';
import ccCheckbox from '../../components/ccCheckbox';
import ccRuleSelect from '../../components/ccRuleSelect';
import ccMultiSelect from '../../components/ccMultiSelect';

export default {
  name: 'PreviewForm',
  components: {
    componentWrapper,
    ccInput,
    ccRadioButton,
    ccCheckbox,
    ccRuleSelect,
    ccMultiSelect,
  },
  mixins: [props, helpers],
  props: {
    model: { type: [Object, null], default: null },
    variationsModel: { type: Array, default: () => [] },
  },
  data() {
    return {
      localVariations: [],
    };
  },
  created() {
    this.$watch('variationsModel', (variations) => {
      this.localVariations = [];
      variations.forEach((variation) => {
        const copy = _.cloneDeep(variation);
        copy.components.forEach((cmp) => {
          cmp.disabled = true;
        });
        this.localVariations.push(copy);
      });
    }, { deep: true, immediate: true });
  },
  methods: {
    validate() {
      return this.localVariations.length > 0;
    },
    $_getLabelProps(component) {
      return { label: component.label, popover: component.popover, disableLabel: !!component.disableLabel };
    },
    $_getProps(component) {
      return {
        ref: component.name, mode: this.mode, retry: this.retry, config: this.config, model: component, key: name,
      };
    },
  },
};
</script>

<style lang="scss">
.preview-form-wrapper {
  .preview-variations-container {
      &:first-child {
        margin-bottom: 0;
      }

      .variation {
        margin-top: 4rem;

        &-card {
          padding: 1.5rem 2.5rem;
        }
      }
  }
}
</style>
