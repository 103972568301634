/* eslint-disable no-unused-vars */
import { api } from '@sh/services/api';

const options = {
  taboolaPlatforms: [
    { text: 'Desktop', value: 'DESK' },
    { text: 'Tablet', value: 'TBLT' },
    { text: 'Mobile', value: 'PHON' },
  ],
  revContentPlatformOptions: [
    { text: 'Desktop', value: '1' },
    { text: 'Mobile', value: '2' },
    { text: 'Tablet', value: '3' },
  ],
  revContentOsOptionsDesktop: [
    { text: 'macOS', value: '7' },
    { text: 'Windows', value: '8' },
  ],
  revContentOsOptionsMobile: [
    { text: 'Android', value: '4' },
    { text: 'iOS', value: '5' },
    { text: 'Windows', value: '6' },
  ],
  outbrainPlatformOptions: [
    { text: 'Desktop', value: 'DESKTOP' },
    { text: 'Mobile', value: 'MOBILE' },
    { text: 'Tablet', value: 'TABLET' },
  ],
  contentAdCpcOptions: [
    { text: 'Desktop', value: 'cpc_desktop' },
    { text: 'Mobile', value: 'cpc_mobile' },
  ],
  geminiGenderOptions: [
    { name: 'All', value: 'ALL' },
    { name: 'Male', value: 'MALE' },
    { name: 'Female', value: 'FEMALE' },
  ],
  geminiAgeOptions: [
    { name: 'All', value: 'ALL' },
    { name: '18-24', value: '18-24' },
    { name: '25-34', value: '25-34' },
    { name: '35-44', value: '35-44' },
    { name: '45-54', value: '45-54' },
    { name: '55-64', value: '55-64' },
    { name: '65+', value: '65-120' },
  ],
  geminiDeviceOptions: [
    { name: 'All computers and devices', value: 'ALL' },
    { name: 'Mobile devices only', value: 'MOBILE' },
  ],
};

export default {
  async getConfig() {
    const rules = await api.rules.index();
    const variations = {
      Taboola: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required',
          },
          {
            active: true, name: 'platform_targeting', component: 'cc-checkbox', value: ['DESK', 'TBLT', 'PHON'], label: 'Platform', 'v-validate': 'required', options: options.taboolaPlatforms,
          },
          {
            active: true, name: 'cpc', component: 'cc-input', value: '', label: 'Bid Amount', 'v-validate': 'required|min_value:0', left: 'USD', right: 'per click', manageState({ component }, campaign) { if (campaign?.traffic_source_unique_name === 'Taboola' && campaign?.bid_strategy?.value === 'MAX_CONVERSIONS' && component.name === 'cpc') { component.disabled = true; } },
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = {};
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          if (cmpMap.platform_targeting.active) {
            form.platform_targeting = { type: 'INCLUDE', value: cmpMap.platform_targeting.value };
          }
          if (cmpMap.cpc.active) form.cpc = cmpMap.cpc.value;
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
      RevContent: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Boost Name', 'v-validate': 'required',
          },
          // eslint-disable-next-line object-property-newline
          {
            active: true,
            name: 'platform_targeting',
            component: 'cc-checkbox',
            value: ['1', '2', '3'],
            label: 'Device Targeting',
            'v-validate': 'required',
            options: options.revContentPlatformOptions,
            events: {
              input: ({ components, component }, value) => {
                const platform_targeting_value_map = {};
                value.forEach((val) => { platform_targeting_value_map[val] = true; });

                const osTargetingDesktop = components.find((cmp) => cmp.name === 'os_targeting_desktop');
                const osTargetingMobile = components.find((cmp) => cmp.name === 'os_targeting_mobile');

                // Check Desktop platforms
                if (platform_targeting_value_map['1']) {
                  osTargetingDesktop.active = true;
                } else {
                  osTargetingDesktop.active = false;
                }

                // Check Mobile platforms
                if (platform_targeting_value_map['2'] || platform_targeting_value_map['3']) {
                  osTargetingMobile.active = true;
                } else {
                  osTargetingMobile.active = false;
                }
              },
            },
            init({ components, component }) {
              component.events.input({ components, component }, component.value);
            },
          },
          {
            active: true, name: 'os_targeting_desktop', component: 'cc-checkbox', value: ['7', '8'], label: 'Operating System ( Desktop )', 'v-validate': 'required', options: options.revContentOsOptionsDesktop,
          },
          {
            active: true, name: 'os_targeting_mobile', component: 'cc-checkbox', value: ['4', '5', '6'], label: 'Operating System ( Mobile & Tablet )', 'v-validate': 'required', options: options.revContentOsOptionsMobile,
          },
          {
            active: true, name: 'bid_amount', component: 'cc-input', value: 0.35, label: 'Bid Amount', 'v-validate': 'required|min_value:0|excluded:0', left: '$', right: 'per click',
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = {};
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          form.device_targeting = [...cmpMap.platform_targeting.value];
          form.os_targeting = [];
          if (cmpMap.os_targeting_desktop.active) form.os_targeting.push(...cmpMap.os_targeting_desktop.value);
          if (cmpMap.os_targeting_mobile.active) form.os_targeting.push(...cmpMap.os_targeting_mobile.value);
          if (cmpMap.bid_amount.active) form.bid_amount = cmpMap.bid_amount.value;
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
      Outbrain: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required',
          },
          {
            active: true, name: 'platform', component: 'cc-checkbox', value: ['DESKTOP', 'MOBILE', 'TABLET'], label: 'Device Targeting', 'v-validate': 'required', options: options.outbrainPlatformOptions,
          },
          {
            active: true, name: 'cpc', component: 'cc-input', value: '', label: 'Cost per Click', 'v-validate': 'required|min_value:0|excluded:0', left: '$', right: 'per click',
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = { _nested: { targeting: {} } };
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          if (cmpMap.platform.active) form._nested.targeting.platform = cmpMap.platform.value;
          if (cmpMap.cpc && cmpMap.cpc.active) form.cpc = cmpMap.cpc.value;
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
      Mgid: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required',
          },
          {
            active: true, name: 'cpc', component: 'cc-input', value: '', label: 'Cpc', 'v-validate': 'required|min_value:0|excluded:0', right: '¢ ( US cent )',
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = {};
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          if (cmpMap.cpc.active) form.cpc = cmpMap.cpc.value;
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
      ContentAd: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required',
          },
          // eslint-disable-next-line object-property-newline
          {
            active: true,
            name: 'cpc_model',
            component: 'cc-checkbox',
            value: ['cpc_desktop', 'cpc_mobile'],
            label: 'CPC Bid',
            'v-validate': 'required',
            options: options.contentAdCpcOptions,
            events: {
              input: ({ components, component }, value) => {
                const componentsMap = {
                  cpc_desktop: components.find((cmp) => cmp.name === 'cpc_desktop'),
                  // cpc_tablet: components.find(cmp => cmp.name === 'cpc_tablet'),
                  cpc_mobile: components.find((cmp) => cmp.name === 'cpc_mobile'),
                };
                const deviceMap = { cpc_desktop: false, cpc_mobile: false };
                component.value.forEach((device) => { deviceMap[device] = true; });
                Object.entries(deviceMap).forEach(([key, value]) => {
                  componentsMap[key].active = value;
                  if (!value) componentsMap[key].value = '';
                });
              },
            },
            init({ components, component }) {
              component.events.input({ components, component }, component.value);
            },
          },
          {
            active: true, name: 'cpc_desktop', component: 'cc-input', value: '', label: 'Cpc Desktop', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Desktop', right: '$',
          },
          // { active: true, name: 'cpc_tablet', component: 'cc-input', value: '', label: 'Cpc Tablet', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Tablet', right: '$' },
          {
            active: true, name: 'cpc_mobile', component: 'cc-input', value: '', label: 'Cpc Mobile', disableLabel: true, 'v-validate': 'required|min_value:0.01|max_value:10', left: 'Cpc Mobile', right: '$',
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = {};
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          if (cmpMap.cpc_desktop) form.cpc_desktop = cmpMap.cpc_desktop.value || 0;
          // if (cmpMap.cpc_tablet) form.cpc_tablet = cmpMap.cpc_tablet.value || 0
          if (cmpMap.cpc_mobile) form.cpc_mobile = cmpMap.cpc_mobile.value || 0;
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
      Gemini: {
        components: [
          {
            active: true, name: 'name', component: 'cc-input', value: '', label: 'Name', 'v-validate': 'required|max:255',
          },
          {
            active: true, name: 'gender', component: 'cc-multi-select', value: options.geminiGenderOptions.find(({ value }) => value === 'ALL'), label: 'Gender', 'v-validate': 'required', options: options.geminiGenderOptions, placeholder: 'Select Gender', multiple: false,
          },
          // eslint-disable-next-line object-property-newline
          {
            active: true,
            name: 'age',
            component: 'cc-multi-select',
            value: [options.geminiAgeOptions.find(({ value }) => value === 'ALL')],
            oldValue: null,
            label: 'Age',
            'v-validate': 'required',
            options: options.geminiAgeOptions,
            placeholder: 'Select Age',
            events: {
              input: ({ components, component }, value) => {
                const { oldValue } = component;
                if (value.length > 1) {
                  const valueAll = value ? value.find(({ value }) => value === 'ALL') : null;
                  const oldValueAll = oldValue ? oldValue.find(({ value }) => value === 'ALL') : null;
                  if (valueAll && oldValueAll) {
                    // Remove option All
                    component.value = value.filter(({ value }) => value !== 'ALL');
                  } else if (valueAll && !oldValueAll) {
                    // Set only option All
                    component.value = [valueAll];
                  }
                }
                component.oldValue = value;
              },
            },
          },
          {
            active: true, name: 'device', component: 'cc-multi-select', value: options.geminiDeviceOptions.find(({ value }) => value === 'ALL'), label: 'Device', 'v-validate': 'required', options: options.geminiDeviceOptions, placeholder: 'Select Device', multiple: false,
          },
          {
            active: true, name: 'target_url', component: 'cc-input', value: '', label: 'Target Url', 'v-validate': { url: { require_protocol: true }, required: true },
          },
          {
            active: true, name: 'rules', component: 'cc-rule-select', value: [], label: 'Add rules', options: rules,
          },
        ],
        getForm(components) {
          const form = {
            _nested: { targeting_attribute: [] },
            _nestedParser: {
              targeting_attribute: (form, values) => {
                const woeids = form.targeting_attribute.filter(({ type }) => type === 'WOEID');
                form.targeting_attribute = [...woeids, ...values];
              },
            },
          };
          const cmpMap = {};
          components.forEach((cmp) => { cmpMap[cmp.name] = cmp; });
          if (cmpMap.name.active) form.name = cmpMap.name.value;
          if (cmpMap.gender.active) form._nested.targeting_attribute.push({ type: 'GENDER', value: cmpMap.gender.value.value });
          if (cmpMap.device.active) form._nested.targeting_attribute.push({ type: 'DEVICE', value: cmpMap.device.value.value });
          if (cmpMap.age.active) {
            cmpMap.age.value.forEach(({ value }) => {
              form._nested.targeting_attribute.push({ type: 'AGE', value });
            });
          }
          if (cmpMap.target_url.active) form.target_url = cmpMap.target_url.value;
          if (cmpMap.rules.active) form.rules = cmpMap.rules.value;
          return form;
        },
      },
    };
    variations.Adskeeper = variations.Mgid;
    return variations;
  },
};
