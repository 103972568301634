<template>
  <div id="accountWizardWrapper" :class="`platform-${$platform} account-wizard-container`">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Account Wizard</h1>
        <p class="section-header__content__subtitle"><a :href="docUrl" v-if="docUrl" target="_blank">Learn More<i class="fa fa-angle-right ml-1"></i></a></p>
      </div>
    </div>
    <form-wizard
      ref="wizard"
      @on-validate="handleValidation"
      @on-loading="setLoading"
      @on-change="$_onChange"
      :start-index="startIndex"
      :validate-on-back="true"
      color="#4158D0"
      step-size="xs"
      :class="`step-${currentIndex}`"
    >
      <template slot="title">
        <div class="account-wizard-header">
          <img :src="wizardHeaderContent.img">
          <div class="account-wizard-header__content">
            <h5>{{ wizardHeaderContent.title }}</h5>
            <p v-if="wizardHeaderContent.subtitle" v-html="wizardHeaderContent.subtitle"></p>
          </div>
        </div>
      </template>

      <template
        slot="step"
        slot-scope="props"
      >
        <wizard-step
          :tab="props.tab"
          :transition="props.transition"
          :key="`${props.index}-${props.tab.title}`"
          :index="props.index"
        >
        </wizard-step>
      </template>
      <loadizer :loading="loading" message="Please wait, we are fetching your accounts..." />
      <tab-content :before-change="() => beforeTabSwitch(0)">
        <LoadingOverlay v-if="loadingWizard" :noSpace="false" />
        <TrafficSources ref="trafficsources" @on-select="$_onSourceSelect" v-show="!loadingWizard" />
      </tab-content>
      <tab-content :before-change="() => beforeTabSwitch(1)">
        <LoadingOverlay v-if="loadingWizard" :noSpace="false" />
        <Trackers ref="trackers" @on-select="$_onTrackerSelect" v-show="!loadingWizard" />
      </tab-content>
      <tab-content :before-change="() => beforeTabSwitch(2)">
        <LoadingOverlay v-if="loadingWizard" :noSpace="false" />
        <Connect ref="connect" :account-id="params.id" v-show="!loadingWizard" :tracker="tracker" :sources="sources" />
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div v-if="footer" class="wizard-footer fixed-footer">
          <div class="wizard-footer__left">
            <wizard-button v-if="props.activeTabIndex > 0" class="wizard-back-btn secondary-button" :style="props.fillButtonStyle" @click.native="$_onBackNative(props)">Back</wizard-button>
          </div>
          <div class="wizard-footer__right">
            <button v-if="currentIndex === 1" @click="$_noTracker" class="mr-3 primary-button-outline" :disabled="loadingWizard">I don't use a tracker</button>
            <wizard-button @click.native="$_onNextNative(props)" class="wizard-next-btn" :style="props.fillButtonStyle">{{ props.activeTabIndex === 2 ? 'Finish' : 'Next' }}</wizard-button>
          </div>
        </div>
        <div v-if="finished" class="finished-footer fixed-footer">
          <div class="fixed-footer__actions d-flex justify-content-end">
            <b-btn v-if="finished" class="primary-button-outline mr-3" :to="{name: 'Dashboard'}">Go To Dashboard</b-btn>
            <b-btn v-if="finished" class="primary-button" :to="{name: 'GotoAccountWizard'}">Add New Integration</b-btn>
          </div>
        </div>
        <!-- <template v-if="footer">
          <div class="wizard-footer-left">
            <wizard-button v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle" @click.native="$_onBackNative(props)">Back</wizard-button>
          </div>
          <div class="wizard-footer-right">
            <b-btn @click="$_noTracker" class="mr-2" :disabled="loadingWizard" variant="outline-primary" v-if="currentIndex === startIndex">I don't use a tracker</b-btn>
            <wizard-button @click.native="$_onNextNative(props)" class="wizard-footer-right" :style="props.fillButtonStyle">{{ props.activeTabIndex === 2 ? 'Finish' : 'Next' }}</wizard-button>
          </div>
        </template> -->
      </template>
      <!-- <div class="wizard-loader" v-if="loadingWizard" :style="{'border-left': config ? config.model.color.loader : null}"></div> -->
    </form-wizard>
  </div>
</template>

<script>
/* eslint-disable */
import {
  FormWizard, TabContent, WizardStep, WizardButton,
} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Trackers from './Trackers';
import TrafficSources from './TrafficSources';
import Connect from './Connect';
import notifications from '@sh/mixins/notifications';
import configFile from './config';
import MultiAccountModal from "@sh/components/TrafficSources/MultiAccountModal";
import LoadingOverlay from '@sh/components/Utils/Table/CellRenderers/LoadingOverlay.ts.vue';

export default {
  name: 'AccountWizard',
  components: {
    MultiAccountModal,
    FormWizard,
    TabContent,
    WizardStep,
    WizardButton,
    Trackers,
    TrafficSources,
    Connect,
    LoadingOverlay,
  },
  mixins: [notifications],
  data() {
    return {
      loading:false,
      config: null,
      loadingWizard: true,
      tracker: {},
      sources: [],
      footer: true,
      direction: null,
      startIndex: 0,
      currentIndex: null,
      params: {
        ts: null,
        tr: null,
        step: null,
        id: null,
        refresh_token: null,
        account_name: null,
        auth_error: null,
      },
      finished: false
    };
  },
  computed: {
    prevIndex() {
      return this.currentIndex - 1;
    },
    nextIndex() {
      return this.currentIndex + 1;
    },
    docUrl() {
      if (this.currentIndex === 0) {
        return 'https://theoptimizer.io/help/article-categories/traffic-source-setup';
      } else if (this.currentIndex === 1) {
        return 'https://theoptimizer.io/help/article-categories/tracking-platform-setup';
      }
    },
    wizardHeaderContent() {
      if (this.currentIndex === 0) {
        return {
          img: 'sh_static/other/traffic-source-setup.svg',
          title: 'Traffic Source Setup',
          subtitle: 'Click Add New to start connecting a new traffic source account <br> or select an existing one to link it with a tracker on the next steps.'
        }
      } 
      if (this.currentIndex === 1) {
        return {
          img: 'sh_static/other/tracker-setup.svg',
          title: 'Tracking Platform Setup',
          subtitle: 'Click Add New to start connecting a new tracking platform account <br> or select an existing one to finalize linking on the next step.'
        }
      }
      if (this.currentIndex === 2) {
        if (!this.finished) {
          return {
            img: 'sh_static/other/connect-finish.svg',
            title: 'Connect and Finish',
            subtitle: ''
          }
        } 
        return {
          img: 'sh_static/other/connect-finish.svg',
          title: 'Integration completed successfully',
          subtitle: 'The system is retrieving data for the past 15 days for this account, and the completion of this process could take anywhere from 3 to 4 hours.'
        }
      }
    },
  },
  async created() {
    // Reset
    this.$_updateParamsFromUrl();
    this.startIndex = this.params.step;
    this.currentIndex = this.startIndex;
    // Update Query Uri
    ['tr', 'ts', 'step', 'id'].forEach((key) => {
      this.$watch(`params.${key}`, (value) => {
        this.$_updateQueryParams(key, value)
      });
    });
  },
  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.noIntegrations) {
          this.$router.replace({ query: {
            ...query,
            noIntegrations: undefined,
          }});
          this.$swal({
            title: '<small>Register your first traffic source account to get started.</small>',
            icon: 'info',
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    // Init StartStep
    this.config = await configFile.getConfig();
    await this.$_callbackHandler();
    if (this.params.ts || this.params.tr) {
      if (this.params.ts && this.params.tr) {
        await Promise.all([this.$refs.trafficsources.load(), this.$refs.trackers.load()]);
        this.$refs.trafficsources.select(this.params.ts);
        this.sources = this.$refs.trafficsources.getAccount();
        this.$refs.trackers.select(this.params.tr);
        this.tracker = this.$refs.trackers.getAccount(this.params.tr);
      } else if (this.params.ts) {
        await this.$refs.trafficsources.load();
        this.$refs.trafficsources.select(this.params.ts);
        this.sources = this.$refs.trafficsources.getAccount();
      } else if (this.params.tr) {
        await this.$refs.trackers.load();
        this.$refs.trackers.select(this.params.ts);
        this.tracker = this.$refs.trackers.getAccount(this.params.tr);
      }
      if (this.startIndex === 1) {
        await this.$refs.trackers.load();
      } else if (this.startIndex === 2) {
        await this.$refs.connect.load();
        // Wizard Component Bug
        this.$refs.wizard.activateTab(1);
        this.$refs.wizard.activateTab(2);
      }
    } else {
      await this.$_loadByIndex(this.startIndex);
    }
    // consoleconsole.log(this.$refs.connect.$refs.tokens)
    this.loadingWizard = false;
  },
  methods: {
    setLoading(value) {
      this.loadingWizard = value;
    },
    handleValidation(isValid, tabIndex) {
      // console.log('Tab: ' + tabIndex + ' valid: ' + isValid)
    },
    async beforeTabSwitch(index) {
      if (this.direction === 'right') {
        // Next
        if (index === 0 && this.$refs.trafficsources.isCompleted()) {
          this.sources = this.$refs.trafficsources.getAccount();
          this.params.ts = this.sources.map((s) => s.id).join(',');
          await this.$refs.trackers.load();
          return true;
        } if (index === 1 && this.$refs.trackers.isCompleted() && this.$refs.trafficsources.isCompleted()) {
          this.tracker = this.$refs.trackers.getAccount();
          this.params.tr = this.tracker.id;
          if (this.sources[0].type.uniqueName === 'VoluumDSP' && this.tracker.type.uniqueName === 'Voluum') {
            this.$_noTracker();
            return true;
          }
          await this.$refs.connect.load();
          return true;
        } if (index === 2) {
          const completed = await this.$refs.connect.isCompleted();
          if (completed) {
            this.footer = false;
            const saveStatus = await this.$refs.connect.save();
            if (!saveStatus) {
              this.finished = false;
              this.footer = true;
            } else {
              this.finished = true;
            }
            return true;
          }
          // this.$swal({
          //   title: 'Please complete a valid token configuration',
          //   type: 'error',
          // });
          return false;
        }
        return false;
      }
      // Back
      if (index === 1) {
        await this.$refs.trafficsources.load();
      } else if (index === 2) {
        await this.$refs.trackers.load();
      }
      return true;
    },
    $_onTrackerSelect(account) {},
    $_onSourceSelect(account) {},
    $_onChange(prevIndex, nextIndex) {
      if (prevIndex === 0 && nextIndex === -1) return;
      // console.log('on change', prevIndex, nextIndex, arguments)
      this.currentIndex = nextIndex;
      this.params.step = nextIndex;
      // this.$_updateUrlFromParams()
      if (prevIndex < nextIndex) {
        this.$_onNext(nextIndex);
      } else {
        this.$_onBack(nextIndex);
      }
    },
    $_onNext(index) {},
    $_onBack(index) {},
    $_onBackNative(props) {
      this.direction = 'left';
      props.prevTab();
    },
    $_onNextNative(props) {
      this.direction = 'right';
      props.nextTab();
    },
    $_updateParamsFromUrl() {
      const query = { ...this.$route.query };
      if (query.ts) this.params.ts = parseInt(query.ts);
      if (query.tr) this.params.tr = parseInt(query.tr);
      if (query.id) this.params.id = query.id.trim();
      if (query.step) this.params.step = parseInt(query.step);
      // if (query.refresh_token) this.params.refresh_token = query.refresh_token
      // if (query.account_name) this.params.account_name = query.account_name
      else this.params.step = 0;
    },
    $_updateUrlFromParams() {
      const params = {};
      if (this.params.tr) params.tr = this.params.tr;
      if (this.params.ts) params.ts = this.params.ts;
      if (this.params.step) params.step = this.params.step;
      if (this.params.id) params.id = this.params.id;
    },
    async $_loadByIndex(index) {
      if (index === 0) {
        await this.$refs.trafficsources.load();
      } else if (index === 1) {
        await this.$refs.trackers.load();
      } else if (index === 2) {
        await this.$refs.connect.load();
      }
    },
    $_noTracker(reset) {
      if (this.$refs.trafficsources.isCompleted()) {
        if (reset) {
          // This code is needed to reset errors when user has clicked next without selecting a tracker
          this.$refs.wizard.reset()
          this.$refs.wizard.activateTab(1);
          this.$refs.connect.$_hideAlert();
          this.$refs.trackers.$_hideAlert();
        }
        this.tracker = {};
        this.finished = !!reset;
        this.$refs.connect.setFinished();
        this.$refs.wizard.activateTab(2);
        this.currentIndex = 2;
      }
    },
    async $_callbackHandler() {
      const query = { ...this.$route.query };
      let state;
      if (query && query.state) {
        try {
          state = JSON.parse(query.state);
        // eslint-disable-next-line no-empty
        } catch (err) {
        }

      }
      if (state && state.account_unique_name === 'Facebook') {
        let account = null;
        if(query.code){
          try {
            const payload = {
              typeName: state.account_unique_name,
              typeId: state.account_type_id,
              name: decodeURIComponent(state.account_name),
              credentials: { accessToken: query.code },
            };
            if (state.settings) payload.settings = state.settings;
            this.$data.loading = true
            account = await this.config.api.accountWizard.extendToken(payload);
            this.$data.loading = false
            this.$_showMultiAccountModal(account, "Facebook", 'AdAccounts')
          } catch (error) {
            this.$data.loading = false
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
          delete query.state;
          delete query.code
        }
        if(query.error){
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
          Object.keys(query).forEach(key => delete query[key])
        }
        this.$router.replace({ query });
      }else
      if (state && state.account_unique_name === 'TikTok') {
        let account = null;
        if(query.auth_code){
          try {
            const payload = {
              typeName: state.account_unique_name,
              typeId: state.account_type_id,
              name: decodeURIComponent(state.account_name),
              credentials: { accessToken: query.auth_code },
            };
            if (state.settings) payload.settings = state.settings;
            this.$data.loading = true
            account = await this.config.api.accountWizard.extendToken(payload);
            this.$data.loading = false
            this.$_showMultiAccountModal(account, "TikTok", 'AdAccounts')
          } catch (error) {
            this.$data.loading = false
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
          delete query.state;
          delete query.auth_code;
        }
        if(query.error){
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
          Object.keys(query).forEach(key => delete query[key])
        }
        this.$router.replace({ query });
      }
      else if (state && state.account_unique_name === 'GoogleAds') {
        let account = null;
        if(query.code){
          try {
           //let fullcode = query.code + "&scope="+ query.scope + "&scope=" + query.scope + "&authuser=" + query.authuser + "&prompt=" + query.prompt
            const payload = {
              typeName: state.account_unique_name,
              typeId: state.account_type_id,
              name: decodeURIComponent(state.account_name),
              credentials: { accessToken: query.code },
            };
            if (state.settings) payload.settings = state.settings;
            this.$data.loading = true
            account = await this.config.api.accountWizard.extendToken(payload);
            this.$data.loading = false
            this.$_showMultiAccountModal(account, "GoogleAds", 'AdAccounts')
          } catch (error) {
            this.$data.loading = false
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
          delete query.state;
          delete query.auth_code;
        }
        if(query.error){
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
          Object.keys(query).forEach(key => delete query[key])
        }
        this.$router.replace({ query });
      }
      // AMAZON
      else if (state && ['AmazonDisplay', 'AmazonDSP'].includes(state.account_unique_name)){
        let account = null;
        if(query.code){
          const accountData= JSON.parse(query.state)
          try {
            const payload = {
              typeName: accountData.account_unique_name,
              typeId: accountData.account_type_id,
              name: decodeURIComponent(accountData.account_name),
            credentials: { accessToken: query.code, loginUrl: accountData.loginUrl  },
            };
            if (accountData.settings) payload.settings = accountData.settings;
            this.$data.loading = true
            account = await this.config.api.accountWizard.extendToken(payload);
            account = account.map(el => {return {...el, name: `${payload.name} - ${el.name}` }})
            this.$_showMultiAccountModal(account, 'Amazon Display', 'Profiles' )
            this.$data.loading = false
          } catch (error) {
            this.$data.loading = false
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
        }
        if(query.error){
          console.log(query.error)
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
        }
        this.$router.replace({  });
      }
      
      if (query.account_unique_name && query.account_unique_name === 'Gemini' && query.refresh_token && query.account_name && query.account_type_id) {
        // Gemini callback account
        let account = null;
        try {
          // Create Account
          const payload = {
            typeName: query.account_unique_name,
            typeId: query.account_type_id,
            name: query.account_name,
            credentials: { api_key: query.refresh_token },
          };
          if (query.settings) payload.settings = JSON.parse(query.settings);
          account = await this.config.api.accountWizard.createTrafficSource(payload);
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
        delete query.account_unique_name;
        delete query.refresh_token;
        delete query.account_name;
        delete query.account_type_id;
        delete query.settings;
        if (account) {
          query.ts = account.id;
          this.params.ts = account.id;
        }
        this.$router.replace({ query });
      } else if (query.account_unique_name && ['GoogleAnalytics4', 'GoogleAnalytics'].includes(query.account_unique_name) && query.refresh_token && query.account_name && query.account_type_id) {
        // GoogleAnalytics callback account
        let account = null;
        try {
          // Create Account
          const payload = {
            typeName: query.account_unique_name,
            typeId: query.account_type_id,
            name: query.account_name,
            credentials: { api_key: query.refresh_token },
          };
          if (query.settings) payload.settings = JSON.parse(query.settings);
          account = await this.config.api.accountWizard.createTracker(payload);
        } catch (error) {
          console.log('GOOGLE ERROR', error);
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
        delete query.account_unique_name;
        delete query.refresh_token;
        delete query.account_name;
        delete query.account_type_id;
        delete query.settings;
        if (account) {
          query.tr = account.id;
          this.params.tr = account.id;
          this.$apiStore.trackers.accounts.clearCache();
        }
        this.$router.replace({ query });
      }
    },
    $_updateQueryParams (key, value) {
      const newValue = `${value}`;
      const oldValue = this.$route.query[key];
      if (oldValue !== newValue) {
        const query = { ...this.$route.query };
        query[key] = newValue;
        this.$router.push({ query });
      }
    },
    $_showMultiAccountModal(accounts, trafficSourceName, accountAlias) {
      this.$refs.trafficsources.showMultiModal(accounts, trafficSourceName, accountAlias);
    },
  },
};
</script>

<style lang="scss">
  .account-wizard-container {
    padding-bottom: 4.1rem;

    .loading-overlay {
      height: calc(100vh - 500px);
    }

    .vue-form-wizard {
      &.step-0 {
        .wizard-progress-with-circle {
          .wizard-progress-bar {
            width: 0 !important;
          }
        }
      }

      &.step-1 {
        .wizard-progress-with-circle {
          .wizard-progress-bar {
            width: calc(100% / 2) !important;
          }
        }
      }

      &.step-2 {
        .wizard-progress-with-circle {
          .wizard-progress-bar {
            width: 100% !important;
          }
        }
      }

      .wizard-header {
        margin-bottom: 2rem;

        .account-wizard-header {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 8rem;
            margin-right: 2rem;
          }

          &__content {
            h5 {
              font-size: 2.2rem;
              color: #546582;
              font-weight: bold;
            }

            p {
                margin-top: 1rem;
              font-size: 1.5rem;
              color: $text-secondary;
            }
          }
        }
      }

      .wizard-navigation {
        .wizard-progress-with-circle {
          width: 34%;
        }

        .wizard-nav {
          margin: 0 auto;
          width: 50%;
        }
      }

      .wizard-footer {
        display: flex;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4.3rem;

        &__left {
          .wizard-back-btn {
            background: $color-white !important;
            border: 0.1rem solid #E5E5E5 !important;
            color: $black-500 !important;
            padding: .6rem 1.2rem;
            height: 100%;
          }
        }
      }

      .finished-footer {
        a {
          padding: .6rem 1.2rem;
        }

        .primary-button-outline {
          &:hover,
          &:focus,
          &:active {
            border-color: #3ba0ff;
          }
        }

        .primary-button {
          background: #24C2F3;
          border-color: #24C2F3;
          color: $color-white;

          &:hover,
          &:active,
          &:focus {
            background: #14b6e7 !important;
            border-color: #14b6e7 !important;
          }
        }
      }
    }
  }

// #accountWizardWrapper {
//   $unselected-color: rgba(137,148,156,1);
//   $native-item-color: #67c2ef;
//   $mobile-item-color: #e65546;
//   $adult-item-color: #38bd6f;

//   @mixin box-shadow($color) {
//     -webkit-box-shadow: 0px 0px 15px -3px $color;
//     -moz-box-shadow: 0px 0px 15px -3px $color;
//     box-shadow: 0px 0px 15px -3px $color;
//   }
//   @mixin box-style($color, $width: 4px) {
//     border: $width solid $color;
//     @include box-shadow($color);
//   }

//   .wizard-header {
//     padding: 5px 5px 10px;
//   }
//   .wizard-navigation {
//     .wizard-tab-content {
//       padding: 5px 0px;
//     }
//   }
//   .wizard-loader {
//     margin: 60px auto;
//     font-size: 10px;
//     position: relative;
//     text-indent: -9999em;
//     border-top: 1.1em solid rgba(255, 255, 255, 0.2);
//     border-right: 1.1em solid rgba(255, 255, 255, 0.2);
//     border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
//     -webkit-transform: translateZ(0);
//     -ms-transform: translateZ(0);
//     transform: translateZ(0);
//     -webkit-animation: load8 1.1s infinite linear;
//     animation: load8 1.1s infinite linear;
//     border-radius: 50%;
//     width: 10em;
//     height: 10em;
//     &:after {
//       border-radius: 50%;
//       width: 10em;
//       height: 10em;
//     }
//   }
//   @media (max-height: 692px) {
//     .elastic-wrapper-items {
//       max-height: 270px;
//       overflow-y: scroll;
//     }
//   }

//   h4.lead {
//     margin-top: 5px;
//     margin-bottom: 15px;
//     .fa{
//       font-size: 17px;
//     }
//   }
//   .item {
//     @include box-shadow($unselected-color);
//     padding: 4px;
//     cursor: pointer;
//     float: left;
//     margin-right: 13px;
//     width: 150px;
//     height: 160px;
//     position: relative;
//     line-height: 1.42857;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     -webkit-transition: border .2s ease-in-out;
//     -o-transition: border .2s ease-in-out;
//     transition: border .2s ease-in-out;
//     margin-bottom: 13px;
//     .account-name-wraper {
//       height: 45px;
//       padding: 0px 2px;
//       .account-name {
//         font-size: 12px;
//         word-break: break-all;
//       }
//     }
//     .type-wraper {
//       bottom: 0;
//       left: 0;
//       position: absolute;
//       padding: 4px;
//       color: #333;
//       width: 100%;
//       .badge {
//         background-color: #ededed;
//         border-radius: 2px;
//       }
//     }
//     .caption{
//       bottom: 0;
//       left: 0;
//       position: absolute;
//       padding: 4px;
//       color: #333;
//       text-align: center;
//       width: 100%;
//       h4 {
//         font-size: 22px;
//       }
//     }
//     &:hover{
//       @include box-shadow($native-item-color);
//     }
//     &.nohover:hover{
//       @include box-shadow($unselected-color);
//     }
//     &:not(.account-new) img{
//       width: 100%;
//       padding: 0 6px;
//       position: absolute;
//       bottom: 70px;
//       left: 0;
//     }
//     &.type-Mgid img{
//       bottom: 50px;
//     }
//     &.type-Gemini img{
//       bottom: 62px;
//     }
//     &.type-Engageya img{
//       height: 70px;
//       bottom: 49px;
//     }
//     &.type-Adsterra img {
//       bottom: 41px;
//       height: 78px;
//     }
//     &.type-MegaPush img {
//       bottom: 60px;
//     }
//     &.type-RtxPops img {
//       bottom: 58px;
//     }
//      &.type-PushGround img {
//       top: 35px;
//     }
//     &.type-RichPush img {
//       bottom: 50px;
//     }
//     &.type-PopAds img {
//       bottom: 58px;
//     }
//     &.type-PopCash img {
//       bottom: 62px;
//     }
//     &.type-ExoClick img {
//       bottom: 62px;
//     }
//     &.type-ActiveRevenueMobile img {
//       bottom: 65px;
//     }
//     &.type-EzMob img {
//       bottom: 50px;
//     }
//     &.type-Evadav img {
//       bottom: 66px;
//     }
//     &.type-FunnelFlux img{
//       bottom: 45px;
//       height: 65px;
//     }
//     &.type-Binom img{
//       bottom: 64px;
//     }
//     &.type-Everflow img{
//       bottom: 40px;
//       height: 80px;
//     }
//     &.type-Thrive img{
//       bottom: 67px;
//     }
//     &.type-CloudThrive img{
//       bottom: 67px;
//     }
//     &.type-GoogleAnalytics img{
//       bottom: 45px;
//       height: 73px;
//     }
//     &.type-Voluum img{
//       bottom: 55px;
//     }
//     &.type-Kintura img{
//       bottom: 45px;
//       height: 70px;
//     }
//     &.type-RedTrack img{
//       bottom: 65px;
//     }
//     &.type-Bemob img{
//       bottom: 46px;
//       height: 77px;
//     }
//     p{
//       margin: 5px 0;
//     }
//     &.account-item.selected, &.account-config {
//       @include box-style($native-item-color);
//     }
//     &.account-new {
//       img {
//         height: 75px;
//         margin-top: 28px;
//         width: auto;
//         display: block;
//         max-width: 100%;
//         margin-left: auto;
//         margin-right: auto;
//       }
//       h4{
//         margin: 5px 0;
//       }
//     }
//     &.account-config {
//       .caption{
//         width: 100%;
//       }
//       .caption-top h2{
//         margin-top: 0;
//       }
//     }
//     &.min{
//       margin-top: 72px;
//       zoom: 1.5;
//     }

//     &.max{
//       min-height: 500px;
//       width: 400px;
//       padding-top: 10px;
//       height: unset;
//     }
//   }
//   .modal {
//     top: 0;
//   }
//   &.platform-mobile {
//     .account-search-wraper {
//       .account-search-input {
//         .before-icon {
//           color: $mobile-item-color;
//         }
//         input {
//           border-bottom-color: $mobile-item-color !important;
//         }
//       }
//     }
//     .item {
//       &.account-item.selected, &.account-config {
//         @include box-style($mobile-item-color);
//       }
//       &:hover{
//         @include box-shadow($mobile-item-color);
//       }
//     }
//   }
//   &.platform-adult {
//     .account-search-wraper {
//       .account-search-input {
//         .before-icon {
//           color: $adult-item-color;
//         }
//         input {
//           border-bottom-color: $adult-item-color !important;
//         }
//       }
//     }
//     .item {
//       &.account-item.selected, &.account-config {
//         @include box-style($adult-item-color);
//       }
//       &:hover{
//         @include box-shadow($adult-item-color);
//       }
//     }
//   }

//   .wizard-card-footer {
//     padding: 0;
//   }
// }

@media (max-width: 767.98px) {
  .account-wizard-container {
    .vue-form-wizard {
      .wizard-footer {
        gap: 1.5rem;

        &__right {
          display: inline-flex;
          flex-direction: column;
          gap: 1.5rem;
          > * {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
