<template></template>

<script>
export default {
  name: 'GotoComponent',
  // eslint-disable-next-line vue/require-prop-types
  props: ['to'],
  created() {
    this.$router.push(this.to);
  },
};
</script>
