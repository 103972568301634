export default {
  props: {
    config: { type: Object, required: true },
    mode: {
      type: String,
      required: true,
      validator: (value) => ['create', 'edit', 'clone'].indexOf(value) !== -1,
    },
    cloneMode: {
      type: String,
      default: 'FRESH_CLONE',
      validator: (value) => ['FRESH_CLONE', 'EXACT_CLONE'].indexOf(value) !== -1,
    },
    retry: { type: Boolean, required: true },
  },
};
