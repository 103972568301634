export default new Map([
  ['USD', '$'],
  ['AUD', '$'],
  ['ARS', '$'],
  ['EUR', '€'],
  ['ALL', 'L'],
  ['DZD', 'دج'],
  ['AWG', 'ƒ'],
  ['GBP', '£'],
  ['BSD', 'B$'],
  ['BHD', '.د.ب'],
  ['BDT', 'Tk'],
  ['BYR', 'Br'],
  ['BZD', 'BZ$'],
  ['BMD', 'BD$'],
  ['BTN', 'Nu.'],
  ['BOB', 'Bs'],
  ['BWP', 'P'],
  ['BRL', 'R$'],
  ['BND', 'B$'],
  ['BGN', 'лв'],
  ['BIF', 'FBu'],
  ['CAD', 'C$'],
  ['CNY', '¥'],
  ['KHR', 'KHR'],
  ['CVE', 'Esc'],
  ['KYD', '$'],
  ['XOF', 'BCEAO'],
  ['XAF', 'BEAC'],
  ['CLP', '$'],
  ['COP', '$'],
  ['KMF', 'KMF'],
  ['CRC', '₡'],
  ['HRK', 'kn'],
  ['CUP', '$MN'],
  ['CZK', 'Kč'],
  ['DKK', 'kr'],
  ['DJF', 'Fdj'],
  ['DOP', 'RD$'],
  ['XCD', 'EC$'],
  ['EGP', 'ج.م'],
  ['SVC', '₡'],
  ['EEK', 'EEK'],
  ['ETB', 'Br'],
  ['FKP', '£'],
  ['FJD', 'FJ$'],
  ['GMD', 'D'],
  ['GTQ', 'Q'],
  ['GNF', 'FG'],
  ['GYD', 'GY$'],
  ['GHS', 'GHS'],
  ['HKD', 'HK$'],
  ['HNL', 'L'],
  ['HUF', 'Ft'],
  ['IDR', 'Rp'],
  ['INR', 'Rs.'],
  ['ISK', 'kr'],
  ['IQD', 'ع.د'],
  ['ILS', '₪'],
  ['JPY', '¥'],
  ['JOD', 'JOD'],
  ['KZT', 'KZT'],
  ['KES', 'KSh'],
  ['KRW', '₩'],
  ['KWD', 'د.ك'],
  ['KGS', 'KGS'],
  ['MOP', '$'],
  ['MKD', 'MKD'],
  ['MWK', 'MK'],
  ['MYR', 'RM'],
  ['MVR', 'Rf'],
  ['MRO', 'UM'],
  ['MUR', '₨'],
  ['MXN', '$'],
  ['MDL', 'MDL'],
  ['MNT', '₮'],
  ['MNT', 'د.م.'],
  ['MMK', 'K'],
  ['NAD', 'N$'],
  ['NPR', '₨'],
  ['ANG', 'NAƒ'],
  ['NZD', '$'],
  ['NIO', 'C$'],
  ['NGN', '₦'],
  ['KPW', '₩'],
  ['NOK', 'kr'],
  ['OMR', 'ر.ع.'],
  ['XPF', 'F'],
  ['PKR', 'Rs.'],
  ['PAB', 'B'],
  ['PGK', 'K'],
  ['PEN', 'S/.'],
  ['PHP', '₱'],
  ['PLN', 'zł'],
  ['QAR', 'ر.ق'],
  ['RON', 'L'],
  ['RUB', 'руб'],
  ['RWF', 'RF'],
  ['CHF', 'CHF'],
  ['WST', 'WS$'],
  ['STD', 'Db'],
  ['SAR', 'ر.س'],
  ['SCR', 'SR'],
  ['SLL', 'Le'],
  ['SGD', 'S$'],
  ['SKK', 'Sk'],
  ['SBD', 'SI$'],
  ['SOS', 'So.'],
  ['ZAR', 'R'],
  ['LKR', 'ரூ'],
  ['SHP', '£'],
  ['SDG', 'SDG'],
  ['SZL', 'SZL'],
  ['SEK', 'kr'],
  ['SYP', 'SYP'],
  ['THB', '฿'],
  ['TRY', 'YTL'],
  ['TWD', 'NT$'],
  ['TZS', 'x'],
  ['TOP', 'T$'],
  ['TTD', 'TTD'],
  ['TND', 'د.ت'],
  ['AED', 'د.إ'],
  ['UGX', 'USh'],
  ['UYU', 'UYU'],
  ['UZS', 'UZS'],
  ['VUV', 'Vt'],
  ['VND', '₫'],
  ['VEF', 'VEF'],
  ['YER', 'YER'],
  ['ZMK', 'ZMK'],
]);
