/* eslint-disable import/no-cycle */
import * as Creative from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import { ReadableNameCallToAction } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import { AdABGroups } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import {
  DefaultGroupMacros,
  DefaultMacros,
  FieldConfiguration,
  RequiredLiteralKeys,
} from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import Joi from 'joi';

const macros = DefaultMacros;

const groupMacros = DefaultGroupMacros;

export enum CreativeAbGroups {
  CREATIVE_ITEMS = 'CREATIVE_ITEMS',
}

export const CreativeAbGroupsPublic = {
  [CreativeAbGroups.CREATIVE_ITEMS]: {
    readableName: 'Creatives',
    macros: groupMacros,
    isActive: () => true,
  },
} as const;

const BaseCreativeValidation: Record<RequiredLiteralKeys<Creative.BaseCreative>, FieldConfiguration> = {
  headline: {
    validation: Joi.array().items(Joi.string().allow('')).optional(),
    isActive: true,
    fieldGroup: AdABGroups.CREATIVE_ITEMS,
    readableName: 'Headline',
    renderFunction: (value) => value.join(', '),
    macros,
  },
  account_id: {
    validation: Joi.string().required(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Account ID',
    macros,
  },
  object_type: {
    validation: Joi.string().valid(Creative.ObjectType).required(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Object Type',
  },
  adlabels: {
    validation: Joi.array().items(Joi.string()).optional(),
    isActive: false,
    readableName: 'Ad Labels',
    macros,
  },
  title: {
    validation: Joi.array().items(Joi.string().allow('')).optional(),
    isActive: true,
    fieldGroup: AdABGroups.CREATIVE_ITEMS,
    readableName: 'Title',
    macros,
    renderFunction: (value) => value.join(', '),
  },
  body: {
    validation: Joi.array().items(Joi.string().allow('')).optional(),
    isActive: true,
    fieldGroup: AdABGroups.CREATIVE_ITEMS,
    readableName: 'Description',
    renderFunction: (value) => value.join(', '),
    macros,
  },
};

const cropValidation = Joi.object({
  coordinates: Joi.object().keys({
    width: Joi.number().required(),
    height: Joi.number().required(),
    left: Joi.number().required(),
    top: Joi.number().required(),
  }),
  pixelCoordinates: Joi.array().items(Joi.array().items(Joi.number())).required(),
  cropRatio: Joi.string().required(),
  useCrop: Joi.boolean().required(),

  // TODO - Recheck if ok
  width: Joi.number().required(),
  height: Joi.number().required(),
  transforms: Joi.object({
    flip: Joi.object({
      horizontal: Joi.boolean().required(),
      vertical: Joi.boolean().required(),
    }).required(),
    rotate: Joi.number().required(),
    scaleX: Joi.number().required(),
    scaleY: Joi.number().required(),
    translateX: Joi.number().required(),
    translateY: Joi.number().required(),
  }),
});

const ImageCreativeValidation: FieldConfiguration = {
  validation: Joi.object({
    image_hash: Joi.string(),
    image_url: Joi.string(),
    width: Joi.number().optional(),
    height: Joi.number().optional(),
    size: Joi.number().optional(),
    cropVariations: Joi.object().pattern(Joi.string(), cropValidation).optional(),
  })
    .when('.image_url', {
      is: Joi.exist(),
      then: Joi.object({ image_hash: Joi.forbidden() }),
    })
    .when('.image_hash', {
      is: Joi.exist(),
      then: Joi.object({ image_url: Joi.forbidden() }),
    })
    .unknown(true),
  isActive: true,
  fieldGroup: AdABGroups.CREATIVE_ITEMS,
  readableName: 'Image',
  macros,
};

const CallToActionValidation: Record<RequiredLiteralKeys<Creative.CallToAction>, FieldConfiguration> = {
  call_to_action_type: {
    validation: Joi.string()
      .valid(...Object.values(Creative.CallToActionType))
      .required()
      .messages({
        'any.only': `Please add call to action variation.`,
      }),
    isActive: true,
    fieldGroup: AdABGroups.CREATIVE_ITEMS,
    readableName: 'Call to Action Type',
    macros,
    renderFunction(value) {
      return ReadableNameCallToAction[value as keyof typeof ReadableNameCallToAction] || value;
    },
  },
  link_destination_display_url: {
    validation: Joi.string().domain().required(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Link Destination Display URL',
    macros,
  },
  link_url: {
    validation: Joi.string()
      .regex(/^https?:\/\/(?:[\w-]+\.)?[\w-]+\.[a-zA-Z]{2,}.*$/)
      .required()
      .messages({
        'string.empty': `Please enter a valid URL.`,
        'string.pattern.base': `Please enter a valid URL.`,
      }),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Link URL',
  },
  object_url: {
    validation: Joi.string().required(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Object URL',
  },
  template_url: {
    validation: Joi.string().required(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Template URL',
  },
  url_tags: {
    validation: Joi.string(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'URL Tags',
  },
  pixel_id: {
    validation: Joi.string().optional(),
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Pixel',
  },
};

const IdentityValidation: Record<RequiredLiteralKeys<Creative.Identity>, FieldConfiguration> = {
  actor_id: {
    validation: Joi.string().required(),
    isActive: true,
    readableName: 'Facebook Page',
    fieldGroup: AdABGroups.IDENTITY,
  },
  instagram_actor_id: {
    validation: Joi.string().required(),
    isActive: true,
    readableName: 'Instagram Page',
    fieldGroup: AdABGroups.IDENTITY,
  },
};

export type CreativeAbVariables = Creative.CallToAction & Creative.BaseCreative & Creative.Identity;

export const CreativeValidation = {
  ...IdentityValidation,
  ...BaseCreativeValidation,
  ...CallToActionValidation,
  image: ImageCreativeValidation,
};
