<template>
  <div class="content-wrapper dashboard-container">

    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Welcome, {{ $session.getUser().username }}!</h1>
      </div>
      <div class="section-header__date-range d-flex">
        <div class="refresh-button mr-8" v-b-tooltip :title="`Calculate Dashboard metrics based on ${revenueFrom === 'tr' ? 'Traffic Source' : 'Tracker'} data`">
          <switch-button
            class="secondary-button"
            :hideLoader="true"
            :checked="revenueFrom === 'tr'"
            :update="switchRevenueSource"
          />
        </div>
        <div class="refresh-button mr-8" v-b-tooltip title="Refresh">
          <b-btn class="secondary-button" :disabled="isLoading" @click="onRefreshClick">
            <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
          </b-btn>
        </div>
        <date-range v-model="dateRangePicker" :auto-emit="true"></date-range>
      </div>
    </div>

    <!-- Dashboard Stats Cards -->
    <div class="dashboard-container__stats">
      <div class="row">
        <div
          v-for="key in statsList"
          :key="key"
          class="col-md-3"
        >
          <loading-skeleton
            v-if="isLoading"
            class="dashboard-basic-stats-skeleton"
            type="card"
            :card="{ rows: 2, shape: 'square' }"
          />
          <dashboard-stats-card
            v-else
            :name="stats[key].name"
            :value="stats[key].value"
            :info="stats[key].info"
            type="area"
            :height="70"
            :options="stats[key].chart"
            :series="stats[key].chart.series"
            :isChartLoading="isChartLoading"
          >
          </dashboard-stats-card>
        </div>
      </div>
    </div>

    <!-- Dashboard Main Chart -->
    <div class="dashboard-container__main-chart">
      <h2>{{ $c_dateRange || 'Last 30 Days' }}</h2>
      <div class="optimizer-card">
        <loading-skeleton
          v-if="isChartLoading"
          class="dashboard-main-chart-skeleton"
          :card="{ rows: 14, shape: 'square' }"
        />
        <apexchart v-else ref="mainChart" class="mainChart" type="area" :height="400" :options="perforamanceStatsChart" :series="perforamanceStatsChart.series"></apexchart>
      </div>
    </div>

    <!-- Traffic Source Performance Table -->
    <div class="dashboard-container__performance-table">
      <h2>Performance by Traffic Source</h2>
      <loading-skeleton
        v-if="isLoading"
        class="dashboard-performance-table-skeleton"
        type="table"
        :table="{ headItems: 5, bodyItems: 5, filters: false, search: false }"
      />
      <div v-else class="optimizer-table no-compact-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          name="performanceList"
          :hover="false"
          :enable-export="false"
          :selectable="false"
          select-label="Performance"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :show-search="false"
          :show-pagination="false"
          :enable-columns="false"
          :sort="{ key: 'spent_ts', order: 'desc' }"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <router-link
              :to="{
                name: 'Campaigns',
                query: { type: $_getTrafficSourceId(props.item.traffic_source_unique_name), startDate: $route.query.startDate || moment(new Date()).format('YYYY-MM-DD'), endDate: $route.query.endDate || moment(new Date()).format('YYYY-MM-DD') }
              }"
            >
              <div style="display: inline;">
                <img
                  class="account-logo"
                  alt="account_logo"
                  :src="getTrafficSourceLogo(props.item.traffic_source_unique_name)"
                >
                {{ props.item.traffic_source_unique_name }}
              </div>
            </router-link>
          </template>
        </vue-opti-table-light>
      </div>
    </div>

    <!-- Winners Loosers Table -->
    <h2 class="mb-4">
      Top Winners/Losers
    </h2>
    <div class="dashboard-container__winners-loosers-table mb-4">
      <loading-skeleton
        v-if="isLoading"
        class="dashboard-winners-skeleton"
        type="table"
        :table="{ headItems: 5, bodyItems: 5, filters: false, search: false }"
      />
      <div v-else class="optimizer-table no-compact-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          name="performanceList"
          :hover="false"
          :enable-export="false"
          :selectable="false"
          select-label="Performance"
          :header-fields="table.fields"
          :items="table.topWinners"
          v-model="tableModel"
          :show-search="false"
          :show-pagination="false"
          :enable-columns="false"
          :sort="{ key: 'NET', order: 'desc' }"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <router-link
              :to="{
                name: 'Campaign',
                params: { id: props.item.campaign_id, type: props.item.traffic_source_unique_name },
                query: { type: $_getTrafficSourceId(props.item.traffic_source_unique_name), startDate: $route.query.startDate || moment(new Date()).format('YYYY-MM-DD'), endDate: $route.query.endDate || moment(new Date()).format('YYYY-MM-DD') }
              }"
            >
              <div style="display: inline;">
                <img
                  class="account-logo"
                  v-b-tooltip.hover
                  :title="props.item.traffic_source_account_name"
                  alt="account_logo"
                  :src="getTrafficSourceLogo(props.item.traffic_source_unique_name)"
                >
                {{ props.item.name }}
              </div>
            </router-link>
          </template>
        </vue-opti-table-light>
      </div>

      <loading-skeleton
        v-if="isLoading"
        class="dashboard-loosers-skeleton"
        type="table"
        :table="{ headItems: 5, bodyItems: 5, filters: false, search: false }"
      />
      <div v-else class="optimizer-table no-compact-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          name="performanceList"
          :hover="false"
          :enable-export="false"
          :selectable="false"
          select-label="Performance"
          :header-fields="table.fields"
          :items="table.topLoosers"
          v-model="tableModel"
          :show-search="false"
          :show-pagination="false"
          :enable-columns="false"
          :sort="{ key: 'NET', order: 'desc' }"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <router-link
              :to="{
                name: 'Campaign',
                params: { id: props.item.campaign_id, type: props.item.traffic_source_unique_name },
                query: { type: $_getTrafficSourceId(props.item.traffic_source_unique_name), startDate: $route.query.startDate || moment(new Date()).format('YYYY-MM-DD'), endDate: $route.query.endDate || moment(new Date()).format('YYYY-MM-DD') }
              }"
            >
              <div style="display: inline;">
                <img
                  class="account-logo"
                  v-b-tooltip.hover
                  :title="props.item.traffic_source_account_name"
                  alt="account_logo"
                  :src="getTrafficSourceLogo(props.item.traffic_source_unique_name)"
                >
                {{ props.item.name }}
              </div>
            </router-link>
          </template>
        </vue-opti-table-light>
      </div>
    </div>
    <!-- <intro-modal v-if="$platform === 'mobile'" /> -->
  </div>
</template>

<script>
import ui from '@sh/helpers/ui';
import moment from 'moment';
import { getTrafficSourceLogo } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import dateHelper from '@sh/helpers/date';
import DashboardStatsCard from '@sh/components/Dashboard/DashboardStatsCard';
import { basicStatsChart, perforamanceStatsChart } from './charts';
import { getFieldsWithDescription, getFieldTextAndDescription } from '@sh/configurations/fields/parser';
import { getFieldsAndFilters } from '@sh/configurations/fields/FieldFilter';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'Dashboard',
  components: {
    DashboardStatsCard,
  },
  mixins: [notifications],
  data() {
    return {
      ui,
      moment,
      dateRangePicker: {
        startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment().endOf('day').format(FORMAT),
      },
      performanceOptions: [
        { name: 'Traffic Source', selected: true, value: 'traffic-source' },
        { name: 'TS Account', selected: false, value: 'traffic-source-account' },
      ],
      winLossOptions: [
        { name: 'NET', selected: true, value: 'tr_net' },
        { name: 'ROI', selected: false, value: 'tr_roi' },
      ],
      statsList: ['cost', 'revenue', 'net', 'roi', 'clicks', 'conversions', 'cpa', 'epc'],
      stats: {
        cost: {
          name: 'Amount Spent',
          value: 0,
          chart: {},
        },
        net: {
          name: 'NET',
          value: 0,
          chart: {},
        },
        clicks: {
          name: 'Clicks',
          value: 0,
          chart: {},
        },
        cpa: {
          name: 'CPA',
          value: 0,
          chart: {},
        },
        revenue: {
          name: 'Revenue',
          value: 0,
          chart: {},
        },
        roi: {
          name: 'ROI ',
          value: 0,
          chart: {},
        },
        conversions: {
          name: 'Conversions',
          value: 0,
          chart: {},
        },
        epc: {
          name: 'EPC',
          value: 0,
          chart: {},
        },
      },
      basicStatsChart,
      perforamanceStatsChart,
      user: '',
      performanceType: {},
      winLossType: {},
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
        defaultHidden: [],
      },
      tsAccounts: [],
      table: {
        fields: [
          {
            header: { content: () => 'Name', style: '' },
            item: {
              key: 'name',
              slot: 'name',
              sortable: true,
              searchable: true,
              content: (item) => item.name,
              style: {},
            },
          },
          {
            header: { content: () => 'Impressions', style: '' },
            item: {
              key: 'impressions',
              sortable: true,
              searchable: true,
              content: (item) => item.impressions,
              style: { textAlign: 'center', position: 'relative' },
            },
          },
          {
            header: { content: () => 'Clicks', style: '' },
            item: {
              key: 'clicks',
              sortable: true,
              searchable: true,
              content: (item) => item.clicks,
              style: { textAlign: 'center', position: 'relative' },
            },
          },
          {
            header: { content: () => 'Amount Spent', style: '' },
            item: {
              key: 'cost',
              sortable: true,
              searchable: true,
              content: (item) => this.ui.table.spent(item.cost),
              style: { textAlign: 'center', position: 'relative' },
            },
          },
          {
            header: { content: () => 'Revenue', style: '' },
            item: {
              key: 'revenue',
              sortable: true,
              searchable: true,
              content: (item) => this.ui.table.revenue(item.revenue),
              style: { textAlign: 'center', position: 'relative' },
            },
          },
          {
            header: { content: () => 'NET', style: '' },
            item: {
              key: 'net',
              sortable: true,
              searchable: true,
              content: (item) => this.ui.table.net(item.net),
              style: { textAlign: 'center', position: 'relative' },
            },
          },
          {
            header: { content: () => 'ROI', style: '' },
            item: {
              key: 'roi',
              sortable: true,
              searchable: true,
              content: (item) => this.ui.table.roi(item.roi),
              style: { textAlign: 'center', position: 'relative' },
            },
          },
        ],
        items: [],
        topWinners: [],
        topLoosers: [],
      },
      names: {
        net: 'Net',
        clicks: 'Clicks',
        roi: 'Roi',
        revenue: 'Revenue',
        cost: 'Amount Spent',
        epc: 'Epc',
        cpa: 'Cpa',
        conversions: 'Conversions',
      },
      isChartLoading: true,
      isLoading: true,
      isRefreshLoading: false,
      settings: {},
      userFields: {},
      revenueFrom: '',
      apiData: {
        totals: {},
        losers: [],
        winners: [],
        byTrafficSource: [],
        chartData: {},
      },
    };
  },
  computed: {
    performanceName() {
      if (this.performanceType[0]) {
        return this.performanceType[0].name;
      }
      return '';
    },
    $c_dateRange() {
      return dateHelper.getLabel(this.dateRangePicker.startDate, this.dateRangePicker.endDate);
    },
  },
  async created() {
    /* *********** Set Date Range ************ */
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRangePicker.startDate = this.$route.query.startDate;
      this.dateRangePicker.endDate = this.$route.query.endDate;
    } else {
      const dateRange = this.$settings.dashboardDateRange.getDateRange();
      if (dateRange) {
        this.dateRangePicker.startDate = dateRange.startDate;
        this.dateRangePicker.endDate = dateRange.endDate;
        this.$router.replace({ query: { ...this.$route.query, ...{ startDate: dateRange.startDate, endDate: dateRange.endDate } } });
      }
    }
    /* *********** ************** ************ */

    try {
      this.tsAccounts = await this.$apiStore.trafficSources.accounts();
      this.$_getPerformance();
      this.$watch('dateRangePicker', ({ startDate, endDate }) => {
        this.$_getPerformance();
        this.$settings.dashboardDateRange.setDateRange({ startDate, endDate });
      });
      this.preloader = false;
    } catch (error) {
      this.preloader = false;
      this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
    }
  },
  methods: {
    getFieldsWithDescription,
    getTrafficSourceLogo,
    $_prefixedKey(key) {
      return `${this.revenueFrom}_${key}`;
    },
    async $_getNativeStats(startDate, endDate) {
      try {
        this.isLoading = true;
        const [settings, [totals], byTrafficSource, winners, losers] = await Promise.all([
          this.$api.settings.getSettings(),
          this.$api.dashboard.getTotals(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')),
          this.$api.dashboard.getByTrafficSource(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')),
          this.$api.dashboard.getWinners(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')),
          this.$api.dashboard.getLosers(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')),
        ]);
        this.apiData = { totals, losers, winners, byTrafficSource };
        this.settings = settings;
        this.userFields = await this.$_getUserFields();
        this.revenueFrom = this.$_getRevenueSource(settings, this.tsAccounts.find((ts) => ts?.linkedTrackers?.length > 0));
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    $_handleAllStats({ tables, chart }) {
      const { totals, losers, winners, byTrafficSource, chartData } = this.apiData;
      const [
        performanceTotals,
        performanceLosers,
        performanceWinners,
        performanceByTrafficSource,
        performanceChartData,
      ] = this.$_getDataBasedOnSource([totals, losers, winners, byTrafficSource, chartData]);
      if (tables) {
        this.$_setTableHeaders();
        this.$_handlePerformanceTotals(performanceTotals);
        this.$_handlePerformanceTable(performanceByTrafficSource);
        this.$_handleWLStats(performanceWinners, performanceLosers);
      }
      if (chart) {
        this.$_handlePerformanceData(performanceChartData);
        this.$_handlePerformanceChart(performanceChartData);
      }
    },
    async $_getUserFields() {
      let userFields = [];
      try {
        const avMetrics = await this.$apiStore.dashboard.getAvailableMetrics();
        userFields = avMetrics.fields.map((item) => ({ ...item, key: item.item.key }));
      } catch (err) {
        const fieldsAndFilters = getFieldsAndFilters();
        userFields = fieldsAndFilters.fields;
      }
      return userFields;
    },
    $_getDataBasedOnSource(data) {
      return data.map((item) => {
        if (Array.isArray(item)) {
          return item.map((i) => i[this.revenueFrom]);
        }
        return item[this.revenueFrom];
      });
    },
    async $_getPerformance() {
      const { startDate, endDate } = this.dateRangePicker;

      try {
        this.isChartLoading = true;
        const [chartData] = await Promise.all([this.$api.dashboard.getByDate(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')), this.$_getNativeStats(startDate, endDate)]);
        this.apiData.chartData = chartData;
        this.$_handleAllStats({ tables: true, chart: true });
        this.isChartLoading = false;
        setTimeout(() => { if (this.$refs.mainChart) this.$refs.mainChart.toggleSeries('Clicks'); });
      } catch {
        this.$_handlePerformanceTotals();
        this.isChartLoading = false;
      }
    },
    $_handleBasicStats(performanceTotals, performanceData) {
      this.$_handlePerformanceTotals(performanceTotals);
      this.$_handlePerformanceData(performanceData);
    },
    $_setRevenueSource() {
      this.revenueFrom = this.revenueFrom === 'tr' ? 'ts' : 'tr';
    },
    $_setSettings() {
      return this.$api.settings.setSettings({ ...this.settings, revenue_from: this.revenueFrom });
    },
    $_getRevenueSource(settings, hasTracker) {
      return settings?.revenue_from || (hasTracker ? 'tr' : 'ts');
    },
    $_setTableHeaders() {
      const noPrefix = ['cost', 'impressions'];
      const exclude = ['name'];
      this.table.fields = this.table.fields.map((field) => {
        if (exclude.includes(field.item.key)) return field;
        const configKey = noPrefix.includes(field.item.key) ? field.item.key : this.$_prefixedKey(field.item.key);
        const config = this.userFields.find((item) => (item.key === configKey));
        const info = getFieldTextAndDescription(config);
        return { ...field, header: { ...field.header, info: info.description, customClass: 'custom-tooltip max-width-300' } };
      });
    },
    $_handlePerformanceTotals(performanceTotals) {
      const format = {
        cost: 'money',
        revenue: 'money',
        net: 'money',
        roi: 'percentage',
        cpa: 'money',
        epc: 'money',
      };
      this.statsList.forEach((key) => {
        const formatKey = format[key];
        const noPrefix = ['cost'];
        const configKey = noPrefix.includes(key) ? key : this.$_prefixedKey(key);
        const config = this.userFields.find((item) => (item.key === configKey));
        if (formatKey) {
          this.stats[key].value = this.ui.table[formatKey]((performanceTotals && performanceTotals[key]) || 0);
        } else {
          this.stats[key].value = (performanceTotals && performanceTotals[key]) || 0;
        }
        this.stats[key].info = getFieldTextAndDescription(config);
      });

      // Reset
      this.statsList.forEach((stat) => {
        this.stats[stat].chart = {};
      });
    },
    $_handlePerformanceData(performanceData) {
      const { categories, chartStats } = this.$_mapChartsData(performanceData);

      Object.keys(chartStats).forEach((chartKey) => {
        this.stats[chartKey].chart = {
          ...basicStatsChart,
          series: [
            {
              data: chartStats[chartKey].map((num) => num.toFixed(3)),
              name: this.names[chartKey],
            },
          ],
          xaxis: {
            ...basicStatsChart.xaxis,
            categories,
          },
        };
      });
    },
    $_handlePerformanceChart(performanceData) {
      const { categories, chartStats } = this.$_mapChartsData(performanceData);
      const listItems = ['net', 'clicks', 'roi', 'revenue', 'cost'];

      const filtered = Object.keys(chartStats)
        .filter((key) => listItems.includes(key))
        .reduce((acc, key) => {
          acc.push({ data: chartStats[key].map((item) => parseFloat(item).toFixed(3)), name: this.names[key] });
          return acc;
        }, []);

      this.perforamanceStatsChart = { ...perforamanceStatsChart, series: filtered, xaxis: { ...perforamanceStatsChart.xaxis, categories } };
    },
    async $_handlePerformanceTable(data) {
      this.table.items = data;
    },
    $_handleWLStats(winners, losers) {
      this.table.topWinners = winners;
      this.table.topLoosers = losers;
    },
    $_mapChartsData(data) {
      const chartStats = {};
      const categories = data.map((item) => (item.performance_date || item.datetime).split('T')[0]);

      this.statsList.forEach((item) => {
        chartStats[item] = data.map((e) => e[item]);
      });

      return { categories, chartStats };
    },

    $_getTrafficSourceId(name) {
      const trafficSource = this.tsAccounts.find((item) => item.trafficSourceType.uniqueName === name);
      return trafficSource?.trafficSourceType?.id;
    },
    async goToCampaign(id) {
      const { startDate, endDate } = this.dateRangePicker;
      this.$router.push({ path: `/campaigns/${id}?startDate=${startDate}&endDate=${endDate}&tab=publishers` });
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getPerformance();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    async switchRevenueSource() {
      try {
        this.$_setRevenueSource();
        this.$_handleAllStats({ tables: true, chart: true });
        await this.$_setSettings();
      } catch (err) {
        console.log({ err });
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-container {
  .section-header {
    margin-bottom: 3rem;
  }

  .account-logo {
    width: 2.2rem;
    margin-inline: 10px 5px;
  }
  .switch-button .busy-overlay {
    top: 0.1rem;
    left: 0.2rem;
  }
  &__stats {
    margin-bottom: 2rem;

    .dashboard-basic-stats-skeleton {
      height: 17.1rem;
      margin-bottom: 2.5rem;
    }
  }

  &__main-chart {
    margin-bottom: 4rem;

    h2 {
      font-size: 1.9rem;
      color: $color-dark-gray;
      font-weight: 500;
    }

    .dashboard-main-chart-skeleton {
      height: 41.5rem;
    }

    .apexcharts-svg {

      .apexcharts-legend {
        top: 1.5rem !important;
        right: 2rem !important;
        &-series {
          display: flex !important;
          align-items: center;
          font-size: 1.2rem;
          color: $color-dark;
          margin-right: 1.5rem !important;

          .apexcharts-legend-text {
            color: $black-800 !important;
          }

          &:first-child {
            margin-right: 1rem;
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  &__performance-table {
    margin-bottom: 5rem;
    h2 {
      font-size: 1.9rem;
      color: $color-dark-gray;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }

    .dashboard-performance-table-skeleton {
      height: 27.5rem;
    }
  }

  &__winners-loosers-table {
    display: flex;
    justify-content: space-between;

    .dashboard-winners-skeleton,
    .dashboard-loosers-skeleton {
      height: auto;
      width: calc(100% / 2 - .8rem);
    }

    .optimizer-table {
      width: calc(100% / 2 - .8rem);
    }

    .table-holder {
      max-height: 30rem;
      @include small-scrollbar();
    }
  }
  .datatable-wrapper table tbody tr > td .field {
        max-width: 30rem;
  }
  h2 {
    font-size: 1.9rem;
    color: $color-dark-gray;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .optimizer-table {
    margin-bottom: 0;

    .datatable-wrapper {
      border-radius: 1rem;

      .table-holder {
        margin-bottom: 0;
        border-radius: 1rem;
      }
    }
    border-radius: 1rem;
  }
}

  .apexcharts-tooltip-text-label,
  .apexcharts-legend-text {
    text-transform: capitalize;
  }
</style>
