<template>
  <div class="rule-widgets-exclude-wraper">
    <!-- <div class="col d-flex justify-content-between">
      <h5 class="mb-0">{{ $options.label }} Filtering
        <i class="fa fa-info-circle" style="font-size: 1rem;cursor: pointer" v-b-popover.html.hover="$options.info"></i></h5>
    </div> -->
    <div class="row">
      <div class="col-auto pr-1">
        <vue-opti-select-light
          class="optimizer-select"
          :value="type"
          @change="(option) => { type = option.value }"
          :options="[{ content: 'Select Filter', value: null }, { content: 'Include', value: 'INCLUDE' }, { content: 'Exclude', value: 'EXCLUDE' }]"
          button-placeholder="Select Type"
          single
          button-block
        />
        <!-- <vue-opti-select
          :placeholder="'Select Type'"
          :list="[{ content: 'Select Filter', value: null }, { content: 'Include', value: 'INCLUDE' }, { content: 'Exclude', value: 'EXCLUDE' }]"
          v-model="type"
        >
        </vue-opti-select> -->
      </div>
      <div class="col-auto exclude-form-wraper pl-1">
        <vue-tags-input
          :class="{ invalid: $_hasError('widgetTag') === false }"
          v-model="tag"
          :disabled="type === null"
          :tags.sync="widgets"
          @tags-changed="newTags => widgets = newTags"
          @before-adding-tag="$_beforeAddingTag"
          :separators="[',', ';']"
          :placeholder="`Add ${$options.label}s ( comma separated )`"
          name="widgetTag"
          v-validate="{ required: type !== null }"
          data-vv-validate-on="tags-changed"
          data-vv-value-path="tagsCopy"
          :data-vv-as="`${$options.label}s`"
        />
      </div>
      <div class="col-md-auto">
        <b-button-group size="sm" class="widgets-buttons">
          <b-btn variant="outline-info"
                 v-clipboard="$_copyToClipboard"
                 @success="$_clipboardSuccessHandler"
                 @error="$_clipboardErrorHandler"
          >Copy to clipboard</b-btn>
          <b-btn variant="outline-info" @click="$_clearWidgets">
            Clear {{ $options.label }}s
          </b-btn>
        </b-button-group>
      </div>
    </div>
    <small class="d-inline-block" v-if="$_hasError('widgetTag') === false" style="color: #ff5454;">{{ $_getError('widgetTag') }}</small>
  </div>
</template>

<script>
import component from '../mixins/component';
import VueTagsInput from '@johmun/vue-tags-input';
import notifications from '@sh/mixins/notifications';
// import { VueOptiSelect } from 'vue-opti-select';

export default {
  name: 'RuleWidgetsExclude',
  components: {
    VueTagsInput,
    // VueOptiSelect,
  },
  mixins: [component, notifications],
  props: {
    value: { type: Object, default: () => ({ type: 'EXCLUDE', widgets: [] }) },
  },
  data() {
    return {
      tag: '',
      widgets: [],
      type: null,
    };
  },
  created() {
    // Default value
    this.$options.label = this.ruleModel.rule.components.widgetsExclude.label || 'Widget';
    this.type = this.value.type || null;
    this.value.widgets.forEach((item) => {
      this.widgets.push({ text: item.id });
    });
    // Emit model
    this.$watch('widgets', () => {
      this.$_emit();
    }, { deep: true });

    this.$watch('type', (value) => {
      if (value === null) {
        this.$_removeError('widgetTag');
        this.$_clearWidgets();
      }
      this.$_emit();
    });

    // this.$options.info = `${this.$options.label} Filtering allows you to specify a list of ${this.$options.label.toLowerCase()}s that you want to exclude or include in the action of the rule.
    //                       <br><b>Include</b>: the rule will only work with the ${this.$options.label.toLowerCase()}s that you specify in the list
    //                       <br><b>Exclude</b>: the rule will only work with the ${this.$options.label.toLowerCase()}s that are NOT on this list`;
  },
  methods: {
    async validate() {
      try {
        if (this.widgets.length) {
          return true;
        }
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_beforeAddingTag(data) {
      if (data.tag.text) {
        data.tag.text = data.tag.text.trim();
        data.addTag();
      }
    },
    $_clearWidgets() {
      this.widgets = [];
    },
    $_copyToClipboard() {
      return this.widgets.map((widget) => widget.text).join(',');
    },
    $_clipboardSuccessHandler() {
      this.$n_infoNotification({ title: `${this.widgets.length} ${this.$options.label}${this.widgets.length > 1 ? 's' : ''} copied to clipboard` });
    },
    $_clipboardErrorHandler() {
      this.$n_failNotification({ title: 'Clipboard error' });
    },
    $_emit() {
      const model = { type: this.type, widgets: this.widgets.map((item) => ({ id: item.text })) };
      this.$emit('input', model);
    },
  },
};
</script>

<style lang="scss">
.rule-widgets-exclude-wraper {
  .optimizer-select {
    min-width: 15rem;
    margin-right: 1rem;
    padding-right: 0;
  }

  .exclude-form-wraper {
    padding-right: 0;
    flex: 1;

    .vue-tags-input {
      max-width: 100%;

      &.ti-disabled {
        cursor: not-allowed;

        input {
          cursor: not-allowed;
        }
      }

      .ti-input {
        background: $color-white;
        color: $black-500;
        font-size: 1.4rem;
        height: auto;
        border: .1rem solid $color-light-gray;
        border-radius: .6rem;

        .ti-new-tag-input {
          font-size: 1.4rem;

          &::placeholder {
            color: $black-300;
          }
        }

        .ti-tags {
          .ti-tag {
            background: #E4EAEE;
            border-radius: .6rem;
            color: $text-primary;
            padding: .2rem 1rem;

            &.ti-deletion-mark {
              background-color: $color-red;
              color: $color-white;
            }

            .ti-content {
              margin-top: .2rem;
            }

            .ti-icon-close {
              margin-left: .2rem;
              margin-top: .2rem;
              color: black;
            }
          }
        }
      }
    }
  }

  .widgets-buttons {
    display: flex;
    padding-top: .4rem;

    .btn {
      border: none;
      font-size: 1.375rem;
      color: $color-light-blue;
      display: flex;

      &:first-of-type {
        margin-right: .6rem;

        &::after {
          content: '';
          display: block;
          width: .1rem;
          height: 1.4rem;
          background: $color-light-blue;
          right: -.4rem;
          top: .5rem;
          position: absolute;
        }
      }

      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: $blue-400;
      }
    }
  }

  // .exclude-form-wraper {
  //   @mixin placeholder {
  //     &::placeholder               {@content}
  //     &::-webkit-input-placeholder {@content}
  //     &:-moz-placeholder           {@content}
  //     &::-moz-placeholder          {@content}
  //     &:-ms-input-placeholder      {@content}
  //   }
  //   .vue-tags-input {
  //     max-width: 100% !important;
  //     .input {
  //       border: 1px solid #e1e6ef;
  //     }
  //     .new-tag-input-wrapper input {
  //       border: none !important;
  //       @include placeholder {
  //           color: #9faecb;
  //           font-weight: 500;
  //           font-size: 14px;
  //       }
  //     }
  //     .tag {
  //       background-color: #f0f0f0;
  //       color: #333;
  //       border: 1px solid #ccc;
  //       border-radius: 1px;
  //       line-height: 13px;
  //       font-size: 12px;
  //       &.deletion-mark {
  //         background-color: #cac9c9!important;
  //       }
  //     }
  //   }
  // }
}
</style>
