/* eslint-disable */
import _ from 'lodash';
import ui from '@sh/helpers/ui';
import symbols from '@sh/helpers/symbols';

/** ********** Helpers ********* * */
const getLeafValue = (key, obj) => {
  try {
    const keys = key.split('.');
    let pointerValue = obj;
    keys.forEach((k) => { pointerValue = pointerValue[k]; });
    return typeof pointerValue !== 'undefined' ? pointerValue : '-';
  } catch (error) {
    return '-';
  }
};

const searchField = (obj, _keys) => {
  let _field = null;
  if (obj[`${_keys.key}::${_keys.uniqueName}`]) _field = obj[`${_keys.key}::${_keys.uniqueName}`];
  else if (obj[_keys.key]) _field = obj[_keys.key];
  return _field;
};
/** ********** ******* ********* * */

const setPrecision = (val, fixed, customMetric = false) => (isNaN(val) ? '-' : ui[`${customMetric ? 'cmToFixed' : 'toFixed'}`](val, fixed));

const stringFormat = (val) => `${val}`;

const currencyFormat = (val, symbol) => (isNaN(val) ? '-' : `${val >= 0 ? '' : '-'}${symbol || '$'}${Math.abs(val)}`);

const percentFormat = (val) => (isNaN(val) ? '-' : `${val}%`);

const totalsValue = (val, hasTotals) => (hasTotals === false || (typeof val === 'string' && val.includes('undefined')) ? '-' : val);

const timeFormat = (val) => (isNaN(val) ? '-' : ui.timeFormat(val));

const signColorStyle = (val, nr) => (val && !isNaN(nr) ? ui.signColorStyle(val, nr) : '-');

const getComparisonFieldContent = (val, absoluteVal, percentageVal, format, traffic_source_currency) => {
  absoluteVal = setPrecision(absoluteVal, 2, false);
  percentageVal = setPrecision(percentageVal, 1, false);
  percentageVal = percentFormat(percentageVal);
  let color = absoluteVal > 0 ? 'green' : 'red';
  color = (absoluteVal === '-' && percentageVal === '-') || absoluteVal === 0 ? 'gray' : color;
  const showDiff = absoluteVal !== '-' && percentageVal !== '-';
  if (format === 'currency') {
    absoluteVal = currencyFormat(absoluteVal, symbols.get(traffic_source_currency) || '$');
  }
  return `<span class="d-flex flex-column comparison-container">
    <a style="font-size: 12px; line-height: 12px; color: ${color}">${val}</a>
    <span style="font-size: 10px; line-height: 12px; display: ${!showDiff ? 'none' : 'initial'}">
      <a style="color: ${color}; cursor: pointer;" title="${absoluteVal} compared to current period.">${absoluteVal}</a>
      <a style="color: ${color}; cursor: pointer;" title="${percentageVal} compared to current period.">(${percentageVal})</a>
    </span>
  </span>`;
}

const applyOptions = (field, options) => {
  options.to.forEach((to) => {
    let pointer = null;
    if (to === 'value') pointer = field.item;
    else if (to === 'total') pointer = field.item.total;

    if (pointer) {
      const { content } = pointer;
      pointer.content = (item) => {
        const val = content(item);
        let newVal = val;
        let absoluteDiffVal = null;
        let percentageDiffVal = null;
        if (field.item.key.includes('comparison')) {
          const current = item[field.item.parent_key];
          const previous = item[field.item.key];
          const diff = current - previous;
          absoluteDiffVal = diff === 0 ? 0 : diff;
          percentageDiffVal = diff === 0 ? 0 : ((current - previous) / ((current + previous) / 2)) * 100;
        }

        switch(options.format) {
          case 'string': {
            newVal = stringFormat(val);
            break;
          }
          case 'number':
          case 'numeric': {
            newVal = setPrecision(val, options.precision, !!field.customMetric);
            break;
          }
          case 'percentage': {
            newVal = setPrecision(val, options.precision, !!field.customMetric);
            newVal = percentFormat(newVal);
            break;
          }
          case 'currency': {
            newVal = setPrecision(val, options.precision, !!field.customMetric);
            newVal = currencyFormat(newVal, symbols.get(item.traffic_source_currency) || '$');
            break;
          }
          case 'time': {
            newVal = setPrecision(val, options.precision);
            newVal = timeFormat(newVal);
            break;
          }
        }

        /* ************ Style ************** */
        if (options.style === 'color') {
          newVal = signColorStyle(newVal, val);
        }
        /* ************ ***** ************** */

        /* ************ Totals ************** */
        if (to === 'total') {
          newVal = totalsValue(newVal, options.hasTotals);
        }
        /* ************ ***** ************** */
        if (field.item.key.includes('comparison') && to === 'value') {
          return getComparisonFieldContent(newVal, absoluteDiffVal, percentageDiffVal, options.format, item.traffic_source_currency);
        } else {
          return newVal;
        }
      };
    }
  });
};

class Field {
  constructor(field) {
    this._field = field;
    this._options = {
      format: 'string',
      precision: 0,
      style: '',
      to: ['value', 'total'],
    };
  }

  setOptions(option = {}) {
    Object.assign(this._options, option);
    return this;
  }

  get(data = {}) {
    const field = _.merge({}, this._field, data);
    applyOptions(field, this._options);
    return field;
  }

  getRaw() {
    return this._field;
  }
}

class Fields {
  constructor() {
    this._fields = {};
    this._defaultTotalStyle = { background: '#fffdf5', fontWeight: 'bold', textAlign: 'center' };
    this._defaultProps = {
      header: { content: 'Default' },
      item: {
        sortable: true,
        style: { textAlign: 'center' },
        total: {
          style: this._defaultTotalStyle,
        },
      },
    };
  }

  create(data = {}) {
    if (!data.item.key) return false;
    // Set default content() if not defined
    const field = _.merge({}, this._defaultProps, data);
    if (!field.item.content) {
      // Nested Keys support
      field.item.content = (item) => getLeafValue(field.item.key, item);
    }
    if (field.item.total && !field.item.total.content) field.item.total.content = (item) => (typeof item[field.item.key] !== 'undefined' ? item[field.item.key] : '-'); // Nested Keys support
    return new Field(field);
  }

  set(fields) {
    this._fields = fields;
  }

  getField(keys, data = {}) {
    if (!keys?.level || !keys.key) throw Error('Invalid VueOptiTable header item');
    const fieldData = { ...data };
    const { options } = fieldData;

    let field = null;
    // Search fields otherwise default field
    if (this._fields[keys.level]) field = searchField(this._fields[keys.level], keys);
    if (!field) field = searchField(this._fields.DEFAULTS, keys);
    if (!field) {
      // If default field set default params
      field = this.create({
        item: {
          key: keys.key,
        },
      });
    }
    if (options) {
      // delete fieldData.options
      field.setOptions(options);
    }
    return field.get(fieldData);
  }
}

export default Fields;
