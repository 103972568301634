import { permissionManager } from '@paperclicks/unleash-client';
import AbortController from '@sh/helpers/AbortController';
import TrafficSourceConfig from '@sh/helpers/TrafficSourceConfig';
import { TrafficSource } from '@sh/types';
import { cloneDeep, isArray, isNull, isObject, isUndefined } from 'lodash';

const timeout = 300;
const pageSize = 60;
const TheOptimizer = 'TheOptimizer';
const LoadingMessage = 'Loading data, please wait...';
const EmptyTableCell = 'N/A';
const unleashFeatures = ['media-manager'];

const canAccess = async (feature: string): Promise<boolean> => {
  try {
    const { hasPermission } = await permissionManager.check(feature, {}, true);
    return hasPermission;
  } catch {
    return false;
  }
};

const roundNumber = (number: number, isPercentage = false) => {
  if (number > 10) {
    return Math.round(number);
  }
  const divider = isPercentage ? 10000 : 100;
  return Math.round((number + Number.EPSILON) * divider) / divider;
};

const deleteEmptyFields = (data: Record<string, any>) => {
  const clonedData = cloneDeep(data);

  for (const key in clonedData) {
    if (
      (!clonedData[key] && !isObject(clonedData[key])) ||
      (isObject(clonedData[key]) &&
        ((isArray(clonedData[key]) && !clonedData[key].length) || !Object.keys(clonedData[key]).length))
    ) {
      delete clonedData[key];
    }
  }
  return clonedData;
};

const addImageEditorScript = () => {
  const imageEditorScriptID = 'image-editor';

  if (!document.getElementById(imageEditorScriptID)) {
    const imageEditorScript = document.createElement('script');
    imageEditorScript.setAttribute('id', imageEditorScriptID);
    imageEditorScript.setAttribute('src', '/sh_static/scripts/filerobot-image-editor-v4.7.0.min.js');
    document.head.appendChild(imageEditorScript);
  }
};

const getConfigData = (trafficSourceName: string) => cloneDeep(TrafficSourceConfig[trafficSourceName]);

const imageToBase64 = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const textToInsert = (text: string, tags: string[]) => {
  text = text.trim();

  for (const tag of tags) {
    if (text === tag) {
      text = '';
      break;
    }
  }
  return text;
};

const pluralize = (count: number, noun: string, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

const onImageError = (event: Event) => {
  const eventTarget = event.target as HTMLImageElement;

  if (eventTarget && !eventTarget?.dataset.image_error) {
    eventTarget.src = '/sh_static/other/no-image.svg';
  }
};

const isVideoUrl = (data?: string) => {
  const link = data ?? '';

  if (link.includes('mime_type=video') || link.includes('www.youtube.com/embed/')) {
    return true;
  }

  const videoExt = ['mp4', 'webm', 'ogg', 'mov'];
  const url = link.split('.');
  const ext = url[url.length - 1];

  return videoExt.includes(ext);
};

const getLogoUniqueName = (name: TrafficSource) => {
  switch (name) {
    case 'Gemini':
      return 'YahooGemini';
    case 'RevContent':
      return 'Revcontent';
    case 'VoluumDSP':
      return 'VolumDSP';
    default:
      return name;
  }
};

const getTrafficSourceLogo = (name: TrafficSource) =>
  `https://mediamanager-images.theoptimizerimg.com/config/${getLogoUniqueName(name)}.svg`;

const getNestedValue = (from: any, selectors: string) =>
  selectors
    .replace(/\[([^\]]*)]/g, '.$1.')
    .split('.')
    .filter((item) => item !== '')
    .reduce((acc, cur) => {
      if (acc instanceof Object) {
        return acc[cur];
      }
      return undefined;
    }, from);

function isDefined<T>(value: T): value is NonNullable<T> {
  return !(isUndefined(value) || isNull(value));
}

const defaultColumnsWidth = {
  headline: 350,
  traffic_source_unique_name: 180,
  traffic_source_account_name: 300,
  traffic_source_item_id: 300,
  name: 300,
  date_range: 150,
  'additional_fields.daily_budget': 180,
  'additional_fields.budget': 120,
  'additional_fields.bid_strategy': 210,
};

const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const AccountNameKey = 'traffic_source_account_name';

function validateData<T>(
  data: NullableType<Partial<T>>,
  validate: Partial<Record<keyof T, boolean>>
): asserts data is T {
  if (!data) {
    throw new Error('No data found');
  }

  Object.entries(validate).forEach(([key, required]) => {
    if (required && !(key in data)) {
      throw new Error(`No ${key} found`);
    }
  });
}

function currencyFormatter(value: NullableType<number>, currency = '$') {
  if (isDefined(value)) {
    return '';
  }

  switch (currency) {
    case '$':
      return `${currency}${value}`;
    default:
      return `${value}${currency}`;
  }
}

function isValidTag(input: string): boolean {
  /** This regex allows strings that contain letters, numbers, and spaces, but with a maximum length of 15 characters.
   * The \p{L} matches any kind of letter from any language, \p{N} matches any kind of number, and \s matches spaces.
   * The u flag is necessary to support Unicode.
   */
  const regex = /^[\p{L}\p{N}\s]{1,15}$/u;

  /** This regex checks that the string is not only spaces */
  const nonEmptyStringRegex = /^(?!\s*$).+/;

  return regex.test(input) && nonEmptyStringRegex.test(input);
}

export {
  AbortController,
  EmptyTableCell,
  LoadingMessage,
  TheOptimizer,
  addImageEditorScript,
  canAccess,
  defaultColumnsWidth,
  deleteEmptyFields,
  getConfigData,
  getLogoUniqueName,
  getNestedValue,
  getTrafficSourceLogo,
  imageToBase64,
  isDefined,
  isVideoUrl,
  onImageError,
  pageSize,
  pluralize,
  roundNumber,
  textToInsert,
  timeout,
  unleashFeatures,
  AccountNameKey,
  capitalizeFirstLetter,
  validateData,
  currencyFormatter,
  isValidTag,
};
