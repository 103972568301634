<template>
  <div :class="`cc-date-picker-wrapper component-${model.name}`">
    <b-form-group :state="$_hasErrorState()" class="mb-0">
      <div class="cc-date-picker-wrapper__container">
        <datepicker
          v-model="rawDate"
          @input="$_input"
          name="date"
          :data-vv-as="model.label"
          v-validate="model['v-validate']"
          data-vv-validate-on="input|closed|cleared"
          :bootstrap-styling="true"
          :placeholder="model.placeholder"
          :calendar-button="true"
          format="yyyy-MM-dd"
          calendar-button-icon="fa fa-calendar"
          :disabled-dates="model.disabledDate"
          :input-class="`cc-date-input ${ $_hasError('date') === false ? 'is-invalid' : '' }`"
          :clear-button="true"
          ref="date"
        >
        </datepicker>
        <div v-if="model.time" class="time-select-wrapper ml-1">
          <b-input-group :state="$_hasError('time')" :append="model.time.right">
            <vue-opti-select-light
              :class="[{ 'has-error': $_hasError('time') === false }, 'optimizer-select time-select']"
              name="time"
              v-validate="model['v-validate']"
              data-vv-validate-on="input|update"
              data-vv-value-path="$c_model"
              :value="time"
              @change="({ value }) => { time = value }"
              @input="$_input"
              :options="$options.timeOptions"
              label-key="text"
              single
              button-block
            />
          </b-input-group>
        </div>
      </div>
      <small slot="invalid-feedback">{{ $_getError('date') || $_getError('time') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
  name: 'CcDatePicker',
  components: {
    Datepicker,
  },
  mixins: [component],
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      rawDate: null,
      time: null,
      dateTime: '',
    };
  },
  created() {
    // Options
    this.$options.timeOptions = [
      { value: null, text: '', disabled: true },
      { value: '00:00:00', text: '00:00' },
      { value: '01:00:00', text: '01:00' },
      { value: '02:00:00', text: '02:00' },
      { value: '03:00:00', text: '03:00' },
      { value: '04:00:00', text: '04:00' },
      { value: '05:00:00', text: '05:00' },
      { value: '06:00:00', text: '06:00' },
      { value: '07:00:00', text: '07:00' },
      { value: '08:00:00', text: '08:00' },
      { value: '09:00:00', text: '09:00' },
      { value: '10:00:00', text: '10:00' },
      { value: '11:00:00', text: '11:00' },
      { value: '12:00:00', text: '12:00' },
      { value: '13:00:00', text: '13:00' },
      { value: '14:00:00', text: '14:00' },
      { value: '15:00:00', text: '15:00' },
      { value: '16:00:00', text: '16:00' },
      { value: '17:00:00', text: '17:00' },
      { value: '18:00:00', text: '18:00' },
      { value: '19:00:00', text: '19:00' },
      { value: '20:00:00', text: '20:00' },
      { value: '21:00:00', text: '21:00' },
      { value: '22:00:00', text: '22:00' },
      { value: '23:00:00', text: '23:00' },
    ];
    // Bind Model
    this.$watch('value', (value) => {
      if (value !== '') {
        const spl = value.split(' ');
        this.rawDate = new Date(spl[0]);
        this.time = spl[1] || null;
      } else {
        this.rawDate = null;
        this.time = null;
      }
    }, { immediate: true });
  },
  mounted() {
    // Make Input ReadOnly
    try {
      const el = this.$refs.date.$el.getElementsByClassName('cc-date-input')[0];
      el.setAttribute('readonly', true);
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    $_input() {
      if (this.rawDate !== null) {
        this.dateTime = moment(this.rawDate).format('YYYY-MM-DD');
        if (this.model.time && this.time) this.dateTime += ` ${this.time}`;
      } else {
        this.dateTime = '';
      }
      this.$_emit('dateTime');
    },
    $_hasErrorState() {
      if (this.$_hasError('date') === false) return false;
      if (this.model.time && this.$_hasError('time') === false) return false;
      return null;
    },
  },
};
</script>

<style lang="scss">
.cc-date-picker-wrapper {
  // .vdp-datepicker {
  //   width: 200px;
  // }
  &__container {
    display: flex;

    .vdp-datepicker {
      flex: 1;
    }
  }

  .time-select-wrapper {

    .input-group {
      display: flex;
      height: 3.7rem;

      .optimizer-select {
        width: 8rem;

        .dropdown-toggle {
          height: 3.7rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &-append {
        background: #F4F9FC;
        border: .1rem solid $color-light-gray;
        border-radius: .6rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        align-items: center;
        color: $black-500;
        padding: 0;

        .input-group-text {
          background: transparent;
          border: none;
        }
      }
    }
  }
  .custom-select.is-invalid {
    border-color: #ff5454 !important;
  }
}
</style>
