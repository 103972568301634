import { getInitialDateRange } from '@/views/Tools/Reporting/helpers';
import { DateRange } from '@/views/Tools/Reporting/types';

export default class DateRangeFilter {
  constructor(private selected: DateRange = getInitialDateRange()) {}

  get options(): never {
    throw new Error('There are no options for this filter.');
  }

  get value(): DateRange {
    return this.selected;
  }

  set value(value: DateRange) {
    this.selected = value;
  }
}
