import '@/loaders/injections';
import { api, apiStore } from '@sh/services/api';
import session from '@sh/services/session';
import settings from '@sh/services/settings';
import socket from '@sh/services/socket';
import userLocalStorage from '@sh/services/userLocalStorage';
import Vue from 'vue';

Vue.prototype.$session = session;
Vue.prototype.$socket = socket;
Vue.prototype.$api = api;
Vue.prototype.$apiStore = apiStore;
Vue.prototype.$settings = settings;
Vue.prototype.$userLocalStorage = userLocalStorage;
Vue.prototype.$platform = process.env.VUE_APP_PLATFORM;
