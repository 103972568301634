import {
  BaseFieldType,
  FieldValue as BaseValue,
  NumericOperatorValue,
  Option as BaseOption,
  StringSearchOperatorValue,
  ValueType,
} from '@sh/types/Rules/Conditions';

export enum DateOperationsType {
  Add = 'add',
  Remove = 'remove',
}

export enum DateOperationsUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum BaseModel {
  Campaign = 'Campaign',
  Current = 'Current',
}

export type DateOperations = {
  type: DateOperationsType;
  unit: DateOperationsUnit;
  value: number;
};

export interface Option<T = BaseValue> extends BaseOption<T> {
  interval?: DateOperations[];
  operations?: DateOperations[];
  percentage?: { value: number };
  baseModel?: BaseModel;
  specificDate?: string;
}

export interface BaseField {
  type: BaseFieldType;
  valueType: ValueType;
  value: string | number;
  percentage?: Record<'value', number>;
}

export interface DateField extends BaseField {
  type: BaseFieldType.Date;
  valueType: ValueType.DateField | ValueType.DateString | ValueType.Now;
  value: string;
  baseModel?: BaseModel;
  operations?: DateOperations[];
}

export interface MetricField extends BaseField {
  type: BaseFieldType.Metric;
  valueType: ValueType.Number;
  baseModel: BaseModel;
  interval?: DateOperations[];
}

export interface EntityField extends BaseField {
  type: BaseFieldType.Entity;
  value: string;
  valueType: ValueType.String | ValueType.Number;
  baseModel: BaseModel;
}

export interface ConstantField extends Exclude<BaseField, 'percentage'> {
  type: BaseFieldType.Constant;
  value: string | number;
  valueType: ValueType.Number | ValueType.String;
}

export enum OperatorType {
  Numeric = 'Numeric',
  StatusOperator = 'StatusOperator',
  StringSearch = 'StringSearch',
}

export type Operator = {
  type: 'Operator';
  value: NumericOperatorValue | StringSearchOperatorValue;
  operatorType: OperatorType;
};

export type Field = DateField | ConstantField | EntityField | MetricField;

export type ConditionsV2 = {
  left: Field;
  operator: Operator;
  right: Field;
};

export type IntervalPreset = { value: DateOperations[]; text: string };

export enum CustomDateCondition {
  CreatedAt = 'custom_created_at',
  StartDate = 'custom_startDate',
  EndDate = 'custom_endDate',
}
