
import CcError from '@/views/Automation/CampaignCreatorV2/components/CcError/CcError.ts.vue';
import CcIcon from '@/views/Automation/CampaignCreatorV2/components/CcIcon/CcIcon.ts.vue';
import CcManualLocationAddModal from '@/views/Automation/CampaignCreatorV2/components/Modals/CcManualLocationAddModal.ts.vue';
import { FieldVariable } from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import Treeselect from '@riophae/vue-treeselect';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentRefs {
  $refs: {
    treeselect?: { closeMenu: () => void; clear: () => void };
    treeselectCountry?: { closeMenu: () => void; clear: () => void };
  };
}

type NestedOptions = SelectOption<string, 'id', 'label'> & {
  children?: SelectOption<string, 'id', 'label'>[];
};

interface ComponentData {
  treeSelectValue: SelectOption<string, 'id', 'label'>[];
  treeSelectValueCountries: Array<NestedOptions>;
  isModalOpened: boolean;
}

type TreeSelectOption = SelectOption<string, 'id', 'label'> & {
  children?: TreeSelectOption[];
};

type ComponentTypes = VueConstructor<Vue & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    Treeselect,
    CcIcon,
    CcError,
    CcManualLocationAddModal,
  },
  model: {
    prop: 'value',
    event: 'onValueChange',
  },
  props: {
    options: {
      type: Array as PropType<TreeSelectOption[]>,
    },
    field: {
      type: Object as PropType<FieldVariable<any, any>>,
    },
    loadOptions: {
      type: Function as PropType<
        (params: {
          action: string;
          searchQuery: string;
          callback: (options: SelectOption<string, 'id', 'label'>[]) => void;
        }) => void
      >,
    },
    value: {
      type: Array as PropType<SelectOption<string, 'id', 'label'>[]>,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    emptyPlaceholder: {
      type: String,
      default: 'No tags selected',
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      treeSelectValue: [],
      treeSelectValueCountries: [],
      isModalOpened: false,
    };
  },
  computed: {
    allOptions(): SelectOption<string, 'id', 'label'>[] {
      return this.flattenOptions(this.options);
    },
    selected(): SelectOption<string, 'id', 'label'>[] {
      return this.value || [];
    },
  },
  created() {
    this.treeSelectValue = [];
  },
  methods: {
    openModal() {
      this.isModalOpened = true;
    },
    onModalSave(value: string) {
      this.$emit('addManualLocation', value);
      this.isModalOpened = false;
    },
    flattenOptions(options: TreeSelectOption[]) {
      return options.reduce((acc: SelectOption<string, 'id', 'label'>[], option) => {
        acc.push(option);

        if (option.children) {
          acc = acc.concat(this.flattenOptions(option.children));
        }

        return acc;
      }, []);
    },
    onApply() {
      this.$emit(
        'onValueChange',
        this.treeSelectValueCountries.map((c) => {
          const idClean = c.id.split('-');
          const lastId = idClean[idClean.length - 1];
          return {
            ...c,
            id: lastId,
            label: c.label,
          };
        })
      );
      this.$refs.treeselectCountry?.clear();
      this.$refs.treeselectCountry?.closeMenu();
    },
    onCancel(value: any) {
      this.$refs.treeselect?.clear();
      this.$emit('onValueChange', this.treeSelectValue);
      this.treeSelectValue = [];
    },
    onModalCancel() {
      this.$refs.treeselectCountry?.closeMenu();
    },
    onTagDelete(id: string) {
      this.$emit('onRemove', id);
    },
  },
});
