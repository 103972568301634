<template>
  <div :class="`cc-textarea-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('input')" class="mb-0">
      <b-form-textarea
        class="optimizer-form-textarea"
        v-model="text"
        @input="$_emit('text')"
        name="input"
        size="sm"
        :rows="model.rows || 5"
        :placeholder="model.placeholder || ''"
        :data-vv-as="model.label"
        :state="$_hasError('input')"
        v-validate="model['v-validate']"
      ></b-form-textarea>
      <small slot="invalid-feedback">{{ $_getError('input') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcTextarea',
  mixins: [component],
  props: {
    value: { type: [String, Number, null], default: null },
  },
  data() {
    return {
      text: '',
    };
  },
  created() {
    // Bind Model
    this.$watch('value', (value) => {
      this.text = value;
    }, { immediate: true });
  },
};
</script>
