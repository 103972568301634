import currencySymbols from '@sh/helpers/symbols';
import { RuleTypes, TrafficSource } from '@sh/types';
import Vue from 'vue';

const dailyBudgetProperties: RuleTypes.Bulk.Properties = {
  method: 'changeDailyBudget',
  field: 'daily_budget',
  stateKey: 'dailyBudgetLiveEditState',
  title: 'Change Daily Budget',
  type: 'budget',
  filterBy: 'daily_budget',
  fieldOptionName: 'Daily Budget',
  isAdditionalField: true,
  columnName: 'Daily Budget',
  classSelector: 'additional_fields-daily_budget',
  payloadHandler: (i: RuleTypes.Bulk.Item) => ({ id: i.id, value: i.value }),
  getMethod: () => '',
  minInfo:
    'If the new adjusted budget value, is lower than the selected Min Budget Cap, then the budget will be set to the Min Budget Cap value.',
  maxInfo:
    'If the new adjusted budget value, is higher the selected Max Budget Cap, then the budget will  be set to the Max Budget Cap value.',
  hasConditionalMethod: false,
  action_name: 'Change Daily Budgets',
  limits: {
    step: '0.001',
    min: '0',
    max: '100',
  },
};

const totalBudgetProperties: RuleTypes.Bulk.Properties = {
  method: 'changeBudget',
  field: 'budget',
  stateKey: 'budgetLiveEditState',
  title: 'Change Budget',
  type: 'budget',
  filterBy: 'budget',
  fieldOptionName: 'Total Budget',
  isAdditionalField: true,
  columnName: 'Budget',
  classSelector: 'additional_fields-budget',
  payloadHandler: (i: RuleTypes.Bulk.Item) => ({ id: i.id, value: i.value }),
  getMethod: () => '',
  hasConditionalMethod: false,
  minInfo:
    'If the new adjusted budget value, is lower than the selected Min Budget Cap, then the budget will be set to the Min Budget Cap value.',
  maxInfo:
    'If the new adjusted budget value, is higher the selected Max Budget Cap, then the budget will  be set to the Max Budget Cap value.',
  action_name: 'Change Budgets',
  limits: {
    step: '0.001',
    min: '0',
    max: '500',
  },
};

const bidProperties: RuleTypes.Bulk.Properties = {
  method: 'changeBid',
  field: 'bid',
  stateKey: 'bidLiveEditState',
  title: 'Change Bid',
  type: 'bid',
  filterBy: 'bid',
  fieldOptionName: 'Bid',
  isAdditionalField: true,
  columnName: 'CPC',
  classSelector: 'additional_fields-bid',
  payloadHandler: (i: RuleTypes.Bulk.Item) => ({ id: i.id, value: i.value }),
  getMethod: () => '',
  action_name: 'Change CPCs',
  minInfo:
    'If the new adjusted bid value, is lower than the selected Min Bid Cap, then the bid will be set to the Min Bid Cap value.',
  maxInfo:
    'If the new adjusted bid value, is higher the selected Max Bid Cap, then the bid will  be set to the Max Bid Cap value.',
  hasConditionalMethod: false,
  limits: {
    step: '0.001',
    min: '0',
    max: '0.5',
  },
};

const targetCpaProperties: RuleTypes.Bulk.Properties = {
  method: 'changeBid',
  field: 'target_cpa',
  stateKey: 'targetCpaLiveEditState',
  title: 'Change Target CPA',
  type: 'bid',
  filterBy: 'target_cpa',
  fieldOptionName: 'Target CPA',
  isAdditionalField: true,
  columnName: 'Target CPA',
  classSelector: 'additional_fields-target_cpa',
  payloadHandler: (i: RuleTypes.Bulk.Item) => ({ id: i.id, value: i.value }),
  getMethod: () => '',
  minInfo:
    'If the new adjusted cpa value, is lower than the selected Min CPA Cap, then the cpa will be set to the Min CPA Cap value.',
  maxInfo:
    'If the new adjusted cpa value, is higher the selected Max CPA Cap, then the cpa will be set to the Max CPA Cap value.',
  action_name: 'Change Target CPAs',
  hasConditionalMethod: false,
  limits: {
    step: '0.001',
    min: '0',
    max: '0.5',
  },
};

const bidCapProperties: RuleTypes.Bulk.Properties = {
  method: 'changeBidCap',
  field: 'bid_cap',
  stateKey: 'bidCapLiveEditState',
  title: 'Change Bid Cap',
  type: 'bid',
  filterBy: 'bid_cap',
  fieldOptionName: 'bid_cap',
  isAdditionalField: true,
  columnName: 'Bid Cap',
  classSelector: 'additional_fields-bid_cap',
  payloadHandler: (i: RuleTypes.Bulk.Item) => ({ id: i.id, value: i.value }),
  getMethod: () => '',
  minInfo: '',
  maxInfo: '',
  action_name: 'Change Bid Cap',
  hasConditionalMethod: false,
  limits: {
    step: '0.001',
    min: '0',
    max: '2',
  },
};

const customBudgetProperties: RuleTypes.Bulk.CloneActionsProperties = {
  columnName: 'Budget',
  type: 'budget',
  field: 'daily_budget',
  minInfo:
    'If the new adjusted budget value, is lower than the selected Min Budget Cap, then the budget will be set to the Min Budget Cap value.',
  maxInfo:
    'If the new adjusted budget value, is higher the selected Max Budget Cap, then the budget will be set to the Max Budget Cap value.',
};

export default Vue.extend({
  data(): RuleTypes.Bulk.BulkOperationData {
    return {
      trafficSources: {
        Adskeeper: {
          name: 'Adskeeper',
          entities: {
            campaign: {
              bulkActions: {
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.daily_budget === 'null' || i.additional_fields.daily_budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  action_name: 'Change Total Budgets',
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            content: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  isAdditionalField: false,
                  field: 'cpc',
                  classSelector: 'cpc',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    contentId: [i.traffic_source_content_id.toString()],
                  }),
                },
              },
              entityName: 'Ads',
            },
          },
        },
        Mgid: {
          name: 'Mgid',
          entities: {
            campaign: {
              bulkActions: {
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.daily_budget === 'null' || i.additional_fields.daily_budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            content: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  isAdditionalField: false,
                  field: 'cpc',
                  classSelector: 'cpc',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    contentId: [i.traffic_source_content_id.toString()],
                  }),
                },
              },
              entityName: 'Ads',
            },
          },
        },
        Taboola: {
          name: 'Taboola',
          entities: {
            campaign: {
              bulkActions: {
                change_target_cpa_bulk: targetCpaProperties,
                change_bid_bulk: {
                  ...bidProperties,
                  action_name: 'Change Bids',
                  columnName: 'Bid',
                  title: 'Change Bids',
                  isNotAnEditableField: (i) => {
                    if (['Maximize conversions', 'Target CPA', '-'].includes(i.additional_fields.bid_strategy)) {
                      return true;
                    }
                    return false;
                  },
                },
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  title: 'Change Spending Limit',
                  columnName: 'Spending Limit',
                  action_name: 'Change Spending Limit',
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    if (i.additional_fields.daily_budget === 0 || i.additional_fields.daily_budget === null) {
                      return true;
                    }
                    return false;
                  },
                },
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  columnName: 'Budget',
                  hasConditionalField: true,
                  hasConditionalMethod: true,
                  stateKey: 'dailyBudgetLiveEditState',
                  conditionalField: (i) => {
                    if (i.daily_budget === 0 || i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  getMethod: (i) => {
                    if (i.daily_budget > 0) {
                      return 'changeDailyBudget';
                    }
                    return 'changeBudget';
                  },
                  payloadHandler: (i) => ({ id: i.id, value: i.value.toString(), budgetType: i.budget_type }),
                  action_name: 'Change Budgets',
                  title: 'Change Budgets',
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.daily_budget === 'null' || i.additional_fields.daily_budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            widget: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  classSelector: 'cpc',
                  field: 'cpc',
                  isAdditionalField: false,
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value,
                    widgetId: [
                      {
                        traffic_source_widget_id: i.traffic_source_widget_id,
                        publisher_id: i.publisher_id,
                        name: i.name,
                      },
                    ],
                  }),
                  isNotAnEditableField: (i) => {
                    if (i.cpc === null) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
          },
        },
        Outbrain: {
          name: 'Outbrain',
          entities: {
            campaign: {
              bulkActions: {
                change_bid_cap_bulk: bidCapProperties,
                change_target_cpa_bulk: targetCpaProperties,
                change_bid_bulk: {
                  ...bidProperties,
                  action_name: 'Change Bids',
                  columnName: 'Bid',
                },
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  hasConditionalMethod: true,
                  columnName: 'Budget',
                  hasConditionalField: true,
                  title: 'Change Budgets',
                  action_name: 'Change Budgets',
                  conditionalField: (i) => {
                    if (i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  getMethod: (i) => {
                    if (i.budget !== null) {
                      return 'changeBudget';
                    }
                    return 'changeDailyBudget';
                  },
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            content: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  isAdditionalField: false,
                  field: 'cpc',
                  classSelector: 'cpc',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    contentId: [i.traffic_source_content_id],
                  }),
                },
              },
              entityName: 'Ads',
            },
            section: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  field: 'cpc',
                  isAdditionalField: false,
                  classSelector: 'cpc',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    sectionIds: [i.traffic_source_section_id],
                  }),
                },
              },
            },
          },
        },
        RevContent: {
          name: 'RevContent',
          entities: {
            campaign: {
              bulkActions: {
                change_bid_bulk: bidProperties,
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            widget: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  classSelector: 'cpc',
                  columnName: 'Max CPC Bid',
                  action_name: 'Max CPC Bid',
                  field: 'cpc',
                  isAdditionalField: false,
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    widgetIds: [{ traffic_source_widget_id: i.traffic_source_widget_id }],
                  }),
                  isNotAnEditableField: (i) => {
                    if (i.cpc === null) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
          },
        },
        GoogleAds: {
          name: 'GoogleAds',
          entities: {
            campaign: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  action_name: 'Change Bids',
                  columnName: 'Bid',
                  title: 'Change Bids',
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.canChangeCpc === false) {
                      return true;
                    }
                    return false;
                  },
                },
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            widget: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  classSelector: 'cpc',
                  columnName: 'Default Max CPC',
                  field: 'cpc',
                  isAdditionalField: false,
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value,
                    widgetIds: [{ traffic_source_widget_id: i.traffic_source_widget_id }],
                  }),
                  action_name: 'Change Default Max CPCs',
                  isNotAnEditableField: (i) => {
                    if (i?.cpc === null) {
                      return true;
                    }
                    return false;
                  },
                },
              },
              entityName: 'Keyword',
            },
            adgroup: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  field: 'cpc_native',
                  classSelector: 'cpc_native',
                  filterBy: 'bid',
                  columnName: 'Default MAX CPC',
                  method: 'changeNativeBid',
                  isAdditionalField: false,
                  stateKey: 'nativeBidLiveEditState',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    nativeBid: i.value,
                    searchBid: i.searchBid,
                    adGroupId: [i.traffic_source_adgroup_id],
                  }),
                  action_name: 'Change Default Max CPCs',
                  isNotAnEditableField: (i) => {
                    if (!i?.cpc_native || i?.cpc_native === 0 || i?.cpc_native === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
          },
        },
        MediaGo: {
          name: 'MediaGo',
          entities: {
            campaign: {
              bulkActions: {
                change_target_cpa_bulk: targetCpaProperties,
                change_bid_bulk: bidProperties,
                change_budget_bulk: {
                  ...totalBudgetProperties,
                  payloadHandler: (i) => ({ id: i.id, value: i.value, budgetType: i.budget_type }),
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.daily_budget === 'null' || i.additional_fields.daily_budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
            widget: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  classSelector: 'additional_fields-cpc',
                  field: 'cpc',
                  isAdditionalField: false,
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    value: i.value.toString(),
                    widgetIds: [{ traffic_source_widget_id: i.traffic_source_widget_id }],
                  }),
                  isNotAnEditableField: (i) => {
                    if (i?.cpc === null) {
                      return true;
                    }
                    return false;
                  },
                },
              },
              entityName: 'Sites',
            },
          },
        },
        TikTok: {
          name: 'TikTok',
          entities: {
            campaign: {
              bulkActions: {
                change_bid_bulk: { ...bidProperties, columnName: 'Bid', action_name: 'Change Bids' },
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  stateKey: 'dailyBudgetLiveEditState',
                  classSelector: 'additional_fields-daily_budget',
                  hasConditionalMethod: true,
                  columnName: 'Budget',
                  hasConditionalField: true,
                  title: 'Change Budgets',
                  action_name: 'Change Budgets',
                  conditionalField: (i) => {
                    if (i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  getMethod: (i) => {
                    if (i.budget !== null) {
                      return 'changeBudget';
                    }
                    return 'changeDailyBudget';
                  },
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.budget === 'null' || i.additional_fields.budget === -1) {
                      return true;
                    }
                    if (i.additional_fields.daily_budget === null && i.additional_fields.budget === null) {
                      return true;
                    }
                    return false;
                  },
                },
              },
              cloneActions: {
                custom_budget: {
                  ...customBudgetProperties,
                  hasConditionalField: true,
                  conditionalField: (i) => {
                    if (i.daily_budget === null || i.daily_budget === 0) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                },
              },
            },
            adgroup: {
              bulkActions: {
                change_bid_bulk: {
                  ...bidProperties,
                  field: 'cpc_native',
                  stateKey: 'nativeBidLiveEditState',
                  method: 'changeNativeBid',
                  classSelector: 'cpc_native',
                  columnName: 'Bid',
                  isAdditionalField: false,
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    nativeBid: i.value,
                    searchBid: 0,
                    adGroupId: [i.traffic_source_adgroup_id],
                  }),
                  action_name: 'Change Bids',
                  isNotAnEditableField: (i) => {
                    if (!i?.cpc_native || i?.cpc_native === 0 || i?.cpc_native === -1) {
                      return true;
                    }
                    return false;
                  },
                },
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  method: 'changeAdGroupDailyBudget',
                  title: 'Change Budgets',
                  action_name: 'Change Budgets',
                  columnName: 'Budget',
                  hasConditionalMethod: true,
                  getMethod: (i) => {
                    if (i.daily_budget === null) {
                      return null;
                    }
                    return 'changeAdGroupDailyBudget';
                  },
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    adGroupId: [i.traffic_source_adgroup_id],
                    value: i.value,
                    dailyBudgetType: i.daily_budget_type || 'limited',
                  }),
                  hasConditionalField: true,
                  conditionalField: (i) => {
                    if (i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  skipMin: true,
                  isNotAnEditableField: (i) => {
                    if (
                      !i.additional_fields.daily_budget ||
                      i.additional_fields.daily_budget === 0 ||
                      i.additional_fields.daily_budget === -1
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              },
            },
          },
        },
        Facebook: {
          name: 'Facebook',
          entities: {
            campaign: {
              bulkActions: {
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  hasConditionalMethod: true,
                  title: 'Change Budgets',
                  action_name: 'Change Budgets',
                  columnName: 'Budget',
                  hasConditionalField: true,
                  conditionalField: (i) => {
                    if (i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  getMethod: (i) => {
                    if (i.daily_budget === null) {
                      return null;
                    }
                    if (i.budget !== null) {
                      return 'changeBudget';
                    }
                    return 'changeDailyBudget';
                  },
                  payloadHandler: (i) => ({
                    id: i.id,
                    value: i.value,
                    budgetType: i.budget !== null ? 'Lifetime Budget' : null,
                  }),
                  skipMin: true,
                  isNotAnEditableField: (i) => {
                    if (i.additional_fields.daily_budget === null && i.additional_fields.budget === null) {
                      return true;
                    }
                    if (i.additional_fields.daily_budget === 'null' || i.additional_fields.daily_budget === -1) {
                      return true;
                    }
                    return false;
                  },
                },
              },
              cloneActions: {
                custom_budget: {
                  ...customBudgetProperties,
                  hasConditionalField: true,
                  conditionalField: (i) => {
                    if (i.daily_budget === null || i.daily_budget === 0) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                },
              },
            },
            adgroup: {
              bulkActions: {
                change_daily_budget_bulk: {
                  ...dailyBudgetProperties,
                  getMethod: (i) => {
                    if (i.daily_budget === null) {
                      return null;
                    }
                    return 'changeAdGroupDailyBudget';
                  },
                  hasConditionalMethod: true,
                  method: 'changeAdGroupDailyBudget',
                  payloadHandler: (i) => ({
                    id: i.campaign_id,
                    adGroupId: [i.traffic_source_adgroup_id],
                    value: i.value,
                    dailyBudgetType: i.value ? 'limited' : 'unlimited',
                  }),
                  hasConditionalField: true,
                  conditionalField: (i) => {
                    if (i.daily_budget === null) {
                      return 'budget';
                    }
                    return 'daily_budget';
                  },
                  skipMin: true,
                  isNotAnEditableField: (i) => {
                    if (
                      !i.additional_fields.daily_budget ||
                      i.additional_fields.daily_budget === 0 ||
                      i.additional_fields.daily_budget === -1
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              },
              entityName: 'Adset',
              conditionalEntityName: (campaign) => {
                if (
                  !campaign?.budget &&
                  !campaign?.additional_fields?.budget &&
                  !campaign?.additional_fields?.daily_budget &&
                  !campaign?.daily_budget
                ) {
                  return 'Adgroup';
                }
                return 'Campaign';
              },
            },
          },
        },
      },
      toBeUpdatedItems: [],
      showApplyChangesButton: false,
      rowsWithPreview: [],
      failedItems: [],
      bulkRuleConfig: {},
      bulkAction: '',
      classSelector: '',
      disableActions: false,
    };
  },
  computed: {
    $c_disableHeaderButtons() {
      if (this.disableActions || this.showApplyChangesButton) {
        return true;
      }
      return false;
    },
    $c_availableTrafficSourcesCampaignLevel() {
      return [
        TrafficSource.Taboola,
        TrafficSource.Outbrain,
        TrafficSource.MediaGo,
        TrafficSource.TikTok,
        TrafficSource.Facebook,
        TrafficSource.RevContent,
        TrafficSource.GoogleAds,
        TrafficSource.Mgid,
        TrafficSource.Adskeeper,
      ];
    },
    $c_availableTrafficSourcesWidgetLevel() {
      return [TrafficSource.Taboola, TrafficSource.RevContent, TrafficSource.MediaGo, TrafficSource.GoogleAds];
    },
    $c_availableTrafficSourcesContentLevel() {
      return [TrafficSource.Adskeeper, TrafficSource.Mgid, TrafficSource.Outbrain];
    },
    $c_availableTrafficSourcesAdgroupLevel() {
      return [TrafficSource.TikTok, TrafficSource.Facebook, TrafficSource.GoogleAds];
    },
    $c_availableTrafficSourcesSectionLevel() {
      return [TrafficSource.Outbrain];
    },
  },
  methods: {
    $_setStyles(traffic_source_type: string, level: string, action: string) {
      const { classSelector } = this.trafficSources[traffic_source_type].entities[level].bulkActions[action];
      this.classSelector = classSelector;
      const el = document.querySelectorAll(`.col-size-sync-${classSelector}`);
      if (el) {
        el?.[0]?.classList.add('highlight-top');
        el?.[1]?.classList.add('highlight');
      }
    },
    $_filterItemsToCorrespondingField(failedItems: RuleTypes.Bulk.FailedItems[], type: string) {
      return failedItems.filter((item) => item.type === type);
    },
    $_numericActiveColumns(displayColumns: any[], action?: RuleTypes.Bulk.BulkAction) {
      const allActiveColumns = [];
      // in some cases we want only specific column(s) to be available
      if (action === 'change_budget_bulk' || action === 'change_daily_budget_bulk') {
        const options = [
          {
            value: 'budget',
            text: 'of Budget',
          },
        ];
        allActiveColumns.push(...options);
        return allActiveColumns;
      }
      if (displayColumns) {
        allActiveColumns.push(
          ...displayColumns
            .filter((c) => c.options?.format === 'numeric' || c.options?.format === 'currency')
            .map((c) => ({
              value: c.item.key,
              text: `of ${c.header.content}`,
            }))
        );
      }
      return allActiveColumns;
    },
    $_setItemsThatWillBeUpdated(values: RuleTypes.Bulk.FinalValues[]) {
      this.failedItems = [];
      const { traffic_source_type, level, action } = values[0];
      this.$_setStyles(traffic_source_type, level, action);
      this.showApplyChangesButton = true;
      this.toBeUpdatedItems = values;
    },
    $_findPreviewRow(id: string, type: string, currency = 'USD') {
      if (this.toBeUpdatedItems.length === 0) return;
      const c = currencySymbols.get(currency) || '$';
      const previwValue = this.toBeUpdatedItems.find((item: RuleTypes.Bulk.FinalValues) => {
        const data: any = item;
        const value: string = data[`traffic_source_${item.level}_id`];
        return value === id && item.type === type;
      });
      if (previwValue) {
        return `-> ${c + previwValue.value}`;
      }
      return null;
    },
    $_clearPreviewRows() {
      this.toBeUpdatedItems = [];
    },
    $_cancelBulkOperation() {
      this.$_removeStyles();
      this.$_clearPreviewRows();
      this.showApplyChangesButton = false;
    },
    $_setFailedItems(failedItems: RuleTypes.Bulk.FailedItems[]) {
      this.failedItems = failedItems;
    },
    $_getFailedItems() {
      return this.failedItems;
    },
    $_setItems(items: RuleTypes.Bulk.FinalValues[]) {
      this.toBeUpdatedItems = items;
    },
    $_switchApplyButton(action: boolean) {
      this.showApplyChangesButton = action;
    },
    $_switchDisableActions(action: boolean) {
      this.disableActions = action;
    },
    $_removeStyles() {
      const el = document.querySelectorAll(`.col-size-sync-${this.classSelector}`);
      if (el) {
        el?.[0]?.classList.remove('highlight-top');
        el?.[1]?.classList.remove('highlight');
      }
    },
    $_disableHeaderButtons() {
      if (this.disableActions || this.showApplyChangesButton) {
        return true;
      }
      return false;
    },
  },
});
