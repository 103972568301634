<template>
  <b-modal
    id="googleAdsCampaignBidModal"
    modal-class="optimizer-modal"
    ref="googleAdsCampaignBidModal"
    centered
    @ok="$_handleOk"
    @show="$_clear"
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Update Device Bid Adjustment</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div class="googleads-bid-modal-wrapper">
      <b-form
        :ref="$_getScope()"
        :data-vv-scope="$_getScope()"
        @submit.prevent
      >
        <b-table
          :items="groups"
          :fields="fields"
          borderless
          small
          responsive
        >
          <template #cell(title)="data">
            <div class="publisher-title">
              <i
                v-b-tooltip.hover="data.item.description"
                class="fa fa-question-circle-o text-muted info-icon"
                aria-hidden="true"
              />
              {{ data.item.title }}
              <span
                v-b-tooltip="{ disabled: !$_hasError(`publisher-${data.item.value}`), title: $_getError(`publisher-${data.item.value}`), hover: true }"
                :class="$_hasError(`publisher-${data.item.value}`) ? 'text-danger' : 'text-muted'"
              >(+{{ data.item.range.max }}%  <template v-if="data.item.range.min !== 0"><i
                class="fa fa-arrows-h"
                aria-hidden="true"
              /> {{ data.item.range.min }}%</template>)</span>
            </div>
          </template>
          <template #cell(bid)="data">
            <div class="bid-input d-inline-flex pull-right">
              <b-btn size="sm" @click="$_decreaseBid(data.item)" class="mr-2">
                <i class="fa fa-minus" />
              </b-btn>
              <b-input-group size="sm" append="%">
                <b-form-input
                  v-model="data.item.bid"
                  v-validate="`required|min_value:${data.item.range.min}|max_value:${data.item.range.max}`"
                  data-vv-as="Bid"
                  :name="`publisher-${data.item.value}`"
                />
              </b-input-group>
              <b-btn size="sm" @click="$_increaseBid(data.item)" class="ml-2">
                <i class="fa fa-plus" />
              </b-btn>
            </div>
          </template>
        </b-table>

      </b-form>
    </div>

    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn class="secondary-button" @click="hide">Cancel</b-btn>
      <b-btn class="primary-button" @click="$_handleOk">Update Bid</b-btn>
    </template>
  </b-modal>
</template>

<script>
import ui from '@sh/helpers/ui';

const scope = 'googleAdsCampaignBidForm';
export default {
  name: 'GoogleAdsBidModal',
  data() {
    return {
      fields: [
        { key: 'title', label: 'Devices' },
        { key: 'bid', label: 'Bid adjustments', thClass: 'text-right' },
      ],
      groups: [
        {
          value: 'DESKTOP', range: { max: 800, min: -100 }, title: 'Desktop', description: 'Native ads bid adj for Desktop', bid: 0,
        },
        {
          value: 'MOBILE', range: { max: 800, min: -100 }, title: 'Mobile', description: 'Native ads bid adj for Mobile', bid: 0,
        },
        {
          value: 'TABLET', range: { max: 800, min: -100 }, title: 'Tablet', description: 'Native ads bid adj for Tablet', bid: 0,
        },
      ],
      campaign: null,
    };
  },
  computed: {
    $c_bids() {
      if (this.campaign) {
        if (this.campaign.additional_fields && this.campaign.additional_fields.bids) {
          const bidsMap = {};
          this.campaign.additional_fields.bids.forEach((item) => {
            bidsMap[item.value] = item;
          });
          return bidsMap;
        }
      }
      return null;
    },
  },
  watch: {
    $c_bids(bids) {
      if (bids) {
        this.groups.forEach((item) => {
          if (bids[item.value] && bids[item.value].bidModifier !== null) {
            item.bid = ui.getGeminiBidModifierToPercentage(bids[item.value].bidModifier);
          } else {
            item.bid = 0;
          }
        });
      } else {
        this.groups.forEach((item) => { item.bid = 1; });
      }
    },
  },
  methods: {
    $_increaseBid(item) {
      let bid = parseInt(item.bid, 10);
      if (!isNaN(bid)) {
        if (bid < item.range.max) {
          const newBid = bid + 10;
          if (newBid <= item.range.max) {
            if (newBid < item.range.min) {
              bid = item.range.min;
            } else {
              bid = newBid;
            }
          } else {
            bid = item.range.max;
          }
        } else if (bid > item.range.max) {
          bid = item.range.max;
        }
      } else {
        bid = 0;
      }
      item.bid = bid;
    },
    $_decreaseBid(item) {
      let bid = parseInt(item.bid, 10);
      if (!isNaN(bid)) {
        if (item.bid > item.range.min) {
          const newBid = bid - 10;
          if (newBid >= item.range.min) {
            if (newBid > item.range.max) {
              bid = item.range.max;
            } else {
              bid = newBid;
            }
          } else {
            bid = item.range.min;
          }
        } else if (bid < item.range.min) {
          bid = item.range.min;
        }
      } else {
        bid = 0;
      }
      item.bid = bid;
    },
    show(campaign) {
      this.campaign = null;
      this.campaign = campaign;
      this.$refs.googleAdsCampaignBidModal.show();
    },
    hide() {
      this.$refs.googleAdsCampaignBidModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.$_getScope());
      if (isValid) {
        const bids = {};
        this.groups.forEach((item) => {
          bids[item.value] = { value: item.value, bidModifier: ui.getGeminiPercentageToBidModifier(item.bid) };
        });
        this.$emit('updateBid', { campaign: this.campaign, bids });
        this.$_clear();
        this.hide();
      }
    },
    $_clear() {
      this.$validator.reset(this.$_getScope());
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss">
.goolgeads-bid-modal-wrapper {
  .table {
    th {
      border: 0;
    }
    td {
      border: 0;
      .info-icon {
        cursor: pointer;
      }
      .bid-input {
        .btn-secondary {
          width: 2.8rem;
          height: 2.8rem;
        }

        .input-group {
          width: 80px;

          input {
            border: .1rem solid #e5e5e5;
            height: 2.8rem;
            box-shadow: none !important;
            outline: none !important;
            font-size: 1.4rem;
            color: $text-primary;
            padding-left: 1.2rem;
          }

          &-append {
            .input-group-text {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
