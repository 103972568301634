<template>
  <b-modal
    modal-class="optimizer-modal"
    ref="ruleTemplateModal"
    hide-footer
    size="xl"
    @hidden="$_hidden"
    centered
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Templates</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div class="templates-container">
      <!-- Templates Filters -->
      <div v-if="!preloader" class="rule-templates-filters">
        <div class="row">
          <div class="col-lg-12">
            <b-form-group class="filter">
              <b-form-radio-group
                v-model="filter.value"
                :options="filter.options"
                buttons
                button-variant="outline-secondary"
                size="sm"
              />
            </b-form-group>
          </div>

          <div class="col-lg-12">
            <b-form-group class="rule-search">
              <b-form-input v-model="searchModel" placeholder="Search..." size="sm" trim />
            </b-form-group>
          </div>
        </div>
      </div>
      <hr v-if="!preloader" class="my-3 pb-3">

      <preloader v-if="preloader" />

      <!-- Templates -->
      <div v-else class="rule-templates-wrapper">
        <b-card no-body class="rule-template-card" v-for="(item, index) in $c_templates" :key="index">
          <b-card-header header-tag="header" role="tab">
            <b-btn block href="#" v-b-toggle="`${accordionUid}-${index}`" variant="default" class="template-card-btn">
              <div class="rule-header">
                <div class="rule-name">
                  <i v-if="item.rule_action === 'start'" class="fa fa-play" />
                  <i v-if="item.rule_action === 'pause'" class="fa fa-stop" />
                  <i v-if="item.rule_action === 'change_bid'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_revcontent'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_googleads'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_gemini'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_voluumdsp'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_adgroup'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_budget'" class="fa fa-dollar" />
                  <i v-if="item.rule_action === 'change_bid_cad'" class="fa fa-dollar" />
                  <b v-if="item.rule_action === 'change_coefficient'" style="width: 19px;display: inline-block;">C</b>
                  <i v-if="item.rule_action === 'day_parting'" class="fa fa-calendar-check-o" />
                  <i v-if="item.rule_action === 'change_target_cpa'" class="fa fa-dollar" />
                  {{ item.name }}
                </div>
                <span class="ts-icons">
                  <img
                    v-for="uniqueName in $_getItem(item).ts_types"
                    :key="uniqueName"
                    :src="getTrafficSourceLogo(uniqueName)"
                    :title="uniqueName"
                    v-b-tooltip.hover.top="{
                      customClass: 'custom-tooltip',
                    }"
                    alt="traffic-source-logo"
                  >
                </span>
              </div>
              <i class="fa fa-angle-down collapse-icon"></i>
            </b-btn>
          </b-card-header>
          <b-collapse :id="`${accordionUid}-${index}`" :visible="item.visible || false" :accordion="accordionUid" role="tabpanel" class="template-body">
            <b-card-body>
              <div class="rule-template-content">
                <p class="rule-template-content__description">
                  <b>Description: </b><span v-html="item.template.description" />
                </p>
                <p class="cell-conditions">
                  <b>Conditions: </b><span class="field" v-html="config.conditionsConfig.conditionsTranslator(item.query, item.hour_of_day, false, item)" />
                </p>
                <div class="rule-template-content__actions">
                  <b-btn :to="{name: 'New Rule', query: {templateId: item._id}}" class="primary-button">
                    <OptimizerIcon type="addTemplate" /> Use Template
                  </b-btn>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import uid from 'uid';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'RuleTemplatesModal',
  props: {
    config: { type: [Object, null], default: () => null },
  },
  components: { OptimizerIcon },
  data() {
    return {
      preloader: true,
      templates: [],
      accordionUid: `accordion-${uid(10)}`,
      filter: {
        value: 'all',
        options: [
          { text: 'All', value: 'all' },
        ],
      },
      searchModel: '',
      search: '',
    };
  },
  computed: {
    $c_templates() {
      const searchValue = this.search.toLowerCase().trim();
      let { templates } = this;
      if (this.filter.value !== 'all') {
        templates = templates.filter((item) => item.template.tags.includes(this.filter.value));
      }
      if (searchValue) {
        templates = templates.filter((item) => item.searchField.toLowerCase().indexOf(searchValue) !== -1);
      }
      return templates;
    },
  },
  watch: {
    'filter.value': function () {
      this.searchModel = '';
    },
  },
  async created() {
    this.preloader = true;
    /* --------------- Load Data ---------------- */
    this.templates = await this.config.api.rules.templates();
    this.templates = this.templates.filter((item) => item.status);
    const tagsMap = {};
    this.templates.forEach((item) => {
      item.visible = false;
      item.searchField = `${item.name} ${this.$_getItem(item).ts_types.join(' ')}`;
      item.template.tags.forEach((tag) => { tagsMap[tag] = tag; });
    });
    const tags = Object.values(tagsMap).map((tag) => ({ value: tag, text: `${tag.charAt(0).toUpperCase()}${tag.slice(1)}` }));
    this.filter.options.push(...tags);
    /* --------------- --------- ---------------- */
    /* ------------- Search Debounce ------------ */
    const searchDebounce = this.$_debounce(() => {
      this.search = this.searchModel;
    }, 300);
    this.$watch('searchModel', () => {
      searchDebounce();
    });
    /* ------------- --------------- ------------ */
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    show() {
      this.$refs.ruleTemplateModal.show();
    },
    hide() {
      this.$refs.ruleTemplateModal.hide();
    },
    $_getItem(rule) {
      return this.config.ruleConfig.ruleTypes[`${rule.level.toLowerCase()}_${rule.rule_action.toLowerCase()}`];
    },
    $_debounce(fn, delay) {
      let timer = null;
      return () => {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      };
    },
    $_hidden() {
      this.filter.value = 'all';
      this.searchModel = '';
    },
  },
};
</script>

<style lang="scss">
.templates-container {

  .rule-templates-filters {
    .filter {
      margin-bottom: 1.5rem;
      height: 100%;

      .btn-group-toggle {
        width: 100%;
        height: 100%;

        .btn {
          height: 3.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #68B2F7;
          margin-right: 1rem;
          border: .1rem solid #68B2F7;
          border-radius: .6rem;
          font-size: 1.3rem;
          font-weight: 500;
          padding: .7rem;
          box-shadow: none;

          &:last-of-type {
            margin-right: 0;
          }

          &:hover {
            color: #68B2F7;
            background: $color-white;
          }

          &.active {
            background: #68B2F7;
            color: $color-white;
          }
        }
      }
    }
  }

  .rule-search {
    position: relative;
    width: 100%;

    input {
      border: .1rem solid #E5E5E5;
      font-size: 1.4rem;
      padding: .7rem 1.5rem .7rem 3.5rem;
      border-radius: .6rem;
      height: 3.6rem;
    }

    &::placeholder {
      color: $black-200;
    }

    &::after {
      font-family: 'FontAwesome';
      content: '\f002';
      position: absolute;
      left: 1rem;
      top: 0.6rem;
      font-size: 1.5rem;
      color: $black-400;
      z-index: 3;
    }
  }

  .rule-templates-wrapper {
    max-height: calc(100vh - 35rem);
    min-height: calc(100vh - 35rem);
    overflow-y: auto;

    .rule-template-card {
      margin-bottom: 1.5rem;
      border: none;
      border-radius: .6rem;
      background: #F0F6F9;

      &:last-of-type {
        margin-bottom: 0;
      }

      .card-header {
        padding: 0;
        border-bottom: none;
        border-radius: .6rem;
        background: #F0F6F9;

        .template-card-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 2rem;

          &[aria-expanded=true] {
            .collapse-icon {
              transform: rotate(-180deg);
            }
          }

          .rule-header {
            flex: 1;
            display: flex;
            justify-content: space-between;
            margin-right: 2rem;

            .rule-name {
              font-size: 1.4rem;
              color: $black-600;

              i {
                margin-right: 1rem;
              }
            }

            .ts-icons {
              img {
                margin-right: .5rem;
                width: 2.4rem;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .collapse-icon {
            font-size: 2.2rem;
            color: $black-500;
            transition: transform .25s ease-in-out;
          }
        }
      }

      .template-body {
        .card-body {
          padding: 1.5rem;
          border-radius: .6rem;
          border: .1rem solid #E5E5E5;
          background: #fff;
          margin: 0 1.5rem 1.5rem 1.5rem;

          .rule-template-content {
            &__description {
              font-size: 1.4rem;
              color: $text-primary;
              margin-bottom: 1.2rem;
            }

            .cell-conditions {
              font-size: 1.4rem;
              margin-bottom: 2rem;

              .field {
                font-size: 1.6rem;
                margin-left: 1rem;

                .badge {
                  padding: .5rem 1.2rem;
                }

                .badge-blue-chambray {
                  background: #68B2F7;
                }
              }
            }

            &__actions {
              text-align: right;

              .primary-button {
                i {
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
