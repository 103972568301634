const rotation = [
  { value: '0.16', text: 'Every 10 Minutes' },
  { value: '0.33', text: 'Every 20 Minutes' },
  { value: '0.5', text: 'Every 30 Minutes' },
  { value: '1', text: 'Every 1 Hour' },
  { value: '2', text: 'Every 2 Hour' },
  { value: '3', text: 'Every 3 Hour' },
  { value: '6', text: 'Every 6 Hour' },
  { value: '12', text: 'Every 12 Hour' },
  { value: 'COO', text: 'Only Once <i class="fa fa-info-circle" aria-hidden="true" title="Once entity is cloned in the amount of selected copies, it will not be cloned anymore"></i>' }, // clones only
  { value: 'DO', text: 'Once Daily ( As soon as conditions are met )' },
  { value: 'D', text: 'Daily ( At 12:00 PM UTC )' },
  { value: 'W', text: 'Weekly ( At 12:00 PM UTC on Saturday )' },
];

const rotationMap = {};
rotation.forEach((item) => {
  rotationMap[item.value] = item.text;
});

module.exports = { rotation, rotationMap };
