import io from 'socket.io-client';
import session from './session';

class Socket {
  constructor() {
    /* *********** Private props ************ */
    this._platform = process.env.VUE_APP_PLATFORM;
    this._enabled = process.env.VUE_APP_BASE_URL_NOTIFICATION && process.env.VUE_APP_ENABLE_NOTIFICATIONS && process.env.VUE_APP_ENABLE_NOTIFICATIONS === 'true';
    this._socket_uri = process.env.VUE_APP_BASE_URL_NOTIFICATION;
    this._callbacks = {
      notification: [],
    };
    this._socket = null;
    /* ************************************** */

    /* ************ Auto Runner ************* */
    session.afterAuthentication(() => {
      this._connect();
    });
    session.afterLogout(() => {
      this._disconnect();
    });
    if (session.isAuthenticated()) {
      this._connect();
    }
    /* ************************************** */
  }

  /**
   *
   * @param event string
   * @param callback function
   * @param noDuplicate boolean
   */
  on(event, callback, noDuplicate = true) {
    if (this._callbacks[event] && typeof callback !== 'undefined') {
      if (noDuplicate) {
        const cbToString = callback.toString();
        const index = this._callbacks[event].findIndex((fn) => fn.toString() === cbToString);
        if (index > -1) {
          this._callbacks[event][index] = callback;
        } else {
          this._callbacks[event].push(callback);
        }
      } else {
        this._callbacks[event].push(callback);
      }
    }
  }

  /**
   * Trigger get notifications
   */
  emitGetNotifications() {
    if (this._enabled && this._socket) this._socket.emit('get-notifications', this._getSocketData());
  }

  /**
   * Trigger mark notification as read
   */
  emitMarkNotificationAsRead(_id) {
    if (this._enabled && this._socket) this._socket.emit('mark-notification-as-read', this._getSocketData({ notification: _id }));
  }

  _connect() {
    if (this._enabled && !this._socket) {
      this._socket = io.connect(this._socket_uri);
      this._socket.on('connect', () => {
        this._socket.emit('authentication', this._getSocketData());
        this._socket.on('authenticated', () => {
          this._socket.removeAllListeners('notifications');
          this._socket.on('notifications', (data) => {
            this._emit('notification', data);
          });
        });
      });
    }
  }

  _disconnect() {
    if (this._enabled && this._socket) {
      this._socket.emit('remove-user', this.socketData);
      this._socket = null;
    }
  }

  /**
   *
   * @param event string
   * @param data array
   */
  _emit(event, data) {
    this._callbacks[event].forEach((fn, index) => {
      fn(data, index);
    });
  }

  _getSocketData(data = {}) {
    return { ...data, platform: this._platform, jwt: session.getToken() };
  }
}

const socket = new Socket();

export default socket;
