
import { useMediaManagerStore } from '@/stores/media-manager';
import helpers from '@/views/Automation/CampaignCreator/mixins/helpers';
import ExistingContentsModal from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/ExistingContentsModal.ts.vue';
import ImageUploader from '@/views/Automation/CampaignCreator/steps/Contents/Uploader/ImageUploader.ts.vue';
import AdCard from '@sh/components/MediaManager/AdCard/AdCard.ts.vue';
import TagsContainer from '@sh/components/MediaManager/TagsContainer/TagsContainer.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { ImageHelperType, ImageType } from '@sh/types/ImageTypes';
import md5 from 'md5';
import { mapState } from 'pinia';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  hasPendingRequest: boolean;
  headlinesTextarea: string;
  images: { original: ImageHelperType; cropped?: ImageHelperType }[];
  tags: string[];
  contents: Partial<MediaManager.AdCard>[];
  isExistingContentsModal: boolean;
}

type ComponentTypes = VueConstructor<Vue & HelperMixin & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    ExistingContentsModal,
    ImageUploader,
    TagsContainer,
    AdCard,
    OptimizerIcon,
  },
  mixins: [notifications, helpers],
  props: {
    mode: {
      type: String as PropType<MediaManager.Mode>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      hasPendingRequest: false,
      headlinesTextarea: '',
      images: [],
      tags: [],
      contents: [],
      isExistingContentsModal: false,
    };
  },
  computed: {
    replaceMode(): string {
      return this.mode === MediaManager.Mode.ADS ? 'Creative' : this.mode;
    },
    headlines(): string[] {
      const text = this.headlinesTextarea.replace(/(\r\n|\n|\r)/gm, '\n');
      const rows = text.split('\n').filter((text) => text !== '');
      return rows;
    },
    imagesDto(): ImageType[] {
      return this.images.map((image) => {
        const data = image.cropped?.data ? image.cropped : image.original;
        return {
          base64: data.data,
          mimeType: data.mimeType,
          size: data.size,
          dimensions: {
            width: data.width,
            height: data.height,
          },
          md5: md5(data.data),
        };
      });
    },
    errorMessage(): string {
      if (
        (!this.headlines.length && this.mode !== MediaManager.Mode.IMAGES) ||
        (!this.imagesDto.length && this.mode !== MediaManager.Mode.HEADLINES)
      ) {
        return 'Please fill all required fields to save changes.';
      }
      return '';
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    autocompleteItems(): MediaManager.Tag[] {
      return this.settings.uniqueTags.map((text) => ({ text }));
    },
    ...mapState(useMediaManagerStore, ['settings']),
  },
  watch: {
    imagesDto: {
      handler() {
        this.generateContents();
      },
      deep: true,
    },
    headlinesTextarea: {
      handler() {
        this.generateContents();
      },
      deep: true,
    },
  },
  methods: {
    addExistingHeadlines(headlines: string[]) {
      if (this.headlinesTextarea) {
        this.headlinesTextarea = [this.headlinesTextarea, headlines.join('\n')].join('\n');
      } else {
        this.headlinesTextarea = headlines.join('\n');
      }
    },
    generateContents() {
      const contents: Partial<MediaManager.AdCard>[] = [];

      switch (this.mode) {
        case MediaManager.Mode.ADS: {
          this.headlines.forEach((headline, headlineIndex) => {
            this.imagesDto.forEach((image, imageIndex) => {
              contents.push({
                id: md5(image.base64 + headline + headlineIndex + imageIndex),
                image,
                headline,
                tags: this.tags,
                metrics: [],
                selected: true,
              });
            });
          });
          break;
        }
        case MediaManager.Mode.HEADLINES: {
          this.headlines.forEach((headline, headlineIndex) => {
            contents.push({
              id: md5(headline + headlineIndex),
              headline,
              tags: this.tags,
              metrics: [],
              selected: true,
            });
          });
          break;
        }
        case MediaManager.Mode.IMAGES: {
          this.imagesDto.forEach((image, imageIndex) => {
            contents.push({
              id: md5(`${image.base64}${imageIndex}`),
              image,
              tags: this.tags,
              metrics: [],
              selected: true,
            });
          });
          break;
        }
      }
      this.contents = contents;
    },
    onCancelClick() {
      this.$router.push({ path: `/media-manager/${this.mode}s` });
    },
    async onSaveClick() {
      this.hasPendingRequest = true;

      if (!this.errorMessage) {
        this.contents = this.contents.filter((content) => content.selected);
        const responses = await Promise.allSettled(
          this.contents.map(async (content) => {
            const data = {
              ...(this.mode !== MediaManager.Mode.HEADLINES &&
                content.image && {
                  file: {
                    ...content.image,
                    size: (content.image.size ?? 0) * 1000,
                    base64: content.image.base64?.split(';base64,')[1],
                  },
                }),
              ...(this.mode !== MediaManager.Mode.IMAGES && { headline: content.headline }),
              tags: this.tags,
            };

            return this.$api.mediaManager.createNewData(this.mode, data).then(() => {
              this.contents = this.contents.filter((data) => data.id !== content.id);
            });
          })
        );
        this.hasPendingRequest = false;

        if (this.contents.length) {
          this.$n_failNotification({
            title: `${this.contents.length} ${this.mode}${this.contents.length === 1 ? '' : 's'} failed to be created!`,
          });
        } else {
          this.onCancelClick();
        }

        const successfulResponses = responses.length - this.contents.length;

        if (successfulResponses) {
          this.$n_successNotification({
            title: `${successfulResponses} ${this.mode}${
              successfulResponses === 1 ? ' is' : 's are'
            } created successfully!`,
          });
        }
      }
    },
  },
});
