const moment = require('moment');

const nodeWalker = (obj, cb = () => {}, path = [], typeofLeaf = 'function') => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== typeofLeaf) { // eslint-disable-line
      nodeWalker(value, cb, [...path, key], typeofLeaf);
    } else {
      cb([...path, key], value) // eslint-disable-line
    }
  });
};

export default class ApiProxy {
  constructor(api, TTL = 0, GCTTL = 300000) {
    this._api = {};
    this._apiStore = {};
    this._apiStoreData = {};
    this._defaultConfig = { TTL, GCTTL };
    this._responseHandler = (response) => response.data;
    this._afterMiddlewares = [];

    nodeWalker(api, this._registerApi.bind(this));
    // Routine Garbage collector
    setInterval(() => {
      const now = +moment();
      Object.values(this._apiStoreData).forEach((item) => {
        this._checkExpCache(item, now);
      });
    }, GCTTL);
  }

  getApi() {
    return this._api;
  }

  getApiStore() {
    return this._apiStore;
  }

  setTTL(key, value) {
    this._apiStoreData[key].TTL = value;
  }

  clearCache(key) {
    if (key) {
      try {
        delete this._apiStoreData[key].cache;
      } catch (err) {
        console.log(err);
      }
    } else {
      Object.values(this._apiStoreData).forEach((item) => {
        delete item.cache;
        delete item.exp;
      });
    }
  }

  resultHandler(result) {
    return result.data;
  }

  addAfterMiddleware(middleware) {
    this._afterMiddlewares.push(middleware);
    return this;
  }

  _registerApi(path, fn) {
    // Add Method settings
    const pathKey = path.join('.');
    this._apiStoreData[pathKey] = { TTL: this._defaultConfig.TTL };
    // Add Method
    let refApi = this._api;
    let refApiStore = this._apiStore;
    path.forEach((key, index) => {
      if (index === (path.length - 1)) {
        // Register API
        refApi[key] = async (...args) => {
          try {
            const body = {
              method_args: args,
              response: undefined,
              // path is passed to check if
              // middleware is for this path and it should execute
              path,
            };
            const result = await fn(...args);
            if (process.env.NODE_ENV === 'development') {
              console.log(`API ${pathKey}, METHOD: ${result.config.method}, STATUS: ${result.status}, RESULT:`, result);
            }
            for (const middleware of this._afterMiddlewares) {
              body.response = result.data;
              // eslint-disable-next-line no-await-in-loop
              await middleware(body);
            }
            return this.resultHandler(result, path);
            // return result.data
          } catch (err) {
            if (process.env.NODE_ENV === 'development') {
              // TODO: uniform error
              if (err.response) {
                console.error(`API ${pathKey}, METHOD: ${err.response.config.method}, STATUS: ${err.response.status}, ERROR:`, err);
              } else if (err.request) {
                console.error(`API ${pathKey}, EMPTY_RESPONSE, ERROR:`, err);
              } else {
                console.error(`API ${pathKey}, CONFIG_ERROR, ERROR:`, err);
              }
            }
            throw err;
          }
        };
        // Register API Store
        refApiStore[key] = async (...args) => {
          this._checkExpCache(this._apiStoreData[pathKey], +moment());
          if (!this._apiStoreData[pathKey].cache) {
            this._apiStoreData[pathKey].cache = refApi[key](...args).then((res) => {
              if (this._apiStoreData[pathKey].TTL) this._apiStoreData[pathKey].exp = +moment() + this._apiStoreData[pathKey].TTL;
              return res;
            }).catch((error) => {
              throw error;
            });
          }
          return this._apiStoreData[pathKey].cache;
        };
        refApiStore[key].clearCache = () => {
          this.clearCache(pathKey);
        };
      } else {
        if (!refApi[key]) refApi[key] = {};
        if (!refApiStore[key]) refApiStore[key] = {};
        refApi = refApi[key];
        refApiStore = refApiStore[key];
      }
    });
  }

  _checkExpCache(item, now) {
    if (item.TTL !== 0) {
      if (!item.exp || now > item.exp) {
        delete item.cache;
        delete item.exp;
      }
    }
  }
}
