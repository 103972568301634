/* eslint-disable import/no-cycle */
import { Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import * as AdSet from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import * as Campaign from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { nameValidation } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import {
  DefaultGroupMacros,
  DefaultMacros,
  FieldConfiguration,
  RequiredLiteralKeys,
} from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import { currencyFormatter } from '@sh/helpers';
import Joi from 'joi';
import { languages } from '../helpers/facebook/countries';

type BudgetKeys =
  | 'daily_budget'
  | 'start_time'
  | 'end_time'
  | 'lifetime_budget'
  | 'bid_amount'
  | 'daily_min_spend_target'
  | 'daily_spend_cap'
  | 'lifetime_spend_cap'
  | 'bid_strategy'
  | 'lifetime_min_spend_target';

export enum AdSetAbGroups {
  TARGETING = 'TARGETING',
  PLACEMENT = 'PLACEMENT',
  BUDGET = 'BUDGET',
  DEFAULT = 'DEFAULT',
}

export const AdSetABGroupsPublic = {
  [AdSetAbGroups.TARGETING]: {
    readableName: 'Targeting',
    macros: DefaultGroupMacros,
    isActive: () => true,
  },
  [AdSetAbGroups.PLACEMENT]: {
    readableName: 'Placement',
    macros: DefaultGroupMacros,
    isActive: () => true,
  },
  [AdSetAbGroups.BUDGET]: {
    readableName: 'Budget',
    macros: DefaultGroupMacros,
    isActive: () => true,
  },
} as const;

export const TargetingValidation: Record<RequiredLiteralKeys<AdSet.TargetingObjectAbVariables>, FieldConfiguration> = {
  targeting_automation: {
    validation: Joi.object().optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Targeting Automation',
    renderFunction: (v: { advantage_audience: 1 | 0 }) =>
      v?.advantage_audience ? 'Advantage Audience' : 'No Advantage Audience',
    macros: DefaultMacros,
  },
  locales: {
    validation: Joi.array().optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Locales',
    renderFunction: (v: Array<number>) => (v?.map((id) => languages.find((l) => l.key === id)?.name) || []).join(', '),
    macros: DefaultMacros,
  },
  genders: {
    validation: Joi.array().items(Joi.number()).optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.TARGETING,
    readableName: 'Gender',
    // eslint-disable-next-line no-nested-ternary
    renderFunction: (v: number[] = []) => {
      if (v.length > 1 || !v.length || v[0] === 0) {
        return 'All Genders';
      }
      return v[0] === 1 ? 'Male' : 'Female';
    },
    macros: DefaultMacros,
  },
  age_max: {
    validation: Joi.number().max(99).required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.TARGETING,
    readableName: 'Age Max',
    macros: DefaultMacros,
  },
  age_min: {
    validation: Joi.number().min(13).required(),
    default: 18,
    isActive: true,
    fieldGroup: AdSetAbGroups.TARGETING,
    readableName: 'Age Min',
    macros: DefaultMacros,
  },
  device_platforms: {
    validation: Joi.array()
      .items(...Object.values(AdSet.DevicePlatforms))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Device Platforms',
    macros: DefaultMacros,
  },
  facebook_positions: {
    validation: Joi.array()
      .items(...Object.values(AdSet.FacebookPositions))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Facebook Positions',
    macros: DefaultMacros,
  },
  instagram_positions: {
    validation: Joi.array()
      .items(...Object.values(AdSet.InstagramPositions))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Instagram Positions',
    macros: DefaultMacros,
  },
  publisher_platforms: {
    validation: Joi.array()
      .items(...Object.values(AdSet.PublisherPlatforms))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Publisher Platforms',
    macros: DefaultMacros,
  },
  messenger_positions: {
    validation: Joi.array()
      .items(...Object.values(AdSet.MessengerPositions))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Messenger Positions',
    macros: DefaultMacros,
  },
  audience_network_positions: {
    validation: Joi.array().items(Joi.string()).required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.PLACEMENT,
    readableName: 'Audience Network Positions',
    macros: DefaultMacros,
  },
  geo_locations: {
    validation: Joi.object()
      .keys({
        custom_locations: Joi.array()
          .items(
            Joi.object()
              .keys({
                latitude: Joi.number().required(),
                longitude: Joi.number().required(),
                radius: Joi.number().required(),
                distance_unit: Joi.string().valid('mile', 'kilometer').required(),
              })
              .unknown(true)
          )
          .optional(),
        cities: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        countries: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        country_group: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        regions: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        zips: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
      })
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.TARGETING,
    readableName: 'Geo Locations',
    renderFunction: (geo = {}) => {
      const customLocations = geo.custom_locations?.map(
        (c: any) => `${c.latitude},${c.longitude},${c.radius},${c.distance_unit}`
      );
      const cities = geo.cities?.map((c: any) => c.name);
      const countries = geo.countries?.map((c: any) => c.name);
      const countryGroups = geo.country_group?.map((c: any) => c.name);
      const regions = geo.regions?.map((c: any) => c.name);
      const zips = geo.zips?.map((c: any) => c.name);
      return [customLocations, cities, countries, countryGroups, regions, zips]
        ?.flat()
        .filter((v) => v)
        .join(', ');
    },
    macros: DefaultMacros,
  },
  excluded_geo_locations: {
    validation: Joi.object()
      .keys({
        custom_locations: Joi.array()
          .items(
            Joi.object()
              .keys({
                latitude: Joi.number().required(),
                longitude: Joi.number().required(),
                radius: Joi.number().required(),
                distance_unit: Joi.string().valid('mile', 'kilometer').required(),
              })
              .unknown(true)
          )
          .optional(),
        cities: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        countries: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        country_group: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        regions: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
        zips: Joi.array()
          .items(
            Joi.object()
              .keys({
                key: Joi.string().required(),
                name: Joi.string().required(),
              })
              .unknown(true)
          )
          .required(),
      })
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.TARGETING,
    renderFunction: (geo = {}) => {
      const customLocations = geo.custom_locations?.map(
        (c: any) => `${c.latitude},${c.longitude},${c.radius},${c.distance_unit}`
      );
      const cities = geo.cities?.map((c: any) => c.name);
      const countries = geo.countries?.map((c: any) => c.name);
      const countryGroups = geo.country_group?.map((c: any) => c.name);
      const regions = geo.regions?.map((c: any) => c.name);
      const zips = geo.zips?.map((c: any) => c.name);
      return [countryGroups, countries, customLocations, cities, regions, zips]
        ?.flat()
        .filter((v) => v)
        .join(', ');
    },
    readableName: 'Excluded Geo Locations',
    macros: DefaultMacros,
  },
};

const AdSetBudgetValidator: Record<BudgetKeys, FieldConfiguration> = {
  daily_budget: {
    validation: Joi.number().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    interchangeableGroup: 'adset_BudgetGroup',
    readableName: 'Daily Budget',
    renderFunction: (value) => currencyFormatter(value / 100),
    macros: DefaultMacros,
  },
  start_time: {
    validation: Joi.date().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Start Time',
  },
  end_time: {
    validation: Joi.date().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'End Time',
  },
  lifetime_budget: {
    validation: Joi.number().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    interchangeableGroup: 'adset_BudgetGroup',
    readableName: 'Lifetime Budget',
    renderFunction: (value) => currencyFormatter(value / 100),
    macros: DefaultMacros,
  },
  bid_amount: {
    validation: Joi.number().positive().optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Bid Amount',
    renderFunction: (value) => currencyFormatter(value / 100),
  },
  daily_min_spend_target: {
    validation: Joi.number().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Daily Min Spend Target',
    renderFunction: (value) => currencyFormatter(value / 100),
  },
  daily_spend_cap: {
    validation: Joi.number().optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Daily Spend Cap',
    renderFunction: (value) => currencyFormatter(value / 100),
  },
  lifetime_spend_cap: {
    validation: Joi.number().optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Lifetime Spend Cap',
    renderFunction: (value) => currencyFormatter(value / 100),
  },
  lifetime_min_spend_target: {
    validation: Joi.number().required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Lifetime Min Spend Target',
  },
  bid_strategy: {
    isActive: (campaign: Campaign.Create) => campaign.buying_type === Campaign.BuyingType.AUCTION,
    validation: Joi.string()
      .valid(...Object.values(Campaign.BidStrategy))
      .required(),
    fieldGroup: AdSetAbGroups.BUDGET,
    readableName: 'Bid Strategy',
    macros: DefaultMacros,
  },
};

export type AdSetFlat = Omit<AdSet.Create, 'targeting' | 'campaign_id'>;

export const AdSetValidation: Record<RequiredLiteralKeys<AdSetFlat>, FieldConfiguration> = {
  ...AdSetBudgetValidator,
  attribution_spec: {
    validation: Joi.object()
      .keys({
        [AdSet.AttributionSpecEvent.CLICK_THROUGH]: Joi.number().optional(),
        [AdSet.AttributionSpecEvent.VIEW_THROUGH]: Joi.number().optional(),
      })
      .optional(),
    isActive: true,
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Attribution Spec',
    renderFunction: (v) =>
      `${Object.entries(v || {})
        ?.map(([event_type, window_days]: any) => `${event_type}: ${window_days} days`)
        .join(', ')}`,
    macros: DefaultMacros,
  },
  name: {
    validation: nameValidation,
    isActive: true,
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Name',
    renderFunction: (v) => v.name || v,
  },
  status: {
    validation: Joi.string()
      .valid(...Object.values(Status))
      .default(Status.PAUSED)
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.DEFAULT,
    default: Status.PAUSED,
  },
  destination_type: {
    // Conversion Location
    validation: Joi.string()
      .valid(...Object.values(AdSet.DestinationType))
      .required(),
    isActive: true,
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Destination Type',
    macros: DefaultMacros,
  },

  billing_event: {
    // When you get Charged
    isActive: (campaign: Campaign.Create) => campaign.buying_type === Campaign.BuyingType.AUCTION,
    validation: Joi.string()
      .valid(...Object.values(AdSet.BillingEvent))
      .required(),
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Billing Event',
    macros: DefaultMacros,
  },
  optimization_goal: {
    // Performance Goal
    isActive: (campaign: Campaign.Create) => campaign.buying_type === Campaign.BuyingType.AUCTION,
    validation: Joi.string()
      .valid(...Object.values(AdSet.OptimizationGoal))
      .required(),
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Optimization Goal',
    macros: DefaultMacros,
  },
  daily_imps: {
    isActive: (campaign: Campaign.Create) => campaign.buying_type === Campaign.BuyingType.FIXED_CPM,
    validation: Joi.number().optional(),
    readableName: 'Daily Impressions',
    macros: DefaultMacros,
  },
  lifetime_imps: {
    isActive: (campaign: Campaign.Create) => campaign.buying_type === Campaign.BuyingType.FIXED_CPM,
    validation: Joi.number().optional(),
    readableName: 'Lifetime Impressions',
    macros: DefaultMacros,
  },
  promoted_object: {
    isActive: (campaign: Campaign.Create) => campaign.objective === Campaign.Objective.OUTCOME_SALES,
    validation: Joi.object().required(),
    fieldGroup: AdSetAbGroups.DEFAULT,
    readableName: 'Promoted Object',
    renderFunction: (v = {}) => {
      return `Pixel: ${v.pixel_id} Event: ${v.custom_event_str || v.custom_event_type}`;
    },
    macros: DefaultMacros,
  },
};
