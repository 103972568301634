<template>
  <div :class="`cc-multi-select-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('multiSelect')">
      <b-input-group class="campaign-select-container">
        <v-select
          name="multiSelect"
          :data-vv-as="model.label"
          v-validate="model['v-validate']"
          data-vv-validate-on="input|search:blur"
          :class="{ 'is-invalid': $_hasError('multiSelect') === false }"
          ref="multiSelect"
          v-model="selected"
          :label="$options.model.optionLabel"
          :max-height="$options.model.maxHeight"
          :placeholder="$options.model.placeholder"
          class="w-100"
          :close-on-select="$options.model.multiple ? false : true"
          :clear-search-on-select="false"
          :multiple="$options.model.multiple"
          :loading="loading"
          :disabled="$options.model.disabled || loading"
          :selectable="(option) => !option.disabled"
          :options="displayItems"
          @search="$_onSearch"
          @input="$_input"
          :filterable="false"
          @search:focus="$_onFocus"
          @search:blur="$_onBlur"
          v-b-popover.hover="model.componentPopover"
        >
          <template slot="option" slot-scope="option">
            <span v-html="$options.OptionLabel(option)['option']"></span>
          </template>
          <template slot="selected-option" slot-scope="option">
            <span v-html="$options.OptionLabel(option)['selected-option']"></span>
          </template>
        </v-select>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError('multiSelect') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';
import _ from 'lodash';

export default {
  name: 'CcMultiSelect',
  mixins: [component],
  // eslint-disable-next-line vue/require-prop-types
  props: ['value'],
  data() {
    return {
      selected: [],
      localOptions: [],
      filteredOptions: [],
      displayOptions: [],
      loading: false,
    };
  },
  computed: {
    displayItems() {
      if (this.model.name === 'external_brand_safety' || this.model.name === 'conversions') {
        return this.model.options;
      }
      return this.displayOptions;
    },
  },
  created() {
    // Default Values
    this.$options.model = {
      placeholder: this.model.placeholder || 'Select...',
      optionLabel: this.model.optionLabel || 'name',
      disabled: this.model.disabled || false,
      maxHeight: this.model.maxHeight || '400px !important',
      flagLabel: this.model.flagLabel || 'value',
      exceptFlag: this.model.exceptFlag || [],
      exceptFlagMap: {},
      multiple: true,
    };
    if (typeof this.model.multiple !== 'undefined') this.$options.model.multiple = this.model.multiple;
    this.$options.model.exceptFlag.forEach((flag) => { this.$options.model.exceptFlagMap[flag] = true; });
    // Set Render Type
    if (this.model.type === 'countries') {
      this.$options.OptionLabel = this.$_getOptionLabelCountry;
    } else {
      this.$options.OptionLabel = this.$_getOptionLabel;
    }
    // Set Static OR Dynamic Options
    this.localOptions = this.model.options || [];
    this.filteredOptions = this.localOptions;
    if (this.model.getOptions && this.localOptions.length === 0) {
      this.loading = true;
      this.model.getOptions().then((res) => {
        if (res) {
          this.model.options = res;
          this.localOptions = this.model.options;
          this.filteredOptions = this.localOptions;
          this.model.trigger('ready');
        }
        this.loading = false;
      }).catch((err) => {
        console.error(err);
        this.loading = false;
      });
    }
    // Bind Model
    this.$watch('value', (value) => {
      this.selected = value;
    }, { immediate: true });
  },
  methods: {
    $_input(value) {
      if (Array.isArray(value)) {
        const resetItem = value.find((item) => item.reset);
        if (resetItem) this.selected = [resetItem];
      }
      this.$_emit('selected');
    },
    $_onSearch(search) {
      this.$_search(search, this);
    },
    $_search: _.debounce(async (search, vm) => {
      search = search.toLowerCase();
      if (search === '') {
        vm.filteredOptions = vm.localOptions;
      } else if (vm.model.getSearchOptions) {
        vm.filteredOptions = await vm.model.getSearchOptions(search);
      } else {
        vm.filteredOptions = vm.localOptions.filter((item) => {
          const value = (item.name ?? item.text ?? '').toLowerCase();
          return value.includes(search);
        });
      }
      vm.$_dropdownRenderItems(true);
    }, 500),
    $_dropdownRenderItems(reset = false) {
      const ul = this.$refs.multiSelect.$el.querySelector('.vs__dropdown-menu');
      if (ul) {
        const scrollTop = ul.scrollTop || 0;
        if (reset) this.displayOptions = [];
        if (!this.displayOptions.length || (ul.scrollHeight - 30 <= ul.clientHeight + scrollTop)) {
          if (this.displayOptions.length < this.filteredOptions.length) {
            // Load items
            this.displayOptions.push(...this.filteredOptions.slice(this.displayOptions.length, this.displayOptions.length + 30));
            this.$nextTick(() => {
              ul.scrollTo(0, scrollTop);
            });
          }
        }
      }
    },
    $_onFocus() {
      this.$nextTick(() => {
        const ul = this.$refs.multiSelect.$el.querySelector('.vs__dropdown-menu');
        if (ul) {
          ul.onscroll = () => {
            this.$_dropdownRenderItems();
          };
          this.$_dropdownRenderItems();
        }
      });
    },
    $_onBlur() {
      this.$refs.multiSelect.$data.search = '';
    },
    $_getOptionLabel(option) {
      return {
        option: option[this.$options.model.optionLabel],
        'selected-option': option[this.$options.model.optionLabel],
      };
    },
    $_getOptionLabelCountry(option) {
      const flag = (option[this.$options.model.flagLabel]).toLowerCase();
      const name = option[this.$options.model.optionLabel];
      const hasFlag = !this.$options.model.exceptFlagMap[flag];
      return {
        option: hasFlag ? `<img src="/sh_static/flags/${flag}.png" style="height: 18px" class="mr-2"> <span>${name}</span>` : `<span>${name}</span>`,
        'selected-option': hasFlag ? `<img src="/sh_static/flags/${flag}.png" style="height: 18px" class="mr-1"> <span>${name}</span>` : `<span>${name}</span>`,
      };
    },
  },
};
</script>

<style lang="scss">
.cc-multi-select-wrapper {
  .v-select {
    min-height: 3.7rem;
    // height: 3.7rem;
  }
  .vs__dropdown-menu {
      z-index: 9999;
      .vs__dropdown-option.vs__dropdown-option--disabled {
        opacity: 0.5;
      }
    }
  // .multi-select-wrapper {
  //   z-index: unset !important;
  //   .vs__dropdown-menu {
  //     z-index: 9999;
  //     .vs__dropdown-option.vs__dropdown-option--selected {
  //       opacity: 0.5;
  //     }
  //   }
  //   .vs__dropdown-toggle {
  //     input {
  //       width: auto;
  //       &:focus, &:active {
  //         border: 1px solid #ddd !important;
  //       }
  //     }
  //   }
  // }
}
</style>
