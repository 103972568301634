<template>
  <vue-opti-select-light
    class="optimizer-select new-rule-dropdown dropdown-auto-width"
    :options="$c_ruleOptions"
    :unique-key="(option) => option.to.query.type"
    searchable
    single
    button-block
    :search-fields="['searchField']"
    search-placeholder="Search for rules"
    :disabled="disabled"
  >
    <template #BUTTON_PLACEHOLDER>
      <div class="new-rule-btn" v-b-tooltip.hover.top.html="{
        title: disabled ? $c_tooltipTitle : '',
        customClass:'custom-tooltip',
        delay: {hide: 300}
      }"
      >
        <i class="fa fa-plus"></i>{{ placeholder }}
      </div>
    </template>
    <template #ITEM="{ option }">
      <b-link @click="$_getSelectedItem(option)" class="rule-item">
        <div class="rule-name">
          <i v-if="option.rule_action === 'start'" class="fa fa-play" />
          <i v-if="option.rule_action === 'pause'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'start_googleads'" class="fa fa-play" />
          <i v-if="option.rule_action === 'pause_googleads'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'start_facebook'" class="fa fa-play" />
          <i v-if="option.rule_action === 'start_geo'" class="fa fa-play" />
          <i v-if="option.rule_action === 'start_device'" class="fa fa-play" />
          <i v-if="option.rule_action === 'pause_facebook'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'start_amazon'" class="fa fa-play" />
          <i v-if="option.rule_action === 'pause_device'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'pause_geo'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'pause_amazon'" class="fa fa-stop" />
          <i v-if="option.rule_action === 'change_bid'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_revcontent'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_googleads'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_gemini'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_voluumdsp'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_adgroup'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_facebook'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_amazon'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_budget_amazon'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_budget'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_budget_yahoodsp'" class="fa fa-dollar" />
          <i v-if="option.rule_action === 'change_bid_cad'" class="fa fa-dollar" />
          <b v-if="option.rule_action === 'change_coefficient'" style="width: 19px;display: inline-block;">C</b>
          <i v-if="option.rule_action === 'day_parting'" class="fa fa-calendar-check-o" />
          <i v-if="option.rule_action === 'copy'" class="fa fa-clipboard" />
          <i v-if="option.rule_action === 'copy_budget'" class="fa fa-clipboard" />
          <i v-if="option.rule_action === 'change_target_roas'" class="fa fa-percent" />
          <i v-if="option.rule_action === 'change_bid_cap'" class="fa fa-dollar" />
          <i v-if="(option.rule_action_alias === 'change_target_cpa_googleads' && option.rule_action === 'change_target_cpa')" class="fa fa-percent" />
          <i v-if="(option.rule_action === 'change_target_cpa' && option.rule_action_alias != 'change_target_cpa_googleads')" class="fa fa-dollar" />
          {{ option.content }}
        </div>
        <span class="ts-icons">
          <img
            v-for="uniqueName in option.ts_types"
            :key="uniqueName"
            :src="getTrafficSourceLogo(uniqueName)"
            :title="uniqueName"
            v-b-tooltip.hover.top="{
              customClass: 'custom-tooltip',
            }"
            alt="traffic-source-icon"
          >
        </span>
      </b-link>
    </template>
  </vue-opti-select-light>
</template>

<script>
import _object from 'lodash/fp/object';
import SelectedRule from './ruleHelper';
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'NewRuleDropdown',
  props: {
    placeholder: { type: String, default: 'New Rule' },
    campaign: { type: [String, Number] },
    tsType: { type: String },
    config: { type: [Object, null], default: () => null },
    isGlobalRule: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      rulesOptions: [],
      trafficSources: [],
      trafficSourceAccounts: [],
    };
  },
  computed: {
    $c_tooltipTitle() {
      return '<span>This feature is only available starting from the Master plan. <a href="https://membership.theoptimizer.io" target="_blank">Upgrade Now</a></span>';
    },
    $c_ruleOptions() {
      if (this.tsType) {
        return this.rulesOptions.filter((item) => item.ts_types.indexOf(this.tsType) > -1);
      }

      this.rulesOptions.forEach((rule) => {
        const newTrafficSources = [];
        rule.ts_types.forEach((ts) => {
          if (this.trafficSources.includes(ts)) { newTrafficSources.push(ts); }
        });
        rule.ts_types = newTrafficSources;
      });

      return this.rulesOptions.filter((item) => item.ts_types.length);
    },
  },
  async created() {
    [this.rulesOptions, this.trafficSourceAccounts] = await Promise.all([this.config.ruleConfig.enabledTypes.map((rule) => this.$_getItem(rule)), this.$apiStore.trafficSources.accounts()]);
    this.trafficSourceAccounts.forEach((acc) => { if (acc.trafficSourceType.status) this.trafficSources.push(acc.trafficSourceType.uniqueName); });
    this.trafficSources = [...new Set(this.trafficSources)];
  },
  methods: {
    getTrafficSourceLogo,
    $_getItem(rule) {
      let item = rule;
      if (this.isGlobalRule) {
        item = _object.merge(item, { to: { query: { is_global: true } } });
      }
      if (this.campaign) {
        item = _object.merge(item, { to: { query: { campaign_id: this.campaign } } });
      }
      item = _object.merge(item, { searchField: `${item.content} ${item.ts_types.join(' ')}` });
      return item;
    },
    $_getSelectedItem(rule) {
      const enrichedRule = this.$_getItem(rule);
      SelectedRule.setSelectedRule(enrichedRule);
      this.$router.push({ name: 'New Rule', query: enrichedRule.to.query });
    },
  },
};
</script>

<style lang="scss">
.new-rule-dropdown {
  width: 13rem;

  .btn.dropdown-toggle {
    &::after {
      color: $black-500;
    }
  }

  .new-rule-btn {
    color: $black-500;
    margin-right: 1rem;

    i {
      margin-right: .5rem;
    }
  }

  .dropdown-menu {
    .options-list {
      .option-content {
        padding: 0 !important;

        .rule-item {
          display: inline-block;
          padding: 0.7rem 1.2rem 0.7rem 1.2rem;
          width: 100%;
          color: $black-600;

          &:hover {
            color: $color-dark;
          }

          .rule-name {
            display: inline-block;
            min-width: 24.5rem;

            i {
              margin-right: 1rem;
            }
          }

          .ts-icons {
            display: inline-block;

            img {
              margin-right: .7rem;
              width: 2.4rem;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  // .dropdown-menu {
  //   // max-height: 400px;
  //   overflow-y: scroll;
  //   width: auto !important;
  //   .dropdown-item {
  //     .rule-item {
  //       width: 100%;
  //       display: inline-block;
  //       .rule-name {
  //         display: inline-block;
  //         min-width: 225px;
  //         i {
  //           padding-left: 10px;
  //           color: #151b1e
  //         }
  //       }
  //       .ts-icons {
  //         display: inline-block;
  //         img {
  //           margin-right: 5px;
  //           width: 20px;
  //           display: inline;
  //           &:last-child {
  //             margin-right: 0px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

@media (max-width: 767.98px) {
  .optimizer-select.new-rule-dropdown {
    width: 100%;
    .dropdown-menu {
      width: 100% !important;
    }
  }
}
</style>
