<template>
  <div class="subusers-settings-wrapper">
    <b-alert show class="subusers-settings-wrapper__alert">
      Build your team by inviting other users and give them access to specific resources!
    </b-alert>

    <div v-if="!preloader" class="invite-user-button">
      <b-btn class="secondary-button" @click="$_openInviteModal"><i class="fa fa-plus"></i> Invite User</b-btn>
    </div>

    <LoadingSkeleton
      v-if="preloader"
      :rows="2"
      type="table"
      :table="{ headItems: 7, bodyItems: 5 }"
      shape="square"
    />
    <div v-else>
      <div class="optimizer-table subusers-table">
        <vue-opti-table-light
          v-if="table.items.length > 0"
          name="SubusersSettingsTable"
          class="table-wrapper"
          :hover="true"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :default-rows="100"
          :show-search="false"
          :show-pagination="false"
          :enable-export="false"
          row-key="email"
        >

          <template #actions="{ item }">
            <div class="d-flex justify-content-center p-3">
              <switch-button
                :checked="item._enabled"
                :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
                :update="(value) => $_changeStatus({ item: item, value })"
              />
              <action-btn v-if="item._type === 'subuser'" type="resetPassword" title="Reset password" :click="() => $_resetPassword({ item })" />
              <action-btn v-else type="evenlope" title="Resend invitation email" :click="() => $_resendInvitationEmail({ item })" />
              <action-btn type="delete" class="ml-2" :click="() => $_delete({ item })" />
            </div>
          </template>
          <template #status="{ item }">
            <span v-if="item._enabled" class="status-active">{{ item._status }}</span>
            <span v-else class="status-disabled">{{ item._status }}</span>
          </template>
          <template #accounts="{ item }">
            <vue-opti-select-light
              class="optimizer-select"
              :options="$options.trafficSourceAccounts"
              :groups="$options.trafficSourceTypesGroup"
              unique-key="id"
              label-key="name"
              option-type="checkbox"
              searchable
              :search-fields="['name']"
              @change="(options) => { $_changeAccounts(item, options) }"
              :value="item.allowed_accounts.map(({ id }) => id)"
              button-placeholder="Select Accounts"
              button-type="tag"
              button-block
            >
              <template #ITEM="{ option }">
                <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.trafficSourceType.uniqueName)" :alt="option.trafficSourceType.uniqueName" :title="option.trafficSourceType.uniqueName" />
                <div class="acc-item-name">
                  {{ option.name }}
                </div>
              </template>
              <template #TAG_LABEL="{ option }">
                <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.trafficSourceType.uniqueName)" :alt="option.trafficSourceType.uniqueName" :title="option.trafficSourceType.uniqueName" />
                <div class="acc-item-name">
                  {{ option.name }}
                </div>
              </template>
            </vue-opti-select-light>
          </template>
        </vue-opti-table-light>
      </div>
      <invite-modal ref="account-modal" @add="$_addAccount" />
    </div>
  </div>
</template>

<script>
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import notifications from '@sh/mixins/notifications';
import { differenceWith, isEqual } from 'lodash';
import InviteModal from '../modals/InviteModal';
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'SubusersSettings',
  components: { LoadingSkeleton, InviteModal },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      table: {
        fields: [
          {
            header: { content: 'Action', style: 'width: 1%' },
            item: {
              key: 'actions',
              slot: 'actions',
              style: { textAlign: 'center', padding: '.5rem 1.5rem' },
            },
          },
          {
            header: { content: 'Status', style: '' },
            item: {
              key: 'status',
              slot: 'status',
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Email', style: '' },
            item: {
              key: 'email',
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Username', style: '' },
            item: {
              key: 'username',
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Full Name', style: '' },
            item: {
              key: 'firstname',
              content: (item) => `${item.firstname || ''} ${item.lastname || ''}`,
              sortable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Accounts', style: '' },
            item: {
              key: 'accounts',
              slot: 'accounts',
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
    };
  },
  async created() {
    this.$options.statusOptionsMap = { true: 'ENABLED', false: 'DISABLED' };
    this.preloader = true;
    try {
      const [invitations, subusers, trafficSourceTypes, trafficSourceAccounts] = await Promise.all([this.$api.invitation.getInvitations(), this.$api.subusers.getAllSubusers(), this.$apiStore.trafficSources.types(), this.$apiStore.trafficSources.accounts()]);
      this.$options.trafficSourceTypesGroup = trafficSourceTypes.map((type) => ({ value: type.uniqueName, content: type.name }));
      this.$options.trafficSourceAccounts = trafficSourceAccounts.map((account) => ({ ...account, group: account.trafficSourceType.uniqueName }));
      this.table.items.push(...invitations.map(this.$_mapUserData), ...subusers.map(this.$_mapUserData));
    } catch (error) {
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    }
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    $_mapUserData(user) {
      // Detect if invitation or subuser
      const _type = user.token ? 'invitation' : 'subuser';
      const _enabled = typeof user[`${this.$platform}Access`] !== 'undefined' ? user[`${this.$platform}Access`] : user[`${this.$platform}_access`];
      const _status = user.token ? `PENDING ( ${this.$options.statusOptionsMap[`${_enabled}`]} )` : this.$options.statusOptionsMap[`${_enabled}`];
      return { ...user, _type, _enabled, _status };
    },
    async $_changeStatus({ item, value }) {
      try {
        const api = item._type === 'subuser' ? this.$api.subusers : this.$api.invitation;
        const id = item._type === 'subuser' ? item.user_id : item.id;
        const user = value ? await api.enableAccess(id) : await api.disableAccess(id);
        const rowIndex = this.table.items.findIndex(({ email }) => email === user.email);
        if (rowIndex > -1) {
          this.$set(this.table.items, rowIndex, this.$_mapUserData(user));
        }
        item._enabled = value;
        this.$n_successNotification({ title: 'Status successfully updated' });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    async $_delete({ item: user }) {
      try {
        const swal = await this.$swal({
          title: `Do you want to delete "${user.email}"?`,
          showCancelButton: true,
          confirmButtonText: `Yes, Delete "${user.email}"`,
          cancelButtonText: 'No, keep me in',
        });
        if (swal.value === true) {
          try {
            if (user._type === 'subuser') {
              await this.$api.subusers.deleteSubuser(user.user_id);
            } else {
              await this.$api.invitation.deleteInviation(user.id);
            }
            const rowIndex = this.table.items.findIndex(({ email }) => email === user.email);
            if (rowIndex > -1) {
              this.table.items.splice(rowIndex, 1);
            }
            this.$n_successNotification({ title: `Account "${user.email}" successfully deleted!` });
            return true;
          } catch (error) {
            try {
              this.$n_failNotification({ title: error.response.data.message });
            } catch (err) {
              this.$n_failNotification({ title: 'An error occurred' });
            }
            return false;
          }
        }
      } catch (value) { /* Nothing here */ }
      return false;
    },
    async $_resetPassword({ item: user }) {
      try {
        const swal = await this.$swal({
          title: `Do you want to reset password "${user.email}"?`,
          showCancelButton: true,
          confirmButtonText: `Yes, Reset password for "${user.email}"`,
          cancelButtonText: 'No, keep old password',
        });
        if (swal.value === true) {
          try {
            await this.$api.subusers.resetSubuserPassword(user.user_id);
            this.$n_successNotification({ title: `An email has been sent to "${user.email}" with the instructions!` });
            return true;
          } catch (error) {
            try {
              this.$n_failNotification({ title: error.response.data.message });
            } catch (err) {
              this.$n_failNotification({ title: 'An error occurred' });
            }
            return false;
          }
        }
      } catch (value) { /* Nothing here */ }
      return false;
    },
    async $_resendInvitationEmail({ item: user }) {
      try {
        const swal = await this.$swal({
          title: `Do you want to resend invitation email "${user.email}"?`,
          showCancelButton: true,
          confirmButtonText: `Yes, Resend email to "${user.email}"`,
          cancelButtonText: 'No, don\'t resend',
        });
        if (swal.value === true) {
          try {
            await this.$api.invitation.resendInvitation(user.id);
            this.$n_successNotification({ title: `Email invitation successfully sent to "${user.email}"!` });
            return true;
          } catch (error) {
            try {
              this.$n_failNotification({ title: error.response.data.message });
            } catch (err) {
              this.$n_failNotification({ title: 'An error occurred' });
            }
            return false;
          }
        }
      } catch (value) { /* Nothing here */ }
      return false;
    },
    async $_changeAccounts(user, options) {
      const rowIndex = this.table.items.findIndex(({ email }) => email === user.email);
      if (rowIndex > -1) {
        const row = this.table.items[rowIndex];
        const allowed_accounts = options.map(({ id, trafficSourceType: { platform } }) => ({ id, platform }));
        // Diff accounts to "add" & "remove"
        const addAccounts = differenceWith(allowed_accounts, row.allowed_accounts, isEqual);
        const removeAccounts = differenceWith(row.allowed_accounts, allowed_accounts, isEqual);
        // Dirty, dirty, dirty API
        const apiPromiseAll = [];
        if (row._type === 'subuser') {
          if (addAccounts.length) addAccounts.forEach(({ id }) => { apiPromiseAll.push(this.$api.subusers.addAccountToSubuser(user.user_id, id)); });
          if (removeAccounts.length) removeAccounts.forEach(({ id }) => { apiPromiseAll.push(this.$api.subusers.removeAccountFromSubuser(user.user_id, id)); });
        } else {
          if (addAccounts.length) addAccounts.forEach(({ id }) => { apiPromiseAll.push(this.$api.invitation.addAccountToInvitation(user.id, id)); });
          if (removeAccounts.length) removeAccounts.forEach(({ id }) => { apiPromiseAll.push(this.$api.invitation.removeAccountFromInvitation(user.id, id)); });
        }
        // Update table UI
        row.allowed_accounts = allowed_accounts;
        try {
          await Promise.all(apiPromiseAll);
          this.$n_successNotification({ title: 'Account updated successfully!' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    $_openInviteModal() {
      this.$refs['account-modal'].show();
    },
    async $_addAccount(account) {
      try {
        const users = await this.$api.invitation.createInvitation({
          email: account.email,
          mobile_access: this.$platform === 'mobile',
          native_access: this.$platform === 'native',
          adult_access: this.$platform === 'adult',
          allowed_accounts: account.accounts,
        });
        const user = users.find(({ email }) => email === account.email);
        if (user) {
          this.table.items.push(this.$_mapUserData(user));
          this.$n_successNotification({ title: `"${account.email}" successfully invited!` });
        }
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.subusers-settings-wrapper {
  &__alert {
    background: rgba(132, 164, 247, 0.2);
    font-size: 1.5rem;
    color: var(--black-600);
    padding: 2rem;
    border: none;
    border-radius: .6rem;
    margin-bottom: 1.5rem;
  }

  .invite-user-button {
    margin-bottom: 1rem;

    .secondary-button {
      height: 3.6rem;
    }
  }

  .status-active {
    color: $color-green;
  }
  .status-disabled {
    color: $color-dark-red;
  }
  .optimizer-select {
    .button-placehoder-tag {
      .tag-item {
        .acc-item-icon {
          height: 25px;
          margin-right: 5px;
          display: inline-block;
        }
        .acc-item-name {
          display: inline-block;
        }
      }
    }
  }

  .subusers-table {
    .table-holder {
      overflow: visible !important;

      table {
        td {
          overflow: visible;
        }
      }
    }
  }
}
</style>
