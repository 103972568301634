<template>
  <div class="content-wrapper smart-lists-container">

    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Smart Lists</h1>
        <p class="section-header__content__subtitle"><a href="https://theoptimizer.io/help/knowledge-base/working-with-smartlists" target="_blank">Learn More <i class="fa fa-angle-right ml-1"></i></a></p>
      </div>
    </div>

    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 1 }"
    />
    <template v-else>
      <div class="optimizer-table smart-lists-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          name="listsTable"
          class="lists-wrapper"
          :hover="true"
          :enable-export="false"
          :selectable="true"
          select-label="Lists"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :resized-columns="resizedColumns"
          @resize="$_handleColumnsResize($event)"
          :sort="{ key: 'id', order: 'desc' }"
          :default-rows="100"
          row-key="id"
          sticky
        >
          <template slot="search">
            <div class="col-md-auto add-list">
              <b-btn :to="'/smart-lists/new'" class="secondary-button">
                <i class="fa fa-plus" /> New List
              </b-btn>
            </div>
          </template>
          <template slot="name" slot-scope="props">
            <b-link :to="{name: 'Edit Smart List', params: { id: props.item.id }}">
              {{ props.item.name }}
            </b-link>
          </template>
          <template slot="is_whitelist" slot-scope="props">
            <div v-if="props.item.is_whitelist" class="list-type-item">
              <div class="flags">
                <div class="flag-item label-light-blue">
                  W
                </div>
              </div> Whitelist
            </div>
            <div v-else class="list-type-item">
              <div class="flags">
                <div class="flag-item label-red">
                  B
                </div>
              </div> Blacklist
            </div>
          </template>
          <template #actions="{ item }">
            <div class="d-flex justify-content-center">
              <switch-button
                :checked="item.enabled"
                :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                :update="(value) => $_updateStatus(item, value)"
              />
              <action-btn type="clone" title="Clone" class="mr-2" :to="{ name: 'Clone Smart List', params: { id: item.id } }" />
              <action-btn type="delete" title="Delete" :click="() => $_delete(item)" />
            </div>
          </template>
        </vue-opti-table-light>
      </div>
    </template>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'SmartLists',
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      selectedLists: [],
      resizedColumns: {},
      table: {
        fields: [
          {
            header: { content: 'List ID', style: '' },
            display: false,
            item: {
              key: 'id',
              content: (item) => item.id,
              style: { textAlign: 'center' },
              searchable: true,
            },
          },
          {
            header: { content: 'Actions', style: '' },
            item: {
              key: 'actions',
              slot: 'actions',
              style: { textAlign: 'center', width: '1%' },
            },
          },
          {
            header: { content: 'List Name', style: '' },
            item: {
              key: 'name',
              slot: 'name',
              content: (item) => item.name,
              sortable: true,
              searchable: true,
              cellClass: 'cell-name',
              total: {
                parse: () => 1,
                content: (totals) => `Total: ${totals.name}`,
                style: { background: '#fffdf5', fontWeight: 'bold', textAlign: 'center' },
              },
            },
            colClass: 'sticky sticky--name',
          },
          {
            header: { content: 'List Type', style: '' },
            item: {
              key: 'is_whitelist',
              slot: 'is_whitelist',
              content: (item) => {
                if (item.is_whitelist) {
                  return 'Whitelist';
                }
                return 'Blacklist';
              },
              style: { textAlign: 'center' },
              sortable: true,
            },
          },
          {
            header: { content: 'Rules Writing To List', style: '' },
            item: {
              key: 'ruleIds',
              content: (item) => `<b>${item.ruleIds.length}</b> rule/s`,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Campaigns Reading To List', style: '' },
            item: {
              key: 'campaignIds',
              content: (item) => `<b>${item.campaignIds.length}</b> campaign/s`,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Widgets On List', style: '' },
            item: {
              key: 'widgetIds',
              content: (item) => `<b>${item.widgetIds.length}</b> widget/s`,
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
    };
  },
  watch: {
  },
  async created() {
    this.preloader = true;
    /** ***************** Set Resized Columns from Local Storage **************** */
    this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('smartLists', 'table');
    /** ************************************************************************* */

    try {
      const result = await this.$apiStore.lists.all();
      if (result) {
        this.table.items = [...result];
      }
    } catch (error) {
      this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
    }
    this.preloader = false;
  },
  methods: {
    async $_updateStatus(item, enabled) {
      try {
        const result = await this.$api.lists.updateStatus(item.id, enabled);
        item.enabled = enabled;
        this.$n_successNotification({ title: result.message || 'Status updated successfully' });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
    },
    async $_delete(item) {
      const swal = await this.$swal({
        title: `Delete "${item.name}" list ?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });
      if (swal.value === true) {
        try {
          const response = await this.$api.lists.delete(item.id);
          this.table.items = this.table.items.filter((row) => row.id !== item.id);
          this.$n_successNotification({ title: response.message || 'List successfully deleted!' });
        } catch (error) {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        }
      }
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('smartLists', 'table', payload);
    },
  },
};
</script>
<style lang="scss">
.smart-lists-container {
    .section-header {
      margin-bottom: 1.5rem;
    }

    .optimizer-table {
      .table-holder {
        border: $border;
      }
    }

    .list-type-item {
      display: inline-block;
      .flags {
        display: block;
        margin-right: 5px;
        float: left;
        margin-top: -1px;
      }
    }
}
@media (max-width: 767.98px) {
  .smart-lists-container {
    .optimizer-table {
      > .datatable-wrapper {
        &-sticky {
          .stickyFooter {
            bottom: 9.4rem;
          }
        }
        > .header {
          >*:not(.datatable-search-wrapper) {
            width: 100%;
            margin: 0.5rem 1.5rem !important;
            padding: 0 !important;
            height: 4rem;
          }
        }
      }
    }
  }
}
</style>
