<template>
  <b-form :ref="getScope()" :data-vv-scope="getScope()" @submit.prevent @keyup.enter="$_handleOk">
    <b-modal
      id="bulkModal"
      @ok="$_handleOk"
      @show="$_clear"
      @shown="$refs.input.$el.focus()"
      @hidden="$_handleClose"
      ref="createGroupModal"
    >
      <h5 slot="modal-title">{{ $_getProp('title') }} <small class="text-muted">( {{ items.length }} items will be affected )</small></h5>
      <b-form-group :state="hasError('input')">
        <b-input-group>
          <b-input-group-addon v-if="left" class="text-center" slot="left" v-html="left"></b-input-group-addon>
          <b-form-input
            ref="input"
            :data-vv-as="$_getProp('fieldName')"
            v-validate="'required'"
            data-vv-validate-on="input"
            autocomplete="false"
            type="number"
            v-model="input"
            name="input"
            :min="min"
            :max="max"
            :step="step"
            :placeholder="$_getProp('placeholder')"
          >
          </b-form-input>
          <b-input-group-addon v-if="right" class="text-center" slot="right" v-html="$_getProp('right')"></b-input-group-addon>
        </b-input-group>
        <small slot="invalid-feedback">{{ getError('input') }}</small>
      </b-form-group>
    </b-modal>
  </b-form>
</template>

<script>
const scope = 'bulkModal';
export default {
  name: 'BulkModal',
  props: {
    title: { type: String, default: 'Bulk Edit' },
    placeholder: { type: String, default: 'Enter Value' },
    fieldName: { type: String, default: 'Input' },
    items: { type: Array, default: () => [] },
    itemKeyName: { type: String, default: 'id' },
    min: { type: Number, default: 0 },
    max: { type: [Number, null], default: null },
    step: { type: Number, default: 1 },
    left: { type: [String, null], default: null },
    right: { type: [String, null], default: null },
    maxLimitWarning: { type: [Number, null], default: null },
    handler: { type: Function, default: async () => false },
  },
  data() {
    return {
      input: null,
      localProps: {
        right: null,
        title: null,
        placeholder: null,
        fieldName: null,
        maxLimitWarning: null,
      },
    };
  },
  methods: {
    show(props) {
      if (props) Object.keys(this.localProps).forEach((key) => { if (props[key]) this.localProps[key] = props[key]; });
      this.$refs.createGroupModal.show();
    },
    hide() {
      this.$refs.createGroupModal.hide();
    },
    async $_handleOk(evt) {
      evt.preventDefault();
      await this.$_submit();
    },
    async $_submit() {
      const isValid = await this.$validator.validateAll(this.getScope());
      if (isValid) {
        const acceptMaxLimit = await this.$_validateLimits();
        if (acceptMaxLimit) {
          this.handler(this.input, this.items);
          this.$_clear();
          this.hide();
        }
      }
    },
    async $_validateLimits() {
      if (this.$_getProp('maxLimitWarning') && parseFloat(this.input) >= this.$_getProp('maxLimitWarning')) {
        const swal = await this.$swal({
          title: `${this.$_getProp('fieldName')} seems unusually high!`,
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Keep Anyway',
          cancelButtonText: 'Reset',
        });
        if (swal.value !== true) this.input = null;
        return swal.value;
      }
      return true;
    },
    $_clear() {
      this.input = '';
      this.$validator.reset(this.getScope());
    },
    $_handleClose() {
      Object.keys(this.localProps).forEach((key) => { this.localProps[key] = null; });
    },
    $_getProp(name) {
      return this.localProps[name] || this[name];
    },
    getScope() {
      return scope;
    },
    hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? 'invalid' : null;
    },
    getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>

<style lang="scss">
#bulkModal {
  .input-group-addon, .input-group-btn {
    min-width: 20px !important;
  }
}
@media (min-width: 576px) {
  #bulkModal .modal-dialog {
        max-width: 500px !important;
  }
}
</style>
