<template>
  <div class="target-url-wrapper">
    <b-form-group
      class="optimizer-form-group"
    >
      <template slot="label">
        Target url:<span class="text-danger"> *</span>
      </template>
      <b-form-input
        class="optimizer-form-input"
        placeholder="http://example.com"
        id="targetUrlInput"
        :size="size"
        v-model="input"
        name="targetUrlInput"
        data-vv-as="Target url"
        v-validate="(type === 'Mgid' || type === 'Adskeeper') ? {url: {require_protocol: true, protocols: ['https']}} : {url: {require_protocol: true}}"
        data-vv-delay="0"
        :disabled="disabled"
      >
      </b-form-input>
      <div slot="description">
        <span v-if="$validator.errors.has('targetUrlInput')" class="text-danger">{{ $validator.errors.first('targetUrlInput') }}</span>
        <span v-else-if="input.length > 7" class="tokens" v-html="tokensHtml"></span>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'TargetUrl',
  mixins: [notifications],
  props: {
    tokens: { type: Array, default: () => [] },
    validate: { type: Function, default: (url, token) => encodeURI(url).indexOf(token) > -1 },
    labelCols: { type: Number, default: 3 },
    breakpoint: { type: String, default: 'md' },
    labelClass: { type: String, default: 'text-md-right' },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'sm' },
    value: { type: String, default: '' },
    type: { type: String, default: null },
  },
  data() {
    return {
      input: this.value,
    };
  },
  computed: {
    tokensHtml() {
      return this.tokens.map((item) => {
        for (const index in item) {
          if (this.validate(this.input, item[index])) {
            return `<code class="exists">${item[index]}</code>`;
          }
        }
        return `<code>${item.join(' or ')}</code>`;
      }).join(' , ');
    },
  },
  watch: {
    async input(value) {
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          this.$emit('input', value);
        } else {
          this.$emit('input', '');
        }
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.target-url-wrapper {
  .tokens {
    code {
      white-space: nowrap;
      color: #151b1e;
      -moz-box-shadow: 0px 0px 2px #aba9a9;
      -webkit-box-shadow: 0px 0px 2px #aba9a9;
      box-shadow: 0px 0px 2px #aba9a9;
      margin-bottom: 5px;
      display: inline-block;
      font-size: 10px;
      line-height: 9px;
      padding: 5px;
      &.exists {
        color: #2794ca;
      }
    }
  }
}
</style>
