
import VueTagsInput from '@johmun/vue-tags-input';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { textToInsert, timeout } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import { throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { VueTagsInput, OptimizerIcon },
  props: {
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isDisabled: { type: Boolean, default: false },
    autocompleteItems: { type: Array as PropType<MediaManager.Tag[]>, default: () => [] },
  },
  data() {
    return {
      isCreateTagOpen: false,
      hasChanges: false,
      text: '',
    };
  },
  computed: {
    filteredAutocompleteItems(): MediaManager.Tag[] {
      return this.autocompleteItems.filter(
        (value) =>
          !!value.text &&
          value.text.toLowerCase().indexOf(this.text.toLowerCase()) !== -1 &&
          !this.tags.includes(value.text)
      );
    },
  },
  mounted() {
    this.onBackgroundClick = throttle(this.onBackgroundClick, timeout);
    document.addEventListener('mouseup', this.onBackgroundClick);
  },
  destroyed() {
    document.removeEventListener('mouseup', this.onBackgroundClick);
  },
  methods: {
    onBackgroundClick(event: Event) {
      if (this.isCreateTagOpen && !(this.$refs?.addTag as HTMLInputElement)?.contains(event.target as Node)) {
        this.toggleCreateTag();
      }
    },
    toggleCreateTag() {
      this.isCreateTagOpen = !this.isCreateTagOpen && !this.isDisabled;

      if (!this.isCreateTagOpen) {
        this.text = '';

        if (this.hasChanges) {
          this.$emit('onAddTags');
        }
      }
    },
    onInsertTag(data: MediaManager.AddTag | Event) {
      const text = textToInsert(data instanceof Event ? this.text : data.tag.text, this.tags);

      if (text) {
        this.hasChanges = true;
        this.tags.push(text);
        this.text = '';
      }
      (this.$refs.emptyContainer as HTMLInputElement).click();
    },
  },
});
