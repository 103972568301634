import { NameType } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { Budget, ExecutionOption, Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import { GeoTargeting } from '../../types';

export enum Objective {
  APP_INSTALLS = 'APP_INSTALLS',
  BRAND_AWARENESS = 'BRAND_AWARENESS',
  CONVERSIONS = 'CONVERSIONS',
  EVENT_RESPONSES = 'EVENT_RESPONSES',
  LEAD_GENERATION = 'LEAD_GENERATION',
  LINK_CLICKS = 'LINK_CLICKS',
  LOCAL_AWARENESS = 'LOCAL_AWARENESS',
  MESSAGES = 'MESSAGES',
  OFFER_CLAIMS = 'OFFER_CLAIMS',
  OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION',
  OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
  OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
  OUTCOME_LEADS = 'OUTCOME_LEADS',
  OUTCOME_SALES = 'OUTCOME_SALES',
  OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC',
  PAGE_LIKES = 'PAGE_LIKES',
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES',
  REACH = 'REACH',
  STORE_VISITS = 'STORE_VISITS',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
}

export enum BidStrategy {
  LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP',
  LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP',
  COST_CAP = 'COST_CAP',
  LOWEST_COST_WITH_MIN_ROAS = 'LOWEST_COST_WITH_MIN_ROAS',
}

export const BidStrategyOptions = [
  { id: BidStrategy.LOWEST_COST_WITHOUT_CAP, label: 'Highest Volume', info: 'Get the most results for your budget.' },
  {
    id: BidStrategy.LOWEST_COST_WITH_BID_CAP,
    label: 'Cost per result goal',
    info: 'Aim for a certain cost per result while maximizing the volume of results.',
    adsetLevel: true,
  },
  {
    id: BidStrategy.COST_CAP,
    label: 'Bid cap',
    info: 'Set the highest you want to bid in any auction.',
    adsetLevel: true,
  },
  {
    id: BidStrategy.LOWEST_COST_WITH_MIN_ROAS,
    label: 'Lowest Cost With Min ROAS',
    campaignExclude: [Objective.OUTCOME_TRAFFIC, Objective.OUTCOME_LEADS],
    info: 'Aim for a certain return on ad spend while maximizing the value of results.',
  },
];

export const ObjectiveOptions = {
  [Objective.OUTCOME_SALES]: { value: Objective.OUTCOME_SALES, label: 'Sales', icon: 'salesIcon' },
  [Objective.OUTCOME_TRAFFIC]: { value: Objective.OUTCOME_TRAFFIC, label: 'Traffic', icon: 'trafficIcon' },
  [Objective.OUTCOME_LEADS]: { value: Objective.OUTCOME_LEADS, label: 'Leads', icon: 'leadGenerationIcon' },
  [Objective.OUTCOME_AWARENESS]: {
    value: Objective.OUTCOME_AWARENESS,
    label: 'Awareness',
    disabled: true,
    requestFeature: `CAMPAIGN-OBJECTIVE-${Objective.OUTCOME_AWARENESS}`,
    icon: 'brandAwarenessIcon',
  },
  [Objective.OUTCOME_ENGAGEMENT]: {
    value: Objective.OUTCOME_ENGAGEMENT,
    label: 'Engagement',
    disabled: true,
    requestFeature: `CAMPAIGN-OBJECTIVE-${Objective.OUTCOME_ENGAGEMENT}`,
    icon: 'engagementIcon',
  },
  [Objective.OUTCOME_APP_PROMOTION]: {
    value: Objective.OUTCOME_APP_PROMOTION,
    label: 'App Promotion',
    requestFeature: `CAMPAIGN-OBJECTIVE-${Objective.OUTCOME_APP_PROMOTION}`,
    disabled: true,
    icon: 'appPromotionIcon',
  },
};

export enum BuyingType {
  AUCTION = 'AUCTION',
  RESERVED = 'RESERVED',
  FIXED_CPM = 'FIXED_CPM',
}

export enum CampaignOptimizationType {
  NONE = 'NONE',
  ICO_ONLY = 'ICO_ONLY',
}

export interface CreateApi {
  // internalId: string;
  // https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#example-2
  adlabels: Array<{ name: string }>;
  bid_strategy: BidStrategy;
  budget_schedule_specs?: Array<Budget>;
  buying_type: BuyingType;
  campaign_optimization_type?: CampaignOptimizationType;
  daily_budget: number;
  ruleIds?: string[];
  // list<enum{validate_only, include_recommendations}>
  execution_options?: Array<ExecutionOption>;
  lifetime_budget: number;
  name: string;
  objective: Objective;
  source_campaign_id?: string;
  special_ad_categories: Array<string>;
  special_ad_category_country: Array<string>;
  spend_cap: number;
  start_time?: Date;
  status: Status;
  stop_time?: Date;
  topline_id?: string;
}

export interface Create extends Omit<CreateApi, 'name' | 'special_ad_category_country'> {
  name: NameType;
  account_id: number;
  special_ad_category_country: Array<GeoTargeting>;
}
