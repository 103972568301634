
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: String,
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCheckboxClick(value: boolean) {
      this.$emit('change', value);
    },
  },
});
