<template>
  <div class="custom-stats-update-container">
    <div class="custom-stats">
      <b-alert show variant="info" class="custom-stats__info">
        Create automations that will update your traffic source and tracker stats at the selected
        hour.<br />This is useful when you have late conversions or revenue coming in for past
        days. <br /><b>Note:</b> The system will continue to update the stats for the current day
        anyway. These are to be considered as extra updates.
      </b-alert>

      <!-- Custom Stats Cards -->
      <div class="row custom-stats__cards">
        <div v-if="!form.length && !preloader" class="col-12">
          <h5 class="text-center mb-3">
            No automations! To add a new automation click on <code>+ New Automation</code> button.
          </h5>
        </div>
        <template v-if="preloader">
          <div v-for="item in 2" :key="item" class="col-sm-6 col-md-4">
            <LoadingSkeleton
              class="manual-update-card-skeleton"
              type="card"
              :card="{ rows: 2, shape: 'square' }"
            />
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in form" class="col-sm-6 col-md-4 mb-5" :key="index">
            <!-- Custom Stats Card -->
            <div class="custom-stats-card">
              <div
                class="custom-stats-card__header d-flex justify-content-between align-items-center"
              >
                <div class="custom-stats-card__header__name d-flex align-items-center">
                  <img
                    class="type-img pr-1"
                    :src="getTrafficSourceLogo(item.type.uniqueName)"
                    :title="item.type.uniqueName"
                    :alt="item.type.uniqueName"
                  />
                  {{ item.type.name }}
                </div>
                <svg
                  @click="$_removeSchedule(index)"
                  v-b-tooltip.hover
                  :title="`Remove ${item.type.name} Scheduler`"
                  width="12"
                  height="12"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L8 8" stroke="#546582" stroke-width="2" stroke-linecap="round" />
                  <path d="M8 1L1 8" stroke="#546582" stroke-width="2" stroke-linecap="round" />
                </svg>
              </div>
              <div class="custom-stats-card__body">
                <div class="custom-stats-card__body__item d-flex justify-content-between">
                  <span>Run update at (UTC):</span>
                  <vue-opti-select-light
                    class="optimizer-select"
                    label-key="text"
                    :value="item.hour"
                    @change="({ value }) => (item.hour = value)"
                    single
                    :options="$options.hoursOptions"
                  >
                  </vue-opti-select-light>
                </div>
                <div class="custom-stats-card__body__item d-flex justify-content-between">
                  <span>Update stats for:</span>
                  <vue-opti-select-light
                    class="optimizer-select"
                    label-key="text"
                    :value="item.interval"
                    @change="({ value }) => (item.interval = value)"
                    single
                    :options="$options.intervalOptions"
                  >
                  </vue-opti-select-light>
                </div>
                <div
                  class="custom-stats-card__body__item__small-text d-flex justify-content-end small-text"
                  v-if="item.last_executed"
                >
                  <span>Last executed:
                    {{
                      new Date(item.last_executed).toLocaleString('en-US', { timeZone: 'UTC' })
                    }}</span>
                </div>
                <div class="custom-stats-card__body__item d-flex justify-content-between">
                  <b-form-checkbox
                    v-model="item.onlyTracker"
                    :name="`onlyTracker-${item.type.uniqueName}`"
                  >
                    Update Trackers Statistics Only
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="fixed-footer">
      <div class="custom-stats-add-container pull-left">
        <vue-opti-select-light
          class="optimizer-select"
          unique-key="id"
          :options="$options.trackerSourcesOptions"
          @click="({ uniqueName }) => $_addSchedule(uniqueName)"
          single
          prevent
          block
        >
          <template #BUTTON_PLACEHOLDER> <i class="fa fa-plus"></i> New Automation </template>
          <template #ITEM="{ option }">
            <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.uniqueName)" :alt="option.uniqueName" :title="option.uniqueName" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
        </vue-opti-select-light>
      </div>
      <div class="custom-stats-save-container">
        <b-btn @click="$_submit" class="primary-button">Save</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import { getTrafficSourceLogo } from '@sh/helpers';
import configFile from './config';

export default {
  name: 'CustomStatsUpdate',
  components: {
    LoadingSkeleton,
  },
  mixins: [notifications],
  data() {
    return {
      preloader: true,
      config: null,
      form: [],
    };
  },
  async created() {
    // Set Options
    this.$options.intervalOptions = [
      { value: 'yesterday', text: 'Yesterday' },
      { value: 'beforeyesterday', text: 'Before Yesterday' },
      { value: 'last3days', text: 'Last 3 days' },
      { value: 'last7days', text: 'Last 7 days' },
    ];
    this.$options.hoursOptions = [
      { value: '0', text: '00:00' },
      { value: '1', text: '01:00' },
      { value: '2', text: '02:00' },
      { value: '3', text: '03:00' },
      { value: '4', text: '04:00' },
      { value: '5', text: '05:00' },
      { value: '6', text: '06:00' },
      { value: '7', text: '07:00' },
      { value: '8', text: '08:00' },
      { value: '9', text: '09:00' },
      { value: '10', text: '10:00' },
      { value: '11', text: '11:00' },
      { value: '12', text: '12:00' },
      { value: '13', text: '13:00' },
      { value: '14', text: '14:00' },
      { value: '15', text: '15:00' },
      { value: '16', text: '16:00' },
      { value: '17', text: '17:00' },
      { value: '18', text: '18:00' },
      { value: '19', text: '19:00' },
      { value: '20', text: '20:00' },
      { value: '21', text: '21:00' },
      { value: '22', text: '22:00' },
      { value: '23', text: '23:00' },
    ];
    this.$options.trackerSourcesOptions = [];
    this.$options.trackerSourcesMap = {};
    try {
      this.config = await configFile.getConfig();
      let accounts = [];
      let scheduleData = [];
      [accounts, scheduleData] = await Promise.all([
        this.config.api.trafficSources.accounts(),
        this.config.api.activityLogs.getScheduler(),
      ]);
      accounts.forEach((item) => {
        this.$options.trackerSourcesMap[item.type.uniqueName] = item.type;
      });
      this.$options.trackerSourcesOptions = Object.values(this.$options.trackerSourcesMap).map(
        (item) => {
          item.content = `<img style="width: 20px; display: inline;" src="/sh_static/accounts/${item.uniqueName}.png"/><div style="display: inline; padding-left: 10px;">${item.name}</div>`;
          return item;
        },
      );
      if (scheduleData.length) {
        scheduleData[0].schedules.reverse();
        // Set Data
        scheduleData[0].schedules.forEach((item) => {
          this.$_addSchedule(item.uniqueName, item);
        });
      }
      this.preloader = false;
    } catch (error) {
      console.log(error);
      this.$n_failNotification({ title: 'An error occurred' });
    }
  },
  methods: {
    getTrafficSourceLogo,
    async $_submit() {
      const schedules = this.form.map((item) => {
        const newItem = { ...item, uniqueName: item.type.uniqueName };
        delete newItem.type;
        return newItem;
      });
      try {
        this.preloader = true;
        await this.config.api.activityLogs.updateScheduler({ schedules });
        this.$n_successNotification({ title: 'Schedules updated successfully' });
      } catch (err) {
        console.log(err);
        this.$n_failNotification({ title: 'An error occurred' });
      }
      this.preloader = false;
    },
    $_addSchedule(uniqueName, payload) {
      if (this.form.find((item) => {
        console.log({ item });
        return item.type.uniqueName === uniqueName;
      })) return;
      const type = this.$options.trackerSourcesMap[uniqueName];
      if (type) {
        const item = { type, hour: '0', interval: 'yesterday', onlyTracker: false };
        if (payload) {
          Object.assign(item, payload);
          delete item.uniqueName;
        }
        this.form.unshift(item);
      }
    },
    $_removeSchedule(index) {
      this.form.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.custom-stats-update-container {
  .custom-stats {
    &__info {
      background: rgba(132, 164, 247, 0.2);
      font-size: 1.5rem;
      color: $black-600;
      padding: 2rem;
      border: none;
      border-radius: 0.6rem;
      margin-bottom: 3rem;
      margin-inline: 0;
    }

    &__cards {
      .custom-stats-card {
        background: $color-white;
        border: $border;
        border-radius: 1rem;

        &__header {
          padding: 1.2rem 2rem;
          border-bottom: 0.1rem solid $color-light-gray;

          &__name {
            color: $text-primary;
            font-weight: 500;

            img {
              height: 2.2rem;
              margin-right: 0.8rem;
            }
          }
          svg {
            cursor: pointer;
          }
        }

        &__body {
          padding: 2.5rem 2rem;
          font-size: 1.6rem;
          color: $black-600;

          &__item {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            &__small-text {
              font-size: x-small;
              color: #999999;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            span {
              width: 100%;
            }

            .optimizer-select {
              width: 100%;
              display: inline-block;
            }

            .custom-checkbox {
              .custom-control-label {
                padding-left: 1rem;
                &::before {
                  width: 1.5rem;
                  height: 1.5rem;
                  top: 0.35rem;
                }
                &::after {
                  width: 1.5rem;
                  height: 1.5rem;
                  top: 0.35rem;
                }
              }
            }
          }
        }
      }
    }

    .options-list {
      max-height: 31rem !important;
    }
  }

  .manual-update-card-skeleton {
    height: 18.3rem !important;
  }
}

.fixed-footer {
  position: fixed;
  margin-left: 25rem;
  width: calc(100% - 25rem);
  background: $color-white;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  text-align: right;
  border-top: $border;
  z-index: 100;
  transition: width 0.15s ease-in-out;

  .custom-stats-add-container {
    min-width: 18rem;

    .dropdown-menu.show {
      width: 23rem;
    }
  }

  .primary-button {
    padding: 0.5rem;
    margin-right: 1%;
  }
}
</style>
