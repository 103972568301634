export default (F) => ({
  traffic_source_site_id: F.create({
    header: { content: 'Site ID' },
    item: {
      key: 'traffic_source_site_id',
      sortable: true,
      total: {
        content: (totals) => `Total: ${totals.totalElements}`,
      },
    },
  }),
});
