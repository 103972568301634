<template>
  <b-btn
    :class="[{ 'loading': loading }, trackerActionBtn ? 'tracker-action-btn' : 'actions-icon-container', `${type}-icon`]"
    :disabled="loading || disabled"
    @click="$_click"
    v-b-tooltip="{ title: $c_title, placement: 'top', interactive: false, delay: { show: 0, hide: 0 }, trigger: 'hover', boundary: 'window' }"
    :to="to"
  >
    <i v-if="!loading" class="optimizer-icon" v-html="$c_iconType" />
    <i v-else class="fa fa-refresh fa-spin"></i>
  </b-btn>
</template>

<script>
import icons from '@sh/components/Utils/icons';

export default {
  name: 'ActionBtn',
  props: {
    type: { type: String, required: true },
    click: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    trackerActionBtn: { type: Boolean, default: false },
    to: { type: [Object, null], default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    $c_title() {
      return this.title || `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)}`;
    },
    $c_iconType() {
      return icons[this.type];
    },
  },
  methods: {
    async $_click() {
      this.loading = true;
      try {
        await this.click();
      } catch (err) { /* Do nothing */ }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.actions-icon-container .optimizer-icon {
  pointer-events: none;
}
</style>
