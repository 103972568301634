<template>
  <div class="row upload-row mb-2">
    <div :class="[fileName ? 'is-active' : '', 'col-md-12 upload-csv-button mx-3']">
      <span class="upload-csv-button-text">Choose file</span>
      <span class="upload-csv-button-content-text">{{ fileName ? fileName : 'or drag and drop file here' }}</span>
      <span v-if="fileName" @click="clearFile" class="clear-file"><i class="fa fa-times"></i></span>
      <form enctype="multipart/form-data">
        <input type="file" ref="file" @dragend="loadTextFromFile" @change="loadTextFromFile">
      </form>
    </div>
    <b-modal class="text-center" centered ref="errorsModal" hide-footer>
      <template slot="modal-header">
        <h3 class="text-center">Errors in your Csv file</h3>
      </template>
      <div class="mb-4">
        <div class="text-muted mb-4" style="font-size:11px;word-wrap:break-word;" v-if="extraHeaders.length">Extra header fields: <span class="text-danger">{{ extraHeaders.join(', ') }}</span> </div>
        <div class="text-muted mb-4" style="font-size:11px;word-wrap:break-word;" v-if="missingHeaders.length">Missing header fields: <span class="text-danger">{{ missingHeaders.join(', ') }}</span> </div>
      </div>
      <div class="buttons-container d-flex">
        <b-button @click="hideErrorModal()" variant="outline-danger" class="w-100">Cancel</b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import notifications from '@sh/mixins/notifications';
import Papa from 'papaparse';

const csvHeaders = [
  'Date',
  'Type',
  'TrafficSourceCampaignId',
  'TrafficSourceWidgetId',
  'TrafficSourceContentId',
  'TrafficSourceSectionId',
  'TrafficSourceDomainId',
  'TrafficSourceSiteId',
  'TrafficSourceExchangeId',
  'TrafficSourceAdGroupId',
  'TrafficSourceTargetId',
  'TrackerCampaignId',
  'TrackerClicks',
  'TrackerConversions',
  'TrackerRevenue',
  'TrafficSourceImpressions',
  'TrafficSourceClicks',
  'TrafficSourceConversions',
  'Cost',
  'TrafficSourceRevenue',
  'PublisherRevenue',
  'PublisherClicks',
  'PublisherConversions',
];

export default {
  name: 'UploadBtn',
  mixins: [notifications],
  data() {
    return {
      hasError: false,
      fileName: null,
      extraHeaders: [],
      missingHeaders: [],
    };
  },
  methods: {
    // if extension different from csv return notification
    // if not headers ok return extra headers not known or headers missing
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const extension = file.name.split('.').slice(-1)[0];
      if (extension !== 'csv' || ev.target.files.length === 0) {
        this.$n_failNotification({ title: 'Please upload a csv file' });
      }
      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            const headers = results.meta.fields;
            this.extraHeaders = headers.filter((h) => h !== '' && !csvHeaders.includes(h));
            this.missingHeaders = csvHeaders.filter((h) => h !== '' && !headers.includes(h));
            if (this.extraHeaders.length || this.missingHeaders.length) {
              this.hasError = true;
              this.showErrorModal();
            } else {
              this.hasError = false;
            }
          } });
      };
    },
    showErrorModal() {
      this.$refs.errorsModal.show();
    },
    hideErrorModal() {
      this.$refs.errorsModal.hide();
    },
    clearFile() {
      this.fileName = '';
      this.extraHeaders = [];
      this.missingHeaders = [];
      this.hasError = false;
      this.$refs.file.value = '';
      this.$emit('on-clear');
    },
  },
};
</script>

<style>
.upload-csv-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  max-width: 100%;
  border: 1px dashed #c4c4c4;
  border-radius: 3px;
  padding: 0 20px;
  transition: .5s;
}

.upload-csv-button:hover {
  background: #f4fafc;
}

.upload-csv-button.is-active {
  background: #ecf0f1;
}

.upload-csv-button input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-csv-button input[type="file"]:focus {
  outline: none;
}

.upload-csv-button-text {
  display: inline-block;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  padding: 5px 12px;
  margin-right: 10px;
  font-size: 11px;
  text-transform: uppercase;
}

.is-active .upload-csv-button-content-text {
  flex: 1;
}

.upload-csv-button-content-text {
  font-size: 11px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: .5s;
}

.upload-csv-button .clear-file {
  z-index: 1;
  cursor: pointer;
}

.upload-row {
  margin-right: 15px !important;
}

@media only screen and (max-width: 767px) {
  .upload-row {
    margin-right: -15px !important;
  }
}
</style>
