import { AnonymousField } from '@/views/Automation/CampaignCreatorV2/ab/core/helpers';
import { Account } from '@sh/types';
import uid from 'uid';

export enum TaskStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum TaskCategory {
  Facebook = 'Facebook',
}

export enum TaskType {
  CREATE_ALL_IN_ONE = 'CREATE_ALL_IN_ONE',
  CAMPAIGN = 'CREATE_CAMPAIGN',
  ADSET = 'CREATE_ADSET',
  AD = 'CREATE_AD_AND_CREATIVE',
}
export interface TaskPayload<P extends Record<string, any> = any> {
  subTasks: Array<{
    taskId: string;
    taskType: string;
    taskCategory: string;
  }>;
  jobId: string;
  traffic_source_account_id: number;
  traffic_source_unique_name: string;
  name: string;
  status: TaskStatus;
  taskId: string;
  taskType: TaskType;
  taskCategory: TaskCategory;
  payload: P;
}

export abstract class CampaignCreatorTask<P extends Record<string, any> = any> {
  private subTaskList: CampaignCreatorTask[] = [];
  private jobId!: string;
  private name!: string;
  private traffic_source_account_id!: number;
  private traffic_source_unique_name!: string;
  private status: TaskStatus = TaskStatus.PENDING;
  private taskId!: string;
  private internalId!: string;
  private parentInternalId!: string;
  private taskType!: TaskType;
  private taskCategory!: TaskCategory;
  private payload!: P;

  constructor({ taskType, taskCategory, jobId }: { taskType: TaskType; taskCategory: TaskCategory; jobId?: string }) {
    this.taskId = uid();
    this.internalId = uid();
    this.taskType = taskType;
    this.taskCategory = taskCategory;
    this.jobId = jobId || uid();
  }

  public getTaskCategory(): string {
    return this.taskCategory;
  }

  public getSubTaskList() {
    return this.subTaskList;
  }

  public abstract itemMetrics(): {
    meta: Record<string, { name: string; key: string }>;
    metrics: Record<string, number | string[]>;
  };

  public getAllMetrics(): Array<{
    meta: Record<string, { name: string; key: string; suffix?: string }>;
    metrics: Record<string, number | string[]>;
  }> {
    const subTaskMetrics = this.subTaskList.map((task) => task.getAllMetrics()).flat();
    return [this.itemMetrics(), ...subTaskMetrics];
  }

  public getTaskType(): string {
    return this.taskType;
  }

  public getPayload() {
    return this.payload;
  }

  public setPayload(value: P) {
    this.payload = value;
    return this;
  }

  public getTaskId() {
    return this.taskId;
  }

  public getInternalId() {
    return this.internalId;
  }

  public setParentInternalId(value: string) {
    this.parentInternalId = value;
    return this;
  }

  public getParentInternalId() {
    return this.parentInternalId;
  }

  public getStatus() {
    return this.status;
  }

  public setStatus(value: TaskStatus) {
    this.status = value;
    return this;
  }

  public getName() {
    return this.name;
  }

  protected setName(value: string) {
    this.name = value;
    return this;
  }

  public getJobId() {
    return this.jobId;
  }

  public setJobId(value: string) {
    this.jobId = value;
    return this;
  }

  public getAnonymousVariable(
    from: string,
    metadata: { key: string; key_path: string[] } = {
      key: 'id',
      key_path: ['id'],
    }
  ): AnonymousField {
    return {
      internalId: from,
      key: metadata.key,
      key_path: metadata.key_path,
    };
  }

  public get subTasks(): Array<{
    taskId: string;
    taskType: string;
    taskCategory: string;
  }> {
    return this.subTaskList.map((task) => ({
      taskId: task.getTaskId(),
      taskType: task.getTaskType(),
      taskCategory: task.getTaskCategory(),
    }));
  }

  public registerSubTask(subTask: CampaignCreatorTask) {
    subTask.setParentInternalId(this.getInternalId());
    this.subTaskList.push(subTask);
  }

  public getAllTasks(): CampaignCreatorTask[] {
    return [this, ...this.subTaskList.map((task) => task.getAllTasks()).flat()];
  }

  public setTsAccount(account: Account<any>) {
    this.traffic_source_account_id = account?.id;
    this.traffic_source_unique_name = account?.trafficSourceType?.uniqueName;
    return this;
  }

  public getTaskForCreation(): TaskPayload {
    return {
      subTasks: this.subTasks,
      jobId: this.jobId,
      name: this.name,
      status: this.status,
      taskId: this.taskId,
      taskType: this.taskType,
      taskCategory: this.taskCategory,
      payload: this.payload,
      traffic_source_account_id: this.traffic_source_account_id,
      traffic_source_unique_name: this.traffic_source_unique_name,
    };
  }

  public getAllTaskForCreation(): TaskPayload[] {
    return this.getAllTasks().map((task) => task.getTaskForCreation());
  }

  public abstract setupTask(): void;
}
