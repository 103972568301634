import { AbortController } from '@sh/helpers';
import { apiStore } from '@sh/services/api';
import { notificationRequest, request, requestDashboard, requestPerformance, requestV2 } from '@sh/services/axios';
import session from '@sh/services/session';
import moment from 'moment';

const platform = process.env.VUE_APP_PLATFORM;

// export api
export default {
  changePassword(currentPassword, newPassword) {
    return request.post('users/change-password', {
      oldPassword: currentPassword,
      password: newPassword,
      password_confirmation: newPassword,
    });
  },
  auth: {
    async login(username, password) {
      return requestV2.post('usermanager/api/login', { username, password });
    },
    async register(firstname, lastname, email, plainPassword, username) {
      return request.post('register', {
        firstname,
        lastname,
        email,
        plainPassword,
        username,
      });
    },
    async generateHelloNextToken(user) {
      return requestV2.post('hello-next-token/sso-token', { user });
    },
  },
  profile: {
    async getProfileData() {
      return requestPerformance.get('profile');
    },
  },
  dashboard: {
    async getPerformance(from, to) {
      return requestDashboard.post('dashboard', {
        platform,
        from,
        to,
      });
    },
    async basicStats(startDate, endDate) {
      const params = { startDate, endDate };
      return request.get('dashboard-basic-stats', { params });
    },
    async campaignsPerformanceChart(startDate, endDate, type) {
      const params = { type, startDate, endDate };
      return request.get('dashboard-campaigns-performance-chart', { params });
    },
    async performance(type, startDate, endDate) {
      const params = { type, startDate, endDate };
      return request.get('dashboard-performance', { params });
    },
    async winnersLooseres(type, startDate, endDate) {
      const params = { type, startDate, endDate };
      return request.get('dashboard-winners-loosers', { params });
    },
    async getByDate(from, to) {
      return requestDashboard.post('dashboard/date', {
        from, to, platform,
      });
    },
    async getByTrafficSource(from, to) {
      return requestDashboard.post('dashboard/traffic-source', {
        from, to, platform,
      });
    },
    async getTotals(from, to) {
      return requestDashboard.post('dashboard/totals', {
        from, to, platform,
      });
    },
    async getWinners(from, to) {
      return requestDashboard.post('dashboard/winners', {
        from, to, platform,
      });
    },
    async getLosers(from, to) {
      return requestDashboard.post('dashboard/losers', {
        from, to, platform,
      });
    },
    async getAvailableMetrics() {
      return requestDashboard.post('configuration/all-user-metrics', {});
    },
  },
  trafficSources: {
    async accounts() {
      const result = await requestV2.get('usermanager/traffic-sources');
      if (session.getUser()) {
        session.setTrafficSources(Array.from(
          new Set(result.data.map((x) => x.type.uniqueName)),
        ).join(', '));

        if (session.isSearchFeed) {
          window.location.href = '/#/reporting';
        } else if (!session.hasIntegration && !window.location.pathname.includes('account-wizard')) {
          window.location.href = '/#/account-wizard?noIntegrations=true';
        }
      }
      return result;
    },
    async account(id) {
      return requestV2.get(`usermanager/traffic-sources/${id}`);
    },
    async types() {
      return requestV2.get('usermanager/traffic-source-types');
    },
    async create(params) {
      const request = await requestV2.post('usermanager/traffic-sources', params);
      apiStore.trafficSources.accounts.clearCache();
      apiStore.trafficSources.accounts();
      return request;
    },
    async update(id, params) {
      return requestV2.put(`usermanager/traffic-sources/${id}`, params);
    },
    // async delete (id) {
    //   return request.delete(`traffic-sources/${id}`)
    // },
    async updateStatus(id, status) {
      return requestV2.put(`usermanager/traffic-sources/${id}`, { status });
    },
    async createBulkAccounts(params) {
      return requestV2.post('usermanager/traffic-sources/createBulkAccounts', params);
    },
    async extendToken(params) {
      return requestV2.post('usermanager/traffic-sources/extendToken', params);
    },
    async updateBulkCredentials(params) {
      return requestV2.post('usermanager/traffic-sources/updateBulkCredentials', params);
    },
    async updateAccountsTags(params) {
      return requestV2.post('usermanager/traffic-sources/update-tags', params);
    },
    async linkTracker(trafficSourceId, trackerId, variables, sourceIdOnTracker, metrics) {
      const payload = {
        // trafficSourceId: `${trafficSourceId}`,
        // trackerId: `${trackerId}`,
        sourceIdOnTracker: `${sourceIdOnTracker}`,
      };

      if (metrics) {
        payload.metrics = metrics;
      }

      Array.isArray(variables)
        ? (payload.variables = variables)
        : (payload.variables = [variables]);
      return requestV2.post(
        `usermanager/traffic-sources-trackers/${trafficSourceId}/${trackerId}`,
        payload,
      );
    },
    async unlinkTracker(sourceId, trackerId, sourceIdOnTracker) {
      return requestV2.delete(`usermanager/traffic-sources-trackers/${sourceId}/${trackerId}/${sourceIdOnTracker || 'unknown'}`);
    },
    async users() {
      return requestV2.get('usermanager/user/settings');
    },
  },
  trackers: {
    async accounts() {
      const result = await requestV2.get('usermanager/trackers');

      if (session.getUser()) {
        session.setTrackers(Array.from(new Set(result.data.map((x) => x.type.uniqueName))).join(', '));
      }

      return result;
    },
    async account(id) {
      return requestV2.get(`usermanager/trackers/${id}`);
    },
    async types() {
      return requestV2.get('usermanager/tracker-types');
    },
    async create(params) {
      return requestV2.post('usermanager/trackers', params);
    },
    async update(id, params) {
      return requestV2.put(`usermanager/trackers/${id}`, params);
    },
    // async delete (id) {
    //   return requestV2.delete(`trackers/${id}`)
    // },
    async updateStatus(id, status) {
      return requestV2.put(`usermanager/trackers/${id}`, { status });
    },
  },
  rules: {
    async get(params) {
      return requestV2.get('rules', { params });
    },
    async index() {
      return requestV2.get('rules');
    },
    async getByCampaign(id) {
      return requestV2.get(`rules/campaign/${id}`);
    },
    async editCampaign(params) {
      const result = await requestV2.put('rules/editCampaigns', params);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async updateStatus(id, status) {
      apiStore.rules.index.clearCache();
      return requestV2.put(`rules/${id}/change-status`, { status });
    },
    async updateStatusBatch(ids, status) {
      const result = await requestV2.put('rules/change-status-batch', { ids, status });
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async create(params) {
      const result = await requestV2.post('rules', params);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async createGlobalRule(params) {
      const result = await requestV2.post('rules/global-rule', params);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async update(id, params) {
      const result = await requestV2.put(`rules/${id}`, params);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async simulate(payload) {
      return requestV2.post('rules/simulate', payload);
    },
    async remove(id) {
      const result = await requestV2.delete(`rules/${id}`);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
    async execute(id) {
      return requestV2.post('rules/execute', { id });
    },
    async action(id, status) {
      return requestV2.put(`rules/${id}`, { status });
    },
    async campaigns() {
      return request.get('campaigns');
    },
    async groups() {
      return requestV2.get('rules/groups');
    },
    async gaFields() {
      return requestV2.get('rules/ga-fields');
    },
    async gaFields4() {
      return requestV2.get('rules/ga-fields4');
    },
    async ccFields() {
      return requestV2.get('rules/cc-fields');
    },
    async templates(params) {
      return requestV2.get('rules/templates', { params });
    },
    async getRulesByGroup() {
      return requestV2.get('rules/rules-by-group');
    },
    async updateRulesByGroup(payload) {
      const result = await requestV2.post('rules/rules-by-group', payload);
      apiStore.rules.index.clearCache();
      apiStore.rules.index();
      return result;
    },
  },
  activityLogs: {
    async getActivityLogsWithPages(query, pagination) {
      const params = {
        platform,
        from: moment(query.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(query.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        searchTerm: query.search,
        page: pagination.page,
        limit: pagination.limit,
        sortBy: pagination.sortField,
        sortType: pagination.sortType,
        campaignId: pagination.campaingsIds.join(','),
        ruleId: pagination.rulesIds.join(','),
        ...(pagination.timezone && {
          timezone: pagination.timezone,
        }),
      };
      if (query.error !== 'all') {
        params.status = query.error;
      }
      if (query.affectedItem !== 'all') params.level = query.affectedItem;
      if (query.type !== 'all') params.type = query.type;
      if (query.log !== 'all') params.log = query.log;
      // eslint-disable-next-line prefer-destructuring
      if (query.campaignId && query.campaignId.length) params.campaignId = query.campaignId[0];
      const result = await requestPerformance.get('/activity', { params });
      return result;
    },
    async rollbackRuleActions(activities) {
      return request.post('/activity/rollbackRule', { activities });
    },
    getScheduler() {
      return requestV2.get('scheduler');
    },
    updateScheduler(payload) {
      return requestV2.post('scheduler', payload);
    },
  },
  reporting: {
    async get(typeName, reportType, dateType, trafficSourceAccountIds, reportFields, campaignIds, startDate, endDate) {
      return request.post('/reporting', {
        startDate,
        endDate,
        typeName,
        reportType,
        dateType,
        trafficSourceAccountIds,
        reportFields,
        campaignIds,
      });
    },
    async backgroundReporting(reportType) {
      return request.post('/backgroundReporting', {
        reportType,
      });
    },
    async reportOptions() {
      return request.get('/report-options');
    },
    async updateUserTable(type, level, fields, isReporting) {
      return request.put(
        `/configuration/table/update?level=${level}&traffic_source_name=${type}&isReporting=${isReporting}`,
        { fields },
      );
    },
    async reportTable(params) {
      return requestDashboard.get(`configuration/reporting?typeName=${params.typeName}&reportType=${params.reportType}&presetId=${params.presetId}`);
    },
  },
  notification: {
    getUserEmail() {
      return notificationRequest.get('user/email');
    },
    getSettings() {
      return notificationRequest.get('settings');
    },
    activateSlack(code) {
      return notificationRequest.post('settings/slack/activate', { code });
    },
    activateTelegram(telegramUser) {
      return notificationRequest.post('settings/telegram/activate', { telegramUser });
    },
    getNotifications(params) {
      return notificationRequest.get('notifications', { params });
    },
    async updateSettings(payload) {
      const result = await notificationRequest.post('settings', payload);
      apiStore.notification.getSettings.clearCache();
      apiStore.notification.getSettings();
      return result;
    },
  },
  configuration: {
    async updateUserTable(type, level, fields, preset_id) {
      const body = { traffic_source_name: type, level, fields, platform: 'native', preset_id };
      const request = await requestDashboard.post('configuration/update', body);
      return request;
    },
    async updateCustomMetric(metric) {
      return request.put('/configuration/customMetric/update', metric);
    },
    async cmFields(scope = 'rule') {
      return requestDashboard.post('/configuration/custom-metrics', { scope });
    },
    async reportTable(params) {
      return request.get('/configuration/table/report', { params });
    },
  },
  invitation: {
    async getInvitations() {
      return requestV2.get('usermanager/invitations');
    },
    async getSingleInvitation(id) {
      return requestV2.get(`usermanager/invitations/${id}`);
    },
    async deleteInviation(id) {
      return requestV2.delete(`usermanager/invitations/${id}`);
    },
    async createInvitation(payload) {
      return requestV2.post('usermanager/invitations', payload);
    },
    async resendInvitation(id) {
      return requestV2.post(`usermanager/invitations/resend/${id}`);
    },
    async registerFromInvitation(payload) {
      return requestV2.post('usermanager/signup/invitation', payload);
    },
    async addAccountToInvitation(invitation_id, account_id) {
      return requestV2.post(`usermanager/invitations/${invitation_id}/account/add/${account_id}`);
    },
    async removeAccountFromInvitation(invitation_id, account_id) {
      return requestV2.delete(
        `usermanager/invitations/${invitation_id}/account/remove/${account_id}`,
      );
    },
    async updateInvitation(invitation_id, payload) {
      return requestV2.put(`usermanager/invitations/${invitation_id}`, payload);
    },
    enableAccess(id) {
      return requestV2.post(`usermanager/invitations/enable/${id}`);
    },
    disableAccess(id) {
      return requestV2.post(`usermanager/invitations/disable/${id}`);
    },
  },
  subusers: {
    async getAllSubusers() {
      return requestV2.get('usermanager/subusers');
    },
    async deleteSubuser(id) {
      return requestV2.delete(`usermanager/subusers/${id}`);
    },
    async addRoleToSubuser(id, role) {
      return requestV2.post(`usermanager/subusers/role/add/${id}`, {
        name: role,
      });
    },
    async removeRoleFromSubuser(id, role) {
      return requestV2.post(`usermanager/subusers/role/remove/${id}`, {
        name: role,
      });
    },
    async addAccountToSubuser(user_id, account_id) {
      return requestV2.post(`usermanager/subusers/${user_id}/account/add/${account_id}`);
    },
    async removeAccountFromSubuser(user_id, account_id) {
      return requestV2.delete(`usermanager/subusers/${user_id}/account/remove/${account_id}`);
    },
    enableAccess(id) {
      return requestV2.post(`usermanager/subusers/enable/${id}`);
    },
    disableAccess(id) {
      return requestV2.post(`usermanager/subusers/disable/${id}`);
    },
    resetSubuserPassword(id) {
      return requestV2.post(`usermanager/subusers/${id}/reset-password`);
    },
    changeSubuserPassword(req) {
      return requestV2.post('usermanager/subusers/change-password', req);
    },
  },
  campaigns: {
    async performance(_params, signalId) {
      AbortController.abort(signalId);
      const params = { ..._params };
      params.level = 'Campaign';
      if (params.trafficSourceAccountIds && params.trafficSourceAccountIds.length > 0) {
        params.trafficSourceAccountIds = JSON.stringify(params.trafficSourceAccountIds);
      }
      if (params.trackerAccountIds && params.trackerAccountIds.length > 0) {
        params.trackerAccountIds = JSON.stringify(params.trackerAccountIds);
      }
      if (params.comparisonStartDate?.length === 0 || params.comparisonEndDate?.length === 0) {
        delete params.comparisonStartDate;
        delete params.comparisonEndDate;
      }
      return requestDashboard.post('performance', params, AbortController.create(signalId));
    },
    async performanceTotals(_params, signalId) {
      AbortController.abort(signalId);
      const params = { ..._params };
      params.level = 'Campaign';
      if (params.trafficSourceAccountIds && params.trafficSourceAccountIds.length > 0) {
        params.trafficSourceAccountIds = JSON.stringify(params.trafficSourceAccountIds);
      }
      if (params.trackerAccountIds && params.trackerAccountIds.length > 0) {
        params.trackerAccountIds = JSON.stringify(params.trackerAccountIds);
      }
      return requestDashboard.post('performance/campaign/totals', params, AbortController.create(signalId));
    },
    async getCampaignPerformance(id, params) {
      params.id = id;
      return requestDashboard.post('performance/campaignTotals', params);
    },
    async all(filter) {
      const params = {};
      if (filter) {
        const { typeName, trafficSourceAccountIds, trackerAccountIds } = filter;
        Object.entries({ typeName, trafficSourceAccountIds, trackerAccountIds }).forEach(
          ([key, value]) => {
            if (value) params[key] = JSON.stringify(Array.isArray(value) ? value : [value]);
          },
        );
      }
      return request.get('campaigns', { params });
    },
    async getByFilters(payload) {
      return request.post('campaigns/getByFilters', payload);
    },
    async getAllAdGroups(payload) {
      return request.post('adgroups/all', payload);
    },
    async getAdGroupStatusStats(_params) {
      const params = JSON.parse(JSON.stringify(_params));
      ['typeName'].forEach((key) => {
        if (params[key]) {
          params[key] = JSON.stringify(Array.isArray(params[key]) ? params[key] : [params[key]]);
        }
      });
      return request.get('adgroups/getStatusStats', { params });
    },
    async getStatusStats(_params) {
      const params = JSON.parse(JSON.stringify(_params));
      ['typeName', 'trafficSourceAccountIds', 'trackerAccountIds'].forEach((key) => {
        if (params[key]) {
          params[key] = JSON.stringify(Array.isArray(params[key]) ? params[key] : [params[key]]);
        }
      });

      return request.get('campaigns/getStatusStats', { params });
    },
    async get(id) {
      return request.get(`campaigns/${id}`);
    },
    async accounts() {
      return request.get('campaign/traffic-sources');
    },
    async action(campaignId, action) {
      return request.post('campaign-actions', { campaignId, action });
    },
    async basicStats(startDate, endDate, accountIds, trackerIds) {
      const params = { startDate, endDate };
      if (accountIds) {
        params.accountIds = accountIds;
      }
      if (trackerIds) {
        params.trackerIds = trackerIds;
      }
      return request.get('basic-stats', { params });
    },
    async index(startDate, endDate, accountIds, trackerIds, traffic_source_name) {
      const params = { startDate, endDate };
      if (accountIds) {
        params.accountIds = accountIds;
      }
      if (trackerIds) {
        params.trackerIds = trackerIds;
      }
      if (traffic_source_name) {
        params.traffic_source_name = traffic_source_name;
      }
      return request.get('campaigns-stats', { params });
    },
    async navigation(id) {
      return request.get(`campaign/${id}/navigation`);
    },
    async changePayout(id, payout) {
      return request.post(`campaigns/${id}/change-payout`, { payout });
    },
    async updateCampaignCompliance(campaignIds, compliance, sharedEmail, type) {
      return request.post('campaigns/allow-compliance', { campaign_ids: campaignIds, allow_compliance: compliance, revenue_streaming_sharing_compliance: sharedEmail, compliance_revenue_stream_type: type });
    },
    async updateContentsCompliance(contentsIds, compliance, sharedEmail, campaignId, type) {
      return request.post(`campaigns/${campaignId}/contents/allow-compliance`, { allow_compliance: compliance, revenue_streaming_sharing_compliance: sharedEmail, contentIds: contentsIds, compliance_revenue_stream_type: type });
    },
    async updateCampaignsTags(payload) {
      return request.post('campaigns/tags', { ...payload });
    },
    async updateContentTags(id, payload) {
      return request.post(`campaigns/${id}/contents/tags`, { ...payload });
    },
  },
  tags: {
    async getAllUniqueTags(level, tsType) {
      return request.post('tags', {
        level,
        tsType,
      });
    },
  },
};
