import CampaignsPerformanceFilter from '@sh/lib/CampaignsPerformanceFilter.class';
import DashboardDateRange from '@sh/lib/DashboardDateRange.class';
import RecentlySearch from '@sh/lib/RecentlySearch.class';
import ResizedColumns from '@sh/lib/ResizedColumns.class';
import TrackerPerformanceFilter from '@sh/lib/TrackerPerformanceFilter.class';
import Presets from '@sh/lib/Presets.class';

export default {
  recentlySearch: new RecentlySearch(10),
  dashboardDateRange: new DashboardDateRange(),
  campaignsPerformanceFilter: new CampaignsPerformanceFilter(),
  trackerPerformanceFilter: new TrackerPerformanceFilter(),
  resizedColumns: new ResizedColumns(),
  presets: new Presets(),
};
