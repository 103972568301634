/* eslint-disable import/no-cycle */
import * as Targeting from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdSet';
import useAbGroups from '@/views/Automation/CampaignCreatorV2/store/AbGroups';
import useABVariableStore from '@/views/Automation/CampaignCreatorV2/store/AbVariables';
import useNavigationStore from '@/views/Automation/CampaignCreatorV2/store/Navigation';
import { FieldError, InternalABVariable, SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { AdSetAbGroups, TargetingValidation } from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import uid from 'uid';

type Targeting = Pick<Targeting.TargetingObjectAbVariables, keyof typeof TargetingValidation>;
interface InternalAdSetMetadata {
  enableTargeting: boolean;
  internalId: string;
  adsetId: number;
}

type TargetingState = InternalAdSetMetadata;

export interface TargetingStore {
  globalErrors: FieldError[];
  adAccountId: string;
  targeting: {
    [campaignIdAdsetId: string]: TargetingState;
  };
}

const useTargetingStore = defineStore(SupportedEntities.targeting, {
  state: (): TargetingStore => ({
    targeting: {} as any,
    globalErrors: [],
    adAccountId: '',
  }),
  getters: {
    getField:
      (state) =>
      <K extends keyof Targeting>(key: K) =>
        useABVariableStore().getField(SupportedEntities.targeting, key),
    getInternalMetadata:
      (state) =>
      <K extends keyof InternalAdSetMetadata>(field: K): InternalAdSetMetadata[K] => {
        const navigation = useNavigationStore();
        return state.targeting[navigation.getAdsetBuildId][field];
      },
  },
  actions: {
    saveState() {
      return { targeting: this.$state };
    },
    loadState(template: any) {
      const parsedState = template.targeting as TargetingStore;
      if (parsedState) {
        this.targeting = { ...this.targeting, ...parsedState.targeting };
      }
    },
    addVariable<K extends keyof Targeting>(
      field: K,
      value: Omit<InternalABVariable<Targeting[K]>, 'variableId'> & { variableId?: string }
    ) {
      useABVariableStore().addVariable(SupportedEntities.targeting, field, value);
    },
    removeVariable<K extends keyof Targeting>(field: K, key: string) {
      useABVariableStore().removeVariable(SupportedEntities.targeting, field, key);
    },
    editVariable<K extends keyof Targeting>(field: K, key: string, value: InternalABVariable<Targeting[K]>) {
      useABVariableStore().editVariable(SupportedEntities.targeting, field, key, value);
    },
    addOrEditVariableName<K extends keyof Targeting>(field: K, groupId: string, variableId: string, newName: string) {
      const adsetBuildID = useNavigationStore().getAdsetBuildId;
      useABVariableStore().renameVariable(
        SupportedEntities.targeting,
        adsetBuildID,
        groupId,
        field,
        variableId,
        newName
      );
    },
    addOrEditVariable<K extends keyof Targeting>(field: K, value: InternalABVariable<Targeting[K]>) {
      useABVariableStore().addOrEditVariable(SupportedEntities.targeting, field, value);
    },
    updateInternalMetadata<K extends keyof InternalAdSetMetadata>(field: K, value: InternalAdSetMetadata[K]) {
      const navigation = useNavigationStore();
      this.targeting = {
        ...this.targeting,
        [navigation.getAdsetBuildId]: {
          ...this.targeting[navigation.getAdsetBuildId],
          [field]: value,
        },
      };
    },
    addError(error: Partial<FieldError>) {
      const errorId = uid();
      this.globalErrors.push({
        errorId,
        message: error.message || '',
        description: error.description || '',
        requiresAction: error.requiresAction || false,
      });
    },
    addNewTargeting() {
      const navigation = useNavigationStore();
      const adsetId = navigation.getAdSetInternalId;
      const adsetBuildId = navigation.getAdsetBuildId;
      if (!adsetId) {
        throw new Error('Ad Set ID not found');
      }
      const abGroups = useAbGroups();
      abGroups.addAbGroup(SupportedEntities.targeting, {
        typeId: AdSetAbGroups.TARGETING as any,
        entity: SupportedEntities.targeting,
        description: SupportedEntities.targeting,
        name: 'Audience Group 1',
      });
      abGroups.addAbGroup(SupportedEntities.targeting, {
        typeId: AdSetAbGroups.PLACEMENT as any,
        entity: SupportedEntities.targeting,
        description: 'PLACEMENT',
        name: 'Placement Group 1',
      });
      const targetingAbGroup = useNavigationStore().getCurrentAbGroupFor(
        SupportedEntities.targeting,
        AdSetAbGroups.TARGETING
      );
      const placementAbGroup = useNavigationStore().getCurrentAbGroupFor(
        SupportedEntities.targeting,
        AdSetAbGroups.PLACEMENT
      );
      const targetingVariables = {
        geo_locations: {
          value: [
            {
              field: 'geo_locations',
              value: {
                country_group: [
                  {
                    key: 'worldwide',
                    name: 'Worldwide',
                    type: 'country_group',
                    is_worldwide: true,
                    supports_region: true,
                    supports_city: true,
                    id: 'worldwide',
                    label: 'Worldwide',
                  },
                ],
                cities: [],
                regions: [],
                countries: [],
                zips: [],
              },
              variableId: 'geo_locations',
              abGroup: targetingAbGroup,
            },
          ],
        },
        excluded_geo_locations: {
          value: [
            {
              field: 'excluded_geo_locations',
              value: { country_group: [], cities: [], regions: [], countries: [], zips: [] },
              variableId: 'excluded_geo_locations',
              name: 'Location Variant',
              abGroup: targetingAbGroup,
            },
          ],
        },
        genders: {
          value: [{ field: 'genders', value: [0], variableId: 'genders', abGroup: targetingAbGroup }],
        },
        targeting_automation: {
          value: [
            {
              field: 'targeting_automation',
              value: { advantage_audience: 1 },
              variableId: 'u5c5qd6in3tyayjl8mfs255eu',
              abGroup: placementAbGroup,
            },
          ],
        },
        age_max: {
          value: [{ field: 'age_max', value: 65, variableId: 'age_max', abGroup: targetingAbGroup }],
        },
        age_min: {
          value: [{ field: 'age_min', value: 24, variableId: 'age_min', abGroup: targetingAbGroup }],
        },
      } as any;
      useABVariableStore().initEntity(SupportedEntities.targeting, adsetBuildId, targetingVariables);
      this.targeting = {
        ...this.targeting,
        [adsetBuildId]: {
          enableTargeting: false,
          internalId: uid(),
          adsetId,
        } as any,
      };
    },
    cloneTargeting({ currentAdsetBuildId, newAdsetBuildId }: { currentAdsetBuildId: string; newAdsetBuildId: string }) {
      const navigation = useNavigationStore();
      navigation.cloneNavigation(SupportedEntities.targeting, {
        currentBuildId: currentAdsetBuildId,
        newBuildId: newAdsetBuildId,
      });
      const abStore = useAbGroups();
      abStore.clone(SupportedEntities.targeting, {
        currentBuildId: currentAdsetBuildId,
        newBuildId: newAdsetBuildId,
      });
      useABVariableStore().cloneEntity(SupportedEntities.targeting, currentAdsetBuildId, newAdsetBuildId);

      this.targeting = {
        ...this.targeting,
        [newAdsetBuildId]: cloneDeep(this.targeting[currentAdsetBuildId]),
      };
    },
    delete(buildId: string) {
      const navigation = useNavigationStore();
      const abStore = useAbGroups();
      navigation.delete(SupportedEntities.targeting, buildId);
      abStore.delete(SupportedEntities.targeting, buildId);
      useABVariableStore().removeEntity(SupportedEntities.targeting, buildId);

      delete this.targeting[buildId];
      this.targeting = { ...this.targeting };
    },
    deleteABGroup(groupId: string) {
      const adsetBuildId = useNavigationStore().getAdsetBuildId;
      useABVariableStore().removeAbGroup(SupportedEntities.targeting, adsetBuildId, groupId);
    },
    cloneABGroup(currentGroupId: string) {
      const adsetBuildId = useNavigationStore().getAdsetBuildId;
      const abVariable = useABVariableStore();
      const currentTargeting = this.targeting[adsetBuildId];
      if (!currentTargeting) {
        console.log('Current Targeting not found');
        return;
      }
      const newGroupId = uid(8);
      useAbGroups().cloneGroup(SupportedEntities.targeting, { groupId: currentGroupId, newGroupId });
      abVariable.cloneAbGroup(SupportedEntities.targeting, adsetBuildId, currentGroupId, newGroupId);
    },
  },
});

export default useTargetingStore;
