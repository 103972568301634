<template>
  <b-form :data-vv-scope="$_getScope()" @submit.prevent class="custom-domains">
    <b-form-group class="optimizer-form-group">
      <template slot="label">
        Custom Domains (optional) <i class="fa fa-info-circle" aria-hidden="true" v-b-tooltip="{hover: true, top: true, title: 'If you are using multiple domains (custom domains) with this tracker account, please enter them below.', boundary: 'window'}"></i>
      </template>
      <div v-for="(row, i) in domains" :key="i">
        <!--Domain Input-->
        <b-form-group
          :state="$_hasError('domain-' + i)"
          :invalid-feedback="$_getError('domain-' + i)"
        >
          <b-input-group :prepend="`Domain ${i+1}`">
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Domain"
              v-validate="validateRule"
              v-model="row.url"
              :name="'domain-' + i"
            >
            </b-form-input>
            <template slot="append">
              <button @click.prevent="$_removeAction(i)" class="btn btn-secondary text-danger"><i class="fa fa-minus"></i></button>
            </template>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="add-domain-btn">
        <b-btn class="primary-button-outline" @click.prevent="$_addAction"><i class="fa fa-plus"></i> Add Domain</b-btn>
      </div>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: 'CustomDomains',
  props: {
    value: null,
    limit: { type: Number, default: 10 },
    validateRule: { type: String, default: 'url:require_protocol' },
  },
  data() {
    return {
      domains: [{ url: '' }],
    };
  },
  watch: {
    domains: {
      handler(domains) {
        this.$emit('input', domains);
      },
      deep: true,
    },
  },
  created() {
    if (Array.isArray(this.value)) {
      const domains = [];
      this.value.forEach((item) => {
        if (typeof item.url === 'string') {
          domains.push({ url: item.url });
        }
      });
      if (domains.length > 0) this.domains = domains;
    }
  },
  methods: {
    async validate() {
      return this.$validator.validateAll(this.$_getScope());
    },
    reset() {
      this.domains = [{ url: '' }];
    },
    async $_addAction() {
      if (await this.validate() && this.domains.filter((item) => item.url === '').length === 0 && this.domains.length < this.limit) {
        this.domains.push({ url: '' });
      }
    },
    $_removeAction(index) {
      if (this.domains.length > 1) {
        this.domains.splice(index, 1);
      } else if (this.domains.length === 1) {
        this.domains[0].url = '';
      }
    },
    $_getScope() {
      return 'customDomains';
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${this.$_getScope()}.${name}`) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${this.$_getScope()}.${name}`);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name, this.$_getScope());
    },
  },
};
</script>

<style lang="scss">
.custom-domains {
  margin-top: 2rem;

  .input-group {
    &-prepend,
    &-append {
      height: 3.7rem;
      background: #F4F9FC;
      border: .1rem solid $color-light-gray;
      border-radius: .6rem;
      display: flex;
      align-items: center;
      color: $black-600;
      font-size: 1.4rem;

      .input-group-text {
        font-size: 1.4rem;
        color: $black-600;
        background: transparent;
        border: none;
      }

      .btn-secondary {
        height: 3.7rem;
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        border-left: .1rem solid $color-light-gray;
        padding: 1.5rem;

        &:focus,
        &:active {
          background: transparent;
          border-color: $color-light-gray;
        }

        i {
          font-size: 1.6rem;
          padding: 0;
        }
      }
    }

    &-prepend {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-append {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .form-control {
      border-right: 0 !important;
    }
  }

  .add-domain-btn {
    .primary-button-outline {
      font-size: 1.3rem;
      padding: .5rem .8rem;

      &:hover {
        border-color: #2196F3;
      }
    }
  }
}
</style>
