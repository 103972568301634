var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('vue-opti-select-light',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:_vm.name,ref:"dateConditionField",staticClass:"optimizer-select date-condition-field",class:{
    'prevent-overflow-right': _vm.preventOverflowRight,
    'prevent-overflow-top': _vm.preventOverflowTop,
    'has-error': _vm.errorText,
    'has-custom-interval': _vm.isCustomDateInterval,
  },attrs:{"data-vv-validate-on":"input|update","labelKey":"text","optionType":"checkbox","data-vv-as":_vm.validateType,"data-vv-value-path":_vm.validatePath,"name":_vm.name,"options":_vm.radioTypeOptions,"groups":_vm.groups,"uniqueKey":_vm.uniqueKeyFunction,"lazy":"","lazyRender":"","searchable":"","emitOnClick":"","buttonBlock":"","groupCollapse":"","collapsed":"","allowClear":""},on:{"input":_vm.onItemChange,"clear":function($event){return _vm.$emit('onChange', null)},"shown":_vm.onDropdownShow,"hidden":_vm.onSaveClick},scopedSlots:_vm._u([{key:"BUTTON_PLACEHOLDER",fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:({
        title: _vm.buttonPlaceholderTooltip,
        customClass: 'custom-tooltip default-cursor max-width-270',
      }),expression:"{\n        title: buttonPlaceholderTooltip,\n        customClass: 'custom-tooltip default-cursor max-width-270',\n      }",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"button-placeholder-filter text-truncate"},[(_vm.value && _vm.value.text)?[_vm._v(" "+_vm._s(_vm.value.text)+" "),(_vm.value.interval)?_c('div',{staticClass:"fs-10 w-100 text-left text-truncate"},[_vm._v(" "+_vm._s(_vm.intervalText)+" ")]):_vm._e()]:[_vm._v(_vm._s(_vm.emptyButtonPlaceholder))]],2)]},proxy:true},_vm._l((_vm.groups),function(item){return {key:`GROUP_${item.value}`,fn:function(){return [_c('div',{key:item.content},[_vm._v(" "+_vm._s(item.content)+" ")])]},proxy:true}}),{key:"ITEM",fn:function({ option }){return [(option)?_c('FieldTooltip',{attrs:{"option":option,"info":option.description}}):_vm._e()]}},_vm._l((_vm.disabledOptions),function(item){return {key:`ITEM_BEFORE_${_vm.uniqueKeyFunction(item)}`,fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:({
        customClass: 'custom-tooltip max-width-250',
        boundary: 'viewport',
      }),expression:"{\n        customClass: 'custom-tooltip max-width-250',\n        boundary: 'viewport',\n      }",modifiers:{"hover":true,"left":true}}],key:item.value.key,staticClass:"option-tooltip not-allowed",attrs:{"title":item.tooltip || 'This option is disabled.'}})]},proxy:true}}),{key:"FOOTER",fn:function(){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right.html",value:({
        customClass: 'custom-tooltip max-width-250',
        title: _vm.dataIntervalDisabledText,
        delay: { hide: 300 },
      }),expression:"{\n        customClass: 'custom-tooltip max-width-250',\n        title: dataIntervalDisabledText,\n        delay: { hide: 300 },\n      }",modifiers:{"hover":true,"right":true,"html":true}}],staticClass:"py-12",class:{ 'not-allowed': _vm.dataIntervalMetricFieldDisabledText }},[_c('CustomInterval',{class:{ 'disabled-section': _vm.dataIntervalMetricFieldDisabledText },attrs:{"isDateIntervalSwitch":_vm.isDateIntervalSwitch,"isCustomDateInterval":_vm.isCustomDateInterval,"selectedFieldName":_vm.selectedFieldName},on:{"isCustomDateInterval":function($event){_vm.isCustomDateInterval = $event}},model:{value:(_vm.dateOperations),callback:function ($$v) {_vm.dateOperations=$$v},expression:"dateOperations"}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }