import { RuleTypes } from '@sh/types';

export const conditionsMap: RuleTypes.Conditions.ConditionMap = {
  greater: { value: { key: 'greater', behind: 'THAN' }, text: RuleTypes.Conditions.ConditionTextMap.greater },
  less: { value: { key: 'less', behind: 'THAN' }, text: RuleTypes.Conditions.ConditionTextMap.less },
  greater_equal: {
    value: { key: 'greater_equal', behind: 'THAN / TO' },
    text: RuleTypes.Conditions.ConditionTextMap.greater_equal,
  },
  less_equal: {
    value: { key: 'less_equal', behind: 'THAN / TO' },
    text: RuleTypes.Conditions.ConditionTextMap.less_equal,
  },
};
