/* eslint-disable prefer-destructuring */
import moment from 'moment';
import { api } from '@sh/services/api';
import CampaignModel from '../../lib/CampaignModel';
import countryData from './countryData';

/**
 * API Model: {
 *  name: <String>,
 *  bid_amount: <String>,
 *  budget: <String>,
 *  pacing: <String|on|off>
 *  optimize: <String|cpc|cpa>,
 *  conversion: <Array|[{id: <Integer>}]>,
 *  start_date: <String|immediately|y-m-d h:m:s>,
 *  end_date: <String|never|y-m-d h:m:s>,
 *  traffic_types: <Array|[<String>]>
 *  // target_type: <String|topic>, #Only on CREATE @DEPRECATED
 *  // target_targeting: <String|all|include|exclude>, #Only on CREATE @DEPRECATED
 *  // target_codes: <Array|[<String>]>, #Only on CREATE @DEPRECATED
 *  country_targeting: <String|all|include|exclude>,
 *  country_codes: <Array|[<String>]>,
 *  region_targeting: <String|all|include|exclude>,
 *  region_codes: <Array|[<String>]>,
 *  device_targeting: <Array|[<String|"1"-"3">]>,
 *  os_targeting: <Array|[<String|"4"-"8">]>,
 *  language_targeting: <Array|[<String>]>,
 *  tracking_code: <String>
 *  bid_type: <String>
 *  zipcode_targeting: <String>
 *  browser_targeting: <String>
 *  zipcode_codes: <Array>
 * }
 */

const BROWSERS = [];
export default class RevContentCampaignModel extends CampaignModel {
  async setModel(form, nextTick, mode) {
    this.components.name.value = mode === 'clone' ? `${form.name} CLONE` : form.name;
    this.components.bid_amount.value = form.bid_amount;
    if (form.budget === 'unlimited') {
      this.components.budget_model.value = 'unlimited';
    } else {
      this.components.budget_model.value = 'daily';
      this.components.budget.value = form.budget;
      this.components.pacing.value = form.pacing;
    }
    this.components.optimize.value = form.optimize;
    if (form.optimize === 'cpa' && form.conversion) {
      nextTick(() => {
        this.components.conversion.once('ready', () => {
          this.__helperSetMultiSelectValue({
            component: this.components.conversion,
            values: form.conversion,
            getValueKey: (item) => item.id,
            getOptionKey: (option) => option.id,
          });
        });
      });
    }
    if (mode !== 'clone') {
      if (form.start_date && form.start_date !== 'immediately') {
        this.components.start_date_model.value = 'custom';
        this.components.start_date.value = form.start_date;
      } else {
        this.components.start_date_model.value = 'immediately';
      }
      if (form.end_date && form.end_date !== 'never') {
        this.components.end_date_model.value = 'custom';
        this.components.end_date.value = form.end_date;
      } else {
        this.components.end_date_model.value = 'never';
      }
    }
    if (form.traffic_types) {
      // Set Traffic Types
      this.components.traffic_types.value = [];
      this.__helperSetMultiSelectValue({
        component: this.components.traffic_types,
        values: form.traffic_types,
        getValueKey: (code) => code,
        getOptionKey: (option) => option.id,
      });
    }
    this.components.country_targeting.value = form.country_targeting;
    if (['include', 'exclude'].indexOf(form.country_targeting) > -1) {
      // Set Country
      this.__helperSetMultiSelectValue({
        component: this.components.country_codes,
        values: form.country_codes,
        getValueKey: (code) => code,
        getOptionKey: (option) => option.value,
      });
      // Set Region
      if (form.country_codes.length === 1) {
        if (['include', 'exclude'].indexOf(form.region_targeting) > -1) {
          nextTick(() => {
            this.components.region_targeting.value = form.region_targeting;
            this.components.region_codes.once('ready', () => {
              this.__helperSetMultiSelectValue({
                component: this.components.region_codes,
                values: form.region_codes,
                getValueKey: (id) => id,
                getOptionKey: (option) => option.value,
              });
            });
          });
        }
      }
    }
    // Set Device Targeting
    if (form.device_targeting.length) {
      const allowedDeviceTargeting = { 1: true, 2: true, 3: true };
      this.components.platform_targeting.value = form.device_targeting.filter((val) => allowedDeviceTargeting[val]);
      this.components.os_targeting_desktop.value = [];
      this.components.os_targeting_mobile.value = [];
      if (form.os_targeting && form.os_targeting.length) {
        form.os_targeting.forEach((val) => {
          if (['7', '8'].indexOf(val) > -1) {
            this.components.os_targeting_desktop.value.push(val);
          } else {
            this.components.os_targeting_mobile.value.push(val);
          }
        });
      } else {
        this.components.os_targeting_desktop.value = ['7', '8'];
        this.components.os_targeting_mobile.value = ['4', '5', '6'];
      }
    }
    // Language Targeting
    if (form.language_targeting) {
      this.__helperSetMultiSelectValue({
        component: this.components.language_targeting,
        values: form.language_targeting,
        getValueKey: (id) => id,
        getOptionKey: (option) => option.id,
      });
    }
    this.components.tracking_code.value = form.tracking_code || '';
    this.components.bid_type.value = form.bid_type;
    this.components.browser_targeting.value = form.browser_targeting;
    this.components.zipcode_targeting.value = form.zipcode_targeting;

    if (form.zipcode_codes) {
      this.components.zipcode_codes.value = form.zipcode_codes.map((item) => (item));
    }

    if (mode === 'edit') {
      nextTick(() => {
        this.components.conversion.disabled = true;
        this.components.conversion.componentPopover = {
          title: 'Conversion disabled',
          content: 'This feature can only be edited on RevContent.',
          html: true,
        };
        // this.components.target_type.active = false;
        // this.components.target_targeting.active = false;
        // this.components.target_codes.active = false;
      });
    }
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const form = {
      campaignCreatorV2: true,
      name: modelForm.name,
      bid_amount: modelForm.bid_amount,
      budget: modelForm.budget || modelForm.budget_model,
      optimize: modelForm.optimize,
      start_date: modelForm.start_date || modelForm.start_date_model,
      end_date: modelForm.end_date || modelForm.end_date_model,
      country_targeting: modelForm.country_targeting,
      device_targeting: [...modelForm.platform_targeting],
      os_targeting: [],
      language_targeting: [],
      tracking_code: modelForm.tracking_code,
      bid_type: modelForm.bid_type,
      browser_targeting: modelForm.browser_targeting?.length ? modelForm.browser_targeting : 'all',
      zipcode_targeting: modelForm.zipcode_targeting,
      zipcode_codes: [],
    };

    if (modelForm.zipcode_codes) {
      modelForm.zipcode_codes.forEach((item) => {
        form.zipcode_codes.push(item.text);
      });
    }

    if (typeof modelForm.pacing !== 'undefined') form.pacing = modelForm.pacing;
    if (typeof modelForm.conversion !== 'undefined') form.conversion = modelForm.conversion.map(({ id }) => ({ id }));
    if (typeof modelForm.traffic_types !== 'undefined') form.traffic_types = modelForm.traffic_types.map(({ id }) => id);
    // if (typeof modelForm.target_type !== 'undefined') form.target_type = modelForm.target_type;
    // if (typeof modelForm.target_targeting !== 'undefined') form.target_targeting = modelForm.target_targeting;
    // if (typeof modelForm.target_codes !== 'undefined') form.target_codes = modelForm.target_codes.map(({ id }) => id);
    if (typeof modelForm.country_codes !== 'undefined') form.country_codes = modelForm.country_codes.map(({ value }) => value);
    if (typeof modelForm.region_targeting !== 'undefined') form.region_targeting = modelForm.region_targeting;
    if (typeof modelForm.region_codes !== 'undefined') form.region_codes = modelForm.region_codes.map(({ value }) => value);
    if (typeof modelForm.os_targeting_desktop !== 'undefined') form.os_targeting.push(...modelForm.os_targeting_desktop);
    if (typeof modelForm.os_targeting_mobile !== 'undefined') form.os_targeting.push(...modelForm.os_targeting_mobile);
    if (typeof modelForm.language_targeting !== 'undefined' && modelForm.language_targeting.length) form.language_targeting = modelForm.language_targeting.map(({ id }) => id);

    if (form.zipcode_codes && form.zipcode_codes.length === 0) {
      delete form.zipcode_codes;
      form.zipcode_targeting = 'all';
    }

    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      bidOptions: [
        { text: 'CPC', value: 'cpc' },
        { text: 'vCPM', value: 'cpv' },
      ],
      budgetOptions: [
        { text: 'Unlimited', value: 'unlimited' },
        { text: 'Daily', value: 'daily' },
      ],
      pacingOptions: [
        { text: 'On', value: 'on' },
        { text: 'Off', value: 'off' },
      ],
      optimizeOptions: [
        { text: 'Engagement', value: 'cpc' },
        { text: 'CPA ROI', value: 'cpa' },
      ],
      getConversion: async () => {
        const { data } = await api.campaignCreator.getRevcontentConversions(this.account.value.id);
        return data;
      },
      startDateOptions: [
        { text: 'Immediately', value: 'immediately' },
        { text: 'Specific Date', value: 'custom' },
      ],
      endDateOptions: [
        { text: 'Never Ends', value: 'never' },
        { text: 'Specific Date', value: 'custom' },
      ],
      // targetTypeOptions: [
      //   { text: 'Topic', icon: 'fa fa-tags', value: 'topic' },
      // ],
      // targetTargetingOptions: [
      //   { text: 'All Topics', value: 'all' },
      //   { text: 'Include', value: 'include' },
      //   { text: 'Exclude', value: 'exclude' },
      // ],
      // targetCodes: [
      //   { id: '3932692', name: 'Push Notifications' },
      //   { id: '3932916', name: 'Adblock' },
      //   { id: '3934002', name: 'Native' },
      // ],
      trafficTypesOptions: [
        { id: '3932916', name: 'Adblock' },
        { id: '3934002', name: 'Native' },
        { id: '3932692', name: 'Push Notifications' },
      ],
      countryOptions: [
        { text: 'All Countries', value: 'all' },
        { text: 'Include', value: 'include' },
        { text: 'Exclude', value: 'exclude' },
      ],
      countryData,
      regionOptions: [
        { text: 'All Regions', value: 'all' },
        { text: 'Include', value: 'include' },
        { text: 'Exclude', value: 'exclude' },
      ],
      getRegions: async () => {
        if (this.components.country_codes.value.length === 1) {
          const trafficSourceAccountId = this.account.value.id;
          const countryCode = this.components.country_codes.value[0].value;
          const result = await api.campaignCreator.getRevcontentRegions(trafficSourceAccountId, countryCode);
          return result.map((item) => ({ name: item.name, value: item.id }));
        }
      },
      platformOptions: [
        { text: 'Desktop', value: '1' },
        { text: 'Mobile', value: '2' },
        { text: 'Tablet', value: '3' },
      ],
      osOptionsMobile: [
        { text: 'Android', value: '4' },
        { text: 'iOS', value: '5' },
        { text: 'Windows', value: '6' },
      ],
      osOptionsDesktop: [
        { text: 'macOS', value: '7' },
        { text: 'Windows', value: '8' },
      ],
      getBrowsers: async () => {
        const result = await api.campaignCreator.getRevcontentBrowsers(this.account.value.id);
        result.map((item) => BROWSERS.push(item.id));
        return result.map((item) => ({ text: (item.name.charAt(0).toUpperCase() + item.name.slice(1)), value: item.id }));
      },
      zipCodeOptions: [
        { text: 'All Zip Codes', value: 'all' },
        { text: 'Include', value: 'include' },
        { text: 'Exclude', value: 'exclude' },
      ],
      languageOptions: [
        { id: '1', name: 'english' },
        { id: '2', name: 'spanish' },
        { id: '3', name: 'chinese' },
        { id: '4', name: 'german' },
        { id: '5', name: 'french' },
        { id: '6', name: 'hindi' },
        { id: '7', name: 'arabic' },
        { id: '8', name: 'portuguese' },
        { id: '9', name: 'russian' },
        { id: '10', name: 'bengali' },
        { id: '11', name: 'japanese' },
        { id: '12', name: 'dutch' },
        { id: '13', name: 'italian' },
        { id: '14', name: 'swedish' },
        { id: '15', name: 'danish' },
        { id: '16', name: 'malay' },
        { id: '17', name: 'romanian' },
        { id: '18', name: 'indonesian' },
        { id: '19', name: 'slovak' },
        { id: '20', name: 'bulgarian' },
        { id: '21', name: 'hebrew' },
        { id: '22', name: 'tagalog/Filipino' },
        { id: '23', name: 'tamil' },
        { id: '24', name: 'albanian' },
        { id: '25', name: 'armenian' },
        { id: '26', name: 'turkish' },
        { id: '27', name: 'nepali' },
        { id: '28', name: 'polish' },
        { id: '29', name: 'thai' },
        { id: '30', name: 'swiss' },
        { id: '31', name: 'korean' },
        { id: '32', name: 'greek' },
        { id: '33', name: 'norwegian' },
        { id: '34', name: 'finnish' },
        { id: '35', name: 'ukrainian' },
        { id: '36', name: 'czech' },
        { id: '37', name: 'vietnamese' },
        { id: '38', name: 'icelandic' },
        { id: '39', name: 'urdu' },
        { id: '40', name: 'hungarian' },
        { id: '41', name: 'croatian' },
        { id: '42', name: 'telugu' },
      ],
    };
  }

  __generatePopOver() {
    return {
      budgetPopover: {
        title: 'Budget Amount',
        content: 'Actual daily spend may be higher depending on the targeting settings of your boost.',
        html: true,
      },
      pacingPopover: {
        title: 'Pacing',
        content: 'Pacing is available for all campaigns with a limited budget. Enabling pacing forces your budget to spend evenly throughout the day rather than spending as quickly as possible.',
        html: true,
      },
      languagePopover: {
        title: 'Language Targeting',
        content: 'Language Targeting puts your content in front of users whose device & browser settings match the language of your content. If you target Spanish for example, your content will <strong>ONLY</strong> show to users who speak Spanish. Be sure your headlines match the language settings in your Boost.',
        html: true,
      },
      trafficTypesPopover: {
        title: 'Traffic Types',
        content: 'We recommend targeting Native and Push Traffic Types in separate boosts.',
        html: true,
      },
      zipCodesPopover: {
        title: 'Zip Code Targeting ',
        content: 'Zip Code Targeting is only available when targeting the United States. ',
        html: true,
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      name: {
        active: true, component: 'cc-input', value: '', label: 'Boost Name', 'v-validate': 'required',
      },
      bid_type: { active: true, component: 'cc-radio-button', value: 'cpc', label: 'Bid Type', 'v-validate': 'required', options: this.options.bidOptions },
      bid_amount: {
        active: true, component: 'cc-input', value: 0.35, label: 'Bid Amount', 'v-validate': 'required|min_value:0|excluded:0', left: '$', right: 'per click',
      },
      budget_model: {
        active: true, component: 'cc-radio-button', value: 'unlimited', label: 'Budget Amount', 'v-validate': 'required', options: this.options.budgetOptions, popover: this.popover.budgetPopover,
      },
      budget: {
        active: false, component: 'cc-input', value: 100, label: 'Budget', disableLabel: true, 'v-validate': 'required|min_value:100', left: '$', right: 'per day',
      },
      pacing: {
        active: false, component: 'cc-radio-button', value: 'off', label: 'Pacing', 'v-validate': 'required', options: this.options.pacingOptions, popover: this.popover.pacingPopover,
      },
      optimize: {
        active: true, component: 'cc-radio-button', value: 'cpc', label: 'Optimize For', 'v-validate': 'required', options: this.options.optimizeOptions,
      },
      conversion: {
        active: false, component: 'cc-multi-select', value: [], label: 'Conversion', disableLabel: true, 'v-validate': { required: true, length: [0, 5] }, options: [], getOptions: this.options.getConversion, optionLabel: 'name', placeholder: 'Select conversion pixels',
      },
      start_date_model: {
        active: true, component: 'cc-radio-button', value: 'immediately', label: 'Start Date', 'v-validate': 'required', options: this.options.startDateOptions,
      },
      start_date: {
        active: false, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date', time: { right: 'EDT' },
      },
      end_date_model: {
        active: true, component: 'cc-radio-button', value: 'never', label: 'End Date', 'v-validate': 'required', options: this.options.endDateOptions,
      },
      end_date: {
        active: false, component: 'cc-date-picker', value: '', label: 'End Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select End Date', time: { right: 'EDT' },
      },
      traffic_types: {
        active: true, component: 'cc-multi-select', value: [{ id: '3934002', name: 'Native' }], label: 'Traffic Types', 'v-validate': 'required', options: this.options.trafficTypesOptions, optionLabel: 'name', placeholder: 'Select Traffic types', popover: this.popover.trafficTypesPopover,
      },
      // target_type: {
      //   active: true, component: 'cc-radio-button', value: 'topic', label: 'Targeting Type', 'v-validate': 'required', options: this.options.targetTypeOptions,
      // },
      // target_targeting: {
      //   active: true, component: 'cc-radio-button', value: 'all', label: 'Topic Targeting', 'v-validate': 'required', options: this.options.targetTargetingOptions,
      // },
      // target_codes: {
      //   active: false, component: 'cc-multi-select', value: [], label: 'Topic', disableLabel: true, 'v-validate': 'required', options: this.options.targetCodes, optionLabel: 'name', placeholder: 'All Topics',
      // },
      country_targeting: {
        active: true, component: 'cc-radio-button', value: 'all', label: 'Country Targeting', 'v-validate': 'required', options: this.options.countryOptions,
      },
      country_codes: {
        active: false, component: 'cc-multi-select', value: [], label: 'Country', disableLabel: true, 'v-validate': 'required', options: this.options.countryData, optionLabel: 'name', placeholder: 'Select Countries', type: 'countries',
      },
      region_targeting: {
        active: false, component: 'cc-radio-button', value: 'all', label: 'Region Targeting', 'v-validate': 'required', options: this.options.regionOptions,
      },
      region_codes: {
        active: false, component: 'cc-multi-select', value: [], label: 'Region', disableLabel: true, 'v-validate': 'required', options: [], getOptions: this.options.getRegions, optionLabel: 'name', placeholder: 'Select Regions',
      },
      platform_targeting: {
        active: true, component: 'cc-checkbox', value: ['1', '2', '3'], label: 'Device Targeting', 'v-validate': 'required', options: this.options.platformOptions,
      },
      os_targeting_desktop: {
        active: true, component: 'cc-checkbox', value: ['7', '8'], label: 'Operating System ( Desktop )', 'v-validate': 'required', options: this.options.osOptionsDesktop,
      },
      os_targeting_mobile: {
        active: true, component: 'cc-checkbox', value: ['4', '5', '6'], label: 'Operating System ( Mobile & Tablet )', 'v-validate': 'required', options: this.options.osOptionsMobile,
      },
      language_targeting: {
        active: true, component: 'cc-multi-select', value: [], label: 'Language Targeting', options: this.options.languageOptions, optionLabel: 'name', popover: this.popover.languagePopover, placeholder: 'All Languages',
      },
      tracking_code: {
        active: true, component: 'cc-textarea', value: '', label: 'Tracking Code', placeholder: 'Tracking Code', rows: 3,
      },
      browser_targeting: {
        active: true, component: 'cc-checkbox', value: BROWSERS, label: 'Browser Targeting', options: [], getOptions: this.options.getBrowsers,
      },
      zipcode_targeting: {
        active: true, component: 'cc-radio-button', value: 'all', label: 'Zip Code Targeting', 'v-validate': 'required', options: this.options.zipCodeOptions, popover: this.popover.zipCodesPopover,
      },
      zipcode_codes: {
        active: false, component: 'cc-input-tags', value: [], label: '', textBottom: 'US zip codes only',
      },
      // isTemplate: { active: true, component: 'cc-checkbox', label: 'Template', 'v-validate': '', value: [true], options: { value: true, text: 'Save also as template' } },
    };

    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      bid_type: {
        fn: (value) => {
          if (value === 'cpc') {
            _this.components.bid_amount.right = 'per click';
          } else if (value === 'cpv') {
            _this.components.bid_amount.right = 'per 1000 views';
          }
        },
      },
      budget_model: {
        fn: (value) => {
          if (value === 'unlimited') {
            _this.components.budget.active = false;
            _this.components.pacing.active = false;
          } else if (value === 'daily') {
            _this.components.budget.active = true;
            _this.components.pacing.active = true;
          }
        },
      },
      optimize: {
        fn: (value) => {
          if (value === 'cpc') {
            _this.components.conversion.active = false;
          } else if (value === 'cpa') {
            _this.components.conversion.active = true;
          }
        },
      },
      start_date_model: {
        fn: (value) => {
          if (value === 'immediately') {
            _this.components.start_date.active = false;
            _this.components.start_date.value = '';
            _this.components.start_date.disabledDate.to = _this.data.today;
          } else if (value === 'custom') {
            _this.components.start_date.active = true;
          }
        },
      },
      end_date_model: {
        fn: (value) => {
          if (value === 'never') {
            _this.components.end_date.active = false;
            _this.components.end_date.value = '';
            _this.components.end_date.disabledDate.to = _this.data.today;
          } else if (value === 'custom') {
            _this.components.end_date.active = true;
          }
        },
      },
      // // Start Date <=> End Date // Logic
      start_date: {
        fn: (value) => {
          const startDate = value;
          const endDate = _this.components.end_date.value;
          if (startDate) {
            const startDateSpl = value.split(' ');
            _this.components.end_date.disabledDate.to = new Date(startDateSpl[0]);
            if (endDate && moment(startDate) > moment(endDate)) {
              const endDateSpl = endDate.split(' ');
              if (startDateSpl[0] === endDateSpl[0]) {
                _this.components.end_date.value = endDateSpl[0];
              } else {
                _this.components.end_date.value = '';
              }
            }
          } else {
            _this.components.end_date.disabledDate.to = _this.data.today;
          }
        },
      },
      end_date: {
        fn: (value) => {
          const startDate = _this.components.start_date.value;
          const endDate = value;
          if (startDate && endDate) {
            const endDateSpl = endDate.split(' ');
            if (endDateSpl[1] && moment(startDate) > moment(endDate)) {
              const startDateSpl = startDate.split(' ');
              _this.components.start_date.value = startDateSpl[0];
            }
          }
        },
      },
      // target_targeting: {
      //   fn: (value) => {
      //     if (value === 'all') {
      //       _this.resetComponent('target_codes');
      //     } else {
      //       _this.components.target_codes.active = true;
      //     }
      //   },
      // },
      country_targeting: {
        fn: (value) => {
          _this.components.zipcode_targeting.active = true;
          if (value === 'all') {
            _this.resetComponent('country_codes');
          } else if (value === 'exclude') {
            _this.components.zipcode_targeting.active = false;
          } else {
            _this.components.country_codes.active = true;
          }
        },
      },
      country_codes: {
        fn: (value) => {
          _this.components.region_targeting.value = 'all';
          if (value.length === 1) {
            _this.components.region_targeting.active = true;
          } else {
            _this.components.region_targeting.active = false;
          }
        },
      },
      region_targeting: {
        fn: (value) => {
          if (value === 'all') {
            _this.resetComponent('region_codes');
          } else {
            _this.components.region_codes.active = true;
          }
        },
      },
      platform_targeting: {
        fn: (value) => {
          const platform_targeting_value_map = {};
          value.forEach((val) => { platform_targeting_value_map[val] = true; });
          // Check Desktop platforms
          if (platform_targeting_value_map['1']) {
            _this.components.os_targeting_desktop.active = true;
            _this.components.browser_targeting.active = true;
          } else {
            _this.components.os_targeting_desktop.active = false;
            _this.components.browser_targeting.active = false;
          }

          // Check Mobile platforms
          if (platform_targeting_value_map['2'] || platform_targeting_value_map['3']) {
            _this.components.os_targeting_mobile.active = true;
          } else {
            _this.components.os_targeting_mobile.active = false;
          }
        },
      },
      zipcode_targeting: {
        fn: (value) => {
          _this.components.zipcode_codes.active = false;
          if (value !== 'all') {
            _this.components.zipcode_codes.active = true;
          }
        },
      },
    };
  }
}
