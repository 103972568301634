var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cc-preview-card",class:{ 'cc-preview-card--opened': _setup.opened, 'cc-preview-card--selected': _vm.selected },on:{"click":function($event){return _setup.emits('click')}}},[_c('div',{staticClass:"cc-preview-card__container"},[_c('div',{staticClass:"cc-preview-card__container__info"},[_c('div',{staticClass:"cc-preview-card__container__action"},[_c('div',{staticClass:"cc-preview-card__container__action__enable"},[_c(_setup.CcSwitch,{staticClass:"cc-switch--green",attrs:{"disabled":_vm.statusDisabled,"value":_vm.active},on:{"update":_setup.updateStatus}}),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
              customClass: 'cc-popover',
              content: _vm.title,
              boundary: 'viewport',
            }),expression:"{\n              customClass: 'cc-popover',\n              content: title,\n              boundary: 'viewport',\n            }",modifiers:{"hover":true,"top":true}}],staticClass:"limit-rows",attrs:{"id":_vm.title}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),(_setup.uiVariables.body.length)?_c('div',{staticClass:"cc-preview-card__container__action__show-more"},[_c('button',{staticClass:"cc-btn cc-btn--text",on:{"click":_setup.onShowMoreClick}},[_vm._v(" Show "+_vm._s(_setup.opened ? 'Less' : 'More')+" "),_c(_setup.CcIcon,{attrs:{"type":"arrowDown"}})],1)]):_vm._e()]),_c('div',{staticClass:"cc-preview-card__container__value"},_vm._l((_setup.uiVariables.header),function(variable){return _c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
            customClass: 'cc-popover',
            content: `${variable.name}: ${variable.value}`,
            boundary: 'viewport',
          }),expression:"{\n            customClass: 'cc-popover',\n            content: `${variable.name}: ${variable.value}`,\n            boundary: 'viewport',\n          }",modifiers:{"hover":true,"top":true}}],key:variable.field,staticClass:"cc-preview-card__value"},[_c('p',[_vm._v(_vm._s(variable.name)+" :")]),_c('span',[_vm._v(_vm._s(variable.value))])])}),0)]),(_setup.imageURL)?_c('div',{staticClass:"cc-preview-card__container__image"},[_c('img',{attrs:{"src":_setup.imageURL,"alt":"ad-preview"}})]):_vm._e()]),(_setup.uiVariables.body.length && _setup.opened)?_c('div',{staticClass:"cc-preview-card__body"},_vm._l((_setup.uiVariables.body),function(variable){return _c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topleft",value:({
        customClass: 'cc-popover',
        content: `${variable.name}: ${variable.value}`,
        delay: { show: 800 },
        boundary: 'viewport',
      }),expression:"{\n        customClass: 'cc-popover',\n        content: `${variable.name}: ${variable.value}`,\n        delay: { show: 800 },\n        boundary: 'viewport',\n      }",modifiers:{"hover":true,"topleft":true}}],key:variable.field,staticClass:"cc-preview-card__body__item"},[_c('p',[_vm._v(_vm._s(variable.name)+" :")]),_c('span',[_vm._v(_vm._s(variable.value))])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }