import ApiProxy from '@sh/services/apiProxy';
import { merge } from 'lodash';
import sharedEndpoints from './shared';

let endpoints = require(`./${process.env.VUE_APP_PLATFORM}`).default;

endpoints = merge({}, sharedEndpoints, endpoints);

const apiService = new ApiProxy(endpoints);
const api = apiService.getApi();
const apiStore = apiService.getApiStore();
const clearCache = () => apiService.clearCache();

export { api, apiStore, clearCache };
