/* eslint-disable no-unused-vars */
import _ from 'lodash';

export default class CampaignModel {
  constructor(account) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.account = account;
    this.data = { today };
    this.loaded = false;
    this.mode = null;
    this.cloneMode = null;
  }

  onInit({ mode, cloneMode, retry, account }) {
    this.mode = mode;
    this.cloneMode = cloneMode;
    // Async OnInit lifecycle hook
    if (!this.loaded) {
      this.loaded = true;
      this.popover = this.__generatePopOver();
      this.options = this.__generateOptions();
      this.components = this.__generateComponents();
      // Defaults attributes
      Object.entries(this.components).forEach(([name, component]) => {
        component.name = name;
        component.callbacksOnce = {};
        component.once = (eventName, fn) => {
          if (!component.callbacksOnce[eventName]) component.callbacksOnce[eventName] = [];
          component.callbacksOnce[eventName].push(fn);
        };
        component.trigger = (eventName) => {
          if (component.callbacksOnce[eventName]) {
            component.callbacksOnce[eventName].forEach((fn) => fn());
          }
        };
      });
      this._COPY = {
        components: _.cloneDeep(this.components),
      };
      const _this = this;
      this.watchList = {
        model: {
          trigger() {
            const form = {
              traffic_source_account_id: _this.account.value.id,
              traffic_source_unique_name: _this.account.value.type.uniqueName,
            };
            Object.entries(_this.components).forEach(([key, component]) => {
              if (component.active) {
                form[key] = component.value;
              }
            });
            return form;
          },
          fn(form) {
            this.$emit('input', form);
          },
          deep: true,
          immediate: true,
        },
        ...this.__generateWatchList(),
      };
    }
  }

  getWatches(name) {
    return name ? this.watchList[name] : this.watchList;
  }

  getComponents(name) {
    return name ? this.components[name] : this.components;
  }

  getOptions(name) {
    return name ? this.options[name] : this.options;
  }

  resetComponent(name) {
    _.mergeWith(this.components[name], this._COPY.components[name]);
    this.components[name].value = _.cloneDeep(this._COPY.components[name].value);
    this.components[name].options = _.cloneDeep(this._COPY.components[name].options);
  }

  resetComponentOptions(name) {
    _.mergeWith(this.components[name].options, this._COPY.components[name].options);
  }

  async setModel(form, nextTick, mode) {
    // Abstract Method
  }

  getForm() {
    return {};
  }

  // OPTIONS
  __generateOptions() {
    return {};
  }

  __generatePopOver() {
    return {};
  }

  // MODEL
  __generateComponents() {
    return {};
  }

  // LOGIC
  __generateWatchList() {
    return {};
  }

  __helperSetMultiSelectValue({
    component, values, getValueKey, getOptionKey,
  }) {
    const valueMap = {};
    values.forEach((item) => { valueMap[getValueKey(item)] = true; });
    component.options.forEach((option) => {
      if (valueMap[getOptionKey(option)]) component.value.push(option);
    });
  }
}
