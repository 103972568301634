<template>
  <div class="display-url-wrapper">
    <b-form-group
      class="optimizer-form-group"
    >
      <template slot="label">
        Display URL: <span class="text-danger"> *</span><br />
        <small class="character-left">Character left: <b>{{ maxCharacter - input.length }}</b></small>
      </template>
      <b-form-input
        class="optimizer-form-input"
        placeholder="http://example.com"
        id="displayUrlInput"
        :size="size"
        :maxlength="maxCharacter"
        v-model="input"
        name="displayUrlInput"
        data-vv-as="Display URL"
        v-validate="'url:require_protocol'"
        data-vv-delay="0"
        data-vv-validate-on="blur"
      >
      </b-form-input>
      <div slot="description">
        <span v-if="$validator.errors.has('displayUrlInput')" class="text-danger">{{ $validator.errors.first('displayUrlInput') }}</span>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'DisplayUrl',
  mixins: [notifications],
  props: {
    maxCharacter: { type: Number, default: 35 },
    labelCols: { type: Number, default: 3 },
    breakpoint: { type: String, default: 'md' },
    labelClass: { type: String, default: 'text-md-right' },
    size: { type: String, default: 'sm' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      input: this.value,
    };
  },
  watch: {
    async input(value) {
      try {
        const validation = await this.$validator.validateAll();
        if (validation) {
          this.$emit('input', value);
        } else {
          this.$emit('input', '');
        }
      } catch (error) {
        this.$n_failNotification({ title: 'An error occurred' });
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.display-url-wrapper {

}
</style>
