/* eslint-disable import/no-cycle */
import { ABBase } from '@/views/Automation/CampaignCreatorV2/ab/core/ABBase';
import { ABRegistry } from '@/views/Automation/CampaignCreatorV2/ab/core/ABRegistry';
import { EntityForCreation, GroupABEntity } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABEntity';
import * as ABTest from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import { SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { chunk } from 'lodash';
import { GroupABTestJoiner } from './GroupABTestJoiner';

export type EntityFullTree = {
  type: string;
  entityId: string;
  internalEntityId: string;
  children: Array<{
    entityId: string;
    status: boolean;
    variables: ABTest.ABDependency<unknown>[];
    movingVariables: string[];
    children: Array<EntityFullTree>;
  }>;
};

export type EntityTree = {
  type: string;
  entityId: string;
  internalEntityId: string;
  abVariationGenerated: Array<Variation>;
};

export type Variation = {
  children?: Array<EntityTree>;
  entityVariations: Array<EntityForCreation<any>>;
};

// This represent the main group. It's a way to define the relations between entity and serve data.
// This is used also to define ways of combinations between entities. Also manage status of entities that needs calculation on more than one level.
export class GroupABEntityCombiner<
  Value extends ABTest.ABValue<unknown>[],
  E extends SupportedEntities
> extends ABBase<Value> {
  public get possibleOptions(): ABTest.ABDependency<Value>[][] {
    throw new Error('Method not implemented.');
  }

  public get name(): string {
    return this.parent?.name;
  }

  public get description(): string {
    return this.parent.description;
  }

  // Current use case, Identity Group (We have a few groups that are globally )
  // A list of variations will be available (Identity Groups) -
  // For each of those variation we will feed them to Entities one by one (entity level may be present to filter only items they need)
  // The other part is supposed to work as it's.
  // Basically We will be adding a new level: Account, at navigation (it will be identified by campaign id)
  // TODO: Think scope for those groups!
  // 1) If a  group of account's has been selected at campaign level
  //     - If one AdSet fills required fields for only a few of those groups what should happen.
  //     - What if Ad level want to use only a few of those Accounts what should happen.

  public globalScopeGroups!: GroupABTestJoiner<any, any>;

  constructor(
    private _id: string,
    private parent: GroupABEntity<Value, E>,
    private entityType: string,
    private registry: ABRegistry,
    private perChildCoefficient: number
  ) {
    super();
    this._id;
  }

  protected get abTests(): Array<GroupABEntityCombiner<Value, E> | GroupABEntity<Value, E>> {
    const abValues = this.children.map((child) => {
      const group = (this.registry.getAbGroup<Value>(child, this.entityId) ||
        this.registry.getEntityCombiner(child) ||
        this.registry.getEntityGroupWithGroupId(child)) as GroupABEntityCombiner<Value, E> | GroupABEntity<Value, E>;
      if (group.type !== 'ENTITY' && group.type !== 'ENTITY-COMBINER') {
        throw new Error('Group should be of type ENTITY');
      }
      return group;
    });
    return abValues;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get dependencies(): ABTest.ABDependency[] {
    return this.abTests.map((abTest) => abTest.dependencies).reduce((prev, curr) => [...prev, ...curr], []);
  }

  public get previewVariations(): Array<ABTest.AbTestPreview<Value>> {
    throw new Error('You cannot get preview variations for entity combiner');
  }

  private generateVariations(
    parentVariables: ABTest.ABDependency<Value>[] = [],
    movingVariables: string[] = [],
    parentMacros: ABTest.Macro[] = []
  ) {
    const parentVariations = this.parent.buildEntityForCreation(parentVariables, movingVariables, parentMacros);
    const allMacros = [...parentMacros, ...this.parent.macros];
    // Build Main entity first after that create chunks by child.
    const variationPerParent = parentVariations
      .map((parentVariation) => {
        const variables = [...parentVariables, ...parentVariation.variables];
        const childVariations = this.abTests
          .map((abTest) =>
            abTest.buildEntityForCreation(
              variables,
              [...parentVariation.movingVariables, ...movingVariables],
              allMacros
            )
          )
          .flat(1);
        return {
          status: parentVariation.status === false ? false : this.variationStatus(variables),
          type: this.entityType,
          macros: allMacros,
          entityId: this.parent.entityId,
          variables: parentVariation.variables,
          children: childVariations,
          movingVariables: [...parentVariation.movingVariables, ...movingVariables],
          variableUsage: this.variableUsage,
        };
      })
      // TODO: this is not intended to be here. This should be handled by consumer (Not always you want to filter them)
      .filter((s) => s.status);
    return variationPerParent;
  }

  public get variableUsage(): Record<string, number> {
    return this.parent.variableUsage;
  }

  public getEntityTree(
    parentVariables: ABTest.ABDependency<Value>[] = [],
    movingVariables: string[] = [],
    parentMacros: ABTest.Macro[] = []
  ): EntityTree {
    const parentVariations = this.parent.buildEntityForCreation(parentVariables, movingVariables, parentMacros);

    return {
      type: this.entityType,
      internalEntityId: this.parent.internalEntityId,
      entityId: this.parent.entityId,
      abVariationGenerated: parentVariations.map((parentVariation) => {
        const children = this.abTests.map((abTest) => {
          const children = abTest.getEntityTree(
            [...parentVariables, ...parentVariation.variables],
            [...parentVariation.movingVariables],
            [...parentVariation.macros]
          );
          return children;
        });
        return {
          entityVariations: [parentVariation],
          children: children || [],
        };
      }),
    };
  }

  // Building Entity for creation.
  //  Entity: Main responsibility is to return an array of EntityVariation[] that will be created for that entity.
  //  EntityCombiner: Based on parent Variation it will return an array of EntityVariation[] that will be created for that entity. Supporting entity settings.
  //      // For example if our strategy is to create 1 campaign with 1 adset and 1 ad.
  //      // We would have CampaignEntityCombiner(Child 2 x AdSetEntityCombiner) and 2 x AdSetEntityCombiner(Child AdEntity) (Ad doesn't group any sub entity)
  //      // In Such case,
  //              // CampaignEntity(Will return 4 variation)
  //              // 2 x AdSetEntity(Will return 3 variation and 4 variation)
  //              // 2 x AdEntity(Will return 2 variation)
  //      // We would have:
  //              //  2 x AdSetEntityCombiner would return EntityTree ( 2 x AdSetEntity + 3 x AdEntity)
  //
  public buildEntityForCreation(
    parentVariables: ABTest.ABDependency<Value>[] = [],
    movingVariables: string[] = [],
    parentMacros: ABTest.Macro[] = []
  ): EntityForCreation<Value>[] {
    const variationPerParent = this.generateVariations(parentVariables, movingVariables, parentMacros);

    const result: EntityForCreation<Value>[] = [];
    for (const parent of variationPerParent) {
      const chunkedItems = chunk(parent.children, this.perChildCoefficient);
      for (const childGroup of chunkedItems) {
        result.push({
          ...parent,
          children: childGroup,
        });
      }
    }
    return result;
  }

  public get type(): ABTest.ABTestType {
    return 'ENTITY-COMBINER';
  }
}
