import { AvailableFilters, configuration } from '@/views/Tools/Reporting/core/Filters';
import { getFiltersFromQueryParams } from '@/views/Tools/Reporting/core/getFilter';
import { AdvancedReport, changeDateRangeVisibility, validateData } from '@/views/Tools/Reporting/helpers';
import {
  ColumnCondition,
  PaginationInfo,
  ReportingRequest,
  ReportQueryStringDto,
  ReportType,
  TableModel,
} from '@/views/Tools/Reporting/types';
import { FilterOptions, Filters, FilterType } from '@/views/Tools/Reporting/types/Filter';
import { TrafficSource, VueOptiTable } from '@sh/types';
import Joi from 'joi';
import { identity, pickBy } from 'lodash';
import moment from 'moment';
import pako from 'pako';

interface Table {
  columns: VueOptiTable.Field[];
  items: unknown[];
  conditions: ColumnCondition[];
  tableModel: TableModel;
  totals?: Record<string, unknown>;
  pagination: PaginationInfo;
  sort: string;
  search?: string;
}

export class ReportBuilder {
  table: Table = {
    columns: [],
    items: [],
    tableModel: {
      selectedRows: [],
      displayColumns: [],
      columnsOrder: [],
    },

    pagination: {
      currentPage: 1,
      pageSize: 100,
      total: 1,
      lastPage: 1,
    },
    sort: '-cost',
    conditions: [],
    search: undefined,
  };

  generatedTime: Date | null;
  isLoadingTable = false;
  isFormTouched = false;
  noDataMessage = '';
  tableMessage = '';
  errorMessage = '';

  private constructor(public tab: ReportType | string, public filters: Partial<Filters>) {
    this.generatedTime = null;
  }

  static createInstance(tab: ReportType | string, filters: Partial<Filters> = {}): ReportBuilder {
    return new ReportBuilder(tab, filters);
  }

  static validateReport(data: ReportBuilder): boolean {
    const schema = Joi.object({
      tab: Joi.string().required(),
      table: Joi.object({
        columns: Joi.array().required().items(Joi.object()).min(1),
        items: Joi.array().required().items(Joi.object()).min(1),
      }).required(),
    });

    const { error } = schema.validate(data, { stripUnknown: true, abortEarly: false });
    return !error;
  }

  static getGlobalFiltersValues(data: Filters, globalFilters: FilterType[]) {
    return globalFilters.reduce((filters: Partial<Filters>, filterType) => {
      if (data[filterType]) {
        // @ts-ignore
        filters[filterType] = data[filterType];
      }
      return filters;
    }, {});
  }

  static getAvailableFilters(trafficSource: TrafficSource | 'ALL', reportType: ReportType | string): AvailableFilters {
    const existingConfiguration = configuration[trafficSource]?.[reportType] ?? configuration.ALL?.[reportType] ?? {};

    return {
      activeFilters: [...Object.values(FilterType)],
      globalFilters: [FilterType.DateRange, FilterType.BreakDown],
      ...existingConfiguration,
    };
  }

  static setFilterOptions(report: ReportBuilder, options: FilterOptions) {
    Object.keys(report.filters).forEach((key) => {
      if (report.filters[key as FilterType]) {
        // @ts-ignore
        report.filters[key as FilterType].availableOptions = options[key as FilterType];
      }
    });
  }

  static isTableShown(report: ReportBuilder) {
    return !!(report.table.items.length || report.table.conditions.length || report.table.search);
  }

  static setQueryStringHash(report: ReportBuilder, queryStringHash: string, options: FilterOptions) {
    const hash = decodeURIComponent(queryStringHash);

    if (hash && hash !== report.queryStringHash) {
      const binaryString = atob(hash);
      const compressedUint8Array = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        compressedUint8Array[i] = binaryString.charCodeAt(i);
      }

      const decompressedUint8Array = pako.inflate(compressedUint8Array);
      const input = new TextDecoder().decode(decompressedUint8Array);
      const data: ReportQueryStringDto = JSON.parse(input);
      const availableFilters = ReportBuilder.getAvailableFilters(data.payload.typeName ?? 'ALL', data.tab);

      report.tab = data.tab;
      report.filters = getFiltersFromQueryParams(data.payload, options, availableFilters.activeFilters);
      report.patchTable({
        columns: data.payload.reportFields,
        tableModel: {
          selectedRows: [],
          displayColumns: [],
          columnsOrder: [],
        },
        pagination: {
          ...report.table.pagination,
          currentPage: data.payload.pagination.page!,
          pageSize: data.payload.pagination.pageSize!,
        },
        sort: data.payload.sort,
        conditions: data.payload.conditions ?? report.table.conditions,
        search: data.payload.search,
      });
    }
  }

  get isValid(): boolean {
    return ReportBuilder.validateReport(this);
  }

  get activeReportType(): ReportType {
    const reportType = this.tab === AdvancedReport ? undefined : this.tab;
    return (this.filters[FilterType.ReportType]?.value ?? reportType ?? '') as ReportType;
  }

  get reportFields(): VueOptiTable.Field[] {
    const isDateRangeField = !!this.filters[FilterType.BreakDown]?.value;
    return changeDateRangeVisibility(this.table.columns, isDateRangeField);
  }

  get trafficSource(): TrafficSource | 'ALL' {
    return this.filters[FilterType.TrafficSource]?.value ?? 'ALL';
  }

  get isSearchFeedReport(): boolean {
    return [ReportType.ClientCompliance, ReportType.AccountCompliance, ReportType.ContentCompliance].includes(
      this.activeReportType
    );
  }

  get payload(): ReportingRequest {
    if (this.isSearchFeedReport) {
      return pickBy(
        {
          startDate: this.filters[FilterType.DateRange]?.value?.startDate ?? '',
          endDate: this.filters[FilterType.DateRange]?.value?.endDate ?? '',
          reportType: this.activeReportType,
          pagination: {
            page: this.table.pagination?.currentPage,
            pageSize: this.table.pagination?.pageSize,
          },
          reportFields: this.reportFields,
          conditions: this.table.conditions?.length ? this.table.conditions : undefined,
          search: this.table.search,
          sort: this.table.sort,
          selectedUserId: this.filters[FilterType.User]?.userIds?.join(','),
          trafficSourceTypes: this.filters[FilterType.TrafficSourceComplianceFilter]?.value,
          clientEmails:
            this.activeReportType !== ReportType.ClientCompliance ? [this.filters[FilterType.User]?.value] : undefined,
        },
        identity
      ) as unknown as ReportingRequest;
    }

    return pickBy(
      {
        startDate: this.filters[FilterType.DateRange]?.value?.startDate ?? '',
        endDate: this.filters[FilterType.DateRange]?.value?.endDate ?? '',
        typeName: this.filters[FilterType.TrafficSource]?.value ?? null,
        reportType: this.activeReportType,
        dateType: this.filters[FilterType.BreakDown]?.value ?? null,
        campaignIds: this.filters[FilterType.Campaign]?.value,
        trafficSourceAccountIds: this.filters[FilterType.AdAccount]?.value?.length
          ? this.filters[FilterType.AdAccount]?.value
          : undefined,
        pagination: {
          page: this.table.pagination?.currentPage,
          pageSize: this.table.pagination?.pageSize,
        },
        reportFields: this.reportFields,
        conditions: this.table.conditions?.length ? this.table.conditions : undefined,
        search: this.table.search,
        sort: this.table.sort,
      },
      identity
    ) as unknown as ReportingRequest;
  }

  get validationData(): Joi.ValidationResult {
    return validateData(this.payload, this.isSearchFeedReport);
  }

  get validationMessage(): string | undefined {
    const { error } = this.validationData;
    return error?.message;
  }

  get generatedTimeText(): string {
    if (this.generatedTime) {
      return moment(this.generatedTime).fromNow();
    }
    return '';
  }

  get queryStringHash(): string {
    const data: ReportQueryStringDto = {
      payload: this.payload,
      tab: this.tab,
    };
    const textToCompress = JSON.stringify(data);
    const compressedUint8Array = pako.deflate(textToCompress);
    const compressedBase64 = btoa(String.fromCharCode.apply(null, Array.from(compressedUint8Array)));

    return encodeURIComponent(compressedBase64);
  }

  patchTable(table: Partial<Table>) {
    this.table = {
      ...this.table,
      ...table,
    };
  }

  resetTable() {
    this.patchTable({
      items: [],
      sort: '-cost',
      conditions: [],
      search: undefined,
      pagination: {
        currentPage: 1,
        total: 1,
        lastPage: 1,
        pageSize: this.table.pagination.pageSize,
      },
    });
  }

  getTableMetadata(reportTypeName: string) {
    const { startDate, endDate } = this.filters[FilterType.DateRange]?.value ?? { startDate: '', endDate: '' };
    const name = `${this.trafficSource}${reportTypeName}ReportTable`;
    const exportLabel = `${this.trafficSource}_${reportTypeName}_performance_report_${startDate}_${endDate}`;

    return {
      name,
      exportLabel,
    };
  }
}
