import { debounce } from 'lodash';
import { createPinia } from 'pinia';
import Vue from 'vue';
import App from './App.vue';
import './loaders/components';
import './loaders/injections';
import './loaders/plugins';
import './loaders/prefetch';
import './services/accessControl';
import router from './services/router';

if (process.env.NODE_ENV === 'production') Vue.config.productionTip = false;
if (process.env.VUE_APP_ENV === 'prod') Vue.config.devtools = false;

const pinia = createPinia();
pinia.use(({ options, store }) => {
  if (options.debounce) {
    return Object.keys(options.debounce).reduce((debouncedActions, action) => {
      debouncedActions[action] = debounce(store[action], options.debounce[action]);
      return debouncedActions;
    }, {});
  }
});

const root = new Vue({
  render: (h) => h(App),
  router,
  pinia,
}).$mount('#app');

const originalSwalFunction = root.$swal;
Vue.prototype.$swal = function (...args) {
  root.$emit('bv::hide::tooltip');
  return originalSwalFunction(...args);
};
