
import ruleConfig from '@/views/Automation/Rules/config';
import { RuleHelpers } from '@sh/helpers';
import { RuleTypes, TrafficSource } from '@sh/types';
import RuleBidOptions from '@sh/views/Rules/components/subcomponents/OldRuleBidOptions.vue';
import RuleMinMax from '@sh/views/Rules/components/subcomponents/OldRuleMinMax.vue';
import component from '@sh/views/Rules/mixins/bulkComponent';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentRefs {
  $refs: {
    ruleBidOptions: {
      resetValue: () => void;
    };
  };
}

const VueComponent = Vue as VueConstructor<
  Vue & RuleTypes.Bulk.RuleMetricCalculationRef & RuleValidatorMixin & ComponentRefs
>;

export default VueComponent.extend({
  name: 'RuleMetricCalculation',
  components: {
    RuleBidOptions,
    RuleMinMax,
  },
  mixins: [component],
  props: {
    items: { type: Array as PropType<RuleTypes.Bulk.Item[]>, required: false },
    handler: { type: Function, required: false },
    options: { type: Array, default: () => [] },
    level: { type: String as PropType<RuleTypes.Bulk.Levels>, required: false },
    bulkConfig: { type: Object as PropType<RuleTypes.Bulk.BulkConfig>, required: false },
    bulkAction: { type: String, required: false },
    mode: { type: String as PropType<RuleTypes.Bulk.Mode>, default: 'bulk' },
    view: { type: String, default: 'column' },
    showError: { type: Boolean, default: true },
    enableMinMaxOption: { type: Boolean, default: true },
    enableMinMaxOptionForManualClone: { type: Boolean, default: false },
    showSpreadBudget: { type: Boolean, default: false },
    trafficSourceName: { type: String, default: '' },
    conditionalEntity: { type: String, default: null },
  },
  data(): RuleTypes.Bulk.ComponentData {
    return {
      showMinMax: true,
      config: null,
      ruleModel: null,
      type: 'bid',
      form: {
        action: 'set',
        unit: 'static',
        to: '',
        value: '',
        min: '',
        max: '',
        minObject: { unit: 'static', to: '', value: '' },
        maxObject: { unit: 'static', to: '', value: '' },
      },
      enableMinMax: false,
      alert: {
        type: 'danger',
        message: '',
      },
    };
  },
  computed: {
    $c_switch_title(): string {
      return `Enable this option if you want to set a minimum and maximum ${this.$c_getColumnName} cap, to make sure that the ${this.$c_getColumnName} adjustment will not go above or below the cap.`;
    },
    $c_currentAction(): string {
      return this.$refs.ruleBidOptions?.form.action;
    },
    $c_currentUnit(): string {
      return this.$refs.ruleBidOptions?.form.unit;
    },
    $c_currentTo(): string {
      return this.$refs.ruleBidOptions?.form.to;
    },
    $c_action(): 'bulkActions' | 'cloneActions' {
      return this.mode === 'bulk' ? 'bulkActions' : 'cloneActions';
    },
    $c_name(): string {
      if (this.type === 'bid') {
        return 'Bid';
      }
      return 'Budget';
    },
    $c_ruleType(): 'campaign_change_budget' | 'campaign_change_bid' {
      return `campaign_change_${this.type as 'bid'}`;
    },
    $c_render(): boolean {
      if (this.ruleModel?.rule.components[this.type]?.value) {
        return true;
      }
      return false;
    },
    $c_minInfo(): string {
      return this.bulkConfig.entities[this.level][this.$c_action as 'bulkActions']?.[this.bulkAction]?.minInfo;
    },
    $c_maxInfo(): string {
      return this.bulkConfig.entities[this.level][this.$c_action as 'bulkActions']?.[this.bulkAction]?.maxInfo;
    },
    $c_template(): RuleTypes.Bulk.RuleTemplate {
      if (this.type === 'bid') {
        return this.config.bidConfig.bidModelTemplates[this.ruleModel?.rule.components.bid.template];
      }
      return this.config.budgetConfig.budgetModelTemplates[this.ruleModel?.rule.components.budget.template];
    },
    $c_getEntity(): string {
      return this.trafficSourceName === TrafficSource.Facebook ? 'AdSet' : 'AdGroup';
    },
    $c_fieldOptions(): RuleTypes.Bulk.Options {
      if (this.bulkConfig?.entities[this.level]?.[this.$c_action as 'bulkActions']?.[this.bulkAction].columnName) {
        const columnName = this.$c_getColumnName;
        const adSetOptions = [
          { value: 'set', text: `Set Campaign ${columnName} To` },
          { value: 'increase', text: `Increase Campaign ${columnName} By` },
          { value: 'decrease', text: `Decrease Campaign ${columnName} By` },
          {
            value: 'spread',
            text: `Spread ${columnName} Equally between all ${this.$c_entityName}s`,
            tooltip: `Example: If your campaign has 4 ${this.$c_entityName}s and you set the budget to $100, the budget of each ${this.$c_entityName} will be set to $25.`,
          },
        ];
        const campaingOptions = [
          { value: 'set', text: `Set ${this.$c_entityName} ${columnName} To` },
          { value: 'increase', text: `Increase ${this.$c_entityName} ${columnName} By` },
          { value: 'decrease', text: `Decrease ${this.$c_entityName} ${columnName} By` },
        ];
        return this.showSpreadBudget ? adSetOptions : campaingOptions;
      }
      return [];
    },
    $c_entityName(): string {
      return (
        this.bulkConfig.entities[this.level]?.entityName ||
        this.conditionalEntity ||
        `${this.level.charAt(0).toUpperCase()}${this.level.slice(1)}`
      );
    },
    $c_getField(): string {
      return this.bulkConfig.entities[this.level]?.[this.$c_action as 'bulkActions'][this.bulkAction]?.field;
    },
    $c_hasCondtionalField(): boolean {
      return (
        this.bulkConfig.entities[this.level]?.[this.$c_action as 'bulkActions'][this.bulkAction]?.hasConditionalField ||
        false
      );
    },
    $c_getColumnName(): string {
      return this.bulkConfig.entities[this.level]?.[this.$c_action as 'bulkActions'][this.bulkAction]?.columnName;
    },
    $c_filterBy(): 'bid' | 'budget' | 'daily_budget' | 'target_cpa' | 'bid_cap' {
      return this.bulkConfig.entities[this.level]?.bulkActions[this.bulkAction]?.filterBy;
    },
    $c_showMinMax(): boolean {
      return !(['set', 'spread'].includes(this.form.action) && this.form.unit === 'static');
    },
    $c_limits(): RuleTypes.Bulk.Limits {
      if (this.type === 'bid') {
        return this.ruleModel?.rule.components.bid.limits;
      }
      return this.ruleModel?.rule.components.budget.limits;
    },
    $c_toOptions(): RuleTypes.Bulk.Options {
      if (this.type === 'bid') {
        return this.config.bidConfig.bidToOptions;
      }
      return this.config.budgetConfig.budgetToOptions;
    },
    $c_unitOptions(): RuleTypes.Bulk.Options {
      if (this.type === 'bid') {
        return this.config.bidConfig.bidUnitOptions;
      }
      return this.config.budgetConfig.budgetUnitOptions;
    },
    $c_component(): RuleTypes.Bulk.RuleComponent {
      if (this.type === 'bid') {
        return this.ruleModel?.rule.components.bid;
      }
      return this.ruleModel?.rule.components.budget;
    },
    $c_minMaxOptions(): RuleTypes.Bulk.Options {
      if (this.type === 'bid') {
        return this.config.bidConfig.bidMinMaxOptions;
      }
      return [];
    },
    $c_minMaxToOptions(): RuleTypes.Bulk.Options {
      if (this.type === 'bid') {
        return this.config.bidConfig.bidToOptions;
      }
      return [];
    },
    $c_form(): RuleTypes.Bulk.Form {
      return this.form;
    },
    $c_skipMin(): boolean {
      return this.bulkConfig.entities[this.level]?.bulkActions[this.bulkAction]?.skipMin || false;
    },
    $c_showMinMaxComponent(): boolean {
      if (this.mode === 'clone') {
        return !['set', 'spread'].includes(this.form.action);
      }
      return !['set', 'spread'].includes(this.form.action);
    },
    $c_minMaxOrBudgetEnabled(): boolean {
      if (this.mode === 'clone') {
        return this.enableMinMax;
      }
      return this.enableMinMax;
    },
    $c_enableRuleBidOptions(): boolean {
      if (this.mode === 'clone' || (this.mode === 'bulk' && ['budget', 'daily_budget'].includes(this.type))) {
        return false;
      }
      return true;
    },
  },
  watch: {
    bulkAction: {
      async handler(action: string) {
        if (action) {
          this.type = this.bulkConfig.entities[this.level][this.$c_action as 'bulkActions'][action]?.type;
          await this.$_init();
        }
      },
      immediate: true,
    },
    form: {
      handler() {
        this.$emit('calculateMetric');
        // eslint-disable-next-line no-prototype-builtins
        if (!this.$c_showMinMax && this.form.hasOwnProperty('minObject')) {
          this.form.minObject.value = this.form.value;
          this.form.maxObject.value = this.form.value;
          this.form.min = this.form.value;
          this.form.max = this.form.value;
          this.$_emit('form');
        }
      },
      deep: true,
    },
    $c_showMinMax() {
      this.form.minObject = { unit: 'static', to: '', value: '' };
      this.form.maxObject = { unit: 'static', to: '', value: '' };
      this.form.min = '';
      this.form.max = '';
    },
  },
  created() {
    this.$_init();
  },
  methods: {
    async $_init() {
      try {
        const type = this.$c_ruleType;
        this.config = await ruleConfig.getConfig();
        this.ruleModel = new this.config.RuleModel(type, false);
      } catch (error) {
        console.log(error);
      }
    },
    $_handleMin(value: string) {
      this.form.min = value;
    },
    $_handleMax(value: string) {
      this.form.max = value;
    },
    $_handleMaxObject(objectValue: RuleTypes.Bulk.MaxObject) {
      if (this.form.maxObject) {
        this.form = { ...this.form, maxObject: objectValue };
      }
    },
    $_removeMinMaxObjectFromForm() {
      const form: Partial<RuleTypes.Bulk.Form> = {
        ...this.form,
      };
      delete form.minObject;
      delete form.maxObject;
      this.form = form as RuleTypes.Bulk.Form;
    },
    $_handleMinObject(objectValue: RuleTypes.Bulk.MinObject) {
      if (this.form.minObject) {
        this.form = { ...this.form, minObject: objectValue };
      }
    },
    $_isValid(): { valid: boolean; where?: string } {
      const action = this.$c_currentAction;
      if (this.form.value === '') {
        return { valid: false, where: `${this.$c_getColumnName} field` };
      }
      if (action === 'increase' || action === 'decrease') {
        if (
          ((this.form.minObject.value === '' || this.form.maxObject.value === '') && this.enableMinMax) ||
          ((this.form.minObject.value === '' || this.form.maxObject.value === '') &&
            this.enableMinMaxOptionForManualClone)
        ) {
          return { valid: false, where: 'Min/Max fields' };
        }
      }
      return { valid: true };
    },
    $_getCalculatedMetric(
      item: RuleTypes.Bulk.Item,
      hardCodedTo?: string
    ): {
      calculatedMetric: number | null;
      current: string | number | null;
      calculatedMinVal: string | number | undefined;
      calculatedMaxVal: string | number | undefined;
      isNullCurrentField: boolean;
      columnName: string;
    } {
      const { action, unit, value, min, max, minObject, maxObject } = this.form;
      let field = this.$c_getField;
      let isNullCurrentField = false;
      let { to } = this.form;
      // if the type of the field we are updating is either budget or daily_budget, "to" is going to always be hardcoded since in this case budget & daily_budget can be increased/decreased only by themselves
      if (['daily_budget', 'budget'].includes(this.type)) {
        to = item.additional_fields.budget ? 'budget' : 'daily_budget';
      }
      if (hardCodedTo) {
        to = hardCodedTo;
      }
      // in some cases the field we are trying to update is getting the value from a different key than the one configured in admin, we need to check through a condition in order to get the "real" field
      if (this.$c_hasCondtionalField) {
        const conditionalField =
          this.bulkConfig.entities[this.level]?.[this.$c_action as 'bulkActions'][this.bulkAction].conditionalField?.(
            item
          );
        if (conditionalField) {
          field = conditionalField;
          to = conditionalField;
        }
      }
      // some fields that we are trying to update don't really have a numeric value, instead that can have a string with a specific message depending on the type of budget/bid, in this case we don't edit them at all. They get ignored from the action and an inline error is shown in the table
      const isNotEditable =
        this.bulkConfig.entities[this.level]?.[this.$c_action as 'bulkActions'][this.bulkAction].isNotAnEditableField?.(
          item
        );
      if (isNotEditable) {
        isNullCurrentField = true;
      }
      let current = item[field as 'bid'] || item.additional_fields[field as 'bid'];
      if (current === null) {
        current = 0;
      }
      const metricToChange = RuleHelpers.MetricCalculation.toChange(action, Number(value), current, unit, to, item);
      let calculatedMaxVal;
      let calculatedMinVal;
      if (this.enableMinMax) {
        const { calculatedMax, calculatedMin } = RuleHelpers.MetricCalculation.calculateMinMax(
          min,
          max,
          minObject,
          maxObject,
          item
        );
        calculatedMaxVal = calculatedMax;
        calculatedMinVal = calculatedMin;
      }

      const { calculatedMetric } = RuleHelpers.MetricCalculation.calculateMetric(
        Number(current),
        Number(metricToChange),
        Number(calculatedMinVal),
        Number(calculatedMaxVal)
      );
      const columnName = this.$c_getColumnName;
      return {
        calculatedMetric: Number(calculatedMetric),
        current,
        calculatedMinVal,
        calculatedMaxVal,
        isNullCurrentField,
        columnName,
      };
    },
    $_showInlineError(
      variant: 'primary' | 'secondary' | 'warning' | 'success' | 'info' | 'light' | 'dark' | 'danger',
      message: string
    ) {
      this.$_showAlert(variant, message);
    },
    async $_maxLimitExhausted() {
      const swal = await this.$swal({
        title: `${this.$c_name} seems unusually high!`,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Keep Anyway',
        cancelButtonText: 'Reset',
      });
      if (swal.value !== true) {
        this.$refs.ruleBidOptions?.resetValue();
      }
    },
    $_getComponentRefName(name: string): string {
      return `ruleRef${name}`;
    },
    $_getRef(name: string): any {
      return this.$refs[this.$_getComponentRefName(name)];
    },
    $_clear() {
      const type = this.$c_ruleType;
      this.ruleModel = new this.config.RuleModel(type, false);
      this.form = {
        action: 'set',
        unit: 'static',
        to: '',
        value: '',
        min: '',
        max: '',
        minObject: { unit: 'static', to: '', value: '' },
        maxObject: { unit: 'static', to: '', value: '' },
      };
    },
    $_switchToggle(value: boolean) {
      this.enableMinMax = value;
    },
    $_showAlert(type: string, message: string) {
      this.alert.type = type;
      this.alert.message = message;
    },
    $_dismissAlert() {
      this.alert.message = '';
    },
  },
});
