<template>
  <div>
    <div class="animated fadeIn">
      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
      />
      <template v-else>
        <div class="optimizer-table domains-table">
          <vue-opti-table-light
            v-if="table.fields.length > 0"
            @on-pagination="$_setPaginationValues($event,$_getDomainsPaginated)"
            @on-search="$_setSearchValue($event)"
            @on-row-per-page-change="$_setPaginationValues($event,$_getDomainsPaginated)"
            @on-sort="$_setPaginationValues($event,$_getDomainsPaginated)"
            @on-column-filter="$_setPaginationValues($event,$_getDomainsPaginated)"
            :name="$c_tableName"
            :server-side-pagination="true"
            :pages="pages"
            :page="page"
            class="domains-table"
            :hover="true"
            :export-label="$c_exportLabel"
            :selectable="true"
            select-label="Domains"
            :header-fields="$c_tableHeaders"
            :items="table.items"
            v-model="tableModel"
            :resized-columns="resizedColumns"
            @resize="$_handleColumnsResize($event)"
            :sort="{ key: 'cost', order: 'desc' }"
            :default-rows="defaultRows"
            :totals="table.totals"
            :save-settings="$_saveSettings"
            :export-csv-items="$_exportCsvItems"
            :column-filter-enable="true"
            :column-filter.sync="columnFilter"
            :column-filter-reset="false"
            :update-custom-metric="$_updateCustomMetric"
            :custom-metric-options="$options.customMetricOptions"
            :nativeFields="$c_nativeFields"
            :hasGroups="true"
            :hasComparisonColumns="true"
            :updateComparisonColumns="updateComparisonColumns"
            :hasPresets="true"
            :presetList="presets"
            :selectedPreset="selectedPreset"
            :deletePreset="$_deletePreset"
            :editPreset="$_editPresetName"
            :savePreset="$_createPreset"
            :changePreset="$_changePreset"
            :showSubUserSettings="hasSubuserFeatureAccess"
            :switchPresetAccess="switchPresetAccess"
            infoType="popover"
            :showHeaderPopover="true"
            sticky
            focusSelectedRows
          >
            <template slot="search">
              <div class="d-flex flex-grow-1">
                <vue-opti-select-light
                  :class="[ tableModel.selectedRows.length ? 'active-select' : '', 'optimizer-select icon-select dropdown-auto-width pr-2 col-md-auto']"
                  :options="bulkActionOptions"
                  :unique-key="({ value: { value } }) => value"
                  label-key="content"
                  button-type="static"
                  @change="({ value }) => { $_bulkAction(value) }"
                  single
                >
                  <template #BUTTON_PLACEHOLDER>
                    <span class="button-placehoder-static">
                      <i class="fa fa-ellipsis-h"></i>
                      <span>Actions</span>
                    </span>
                  </template>
                </vue-opti-select-light>
                <div class="col-md-auto mb-2 mb-md-0 pl-0">
                  <b-btn
                    class="secondary-button h-100"
                    block
                    v-clipboard="$_copyToClipboard('traffic_source_domain_id')"
                    @success="$_clipboardSuccessHandler($_toolTipMessage('domains'))"
                    @error="$_clipboardErrorHandler"
                  >
                    <i class="fa fa-clipboard" /> Copy <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.top :title="$_toolTipMessage(`Copy selected domains id's to clipboard.`)" />
                  </b-btn>
                </div>
                <div v-show="userAction.loading" class="col-md-auto" style="line-height: 2.5em">
                  <i class="fa fa-spinner fa-spin" /> Processing...
                </div>
                <loadizer :loading="ready.pagination" />
              </div>
              <div class="refresh-button" v-b-tooltip title="Refresh Table">
                <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
                  <i class="fa fa-refresh" :class="{'fa-spin': isRefreshLoading}" />
                </b-btn>
              </div>
            </template>
            <template slot="status" slot-scope="props">
              <span
                :class="`status-item status-item-${props.item.status}`"
                v-b-tooltip.hover.right
                :title="props.item.status"
                v-html="ui.content.status(props.item.status)"
              />
            </template>
            <template slot="placement" slot-scope="props">
              <a :href="$_getTargetUrl(props.item.targetUrl)" target="_blank">{{ props.item.targetUrl }}</a>
            </template>
            <template #actions="{ item }">
              <div class="d-flex justify-content-center">
                <switch-button
                  :loading="item.statusProcessing"
                  :checked="item.status === 'RUNNING'"
                  :hover-title="{ enabled: 'Deactivate', disabled: 'Activate' }"
                  :update="(value) => $_updateStatus(item, value)"
                />
                <action-btn type="breakdown" title="Performance Breakdown" :click="() => $_showPerformanceModal(item)" />
              </div>
            </template>
          </vue-opti-table-light>
        </div>
      </template>
    </div>
    <performance-modal
      ref="performanceModal"
      :type="type"
      :trafficSource="account"
      :itemId="currentDomainPerformance.traffic_source_domain_id"
      :id="currentDomainPerformance.id || ''"
      :name="currentDomainPerformance.name || currentDomainPerformance.traffic_source_domain_id || ''"
      :timezone="timezone"
      :dateRange="dateRange"
    />
  </div>
</template>

<script>
import tableFields from '@/helpers/fields/index';
import helperMixins from './mixins/helperMixins';
import tableMixins from '@sh/mixins/table';
import PerformanceModal from '@sh/components/Performance/PerformanceModal.vue';
import columnsMixin from '@sh/mixins/columnsConfig.js';
import presetHelper from '@/views/Campaign/Tabs/mixins/presetHelper';
import { usePresetStore } from '@/stores/presets';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'Domains',
  components: { PerformanceModal },
  mixins: [helperMixins, tableMixins, columnsMixin, presetHelper],
  props: {
    type: { type: Object },
  },
  data() {
    return {
      bulkActionOptions: this.campaign.traffic_source_unique_name !== 'GoogleAds' ? [
        { value: { value: 'pause', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Deactivate' },
        { value: { value: 'start', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Activate' },
      ] : [
        { value: { value: 'pause', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-pause\'></i>&nbsp; Exclude Placement' },
        { value: { value: 'start', fn: this.$_bulkUpdateStatus }, content: '<i class=\'fa fa-play\'></i>&nbsp; Allow Placement' },
      ],
      currentDomainPerformance: {},
      isRefreshLoading: false,
    };
  },
  computed: {
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
    $c_tableName() {
      return `${this.account.type.name}DomainsTable`;
    },
    $c_exportLabel() {
      return `${this.account.type.name}_domains_performance_${this.dateRange.startDate}_${this.dateRange.endDate}`;
    },
    $c_nativeFields() {
      return this.$_filterNativeFields(this.account.type.uniqueName, this.table.fields, this.$options.fieldsGroup);
    },
    ...mapState(usePresetStore, [
      'presets',
      'selectedPreset',
      'hasSubuserFeatureAccess',
    ]),
  },
  methods: {
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getCurrentPreset',
      'getFilteredPresets',
      'switchPresetAccess',
      'setLinkedTrackers',
      'setFilter',
    ]),
    async $_init() {
      const trackerTypesLinkedToTrafficSource = await this.getTrackerTypesLinkedToTrafficSource(this.account.type.uniqueName);
      this.setLinkedTrackers(trackerTypesLinkedToTrafficSource);
      this.setFilter(this.account);
      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('domains', this.campaign.traffic_source_unique_name);
      /** ************************************************************************* */

      try {
        await this.getFilteredPresets();
        await this.$_getDomainsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
      }
      this.preloader = false;
      this.debouncedGetActivityLogs = this.debounce(this.$_getDomainsPaginated, 1000);
    },
    $_showPerformanceModal(item) {
      this.currentDomainPerformance = item;
      this.$refs.performanceModal.showModal();
    },
    $_toolTipMessage(message) {
      return (this.account.type.uniqueName === 'Facebook') ? message.replace('domain', 'device') : message;
    },
    async $_getDomainsPaginated() {
      this.ready.pagination = true;
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: this.page,
        pageSize: this.limit,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        columnFilter: this.columnFilter,
        comparisonStartDate: this.$route.query.comparisonStartDate,
        comparisonEndDate: this.$route.query.comparisonEndDate,
        typeName: this.account.type.uniqueName,
        preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName),
      };
      if (this.search !== '') {
        filter.search = this.search;
      }
      let domains = [];
      try {
        domains = await this.$api.domains.performance(this.campaign.id, filter);
        domains.items = domains.items.map((item) => {
          item.statusProcessing = false;
          item.traffic_source_currency = this.campaign.traffic_source_currency;
          return item;
        });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.ready.pagination = false;
      }
      if (domains.pagination.lastPage) {
        this.pages = domains.pagination.lastPage;
        this.defaultRows = domains.pagination.pageSize;
      }
      if (!domains.items.length) {
        this.page = 1;
      }
      if (Object.keys(domains).length > 0) {
        this.table.fields = [];
        this.table.fields.push(tableFields.getField('DOMAINS', { name: 'actions' }));

        domains.fields.forEach((item) => {
          this.table.fields.push(tableFields.getField('DOMAINS', {
            name: item.item.key,
            uniqueName: this.campaign.traffic_source_unique_name,
          }, item));
        });
        domains.totals.totalElements = domains.pagination.total;
        this.table.totals = domains.totals;
        this.table.items = domains.items;
      }
      this.preloader = false;
      this.ready.pagination = false;
      return domains;
    },
    async $_bulkUpdateStatus(value) {
      const localValue = value === 'start' ? 'RUNNING' : 'PAUSED';
      const payload = [];
      const payloadIdsMap = {};
      this.tableModel.selectedRows.forEach((row) => {
        if (row.status !== localValue) {
          payloadIdsMap[row.traffic_source_domain_id] = true;
          const payloadItem = { traffic_source_domain_id: row.traffic_source_domain_id };
          payload.push(payloadItem);
          row.statusProcessing = true;
        }
      });
      if (payload.length > 0) {
        const task = this.userAction.task.create();
        try {
          const result = await this.$_userActionHandler({
            request: this.$api.domains.changeStatus(this.campaign.id, value, payload),
            ts: this.campaign.traffic_source_unique_name,
            entityToReplace: 'Domains',
            newEntityName: 'Devices',
          });
          if (result.success.length > 0) {
            this.table.items.forEach((row) => {
              if (result.success.indexOf(row.traffic_source_domain_id) > -1) row.status = localValue;
            });
          }
        } catch (error) { /** * Do nothing ** */ }
        this.table.items.forEach((row) => {
          if (payloadIdsMap[row.traffic_source_domain_id]) row.statusProcessing = false;
        });
        task.finish();
      }
    },
    async $_updateStatus(item, value) {
      try {
        const payload = { traffic_source_domain_id: item.traffic_source_domain_id };
        const result = await this.$_userActionHandler({
          request: this.$api.domains.changeStatus(item.campaign_id, value ? 'start' : 'pause', [payload]),
          ts: this.campaign.traffic_source_unique_name,
          entityToReplace: 'Domains',
          newEntityName: 'Device',
        });
        if (result.success.length > 0) item.status = value ? 'RUNNING' : 'PAUSED';
      } catch (error) { /** * Do nothing ** */ }
    },
    async $_saveSettings(fields) {
      fields = this.mapFields(fields);
      return this.$_saveSettingsOptions(fields, { type: this.campaign.traffic_source_unique_name, level: 'DOMAIN', preset_id: this.$settings.presets.getPresetByTrafficSource(this.account.type.uniqueName) }).then(() => {
        this.$apiStore.presets.getPresets.clearCache();
        this.getFilteredPresets();
        this.$_getDomainsPaginated();
      });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('domains', this.campaign.traffic_source_unique_name, payload);
    },
    $_getTargetUrl(url) {
      const containsHttpOrHttps = (url) => url.includes('http') || url.includes('https');
      if (containsHttpOrHttps(url)) {
        return url;
      }
      return `https://${url}`;
    },
    $_exportCsvItems() {
      const filter = {
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        page: 1,
        pageSize: this.table.totals.totalElements,
        sort: this.sortType === 'asc' ? `+${this.sortField}` : `-${this.sortField}`,
        typeName: this.account.type.uniqueName,
      };
      if (this.filter?.compareRanges?.isDateComparisonEnabled) {
        filter.comparisonStartDate = this.filter.compareRanges.comparisonStartDate;
        filter.comparisonEndDate = this.filter.compareRanges.comparisonEndDate;
      }
      if (this.search !== '') {
        filter.search = this.search;
      }
      return this.$_exportCsv({ level: 'domains', campaignId: this.campaign.id, filter });
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getDomainsPaginated();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    // presets
    async $_createPreset(preset) {
      try {
        await this.createPreset(preset, 'DOMAIN');
        this.$n_successNotification({ title: `Preset with name ${preset.name} was created successfully` });
        await this.$_getDomainsPaginated();
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred, please try again!' });
      }
    },
    async $_changePreset(preset) {
      this.changePreset(preset);
      await this.$_getDomainsPaginated();
    },
    async $_deletePreset(preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.$_getDomainsPaginated();
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not delete preset, please try again' });
      }
    },
    async $_editPresetName(preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was updated successfully` });
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'Could not update preset, please try again' });
      }
    },
  },
};
</script>
