<template>
  <div>
    <!-- <preloader v-if="preloader" /> -->
    <!-- Loading Skeleton -->
    <div v-if="preloader" class="new-list-wrapper optimizer-card">
      <div class="row">
        <div v-for="item in 6" :key="item" :class="[ item > 5 ? '' : 'mb-5', 'col-md-12']">
          <loading-skeleton class="optimizer-form-skeleton" type="form" />
        </div>
      </div>
    </div>

    <div v-else class="new-list-wrapper optimizer-card">
      <!-- List Name -->
      <b-form-group
        class="optimizer-form-group"
        label="Name:"
        :state="$_hasError('name')"
        :invalid-feedback="$_getError('name')"
      >
        <b-form-input
          id="name"
          class="optimizer-form-input"
          name="name"
          placeholder="Add name"
          v-model="form.name"
          data-vv-as="Name"
          :state="$_hasError('name')"
          v-validate="'required'"
        />
      </b-form-group>

      <!-- List Type -->
      <b-form-group
        class="optimizer-form-group"
        label="List Type:"
        :state="$_hasError('listType')"
        :invalid-feedback="$_getError('listType')"
      >
        <vue-opti-select-light
          id="listType"
          :class="[{ 'has-error': $_hasError('listType') === false }, 'optimizer-select']"
          data-vv-as="List Type"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          name="listType"
          v-model="models.listType"
          :options="$c_listTypeOptions"
          value="blacklist"
          single
          button-block
        />
      </b-form-group>

      <!-- Traffic Source Type -->
      <b-form-group
        class="optimizer-form-group"
        label="Traffic Source Type:"
        :state="$_hasError('trafficSourceType')"
        :invalid-feedback="$_getError('trafficSourceType')"
      >
        <vue-opti-select-light
          id="trafficSourceType"
          :class="[{ 'has-error': $_hasError('trafficSourceType') === false }, 'optimizer-select']"
          data-vv-as="Traffic Source Type"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          name="trafficSourceType"
          v-model="models.trafficSourceType"
          :options="$c_trafficSourceTypeOptions"
          single
          button-block
        />
      </b-form-group>

      <!-- Widgets -->
      <b-form-group
        class="optimizer-form-group"
      >
        <template slot="label">
          {{ $_getEntityName(true) }}: <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.right title="Here you can see all the Widget IDs present in the SmartList. The Widget IDs may have been added to the SmartList manually (by pasting a list of widget IDs), or from one of the rules that can write to this SmartList (see “Rules writing to this List” tab)." />
        </template>

        <div class="row">
          <div class="col-auto widget-tags-wrapper">
            <vue-tags-input
              id="listWidgets"
              v-model="models.tag"
              :tags.sync="models.widgets"
              @tags-changed="newTags => models.widgets = newTags"
              @before-adding-tag="$_beforeAddingTag"
              :separators="[',', ';']"
              :placeholder="`Add ${$_getEntityName(true)} (comma separated)`"
            />
          </div>

          <div class="col-md-auto">
            <b-button-group size="sm" class="widgets-buttons">
              <b-btn variant="outline-info"
                     v-clipboard="form.widgetIds.join(', ')"
                     @success="$_clipboardSuccessHandler"
                     @error="$_clipboardErrorHandler"
              >Copy to clipboard</b-btn>
              <b-btn variant="outline-info" @click="$_clearWidgets">
                Clear {{ $_getEntityName(true) }}
              </b-btn>
            </b-button-group>
          </div>
        </div>
      </b-form-group>


      <!-- Rules -->
      <b-form-group
        class="optimizer-form-group"
      >

        <template slot="label">
          Rules Writing To List: <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.right title="Here you can add 1 or more rules that you want to populate this SmartList with Widget IDs. Every time a rule present in this SmartList is executed, and blocks a widget ID, it will also make sure to add that widget ID to this SmartList." />
        </template>

        <v-select
          id="listRules"
          placeholder="Add Rules"
          class="form-control multiselect rule-v-select"
          multiple
          v-model="models.rules"
          :options="$c_ruleOptions"
        />
      </b-form-group>

      <!-- Campaigns -->
      <b-form-group
        class="optimizer-form-group"
        label="Traffic Source Type:"
        :state="$_hasError('trafficSourceType')"
        :invalid-feedback="$_getError('trafficSourceType')"
      >

        <template slot="label">
          Campaigns Reading From List: <i class="fa fa-info-circle info-icon" v-b-tooltip.hover.right title="By specifying a list of campaigns here,  you are telling the system that you want all the Widget IDs present in this SmartList to also get blocked on each campaign that you are adding.

                                                The system will make sure that every time a new widget ID is added to the SmartList, by one of the rules that can write to the SmartList, it will also get block on every campaign present in the SmartList.

                                                Example: you can have 2 rules that are blocking widgets with very low or very high LP Ctr (possible bots) called:  “Block Low LP CTR widgets”  and “Block High LP Ctr widgets”.
                                                You can then create a SmartList called “Bot Widgets” and add these 2 rules to it. Now every time a widget is blocked by one of the 2 rules it will be added to this SmartList, and the system will make sure to also block that widget ID for all the campaigns present in this SmartList."
          />
        </template>

        <campaign-select
          v-model="models.campaigns"
          :options="$c_campaignOptions"
          :traffic-source-filter="form.typeName ? [form.typeName] : []"
          :filter-campaigns="{archived: false}"
          enable-filter-all-by-type
        />
      </b-form-group>

    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import notifications from '@sh/mixins/notifications';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import { getTabRenaming } from '../../views/Campaign/config';
import { TrafficSource } from '@sh/types';

export default {
  name: 'SmartList',
  components: {
    VueTagsInput,
    LoadingSkeleton,
  },
  mixins: [notifications],
  props: {
    mode: { type: String, default: 'create' },
    defaultWidgetsId: { type: Array, default: () => [] },
    defaultCampaignsId: { type: Array, default: () => [] },
    defaultTrafficSourceType: { type: [String, null], default: null },
    afterCreate: { type: Function, default: () => {} },
    afterUpdate: { type: Function, default: () => {} },
  },
  data() {
    return {
      preloader: true,
      form: {
        name: '',
        is_whitelist: false,
        typeName: null,
        widgetIds: [],
        ruleIds: [],
        campaignIds: [],
      },
      raw: {
        rules: [],
        trafficSourceTypes: [],
        campaigns: [],
      },
      list: null,
      models: {
        listType: null,
        trafficSourceType: null,
        widgets: [],
        rules: [],
        campaigns: [],
        tag: '',
      },
    };
  },
  computed: {
    $c_listTypeOptions() {
      return [
        {
          value: 'blacklist',
          content: '<div class="flags"><div class="flag-item label-red" >B</div></div> Blacklist',
        },
      ];
    },
    $c_trafficSourceTypeOptions() {
      return this.raw.trafficSourceTypes.reduce((acc, item) => {
        if (item.uniqueName !== TrafficSource.MediaGo) {
          acc.push({
            ...item,
            value: item.uniqueName,
            content: `<img style="width: 20px; display: inline;" src="/sh_static/accounts/${item.uniqueName}.png"/><div style="display: inline;">${item.name}</div>`,
          });
        }
        return acc;
      }, []);
    },
    $c_ruleOptions() {
      return this.raw.rules.filter((item) => item.level === 'Widget').map((item) => {
        item.value = item._id;
        item.label = item.name;
        return item;
      });
    },
    $c_campaignOptions() {
      if (this.form.typeName) {
        return this.raw.campaigns.filter((item) => item.traffic_source_unique_name === this.form.typeName).map((item) => {
          item.value = item.id;
          item.uniqueName = item.traffic_source_unique_name;
          return item;
        });
      }
      return [];
    },
  },
  watch: {
    'models.listType': function (item) {
      this.form.is_whitelist = item.value === 'whitelist';
    },
    'models.trafficSourceType': function (item) {
      this.form.typeName = item.value;
    },
    'models.widgets': function (widgets) {
      this.form.widgetIds = widgets.map((item) => item.text);
    },
    'models.rules': function (rules) {
      this.form.ruleIds = rules.map((item) => item._id);
    },
    'models.campaigns': function (campaigns) {
      this.form.campaignIds = campaigns.map((item) => item.id);
    },
  },
  async created() {
    try {
      if (this.mode === 'edit' || this.mode === 'clone') {
        [this.list, this.raw.trafficSourceTypes, this.raw.rules, this.raw.campaigns] = await Promise.all([this.$api.lists.get(this.$route.params.id), this.$apiStore.trafficSources.types(), this.$apiStore.rules.index(), this.$api.campaigns.all()]);
        this.form.name = this.list.name;
        if (this.mode === 'clone') this.form.name += ' (Copy)';
        if (this.mode === 'edit') {
          // Update RecentlySearch
          this.$settings.recentlySearch.setItem('smartlists', `smartlist-${this.list.id}`);
        }
        /* *************** Set List Type Option **************** */
        const listValue = this.list.is_whitelist ? 'whitelist' : 'blacklist';
        const listOption = this.$c_listTypeOptions.find(({ value }) => value === listValue);
        if (listOption) this.models.listType = listOption;
        /* ***************************************************** */
        /* ********** Set Traffic Source Type Option *********** */
        const trafficSourceTypeOption = this.$c_trafficSourceTypeOptions.find(({ value }) => value === this.list.typeName);
        if (trafficSourceTypeOption) {
          this.models.trafficSourceType = trafficSourceTypeOption;
          this.form.typeName = trafficSourceTypeOption.value;
        }
        /* ***************************************************** */
        if (this.list.widgetIds.length > 0) {
          this.models.widgets = this.list.widgetIds.map((id) => ({
            text: id,
            tiClasses: ['valid'],
          }));
        }
        if (this.list.ruleIds.length > 0) {
          this.models.rules = this.$c_ruleOptions.filter((rule) => this.list.ruleIds.indexOf(rule._id) > -1);
        }
        if (this.list.campaignIds.length > 0) {
          this.models.campaigns = this.$c_campaignOptions.filter((campaign) => this.list.campaignIds.indexOf(campaign.id) > -1);
        }
      } else {
        [this.raw.trafficSourceTypes, this.raw.rules, this.raw.campaigns] = await Promise.all([this.$apiStore.trafficSources.types(), this.$api.rules.index(), this.$api.campaigns.all()]);

        if (this.defaultTrafficSourceType) {
          /* ********** Set Traffic Source Type Option *********** */
          const trafficSourceTypeOption = this.$c_trafficSourceTypeOptions.find(({ value }) => value === this.defaultTrafficSourceType);
          if (trafficSourceTypeOption) {
            this.models.trafficSourceType = trafficSourceTypeOption;
            this.form.typeName = trafficSourceTypeOption.value;
          }
          /* ***************************************************** */
        }
        if (this.defaultWidgetsId) {
          this.models.widgets = this.defaultWidgetsId.map((id) => ({
            text: id,
            tiClasses: ['valid'],
          }));
        }
        if (this.defaultCampaignsId.length > 0) {
          this.models.campaigns = this.$c_campaignOptions.filter((campaign) => this.defaultCampaignsId.indexOf(campaign.id) > -1);
        }
      }
      this.preloader = false;
      this.$emit('loading', false);
    } catch (error) {
      this.$n_failNotification({ title: error.response.data.message || 'An error occurred!' });
    }
  },
  methods: {
    async submit() {
      try {
        if (await this.$validator.validateAll()) {
          this.preloader = true;
          const payload = { ...this.form };
          if (this.mode === 'edit') {
            payload.enabled = this.list.enabled;
            await this.$api.lists.update(this.list.id, payload);
            this.$n_successNotification({ title: 'List successfully updated!' });
            await this.afterUpdate();
          } else {
            await this.$api.lists.create(payload);
            this.$n_successNotification({ title: 'List successfully created!' });
            await this.afterCreate();
          }
          this.preloader = false;
          this.$emit('loading', false);
          return true;
        }
      } catch (error) {
        this.preloader = false;
        this.$emit('loading', false);
        this.$n_failNotification({ title: 'An error occurred' });
      }
      return false;
    },
    $_beforeAddingTag(data) {
      if (data.tag.text) {
        data.tag.text = data.tag.text.trim();
        data.addTag();
      }
    },
    $_clearWidgets() {
      this.models.widgets = [];
    },
    $_clipboardSuccessHandler() {
      this.$n_infoNotification({ title: `${this.form.widgetIds.length} widgets copied to clipboard` });
    },
    $_clipboardErrorHandler() {
      this.$n_failNotification({ title: 'Clipboard error' });
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_getEntityName(capitalize) {
      const entityNameMatch = getTabRenaming('widget', this.defaultTrafficSourceType);
      if (entityNameMatch) {
        return capitalize ? entityNameMatch.upper : entityNameMatch.lower;
      } return capitalize ? 'Widgets' : 'widgets';
    },
  },
};
</script>

<style lang="scss">
  .new-list-wrapper {
    padding: 2rem 2.5rem;

    .optimizer-form-group {
      margin-bottom: 2rem;

      .flags {
        display: inline-block;
        font-weight: bold;
      }

      .optimizer-form-input {
        margin-bottom: 0;
      }
    }

    .widget-tags-wrapper {
      flex: 1;
      padding-right: 0;
    }

    .vue-tags-input {
      max-width: 100% !important;

      &.ti-disabled {
        cursor: not-allowed;

        input {
          cursor: not-allowed;
        }
      }

      .ti-input {
        background: $color-white;
        color: $black-500;
        font-size: 1.4rem;
        height: auto;
        border: .1rem solid $color-light-gray;
        border-radius: .6rem;

        .ti-new-tag-input {
          font-size: 1.4rem;

          &::placeholder {
            color: $black-300;
          }
        }

        .ti-tags {
          .ti-tag {
            background: #E4EAEE;
            border-radius: .6rem;
            color: $text-primary;
            padding: .2rem 1rem;

            &.ti-deletion-mark {
              background-color: $color-red;
              color: $color-white;
            }

            .ti-content {
              margin-top: .2rem;
            }

            .ti-icon-close {
              margin-left: .2rem;
              margin-top: .2rem;
              color: black;
            }
          }
        }
      }
    }

    .widgets-buttons {
      display: flex;
      padding-top: .4rem;

      .btn {
        border: none;
        font-size: 1.375rem;
        color: $color-light-blue;
        display: flex;

        &:first-of-type {
          margin-right: .6rem;

          &::after {
            content: '';
            display: block;
            width: .1rem;
            height: 1.4rem;
            background: $color-light-blue;
            right: -.4rem;
            top: .5rem;
            position: absolute;
          }
        }

        &:hover,
        &:focus,
        &:active {
          background: transparent;
          color: $blue-400;
        }
      }
    }
  }
</style>
