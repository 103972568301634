<template>
  <div class="campaign-creator-form">
    <!-- BASIC INFO SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">2</span>
        <h1>Basic Info</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <!-- Bid Type -->
          <div class="col-md-12 mb-3">
            <component-wrapper v-if="$_isActive('bid_type')" v-bind="$_getLabelProps('bid_type')">
              <cc-radio-button v-bind="$_getProps('bid_type')" v-on="$_getEvents('bid_type')" v-model="$c_components['bid_type'].value" />
            </component-wrapper>
          </div>
          <!-- Name -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
              <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
            </component-wrapper>
          </div>

          <!-- Bid Amount -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('bid_amount')" v-bind="$_getLabelProps('bid_amount')">
              <cc-input v-bind="$_getProps('bid_amount')" v-on="$_getEvents('bid_amount')" v-model="$c_components['bid_amount'].value" />
            </component-wrapper>
          </div>

          <!-- Budget Amount -->
          <div v-if="$_isActive('budget_model')" class="col-md-6">
            <component-wrapper v-bind="$_getLabelProps('budget_model')">
              <cc-radio-button v-bind="$_getProps('budget_model')" v-on="$_getEvents('budget_model')" v-model="$c_components['budget_model'].value" />
              <cc-input v-if="$_isActive('budget')" v-bind="$_getProps('budget')" v-on="$_getEvents('budget')" v-model="$c_components['budget'].value" class="mt-3" />
            </component-wrapper>
          </div>

          <!-- Pacing -->
          <div v-if="$_isActive('pacing')" class="col-md-6">
            <component-wrapper v-bind="$_getLabelProps('pacing')">
              <cc-radio-button v-if="$_isActive('pacing')" v-bind="$_getProps('pacing')" v-on="$_getEvents('pacing')" v-model="$c_components['pacing'].value" />
            </component-wrapper>
          </div>
        </div>
        <!-- Optimize for -->
        <div class="row">
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('optimize')" v-bind="$_getLabelProps('optimize')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('optimize')" v-on="$_getEvents('optimize')" v-model="$c_components['optimize'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('conversion')" v-bind="$_getProps('conversion')" v-on="$_getEvents('conversion')" v-model="$c_components['conversion'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- SCHEDULING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">3</span>
        <h1>Schedule</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Start Date -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('start_date_model')" v-bind="$_getLabelProps('start_date_model')">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <cc-radio-button v-bind="$_getProps('start_date_model')" v-on="$_getEvents('start_date_model')" v-model="$c_components['start_date_model'].value" />
                </div>
                <div class="col-lg-6 col-md-12">
                  <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- End Date -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('end_date_model')" v-bind="$_getLabelProps('end_date_model')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('end_date_model')" v-on="$_getEvents('end_date_model')" v-model="$c_components['end_date_model'].value" />
                </div>
                <div class="col-md-6">
                  <cc-date-picker v-if="$_isActive('end_date')" v-bind="$_getProps('end_date')" v-on="$_getEvents('end_date')" v-model="$c_components['end_date'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TARGETING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">4</span>
        <h1>Targeting</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">
          <!-- Traffic Types -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('traffic_types')" v-bind="$_getLabelProps('traffic_types')">
              <cc-multi-select v-bind="$_getProps('traffic_types')" v-on="$_getEvents('traffic_types')" v-model="$c_components['traffic_types'].value" />
            </component-wrapper>
          </div>

          <!-- Target Type -->
          <!-- <div class="col-md-6">
            <component-wrapper v-if="$_isActive('target_type')" v-bind="$_getLabelProps('target_type')">
              <cc-radio-button v-bind="$_getProps('target_type')" v-on="$_getEvents('target_type')" v-model="$c_components['target_type'].value" />
            </component-wrapper>
          </div> -->

          <!-- Topic Targeting -->
          <!-- <div class="col-md-12">
            <component-wrapper v-if="$_isActive('target_targeting')" v-bind="$_getLabelProps('target_targeting')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('target_targeting')" v-on="$_getEvents('target_targeting')" v-model="$c_components['target_targeting'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('target_codes')" v-bind="$_getProps('target_codes')" v-on="$_getEvents('target_codes')" v-model="$c_components['target_codes'].value" />
                </div>
              </div>
            </component-wrapper>
          </div> -->

          <!-- Country Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('country_targeting')" v-bind="$_getLabelProps('country_targeting')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('country_targeting')" v-on="$_getEvents('country_targeting')" v-model="$c_components['country_targeting'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('country_codes')" v-bind="$_getProps('country_codes')" v-on="$_getEvents('country_codes')" v-model="$c_components['country_codes'].value" />
                </div>
              </div>
            </component-wrapper>
          </div>

          <!-- Region Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('region_targeting')" v-bind="$_getLabelProps('region_targeting')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('region_targeting')" v-on="$_getEvents('region_targeting')" v-model="$c_components['region_targeting'].value" />
                </div>
                <div class="col-md-6">
                  <cc-multi-select v-if="$_isActive('region_codes')" v-bind="$_getProps('region_codes')" v-on="$_getEvents('region_codes')" v-model="$c_components['region_codes'].value" />
                </div>
              </div>

            </component-wrapper>
          </div>

          <!-- Zip Codes Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('zipcode_targeting')" v-bind="$_getLabelProps('zipcode_targeting')">
              <div class="row">
                <div class="col-md-6">
                  <cc-radio-button v-bind="$_getProps('zipcode_targeting')" v-on="$_getEvents('zipcode_targeting')" v-model="$c_components['zipcode_targeting'].value" />
                </div>
                <div class="col-md-6">
                  <cc-input-tags v-if="$_isActive('zipcode_codes')" v-bind="$_getProps('zipcode_codes')" v-on="$_getEvents('zipcode_codes')" v-model="$c_components['zipcode_codes'].value" placeholder="Add Zip Code ( comma separated )" />
                </div>
              </div>

            </component-wrapper>
          </div>

          <!-- Platform Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('platform_targeting')" v-bind="$_getLabelProps('platform_targeting')">
              <cc-checkbox v-bind="$_getProps('platform_targeting')" v-on="$_getEvents('platform_targeting')" v-model="$c_components['platform_targeting'].value" />
            </component-wrapper>
          </div>

          <!-- Browser Targeting -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('browser_targeting')" v-bind="$_getLabelProps('browser_targeting')">
              <cc-checkbox v-bind="$_getProps('browser_targeting')" v-on="$_getEvents('browser_targeting')" v-model="$c_components['browser_targeting'].value" isForBrowserTargeting />
            </component-wrapper>
          </div>

          <!-- OS Targeting Desktop -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('os_targeting_desktop')" v-bind="$_getLabelProps('os_targeting_desktop')">
              <cc-checkbox v-bind="$_getProps('os_targeting_desktop')" v-on="$_getEvents('os_targeting_desktop')" v-model="$c_components['os_targeting_desktop'].value" />
            </component-wrapper>
          </div>

          <!-- OS Targeting Mobile -->
          <div class="col-md-12">
            <component-wrapper v-if="$_isActive('os_targeting_mobile')" v-bind="$_getLabelProps('os_targeting_mobile')">
              <cc-checkbox v-bind="$_getProps('os_targeting_mobile')" v-on="$_getEvents('os_targeting_mobile')" v-model="$c_components['os_targeting_mobile'].value" />
            </component-wrapper>
          </div>

          <!-- Language Targeting -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('language_targeting')" v-bind="$_getLabelProps('language_targeting')">
              <cc-multi-select v-bind="$_getProps('language_targeting')" v-on="$_getEvents('language_targeting')" v-model="$c_components['language_targeting'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- TRACKING SECTION -->
    <div class="form-section">
      <div class="form-section__title">
        <span class="form-section__title__badge">5</span>
        <h1>Tracking</h1>
      </div>
      <div class="optimizer-card">
        <div class="row">

          <!-- Tracking Code -->
          <div class="col-md-6">
            <component-wrapper v-if="$_isActive('tracking_code')" v-bind="$_getLabelProps('tracking_code')">
              <cc-textarea v-bind="$_getProps('tracking_code')" v-on="$_getEvents('tracking_code')" v-model="$c_components['tracking_code'].value" />
            </component-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- <component-wrapper legend="Basic Info"></component-wrapper> -->

    <!-- Name -->
    <!-- <component-wrapper v-if="$_isActive('name')" v-bind="$_getLabelProps('name')">
      <cc-input v-bind="$_getProps('name')" v-on="$_getEvents('name')" v-model="$c_components['name'].value" />
    </component-wrapper> -->

    <!-- Bid Type -->
    <!-- <component-wrapper v-if="$_isActive('bid_type')" v-bind="$_getLabelProps('bid_type')">
      <cc-radio-button v-bind="$_getProps('bid_type')" v-on="$_getEvents('bid_type')" v-model="$c_components['bid_type'].value" />
    </component-wrapper> -->

    <!-- Bid Amount -->
    <!-- <component-wrapper v-if="$_isActive('bid_amount')" v-bind="$_getLabelProps('bid_amount')">
      <cc-input v-bind="$_getProps('bid_amount')" v-on="$_getEvents('bid_amount')" v-model="$c_components['bid_amount'].value" />
    </component-wrapper> -->

    <!-- Budget Amount -->
    <!-- <component-wrapper v-if="$_isActive('budget_model')" v-bind="$_getLabelProps('budget_model')">
      <cc-radio-button v-bind="$_getProps('budget_model')" v-on="$_getEvents('budget_model')" v-model="$c_components['budget_model'].value" />
      <cc-input v-if="$_isActive('budget')" v-bind="$_getProps('budget')" v-on="$_getEvents('budget')" v-model="$c_components['budget'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Pacing -->
    <!-- <component-wrapper v-if="$_isActive('pacing')" v-bind="$_getLabelProps('pacing')">
      <cc-radio-button v-if="$_isActive('pacing')" v-bind="$_getProps('pacing')" v-on="$_getEvents('pacing')" v-model="$c_components['pacing'].value" />
    </component-wrapper> -->

    <!-- Optimize For -->
    <!-- <component-wrapper v-if="$_isActive('optimize')" v-bind="$_getLabelProps('optimize')">
      <cc-radio-button v-bind="$_getProps('optimize')" v-on="$_getEvents('optimize')" v-model="$c_components['optimize'].value" />
      <cc-multi-select v-if="$_isActive('conversion')" v-bind="$_getProps('conversion')" v-on="$_getEvents('conversion')" v-model="$c_components['conversion'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Scheduling"></component-wrapper> -->

    <!-- Start Date -->
    <!-- <component-wrapper v-if="$_isActive('start_date_model')" v-bind="$_getLabelProps('start_date_model')">
      <cc-radio-button v-bind="$_getProps('start_date_model')" v-on="$_getEvents('start_date_model')" v-model="$c_components['start_date_model'].value" />
      <cc-date-picker v-if="$_isActive('start_date')" v-bind="$_getProps('start_date')" v-on="$_getEvents('start_date')" v-model="$c_components['start_date'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- End Date -->
    <!-- <component-wrapper v-if="$_isActive('end_date_model')" v-bind="$_getLabelProps('end_date_model')">
      <cc-radio-button v-bind="$_getProps('end_date_model')" v-on="$_getEvents('end_date_model')" v-model="$c_components['end_date_model'].value" />
      <cc-date-picker v-if="$_isActive('end_date')" v-bind="$_getProps('end_date')" v-on="$_getEvents('end_date')" v-model="$c_components['end_date'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Targeting"></component-wrapper> -->

    <!-- Target Type -->
    <!-- <component-wrapper v-if="$_isActive('target_type')" v-bind="$_getLabelProps('target_type')">
      <cc-radio-button v-bind="$_getProps('target_type')" v-on="$_getEvents('target_type')" v-model="$c_components['target_type'].value" />
    </component-wrapper> -->

    <!-- Topic Targeting -->
    <!-- <component-wrapper v-if="$_isActive('target_targeting')" v-bind="$_getLabelProps('target_targeting')">
      <cc-radio-button v-bind="$_getProps('target_targeting')" v-on="$_getEvents('target_targeting')" v-model="$c_components['target_targeting'].value" />
      <cc-multi-select v-if="$_isActive('target_codes')" v-bind="$_getProps('target_codes')" v-on="$_getEvents('target_codes')" v-model="$c_components['target_codes'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Country Targeting -->
    <!-- <component-wrapper v-if="$_isActive('country_targeting')" v-bind="$_getLabelProps('country_targeting')">
      <cc-radio-button v-bind="$_getProps('country_targeting')" v-on="$_getEvents('country_targeting')" v-model="$c_components['country_targeting'].value" />
      <cc-multi-select v-if="$_isActive('country_codes')" v-bind="$_getProps('country_codes')" v-on="$_getEvents('country_codes')" v-model="$c_components['country_codes'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Region Targeting -->
    <!-- <component-wrapper v-if="$_isActive('region_targeting')" v-bind="$_getLabelProps('region_targeting')">
      <cc-radio-button v-bind="$_getProps('region_targeting')" v-on="$_getEvents('region_targeting')" v-model="$c_components['region_targeting'].value" />
      <cc-multi-select v-if="$_isActive('region_codes')" v-bind="$_getProps('region_codes')" v-on="$_getEvents('region_codes')" v-model="$c_components['region_codes'].value" class="mt-3" />
    </component-wrapper> -->

    <!-- Platform Targeting -->
    <!-- <component-wrapper v-if="$_isActive('platform_targeting')" v-bind="$_getLabelProps('platform_targeting')">
      <cc-checkbox v-bind="$_getProps('platform_targeting')" v-on="$_getEvents('platform_targeting')" v-model="$c_components['platform_targeting'].value" />
    </component-wrapper> -->

    <!-- OS Targeting -->
    <!-- <component-wrapper v-if="$_isActive('os_targeting')" v-bind="$_getLabelProps('os_targeting')">
      <cc-checkbox v-bind="$_getProps('os_targeting')" v-on="$_getEvents('os_targeting')" v-model="$c_components['os_targeting'].value" />
    </component-wrapper> -->

    <!-- Language Targeting -->
    <!-- <component-wrapper v-if="$_isActive('language_targeting')" v-bind="$_getLabelProps('language_targeting')">
      <cc-multi-select v-bind="$_getProps('language_targeting')" v-on="$_getEvents('language_targeting')" v-model="$c_components['language_targeting'].value" />
    </component-wrapper> -->

    <!-- <component-wrapper legend="Tracking"></component-wrapper> -->

    <!-- Tracking Code -->
    <!-- <component-wrapper v-if="$_isActive('tracking_code')" v-bind="$_getLabelProps('tracking_code')">
      <cc-textarea v-bind="$_getProps('tracking_code')" v-on="$_getEvents('tracking_code')" v-model="$c_components['tracking_code'].value" />
    </component-wrapper> -->

  </div>
</template>

<script>
import formMixin from './formMixin';

export default {
  name: 'RevcontentForm',
  mixins: [formMixin],
};
</script>
<style lang="scss">
  .campaign-creator-form {
    .form-section {
      margin-top: 4rem !important;
    }
  }
</style>
