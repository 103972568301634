<template>
  <b-modal
    modal-class="optimizer-modal payments-modal"
    size="xl"
    hide-footer
    v-model="modal"
    centered
  >

    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 class="modal-header__title">Payment History</h2>

      <svg class="modal-header__close-icon" @click="hide" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
      </svg>
    </template>

    <div class="payment-history-table optimizer-table">
      <vue-opti-table-light
        v-if="payments.length > 0"
        name="paymentsTable"
        class="table-wrapper"
        :hover="true"
        export-label="payment_history"
        :header-fields="table.fields"
        :items="payments"
        v-model="tableModel"
        :show-search="false"
        :default-rows="100"
      >
        <template
          slot="description"
          slot-scope="props"
        >
          <span :title="props.item.payment_item_description"><b style="font-size: 1.3rem" class="mr-2">{{ props.item.payment_item_title }}</b> {{ props.item.payment_item_description }}</span>
        </template>
        <template
          slot="amount"
          slot-scope="props"
        >
          <b style="font-size: 1.3rem">${{ props.item.amount.toFixed(2) }}</b>
        </template>
        <template #details>
          <a class="download-invoice" href="https://membership.theoptimizer.io" target="_blank"><i class="fa fa-download"></i>Invoice</a>
        </template>
      </vue-opti-table-light>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';

export default {
  name: 'EmailModal',
  props: {
    payments: { type: Array, default: () => [] },
  },
  data() {
    return {
      modal: false,
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      table: {
        fields: [
          {
            header: { content: 'Payment Date', style: 'width: 1%' },
            display: true,
            item: {
              key: 'dattm',
              sortable: true,
              content: (item) => moment(item.dattm).format('MMMM DD, YYYY'),
              style: { textAlign: 'left' },
            },
          },
          {
            header: { content: 'Description', style: '' },
            display: true,
            item: {
              key: 'payment_item_description',
              slot: 'description',
              content: (item) => item.payment_item_description,
              searchable: true,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Amount', style: '' },
            display: true,
            item: {
              key: 'amount',
              slot: 'amount',
              sortable: true,
              searchable: true,
              content: (item) => item.amount,
              style: { textAlign: 'right' },
            },
          },
          {
            header: { content: 'Details', style: '' },
            display: true,
            item: {
              slot: 'details',
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.payments-modal {
  .modal-body {
    padding: 0 !important;

    .payment-history-table {
      .table-holder {
        max-height: calc(100vh - 20rem);

        table {
          // border: .1rem solid $table-border-color;

          thead {
            th {
              // top: -.1rem !important;
              // border-top: .1rem solid $table-border-color !important;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: .1rem;
                width: 100%;
                background: $table-border-color;
              }

              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: .1rem;
                width: 100%;
                background: $table-border-color;
              }
            }
          }

          tr {
            td {
              .field {
                padding: .5rem;
              }
            }
          }
        }

        .download-invoice {
          display: inline-block;
          font-size: 1.4rem;
          color: #59acfa;

          &:hover {
            text-decoration: none;
            color: $color-blue;
          }

          i {
            margin-right: 5px;
          }
        }
      }

      .footer {
        margin-right: 0;
        margin-left: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}
</style>
