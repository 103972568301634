<template>
  <div class="smart-bid">
    <div v-if="state === 'BUSY'" class="busy-overlay" />
    <b-form-group class="m-0" :style="{ opacity: state === 'BUSY' ? '0.7' : '1' }">
      <b-form-radio-group
        v-model="selected"
        :options="options"
        buttons
        button-variant="outline-primary"
        size="sm"
        @change="$_onChange"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'BidStrategy',
  props: {
    value: { type: String },
    onUpdate: { type: Function, default: () => true },
  },
  data() {
    return {
      state: 'READY',
      lastValue: null,
      selected: null,
      options: [
        { text: 'Fixed', value: 'FIXED' },
        { text: 'Smart', value: 'SMART_BID' },
      ],
    };
  },
  created() {
    this.selected = this.value;
  },
  methods: {
    async $_onChange(value) {
      this.state = 'BUSY';
      try {
        const result = await this.onUpdate(value);
        if (result) {
          // Emit
          this.$emit('input', value);
        } else {
          // Revert
          this.selected = this.value;
        }
        this.state = 'READY';
      } catch (error) {
        this.state = 'READY';
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.smart-bid {
  position: relative;
  overflow: hidden;

  .btn-group {
    width: auto;
    .btn {
      font-size: 1.2rem;
      padding: .1rem .5rem;
      color: #47b3d4;
      cursor: pointer;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }

      &.btn-outline-primary {
        border-color: rgba(32, 168, 216, 0.25);

        &.active {
          background-color: #47b3d4;
        }

        &:hover:not(.active) {
          color: #fff;
          background-color: #47b3d4;
        }
      }
    }
  }
  .busy-overlay {
    position: absolute;
    width: 255px;
    background-image: repeating-linear-gradient(-45deg, rgba(80, 169, 224, 0.25), rgba(80, 169, 224, 0.25) 11px, rgba(255, 255, 255, 0) 10px, rgba(255, 255, 255, 0) 20px);
    animation: move .7s linear infinite;
    position: absolute;
    height: 100%;
    z-index: 9999;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }
}
</style>
