
import SearchFeedTrackingUrl from '@sh/components/TrafficSources/SearchFeedTrackingUrl.vue';
import TrackingUrl from '@sh/components/TrafficSources/TrackingUrl.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { AccountWizard } from '@sh/types';
import { defineComponent, PropType } from 'vue';

interface TokensData {
  tokens: Record<string, string>[];
  displayNames: string[];
}

export default defineComponent({
  components: {
    OptimizerIcon,
    TrackingUrl,
    SearchFeedTrackingUrl,
  },
  props: {
    tracker: {
      type: Object as PropType<AccountWizard.GoogleAnalytics.Tracker>,
      required: true,
    },
    sourceIdOnTracker: String,
    sources: {
      type: Object as PropType<AccountWizard.GoogleAnalytics.Source[]>,
      required: true,
    },
    tokensData: Object as PropType<TokensData>,
  },
  data() {
    return {
      searchFeeds: [
        'Tonic',
        'Sedo',
        'Bodis',
        'DomainActive',
        'System1',
        'GoogleAnalytics4',
        'Ads',
        'AssertiveYield',
        'Media',
      ],
      trackers: ['ClickFlare', 'Voluum'],
    };
  },
  computed: {
    source(): AccountWizard.GoogleAnalytics.Source {
      return this.sources[0];
    },
    headerDisplayedSources(): AccountWizard.GoogleAnalytics.Source[] {
      if (this.checkIfTrackerIsNotEmpty) {
        return [this.source];
      }
      return this.sources;
    },
    checkIfTrackerIsNotEmpty(): boolean {
      if (this.tracker) {
        return !!Object.values(this.tracker).length;
      }
      return false;
    },
    checkIfTrafficSourceIsNotEmpty(): boolean {
      if (this.source) {
        return !!Object.values(this.source).length;
      }
      return false;
    },
    getHeader(): string {
      if (this.tracker?.type.uniqueName === 'CSVSource') {
        return 'Successfully connected to Google Sheets';
      }
      return 'IMPORTANT: Tracking Code';
    },
    mainText(): string {
      if (this.tracker?.type.uniqueName === 'CSVSource') {
        return (
          'The system will automatically import any data available in the Google Sheet document every 30 minutes. ' +
          'Keep updating the data in the Google Sheet as frequently as possible.'
        );
      }
      return `In order for the integration to work properly, please make sure that all your
                ${this.source.type.uniqueName} campaigns or Ad URLs include this ${this.tracker.type.uniqueName} tracking
                code.`;
    },
  },
});
