<template>
  <b-input-group class="campaign-select-container-light w-100">
    <vue-opti-select-light
      ref="campaignsDropdown"
      class="optimizer-select campaign-optimizer-select flex-grow-1"
      :options="$_options"
      v-model="model"
      unique-key="id"
      label-key="name"
      button-type="tag"
      option-type="checkbox"
      button-placeholder="Search campaign..."
      @change="(value) => { $emit('input', value) }"
      :disabled="disabled"
      button-block
      debounce
      searchable
    >
      <template #TAG_LABEL="{ option }">
        <img
          class="acc-item-icon new-icon"
          :src="getTrafficSourceLogo(option.traffic_source_unique_name)"
          :title="option.traffic_source_unique_name"
          v-b-tooltip.hover.top="{
            customClass: 'custom-tooltip',
          }"
          alt="traffic-source-image"
        />
        <div class="acc-item-name">
          {{ option.name }}
        </div>
      </template>
      <template #ITEM="{ option }">
        <img
          class="acc-item-icon new-icon"
          :src="getTrafficSourceLogo(option.traffic_source_unique_name)"
          :title="option.traffic_source_unique_name"
          alt="traffic-source-image"
        />
        <div class="acc-item-name">
          {{ option.name }}
        </div>
      </template>
    </vue-opti-select-light>
    <b-dropdown v-if="enableFilter" class="filter-dropdown" slot="append" :no-flip="true" @hidden="$_dropdownHidden" right>
      <template #button-content>
        <i class="fa fa-filter" /> Add Campaigns
      </template>
      <div class="searchCampaignItem">
        <b-input v-model="$c_searchModel" placeholder="Search..." autocomplete="off" class="searchCampaignInput w-100" />
        <div class="searchInfo d-flex justify-content-between">
          <span class="count-display">
            Found: <i v-if="statsLoading" class="fa fa-refresh fa-spin" /><template v-else>{{ $c_countSearchCampaings }}</template>
          </span>
          <b-btn :disabled="statsLoading" class="add-btn" size="sm" @click="$_addSearchCampaigns">
            Add
          </b-btn>
        </div>
      </div>
      <b-dropdown-header v-for="(item, index) in $c_filter" :key="index">
        <b-form-checkbox v-model="item.selected" @change="(value) => {$_selectItem(item, value)}">
          <div class="d-inline-block" v-html="item.content" />
          <div class="d-inline-block counter">
            (<template v-if="(typeof item.type) !== 'undefined' && (typeof item.status) !== 'undefined'">
              {{ item.ids.length }}
            </template>)
          </div>
        </b-form-checkbox>
      </b-dropdown-header>
      <b-dropdown-item @click="$_clearForm" class="clear-form-item">
        <i class="fa fa-times text-danger" /> Clear
      </b-dropdown-item>
    </b-dropdown>
  </b-input-group>
</template>

<script>
import { debounce } from 'lodash';
import { getTrafficSourceLogo } from '@sh/helpers';
import { TrafficSource } from '@sh/types';

export default {
  name: 'CampaignSelectLight',
  props: {
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    enableFilter: { type: Boolean, default: true },
    trafficSourceFilter: { type: Array, default: () => [] },
    trackerAccountFilter: { type: Array, default: () => [] },
    customFilter: { type: [Object, null], default: null },
    enableFilterAllByType: { type: Boolean, default: false }, // TODO filter response
    managed: { type: Boolean, default: true },
    archived: { type: Boolean, default: false },
    propellerBidType: { type: String, default: null },
  },
  data() {
    return {
      model: [],
      stats: [],
      search: '',
      searchModel: '',
      statsLoading: false,
      preloader: true, // Unused
    };
  },
  computed: {
    $c_platformFilter() {
      const filter = [
        // {content: this.$_getItemContent('Add All'), selected: false},
        // {enabled: true, content: this.$_getItemContent('Add Active Only'), selected: false},
        // {enabled: false, content: this.$_getItemContent('Add Paused Only'), selected: false},
      ];
      let trafficSources = [TrafficSource.RevContent, TrafficSource.Taboola, TrafficSource.Outbrain, TrafficSource.Mgid, TrafficSource.ContentAd, TrafficSource.Adskeeper, TrafficSource.Gemini, TrafficSource.VoluumDSP, TrafficSource.ActiveRevenue, TrafficSource.Runative, TrafficSource.Facebook, TrafficSource.AmazonDSP, TrafficSource.MediaGo, TrafficSource.TikTok, TrafficSource.GoogleAds, TrafficSource.YahooDSP];
      /* ******** Filter by allowed Traffic Source Type ********* */
      if (this.trafficSourceFilter.length === 1) {
        trafficSources = trafficSources.filter((type) => type === this.trafficSourceFilter[0]);
      } else if (this.trafficSourceFilter.length > 1) {
        trafficSources = trafficSources.filter((type) => this.trafficSourceFilter.indexOf(type) > -1 || typeof type === 'undefined');
      }
      /* ******** ************************************* ********* */
      trafficSources.forEach((type) => {
        if (this.enableFilterAllByType) filter.push({ type, status: 'ALL', content: this.$_getItemContent('Add All', type), selected: false });
        filter.push(
          {
            type, status: 'RUNNING', enabled: true, content: this.$_getItemContent('Add Active', type), selected: false,
          },
          {
            type, status: 'PAUSED', enabled: false, content: this.$_getItemContent('Add Paused', type), selected: false,
          },
          {
            type, status: 'OUT OF FUNDS', enabled: false, content: this.$_getItemContent('Add Out Of Founds', type), selected: false,
          },
          {
            type, status: 'OTHER', enabled: false, content: this.$_getItemContent('Add Other', type), selected: false,
          },
        );
      });

      return filter;
    },
    $c_statResult() {
      const dimensions = {};
      this.stats.forEach((item) => {
        if (!dimensions[item.traffic_source_unique_name]) dimensions[item.traffic_source_unique_name] = {};
        dimensions[item.traffic_source_unique_name][item.statusts] = item.ids;
      });
      return dimensions;
    },
    $c_filter() {
      const filter = [];
      this.$c_platformFilter.forEach((item) => {
        if (this.$c_statResult[item.type] && this.$c_statResult[item.type][item.status]) {
          const newItem = { ...item, ids: this.$c_statResult[item.type][item.status] };
          filter.push(newItem);
        }
      });
      return filter;
    },
    $c_searchModel: {
      get() {
        return this.searchModel;
      },
      set(value) {
        // Debounce set value
        this.$options.debounceSetSearchFunction(value);
      },
    },
    $c_countSearchCampaings() {
      return this.search === '' ? '' : this.$c_filter.reduce((total, { ids }) => total + ids.length, 0);
    },
  },
  async created() {
    this.$watch('customFilter', () => {
      this.$refs.campaignsDropdown.resetOptions();
      this.$_updateStats();
    });

    this.$watch('trackerAccountFilter', () => {
      this.$refs.campaignsDropdown.resetOptions();
      this.$_updateStats();
    });

    this.$watch('trafficSourceFilter', () => {
      this.$refs.campaignsDropdown.resetOptions();
      this.$_updateStats();
    });

    this.$watch('value', (value) => {
      this.model = value;
    }, { immediate: true });

    this.$watch('searchModel', () => {
      this.$_updateStats();
    });

    /* ********** Debounced Search ********** */
    this.$options.debounceSetSearchFunction = debounce((value) => {
      this.searchModel = value;
    }, 300);
    /* ************************************** */

    await this.$_updateStats();
  },
  methods: {
    getTrafficSourceLogo,
    async $_selectItem(item, value) {
      if (item.ids.length) {
        if (value) {
          const campaigns = await this.$_getCampaignsByIds(item.ids);
          this.$emit('input', [...this.model, ...campaigns]);
        } else {
          this.$refs.campaignsDropdown.remove(item.ids);
          this.$emit('input', this.model);
        }
      }
    },
    $_getItemContent(text, type) {
      return type ? `<span class="filter-item"><img src="${getTrafficSourceLogo(type)}" title="${type}" /> ${text}</span>` : `<span class="filter-item">${text}</span>`;
    },
    $_clearForm() {
      this.$c_platformFilter.forEach((item) => { item.selected = false; });
      this.$emit('input', []);
    },
    async $_addSearchCampaigns() {
      if (this.search) {
        const allIds = [];
        this.$c_filter.forEach(({ ids }) => { allIds.push(...ids); });
        const campaigns = await this.$_getCampaignsByIds(allIds);
        const payload = [...this.model, ...campaigns];
        this.$emit('input', payload);
      }
    },
    $_dropdownHidden() {
      this.searchModel = '';
    },
    async $_options(page = 1, search = '') {
      const payload = { managed: this.managed, archived: this.archived, page, search };
      if (this.trafficSourceFilter && this.trafficSourceFilter.length) payload.typeName = this.trafficSourceFilter;
      // Temporarily disabled until we support multi trackers for a single campaign
      // if (this.trackerAccountFilter && this.trackerAccountFilter.length) payload.trackerAccountIds = this.trackerAccountFilter;
      if (this.customFilter) Object.assign(payload, this.customFilter);
      const { data } = await this.$api.campaigns.getByFilters(payload);
      if (this.propellerBidType) {
        return data.filter((c) => c.bid_strategy === this.propellerBidType);
      }
      return data;
    },
    async $_updateStats() {
      const payload = { managed: this.managed, archived: this.archived, search: this.searchModel };
      if (this.trafficSourceFilter && this.trafficSourceFilter.length) payload.typeName = this.trafficSourceFilter;
      // Temporarily disabled until we support multi trackers for a single campaign
      // if (this.trackerAccountFilter && this.trackerAccountFilter.length) payload.trackerAccountIds = this.trackerAccountFilter;
      if (this.customFilter) Object.assign(payload, this.customFilter);
      if (this.propellerBidType) Object.assign(payload, { propellerBidType: this.propellerBidType });
      this.statsLoading = true;
      try {
        const stats = await this.$api.campaigns.getStatusStats(payload);
        this.search = payload.search || '';
        this.$set(this, 'stats', stats);
      } catch (error) {
        console.log(error);
      }
      this.statsLoading = false;
    },
    async $_getCampaignsByIds(ids = []) {
      if (ids.length) {
        const { data } = await this.$api.campaigns.getByFilters({ ids, pageSize: 1000000 });
        return data;
      }
      return [];
    },
  },
};
</script>
