<template>
  <div v-if="!preloader" class="tracker-wrapper">

    <!-- Alert Message -->
    <b-alert
      :show="!!alert.message"
      :variant="alert.type"
      dismissible
      @dismissed="$_dismissAlert()"
    >
      <div v-html="alert.message" />
    </b-alert>

    <!-- Type Select -->
    <b-form-group
      label="Select Tracking Platform"
      class="optimizer-form-group"
    >
      <vue-opti-select-light
        class="optimizer-select w-100"
        name="type"
        :disabled="mode.action !== 'create'"
        v-model="form.type"
        :options="$c_trackerTypes"
        unique-key="id"
        label-key="name"
        single
        button-block
        button-placeholder="Select Tracker Type"
        @change="(item) => $_setCredentials(item.uniqueName || null)"
      >
        <template #ITEM="{ option }">
          <img
            class="m-0"
            :src="`/sh_static/accounts/${option.uniqueName}.png`"
            alt="traffic-source-icon"
          />
          <div class="ml-8 option-text">
            {{ option.name }}
          </div>
          <span class="tracker-item-recommended" v-if="option.uniqueName === 'ClickFlare'">
            (Recommended Tracker)
            <i @click.stop="openLinkInNewTab" class="fa fa-question-circle" v-b-tooltip.top title="Click to learn more" />
          </span>
        </template>
        <template #BUTTON_PLACEHOLDER="{ option }">
          <div class="traffic-source-value d-flex">
            <img
              class="m-0"
              :src="`/sh_static/accounts/${option.uniqueName}.png`"
              alt="traffic-source-icon"
            />
            <div class="ml-8 option-text">
              {{ option.name }}
            </div>
          </div>
        </template>
      </vue-opti-select-light>
    </b-form-group>

    <!-- Account Name -->
    <b-form-group
      class="optimizer-form-group"
      label="Name"
      description="( Give this tracker a name. This is for your personal reference only. You can put whatever you like here )"
      :feedback="$_getError('name')"
      :state="$_hasError('name')"
    >
      <a v-if="form.type && form.type.uniqueName === 'GoogleAnalytics'"
         href="https://support.theoptimizer.io/en/articles/5676496-google-analytics-setup"
         target="_blank"
         class="info-link fs-14 fw-400"
      >
        {{ $_infoText }}
      </a>
      <a v-else-if="form.type && form.type.uniqueName === 'GoogleAnalytics4'"
         href="https://theoptimizer.io/help/knowledge-base/google-analytics-4-setup-guide"
         target="_blank"
         class="info-link fs-14 fw-400"
      >
        {{ $_infoText }}
      </a>
      <b-form-input
        class="optimizer-form-input"
        data-vv-as="Account Name"
        :state="$_hasError('name')"
        v-validate="'required'"
        type="text"
        v-model="form.name"
        name="name"
        key="name"
        placeholder="Account Name"
      />
    </b-form-group>

    <!-- Thrive -->
    <div
      v-if="form.type.uniqueName === 'Thrive'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5679826-thrive-tracker-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            v-model="form.credentials.apiToken"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- apiSecret -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiSecret')"
        :state="$_hasError('apiSecret')"
      >
        <template slot="label">
          API Secret
          <a
            href="https://support.theoptimizer.io/en/articles/5679826-thrive-tracker-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Secret"
            :state="$_hasError('apiSecret')"
            v-validate="'required'"
            :type="showCredential['apiSecret'] ? 'text' : 'password'"
            v-model="form.credentials.apiSecret"
            name="apiSecret"
            placeholder="API Secret"
          />
          <button @click="$_switchCredential('apiSecret')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- apiVar -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiVar')"
        :state="$_hasError('apiVar')"
      >
        <template slot="label">
          Security Token
          <a
            href="https://support.theoptimizer.io/en/articles/5679826-thrive-tracker-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Security Token"
          :state="$_hasError('apiVar')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.apiVar"
          name="apiVar"
          placeholder="Security Token"
        />
      </b-form-group>

      <!-- url -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('url')"
        :state="$_hasError('url')"
      >
        <template slot="label">
          Tracker Url
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Tracker Url"
          :state="$_hasError('url')"
          v-validate="'required|url:require_protocol'"
          type="text"
          v-model="form.credentials.url"
          name="url"
          placeholder="Tracker Url"
        />
      </b-form-group>
    </div>

    <!-- CloudThrive -->
    <div
      v-if="form.type.uniqueName === 'CloudThrive'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiKey -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676462-thrive-tracker-cloud-setup"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiKey')"
            v-validate="'required'"
            :type="showCredential['apiKey'] ? 'text' : 'password'"
            v-model="form.credentials.apiKey"
            name="apiKey"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiKey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- installId -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiSecret')"
        :state="$_hasError('apiSecret')"
      >
        <template slot="label">
          Install Id
          <a
            href="https://support.theoptimizer.io/en/articles/5676462-thrive-tracker-cloud-setup"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Install Id"
          :state="$_hasError('installId')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.installId"
          name="installId"
          placeholder="Install Id"
        />
      </b-form-group>
    </div>

    <!-- Voluum -->
    <div
      v-if="form.type.uniqueName === 'Voluum'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- accessId -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('accessId')"
        :state="$_hasError('accessId')"
      >
        <template slot="label">
          Access Key ID
          <a
            href="https://support.theoptimizer.io/en/articles/5676450-voluum-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Access Key ID"
            :state="$_hasError('accessId')"
            v-validate="'required'"
            :type="showCredential['accessId'] ? 'text' : 'password'"
            v-model="form.credentials.accessId"
            name="accessId"
            placeholder="Access Key ID"
          />
          <button @click="$_switchCredential('accessId')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- accessKey -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('accessKey')"
        :state="$_hasError('accessKey')"
      >
        <template slot="label">
          Access Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676450-voluum-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Access Key"
            :state="$_hasError('accessKey')"
            v-validate="'required'"
            :type="showCredential['accessKey'] ? 'text' : 'password'"
            v-model="form.credentials.accessKey"
            name="accessKey"
            placeholder="Access Key"
          />
          <button @click="$_switchCredential('accessKey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- Currency -->
      <b-form-group
        class="optimizer-form-group"
        :invalid-feedback="$_getError('currency')"
        :state="$_hasError('currency')"
      >
        <template slot="label">
          Select the currency of your tracker account
        </template>
        <vue-opti-select-light
          class="optimizer-select w-100 mb-0"
          data-vv-as="Currency"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          unique-key="code"
          label-key="currency"
          name="currency"
          :value="form.credentials.currency"
          @change="(option) => { form.credentials.currency = option.code }"
          :options="rawData.currencies"
          :search-fields="['code', 'currency']"
          button-placeholder="Select currency"
          single
          searchable
          button-block
        />
      </b-form-group>

      <!-- conversionTimeMode -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('conversionTimeMode')"
        :state="$_hasError('conversionTimeMode')"
      >
        <template slot="label">
          <span>
            Conversion registration time reporting
            <i
              class="fa fa-info-circle"
              aria-hidden="true"
              v-b-tooltip.hover
              title="It determines how your global conversion reporting is represented based on event timestamps. Registered entities are shown in the reports only with a given timestamp (visit or postback) for the specified time period."
            />
          </span>
        </template>
        <b-form-radio-group
          v-model="form.credentials.conversionTimeMode"
          :options="[{ text: 'Visit timestamp', value: 'VISIT' }, { text: 'Postback timestamp', value: 'CONVERSION' }]"
          name="conversionTimeMode"
          v-validate="'required'"
          data-vv-as="Conversion registration time reporting"
          :state="$_hasError('conversionTimeMode')"
          stacked
        />
      </b-form-group>
    </div>

    <!-- Bemob -->
    <div
      v-if="form.type.uniqueName === 'Bemob'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- accessId -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('accessId')"
        :state="$_hasError('accessId')"
      >
        <template slot="label">
          Access Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676542-bemob-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Access Key"
            :state="$_hasError('accessId')"
            v-validate="'required'"
            :type="showCredential['accessId'] ? 'text' : 'password'"
            v-model="form.credentials.accessId"
            name="accessId"
            placeholder="Access Key"
          />
          <button @click="$_switchCredential('accessId')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- accessKey -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('accessKey')"
        :state="$_hasError('accessKey')"
      >
        <template slot="label">
          Secret Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676542-bemob-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Secret Key"
            :state="$_hasError('accessKey')"
            v-validate="'required'"
            :type="showCredential['accessKey'] ? 'text' : 'password'"
            v-model="form.credentials.accessKey"
            name="accessKey"
            placeholder="Secret Key"
          />
          <button @click="$_switchCredential('accessKey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- AdsBridge -->
    <div
      v-if="form.type.uniqueName === 'AdsBridge'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- userId -->
      <b-form-group
        class="optimizer-form-group"
        description="This is the id in AdsBridge account"
        :feedback="$_getError('userId')"
        :state="$_hasError('userId')"
      >
        <template slot="label">
          Account ID
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Account ID"
          :state="$_hasError('userId')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.userId"
          name="userId"
          placeholder="Account ID"
        />
      </b-form-group>

      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is the api key in AdsBridge account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676571-adsbridge-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- Binom -->
    <div
      v-if="form.type.uniqueName === 'Binom'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is the api key in Binom account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676534-binom-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- url -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('url')"
        :state="$_hasError('url')"
        description=" Disclaimer: ( Please do not include any '.php' in your Tracking URL ) "
      >
        <template slot="label">
          Tracker Url
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Tracker Url"
          :state="$_hasError('url')"
          v-validate="{ required: true, regex:/^(?!.*.php).*$/ }"
          type="text"
          v-model="form.credentials.url"
          name="url"
          placeholder="Tracker Url"
        />
      </b-form-group>
      <div class="binom__tracking__url">
        <h4>Login</h4>
        <div>
          <span>index.php <i class="fa fa-arrows-h divider" /></span>
          <b-form-group
            class="optimizer-form-group"
            :feedback="$_getError('url')"
            :state="$_hasError('url')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Path"
              type="text"
              :state="$_hasError('indexPath')"
              v-validate="{ required: true, regex:/^(?!.*.php).*$/ }"
              v-model="form.credentials.index_php_path"
              name="indexPath"
              placeholder="index.php"
            />
          </b-form-group>
          <p>.php</p>
        </div>
        <div>
          <span class="second-span">arm.php <i class="fa fa-arrows-h divider" /></span>
          <b-form-group
            class="optimizer-form-group"
            :feedback="$_getError('path')"
            :state="$_hasError('path')"
          >
            <b-form-input
              class="optimizer-form-input"
              data-vv-as="Path"
              :state="$_hasError('armPath')"
              v-validate="{ required: true, regex:/^(?!.*.php).*$/ }"
              type="text"
              v-model="form.credentials.arm_php_path"
              name="armPath"
              placeholder="arm.php"
            />
          </b-form-group>
          <p>.php</p>
        </div>
        <small class="form-text text-muted">( Please check Binom and add the same URL path that you have in your *URL CUSTOMIZATION* settings )</small>
      </div>

      <!-- secretKey -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="If you are using 2FA (two-factor authentication) in Binom, please enter your secret key"
        :feedback="$_getError('secretKey2fa')"
        :state="$_hasError('secretKey2fa')"
      >
        <template slot="label">
          Secret Key (optional)
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Secret Key"
            :state="$_hasError('secretKey2fa')"
            :type="showCredential['secretKey2fa'] ? 'text' : 'password'"
            v-model="form.credentials.secretKey2fa"
            name="secretKey2fa"
            placeholder="Secret Key Key"
          />
          <button @click="$_switchCredential('secretKey2fa')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

    </div>

    <!-- Binom V2-->
    <div
      v-if="form.type.uniqueName === 'BinomV2'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is the api key in Binom account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676534-binom-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- url -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('url')"
        :state="$_hasError('url')"
        description="Your tracking domain"
      >
        <template slot="label">
          Tracker Url
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Tracker Url"
          :state="$_hasError('url')"
          v-validate="{ required: true, regex:/^(?!.*.php).*$/ }"
          type="text"
          v-model="form.credentials.url"
          name="url"
          placeholder="Tracker Url"
        />
      </b-form-group>
    </div>
    <!-- FunnelFlux -->
    <div
      v-if="form.type.uniqueName === 'FunnelFlux'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is API Key FunnelFlux account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5679806-funnelflux-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- url -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('url')"
        :state="$_hasError('url')"
      >
        <template slot="label">
          Tracker Url
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Tracker Url"
          :state="$_hasError('url')"
          v-validate="'required|url:require_protocol'"
          type="text"
          v-model="form.credentials.url"
          name="url"
          placeholder="Tracker Url"
        />
      </b-form-group>
    </div>

    <!-- RedTrack -->
    <div
      v-if="form.type.uniqueName === 'RedTrack'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676502-redtrack-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
      <b-form-group
        class="optimizer-form-group"
      >
        <div class="d-flex align-items-center justify-content-">
          <div class="info mr-2">
            <i v-b-tooltip.hover title="Only enable this option if you are on the RedTrack Enterprise plan and the Premium API is active on your account." class="fa fa-info-circle field-info"></i>
          </div>
          <span class="mr-2 col-form-label fw-700">
            Enable Enterprise API
          </span>
          <switch-button
            :checked="form.credentials.plan === 'Enterprise'"
            :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
            :update="(value) => form.credentials.plan = value ? 'Enterprise' : 'Basic' "
          />
        </div>
      </b-form-group>
      <b-form-group
        class="optimizer-form-group"
        :invalid-feedback="$_getError('revenueField')"
        :state="$_hasError('revenueField')"
      >
        <template slot="label">
          Select which revenue metric you want to pull from RedTrack <i class="fa fa-info-circle" aria-hidden="true" v-b-tooltip="{hover: true, top: true, title: 'The default value, if no selection is made, will be Conversion Revenue.', boundary: 'window'}"></i>
        </template>
        <vue-opti-select-light
          class="optimizer-select w-100 mb-0"
          data-vv-as="Revenue Field"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          unique-key="code"
          label-key="revenueField"
          name="revenueField"
          :value="form.credentials.revenueField"
          @change="(option) => { form.credentials.revenueField = option.code }"
          :options="[{ code: 'revenue', revenueField: 'Conversion Revenue', title: 'Only the revenue generated from the main conversion event.'}, { code: 'total_revenue', revenueField: 'Total Revenue', title: 'The total revenue generated from all conversion events.'}]"
          button-placeholder=""
          single
          button-block
        >
          <template #ITEM="{ option }">
            <div class="ml-8 option-text"
                 v-b-tooltip.hover.right.html="{
                   customClass: 'field-tooltip custom-tooltip max-width-190 default-cursor',
                   title: option.title,
                   fallbackPlacement: 'counterclockwise'
                 }"
            >
              {{ option.revenueField }}
            </div>
          </template>
        </vue-opti-select-light>

      </b-form-group>
    </div>

    <!-- Kintura -->
    <div
      v-if="form.type.uniqueName === 'Kintura'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group"
        description="Here you can find API Key"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- apiSecret -->
      <b-form-group
        class="optimizer-form-group"
        description="Here you can find API Secret"
        :feedback="$_getError('apiSecret')"
        :state="$_hasError('apiSecret')"
      >
        <template slot="label">
          API Secret
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Secret"
            :state="$_hasError('apiSecret')"
            v-validate="'required'"
            :type="showCredential['apiSecret'] ? 'text' : 'password'"
            v-model="form.credentials.apiSecret"
            name="apiSecret"
            placeholder="API Secret"
          />
          <button @click="$_switchCredential('apiSecret')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- url -->
      <b-form-group
        class="optimizer-form-group"
        description="Tracker url"
        :feedback="$_getError('url')"
        :state="$_hasError('url')"
      >
        <template slot="label">
          Tracker Url
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Tracker Url"
          :state="$_hasError('url')"
          v-validate="'required|url:require_protocol'"
          type="text"
          v-model="form.credentials.url"
          name="url"
          placeholder="Tracker Url"
        />
      </b-form-group>
    </div>

    <!-- Tonic -->
    <div
      v-if="form.type.uniqueName === 'Tonic'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="Here you can find API Key"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- apiSecret -->
      <b-form-group
        class="optimizer-form-group"
        description="Here you can find API Secret"
        :feedback="$_getError('apiSecret')"
        :state="$_hasError('apiSecret')"
      >
        <template slot="label">
          API Secret
          <a
            href="https://support.theoptimizer.io/en/articles/5676435-tonic-integration-guide"
            target="_blank"
            class="info-link"
            style="color: #42a0f8; font-size: 1.4rem; font-weight: normal"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Secret"
            :state="$_hasError('apiSecret')"
            v-validate="'required'"
            :type="showCredential['apiSecret'] ? 'text' : 'password'"
            v-model="form.credentials.apiSecret"
            name="apiSecret"
            placeholder="API Secret"
          />
          <button @click="$_switchCredential('apiSecret')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- System1 -->
    <div
      v-if="form.type.uniqueName === 'System1'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="Here you can find API Key"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676405-system1-integration-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- Sedo -->
    <div
      v-if="form.type.uniqueName === 'Sedo'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- username -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('username')"
        :state="$_hasError('username')"
      >
        <template slot="label">
          Username
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Username"
          :state="$_hasError('username')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.username"
          name="username"
          placeholder="Username"
        />
      </b-form-group>

      <!-- password -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('password')"
        :state="$_hasError('password')"
      >
        <template slot="label">
          Password
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Password"
            :state="$_hasError('password')"
            v-validate="'required'"
            :type="showCredential['password'] ? 'text' : 'password'"
            v-model="form.credentials.password"
            name="password"
            placeholder="Password"
          />
          <button @click="$_switchCredential('password')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('partnerid')"
        :state="$_hasError('partnerid')"
      >
        <template slot="label">
          PartnerID
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="PartnerID"
          :state="$_hasError('partnerid')"
          v-validate="'required'"
          type="number"
          v-model="form.credentials.partnerid"
          name="partnerid"
          placeholder="PartnerID"
        />
      </b-form-group>

      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('signkey')"
        :state="$_hasError('signkey')"
      >
        <template slot="label">
          Signkey
          <a
            href="https://support.theoptimizer.io/en/articles/5676417-sedo-integration"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="signkey"
            :state="$_hasError('signkey')"
            v-validate="'required'"
            :type="showCredential['signkey'] ? 'text' : 'password'"
            v-model="form.credentials.signkey"
            name="signkey"
            placeholder="Signkey"
          />
          <button @click="$_switchCredential('signkey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- Bodis -->
    <div
      v-if="form.type.uniqueName === 'Bodis'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- ApiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="( This is the api key in Bodis Account )"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          Api Token
          <a
            href="https://support.theoptimizer.io/en/articles/5676417-bodis-integration"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="signkey"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiKey"
            placeholder="Api Token"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- Ads -->
    <div
      v-if="form.type.uniqueName === 'Ads'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- Username -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="( This is the username in your Ads Account )"
        :feedback="$_getError('username')"
        :state="$_hasError('username')"
      >
        <template slot="label">
          Username
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="signkey"
          :state="$_hasError('username')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.username"
          name="username"
          placeholder="Username"
        />
      </b-form-group>

      <!-- Password -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="( This is the password in your Ads Account )"
        :feedback="$_getError('password')"
        :state="$_hasError('password')"
      >
        <template slot="label">
          Password
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="signkey"
            :state="$_hasError('password')"
            v-validate="'required'"
            :type="showCredential['password'] ? 'text' : 'password'"
            v-model="form.credentials.password"
            name="password"
            placeholder="Password"
          />
          <button @click="$_switchCredential('password')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- Secret Keys -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="( This is the secret key in your Ads Account )"
        :feedback="$_getError('secretKey')"
        :state="$_hasError('secretKey')"
      >
        <template slot="label">
          Secret Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676417-ads-integration"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="signkey"
            :state="$_hasError('secretKey')"
            v-validate="'required'"
            :type="showCredential['secretKey'] ? 'text' : 'password'"
            v-model="form.credentials.secretKey"
            name="secretKey"
            placeholder="Secret Key"
          />
          <button @click="$_switchCredential('secretKey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- DomainActive -->
    <div
      v-if="form.type.uniqueName === 'DomainActive'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- apiKey -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiKey')"
        :state="$_hasError('apiKey')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676393-domainactive-integration-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="apiKey"
            :state="$_hasError('apiKey')"
            v-validate="'required'"
            :type="showCredential['apiKey'] ? 'text' : 'password'"
            v-model="form.credentials.apiKey"
            name="apiKey"
            placeholder="ApiKey"
          />
          <button @click="$_switchCredential('apiKey')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- Everflow -->
    <div
      v-if="form.type.uniqueName === 'Everflow'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is the api key in Everflow account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676492-everflow-setup-guide"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>

      </b-form-group>

      <b-form-group
        class="optimizer-form-group"
      >
        <template slot="label">
          Account Type
        </template>
        <b-form-radio-group
          v-model="form.credentials.accountType"
          :options="[{ text: 'Administrator', value: 1 }, { text: 'Affiliate', value: 0 }]"
          name="accountType"
          data-vv-as="Account Type"
          stacked
        />
      </b-form-group>
    </div>

    <!-- FunnelFluxPro -->
    <div v-if="form.type.uniqueName === 'FunnelFluxPro'" :key="form.type.uniqueName">
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group"
        description="This is the api key in FunnelFluxPro Account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href="https://support.theoptimizer.io/en/articles/5676445-funnelflux-pro-setup-guide"
            target="_blank"
            class="info-link"
            style="color: #42a0f8; font-size: 1.4rem; font-weight: normal"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          ></b-form-input>
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- CustomTracker -->
    <div
      v-if="form.type.uniqueName === 'CustomTracker'"
      :key="form.type.uniqueName"
      class="mt-4"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group"
        description="This is the api token of your account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="API Key"
          :state="$_hasError('apiToken')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.apiToken"
          name="apiToken"
          placeholder="API Key"
        />
      </b-form-group>
    </div>

    <!-- AssertiveYield -->
    <div
      v-if="form.type.uniqueName === 'AssertiveYield'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- email -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('email')"
        :state="$_hasError('email')"
      >
        <template slot="label">
          Email
          <a
            href="https://theoptimizer.io/help/knowledge-base/assertive-yield-metrics-mapping-details/"
            target="_blank"
            class="info-link"
            style="color: #42a0f8; font-size: 1.4rem; font-weight: normal"
          >{{ $_infoText }}</a>
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Email"
          :state="$_hasError('email')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.email"
          name="email"
          placeholder="email"
        />
      </b-form-group>

      <!-- password -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('password')"
        :state="$_hasError('password')"
      >
        <template slot="label">
          Password
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Password"
            :state="$_hasError('password')"
            v-validate="'required'"
            :type="showCredential['password'] ? 'text' : 'password'"
            v-model="form.credentials.password"
            name="password"
            placeholder="Password"
          />
          <button @click="$_switchCredential('password')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- ClickFlare -->
    <div
      v-if="form.type.uniqueName === 'ClickFlare'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          Api Key
          <a
            href="https://theoptimizer.io/help/knowledge-base/clickflare-setup-guide/"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Api Token"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="Api Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>

      <!-- Currency -->
      <b-form-group
        class="optimizer-form-group"
        :invalid-feedback="$_getError('currency')"
        :state="$_hasError('currency')"
      >
        <template slot="label">
          Select the currency of your tracker account
        </template>
        <vue-opti-select-light
          class="optimizer-select w-100 mb-0"
          data-vv-as="Currency"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          unique-key="code"
          label-key="currency"
          name="currency"
          :value="form.credentials.currency"
          @change="(option) => { form.credentials.currency = option.code }"
          :options="rawData.currencies"
          :search-fields="['code', 'currency']"
          button-placeholder="Select currency"
          single
          searchable
          button-block
        />
      </b-form-group>

      <!-- conversionTimeMode -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('conversionTimeMode')"
        :state="$_hasError('conversionTimeMode')"
      >
        <template slot="label">
          <span>
            Conversion registration time reporting
            <i
              class="fa fa-info-circle"
              aria-hidden="true"
              v-b-tooltip.hover
              title="This option determines how TheOptimizer will count the conversion data from ClickFlare. We recommend keeping the same option that you are using in ClickFlare."
            />
          </span>
        </template>
        <b-form-radio-group
          v-model="form.credentials.conversionTimeMode"
          :options="[{ text: 'Visit timestamp', value: 'VISIT' }, { text: 'Postback timestamp', value: 'CONVERSION' }]"
          name="conversionTimeMode"
          v-validate="'required'"
          data-vv-as="Conversion registration time reporting"
          :state="$_hasError('conversionTimeMode')"
          stacked
        />
      </b-form-group>
    </div>

    <!-- CPVLab -->
    <div
      v-if="form.type.uniqueName === 'CPVLab'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- apiToken -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        description="This is the api key in CPVLab account"
        :feedback="$_getError('apiToken')"
        :state="$_hasError('apiToken')"
      >
        <template slot="label">
          API Key
          <a
            href=""
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="API Key"
            :state="$_hasError('apiToken')"
            v-validate="'required'"
            :type="showCredential['apiToken'] ? 'text' : 'password'"
            v-model="form.credentials.apiToken"
            name="apiToken"
            placeholder="API Key"
          />
          <button @click="$_switchCredential('apiToken')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- CSVSource -->
    <div
      v-if="form.type.uniqueName === 'CSVSource'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >

      <b-form-group
        class="optimizer-form-group"
      >
        <template slot="label">
          Get a copy of our Google Sheets Template
        </template>
        <small class="form-text text-muted">
          The Google Sheets document needs to have a specific format in order for this integration to work properly.
          We have prepared a template which you can clone into your Google Sheets and use it for this integration.
          <b-link @click="$_openArticle()">Learn more ></b-link> <br>
        </small>
        <span class="custom-border">
          <i class="fa fa-clipboard" /><b-link class="custom-font-color" @click="$_downloadTemplate()"> Click to clone Template </b-link>
        </span>
      </b-form-group>

      <b-form-group
        class="optimizer-form-group"
      >
        <template slot="label">
          Share
        </template>
        <small class="form-text text-muted">
          Make sure to give us access to your google sheets by sharing it with this email:
          <b>{{ $_googleServiceEmail }}</b>
        </small>
      </b-form-group>

      <!-- spreadsheet id -->
      <b-form-group
        class="optimizer-form-group"
        :feedback="$_getError('g-sheets-url')"
        :state="$_hasError('g-sheets-url')"
      >
        <template slot="label">
          Google Sheets URL
          <i
            class="fa fa-info-circle"
            aria-hidden="true"
            v-b-tooltip.hover
            title="The URL of the Google Sheets document you cloned on the previous step."
          />
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="SID"
          :state="$_hasError('g-sheets-url')"
          type="text"
          v-validate="'required|url:require_protocol'"
          v-model="form.credentials.spreadsheet.url"
          name="g-sheets-url"
          placeholder="Google Sheets URL"
        />
      </b-form-group>

      <!-- Currency -->
      <b-form-group
        class="optimizer-form-group"
        :invalid-feedback="$_getError('currency')"
        :state="$_hasError('currency')"
      >
        <template slot="label">
          Select Currency
          <i
            class="fa fa-info-circle"
            aria-hidden="true"
            v-b-tooltip.hover
            title="Specify the currency you are going to use for the Spend and Revenue data in the Google Sheet document."
          />
        </template>
        <vue-opti-select-light
          class="optimizer-select w-100 mb-0"
          data-vv-as="Currency"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          unique-key="code"
          label-key="currency"
          name="currency"
          :value="form.credentials.currency"
          @change="(option) => { form.credentials.currency = option.code }"
          :options="rawData.currencies"
          :search-fields="['code', 'currency']"
          button-placeholder="Select currency"
          single
          searchable
          button-block
        />
      </b-form-group>

      <b-form-group
        class="optimizer-form-group"
      >
        <template slot="label">
          How does it work?
        </template>
        <small class="form-text text-muted">
          If you have data (conversions, revenue, etc) coming from 3d party systems not directly integrated with TheOptimizer,
          you can put this data in a Google Sheets document and then we will automatically import the data (every 30 minutes)
          and make it available on TheOptimizer.
          <b-link @click="$_openArticle()">Learn more ></b-link>
        </small>
      </b-form-group>

    </div>


    <!-- Media -->
    <div
      v-if="form.type.uniqueName === 'Media'"
      :key="form.type.uniqueName"
      class="tracker-wrapper__form"
    >
      <!-- Credentials -->
      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('email')"
        :state="$_hasError('email')"
      >
        <template slot="label">
          Email
          <a
            href="https://theoptimizer.io/help/knowledge-base/media-net-integration-guide/"
            target="_blank"
            class="info-link"
          >{{ $_infoText }}</a>
        </template>
        <b-form-input
          class="optimizer-form-input"
          data-vv-as="Email"
          :state="$_hasError('email')"
          v-validate="'required'"
          type="text"
          v-model="form.credentials.email"
          name="email"
          placeholder="Email"
        />
      </b-form-group>

      <b-form-group
        class="optimizer-form-group form-group-info-link"
        :feedback="$_getError('password')"
        :state="$_hasError('password')"
      >
        <template slot="label">
          Password
        </template>
        <span class="view-password-container">
          <b-form-input
            class="optimizer-form-input"
            data-vv-as="Password"
            :state="$_hasError('password')"
            v-validate="'required'"
            :type="showCredential['password'] ? 'text' : 'password'"
            v-model="form.credentials.password"
            name="password"
            placeholder="Password"
          />
          <button @click="$_switchCredential('password')"><i class="fa fa-eye" aria-hidden="true"></i></button>
        </span>
      </b-form-group>
    </div>

    <!-- customDomains -->
    <custom-domains
      v-if="['Thrive', 'CloudThrive', 'Voluum', 'AdsBridge', 'Binom', 'BinomV2', 'FunnelFlux', 'FunnelFluxPro', 'Bemob', 'RedTrack', 'CPVLab'].indexOf(form.type.uniqueName) > -1"
      v-model="form.credentials.customDomains"
      :limit="500"
      ref="customDomains"
    />

    <div class="tracker-wrapper__form" v-if="!['ClickFlare', 'Voluum', 'GoogleAnalytics4', 'CSVSource'].includes(form.type.uniqueName)">
      <b-form-group
        class="optimizer-form-group"
        :invalid-feedback="$_getError('currency')"
        :state="$_hasError('currency')"
      >
        <template slot="label">
          Select the currency of your tracker account
        </template>

        <vue-opti-select-light
          class="optimizer-select w-100 mb-0"
          data-vv-as="Currency"
          v-validate="'required'"
          data-vv-validate-on="input|update"
          data-vv-value-path="$c_model"
          unique-key="code"
          label-key="currency"
          name="currency"
          :value="form.credentials.currency"
          @change="(option) => { form.credentials.currency = option.code }"
          :options="rawData.currencies"
          :search-fields="['code', 'currency']"
          button-placeholder="Select currency"
          single
          searchable
          button-block
        />
      </b-form-group>
    </div>

    <div
      class="row row-footer"
      v-if="submitBtn"
    >
      <div class="col-sm-12">
        <b-button
          variant="primary"
          @click="save"
        >
          {{ mode.action === 'create' ? 'Save' : 'Update' }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CustomDomains from '@sh/components/misc/CustomDomains';
import Headers from '@sh/components/misc/Headers';
import notifications from '@sh/mixins/notifications';
import configFile from './config';
import currencies from 'currency-codes/data';
import { cloneDeep } from 'lodash';

export default {
  name: 'Tracker',
  components: { CustomDomains, Headers },
  mixins: [notifications],
  props: {
    mode: {
      type: Object,
      default: () => ({ action: 'create', account: null, accountId: null }),
    }, // action: create|edit, accountId: null|Number
    submitBtn: { type: Boolean, default: false },
  },
  data() {
    return {
      preloader: true,
      config: null,
      rawData: {
        types: [],
        tsTypes: [],
        trTypes: [],
        account: null,
        currencies,
      },
      maps: {
        trafficSourceAccountsByTypeId: {},
      },
      form: {
        type: null,
        name: '',
        credentials: {},
      },
      alert: {
        type: 'danger',
        message: '',
      },
      showCredential: {},
    };
  },
  computed: {
    // $_typeOptions() {
    //   const options = [];
    //   options.push({
    //     text: '-- Please select Tracker Type --',
    //     value: null,
    //     disabled: true,
    //   });
    //   this.rawData.types.forEach((item, index) => {
    //     options.push({ text: item.name, value: item });
    //   });
    //   return options;
    // },
    $c_trackerTypes() {
      return this.rawData.types.toSorted((first, second) => {
        if (second.uniqueName === 'ClickFlare') {
          return 1;
        }
        return first.name.localeCompare(second.name);
      });
    },
    $c_tsTypes() {
      return cloneDeep(this.rawData.tsTypes);
    },
    $_infoText() {
      return `Where can I find API information for ${this.form.type.name || ''} ?`;
    },
    $_googleServiceEmail() {
      return process.env.VUE_APP_GOOGLE_SERVICE_EMAIL || 'dorina-opt@northern-math-409512.iam.gserviceaccount.com';
    },
  },
  async created() {
    this.preloader = true;
    try {
      await this.$_init();
    } catch (error) {
      console.log(error);
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    }
    this.preloader = false;
  },
  methods: {
    async $_init() {
      /** ***************** Load Config **************** */
      this.config = await configFile.getConfig();
      /** *********************************************** */

      /** ************ Resolve Tracker Type ************ */
      if (this.mode.action === 'create') {
        this.rawData.types = await this.config.api.trackers.types();

        /** Make Clickflare first item in the list */
        const clickFlareIndex = this.rawData.types.findIndex(item => item.uniqueName === 'ClickFlare');
        if (clickFlareIndex >= 0) this.rawData.types.unshift(this.rawData.types.splice(clickFlareIndex, 1)[0]);
      } else {
        const resolveAccount = () => this.mode.account || this.config.api.trackers.account(this.mode.accountId);
        [this.rawData.types, this.rawData.account] = await Promise.all([this.config.api.trackers.types(), resolveAccount()]);
      }

      /** *********************************************** */

      /** ***************** Init Form ****************** */
      this.$_setForm(this.rawData.account);
      /** *********************************************** */

      this.$emit('ready');
    },
    $_setForm(account) {
      if (account) {
        this.form.type = account.type;
        this.form.name = account.name;
        this.$_setCredentials(this.form.type.uniqueName, account.credentials);
      } else {
        this.form.type = this.rawData.types[0];
        this.$_setCredentials(this.form.type.uniqueName);
      }
    },
    $_setCredentials(uniqueName, credentials = null) {
      const form = cloneDeep(this.config.form[uniqueName] || this.config.form['default']);
      this.form.credentials = form.credentials;

      if (credentials) {
        // Set Values
        Object.keys(this.form.credentials).forEach((key) => {
          this.$set(this.showCredential, key, false);
          if (typeof credentials[key] !== 'undefined') { this.form.credentials[key] = credentials[key]; }
        });
        this.form.credentials.customDomains = credentials.customDomains
          ? credentials.customDomains.map((url) => ({ url }))
          : [];
      } else {
        this.form.credentials.customDomains = [];
      }
    },
    $_switchCredential(key) {
      this.showCredential[key] = !this.showCredential[key];
    },
    async save() {
      this.$_dismissAlert();
      try {
        let validation = await this.$validator.validateAll();
        if (validation && this.$refs.customDomains) { validation = await this.$refs.customDomains.validate(); }
        if (validation) {
          if (['GoogleAnalytics4','GoogleAnalytics'].includes(this.form.type.uniqueName)) {
            // Redirect to Google url
            if (this.mode.action === 'create') {
              window.location.href = this.$_generateNewGoogleAnalyticsAuthUrl();
            } else {
              window.location.href = this.$_generateEditGoogleAnalyticsAuthUrl();
            }
            return true;
          }

          if (['CSVSource'].includes(this.form.type.uniqueName)) {
            const { success, error = '' } = await this.config.api.trackers.validateHeaders(this.form.credentials, []);
            const errorWithUrl = error.replaceAll('{linkPlaceholder}', '<a target="_blank" href="https://theoptimizer.io/help/knowledge-base/google-sheets-integration/" >here</a>')
            if (!success) {
              this.$_showAlert('danger',errorWithUrl);
              return false;
            }
          }

          try {
            const payload = {
              name: this.form.name,
              credentials: {
                ...this.form.credentials,
                customDomains: this.form.credentials.customDomains
                  .map((item) => item.url)
                  .filter((url) => url !== ''),
              },
              settings: {},
            };

            if (!payload.credentials.customDomains.length) { delete payload.credentials.customDomains; }

            let request = null;
            if (this.mode.action === 'create') {
              // Create
              payload.typeName = this.form.type.uniqueName;
              payload.typeId = this.form.type.id.toString();
              request = await this.config.api.trackers.create(payload);
              this.$_showAlert('success', 'Account successfully created !');
            } else {
              // Update
              if (this.rawData.account.settings) { payload.settings = this.rawData.account.settings; }
              request = await this.config.api.trackers.update(
                this.rawData.account.id,
                payload,
              );
              this.$_showAlert('success', 'Account successfully updated !');
            }
            this.$emit('save', request);
            return request;
          } catch (error) {
            try {
              this.$_showAlert('danger', error.response.data.message);
            } catch (err) {
              this.$_showAlert('danger', 'An error occurred');
            }
            return false;
          }
        } else {
          this.$_showAlert('warning', 'Validation fail');
          return false;
        }
      } catch (error) {
        this.$_showAlert('danger', 'An error occurred');
        return false;
      }
    },
    async delete() {
      try {
        const response = await this.config.api.trackers.delete(
          this.rawData.account.id,
        );
        if (response) {
          this.$_showAlert('success', 'Account successfully deleted !');
          return true;
        }
        this.$_showAlert('danger', 'Deleting account failed !');
        return false;
      } catch (error) {
        this.$_showAlert('danger', 'An error occurred');
        return false;
      }
    },
    reset() {
      this.form.name = '';
      this.$_setCredentials(this.form.type.uniqueName);
      this.$_dismissAlert();
      this.$validator.reset();
      if (this.$refs.customDomains) this.$refs.customDomains.reset();
    },
    $_showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
    },
    $_dismissAlert() {
      this.alert.message = '';
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_generateNewGoogleAnalyticsAuthUrl() {
      if (!this.form.name) return 'javascript:void(0)';
      const data = {
        url: `${location.origin}/#/account-wizard`,
        params: {
          step: 1,
          ts: this.$route.query.ts || undefined,
          account_name: this.form.name,
          account_unique_name: this.form.type.uniqueName,
          account_type_id: this.form.type.id,
          settings: JSON.stringify(this.form.settings),
        },
      };
      return `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://user-manager.theoptimizer.io/ga4/callback/&prompt=consent&response_type=code&client_id=544523385849-jvuo61d36n40utlsimitnsmh4tfvlod6.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/analytics.readonly&access_type=offline&state=${btoa(
        JSON.stringify(data),
      )}`;
    },
    $_generateEditGoogleAnalyticsAuthUrl() {
      if (!this.form.name || !(this.rawData.account && this.rawData.account.id)) { return 'javascript:void(0)'; }
      const data = {
        url: `${location.origin}/#trackers`,
        params: {
          account_id: this.rawData.account.id,
          account_name: this.form.name,
          account_unique_name: this.form.type.uniqueName,
          account_type_id: this.form.type.id,
          settings: JSON.stringify(this.form.settings),
        },
      };
      return `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://user-manager.theoptimizer.io/ga4/callback/&prompt=consent&response_type=code&client_id=544523385849-jvuo61d36n40utlsimitnsmh4tfvlod6.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/analytics.readonly&access_type=offline&state=${btoa(
        JSON.stringify(data),
      )}`;
    },
    openLinkInNewTab() {
      window.open('https://clickflare.io', '_blank');
    },
    async $_openArticle() {
      const articleUrl = 'https://theoptimizer.io/help/knowledge-base/google-sheets-integration/';
      window.open(articleUrl, '_blank');
    },
    async $_downloadTemplate() {
      const csvUrl = 'https://docs.google.com/spreadsheets/u/0/d/16HdgsTZg0dW85bSJZJdBe2iyTzlb6S-R_fE3QkWcbQA/copy';
      window.open(csvUrl);
    },
    async $_downloadCustomTemplate() {
      const tokenPromises = [];
      const tsUniqueNames = [];
      this.form.credentials.trafficSources.forEach(ts => {
        tokenPromises.push(this.config.api.trafficSources.tokens(ts.uniqueName));
        tsUniqueNames.push(ts.uniqueName);
      });
      const tokens = await Promise.all(tokenPromises);
      let csv = await this.config.api.trackers.getCsvTemplate(tokens);
      const exportedFilenmae = `${tsUniqueNames.join('_')}_template.csv`;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    getSelectedTrafficSourcesText(totalSelected) {
      if (!totalSelected || totalSelected === this.rawData.tsTypes.length) {
        return 'All';
      }
      return totalSelected;
    },
  },
};
</script>

<style lang="scss">
.tracker-wrapper {

  .custom-border {
    border-bottom: solid 2px #2b00ff3b;
  }

  .custom-font-color {
    font-size: 1.4rem;
    color: #2d0addc7;
  }

  .mb {
    margin-bottom: 2rem;
  }

  .badge-fix .dropdown .dropdown-toggle .button-placehoder-filter {
    width: 100%;
    text-align: left;
    .badge {
      background-color: #42a0f8;
    }
  }

  .transition {
    transition: opacity .5s;
    margin-top: 2rem;

    &.hidden {
      position: absolute;
    }
  }

  .alert {
    margin-bottom: 2.5rem;

    .close {
      font-size: 2.5rem;
    }
  }

  &__form {
    margin-top: 2rem;

    .custom-control-input:checked {
      ~ .custom-control-label::before {
        background-color: #42a0f8;
        border-color: #42a0f8;
      }
    }

    .custom-control-label {
      margin-right: 1.5rem;
      font-size: 1.5rem;

      span {
        padding-left: .3rem;
      }

      &::before {
        top: .3rem;
        left: -1.9rem;
        width: 1.5rem;
        height: 1.5rem;
        box-shadow: none !important;
        outline: none;
      }

      &::after {
        top: 0.3rem;
        left: -1.9rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .optimizer-form-group {
    margin-bottom: 0;
    margin-top: 2rem;

    &:first-of-type {
      margin-top: 0;
    }

    .traffic-source-value img {
      width: 2rem;
    }

    .tracker-item-recommended {
      font-size: 1.3rem;
      color: #b1b1b1;
      margin-left: 1rem;

      i {
        color: #5e5e5e;
        font-size: 1.5rem;
        margin-left: .5rem;

        &:hover {
          color: #494949;
        }
      }
    }

    .view-password-container {
      display: flex;
      align-items: center;

      button {
        background-color: transparent;
        border: none;
        margin-bottom: .8rem;
      }
    }
  }

  .binom__tracking__url {
    margin-top: 1rem;
    padding: 1rem 0rem;

    div {
      display: flex;
      align-items: center;
      gap: 1rem;

      span {
        width: 100px;
      }

      .second-span {
        padding-left: 1.4rem;
      }
    }
  }

  .info-link {
    color: #42a0f8;
  }
}
</style>
