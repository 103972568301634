import { NavUrls } from '@sh/components/Layout/Sidebar/navItems';

export enum VideoCategory {
  DASHBOARD = 'Dashboard',
  CAMPAIGNS = 'Campaigns',
  REPORTING = 'Reporting',
  CREATIVE_LIBRARY = 'Creative Library',
  CAMPAIGN_CREATOR = 'Campaign Creator',
  WEBINAR = 'Webinars',
  RULES = 'Rules',
  SMART_LISTS = 'Smart Lists',
  MANUAL_CONNECTION = 'Manual Connection',
  AUTOMATED_STATS_UPDATE = 'Automated Stats Update',
  INTEGRATIONS = 'Integrations',
  ACCOUNT_WIZARD = 'Account Wizard',
  TRAFFIC_SOURCES = 'Traffic Sources',
  TRACKERS = 'Trackers',
}

export interface Category {
  title: string;
  description?: string;
  category: VideoCategory;
  routes: NavUrls[];
}

export interface Video {
  link: string;
  title: string;
  duration: string;
  thumbnail: string;
  category: VideoCategory;
  order?: number;
}

export interface MergedCategory extends Category {
  videos: Video[];
}

export const categories: Category[] = [
  {
    title: 'Dashboard Video Tutorials',
    category: VideoCategory.DASHBOARD,
    routes: [NavUrls.DASHBOARD],
  },
  {
    title: 'Campaigns Video Tutorials',
    category: VideoCategory.CAMPAIGNS,
    routes: [NavUrls.CAMPAIGNS],
  },
  {
    title: 'Reporting Video Tutorials',
    category: VideoCategory.REPORTING,
    routes: [NavUrls.REPORTING],
  },
  {
    title: 'Creative Library Video Tutorials',
    category: VideoCategory.CREATIVE_LIBRARY,
    routes: [NavUrls.CREATIVE_LIBRARY_CREATIVES, NavUrls.CREATIVE_LIBRARY_HEADLINES, NavUrls.CREATIVE_LIBRARY_IMAGES],
  },
  {
    title: 'Campaign Creator Tutorials',
    category: VideoCategory.CAMPAIGN_CREATOR,
    routes: [NavUrls.CAMPAIGN_CREATOR],
  },
  {
    title: 'Webinars',
    category: VideoCategory.WEBINAR,
    routes: [],
  },
  {
    title: 'Rules Video Tutorials',
    category: VideoCategory.RULES,
    routes: [NavUrls.RULES],
  },
  {
    title: 'Smart Lists Video Tutorials',
    category: VideoCategory.SMART_LISTS,
    routes: [NavUrls.SMART_LISTS],
  },
  {
    title: 'Manual Connection Video Tutorials',
    category: VideoCategory.MANUAL_CONNECTION,
    routes: [NavUrls.MANUAL_CONNECTION],
  },
  {
    title: 'Automated Stats Update Video Tutorials',
    category: VideoCategory.AUTOMATED_STATS_UPDATE,
    routes: [NavUrls.MANUAL_STATS_UPDATE],
  },
  {
    title: 'Integrations Video Tutorials',
    category: VideoCategory.INTEGRATIONS,
    routes: [NavUrls.INTEGRATIONS],
  },
  {
    title: 'Traffic Sources Video Tutorials',
    category: VideoCategory.TRAFFIC_SOURCES,
    routes: [NavUrls.TRAFFIC_SOURCES],
  },
  {
    title: 'Trackers Video Tutorials',
    category: VideoCategory.TRACKERS,
    routes: [NavUrls.TRACKERS],
  },
];

export const videos: Video[] = [
  {
    link: 'yXyXq_djUQc',
    title: 'Manually Create multiple campaign variations at once to Taboola, Outbrain or Revcontent',
    duration: '2024-03-09T21:00:17Z',
    thumbnail: 'https://i.ytimg.com/vi/yXyXq_djUQc/default.jpg',
    category: VideoCategory.CREATIVE_LIBRARY,
  },
  {
    link: '-fXmhmmVCxU',
    title: 'Excel Bulk Campaign Uploader - Taboola, Outbrain, RevContent',
    duration: '2024-03-09T21:03:22Z',
    thumbnail: 'https://i.ytimg.com/vi/-fXmhmmVCxU/default.jpg',
    category: VideoCategory.CAMPAIGN_CREATOR,
  },
  {
    link: 'kU3AEgCuGow',
    title: 'Campaign Creator - How to bulk create campaigns from TheOptimizer',
    duration: '2024-03-23T10:12:10Z',
    thumbnail: 'https://i.ytimg.com/vi/kU3AEgCuGow/default.jpg',
    category: VideoCategory.CAMPAIGN_CREATOR,
  },
  {
    link: 'OmFud_CGiJc',
    title: 'Creative Library - Your creative cockpit',
    duration: '202411:46:55Z',
    thumbnail: 'https://i.ytimg.com/vi/OmFud_CGiJc/default.jpg',
    category: VideoCategory.CAMPAIGN_CREATOR,
  },
  {
    link: 'PU3hX1FAFK8',
    title: 'TikTok & Domain Active - How to connect Domain Active to TikTok?',
    duration: '2024-06-11T13:42:41Z',
    thumbnail: 'https://i.ytimg.com/vi/PU3hX1FAFK8/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'IKriSN4OO_E',
    title: 'Taboola & Voluum - How to connect Voluum to Taboola?',
    duration: '2024-06-11T13:40:49Z',
    thumbnail: 'https://i.ytimg.com/vi/IKriSN4OO_E/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'prbuFStutd0',
    title: 'How to connect Voluum tracker to Facebook?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/prbuFStutd0/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'uT63tQBG9fQ',
    title: 'How to connect Tonic  to Facebook?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/uT63tQBG9fQ/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'mv1JlCHStXo',
    title: 'How to connect System1 to Facebook?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/mv1JlCHStXo/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'tBx6LJOuu7w',
    title: 'How to connect Taboola  to TheOptimizer?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/tBx6LJOuu7w/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'jEU-yTBve0c',
    title: 'Taboola & System1 - How to connect System1 to Taboola?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/jEU-yTBve0c/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'nER8hmK2_Nw',
    title: 'Taboola & Media.net - How to connect Media.Net to Taboola?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/nER8hmK2_Nw/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'zRfT1dVQdRs',
    title: 'Taboola & Domain Active - How to connect Domain Active to Taboola?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/zRfT1dVQdRs/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'w-Q-1q7D6hk',
    title: 'How to connect Sedo to TikTok?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/w-Q-1q7D6hk/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'ij-6t_r-7iQ',
    title: 'How to connect System1 to TikTok?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/ij-6t_r-7iQ/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'g1dse4vMDVE',
    title: 'How to connect ClickFlare to Outbrain?',
    duration: '2024-06-11T10:34:51Z',
    thumbnail: 'https://i.ytimg.com/vi/g1dse4vMDVE/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'En2l6rPjo3g',
    title: 'How to connect Media.net to Facebook?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/En2l6rPjo3g/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'MoS_3TRyqHQ',
    title: 'How to connect Domain Active to Facebook?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/MoS_3TRyqHQ/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'a5uwpP8fq-k',
    title: 'Taboola & Tonic - How to connect Tonic to Taboola?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/a5uwpP8fq-k/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'fxA4gpca0rU',
    title: 'Taboola & Google Analytics - How to connect Google Analytics to Taboola?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/fxA4gpca0rU/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'YtLSGV5zLgk',
    title: 'Taboola & Ads.com - How to connect Ads.com to Taboola?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/YtLSGV5zLgk/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'bcNkpAsTaIg',
    title: 'Taboola & Sedo - How to connect Sedo to Taboola?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/bcNkpAsTaIg/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'VIVvF_KvNyc',
    title: 'How to connect Ads.com to TikTok?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/VIVvF_KvNyc/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'IXWdGVhGCpE',
    title: 'How to connect Google Analytics to TikTok?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/IXWdGVhGCpE/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'Z8-qq-VFi0E',
    title: 'How to connect Media.net to TikTok?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/Z8-qq-VFi0E/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'KzFViAIc19c',
    title: 'How to connect TikTok to TheOptimizer?',
    duration: '2024-06-11T10:34:49Z',
    thumbnail: 'https://i.ytimg.com/vi/KzFViAIc19c/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '4pj4RG2Oczo',
    title: 'How to connect Ads.com to Facebook?',
    duration: '2024-06-11T10:34:48Z',
    thumbnail: 'https://i.ytimg.com/vi/4pj4RG2Oczo/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'BOPGbxOzJdY',
    title: 'How to connect Facebook to TheOptimizer.',
    duration: '2024-06-11T10:34:48Z',
    thumbnail: 'https://i.ytimg.com/vi/BOPGbxOzJdY/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '2589QRC8b3E',
    title: 'How to connect ClickFlare tracker to Facebook?',
    duration: '2024-06-11T10:34:48Z',
    thumbnail: 'https://i.ytimg.com/vi/2589QRC8b3E/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'Ct6aPtSqtXw',
    title: 'How to connect Tonic to TikTok?',
    duration: '2024-06-11T10:34:48Z',
    thumbnail: 'https://i.ytimg.com/vi/Ct6aPtSqtXw/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '2ul4K9q1-Y0',
    title: 'How to connect Outbrain to TheOptimizer?',
    duration: '2024-06-11T10:34:48Z',
    thumbnail: 'https://i.ytimg.com/vi/2ul4K9q1-Y0/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'D52lOud8T3w',
    title: 'How to connect Sedo to Facebook?',
    duration: '2024-06-11T10:34:47Z',
    thumbnail: 'https://i.ytimg.com/vi/D52lOud8T3w/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '6utBUfokqk0',
    title: 'How to connect Google Analytics to Facebook?',
    duration: '2024-06-11T10:34:47Z',
    thumbnail: 'https://i.ytimg.com/vi/6utBUfokqk0/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'EjOtuXmLLz4',
    title: 'How to connect ClickFlare to Taboola?',
    duration: '2024-06-11T10:34:47Z',
    thumbnail: 'https://i.ytimg.com/vi/EjOtuXmLLz4/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '3DXgsp5sweg',
    title: 'How to connect ClickFlare to TikTok?',
    duration: '2024-06-11T10:34:47Z',
    thumbnail: 'https://i.ytimg.com/vi/3DXgsp5sweg/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: '6c46walKg5Q',
    title: 'How to connect Voluum to Outbrain?',
    duration: '2024-06-11T10:34:47Z',
    thumbnail: 'https://i.ytimg.com/vi/6c46walKg5Q/default.jpg',
    category: VideoCategory.INTEGRATIONS,
  },
  {
    link: 'jNY2AkBonyc',
    title: 'Webinar: Tested Optimization Strategies for Running Profitable Meta Ads',
    duration: '2024-06-14T04:16:48Z',
    thumbnail: 'https://i.ytimg.com/vi/jNY2AkBonyc/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: '-tLcfFjYGvI',
    title: 'Webinar: TheOptimizer Secrets to Scaling, Automating, and Crushing ROI',
    duration: '2024-04-12T04:24:00Z',
    thumbnail: 'https://i.ytimg.com/vi/-tLcfFjYGvI/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: '8b5Cd2VHxMA',
    title: 'Secrets From an 8-Figure Lead Generation Super Affiliate',
    duration: '2023-06-21T05:10:44Z',
    thumbnail: 'https://i.ytimg.com/vi/8b5Cd2VHxMA/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'XwfBPzJa1G8',
    title: 'Webinar: Setting Yourself Up for Success in Q4 with Native Ads',
    duration: '2022-10-26T04:07:24Z',
    thumbnail: 'https://i.ytimg.com/vi/XwfBPzJa1G8/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: '4tmp0ABu5bs',
    title: 'Webinar: Running and Scaling Native-to-Search Campaigns on Taboola',
    duration: '2022-05-31T19:53:39Z',
    thumbnail: 'https://i.ytimg.com/vi/4tmp0ABu5bs/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'VlzpYnZj8MU',
    title: 'Practical Tips to Scale Your NativeAds Campaigns',
    duration: '2022-04-25T10:30:47Z',
    thumbnail: 'https://i.ytimg.com/vi/VlzpYnZj8MU/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'e6y7jWaztM8',
    title: 'Running Search Feed Arbitrage Campaigns on Native',
    duration: '2020-12-03T20:14:34Z',
    thumbnail: 'https://i.ytimg.com/vi/e6y7jWaztM8/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'BdNnEMSsoE4',
    title: 'TheOptimizer Weekly Webinar',
    duration: '2020-06-11T21:05:06Z',
    thumbnail: 'https://i.ytimg.com/vi/BdNnEMSsoE4/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'g0BgDHR4A4E',
    title: 'Webinar: Meet The All-New Automatic Optimization Rules 🔥🔥🔥',
    duration: '2020-06-05T11:55:39Z',
    thumbnail: 'https://i.ytimg.com/vi/g0BgDHR4A4E/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'gD62XQtWTbY',
    title: 'How to Find and Properly Launch Sweepstakes Campaigns',
    duration: '2020-05-13T12:11:59Z',
    thumbnail: 'https://i.ytimg.com/vi/gD62XQtWTbY/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'U7FXLvq0at8',
    title: 'How To Clean Up Ripped Landing Pages - Live Example!',
    duration: '2020-04-29T07:52:36Z',
    thumbnail: 'https://i.ytimg.com/vi/U7FXLvq0at8/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'KG6rUx5yEMM',
    title: 'How to Quickly Build Landing Pages that Convert!',
    duration: '2020-04-14T20:12:55Z',
    thumbnail: 'https://i.ytimg.com/vi/KG6rUx5yEMM/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'I8F8RzEgTNU',
    title: 'Webinar Apr 7, 2020 - Campaign Automatic Optimization',
    duration: '2020-04-08T11:34:50Z',
    thumbnail: 'https://i.ytimg.com/vi/I8F8RzEgTNU/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'QCqBaMSYzMY',
    title: 'How to Automate Your Campaigns Optimization Process',
    duration: '2020-03-31T21:23:22Z',
    thumbnail: 'https://i.ytimg.com/vi/QCqBaMSYzMY/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'D6ajSgiDZTU',
    title: "Virtual Whitelists - How to Create Whitelist Campaigns When Traffic Sources Don't Allow It",
    duration: '2020-03-17T21:57:37Z',
    thumbnail: 'https://i.ytimg.com/vi/D6ajSgiDZTU/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: '6B3cjC7o5GE',
    title: 'Leveraging the Power of Automatic Optimization for Native Ads',
    duration: '2020-03-06T09:21:02Z',
    thumbnail: 'https://i.ytimg.com/vi/6B3cjC7o5GE/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'IEkZmo8UrAo',
    title: 'Native Ads Automatic Optimization Live Demo + Q&A',
    duration: '2020-01-21T22:51:42Z',
    thumbnail: 'https://i.ytimg.com/vi/IEkZmo8UrAo/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'OV6QE4Ie3bQ',
    title: 'Live Platform Demo + Q&A - January 10, 2020',
    duration: '2020-01-10T21:24:10Z',
    thumbnail: 'https://i.ytimg.com/vi/OV6QE4Ie3bQ/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'OD80i7jObOo',
    title: 'Webinar: How to Leverage the Power of Automation with Custom Conversions',
    duration: '2019-12-13T22:44:19Z',
    thumbnail: 'https://i.ytimg.com/vi/OD80i7jObOo/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'r7OFmDeX2Ek',
    title: 'Webinar: How to Leverage the Power of Automation with Custom Conversions',
    duration: '2019-12-13T20:32:44Z',
    thumbnail: 'https://i.ytimg.com/vi/r7OFmDeX2Ek/default.jpg',
    category: VideoCategory.WEBINAR,
  },
  {
    link: 'QDweIXWZa9E',
    title: 'TheOptimizer Native Full Demo Replay',
    duration: '2019-11-08T10:10:08Z',
    thumbnail: 'https://i.ytimg.com/vi/QDweIXWZa9E/default.jpg',
    category: VideoCategory.WEBINAR,
  },
];
