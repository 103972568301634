import Vue from 'vue';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import VueOptiTableLight from '@paperclicks/vue-opti-table-light';
import VueOptiSelectLight from 'vue-opti-select-light';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies';
import VueClipboards from 'vue-clipboards';
import VueApexCharts from 'vue-apexcharts';

/* Fusion Charts */
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';

import VueNotifications from 'vue-notifications';
import DateRangePicker from 'vue2-daterange-picker';
import miniToastr from 'mini-toastr';
import '@/loaders/plugins';

import { PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VeeValidate, {
  errorBagName: 'vee_errors', // change if property conflicts.
  fieldsBagName: 'vee_fields', // change if property conflicts.
});
Vue.use(VueOptiTableLight);
Vue.use(VueOptiSelectLight);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueClipboards);
Vue.use(DateRangePicker);
Vue.component('apexchart', VueApexCharts);

Vue.use(VueFusionCharts, FusionCharts, Charts, FusionTheme, TimeSeries);

// *************************************/
// Notifications
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn',
};
miniToastr.init({ types: toastTypes });

miniToastr.setIcon('error', 'img', { src: 'sh_static/notification/error.svg' });
miniToastr.setIcon('info', 'img', { src: 'sh_static/notification/info.svg' });
miniToastr.setIcon('warn', 'img', { src: 'sh_static/notification/warn.svg' });
miniToastr.setIcon('success', 'img', { src: 'sh_static/notification/success.svg' });

const toast = ({ title, message, type, timeout, cb }) => miniToastr[type](message, title, timeout, cb);

Vue.use(VueNotifications, {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
});
// **************************************/
