<template>
  <div v-if="!['GoogleAnalytics4', 'GoogleAnalytics', 'CSVSource', 'AssertiveYield'].includes(tracker.type.uniqueName)" id="config_account" class="account-config-container">
    <div class="account-config-header">
      <div class="account-config-header__title">
        <h2>{{ connectTitle }}</h2>
      </div>
      <div class="config-btn">
        <button class="secondary-button" v-if="hasConnection && isSearchFeed" @click="switchConfiguration">{{ showCurrentConfiguration ? 'Show' : 'Edit' }} configuration</button>
      </div>
      <div v-if="!customSubIds" class="select-accounts">
        <vue-opti-select-light
          class="optimizer-select w-100 mb-3"
          v-model="accountSelected"
          :options="$c_options"
          unique-key="id"
          label-key="name"
          :value="[accountId]"
          single
          :searchable="true"
          button-block
          :button-placeholder="`Select a ${trafficSourceName} configuration from ${tracker.type.uniqueName}`"
        />
      </div>
      <preloader v-if="preloader" margin="30px"></preloader>
      <div v-else-if="tokens" class="info-tokens">
        <div v-for="(token, index) in $c_token" :key="index">
          <p v-for="(value, key) in token" :key="key" :title="sources[index].name" :class="(!showCurrentConfiguration && hasConnection && isSearchFeed) ? 'hide' : ''">
            <template v-if="value.values ? value.values.length > 1 : ''">
              <template v-if="value.found">
                <i class="fa fa-check text-success" aria-hidden="true"></i> <b><code class="text-success">{{ value.values.join(' Or ') }}</code> Was Found!</b>
              </template>
              <template v-else>
                <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> <b><code>{{ value.values.join(' Or ') }}</code> Was Not Found!</b> {{ value.message }}
              </template>
            </template>
            <template v-else>
              <template v-if="value.found">
                <i class="fa fa-check text-success" aria-hidden="true"></i> <b><code class="text-success">{{ customSubIds ? value.tsVal: value.values[0] }}</code> Is Found!</b>
              </template>
              <template v-else>
                <template v-if="value.message">
                  <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> <b><code>{{ customSubIds ? value.tsVal: value.values[0] }}</code> Is Not Found!</b> <span>
                    {{ value.message }}
                  </span>
                </template>
              </template>
            </template>
          </p>
          <span v-if="!showCurrentConfiguration && hasConnection && isSearchFeed">
            <p v-for="(value, name, variablesEditTrackerIndex) in variablesEditTracker[0]" :key="variablesEditTrackerIndex">
              <template v-if="name.includes('|')">
                <p v-for="n in name.split('|')" :key="n">
                  <i class="fa fa-check text-success" aria-hidden="true" /> <b><code class="text-success">{{ n }}</code> Was Found!</b>
                </p>
              </template>
              <template v-else-if="value && !name.includes('|')">
                <i class="fa fa-check text-success" aria-hidden="true" /> <b><code class="text-success">{{ name }}</code> Was Found!</b>
              </template>
            </p>
          </span>
          <!-- <i v-if="token.info" class="fa fa-info-circle" aria-hidden="true" v-b-tooltip="{hover: true, top: true, title: token.info, boundary: 'window'}"></i> -->
        </div>
      </div>
    </div>
    <div v-if="$c_items && !preloader" class="tokens">
      <div v-if="tracker.type.uniqueName != 'System1'" class="fieldsContainer">
        <div v-for="(item, key) in $c_items" :key="key" class="fieldRow">
          <b :class="(!customSubIds)?'fieldName':'fieldNameBlock'">{{ item.name }} :</b>
          <template v-if="!customSubIds">
            <span class="fieldInput">
              <b-form-input class="optimizer-form-input" type="text" size="sm" :formatter="$_format" v-model="item.value" @input="() => $_validateRequiredTokens()"></b-form-input>
            </span>
          </template>
          <template v-else>
            <div class="select-accounts">
              <vue-opti-select-light
                class="optimizer-select mb-3"
                :options="subOptions[key]"
                unique-key="id"
                label-key="name"
                single
                allowClear
                @clear="()=> { subSelected[key] = null }"
                :value="getSelectedValue(key)"
                button-block
                :disabled="!showCurrentConfiguration && hasConnection ? true : false"
                :button-placeholder="key"
                @change="({id}) => { checkIfPreviousId(id); subSelected[key] = id }"
              />
            </div>
          </template>
        </div>
      </div>
      <div v-if="allowsCustomTracking" class="fieldsContainer mt-3">
        <b-alert show variant="info" :style="{'font-size': '1.4rem'}">{{ 'Custom Tracking can be used when you do not have enough free subids for each traffic source token. In this case you can pass all the traffic source tokens in one single subid, using our custom format. Click on “Enable Custom Tracking” to use it.' }}</b-alert>
        <div class="fieldRow">
          <switch-button
            :checked="customTrackingEnabled"
            :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
            :update="(value) => $_enableCustomTracking(value)"
            class="mb-2"
          />
          <span class="mb-1">Enable Custom Tracking</span>
        </div>
        <div class="fieldRow">
          <template v-if="allowsCustomTracking">
            <span class="fieldInput">
              <b-form-input class="optimizer-form-input" @keyup="$_insertTokenFound" type="text" :placeholder="'Custom Tracking Token'" :disabled="!customTrackingEnabled" size="sm" :formatter="$_format" v-model="customTrackingToken"></b-form-input>
            </span>
          </template>
          <template>
            <vue-opti-select-light
              class="optimizer-select w-100 mb-2 ml-2"
              :options="subOptions['customTrackingToken']"
              unique-key="id"
              label-key="name"
              single
              :value="!showCurrentConfiguration && hasConnection ? variablesEditTracker[0][Object.keys(variablesEditTracker[0]).find((token) => token.includes('|'))] : subSelected['customTrackingToken']"
              button-block
              allowClear
              @clear="()=> { subSelected['customTrackingToken'] = null }"
              :disabled="!customTrackingEnabled || (!showCurrentConfiguration && hasConnection)"
              button-placeholder="Select Subid"
              @change="({id}) => { checkIfPreviousId(id); subSelected['customTrackingToken'] = id; }"
            />
          </template>
        </div>
        <div class="fieldRow" v-show="tracker.type.uniqueName === 'System1'">
          <template v-if="allowsCustomTracking">
            <span class="fieldInput">
              <b-form-input class="optimizer-form-input" @keyup="$_insertTokenFound" type="text" :placeholder="'Custom Tracking Token'" :disabled="!customTrackingEnabled" size="sm" :formatter="$_format" v-model="customTrackingToken1"></b-form-input>
            </span>
          </template>
          <template>
            <vue-opti-select-light
              class="optimizer-select w-100 mb-2 ml-2"
              :options="subOptions['customTrackingToken1']"
              unique-key="id"
              label-key="name"
              single
              :value="!showCurrentConfiguration && hasConnection ? variablesEditTracker[0][Object.keys(variablesEditTracker[0]).find((token) => token.includes('|'))] : subSelected['customTrackingToken1']"
              button-block
              allowClear
              @clear="()=> { subSelected['customTrackingToken1'] = null }"
              :disabled="!customTrackingEnabled"
              button-placeholder="Select Subid"
              @change="({id}) => { checkIfPreviousId(id); subSelected['customTrackingToken1'] = id; }"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="['CSVSource'].includes(tracker.type.uniqueName)" class="account-config-container">
    <div class="account-config-header col-md-12 text-muted mb-4 p-4">
      <i
        class="fa fa-info-circle"
        aria-hidden="true"
      /> Please make sure that the Google Sheets document respects the column naming convention as described in<b-link @click="$_openArticle()"> this article </b-link>.
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Preloader from '@sh/components/Utils/Preloader.vue';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'SourceTokens',
  components: {
    Preloader,
  },
  mixins: [notifications],
  props: {
    tracker: {
      type: Object,
      default: null,
      required: true,
    },
    sources: {
      type: Array,
      default: null,
      required: true,
    },
    accountId: null,
  },
  data() {
    return {
      accounts: {},
      tokens: {},
      accountSelected: null,
      valid: false,
      preloader: true,
      connectTitle: 'Connect accounts',
      subOptions: {},
      subSelected: {},
      customTrackingEnabled: false,
      customTrackingToken: '',
      customTrackingToken1: '',
      variablesEditTracker: [],
      tokenMapping: {},
      hasConnection: false,
      trafficSourceName: '',
      showCurrentConfiguration: false,
      isSearchFeed: null,
      swalContent: {
        isValid: {
          title: 'Are you sure to continue ?',
          html: 'The traffic source configuration found on your tracker is missing one or more of the required tracking tokens.<br>This may affect the accuracy of the statistics reported in TheOptimizer.',
          footer: '<a href="https://theoptimizer.io/help" target="_blank">Click here to learn more on How to fix missing tokens?</a>',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Yes, continue!',
          cancelButtonText: 'No',
        },
        isCampaignTokenSelected: {
          title: 'Campaign Token is required!',
          html: 'You cannot complete the connection if the campaign token field is not selected.',
          footer: '',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: '',
          cancelButtonText: 'Ok',
        }
      }
    };
  },
  computed: {
    $c_options() {
      const options = this.accounts.results || [];
      // Set value here only for multiple watch purpose
      if (this.accountId) {
        const selectedAccount = options.find(item => item.id === this.accountId)
        this.accountSelected = selectedAccount || null
      }
      return options
    },
    $c_items() {
      const fields = {};
      const subSelected = {};
      if (this.accountSelected === null) {
        if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
          if (this.tokens.length > 0) this.tokens?.forEach((token) => {
            for (const key in token) {
              fields[key] = {
                name: token[key].message,
                value: null,
              }
            }
          })
          return fields;
        }
        return null;
      }
      if (['FunnelFlux', 'FunnelFluxPro', 'Kintura'].includes(this.tracker.type.uniqueName)) {
        for (const key in this.accountSelected.variables) {
          fields[key] = {
            name: key,
            value: this.accountSelected
              .variables[key],
          };
        }
      } else {
        for (const key in this.accounts.fieldsName) {
          const value = typeof this.accountSelected.variables[key] !== 'undefined' ? this.accountSelected.variables[key] : '';
          fields[key] = {
            name: this.accounts.fieldsName[key],
            value,
          };
        }
      }

      return fields;
    },
    customSubIds() {
      if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
        this.accountSelected = null;
        return true;
      }
      return false;
    },
    $c_externalSource() {
      if (['CSVSource'].includes(this.tracker.type.uniqueName)) {
        this.accountSelected = null;
        return true;
      }
      return false;
    },
    allowsCustomTracking() {
      const allowCustomTrackingList = ['Tonic', 'Sedo', 'System1', 'Bodis', 'Ads', 'DomainActive'];
      return allowCustomTrackingList.includes(this.tracker.type.uniqueName);
    },
    $c_customTrackerSources() {
      const options = ['sid', 'snm', 'clkid', 'adttl', 'tscid', 'adid', 'secnm', 'cost', 'secid'];
      return options.map((token) => ({ name: token, id: token }));
    },
    $c_token() {
      if (this.tokens.length > 0) {
        return this.tokens.slice(0, 1);
      }
    },
    $c_campaignTokenNotEmpty() {
      if (this.tokens.length > 0) {
        return this.tokens[0].campaignIdToken?.found || this.tokens[0]?.campaignToken?.found;
      }
      return false;
    },
    $c_getSwalContent() {
      if (!this.$c_campaignTokenNotEmpty) {
        return this.swalContent.isCampaignTokenSelected;
      } else if (!this.valid) {
        return this.swalContent.isValid;
      }
    }
  },
  watch: {
    tracker: {
      immediate: true,
      async handler() {
        if (this.tracker.id) {
          this.preloader = true;
          let accounts;

          await this.getTrafficSourceConnected();

          if (this.customSubIds) {
            this.connectTitle = 'Connect tokens';
          }
          if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
            this.isSearchFeed = true;
          }
          if (this.tracker?.type?.uniqueName !== 'GoogleAnalytics4') {
            accounts = await this.$_getTrackerSources(this.tracker.id);
          }
          if (this.hasConnection) {
            const token = await this.$apiStore.trafficSources?.tokenMapping();
             this.tokenMapping = token;
          }
          if (accounts !== false) {
            this.accounts = accounts;
          } else {
            this.accounts = {};
          }
          this.preloader = false;
        }
      },
    },
    // async tracker () {
    //   if (this.tracker.id) {
    //     let accounts = await this.$_getTrackerSources(this.tracker.id)
    //     if (accounts !== false) {
    //       this.accounts = accounts
    //     } else {
    //       this.accounts = {}
    //     }
    //   }
    // },
    source: {
      immediate: true,
      async handler() {
        await this.getTrafficSourceConnected();
        if (this.sources) {
          this.preloader = true;
          // we multiple sources get connected, we only get the tokens from the first source since they have the same tokens
          const tokens = await this.getTokens(this.sources[0]);
          let subSelected = {};
          [tokens].forEach((token) => {
            for (const key in token) {
              token[key].found = false;
              if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Binom', 'Ads', 'BinomV2', 'Media'].includes(this.tracker.type.uniqueName)) {
                token[key].tsVal = token[key].values[0];
                subSelected[key] = null;
                if (this.allowsCustomTracking) {
                  subSelected['customTrackingToken'] = null;
                  subSelected['customTrackingToken1'] = null;
                }
              }
            }
          if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
            this.subSelected = subSelected;
          }
          if (this.allowsCustomTracking){
            const tokenLimit = ['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1'].includes(this.tracker.type.uniqueName);
            if (tokenLimit) {
              const tsTokensLen = Object.values(token).length;
              this.customTrackingToken = Object.values(token).slice(0, Math.ceil(tsTokensLen/2)).map(token => token.tsVal).join('|');
              this.customTrackingToken1 = Object.values(token).slice(Math.ceil(tsTokensLen/2), tsTokensLen).map(token => token.tsVal).join('|');
            } else {
              this.customTrackingToken = Object.values(token).map(token => token.tsVal).join('|');
            }
          }
          })
          this.preloader = false;
          this.tokens = [tokens];
        }
      },
    },
    // async source () {
    //   if (this.source) {
    //     let tokens = await this.$_getTokens(this.source.type.uniqueName)
    //     for (let key in tokens) {
    //       tokens[key].found = false
    //     }
    //     this.tokens = tokens
    //   }
    // },
    accountSelected() {
      this.$_validateRequiredTokens();
    },
    subSelected: {
      immediate: true,
      deep: true,
      async handler() {
        let tokens = this.tokens;
        Object.keys(this.subSelected).map(key => {
          tokens.forEach((token) => {
              Object.keys(token).reduce((acc, cKey) => {
                let c = token[cKey];
                if (this.subSelected[cKey] || (this.subSelected['customTrackingToken'] && this._hasIntersect(this.customTrackingToken.split("|"), c.values)) || (this.subSelected['customTrackingToken1'] && (this._hasIntersect(this.customTrackingToken1.split("|"), c.values)))) {
                  c.found = true;
                } else {
                  c.found = false;
                }
                return {...acc, [cKey]: c}
            }, {});
          })
        })
      }
    },
    valid(value) {
      this.$_emitByValidate();
    },
  },
  created() {
    if (this.tracker.type.uniqueName === 'System1'){
    this.customTrackingEnabled = true;
    }
    // console.info('Created: Tokens')
  },
  methods: {
    async validate() {
      if (!this.accountSelected && !this.customSubIds && !this.$c_externalSource) return false;
      if (this.$c_externalSource) {
        this.$_setExternalSourceTokens();
        return true;
      }
      this.$_validateRequiredTokens(this.customSubIds);
      if (!this.$c_campaignTokenNotEmpty || !this.valid) {
        try {
          const swalContent = this.$c_getSwalContent;
          const swal = await this.$swal({
            title: swalContent?.title || '',
            html: swalContent?.html || '',
            footer: swalContent?.footer || '',
            icon: swalContent?.icon || '',
            showCancelButton: swalContent?.showCancelButton || false,
            showConfirmButton: swalContent?.showConfirmButton || false,
            confirmButtonText: swalContent?.confirmButtonText || '',
            cancelButtonText: swalContent?.cancelButtonText || '',
          })
          if (swal.value === true) {
            return true;
          }
        } catch (value) { /* No nothing */ }
        return false;
      }
      return true;
    },
    async getTokens(source) {
      return this.$_getTokens(source.type.uniqueName);
    },
    checkIfPreviousId(id) {
      //if id selected previously, remove from selected and tokens state
      const prevIndx = Object.values(this.subSelected).findIndex(c => c === id);
      if(prevIndx > -1) {
        const prevToken = Object.keys(this.subSelected)[prevIndx];
        const newSubSelected = {...this.subSelected, [prevToken]: null};
        this.subSelected = newSubSelected;
      }
    },
    _hasIntersect(arr1, arr2){
      return !!arr1.filter((c) => arr2.includes(c)).length;
    },
    async getTrafficSourceConnected() {
      const query = { ...this.$route.query };
      const ts = await this.$apiStore.trafficSources.accounts();
      const findTs = ts.find((item) => item.id === parseInt(query.ts));
      const tracker = findTs?.linkedTrackers.find((tr) => tr.id === parseInt(query.tr));

      this.trafficSourceName = findTs.type.uniqueName;
      this.hasConnection = tracker === undefined ? false : true;
      this.variablesEditTracker = tracker?.variables;
    },
    async $_getTrackerSources(trackerId) {
      try {
        const request = await this.$api.trackers.sources(trackerId);
        return request || false;
      } catch (error) {
        if (this.tracker.type.uniqueName === 'Voluum') {
          let title = `We are not able to get the list of traffic sources from your ${this.tracker.type.uniqueName} account. If you are on the lowest ${this.tracker.type.uniqueName} plan, it has have some API limits, and you might need to upgrade to the next ${this.tracker.type.uniqueName} plan`
          this.$n_failNotification({ title });
          return false;
        } else {
          this.$n_failNotification({ title: `We are not able to get the list of traffic sources from your ${this.tracker.type.uniqueName} account. Please contact support for further assistance.` });
          return false;
        }
      }
    },
    async $_getTokens(type) {
      try {
        const request = await this.$api.trafficSources.tokens(type);
        if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
          const subOptions = this.generateSubOptions(request);
          this.subOptions = subOptions;
        }
        return request;
      } catch (error) {
        return false;
      }
    },
    $_enableCustomTracking(value){
      let newTokens = this.tokens;
      this.customTrackingEnabled = value;
      if(value){
        this.$_insertTokenFound();
      }else {
        // remove found from tokens
        Object.keys(newTokens).forEach(token => {
            newTokens[token].values = [];
            newTokens[token].found = false;
        })
        this.tokens = newTokens;
      }
    },
    $_insertTokenFound(){
      // insert token as found and disable select
      if(this.subSelected['customTrackingToken'] && this.customTrackingToken.includes('|')){
        let newTokens = {...this.tokens};
        const customTrackingToken = this.customTrackingToken.split("|");
        Object.keys(newTokens).forEach(token => {
          if(this._hasIntersect(customTrackingToken, this.tokens[token].values)){
            newTokens[token] = {...this.tokens[token], found : true};
          }
        })
        this.tokens = newTokens;
      }
      if (this.subSelected['customTrackingToken1'] && (this.customTrackingToken1).includes('|')) {
        let newTokens = {...this.tokens};
        const customTrackingToken = this.customTrackingToken1.split("|");
        Object.keys(newTokens).forEach(token => {
          if(this._hasIntersect(customTrackingToken,this.tokens[token].values)){
            newTokens[token].found = true;
          }
        })
        this.tokens = newTokens;
      }
    },
    $_validateRequiredTokens(customSubIds) {
      let valid = true;
      if (!customSubIds) {
        for (const key in this.tokens) {
        const allTokens = Object.values(this.tokens[key]);
        allTokens.forEach((t) => {
          t.found = false;
          for (const fieldKey in this.$c_items) {
            t.values.forEach((item, index) => {
              if (this.$c_items[fieldKey].value.toLowerCase() === item.trim().toLowerCase()) {
                t.found = true;
              }
            })
          }
          if (valid && !t.found) {
            valid = false;
          }
        });
        }
      } else {
        for (const key in this.tokens) {
          const allTokens = Object.values(this.tokens[key]);
          allTokens.forEach((t) => {
            if (!t.found) {
              valid = false;
            }
          })
        }
      }
      if (this.valid === valid) {
        this.$_emitByValidate();
      }
      this.valid = valid;
    },
    $_setExternalSourceTokens() {
      this.$_emitByValidate();
    },
    $_format(value, event) {
      return value.trim();
    },
    $_emitByValidate() {
      const customSubIds = ['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName);
      if (this.accountSelected === null && !customSubIds && !this.$c_externalSource) return false;
      const data = {
        variables: {},
        sourceIdOnTracker: !customSubIds ? this.accountSelected?.id : null,
      };
      if (this.$c_externalSource) {
        const tokensByType = {
          adgroup: 'AdGroup',
        };
        this.tokens.forEach((tokens) => {
          Object.keys(tokens).forEach(tokenKey => {
            const entity = tokensByType[tokenKey.replace('Token', '')] || _.capitalize(tokenKey.replace('Token', ''));
            data.variables[tokens[tokenKey].values[0]] = `TrafficSource${entity}Id`
          })
        });
      }
      if (!customSubIds) {
        for (const itemKey in this.$c_items) {
          if (this.$c_items[itemKey].value.trim() !== '') {
            data.variables[this.$c_items[itemKey].value] = itemKey;
          }
        }
      } else {
          for (const itemKey in this.subSelected) {
            if (itemKey==='customTrackingToken'){
              if (this.customTrackingToken && this.subSelected['customTrackingToken']) {
                data.variables[this.customTrackingToken] = this.subSelected['customTrackingToken'];
              }
            } else if (itemKey==='customTrackingToken1') {
              if (this.customTrackingToken1 && this.subSelected['customTrackingToken1']) {
                data.variables[this.customTrackingToken1] = this.subSelected['customTrackingToken1'];
              }
            } else {
              // dont allow mixed tracking variables for system1 - has 2 custom tracking tokens
              if ((!this.subSelected['customTrackingToken1'] && !this.subSelected['customTrackingToken']) || this.tracker.type.uniqueName != 'System1') {
                this.tokens.forEach((token) => {
                  data.variables[`${token[itemKey].tsVal}`] = this.subSelected[itemKey];
                })
              }
            }
          }
      }
      if (this.valid) {
        this.$emit('completed', data);
      } else {
        this.$emit('uncompleted', data, true);
      }
    },
    getTokenName(token) {
      if (['Everflow', 'Tonic', 'Sedo', 'DomainActive', 'CustomTracker', 'System1', 'Bodis', 'Ads', 'Media'].includes(this.tracker.type.uniqueName)) {
        return token.ts;
      }
      return token.values[0];
    },
    generateSubOptions(tokens) {
      const newOptions = {};
      let generateSubLength = [];
      // can be Everflow | Tonic | Sedo | System1 | Bodis
      const subValue = this.tracker.type.uniqueName === 'Tonic' || this.tracker.type.uniqueName === 'Bodis' ? 'subid' : this.tracker.type.uniqueName === 'DomainActive' ? 'tg' : 'sub';
      const subLength = this.tracker.type.uniqueName === 'Everflow' ? 5 : this.tracker.type.uniqueName === 'Sedo' ? 3 : this.tracker.type.uniqueName === 'DomainActive' ? 10 : 4 ;

      if (this.tracker.type.uniqueName === 'CustomTracker') {
        generateSubLength = this.$c_customTrackerSources;
      } else if (this.tracker.type.uniqueName === 'DomainActive') {
        generateSubLength.push({name: 'Campaign Number', id: 'campaign_number'},{name: 'Publisher', id: 'publisher'},{name: 'Ad ID', id: 'ad_id'})
        for (let i = 1; i <= subLength; i++) {
          generateSubLength.push({ name: `Tg ${i}`, id: `${subValue}${i}` })
        }
      } else if (this.tracker.type.uniqueName === 'System1') {
        generateSubLength.push({name: 'sub_id', id: 'sub_id'}, {name: 'sub_id2', id: 'sub_id2'})
      } else if (this.tracker.type.uniqueName === 'Bodis') {
        generateSubLength.push({name: 'subid1', id: 'subid1'}, {name: 'subid2', id: 'subid2'}, {name: 'subid3', id: 'subid3'}, {name: 'subid4', id: 'subid4'}, {name: 'subid5', id: 'subid5'});
      } else if (this.tracker.type.uniqueName === 'Tonic') {
        generateSubLength.push({name: 'subid1', id: 'subid1'}, {name: 'subid2', id: 'subid2'}, {name: 'subid3', id: 'subid3'}, {name: 'subid4', id: 'subid4'});
      } else if (this.tracker.type.uniqueName === 'Ads') {
        generateSubLength.push({name: 'subid1', id: 'subid1'}, {name: 'subid2', id: 'subid2'}, {name: 'subid3', id: 'subid3'}, {name: 'subid4', id: 'subid4'}, {name: 'subid5', id: 'subid5'});
      } else if (this.tracker.type.uniqueName === 'Media') {
        generateSubLength.push({ name: 'Channel', id: 'chnm' }, { name: 'Channel 2', id: 'chnm2' }, { name: 'Channel 3', id: 'chnm3' })
      } else {
        for (let i = 1; i <= subLength; i++) {
          generateSubLength.push({ name: `Sub ${i}`, id: `${subValue}${i}` })
        }
      }

      // add custom tracking token
      if(this.allowsCustomTracking){
        newOptions['customTrackingToken'] = [...generateSubLength];
        if (this.tracker.type.uniqueName === 'System1') {
          newOptions['customTrackingToken1'] = [...generateSubLength];
        }
      }

      for (const token in tokens) {
       newOptions[token] = [...generateSubLength];
      }
      return newOptions;
    },
    switchConfiguration() {
      if (this.showCurrentConfiguration) {
        this.showCurrentConfiguration = false;
      } else {
        this.showCurrentConfiguration = true;
      }
    },
    async $_openArticle() {
      const articleUrl = 'https://theoptimizer.io/help/knowledge-base/google-sheets-integration/';
      window.open(articleUrl, '_blank');
    },
    getSelectedValue(key) {
      return !this.showCurrentConfiguration && this.hasConnection ? this.variablesEditTracker?.[0]?.[this.tokenMapping?.[this.trafficSourceName]?.[key]] : this.subSelected?.[key];
    }
  },
};
</script>

<style lang="scss">
  .fieldsContainer {
    .select-accounts{
      width: 100%;
    }
  }

.account-config-container {
  .config-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem;
  }
  .account-config-header {
    padding-bottom: 1rem;

    &__title {
      padding: 1.5rem 2rem;
      border-bottom: .1rem solid $color-gray;
      text-align: center;
      font-weight: bold;
      font-size: 2.2rem;
    }

    .select-accounts {
      margin: 1.5rem 2rem;

      .optimizer-select {
        width: 60%;
      }
      .dropdown-menu .options-list {
        max-height: 27rem !important;
      }
    }

    .info-tokens {
      margin: 1.5rem 2rem;
      border: .1rem solid #e5e5e5;
      border-radius: .5rem;
      padding: 1rem;
      max-height: 348px;
      overflow-x: auto;
      p {
        font-size: 1.4rem;
        margin-bottom: .5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .tokens {
    padding: 0 2rem 1.5rem 2rem;

    .fieldsContainer {
      .fieldRow {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        .fieldNameBlock {
          width: 80%;
        }

        b {
          width: 30%;
          margin-right: 1rem;
        }

        .fieldInput {
          width: 100%;
          // flex: 1;
          // margin-left: 1.5rem;
        }
      }
    }
  }
}

.custom-width {
  width: 250px;
}
.hide {
  display: none;
}

// #config_account .tokens .fieldsContainer {
//   display: table;
// }
// #config_account .tokens .fieldRow {
//   display: table-row;
// }
// #config_account .tokens .fieldRow:hover {
//   display: table-row;
//   background-color: #eef1f5;
// }
// #config_account .tokens .fieldName {
//   display: table-cell;
//   width: 1%;
//   white-space: nowrap;
//   padding: 5px;
// }
// #config_account .tokens .fieldInput {
//   display: table-cell;
//   padding: 5px;
// }
// #config_account .tokens .fieldDropdown {
//   display: table-cell;
//   padding: 5px;
//   width: 100%;
//   vertical-align: middle;
// }
// #config_account .tokens .fieldDropdown  >select {
//   height: 35px;
// }

// #config_account .tokens {
//   overflow-y: scroll;
//   height: 243px;
// }
// #config_account .info-tokens .card-body {
//   padding: 0.7rem;
// }
// #config_account .tokens .fieldNameBlock {
//   display: block;
//   padding: 5px;
//   width: 150px;
// }

// #config_account .tokens .subFieldName {
//     color: gray;
//     margin-left: 5px;
//     margin-top: -3px;
//     font-size: 12px;
// }
</style>
