<template>
  <div class="loading-wrapper">
    <div v-show="loading" class="overlay">
      <pulse-loader :loading="loading" class="loader"></pulse-loader>
    </div>
    <slot />
  </div>
</template>

<style scoped>
.loading-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: 100%;
}

.loader {
    position: absolute;
    z-index: 9999999;
    width: 100px;
    left: calc(50% - 30px);
    top: calc(50% - 20px);
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(243, 243, 243, 0.5);
    z-index: 999;
    cursor: wait;
}
</style>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'Loading',
  components: {
    PulseLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
