<template>
  <b-input-group class="campaign-select-form">
    <v-select
      ref="select"
      v-model="model"
      label="label"
      :max-height="maxHeight"
      :placeholder="placeholder"
      class="campaign-select-wrapper form-control multiselect test-style"
      :close-on-select="false"
      :clear-search-on-select="false"
      :multiple="true"
      :options="options"
      @search="$_onSearch"
      :filterable="false"
      @search:blur="$_onBlur"
      @search:focus="$_onFocus"
    >
      <template slot="option" slot-scope="option">
        <span v-html="$_getOptionLabel(option)"></span>
      </template>
      <template slot="selected-option" slot-scope="option">
        <span v-html="$_getOptionLabel(option)"></span>
      </template>
    </v-select>
  </b-input-group>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TrafficSourceSelect',
  props: {
    value: { type: Array, default() { return []; } },
    options: { type: Array, default() { return []; }, required: true },
    uniqueName: { type: String, default: 'uniqueName' },
    placeholder: { type: String, default: 'Search Traffic source...' },
    maxHeight: { type: String, default: '400px !important' },
    path: { type: String, default: '/sh_static/img/accounts/' },
  },
  data() {
    return {
      model: [],
      emit: false, // Skip first input emit
      localOptions: [],
      filteredOptions: [],
      searchModel: '',
    };
  },
  computed: {
    $c_searchResult() {
      const formatedSearch = this.searchModel.toLowerCase();
      if (formatedSearch.trim() === '') return [];
      return this.localOptions.filter((item) => item.name.toLowerCase().indexOf(formatedSearch) > -1);
    },
  },
  watch: {
    model(value) {
      if (this.emit) {
        this.$emit('input', value);
      } else {
        this.emit = true;
      }
    },
  },
  created() {
    this.localOptions = this.options.applyToCountryBids;
    this.filteredOptions = this.localOptions;
    // this.model = this.applyToCountryBidsValue
    this.model = this.value;
  },
  methods: {
    $_onBlur() {
      this.$emit('blur');
    },
    $_getOptionLabel(option) {
      return `<div class="iOption true"><img src="${this.path + option.label}.png"/><span class="option-label">${option.label}</span></div>`;
    },
    $_onSearch(search) {
      this.$_search(search, this);
    },
    $_search: _.debounce((search, vm) => {
      search = search.toLowerCase();
      if (search === '') {
        vm.filteredOptions = vm.localOptions;
      } else {
        vm.filteredOptions = vm.localOptions.filter((item) => item.name.toLowerCase().indexOf(search) > -1);
      }
      vm.$_dropdownRenderItems(true);
    }, 500),
    $_onFocus() {
      this.$nextTick(() => {
        const ul = this.$refs.select.$el.querySelector('.vs__dropdown-menu');
        ul.onscroll = () => {
          this.$_dropdownRenderItems();
        };
        this.$_dropdownRenderItems();
      });
    },
    $_selectItem(item, value) {
      if (typeof item.type !== 'undefined' && typeof item.enabled !== 'undefined') {
        // Add Enabled or Disabled by SourceType
        this.model = this.model.filter((row) => this.$_enabled(row) !== item.enabled || row.traffic_source_unique_name !== item.type);
        if (value) this.model.push(...this.localOptions.filter((row) => this.$_enabled(row) === item.enabled && row.traffic_source_unique_name === item.type));
      } else if (typeof item.type !== 'undefined' && typeof item.enabled === 'undefined') {
        // Add All by SourceType
        this.model = this.model.filter((row) => row.traffic_source_unique_name !== item.type);
        if (value) this.model.push(...this.localOptions.filter((row) => row.traffic_source_unique_name === item.type));
      } else if (typeof item.type === 'undefined' && typeof item.enabled === 'undefined') {
        // Add All
        this.model = value ? this.localOptions : [];
      } else if (typeof item.type === 'undefined' && typeof item.enabled !== 'undefined') {
        // Add All Enabled or Disabled
        this.model = this.model.filter((row) => this.$_enabled(row) !== item.enabled);
        if (value) this.model.push(...this.localOptions.filter((row) => this.$_enabled(row) === item.enabled));
      }
    },
    $_clearForm() {
      this.model = [];
      this.$c_platformFilter.forEach((item) => { item.selected = false; });
    },
    $_addSearchCampaigns() {
      const modelMap = {};
      this.model.forEach((item) => { modelMap[item.id] = item; });
      this.$c_searchResult.forEach((item) => {
        if (!modelMap[item.id]) this.model.push(item);
      });
    },
    $_dropdownHidden() {
      this.searchModel = '';
    },
    $_dropdownRenderItems(reset = false) {
      // const ul = this.$refs.select.$el.querySelector('.vs__dropdown-menu');
      // const { scrollTop } = ul;
      if (reset) this.options = [];
      else this.$emit('getTSList');
    },
    $_enabled(row) {
      try {
        const value = this.statusConfig.values[row[this.statusConfig.field]];
        return typeof value === 'boolean' ? value : null;
      } catch (err) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss">
  .test-style {
    z-index: 999 !important;
  }
</style>
