<template>
  <b-input-group class="campaign-select-container-light w-100">
    <vue-opti-select-light
      ref="accountsDropdown"
      class="optimizer-select campaign-optimizer-select max-height-200 flex-grow-1"
      :options="options"
      v-model="model"
      unique-key="id"
      label-key="name"
      button-type="tag"
      option-type="checkbox"
      button-placeholder="Search account..."
      @change="(value) => { $emit('input', value) }"
      :disabled="disabled"
      button-block
      debounce
      searchable
    >
      <template v-if="options.length" #HEADER>
        <div class="mb-12 mx-12">
          <b-btn
            class="secondary-button fs-14 fw-700 lh-16 mr-8 py-9 px-24"
            @click="onSelectAllClick"
          >
            Select All
          </b-btn>
          <b-btn
            class="secondary-button fs-14 fw-700 lh-16 mr-8 py-9 px-24"
            @click="onClearAllClick"
          >
            Clear
          </b-btn>
        </div>
      </template>
      <template #TAG_LABEL="{ option }">
        <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.type.uniqueName)" :alt="option.type.uniqueName" />
        <div class="acc-item-name">
          {{ option.name }}
        </div>
      </template>
      <template #ITEM="{ option }">
        <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.type.uniqueName)" :alt="option.type.uniqueName" />
        <div class="acc-item-name">
          {{ option.name }}
        </div>
      </template>
    </vue-opti-select-light>
  </b-input-group>
</template>

<script>
import { getTrafficSourceLogo } from '@sh/helpers';

export default {
  name: 'TrafficSourceAccountSelectLight',
  props: { // some unused props
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    enableFilter: { type: Boolean, default: true },
    trafficSourceFilter: { type: Array, default: () => [] },
    trackerAccountFilter: { type: Array, default: () => [] },
    customFilter: { type: [Object, null], default: null },
    enableFilterAllByType: { type: Boolean, default: false },
    propellerBidType: { type: String, default: null },
  },
  data() {
    return {
      model: [],
      stats: [],
      search: '',
      searchModel: '',
      statsLoading: false,
      preloader: true, // Unused
      options: [],
    };
  },
  computed: {
    $c_searchModel: {
      get() {
        return this.searchModel;
      },
      set(value) {
        // Debounce set value
        this.$options.debounceSetSearchFunction(value);
      },
    },
  },
  async created() {
    this.options = await this.getOptions();
    this.$watch('value', (value) => {
      this.model = value;
    }, { immediate: true });
  },
  methods: {
    getTrafficSourceLogo,
    async $_selectItem(item, value) {
      if (item.ids.length) {
        if (value) {
          const accounts = await this.$_getAccountsByIds(item.ids);
          this.$emit('input', [...this.model, ...accounts]);
        } else {
          this.$refs.accountsDropdown.remove(item.ids);
          this.$emit('input', this.model);
        }
      }
    },
    $_getItemContent(text, type) {
      return type ? `<span class="filter-item"><img src="/sh_static/accounts/${type}.png"/> ${text}</span>` : `<span class="filter-item">${text}</span>`;
    },
    // eslint-disable-next-line no-unused-vars
    async getOptions() {
      const accounts = await this.$apiStore.trafficSources.accounts();
      // active accounts only
      const activeAccounts = accounts.filter((acc) => acc.status);
      // filter accounts by ts types and search
      return activeAccounts.filter((acc) => this.trafficSourceFilter.includes(acc.type.uniqueName));
    },
    async $_getAccountsByIds(ids = []) {
      if (ids.length) {
        const accounts = await this.$apiStore.trafficSources.accounts();
        // filter accounts by ids
        return accounts.filter((acc) => ids.includes(acc.id));
      }
      return [];
    },
    onClearAllClick() {
      this.$refs.accountsDropdown?.clear();
      this.$refs.accountsDropdown?.hide();
    },
    onSelectAllClick() {
      this.$refs.accountsDropdown?.selectAll();
      this.$refs.accountsDropdown?.hide();
    },
  },
};
</script>

<style lang="scss">
.campaign-select-container-light .optimizer-select.max-height-200 .dropdown-menu .options-list {
  max-height: 20rem !important;
}
</style>
