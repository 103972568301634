var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item.external)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
    customClass: `custom-tooltip ${!_vm.collapsed && 'hidden'}`,
    title: _vm.item.label,
    boundary: 'viewport',
    'boundary-padding': 10,
  }),expression:"{\n    customClass: `custom-tooltip ${!collapsed && 'hidden'}`,\n    title: item.label,\n    boundary: 'viewport',\n    'boundary-padding': 10,\n  }",modifiers:{"hover":true,"right":true}}],staticClass:"op-sidebar__item",attrs:{"id":_vm.item.id,"href":_vm.item.url,"target":"_blank"}},[_c(_setup.OptimizerIconV2,{attrs:{"type":_vm.item.icon.type,"fill":_vm.item.icon.fill,"height":22}}),_c('span',[_vm._v(_vm._s(_vm.item.label))])],1):_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
    customClass: `custom-tooltip ${!_vm.collapsed && 'hidden'}`,
    title: _vm.item.label,
    boundary: 'viewport',
    'boundary-padding': 10,
  }),expression:"{\n    customClass: `custom-tooltip ${!collapsed && 'hidden'}`,\n    title: item.label,\n    boundary: 'viewport',\n    'boundary-padding': 10,\n  }",modifiers:{"hover":true,"right":true}}],staticClass:"op-sidebar__item",class:{ active: _setup.isActive },attrs:{"id":_vm.item.id,"to":_vm.item.url}},[_c(_setup.OptimizerIconV2,{attrs:{"type":_vm.item.icon.type,"fill":_vm.item.icon.fill,"height":22}}),_c('span',[_vm._v(_vm._s(_vm.item.label))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }