import Joi from 'joi';

export type RequiredLiteralKeys<T> = keyof {
  [K in keyof T as string extends K ? never : number extends K ? never : {} extends Pick<T, K> ? never : K]: 0;
};

export type IndexKeys<T> = string extends keyof T ? string : number extends keyof T ? number : never;

export type OptionalLiteralKeys<T> = keyof {
  [K in keyof T as string extends K ? never : number extends K ? never : {} extends Pick<T, K> ? K : never]: 0;
};

export interface FieldConfiguration {
  isActive: boolean | ((...args: any) => boolean);
  validation: Joi.Schema;
  default?: any;
  fieldGroup?: string;
  key?: string;
  readableName?: string;
  interchangeableGroup?: string;
  ab?: boolean;
  macros?: Array<{ macro: string; type: string; valueType: string; suffix: string }>;
  objectValidation?: Joi.Schema;
  renderFunction?: (value: any) => string;
}
export enum MacroType {
  Value = 'Value',
  VariableName = 'VariableName',
  GroupName = 'GroupName',
  TextValue = 'TextValue',
  CurrentDate = 'CurrentDate',
  CurrentTime = 'CurrentTime',
  SubEntityName = 'SubEntityName',
  SubEntityNumber = 'SubEntityNumber',
}

export const DefaultMacros = [
  { macro: 'VARIABLE_VALUE', valueType: MacroType.Value, type: MacroType.Value, suffix: 'Value' },
  { macro: 'VARIABLE_NAME', valueType: MacroType.VariableName, type: MacroType.VariableName, suffix: 'Variable Name' },
];

export const DefaultGroupMacros = [
  { macro: 'VARIABLE_NAME', valueType: MacroType.GroupName, type: MacroType.GroupName, suffix: 'Group Name' },
];

export const EntityMacros = [
  {
    macro: 'CURRENT_DATE',
    valueType: MacroType.CurrentDate,
    type: MacroType.CurrentDate,
    suffix: 'Current Date (YYYY-MM-DD)',
  },
  {
    macro: 'CURRENT_TIME',
    valueType: MacroType.CurrentTime,
    type: MacroType.CurrentTime,
    suffix: 'Current Time (HH:MM)',
  },
];

export const AdSetEntityMacros = [
  ...EntityMacros,
  {
    macro: 'AD_NUMBER',
    valueType: MacroType.SubEntityNumber,
    type: MacroType.SubEntityNumber,
    suffix: 'Number of Ads',
  },
  { macro: 'AD_NAME', valueType: MacroType.SubEntityName, type: MacroType.SubEntityName, suffix: 'Ads Name' },
];

export const CampaignEntityMacros = [
  ...EntityMacros,
  {
    macro: 'ADSET_NUMBER',
    valueType: MacroType.SubEntityNumber,
    type: MacroType.SubEntityNumber,
    suffix: 'Number of Ad Sets',
  },
  { macro: 'ADSET_NAME', valueType: MacroType.SubEntityName, type: MacroType.SubEntityName, suffix: 'Ad Set Name' },
];
