<template>
  <div class="content-wrapper rule-container has-fixed-footer">
    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">
          <template>{{ $_getHeaderText() }}</template>
        </h1>
      </div>
    </div>

    <preloader v-if="preloaderSubmit"></preloader>
    <div v-show="!preloaderSubmit">
      <preloader v-if="preloader"></preloader>
      <div v-else class="rule-container__form">
        <b-form @submit.prevent="$_validateBeforeSubmit">

          <!-- RULE SECTION -->
          <div class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter(true) }}</span>
              <h1>Rule: <span class="form-section__title__rule-name">{{ ruleModel.rule.content }}</span></h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 4" :key="item" :class="[ item > 2 ? '' : 'mb-5', 'col-md-6']">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!--RULE NAME-->
                <div v-if="ruleModel.hasComponent('name')" class="col-md-6">
                  <RuleName v-model="ruleModel.rule.components.name.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('name')" />
                </div>

                <!--RULE TEMPLATE DESCRIPTION-->
                <div v-if="ruleModel.hasComponent('template_description')" class="col-md-6">
                  <RuleTemplateDescription v-model="ruleModel.rule.components.template_description.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('template_description')" />
                </div>

                <!--RULE TEMPLATE TAG-->
                <div v-if="ruleModel.hasComponent('template_tag')" class="col-md-6">
                  <RuleTemplateTag v-model="ruleModel.rule.components.template_tag.value" :config="config" :mode="mode" :rule-model="ruleModel" :tag-options="options.tags" @openModal="$refs.newTagModal.show()" :ref="$_getComponentRefName('template_tag')" />
                </div>

                <!--RULE GROUP-->
                <div v-if="ruleModel.hasComponent('groups')" class="col-md-6">
                  <RuleGroup v-model="ruleModel.rule.components.groups.value" :config="config" :mode="mode" :rule-model="ruleModel" :group-options="options.groups" @openModal="$refs.newGroupModal.show()" :ref="$_getComponentRefName('groups')" />
                </div>

                <!-- RULE INTERVAL -->
                <div v-if="ruleModel.hasComponent('interval')" class="col-md-12">
                  <RuleInterval v-model="ruleModel.rule.components.interval.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('interval')" />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE CONDITIONS SECTION -->
          <div v-if="ruleModel.hasComponent('conditions')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Conditions</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!--RULE CONDITIONS-->
                <div class="col-md-12">
                  <RuleConditions v-model="ruleModel.rule.components.conditions.value"
                                  :config="config" :mode="mode" :rule-model="ruleModel"
                                  :ref="$_getComponentRefName('conditions')"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE BID SECTION-->
          <div v-if="ruleModel.hasComponent('bid') || ruleModel.hasComponent('budget') || ruleModel.hasComponent('coefficient') || ruleModel.hasComponent('bidCad') || ruleModel.hasComponent('bidModifier')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Action</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 2" :key="item" :class="[ item > 1 ? '' : 'mb-5', 'col-md-12']">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE BID -->
                <div v-if="ruleModel.hasComponent('bid')" class="col-md-12">
                  <RuleBid v-model="ruleModel.rule.components.bid.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('bid')" :showResetStrategy="$c_showResetStrategy" />
                </div>

                <!-- RULE BUDGET -->
                <div v-if="ruleModel.hasComponent('budget')" class="col-md-12">
                  <RuleBudget v-model="ruleModel.rule.components.budget.value" :actionOptions="config.budgetConfig.otherBudgetActionOptions" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('budget')" />
                </div>

                <!-- RULE COEFFICIENT -->
                <div v-if="ruleModel.hasComponent('coefficient')" class="col-md-12">
                  <RuleCoefficient v-model="ruleModel.rule.components.coefficient.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('coefficient')" />
                </div>

                <!-- RULE BID CONTENTAD -->
                <div v-if="ruleModel.hasComponent('bidCad')" class="col-md-12">
                  <RuleBidCad v-model="ruleModel.rule.components.bidCad.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('bidCad')" />
                </div>

                <!-- RULE BID MODIFIER -->
                <div v-if="ruleModel.hasComponent('bidModifier')" class="col-md-12">
                  <RuleBidModifier v-model="ruleModel.rule.components.bidModifier.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('bidModifier')" />
                </div>

                <div v-if="ruleModel.hasComponent('ruleWithScheduledAdGroup')" class="col-md-12">
                  <RuleWithScheduledAdGroup v-model="ruleModel.rule.components.ruleWithScheduledAdGroup.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('ruleWithScheduledAdGroup')" />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE TARGET CPA SECTION -->
          <div v-if="ruleModel.hasComponent('targetCpa')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Action</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 2" :key="item" :class="[ item > 1 ? '' : 'mb-5', 'col-md-12']">
                  <p>{{ item }}
                    <loading-skeleton class="optimizer-form-skeleton" type="form" />
                  </p></div>
              </div>

              <div v-else class="row">
                <div class="col-md-12">
                  <RuleTargetCpa v-model="ruleModel.rule.components.targetCpa.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('targetCpa')" :showResetStrategy="$c_showResetStrategy" />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE BID CAP SECTION -->
          <div v-if="ruleModel.hasComponent('bidCap')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Action</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 2" :key="item" :class="[ item > 1 ? '' : 'mb-5', 'col-md-12']">
                  <p>{{ item }}
                    <loading-skeleton class="optimizer-form-skeleton" type="form" />
                  </p></div>
              </div>

              <div v-else class="row">
                <div class="col-md-12">
                  <RuleBidCap v-model="ruleModel.rule.components.bidCap.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('bidCap')" :showResetStrategy="$c_showResetStrategy" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="ruleModel.hasComponent('targetRoas')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Action</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 2" :key="item" :class="[ item > 1 ? '' : 'mb-5', 'col-md-12']">
                  <p>{{ item }}
                    <loading-skeleton class="optimizer-form-skeleton" type="form" />
                  </p></div>
              </div>

              <div v-else class="row">
                <div class="col-md-12">
                  <RuleTargetRoas v-model="ruleModel.rule.components.targetRoas.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('targetRoas')" :showResetStrategy="$c_showResetStrategy" />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE WIDGETS FILTERING SECTION-->
          <div v-if="ruleModel.hasComponent('widgetsExclude')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>{{ ruleModel.rule.components.widgetsExclude.label }} Filtering</h1>
              <i class="fa fa-info-circle ml-2" style="cursor: pointer" v-b-popover.html.hover="$_getWidgetFilteringInfo(ruleModel.rule.components.widgetsExclude.label)"></i>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE WIDGETS EXCLUDE -->
                <div class="col-md-12">
                  <RuleWidgetsExclude v-model="ruleModel.rule.components.widgetsExclude.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('widgetsExclude')" />
                </div>
              </div>
            </div>
          </div>
          <!-- RULE AdGroup FILTERING SECTION-->
          <div v-if="ruleModel.hasComponent('entitySelector')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>{{ ruleModel.rule.components.entitySelector.label }} Filtering</h1>
              <i class="fa fa-info-circle ml-2" style="cursor: pointer" v-b-popover.html.hover="$_getWidgetFilteringInfo(ruleModel.rule.components.entitySelector.label)"></i>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE Entity Selector  :filter="$c_campaignFilter"  -->
                <div v-if="ruleModel.hasComponent('entitySelector')" class="col-md-12">
                  <RuleAdGroupSelector v-model="ruleModel.rule.components.entitySelector.value" :label="ruleModel.rule.components.entitySelector.label" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('entitySelector')" :isError="adGroupsError" />
                </div>
              </div>
            </div>
          </div>
          <!-- RULE Filter By parent SECTION-->
          <div v-if="ruleModel.hasComponent('parentWhitelist')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Filter by {{ ruleModel.rule.components.parentWhitelist.label }}</h1>
              <i class="fa fa-info-circle ml-2" style="cursor: pointer" v-b-popover.html.hover="$_getFilterByParentInfo(ruleModel.rule.components.parentWhitelist.label)"></i>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <div v-if="ruleModel.hasComponent('parentWhitelist')" class="col-md-12">
                  <RuleParentWhitelist v-model="ruleModel.rule.components.parentWhitelist.value" :label="ruleModel.rule.components.parentWhitelist.label" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('parentWhitelist')" />
                </div>
              </div>
            </div>
          </div>
          <!-- RULE ACTION SECTION -->
          <div v-if="ruleModel.hasComponent('campaign_action')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Rule Action</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE ACTION CAMPAIGN -->
                <div class="col-md-12">
                  <RuleActionCampaign v-model="ruleModel.rule.components.campaign_action.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('campaign_action')" />
                </div>
              </div>
            </div>
          </div>

          <!-- APPLY RULES TO CAMPAIGN SECTION-->
          <div v-if="ruleModel.hasComponent('campaigns')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Apply Rule</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE CAMPAIGNS -->
                <div v-if="ruleModel.hasComponent('campaigns')" class="col-md-12">
                  <RuleCampaigns v-model="ruleModel.rule.components.campaigns.value" :config="config" :mode="mode" :rule-model="ruleModel" :filter="$c_campaignFilter" :ref="$_getComponentRefName('campaigns')" />
                </div>

                <!-- RULE COUNTRY BIDS -->
                <div v-if="ruleModel.hasComponent('applyToCountryBids')" class="col-md-12">
                  <RuleCountryBids v-model="ruleModel.rule.components.applyToCountryBids.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('applyToCountryBids')" />
                </div>

                <!-- RULE ADGROUP -->
                <div v-if="ruleModel.hasComponent('adgroup')" class="col-md-12">
                  <RuleAdGroup v-model="ruleModel.rule.components.adgroup.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('adgroup')" />
                </div>

                <!-- RULE WIDGET -->
                <div v-if="ruleModel.hasComponent('widget')" class="col-md-12">
                  <RuleWidget v-model="ruleModel.rule.components.widget.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('widget')" />
                </div>

                <!-- RULE SPOT -->
                <div v-if="ruleModel.hasComponent('spot')" class="col-md-12">
                  <RuleSpot v-model="ruleModel.rule.components.spot.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('spot')" />
                </div>

                <!-- RULE FLAGS -->
                <div v-if="ruleModel.hasComponent('flags')" class="col-md-12">
                  <RuleFlags v-model="ruleModel.rule.components.flags.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('flags')" />
                </div>
              </div>
            </div>
          </div>

          <!-- APPLY RULES TO TRAFFIC SOURCES ACCOUNT SECTION-->
          <div v-if="ruleModel.hasComponent('accounts')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Apply Rule</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div class="col-md-12">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <!-- RULE Accounts -->
                <div v-if="ruleModel.hasComponent('accounts')" class="col-md-12">
                  <RuleAccounts v-model="ruleModel.rule.components.accounts.value" :config="config" :mode="mode" :rule-model="ruleModel" :filter="$c_campaignFilter" :ref="$_getComponentRefName('accounts')" />
                </div>

                <!-- RULE ADGROUP -->
                <div v-if="ruleModel.hasComponent('adgroup')" class="col-md-12">
                  <RuleAdGroup v-model="ruleModel.rule.components.adgroup.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('adgroup')" />
                </div>

                <!-- RULE WIDGET -->
                <div v-if="ruleModel.hasComponent('widget')" class="col-md-12">
                  <RuleWidget v-model="ruleModel.rule.components.widget.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('widget')" />
                </div>
              </div>
            </div>
          </div>

          <!-- RULE NUMBER OF COPIES SECTION -->
          <div v-if="ruleModel.hasComponent('numberOfCopies')" class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1 v-if="ruleModel.rule.components.numberOfCopies.title">{{ ruleModel.rule.components.numberOfCopies.title }}</h1>
              <h1 v-else>Copies</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 2" :key="item" :class="[ item > 1 ? '' : 'mb-5', 'col-md-12']">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">
                <div class="col-md-12">
                  <RuleNumberOfCopies :ref="$_getComponentRefName('numberOfCopies')"
                                      v-model="ruleModel.rule.components.numberOfCopies.value" :config="config" :mode="mode"
                                      :rule-model="ruleModel"
                                      :showResetStrategy="$c_showResetStrategy"
                  ></RuleNumberOfCopies>
                </div>
              </div>
            </div>
          </div>

          <!-- SCHEDULE RULE SECTION-->
          <div class="form-section">
            <div class="form-section__title">
              <span class="form-section__title__badge">{{ $_getCounter() }}</span>
              <h1>Schedule Rule</h1>
            </div>
            <div class="optimizer-card">

              <!-- Loading Skeleton -->
              <div v-if="preloader" class="row">
                <div v-for="item in 3" :key="item" :class="[ item > 2 ? '' : 'mb-5', 'col-md-6']">
                  <loading-skeleton class="optimizer-form-skeleton" type="form" />
                </div>
              </div>

              <div v-else class="row">

                <!-- RULE FREQUENCY -->
                <div v-if="ruleModel.hasComponent('rotation')" class="col-md-6">
                  <RuleRotation v-model="ruleModel.rule.components.rotation.value" :config="config"
                                :mode="mode" :rule-model="ruleModel" :isCloneRule="isCloneRule"
                                :ref="$_getComponentRefName('rotation')"
                  />
                </div>

                <div v-if="showTimeZone" class="col-md-6">
                  <template>
                    <!-- Input Timezone -->
                    <b-form-group class="optimizer-form-group">
                      <template slot="label">
                        Select the timezone <i class="fa fa-info-circle ml-2" style="cursor: pointer" v-b-popover.html.hover="$_getOnceDailyInfo"></i>
                      </template>
                      <vue-opti-select-light
                        class="optimizer-select w-100 mb-0"
                        data-vv-validate-on="input|update"
                        data-vv-value-path="$c_model"
                        name="timezone"
                        :value="ruleModel.timezone"
                        @change="(option) => { $_setTimezone(option.value) }"
                        :options="$c_timezones"
                        button-placeholder="Select timezone"
                        single
                        searchable
                        button-block
                      />
                      <span class="time-now ml-2 pt-1">( Current Time: {{ $options.momentTimezone.tz(timezone).format('hh:mm A') }} )</span>
                    </b-form-group>
                  </template>
                </div>

                <!-- RULE ALERT -->
                <div v-if="ruleModel.hasComponent('alert')" class="col-md-6">
                  <RuleAlert v-model="ruleModel.rule.components.alert.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('alert')" />
                </div>

                <!-- SWITCH TO EXECUTE RULES WITH PAUSED CMPS -->
                <div v-if="ruleModel.hasComponent('ruleWithPausedCampaigns')" class="col-md-12">
                  <RuleWithPausedCampaigns v-model="ruleModel.rule.components.ruleWithPausedCampaigns.value" :config="config" :mode="mode" :rule-model="ruleModel" :ref="$_getComponentRefName('ruleWithPausedCampaigns')" />
                </div>

                <!-- NOTIFICATIONS -->
                <div class="col-md-12">
                  <NotificationsSection :config="config" :mode="mode" :rule-model="ruleModel" />
                </div>
              </div>
            </div>
          </div>

          <!--RULE CONTROLS-->
          <div v-if="!preloader" class="fixed-footer">
            <div class="fixed-footer__actions d-flex justify-content-end">
              <b-btn class="secondary-button" @click.prevent="$router.go(-1)">Back</b-btn>
              <!-- <b-btn variant="warning" v-if="$platform === 'native' && ruleModel.rule.rule_action!=='day_parting'" @click="$_getRuleSimulationData()" style="display: none">Simulate</b-btn> -->
              <b-btn class="primary-button" type="submit">Save</b-btn>
            </div>
          </div>
        </b-form>
      </div>

    </div>

    <!-- Create Group modal -->
    <NewGroupModal ref="newGroupModal" @newGroup="$_onNewGroup" />

    <!-- Create Tag modal -->
    <NewTagModal ref="newTagModal" @newTag="$_onNewTag" />

    <!-- Create Rule Simulation Modal -->
    <!-- <RuleSimulationModal ref="ruleSimulationModal" @simulateRule="$_getRuleSimulationData()"></RuleSimulationModal> -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import RuleName from './components/RuleName.vue';
import RuleGroup from './components/RuleGroup.vue';
// import RuleSimulationModal from './components/RuleSimulationModal';
import RuleConditions from './components/RuleConditions';
import RuleInterval from './components/RuleInterval.vue';
import RuleCampaigns from './components/RuleCampaigns.vue';
import RuleAdGroupSelector from './components/RuleAdGroupSelector.vue';
import RuleCountryBids from './components/RuleCountryBids.vue';
import RuleRotation from './components/RuleRotation.vue';
import RuleWithPausedCampaigns from './components/RuleWithPausedCampaigns.vue';
import RuleWithScheduledAdGroup from './components/RuleWithScheduledAdGroup.vue';
import RuleAlert from './components/RuleAlert.vue';
import RuleAdGroup from './components/RuleAdGroup.vue';
import RuleWidget from './components/RuleWidget.vue';
import RuleSpot from './components/RuleSpot.vue';
import RuleActionCampaign from './components/RuleActionCampaign.vue';
import RuleFlags from './components/RuleFlags.vue';
import RuleWidgetsExclude from './components/RuleWidgetsExclude.vue';
import RuleParentWhitelist from './components/RuleParentSelector.vue';

import RuleBid from './components/RuleBid.vue';
import RuleBudget from './components/RuleBudget.vue';
import RuleCoefficient from './components/RuleCoefficient.vue';
import RuleBidCad from './components/RuleBidCad.vue';
import RuleBidModifier from './components/RuleBidModifier.vue';
import RuleTargetCpa from './components/RuleTargetCpa.vue';
import RuleTargetRoas from './components/RuleTargetRoas.vue';
import RuleBidCap from './components/RuleBidCap.vue';

import NewGroupModal from './components/NewGroupModal';
import NewTagModal from './components/NewTagModal';
import NotificationsSection from './components/NotificationsSection';
import notifications from '@sh/mixins/notifications';

import RuleTemplateDescription from './components/RuleTemplateDescription.vue';
import RuleTemplateTag from './components/RuleTemplateTag.vue';

import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import RuleAccounts from '@sh/views/Rules/components/RuleAccounts';
import SelectedRule from './other/ruleHelper';
import RuleNumberOfCopies from '@sh/views/Rules/components/RuleNumberOfCopies.vue';

import { mapWritableState } from 'pinia';
import { useRuleStore } from '@sh/views/Rules/store';
import { canAccess } from '@sh/helpers';
import { budgetConfigValid } from '../../configurations/fields/helpers';

export default {
  name: 'Rule',
  components: {
    RuleNumberOfCopies,
    RuleAccounts,
    RuleName,
    RuleGroup,
    // RuleSimulationModal,
    RuleConditions,
    RuleInterval,
    RuleCampaigns,
    RuleCountryBids,
    RuleRotation,
    RuleWithScheduledAdGroup,
    RuleWithPausedCampaigns,
    RuleAlert,
    RuleAdGroup,
    RuleWidget,
    RuleSpot,
    RuleActionCampaign,
    RuleFlags,
    RuleWidgetsExclude,
    RuleBid,
    RuleBidCap,
    RuleBudget,
    RuleCoefficient,
    RuleBidCad,
    RuleBidModifier,
    RuleTargetCpa,
    RuleTargetRoas,
    NewGroupModal,
    NewTagModal,
    NotificationsSection,
    RuleTemplateDescription,
    RuleTemplateTag,
    LoadingSkeleton,
    RuleAdGroupSelector,
    RuleParentWhitelist,
  },
  mixins: [notifications],
  props: {
    configFile: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  data() {
    return {
      preloader: true,
      showTimeZone: false,
      rotation: '',
      timezone: 'UTC',
      preloaderSubmit: false,
      adGroupsError: false,
      rulePayload: {},
      options: {
        groups: [],
        tags: [],
        timezones: moment.tz.names(),
      },
      env: process.env,
    };
  },
  computed: {
    isCloneRule() {
      return ['copy', 'copy_budget'].includes(this.ruleModel.rule.rule_action);
    },
    $c_showResetStrategy() {
      return this.ruleModel && ((this.ruleModel.rule.ts_types.includes('Taboola') && this.ruleModel.type === 'widget_change_bid') || (this.ruleModel.rule.ts_types.includes('Outbrain') && this.ruleModel.type === 'campaign_change_bid_cap'));
    },
    $c_campaignFilter() {
      let trackerId = null;
      // let payout = false
      const BreakException = {};
      try {
        if (this.ruleModel) {
          this.ruleModel.rule.components.conditions.value.forEach((row) => {
            const key = Object.keys(row)[0];
            const expKey = key.split('::');
            // eslint-disable-next-line prefer-destructuring
            if (!trackerId && expKey.length > 1) trackerId = expKey[1];
            // if (!payout && row[key].to === 'payout') payout = true
            if (trackerId) throw BreakException;
          });
        }
      } catch (err) {
        if (err !== BreakException) console.log(err);
      }
      // return { trackerId, payout }
      return { trackerId };
    },
    $c_ccTrackerId() {
      let id = null;
      try {
        const ccItem = this.ruleModel.rule.components.conditions.value.find((row) => {
          const key = Object.keys(row)[0];
          return key.split('::').length > 1;
        });
        if (ccItem) {
          const explodeKey = Object.keys(ccItem)[0].split('::');
          id = Number(explodeKey[1]);
        }
      } catch (err) {
        // console.log(err);
      }
      return id;
    },
    $c_timezones() {
      return this.options.timezones.map((value) => ({ value, content: value }));
    },
    ...mapWritableState(useRuleStore, ['ruleModel', 'config', 'canAccessCustomInterval', 'noAccessRuleFrequency']),
  },
  async created() {
    this.preloader = true;
    try {
      await this.$_init();
    } catch (error) {
      console.log(error);
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    }
    this.preloader = false;
    this.$options.momentTimezone = moment;
    this.canAccessCustomInterval = await canAccess('customDataIntervalRule');
    this.noAccessRuleFrequency = await canAccess('limitedRuleExecutionFrequency');
  },
  destroyed() {
    SelectedRule.setSelectedRule({});
  },
  methods: {
    afterCreate() {
      this.$router.push({ name: 'Rules' });
    },
    async $_init() {
      this.config = await this.configFile.getConfig();
      /** ************ Resolve Rule Type ************ */
      let type = '';
      let rule = {};
      const { templateId } = this.$route.query;
      let isGlobalRule = false;

      // Get Rule Type
      if (this.mode === 'create') {
        if (!templateId) {
          if (!this.$route.query.type) throw Error('Invalid Url Rule Type');
          type = this.$route.query.type;
        } else {
          rule = await this.config.api.rules.templates(templateId);
          if (!rule) throw Error('Template not found');
          type = `${rule.level.toLowerCase()}_${rule.rule_action_alias ? rule.rule_action_alias : rule.rule_action}`;
        }
        isGlobalRule = this.$route.query.is_global;
      } else {
        rule = await this.config.api.rules.get(this.$route.params.id);
        isGlobalRule = rule.is_global;
        type = `${rule.level.toLowerCase()}_${rule.rule_action_alias ? rule.rule_action_alias : rule.rule_action}`;
        // Update RecentlySearch
        this.$settings.recentlySearch.setItem('rules', `rule-${rule._id}`);
      }
      this.rulePayload = rule;
      this.ruleModel = new this.config.RuleModel(type, isGlobalRule);
      /** ******************************************* */
      /** *********** Update available fields base on rule ******* */

      if (SelectedRule.getSelectedRule() && Object.keys(SelectedRule.getSelectedRule()).length !== 0) {
        const selectedRuleType = SelectedRule.getSelectedRule();
        this.ruleModel.rule.ts_types = selectedRuleType.ts_types;
      }

      this.config = await this.configFile.getConfig({ trafficSources: this.ruleModel.rule.ts_types, level: this.ruleModel.rule.level });
      // TEMPORARY FIX
      if (!type.includes('widget_change_bid') && this.config.bidConfig) {
        this.config.bidConfig.bidToOptions = this.config.bidConfig.bidToOptions.filter((item) => item.value !== 'avg_cpc' && item.value !== 'campaign_avg_cpc');
      }
      /** ******** Create Promises Function ********* */
      // Doing this way to gain parallel performance with Promise All
      let campaignsPromise = () => { /** Do nothing */ };
      /** ******************************************* */

      /** ************* Set Rule Values ************* */
      if (this.mode === 'edit' || this.mode === 'clone') {
        this.ruleModel.setRuleData(rule, this.mode);
        if (this.ruleModel.hasComponent('campaigns')) {
          campaignsPromise = async () => {
            const ids = this.ruleModel.rule.components.campaigns.value;

            if (ids?.length) {
              const { data } = await this.config.api.campaigns.getByFilters({ ids, pageSize: 1000000 });

              if (data?.length) {
                this.ruleModel.rule.components.campaigns.value = data;
              }
            }
          };
        }
        if (this.ruleModel.hasComponent('accounts')) {
          const ids = this.ruleModel.rule.components.accounts.value;
          const accounts = await this.$apiStore.trafficSources.accounts();
          // filter accounts by ids
          const data = accounts.filter((acc) => ids.includes(acc.id.toString()));
          if (data && data.length) {
            this.ruleModel.rule.components.accounts.value = data;
          }
        }
        if (this.ruleModel.hasComponent('entitySelector')) {
          const { exclude_widgets, is_whitelist } = rule;
          if (exclude_widgets && exclude_widgets.length) {
            const { adgroups } = await this.config.api.campaigns.getAllAdGroups({ ids: [...new Set(exclude_widgets.map((e) => e.id))] });
            if (adgroups.data && adgroups.data.length) {
              this.ruleModel.rule.components.entitySelector.value = { entities: adgroups.data, type: is_whitelist ? { value: 'INCLUDE', content: 'Include' } : { value: 'EXCLUDE', content: 'Exclude' } };
            }
          }
        }
        if (this.ruleModel.hasComponent('parentWhitelist')) {
          const { parent_level, parent_ids, parent_whitelist } = rule;
          if (parent_ids && parent_ids.length) {
            const data = await this.config.api.campaigns.getByIds({ ids: parent_ids.map((i) => i.id), model: parent_level });
            if (data && data.length) {
              this.ruleModel.rule.components.parentWhitelist.value = {
                entities: data,
                parent_level,
                type: parent_whitelist ? { value: 'INCLUDE', content: 'Include' } : { value: 'EXCLUDE', content: 'Exclude' } };
            }
          }
        }
      } else if (templateId) {
        rule.template_id = templateId;
        this.ruleModel.setRuleData(rule, this.mode);
      }

      // Set Default Campaign
      if (this.mode === 'create' && this.$route.query.campaign_id) {
        const { campaign_id } = this.$route.query;
        campaignsPromise = async () => {
          if (campaign_id) {
            const { data } = await this.config.api.campaigns.getByFilters({ ids: [campaign_id] });

            if (data?.length) {
              this.ruleModel.rule.components.campaigns.value = data;
            }
          }
        };
      }
      /** ******************************************* */

      /** ***** Load Options & Resolve Promises ***** */
      const [groups, tags] = await Promise.all([this.config.api.rules.groups(), this.config.api.rules.tags(), campaignsPromise()]);
      this.options.groups = groups.map((group) => ({ value: group, label: group }));
      this.options.tags = tags.map((tag) => ({ value: tag, label: `${tag.charAt(0).toUpperCase()}${tag.slice(1)}` }));
      /** ******************************************* */

      if (this.mode === 'create' && this.isCloneRule) {
        this.ruleModel.rule.components.rotation.value = 'COO';
      }
      this.timezone = this.ruleModel.timezone;
    },
    $_validateAdGroups() {
      const adGroupsComponent = this.ruleModel.rule.components.entitySelector;
      if (adGroupsComponent) {
        const adGroupSelectionType = adGroupsComponent.value?.type?.value || null;
        const adGroupSelectedEntities = adGroupsComponent.value?.entities || [];
        const adGroupsStatus = adGroupSelectionType === null || adGroupSelectedEntities.length > 0;
        this.adGroupsError = !adGroupsStatus;
        return adGroupsStatus;
      }
      return true;
    },
    async $_validateBeforeSubmit() {
      try {
        this.$refs?.ruleRefbudget?.$_dismissAlert();
        const refs = this.ruleModel.getActiveComponentskey().map((key) => this.$_getRef(key));
        const adGroupsStatus = this.$_validateAdGroups();
        const isValidNumberOfCopies = this.$refs?.ruleRefnumberOfCopies?.validateRuleBudget?.() ?? true;
        const componentsStatus = await Promise.all(refs.map((ref) => ref.validate()));
        const isValidNumberOfCopiesAdset = (this.$refs?.ruleRefnumberOfCopies?.validateRuleAdsetBudget?.() ?? true);
        const isValid = componentsStatus.reduce((status, formStatus) => status && formStatus, true) && adGroupsStatus;
        const form = this.$refs?.ruleRefbudget?.form;
        if (form && budgetConfigValid(form.min, form.max, form.value)) {
          this.$refs.ruleRefbudget.$_showInlineError('danger', 'Please check the value for the budgets. Min budget cannot be greater or equal than max budget.');
          this.$n_warnNotification({ title: 'Invalid form' });
          return;
        }
        if (isValid && isValidNumberOfCopies && isValidNumberOfCopiesAdset) {
          if (this.ruleModel.rule.components.conditions.value.map((v) => Object.keys(v)).map((v) => v[0]).includes('today_ts_clicks') && (this.ruleModel.rule.components.interval.value || {}).interval_exclude !== '0') {
            const swal = await this.$swal({
              title: '<small>Daily Cap Rules don\'t consider excluded data. Do you want to continue anyway? </small>',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Continue',
              cancelButtonText: 'Cancel',
            });
            if (swal.value !== true) return;
          }
          // Valid Form
          this.preloaderSubmit = true;
          try {
            const rule = this.ruleModel.getApiForm(this.rulePayload);
            rule.timezone = this.timezone;
            if (this.mode === 'edit') {
              await this.config.api.rules.update(this.$route.params.id, rule);
              this.$n_successNotification({ title: 'Rule successfully updated!' });
            } else {
              this.ruleModel.isGlobalRule ? await this.config.api.rules.createGlobalRule(rule) : await this.config.api.rules.create(rule);
              this.$n_successNotification({ title: 'Rule successfully created!' });
              await this.afterCreate();
            }
          } catch (error) {
            console.log(error);
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
          this.preloaderSubmit = false;
        } else {
          this.$n_warnNotification({ title: 'Invalid form' });
        }
      } catch (error) {
        console.log({ error });
        this.preloaderSubmit = false;
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          console.log({ err });
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    $_onNewGroup(value) {
      const searchOptionItem = this.options.groups.find((item) => item.value === value);
      if (searchOptionItem) {
        const searchSelectedItem = this.ruleModel.rule.components.groups.value.find((item) => item.value === value);
        if (!searchSelectedItem) this.ruleModel.rule.components.groups.value.push(searchOptionItem);
      } else {
        const item = {
          label: value,
          value,
        };
        this.options.groups.push(item);
        this.ruleModel.rule.components.groups.value.push(item);
      }
    },
    $_onNewTag(value) {
      const searchOptionItem = this.options.tags.find((item) => item.value === value);
      if (searchOptionItem) {
        const searchSelectedItem = this.ruleModel.rule.components.template_tag.value.find((item) => item.value === value);
        if (!searchSelectedItem) this.ruleModel.rule.components.template_tag.value.push(searchOptionItem);
      } else {
        const item = {
          label: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
          value,
        };
        this.options.tags.push(item);
        this.ruleModel.rule.components.template_tag.value.push(item);
      }
    },
    $_showTimezone(rotation) {
      // Daily Once
      this.showTimeZone = rotation === 'DO';
      this.rotation = rotation;
    },
    $_hideTimezoneInHourOfDay(inputs) {
      const inputConditions = inputs.map((input) => Object.keys(input)?.[0] || undefined);
      this.showTimeZone = this.rotation === 'DO' && !inputConditions.includes('hour_of_day');
    },
    $_setTimezone(timezone) {
      // Daily Once
      this.timezone = timezone;
    },
    // Unusable method & don't work!
    // async $_getRuleSimulationData() {
    //   const campaignList = {};
    //   this.options.campaigns.forEach((campaign) => { campaignList[campaign.id.toString()] = campaign.name; });
    //   const ruleObject = await this.ruleModel.getApiForm(this.rulePayload);

    //   if (!ruleObject || !ruleObject.campaign_ids || ruleObject.campaign_ids.length < 1) {
    //     this.$n_warnNotification({ title: 'You have to select at least one campaign', timeout: 5000 });
    //   } else {
    //     const simulationResponse = await this.$api.rules.simulate(ruleObject);
    //     simulationResponse.name = this.ruleModel.rule.components.name.value;
    //     simulationResponse.simulation_data_list.forEach((campaign) => {
    //       campaign.name = campaignList[campaign.campaign_id];
    //     });
    //     this.$refs.ruleSimulationModal.show({ simulation_data: simulationResponse });
    //   }
    // },
    $_getComponentRefName(name) {
      return `ruleRef${name}`;
    },
    $_getRef(name) {
      return this.$refs[this.$_getComponentRefName(name)];
    },
    $_getCounter(reset) {
      if (typeof this.$options.counter === 'undefined' || reset) this.$options.counter = 0;
      return ++this.$options.counter;
    },
    $_getOnceDailyInfo() {
      return 'Timezone within which the rule will be executed.';
    },
    $_getHeaderText() {
      let text = 'Rule';
      if (this.ruleModel && this.ruleModel.isGlobalRule) {
        text = `Global ${text}`;
      }
      if (this.mode === 'create') {
        text = `New ${text}`;
      } else {
        text = `${this.mode.charAt(0).toUpperCase()}${this.mode.slice(1)} ${text}`;
      }
      return text;
    },
    $_getWidgetFilteringInfo(label = 'Widget') {
      return `${label} Filtering allows you to specify a list of ${label.toLowerCase()}s that you want to exclude or include in the action of the rule.
              <br><b>Include</b>: the rule will only work with the ${label.toLowerCase()}s that you specify in the list
              <br><b>Exclude</b>: the rule will only work with the ${label.toLowerCase()}s that are NOT on this list`;
    },
    $_getFilterByParentInfo(label = 'AdGroup') {
      return `${label} Filtering allows you to specify a list of ${label.toLowerCase()}s that you want to exclude or include in the action of the rule.
              <br><b>NOTE: This filter will work only with selected campaigns, It will not include ${label}s if their Campaign it's not selected!</b>
              <br><b>Include</b>: the rule will only work with the ${label.toLowerCase()}s that you specify in the list
              <br><b>Exclude</b>: the rule will only work with the ${label.toLowerCase()}s that are NOT on this list`;
    },
  },
};
</script>

<style lang="scss">
.rule-container {
  .section-header {
    margin-bottom: 3rem;
  }

  &__form {
    .optimizer-select {
      .dropdown-toggle {
        color: $black-600;

        &:focus,
        &:active {
          color: $black-600;
        }
      }

      .dropdown-menu {
        z-index: 1100;
      }
    }

    .form-section {
      margin-top: 4rem;

      &:first-of-type {
        margin-top: 0 !important;
      }

      &__title {
        margin-bottom: 2rem;

        &__rule-name {
          font-size: 1.7rem;
          font-weight: normal;
          color: $black-600;
        }
      }
    }

    .optimizer-form-skeleton {
      // margin-bottom: 3rem;
    }
  }


  .fixed-footer {
    &__actions {
      button {
        margin-left: 1rem;
      }
    }
  }
}

// .rule-form-wrapper {
//   @mixin h-display($size) {
//     .h-display-#{$size} {
//       .input-group {
//         display: block;
//         .input-group-addon {
//           border-right: 1px solid #e1e6ef;
//           border-left: 1px solid #e1e6ef;
//           &+* {
//             border-top-width: 0px;
//           }
//         }
//         .v-select {
//           border-right-width: 1px !important;
//           border-bottom-width: 0px;
//            &~* {
//             border-bottom-width: 0px !important;
//           }
//         }
//         &>* {
//           width: 100%;
//           &:last-child {
//             border-bottom-width: 1px !important;
//           }
//         }
//       }
//     }
//   }

//   /* Small Devices, Tablets */
//   @media only screen and (max-width : 768px) {
//     @include h-display('sm');
//   }
//   /* Extra Small Devices, Phones */
//   @media only screen and (max-width : 480px) {
//     @include h-display('xs');
//   }
//   /* Custom, iPhone Retina */
//   @media only screen and (max-width : 320px) {
//     @include h-display('xxs');
//   }

//   #ruleGroup.v-select {
//     border-right-width: 0px;
//   }

//   .rule-name {
//     font-size: 19px;
//     font-weight: 600;
//     line-height: 20px;
//     background-color: #e6f6ff;
//     padding: 0px 6px;
//     color: #4d5253;
//   }
//   hr {
//     margin-top: 0.5rem;
//     margin-bottom: 0.7rem;
//   }
//   .rule-alert {
//     line-height: 1.5rem;
//   }
//   .campaign-form {
//     .input-group-addon {
//       min-width: 175px;
//       height: 2.6em;
//     }
//   }
// }
.rule-template-alert {
  padding: 0.5em;
  .swal2-header {
    .swal2-icon.swal2-warning {
      height: 4em;
      width: 4em;
      .swal2-icon-text {
        font-size: 3.3em;
        line-height: 1.2em;
      }
    }
    .swal2-title {
      font-size: 23px;
      font-weight: 400;
    }
  }
}
</style>
