export default [
  {
    name: 'Include All Tablet OS',
    code: true,
    reset: true,
  },
  {
    name: 'Exclude All Tablet OS',
    code: false,
    reset: true,
  },
  {
    name: 'Android 13.x.x',
    code: 'android13tablet',
  },
  {
    name: 'Android 12.x.x',
    code: 'android12tablet',
  },
  {
    name: 'Android 11.x.x',
    code: 'android11tablet',
  },
  {
    name: 'Android 10.x.x',
    code: 'android10tablet',
  },
  {
    name: 'Android 9.x.x',
    code: 'android90tablet',
  },
  {
    name: 'Android 8.x.x',
    code: 'android80tablet',
  },
  {
    name: 'Android 7.x.x',
    code: 'android70tablet',
  },
  {
    name: 'Android 6.x.x',
    code: 'android60tablet',
  },
  {
    name: 'Android 5.x.x',
    code: 'android50tablet',
  },
  {
    name: 'Android 4.4',
    code: 'android44tablet',
  },
  {
    name: 'Android 4.3',
    code: 'android43tablet',
  },
  {
    name: 'Android 4.2',
    code: 'android42tablet',
  },
  {
    name: 'Android 4.1',
    code: 'android41tablet',
  },
  {
    name: 'Android 4.0',
    code: 'android40tablet',
  },
  {
    name: 'Android 3.x.x',
    code: 'android3tablet',
  },
  {
    name: 'Android 2.3',
    code: 'android23tablet',
  },
  {
    name: 'Android 2.2 and lower',
    code: 'android22tablet',
  },
  {
    name: 'iOS 16.x.x',
    code: 'ios16tablet',
  },
  {
    name: 'iOS 15.x.x',
    code: 'ios15tablet',
  },
  {
    name: 'iOS 14.x.x',
    code: 'ios14tablet',
  },
  {
    name: 'iOS 13.x.x',
    code: 'ios13tablet',
  },
  {
    name: 'iOS 12.x.x',
    code: 'ios12tablet',
  },
  {
    name: 'iOS 11.x.x',
    code: 'ios11tablet',
  },
  {
    name: 'iOS 10.x.x',
    code: 'ios10tablet',
  },
  {
    name: 'iOS 9.x.x',
    code: 'ios9tablet',
  },
  {
    name: 'iOS 8.x.x',
    code: 'ios8tablet',
  },
  {
    name: 'iOS 7.x.x',
    code: 'ios7tablet',
  },
  {
    name: 'iOS 6.x.x',
    code: 'ios6tablet',
  },
  {
    name: 'iOS 5.x.x',
    code: 'ios5tablet',
  },
  {
    name: 'iOS 4.x.x and lower',
    code: 'ios4tablet',
  },
  {
    name: 'Other Tablet OS',
    code: 'othertablet',
  },
];
