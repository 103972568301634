<template>
  <div class="performance-stats-container">
    <div class="performance-stats-header">
      <!-- <h2 v-if="!modal" class="performance-stats-header__title">{{ type.charAt(0).toUpperCase() + type.slice(1) }} Performance for {{ $c_dateRange || 'Last 30 days' }}</h2> -->
      <div class="performance-stats__actions">
        <vue-opti-select-light
          class="optimizer-select"
          label-key="text"
          unique-key="value"
          :value="groupBy"
          @change="({value}) => groupBy = value"
          button-block
          single
          :options="groupByOptions"
        >
        </vue-opti-select-light>
        <date-range v-model="dateRangePicker" :auto-emit="true" :query="false"></date-range>
      </div>
    </div>

    <!-- Chart -->
    <div class="performance-stats__main-chart">
      <loading-skeleton
        v-if="loading"
        class="dashboard-main-chart-skeleton"
        :card="{ rows: 13, shape: 'square' }"
      />
      <fusioncharts
        v-else
        :type="chart.type"
        :width="chart.width"
        :height="chart.height"
        :data-format="chart.dataFormat"
        :data-source="chart.dataSource"
      ></fusioncharts>
      <span class="hide-chart-name"></span>
    </div>

    <!-- Table -->
    <loading-skeleton
      v-if="loading"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: false }"
    />
    <div :class="[ modal ? 'mb-0' : '', 'optimizer-table performance-table']">
      <vue-opti-table-light
        v-if="table.items.length > 0"
        :default-rows="100"
        :header-fields="$c_tableHeaders"
        :items="table.items"
        :export-label="$c_exportLabel"
        :show-search="false"
        name="performance-table"
        v-model="tableModel"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        sticky
      >
        <template v-for="(item, slot) in mappedFields" :slot="slot" slot-scope="props">
          <div class="table-item" :key="slot">
            <span class="table-item__value" v-if="item.options.format === 'currency'">
              {{ ui.table.money(props.item[slot]) }}
            </span>

            <span v-else-if="item.options.format === 'percentage'" :title="props.item[slot]" class="table-item__value">
              {{ ui.table.percentage(props.item[slot]) }}
            </span>

            <span v-else class="table-item__value">
              {{ ui.toFixed(props.item[slot], item.options.precision) }}
            </span>

            <span
              v-if="props.item[`${slot}_change`] !== 0 && item.options.format === 'currency'"
              :class="[ props.item[`${slot}_change`] > 0 ? 'text-success' : 'text-danger', 'table-item__change']"
              :title="props.item[`${slot}_change`]"
            >
              ({{ props.item[`${slot}_change`] > 0 ? '+' : '' }}{{ ui.table.money(props.item[`${slot}_change`]) }})
            </span>

            <span
              v-if="props.item[`${slot}_change`] !== 0 && item.options.format === 'percentage'"
              :class="[ props.item[`${slot}_change`] > 0 ? 'text-success' : 'text-danger', 'table-item__change']"
              :title="props.item[`${slot}_change`]"
            >
              ({{ props.item[`${slot}_change`] > 0 ? '+' : '' }}{{ ui.table.percentage(props.item[`${slot}_change`]) }})
            </span>

            <span
              v-else-if="props.item[`${slot}_change`] !== 0 && ['number', 'numeric'].includes(item.options.format)"
              :class="[ props.item[`${slot}_change`] > 0 ? 'text-success' : 'text-danger', 'table-item__change']"
              :title="props.item[`${slot}_change`]"
            >
              ({{ props.item[`${slot}_change`] > 0 ? '+' : '' }}{{ ui.toFixed(props.item[`${slot}_change`], item.options.precision) }})
            </span>
          </div>
        </template>
      </vue-opti-table-light>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import dateHelper from '@sh/helpers/date';
import ui from '@sh/helpers/ui';
import FusionCharts from 'fusioncharts';
import chartConfig from './chart';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import tableMixins from '@sh/mixins/table';
import { debounce } from 'lodash';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'PerformanceStats',
  components: { LoadingSkeleton },
  mixins: [tableMixins],
  props: {
    type: { type: String, default: 'campaign' },
    timezone: { type: String },
    id: { type: String },
    dateRange: { type: Object,
      default: () => ({
        startDate: moment().subtract(2, 'days').startOf('day').format(FORMAT),
        endDate: moment().endOf('day').format(FORMAT) }),
    },
    modal: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      ui,
      dateRangePicker: {
        startDate: moment(new Date()).subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment(new Date()).endOf('day').format(FORMAT),
      },
      groupByOptions: [
        { value: 'hour', text: 'Group By Hour' },
        { value: 'hourOfDay', text: 'Group By Hour of Day' },
        { value: 'day', text: 'Group By Day' },
      ],
      groupBy: 'day',
      chart: {
        type: 'timeseries',
        width: '100%',
        height: '400',
        dataFormat: 'json',
        schema: [
          { name: 'Clicks', type: 'number' },
          { name: 'Cost', type: 'number' },
          { name: 'Conversions', type: 'number' },
          { name: 'Revenue', type: 'number' },
          { name: 'Events', type: 'number' },
        ],
        dataMarkers: null,
        markerTypes: {
          cpc_change: { name: 'CPC Change', identifier: 'C' },
          budget_change: { name: 'Budget Change', identifier: 'B' },
          cpc_mobile_change: { name: 'CPC Mobile Change', identifier: 'C' },
          cpc_desktop_change: { name: 'CPC Desktop Change', identifier: 'C' },
          daily_budget_change: { name: 'Daily Budget Change', identifier: 'B' },
        },
        dataSource: null,
      },
      performanceList: {
        tr_clicks: 'Clicks',
        cost: 'Cost',
        tr_conversions: 'Conversions',
        tr_revenue: 'Revenue',
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      resizedColumns: {},
      table: {
        fields: [],
        items: [],
      },
      mappedFields: {},
    };
  },
  computed: {
    $c_dateRange() {
      return dateHelper.getLabel(this.dateRangePicker.startDate, this.dateRangePicker.endDate);
    },
    $c_slotLabels() {
      return Object.keys(this.mappedFields);
    },
    $c_exportLabel() {
      return `${this.type}_performance_${this.dateRangePicker.startDate}_${this.dateRangePicker.endDate}`;
    },
    $c_tableHeaders() {
      return this.$_getFieldInfo(this.table.fields);
    },
  },
  watch: {
    dateRangePicker: {
      handler() {
        this.debouncedGetPerformance();
      },
      deep: true,
    },
    groupBy() {
      this.debouncedGetPerformance();
    },
    $c_dateRange() {
      this.groupBy = (this.dateRangePicker.startDate === this.dateRangePicker.endDate) ? 'hour' : this.groupBy;
    },
  },
  created() {
    this.dateRangePicker = { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate };
    this.$watch('dateRange', () => {
      this.dateRangePicker.startDate = this.dateRange.startDate;
      this.dateRangePicker.endDate = this.dateRange.endDate;
    }, { deep: true });
    this.debouncedGetPerformance = debounce(this.$_getPerformance, 300);
  },
  methods: {
    async $_getPerformance() {
      this.loading = true;
      const { startDate, endDate } = this.dateRangePicker;

      /** ***************** Set Resized Columns from Local Storage **************** */
      this.resizedColumns = this.$settings.resizedColumns.getResizedColumns('performance', 'table');
      /** ************************************************************************* */
      const performanceParams = {
        from: moment(startDate)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        item_id: this.id,
        type: this.type,
        campaign_id: this.$route.params.id,
        timezone: this.timezone,
        group_by: this.groupBy,
        traffic_source_type: this.$route.params.type,
        traffic_source_account_id: Number(this.$route.query.account),
      };
      try {
        const [
          {
            data: {
              fields,
              performance,
            },
          },
          { events },
        ] = await Promise.all([
          this.$api.campaigns.getPerformanceChart({
            ...performanceParams,
            preset_id: this.$settings.presets.getPresetByTrafficSource(this.$route.params.type),
          }),
          this.$api.campaigns.getPerformanceEvents(performanceParams),
        ]);
        this.$_handlePerformanceChart(performance, events);
        this.$_handleTableFields(fields, performance);
        this.table.items = performance;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    $_handlePerformanceChart(performance, events) {
      const { chartStats } = this.$_mapChartsData(performance, events);
      if (chartStats.length === 1 && this.groupBy === 'day') {
        const specificDate = chartStats[0][0];
        const emptyStats = [0, 0, 0, 0, 0];
        const dayBefore = moment(specificDate).subtract(1, 'day').format('YYYY-MM-DD');
        const dayAfter = moment(specificDate).add(1, 'day').format('YYYY-MM-DD');

        // Insert new entries for day before, specificDate, and day after
        chartStats.unshift([dayBefore, ...emptyStats]);
        chartStats.push([dayAfter, ...emptyStats]);
      }
      const chartSchema = [
        { name: 'Time', type: 'date', format: this.getTimeFormat().chart.schema },
        ...this.chart.schema,
      ];
      const chartData = new FusionCharts.DataStore().createDataTable(chartStats, chartSchema);
      this.chart.dataSource = {
        ...chartConfig,
        tooltip: {
          outputtimeformat: {
            year: '%Y', // %Y - 2018
            month: '%B, %Y', // %B, %Y - September, 2018
            day: '%b %-d, %Y', // %B %-d, %Y - November 11, 2018
            hour: this.getTimeFormat().chart.hour, // %b %-d, %Y, %-I %p - Nov 5, 2018, 4 PM
            minute: '%b %-d, %Y, %H:%M', // %b %-d, %Y, %-I:%-M %p - Nov 5, 2018, 4:24 PM
            second: '%b %-d, %Y, %H:%M:%S', // %b %-d, %Y, %-I:%-M:%-S %p - Nov 5, 2018, 4:25:30 PM
            millisecond: '%b %-d, %Y, %-I %H:%M:%S:%L', // %b %-d, %Y, %-I:%-M:%-S:%-L %p- Nov 29, 2017, 4:29:12.075 PM
          },
        },
        data: chartData,
        datamarker: this.chart.dataMarkers,
        xaxis: {
          binning: this.getBinning(),
          outputtimeformat: {
            year: '%Y', // %Y - 2018
            month: '%B, %Y', // %B, %Y - September, 2018
            day: '%b %-d', // %B %-d, %Y - Nov 11
            hour: this.getTimeFormat().chart.hour, // %b %-d, %Y, %-I %p - Nov 5, 2018, 4 PM
            minute: '%b %-d, %Y, %H:%M', // %b %-d, %Y, %-I:%-M %p - Nov 5, 2018, 4:24 PM
            second: '%b %-d, %Y, %H:%M:%S', // %b %-d, %Y, %-I:%-M:%-S %p - Nov 5, 2018, 4:25:30 PM
            millisecond: '%b %-d, %Y, %-I %H:%M:%S:%L', // %b %-d, %Y, %-I:%-M:%-S:%-L %p- Nov 29, 2017, 4:29:12.075 PM
          },
        },
      };
    },

    $_mapChartsData(performance, events) {
      const eventsMap = events.reduce((acc, ev) => {
        const eventDate = this.isHourOfDay()
          ? moment(ev.date).get('hour')
          : ev.date;
        const date = this.getMoment(eventDate).format(this.getTimeFormat().chartData.events);
        acc[date] = acc[date] ? acc[date] += 1 : 1;
        return acc;
      }, {});

      const chartStats = performance.map((item) => {
        const itemDate = this.getMoment(item.date_range).format(this.getTimeFormat().chartData.stats);
        const eventCount = eventsMap[itemDate] || 0;
        return [itemDate, item.tr_clicks, item.cost, item.tr_conversions, item.tr_revenue, eventCount];
      });

      let eventMarkers = null;
      if (!this.isHourOfDay()) {
        eventMarkers = events.map((item) => ({
          time: moment(item.date)
            .format(this.getTimeFormat().chartData.markers),
          tooltext: this.getMarkerContent(item.type, item.value, item.change),
          value: 'Events',
          identifier: this.chart.markerTypes[item.type].identifier,
          timeFormat: this.getTimeFormat().chart.markers,
          style: {
            text: {
              fill: '#fff',
              'font-weight': 'bold',
            },
            marker: {
              fill: '#114fb9',
              'font-weight': 'bold',
              'fill-opacity': '0.9',
              stroke: '#c5c5c5',
            },
          },
        }));
      }
      this.chart.dataMarkers = eventMarkers;

      return { chartStats };
    },
    $_handleTableFields(fields, performance) {
      const fieldsToRemove = ['traffic_source_campaign_id', 'name', 'status', 'payout', 'est_cost', 'est_net', 'est_roi'];
      const fieldsToMap = {
        // 'additional_fields.daily_budget': 'daily_budget',
        // 'additional_fields.budget': 'budget',
        // 'additional_fields.bid': 'cpc',
        'ga:pvs': 'pvs',
      };

      fields.forEach((item) => {
        fieldsToMap[item.item.key] ? item.item.key = fieldsToMap[item.item.key] : null;
        item.item.slot = item.item.key;
        item.colStyle = { width: '180px' };
      });

      if (performance.length) {
        const performanceFields = Object.keys(performance[0]);
        const filteredFields = fields.filter((item) => performanceFields.includes(item.item.key))
          .map((e) => ({ ...e, item: { ...e.item, content: (val) => val[e.item.key] }, display: true }));

        this.mappedFields = filteredFields.reduce((acc, item) => {
          acc[item.item.slot] = item;
          return acc;
        }, {});

        this.table.fields = [
          {
            header: { content: 'Date', style: '' },
            item: {
              key: 'date_range',
              content: (item) => moment.utc(item.date_range, this.getLocaleFormat()).format(this.getTableDateFormat()),
              sortable: true,
              style: { textAlign: 'center', padding: '2px 5px !important' },
            },
            colStyle: { width: '140px' },
            colClass: 'sticky sticky--date',
          }, ...filteredFields];
      } else {
        this.table.fields = [...this.table.fields, ...fields.filter((item) => !fieldsToRemove.includes(item.item.key))];
      }
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('performance', 'table', payload);
    },
    isHourOfDay() {
      return this.groupBy === 'hourOfDay';
    },
    getMoment(date) {
      if (this.groupBy === 'hourOfDay') {
        return moment(this.dateRange.endDate).subtract(1, 'day').set('hour', date);
      }
      return moment(date, this.getLocaleFormat());
    },
    getTableDateFormat() {
      if (this.groupBy === 'day') { return 'MMM D, YYYY'; }
      if (this.groupBy === 'hour') return 'MMM D, YYYY HH:mm:ss';
      if (this.groupBy === 'hourOfDay') return 'HH:mm:ss';
    },
    getTimeFormat() {
      if (['hour', 'hourOfDay'].includes(this.groupBy)) {
        return {
          chartData: {
            events: 'YYYY-MM-DD HH:mm:ss',
            stats: 'YYYY-MM-DD HH:mm:ss',
            markers: 'YYYY-MM-DD HH:mm',
          },
          chart: {
            markers: '%Y-%m-%d %H:%M',
            hour: '%H:%M',
            schema: '%Y-%m-%d %H:%M:%S',
          },
        };
      }
      return {
        chartData: {
          events: 'YYYY-MM-DD',
          stats: 'YYYY-MM-DD',
          markers: 'YYYY-MM-DD HH:mm',
        },
        chart: {
          markers: '%Y-%m-%d %H:%M',
          hour: '%b %-d, %H:%M',
          schema: '%Y-%m-%d',
        },
      };
    },
    getBinning() {
      return { hour: ['hour', 'hourOfDay'].includes(this.groupBy) ? [1] : [] };
    },
    getLocaleFormat() {
      return this.isHourOfDay() ? 'LT' : '';
    },
    getMarkerContent(type, value, change) {
      return `<span>${this.chart.markerTypes[type].name}: ${this.ui.table.money(value - change)} => ${this.ui.table.money(value)} <span class="${change > 0 ? 'text-success' : 'text-danger'}">(${change > 0 ? '+' : ''}${this.ui.table.money(change)})</span></span>`;
    },
  },
};
</script>

<style lang="scss">
.performance-stats-container {
  .performance-stats-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    padding: 2rem 2rem 0 2rem;

    &__title {
      font-size: 1.8rem;
    }

    .performance-stats__actions {
      display: flex;

      .optimizer-select {
        width: 20rem;
      }

      .optimizer-date-range {
        margin-left: 1.5rem;
      }
    }
  }

  .performance-stats__main-chart {
    margin-bottom: 2rem;
    padding: 0;
    position: relative;

    .hide-chart-name {
      position: absolute;
      display: inline-block;
      width: 9rem;
      height: 1rem;
      background: #fff;
      bottom: .5rem;
    }
  }

  .dashboard-main-chart-skeleton {
      height: 40rem;

      .optimizer-skeleton__container {
        box-shadow: none;
        // display: flex;
        // flex-direction: row;

        &__rows {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // margin-right: 2.5rem;
        }

        &__row {
          // display: block;
          // width: 10rem;
          // height: 1.3rem;
        }

        &__shape-square {
          // margin-top: 0;
        }
      }
    }

    .performance-table {
      padding: 0;

      .table-item {
        text-align: center;
        position: relative;

        &__value {
          margin-right: 7rem;
        }

        &__change {
          position: absolute;
          z-index: 10;
          font-size: 1.3rem;
          right: 0;
          // top: 50%;
          // transform: translateY(-50%);
          text-align: right;
        }
      }

      .field {
        padding: .3rem;
      }

      .row.header {
        padding: 0 1.5rem;
      }

      .stickyHeader {

        th {
          border-top: .1rem solid $color-light-gray !important;
        }
      }
    }

    .optimizer-skeleton__actions__search {
      margin-right: 1rem;
    }
}
</style>
