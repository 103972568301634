import { api, apiStore } from '@sh/services/api';

const backendApi = {
  accountWizard: {},
};

backendApi.accountWizard = {
  loadAccounts() {
    return apiStore.trafficSources.accounts();
  },
  loadTrackerAccounts() {
    return apiStore.trackers.accounts();
  },
  loadTypes() {
    return apiStore.trafficSources.types();
  },
  loadTrackerTypes() {
    return apiStore.trackers.types();
  },
  createTrafficSource(payload) {
    return api.trafficSources.create(payload);
  },
  createTracker(payload) {
    return api.trackers.create(payload);
  },
  async validateHeaders(credentials, tokenVariables) {
    return api.trackers.validateHeaders(credentials, tokenVariables);
  },
  linkTracker(sourceId, trackerId, tokens, tokensTracker, metrics) {
    return api.trafficSources.linkTracker(sourceId, trackerId, tokens, tokensTracker, metrics);
  },
  extendToken(payload) {
    return api.trafficSources.extendToken(payload);
  },
};

export default backendApi;
