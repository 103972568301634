<template>
  <div class="row ruleWithPausedCmps">
    <switch-button
      :checked="ruleWithPausedCampaigns"
      :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
      :update="(value) => $_updateApplyToPausedCampaigns(value)"
    />
    <div class="text-with-info">Execute rule even when <strong>campaigns are paused</strong></div>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleWithPausedCampaigns',
  mixins: [component],
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      ruleWithPausedCampaigns: '',
    };
  },
  watch: {
    ruleWithPausedCampaigns: {
      immediate: true,
      handler() {
        this.$_emit('ruleWithPausedCampaigns');
      },
      deep: true,
    },
  },
  created() {
    this.ruleWithPausedCampaigns = this.value;
  },
  methods: {
    $_updateApplyToPausedCampaigns(value) {
      this.ruleWithPausedCampaigns = value;
    },
  },
};
</script>

<style lang="scss">
.rule-name-option-wrapper {

}

.ruleWithPausedCmps{
  padding-left: 2rem;
}
</style>
