<template>
  <div>
    <div class="ss--overlay" ref="overlay" v-if="show_dropdown"></div>
    <div class="inpt-group" @click="!disabled ? focusInput($event) : null" ref="container" :class="{ disabled: disabled }">
      <div class="inpt-cont">
        <span
          class="selected-option"
          v-for="(item, index) in local_model"
          :key="index"
        >
          <span v-if="label">{{ item[label] }}</span>
          <span v-else>{{ item }}</span>
          <svg
            @click="!disabled ? selectOption(item, $event) : null"
            style="margin: auto 0;cursor: pointer"
            viewBox="64 64 896 896"
            fill="currentColor"
            width=".8em"
            height=".8em"
            class="ng-tns-c11-16"
            data-icon="close"
            aria-hidden="true"
          >
            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          </svg>
        </span>
        <input
          type="text"
          class="search-box"
          :placeholder="placeholder"
          v-model="input_model"
          @keyup="searchOptions(input_model)"
          @focus="canShowDropdown()"
          ref="searchBox"
          :disabled="disabled"
        >
      </div>
      <div class="options-dropdwn" v-if="show_dropdown" ref="dropdown" id="dropdown">
        <div class="no-items" v-if="!items || items.length === 0 && !loading">
          <span>Not found</span>
        </div>
        <div class="loader" v-if="serverSearch && loading">
          <svg
            viewBox="0 0 1024 1024"
            fill="#3e515b"
            width="1em"
            height="1em"
            data-icon="loading"
            aria-hidden="true"
            class="anticon-spin"
          >
            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
          </svg>
          Searching ...
        </div>
        <span
          class="option"
          v-for="(item, index) in items"
          :key="index"
          :class="{ 'active': selectedOption() === index || selectedOption(item) === true }"
          @click="selectOption(item, $event)"
        >
          {{ item[label] }}
          <svg
            v-if="selectedOption() === index || selectedOption(item) === true"
            style="margin: auto 0"
            viewBox="64 64 896 896"
            fill="currentColor"
            width="1em"
            height="1em"
            data-icon="check"
            aria-hidden="true"
          >
            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
import _ from 'lodash';

export default {
  name: 'SearchSelect',
  props: {
    watch: {
      type: Boolean,
      default: false,
      required: false,
    },
    value: {
      type: [Array, String, Object, Boolean, Number],
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    search: {
      type: Boolean,
      required: false,
      defualt: false,
    },
    serverSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: false,
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: false,
    },
    delay: {
      type: Number,
      required: false,
      default: 200,
    },
    defaultItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input_model: '',
      local_model: [],
      show_dropdown: false,
      delayTimeout: null,
    };
  },
  computed: {
    $c_ruleMap() {
      const rules = {};
      this.items.forEach((item) => { rules[item._id] = item; });
      return rules;
    },
  },
  watch: {
    show_dropdown(val) {
      if (val === true) {
        setTimeout(() => {
          document.querySelector('.ss--overlay').addEventListener('click', () => { this.show_dropdown = false; }, { once: true });
        }, 100);
      }
      if (!val && this.multiselect) {
        this.input_model = '';
      }
    },
    input_model(value) {
      const labels = this.items.map((item) => item[this.label]);
      if (this.search && !this.serverSearch && !labels.includes(value)) {
        this.$emit('on-search', value);
      }
    },
    value: {
      handler(value) {
        if (this.watch) {
          this.local_model = this.items.filter((item) => value.includes(this.itemValue ? item[this.itemValue] : item));
        }
      },
      deep: true,
    },
  },
  updated() {
    if (this.$refs.dropdown) {
      this.$refs.dropdown.style.top = getComputedStyle(this.$refs.container).height;
    }
  },
  created() {
    const selectedItem = this.items.find((item) => item.selected);
    if (!this.multiselect && selectedItem) {
      this.input_model = selectedItem[this.label] ? selectedItem[this.label] : selectedItem;
      this.$emit('input', selectedItem[this.itemValue] ? selectedItem[this.itemValue] : selectedItem);
    }
    if (this.multiselect) {
      if (this.defaultItems) {
        this.local_model = this.local_model.concat(this.defaultItems);
      }
      this.$emit('input', this.itemValue ? this.local_model.map((item) => item[this.itemValue]) : this.local_model);
    }
  },
  methods: {
    searchOptions(value) {
      if (!this.serverSearch) {
        this.show_dropdown = true;
      } else if (this.serverSearch && value.length > 1) {
        this.show_dropdown = true;
        if (this.delayTimeout) {
          clearTimeout(this.delayTimeout);
        }
        this.delayTimeout = setTimeout(() => {
          this.$emit('on-search', value);
        }, this.delay);
      } else {
        this.show_dropdown = false;
      }
    },
    canShowDropdown() {
      if (!this.serverSearch) {
        this.show_dropdown = true;
      } else if (this.serverSearch && this.input_model.length > 1) {
        this.show_dropdown = true;
      }
    },
    selectOption(item, e) {
      e.stopPropagation();
      switch (this.multiselect) {
        case false:
          this.input_model = item[this.label];
          this.show_dropdown = false;
          this.$emit('input', item[this.itemValue] ? item[this.itemValue] : item);
          break;
        case true:
          const exists = this.local_model.find((i) => _.isEqual(i, item));
          if (exists) {
            this.local_model.splice(this.local_model.indexOf(item), 1);
          } else {
            this.local_model.push(item);
          }
          this.$emit('input', this.itemValue ? this.local_model.map((item) => item[this.itemValue]) : this.local_model);
          break;
      }
    },
    selectedOption(i) {
      switch (this.multiselect) {
        case false:
          const index = this.items.indexOf(this.items.find((item) => item[this.label] === this.input_model), 1);
          return index > 0 ? index : 0;
        case true:
          const exists = this.local_model.find((item) => _.isEqual(item, i));
          return !!exists;
      }
    },
    focusInput(e) {
      e.stopPropagation();
      this.$refs.searchBox.focus();
    },
  },
};
</script>

<style scoped lang="scss">
  .inpt-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    border: 1px solid #e1e6ef;
    cursor: text;
    box-sizing: border-box;
    padding: 0 5px;
  }
  .inpt-group.disabled, .inpt-group.disabled input {
    background-color: #e1e6ef;
  }
  .search-box {
    box-sizing: border-box;
    display: flex;
    padding: 6px 8px;
    border: none !important;
    outline: none;
    color: #3e515b;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  .search-box:focus {
    border: none !important;
  }
  .options-dropdwn {
    box-sizing: border-box;
    width: 100%;
    background: white;
    position: absolute;
    top: 38px;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
    max-height: 200px;
    overflow-y: scroll;
    z-index: 9999;
    left: 0px;
  }
  .options-dropdwn::-webkit-scrollbar {
    display: none;
  }
  .no-items {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .no-items span {
    margin: auto 0;
  }
  .option {
    width: 100%;
    box-sizing: border-box;
    padding: 3px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    color: #3e515b;
  }
  .option.active {
    background: rgba(0, 0, 0, 0.05);
    font-weight: 600
  }
  .option:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  .loader {
    box-sizing: border-box;
    width: 100%;
    padding: 6px 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #3e515b;
  }
  .anticon-spin {
    margin: auto 0;
    margin-right: 10px;
  }
  .anticon-spin, .anticon-spin:before {
    animation: 1s linear infinite loadingCircle;
  }
  .ss--overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    position: fixed;
    z-index: 5;
  }
  .selected-option {
    height: 23px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
    color: #3e515b;
    margin: 5px;
    margin-left: 0;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    padding: 0 8px;
  }
  .inpt-cont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .inpt-cont::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    margin: auto;
  }
  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
