import { RuleTypes } from '@sh/types';
import Decimal from 'decimal.js';

const toFixed = (num: number | Decimal, fixed?: any) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  const matchResult = (num || 0).toString().match(re);
  if (matchResult) {
    return parseFloat(matchResult[0]);
  }
};

export const toChange = (
  action: RuleTypes.Bulk.Action,
  value: number,
  current: number,
  unit: RuleTypes.Bulk.Unit,
  to: string,
  item: RuleTypes.Bulk.Item
) => {
  if (to.includes('additional_fields')) {
    const splitedTo = to.split('.')[1];
    to = splitedTo;
  }
  const fixed = 3;
  if (action === 'set') {
    if (unit === 'static') {
      return value;
    }
    if (unit === 'percentage') {
      const setValue: any = item[to as 'bid'];
      if (current === 0) {
        return toFixed((value / 100) * setValue, fixed);
      }
      return toFixed(setValue * (value / 100), fixed);
    }
  } else if (action === 'increase') {
    if (unit === 'static') {
      const x = new Decimal(current);
      const y = new Decimal(value);
      return toFixed(Decimal.add(x, y), fixed);
    }
    if (unit === 'percentage') {
      const increaseValue = item[to as 'bid'];
      if (current === 0) {
        return toFixed((value / 100) * increaseValue, fixed);
      }
      return toFixed(increaseValue * (value / 100) + current, fixed);
    }
  } else if (action === 'decrease') {
    if (unit === 'static') {
      return toFixed(current - value, fixed);
    }
    if (unit === 'percentage') {
      const decreaseValue = item[to as 'bid'];
      if (current === 0) {
        return toFixed((value / 100) * decreaseValue, fixed);
      }
      return toFixed(current - decreaseValue * (value / 100), fixed);
    }
  }
};

export const calculateMinMax = (
  min: string | number,
  max: string | number,
  minObject: RuleTypes.Bulk.MinObject,
  maxObject: RuleTypes.Bulk.MaxObject,
  item: RuleTypes.Bulk.Item
): { calculatedMin: number | string; calculatedMax: number | string } => {
  if (!(minObject && maxObject)) {
    return {
      calculatedMin: Number(min),
      calculatedMax: Number(max),
    };
  }

  const calculatedMin = toChange(
    'set',
    Number(minObject.value),
    Number(minObject.value),
    minObject.unit,
    minObject.to,
    item
  );
  const calculatedMax = toChange(
    'set',
    Number(maxObject.value),
    Number(maxObject.value),
    maxObject.unit,
    maxObject.to,
    item
  );
  return {
    calculatedMin: Number(calculatedMin),
    calculatedMax: Number(calculatedMax),
  };
};

export const calculateMetric = (
  current: number,
  toChange: number,
  min: number,
  max: number
): { calculatedMetric: number | string } => {
  if (toChange === 0 && !min && !max) {
    return { calculatedMetric: 0 };
  }
  if (current === toChange) {
    return { calculatedMetric: toChange };
  }
  if (toChange >= min && toChange <= max) {
    return { calculatedMetric: toChange };
  }
  if (toChange <= min && current !== min && min <= max) {
    return { calculatedMetric: min };
  }
  if (toChange >= max && current !== max && max >= min) {
    return { calculatedMetric: max };
  }
  if (toChange >= max && current === max) {
    return { calculatedMetric: max };
  }
  if (toChange <= min && current === min) {
    return { calculatedMetric: min };
  }
  return { calculatedMetric: toChange };
};
