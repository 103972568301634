import { FilterImageEvent } from '@/views/Automation/CampaignCreatorV2/components/CcPopover/helpers';
import {
  AspectRatio,
  Category,
  CategoryType,
} from '@/views/Automation/CampaignCreatorV2/components/Modals/CcAddImageModal/types';
import { SupportedCrop } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import { ViewLevelAction } from '@/views/Automation/CampaignCreatorV2/types';

export const availablePlacementAspectRatios: Partial<Record<SupportedCrop, AspectRatio>> = {
  [SupportedCrop['100x100']]: {
    value: SupportedCrop['100x100'],
    option: '1:1',
    style: 1 / 1,
    text: 'Feeds, In-stream ads for videos and reels, Search results',
    disabled: false,
  },
  [SupportedCrop['90x160']]: {
    value: SupportedCrop['90x160'],
    option: '9:16',
    style: 9 / 16,
    text: 'Stories and Reels, Apps and sites',
    disabled: false,
  },
  [SupportedCrop['191x100']]: {
    value: SupportedCrop['191x100'],
    option: '1.91:1',
    style: 191 / 100,
    text: 'Right column, Search results',
    disabled: true,
  },
};

export const categories: Category[] = [
  { name: 'All', icon: 'allContents', category: CategoryType.ALL },
  { name: 'Selected Account', icon: 'accountContents', category: CategoryType.ACCOUNT },
  { name: 'Uploaded Images', icon: 'uploadedItems', category: CategoryType.UPLOADED },
  { name: 'Creative Library', icon: 'creativeLibrary', category: CategoryType.CREATIVE_LIBRARY },
  { name: 'Recommended', icon: 'recommended', category: CategoryType.RECOMMENDED },
];

export const secondaryFilters: ViewLevelAction<FilterImageEvent>[][] = [
  [
    { name: 'Fullscreen horizontal (16:9)', event: FilterImageEvent.FULL_HORIZONTAL },
    { name: 'Fullscreen vertical (9:16)', event: FilterImageEvent.FULL_VERTICAL },
    { name: 'Vertical (4:5)', event: FilterImageEvent.VERTICAL },
    { name: 'Square (1:1)', event: FilterImageEvent.SQUARE },
  ],
  [
    { name: '600 pixels or more', event: FilterImageEvent.WIDTH_600PX },
    { name: '1080 pixels or more', event: FilterImageEvent.WIDTH_1080PX },
  ],
  [
    { name: '600 pixels or more', event: FilterImageEvent.HEIGHT_600PX },
    { name: '1080 pixels or more', event: FilterImageEvent.HEIGHT_1080PX },
  ],
];
