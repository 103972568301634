import { ImageCreative, SupportedCrop } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import { Icons } from '@/views/Automation/CampaignCreatorV2/helpers/icons';
import { BaseOption } from '@/views/Automation/CampaignCreatorV2/types';
import { Position, TooltipPlacement } from '@sh/types';

export enum BaseMessageType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum Step {
  SELECT_MEDIA,
  EDIT_MEDIA,
}

export enum CategoryType {
  ALL = 'ALL',
  RECOMMENDED = 'RECOMMENDED',
  CREATIVE_LIBRARY = 'CREATIVE_LIBRARY',
  ACCOUNT = 'ACCOUNT',
  UPLOADED = 'UPLOADED',
}

export interface Category extends BaseOption {
  category: CategoryType;
}

export interface ImageMessage {
  type: BaseMessageType;
  content: string;
  icon: Icons;
  placement: TooltipPlacement;
  height: number;
  color?: string;
  positionY?: Position.TOP | Position.BOTTOM;
  positionX?: Position.LEFT | Position.RIGHT;
}

export interface AspectRatio {
  value: SupportedCrop;
  style: number;
  option: string;
  text: string;
  disabled: boolean;
  info?: string;
}

export interface Image extends ImageCreative {
  selected: boolean;
  category: CategoryType;
  message?: ImageMessage;
}

export interface EditImage extends ImageCreative {
  aspectRatio: AspectRatio;
}
