<template>
  <div v-if="!preloader" class="traffic-source-wraper align-tems:center">
    <!-- Alert Message -->
    <b-alert
      :show="!!alert.message"
      :variant="alert.type"
      dismissible
      @dismissed="$_dismissAlert()"
    >
      <div v-html="alert.message"></div>
    </b-alert>
    <div>
      <vue-opti-select-light
        class="optimizer-select"
        :options="mode.accounts"
        unique-key="id"
        ref="trafficSourceAccount"
        option-type="checkbox"
        :search-fields="['name', 'id']"
        button-placeholder="All Accounts"
        button-placeholder-all-selected="All Accounts"
        :button-placeholder-multiple="({ count, suffix }) => `${count} Account${suffix}`"
        searchable
        button-block
        v-model="form.accounts"
        @input="$_changeTrafficSourceAccount"
        :disabled="mode.accounts.length === 0"
      >
        <template v-for="item in mode.accounts" v-slot:[`ITEM_BEFORE_${item.id}`]>
          <span
            :key="item.id"
            class="option-tooltip"
            :class="{ 'not-allowed': item.disabled }"
            v-b-tooltip.hover.bottom="{
              html: true,
              customClass: 'custom-tooltip max-width-400',
              title: item.name,
              interactive: false,
              boundary: 'viewport',
            }"
            @click="onOptionClick(item)"
          />
        </template>
        <template #ITEM="{ option }">
          <img class="acc-item-icon" :src="getTrafficSourceLogo(option.typeName)" alt="traffic-source-logo" />
          <div class="acc-item-name d-flex m-0">
            <div class="acc-item-name-text">
              {{ option.name }}
            </div>
            <div v-if="option.disabled" class="disabled-account-info">
              <i class="fa fa-check-circle" aria-hidden="true"></i> (Already Connected)
            </div>
          </div>
        </template>

        <template #HEADER_2>
          <header class="select-dropdown-actions">
            <b-btn @click="$_toggleSelectAllAccounts" class="secondary-button select-all-btn">
              {{ $_toggleSelectText }} All Accounts
            </b-btn>
          </header>
        </template>
      </vue-opti-select-light>
    </div>
  </div>
  <!-- </template>
  </div> -->
</template>

<script>
/* eslint-disable */
import notifications from "@sh/mixins/notifications";
import { getTrafficSourceLogo } from "@sh/helpers"; 
import configFile from "./config";

export default {
  name: "MultiAccountForm",
  mixins: [notifications],
  props: {
    mode: {
      type: Object,
      default: () => ({ action: "create", accounts: null, typeName: null }),
    },
    submitBtn: { type: Boolean, default: false },
  },
  data() {
    return {
      preloader: true,
      config: null,
      form: {
        type: null,
        accounts: [],
      },
      alert: {
        type: "danger",
        message: "",
      },
      watchList: {},
      trafficSourceAccountOptions: [],
      allAccounts: [],
    };
  },
  computed: {
    availableOptions() {
      return this.mode.accounts.filter((account) => !account.disabled);
    },
    $_toggleSelectText() {
      return this.form.accounts.length === this.availableOptions.length ? 'Deselect' : 'Select';
    },
  },
  async created() {
    this.preloader = true;
    try {
      await this.$_init();
    } catch (error) {
      console.log(error);
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: "An error occurred" });
      }
    }
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    async $_init() {
      /** ***************** Load Config **************** */
      this.config = await configFile.getConfig();
      this.allAccounts = await this.$apiStore.trafficSources.accounts();
      this.$_checkIfAccountAlreadyCreated();
      this.$emit("ready");
    },
    $_changeTrafficSourceAccount(options){
      this.trafficSourceAccountOptions=options
    },
    async save() {
     this.$_dismissAlert();
      try {
           let request = null;
            if (this.trafficSourceAccountOptions.length < 1) {
              this.$_showAlert("danger", "Please select at least one account!");
              return false;
            }
            request = await this.config.api.trafficSources.createBulkAccounts(this.trafficSourceAccountOptions);
            this.$n_successNotification({ title: 'Account Created!' });
            this.$emit("save", request);
            return request;
          } catch (error) {
            try {
              this.$_showAlert("danger", error.response.data.message);
            } catch (err) {
              this.$_showAlert("danger", "An error occurred");
            }
            return false;
          }
    },
    $_showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
    },
    $_dismissAlert() {
      this.alert.message = "";
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_checkIfAccountAlreadyCreated() {
      this.allAccounts.forEach((account) => {
        const filtered = this.mode.accounts.filter((acc) => acc.settings.adAccountId === account.settings.adAccountId);
        if (filtered.length > 0) {
          filtered[0].disabled = true;
          this.mode.accounts = this.mode.accounts.filter((acc) => acc.settings.adAccountId !== filtered[0].settings.adAccountId);
          this.mode.accounts.push(filtered[0])
        }
      })
    },
    $_toggleSelectAllAccounts() {
      if (this.form.accounts.length === this.availableOptions.length) {
        this.$refs.trafficSourceAccount.clear();
      } else {
        this.$refs.trafficSourceAccount.add(this.availableOptions.map((account) => account.id));
      }
      this.$refs.trafficSourceAccount.touched = true;
    },
    onOptionClick(item) {
      if (this.form.accounts.find((account) => account.id === item.id) || item.disabled) {
        this.$refs.trafficSourceAccount?.remove(item.id);
      } else {
        this.$refs.trafficSourceAccount?.add(item.id);
      }
    }
  },
};
</script>

<style lang="scss">
.traffic-source-wraper {
  .alert {
    margin-bottom: 2.5rem;

    .close {
      font-size: 2.5rem;
    }
  }

  .optimizer-form-group {
    margin-top: 2.5rem;
    margin-bottom: 0;
  }

  .row {
    [class^="col-"] {
      &:nth-of-type(-n + 2) {
        .optimizer-form-group {
          margin-top: 0;
        }
      }
    }
  }

  .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px;
  }
  .row-footer {
    margin-bottom: 20px;
  }
  .v-select {
    .vs__dropdown-menu {
      z-index: 9999999;
    }
  }
  .form-group {
    .field-info {
      margin-left: 5px;
    }
  }

  .linked-tracker-dropdown {
    &:first-of-type {
      margin-left: 0 !important;
    }

    .btn.dropdown-toggle {
      background: $color-white;
      border: 0.1rem solid #e5e5e5;
      border-radius: 0.6rem;
      color: $black-500;
      font-size: 1.4rem;
      padding: 0.7rem 1.2rem;
      min-width: 10rem;
    }

    .dropdown-menu {
      padding: 1rem;
      background: $color-white;
      border: none;
      box-shadow: $bs-lg;
      border-radius: 0.6rem;
      color: $color-dark;

      li {
        a {
          display: flex;
          align-items: center;
          padding: 0.6rem 1.5rem 0.6rem 1.2rem;
          min-width: 12rem;
          border-radius: 0.4rem;
          color: $black-500;
          font-size: 1.4rem;

          &:active,
          &:focus {
            background: $color-light;
          }

          &:hover {
            color: $color-dark;
            background: $color-light;
          }

          i {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .optimizer-select {
    .select-dropdown-actions {
      padding-bottom: 0.5rem;
      border-bottom: 0.1rem solid $table-border-color;
    }

    .button-placehoder-selected .acc-item-name-text {
      max-width: unset !important;
    }

    .acc-item-name {
      width: 100%;

      &-text {
        max-width: 65%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 1rem;
      }

      .disabled-account-info {
        font-size: 1rem;
      }
    }
  }
}
</style>
