import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import ChannelUi from '../components/ChannelUi';
import ChannelActionBar from '../components/ChannelActionBar';
import notifications from '@sh/mixins/notifications';

export default {
  components: { LoadingSkeleton, ChannelUi, ChannelActionBar },
  mixins: [notifications],
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      loadingUpdateSettings: false,
    };
  },
  computed: {
    $c_channel() {
      return this.config.notification.model.settingsModel.channels[this.name];
    },
    $c_originalChannel() {
      return this.config.notification.model.settings.channels[this.name];
    },
    $c_hasChanges() {
      return JSON.stringify(this.$c_channel) !== JSON.stringify(this.$c_originalChannel);
    },
  },
  methods: {
    $_update({ item, key, value, type }) {
      if (type === 'delete') {
        this.config.notification.model.deleteAccount(this.name, item);
      } else if (type === 'status') {
        if (['RE', 'AI', 'AN'].includes(key)) {
          item.notification[key] = value;
          if (key === 'AI') {
            item.notification.DA = item.notification.AI;
            item.notification.MAT = item.notification.AI;
          }
        } else {
          item[key] = value;
        }
      }
    },
    async $_updateSettings() {
      this.loadingUpdateSettings = true;
      try {
        this.config.notification.model.updateSettings();
        this.$n_successNotification({ title: `${this.name.charAt(0).toUpperCase()}${this.name.slice(1)} notification successfully updated` });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      this.loadingUpdateSettings = false;
    },
    $_openAccountModal(account) {
      this.$refs['account-modal'].show(account);
    },
    $_addAccount(account) {
      this.config.notification.model.settingsModel.channels[this.name].accounts.push(account);
    },
  },
};
