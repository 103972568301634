<template>
  <div>
    <div class="alert alert-log">
      <div class="row">
        <div class="col-md-6">
          <p><strong>Date:</strong> {{ selectedItem.formated_date }}</p>
          <p><strong>Name:</strong> {{ campaignName }}</p>
          <p><strong>Status:</strong> <span v-html="htmlStatusFn(selectedItem.status)"></span></p>
          <p><strong>Account:</strong> <span v-html="htmlAccountNameFn(selectedItem)"></span></p>
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-end">
          <b-btn v-if="$c_retryContent" size="md" variant="outline-info" @click="$_retryContents(selectedItem)">
            <i class="fa fa-repeat" aria-hidden="true"></i> Retry Failed Contents
          </b-btn>
          <b-btn
            v-if="selectedItem.status === 'FAILED' && selectedItem.action !== 'CREATE_CONTENTS'"
            :disabled="campaignRetryLoading"
            size="md"
            variant="outline-info"
            :to="{
              name: 'CampaignCreator',
              query: {
                account: selectedItem.traffic_source_account_id,
                retry: selectedItem._id,
                mode: $_getMode(selectedItem),
              },
            }"
          >
            <i :class="[{ 'fa-spin': campaignRetryLoading }, 'fa fa-repeat']" aria-hidden="true"></i> Retry Failed
            Campaign
          </b-btn>
        </div>
        <b-alert
          v-if="selectedItem.campaign && selectedItem.campaign.error_message"
          class="error-alert"
          show
          variant="danger"
        >{{ selectedItem.campaign.error_message }}
        </b-alert>
      </div>
    </div>

    <!-- Table -->
    <div v-if="selectedItem.contents && selectedItem.contents.length" class="details-view-table">
      <b-table hover :items="selectedItem.contents" :fields="detailViewFields">
        <template slot="status" slot-scope="props">
          <span v-if="props.item.status === 'SUCCESSFUL'" class="text-success">FINISHED</span>
          <span v-else-if="props.item.status === 'FAILED'" class="text-danger">FAILED</span>
          <span v-else class="text-warning">PENDING</span>
          <b-alert v-if="props.item.error_message" class="mb-0 mt-2 px-3" show variant="danger">{{
            props.item.error_message
          }}
          </b-alert>
        </template>
        <template v-slot:cell(imageUrl)="data">
          <img class="image-thumb" :src="data.item.imageUrl" />
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import helpers from '../mixins/helpers';
import md5 from 'md5';

export default {
  name: 'DetailsTable',
  mixins: [helpers],
  props: {
    campaignRetryLoading: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    htmlStatusFn: {
      type: Function,
      default: () => '',
    },
    htmlAccountNameFn: {
      type: Function,
      default: () => '',
    },
    campaignName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      detailViewFields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'imageUrl',
          label: 'Image',
        },
        {
          key: 'headline',
          label: 'Headline',
        },
        {
          key: 'targetUrl',
          label: 'Target Url',
        },
      ],
    };
  },
  computed: {
    $c_retryContent() {
      return (
        this.selectedItem.status === 'PARTIALLY FINISHED'
        || ((this.selectedItem.status === 'PARTIALLY FINISHED' || this.selectedItem.status === 'FAILED')
          && this.selectedItem.action === 'CREATE_CONTENTS')
      );
    },
  },
  methods: {
    $_retryContents(item) {
      const md5el = [item.traffic_source_account_id.toString(), item.campaign.campaign_id].join('|');
      const query = {
        account_id: item.traffic_source_account_id,
        campaign_id: item.campaign_id || item.campaign.id || md5(md5el),
        target_url: item.contents[0].targetUrl,
        retryId: item._id,
        default: JSON.stringify(this.selectedItem.contents),
        contents: this.selectedItem.contents,
      };
      try {
        if (item.campaign.ad_group) {
          query.ad_group = JSON.stringify({
            id: item.campaign.ad_group.traffic_source_adgroup_id,
            name: item.campaign.ad_group.name,
          });
        }
      } catch (err) {
        /* -- Do Nothing -- */
      }
      this.$router.push({
        name: 'ContentUploader',
        query,
      });
    },
    $_getMode(selectedItem) {
      switch (selectedItem.action) {
        case 'CREATE_CAMPAIGNS':
          return 'create';
        case 'EDIT_CAMPAIGNS':
          return 'edit';
        case 'CLONE_CAMPAIGNS':
          return 'clone';
        case 'CREATE_CAMPAIGNS_CSV':
          return 'create';
        default:
          return '';
      }
    },
  },
};
</script>
