export default (F) => ({
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: {
        parse: () => 1,
        content: (totals) => `Total: ${totals.name}`,
      },
    },
  }),
});
