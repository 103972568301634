<template>
  <div :class="`cc-checkbox-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('input')" class="mb-0">
      <b-form-checkbox-group
        class="optimizer-checkbox-form-group"
        @input="$_emit('selected')"
        v-model="selected"
        :options="model.options"
        :disabled="model.disabled"
        name="input"
        :data-vv-as="model.label"
        :state="$_hasError('input')"
        v-validate="model['v-validate']"
      >
      </b-form-checkbox-group>
      <small slot="invalid-feedback">{{ $_getError('input') }}</small>
    </b-form-group>
    <div v-show="loading" class="col-md-auto">
      <i class="fa fa-spinner fa-spin" /> Loading...
    </div>
    <p v-if="noDataAvailable && isForBrowserTargeting" class="text-left">
      Browser targeting in not available!
    </p>
    <p v-if="errorMessage.length" class="error-message">Something went wrong! Please try again in a few seconds.</p>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcCheckbox',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    isForBrowserTargeting: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
      localOptions: [],
      errorMessage: '',
      loading: false,
      noDataAvailable: false,
    };
  },
  created() {
    // Set Static OR Dynamic Options
    this.localOptions = this.model.options || [];
    if (this.model.getOptions && this.localOptions.length === 0) {
      this.loading = true;
      this.model.getOptions().then((res) => {
        if (res) {
          this.model.options = res;
          this.localOptions = this.model.options;
        }
        if (!this.model.options.length) { this.noDataAvailable = true; }
        this.loading = false;
      }).catch((err) => {
        console.error(err);
        this.errorMessage = err.message;
        this.loading = false;
      });
    }
    // Bind Model
    this.$watch('value', (value) => {
      this.selected = value;
    }, { immediate: true });
  },
};
</script>

<style lang="scss">
.cc-checkbox-wrapper {
  height: 3.7rem;
  display: flex;
  align-items: center;

  .error-message {
    color: red;
    font-size: 1.4rem;
  }
}
</style>
