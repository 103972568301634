export default [
  {
    text: 'Automotive',
    value: 100,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    text: 'Books and Literature',
    value: 101,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    text: 'Events and Attractions',
    value: 102,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        text: 'Events',
        value: 103,
      },
      {
        text: 'Casinos and Gambling',
        value: 105,
      },
      {
        text: 'Lottery',
        value: 258,
      },
    ],
  },
  {
    text: 'Family and Relationships',
    value: 106,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        text: 'Marriage and Civil Unions',
        value: 107,
      },
      {
        text: 'Dating',
        value: 108,
      },
      {
        text: 'Pick up',
        value: 109,
      },
    ],
  },
  {
    text: 'Food and Drink',
    value: 110,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        text: 'World Cuisines',
        value: 111,
      },
      {
        text: 'Alcoholic Beverages',
        value: 112,
      },
    ],
  },
  {
    text: 'Healthy Living',
    value: 113,
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        text: 'Nutrition',
        value: 114,
        camaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
      },
      {
        text: 'Weight Loss',
        value: 115,
      },
      {
        text: 'Women\'s Health',
        value: 116,
      },
      {
        text: 'Children\'s Health',
        value: 117,
      },
      {
        text: 'Fitness and Exercise',
        value: 118,
      },
      {
        text: 'Wellness',
        value: 119,
        children: [
          {
            value: 120,
            text: 'Alternative Medicine',
          },
          {
            value: 121,
            text: 'Smoking Cessation',
          },
          {
            value: 122,
            text: 'Muscle Building',
          },
          {
            value: 123,
            text: 'Brain Booster',
          },
        ],
      },
    ],
  },
  {
    value: 124,
    text: 'Home and Garden',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 125,
        text: 'Home Appliances',
      },
      {
        value: 126,
        text: 'Home Security',
      },
      {
        value: 127,
        text: 'Home Improvement',
      },
      {
        value: 128,
        text: 'Gardening',
      },
    ],
  },
  {
    value: 129,
    text: 'Medical Health',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 130,
        text: 'Diseases and Conditions',
        children: [
          {
            value: 131,
            text: 'Sleep Disorders',
          },
          {
            value: 132,
            text: 'Diabetes',
          },
          {
            value: 133,
            text: 'Varicosis',
          },
          {
            value: 134,
            text: 'Bone and Joint Conditions',
          },
          {
            value: 135,
            text: 'Eye and Vision Conditions',
          },
          {
            value: 136,
            text: 'Psoriasis',
          },
          {
            value: 137,
            text: 'Papilloma',
          },
          {
            value: 138,
            text: 'Skin and Dermatology',
          },
          {
            value: 139,
            text: 'Medical Services',
          },
          {
            value: 140,
            text: 'Other Treatment',
          },
          {
            value: 141,
            text: 'Foot Health',
          },
          {
            value: 142,
            text: 'Parasites',
          },
          {
            value: 143,
            text: 'Hemorrhoid',
          },
          {
            value: 144,
            text: 'Prostatitis',
          },
          {
            value: 145,
            text: 'Sexual Health',
          },
          {
            value: 270,
            text: 'Blood Disorders',
          },
          {
            value: 271,
            text: 'Digestive Disorders',
          },
          {
            value: 272,
            text: 'Endocrine and Metabolic Diseases',
          },
          {
            value: 273,
            text: 'Heart and Cardiovascular Diseases',
          },
        ],
      },
      {
        value: 281,
        text: 'Cancer',
        children: [],
      },
      {
        value: 251,
        text: 'Ear, Nose and Throat Conditions',
        children: [],
      },
      {
        value: 280,
        text: 'Infectious Diseases',
        children: [],
      },
    ],
  },
  {
    value: 146,
    text: 'Personal Finance',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 147,
        text: 'Personal Investing',
        children: [
          {
            value: 148,
            text: 'Stocks and Bonds',
          },
          {
            value: 149,
            text: 'Options',
          },
        ],
      },
      {
        value: 150,
        text: 'Insurance',
      },
      {
        value: 151,
        text: 'Personal Debt',
      },
      {
        value: 152,
        text: 'Financial Assistance',
      },
      {
        value: 153,
        text: 'Retirement Planning',
      },
    ],
  },
  {
    value: 154,
    text: 'Real Estate',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    value: 155,
    text: 'Shopping',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 156,
        text: 'For Kids',
      },
      {
        value: 157,
        text: 'Gifts and Souvenirs',
      },

      {
        value: 159,
        text: 'Couponing',
      },
      {
        value: 260,
        text: 'Content Media Format',
      },
      {
        value: 261,
        text: 'Other services',
      },
      {
        value: 262,
        text: 'Charity Funds',
      },
      {
        value: 263,
        text: 'Business Services',
      },
      {
        value: 264,
        text: 'Education',
      },
      {
        value: 265,
        text: 'Legal Services Industry',
      },
      {
        value: 266,
        text: 'Language Learning',
      },
      {
        value: 267,
        text: 'Delivery',
      },
      {
        value: 268,
        text: 'Mobile Services',
      },
    ],
  },
  {
    value: 160,
    text: 'Sports',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 160,
        text: 'Sports',
      },
      {
        value: 161,
        text: 'Sporting Goods',
      },
      {
        value: 162,
        text: 'Fishing Sports',
      },
    ],
  },
  {
    value: 163,
    text: 'Style and Fashion',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 164,
        text: 'Beauty',
        children: [
          {
            value: 165,
            text: 'Makeup and Accessories',
          },
          {
            value: 166,
            text: 'Natural and Organic Beauty',
          },
          {
            value: 167,
            text: 'Skin Care',
          },
          {
            value: 168,
            text: 'Hair Care',
          },
          {
            value: 169,
            text: 'Perfume and Fragrance',
          },
          {
            value: 170,
            text: 'Other Beauty Products',
          },
        ],
      },
      {
        value: 171,
        text: 'Personal Care',
        children: [
          {
            value: 172,
            text: 'Oral Care',
          },
          {
            value: 173,
            text: 'Shaving',
          },
        ],
      },
      {
        value: 174,
        text: 'Women\'s Fashion',
        children: [
          {
            value: 175,
            text: 'Women\'s Clothing',
            children: [
              {
                value: 176,
                text: 'Women\'s Intimates and Sleepwear',
              },
              {
                value: 177,
                text: 'Women\'s Outfits',
              },
            ],
          },
          {
            value: 178,
            text: 'Women\'s Accessories',
            children: [
              {
                value: 179,
                text: 'Women\'s Jewelry and Watches',
              },
              {
                value: 180,
                text: 'Other Women\'s Accessories',
              },
            ],
          },
          {
            value: 181,
            text: 'Women\'s Shoes and Footwear',
          },
        ],
      },
      {
        value: 182,
        text: 'Men\'s Fashion',
        children: [
          {
            value: 183,
            text: 'Men\'s Сlothing',
            children: [
              {
                value: 184,
                text: 'Men\'s Underwear and Sleepwear',
              },
              {
                value: 185,
                text: 'Men\'s Outfits',
              },
            ],
          },
          {
            value: 186,
            text: 'Men\'s Accesories',
            children: [
              {
                value: 187,
                text: 'Men\'s Jewelry and Watches',
              },
              {
                value: 188,
                text: 'Other Men\'s Accesories',
              },
            ],
          },
          {
            value: 189,
            text: 'Men\'s Shoes and Footwear',
          },
        ],
      },
      {
        value: 190,
        text: 'Children\'s Clothing',
      },
    ],
  },
  {
    value: 191,
    text: 'Technology and Computing',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 192,
        text: 'Computing',
        children: [
          {
            value: 193,
            text: 'Laptops',
          },
          {
            value: 194,
            text: 'Desktops',
          },
          {
            value: 195,
            text: 'Computer Peripherals',
          },
          {
            value: 196,
            text: 'Computer Software and Applications',
          },
        ],

      },
      {
        value: 197,
        text: 'Consumer Electronics',
        children: [
          {
            value: 198,
            text: 'Smartphones',
          },
          {
            value: 199,
            text: 'Tablets and E-readers',
          },
          {
            value: 200,
            text: 'Cameras and Camcorders',
          },
          {
            value: 201,
            text: 'Wearable Technology',
          },
          {
            value: 202,
            text: 'Energysavers',
          },
          {
            value: 203,
            text: 'Self Defense',
          },
          {
            value: 204,
            text: 'Solar Panels',
          },
          {
            value: 205,
            text: 'Other Electronics',
          },
        ],
      },
    ],
  },
  {
    value: 206,
    text: 'Travel',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    value: 207,
    text: 'Video Gaming',
    campaing_types: ['product', 'video', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    value: 208,
    text: 'Automotive',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 209,
    text: 'Business and Finance',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 210,
    text: 'Careers',
    campaing_types: ['product', 'push'],
    children: [],
  },
  {
    value: 211,
    text: 'Education',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 212,
    text: 'Events and Attractions',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 213,
    text: 'Family and Relationships',
    campaing_types: ['content'],
    children: [
      {
        value: 214,
        text: 'Society',
      },
      {
        value: 215,
        text: 'Parenting',
      },
      {
        value: 216,
        text: 'Sex',
      },
    ],
  },
  {
    value: 217,
    text: 'Fine Art',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 218,
    text: 'Food and Drink',
    campaing_types: ['content'],
    children: [
      {
        value: 219,
        text: 'Cooking',
      },
      {
        value: 220,
        text: 'Alcoholic Beverages',
      },
    ],
  },
  {
    value: 221,
    text: 'Healthy Living',
    campaing_types: ['content'],
    children: [
      {
        value: 222,
        text: 'Wellness',
      },
      {
        value: 223,
        text: 'Nutrition',
      },
      {
        value: 224,
        text: 'Fitness and Exercise',
      },
    ],
  },
  {
    value: 225,
    text: 'Hobbies and Interests',
    campaing_types: ['content', 'push'],
    children: [
      {
        value: 226,
        text: 'Amazing',
      },
      {
        value: 227,
        text: 'Humor',
      },
      {
        value: 228,
        text: 'Interests',
      },
      {
        value: 274,
        text: 'General Social',
      },
      {
        value: 275,
        text: 'Entertainment',
      },
      {
        value: 276,
        text: 'Lifehacks',
      },
      {
        value: 277,
        text: 'Discoveries and Innovations',
      },
    ],
  },
  {
    value: 229,
    text: 'Home and Garden',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 230,
    text: 'Movies',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 231,
    text: 'Music and Audio',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 232,
    text: 'News and Politics',
    campaing_types: ['content', 'push'],
    children: [
      {
        value: 233,
        text: 'Politics',
      },
      {
        value: 234,
        text: 'Crime',
      },
      {
        value: 235,
        text: 'Disasters',
      },
    ],
  },
  {
    value: 238,
    text: 'Pets',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 239,
    text: 'Pop Culture',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 240,
    text: 'Real Estate',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 241,
    text: 'Science',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 242,
    text: 'Sports',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 243,
    text: 'Style and Fashion',
    campaing_types: ['content'],
    children: [
      {
        value: 244,
        text: 'Beauty',
      },
      {
        value: 245,
        text: 'Fashion Trends',
      },
      {
        value: 246,
        text: 'Body Art',
      },
    ],
  },
  {
    value: 247,
    text: 'Technology and Computing',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 248,
    text: 'Television',
    campaing_types: ['content', 'push'],
    children: [],
  },
  {
    value: 249,
    text: 'Travel',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 250,
    text: 'Video Gaming',
    campaing_types: ['content'],
    children: [],
  },
  {
    value: 251,
    text: 'Pets',
    campaing_types: ['product', 'offer', 'push', 'search_feed'],
    children: [],
  },
  {
    value: 253,
    text: 'Business and Investing',
    campaing_types: ['product', 'offer', 'push', 'search_feed'],
    children: [
      {
        value: 254,
        text: 'Currencies',
      },
      {
        value: 278,
        text: 'Business I.T.',
      },
    ],
  },
  {
    value: 255,
    text: 'Local News',
    campaing_types: ['content', 'push'],
    children: [
      {
        value: 236,
        text: 'News Russia',
      },
      {
        value: 237,
        text: 'News Ukraine',
      },
      {
        value: 256,
        text: 'News India',
      },
    ],
  },
  {
    value: 257,
    text: 'International News',
    campaing_types: ['content', 'push'],
    children: [],
  },
];
