<template>
  <div class="settings-tab optimizer-card" v-if="!preloader">
    <div class="settings-tab__header">
      <h4 class="settings-tab__header__title">Notification <sub>{{ label }} accounts {{ channel.accounts.length }}/{{ channel.max }}</sub></h4>
      <b-btn class="add-new-btn primary-button" @click="$emit('add')">Add account</b-btn>
    </div>
    <div class="optimizer-table">
      <vue-opti-table-light
        :name="`${name}SettingsTable`"
        class="table-wrapper"
        :hover="true"
        :header-fields="table.fields"
        :items="table.items"
        v-model="tableModel"
        :default-rows="100"
        :show-search="false"
        :show-pagination="false"
        :enable-export="false"
      >
        <template #actions="{item}">
          <div class="d-flex justify-content-center p-3">
            <switch-button
              :checked="item.isActive"
              :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
              :update="(value) => $_update({ type: 'status', item, key: 'isActive', value })"
            />
            <action-btn type="delete" :click="() => $_update({ type: 'delete', item })" />
          </div>
        </template>
        <template #RE="{item}">
          <switch-button
            :checked="item.notification.RE"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
            :update="(value) => $_update({ type: 'status', item, key: 'RE', value })"
          />
        </template>
        <template #AI="{item}">
          <switch-button
            :checked="item.notification.AI"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
            :update="(value) => $_update({ type: 'status', item, key: 'AI', value })"
          />
        </template>
        <template #AN="{item}">
          <switch-button
            :checked="item.notification.AN"
            :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
            :update="(value) => $_update({ type: 'status', item, key: 'AN', value })"
          />
        </template>
      </vue-opti-table-light>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChannelUi',
  props: {
    channel: { type: Object },
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    update: { type: Function, default: () => {} },
    columns: { type: Array, default: () => ['RE', 'AI', 'AN'] },
    getNotificationField: { type: Function, default: () => {} },
  },
  data() {
    return {
      preloader: true,
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      table: {
        fields: [],
        items: [],
      },
    };
  },
  created() {
    this.preloader = true;
    // Set Table Columns
    this.table.fields.push(this.getNotificationField('actions', this.label));
    this.columns.forEach((key) => { this.table.fields.push(this.getNotificationField(key, this.label)); });
    this.table.items = this.channel.accounts;
    this.$watch('channel', (channel) => {
      this.table.items = channel.accounts;
    }, { deep: true });
    // Render UI
    this.preloader = false;
  },
  methods: {
    $_update({ type, item, key, value }) {
      return this.update({ type, item, key, value });
    },
  },
};
</script>

<style lang="scss">
  .settings-tab {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: .1rem solid #ececec;
      padding: 2rem;

      &__title {
        font-size: 1.8rem;

        sub {
          bottom: 0;
          color: $text-secondary;
          font-weight: normal;
          margin-left: .7rem;
          font-size: 1.3rem;
        }
      }

      .add-new-btn {
        background: #24C2F3;
        border-color: #24C2F3;

        &:hover,
        &:focus,
        &:active {
          background: #13afdf !important;
          border-color: #13afdf !important;
        }
      }
    }

    .optimizer-table {

      .table-holder {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      // table {
      //   tr {
      //     &:last-of-type {
      //       td {
      //         &:first-of-type {
      //           border-bottom-left-radius: 1rem;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
</style>
