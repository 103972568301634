/* eslint-disable import/no-cycle */
import { SupportedEntities } from '@/views/Automation/CampaignCreatorV2/store/Types';
import { AdABGroups } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import { AdSetAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/AdSet';
import { CampaignAbGroupTypes } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import { CreativeAbGroups } from '@/views/Automation/CampaignCreatorV2/validation/Creative';

const groupMacros = [{ macro: 'VARIABLE_NAME', valueType: 'string', type: 'name', suffix: 'Group Name' }];

export const FacebookAbGroups = {
  [CampaignAbGroupTypes.CampaignBudget]: {
    readableName: 'Budget',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.campaign,
    isActive: () => true,
  },
  [AdSetAbGroups.BUDGET]: {
    readableName: 'Budget',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.adset,
    isActive: () => true,
  },
  [AdSetAbGroups.TARGETING]: {
    readableName: 'Audience',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.targeting,
    isActive: () => true,
  },
  [AdSetAbGroups.PLACEMENT]: {
    readableName: 'Placement',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.targeting,
    isActive: () => true,
  },
  [AdABGroups.IDENTITY]: {
    readableName: 'Identity',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.ad,
    isActive: () => true,
  },
  [CreativeAbGroups.CREATIVE_ITEMS]: {
    readableName: 'Creative',
    macros: groupMacros,
    public: true,
    entity: SupportedEntities.creative,
    isActive: () => true,
  },
} as const;
