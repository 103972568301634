<template>
  <div class="rule-bid-wrapper">
    <RuleBidOptions
      :template="config.targetCpaConfig.targetCpaModelTemplates[ruleModel.rule.components.targetCpa.template]"
      :action-options="config.targetCpaConfig.targetCpaActionOptions"
      :to-options="config.targetCpaConfig.targetCpaToOptions"
      :unit-options="config.targetCpaConfig.targetCpaUnitOptions"
      v-model="form"
      :bid="ruleModel.rule.components.targetCpa"
      @max-limit-exhausted="$_maxLimitExhausted"
      name="Target CPA"
      ref="ruleBidOptions"
    />
    <RuleMinMax v-if="$c_showMinMax"
                name="Target CPA"
                :min="form.min"
                :minObject="form.minObject"
                :min-info="'If the new calculated CPA will be lower than this limit, then the CPA will be set to this value'"
                @min="$_handleMin"
                @minObject="$_handleMinObject"
                :max="form.max"
                :maxObject="form.maxObject"
                :max-info="'If the new calculated CPA will be higher than this limit, then the CPA will be set to this value'"
                @max="$_handleMax"
                @maxObject="$_handleMaxObject"
                :unit="ruleModel.rule.rule_action"
                ref="ruleMinMax"
    />
    <div v-if="showResetStrategy" class="row col-md-12 switch-wrapper">
      <switch-button
        :checked="form.reset_strategy"
        :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
        :update="(value) => $_updateResetStrategy(value)"
      />
    </div>
  </div>
</template>

<script>
import RuleBidOptions from './subcomponents/RuleBidOptions.vue';
import RuleMinMax from './subcomponents/RuleMinMax.vue';
import component from '../mixins/component';

export default {
  name: 'RuleTargetCpa',
  components: { RuleBidOptions, RuleMinMax },
  mixins: [component],
  props: {
    value: {
      type: Object,
      default: () => ({
        action: 'set', unit: 'static', to: '', value: '', min: '', max: '', minObject: { unit: 'static', to: '', value: '' }, maxObject: { unit: 'static', to: '', value: '' },
      }),
    },
    showResetStrategy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    $c_showMinMax() {
      return !(this.form.action === 'set' && this.form.unit === 'static');
    },
  },
  watch: {
    form: {
      handler() {
        if (!this.$c_showMinMax) {
          this.form.minObject.value = this.form.value;
          this.form.maxObject.value = this.form.value;
          this.form.min = this.form.value;
          this.form.max = this.form.value;
        }
        this.$_emit('form');
      },
      deep: true,
    },
    $c_showMinMax() {
      this.form.minObject = { unit: 'static', to: '', value: '' };
      this.form.maxObject = { unit: 'static', to: '', value: '' };
      this.form.min = '';
      this.form.max = '';
    },
  },
  created() {
    this.form = this.value;
    if (this.showResetStrategy) {
      this.form.reset_strategy = this.value.reset_strategy || false;
    }
  },
  methods: {
    async validate() {
      try {
        let subcomponentsStatus;
        if (this.$refs.ruleMinMax) {
          subcomponentsStatus = await Promise.all([this.$refs.ruleBidOptions.validate(), this.$refs.ruleMinMax.validate()]);
          if (subcomponentsStatus.includes(false)) {
            return false;
          }
          return true;
        }
        subcomponentsStatus = await this.$refs.ruleBidOptions.validate();

        return subcomponentsStatus;
      } catch (error) {
        return false;
      }
    },
    async $_maxLimitExhausted() {
      const swal = await this.$swal({
        title: 'Target CPA seems unusually high!',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Keep Anyway',
        cancelButtonText: 'Reset',
      });
      if (swal.value !== true) this.form.value = '';
    },
    $_updateResetStrategy(value) {
      this.form.reset_strategy = value;
    },
    $_handleMax(value) {
      this.form.max = value;
    },
    $_handleMaxObject(maxObject) {
      if (this.form.maxObject) {
        this.form = { ...this.form, maxObject };
      }
    },
    $_handleMin(value) {
      this.form.min = value;
    },
    $_handleMinObject(minObject) {
      if (this.form.minObject) {
        this.form = { ...this.form, minObject };
      }
    },
  },
};
</script>

<style lang="scss">
.rule-bid-wrapper {

}
.text-with-info {
  margin-top: .2rem;
  i {
    color: gray;
  }
}
.switch-wrapper {
  margin-top: 1.5rem;
}

</style>
