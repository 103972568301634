
import notifications from '@sh/mixins/notifications';
import { AccountWizard } from '@sh/types';
import Vue, { VueConstructor } from 'vue';

const VueComponent = Vue as VueConstructor<Vue & AccountWizard.MediaNet.RefsAndApi & NotificationMixin>;

export default VueComponent.extend({
  name: 'MediaNetTokens',
  mixins: [notifications],
  props: {
    tracker: {
      type: Object,
      default: null,
      required: true,
    },
    source: {
      type: Array,
      default: null,
      required: true,
    },
    accountId: { type: String || null, default: null },
  },
  data(): AccountWizard.MediaNet.Data {
    return {
      configuredTokens: [],
      dropdownOptions: [],
      requiredTsTokens: [],
      userSelection: {},
      customToken: null,
      connectTitle: 'Connect tokens',
      customTrackingEnabled: false,
      presetCustomConfiguration: null,
      revenueStreamUniqueSeperators: {
        optimizer: {
          separator: '|',
        },
        Media: {
          separator: '_',
        },
      },
      disabledTokens: {},
      preloader: true,
      validForm: false,
      items: [],
      mode: 'create',
    };
  },
  computed: {
    $c_presetCustomConfiguration: {
      get(): string | undefined {
        if (this.presetCustomConfiguration) return this.presetCustomConfiguration;
      },
      set(configuration: string): void {
        this.presetCustomConfiguration = configuration;
      },
    },
    $c_isEditMode(): boolean {
      return this.mode === 'edit';
    },
  },
  watch: {
    source: {
      immediate: true,
      async handler() {
        this.configuredTokens = [];
        if (this.source) {
          const tokens = await this.getTokens(this.source);
          if (tokens[0]) {
            // in the case of multiple traffic sources selected, we only need to get the tokens from the first one, since they will share the same configuration
            [tokens[0]].forEach((token) => {
              // we define how many available tokens each traffic source can have, this number can range depending on the traffic source
              Object.keys(token).map((k, i) => (this.userSelection[`token${i + 1}`] = null));

              // eslint-disable-next-line guard-for-in
              for (const key in token) {
                // we need to keep track of the tokens that are disabled, this is because we need to disable the tokens that are already selected in the custom tracking
                this.disabledTokens[`token.${key}`] = false;
                // the configuration we need to build is based on the tokens that are required by the traffic source
                this.configuredTokens.push({
                  macros: token[key].values.map((m: any, k: any) => ({
                    value: m,
                    found: false,
                    assignedTo: null,
                    hardCodedValue: `token${k + 1}.${key}`,
                    isCustomToken: false,
                    placeholder: `token.${key}`,
                  })),
                  hasMultipleMacros: token[key].values.length > 1,
                  message: token[key].message,
                  key,
                  placeholder: `token.${key}`,
                });
                this.requiredTsTokens.push(token[key].message);
              }
            });
            this.buildCustomTrackingToken();
          }
        }
      },
    },
    tracker: {
      immediate: true,
      async handler() {
        if (this.tracker.id) {
          const response = await this.$_getTrackerSources(this.tracker.id);
          if (response) {
            for (const key of Object.keys(response.fieldsName)) {
              this.dropdownOptions.push({
                name: key,
                id: key,
              });
            }
          }
          await this.getTrafficSourceConnected();
          this.preloader = false;
        }
      },
    },
    presetCustomConfiguration: {
      handler() {
        this.checkCustomTracking();
      },
    },
    customToken: {
      handler() {
        this.checkCustomTracking();
      },
    },
    customTrackingEnabled: {
      handler() {
        this.clearCustomTrackingSelection();
        this.customToken = null;
      },
    },
  },
  methods: {
    setCustomToken(id: string | null) {
      this.customToken = id;
    },
    showToast() {
      this.$refs.tokenConfig.show();
    },
    async getTokens(sources: any[]) {
      const promises = [];
      for (let i = 0; i < sources.length; i++) {
        promises.push(this.$_getTokens(sources[i].type.uniqueName));
      }
      return Promise.all(promises);
    },
    async $_getTokens(type: string) {
      try {
        const request = await this.$api.trafficSources.tokens(type);
        return request;
      } catch (error) {
        return false;
      }
    },
    async $_getTrackerSources(trackerId: number) {
      try {
        const request = await this.$api.trackers.sources(trackerId);
        return request || false;
      } catch (error) {
        this.$n_failNotification({
          title: `We are not able to get the list of traffic sources from your ${this.tracker.type.uniqueName} account. Please contact support for further assistance.`,
        });
        return false;
      }
    },
    assignToken(id: string, key: any, tokenKey: string) {
      const tKey = `token${key}`;
      const allKeys = Object.keys(this.userSelection);
      const allValues = Object.values(this.userSelection);

      // if the user selects the same token twice the previous dropdown will be reset as well as the value will be set to "not found"
      if (allValues.includes(id)) {
        allKeys.forEach((k) => {
          if (k !== key && this.userSelection[k] === id) {
            this.userSelection[k] = null;
          }
        });
        this.configuredTokens.forEach((c) => {
          c.macros.forEach((m: any) => {
            if (m.assignedTo === id) {
              m.found = false;
              m.assignedTo = null;
            }
          });
        });
      }

      // if custom token is equal to the id selected from one of the dropdowns we need to reset the custom token
      if (this.customToken === id) {
        this.customToken = null;
      }

      // else we assign the token to the corresponding macro that we have in the configured tokens
      this.userSelection[tKey] = id;
      this.configuredTokens.forEach((c) => {
        if (c.key === tokenKey) {
          c.macros.forEach((m) => {
            m.found = true;
            m.assignedTo = id;
          });
        }
      });
    },
    clearSelection(key: any, tKey: string) {
      this.userSelection[`token${key}`] = null;
      this.configuredTokens.forEach((c) => {
        if (c.key === tKey) {
          c.macros.forEach((m) => {
            m.found = false;
          });
        }
      });
    },
    $_enableCustomTracking(value: boolean) {
      this.customTrackingEnabled = value;
      // when the user disables the custom tracking we need to enable all the tokens that were disabled
      if (!value) {
        for (const disabledToken of Object.entries(this.disabledTokens)) {
          this.disabledTokens[disabledToken[0]] = false;
        }
      }
    },
    buildCustomTrackingToken() {
      // it builds the custom tracking token based on the configured tokens, it will always take the first macro from the list of macros
      let tokens: string[] | string = [];
      this.configuredTokens.forEach((c) => {
        if (typeof tokens === 'object') {
          tokens.push(c.macros[0].value);
        }
      });
      const { separator } = this.revenueStreamUniqueSeperators[this.tracker.type.uniqueName as 'Media'];
      tokens = tokens.join(separator);
      this.$c_presetCustomConfiguration = tokens;
    },
    submitedCustomToken() {
      // this function converts the custom token into the format that the optimizer expects
      let optimizerCustomToken = this.$c_presetCustomConfiguration;
      this.configuredTokens.forEach((c) => {
        c.macros.forEach((macro) => {
          optimizerCustomToken = optimizerCustomToken
            ? optimizerCustomToken.replace(macro.value, macro.hardCodedValue)
            : '';
        });
      });
      const { separator } = this.revenueStreamUniqueSeperators[this.tracker.type.uniqueName as 'Media'];
      optimizerCustomToken = optimizerCustomToken
        ? optimizerCustomToken.replaceAll(separator, this.revenueStreamUniqueSeperators.optimizer.separator)
        : '';

      this.configuredTokens.forEach((c) => {
        c.macros.forEach((macro) => {
          optimizerCustomToken = optimizerCustomToken
            ? optimizerCustomToken.replace(macro.hardCodedValue, macro.value)
            : '';
        });
      });
      return optimizerCustomToken;
    },
    checkCustomTracking() {
      if (!this.customTrackingEnabled && !this.customToken) return;
      // if customToken is null or custom tracking is not enabled we need to reset all the macros that had been selected
      if (this.customToken === null || !this.customTrackingEnabled) {
        this.clearCustomTrackingSelection();
        return;
      }
      // if the user selects the same token with the previous dropdown, the dropdown will be reset aswell as the found tokens in the configuration will be set to not found
      if (this.customToken) {
        const userInput = Object.entries(this.userSelection);
        for (const i of userInput) {
          if (i.includes(this.customToken)) {
            this.userSelection[i[0]] = null;
            this.configuredTokens.forEach((c) => {
              c.macros.forEach((m) => {
                if (m.assignedTo === this.customToken) {
                  m.found = false;
                  m.assignedTo = null;
                }
              });
            });
          }
        }
      }
      const normalizedCustomToken = this.submitedCustomToken()
        .split('|')
        .filter((t) => t !== '');
      if (normalizedCustomToken.length === 3) {
        // if the user has selected all the available tokens in custom tracking, then we disable the dropdowns and clear them if they have any token selected
        for (const selection of Object.entries(this.userSelection)) {
          this.userSelection[selection[0]] = null;
        }
      }
      this.configuredTokens.forEach((item) => {
        item.macros.forEach((m) => {
          if (normalizedCustomToken.includes(m.value)) {
            m.found = true;
            m.assignedTo = this.customToken;
            m.isCustomToken = true;
            // for every token that the user puts in custom tracking the corresponding dropdown above will be set to disabled
            this.disabledTokens[`${m.placeholder}`] = true;
          } else if (!normalizedCustomToken.includes(m.value) && m.isCustomToken) {
            m.found = false;
            m.isCustomToken = false;
            m.assignedTo = null;
            // this resets the dropdowns that were disabled
            this.disabledTokens[`${m.placeholder}`] = false;
          }
        });
      });
    },
    clearCustomTrackingSelection() {
      this.configuredTokens.forEach((c) => {
        c.macros.forEach((m) => {
          if (m.isCustomToken) {
            m.found = false;
            m.isCustomToken = false;
          }
        });
      });
    },
    // in the case where we have multiple macros for a token, we need to check if at least one of them is found
    atLeastOneOfMultipleMacrosFound(macros: AccountWizard.MediaNet.Macro[]) {
      return macros.filter((m) => m.found).length > 0;
    },
    validateRequiredTokens() {
      const valuesMissing = [];
      let campaignTokenMissing = false;
      this.configuredTokens.forEach((token) => {
        const macroFound = this.atLeastOneOfMultipleMacrosFound(token.macros);
        if (!macroFound) {
          if (token.message === 'Campaign Token') {
            campaignTokenMissing = true;
          } else {
            valuesMissing.push(token.message);
          }
        }
      });
      return { valuesMissing: valuesMissing.length > 0, campaignTokenMissing };
    },
    async validate() {
      // check if campaign token is missing or the other tokens are missing
      const { valuesMissing, campaignTokenMissing } = this.validateRequiredTokens();
      try {
        if (campaignTokenMissing) {
          await this.$swal({
            title: 'Campaign Token is required!',
            html: 'You cannot complete the connection if the campaign token field is not selected.',
            footer: '',
            icon: 'error',
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonText: '',
            cancelButtonText: 'Ok',
          });
          return false;
        }
        if (valuesMissing) {
          await this.$swal({
            title: 'Are you sure to continue ?',
            html: 'The traffic source configuration found on your tracker is missing one or more of the required tracking tokens.<br>This may affect the accuracy of the statistics reported in TheOptimizer.',
            footer:
              '<a href="https://theoptimizer.io/help" target="_blank">Click here to learn more on How to fix missing tokens?</a>',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Yes, continue!',
            cancelButtonText: 'No',
          });
          this.validForm = false;
          this.getVariables();
          return true;
        }
      } catch (error) {
        return false;
      }
      this.validForm = true;
      this.getVariables();
      return true;
    },
    getVariables() {
      // this function builds the variables that we need to send to the optimizer
      const data = {
        variables: {},
        sourceIdOnTracker: null,
      };
      this.configuredTokens.forEach((token) => {
        const macroThatHasBeenFound = token.macros.filter((m) => m.found)[0];
        if (macroThatHasBeenFound) {
          if (!macroThatHasBeenFound.isCustomToken) {
            data.variables = {
              ...data.variables,
              [macroThatHasBeenFound.value]: macroThatHasBeenFound.assignedTo,
            };
          } else {
            const submitedCustomToken = this.submitedCustomToken();
            data.variables = {
              ...data.variables,
              [submitedCustomToken]: this.customToken,
            };
          }
        }
      });
      if (this.validForm) {
        this.$emit('completed', data);
      } else {
        this.$emit('uncompleted', data, true);
      }
    },
    async getTrafficSourceConnected() {
      // this function is used to show the configured tokens that the traffic source has if it is already connected
      const query = { ...this.$route.query } as { ts: string; tr: string };
      const ts = await this.$apiStore.trafficSources.accounts();
      const findTs = ts.find((item) => item.id === parseInt(query.ts, 0));
      const tracker = findTs?.linkedTrackers.find((tr) => tr.id === parseInt(query.tr, 0));
      if (tracker) {
        this.mode = 'edit';
        for (const [variable, token] of Object.entries(tracker.variables[0])) {
          if (token && variable) {
            const allVars = variable.includes('|') ? variable.split('|') : variable;
            if (Array.isArray(allVars)) {
              allVars.forEach((v) => {
                this.configuredTokens.forEach((c) => {
                  const macro = c.macros.find((m) => m.value === v);
                  if (macro) {
                    this.items.push({
                      Token: c.message,
                      Macro: macro.value,
                      Channel: token,
                    });
                  }
                });
              });
            } else {
              this.configuredTokens.forEach((c) => {
                const macro = c.macros.find((m) => m.value === allVars);
                if (macro) {
                  this.items.push({
                    Token: c.message,
                    Macro: macro.value,
                    Channel: token,
                  });
                }
              });
            }
          }
        }
      }
    },
  },
});
