<template>
  <div class="content-wrapper traffic-sources-container">

    <!-- Section Header -->
    <div class="section-header d-flex justify-content-between align-items-center mb-4">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Traffic Sources</h1>
      </div>
    </div>
    <loadizer :loading="isLoading" message="Loading..." />

    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 3 }"
    />
    <div v-else class="optimizer-table traffic-sources-table">
      <vue-opti-table-light
        ref="trafficSourcesRef"
        name="trafficSourceAccountsTable"
        class="table-wrapper"
        :hover="true"
        :header-fields="table.fields"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        :items="table.items"
        v-model="tableModel"
        :default-rows="100"
        sticky
        selectable
      >
        <template #search>
          <vue-opti-select-light
            class="col-12 optimizer-select icon-select dropdown-auto-width col-md-auto pr-3 mb-2 ts-actions"
            :class="[ tableModel.selectedRows.length ? 'active-select' : '']"
            :options="actionOptions"
            :unique-key="({ value: { value } }) => value"
            @change="({ value }) => { bulkAction(value) }"
            button-type="static"
            single
          >
            <template v-if="!tableModel.selectedRows.length" #HEADER>
              <div class="fs-13">
                Please start by choosing traffic sources.
              </div>
            </template>
            <template #BUTTON_PLACEHOLDER>
              <span class="button-placehoder-filter">
                <OptimizerIcon type="threeDots" />
                <span>Actions</span>
              </span>
            </template>
          </vue-opti-select-light>

          <TrafficSourceFilters @onFilter="onFiltersApplied" @onClear="onFiltersClear" />

          <div class="tag-filter">
            <TagFilter
              title="Tags"
              :optionsProp="getTags"
              :selected="selectedTags || []"
              :isDeleteAction="true"
              @onDelete="onRemoveTags"
              @onFilter="onTagsFilter"
              :disabled="getTags.length === 0"
            />
          </div>

        </template>
        <template #name="{ item }">
          <div class="d-flex align-items-center">
            <TransparencyProgramInfo v-if="hasTransparencyProgram(item)" :id="item.id" :email="item.settings.complianceProgram.email" :searchFeed="item.settings.complianceProgram.type" />
            <LiveEditText
              class="w-100 mr-3"
              v-model="item.name"
              :key="item.name"
              :beforeBlur="(value) => onAccountNameChange(value, item)"
            />
            <span class="accountId-text" v-if="item.updated" v-b-tooltip.hover.top
                  title="(Updated)"
            > (Updated) </span>
            <span class="accountId-text" v-if="showAccountId(item)" v-b-tooltip.hover.top
                  :title="`${item.trafficSourceType.uniqueName} Ad Account Id`"
            > - {{ item.trafficSourceType.uniqueName === 'GoogleAds' ? item.credentials.googleCustomerId : item.settings.adAccountId }}</span>
          </div>
        </template>
        <template #status="{ item }">
          <span v-if="item.status === 1" class="status-item status-item-enabled"><span class="circle" /> Enabled</span>
          <span v-else-if="item.status === 0" class="status-item status-item-disabled"><span class="circle" /> Disabled</span>
          <span v-else-if="item.status === -1" class="status-item status-item-deleted"><span class="circle" /> Deleted</span>
        </template>
        <template #type="{ item }">
          <div class="acc-item">
            <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(item.type.uniqueName)" alt="traffic-source-logo" />
            <div class="acc-item-name">{{ item.type.name }}</div>
          </div>
        </template>
        <template #tags="{ item }">
          <TagsList
            :isTagPredefined="[item.settings.timezone, item.settings.currency]"
            isRemoveIconVisible
            :tags="showExtractedTagsFromTsSettings(item)"
            @enableTagManagement="onTagClicked(item)"
            @deleteTag="tag => handleSingleTagDelete(item, tag)"
            @tagClicked="onTagClicked(item)"
          />
        </template>
        <template #linkedTrackers="{ item }">
          <template v-if="displayedLinkedTrackers[item.id].length > 0">
            <div class="tags-cell">
              <TagCell
                v-for="tracker in displayedLinkedTrackers[item.id].slice(0, 2)"
                :key="item.id + '-' + tracker.id + '-' + tracker.sourceIdOnTracker"
                :label="tracker.name"
                :image-src="tracker.uniqueName === 'ClickFlare' ? `/sh_static/accounts/ClickFlare.svg` : `/sh_static/accounts/${tracker.uniqueName}.png`"
                :alt-name="tracker.uniqueName"
                is-icon-shown
                :is-remove-icon-shown="false"
              >
                <div v-if="getTotalLinkedNumber(tracker, item) > 1" class="position-absolute badge badge-info badge-pill">
                  {{ getTotalLinkedNumber(tracker, item) }}
                </div>
              </TagCell>
              <MoreItems v-if="displayedLinkedTrackers[item.id].length > 2">
                <TagCell
                  v-for="tracker in displayedLinkedTrackers[item.id].slice(2)"
                  :key="item.id + '-' + tracker.id + '-' + tracker.sourceIdOnTracker"
                  :label="tracker.name"
                  :image-src="tracker.uniqueName === 'ClickFlare' ? `/sh_static/accounts/ClickFlare.svg` : `/sh_static/accounts/${tracker.uniqueName}.png`"
                  :alt-name="tracker.uniqueName"
                  is-icon-shown
                  :is-remove-icon-shown="false"
                >
                  <div v-if="getTotalLinkedNumber(tracker, item) > 1" class="position-absolute badge badge-info badge-pill">
                    {{ getTotalLinkedNumber(tracker, item) }}
                  </div>
                </TagCell>
              </MoreItems>
            </div>
          </template>
          <template v-else>
            <i class="text-muted">( No linked trackers )</i>
          </template>
        </template>
        <template #actions="{ item, i }">
          <div class="actions-container">
            <switch-button
              :checked="item.status"
              :enabled-value="1"
              :disabled-value="0"
              :hover-title="{ enabled: 'Stop', disabled: 'Start' }"
              :update="(value) => $_changeStatus(item, value)"
              :loading="item.loading"
            />
            <action-btn type="edit" :click="() => $_editAccount(item, i)" />
          </div>
        </template>
      </vue-opti-table-light>
      <br>
      <h4 v-if="emptyMessageDisplay" class="lead">
        <a style="color: #252D41">You do not have any traffic sources integrated yet. To start adding your integrations go to <a v-if="docUrl" @click="docUrl" class="accountWizardUrl" style="color: #4158D0"><b>Account Wizard.</b></a></a>
      </h4>
    </div>
    <TrafficSourceModal ref="refTrafficSourceModal" :canAccessComplianceProgram="canAccessComplianceProgram" @submit="$_updateAccount" @onTableUpdate="$_load" @onUpdateTransparencySettings="manageTransparencyProgram($event)" />
    <TransparencyProgramModal ref="transparencyProgramModal" @onNotification="onNotification" />
    <BulkTagInsertModal
      ref="bulkTagInsertRef"
      title="Add Tag"
      mode="Traffic Source"
      :autocompleteItems="autocompleteItems"
      @onTagsSave="onSaveClick"
      @onBulkTagInsert="onBulkClosed"
    />
  </div>
</template>

<script>
import { cloneDeep, uniqBy } from 'lodash';
import { mapActions, mapState, mapWritableState } from 'pinia';
import moment from 'moment';
import notifications from '@sh/mixins/notifications';
import configFile from '@sh/components/TrafficSources/config';
import TrafficSourceModal from '@sh/components/TrafficSources/TrafficSourceModal';
import LiveEditText from '@sh/components/Utils/LiveEditText.ts.vue';
import TransparencyProgramInfo from '@sh/components/Utils/TransparencyProgramInfo.ts.vue';
import { canAccess, getTrafficSourceLogo, pluralize, timeout } from '@sh/helpers';
import MoreItems from '@sh/components/Utils/MoreItems.ts.vue';
import TransparencyProgramModal from '@sh/components/TrafficSources/TransparencyProgramModal.ts.vue';
import { extractTagsFromTsSettings, useTrafficSourcesStore } from '@/stores/traffic-sources';
import TagFilter from '@sh/components/MediaManager/TagFilter/TagFilter.ts.vue';
import BulkTagInsertModal from '@sh/components/MediaManager/BulkTagInsertModal/BulkTagInsertModal.ts.vue';
import TrafficSourceFilters from '@sh/views/Accounts/TrafficSources/TrafficSourceFilters.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import TagCell from '@sh/components/Utils/Tags/TagCell.ts.vue';
import TagsList from '@sh/components/MediaManager/TagsContainer/TagsList.ts.vue';

export default {
  name: 'Trafficsources',
  components: {
    TagsList,
    TagCell,
    OptimizerIcon,
    TrafficSourceFilters,
    TagFilter,
    TrafficSourceModal,
    LiveEditText,
    MoreItems,
    TransparencyProgramModal,
    TransparencyProgramInfo,
    BulkTagInsertModal,
  },
  mixins: [notifications],
  data() {
    return {
      COMPONENT_ID: 'trafficSourceFilters',
      preloader: true,
      selectedTags: [],
      isLoading: false,
      config: null,
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      resizedColumns: {},
      canAccessComplianceProgram: false,
      isBulkTagInsertModal: false,
      table: {
        fields: [
          {
            header: { content: 'Id', style: '' },
            display: true,
            item: {
              key: 'id',
              sortable: true,
              searchable: true,
              content: (item) => item.id,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Actions', style: 'width: 1%' },
            display: true,
            item: {
              key: 'actions',
              slot: 'actions',
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Account Name', style: '' },
            display: true,
            item: {
              key: 'name',
              slot: 'name',
              cellClass: 'cell-name',
              sortable: true,
              searchable: true,
              content: (item) => item.name,
            },
          },
          {
            header: { content: 'Status', style: '' },
            display: true,
            item: {
              key: 'status',
              slot: 'status',
              sortable: true,
              content: (item) => item.status,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Traffic Source', style: '' },
            display: true,
            item: {
              key: 'type.name',
              slot: 'type',
              searchable: true,
              sortable: true,
              content: (item) => item.type.name,
            },
          },
          {
            header: {
              content: 'Tags',
              style: '',
            },
            display: true,
            item: {
              key: 'tags',
              slot: 'tags',
              searchable: true,
              cellClass: 'linked-trackers-cell',
            },
          },
          {
            header: { content: 'Linked Trackers', style: '' },
            display: true,
            item: {
              key: 'linkedTrackers',
              slot: 'linkedTrackers',
              cellClass: 'linked-trackers-cell',
              content: (item) => item.linkedTrackers.map((tracker) => tracker.name).join(', '),
            },
          },
          {
            header: { content: 'Timezone', style: '' },
            display: true,
            item: {
              key: 'settings.timezone',
              searchable: true,
              sortable: true,
              content: (item) => item.settings.timezone,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Currency', style: '' },
            display: true,
            item: {
              key: 'settings.currency',
              searchable: true,
              sortable: true,
              content: (item) => item.settings.currency,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Created At', style: '' },
            display: true,
            item: {
              key: 'created_at',
              searchable: true,
              sortable: true,
              content: (item) => moment(item.created_at).format('YYYY-MM-DD'),
              style: { textAlign: 'center' },
            },
          },
        ],
        items: [],
      },
    };
  },
  computed: {
    componentFilterState() {
      return this.filterState[this.COMPONENT_ID];
    },
    actionOptions() {
      return this.tableModel.selectedRows.length ? [
        {
          value: { value: 'tags', fn: this.onAddNewTags },
          content: '<i class=\'fa fa-hashtag\'></i>&nbsp; Add Tags',
        },
        {
          value: { value: 'start', fn: this.trafficSourcesStatusUpdate },
          content: '<i disabled class=\'fa fa-pause\'></i>&nbsp; Start',
        },
        {
          value: { value: 'stop', fn: this.trafficSourcesStatusUpdate },
          content: '<i disabled class=\'fa fa-play\'></i>&nbsp; Stop',
        },
      ] : [];
    },
    autocompleteItems() {
      return this.getTags.map((text) => ({ text }));
    },
    displayedLinkedTrackers() {
      return this.table.items.reduce((linkedTrackers, item) => {
        linkedTrackers[item.id] = uniqBy(item.linkedTrackers, 'id');
        return linkedTrackers;
      }, {});
    },
    emptyMessageDisplay() {
      const isFilterUsed = this.getIsFilterUsed(this.COMPONENT_ID);
      return isFilterUsed ? !this.table?.items?.length && !isFilterUsed : false;
    },
    ...mapState(useTrafficSourcesStore, ['filters', 'getTags', 'getFilters', 'getFilteredTrafficSources', 'getIsFilterUsed', 'getFilterResultCount']),
    ...mapWritableState(useTrafficSourcesStore, ['filterState', 'tsAccounts']),
  },
  async created() {
    this.config = await configFile.getConfig();

    await this.$_callbackHandler();
    await this.$_load();
  },
  methods: {
    onTagClicked(item) {
      this.tableModel.selectedRows = [item];
      this.$refs.bulkTagInsertRef.handleOnOpenModal([item]);
    },
    onAddNewTags() {
      this.$refs.bulkTagInsertRef.handleOnOpenModal(this.tableModel.selectedRows);
    },
    onBulkClosed() {
      if (this.tableModel.selectedRows.length === 1) {
        this.tableModel.selectedRows = [];
      }
    },
    showExtractedTagsFromTsSettings(item) {
      return extractTagsFromTsSettings(item);
    },
    async onSaveClick(data) {
      const { deletedTags, addedTags } = data;
      this.isLoading = true;
      const result = [];

      try {
        if (deletedTags.tags.length > 0) {
          const removeTags = await this.$api.trafficSources.updateAccountsTags({
            trafficSourceIds: deletedTags.id,
            tags: deletedTags.tags,
            action: 'remove',
          });

          await new Promise((resolve) => setTimeout(resolve, timeout));
          result.push(removeTags);
        }

        if (addedTags.tags.length > 0) {
          const addTags = await (this.$api.trafficSources.updateAccountsTags({
            trafficSourceIds: addedTags.id,
            tags: addedTags.tags,
            action: 'add',
          }));
          result.push(addTags);
        }
      } catch (e) {
        this.$n_failNotification({ title: 'Something went wrong' });
      }

      let updatedMessage = `Tags were ${addedTags.tags.length ? 'added' : 'deleted'} successfully!`;
      this.$apiStore.trafficSources.accounts.clearCache();
      await this.$_load();
      this.$refs.trafficSourcesRef.models.selectAllCheckbox = false;
      this.isLoading = false;
      if (result.length === 2) {
        updatedMessage = 'Tags were successfully updated!';
      }
      if (result.length) {
        this.$n_successNotification({ title: updatedMessage });
      }
    },
    async onRemoveTags(tagsToRemove) {
      this.isLoading = true;
      const tagsToRemoveArray = tagsToRemove.map((tag) => tag.content);
      const selectedRowsId = this.tsAccounts.filter((item) => item.tags.filter((el) => tagsToRemove.includes(el))).map((el) => el.id);
      const savedFilters = this.componentFilterState.savedFilters?.find((el) => el.id === this.COMPONENT_ID);
      const isSubset = tagsToRemoveArray.filter((value) => savedFilters?.tags?.includes(value)).length;

      try {
        await this.$api.trafficSources.updateAccountsTags({
          trafficSourceIds: selectedRowsId,
          tags: tagsToRemoveArray,
          action: 'remove',
        });
      } catch (error) {
        this.isLoading = false;
        this.$n_failNotification({ title: 'Failed to delete tags!' });
      }

      if (isSubset) {
        const removeFromSavedFilters = savedFilters.tags.filter((value) => !tagsToRemoveArray.includes(value));
        const removeFromSessionFilters = this.componentFilterState.filterSession.tags.filter((value) => !tagsToRemoveArray.includes(value));
        this.componentFilterState.savedFilters.find((el) => el.id === this.COMPONENT_ID).tags = removeFromSavedFilters;
        this.componentFilterState.filterSession.tags = removeFromSessionFilters;
      }

      this.$apiStore.trafficSources.accounts.clearCache();
      this.$_load();
      this.table.items = this.getFilteredTrafficSources(this.COMPONENT_ID);
      this.isLoading = false;
      this.$n_successNotification({ title: `${pluralize(tagsToRemoveArray.length, 'Tag')} was deleted successfully!` });
    },
    onFiltersClear() {
      this.table.items = this.getFilteredTrafficSources(this.COMPONENT_ID);
    },
    onFiltersApplied() {
      this.table.items = this.getFilteredTrafficSources(this.COMPONENT_ID);
      this.selectedTags = cloneDeep(this.filters(this.COMPONENT_ID).tags);
    },
    async onTagsFilter(options) {
      const selectedTags = options.map((el) => el.content);
      this.filterState[this.COMPONENT_ID] = {
        ...this.componentFilterState,
        filterSession: {
          ...this.componentFilterState.filterSession,
          tags: selectedTags,
        },
        savedFilters: [{
          ...this.componentFilterState.filterSession,
          id: this.COMPONENT_ID,
          type: 'temp',
          tags: selectedTags,
        }],
      };
      this.onFiltersApplied();
    },
    async handleSingleTagDelete(item, tag) {
      const { timezone, currency, tags } = item.settings;
      if (!(timezone === tag || currency === tag) || tags?.includes(tag)) {
        const confirmModal = await this.$swal({
          title: `Delete '${tag}' tag?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          allowEnterKey: false,
          customClass: {
            container: 'confirm-delete-swal',
            confirmButton: 'primary-button primary-button--danger',
            cancelButton: 'secondary-button',
          },
        });

        if (confirmModal.value === true) {
          this.isLoading = true;
          try {
            const reqBody = {
              trafficSourceIds: [item.id],
              tags: [tag],
              action: 'remove',
            };
            await this.$api.trafficSources.updateAccountsTags(reqBody);
            this.$apiStore.trafficSources.accounts.clearCache();
            this.$_load();
            this.$n_successNotification({ title: 'Tag was deleted successfully!' });
          } catch (error) {
            this.$n_failNotification({ title: 'Failed to delete tag!' });
          }
          this.isLoading = false;
        }
      }
    },
    bulkAction(data) {
      if (this.tableModel.selectedRows.length > 0) {
        data.fn(data);
      }
    },
    async trafficSourcesStatusUpdate(value) {
      const statusValue = value.value === 'start' ? 1 : 0;

      this.table.items = this.table.items.map((item) => ({
        ...item,
        loading: Boolean(this.tableModel.selectedRows.find((e) => e.id === item.id)),
      }));
      if (value.value !== 'tags') {
        const response = await Promise.allSettled(
          this.tableModel.selectedRows.map((row) => this.config.api.trafficSources.updateStatus(row.id, statusValue)),
        );
        const hasRejected = response.some((response) => response.status === 'rejected');
        this.table.items = this.table.items.map((item) => ({
          ...item,
          status: response.find((e) => e.value?.id === item.id && e.status === 'fulfilled') ? statusValue : item.status,
        }));

        if (hasRejected) {
          this.$n_failNotification({ title: 'Update failed!' });
        } else {
          this.$n_successNotification({ title: `Accounts ${statusValue ? 'enabled' : 'disabled'}!` });
        }
      }
      this.table.items = this.table.items.map((item) => ({
        ...item,
        loading: false,
      }));
    },
    getTrafficSourceLogo,
    async $_load() {
      try {
        this.canAccessComplianceProgram = await canAccess('ComlianceProgram') ?? false;
        this.resizedColumns = {
          ...this.$settings.resizedColumns.getResizedColumns('trafficSources'),
          actions: 85,
        };
        const items = await this.config.api.trafficSources.accounts();
        await this.registerAccounts();
        if (!this.getIsFilterUsed(this.COMPONENT_ID)) {
          this.table.items = this.getFilteredTrafficSources(this.COMPONENT_ID);
        } else {
          this.table.items = this.transformTsAccounts(items);
        }
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message });
      }
      this.handleEditAccountRequest();
      this.preloader = false;
    },
    hasTransparencyProgram(item) {
      return !!item.settings?.complianceProgram?.is_complianced && this.canAccessComplianceProgram;
    },
    async $_changeStatus(item, status) {
      try {
        const response = await this.config.api.trafficSources.updateStatus(item.id, status);
        if (response.status === 1) {
          this.$n_successNotification({ title: 'Account enabled' });
        } else {
          this.$n_successNotification({ title: 'Account disabled' });
        }
        item.status = status;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message });
        } catch (err) {
          this.$n_failNotification({ title: 'Update failed' });
        }
      }
    },
    getTotalLinkedNumber(tracker, item) {
      return item.linkedTrackers.filter((data) => data.name === tracker.name && tracker.id === data.id).length;
    },
    docUrl() {
      this.$router.push({ path: 'account-wizard' });
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('trafficSources', false, payload);
    },
    $_editAccount(item) {
      const account = {
        ...item,
        linkedTrackers: item.linkedTrackers.map((data) => ({ ...data, isPropertyName: item.linkedTrackers.length !== this.displayedLinkedTrackers[item.id].length })),
      };
      this.$refs.refTrafficSourceModal.showModal({ action: 'edit', account });
    },
    $_updateAccount(account) {
      if (account) {
        const tableItemIndex = this.table.items.findIndex((item) => item.id === account.id);
        if (tableItemIndex > -1) this.$set(this.table.items, tableItemIndex, account);
      }
    },
    async $_callbackHandler() {
      // facebook callback handler
      const query = { ...this.$route.query };
      let state;
      if (query && query.state) {
        try {
          state = JSON.parse(query.state);
        } catch (err) {
          console.log('Error parsing state', query.state, err);
        }
      }
      if (state && ['Facebook', 'GoogleAds'].includes(state.account_unique_name)) {
        if (query.code) {
          const accountData = JSON.parse(query.state);
          try {
            const id = accountData.account_id;
            const payload = {
              name: decodeURIComponent(accountData.account_name),
              credentials: { accessToken: query.code },
            };
            if (accountData.settings) payload.settings = JSON.parse(accountData.settings || '{}');
            if (accountData.updateAllCredentials) {
              payload.typeId = accountData.account_type_id;
              const accounts = await this.config.api.trafficSources.updateBulkCredentials(payload);
              this.$n_successNotification({ title: `${accounts.filter((e) => !!e.updated).length} Accounts Updated!` });
              this.table.items = accounts;
            } else {
              await this.config.api.trafficSources.update(id, payload);
              this.$n_successNotification({ title: 'Account Updated!' });
            }
          } catch (error) {
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
          delete query.state;
          delete query.code;
        }
        if (query.error) {
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
          Object.keys(query).forEach((key) => { delete query[key]; });
        }
        this.$router.replace({ query });
      }
      if (state && ['TikTok'].includes(state.account_unique_name)) {
        if (query.auth_code) {
          const accountData = JSON.parse(query.state);
          try {
            const id = accountData.account_id;
            const payload = {
              name: decodeURIComponent(accountData.account_name),
              credentials: { accessToken: query.auth_code },
            };
            if (accountData.settings) payload.settings = JSON.parse(accountData.settings);
            await this.config.api.trafficSources.update(id, payload);
            this.$n_successNotification({ title: 'Account Updated!' });
          } catch (error) {
            const errorMessage = error?.response?.data?.message || error?.message || 'An error occurred';
            this.$n_failNotification({ title: errorMessage });
          }
          delete query.state;
          delete query.auth_code;
        }
        if (query.error) {
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
          Object.keys(query).forEach((key) => { delete query[key]; });
        }
        this.$router.replace({ query });
      }
      if (state && ['AmazonDisplay', 'AmazonDSP'].includes(state.account_unique_name)) {
        if (query.code) {
          const accountData = JSON.parse(query.state);
          try {
            const id = accountData.account_id;
            const payload = {
              name: decodeURIComponent(accountData.account_name),
              credentials: { accessToken: query.code, loginUrl: accountData.loginUrl },
            };
            if (accountData.settings) payload.settings = accountData.settings;
            this.$data.loading = true;
            await this.config.api.trafficSources.update(id, payload);
            this.$n_successNotification({ title: 'Account Updated!' });
            this.$data.loading = false;
          } catch (error) {
            this.$data.loading = false;
            this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
          }
        }
        if (query.error) {
          this.$n_failNotification({ title: query.error_description || 'An error occurred' });
        }
        this.$router.replace({ });
      }

      if (query.account_unique_name && query.account_unique_name === 'Gemini' && query.refresh_token && query.account_name && query.account_type_id && query.account_id) {
        const id = parseInt(query.account_id, 10);
        // Gemini callback account
        try {
          // Update Account
          const payload = {
            name: query.account_name,
            credentials: { api_key: query.refresh_token },
          };
          if (query.settings) payload.settings = JSON.parse(query.settings);
          await this.config.api.trafficSources.update(id, payload);
        } catch (error) {
          try {
            this.$n_failNotification({ title: error.response.data.message });
          } catch (err) {
            this.$n_failNotification({ title: 'An error occurred' });
          }
        }
        delete query.account_unique_name;
        delete query.refresh_token;
        delete query.account_name;
        delete query.account_type_id;
        delete query.account_id;
        delete query.settings;
        this.$router.replace({ query });
      }
    },
    async onAccountNameChange(name, account) {
      try {
        const data = await this.config.api.trafficSources.update(account.id, { name });
        this.$apiStore.trafficSources.accounts.clearCache();
        this.$apiStore.trafficSources.accounts();
        await this.registerAccounts();
        return data;
      } catch {
        return false;
      }
    },
    showAccountId(item) {
      return ['TikTok', 'Facebook', 'GoogleAds'].includes(item.trafficSourceType.uniqueName);
    },
    handleEditAccountRequest() {
      const editAccount = this.table.items.find((item) => item.id?.toString() === (this.$route.query.editAccount ?? ''));
      this.$router.replace({ editAccount: undefined });

      if (editAccount) {
        this.$_editAccount(editAccount);
      }
    },
    manageTransparencyProgram(item) {
      this.$refs.transparencyProgramModal?.show(item);
    },
    onNotification(title, type) {
      switch (type) {
        case 'success':
          this.$n_successNotification({ title });
          this.$apiStore.trafficSources.accounts.clearCache();
          this.$_load();
          break;
        case 'error':
          this.$n_failNotification({ title });
          break;
        default:
          this.$n_infoNotification({ title });
      }
    },
    ...mapActions(useTrafficSourcesStore, ['registerAccounts', 'transformTsAccounts']),
  },
};
</script>


<style lang="scss">
.traffic-sources-container {
  .table-wrapper {
    td.cell-name a {
      color: #20a8d8;
      text-decoration: none;
      display: inline-block;

      &:hover {
        text-decoration: underline !important;
      }
    }
    td.cell-name:hover a {
      color: #167495;
    }

    .actions-container {
      display: flex;
      justify-content: flex-start;

      .switch-button .custom-control-inline {
        margin-right: 0.4rem;
      }
    }
    .column-header {
      th {
        padding: .8rem;
      }
    }
    .linked-tracker-btn {
      line-height: 1.3rem;
      margin-right: .5rem;

      img {
        width: 1.6rem;
        margin-right: .5rem;
      }
    }

    .acc-item {
      &-icon {
        width: 2.2rem;
        margin-right: 0.5rem;
        display: inline-block;
      }
      &-name {
        display: inline-block;
      }
    }

    .header {
      padding-top: 0.9rem;

      .dropdown {
        .dropdown-toggle {
          color: $black-500;
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            color: $black-500;

            .optimizer-icon path {
              padding: 0;
              stroke: $black-500;
              font-size: 0.9rem;
              stroke-width: .13rem;
            }
          }

          .badge {
            top: -1.8rem;
            right: -9rem;
            color: $color-white;
            background: $color-primary;
            padding: 0.4rem 0.7rem 0.3rem;
            line-height: 1.2rem !important;
            font-size: 1.1rem;
          }
        }

        .dropdown-menu .options-list {
          @include scrollbar-on-hover;
          max-height: 20rem !important;
        }
      }
    }
  }

  .tag-filter {
    width: 115px !important;

    .optimizer-select .dropdown .dropdown-menu .options-list {
      max-height: 15.5rem !important;
    }
  }

    .optimizer-table {
      .table-holder {
        border: $border;

        .linked-trackers-cell {
          .tags-cell {
            display: flex;
            align-items: center;
            gap: 0.8rem;
          }
          .more-items {
            .dropdown-menu {
              .linked-tracker-btn {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .accountWizardUrl {
      &:hover {
        text-decoration: underline !important;
        color: #4158D0;
        }
      }
    .lead {
      font-size: 17px;
      font-family: 'Roboto', sans-serif;
    }
    .accountId-text{
      color:$color-dark-gray;
    }

  // Actions dropdown style
  .ts-actions .show .dropdown-menu .options-list .dropdown-item {
    .option-content {
      padding: 0;
      > div > div {
        padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      }
      span {
        width: 100%;
        padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      }
    }
  }
}
</style>
