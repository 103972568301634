export const timeValues = [
  { text: '12:00 AM', value: '0' },
  { text: '01:00 AM', value: '1' },
  { text: '02:00 AM', value: '2' },
  { text: '03:00 AM', value: '3' },
  { text: '04:00 AM', value: '4' },
  { text: '05:00 AM', value: '5' },
  { text: '06:00 AM', value: '6' },
  { text: '07:00 AM', value: '7' },
  { text: '08:00 AM', value: '8' },
  { text: '09:00 AM', value: '9' },
  { text: '10:00 AM', value: '10' },
  { text: '11:00 AM', value: '11' },
  { text: '12:00 PM', value: '12' },
  { text: '01:00 PM', value: '13' },
  { text: '02:00 PM', value: '14' },
  { text: '03:00 PM', value: '15' },
  { text: '04:00 PM', value: '16' },
  { text: '05:00 PM', value: '17' },
  { text: '06:00 PM', value: '18' },
  { text: '07:00 PM', value: '19' },
  { text: '08:00 PM', value: '20' },
  { text: '09:00 PM', value: '21' },
  { text: '10:00 PM', value: '22' },
  { text: '11:00 PM', value: '23' },
];


export const timeZones = [
  {
    text: 'US/Pacific',
  },
  {
    text: 'US/Mountain',
  },
  {
    text: 'America/Bogota',
  },
  {
    text: 'US/Central',
  },
  {
    text: 'America/Mexico_City',
  },
  {
    text: 'Asia/Kuala_Lumpur',
  },
  {
    text: 'US/Eastern',
  },
  {
    text: 'America/Sao_Paulo',
  },
  {
    text: 'UTC',
  },
  {
    text: 'Europe/London',
  },
  {
    text: 'Europe/Rome',
  },
  {
    text: 'Europe/Berlin',
  },
  {
    text: 'Europe/Madrid',
  },
  {
    text: 'Europe/Warsaw',
  },
  {
    text: 'Europe/Paris',
  },
  {
    text: 'Africa/Nairobi',
  },
  {
    text: 'Europe/Moscow',
  },
  {
    text: 'Europe/Athens',
  },
  {
    text: 'Israel',
  },
  {
    text: 'Asia/Dubai',
  },
  {
    text: 'Asia/Karachi',
  },
  {
    text: 'Asia/Calcutta',
  },
  {
    text: 'Asia/Bangkok',
  },
  {
    text: 'Asia/Manila',
  },
  {
    text: 'Asia/Shanghai',
  },
  {
    text: 'Asia/Singapore',
  },
  {
    text: 'Asia/Seoul',
  },
  {
    text: 'Asia/Tokyo',
  },
  {
    text: 'Australia/Melbourne',
  },
  {
    text: 'Pacific/Auckland',
  },
];
