import { render, staticRenderFns } from "./Alert.ts.vue?vue&type=template&id=98926f60"
import script from "./Alert.ts.vue?vue&type=script&lang=ts&setup=true"
export * from "./Alert.ts.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Alert.ts.vue?vue&type=custom&index=0&blockType=styles&lang=scss"
if (typeof block0 === 'function') block0(component)

export default component.exports