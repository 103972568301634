import { getFieldsAndFilters, getFieldsAndFiltersForRules } from '@sh/configurations/fields/FieldFilter';
import { mapCampaignField } from '@sh/configurations/fields/helpers';
import { RuleHelpers } from '@sh/helpers';
import ui from '@sh/helpers/ui';
import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';
import { concat } from 'lodash';
import { getInterval, DATE_RANGE_REGEX, getFieldText, getDateRangeKey } from '@/views/Automation/Rules/config/date-condition-helper';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';
// Get Available Fields
const fields = [];
const fieldsMap = {};
const toFields = [];
const fieldsGroup = [];

const getAvailableFields = async () => {
  if (!fields.length) {
    let systemFields = {};
    try {
      systemFields = await apiStore.dashboard.getAvailableMetrics();
    } catch (error) {
      console.log(error);
    }
    const { fields: foundFields, groups: availableGroups } = getFieldsAndFiltersForRules({}, systemFields.fields);
    fields.push(...foundFields);
    fieldsGroup.push(...availableGroups);
    const fieldsMapFound = fields.reduce((acc, field) => ({ ...acc, [field.value.key]: field }), {});
    Object.assign(fieldsMap, fieldsMapFound);

    // Get fields that are supported as single metrics for the parent campaign ()
    const { fields: availableFieldsAsParentCampaignCondition } = getFieldsAndFiltersForRules({ fieldsForParentCampaignCondition: true }, systemFields.fields);
    const fieldForParentCampaignCondition = availableFieldsAsParentCampaignCondition.map(mapCampaignField);

    // Add them as available fields
    fields.push(...fieldForParentCampaignCondition);
    const fieldMapWithCampaign = fieldForParentCampaignCondition.reduce((acc, field) => ({ ...acc, [field.value.key]: field }), {});
    Object.assign(fieldsMap, fieldMapWithCampaign);

    // Get fields supported as to Conditions.
    const { fields: toFieldsFound } = getFieldsAndFiltersForRules({ fieldsForToComparison: true }, systemFields.fields);
    toFields.push(...toFieldsFound);

    Object.assign(fieldsMap, RuleHelpers.ConditionsV2.staticDateFields);
  }
};

const conditions = [
  { value: { key: 'greater', behind: 'THAN' }, text: 'Greater' },
  { value: { key: 'less', behind: 'THAN' }, text: 'Less' },
  { value: { key: 'greater_equal', behind: 'THAN / TO' }, text: 'Greater or Equal' },
  { value: { key: 'less_equal', behind: 'THAN / TO' }, text: 'Less or Equal' },
];

const nameConditions = [
  { value: { key: 'contains', behind: 'THAN', type: 'text' }, text: 'Contains' },
  { value: { key: 'starts_with', behind: 'THAN', type: 'text' }, text: 'Starts with' },
  { value: { key: 'ends_with', behind: 'THAN', type: 'text' }, text: 'Ends with' },
  { value: { key: 'not_contains', behind: 'THAN', type: 'text' }, text: 'Does not contain' },
  { value: { key: 'not_starts_with', behind: 'THAN', type: 'text' }, text: 'Does not start with' },
  { value: { key: 'not_ends_with', behind: 'THAN', type: 'text' }, text: 'Does not end with' },
];

const nameConditionsMap = {
  contains: { value: { key: 'contains', behind: 'THAN', type: 'text' }, text: 'Contains' },
  starts_with: { value: { key: 'starts_with', behind: 'THAN', type: 'text' }, text: 'Starts with' },
  ends_with: { value: { key: 'ends_with', behind: 'THAN', type: 'text' }, text: 'Ends with' },
  not_contains: { value: { key: 'not_contains', behind: 'THAN', type: 'text' }, text: 'Does not contain' },
  not_starts_with: { value: { key: 'not_starts_with', behind: 'THAN', type: 'text' }, text: 'Does not start with' },
  not_ends_with: { value: { key: 'not_ends_with', behind: 'THAN', type: 'text' }, text: 'Does not end with' },
};

const learningStateConditions = [
  { value: { key: 'EMPTY_DISPLAY' }, text: 'Empty Display' },
  { value: { key: 'EXPEDITED_LEARNING' }, text: 'Expedited Learning' },
  { value: { key: 'LEARNING' }, text: 'Learning' },
  { value: { key: 'LEARNING_COMPLETE' }, text: 'Scaling' },
  { value: { key: 'LEARNING_LIMITED' }, text: 'Extended Learning' },
];

const learningStateConditionsMap = {
  equals: { value: { key: 'equals' }, text: 'is' },
};

const statusConditions = [
  { value: { key: 'RUNNING' }, text: 'Running' },
  { value: { key: 'PAUSED' }, text: 'Paused' },
];

const statusConditionsMap = {
  equals: { value: { key: 'equals' }, text: 'is' },
};

const customDateConditions = [
  { value: { key: 'less', behind: 'THAN' }, text: 'Greater' },
  { value: { key: 'greater', behind: 'THAN' }, text: 'Less' },
  { value: { key: 'less_equal', behind: 'THAN / TO' }, text: 'Greater or Equal' },
  { value: { key: 'greater_equal', behind: 'THAN / TO' }, text: 'Less or Equal' },
];

const customDateConditionsMap = {
  less: { value: { key: 'less', behind: 'THAN' }, text: '>' },
  greater: { value: { key: 'greater', behind: 'THAN' }, text: '<' },
  less_equal: { value: { key: 'less_equal', behind: 'THAN / TO' }, text: '>=' },
  greater_equal: { value: { key: 'greater_equal', behind: 'THAN / TO' }, text: '<=' },
};

const allConditionsMap = {
  ...statusConditionsMap,
  ...RuleHelpers.Conditions.conditionsMap,
  ...nameConditionsMap,
  ...learningStateConditionsMap,
};

const getConditionKey = (key, value) => {
  const cKey = key.replace(DATE_RANGE_REGEX, '');

  if (value.tracker_account_id) {
    return `${cKey}::${value.tracker_account_id}`;
  }

  return cKey;
};

const conditionsTranslator = (conditions, hourOffDay, returnMapped, ruleObject) => {
  const conditionsArray = conditions.map((condition) => Object.entries(condition)[0]).map(([key, value]) => {
    const cKey = getConditionKey(key, value, ruleObject);
    const condition = RuleHelpers.ConditionsV2.isCustomDateCondition(cKey) ? customDateConditionsMap[value.condition] : allConditionsMap[value.condition];

    let itemData = [];

    if (!fieldsMap[cKey]) {
      itemData = [
        value.value,
        condition,
        { value: { key: cKey, behind: 'IS', step: 1 }, text: cKey, class: 'badge-yellow-gold' },
      ];
    } else {
      const newKey = RuleHelpers.ConditionsV2.convertKey(cKey, value.to_self);
      itemData = [
        fieldsMap[newKey].value.type === 'time' ? ui.timeFormat(value.value) : value.value,
        condition,
        fieldsMap[newKey],
      ];
    }
    // To Field
    const toSelf = !!(value.to_self && value.to_self !== 'static');
    let toKey = value.to;
    if (toSelf) toKey = value.to_self;
    toKey = toKey?.replace(DATE_RANGE_REGEX, '');
    if (value.tracker_account_id_2) toKey = `${toKey}::${value.tracker_account_id_2}`;
    if (toKey && fieldsMap[toKey]) {
      itemData.push(fieldsMap[toKey], toSelf);
    } else {
      itemData.push(null, toSelf);
    }
    itemData.push(getInterval(ruleObject, value));
    return itemData;
  }).map(([value, condition, field, toField, toSelf, interval]) => {
    const isDateField = toField?.baseType === RuleTypes.Conditions.BaseFieldType.Date;
    let html = `<span class="badge badge-pill ${field.class}">${getFieldText(field, interval[0])} ${condition?.text} `;

    if (toField && !isDateField) {
      if (toSelf) {
        html += `${value} % of ${toField.text}`;
      } else {
        html += `${value} % of campaign ${toField.text}`;
      }
    } else {
      if (field.value.placeholderPosition === 'before') html += `${field.value.placeholder}`;
      html += `${value}`;
      if (field.value.placeholderPosition === 'after') html += `${field.value.placeholder}`;
    }

    html += `${getFieldText(toField, interval[1], isDateField, ruleObject)} </span>`;

    try {
      return { key: field.value.key, html, placeholder: field.value.placeholder, leftDateRangeKey: getDateRangeKey(field, interval[0]), rightDateRangeKey: getDateRangeKey(toField, interval[1]) };
    } catch (err) {
      return { key: field.value.key, html, placeholder: field.value.placeholder };
    }
  });
  if (returnMapped) {
    if (hourOffDay) {
      conditionsArray.push({
        key: 'hour_of_day',
        html: '<span class="badge badge-pill badge-blue badge-clock"><i class="fa fa-clock-o"></i></span>',
        placeholder: '',
      });
    }
    return conditionsArray;
    // eslint-disable-next-line no-else-return
  } else {
    const conditionsArrayHtml = conditionsArray.map(({ html }) => html);
    if (hourOffDay) conditionsArrayHtml.push('<span class="badge badge-pill badge-blue badge-clock"><i class="fa fa-clock-o"></i></span>');
    return conditionsArrayHtml.join(' <b>&</b> ');
  }
};

let availableTrackers = [];
const getAvailableTrackers = async () => {
  const response = await apiStore.trackers.accounts();
  availableTrackers = response.map((res) => res.type.uniqueName);
  availableTrackers = [...new Set(availableTrackers)];
};

/* ******************************************** */

let promises;

const cache = {};

async function filterAvailableFields(fields, trafficSources = [], level = null) {
  await getAvailableTrackers();
  if (level || trafficSources.length) {
    fields = fields.filter((field) => {
      let condition = true;
      if (level && field.availableOnLevel && field.availableOnLevel.length) {
        condition = condition && field.availableOnLevel.includes(level);
        if (field.baseModel === RuleTypes.ConditionsV2.BaseModel.Campaign) {
          condition = level !== 'Campaign';
        }
      }

      if (trafficSources.length && field.trafficSource && field.trafficSource.length) {
        condition = condition && field.trafficSource.some((ts) => trafficSources.includes(ts));
      }

      if (availableTrackers.length && field.trackers && field.trackers.length) {
        condition = condition && field.trackers.some((tr) => availableTrackers.includes(tr));
      }
      return condition;
    });
  }

  return fields.map((field) => {
    if (level && field.renameOnLevel && field.renameOnLevel.length) {
      field.value.key = field.renameKey;
    }
    return field;
  });
}

/* **************** GA Fields ***************** */
let gaFields = [];
let campaignGaFields = [];
const loadGaFields = async () => {
  if (gaFields.length === 0) {
    const response = await apiStore.rules.gaFields();
    gaFields = response.map((gaItem) => {
      const item = { group: 'ga', value: { key: gaItem.key, behind: 'IS', step: gaItem.step }, text: gaItem.text, class: 'badge-purple-plum', baseType: RuleTypes.Conditions.BaseFieldType.Metric, type: RuleTypes.Conditions.ValueType.Number };
      if (gaItem.type) item.value.type = gaItem.type;
      return item;
    });
    campaignGaFields = gaFields.map(mapCampaignField);
    // Add fields
    gaFields.forEach((item) => {
      fieldsMap[item.value.key] = item;
    });
  }
};
/* **************** GA Fields ***************** */
let gaFields4 = [];
let campaignGaFields4 = [];
const loadGaFields4 = async () => {
  if (gaFields4.length === 0) {
    const response = await apiStore.rules.gaFields4();
    gaFields4 = response.map((gaItem) => {
      const item = { group: 'ga4', value: { key: gaItem.key, behind: 'IS', step: gaItem.step }, text: gaItem.text, class: 'badge-purple-plum', baseType: RuleTypes.Conditions.BaseFieldType.Metric, type: RuleTypes.Conditions.ValueType.Number };
      if (gaItem.type) item.value.type = gaItem.type;
      return item;
    });
    campaignGaFields4 = gaFields4.map(mapCampaignField);
    // Add fields
    gaFields4.forEach((item) => {
      fieldsMap[item.value.key] = item;
    });
  }
};
/* ******************************************** */

/* **************** CC Fields ***************** */
let ccFields = [];
let campaignCcFields = [];
const loadCcFields = async () => {
  if (ccFields.length === 0) {
    const response = await apiStore.rules.ccFields();
    ccFields = response.map((ccItem) => {
      const item = {
        group: 'cc', value: { key: ccItem.key, behind: 'IS', step: ccItem.step }, text: ccItem.text, class: 'badge-yellow-gold', baseType: RuleTypes.Conditions.BaseFieldType.Metric, type: RuleTypes.Conditions.ValueType.Number,
      };
      if (ccItem.tracker_account_id) item.value.key = `${item.value.key}::${ccItem.tracker_account_id}`;
      return item;
    });
    campaignCcFields = ccFields.map(mapCampaignField);
    // Add fields
    ccFields.forEach((item) => {
      fieldsMap[item.value.key] = item;
    });
  }
};
/* ******************************************** */

/* **************** CM Fields ***************** */
let cmFields = [];
let campaignCmFields = [];
const loadCmFields = async () => {
  if (ccFields.length === 0) {
    const response = await apiStore.configuration.cmFields();
    if (response && response.fields) {
      cmFields = response.fields.map((cmItem) => {
        const step = cmItem.options.precision ? `${1 / (10 ** cmItem.options.precision)}` : '1';

        const item = {
          group: 'cm',
          value: { key: cmItem.item.key, behind: 'IS', step },
          text: getFieldTextAndDescription(cmItem).text || cmItem.header.content,
          class: 'badge-blue-chambray',
          baseType: RuleTypes.Conditions.BaseFieldType.Metric,
          type: RuleTypes.Conditions.ValueType.Number,
          description: getFieldTextAndDescription(cmItem).description,
          label: getFieldTextAndDescription(cmItem).description,
        };
        return item;
      });
      campaignCmFields = cmFields.map(mapCampaignField);
      // Add fields
      // if (toFields.length) toFields.push(...cmFields);
      cmFields.forEach((item) => {
        fieldsMap[item.value.key] = item;
      });
    }
  }
};

export default {
  async getData({ trafficSources = [], level = null }) {
    try {
      if (typeof promises === 'undefined') promises = (() => Promise.all([getAvailableFields(), loadGaFields(), loadCcFields(), loadCmFields(), loadGaFields4()]))();
      if (promises !== null) {
        await promises;
        promises = null;
      }
      const currentFields = concat(fields, gaFields, cmFields, ccFields, gaFields4, campaignGaFields, campaignCcFields, campaignGaFields4);
      const currentToFields = concat(toFields, gaFields, ccFields, gaFields4);
      const currentToSelfFields = concat(fields, cmFields, gaFields, ccFields, gaFields4, campaignGaFields, campaignCcFields, campaignCmFields, campaignGaFields4);
      const dateFields = [
        ...Object.values(RuleHelpers.ConditionsV2.staticDateFields),
        ...getFieldsAndFilters({ baseTypes: [RuleTypes.Conditions.BaseFieldType.Date], trafficSource: trafficSources }).fields ?? [],
      ];

      const cacheKey = `${trafficSources.join(',')}${level}`;

      if (!cache[cacheKey]) {
        const filtered = await filterAvailableFields(currentFields, trafficSources, level);
        const filteredToSelfFields = await filterAvailableFields(currentToSelfFields, trafficSources, level);
        const filteredToFields = await filterAvailableFields(currentToFields, trafficSources, level);
        const filteredDateFields = await filterAvailableFields(dateFields, trafficSources, level);
        const newFieldMap = filtered.reduce((acc, field) => ({ ...acc, [field.value.key]: field }), {});

        cache[cacheKey] = {
          fields: filtered,
          fieldsMap: newFieldMap,
          toSelfFields: filteredToSelfFields,
          toFields: filteredToFields,
          dateFields: filteredDateFields,
        };
      }
      return {
        fieldsGroup, ...cache[cacheKey], conditions, nameConditions, learningStateConditions, statusConditions, customDateConditions, conditionsTranslator,
      };
    } catch (error) {
      throw Error('Fail loading dynamics fields');
    }
  },
};
