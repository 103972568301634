import AccountConfig from './account';
import TaboolaConfig from './models/Taboola';
import RevContentConfig from './models/RevContent';
import OutbrainConfig from './models/Outbrain';
import MgidConfig from './models/Mgid';
import ContentAdConfig from './models/ContentAd';
import GeminiConfig from './models/Gemini';
import VariationsConfig from './variations';

const getConfig = async () => {
  const Account = await AccountConfig.getConfig();
  const [Taboola, RevContent, Outbrain, Mgid, Adskeeper, ContentAd, Gemini, Variations] = await Promise.all([TaboolaConfig.getConfig(Account), RevContentConfig.getConfig(Account), OutbrainConfig.getConfig(Account), MgidConfig.getConfig(Account), MgidConfig.getConfig(Account), ContentAdConfig.getConfig(Account), GeminiConfig.getConfig(Account), VariationsConfig.getConfig()]);
  return {
    Account,
    Models: {
      Taboola,
      RevContent,
      Outbrain,
      Mgid,
      Adskeeper,
      ContentAd,
      Gemini,
    },
    // TODO Filter Edit content by uniqueName
    AllowEditContents: ['Taboola', 'RevContent', 'Mgid', 'Adskeeper', 'Outbrain', 'Gemini'],
    Variations,
  };
};

export { getConfig };
