<template>
  <div class="rule-alert-wrapper">
    <b-form-group>
      <b-form-radio-group
        class="rule-alert"
        v-model="alert"
        :options="options.alert"
      >
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'RuleAlert',
  mixins: [component],
  props: {
    value: { type: String, default: '2' },
  },
  data() {
    return {
      alert: '2', // fix to an unexplained bug
      options: {
        alert: [],
      },
    };
  },
  watch: {
    alert: {
      immediate: true,
      handler() {
        this.$_emit('alert');
      },
      deep: true,
    },
  },
  created() {
    this.alert = this.value;
    this.options.alert = this.config.alertConfig.alert;
  },
  methods: {
  },
};
</script>

<style lang="scss">
.rule-alert-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
  .rule-alert {
    padding-bottom: .6rem;

    .custom-control-input:checked {
      ~ .custom-control-label::before {
        background-color: $color-light-blue;
        border-color: $color-light-blue;
      }
    }

    .custom-control-label {
      margin-right: 1.5rem;
      font-size: 1.5rem;

      span {
        padding-left: .3rem;
      }

      &::before {
        top: .3rem;
        left: -1.9rem;
        width: 1.6rem;
        height: 1.6rem;
        box-shadow: none;
        outline: none;
      }

      &::after {
        top: .3rem;
        left: -1.9rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
</style>
