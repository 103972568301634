
import MetricRow from '@sh/components/MediaManager/MetricFilter/MetricRow.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { timeout } from '@sh/helpers';
import { OPERATORS } from '@sh/helpers/MediaManager';
import { MediaManager } from '@sh/types';
import { throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';

interface ComponentData {
  conditions: MediaManager.MetricFilterTypes.Row[];
  isDropdownShown: boolean;
}

export default defineComponent({
  components: { OptimizerIcon, MetricRow },
  props: {
    metricTypes: {
      type: Array as PropType<MediaManager.MetricFilterTypes.DropDown[]>,
      required: true,
    },
    metricFilters: {
      type: Array as PropType<MediaManager.MetricFilterTypes.Filter[]>,
      required: true,
    },
    disabled: Boolean,
  },
  data(): ComponentData {
    return {
      conditions: [],
      isDropdownShown: false,
    };
  },
  computed: {
    operators() {
      return OPERATORS;
    },
  },
  watch: {
    metricFilters: {
      handler() {
        this.initializeConditions();
      },
      deep: true,
    },
  },
  created() {
    this.initializeConditions();
  },
  mounted() {
    this.onFilter = throttle(this.onFilter, timeout);
    this.onBackgroundClick = throttle(this.onBackgroundClick, timeout);
    document.addEventListener('mouseup', this.onBackgroundClick);
  },
  destroyed() {
    document.removeEventListener('mouseup', this.onBackgroundClick);
  },
  methods: {
    addNewCondition() {
      const newMetric: MediaManager.MetricFilterTypes.Row = {
        metricType: this.metricTypes[0],
        operator: OPERATORS[0],
        value: '',
        valid: false,
      };
      this.conditions.push(newMetric);
    },
    deleteCondition(index: number) {
      this.conditions.splice(index, 1);
    },
    isFormValid() {
      return !this.conditions.some((condition) => condition.valid !== true);
    },
    initializeConditions() {
      this.conditions = this.metricFilters.reduce((filtered: MediaManager.MetricFilterTypes.Row[], metricFilter) => {
        const metricType = this.metricTypes.find((metricType) => metricType.name === metricFilter.name);
        const operator = OPERATORS.find((operator) => operator.name === metricFilter.operator);
        if (metricType && operator) {
          const dtoValue = `${metricFilter.value}`;
          const value =
            metricType.symbol === MediaManager.MetricFilterTypes.SYMBOL.BYTES
              ? `${parseFloat(dtoValue) / 1000000}`
              : dtoValue;

          filtered.push({
            metricType,
            operator,
            value,
            valid: false,
          });
        }
        return filtered;
      }, []);

      if (!this.conditions.length) {
        this.addNewCondition();
      }
    },
    getData(): MediaManager.MetricFilterTypes.Filter[] {
      return this.conditions.map((condition) => ({
        name: condition.metricType.name,
        operator: condition.operator.name,
        value: this.getConditionValue(condition),
      }));
    },
    getConditionValue(condition: MediaManager.MetricFilterTypes.Row) {
      switch (condition.metricType.symbol) {
        case MediaManager.MetricFilterTypes.SYMBOL.PERCENTAGE:
        case MediaManager.MetricFilterTypes.SYMBOL.MONEY:
        case MediaManager.MetricFilterTypes.SYMBOL.NUMBER: {
          return parseFloat(condition.value);
        }
        case MediaManager.MetricFilterTypes.SYMBOL.BYTES: {
          return parseFloat(condition.value) * 1000000;
        }
        default:
          return condition.value;
      }
    },
    onFilter() {
      if (this.isFormValid()) {
        this.onDropdownShowClick(false);
        this.$emit('onFilter', this.getData());
      } else {
        for (let index = 0; index < this.conditions.length; index++) {
          const ref: any = this.$refs[`metricRow-${index}`];

          if (ref[0]) {
            ref[0].onInput(true);
          }
        }
      }
    },
    onClear() {
      this.$emit('onFilter', []);
    },
    onCancel() {
      this.initializeConditions();
      this.onDropdownShowClick(false);
    },
    onBackgroundClick(event: Event) {
      if (
        this.isDropdownShown &&
        !(this.$refs?.dropdown as HTMLDivElement)?.contains(event.target as Node) &&
        !(this.$refs?.openDropdown as HTMLDivElement)?.contains(event.target as Node) &&
        !(event.target as HTMLImageElement)?.id?.includes('delete')
      ) {
        this.onCancel();
      }
    },
    onDropdownShowClick(isDropdownShown: boolean) {
      if (this.isDropdownShown && isDropdownShown) {
        this.isDropdownShown = false;
      } else {
        this.isDropdownShown = isDropdownShown;
        (this.$refs?.optionsList as HTMLDivElement)?.scrollTo({ top: 0 });
      }
    },
  },
});
