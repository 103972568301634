
import { useMediaManagerStore } from '@/stores/media-manager';
import helpers from '@/views/Automation/CampaignCreator/mixins/helpers';
import AdActionButton from '@sh/components/MediaManager/AdActionButton/AdActionButton.ts.vue';
import TagsContainer from '@sh/components/MediaManager/TagsContainer/TagsContainer.ts.vue';
import ImageEditor from '@sh/components/Utils/ImageEditor/ImageEditor.vue';
import Loading from '@sh/components/Utils/Loading.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { getConfigData, MediaManagerHelpers, onImageError, timeout } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { ImageEditorType } from '@sh/types/ImageTypes';
import { cloneDeep, debounce } from 'lodash';
import md5 from 'md5';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  isImageEditor: boolean;
  isPreloader: boolean;
  isDragActive: boolean;
  isWaitingImage: boolean;
  isLoading: boolean;
  isImageEditActivated: boolean;
  isHeadlineEditActivated: boolean;
}

type ComponentTypes = VueConstructor<Vue & HelperMixin & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    TagsContainer,
    ImageEditor,
    Loading,
    AdActionButton,
    OptimizerIcon,
  },
  mixins: [notifications, helpers],
  data(): ComponentData {
    return {
      isImageEditor: false,
      isPreloader: false,
      isDragActive: false,
      isWaitingImage: false,
      isLoading: false,
      isImageEditActivated: false,
      isHeadlineEditActivated: false,
    };
  },
  computed: {
    replaceMode(): string {
      return this.mode === MediaManager.Mode.ADS ? 'Creative' : this.mode;
    },
    actionType() {
      return MediaManager.ActionType;
    },
    validationErrors(): JoiErrorDetail[] {
      if (this.editAdData) {
        const data = cloneDeep(this.editAdData);
        const { error } = MediaManagerHelpers.validateData(data, {
          mode: this.mode,
          ...(this.trafficSourceConfig &&
            !this.editAdData.image?.base64?.includes('http') && {
              maxHeadlineCharacters: this.maxHeadlineCharactersComputed,
              maxImageSize: this.trafficSourceConfig.image.size.max * 1000,
              minImageWidth: this.trafficSourceConfig.image.dimension.minWidth,
              minImageHeight: this.trafficSourceConfig.image.dimension.minHeight,
            }),
        }) as unknown as JoiValidationResponse;

        if (error) {
          return error.details;
        }
      }
      return [];
    },
    emptyInputMessage(): string {
      if (!this.isImageEditor && this.editAdData) {
        let hasEmptySlot = false;

        hasEmptySlot = this.validationErrors.some((errorDetails) =>
          ['required', 'empty', 'includesRequiredUnknowns'].some((value) => errorDetails.type.includes(value))
        );

        if (hasEmptySlot) {
          return `Please make sure there is no empty field for ${this.mode}s.`;
        }
      }
      return '';
    },
    hasCampaigns(): boolean {
      return !!(
        this.activeAd &&
        this.activeAd.usedOn > 0 &&
        (this.activeAd.isCampaignListLoading ||
          this.activeAd.campaignsList?.some((campaign) => campaign.campaing_name || campaign.campaing_url))
      );
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    metrics(): MediaManager.MetricDto[] {
      if (this.activeAd) {
        return this.activeAd.metrics.map((metric) => ({
          ...metric,
          value: MediaManagerHelpers.getMetricValue(metric),
        }));
      }
      return [];
    },
    trafficSourceConfig(): ReturnType<typeof getConfigData> {
      if (this.activeAd?.trafficSource?.name) {
        return getConfigData(this.activeAd.trafficSource.name);
      }
      return undefined;
    },
    maxHeadlineCharactersComputed(): number {
      if (this.trafficSourceConfig) {
        return this.trafficSourceConfig.headline.maxCharacter;
      }
      return Number.MAX_SAFE_INTEGER;
    },
    isImageEditorButton(): boolean {
      return !!(this.editAdData?.image?.dimensions?.width && this.editAdData?.image?.dimensions?.height);
    },
    imageErrorMessage(): string {
      return this.validationErrors.reduce((message: string, errorDetail) => {
        if (errorDetail.path.includes('image') && this.editAdData?.image?.mimeType?.includes('image')) {
          message += errorDetail.message;
        }
        return message;
      }, '');
    },
    title(): string {
      if (this.isImageEditor) {
        return 'Edit Image';
      }
      return `${this.replaceMode} Details`;
    },
    imageSizeText(): string {
      if (this.editAdData?.image?.size) {
        if (this.editAdData.image.size > 100000) {
          return `${Math.round(this.editAdData.image.size / 10000) / 100} Mb`;
        }
        return `${Math.round(this.editAdData.image.size / 10) / 100} Kb`;
      }
      return '';
    },
    autocompleteItems(): MediaManager.Tag[] {
      return this.settings.uniqueTags.map((text) => ({ id: md5(text), text }));
    },
    ...mapState(useMediaManagerStore, ['mode', 'ads', 'isTableView']),
    ...mapWritableState(useMediaManagerStore, [
      'settings',
      'activeAd',
      'editAdData',
      'isAdModalOpen',
      'initialActiveImage',
    ]),
  },
  mounted() {
    this.onSaveChanges = debounce(this.onSaveChanges, timeout) as () => Promise<void>;
  },
  methods: {
    ...mapActions(useMediaManagerStore, ['updateFilters', 'insertNewAdsData', 'editAd']),
    handleDrag(isDragActive: boolean) {
      this.isDragActive = isDragActive;
    },
    onHeadlineEditActivate() {
      (this.$refs.headlineTextarea as HTMLDivElement).focus();
      this.isHeadlineEditActivated = true;
    },
    async onCloneContentClick() {
      if (this.editAdData) {
        try {
          this.isLoading = true;
          const serverData = (await this.$api.mediaManager.cloneContent(this.mode, { id: this.editAdData.id }))?.data;
          this.onModalHide();
          this.$n_successNotification({ title: `Cloned ${this.replaceMode} successfully!`, timeout: 1000 });

          if (serverData) {
            if (this.isTableView) {
              this.updateFilters({ page: 1 });
            } else {
              await this.insertNewAdsData([serverData]);
            }
          }
        } catch {
          this.$n_failNotification({ title: `Failed to clone ${this.mode}` });
        }
        this.isLoading = false;
      }
    },
    onTagsChange() {
      if (this.activeAd && this.editAdData) {
        const removedTags = this.activeAd.tags.filter((tag) => this.editAdData && !this.editAdData.tags.includes(tag));
        const newTags = this.editAdData.tags.filter(
          (tag) => this.activeAd && !this.activeAd.tags.includes(tag) && !removedTags.includes(tag)
        );
        const removedTagsToDelete = removedTags.filter(
          (tag) => !this.ads.find((ad) => this.activeAd && ad.id !== this.activeAd.id && ad.tags.includes(tag))
        );
        const newTagsToInsert = newTags.filter((tag) => !this.settings.uniqueTags.includes(tag));
        const uniqueTags = this.settings.uniqueTags.filter((tag) => !removedTagsToDelete.includes(tag));
        this.settings.uniqueTags = [...uniqueTags, ...newTagsToInsert];
      }
      this.onSaveChanges();
    },
    async onSaveChanges() {
      this.isLoading = true;

      try {
        if (this.editAdData) {
          await this.editAd(this.editAdData);
          this.$n_successNotification({ title: 'Changes were saved successfully!', timeout: 1000 });
        }
      } catch {
        this.$n_failNotification({ title: 'Failed to save changes!' });
      }
      this.editAdData = cloneDeep(this.activeAd);
      this.isLoading = false;
    },
    onModalHide() {
      this.activeAd = undefined;
      this.initialActiveImage = undefined;
      this.editAdData = undefined;
      this.isImageEditor = false;
      this.isImageEditActivated = false;
      this.isHeadlineEditActivated = false;
      this.isAdModalOpen = false;
    },
    onImageEditorSave() {
      this.isWaitingImage = true;
      const saveButton = document.getElementsByClassName('FIE_topbar-save-button')[0];
      (saveButton as HTMLButtonElement)?.click();
      this.onSaveChanges();
    },
    onImageEditorHide() {
      this.isImageEditor = false;
      this.isWaitingImage = false;
      const closeButton = document.getElementsByClassName('FIE_topbar-close-button')[0];
      (closeButton as HTMLButtonElement)?.click();
    },
    onRemoveImageClick() {
      if (this.editAdData?.image) {
        this.editAdData.image.base64 = '';
      }

      if (this.$refs.hiddenInputFile) {
        (this.$refs.hiddenInputFile as HTMLInputElement).value = '';
      }
    },
    onHiddenInputFileClick() {
      (this.$refs.hiddenInputFile as HTMLInputElement)?.click();
    },
    async onAddFileClick(event: Event) {
      const { files } = event.target as HTMLInputElement;

      if (files && this.editAdData) {
        this.isPreloader = true;
        [this.editAdData.image] = await MediaManagerHelpers.getImageData(files);
        this.isPreloader = false;
      }
      this.onSaveChanges();
    },
    onRemoveTagClick(index?: number) {
      if (this.editAdData) {
        if (index) {
          this.editAdData.tags.splice(index, 1);
        } else {
          this.editAdData.tags = [];
        }
        this.onSaveChanges();
      }
    },
    onImageEdit(editedImageObject: ImageEditorType) {
      if (this.editAdData) {
        this.editAdData.image = MediaManagerHelpers.getEditedImage(editedImageObject);
      }

      this.onImageEditorHide();
    },
    async onResetImageClick() {
      if (this.editAdData) {
        this.isLoading = true;
        const image = cloneDeep(this.initialActiveImage);
        const data = cloneDeep(this.editAdData);
        this.editAdData.image = this.initialActiveImage;

        try {
          if (image?.base64?.includes('http')) {
            const response = await this.$api.uploader.imageProxy([image.base64]);
            image.base64 = `;base64,${response[image.base64]}`;
            await this.editAd({ ...data, image });
          } else {
            this.onSaveChanges();
          }
        } catch {
          this.editAdData = data;
          this.$n_failNotification({ title: 'Failed to save changes!' });
        } finally {
          this.isLoading = false;
        }
      }
    },
    onImageError,
  },
});
