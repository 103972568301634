export default [
  {
    name: 'Include All Mobile OS',
    code: true,
    reset: true,
  },
  {
    name: 'Exclude All Mobile OS',
    code: false,
    reset: true,
  },
  {
    name: 'Android 13.x.x',
    code: 'android13mobile',
  },
  {
    name: 'Android 12.x.x',
    code: 'android12mobile',
  },
  {
    name: 'Android 11.x.x',
    code: 'android11mobile',
  },
  {
    name: 'Android 10.x.x',
    code: 'android10mobile',
  },
  {
    name: 'Android 9.x.x',
    code: 'android90mobile',
  },
  {
    name: 'Android 8.x.x',
    code: 'android80mobile',
  },
  {
    name: 'Android 7.x.x',
    code: 'android70mobile',
  },
  {
    name: 'Android 6.x.x',
    code: 'android60mobile',
  },
  {
    name: 'Android 5.x.x',
    code: 'android50mobile',
  },
  {
    name: 'Android 4.4',
    code: 'android44mobile',
  },
  {
    name: 'Android 4.3',
    code: 'android43mobile',
  },
  {
    name: 'Android 4.2',
    code: 'android42mobile',
  },
  {
    name: 'Android 4.1',
    code: 'android41mobile',
  },
  {
    name: 'Android 4.0',
    code: 'android40mobile',
  },
  {
    name: 'Android 3.x.x',
    code: 'android3mobile',
  },
  {
    name: 'Android 2.3',
    code: 'android23mobile',
  },
  {
    name: 'Android 2.2 and lower',
    code: 'android22mobile',
  },
  {
    name: 'iOS 16.x.x',
    code: 'ios16mobile',
  },
  {
    name: 'iOS 15.x.x',
    code: 'ios15mobile',
  },
  {
    name: 'iOS 14.x.x',
    code: 'ios14mobile',
  },
  {
    name: 'iOS 13.x.x',
    code: 'ios13mobile',
  },
  {
    name: 'iOS 12.x.x',
    code: 'ios12mobile',
  },
  {
    name: 'iOS 11.x.x',
    code: 'ios11mobile',
  },
  {
    name: 'iOS 10.x.x',
    code: 'ios10mobile',
  },
  {
    name: 'iOS 9.x.x',
    code: 'ios9mobile',
  },
  {
    name: 'iOS 8.x.x',
    code: 'ios8mobile',
  },
  {
    name: 'iOS 7.x.x',
    code: 'ios7mobile',
  },
  {
    name: 'iOS 6.x.x',
    code: 'ios6mobile',
  },
  {
    name: 'iOS 5.x.x',
    code: 'ios5mobile',
  },
  {
    name: 'iOS 4.x.x and lower',
    code: 'ios4mobile',
  },
  {
    name: 'Other Mobile OS',
    code: 'othermobile',
  },
];
