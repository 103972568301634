<template>
  <!-- Modal Component -->
  <b-modal
    id="traffic-source-modal"
    v-model="modal"
    modal-class="optimizer-modal"
    :no-close-on-backdrop="noCloseOnBlur"
    :no-close-on-esc="noCloseOnBlur"
    size="s"
    centered
    @ok="submit"
    @hidden="$_hidden"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <h2 v-if="form.action === 'edit'" class="modal-header__title">
        Edit Traffic Source
      </h2>
      <h2 v-else class="modal-header__title">Select your {{ form.trafficSourceName }} {{ form.accountAlias }}</h2>
      <svg
        class="modal-header__close-icon"
        @click="hideModal"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L13 13"
          stroke="#546582"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M13 1L1 13"
          stroke="#546582"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </template>

    <!-- Modal Body -->
    <MultiAccountForm ref="multiAccountForm" :mode="form" />
    <!-- Modal Footer -->
    <template slot="modal-footer">
      <b-btn
        :disabled="loadingStatus"
        class="secondary-button"
        @click="hideModal"
      ><template v-if="loadingStatus"><i class="fa fa-spinner fa-spin"></i> </template>Cancel</b-btn>
      <b-btn :disabled="loadingStatus" class="primary-button" @click="submit"><template v-if="loadingStatus"><i class="fa fa-spinner fa-spin"></i> </template>Save</b-btn>
    </template>
  </b-modal>
</template>

<style scoped></style>

<script>
import MultiAccountForm from './MultiAccountForm';

export default {
  name: 'MultiAccountModal',
  components: {
    MultiAccountForm,
  },
  props: {
    noCloseOnBlur: { type: Boolean, default: false },
  },
  data() {
    return {
      modal: false,
      form: {
        //  action: create|edit, accountId: null|Number
        action: 'create',
        accounts: [],
        typeName: null,
        typeId: null,
        trafficSourceName: 'Traffic Source',
        accountAlias: 'Account',
      },
      loadingStatus: false,
    };
  },
  created() {
    // console.info('Created: TrafficSourceModal')
  },
  methods: {
    showModal(accounts = [], trafficSourceName = 'Traffic Source', accountAlias = 'Account') {
      this.$_setForm(accounts, trafficSourceName, accountAlias);
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    async submit(event) {
      // Prevent modal from closing
      event.preventDefault();
      this.loadingStatus = true;
      try {
        const account = await this.$refs.multiAccountForm.save();
        if (account !== false) this.modal = false;
        this.$emit('submit', account);
      } catch (error) {
        this.$emit('submit', false);
      }
      this.loadingStatus = false;
    },
    $_hidden() {
      this.$_setForm({}); // Reset
    },
    $_setForm(accounts, trafficSourceName, accountAlias) {
      this.form.action = 'create';
      this.form.accounts = accounts;
      this.form.trafficSourceName = trafficSourceName;
      this.form.accountAlias = accountAlias;
    },
  },
};
</script>
