
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { getTrafficSourceLogo } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  isLoading: boolean;
  trafficSources: SelectOption<string>[];
  selectedTrafficSource?: SelectOption<string>;
  modalTitle: string;
  modalText: string;
  file: File | null;
  isModal: boolean;
  activePhase: number;
  modal: boolean;
}

export default (Vue as VueConstructor<Vue & NotificationMixin>).extend({
  name: 'CsvUpload',
  components: {
    OptimizerIcon,
  },
  mixins: [notifications],
  data(): ComponentData {
    return {
      isLoading: false,
      selectedTrafficSource: undefined,
      modalTitle: 'Excel File uploaded successfully',
      modalText: 'You will be notified via email once the process has been completed.',
      file: null,
      trafficSources: [
        {
          content: 'Taboola',
          value: 'Taboola',
        },
        {
          content: 'Outbrain',
          value: 'Outbrain',
        },
        {
          content: 'Mgid',
          value: 'Mgid',
        },
        {
          content: 'RevContent',
          value: 'RevContent',
        },
      ],
      isModal: false,
      activePhase: 1,
      modal: false,
    };
  },
  computed: {
    templateLinks() {
      return {
        Outbrain: 'https://docs.google.com/spreadsheets/d/1-2SbPDVBnjkqtgNv23C37rfBHrZIAb-GeQncuhh9XQQ/copy',
        Taboola: 'https://docs.google.com/spreadsheets/d/14Z3lSobmqQxY83I-XK6foWtLTxy3qGP7_2yrIsBBAAw/copy',
        Mgid: 'https://docs.google.com/spreadsheets/u/1/d/1FU0IIrWXHpjt6VBZgtzZT4MVBcN7mXJQTMWc7SPd5ZU/copy',
        RevContent: 'https://docs.google.com/spreadsheets/u/1/d/1Y-xH7rw8H20tzVxQXcp3tLi-dhJuBb_RLcHHt2pAqWM/copy',
      };
    },
    articleUrl(): string {
      const trafficSourceUrls: Record<string, string> = {
        Outbrain: 'https://theoptimizer.io/help/knowledge-base/bulk-upload-campaigns-to-outbrain-via-excel-file/',
        Taboola: 'https://theoptimizer.io/help/knowledge-base/bulk-upload-campaigns-to-taboola-via-csv/',
        Mgid: 'https://theoptimizer.io/help/article-categories/csv-camapign-creator/',
        RevContent: 'https://theoptimizer.io/help/article-categories/csv-camapign-creator/',
      };

      const selectedTrafficSource = this.selectedTrafficSource?.value || '';
      return trafficSourceUrls[selectedTrafficSource] || '';
    },
  },
  methods: {
    getTrafficSourceLogo,
    showModal() {
      this.modal = true;
    },
    goToStep(step: number) {
      this.activePhase = step;
    },
    loadTextFromFile(event: Event) {
      const fileInput = event.target as HTMLInputElement | null;
      const file = fileInput?.files?.[0];

      if (file) {
        const extension = file.name.split('.').slice(-1)[0];

        if (extension === 'xlsx') {
          this.file = file;
        } else {
          this.$n_failNotification({ title: 'Please upload an Excel file' });
        }
      }
    },
    async handleSubmit() {
      this.isLoading = true;
      const formData = new FormData();

      if (this.file) {
        formData.append('form', this.file);
        formData.append('filename', this.file.name);
        try {
          if (this.selectedTrafficSource) {
            await this.$api.campaignCreator.uploadCsv(this.selectedTrafficSource.value, formData);
            this.hideModal();
            this.isModal = true;
          }
        } catch (error: any) {
          this.hideModal();
          this.$n_failNotification({
            title: error?.response?.data?.message || 'Please check the Excel File Format and try again!',
          });
        }
      }
      this.isLoading = false;
    },
    hideModal() {
      this.modal = false;
      this.activePhase = 1;
      this.clearFile();
    },
    clearFile() {
      this.file = null;
      const fileInput = this.$refs.file;

      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = '';
      }
    },
    onModalClose() {
      this.isModal = false;
    },
  },
});
