/* eslint-disable no-restricted-globals */
import moment from 'moment';

const colors = {
  green: '#61a633',
  black: '#000000',
  red: '#ff5454',
  scarlet: '#ff2400',
  orange: 'orange',
  blue: 'blue',
  brown: '#bd5d0f',
};
// TODO should edit campaign statuses to lowercase as from clicjhouse
const optimizerCampaignStatus = {
  active: {
    text: 'ACTIVE',
    color: colors.green,
  },
  running: {
    text: 'RUNNING',
    color: colors.green,
  },
  RUNNING: {
    text: 'RUNNING',
    color: colors.green,
  },
  PAUSED: {
    text: 'PAUSED',
    color: colors.red,
  },
  REJECTED: {
    text: 'REJECTED',
    color: colors.black,
  },
  'OUT OF FUNDS': {
    text: 'OUT OF FUNDS',
    color: colors.orange,
  },
  'ON MODERATION': {
    text: 'ON MODERATION',
    color: colors.blue,
  },
  OTHER: {
    text: 'OTHER',
    color: colors.brown,
  },
};

const optimizerContentStatus = {
  APPROVED: {
    text: 'APPROVED',
    color: colors.green,
  },
  REJECTED: {
    text: 'REJECTED',
    color: colors.red,
  },
  PAUSED: {
    text: 'PAUSED',
    color: colors.scarlet,
  },
  PENDING: {
    text: 'PENDING',
    color: colors.orange,
  },
  OTHER: {
    text: 'OTHER',
    color: colors.brown,
  },
};

const optimizerMobilePublisherStatus = {
  active: {
    text: 'ACTIVE',
    color: colors.green,
  },
  ACTIVE: {
    text: 'ACTIVE',
    color: colors.green,
  },
  BLOCKED: {
    text: 'BLOCKED',
    color: colors.red,
  },
  OTHER: {
    text: 'OTHER',
    color: colors.brown,
  },
};

const ui = {
  toFixed(num, fixed) {
    if (!num) num = 0;
    const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
    return parseFloat((num || 0).toString().match(re)[0]);
  },
  cmToFixed(num, fixed) {
    if (!num) num = 0;
    return Number(num).toFixed(fixed);
  },
  numFormat(num) {
    return ui.toFixed(num, 3);
  },
  currencyFormat(num, sign = '$') {
    return !isNaN(num) ? `${sign}${ui.numFormat(num)}` : '-';
  },
  percentFormat(num) {
    return !isNaN(num) ? `${ui.numFormat(num)}%` : '-';
  },
  timeFormat(sec) {
    return sec ? moment.utc((parseFloat(sec) || 0) * 1000).format('HH:mm:ss') : '-';
  },
  getGeminiBidModifierToPercentage(bidModifier) {
    if (bidModifier <= 1) {
      return parseInt(Math.round(parseFloat((bidModifier * 100) - 100)), 10);
    }
    return parseInt(Math.round(parseFloat(((bidModifier - 1) * 100))), 10);
  },
  getGeminiPercentageToBidModifier(percetage) {
    return ui.toFixed((percetage / 100) + 1, 2);
  },
  avg(sum, length) {
    return length > 0 ? sum / length : 0;
  },
  getSign(value) {
    return value >= 0 ? '' : '-';
  },
  getUndefinedIcon(v) {
    return typeof v === 'undefined' ? '-' : v;
  },
  getConditionalColor(stat, value, defaultColor, cases) {
    let color = null;
    Object.keys(cases).forEach((case_) => {
      if (case_ === 'gt' && stat > value) {
        color = cases.gt;
      } else if (case_ === 'lt' && stat < value) {
        color = cases.lt;
      } else if (case_ === 'gte' && stat >= value) {
        color = cases.gte;
      } else if (case_ === 'lte' && stat <= value) {
        color = cases.lte;
      } else if (case_ === 'eq' && stat === value) {
        color = cases.eq;
      }
    });
    if (color) {
      return color;
    }
    return defaultColor;
  },
  signColorStyle(string, value) {
    return `<span style="color: ${ui.getConditionalColor(value, 0, colors.black, { gt: colors.green, lt: colors.red })}">${string}</span>`;
  },
  table: {
    spent(spent) {
      return typeof spent === 'undefined' ? '-' : `<span style="color: ${colors.black}">${ui.getSign(spent)}$${ui.numFormat(Math.abs(spent))}</span>`;
    },
    revenue(revenue) {
      return typeof revenue === 'undefined' ? '-' : `<span style="color: ${colors.black}">${ui.getSign(revenue)}$${ui.numFormat(Math.abs(revenue))}</span>`;
    },
    profit(profit) {
      return typeof profit === 'undefined' ? '-' : `<span style="color: ${ui.getConditionalColor(profit, 0, colors.black, { gt: colors.green, lt: colors.red })}">${ui.getSign(profit)}$${ui.numFormat(Math.abs(profit))}</span>`;
    },
    net(net) {
      return typeof net === 'undefined' ? '-' : `<span style="color: ${ui.getConditionalColor(net, 0, colors.black, { gt: colors.green, lt: colors.red })}">$${ui.getSign(net)}${ui.numFormat(Math.abs(net))}</span>`;
    },
    roi(roi) {
      return typeof roi === 'undefined' ? '-' : `<span style="color: ${ui.getConditionalColor(roi, 0, colors.black, { gt: colors.green, lt: colors.red })}">${ui.getSign(roi)}${ui.numFormat(Math.abs(roi))}%</span>`;
    },
    ctr(ctr) {
      return typeof ctr === 'undefined' ? '-' : `<span style="color: ${colors.black}}">${ui.getSign(ctr)}${ui.numFormat(Math.abs(ctr))}%</span>`;
    },
    percentage(v) {
      return typeof v === 'undefined' ? '-' : `${Number(v).toFixed(2)}%`;
    },
    money(v) {
      return typeof v === 'undefined' ? '-' : `${ui.getSign(v)}$${ui.numFormat(Math.abs(v))}`;
    },
    campaignName(cmpName, trName) {
      return trName ? `<div style="display: inline;"><img style="width: 20px; display: inline;" src="/static/img/accounts/${trName}.png"/><div style="display: inline;">${cmpName}</div>`
        : `<div style="display: inline;">${cmpName}</div>`;
    },
    urlFix(url) {
      if (typeof url === 'undefined' || !url) return '';
      return url.indexOf('http') === 0 ? url : `//${url}`;
    },
  },
  campaign: {
    getStatus(status) {
      return optimizerCampaignStatus[status] || optimizerCampaignStatus.OTHER;
    },
    status(status) {
      // const statusObj = ui.campaign.getStatus(status);
      // return typeof status === 'undefined' ? '-' : `<span class="d-flex align-items-center"><span class="circle circle-${status}"></span> ${statusObj.text}</span>`;
      return typeof status === 'undefined' ? '-' : `<span class="circle circle-${status} m-0"></span>`;
    },
  },
  content: {
    getStatus(status) {
      return optimizerContentStatus[status] || optimizerContentStatus.OTHER;
    },
    status(status) {
      // const statusObj = ui.content.getStatus(status);
      // return typeof status === 'undefined' ? '-' : `<span class="d-flex align-items-center"><span class="circle circle-${status}"></span> ${statusObj.text}</span>`;
      return typeof status === 'undefined' ? '-' : `<span class="stats-circle circle-${status} m-0"></span>`;
    },
  },
  publishers: {
    getStatus(status) {
      return optimizerMobilePublisherStatus[status] || optimizerMobilePublisherStatus.OTHER;
    },
    status(status) {
      return typeof status === 'undefined' ? '-' : `<span class="stats-circle circle-${status} m-0"></span>`;
    },
  },
  colors,
};

export default ui;
