/* eslint-disable import/no-cycle */
import { EntityForCreation } from '@/views/Automation/CampaignCreatorV2/ab/core/GroupABEntity';
import { ABDependency, Macro } from '@/views/Automation/CampaignCreatorV2/ab/core/IABTest';
import * as AdMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Ad';
import * as AdCreativeMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import { SupportedCropRatio } from '@/views/Automation/CampaignCreatorV2/helpers/facebook/AdCreative';
import * as CampaignMeta from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Campaign';
import { AdValidation } from '@/views/Automation/CampaignCreatorV2/validation/Ad';
import { AdSetDTO } from '@/views/Automation/CampaignCreatorV2/validation/AdSetDTO';
import {
  CampaignCreatorTask,
  TaskCategory,
  TaskType,
} from '@/views/Automation/CampaignCreatorV2/validation/CampaignCreatorTask';
import {
  AbVariableErrorDTO,
  CampaignDTO,
  FacebookDTOMetrics,
} from '@/views/Automation/CampaignCreatorV2/validation/CampaignDTO';
import { CreativeValidation } from '@/views/Automation/CampaignCreatorV2/validation/Creative';
import moment from 'moment';
import uid from 'uid';
import { NameAbVariable } from '../ab/core/NameAbVariable';
import { Status } from '../helpers';
import { ErrorType, InternalABVariable, SupportedEntities } from '../store/Types';
import { FacebookPage } from '../types';
import { FacebookAbGroups } from './FacebookAbGroups';
import { FieldConfiguration, MacroType } from './ValidationTypes';

export class AdDTO extends CampaignCreatorTask {
  static fromApiToVariables(
    variation: {
      call_to_action_type: string;
      body: string;
      headline: string;
      description: string;
    },
    image?: {
      image_url: string;
      image_width?: number;
      image_height?: number;
      image_size?: number;
    }
  ): Record<string, InternalABVariable<any> & { fieldGroup: { readableName: string } }> {
    const variables: Partial<{
      [k in keyof AdCreativeMeta.AdCreative]: Omit<InternalABVariable<AdCreativeMeta.AdCreative[k]>, 'variableId'> & {
        fieldGroup: { readableName: string };
      };
    }> = {
      body: { field: 'body', value: [variation.body], fieldGroup: FacebookAbGroups.CREATIVE_ITEMS },

      call_to_action_type: {
        field: 'call_to_action_type',
        value: variation.call_to_action_type as any,
        fieldGroup: FacebookAbGroups.CREATIVE_ITEMS,
      },
      title: {
        field: 'title',
        value: [variation.body],
        fieldGroup: FacebookAbGroups.CREATIVE_ITEMS,
      },
      headline: {
        field: 'headline',
        value: [variation.headline],
        fieldGroup: FacebookAbGroups.CREATIVE_ITEMS,
      },
      image: {
        field: 'image',
        value: {
          id: '',
          image_url: image?.image_url ?? '',
          width: image?.image_width,
          height: image?.image_height,
          size: image?.image_size,
        },
        name: variation.headline,
        fieldGroup: FacebookAbGroups.CREATIVE_ITEMS,
      },
    };
    return variables as any;
  }

  public static variablePreview<T = unknown>(item?: ABDependency<T>) {
    let fieldConfig: Partial<FieldConfiguration> = {} as any;

    try {
      fieldConfig = AdDTO.getFieldConfiguration(item?.field as any);
    } catch (e) {
      try {
        fieldConfig = AdDTO.getFieldConfigurationCreative(item?.field as any);
      } catch (e) {
        fieldConfig = { readableName: item?.field, fieldGroup: 'TARGETING' };
      }
    }
    const group = FacebookAbGroups[fieldConfig.fieldGroup! as 'TARGETING'];

    return {
      field: fieldConfig.readableName ?? item?.field,
      name: fieldConfig.readableName ?? item?.field,
      groupName: group?.readableName,
      value: fieldConfig.renderFunction ? fieldConfig.renderFunction(item?.value) : item?.value,
    };
  }

  public itemMetrics(): FacebookDTOMetrics {
    return {
      meta: {
        adNumber: { entity: 'Ads', name: 'Number of Ads', key: 'adNumber', suffix: 'Ads created' },
        images: { entity: 'Ads', name: 'Images', key: 'images', suffix: 'Unique images found' },
        headlines: { entity: 'Ads', name: 'Headlines', key: 'headlines', suffix: 'Unique headlines found' },
        descriptions: { entity: 'Ads', name: 'Descriptions', key: 'descriptions', suffix: 'Unique descriptions found' },
        adVariations: {
          entity: 'Ads',
          name: 'Ad Variations',
          key: 'adVariations',
          suffix: 'Unique ad variations found',
        },
      },
      metrics: {
        adNumber: 1,
        images: [this.findVariableCreative('image')?.abId!],
        headlines: [this.findVariableCreative('headline')?.abId!],
        descriptions: [this.findVariableCreative('body')?.abId!],
        adVariations: [
          this.variableList
            .map((e) => [e.abId, ...(e.abGroups || [])]?.join(','))
            .sort()
            .join(','),
        ],
      },
    };
  }

  private campaignTask?: CampaignMeta.CreateApi;
  // private adSetTask?: AdSetMeta.Create & { internalId: string };
  private adSetTask?: any & { internalId: string };

  constructor(
    private variableList: ABDependency<any>[],
    private entityBuildId: string,
    private macros: Macro[],
    private pages?: FacebookPage[],
    private pixelList?: { id: string; name: string }[]
  ) {
    super({ taskType: TaskType.AD, taskCategory: TaskCategory.Facebook });
    this.calculateName();
    this.macros;
  }

  public calculateName() {
    const nameVariable = this.findVariable('name')?.value!;
    const flatMacros = this.macros.map((m) => [m, ...m.nestedMacros]).flat();
    const usedAbGroups = this.variableList.map((e) => [e.variableUniquePath, e.abGroup]).flat();
    // const newName = calculateName(nameVariable, this.macros, this.variableList);
    const macrosInNameValues = nameVariable.macros?.map((m) => {
      const fieldConfig = AdDTO.generateAdFieldConfig(m.field!);
      const variable = this.findVariableCreative(m.field as 'headline') || this.findVariable(m.field as 'name');
      if (m.type === MacroType.Value) {
        if (fieldConfig?.renderFunction && variable) {
          return fieldConfig.renderFunction(variable?.value);
        }
        return variable?.value?.toString();
      }
      if (m.type === MacroType.VariableName) {
        return variable?.name;
      }
      if (m.type === MacroType.GroupName) {
        return flatMacros.find((e) => e.name === m.value && usedAbGroups.includes(e.value.variableUniquePath))?.value
          ?.value;
      }
      if (m.type === MacroType.TextValue) {
        return m.value;
      }
      if (m.type === MacroType.CurrentDate) {
        return moment().format('YYYY-MM-DD');
      }
      if (m.type === MacroType.CurrentTime) {
        return moment().format('HH:mm');
      }
      if (m.type === MacroType.SubEntityName) {
        return (
          this.getSubTaskList()
            .map((e) => {
              (e as AdSetDTO).calculateName();
              return e.getName();
            })
            .filter(Boolean)
            .join(nameVariable.itemSeparator) || '(Ad Set name will be here)'
        );
      }
      if (m.type === MacroType.SubEntityNumber) {
        return this.getSubTaskList().length || '(Ad Set number will be here)';
      }
    });
    const calculatedName = nameVariable.name + (macrosInNameValues?.join(nameVariable.fieldSeparator) || '');
    this.setName(calculatedName);
  }

  private findVariable<
    M extends typeof AdValidation,
    K extends keyof M,
    V = K extends keyof AdMeta.Create ? AdMeta.Create[K] : never
  >(fieldName: K): ABDependency<V> | undefined {
    return this.variableList.find((variable) => variable.field === (fieldName as string));
  }

  private findVariableCreative<
    M extends typeof CreativeValidation,
    K extends keyof M,
    V = K extends keyof AdCreativeMeta.AdCreative ? AdCreativeMeta.AdCreative[K] : never
  >(fieldName: K): ABDependency<V> | undefined {
    return this.variableList.find((variable) => variable.field === (fieldName as string));
  }

  public setCampaignTask(campaignTask: CampaignDTO) {
    this.campaignTask = campaignTask.getPayload();
    return this;
  }

  public setAdSetTask(adSetTask: AdSetDTO) {
    this.adSetTask = { ...adSetTask.getPayload(), internalId: adSetTask.getInternalId() };
    return this;
  }

  public static validateField<M extends typeof AdValidation, F extends keyof typeof AdValidation>(
    field: F,
    value: M[F]
  ) {
    const fieldConfig = AdValidation[field];

    if (fieldConfig.validation) {
      const result = fieldConfig.validation.validate(value);

      if (result.error) {
        return result.error;
      }
    }
    return null;
  }

  public static getFieldConfiguration(field: string) {
    const config = AdValidation[field as 'name'];

    if (!config) {
      throw new Error(
        'Field does not have a configuration, please make sure to add metadata before using it as AB variable'
      );
    }

    return config;
  }

  public static getFieldConfigurationCreative(field: string) {
    const config = CreativeValidation[field as 'title'];

    if (!config) {
      throw new Error(
        'Field does not have a configuration, please make sure to add metadata before using it as AB variable'
      );
    }

    return config;
  }

  public static validateCreativeField<M extends typeof CreativeValidation, F extends keyof typeof CreativeValidation>(
    field: F,
    value: M[F]
  ) {
    const fieldConfig = CreativeValidation[field];

    if (fieldConfig.validation) {
      const result = fieldConfig.validation.validate(value);

      if (result.error) {
        return result.error;
      }
    }

    return null;
  }

  public validate(payload: AdMeta.CreateApi) {
    const errors: AbVariableErrorDTO[] = [];

    const pageId = payload.creative?.object_story_spec?.page_id;
    const existPage = this.pages?.find((e) => e.id === pageId) || !this.pages?.length;
    if (!pageId || !existPage) {
      errors.push({
        message: `A valid Page ID is required`,
        field: 'actor_id',
        type: ErrorType.BLOCKING,
        entityBuildId: this.entityBuildId,
        entity: SupportedEntities.creative,
        variableId: this.findVariableCreative('actor_id')?.abId,
        abGroup: this.findVariableCreative('actor_id')?.abGroup,
        itemEntity: SupportedEntities.creative,
      });
    }
    if (!this.findVariableCreative('link_url')?.value) {
      errors.push({
        message: 'Website URL is required',
        field: 'link_url',
        type: ErrorType.MISSING,
        entityBuildId: this.entityBuildId,
        entity: SupportedEntities.creative,
        itemEntity: SupportedEntities.creative,
      });
    }
    // Make sure pixel is selected
    const pixelId = payload.tracking_specs?.[0]?.fb_pixel;
    const existPixel = this.pixelList?.find((e) => e.id === pixelId);
    if (!payload.tracking_specs?.[0]?.fb_pixel || (!existPixel && this.pixelList?.length)) {
      errors.push({
        message: 'Pixel ID is required',
        field: 'pixel_id',
        type: ErrorType.MISSING,
        entityBuildId: this.entityBuildId,
        entity: SupportedEntities.creative,
        itemEntity: SupportedEntities.creative,
        abGroup: this.findVariableCreative('pixel_id')?.abGroup,
        variableId: this.findVariableCreative('pixel_id')?.abId,
      });
    }
    return errors;
  }

  public static variationPreview(preview: EntityForCreation<unknown>) {
    const id = preview.entityId + preview.variables.map((e) => e.variableUniquePath).join('-') + uid(10);
    const dto = new AdDTO(preview.variables, preview.entityId, preview.macros);
    return {
      id,
      title: dto.getName(),
      name: dto.getName(),
      status: preview.status,
      movingVariables: preview.movingVariables,
      variables: preview.variables
        .filter((e) => e.type !== 'internal')
        .map((item) => {
          const fieldConfig = AdDTO.generateAdFieldConfig(item.field);
          return {
            field: item.field,
            name: fieldConfig.readableName ?? item.field,
            value: fieldConfig.renderFunction ? fieldConfig.renderFunction(item.value) : item.value,
          };
        }),
      active: preview.status,
      entity: 'ad',
    };
  }

  private static generateAdFieldConfig(field: string) {
    let fieldConfig = {} as any;
    try {
      fieldConfig = AdDTO.getFieldConfiguration(field);
    } catch (e) {
      try {
        fieldConfig = AdDTO.getFieldConfigurationCreative(field);
      } catch (e) {
        fieldConfig = { readableName: field };
      }
    }
    return fieldConfig;
  }

  private getImage(): { image?: any; images?: any[]; rules?: any[] } {
    const image = this.findVariableCreative('image')?.value!;
    if (!image) {
      return {};
    }
    const crops = this.getImageCrops(image);
    const hasImageVariations = Object.values(crops || {}).some((e) => e);
    if (!hasImageVariations) {
      return { image };
    }
    const images = Object.entries(crops).map(([crops, pixelLocation], i) => {
      return {
        adlabels: [{ name: `placement_asset_${uid(10)}` }],
        hash: `#${this.findVariableCreative('image')?.value?.image_url}#`,
        image_crops: {
          [crops]: pixelLocation,
        },
      };
    });
    const defaultImage = {
      adlabels: [{ name: `placement_asset_${uid(10)}` }],
      hash: `#${this.findVariableCreative('image')?.value?.image_url}#`,
    };

    const rules = images.map((e, i) => {
      const crops = Object.keys(e?.image_crops || {}) || [];
      const cropRatio = crops[0];
      const customization_spec = SupportedCropRatio[cropRatio as '191x100'];
      return {
        customization_spec,
        image_label: e.adlabels[0],
        priority: i + 1,
      };
    });

    const defaultRule = {
      customization_spec: {
        age_max: 65,
        age_min: 13,
      },
      image_label: defaultImage.adlabels[0],
      priority: images.length + 1,
    };

    return { images: [...images, defaultImage], image, rules: [...rules, defaultRule] };
  }

  private buildAssetFeed() {
    const { image, images, rules } = this.getImage();
    const bodyAdLabels = rules?.map((r, i) => {
      const name = `placement_asset_${uid(10)}`;
      return {
        name,
      };
    });
    const bodies = this.findVariableCreative('title')?.value?.map((v, i) => ({
      text: v,
      adlabels: bodyAdLabels,
    }))!;
    const titlesAdLabels = rules?.map((r, i) => {
      const name = `placement_asset_${uid(10)}`;
      return {
        name,
      };
    });
    const titles = this.findVariableCreative('headline')?.value?.map((v, i) => ({
      text: v,
      adlabels: titlesAdLabels,
    }))!;
    const descriptionsadlabels = rules?.map((r, i) => {
      const name = `placement_asset_${uid(10)}`;
      return {
        name,
      };
    });
    const descriptions = this.findVariableCreative('body')?.value?.map((v, i) => ({
      text: v,
      adlabels: descriptionsadlabels,
    }))!;

    const linkUrlAdLabels = rules?.map((r, i) => {
      const name = `placement_asset_${uid(10)}`;
      return {
        name,
      };
    });
    const website_url = this.findVariableCreative('link_url')?.value;
    const display_url = this.findVariableCreative('link_destination_display_url')?.value;
    const link_urls = [
      {
        website_url,
        display_url,
        adlabels: linkUrlAdLabels,
      },
    ];
    const isDynamic = [bodies, titles, descriptions].some((e) => e?.length > 1) || !!images?.length;
    const asset_feed_spec = !isDynamic
      ? undefined
      : {
          images: images?.length ? images : undefined,
          bodies: isDynamic || bodies?.length > 1 || images?.length ? bodies : undefined,
          titles: isDynamic || titles?.length > 1 || images?.length ? titles : undefined,
          descriptions: isDynamic || descriptions?.length > 1 || images?.length ? descriptions : undefined,
          optimization_type: images?.length ? 'PLACEMENT' : 'DEGREES_OF_FREEDOM',
          link_urls: images?.length ? link_urls : undefined,
          ad_formats: images?.length ? ['AUTOMATIC_FORMAT'] : undefined,
          call_to_action_types: images?.length ? [this.findVariableCreative('call_to_action_type')?.value!] : undefined,
        };
    // Here it Requires rules, to display images based on the aspect ratio
    if (asset_feed_spec) {
      (asset_feed_spec as any).asset_customization_rules = rules?.map((r, i) => {
        const title_label = titlesAdLabels?.[i];
        const body_label = bodyAdLabels?.[i];
        const description_label = descriptionsadlabels?.[i];
        const link_url_label = linkUrlAdLabels?.[i];
        return {
          ...r,
          title_label,
          body_label,
          description_label,
          link_url_label,
        };
      });
    }

    return { image, titles, bodies, descriptions, isDynamic, asset_feed_spec: asset_feed_spec!, images };
  }

  private getLinkData() {
    const { bodies, titles, descriptions, images } = this.buildAssetFeed();
    if (images?.length) {
      return;
    }
    return {
      link: this.findVariableCreative('link_url')?.value!,
      caption: this.findVariableCreative('link_destination_display_url')?.value!,
      attachment_style: 'link',
      name: titles?.length > 1 ? undefined : titles?.[0]?.text,
      message: bodies?.length > 1 ? undefined : bodies?.[0]?.text,
      description: descriptions?.length > 1 ? undefined : descriptions?.[0]?.text,
      image_hash: `#${this.findVariableCreative('image')?.value?.image_url}#`,
      call_to_action: {
        type: this.findVariableCreative('call_to_action_type')?.value!,
      },
    };
  }

  public buildCreative(): AdCreativeMeta.CreateApi {
    const { image, asset_feed_spec } = this.buildAssetFeed();

    return {
      image,
      parentInternalId: this.getInternalId(),
      object_story_spec: {
        page_id: this.findVariableCreative('actor_id')?.value!,
        instagram_actor_id: this.findVariableCreative('instagram_actor_id')?.value!,
        link_data: this.getLinkData()!,
      },
      asset_feed_spec,
      image_crops: image && (this.getImageCrops(image) as AdCreativeMeta.ImageCrops),
      url_tags: this.findVariableCreative('url_tags')?.value!,
      degrees_of_freedom_spec: {
        creative_features_spec: {
          standard_enhancements: {
            enroll_status: 'OPT_OUT',
          },
        },
      },
    };
  }

  public buildAd() {
    const nameVariable = this.findVariable('name');
    if (!nameVariable) {
      throw new Error('Name variable not found.');
    }
    (nameVariable as unknown as NameAbVariable<any>).entityMacros = [];
    const createdAd: CampaignMeta.CreateApi = {
      // @ts-ignore
      name: this.getName(),
      status: Status.ACTIVE,
      // @ts-ignore
      adset_id: this.getAnonymousVariable(this.adSetTask?.internalId!),
      creative: this.buildCreative(),
      tracking_specs: [
        {
          action_type: ['offsite_conversion'],
          fb_pixel: this.findVariableCreative('pixel_id')?.value!,
        },
      ],
    };
    return createdAd;
  }

  private getImageCrops(image: AdCreativeMeta.ImageCreative) {
    const imageVariations = image.cropVariations;
    const imageCrops: Record<string, number[][]> = {};
    for (const key in imageVariations) {
      if (Object.prototype.hasOwnProperty.call(imageVariations, key)) {
        const cropVariation = imageVariations[key as AdCreativeMeta.SupportedCrop];
        if (cropVariation) {
          const { cropRatio, pixelCoordinates } = cropVariation;
          if (cropVariation.useCrop !== false) {
            imageCrops[cropRatio] = pixelCoordinates;
          }
        }
      }
    }
    return imageCrops;
  }

  public setupTask(): AbVariableErrorDTO[] {
    if (!this.campaignTask) {
      throw new Error('Campaign task not set or payload not set. unable to create Ad');
    }
    if (!this.adSetTask) {
      throw new Error('Ad Set task not set or payload not set. unable to create Ad');
    }
    this.calculateName();

    // @ts-ignore
    const payload: AdMeta.CreateApi = this.buildAd();

    this.setPayload(payload);

    return this.validate(payload);
  }
}
