<template>
  <div class="user-info-container">
    <div class="row">
      <!-- User Details -->
      <div class="col-md-6">
        <div class="user-info-block">
          <h4 class="user-info-block__title">User Details</h4>
          <div class="optimizer-card">

            <!-- Loading Skeleton -->
            <div v-if="loading" class="row">
              <div v-for="item in 4" :key="item" :class="[ item > 2 ? '' : 'mb-5', 'col-md-12']">
                <loading-skeleton class="optimizer-form-skeleton" type="form" />
              </div>
            </div>

            <template v-else>
              <div class="user-info-avatar">
                <div class="user-info-avatar__image">
                  <img src="sh_static/header/avatar.png">
                  <b-btn class="upload-new-btn" type="link">Upload New Image</b-btn>
                </div>
                <b-btn class="remove-btn">Remove</b-btn>
              </div>

              <b-form-group
                class="optimizer-form-group"
                label="First Name *"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="First Name"
                  type="text"
                  :value="profile.firstName"
                  name="firstName"
                  key="firstName"
                  placeholder="First Name"
                />
              </b-form-group>

              <b-form-group
                class="optimizer-form-group"
                label="Last Name *"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="Last Name"
                  type="text"
                  :value="profile.lastName"
                  name="lastName"
                  key="lastName"
                  placeholder="Last Name"
                />
              </b-form-group>

              <b-form-group
                class="optimizer-form-group"
                label="Email *"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="Email"
                  type="email"
                  :value="profile.email"
                  name="email"
                  key="email"
                  placeholder="Your email"
                />
              </b-form-group>

              <a class="change-details-btn" href="https://membership.theoptimizer.io" target="_blank">Update User Details</a>
            </template>
          </div>
        </div>
      </div>

      <!-- Business Details -->
      <div class="col-md-6">
        <div class="user-info-block">
          <h4 class="user-info-block__title">Business Details</h4>
          <div class="optimizer-card">
            <!-- Loading Skeleton -->
            <div v-if="loading" class="row">
              <div v-for="item in 3" :key="item" :class="[ item > 2 ? '' : 'mb-5', 'col-md-12']">
                <loading-skeleton class="optimizer-form-skeleton" type="form" />
              </div>
            </div>
            <template v-else>
              <b-form-group
                class="optimizer-form-group"
                label="Company Name"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="Company Name"
                  type="text"
                  :value="profile.companyName"
                  name="companyName"
                  key="companyName"
                  placeholder="Company Name"
                />
              </b-form-group>

              <b-form-group
                class="optimizer-form-group"
                label="Company Address"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="Company Address"
                  type="text"
                  :value="profile.companyAddress"
                  name="companyRegistrationNumber"
                  key="companyRegistrationNumber"
                  placeholder="123456789"
                />
              </b-form-group>

              <b-form-group
                class="optimizer-form-group"
                label="Tax ID"
              >
                <b-form-input
                  disabled
                  class="optimizer-form-input"
                  data-vv-as="Tax ID"
                  type="text"
                  :value="profile.taxId"
                  name="taxId"
                  key="taxId"
                  placeholder="123456789"
                />
              </b-form-group>
              <a class="change-details-btn" href="https://membership.theoptimizer.io" target="_blank">Update Business Details</a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  props: {
    profile: { type: Object },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
  .user-info-container {
    .user-info-block {
      &__title {
        font-size: 1.9rem;
        color: $text-primary;
        margin-bottom: 1rem;
      }

      .optimizer-card {
        padding: 2.5rem;

        .user-info-avatar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: .1rem solid #E5E5E5;
          padding-bottom: 2rem;
          margin-bottom: 2rem;

          &__image {
            img {
              width: 5rem;
              margin-right: 1rem;
            }

            .upload-new-btn {
              background: transparent;
              border: none;
              color: #68B2F7;
              font-size: 1.4rem;

              &:hover {
                color: #4097e9;
              }
            }
          }

          .remove-btn {
            background: #F7F9FB;
            border-radius: .6rem;
            border: none;
            font-size: 1.4rem;
            font-weight: 500;
            color: #FC7474;
            padding: .7rem 3.5rem;

            &:hover {
              background: #f7f7f7;
            }
          }
        }

        .change-details-btn {
          display: block;
          text-align: right;
          font-size: 1.5rem;
          color:  #59acfa;
          margin-left: 10px;

          &:hover {
            text-decoration: none;
            color: $color-blue;
          }
        }
      }
    }
  }
</style>
