import { api, apiStore } from '@sh/services/api';

const backendApi = {
  campaigns: {
    async all(typeName) {
      // console.log(typeName);
      // const allCampaignsMapped = await apiStore.campaigns.allMapped();
      // const filteredCampaigns = [];
      // typeName.forEach((type) => {
      //   if (allCampaignsMapped.typeName[type]) {
      //     allCampaignsMapped.typeName[type].forEach((campaign) => { filteredCampaigns.push(campaign); });
      //   }
      // });
      // return filteredCampaigns;
      return api.campaigns.all({ typeName });
    },
    getByFilters(payload) {
      return api.campaigns.getByFilters(payload);
    },
    getAllAdGroups(payload) {
      return api.campaigns.getAllAdGroups(payload);
    },
    getByIds(payload) {
      return api.entityFilter.getByIds(payload);
    },
  },
  rules: {
    async get(id) {
      const rules = await apiStore.rules.index();
      return rules.find(({ _id }) => _id === id);
    },
    groups() {
      return api.rules.groups();
    },
    tags() {
      return [];
    },
    update(id, rule) {
      return api.rules.update(id, rule);
    },
    create(rule) {
      return api.rules.create(rule);
    },
    createGlobalRule(rule) {
      return api.rules.createGlobalRule(rule);
    },
    templates(id) {
      return api.rules.templates({ id });
    },
  },
  notification: {
    getSettings() {
      return api.notification.getSettings();
    },
  },
};

export { backendApi as api };
