import { RuleTypes } from '@sh/types';
import { RuleHelpers, pluralize } from '@sh/helpers';

export const DATE_RANGE_REGEX = new RegExp(/(?<range>_range(?<fromUnit>day|month|week)(?<from>\d+)_(?<toUnit>day|month|week)(?<to>\d+))/);

export function hasIntervalUsageInChangeBid(rule) {
  const change_budget_configs = Object.keys(rule);
  return change_budget_configs.some(
    (e) => rule[e]?.toField?.interval?.length || rule[e]?.minObject?.toField?.interval?.length || rule[e]?.maxObject?.toField?.interval?.length,
  );
}

export function buildDateRangeKey(field, from, to) {
  return `${field}_range${from?.unit}${from?.value}_${to?.unit}${to?.value}`;
}

function hasQueryIntervalUsage(rule) {
  return rule.query.some((e) => Object.values(e).some((c) => {
    let hasInterval = false;
    if (c.conditionV2?.left?.type === RuleTypes.Conditions.BaseFieldType.Metric) {
      hasInterval = !!c.conditionV2?.left?.interval?.length;
    }
    if (c.conditionV2?.right?.type === RuleTypes.Conditions.BaseFieldType.Metric) {
      hasInterval = hasInterval || !!c.conditionV2?.right?.interval?.length;
    }
    return hasInterval;
  }));
}

export function isRuleUsingDateIntervals(rule) {
  if (!rule) return false;
  return hasQueryIntervalUsage(rule) || hasIntervalUsageInChangeBid(rule);
}

export function getDefaultInterval(rule) {
  if (!isRuleUsingDateIntervals(rule)) {
    return [];
  }
  const from = Number(rule.stats_interval);
  const to = Number(rule.stats_interval_exclude);

  if (Number.isNaN(from)) {
    throw new Error('Invalid interval');
  }

  switch (from) {
    case -1:
      return RuleHelpers.ConditionsV2.getDateOperations([-1, -1]);
    case -1.5:
      return RuleHelpers.ConditionsV2.getDateOperations([-1, 0]);
    case 30:
      return RuleHelpers.ConditionsV2.getDateOperations([0, 0], RuleTypes.ConditionsV2.DateOperationsUnit.Month);
    default:
      return [
        {
          type: RuleTypes.ConditionsV2.DateOperationsType.Remove,
          value: from,
          unit: RuleTypes.ConditionsV2.DateOperationsUnit.Day,
        },
        {
          type: RuleTypes.ConditionsV2.DateOperationsType.Remove,
          value: to || 0,
          unit: RuleTypes.ConditionsV2.DateOperationsUnit.Day,
        },
      ];
  }
}

export function getRelativeIntervalText(data) {
  switch (data.value) {
    case 0:
      return `This ${data.unit}`;
    case 1:
      return `Last ${data.unit}`;
    default:
      return [];
  }
}

export function getIntervalText(interval, isAbsolute = true) {
  if (!interval?.length) {
    return [];
  }

  const data = RuleHelpers.ConditionsV2.getDateOperationValues(interval);

  if (data.length) {
    if (isAbsolute) {
      return data.map((value) => `${Math.abs(value)}`).join('-');
    }
    return data.map((value) => value).join('-');
  }

  return getRelativeIntervalText(interval[0]);
}

export function getFieldText(field, interval, showField = true, ruleObject = {}) {
  const text = showField ? field.text ?? '' : '';

  if (text && interval?.length) {
    switch (field.baseType) {
      case RuleTypes.Conditions.BaseFieldType.Metric:
        return `${text} [${getIntervalText(interval)}] days`;
      case RuleTypes.Conditions.BaseFieldType.Date: {
        if (Object.values(ruleObject).length) {
          const data = Object.values(ruleObject?.query?.find((item) => Object.values(item)[0].to_self === field.key) ?? {})[0];

          if (field.key === 'specific_date') {
            return data?.conditionV2?.right?.value ?? '';
          }

          if (field.key === 'current_date') {
            return data?.conditionV2?.right?.operations?.[0]?.value ?? '';
          }

          const value = getIntervalText(interval, false);
          const unit = value > 0 ? '+' : '-';
          return `${text} ${unit} ${Math.abs(value)} ${pluralize(parseInt(value, 10), 'day')}`;
        }
        break;
      }
      default:
        return text;
    }
  }
  return text;
}

export function getInterval(ruleObject, value) {
  const defaultInterval = getDefaultInterval(ruleObject);
  const isRuleUsingIntervals = isRuleUsingDateIntervals(ruleObject);
  if (isRuleUsingIntervals) {
    return [
      value.conditionV2?.left?.interval?.length ? value.conditionV2?.left?.interval : defaultInterval,
      value.conditionV2?.right?.interval?.length ? value.conditionV2?.right?.interval ?? value.conditionV2?.right?.operations : defaultInterval,
    ];
  }

  return [
    value.conditionV2?.left?.interval ?? [],
    value.conditionV2?.right?.interval ?? value.conditionV2?.right?.operations ?? [],
  ];
}

export function getDateRangeKey(field, interval) {
  const key = field?.key || field?.value?.key;

  if (field?.baseType === RuleTypes.Conditions.BaseFieldType.Metric && interval?.length) {
    return buildDateRangeKey(key, interval[0], interval[1]);
  }

  return key;
}
