import { Field, FieldGroup, SupportedFieldFilter } from '@sh/configurations/fields/Field';
import { fieldsGroup, fieldsMap } from '@sh/configurations/fields/nativeFields';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';
import { GlobalField } from '@sh/configurations/fields/SystemField';

export type FunctionFieldFilter = (field: Field | FieldGroup) => boolean;

export class FieldFilter {
  private filters: SupportedFieldFilter;
  private fields: Field[];
  public constructor(fields: Field[], filters: SupportedFieldFilter = {}) {
    const {
      tracker,
      trafficSource,
      groups,
      useInCustomMetric,
      types,
      baseTypes,
      fieldsForToComparison,
      fieldsForParentCampaignCondition,
    } = filters;
    this.fields = fields;
    this.filters = {
      tracker,
      trafficSource,
      groups,
      useInCustomMetric,
      baseTypes,
      types,
      fieldsForToComparison,
      fieldsForParentCampaignCondition,
    };
  }

  public filterAll() {
    const { fields, groups } = this.filterFields();
    const fieldDescription = fields.map((f) => {
      const tr = f.sourceType === 'Tracker' ? f.trackers?.[0] : '';
      const ts = f.sourceType === 'TrafficSource' ? f.trafficSource?.[0] : '';
      return {
        ...f,
        ...getFieldTextAndDescription(f, false, tr || ts),
      };
    });
    return { fields: fieldDescription, groups };
  }

  private fieldsForParentCampaignConditionFilter(): FunctionFieldFilter {
    const { fieldsForParentCampaignCondition } = this.filters;
    return (field) => {
      if (fieldsForParentCampaignCondition === undefined || fieldsForParentCampaignCondition === null) return true;
      if (
        field.notAvailableAsParentCampaignCondition !== undefined &&
        field.notAvailableAsParentCampaignCondition !== null
      ) {
        return !field.notAvailableAsParentCampaignCondition;
      }
      const fieldGroup = fieldsGroup.find((group) => group.value === field.group);

      if (
        fieldGroup &&
        fieldGroup.notAvailableAsParentCampaignCondition !== undefined &&
        fieldGroup.notAvailableAsParentCampaignCondition !== null
      ) {
        return !fieldGroup.notAvailableAsParentCampaignCondition;
      }
      return true;
    };
  }

  private toComparisonFilter(): FunctionFieldFilter {
    const { fieldsForToComparison } = this.filters;
    return (field) => {
      if (!fieldsForToComparison) return true;
      if (field.notAvailableForToComparison !== undefined && field.notAvailableForToComparison !== null) {
        return !field.notAvailableForToComparison;
      }
      const fieldGroup = fieldsGroup.find((group) => group.value === field.group);

      if (
        fieldGroup &&
        fieldGroup.notAvailableForToComparison !== undefined &&
        fieldGroup.notAvailableForToComparison !== null
      ) {
        return !fieldGroup.notAvailableForToComparison;
      }
      return true;
    };
  }

  private trackerFilter(): FunctionFieldFilter {
    const { tracker } = this.filters;
    return (field) => {
      if (tracker === undefined || tracker === null) return true;
      if (tracker.length && field.trackers?.length) {
        return field.trackers?.some((tracker) => tracker.includes(tracker));
      }
      return true;
    };
  }

  private trafficSourceFilter(): FunctionFieldFilter {
    const { trafficSource } = this.filters;
    return (field) => {
      if (trafficSource === undefined || trafficSource === null) return true;
      if (trafficSource.length && field.trafficSource) {
        return field.trafficSource.some((trafficSource) => trafficSource.includes(trafficSource));
      }
      return true;
    };
  }

  private groupFilter(): FunctionFieldFilter {
    const { groups } = this.filters;
    return (field) => {
      if (groups === undefined || groups === null) return true;
      if (groups.length && field.group) {
        return groups.includes(field.group);
      }
      return true;
    };
  }

  private useInCustomMetricFilter(): FunctionFieldFilter {
    const { useInCustomMetric } = this.filters;
    return (field) => {
      if (useInCustomMetric === undefined || useInCustomMetric === null) return true;
      if (field.useInCustomMetric !== undefined && field.useInCustomMetric !== null) {
        return !!field.useInCustomMetric;
      }
      const fieldGroup = fieldsGroup.find((group) => group.value === field.group);

      if (fieldGroup && fieldGroup.useInCustomMetric) {
        return true;
      }
      return false;
    };
  }

  private fieldBaseTypeFilter(): FunctionFieldFilter {
    const { baseTypes: types } = this.filters;

    return (field) => {
      if (types === undefined || types === null) return true;
      if (field.baseType !== undefined && field.baseType !== null) {
        return types.includes(field.baseType);
      }
      const fieldGroup = fieldsGroup.find((group) => group.value === field.group);

      if (fieldGroup && fieldGroup.baseType) {
        return types.includes(fieldGroup.baseType);
      }
      return false;
    };
  }

  private fieldTypeFilter(): FunctionFieldFilter {
    const { types } = this.filters;

    return (field) => {
      if (types === undefined || types === null) return true;
      if (field.type) {
        return types.includes(field.type);
      }
      const fieldGroup = fieldsGroup.find((group) => group.value === field.group);

      if (fieldGroup?.type) {
        return types.includes(fieldGroup.type);
      }
      return false;
    };
  }

  private filterFields() {
    const { fields: availableFields } = this;
    const trackerFilter = this.trackerFilter();
    const trafficSourceFilter = this.trafficSourceFilter();
    const groupFilter = this.groupFilter();
    const customMetricFilter = this.useInCustomMetricFilter();
    const baseTypeFilter = this.fieldBaseTypeFilter();
    const typeFilter = this.fieldTypeFilter();
    const toComparisonFilter = this.toComparisonFilter();
    const parentCampaignFilter = this.fieldsForParentCampaignConditionFilter();
    const fields = availableFields
      .filter(
        (field) =>
          trackerFilter(field) &&
          trafficSourceFilter(field) &&
          groupFilter(field) &&
          customMetricFilter(field) &&
          baseTypeFilter(field) &&
          typeFilter(field) &&
          toComparisonFilter(field) &&
          parentCampaignFilter(field)
      )
      .sort((a, b) => {
        const aGroupPriority = fieldsGroup.find((group) => group.value === a.group)?.priority || 99;
        const bGroupPriority = fieldsGroup.find((group) => group.value === b.group)?.priority || 99;
        return bGroupPriority <= aGroupPriority ? 1 : -1;
      });
    const groups = fieldsGroup.filter(
      (field) => trackerFilter(field) && trafficSourceFilter(field) && groupFilter(field) && customMetricFilter(field)
    );
    return { fields, groups };
  }
}
function patchFieldDescription(systemFields: GlobalField<string>[] = []) {
  return Object.values(fieldsMap).map((f) => {
    const field = systemFields.find((sf) => sf.item.key === f.key);
    if (field) {
      f.description = field.description;
      f.label = field.label;
    }
    return f;
  });
}

export function getFieldsAndFilters(filters: SupportedFieldFilter = {}, systemFields: GlobalField[] = []) {
  const fieldsPatch = patchFieldDescription(systemFields);
  const fieldFilter = new FieldFilter(fieldsPatch, filters);
  return fieldFilter.filterAll();
}

export function getFieldsAndFiltersForRules(filters: SupportedFieldFilter = {}, systemFields: GlobalField[] = []) {
  const fieldsPatch = patchFieldDescription(systemFields);
  const fieldFilter = new FieldFilter(fieldsPatch, filters);
  const { fields, groups } = fieldFilter.filterAll();
  fields.forEach((field) => (field.value.key = field.key));
  return { fields, groups };
}
