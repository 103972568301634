import { RuleTypes } from '@sh/types';

const budgetActionOptions = [
  { value: 'set', text: 'Set Campaign Budget To' },
  { value: 'increase', text: 'Increase Campaign Budget By' },
  { value: 'decrease', text: 'Decrease Campaign Budget By' },
];

const adSetBudgetActionOptions = [
  { value: 'set', text: 'Set AdSet Budget To' },
  { value: 'increase', text: 'Increase AdSet Budget By' },
  { value: 'decrease', text: 'Decrease AdSet Budget By' },
  { value: 'spread', text: 'Spread Budget Equally between all Ad Sets', tooltip: 'Example: If your campaign has 4 ad sets and you set the budget to $100, the budget of each ad set will be set to $25.' },
];
const otherBudgetActionOptions = [
  { value: 'set', text: 'Set Budget To' },
  { value: 'increase', text: 'Increase Budget By' },
  { value: 'decrease', text: 'Decrease Budget By' },
];
const budgetUnitOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const budgetToOptions = [
  {
    value: 'budget',
    text: 'of Current Budget',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'ts_revenue',
    text: 'of Revenue',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'tr_revenue',
    text: 'of Revenue (Tracker)',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const budgetModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              budget: {},
              ts_revenue: {},
              tr_revenue: {},
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              budget: {},
              ts_revenue: {},
              tr_revenue: {},
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              budget: {},
              ts_revenue: {},
              tr_revenue: {},
            },
          },
        },
      },
      spread: {
        unit: {
          static: {},
        },
      },
    },
  },
};

export { adSetBudgetActionOptions, budgetActionOptions, budgetModelTemplates, budgetToOptions, budgetUnitOptions, otherBudgetActionOptions };

