import { api, apiStore } from '@sh/services/api';

export default {
  trafficSources: {
    types() {
      return apiStore.trafficSources.types();
    },
    account(id) {
      return api.trafficSources.account(id);
    },
    accounts() {
      return apiStore.trafficSources.accounts();
    },
    users() {
      return apiStore.trafficSources.users();
    },
  },
  campaigns: {
    all() {
      return api.campaigns.all();
    },
    getByFilters(payload) {
      return api.campaigns.getByFilters(payload);
    },
  },
  configuration: {
    reportTable(params) {
      return api.configuration.reportTable(params);
    },
  },
  reporting: {
    get(payload) {
      return api.reporting.getNativeReport(payload);
    },
    backgroundReporting() {
      return api.reporting.getNativeSavedReports();
    },
    reportOptions() {
      return apiStore.reporting.getNativeReportOptions();
    },
    saveOfflineReport(payload) {
      return api.reporting.saveOfflineReport(payload);
    },
    getUserComplianceValues(user) {
      return api.reporting.getUserComplianceValues(user.id, user.email);
    },
    reportTable(params) {
      return api.reporting.reportTable(params);
    },
  },
};
