import symbols from '@sh/helpers/symbols';
import ui from '@sh/helpers/ui';
import moment from 'moment';

export default function () {
  return {
    showModal: false,
    ui,
    symbols,
    selectedCampaigns: [],
    filter: {},
    moment,
    ready: {
      filter: false,
      campaigns: false,
      hasData: true,
      pagination: false,
    },
    table: {
      fields: [],
      items: [],
      statsTotals: {},
      advertisers: [],
      totals: {},
      statsInfo: {},
    },
    tableModel: {
      selectedRows: [],
      displayColumns: [],
      columnsOrder: [],
    },
    campaign: {},
    advertisersModel: 'all',
    limit: 100,
    page: 1,
    pages: 1,
    defaultRows: 100,
    search: '',
    sortField: 'cost',
    sortType: 'desc',
    columnFilter: {},
    canAccessComplianceProgram: false,
    bulkActionOptions: [
      { value: 'tags', content: '<span class="outline-fa-icon dark"><i class="fa fa-hashtag"></i></span>&nbsp; Add Tags' },
      { value: 'manage', content: '<span class="outline-fa-icon dark"><i class="fa fa-eye"></i></span>&nbsp; Manage' },
      { value: 'unmanage', content: '<span class="outline-fa-icon dark"><i class="fa fa-eye-slash"></i></span>&nbsp; Un-manage' },
      { value: 'archive', content: '<span class="outline-fa-icon dark"><i class="fa fa-lock"></i></span>&nbsp; Archive' },
      { value: 'unarchive', content: '<span class="outline-fa-icon dark"><i class="fa fa-unlock"></i></span>&nbsp; Un-Archive' },
      { value: 'activate', content: '<span class="outline-fa-icon dark"><i class="fa fa-play"></i></span>&nbsp; Activate' },
      { value: 'deactivate', content: '<span class="outline-fa-icon dark"><i class="fa fa-pause"></i></span>&nbsp; Deactivate' },
      { value: 'add_rule_to_campaign', content: '<span class="outline-fa-icon dark"><i class="fa fa-plus"></i></span>&nbsp; Add Rule to Campaigns' },
    ],
    // Bulk Actions specific to some Traffic Sources
    trafficSourceBulkActions: {
      change_lifetime_budget: { value: 'change_lifetime_budget', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Lifetime Budget', isBulk: false },
      change_daily_budget: { value: 'change_daily_budget', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Daily Budget', isBulk: false },
      change_target_cpa: { value: 'change_target_cpa', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Target CPA', isBulk: false },
      change_target_roas: { value: 'change_target_roas', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Target Roas', isBulk: false },
      change_bid_bulk: { value: 'change_bid_bulk', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Bid Bulk', isBulk: true },
      change_budget_bulk: { value: 'change_budget_bulk', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Budget Bulk', isBulk: true },
      change_daily_budget_bulk: { value: 'change_daily_budget_bulk', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Daily Budget Bulk', isBulk: true },
      change_target_cpa_bulk: { value: 'change_target_cpa_bulk', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Daily Budget Bulk', isBulk: true },
      change_bid_cap_bulk: { value: 'change_bid_cap_bulk', content: '<span class="outline-fa-icon dark"><i class="fa fa-dollar"></i></span>&nbsp; Change Bid Cap Bulk', isBulk: true },
    },
    'TikTok.bulkActionOptions': ['change_bid_bulk', 'change_daily_budget_bulk'],
    'Taboola.bulkActionOptions': ['change_bid_bulk', 'change_budget_bulk', 'change_daily_budget_bulk', 'change_target_cpa_bulk'],
    'Outbrain.bulkActionOptions': ['change_bid_bulk', 'change_budget_bulk', 'change_target_cpa_bulk', 'change_bid_cap_bulk'],
    'GoogleAds.bulkActionOptions': ['change_bid_bulk', 'change_budget_bulk'],
    'RevContent.bulkActionOptions': ['change_bid_bulk', 'change_budget_bulk'],
    'Mgid.bulkActionOptions': ['change_budget_bulk', 'change_daily_budget_bulk'],
    'Adskeeper.bulkActionOptions': ['change_budget_bulk', 'change_daily_budget_bulk'],
    'Facebook.bulkActionOptions': ['change_daily_budget_bulk'],
    'MediaGo.bulkActionOptions': ['change_budget_bulk', 'change_bid_bulk', 'change_daily_budget_bulk', 'change_target_cpa_bulk'],
    campaigns: [],
    statusFilter: [],
    statusOptions: [
      { value: { status: 'RUNNING' }, content: 'RUNNING', group: 'status' },
      { value: { status: 'PAUSED' }, content: 'PAUSED', group: 'status' },
      { value: { status: 'OUT OF FUNDS' }, content: 'OUT OF FUNDS', group: 'status' },
      { value: { status: 'ON MODERATION' }, content: 'ON MODERATION', group: 'status' },
      { value: { status: 'REJECTED' }, content: 'REJECTED', group: 'status' },
      { value: { status: 'OTHER' }, content: 'OTHER', group: 'status' },
      { value: { status: 'ARCHIVED' }, content: 'ARCHIVED', group: 'status' },
      { value: { status: 'UNMANAGED' }, content: 'UNMANAGED', group: 'status' },
    ],
    trackerAccountsMap: {},
    isRefreshLoading: false,
    intervalId: 0,
    trafficSourcesLinkedTrackers: [],
    revenueFrom: 'tr',
    trafficSourceAccounts: [],
  };
}
