export default [
  {
    name: 'Include All Desktop OS',
    code: true,
    reset: true,
  },
  {
    name: 'Exclude All Desktop OS',
    code: false,
    reset: true,
  },
  {
    name: 'Windows OS',
    code: 'windowsos',
  },
  {
    name: 'Windows 11',
    code: 'windowsos11',
  },
  {
    name: 'Windows 10',
    code: 'windowsos10',
  },
  {
    name: 'Windows 8.1',
    code: 'windowsos81',
  },
  {
    name: 'Windows 8',
    code: 'windowsos8',
  },
  {
    name: 'Windows 7',
    code: 'windowsos7',
  },
  {
    name: 'Windows Vista',
    code: 'windowsosvista',
  },
  {
    name: 'Windows XP',
    code: 'windowsosxp',
  },
  {
    name: 'Mac OS',
    code: 'macos',
  },
  {
    name: 'Mac OS 10.12 Sierra',
    code: 'macos1012',
  },
  {
    name: 'Mac OS 10.13 High Sierra',
    code: 'macos1013',
  },
  {
    name: 'Mac OS 10.14 Mojave',
    code: 'macos1014',
  },
  {
    name: 'Mac OS 10.15 Catalina',
    code: 'macos1015',
  },
  {
    name: 'Mac OS 11 Big Sur',
    code: 'macos11',
  },
  {
    name: 'Mac OS 12 Monterey',
    code: 'macos12',
  },
  {
    name: 'Other Desktop OS',
    code: 'otherdesctop',
  },
];
