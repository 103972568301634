<template>
  <div :class="`os-targeting-select-wrapper component-${model.name}`">
    <vue-opti-select-light
      class="optimizer-select  w-100"
      :options="model.options"
      unique-key="text"
      label-key="text"
      @change="$_addOption"
      v-validate="{required: selectedOption && ( selectedOption.length===0 && selectedOption===null)}"
      data-vv-value-path="$c_model"
      name="nestedSelect"
      data-vv-validate-on="input|change|update|blur"
      ref="nestedSelect"
      :data-vv-as="model.label"
      :state="$_hasError('nestedSelect')"
      :class="{ 'is-invalid': $_hasError('nestedSelect') === false }"
      searchable
      button-block
      single
    >
      <template #BUTTON_PLACEHOLDER>
        <span class="placeholder">{{ labelText }}</span>
      </template>
    </vue-opti-select-light>
    <div v-if="options && options.length" class="selected-targeting-wrapper">
      <div class="selected-target" v-for="(option, index) in options" :key="index">
        <span class="selected-target__label" :style="option.options && option.options.length? 'width:10rem':'width:100%'">{{ option.label }}</span>
        <div v-if="option.options && option.options.length" class="selected-target__select campaign-select-container">
          <v-select
            v-model="selectedOption"
            label="text"
            :options="option.options"
            :close-on-select="false"
            @input="$_addSubOption"
            class="optimizer-select  w-100"
            v-validate="{required: selectedOption && (selectedOption.length===0 || selectedOption===null)}"
            data-vv-value-path="$c_model"
            name="nestedSelect"
            data-vv-validate-on="input|change|update|blur"
            ref="nestedSelect"
            :data-vv-as="model.label"
          >
          </v-select>
        </div>
        <span class="remove-icon" @click="$_removeItem(option.label)"><i class="fa fa-times"></i></span>
      </div>
    </div>
    <small class="invalid-feedback">{{ $_getError('nestedSelect') }}</small>
  </div>
</template>

<script>
import component from '../mixins/component';

export default {
  name: 'CcTargetingCategories',
  mixins: [component],
  props: {
    value: { type: Array, default: () => [] },
    labelText: { type: String, required: true },
  },
  data() {
    return {
      selectedOption: '',
      options: [],
      parentOptions: [],
    };
  },
  created() {
    if (this.model.options.length) {
      this.parentOptions = this.model.options;
    } else if (this.model.getOptions && this.parentOptions.length === 0) {
      this.model.getOptions().then((res) => {
        if (res) {
          this.model.options = res;
          this.parentOptions = this.model.options;
        }
      }).catch((err) => {
        console.error(err);
      });
    }

    // Bind Model
    this.$watch('value', (value) => {
      if (this.mode === 'edit') {
        this.$_getSelectedOption(value);
      }
    }, { immediate: true });
  },
  methods: {
    $_addOption(option) {
      this.$_reset(option);
      this.$_emit(option);
    },
    $_addSubOption(option) {
      if (option && option.children) {
        this.selectedOption = '';
        this.$_reset(option);
      }
      this.$_emit(option);
    },
    $_emit(option) {
      if (option && !option.children) {
        this.$emit('input', [option.value]);
      } else if (option && option?.children.length === 0) {
        this.$emit('input', [option.value]);
      } else {
        this.$emit('input', []);
      }
    },
    $_reset(option) {
      this.selectedOption = '';
      this.options = [];
      this.options.push({
        label: option.text,
        value: option.value,
        options: option.children || [],
      });
    },
    $_getSelectedOption(value) {
      this.model.options.forEach((option) => {
        if (option.value === value[0]) {
          this.$_setSelectedOption(option);
        } else if (option.children) {
          option.children.forEach((opt) => {
            if (opt.value === value[0]) {
              this.$_setSelectedOption(option, opt);
            } else if (opt.children) {
              opt.children.forEach((o) => {
                if (o.value === value[0]) {
                  this.$_setSelectedOption(option, o);
                }
              });
            }
          });
        }
      });
    },
    $_setSelectedOption(option, subOption = null) {
      this.$_reset(option);
      subOption ? this.selectedOption = subOption.text : this.selectedOption = option.text;
    },
    $_removeItem(label) {
      this.options = this.options.filter((option) => option.label !== label);
      this.selectedOption = '';
      this.$emit('input', []);
    },
  },
};
</script>

<style lang="scss">
.os-targeting-select-wrapper {
  margin-top: 0;

  .placeholder {
    color: #a9a9a9;
  }

  .invalid-feedback {
    color: #dc3545;
    font-size: 10px;
  }

 .selected-targeting-wrapper {
    border: 0.1rem solid #e5e5e5;
    border-radius: 0.6rem;
    padding: 1rem;

    .selected-target {
      display: flex;
      align-items: center;
      margin-bottom: 0.7rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__label {
        font-size: 1.4rem;
        color: $text-primary;
        font-weight: 500;
        text-align: center;
        width: 10rem;
      }

      &__select {
        flex: 1;
        margin-right: 0.5rem;
      }

      .remove-icon {
        background: #7fa1f8;
        color: $color-white;
        padding: 0.1rem 0.3rem 0.2rem 0.4rem;
        line-height: 0.55;
        border-radius: 50%;
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }
  }
}
</style>
