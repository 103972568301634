
import { defineComponent } from 'vue';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: String,
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    zoomValue: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    onCheckboxClick(value: boolean) {
      this.$emit('update', value);
    },
  },
});
