
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { MediaManager } from '@sh/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { OptimizerIcon },
  props: {
    actionType: {
      type: [String, Number] as PropType<MediaManager.ActionType>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeIconClass: {
      type: String,
      required: false,
    },
    buttonTitle: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('onClick');
      }
    },
  },
});
