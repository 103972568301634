import session from '@sh/services/session';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const requestHandler = ({ request, setAuthToken = null }) => {
  /* ----------------- DEFAULT AUTH MODE --------------- */
  if (!setAuthToken) {
    setAuthToken = (request, token) => {
      request.defaults.headers.common.jwt = token;
    };
  }
  /* ----------------- ----------------- --------------- */
  request.interceptors.response.use((response) => response, (error) => {
    if (!window.location.href.includes('/login') && error.response && !error.response?.config?.url?.includes('ad-preview') && error.response.status === 401) {
      session.removeToken();
      window.location.href = '/#/login';
      return Promise.reject(new Error('Access Expired.'));
    }

    if (error.response && error.response.status === 402) {
      session.removeToken();
      window.location.href = process.env.VUE_APP_BASE_AMEMBER_URL;
      return Promise.reject(new Error('Access Expired.'));
    }
    return Promise.reject(error);
  });
  // set jwt headers
  if (session.isAuthenticated()) {
    setAuthToken(request, session.getToken());
  }
  session.afterAuthentication((_session) => {
    setAuthToken(request, _session.getToken());
  });
};

const retryHandler = (request) => {
  axiosRetry(request, {
    retries: 3,
    retryCondition: axiosRetry.isSafeRequestError,
  });
};

// create axios instances
let request = null;
if (process.env.VUE_APP_BASE_URL) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL, window.location.origin).href;
  request = axios.create({ baseURL, timeout: 60000 });
  retryHandler(request);
  requestHandler({ request });
}

let campaignCreatorRequest = null;
if (process.env.VUE_APP_BASE_URL_CAMPAIGN_CREATOR) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_CAMPAIGN_CREATOR, window.location.origin).href;
  campaignCreatorRequest = axios.create({ baseURL, timeout: 300000 });
  retryHandler(campaignCreatorRequest);
  requestHandler({ request: campaignCreatorRequest });
}

let notificationRequest = null;
if (process.env.VUE_APP_BASE_URL_NOTIFICATION) {
  const baseURL = new URL(`${process.env.VUE_APP_BASE_URL_NOTIFICATION}api/`, window.location.origin).href;
  notificationRequest = axios.create({ baseURL, timeout: 60000, headers: { Platform: process.env.VUE_APP_PLATFORM } });
  retryHandler(notificationRequest);
  requestHandler({ request: notificationRequest });
}

let requestV2 = null;
if (process.env.VUE_APP_BASE_URL_V2) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_V2, window.location.origin).href;
  requestV2 = axios.create({ baseURL, timeout: 60000, headers: { Platform: process.env.VUE_APP_PLATFORM } });
  retryHandler(requestV2);
  requestHandler({ request: requestV2 });
}

let requestDashboard = null;
if (process.env.VUE_APP_BASE_URL_DASHBOARD) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_DASHBOARD, window.location.origin).href;
  requestDashboard = axios.create({ baseURL, timeout: 60000 });
  retryHandler(requestDashboard);
  requestHandler({
    request: requestDashboard,
    setAuthToken(request, token) {
      request.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
  });
}

let requestPerformance = null;
if (process.env.VUE_APP_BASE_URL_PERFORMANCE) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_PERFORMANCE, window.location.origin).href;
  requestPerformance = axios.create({ baseURL, timeout: 60000 });
  retryHandler(requestPerformance);
  requestHandler({
    request: requestPerformance,
    setAuthToken(request, token) {
      request.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
  });
}


let requestUploader = null;
if (process.env.VUE_APP_UPLOADER_URL) {
  const baseURL = new URL(process.env.VUE_APP_UPLOADER_URL, window.location.origin).href;
  requestUploader = axios.create({
    baseURL,
    timeout: 60000,
    headers: { Platform: process.env.VUE_APP_PLATFORM, 'Content-Type': 'multipart/form-data' },
  });
  retryHandler(requestUploader);
  requestHandler({
    request: requestUploader,
    setAuthToken(request, token) {
      request.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
  });
}

let requestImporter = null;
if (process.env.VUE_APP_BASE_URL_IMPORTER) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_IMPORTER, window.location.origin).href;
  requestImporter = axios.create({ baseURL, timeout: 60000 });
  retryHandler(requestImporter);
  requestHandler({
    request: requestImporter,
  });
}

let requestRawConversionUploader = null;
if (process.env.VUE_APP_BASE_URL_RAW_CONVERSION_UPLOADER) {
  const baseURL = new URL(process.env.VUE_APP_BASE_URL_RAW_CONVERSION_UPLOADER, window.location.origin).href;
  requestRawConversionUploader = axios.create({ baseURL, timeout: 60000 });
  retryHandler(requestRawConversionUploader);
  requestHandler({
    request: requestRawConversionUploader,
  });
}

let requestFacebook = null;
if (process.env.VUE_APP_FACEBOOK_BASE_URL) {
  const baseURL = new URL(process.env.VUE_APP_FACEBOOK_BASE_URL, window.location.origin).href;
  requestFacebook = axios.create({ baseURL, timeout: 60000 });
  retryHandler(requestFacebook);
  requestHandler({ request: requestFacebook });
}

export {
  campaignCreatorRequest,
  notificationRequest,
  request,
  requestDashboard,
  requestImporter,
  requestPerformance,
  requestRawConversionUploader,
  requestUploader,
  requestV2,
  requestFacebook,
};
