<template>
  <transition name="fade">
    <div v-if="loading" class="optimizer-loadizer">
      <div class="optimizer-loadizer-content">
        <svg
          class="animation"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
          style="enable-background:new 0 0 35 35;"
          xml:space="preserve"
        >
          <g>
            <polygon
              class="st0"
              points="293.8,2.3 203.8,2.3 159.3,79.6 203.8,158 293.8,158 339.4,79.6"
            />
          </g>
          <g>
            <polygon
              class="st0"
              points="145.5,87 55.5,87 11,164.4 55.5,242.8 145.5,242.8 191.1,164.4"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M424.1,118.8l26.5,46.6L424.1,211h-53l-26.5-46.6l26.5-46.6h53 M442.1,87h-90l-44.5,77.3l45.6,78.4h90
          l45.6-78.4L442.1,87L442.1,87z"
            />
          </g>
          <g>
            <polygon
              class="st0"
              points="145.5,256.5 55.5,256.5 11,333.9 55.5,412.3 145.5,412.3 191.1,333.9"
            />
          </g>
          <g>
            <polygon
              class="st0"
              points="442.1,256.5 352.1,256.5 307.6,333.9 352.1,412.3 442.1,412.3 487.7,333.9"
            />
          </g>
          <g>
            <polygon
              class="st0"
              points="293.8,341.3 203.8,341.3 159.3,418.6 203.8,497 293.8,497 339.4,418.6"
            />
          </g>
        </svg>
        <h3 class="message">
          <template v-if="message">{{ message }}</template>
          <template v-else>
            TheOptimizer <p>{{ $platform }}</p>
          </template>
        </h3>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'Loadizer',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.removeProperty('overflow');
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.optimizer-loadizer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .5);
  z-index: 9999;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity .25s;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  &-content {
    text-align: center;

    .st0{ fill:#ffffff; }
    .st1{ fill:#24C2F3; }

    .animation {
      animation: tick-tock 1s steps(6, end) infinite;
      width: 35px;
      height: 35px;
      margin: 0 auto;
    }

    .message {
      margin-top: 15px;
      font-size: 17px;
      color: rgba(#fff, 0.8);

      p {
        display: inline-block;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  @keyframes tick-tock {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
