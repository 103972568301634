
import { defineComponent, PropType } from 'vue';

export type Option = {
  disabled?: boolean;
  id: string;
} & SelectOption<any, 'value', 'text'>;

export default defineComponent({
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    id: String,
    selected: Array as PropType<string[]>,
    options: Array as PropType<Option[]>,
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedValue: [] as string[],
    };
  },

  created() {
    this.selectedValue = this.value;
  },
  methods: {
    onSelectedChange(id: string, event: boolean | string) {
      if (!event) {
        this.selectedValue = this.selectedValue.filter((item) => item !== id);
      } else {
        this.selectedValue.push(id);
      }
      this.$emit('input', this.selectedValue);
    },
  },
});
