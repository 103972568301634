import { RuleTypes } from '@sh/types';

const coefficientActionOptions = [
  { value: 'set', text: 'Set Coefficient To' },
  { value: 'increase', text: 'Increase Coefficient By' },
  { value: 'decrease', text: 'Decrease Coefficient By' },
];

const coefficientUnitOptions = [
  { value: 'static', text: 'Fixed amount' },
  { value: 'percentage', text: '%' },
];

const coefficientToOptions = [
  {
    value: 'coefficient',
    text: 'of Current Coefficient',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const coefficientModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              coefficient: {},
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              coefficient: {},
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              coefficient: {},
            },
          },
        },
      },
    },
  },
};

export { coefficientActionOptions, coefficientUnitOptions, coefficientToOptions, coefficientModelTemplates };
