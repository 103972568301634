var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cc-facebook-preview"},[_c(_setup.CcPreviewTabs,{attrs:{"tabs":_setup.tabs,"active-tab":_setup.activeTab},on:{"update:active-tab":_setup.onActiveTabChange}}),(_setup.activeTab === _setup.PreviewTab.MY_SPACE)?[_c(_setup.CcCardItem,{staticClass:"cc-facebook-preview__widgets",attrs:{"layer":_setup.CardLayer.LAYER_0,"title":"General Overview"}},[_c('div',{staticClass:"cc-facebook-preview__widgets__items"},_vm._l((_setup.campaignMetrics.meta),function(item,index){return _c(_setup.CcCardVariable,{key:index,attrs:{"value":_setup.campaignMetrics.metrics[item.key].toString() + ' ' + item.suffix,"name":item.name}})}),1)]),_c(_setup.CcCardItem,{staticClass:"cc-facebook-preview__bottom",attrs:{"layer":_setup.CardLayer.LAYER_0}},[_c(_setup.CcPreviewTabs,{attrs:{"variant":"outline","tabs":_setup.mySpaceTabs,"active-tab":_setup.activeMySpaceTabs},on:{"update:active-tab":_setup.onMySpaceTabChange}}),(_setup.activeMySpaceTabs === _setup.PreviewTab.TEMPLATES)?_c(_setup.CcCardItem,{staticClass:"cc-facebook-preview__bottom__items",attrs:{"layer":_setup.CardLayer.LAYER_0}},[(!_setup.templates.length)?_c(_setup.CcPreviewPlaceholder,{attrs:{"loading":_setup.isTemplatesLoading,"icon":"speaker","text":{
            loadingText: 'Loading Templates...',
            noResultHeadline: 'No Templates Found',
            noResultText: `Create your first campaign and save it as template`,
          }}}):_vm._e(),_vm._l((_setup.templates),function(suggestion,index){return _c(_setup.CcWidgetCard,{key:index,attrs:{"title":suggestion.name,"items":suggestion.labels,"id":suggestion.id,"actions":[
            {
              id: index === 0 ? 'APPLY_TEMPLATE' : '',
              label: 'Apply Template',
              icon: 'checkCircle',
              event: 'applySettings',
            },
            {
              label: 'Remove',
              icon: 'xCircle',
              event: 'removeSettings',
            },
          ]},on:{"actionClick":function($event){return _setup.templatesActionClick($event, suggestion.id)}}})}),(_setup.selectedTemplateId)?_c(_setup.CcConfirmModal,{attrs:{"variant":"info","isModalOpen":_setup.isApplyTemplatesModalOpened,"title":"Apply Template","description":"Are you sure you want to apply this template? This action will remove all your current settings and cannot be undone.","confirm":{ label: 'Apply' },"cancel":{ label: 'Cancel' }},on:{"onModalClose":function($event){_setup.isApplyTemplatesModalOpened = false},"onConfirmClick":function($event){return _setup.applyTemplate(_setup.selectedTemplateId)}}}):_vm._e(),(_setup.selectedTemplateId)?_c(_setup.CcConfirmModal,{attrs:{"variant":"danger","isModalOpen":_setup.isDeleteTemplatesModalOpened,"title":"Confirm Template Deletion","description":"Are you sure you want to delete this template? This action is irreversible and will permanently remove the template. Please confirm your decision.","confirm":{ label: 'Delete' },"cancel":{ label: 'Cancel' }},on:{"onModalClose":function($event){_setup.isDeleteTemplatesModalOpened = false},"onConfirmClick":function($event){return _setup.removeTemplate(_setup.selectedTemplateId)}}}):_vm._e()],2):_vm._e(),(_setup.activeMySpaceTabs === _setup.PreviewTab.SUGGESTIONS && _setup.navigationGetters.currentLevel.value === _setup.Level.AdSet)?_c(_setup.CcCardItem,{staticClass:"cc-facebook-preview__bottom__items",attrs:{"layer":_setup.CardLayer.LAYER_0}},[(!_setup.adSetSuggestions.length)?_c(_setup.CcPreviewPlaceholder,{attrs:{"loading":_setup.isSuggestionsAdSetLoading,"icon":"widgets","text":{
            loadingText: 'Loading Ad Set Suggestions...',
            noResultText: 'No Ad Set Suggestions found',
          }}}):_vm._e(),_vm._l((_setup.adSetSuggestions),function(suggestion,index){return _c(_setup.CcWidgetCard,{key:index,attrs:{"title":suggestion.name,"items":suggestion.variables,"id":suggestion.id,"actions":[
            {
              label: 'Apply',
              icon: 'checkCircle',
              event: 'applySettings',
            },
          ]},on:{"actionClick":function($event){return _setup.adSetSuggestionActionClick($event, suggestion.id)}}})}),(_setup.selectedSuggestedAdSetId)?_c(_setup.CcConfirmModal,{attrs:{"variant":"info","isModalOpen":_setup.isAdSetSuggestionsModalOpened,"title":"Confirm Ad Set Changes","description":"Are you sure you want to apply this suggestion to your ad sets? This action will modify your current settings and cannot be undone.","confirm":{ label: 'Apply' },"cancel":{ label: 'Cancel' }},on:{"onModalClose":function($event){_setup.isAdSetSuggestionsModalOpened = false},"onConfirmClick":function($event){return _setup.applySuggestionAdSet(_setup.selectedSuggestedAdSetId)}}}):_vm._e()],2):_vm._e(),(_setup.activeMySpaceTabs === _setup.PreviewTab.SUGGESTIONS && _setup.navigationGetters.currentLevel.value === _setup.Level.Ad)?_c(_setup.CcCardItem,{staticClass:"cc-facebook-preview__bottom__items",attrs:{"layer":_setup.CardLayer.LAYER_0}},[(!_setup.adSuggestions.length)?_c(_setup.CcPreviewPlaceholder,{attrs:{"loading":_setup.isSuggestionsAdLoading,"icon":"widgets","text":{
            loadingText: 'Loading Ad Suggestions...',
            noResultText: 'No Ad Suggestions found',
          }}}):_vm._e(),_vm._l((_setup.adSuggestions),function(chunk,chunkIndex){return _c('div',{key:chunkIndex,staticClass:"preview-ad-card-container"},_vm._l((chunk),function(ad,index){return _c(_setup.CcPreviewAdCard,{key:`${chunkIndex}-${index}`,attrs:{"call_to_action_type":ad.call_to_action_type,"body":ad.body,"displayLink":ad.displayLink,"headline":ad.headline,"image_url":ad.image_url,"title":ad.title,"metrics":ad.metrics},on:{"apply":() => {
                _setup.selectedSuggestedAdId = ad.id;
                _setup.isAdSuggestionsModalOpened = true;
              }}})}),1)})],2):_vm._e()],1),(_setup.selectedSuggestedAdId)?_c(_setup.CcConfirmModal,{attrs:{"variant":"info","isModalOpen":_setup.isAdSuggestionsModalOpened,"title":"Add A/B Test for Creative","description":"Are you sure you want to add an A/B test for this creative? This will add new A/B Groups in your Ad","confirm":{ label: 'Apply' },"cancel":{ label: 'Cancel' }},on:{"onModalClose":function($event){_setup.isAdSuggestionsModalOpened = false},"onConfirmClick":function($event){return _setup.applySuggestionAd(_setup.selectedSuggestedAdId)}}}):_vm._e()]:_vm._e(),(_setup.activeTab === _setup.PreviewTab.AD_SET_PREVIEW)?_c('div',{staticClass:"cc-facebook-preview__cards"},_vm._l((_setup.abGenerated),function(card){return _c(_setup.CcPreviewCard,{key:card.id,attrs:{"id":card.id,"active":card.active,"entity":card.entity,"title":card.title,"variables":card.variables,"movingVariables":card.movingVariables,"statusDisabled":_setup.disableStatusChange && card.active},on:{"changeStatus":_setup.changeStatus}})}),1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }