
import config from '@/views/Campaigns/bulkOperationsConfig';
import Loading from '@sh/components/Utils/Loading.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import RuleMetricCalculation from '@sh/components/Utils/RuleMetricCalculation.ts.vue';
import { budgetConfigValid } from '@sh/configurations/fields/helpers';
import notifications from '@sh/mixins/notifications';
import { TrafficSource } from '@sh/types';
import Vue, { VueConstructor } from 'vue';

const scope = 'clone';
interface Budget {
  action: string;
  unit: string;
  value: number;
  min: number;
  max: number;
  to: string;
}

interface ComponentData {
  modal: boolean;
  isLoading: boolean;
  name: string;
  nrOfCopies: number;
  nameFormat: string;
  nameTitle: string;
  copiesTitle: string;
  customBudgetTitle: string;
  trafficSourcesThatSupportCustomBudget: string[];
  alert: {
    message: string;
    type: string;
  };
  enableFields: boolean;
  customBudget: null | number;
  enableMinMaxOptionForManualClone: boolean;
  showSpreadBudget: boolean;
}
interface ComponentRefs {
  $refs: {
    ruleMetricCalculation?: {
      $c_form: any;
      $_getCalculatedMetric: (
        item: any,
        hardCodedTo?: string
      ) => {
        calculatedMetric: number | null;
        current: any;
        calculatedMinVal: any;
        isNullCurrentField: boolean;
        columnName: string;
        $c_form: Record<string, any>;
      };
    };
  };
}
const VueComponent = Vue as VueConstructor<Vue & ComponentRefs & NotificationMixin>;

export default VueComponent.extend({
  components: {
    OptimizerIcon,
    Loading,
    RuleMetricCalculation,
  },
  mixins: [notifications, config],
  props: {
    campaign: { type: Object },
    options: { type: Array, default: () => [] },
  },
  data(): ComponentData {
    return {
      modal: false,
      isLoading: false,
      name: '',
      nrOfCopies: 1,
      nameFormat: '{Prefix} {Name} {Suffix}',
      nameTitle: `
        <div>The name can be edited and customized. TheOptimizer will automatically add 1 "-Copy" suffix to the name for each cloned copy.</div>
        <div>Example:</div>
        <div>1 copy: "Campaign name - Copy"</div>
        <div>2 copies: "Campaign name - Copy - Copy" etc.</div>
      `,
      copiesTitle:
        'Specifies the number of times this campaign will be cloned. A value of 3 for example, means that the campaign will be cloned 3 times. ',
      customBudgetTitle: '',
      trafficSourcesThatSupportCustomBudget: ['TikTok', 'Facebook'],
      alert: {
        message: '',
        type: '',
      },
      enableFields: false,
      customBudget: null,
      enableMinMaxOptionForManualClone: false,
      showSpreadBudget: false,
    };
  },
  computed: {
    $c_trafficSourceUniqueName(): string {
      return this.campaign.traffic_source_unique_name;
    },
    $c_entity(): string {
      return this.campaign.traffic_source_unique_name === TrafficSource.Facebook ? 'AdSet' : 'AdGroup';
    },
    $c_budgetEntity(): string {
      if (this.$c_showCustomBudgetForSpecificCampaigns) {
        return 'Campaign';
      }
      if (this.$c_entity === 'AdSet') {
        return 'AdSet';
      }
      return 'AdGroup';
    },
    $c_bulkConfig(): any {
      return (this as any).trafficSources[this.campaign.traffic_source_unique_name];
    },
    $c_showRuleModal(): boolean {
      return this.trafficSourcesThatSupportCustomBudget.includes(this.$c_trafficSourceUniqueName);
    },
    $c_showCustomBudgetForSpecificCampaigns(): boolean {
      if (
        !this.campaign.budget &&
        !this.campaign.additional_fields.budget &&
        !this.campaign.additional_fields.daily_budget &&
        !this.campaign.daily_budget
      ) {
        return false;
      }
      return true;
    },
    $c_budgetLevel() {
      if (this.$c_budgetEntity === 'campaign') {
        if (this.$c_entity === 'AdSet') {
          return 'AdSet';
        }
        if (this.$c_entity === 'AdGroup') {
          return 'AdGroup';
        }
      }
      return 'campaign';
    },
    $c_customBudgetTitle(): string {
      return `This budget adjustment will only be applied to ${this.$c_budgetEntity} level budgets. Campaigns managing budgets on ${this.$c_budgetLevel} level will not be affected.`;
    },
    $c_campaignCurrentBudget(): number {
      return this.campaign.additional_fields.budget || this.campaign.additional_fields.daily_budget;
    },
    $c_budgetType(): string {
      if (this.campaign.additional_fields.budget) {
        return 'Total';
      }
      return 'Daily';
    },
  },
  watch: {
    campaign: {
      handler(value) {
        const hasBudget =
          value?.budget ||
          value?.additional_fields?.budget ||
          value?.daily_budget ||
          value?.additional_fields?.daily_budget;
        this.showSpreadBudget = !hasBudget;
        this.enableMinMaxOptionForManualClone = !hasBudget && this.$c_showRuleModal;
      },
      immediate: true,
    },
    $c_showRuleModal(value) {
      this.enableMinMaxOptionForManualClone = value && !this.$c_showCustomBudgetForSpecificCampaigns;
    },
    'campaign.name': {
      handler(item) {
        this.name = item;
      },
    },
    customBudget: {
      handler(item) {
        this.customBudget = item;
      },
    },
  },
  created() {
    this.$validator.extend('nameRule', {
      getMessage: (field: any, params: any, data: any) => data?.message || 'Something went wrong',
      validate: (value: string) => {
        let valid = true;
        const data = { message: '' };
        const campaignName = this.campaign.name;

        if (!value.includes(campaignName)) {
          valid = false;
          data.message = 'The original name can not be changed. You can only add a prefix or suffix.';
        }

        return Promise.resolve({
          valid,
          data,
        });
      },
    });
  },
  methods: {
    formatTooltipContent(content: string) {
      return content.replace(/\n/g, '<br>');
    },
    showModal() {
      this.modal = true;
    },
    hideModal() {
      this.clear();
      this.modal = false;
    },
    validateBudget(budget: number | null | Budget) {
      if (typeof budget === 'object' && budget?.min !== undefined && budget?.max !== undefined) {
        const { min, max, value } = budget;
        if (this.customBudget === 0) {
          this.$_showAlert('danger', 'Please check your input for new calculated budget.');
          this.isLoading = false;
          return false;
        }
        if (budgetConfigValid(min, max, value)) {
          this.$_showAlert(
            'danger',
            'Please check the value for the budgets. Min budget cannot be greater or equal than max budget.'
          );
          this.isLoading = false;
          return false;
        }
      }
      return true;
    },
    async submit() {
      if (await this.validateForm()) {
        this.$_dismissAlert();
        const isValid = this.validateRuleMinMax();
        if (!isValid) return;
        this.isLoading = true;
        this.nrOfCopies = parseInt(`${this.nrOfCopies}`, 10);
        try {
          const calculatedBudget = this.enableFields ? this.customBudget : null;
          let budget: Budget | null | number = calculatedBudget;
          let budgetOnAdset: Budget | null | number = calculatedBudget;

          if (
            this.$c_trafficSourceUniqueName === TrafficSource.Facebook ||
            this.$c_trafficSourceUniqueName === TrafficSource.TikTok
          ) {
            const form = this.$refs.ruleMetricCalculation?.$c_form;
            let budgetForm: Budget | null = !form
              ? null
              : {
                  action: form.action,
                  unit: form.unit,
                  value: Number(form.value),
                  min: Number(form.min),
                  max: Number(form.max),
                  to: form.to,
                };
            if (calculatedBudget) {
              // custom budget is precalculated in frontend
              budgetForm = {
                action: 'set',
                unit: 'static',
                value: calculatedBudget,
                min: calculatedBudget,
                max: calculatedBudget,
                to: '',
              };
            }
            budget = this.showSpreadBudget ? null : budgetForm;
            budgetOnAdset = this.showSpreadBudget ? budgetForm : null;
          }
          const startIndex = this.name.indexOf(this.campaign.name);
          const endIndex = startIndex + this.campaign.name.length;
          const prefix = this.name.substring(0, startIndex);
          const suffix = this.name.substring(endIndex);

          if (!this.validateBudget(budget) || !this.validateBudget(budgetOnAdset)) {
            return;
          }

          const { successMessage, errorMessage } = await this.$api.campaigns.cloneCampaign(
            this.campaign.id,
            true,
            this.name,
            prefix,
            suffix,
            this.nrOfCopies,
            budget,
            budgetOnAdset
          );

          if (successMessage) {
            await this.$swal({
              text: successMessage,
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                cancelButton: 'hide-cancel-btn',
                title: 'swal-title',
              },
            });
          } else if (errorMessage) {
            await this.$swal({
              text: errorMessage,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK',
              customClass: {
                container: 'confirm-delete-swal larger box',
                confirmButton: 'primary-button primary-button--danger',
                cancelButton: 'hide-cancel-btn',
              },
            });
          }
        } catch (error: any) {
          this.customBudget = null;
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } finally {
          this.hideModal();
          this.customBudget = null;
          this.isLoading = false;
        }
      }
    },
    getError(name: string) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
    hasError(name: string) {
      return this.$validator.errors.has(`${scope}.${name}`) ? false : null;
    },
    getScope() {
      return scope;
    },
    getCustomBudget() {
      this.customBudget = null;
      if (
        this.trafficSourcesThatSupportCustomBudget.includes(this.campaign.traffic_source_unique_name) &&
        this.$c_showCustomBudgetForSpecificCampaigns
      ) {
        const to = this.campaign.additional_fields.budget ? 'budget' : 'daily_budget';
        const results = this.$refs.ruleMetricCalculation?.$_getCalculatedMetric(this.campaign, to);
        if (results) {
          const { calculatedMetric } = results;
          this.customBudget = parseFloat(calculatedMetric?.toFixed(2) || '0');
        }
      }
    },
    clear() {
      this.name = this.campaign.name;
      this.nrOfCopies = 1;
      this.enableFields = false;
      this.customBudget = null;
      this.$_dismissAlert();
    },
    async validateForm() {
      try {
        const validation = await this.$validator.validateAll(this.getScope());
        return validation;
      } catch (error) {
        return false;
      }
    },
    validateRuleMinMax() {
      if (!this.$c_showRuleModal) return true;
      const { valid, where } = (this.$refs as any)?.ruleMetricCalculation?.$_isValid() ?? {};
      if (!valid && this.enableFields) {
        this.$_showAlert('danger', `Please fill ${where}`);
        return false;
      }
      return true;
    },
    $_showAlert(type: string, message: string) {
      this.alert.type = type;
      this.alert.message = message;
    },
    $_dismissAlert() {
      this.alert.message = '';
    },
    $_switchToggle(value: boolean) {
      this.enableFields = value;
    },
  },
});
