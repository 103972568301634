import Fields from '@sh/lib/Fields.class.js';
import adgroups from './adgroups';
import campaigns from './campaigns';
import contents from './contents';
import defaults from './defaults';
import domains from './domains';
import exchanges from './exchanges';
import report from './report';
import reportClient from './reportClient';
import reportAccount from './reportAccount';
import reportCampaign from './reportCampaign';
import reportAdgroups from './reportAdgroups';
import reportContents from './reportContents';
import reportExchanges from './reportExchanges';
import reportSections from './reportSections';
import reportTargets from './reportTargets';
import reportWidgets from './reportWidgets';
import sections from './sections';
import sites from './sites';
import spots from './spots';
import targets from './targets';
import widgets from './widgets';

const F = new Fields();

const fields = {
  // Performance
  CAMPAIGNS: campaigns(F),
  WIDGETS: widgets(F),
  CONTENTS: contents(F),
  TARGETS: targets(F),
  SECTIONS: sections(F),
  SITES: sites(F),
  SPOTS: spots(F),
  EXCHANGES: exchanges(F),
  ADGROUPS: adgroups(F),
  DOMAINS: domains(F),
  // Reporting
  REPORTUNIQUENAME: report(F),
  REPORTACCOUNT: reportAccount(F),
  REPORTCAMPAIGN: reportCampaign(F),
  REPORTWIDGETS: reportWidgets(F),
  REPORTCONTENTS: reportContents(F),
  REPORTTARGETS: reportTargets(F),
  REPORTADGROUPS: reportAdgroups(F),
  REPORTSECTIONS: reportSections(F),
  REPORTEXCHANGES: reportExchanges(F),
  REPORTUNIQUENAMECOMPLIANCE: report(F),
  REPORTCONTENTCOMPLIANCE: reportContents(F),
  REPORTCLIENTCOMPLIANCE: reportClient(F),
  // Defaults
  DEFAULTS: defaults(F),
};

F.set(fields);

export default {
  /**
   *
   * @param {string} level - Level represents CAMPAIGNS, WIDGETS, CONTENTS, etc..
   * @param {Object} key - Field identifier, if not found customised field, the default field will be used
   * @param {String} key.name - Field name Like `name`, `additional_fields.budget`, `name::<Traffic-Source-Type>`, etc..
   * @param {String} key.uniqueName - Used when have customised by Traffic Source Type
   * @param {Object} _data - Merge data
   */
  getField(level, key, _data) {
    return F.getField({ level, key: key.name, uniqueName: key.uniqueName }, _data);
  },
};
