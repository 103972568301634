import RevContentCampaignModel from './RevContentCampaignModel';
import ExportModel from '../../lib/ExportModel';

export default {
  async getConfig(account) {
    // Create Campaign Model
    const campaignModel = new RevContentCampaignModel(account);
    return ExportModel(campaignModel);
  },
};
