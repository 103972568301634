import { ColumnCondition, DateRange, ReportingRequest, ReportTab, ReportType } from '@/views/Tools/Reporting/types';
import { RuleHelpers } from '@sh/helpers';
import { VueOptiTable } from '@sh/types';
import Joi from 'joi';
import md5 from 'md5';
import moment from 'moment';

export const AdvancedReport = md5('AdvancedReport');
export const BackgroundReport = md5('BackgroundReport');
export type CachedReportKey = ReportType | typeof AdvancedReport | typeof BackgroundReport;

export const ReportTabs: ReportTab[] = [
  {
    type: AdvancedReport,
    name: 'Custom Reports',
  },
  {
    type: BackgroundReport,
    name: 'Queued Reports',
  },
];

export const dateTypeOptions: SelectOption<string>[] = [
  {
    content: 'by Day',
    value: 'day',
  },
  {
    content: 'by Month',
    value: 'month',
  },
  {
    content: 'by Year',
    value: 'year',
  },
];

export const csvTableFields: VueOptiTable.Field[] = [
  {
    header: { content: 'Date' },
    display: true,
    item: {
      key: 'date',
      sortable: true,
      content: (item: { date: string }) => item.date.replace(/T/, ' ').replace(/\..+/, ''),
      style: { textAlign: 'center', position: 'relative' },
    },
  },
  {
    header: { content: 'URL' },
    display: true,
    item: {
      key: 'url',
      slot: 'url',
      sortable: true,
      content: (item: { url: string }) => item.url,
      style: { textAlign: 'center', position: 'relative' },
    },
  },
  {
    header: { content: 'Status' },
    display: true,
    item: {
      key: 'status',
      slot: 'status',
      sortable: true,
      content: (item: { status: string }) => item.status,
      style: { textAlign: 'center', position: 'relative' },
    },
  },
];

export const columnFilterDto = (data: VueOptiTable.Pagination) => {
  const columnFilter: ColumnCondition[] = [];

  for (const [field, value] of Object.entries(data.columnFilter)) {
    for (const conditionData of value ?? []) {
      const conditionKey = conditionData?.condition ?? '';

      if (conditionKey && RuleHelpers.Conditions.conditionsMap[conditionKey]) {
        columnFilter.push({
          field,
          operator: RuleHelpers.Conditions.conditionsMap[conditionKey].text,
          value: conditionData.value,
        });
      }
    }
  }
  return columnFilter;
};

export const changeDateRangeVisibility = (fields: VueOptiTable.Field[], isDateRangeField: boolean) => {
  const newFields = fields.filter((field) => field.item.key !== 'date_range');
  const dateRangeField = {
    item: {
      key: 'date_range',
      sortable: true,
    },
    header: {
      content: 'Date',
    },
    options: {
      format: 'string',
      precision: 3,
      style: '',
    },
  };

  if (isDateRangeField) {
    newFields.unshift(dateRangeField);
    return newFields;
  }
  return newFields;
};

const VALIDATION_SUPPORT_MESSAGE = {
  'string.empty': 'An error occurred, please contact support!',
  'string.base': 'An error occurred, please contact support!',
};

const getRequiredFieldMessage = (name: string) => ({
  'any.required': `Please select "${name}" to generate Report.`,
  'string.empty': `Please select "${name}" to generate Report.`,
  'string.base': `Please select "${name}" to generate Report.`,
});

export const getSchema = (isSearchFeed = false) => {
  if (isSearchFeed) {
    return Joi.object({
      startDate: Joi.string().required().messages(VALIDATION_SUPPORT_MESSAGE),
      endDate: Joi.string().required().messages(VALIDATION_SUPPORT_MESSAGE),
      reportType: Joi.string().required().messages(getRequiredFieldMessage('Report Type')),
      pagination: Joi.object({
        pageSize: Joi.number(),
        page: Joi.number(),
      }).optional(),
      conditions: Joi.array().items(Joi.object()).optional(),
      search: Joi.string().optional(),
      sort: Joi.string().optional(),
      selectedUserId: Joi.when('reportType', {
        is: ReportType.ClientCompliance,
        then: Joi.forbidden(),
        otherwise: Joi.string().required(),
      }),
      clientEmails: Joi.when('reportType', {
        is: ReportType.ClientCompliance,
        then: Joi.forbidden(),
        otherwise: Joi.array().items(Joi.string()).min(1).required(),
      }),
      trafficSourceTypes: Joi.when('reportType', {
        is: ReportType.ClientCompliance,
        then: Joi.forbidden(),
        otherwise: Joi.array().items(Joi.string()).min(1).required(),
      }),
    });
  }

  return Joi.object({
    startDate: Joi.string().required().messages(VALIDATION_SUPPORT_MESSAGE),
    endDate: Joi.string().required().messages(VALIDATION_SUPPORT_MESSAGE),
    reportType: Joi.string().required().messages(getRequiredFieldMessage('Report Type')),
    typeName: Joi.when('reportType', {
      is: Joi.valid(ReportType.UniqueName, ReportType.Account, ReportType.Campaign, ReportType.Content).required(),
      then: Joi.string().optional(),
      otherwise: Joi.string().required(),
    }).messages(getRequiredFieldMessage('Traffic Source')),
    dateType: Joi.valid('day', 'month', 'year', null),
    trafficSourceAccountIds: Joi.when('reportType', {
      is: Joi.valid(ReportType.UniqueName, ReportType.Account, ReportType.Campaign, ReportType.Content).required(),
      then: Joi.array().items(Joi.number()).min(1).optional(),
      otherwise: Joi.array().items(Joi.number()).min(1).required(),
    }),
    pagination: Joi.object({
      pageSize: Joi.number(),
      page: Joi.number(),
    }).optional(),
    conditions: Joi.array().items(Joi.object()).optional(),
    search: Joi.string().optional(),
    sort: Joi.string().optional(),
  });
};

export const validateData = (data: Partial<ReportingRequest>, isSearchFeed = false) => {
  return getSchema(isSearchFeed).validate(data, { stripUnknown: true, abortEarly: false });
};

export const getFieldsDto = (data: VueOptiTable.Field[]) => {
  const schema = Joi.array().items(
    Joi.object({
      header: Joi.object({
        content: Joi.string().required(),
        info: Joi.string().optional(),
      }).required(),
      item: Joi.object({
        key: Joi.string().required(),
        sortable: Joi.boolean().optional(),
        group: Joi.string().required(),
        type: Joi.string().required(),
      }).required(),
      display: Joi.boolean().optional(),
      options: Joi.object().optional(),
      customMetric: Joi.string().allow('').optional(),
      data: Joi.object().optional(),
    })
  );

  return schema.validate(data, { stripUnknown: true, abortEarly: false }).value as VueOptiTable.Field[];
};

export const getTableSortServerPayload = (sortType: string, sortField: string) => {
  if (sortType === 'asc') {
    return `+${sortField}`;
  }
  return `-${sortField}`;
};

export const getTableColumnFilter = (conditions: ColumnCondition[]) => {
  const columnFilter: Record<string, VueOptiTable.Condition[]> = {};

  for (const condition of conditions) {
    const data = {
      condition: (Object.values(RuleHelpers.Conditions.conditionsMap).find((item) => item.text === condition.operator)
        ?.value.key ?? '') as VueOptiTable.Condition['condition'],
      value: condition.value,
    };

    if (columnFilter[condition.field]) {
      columnFilter[condition.field].push(data);
    } else {
      columnFilter[condition.field] = [data];
    }
  }
  return columnFilter;
};

export const getColumnFilterDto = (tableColumnFilter: Record<string, VueOptiTable.Condition[]>): ColumnCondition[] => {
  const columnFilter: ColumnCondition[] = [];

  for (const [field, value] of Object.entries(tableColumnFilter)) {
    for (const conditionData of value ?? []) {
      const conditionKey = conditionData?.condition ?? '';

      if (conditionKey && RuleHelpers.Conditions.conditionsMap[conditionKey]) {
        columnFilter.push({
          field,
          operator: RuleHelpers.Conditions.conditionsMap[conditionKey].text,
          value: conditionData.value,
        });
      }
    }
  }
  return columnFilter;
};

export const getInitialDateRange = () => ({
  startDate: moment().startOf('day').format('YYYY-MM-DD'),
  endDate: moment().endOf('day').format('YYYY-MM-DD'),
});

export const getDateRangePayload = (date: DateRange) => ({
  startDate: moment(date.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment(date.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
});
