import targets from './targets';
import report from './report';

export default (F) => ({
  ...targets(F),
  ...report(F),
  name: F.create({
    header: { content: 'Name' },
    item: {
      key: 'name',
      sortable: true,
      cellClass: 'cell-name',
      total: {
        parse: () => 1,
        content: (totals) => (totals.name ? `Total: ${totals.name}` : '-'),
      },
    },
    colClass: 'sticky sticky--name',
  }),
});
