
import { onImageError } from '@sh/helpers';
import { defineComponent } from 'vue';

interface ComponentData {
  params?: {
    imageURL: string;
  };
  imageURL?: string;
}

export default defineComponent({
  data(): ComponentData {
    return {
      params: undefined,
      imageURL: undefined,
    };
  },
  beforeMount() {
    // this.params contains the cell and row information and is made available to this component at creation time
    if (this.params) {
      this.imageURL = this.params.imageURL;
    }
  },
  methods: {
    onImageError,
  },
});
