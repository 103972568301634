import { MessageIcon } from '@/views/Automation/CampaignCreatorV2/types';

export enum ExecutionOption {
  ValidateOnly = 'validate_only',
  IncludeRecommendations = 'include_recommendations',
}

export enum Status {
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE',
}

export enum BudgetValueType {
  ABSOLUTE = 'ABSOLUTE',
  MULTIPLIER = 'MULTIPLIER',
}

export enum RecurrenceType {
  ONE_TIME = 'ONE_TIME',
  WEEKLY = 'WEEKLY',
}

export type Budget = {
  time_start: number;
  time_end: number;
  budget_value: number;
  budget_value_type: BudgetValueType;
  recurrence_type: RecurrenceType;
  weekly_schedule: Array<{
    days: Array<number>;
    minute_start: number;
    minute_end: number;
    timezone_type: string;
  }>;
};

export function getContainerInlineStyles(active: boolean, semiactive: boolean, message: NullableType<MessageIcon>) {
  if (active) {
    return message?.containerStyles?.active;
  }
  if (semiactive) {
    return message?.containerStyles?.semiactive;
  }
  return message?.containerStyles?.default;
}
