const bidModifierActionOptions = [
  { value: 'set', text: 'Set Bid Modifier To' },
  { value: 'increase', text: 'Increase Bid Modifier By' },
  { value: 'decrease', text: 'Decrease Bid Modifier By' },
];

const bidModifierUnitOptions = [
  { value: 'static', text: '%' },
];

const bidModifierToOptions = [];

const bidModifierModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
        },
        'v-validate': ['between:-80,800'],
      },
      increase: {
        unit: {
          static: {},
        },
        'v-validate': ['between:0,800'],
      },
      decrease: {
        unit: {
          static: {},
        },
        'v-validate': ['between:0,80'],
      },
    },
  },
};

module.exports = {
  bidModifierActionOptions, bidModifierUnitOptions, bidModifierToOptions, bidModifierModelTemplates,
};
