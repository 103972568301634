
import Loading from '@sh/components/Utils/Loading.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';
import { MediaManager, TrafficSource } from '@sh/types';
import Vue, { VueConstructor } from 'vue';

const FACEBOOK_FORMAT_OPTIONS = [
  {
    content: 'Instagram',
    value: 'INSTAGRAM_STANDARD',
    dimensions: {
      width: '340px',
      height: '640px',
    },
  },
  {
    content: 'Instagram Story',
    value: 'INSTAGRAM_STORY',
    dimensions: {
      width: '340px',
      height: '575px',
    },
  },
  {
    content: 'Mobile',
    value: 'FACEBOOK_STORY_MOBILE',
    dimensions: {
      width: '335px',
      height: '670px',
    },
  },
  {
    content: 'Desktop',
    value: 'DESKTOP_FEED_STANDARD',
    dimensions: {
      width: '520px',
      height: 'calc(100vh - 220px)',
    },
  },
];

interface ComponentData {
  selectedFormat?: (typeof FACEBOOK_FORMAT_OPTIONS)[number];
  formatOptions: typeof FACEBOOK_FORMAT_OPTIONS;
  isLoadingPreview: boolean;
  iframe: string;
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: { Loading, OptimizerIcon },
  mixins: [notifications],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    trafficSource: {
      type: String,
      required: true,
    },
    trafficSourceAccountId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      selectedFormat: FACEBOOK_FORMAT_OPTIONS.find((option) => option.value === 'DESKTOP_FEED_STANDARD'),
      formatOptions: FACEBOOK_FORMAT_OPTIONS,
      isLoadingPreview: false,
      iframe: '',
    };
  },
  computed: {
    TrafficSource() {
      return TrafficSource;
    },
    style(): Record<string, string> {
      if (this.trafficSource === TrafficSource.TikTok) {
        return {
          width: '330px',
          height: '568px',
        };
      }
      return this.selectedFormat?.dimensions ?? {};
    },
    headerClasses(): Record<string, boolean> {
      const headerClass = {
        'modal-small':
          this.selectedFormat?.value !== 'DESKTOP_FEED_STANDARD' || this.trafficSource === TrafficSource.TikTok,
      };
      return headerClass;
    },
    contentDto(): Record<string, string> {
      if (this.trafficSource === TrafficSource.Facebook) {
        return {
          ad_format: this.selectedFormat?.value ?? '',
          id: this.contentId,
        };
      }
      return {
        preview_type: 'AD',
        ad_id: this.contentId,
      };
    },
    payload(): MediaManager.RequestTypes.Preview {
      return {
        ad: this.contentDto,
        tsName: this.trafficSource,
        tsId: this.trafficSourceAccountId,
      };
    },
  },
  methods: {
    async onOptionChange() {
      this.iframe = '';
      this.isLoadingPreview = true;
      this.getPreview();
    },
    onOpenModal() {
      this.isLoadingPreview = true;
      this.iframe = '';
      this.getPreview();
    },
    async getPreview() {
      try {
        const data = await this.$api.contents.getPreview(this.payload);
        this.isLoadingPreview = false;
        this.iframe = data.iframe;
      } catch {
        this.isLoadingPreview = false;
        this.onModalClose();
        this.$n_failNotification({ title: 'Preview could not be opened, please try again!' });
      }
    },
    onModalClose() {
      this.selectedFormat = FACEBOOK_FORMAT_OPTIONS.find((option) => option.value === 'DESKTOP_FEED_STANDARD');
      this.$emit('onModalClose');
    },
  },
});
