<template>
  <div class="content-wrapper campaign-container">
    <div class="row mb-4 pb-3">
      <div class="col-md-9 col-sm-12">
        <loading-skeleton
          v-if="preloaderCampaign"
          class="campaigns-select-skeleton"
          type="card"
          :card="{ rows: 5 }"
        />
        <div v-else class="campaign-container__campaign">
          <div class="campaign-container__campaign__actions">
            <switch-button
              :checked="campaign.enabled"
              :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
              :update="(value) => $_updateStatus(campaign, value)"
            />
            <action-btn v-if="$c_showCloneButton||$c_showSimpleCloneButton" type="clone" title="Clone" class="mr-2"
                        :click="() => $_showCloneModal(campaign)"
            />
            <action-btn
              v-if="$c_showEditButton"
              type="edit"
              title="Edit"
              class="mr-2"
              :to="{
                name: 'CampaignCreator',
                query: {
                  account: campaign.traffic_source_account_id,
                  id: campaign.traffic_source_campaign_id,
                  mode: 'edit',
                },
              }"
            />
            <action-btn
              v-if="$c_showFacebookChangeBudgetStrategyButton"
              type="campaignSettings"
              title="Change Budget Strategy"
              class="mr-2"
              :click="() => $_showFacebookChangeBudgetStrategyModal(campaign)"
            />
          </div>
          <div class="campaign-container__campaign__name">
            <div class="d-flex align-items-center campaign-name">
              <template v-if="account.type">
                <img
                  class="logo-icon"
                  v-b-tooltip.hover.top
                  :title="account.name"
                  :src="getTrafficSourceLogo(account.type.uniqueName)"
                />
              </template>
              <template v-if="tracker.type">
                <i class="fa fa-arrows-h divider" />
                <img
                  class="logo-icon"
                  v-b-tooltip.hover.top
                  :title="tracker.name"
                  :src="
                    tracker.type.uniqueName != 'ClickFlare'
                      ? '/sh_static/accounts/' + tracker.type.uniqueName + '.png'
                      : '/sh_static/accounts/ClickFlare.svg'
                  "
                />
              </template>
              <span :title="campaign.name">{{ campaign.name }}</span>
              <div class="flags text-right">
                <template v-if="$c_showList">
                  <div
                    class="flag-item label-red"
                    v-if="!campaign.is_whitelist"
                    v-b-tooltip.hover.bottomleft
                    title="Blacklist"
                  >
                    B
                  </div>
                  <div
                    class="flag-item label-light-blue"
                    v-else-if="campaign.is_whitelist"
                    v-b-tooltip.hover.bottomleft
                    title="Whitelist"
                  >
                    W
                  </div>
                </template>
                <div
                  class="flag-item label-gray"
                  v-if="!campaign.managed"
                  v-b-tooltip.hover.bottomleft
                  title="Campaign is not managed by Optimizer"
                >
                  U
                </div>
                <div
                  class="flag-item label-blue"
                  v-if="campaign.has_rules"
                  v-b-tooltip.hover.bottomleft
                  title="Has active rules"
                >
                  R
                </div>
                <div
                  class="flag-item label-orange"
                  v-if="campaign.manual_connected"
                  v-b-tooltip.hover.bottomleft
                  title="Manual connected"
                >
                  M
                </div>
              </div>
            </div>
          </div>

          <div class="campaign-container__campaign__status">
            <span
              v-b-tooltip.hover.topcenter
              :class="`status-item status-item-${campaign.status}`"
              :title="campaign.traffic_source_status"
              v-html="ui.campaign.status(campaign.status)"
            />
          </div>

          <template v-if="campaign.traffic_source_unique_name">
            <template v-for="(item, index) in editableFields[campaign.traffic_source_unique_name]">
              <div class="campaign-container__campaign__budget" :key="index">
                <span class="label-tag">{{ item.name }}</span>
                <template v-if="item.key === 'bid'">
                  <live-edit
                    :key="`bidLiveEdit-${item.key}`"
                    v-model="campaign.additional_fields.bid"
                    :prefix="symbols.get(campaign.traffic_source_currency) || '$'"
                    :format="ui.numFormat"
                    field-name="Bid"
                    :max-limit-warning="
                      $_smartMaxCpcMaxLimit(campaign.avg_cpc, campaign.additional_fields.bid)
                    "
                    :before-blur="(value) => $_updateBid(campaign.id, value)"
                  />
                </template>
                <template v-else-if="item.key === 'budget'">
                  <span
                    v-if="
                      campaign.additional_fields.budget === null &&
                        (campaign.traffic_source_unique_name === 'Facebook' || campaign.traffic_source_unique_name === 'TikTok')
                    "
                  >-</span>
                  <span v-else-if="campaign.additional_fields.budget === 'null'">-</span>
                  <span v-else-if="campaign.additional_fields.budget === -1">Unlimited</span>
                  <live-edit
                    v-else
                    :key="`budgetLiveEdit-${item.key}`"
                    v-model="campaign.additional_fields.budget"
                    :prefix="symbols.get(campaign.traffic_source_currency) || '$'"
                    :format="ui.numFormat"
                    field-name="Budget"
                    :before-blur="(value) => $_updateBudget(campaign, value)"
                  />
                </template>
                <template v-else-if="item.key === 'daily_budget'">
                  <span
                    v-if="
                      campaign.additional_fields.daily_budget === null &&
                        (campaign.traffic_source_unique_name === 'Facebook' || campaign.traffic_source_unique_name === 'TikTok')
                    "
                  >-</span>
                  <span v-else-if="campaign.additional_fields.daily_budget === 'null'">-</span>
                  <span v-else-if="campaign.additional_fields.daily_budget === -1">Unlimited</span>
                  <live-edit
                    v-else
                    :key="`dailyBudgetLiveEdit-${item.key}`"
                    v-model="campaign.additional_fields.daily_budget"
                    :prefix="symbols.get(campaign.traffic_source_currency) || '$'"
                    :format="ui.numFormat"
                    field-name="Daily Budget"
                    :before-blur="(value) => $_updateDailyBudget(campaign, value)"
                  />
                </template>
                <template v-else-if="item.key === 'cpc_desktop'">
                  <live-edit
                    :key="`cpcDesktopLiveEdit-${item.key}`"
                    v-model="campaign.additional_fields.cpc_desktop"
                    :prefix="symbols.get(campaign.traffic_source_currency) || '$'"
                    :format="ui.numFormat"
                    field-name="Desktop Bid"
                    :max-limit-warning="
                      $_smartMaxCpcMaxLimit(
                        campaign.avg_cpc,
                        campaign.additional_fields.cpc_desktop
                      )
                    "
                    :before-blur="(value) => $_updateBidCad(campaign.id, { cpc_desktop: value })"
                  />
                </template>
                <template v-else-if="item.key === 'cpc_mobile'">
                  <live-edit
                    :key="`cpcMobileLiveEdit-${item.key}`"
                    v-model="campaign.additional_fields.cpc_mobile"
                    :prefix="symbols.get(campaign.traffic_source_currency) || '$'"
                    :format="ui.numFormat"
                    field-name="Mobile Bid"
                    :max-limit-warning="
                      $_smartMaxCpcMaxLimit(campaign.avg_cpc, campaign.additional_fields.cpc_mobile)
                    "
                    :before-blur="(value) => $_updateBidCad(campaign.id, { cpc_mobile: value })"
                  />
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>

      <!-- <div class="col-md-3">
        <h2 class="content-title">
          Campaign
        </h2>
      </div> -->
      <div class="col-md-3 col-sm-12 ml-md-auto">
        <date-range v-model="dateRangePicker" @change="$_changeDateRangePicker" :hasComparison="true" />
      </div>
    </div>

    <!--STATISTICS-->
    <div class="partial-reload mb-4 pb-1">
      <CampaignsStats
        :cost="campaignStats.cost"
        :revenue="campaignStats.revenue"
        :net="campaignStats.net"
        :roi="campaignStats.roi"
        :cpc="campaignStats.cpc"
        :cpa="campaignStats.cpa"
        :epc="campaignStats.epc"
        :est_cost="campaignStats.est_cost"
        :est_net="campaignStats.est_net"
        :est_roi="campaignStats.est_roi"
        :stats_info="campaignStats.statsInfo"
        :loading="preloaderCampaign"
      />
    </div>
    <facebook-change-budget-strategy-modal
      ref="FacebookChangeBudgetStrategyModal"
      @changeFacebookBudgetStrategy="$_updateFacebookBudgetStrategy"
    />
    <!--Tab Header-->
    <div class="row campaign-container__tabs">
      <h5 class="col-md-5 mr-md-auto mb-md-0 mb-4">
        {{ activeTab.name }}
        <small v-if="activeTab.timezone" class="reporting-timezone-title">Reporting timezone: {{ $c_reportingTimezone }}</small>
      </h5>
      <div class="col-auto ml-md-auto campaign-container__tabs__content">
        <div
          v-for="tab in availableTabs"
          :key="tab.id"
          class="tab-item"
          @click="$_tabAction(tab)"
          :class="{ 'tab-item-active': activeTab.id === tab.id }"
        >
          {{ tab.alias }}
        </div>
      </div>
    </div>

    <loading-skeleton
      v-if="preloaderCampaign"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 2 }"
    />

    <!--Tab Component-->
    <component
      :is="activeTab.component"
      :changeActiveTab="$_tabAction"
      :campaign-loading="preloaderCampaign"
      :campaign="campaign"
      :account="account"
      :tracker="tracker"
      :date-range="dateRangePicker"
      :timezone="$c_reportingTimezone"
      :settings="$c_settings"
      :type="activeTab"
    />
    <CloneModal ref="cloneModal" :campaign="campaign" />

  </div>
</template>

<script>
import CampaignsStats from '@sh/components/Campaigns/CampaignsStats.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import LiveEdit from '@sh/components/Utils/LiveEdit';
import moment from 'moment';
import notifications from '@sh/mixins/notifications';
import tableMixins from '@sh/mixins/table';
import campaignMixins from '@/mixins/campaign';
import _object from 'lodash/fp/object';
import _lang from 'lodash/fp/lang';
import config from './config';
import ui from '@sh/helpers/ui';
import symbols from '@sh/helpers/symbols';
import Widgets from './Tabs/Widgets';
import Contents from './Tabs/Contents';
import Targets from './Tabs/Targets';
import Sites from './Tabs/Sites';
import Exchanges from './Tabs/Exchanges';
import Sections from './Tabs/Sections';
import AdGroups from './Tabs/AdGroups';
import Domains from './Tabs/Domains';
import Spots from './Tabs/Spots';
import Rules from '@sh/views/Campaign/Tabs/Rules';
import TrackerView from './Tabs/TrackerView';
import Performance from './Tabs/Performance';
import SystemActivityCampaign from './Tabs/SystemActivityCampaign';
import FacebookChangeBudgetStrategyModal from '../Campaigns/FacebookChangeBudgetStrategy';
import CloneModal from '../Campaigns/components/CloneModal.ts.vue';
import { getTrafficSourceLogo } from '@sh/helpers';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';
import { getFieldsAndFilters } from '@sh/configurations/fields/FieldFilter';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'Campaign',
  components: {
    CampaignsStats,
    OptimizerIcon,
    LiveEdit,
    Widgets,
    Contents,
    Targets,
    Sites,
    Exchanges,
    Sections,
    AdGroups,
    Domains,
    Spots,
    Rules,
    TrackerView,
    Performance,
    SystemActivityCampaign,
    FacebookChangeBudgetStrategyModal,
    CloneModal,
  },
  mixins: [notifications, tableMixins, campaignMixins],
  data() {
    return {
      ui,
      symbols,
      campaign: {},
      campaignStats: {},
      account: {},
      tracker: {},
      dateRangePicker: {
        startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment().endOf('day').format(FORMAT),
      },
      settings: {},
      preloader: true,
      preloaderCampaign: true,
      availableTabs: [],
      activeTab: {},
      editableFields: {},
      simpleCloneOnProgress: false,
      revenueFrom: 'tr',
      userFields: [],
    };
  },
  computed: {
    $c_reportingTimezone() {
      try {
        let { timezone } = this.account.type;
        if (this.account.settings.timezone) timezone = this.account.settings.timezone;
        return timezone;
      } catch (error) {
        // Do nothing
      }
      return '';
    },
    $c_settings() {
      const campSettings = this.campaign.settings ? _lang.cloneDeep(this.campaign.settings) : {};
      const globalSettings = _lang.cloneDeep(this.settings);
      return _object.merge(globalSettings, campSettings);
    },
    $c_showCloneButton() {
      return (
        ['RevContent', 'Taboola', 'ContentAd', 'Outbrain', 'Gemini'].indexOf(
          this.campaign.traffic_source_unique_name,
        ) > -1
      );
    },
    $c_showSimpleCloneButton() {
      return (
        ['Facebook', 'TikTok', 'GoogleAds'].indexOf(this.campaign.traffic_source_unique_name) > -1
      );
    },
    $c_showEditButton() {
      return (
        ['RevContent', 'Taboola', 'ContentAd', 'Outbrain', 'Gemini'].indexOf(
          this.campaign.traffic_source_unique_name,
        ) > -1
      );
    },
    $c_showFacebookChangeBudgetStrategyButton() {
      if (
        this.campaign.traffic_source_unique_name === 'Facebook'
        && this.campaign.additional_fields.bid_strategy === 'LOWEST_COST_WITHOUT_CAP'
      ) {
        if (
          this.campaign.additional_fields.budget
          || this.campaign.additional_fields.daily_budget
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    $c_showList() {
      return !['TikTok', 'Facebook', 'GoogleAds'].includes(this.campaign.traffic_source_unique_name);
    },
  },
  async created() {
    this.preloader = true;
    // Register UserTask Action
    this.$_registerUserAction();

    /* *********** Set Date Range ************ */
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRangePicker.startDate = this.$route.query.startDate;
      this.dateRangePicker.endDate = this.$route.query.endDate;
    }
    /* *********** ************** ************ */

    try {
      /* *********** Get API Data *********** */
      const [settings, trafficSourceAccounts, trackerAccounts, campaign] = await Promise.all([
        this.$api.settings.getSettings(),
        this.$apiStore.trafficSources.accounts(),
        this.$apiStore.trackers.accounts(),
        this.$_getCampaign(),
        this.$_apiRules(),
      ]);
      const trafficSourceAccount = trafficSourceAccounts.find(
        ({ id }) => id === campaign.traffic_source_account_id,
      );
      // const trackerAccount = campaign.tracker_account_id !== -1 ? trackerAccounts.find(({ id }) => id === campaign.tracker_account_id) : { type: { uniqueName: 'ClickFlare' }, name: 'ClickFlare' };
      const trackerAccount = trackerAccounts.find(({ id }) => id === Number(campaign.tracker_account_id));
      this.userFields = await this.$_getUserFields();
      this.revenueFrom = this.$_getRevenueSource(settings, trackerAccount);
      /* *********** ************ *********** */

      /* *********** Set API Data *********** */
      this.account = trafficSourceAccount || {};
      this.tracker = trackerAccount || {};
      this.campaign = campaign;
      this.$_updateCampaignTotals();
      /* *********** ************ *********** */

      /* *********** Set Active Tab ************ */
      this.availableTabs = config.getAvailableTabs(campaign, trafficSourceAccount, trackerAccount);
      const { query } = this.$route;
      const secondTab = {
        MediaGo: 'contents',
        Facebook: 'adgroups',
        TikTok: 'adgroups',
        GoogleAds: this.availableTabs.find((tab) => tab.id === 'adgroups') ? 'adgroups' : 'assetgroups',
        AmazonDisplay: 'adgroups',
        AmazonDSP: 'adgroups',
      };
      this.activeTab = this.availableTabs.find((tab) => tab.id === query.tab)
        || this.availableTabs.find((tab) => secondTab[this.account.type.uniqueName] === tab.id)
        || this.availableTabs.find((tab) => tab.id === 'widgets');
      if (this.activeTab.id !== query.tab) {
        this.$router.replace({ query: { ...query, tab: this.activeTab.id } });
      }
      /* *********** ************** ************ */

      this.editableFields = config.editableFields;

      // Update RecentlySearch
      this.$settings.recentlySearch.setItem('campaigns', `campaign-${this.campaign.id}`);

      this.preloader = false;
    } catch (error) {
      console.log(error);
      try {
        this.$n_failNotification({ title: error.response.data.message });
      } catch (err) {
        this.$n_failNotification({ title: 'An error occurred' });
      }
    }
  },
  methods: {
    getTrafficSourceLogo,
    async $_getCampaign() {
      this.preloaderCampaign = true;
      try {
        const campaign = await this.$api.campaigns.getCampaignPerformance(this.$route.params.id, {
          startDate: this.dateRangePicker.startDate,
          endDate: this.dateRangePicker.endDate,
        });
        this.preloaderCampaign = false;
        return campaign;
      } catch (error) {
        this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        this.preloaderCampaign = false;
      }
    },
    $_updateCampaignTotals() {
      this.campaignStats = {
        cost: this.campaign.cost,
        revenue: this.campaign[this.$_prefixedKey('revenue')],
        net: this.campaign[this.$_prefixedKey('net')],
        roi: this.campaign[this.$_prefixedKey('roi')],
        cpc: this.campaign[this.$_prefixedKey('cpc')],
        cpa: this.campaign[this.$_prefixedKey('cpa')],
        epc: this.campaign[this.$_prefixedKey('epc')],
        est_cost: this.campaign[this.$_prefixedKey('est_cost')],
        est_net: this.campaign[this.$_prefixedKey('est_net')],
        est_roi: this.campaign[this.$_prefixedKey('est_roi')],
        statsInfo: {
          cost: getFieldTextAndDescription(this.userFields.find((field) => field.key === 'cost'), true).description,
          revenue: getFieldTextAndDescription(this.userFields.find((field) => field.key === this.$_prefixedKey('revenue')), true).description,
          net: getFieldTextAndDescription(this.userFields.find((field) => field.key === this.$_prefixedKey('net')), true).description,
          roi: getFieldTextAndDescription(this.userFields.find((field) => field.key === this.$_prefixedKey('roi')), true).description,
        },
      };
    },
    $_tabAction(tab) {
      this.activeTab = this.availableTabs.find((t) => tab.id === t.id);
      const { query } = this.$route;
      if (this.activeTab.id !== this.$route.query.tab) {
        this.$router.replace({ query: { ...query, tab: this.activeTab.id } });
      }
    },
    async $_changeDateRangePicker() {
      this.campaign = await this.$_getCampaign();
      this.$_updateCampaignTotals();
    },
    $_showFacebookChangeBudgetStrategyModal(item) {
      if (item.additional_fields.daily_budget || item.additional_fields.budget) {
        this.$refs.FacebookChangeBudgetStrategyModal.show(item);
      } else {
        this.$swal({
          title: 'This operation is not available for this campaign!',
          type: 'warning',
        });
      }
    },
    async $_showCloneModal(item) {
      if (this.$c_showSimpleCloneButton) {
        this.campaign = item;
        this.$refs.cloneModal.showModal();
      } else {
        await this.$_cloneCampaign(item);
      }
    },
    async $_getUserFields() {
      let userFields = [];
      try {
        const avMetrics = await this.$apiStore.dashboard.getAvailableMetrics();
        userFields = avMetrics.fields.map((item) => ({ ...item, key: item.item.key }));
      } catch (err) {
        const fieldsAndFilters = getFieldsAndFilters();
        userFields = fieldsAndFilters.fields;
      }
      return userFields;
    },
    $_getRevenueSource(settings, hasTracker) {
      return settings?.revenue_from || (hasTracker ? 'tr' : 'ts');
    },
    $_prefixedKey(key) {
      return `${this.revenueFrom}_${key}`;
    },
  },
};
</script>

<style lang="scss">
.campaign-container {
  &__campaign {
    display: flex;
    border-radius: 0.6rem;
    // box-shadow: $btn-bs-md;
    border: 0.1rem solid #e5e5e5;
    background: $color-white;
    font-size: 1.3rem;

    & > div {
      border-right: 0.1rem solid #ebebeb;
      padding: 0.55rem 1.5rem;
    }

    &__actions {
      display: flex;
      flex-basis: 10%;
      justify-content: center;
    }

    &__name {
      flex-basis: 40%;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      .campaign-name {
        overflow: hidden;
        white-space: nowrap;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .divider {
        margin: 0 0.5rem;
      }

      img {
        height: 2rem;

        &:last-of-type {
          margin-right: 0.5rem;
        }
      }

      .flags {
        display: flex;
        margin-left: 0.7rem;

        .flag-item {
          width: 1.3rem;
          height: 1.3rem;
          border: 0.1rem solid $color-red;
          color: $color-red;
          font-weight: bold;
          margin-right: 0.5rem;
          font-size: 1rem;
          text-align: center;
          line-height: 1.2rem;
          cursor: pointer;

          &.label-red {
            border-color: $color-red;
            color: $color-red;
          }

          &.label-blue {
            border-color: $color-blue;
            color: $color-blue;
          }

          &.label-blue-steel {
            border-color: $color-green;
            color: $color-green;
          }

          &.label-light-blue {
            border-color: $color-purple;
            color: $color-purple;
          }
        }
      }
    }

    &__status {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 5%;

      .status-item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;

        & > span {
          id .circle,
          .stats-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 1.6rem;
            id &::after {
              content: "";
              display: block;
              line-height: 2rem;
              border-radius: 50%;
              width: 1rem;
              height: 1rem;
              background: $color-yellow;
            }
          }

          &.status-item-RUNNING,
          &.status-item-enabled,
          &.status-item-APPROVED {
            color: #61a633 !important;

            .circle,
            .stats-circle {
              background: rgba(#61a633, 0.3);

              &::after {
                background: #61a633;
              }
            }
          }
          id .circle {
            background: rgba(#df3a3a, 0.3);

            &::after {
              background: $color-red;
            }
          }
        }

        &.status-item-OTHER,
        &.status-item-disabled {
          color: $color-dark-yellow !important;

          .circle {
            background: rgba(#fdbe1d, 0.3);

            &::after {
              background: $color-dark-yellow;
            }
          }
        }

        &.status-item.MODERATION {
          color: $color-blue !important;

          .circle {
            background: rgba(#52a8f8, 0.3);

            &::after {
              background: $color-blue;
            }
          }
        }
      }
    }

    &__budget {
      position: relative;
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-light-blue;
      padding: 0 1rem !important;
      // overflow: hidden;
      padding-top: 0.8rem !important;
      font-size: 1.2rem;

      .label-tag {
        // display: none;
        position: absolute;
        left: 0;
        top: 0.2rem;
        width: 100%;
        text-align: center;
        // background: #7676ff;
        color: $text-primary;
        height: 1.3rem;
        font-size: 1.1rem;
        line-height: 1.2;
      }

      .live-edit {
        input {
          height: 2rem;
          border-right: 0.1rem solid $color-light-blue;
        }
      }
    }

    :last-child {
      border-right: 0;
    }
  }

  &__tabs {
    margin-bottom: 2rem;

    h5 {
      font-size: 1.8rem;

      .reporting-timezone-title {
        font-size: 1.3rem;
        color: $text-secondary;
        margin-left: 0.3rem;
      }
    }

    &__content {
      .tab-item {
        display: inline-block;
        padding-inline: 0.5rem;
        margin-right: 1.8rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: #252d41;
        cursor: pointer;

        &:last-of-type {
          padding-right: 0;
          margin-right: 0;
        }

        &.tab-item-active {
          color: $color-light-blue;
          border-bottom: 0.2rem solid $color-light-blue;
        }
      }
    }
  }

  .optimizer-date-range {
    & > .reportrange-text {
      // box-shadow: $btn-bs-md;
      // border: none !important;
    }
  }
}

// .tab-item {
//   cursor: pointer;
//   float: left;
//   padding-right: 5px;
//   margin-right: 7px;
//   color: gray;
//   &:last-child {
//     padding-right: 0px;
//     margin-right: 0px;
//   }
// }
// .tab-item-active {
//   color: black;
// }
@media (max-width: 767.98px) {
  .campaign-container {
    &__campaign {
      overflow-x: auto;
      margin-bottom: 1em;
      white-space: nowrap;

      &__budget {
        min-width: 8rem;
      }
    }

    &__tabs {
      margin-bottom: 1rem;
    }

    .optimizer-table {
      > .datatable-wrapper {
        > .header {
          > *:not(.datatable-search-wrapper) {
            width: 100%;
            margin: 0.5rem 1.5rem !important;
            padding: 0 !important;
            height: 4rem;
          }
        }
      }
    }
  }
}
</style>
