export const generateIdsforAudience = (selectedItems, originalItems) => {
  let ids = [];
  selectedItems.forEach((item) => {
    if (item.parentId && item?.childIds?.length) {
      ids = [...ids, ...item.childIds.map((id) => id)];
    } else if (item.parentId.length) {
      originalItems.forEach((opt) => {
        if (opt.categoryId === item.parentId) {
          ids = [...ids, ...opt.children.map((subCategory) => subCategory.categoryId)];
        }
      });
    }
  });
  return ids;
};

export const generateIdsforInterests = (selectedItems, originalItems) => {
  let ids = [];
  selectedItems.forEach((interes) => {
    if (interes.parentId && interes?.childIds?.length) {
      ids = [...ids, ...interes.childIds.map((id) => id)];
    } else if (interes.parentId) {
      originalItems.forEach((opt) => {
        if (opt.id === interes.parentId) {
          ids = [...ids, ...opt.subConcepts.map((subItem) => subItem.id)];
        }
      });
    }
  });
  return ids;
};

export const popullateComponentInterests = ({ interests }, data) => {
  const selectedInterests = [];
  const infos = { selectedCategories: [], selectedIds: [] };
  interests.forEach((interes) => {
    interes.subConcepts.forEach((child) => {
      if ((data?.ids || []).includes(child.id)) {
        infos.selectedIds = [...infos.selectedIds, { text: child.name, value: child.id }];
        infos.selectedCategories = [...infos.selectedCategories, {
          text: interes.name,
          value: interes.id,
          children: interes.subConcepts.map((subConcept) => ({ text: subConcept.name, value: subConcept.id })),

        }];
      }
    });
  });

  infos.selectedCategories = infos.selectedCategories.filter((element, index, self) => index === self.findIndex((t) => (
    t.value === element.value
  )));

  selectedInterests.push(infos);

  return selectedInterests;
};

export const popullateComponentCategories = ({ categories }, data) => {
  const selectedCategories = [];
  const infos = { selectedCategories: [], selectedIds: [] };
  categories.forEach((category) => {
    category.children.forEach((child) => {
      if (data.ids.includes(child.categoryId)) {
        infos.selectedIds = [...infos.selectedIds, { text: child.categoryName, value: child.categoryId }];
        infos.selectedCategories = [...infos.selectedCategories, {
          text: category.categoryName,
          value: category.categoryId,
          children: category.children.map((children) => ({ text: children.categoryName, value: children.categoryId })),

        }];
      }
    });
  });

  infos.selectedCategories = infos.selectedCategories.filter((element, index, self) => index === self.findIndex((t) => (
    t.value === element.value
  )));

  selectedCategories.push(infos);

  return selectedCategories;
};

export const getConversionsValue = (nextTick, component, formData, helperSetMultiSelectValue) => {
  nextTick(() => {
    component.once('ready', () => {
      helperSetMultiSelectValue({
        component,
        values: formData,
        getValueKey: (id) => id,
        getOptionKey: (option) => option.value,
      });
    });
  });
};
