import { apiStore } from '@sh/services/api';

const prefetchHandler = () => {
  apiStore.rules.index();
  apiStore.rules.gaFields();
  apiStore.rules.gaFields4();
  apiStore.rules.ccFields();
  apiStore.lists.all();
};

export { prefetchHandler };
