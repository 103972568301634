var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"op-sidebar__help",class:{ 'op-sidebar__help--collapsed': _vm.collapsed },staticStyle:{"background-image":"url('sh_static/sidebar/sidebar-info-card-bg.svg')"},attrs:{"name":"cc-fade","mode":"out-in"}},[(!_setup.session.isSearchFeed)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
      customClass: `custom-tooltip ${!_vm.collapsed && 'hidden'}`,
      title: 'Whats New?',
      boundary: 'viewport',
      'boundary-padding': 10,
    }),expression:"{\n      customClass: `custom-tooltip ${!collapsed && 'hidden'}`,\n      title: 'Whats New?',\n      boundary: 'viewport',\n      'boundary-padding': 10,\n    }",modifiers:{"hover":true,"right":true}}],staticClass:"op-btn op-btn--light",class:{ 'op-btn--outline': _vm.collapsed },attrs:{"id":"changelogWidgetId"}},[_c(_setup.OptimizerIconV2,{attrs:{"type":"infoCircle"}}),_vm._v(" "),(!_vm.collapsed)?_c('span',[_vm._v("Whats New")]):_vm._e()],1):_vm._e(),(!_setup.session.isSearchFeed)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
      customClass: `custom-tooltip ${!_vm.collapsed && 'hidden'}`,
      title: 'Feature Request',
      container: 'body',
    }),expression:"{\n      customClass: `custom-tooltip ${!collapsed && 'hidden'}`,\n      title: 'Feature Request',\n      container: 'body',\n    }",modifiers:{"hover":true,"right":true}}],staticClass:"op-btn op-btn--light",class:{ 'op-btn--outline': _vm.collapsed },on:{"click":_setup.openHelloNext}},[_c(_setup.OptimizerIconV2,{attrs:{"type":"star"}}),_vm._v(" "),(!_vm.collapsed)?_c('span',[_vm._v("Feature Request")]):_vm._e()],1):_vm._e(),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
      customClass: `custom-tooltip ${!_vm.collapsed && 'hidden'}`,
      title: 'Contact Support',
      container: 'body',
    }),expression:"{\n      customClass: `custom-tooltip ${!collapsed && 'hidden'}`,\n      title: 'Contact Support',\n      container: 'body',\n    }",modifiers:{"hover":true,"right":true}}],staticClass:"op-btn op-btn--light",class:{ 'op-btn--outline': _vm.collapsed },on:{"click":_setup.toggleSupportChat}},[_c(_setup.OptimizerIconV2,{attrs:{"type":"dialog"}}),_vm._v(" "),(!_vm.collapsed)?_c('span',[_vm._v("Contact Support")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }