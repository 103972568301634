<template>
  <div class="campaign-item-name">
    <router-link
      :to="{
        name: 'Campaign',
        params: { id: campaign.id, type: trafficSourceType.uniqueName },
        query: { type: trafficSourceType.id, account: campaign.traffic_source_account_id, tracker: campaign.tracker_account_id || 0, startDate: route.query.startDate || moment(new Date()).format('YYYY-MM-DD'), endDate: route.query.endDate || moment(new Date()).format('YYYY-MM-DD'), comparisonStartDate: compareRanges.comparisonStartDate, comparisonEndDate: compareRanges.comparisonEndDate, period: compareRanges.period, column_preset: selectedPreset }
      }"
    >
      <div class="d-flex align-items-center campaign-name">
        <img
          v-if="$c_showTrackerLogo"
          v-b-tooltip="{ title: trackerAccountsMap[campaign.tracker_account_id].name, placement: 'top', delay: { show: 0, hide: 0 }, boundary: 'window' }"
          style="width: 20px;"
          class="mr-2"
          :src="trackerAccountsMap[campaign.tracker_account_id].type.uniqueName === 'ClickFlare' ? '/sh_static/accounts/ClickFlare.svg' : `/sh_static/accounts/${trackerAccountsMap[campaign.tracker_account_id].type.uniqueName}.png`"
          alt="tracker-image"
        >
        <!-- <img
          v-else-if="campaign.tracker_account_id === -1"
          v-b-tooltip="{ title: 'ClickFlare', placement: 'top', delay: { show: 0, hide: 0 }, boundary: 'window' }"
          style="width: 20px;"
          class="mr-2"
          src="/sh_static/accounts/ClickFlare.svg"
        > -->
        <img
          v-else-if="campaign.traffic_source_unique_name === 'VoluumDSP'"
          v-b-tooltip="{ title: campaign.traffic_source_unique_name, placement: 'top', delay: { show: 0, hide: 0 }, boundary: 'window' }"
          style="width: 20px;"
          class="mr-2"
          :src="`/sh_static/accounts/${campaign.traffic_source_unique_name}.png`"
          alt="traffic-source-image"
        >
        <template v-if="campaign.ga_account_id !== null && Object.keys(campaign.ga_view_ids).length">
          <img
            :id="`ga-${campaign.id}`"
            style="width: 20px;"
            src="/sh_static/accounts/GoogleAnalytics.png"
            class="mr-2"
            alt="google-analytics-image"
          >
          <b-popover
            :target="`ga-${campaign.id}`"
            triggers="hover focus"
          >
            <template slot="title">
              GA Views
            </template>
            <div v-for="(view, key) in campaign.ga_view_ids" :key="key" class="mb-1 flags">
              <small>- {{ key }}</small>
              <div v-if="view.manual_connected" class="flag-item label-orange" title="Manual connected">
                M
              </div>
            </div>
          </b-popover>
        </template>
        <span :title="campaign.name">{{ campaign.name }}</span>
      </div>
    </router-link>
    <div class="flags">
      <template v-if="$c_showList">
        <div v-if="!campaign.is_whitelist" v-b-tooltip.hover.bottomleft class="flag-item label-red" title="Blacklist">B</div>
        <div v-else-if="campaign.is_whitelist" v-b-tooltip.hover.bottomleft class="flag-item label-light-blue" title="Whitelist">W</div>
      </template>
      <div v-if="!campaign.managed" v-b-tooltip.hover.bottomleft class="flag-item label-gray" title="Campaign is not managed by Optimizer">U</div>
      <template v-if="campaignRulesMap[campaign.id] && campaignRulesMap[campaign.id].find(rule => rule.rule_action !== 'day_parting')">
        <div :id="`rule-${campaign.id}`" class="flag-item label-blue">R</div>
        <b-popover :target="`rule-${campaign.id}`" triggers="hover focus">
          <template slot="title">Has active rules</template>
          <template v-for="(rule, index) in campaignRulesMap[campaign.id]">
            <p v-if="rule.rule_action !== 'day_parting'" :key="index" class="mb-1">
              <small>- {{ rule.name }}</small>
            </p>
          </template>
        </b-popover>
      </template>
      <TransparencyProgramInfo v-if="transparencyProgram.isActive" :id="campaign.id" :email="transparencyProgram.email" :searchFeed="transparencyProgram.searchFeed" :absolute="false" />
      <div
        v-if="campaignRulesMap[campaign.id] && campaignRulesMap[campaign.id].find(rule => rule.rule_action === 'day_parting')"
        v-b-tooltip="{ title: 'Dayparting Enabled', placement: 'bottomLeft', delay: { show: 0, hide: 0 }, boundary: 'window' }"
        class="flag-item label-blue-steel flag-item-icon"
      >
        <i class="fa fa-th-large" aria-hidden="true" />
      </div>
      <div v-if="campaign.duplicate" v-b-tooltip="{ title: 'Campaign is duplicated', placement: 'bottomLeft', delay: { show: 0, hide: 0 }, boundary: 'window' }" class="flag-item label-purple-plum">D</div>
      <div v-if="campaign.manual_connected" v-b-tooltip="{ title: 'Manual connected', placement: 'bottomLeft', delay: { show: 0, hide: 0 }, boundary: 'window' }" class="flag-item label-orange">M</div>
      <div v-if="campaign.archived" v-b-tooltip="{ title: 'Archived', placement: 'bottomLeft', delay: { show: 0, hide: 0 }, boundary: 'window' }" class="flag-item label-black">A</div>
    </div>
  </div>
</template>

<script>
import TransparencyProgramInfo from '@sh/components/Utils/TransparencyProgramInfo.ts.vue';
import { TrafficSource } from '@sh/types';

export default {
  name: 'CampaignName',
  components: {
    TransparencyProgramInfo,
  },
  props: {
    campaign: { type: Object, required: true },
    trafficSourceType: { type: Object, required: true },
    trackerAccountsMap: { type: Object, required: true },
    campaignRulesMap: { type: Object, required: true },
    route: { type: Object, required: true },
    moment: { type: Function, required: true },
    trafficSourcesLinkedTrackers: Object,
    compareRanges: { type: Object, required: true },
    selectedPreset: { type: String, required: false },
    accounts: Array,
  },
  computed: {
    $c_showList() {
      return ![TrafficSource.TikTok, TrafficSource.Facebook, TrafficSource.GoogleAds].includes(this.campaign.traffic_source_unique_name);
    },
    $c_showTrackerLogo() {
      return this.campaign.tracker_account_id && (!this.trafficSourcesLinkedTrackers || this.trafficSourcesLinkedTrackers[this.campaign.traffic_source_account_id]?.[this.campaign.tracker_account_id]) && this.trackerAccountsMap[this.campaign.tracker_account_id];
    },
    transparencyProgram() {
      const getData = (isActive = false, email = '', searchFeed = '') => ({
        isActive: !!isActive,
        email,
        searchFeed,
      });
      const account = this.accounts?.find((account) => account.id === this.campaign.traffic_source_account_id);

      const campaignCompliance = getData(this.campaign.allow_compliance !== '0', this.campaign.revenue_streaming_sharing_compliance, this.campaign.compliance_revenue_stream_type);
      const accountCompliance = getData(account?.settings?.complianceProgram?.is_complianced !== false, account?.settings?.complianceProgram?.email, account?.settings?.complianceProgram?.type);

      if (campaignCompliance.isActive && accountCompliance.isActive !== false && campaignCompliance.email) {
        return campaignCompliance;
      }

      if (accountCompliance.isActive && accountCompliance.email) {
        return accountCompliance;
      }

      return getData();
    },
  },
};
</script>

<style lang="scss">
  .campaign-item-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
</style>
