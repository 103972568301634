<template>
  <div class="content-wrapper system-activity-container">

    <!-- Section Header -->
    <div v-if="!tab" class="section-header d-flex justify-content-between align-items-center">
      <div class="section-header__content d-flex">
        <h1 class="section-header__content__title">Logs</h1>
        <p class="section-header__content__subtitle"><a href="https://theoptimizer.io/help/knowledge-base/system-activity" target="_blank">Learn More <i class="fa fa-angle-right ml-1"></i></a></p>
      </div>
      <div v-if="!preloader" class="section-header__date-range">
        <date-range v-model="dateRangePicker" query></date-range>
      </div>
    </div>
    <div v-if="showUTCtime" class="section-subheader mb-3">
      All dates and times are reported in UTC timezone
      <span
        class="ml-2"
        v-b-tooltip.hover.right="{title:infoTitle, html:true}"
      >
        <OptimizerIcon class="rotate-180" type="infoDescription" />
      </span>
    </div>
    <loading-skeleton
      v-if="preloader"
      type="table"
      :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 3 }"
    />

    <div v-else class="optimizer-table system-activity-table">
      <vue-opti-table-light
        ref="systemActivityTable"
        @on-search="$_setSearchValue($event)"
        @on-row-per-page-change="$_setLimitValue($event)"
        @on-pagination="$_setPageValue($event)"
        @on-sort="$_setSortValue($event)"
        :sort="{ key: 'activityTimestamp', order: 'desc' }"
        :server-side-pagination="true"
        :pages="pages"
        :page="page"
        :selectable="true"
        select-label="Activities"
        :default-rows="limit"
        :header-fields="table.fields"
        :items="table.items"
        :resized-columns="resizedColumns"
        @resize="$_handleColumnsResize($event)"
        name="system-activity-table"
        search-class="system-activity-table-search mr-md-auto"
        v-model="tableModel"
        :export-label="$c_exportLabel"
        :export-csv-items="$_export"
        :save-settings="$_saveColumnDisplay"
        sticky
        focusSelectedRows
      >
        <template slot="search">
          <div class="d-flex flex-grow-1">
            <vue-opti-select-light
              class="optimizer-select filter-select ml-4 mr-3"
              :options="$c_filterOptions"
              unique-key="content"
              label-key="content"
              group-boundary
              :groups="filterGroups"
              button-type="static"
              :allowClear="!!$c_filterBadgeCounter"
              @clear="$_onFilterClear"
              v-model="filterData"
            >
              <template #BUTTON_PLACEHOLDER>
                <span class="button-placehoder-filter">
                  <i class="fa fa-filter"></i>
                  <span class="ml-8">Filters</span>
                  <b-badge v-show="$c_filterBadgeCounter" pill variant="info">{{ $c_filterBadgeCounter }}</b-badge>
                </span>
              </template>
            </vue-opti-select-light>

            <activity-filters
              filteredKey="campaigns"
              :filterOptions="$options.campaignOptions"
              label="Campaigns"
              iconToShow="inbox"
              @filterActivities="$_filterActivity($event)"
              @clear="$_clearCampaigns"
            />

            <activity-filters
              class="ml-2"
              filteredKey="rules"
              :filterOptions="$options.ruleOptions"
              label="Rules"
              :value="rules"
              iconToShow="gears"
              @filterActivities="$_filterActivity($event)"
              @clear="$_clearRules"
            />

            <b-btn
              :disabled="!tableModel.selectedRows.length"
              class="primary-button ml-2"
              @click="$_handleRollback"
            >
              Rollback Activities
            </b-btn>

            <loadizer :loading="apiLoading" />
          </div>

          <div class="refresh-button" v-b-tooltip title="Refresh Table">
            <b-btn class="secondary-button" :disabled="isRefreshLoading" @click="onRefreshClick">
              <i class="fa fa-refresh" :class="{ 'fa-spin': isRefreshLoading }" />
            </b-btn>
          </div>
        </template>
        <template #itemId="{ item }">
          <span v-if="!item.description.includes('Time condition was not met')" :title="item.itemId">{{ item.itemId }}</span>
          <span v-else class="d-block text-center">--</span>
        </template>
        <template #itemName="{ item }">
          <span v-if="!item.description.includes('Time condition was not met')" :title="item.itemName">{{ item.itemName }}</span>
          <span v-else class="d-block text-center">--</span>
        </template>
        <template #action="{ item }">
          <span v-if="item.action" :title="item.action">{{ $_action(item) }}</span>
        </template>
        <template #campaignName="{ item }">
          <div class="campaign-details" v-if="item.campaignId && !item.description.includes('Time condition was not met')">
            <img
              v-if="item.trafficSourceType"
              class="acc-item-icon"
              :src="getTrafficSourceLogo(item.trafficSourceType)"
              :title="item.accountName"
              v-b-tooltip.hover.left="{ customClass: 'custom-tooltip' }"
              alt="traffic-source-logo"
            />
            <span class="campaign-item-name" :title="item.campaignName">
              {{ item.campaignName }}
            </span>
          </div>
          <span v-else class="d-block text-center">--</span>
        </template>
        <template #description="{ item }">
          <div class="field activity-rule-field py-3">
            <span v-if="item.activityRule" class="activityRule">
              <i :id="`popover-activityId-${item.id}`">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" /></svg>
              </i>
              <b-popover custom-class="system-activity-popover" :target="`popover-activityId-${item.id}`" triggers="hover" placement="left">
                <template v-slot:title>Rule: {{ item.ruleName }}</template>
                <b-table v-if="!['rule_update', 'rule_create', 'rule_change_status'].includes(item.type) && !['Rule Change Status', 'Rule Create', 'Rule Update'].includes(item.type)" bordered :fields="[{ key: 'html', label: 'Rule Condition' }, { key: 'value', label: 'Value at execution time' }]" :items="item.activityRule">
                  <template v-slot:cell(html)="activityItem">
                    <span v-html="activityItem.item.html"></span>
                  </template>
                </b-table>
                <div v-else class="rule-update-content">
                  <p v-for="(activity, index) in item.activityRule" :key="index">
                    {{ index + 1 }}. {{ activity }}
                  </p>
                </div>
              </b-popover>
            </span>
            <template v-if="item.description.length < 50">
              <span :title="item.description">{{ $_formatText(item.description) }}</span>
            </template>
            <template v-else>
              <span v-b-tooltip="{title: item.description, triggers: 'hover', placement: 'left', boundary: 'window'}">
                {{ $_formatText(item.description) }}
              </span>

              <!-- {{ item.description.slice(0, 30) }}
              <b-badge class="view-more-badge" v-b-tooltip="{title: item.description, placement: 'left', boundary: 'window'}" variant="secondary">
                [ <i class="fa fa-info" aria-hidden="true"></i> <i class="fa fa-ellipsis-h" aria-hidden="true"></i> ]
              </b-badge>
              {{ item.description.slice(item.description.length - 30, item.description.length) }} -->
            </template>
          </div>
        </template>
      </vue-opti-table-light>
    </div>

    <RollbackModal ref="rollbackModal" :config="config" />
  </div>
</template>

<script>
import notifications from '@sh/mixins/notifications';
import data from './data';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import RollbackModal from './components/RollbackModal.vue';
import ActivityFilters from './components/ActivityFilters.vue';
import Loadizer from '@sh/components/Utils/Loadizer.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import ui from '@sh/helpers/ui';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { getTrafficSourceLogo } from '@sh/helpers';
import { dateFields } from '@sh/configurations/fields/nativeFields.ts';
import rulesConfig from '@/views/Automation/Rules/config';

const FORMAT = 'YYYY-MM-DD';

export default {
  name: 'SystemActivityBase',
  components: {
    LoadingSkeleton,
    Loadizer,
    RollbackModal,
    ActivityFilters,
    OptimizerIcon,
  },
  mixins: [notifications],
  props: {
    uri: { type: Boolean, default: false },
    campaignsId: { type: Array, default: () => [] },
    campaignsFilter: { type: Boolean, default: false },
    dateRange: { type: Object,
      default: () => ({
        startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT),
        endDate: moment().endOf('day').format(FORMAT) }),
    },
    configFile: { type: Object, required: true },
    tab: { type: Boolean, default: false },
    timezone: String,
    showUTCtime: Boolean,
  },
  data,
  computed: {
    $c_filterOptions() {
      return [
        { value: 'all', content: 'All Types', group: 'type' },
        ...this.config.filterConfig.typeOptions,
        { value: 'all', content: 'All Affected Items', group: 'affected' },
        ...this.config.filterConfig.affectedOptions,
        { value: 'all', content: 'All Statuses', group: 'status' },
        ...this.config.filterConfig.errorOptions,
        ...this.config.filterConfig.logOptions,
      ];
    },
    $c_filterBadgeCounter() {
      try {
        return this.filterData.filter((option) => option.value !== 'all').length;
      } catch (err) {
        return 0;
      }
    },
    // $c_logActionOptions() {
    //   return [
    //     { value: { value: 'rollback', fn: this.$_rollbackAction }, content: '<i style=\'color: black; padding-left: 10px;\' class=\'fa fa-undo\'></i> Rollback action' },
    //   ];
    // },
    $c_campaigns_id() {
      if (this.campaignsId.length) {
        return this.campaignsId.map((id) => id.toString());
      }
      return this.selectedCampaigns.map((item) => item.id.toString());
    },
    $c_query() {
      return { startDate: this.dateRangePicker.startDate, endDate: this.dateRangePicker.endDate, ...this.dataFilter };
    },
    $c_apiQuery() {
      return { campaignId: this.$c_campaigns_id.length ? this.$c_campaigns_id : undefined, ...this.$c_query };
    },
    $c_exportLabel() {
      return `SystemActivity_${this.dateRangePicker.startDate}_${this.dateRangePicker.endDate}`;
    },
  },
  watch: {
    filterData(value) {
      const [type, affectedItem, error, log] = value;
      this.dataFilter.type = type.value;
      this.dataFilter.affectedItem = affectedItem.value;
      this.dataFilter.error = error.value;
      this.dataFilter.log = log.value;
    },
    dateRangePicker() {
      this.page = 1;
      if (this.$refs.systemActivityTable) {
        this.$refs.systemActivityTable.currentPage = 1;
      }
    },
  },
  async created() {
    this.preloader = true;

    // Load Config & API data
    const [config, trafficSourceAccounts] = await Promise.all([this.configFile.getConfig(), this.$apiStore.trafficSources.accounts()]);
    this.config = config;
    this.$options.trafficSourceAccounts = trafficSourceAccounts;
    this.$options.trafficSourceAccountsMap = {};
    trafficSourceAccounts.forEach((account) => { this.$options.trafficSourceAccountsMap[account.id] = account; });

    // Get all campaigns for filter
    if (this.campaignsFilter) {
      this.allCampaigns = await this.config.api.getAllCampaigns();
    }

    /** ***************** Set Resized Columns from Local Storage **************** */
    this.resizedColumns = { type: 130, ...this.$settings.resizedColumns.getResizedColumns('systemActivity', this.tab ? 'Taboola' : 'table') };

    /** ************************************************************************* */

    this.debouncedGetActivityLogs = this.$_debounce(this.$_getActivityLogs, 500);

    if (this.uri) {
      const {
        startDate, endDate, type, affectedItem, error, log, search,
      } = this.$route.query;
      // Set DateRange
      if (startDate && endDate) this.dateRangePicker = { startDate, endDate };
      // Set Filter
      this.dataFilter = type ? { type, affectedItem, error, log } : this.dataFilter;
      this.filterData = [];
      this.$c_filterOptions.forEach((option) => {
        if (
          (option.group === 'type' && option.value === this.dataFilter.type)
          || (option.group === 'affected' && option.value === this.dataFilter.affectedItem)
          || (option.group === 'status' && option.value === this.dataFilter.error)
          || (option.group === 'log' && option.value === this.dataFilter.log)
        ) {
          this.filterData.push(option);
        }
      });
      if (search) this.search = search;
      if (this.$route.params.rule) {
        const { startDate, endDate, rule } = this.$route.params;
        this.rules = [rule];
        this.dateRangePicker = { startDate, endDate };
        this.$route.query.startDate = startDate;
        this.$route.query.endDate = endDate;
      }
      // Bind watch
      this.$watch('$c_query', () => {
        this.$_populateQuery();
      }, { deep: true });
      this.debouncedGetActivityLogs();
    } else {
      this.dateRangePicker = this.dateRange.startDate && this.dateRange.endDate ? { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate } : '';
      this.$watch('dateRange', () => {
        this.dateRangePicker.startDate = this.dateRange.startDate;
        this.dateRangePicker.endDate = this.dateRange.endDate;
      }, { deep: true });
      this.debouncedGetActivityLogs();
    }

    const [allCampaigns, allRules] = await Promise.all([this.$apiStore.campaigns.all(), await this.$apiStore.rules.get()]);
    this.$watch('$c_apiQuery', () => {
      this.debouncedGetActivityLogs();
    }, { deep: true });

    this.userRules = allRules;
    this.$options.campaignOptions = allCampaigns.filter((campaign) => campaign.name && campaign.enabled).map((item) => ({ value: item.id, content: item.name, tsName: item.traffic_source_unique_name }));
    this.$options.ruleOptions = allRules.filter((rule) => rule._id && rule.name).map((item) => ({ value: item._id, content: item.name }));
    // TEMPORARY FIX
    const user = this.$session.getUser();
    const isSubUser = user.roles.includes('ROLE_SUB_USER');
    if (isSubUser) {
      this.$options.campaignOptions = allCampaigns.filter((campaign) => user.allowed_accounts.includes(campaign.traffic_source_account_id) && campaign.name && campaign.enabled).map((item) => ({ value: item.id, content: item.name, tsName: item.traffic_source_unique_name }));
    }
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    $_formatText(action) {
      const words = action.split('_');
      const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

      const readableText = words.map(capitalizeFirstLetter).join(' ');
      return readableText;
    },
    $_action(item) {
      if (item.trafficSourceType && item.trafficSourceType !== '') {
        if (item.trafficSourceType === 'Facebook') {
          const replacementMapping = {
            Widget: 'Placement',
            Exchange: 'Geo',
            Domain: 'Device',
          };
          let action = item.action.replace(/\b(Widget|Exchange|Domain)\b/g, (match) => replacementMapping[match]);
          if (action.includes('pause_facebook') || action.includes('start_facebook')) {
            action = action.replace('facebook', item.level);
          }
          return this.$_formatText(action);
        }
        return this.$_formatText(item.action);
      }
      return this.$_formatText(item.action);
    },
    $_setSearchValue(event) {
      this.search = event.search;
      this.page = 1;
      this.debouncedGetActivityLogs();
    },
    $_setPageValue(event) {
      this.page = event.page;
      this.debouncedGetActivityLogs();
    },
    $_setLimitValue(event) {
      this.limit = event.limit;
      this.debouncedGetActivityLogs();
    },
    $_setSortValue(event) {
      this.sortField = event.sortField;
      this.sortType = event.sortType;
      this.debouncedGetActivityLogs();
    },
    $_populateQuery() {
      this.$router.replace({ query: { ...this.$route.query, ...this.$c_query } });
    },
    async $_getActivityLogs() {
      try {
        this.apiLoading = true;
        const { conditionsConfig } = await rulesConfig.getConfig();
        const fieldMap = conditionsConfig.fieldsMap;
        const query = { search: this.search, ...this.$c_apiQuery };
        const { items, total_pages } = await this.config.api.getActivityLogsWithPages(query, {
          page: this.page,
          limit: this.limit,
          sortField: this.sortField,
          sortType: this.sortType,
          campaingsIds: this.campaigns,
          rulesIds: this.rules,
          timezone: this.timezone,
        });

        if (JSON.parse(localStorage.getItem('SYSTEM_ACTIVITY_TABLE'))) { this.$_getSavedTableColumns(); }

        const tableItems = items || [];
        /** ********* Data Map *********** */
        // Set "activityRule"
        // Set "accountName"
        this.table.items = tableItems.map((item) => {
          const newItem = { ...item };
          // "accountName"
          if (this.$options.trafficSourceAccountsMap[newItem.sourceAccountId]) {
            newItem.accountName = this.$options.trafficSourceAccountsMap[newItem.sourceAccountId].name;
          } else {
            newItem.accountName = null;
          }
          // "activityRule"
          let activityRule = [];
          if (newItem.type === 'rule') {
            const translatedConditions = this.$_translateConditionsMapped(newItem);
            if (translatedConditions.length) {
              const ruleDetailsMap = this.$_getRuleDetails(newItem);
              const conditionKeys = JSON.parse(item.ruleConditions)?.map?.((item) => Object.keys(item ?? {})?.[0]);

              translatedConditions.forEach(({ key: baseKey, html, placeholder }, index) => {
                const key = conditionKeys[index] || baseKey;
                let value = typeof ruleDetailsMap[key] !== 'undefined' ? ruleDetailsMap[key] : '-';
                if (value !== '-') {
                  if (placeholder === '$') {
                    value = ui.currencyFormat(value);
                  } else if (placeholder === '%') {
                    value = ui.percentFormat(value);
                  } else if (Object.keys(dateFields).includes(key)) {
                    value = moment(value).format('YYYY-MM-DD HH:mm:ss');
                  } else if (key !== 'name' && key !== 'status') {
                    value = ui.numFormat(value);
                  }
                }
                activityRule.push({ html, value });
              });
            }
          } else if (['rule_update', 'rule_create', 'rule_change_status'].includes(newItem.type)) {
            const itemDetails = JSON.parse(newItem.itemDetails);
            const updatedItemDetails = itemDetails.map((item) => {
              const splitItem = item.split(' ');
              const updatedItem = splitItem.map((word) => {
                if (fieldMap[word]) {
                  return fieldMap[word].text;
                }
                return word;
              });

              return updatedItem.join(' ');
            });
            activityRule = updatedItemDetails;
          }
          if (activityRule.length) newItem.activityRule = activityRule;
          newItem.type = this.$_formatText(newItem.type);
          return newItem;
        });
        /** ****************************** */
        this.pages = total_pages;
        if (!this.table.items.length) {
          this.$n_infoNotification({ title: 'No activities for this filters!', message: 'Try to increase the date range for more data.', timeout: 5000 });
        }
        this.apiLoading = false;
      } catch (err) {
        this.$n_failNotification({ title: `${err} Please Refresh Page`, timeout: 10000 });
        this.apiLoading = false;
      }
    },
    // async $c_logAction(data) {
    //   if (this.tableModel.selectedRows.length > 0 || data.force) {
    //     // make sure that only completed actions that were triggered by rule execution are being reverted
    //     let failedSelected = false;
    //     this.tableModel.selectedRows.map((row) => {
    //       if (row.error === true) failedSelected = 'failed';
    //       else if (row.type !== 'rule') failedSelected = 'type';
    //     });
    //     if (failedSelected === false) data.fn(data.value);
    //     else if (failedSelected === 'failed') this.$n_failNotification({ title: 'You cannot revert failed actions' });
    //     else if (failedSelected === 'type') this.$n_failNotification({ title: 'You can only revert actions of type rule' });
    //     else this.$n_failNotification({ title: 'Cannot revert this action: ', failedSelected });
    //   } else {
    //     this.$n_failNotification({ title: 'Select at least 1 row' });
    //   }
    // },
    $_getClickhouseField(key) {
      const reverseToClickhouse = {
        ts_impressions: 'impressions',
        ts_spent: 'cost',
        totalBudget: 'budget',
        dailyBudget: 'daily_budget',
        bid: 'cpc',
        tr_profit: 'tr_net',
        ts_profit: 'ts_net',
        ts_ctr: 'ctr',
        tr_ctr: 'lp_cr',
        tr_cr: 'tr_cvr',
        ts_cr: 'ts_cvr',
        tr_cpc: 'avg_tr_cpc',
        ts_cpc: 'avg_cpc',
      };
      return reverseToClickhouse[key] || key;
    },
    $_handleColumnsResize(payload) {
      this.$settings.resizedColumns.setResizedColumns('systemActivity', this.tab ? 'Taboola' : 'table', payload);
    },
    $_translateConditionsMapped(item) {
      const conditions = JSON.parse(item.ruleConditions);
      const currentRule = this.userRules.find((rule) => rule._id === item.ruleId);
      return this.config.logConfig.ruleConditionsTranslator(conditions || [], null, true, currentRule);
    },
    $_getRuleDetails(activityItem) {
      const conditions = JSON.parse(activityItem.ruleConditions);
      const itemDetails = JSON.parse(activityItem.itemDetails);
      const details = {};
      conditions.forEach((value) => {
        const keys = Object.keys(value);
        const values = this.$_getValues(itemDetails, keys[0]);
        // eslint-disable-next-line prefer-destructuring
        if (values.length) details[keys[0]] = values[0];
      });
      return details;
    },
    $_getValues(obj, key) {
      let objects = [];
      for (const i in obj) {
        // eslint-disable-next-line no-prototype-builtins, no-continue
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] === 'object') {
          objects = objects.concat(this.$_getValues(obj[i], key));
        // eslint-disable-next-line eqeqeq
        } else if (i == key) {
          objects.push(obj[i]);
        }
      }
      return objects;
    },
    // async $_rollbackAction() {
    //   const activities = [];
    //   this.tableModel.selectedRows.forEach((row) => {
    //     activities.push(row);
    //   });
    //   const response = await this.$api.activityLogs.rollbackRuleActions(activities);
    //   this.$n_infoNotification({ title: response.message, timeout: 5000 });
    // },
    $_handleRollback() {
      this.rollbackActivities = this.tableModel.selectedRows;
      this.$refs.rollbackModal.showModal({ activities: this.rollbackActivities });
    },
    $_debounce(fn, delay) {
      let timer = null;
      return () => {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      };
    },
    $_export() {
      const columsMap = {};
      this.table.fields.forEach((col) => {
        columsMap[col.item.key] = col;
      });

      return this.table.items.map((row) => {
        const row2 = {};
        Object.entries(columsMap).forEach(([key, item]) => {
          const content = typeof item.item.content === 'function' ? item.item.content(row) : item.item.content || '';
          row2[key] = this.$_extractContent(content);
        });
        return row2;
      });
    },
    $_extractContent(s) {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    $_filterActivity({ options, keyToFilter }) {
      if (options.length) {
        this[keyToFilter] = options.map((option) => option.value);
      } else {
        this[keyToFilter] = [];
      }
      this.debouncedGetActivityLogs();
    },
    $_clearRules() {
      this.rules = [];
      this.debouncedGetActivityLogs();
    },
    $_clearCampaigns() {
      this.campaigns = [];
      this.debouncedGetActivityLogs();
    },
    $_saveColumnDisplay(fields) {
      const newFields = this.tableFieldsMap(fields);
      localStorage.setItem('SYSTEM_ACTIVITY_TABLE', JSON.stringify(newFields));
      this.$n_successNotification({ title: 'Settings saved successfully' });
    },
    $_getSavedTableColumns() {
      this.table.fields = JSON.parse(localStorage.getItem('SYSTEM_ACTIVITY_TABLE'));
      this.table.fields.forEach((field) => {
        if (field.header.content === 'Change') {
          field.item.cellClass = 'bg-info';
          field.item.content = (item) => this.config.logConfig.itemChanged(item);
        }
      });
    },
    $_onFilterClear() {
      this.filterData = cloneDeep(this.defaultFilterData);
      this.debouncedGetActivityLogs();
    },
    async onRefreshClick() {
      try {
        this.isRefreshLoading = true;
        await this.$_getActivityLogs();
        this.isRefreshLoading = false;
      } catch {
        this.isRefreshLoading = false;
      }
    },
    tableFieldsMap(fields) {
      return fields.map((field) => {
        const newField = this.table.fields.find((f) => f.item.key === field.item.key);
        return newField;
      });
    },
  },
};
</script>

<style lang="scss">
.system-activity-container {
  .section-header {
    margin-bottom: 1rem;
  }
  .optimizer-select {
    min-width: 6rem;
    padding: 0 !important;
    margin-right: 0.8rem;

    .dropdown {
      height: 100%;

      button {
        height: 100%;
      }
    }

    .btn.dropdown-toggle {
      color: $text-secondary !important;

      &::after {
        margin-left: -2rem;
      }

      .button-placehoder-filter {
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        width: -moz-available;
        width: -webkit-fill-available;

        .badge {
          top: -1.7rem;
          right: -5rem;
          background: $color-light-blue;
          padding: 0.4rem 0.7rem 0.3rem;
        }
      }

      .option-text {
        color: $text-secondary !important;
      }

      .clear-btn-wrapper {
        position: relative;
        top: unset !important;
        opacity: 1;
        min-width: 1.7rem;
        right: 2.25rem;
        background-color: $color-gray;

        &:hover {
          background-color: $color-light-gray;
        }

        i {
          margin-left: 0;
        }
      }
    }

    &.filter-select .option-content {
      &::after {
        color: $blue-300 !important;
        max-height: 1.8rem;
      }

    }

  }
}
  .system-activity-container {
    .system-activity-table-search {
      margin-right: unset !important;
    }
    .view-more-badge {
      cursor: pointer;
    }
    .modal-open .modal {
      top: 15%;
    }
    .modal-lg {
      max-width: 900px !important;
    }
    .v-select.searchable .dropdown-toggle {
      overflow-y: scroll;
      max-height: 135px;
      max-width: 300px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .optimizer-table {
    .table-holder {
        border: $border;

        .field {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 .5rem;

          .activity-rule-field {
            align-items: center;
            vertical-align: middle;

            .activityRule {
              i {
                margin-right: .5rem;
                svg {
                  width: 1.7rem;
                }
              }
            }
          }
        }
        .campaign-details {
          display: flex;

          .acc-item-icon {
            width: 2.4rem;
            margin-right: .7rem;
          }

          .campaign-item-name {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .filter-select {
      .dropdown-menu {
        width: 40rem !important;

        .options-list .dropdown-item .option-content span {
          @include text-ellipsis(40)
        }
      }
    }
    .rotate-180 {
      transform: rotate(180deg) !important;
    }
  }

  .system-activity-popover {
    min-width: 40rem;
    max-width: unset;

    .popover-header {
      text-align: center;
      background: #F0F6F9;
      color: $text-primary;
    }

    .popover-body {
      padding: 2rem;

      table {
        margin-bottom: 0;

        thead {
          border-bottom: .1rem solid $color-light;

          th {
            text-align: center;
            white-space: nowrap;
          }
        }

        tbody {
          td {
            text-align: center;
            max-width: 50rem;
          }
        }
      }

      .rule-update-content {
        p {
          font-size: 1.3rem;
          color: $text-primary;
        }
      }
    }
  }
@media (max-width: 767.98px) {
  .system-activity-container {
    .optimizer-table {
      > .datatable-wrapper {
        > .header {
          >*:not(.datatable-search-wrapper) {
            width: 100%;
            margin: 0.5rem 1.5rem !important;
            padding: 0 !important;
            height: 4rem;
          }
        }
      }
    }
  }
}
</style>
