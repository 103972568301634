<template>
  <div class="switch-button">
    <div v-if="$c_loading" class="busy-overlay" />
    <div :class="[{ 'busy': $c_loading }, 'switch-button__input-wrapper']" :title="$c_title">
      <b-form-checkbox
        class="optimizer-switch"
        switch
        :id="id"
        :size="size"
        v-model="localModel"
        :unchecked-value="disabledValue"
        :value="enabledValue"
        :disabled="$c_disabled"
        @change="$_change"
        inline
      />
    </div>
  </div>
</template>

<script>
/**
 * Usage mode:
 * 1) v-model ( No async/await support )
 * 2) update() + :checked ( async/await, await update() & set from :checked )
 */
export default {
  name: 'SwitchButton',
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    disabledValue: { type: [String, Number, Boolean], default: false },
    enabledValue: { type: [String, Number, Boolean], default: true },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }, // use with caution
    size: { type: String, default: 'lg' },
    update: { type: [Function], default: null },
    hoverTitle: { type: [Object, String], default: null },
    checked: { type: [String, Number, Boolean] },
    hideLoader: { type: Boolean, default: false },
    id: String,
  },
  data() {
    return {
      localModel: null,
      busy: false,
    };
  },
  computed: {
    $c_title() {
      if (!this.hoverTitle) return null;
      if (typeof this.hoverTitle === 'string') return this.hoverTitle;
      if (this.localModel === this.enabledValue) return this.hoverTitle.enabled;
      if (this.localModel === this.disabledValue) return this.hoverTitle.disabled;
      return null;
    },
    $c_loading() {
      return this.loading || (!this.hideLoader && this.busy);
    },
    $c_disabled() {
      return this.disabled || this.$c_loading;
    },
  },
  created() {
    // Set v-model value
    if (typeof this.checked === 'undefined') {
      this.localModel = this.disabledValue;
    } else {
      this.localModel = this.checked;
    }

    // Watch v-model change
    this.$watch('checked', (value) => {
      if (value !== this.localModel) this.localModel = value;
    });
  },
  methods: {
    async $_change(value) {
      if (typeof this.update === 'function') {
        this.busy = true;
        try {
          await this.update(value);
        } catch (err) { /* Do nothing */ }
        // Set to :checked props
        this.localModel = this.checked;
        this.busy = false;
      } else {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss">
.switch-button {
  position: relative;

  .busy-overlay {
    position: absolute;
    width: 90%;
    background-image: repeating-linear-gradient(-45deg, rgba(80, 169, 224, 0.25), rgba(80, 169, 224, 0.25) 11px, rgba(255, 255, 255, 0) 10px, rgba(255, 255, 255, 0) 20px);
    animation: move 1s linear infinite;
    position: absolute;
    height: 100%;
    z-index: 9999;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }
}
</style>
