import ui from '@sh/helpers/ui';
import _object from 'lodash/fp/object';

const formatContent = (number, formatFn) => {
  if (isNaN(number)) return '';
  return formatFn();
};

const fields = {
  Thrive: {
    status: {
      header: { content: 'Status' },
      item: {
        key: 'status',
        content: (item) => `<span style="color: ${item.status === 'Active' ? ui.colors.green : ui.colors.orange}">${item.status}</span>`,
      },
    },
  },
  default: {
    name: {
      header: { content: 'Name' },
      item: {
        key: 'name',
        class: 'text-left',
        total: {
          content: () => 'Total/Wtd Avg',
        },
      },
    },
    status: {
      header: { content: 'Status' },
      item: {
        key: 'status',
        content: (item) => `<span style="color: ${item.status === 'Active' ? ui.colors.green : ui.colors.orange}">${item.status}</span>`,
      },
    },
    network: {
      header: { content: 'Network' },
      item: {
        key: 'network',
        content: (item) => (item.network ? item.network.name || '' : ''),
      },
    },
    payout: {
      header: { content: 'Value/Offer' },
      item: {
        key: 'payout',
        content: (item) => formatContent(item.payout, () => ui.currencyFormat(item.payout)),
        total: {
          content: (totals) => ui.currencyFormat(totals.payout),
        },
      },
    },
    weight: {
      header: { content: 'Weight' },
      item: {
        key: 'weight',
        class: 'weight',
        total: {
          content: (totals) => ui.percentFormat(totals.percent),
        },
      },
    },
    clicks: {
      header: { content: 'Clicks' },
      item: {
        key: 'clicks',
        total: {
          content: (totals) => totals.clicks,
        },
      },
    },
    conversions: {
      header: { content: 'Conv' },
      item: {
        key: 'conversions',
        total: {
          content: (totals) => totals.conversions,
        },
      },
    },
    cr: {
      header: { content: 'CVR' },
      item: {
        key: 'cr',
        content: (item) => formatContent(item.cr, () => ui.percentFormat(item.cr)),
        total: {
          content: (totals) => ui.percentFormat(totals.cr),
        },
      },
    },
    revenue: {
      header: { content: 'Rev' },
      item: {
        key: 'revenue',
        content: (item) => formatContent(item.revenue, () => ui.currencyFormat(item.revenue)),
        total: {
          content: (totals) => ui.currencyFormat(totals.revenue),
        },
      },
    },
    epc: {
      header: { content: 'EPC' },
      item: {
        key: 'epc',
        content: (item) => formatContent(item.epc, () => ui.currencyFormat(item.epc)),
        total: {
          content: (totals) => ui.currencyFormat(totals.epc),
        },
      },
    },
    lp_epc: {
      header: { content: 'LP EPC' },
      item: {
        key: 'lp_epc',
        content: (item) => formatContent(item.lp_epc, () => ui.currencyFormat(item.lp_epc)),
        total: {
          content: (totals) => ui.currencyFormat(totals.lp_epc),
        },
      },
    },
    lp_clicks: {
      header: { content: 'LP Clicks' },
      item: {
        key: 'lp_clicks',
        total: {
          content: (totals) => totals.lp_clicks,
        },
      },
    },
    lp_ctr: {
      header: { content: 'LP CTR' },
      item: {
        key: 'lp_ctr',
        content: (item) => formatContent(item.lp_ctr, () => ui.percentFormat(item.lp_ctr)),
        total: {
          content: (totals) => ui.percentFormat(totals.lp_ctr),
        },
      },
    },
    lp_cr: {
      header: { content: 'LP CVR' },
      item: {
        key: 'lp_cr',
        content: (item) => formatContent(item.lp_cr, () => ui.percentFormat(item.lp_cr)),
        total: {
          content: (totals) => ui.percentFormat(totals.lp_cr),
        },
      },
    },
  },
};

export default {
  /**
   *
   * @param {string} uniqueName - uniqueName represents Thrive, Voluum, etc..
   * @param {Object} key - Field identifier, if not found customised field, the default field will be used
   * @param {Object} data - Merge data
   */
  getField(uniqueName, key, data = {}) {
    let field = null;
    if (fields[uniqueName] && fields[uniqueName][key]) {
      field = fields[uniqueName][key];
    } else if (fields.default[key]) {
      field = fields.default[key];
    } else {
      throw new Error(`${key} field not found!`);
    }
    field = _object.merge(field, data);
    return field;
  },
};
