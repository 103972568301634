import { api, apiStore } from '@sh/services/api';

const backendApi = {
  trafficSources: {},
  campaigns: {},
  reporting: {},
  activityLogs: {
    getScheduler() {
      return api.activityLogs.getScheduler();
    },
    updateScheduler(payload) {
      return api.activityLogs.updateScheduler(payload);
    },
  },
};

backendApi.trafficSources = {
  types() {
    return apiStore.trafficSources.types();
  },
  account(id) {
    return api.trafficSources.account(id);
  },
  accounts() {
    return api.trafficSources.accounts();
  },
};

backendApi.campaigns = {
  all() {
    return api.campaigns.all();
  },
};

backendApi.manualStatsUpdate = {
  submit(trafficSourceAccountIds, startDate, endDate) {
    return api.manualStatsUpdate.submitForm(trafficSourceAccountIds, startDate, endDate);
  },
};

export default backendApi;
