
import TagsContainer from '@sh/components/MediaManager/TagsContainer/TagsContainer.ts.vue';
import { EmptyTableCell, MediaManagerHelpers } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import { defineComponent } from 'vue';

interface ComponentData {
  params?: MediaManager.TableTagsContainer;
  tags: string[];
  showTags: string[];
}

export default defineComponent({
  components: {
    TagsContainer,
  },
  data(): ComponentData {
    return {
      params: undefined,
      tags: [],
      showTags: [],
    };
  },
  computed: {
    EmptyTableCell() {
      return EmptyTableCell;
    },
  },
  beforeMount() {
    // this.params contains the cell and row information and is made available to this component at creation time
    // see ICellRendererParams for more details
    if (this.params) {
      this.showTags = MediaManagerHelpers.getTagsToDisplay(this.params.tags);
      this.tags = this.params.tags;
    }
  },
});
