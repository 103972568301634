const config = {
  Thrive: {
    credentials: {
      apiToken: '',
      apiSecret: '',
      apiVar: '',
      url: '',
      currency: 'USD',
    },
  },
  Voluum: {
    credentials: {
      accessId: '',
      accessKey: '',
      conversionTimeMode: '',
      currency: 'USD',
    },
  },
  Bemob: {
    credentials: { accessId: '', accessKey: '', currency: 'USD' },
  },
  AdsBridge: {
    credentials: { userId: '', apiToken: '', currency: 'USD' },
  },
  Binom: {
    credentials: {
      apiToken: '', url: '', index_php_path: '', arm_php_path: '', secretKey2fa: '', currency: 'USD',
    },
  },
  FunnelFlux: {
    credentials: { apiToken: '', url: '', currency: 'USD' },
  },
  GoogleAnalytics4: {
    credentials: { currency: 'USD', api_key: '' },
  },
  GoogleAnalytics: {
    credentials: { currency: 'USD', api_key: '' },
  },
  RedTrack: {
    credentials: { apiToken: '', plan: 'Basic', currency: 'USD', revenueField: undefined },
  },
  Kintura: {
    credentials: { apiToken: '', apiSecret: '', url: '', currency: 'USD' },
  },
  Everflow: {
    credentials: { apiToken: '', accountType: 0, currency: 'USD' },
  },
  CloudThrive: {
    credentials: { apiKey: '', installId: '', currency: 'USD' },
  },
  FunnelFluxPro: {
    credentials: { apiToken: '', currency: 'USD' },
  },
  Tonic: {
    credentials: { apiToken: '', apiSecret: '', currency: 'USD' },
  },
  Sedo: {
    credentials: {
      username: '', password: '', signkey: '', partnerid: '', currency: 'USD',
    },
  },
  DomainActive: {
    credentials: { apiKey: '', currency: 'USD' },
  },
  System1: {
    credentials: { apiToken: '', currency: 'USD' },
  },
  AssertiveYield: {
    credentials: { email: '', password: '', currency: 'USD' },
  },
  CPVLab: {
    credentials: { apiToken: '', currency: 'USD' },
  },
  ClickFlare: {
    credentials: {
      apiToken: '',
      conversionTimeMode: '',
      currency: 'USD',
    },
  },
  Bodis: {
    credentials: { apiToken: '', currency: 'USD' },
  },
  Ads: {
    credentials: {
      username: '',
      password: '',
      secretKey: '',
      currency: 'USD',
    },
  },
  Media: {
    credentials: {
      email: '',
      password: '',
      currency: 'USD',
    },
  },
  BinomV2: {
    credentials: {
      apiToken: '', url: 'https://yourtrackingurl.com', currency: 'USD',
    },
  },
  CSVSource: {
    credentials: {
      provider: 'GOOGLESHEETS',
      url: '',
      spreadsheet: {
        url: '',
        id: '',
        gname: '',
      },
      headers: [],
      currency: 'USD',
    },
  },
  default: {
    credentials: {
      currency: 'USD',
    },
  },
};

export default config;
