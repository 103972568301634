import { apiStore } from '@sh/services/api';
import { RuleTypes } from '@sh/types';
import { getFieldTextAndDescription } from '@sh/configurations/fields/parser';

const targetCpaActionOptions = [
  { value: 'set', text: 'Set Target CPA To' },
  { value: 'increase', text: 'Increase Target CPA By' },
  { value: 'decrease', text: 'Decrease Target CPA By' },
];

const infoText = {
  payout:
    'If you have not entered a payout manually for a given campaign, then the payout will be calculated as Payout = Revenue/Conversions.',
  ts_cpa:
    'Average cost per action (CPA) is calculated by dividing the total cost of conversions by the total number of conversions.',
};

const targetCpaUnitOptions = [
  { value: 'static', text: '$' },
  { value: 'percentage', text: '%' },
];

const targetCpaToOptions = [
  {
    value: 'target_cpa',
    text: 'of Current Target CPA',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'ts_cpa',
    text: 'of CPA',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
  {
    value: 'payout',
    text: 'of Payout',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
  },
];

const targetCpaModelTemplates = {
  classic: {
    action: {
      set: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              payout: { info: infoText.payout },
              ts_cpa: { info: infoText.cpa },
            },
          },
        },
      },
      increase: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              payout: { info: infoText.payout },
              ts_cpa: { info: infoText.cpa },
            },
          },
        },
      },
      decrease: {
        unit: {
          static: {},
          percentage: {
            to: {
              target_cpa: {},
              payout: { info: infoText.payout },
              ts_cpa: { info: infoText.cpa },
            },
          },
        },
      },
    },
  },
};

const targetCpaToOptionsCM = [];
const loadCmOptions = async () => {
  if (!targetCpaToOptionsCM.length) {
    const response = await apiStore.configuration.cmFields();
    if (response && response.fields) {
      response.fields.forEach((cmItem) => {
        if (cmItem.customMetric) {
          targetCpaToOptionsCM.push({
            value: cmItem.item.key,
            text: `of ${getFieldTextAndDescription(cmItem).text || cmItem.header.content}`,
            baseType: RuleTypes.Conditions.BaseFieldType.Metric,
            type: RuleTypes.Conditions.ValueType.Number,
          });
        }
      });
    }
  }
};

export default {
  async getData() {
    const targetCpaModelTemplatesClone = JSON.parse(JSON.stringify(targetCpaModelTemplates));
    const targetCpaToOptionsClone = JSON.parse(JSON.stringify(targetCpaToOptions));

    try {
      await loadCmOptions();
      if (targetCpaToOptionsCM.length) {
        targetCpaToOptionsClone.push(...targetCpaToOptionsCM);
        targetCpaToOptionsCM.forEach((cmItem) => {
          targetCpaModelTemplatesClone.classic.action.set.unit.percentage.to[cmItem.value] = {};
          targetCpaModelTemplatesClone.classic.action.increase.unit.percentage.to[cmItem.value] = {};
          targetCpaModelTemplatesClone.classic.action.decrease.unit.percentage.to[cmItem.value] = {};
        });
      }
    } catch (error) {
      throw Error('Fail loading dynamics fields custom-metrics');
    }
    return {
      targetCpaActionOptions,
      targetCpaUnitOptions,
      targetCpaToOptions: targetCpaToOptionsClone,
      targetCpaModelTemplates: targetCpaModelTemplatesClone,
    };
  },
};
