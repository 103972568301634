<template>
  <div>
    <div class="calendar-both"></div>
    <date-range-picker
      ref="dateRange"
      :class="[$c_customRangeSelected ? 'optimizer-date-range disable-calendars' : 'optimizer-date-range']"
      :opens="opens"
      :locale-data="{
        firstDay: 1,
        format: 'mmm d, yyyy',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      }"
      :min-date="minDate"
      :max-date="maxDate"
      :single-date-picker="singleDatePicker"
      :time-picker="timePicker"
      :time-picker24hour="true"
      :show-week-numbers="false"
      :show-dropdowns="false"
      :auto-apply="singleDatePicker || autoApply"
      :ranges="singleDatePicker ? {} : ranges"
      date-util="moment"
      :disabled="disabled"
      v-model="dateRange"
      @update="$_updateValues"
      :linked-calendars="true"
      :dateFormat="dateFormatFn"
      @finish-selection="finishSelection"
      @start-selection="startedSelection"
    >
      <template v-slot:input style="min-width: 350px">
        <b-icon icon="calendar" v-if="!compareRanges.startDate" /> <span v-html="$c_inputValue" />
      </template>

      <div slot="header" :class="[enableCompareDates ? 'header-container' : 'header-container disabled-header']">
        <div class="dropdown-inputs-container">
          <div class="compare-dropdown" v-if="enableCompareDates">
            <span class="d-flex align-items-center">
              <i class="fa fa-square mr-4" style="color: #3578e5" aria-hidden="true"></i>
              <p>{{ currentRange }}</p>
              <i id="custom-range-tooltip" class="fa fa-info-circle ml-1" v-b-tooltip.hover :title="$c_tooltipBaseOnRange" aria-hidden="true"></i>
            </span>
            <span class="d-flex align-items-center">
              <i class="fa fa-square mr-4" style="color: #62c68b" aria-hidden="true"></i>
              <vue-opti-select-light
                ref="compareSelect"
                class="optimizer-select"
                :options="options.items"
                :uniqueKey="(option) => option.value"
                single
                emitOnClick
                :selectFirst="true"
                button-block
                optionType="checkbox"
                :groups="options.groups"
                v-model="selected"
                @input="$_selectCompareRange"
              />
            </span>
          </div>
          <div class="compare-inputs" v-if="enableCompareDates">
            <span class="current-inputs">
              <b-form-input
                ref="currentStartDate"
                class="optimizer-form-input"
                :value="dateRange.startDate"
                :state="validDates.startDate"
                @keyup.enter="updateInputs($event.target.value, 'current', 'startDate')"
                @focusout="updateInputs($event.target.value, 'current', 'startDate')"
                @focus="focusInput('currentStartDate')"
              />
              <p>-</p>
              <b-form-input
                ref="currentEndDate"
                class="optimizer-form-input"
                :value="dateRange.endDate"
                :state="validDates.endDate"
                @keyup.enter="updateInputs($event.target.value, 'current', 'endDate')"
                @focusout="updateInputs($event.target.value, 'current', 'endDate')"
                @focus="focusInput('currentEndDate')"
              />
            </span>
            <span class="past-inputs">
              <b-form-input
                ref="previousStartDate"
                class="optimizer-form-input"
                :value="compareRanges.startDate"
                :state="validDates.startDatePrevious"
                @keyup.enter="updateInputs($event.target.value, 'previous', 'startDate')"
                @focusout="updateInputs($event.target.value, 'previous', 'startDate')"
                @focus="focusInput('previousStartDate')"
              />
              <p>-</p>
              <b-form-input
                ref="previousEndDate"
                class="optimizer-form-input"
                :value="compareRanges.endDate"
                :state="validDates.endDatePrevious"
                @keyup.enter="updateInputs($event.target.value, 'previous', 'endDate')"
                @focusout="updateInputs($event.target.value, 'previous', 'endDate')"
                @focus="focusInput('previousEndDate')"
              />
            </span>
          </div>
        </div>
        <div v-if="hasComparison" class="compare-switch mt-1">
          <p class="mt-1">
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              v-b-tooltip.hover
              :title="`This option allows comparing performance between 2 selected interval. You can adjust which columns will be compared from the Column Settings view. Supported date format: 'YYYY-MM-DD'`"
            ></i>
            Compare dates
          </p>
          <switch-button
            class="min-max-switch ml-2 mb-1"
            :checked="enableCompareDates"
            :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
            :update="(value) => $_switchToggle(value)"
          />
        </div>
      </div>

      <template #ranges="{ ranges: presets }">
        <div class="ranges">
          <ul>
            <li v-for="(range, label) in presets" :key="label" @click="$_handleRangeSelect(label, range)">
              {{ label }}
            </li>
          </ul>
        </div>
      </template>

      <div slot="footer" slot-scope="data" class="date-range-footer">
        <div class="buttons">
          <p>{{ data.rangeText }}</p>
          <button @click="data.clickCancel" class="secondary-button">Cancel</button>
          <button @click="applyChanges(data)" :disabled="$c_hasInvalidDate" class="primary-button">Apply</button>
        </div>
      </div>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import { getDateRangePresetsInstance } from './DateRangePresets';

/* eslint-disable */
import moment from 'moment';

export default {
  name: 'DateRange',
  components: { DateRangePicker },
  props: {
    query: { type: Boolean, default: true },
    opens: { type: String, default: 'left' },
    minDate: { type: [String, Date, null], default: null },
    maxDate: { type: [String, Date, null], default: null },
    autoApply: { type: Boolean, default: false },
    singleDatePicker: { type: Boolean, default: false },
    timePicker: { type: Boolean, default: false },
    autoEmit: { type: Boolean, default: false },
    value: { type: [Object, String, null], default: null },
    disabled: { type: Boolean, default: false },
    hasComparison: { type: Boolean, default: false },
  },
  data() {
    return {
      dateRange: { startDate: '', endDate: '' },
      ranges: {},
      FORMAT: 'YYYY-MM-DD',
      defaultRange: {},
      updateRangeIntervalId: 0,
      enableCompareDates: false,
      selected: [],
      options: {
        items: [
          {
            value: 'previous_period',
            content: 'Previous period',
            inputType: 'checkbox',
            inputName: 'previous_period',
            group: 'Period',
          },
          {
            value: 'previous_month',
            content: 'Previous month',
            inputType: 'checkbox',
            inputName: 'previous_month',
            group: 'Period',
          },
          { value: 'previous_year', content: 'Previous year', inputType: 'checkbox', inputName: 'previous_year', group: 'Period' },
          { value: 'custom', content: 'Custom', inputType: 'checkbox', inputName: 'custom', group: 'Period' },
        ],
        groups: [{ value: 'Period', content: 'Period' }],
      },
      currentRange: 'Custom',
      compareRanges: {
        startDate: '',
        endDate: '',
      },
      firstDateSelected: '',
      validDates: {
        startDate: '',
        endDate: '',
        startDatePrevious: '',
        endDatePrevious: '',
      },
    };
  },
  computed: {
    $c_inputValue() {
      const FORMAT = this.timePicker ? 'MMM, D YYYY HH:mm' : 'MMM, D YYYY';

      if (this.enableCompareDates) {
        const dateText = `<div class="first-date">${moment(this.dateRange.startDate).format('MMM, DD YYYY')} - ${moment(
          this.dateRange.endDate
        ).format('MMM, DD YYYY')}</div><br><div class="versus-text">vs. ( ${moment(this.compareRanges.startDate).format(
          'MMM, DD YYYY'
        )} - ${moment(this.compareRanges.endDate).format('MMM, DD YYYY')} )</div>`;
        return dateText;
      }
      if (this.singleDatePicker) {
        return `${moment(this.dateRange.startDate).format(FORMAT)}`;
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        let dateText = '';
        for (let key in this.ranges) {
          if (
            moment(this.ranges[key][0]).format(this.FORMAT) === this.dateRange.startDate &&
            moment(this.ranges[key][1]).format(this.FORMAT) === this.dateRange.endDate
          ) {
            if (key === 'Today') {
              dateText = `Today <small>( ${moment(this.ranges[key][0]).format(FORMAT)} )</small>`;
            } else if (key === 'Yesterday') {
              dateText = `Yesterday <small>( ${moment(this.ranges[key][0]).format(FORMAT)} )</small>`;
            } else {
              dateText = key;
            }
            break;
          }
        }
        return dateText.length > 0
          ? dateText
          : `${moment(this.dateRange.startDate).format(FORMAT)} - ${moment(this.dateRange.endDate).format(FORMAT)}`;
      }
      return '';
    },
    $c_hasInvalidDate() {
      return Object.values(this.validDates).some((value) => value === false);
    },
    $c_customRangeSelected() {
      if (!this.enableCompareDates) return false;
      return this.selected.value === 'custom';
    },
    $c_tooltipBaseOnRange() {
      switch(this.selected.value) {
        case 'previous_year': 
          return 'This option enables performance comparison between the current date range and the date range from the previous year.';
        case 'previous_month':
          return 'This option enables performance comparison between the current date range and the date range from the previous month.';
        case 'previous_period':
          return "This option enables performance comparison between the current date range and the previous period's date range";
        case 'custom':
          return 'Use this option to manually enter two time intervals for performance comparison. Supported date format: "YYYY-MM-DD".';
      }
    },
  },
  created() {
    if (this.timePicker) {
      this.FORMAT = 'YYYY-MM-DD HH:mm';
    }

    this.defaultRange = {
      startDate: moment(new Date()).format(this.FORMAT),
      endDate: moment(new Date()).endOf('day').format(this.FORMAT),
    };
    this.$_updateRangeInterval();

    /* ************* Set DateRange ************** */
    let dateRange = null;
    try {
      if (this.query) {
        const { startDate, endDate, comparisonStartDate, comparisonEndDate, period } = this.$route.query;
        if (comparisonStartDate && comparisonEndDate) {
          this.enableCompareDates = true;
          this.compareRanges = { startDate: comparisonStartDate, endDate: comparisonEndDate };
        }
        if (startDate && endDate) {
          dateRange = { startDate, endDate };
          if (this.enableCompareDates && period === 'previous_period') {
            this.$_calculatePreviousRange(startDate, endDate);
          }
        }
        if (period) {
          this.selected = this.options.items.find((item) => item.value === period);
        }
      } else {
        // Set DateRange from v-model
        dateRange = { startDate: this.value.startDate, endDate: this.value.endDate };
      }
    } catch (err) {
      // Set DateRange from $route URI
      if (this.query) {
        const { startDate, endDate } = this.$route.query;
        if (startDate && endDate) dateRange = { startDate, endDate };
      }
    }
    // If neither set Default DateRange
    if (!dateRange) {
      dateRange = { ...this.defaultRange };
    }

    this.dateRange = { ...dateRange };
    /* ************* ************* ************** */

    // Sync $route URI
    if (this.enableCompareDates) {
      this.$_updateQuery(this.compareRanges);
    } else {
      this.$_updateQuery(this.dateRange);
    }

    // Auto emit
    if (this.autoEmit) this.$_emit();
  },
  destroyed() {
    clearInterval(this.updateRangeIntervalId);
  },
  methods: {
    $_updateRangeInterval() {
      this.ranges = getDateRangePresetsInstance().getDateRangesToDate();
      this.updateRangeIntervalId = setInterval(() => {
        this.ranges = getDateRangePresetsInstance().initRanges();
      }, 60000);
    },
    $_updateValues({ startDate, endDate }) {
      const start = moment(startDate).format(this.FORMAT);
      const end = moment(endDate).format(this.FORMAT);
      this.dateRange = { startDate: start, endDate: end };

      if (this.enableCompareDates && this.selected.value === 'previous_period') {
        this.$_calculatePreviousRange(start, end);
      } 
      if (this.enableCompareDates) {
        this.$_updateQuery(this.compareRanges);
      } else {
        this.$_updateQuery(this.dateRange);
      }
      this.$_emit();
      this.$emit('change', this.dateRange);
    },
    $_emit() {
      this.$emit('input', this.dateRange);
      this.$emit('update:label', this.$c_inputValue);
    },
    $_updateQuery(range) {
      if (this.query) {
        const { startDate: qStartDate, endDate: qEndDate } = this.$route.query;

        if (!this.enableCompareDates) {
          // if compare dates is disabled we only update query for current date range
          if (qStartDate !== range.startDate || qEndDate !== range.endDate) {
            this.$router.replace({
              query: { ...this.$route.query, startDate: range.startDate, endDate: range.endDate },
            });
          }
        } else {
          this.$router.replace({ query: { ...this.$route.query, period: this.selected.value } });
          // if compare dates is enabled we update query for both current and compare date ranges
          const { startDate: currentStartDate, endDate: currentEndDate } = this.dateRange;
          const { startDate: comparisonStartDate, endDate: comparisonEndDate } = range;

          if (qStartDate !== currentStartDate || qEndDate !== currentEndDate) {
            this.$router.replace({
              query: { ...this.$route.query, startDate: currentStartDate, endDate: currentEndDate },
            });
          }
          if (comparisonStartDate && comparisonEndDate) {
            this.$router.replace({ query: { ...this.$route.query, comparisonStartDate, comparisonEndDate } });
          }
        }

        if (!this.enableCompareDates) {
          this.compareRanges = { startDate: '', endDate: '' };
          this.$router.replace({ query: { ...this.$route.query, comparisonStartDate: '', comparisonEndDate: '', period: '' } });
        }
      }
    },
    $_handleRangeSelect(label) {
      this.currentRange = label;
      this.ranges = getDateRangePresetsInstance().initRanges();
      const { startDate, endDate } = this.$_getRange(label);
      if (this.enableCompareDates) {
        // if compare dates is enabled we only update v-model without sending any requests
        this.$_updateCompareValues({ startDate, endDate });
        this.selected = this.options.items.find((item) => item.value === 'previous_period');
      } else {
        this.$_updateValues({ startDate, endDate });
        this.$refs.dateRange.togglePicker();
      }
    },
    $_getRange(label) {
      return { startDate: this.ranges[label][0], endDate: this.ranges[label][1] };
    },
    updateInputs(e, period, time) {
      this.selected = this.options.items.find((item) => item.value === 'custom');
      const validDate = this.isDateValid(e);
      if (period === 'current') {
        if (validDate) {
          this.dateRange[time] = moment(e).format(this.FORMAT);
          this.validDates[time] = true;
        } else {
          this.validDates[time] = false;
        }
      } else {
        if (validDate) {
          this.compareRanges[time] = moment(e).format(this.FORMAT);
          this.validDates[`${time}Previous`] = true;
        } else {
          this.validDates[`${time}Previous`] = false;
        }
      }
    },
    applyChanges(data) {
      data.clickApply();
    },
    // compare methods
    startedSelection(startDate) {
      this.firstDateSelected = startDate;
    },
    finishSelection(value) {
      const firstDateFormatted = moment(this.firstDateSelected);
      const lastDateFormatted = moment(value);

      const startDate = firstDateFormatted.isAfter(lastDateFormatted) ? lastDateFormatted : firstDateFormatted;
      const endDate = firstDateFormatted.isAfter(lastDateFormatted) ? firstDateFormatted : lastDateFormatted;

      this.dateRange.startDate = startDate.format(this.FORMAT);
      this.dateRange.endDate = endDate.format(this.FORMAT);
      this.compareRanges.startDate = startDate.format(this.FORMAT);
      this.compareRanges.endDate = endDate.format(this.FORMAT);
      const diffDays = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days');
      this.currentRange = `Last ${diffDays + 1} days`;
    },
    $_updateCompareValues({ startDate, endDate }) {
      const start = moment(startDate).format(this.FORMAT);
      const end = moment(endDate).format(this.FORMAT);
      this.dateRange = { startDate: start, endDate: end };
      this.$_calculatePreviousRange(start, end);
    },
    $_calculatePreviousRange(startDate, endDate) {
      const startDay = moment(startDate, this.FORMAT);
      const endDay = moment(endDate, this.FORMAT);
      const dif = Math.abs(startDay.diff(endDay, 'days')) + 1;
      const previousStartDate = moment(startDate).subtract(dif, 'days').format(this.FORMAT);
      const previousEndDate = moment(endDate).subtract(dif, 'days').format(this.FORMAT);
      this.compareRanges.startDate = previousStartDate;
      this.compareRanges.endDate = previousEndDate;
    },
    $_selectCompareRange(value) {
      this.$refs.compareSelect?.hide();
      if (value.value === 'custom') {
        this.focusInput('previousStartDate');
        this.$root.$emit('bv::show::tooltip', 'custom-range-tooltip');
      }
      this.selected = value;
      this.$_updateCompareValues(this.dateRange);
    },
    calculateDatesBaseOnPeriod() {
      switch (this.selected.value) {
        case 'previous_month': {
          this.compareRanges.startDate = moment(this.dateRange.startDate).subtract(1, 'month').format(this.FORMAT);
          this.compareRanges.endDate = moment(this.dateRange.endDate).subtract(1, 'month').format(this.FORMAT);
          const previousDateRange = this.getDatesInRange(this.compareRanges.startDate, this.compareRanges.endDate);
          return { previousDateRange };
        }
        case 'previous_year': {
          this.compareRanges.startDate = moment(this.dateRange.startDate).subtract(1, 'year').format(this.FORMAT);
          this.compareRanges.endDate = moment(this.dateRange.endDate).subtract(1, 'year').format(this.FORMAT);
          const previousDateRange = this.getDatesInRange(this.compareRanges.startDate, this.compareRanges.endDate);
          return { previousDateRange };
        }
        case 'previous_period': {
          this.$_calculatePreviousRange(this.dateRange.startDate, this.dateRange.endDate);
          const previousDateRange = this.getDatesInRange(this.compareRanges.startDate, this.compareRanges.endDate);
          return { previousDateRange };
        }
        case 'custom': {
          const previousDateRange = this.getDatesInRange(this.compareRanges.startDate, this.compareRanges.endDate);
          return { previousDateRange };
        }
        default:
          break;
      }
    },
    getDatesInRange(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const dates = [];

      for (const date = start; date <= end; date.setDate(date.getDate() + 1)) {
        dates.push(new Date(date));
      }
      return dates.map((d) => moment(d).format(this.FORMAT));
    },
    dateFormatFn(classes, date) {
      if (this.enableCompareDates) {
        const { previousDateRange } = this.calculateDatesBaseOnPeriod();
        const dateFormatted = moment(date).format(this.FORMAT);
        return {
          ...classes,
          gray: previousDateRange.includes(dateFormatted),
          first: previousDateRange[0] === dateFormatted,
          last: previousDateRange[previousDateRange.length - 1] === dateFormatted,
        };
      }
      return classes;
    },
    focusInput(ref) {
      for (const [key, ref] of Object.entries(this.$refs)) {
        if (key !== ref && ref.$el.classList.contains('focused')) {
          ref.$el.classList.remove('focused');
        }
      }
      if (ref.includes('previous')) {
        this.$refs[ref].$el.classList.add('green-border');
      } else {
        this.$refs[ref].$el.classList.add('blue-border');
      }
      this.$refs[ref].$el.classList.add('focused');
    },
    isDateValid(date) {
      return !isNaN(new Date(date));
    },
    $_switchToggle(value) {
      this.enableCompareDates = value;
      if (value) {
        // in case that compare dates is enabled and there is no previous date range set
        if (this.compareRanges.startDate.length === 0 || this.compareRanges.endDate.length === 0) {
          this.compareRanges = { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate };
        }
      }
    },
  },
};
</script>

<style lang="scss">
.drp-compare {
  .compare-checkbox {
    .custom-control-label {
      font-size: 1.5rem;
      cursor: pointer;
      padding-left: 1.3rem;

      &::before {
        top: 0.4rem;
        height: 1.5rem;
        width: 2.5rem;
        left: -1.8rem;
      }

      &::after {
        top: 0.6rem;
        height: calc(1.5rem - 4px);
        width: calc(1.5rem - 4px);
        left: calc(-1.8rem + 2px);
      }
    }
  }
}

.optimizer-date-range {
  &.disable-calendars {
    .calendars-container {
      pointer-events: none;
      opacity: .7;
    }
  }
  .calendars {
    padding: 0 !important;
  }

  .date-range-footer {
    border-top: 1px solid #e5e6e8;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .compare {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }

    .buttons {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;

      p {
        font-size: 12px;
        color: #7f828a;
        margin-top: 0.3rem;
      }
    }
  }
}

.drp-calendar.col {
  &.left.next,
  &.right.prev {
    display: none;
  }
}

.header-container {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  .dropdown-inputs-container {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .compare-dropdown {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    margin-top: 0.83rem;

    p {
      font-weight: 800;
    }
  }

  .compare-inputs {
    span:first-child {
      margin-bottom: 0.2rem;
    }

    span {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      .form-control.is-valid {
        background-image: none !important;
        border-color: lightgray;
      }

      .optimizer-form-input {
        width: 145px !important;
      }

      .focused {

        &.green-border {
          box-shadow: #62c68b 0px 0px 0px 1px inset !important;
        }
        &.blue-border {
          box-shadow: #3578e5 0px 0px 0px 1px inset !important;
        }
        border: 1px solid rgb(53, 120, 229);
        background-color: #f6f6f6;
      }

      p {
        font-size: 2rem;
      }
    }
  }

  .compare-switch {
    display: flex;
    align-items: center;

    .optimizer-switch {
      input {
        &:checked ~ .custom-control-label::before {
          color: #fff;
          background-color: #d3e4fd !important;
          border: #1878f2 !important;
        }

        &:checked ~ .custom-control-label::after {
          background-color: #1878f2 !important;
        }
      }

      .custom-control-label {
        &::after {
          background-color: $grey-700 !important;
        }
      }
    }
  }

  .optimizer-select .dropdown-menu .options-list .dropdown-item.selected:not(.dropdown-item-text) {
    background-color: #ebf1fd !important;
  }

  .optimizer-select {
    width: 150px !important;
  }

  .dropdown {
    border: 1px solid #3578e5;
    border-radius: 6px;
    .dropdown-toggle {
      height: 3.3rem;
      .button-placeholder-filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 1.6rem) !important;
      }

      .clear-btn-wrapper {
        top: 50% !important;
        bottom: 50%;
        transform: translateY(-50%);

        i {
          margin-left: 0;
        }
      }
    }

    .dropdown-menu {
      border-radius: 0.6rem;
      position: absolute;
      padding: 0 !important;
      margin: 0 !important;
      width: 180px !important;
      top: 7px !important;

      .search-container {
        border: 0;
        margin: 0 0.2rem 1.2rem 0;

        .dropdown-header {
          padding: 0;

          .condition-group-item-name {
            display: none !important;
          }

          input {
            border-radius: 0.4rem;
            border-color: $grey-200;
            height: 3.4rem;

            &::placeholder {
              color: #bbbbbd;
            }
          }

          &::after {
            left: 1rem;
            top: 0.7rem;
            color: #d9d9d9;
          }
        }
      }

      .options-list {
        .condition-group-item-name {
          display: none !important;
        }

        li {
          position: relative;
          border-radius: 0.6rem;
          margin-bottom: 1.2rem;
          min-width: 100%;
          width: max-content;

          .dropdown-header {
            border: 0;
            background: unset;
            padding: 0.8rem;
            font-weight: 700;
            position: unset !important;
          }

          .dropdown-item {
            border-radius: 0.6rem;
            background: $color-white;
            display: flex;
            align-items: center;
            gap: 1.3rem;

            &:hover,
            &.selected:not(.dropdown-item-text) {
              background-color: $color-white !important;

              .option-content {
                color: $grey-900 !important;

                &:hover {
                  color: $grey-900 !important;
                }
              }
            }

            .option-select {
              display: block !important;
              margin: 0 0 0 1rem !important;
              position: relative;

              input {
                visibility: hidden;
              }

              &::after {
                position: absolute;
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                background-color: rgb(53, 120, 229);
                border-radius: 50%;
                top: 0.5rem;
                left: 0.5rem;
                margin-top: auto;
                margin-bottom: auto;
                opacity: 0;
              }

              &::before {
                content: '';
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                background-color: $color-white;
                top: 0;
                bottom: 0;
                border-radius: 50%;
                border: 1px solid #dedee0;
              }

              &:has(input:checked) {
                &::before {
                  border-color: rgb(53, 120, 229);
                }

                &::after {
                  opacity: 1;
                }
              }
            }

            .option-content {
              padding-left: 0.8rem;
              color: $grey-900 !important;
              padding-top: 1.4rem;

              &::after {
                display: none;
              }
            }
          }
        }
      }

      .disabled-section {
        pointer-events: none;
        opacity: 0.75;
      }

      &::before,
      &::after {
        z-index: 1;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 0.4rem;
      }
    }
  }
}

.reportrange-text {
  padding: 8px 0px !important;
  .first-date {
    margin-top: -1rem;
    margin-bottom: 0.3rem;
  }
  .versus-text {
    font-size: 11px;
    margin-left: 1rem;
    margin-top: -2.7rem;
    margin-bottom: -0.6rem;
  }
}

.compare-date {
  font-size: 12px;
  line-height: 1.33333;
  letter-spacing: normal;
  font-weight: normal;
  width: auto;
  overflow-wrap: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.55);
}
</style>
