
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import Vue, { PropType, VueConstructor } from 'vue';

interface ComponentData {
  optionsData: SelectOption<number>[];
  selectedTags: SelectOption<number>[];
}

interface ComponentRefs {
  $refs: {
    dropdown?: VueOptiSelect;
  };
}

type ComponentTypes = VueConstructor<Vue & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    OptimizerIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Tags',
    },
    optionsProp: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selected: {
      type: Array as PropType<string[]>,
      required: true,
    },
    isDeleteAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      optionsData: [],
      selectedTags: [],
    };
  },
  watch: {
    optionsProp() {
      this.initializeData();
    },
    selected() {
      this.initializeData();
    },
  },
  created() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.optionsData = this.optionsProp
        .filter((tagText) => !!tagText)
        .map((content, value) => ({
          value,
          content,
        }))
        .sort(this.compareOptions);
      this.selectedTags = this.optionsData.filter((option) => this.selected.includes(option.content));
    },
    compareOptions(firstOption: SelectOption<number>, secondOption: SelectOption<number>) {
      const isFirstSelected = this.selected.includes(firstOption.content);
      const isSecondSelected = this.selected.includes(secondOption.content);

      if (isFirstSelected && !isSecondSelected) {
        return -1;
      }

      if (!isFirstSelected && isSecondSelected) {
        return 1;
      }

      return 0;
    },
    onActionClick(action: 'onFilter' | 'onDelete') {
      this.$emit(action, this.selectedTags);
      this.$refs?.dropdown?.hide();
    },
    onDropdownShow() {
      document.querySelector('.tag-filter .options-list')?.scrollTo({ top: 0 });
    },
    onCancelClick() {
      this.selectedTags = this.optionsData.filter((option) => this.selected.includes(option.content));
      this.$refs?.dropdown?.hide();
    },
    onClear() {
      this.$emit('onFilter', []);
    },
  },
});
