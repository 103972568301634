export const min_cpc_per_currency = [
  { currency: 'MXN', minimum_cpc: 0.2023 },
  { currency: 'ZAR', minimum_cpc: 0.1701 },
  { currency: 'INR', minimum_cpc: 0.7974 },
  { currency: 'CNY', minimum_cpc: 0.0684 },
  { currency: 'THB', minimum_cpc: 0.3635 },
  { currency: 'AUD', minimum_cpc: 0.0146 },
  { currency: 'ILS', minimum_cpc: 0.0344 },
  { currency: 'KRW', minimum_cpc: 13.388 },
  { currency: 'JPY', minimum_cpc: 1.3818 },
  { currency: 'GBP', minimum_cpc: 0.0085 },
  { currency: 'TRY', minimum_cpc: 0.1791 },
  { currency: 'RUB', minimum_cpc: 0.6005 },
  { currency: 'HKD', minimum_cpc: 0.0785 },
  { currency: 'TWD', minimum_cpc: 0.3116 },
  { currency: 'EUR', minimum_cpc: 0.0099 },
  { currency: 'USD', minimum_cpc: 0.01 },
  { currency: 'CAD', minimum_cpc: 0.013 },
  { currency: 'SGD', minimum_cpc: 0.014 },
  { currency: 'NZD', minimum_cpc: 0.0163 },
  { currency: 'BRL', minimum_cpc: 0.0524 },
];
