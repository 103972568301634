import NotifcationModel from './NotificationModel';

export default {
  getConfig() {
    const notificationModel = new NotifcationModel();
    return {
      notification: {
        model: notificationModel,
      },
    };
  },
};
