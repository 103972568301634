<template>
  <div class="notifications-section-wraper">
    <!-- <div class="col-md-12 col-sm-12 p-0">
      <h5 class="m-0">Notifications <small class="configure-el">(<b-link target="_blank" :to="{ name: 'Settings' }">Configure</b-link>)</small></h5>
    </div>
    <hr /> -->
    <preloader v-if="preloader"></preloader>
    <p v-else v-for="item in notifications" :class="[{ active: item.state },`item-notify item-${item.type} m-0`]" :key="item.type">
      <span :id="`popover-target-${item.type}`">
        <span :class="`${item.icon} type-icon`"></span>
        <span class="text-capitalize">{{ item.type }} Notifications</span>
        <i v-if="item.state" class="fa fa-check text-success" aria-hidden="true"></i>
        <template v-else>
          <i class="fa fa-times text-danger" aria-hidden="true"></i>
          <span class="configure-el">(<b-link target="_blank" :to="{ name: 'Settings' }">Configure</b-link>)</span>
        </template>
      </span>
      <template v-if="item.state">
        <b-popover :target="`popover-target-${item.type}`" triggers="hover" placement="top">
          <template v-slot:title>
            {{ item.accounts.length }} Account{{ item.accounts.length > 1 ? 's' : '' }}
          </template>
          <p class="m-0" v-for="(acc, index) in item.accounts" :key="index">
            - {{ acc.name }}
          </p>
        </b-popover>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotificationsSection',
  props: {
    config: { type: Object, required: true },
    ruleModel: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  data() {
    return {
      preloader: true,
      notifications: [],
    };
  },
  async created() {
    this.preloader = true;
    try {
      const notifications = await this.config.api.notification.getSettings();
      const iconMap = {
        email: 'icon-email',
        slack: 'icon-slack',
        telegram: 'icon-telegram',
      };
      Object.entries(notifications.channels).forEach(([key, item]) => {
        const itemData = {
          type: key,
          icon: iconMap[key],
          state: false,
          accounts: [],
        };
        item.accounts.forEach((acc) => {
          const accData = { state: acc.isActive && acc.notification.RE, name: '' };
          if (accData.state) {
            if (key === 'email') {
              accData.name = acc.email;
            } else if (key === 'slack') {
              accData.name = acc.incoming_webhook.channel;
            } else if (key === 'telegram') {
              accData.name = `${acc.first_name} ${acc.last_name}`;
            }
            itemData.accounts.push(accData);
          }
        });
        if (itemData.accounts.length) itemData.state = itemData.accounts.reduce((total, { state }) => total || state, false);
        this.notifications.push(itemData);
      });
    } catch (err) {
      console.log(err);
      this.notifications = [];
    }
    this.preloader = false;
  },
};
</script>

<style lang="scss">
.notifications-section-wraper {
  display: flex;
  margin-top: 1rem;

  .item-notify {
    font-size: 1.5rem;
    margin-right: 2.5rem !important;
    align-items: center;

    .icon-email,
    .icon-slack,
    .icon-telegram {
      margin-right: .5rem;
    }

    i {
      margin-left: .5rem;
    }

    .configure-el {
      margin-left: .5rem;
    }
  }

  .configure-el {
    // font-size: 70%;
  }
  .preloader {
    margin: 10px 0px !important;
    top: 30px;
    position: relative;
    width: 100px;
  }
  .item-notify {
    .type-icon {
      display: inline-block;
      background-repeat: no-repeat;
      position: relative;
      &.icon-slack {
        background-image: url('/sh_static/other/Slack.svg');
        width: 18px;
        height: 17px;
        bottom: -1px;
      }
      &.icon-email {
        background-image: url('/sh_static/other/Email.svg');
        width: 18px;
        height: 15px;
        bottom: -1px;
      }
      &.icon-telegram {
        background-image: url('/sh_static/other/Telegram.svg');
        width: 17px;
        height: 17px;
        bottom: -2px;
      }
    }
    &.active {
      cursor: pointer;
    }
  }
}
</style>
