<template>
  <div ref="editorContainer" class="position-absolute cropper-wrap" />
</template>

<script>
export default {
  name: 'ImageEditor',
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: {
        source: this.image.includes('http')
          ? this.image.replace('theoptimizerimg', 's3.amazonaws')
          : this.image,
        onBeforeSave: () => false,
        onSave: (savedImageData) => this.$emit('onImageEdit', savedImageData),
        annotationsCommon: {
          fill: '#ff0000',
        },
        crop: {
          ellipse: undefined,
        },
        Text: { text: 'Insert Text...' },
        Rotate: { angle: 90, componentType: 'buttons' },
        tabsIds: ['Adjust', 'Annotate', 'Finetune', 'Filters', 'Resize'],
        defaultTabId: 'Adjust',
        defaultToolId: 'Crop',
        closeAfterSave: true,
        savingPixelRatio: 4,
        previewPixelRatio: window.devicePixelRatio,
        useBackendTranslations: false,
        observePluginContainerSize: true,
      },
    };
  },
  mounted() {
    const imageEditor = new window.FilerobotImageEditor(
      this.$refs.editorContainer,
      this.config,
    );
    imageEditor.render({
      onClose: () => {
        imageEditor.terminate();
      },
    });
  },
};
</script>

<style lang="scss">
.cropper-wrap {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  overflow: hidden;
  min-height: 50rem;

  .FIE_topbar-save-wrapper,
  .FIE_topbar-close-button {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }

  .FIE_topbar,
  .FIE_main-container {
    padding-inline: 0;
  }

  .SfxLabel-root {
    margin-bottom: 0;
  }
}
</style>
