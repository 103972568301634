<template>
  <div class="content-wrapper campaign-queue-container campaign-creator-queue">
    <div class="campaign-queue-wrapper">
      <!-- Section Header -->
      <div class="section-header d-flex justify-content-between align-items-center">
        <div class="section-header__content d-flex">
          <h1 class="section-header__content__title">Campaign Creator Queue</h1>
        </div>
      </div>

      <loading-skeleton
        v-if="preloader"
        type="table"
        :table="{ headItems: 6, bodyItems: 20, filters: true, search: true, filterItems: 3 }"
      />
      <div v-else class="campaign-queue-table optimizer-table">
        <vue-opti-table-light
          v-if="table.fields.length > 0"
          :name="$c_tableName"
          class="queue-wrapper"
          :hover="true"
          :enable-export="false"
          :selectable="false"
          select-label="Items"
          :header-fields="table.fields"
          :items="table.items"
          v-model="tableModel"
          :sort="{ key: table.sortField, order: table.sortType }"
          @on-pagination="$_setPaginationValues($event)"
          @on-search="$_setSearchValue($event)"
          @on-row-per-page-change="$_setPaginationValues($event)"
          @on-sort="$_setPaginationValues($event)"
          :server-side-pagination="true"
          :pages="table.pages"
          :page="table.page"
          :default-rows="table.limit"
          sticky
        >
          <template slot="search">
            <div class="d-flex flex-grow-1">
              <vue-opti-select-light
                class="optimizer-select filters pl-4 pr-2 max-height-240 create-select"
                :options="$c_batchActionOptions"
                button-type="static"
                single
                @change="$_bulkAction"
              >
                <template v-for="item in $c_disabledTsTypes" v-slot:[`ITEM_BEFORE_${item.value}`]>
                  <span
                    :key="item.value"
                    class="option-tooltip not-allowed "
                    v-b-tooltip.hover.right.html="{
                      customClass:'custom-tooltip',
                      title: $c_tooltipTxt,
                      delay: {hide: 300}
                    }"
                  />
                </template>
                <template #ITEM="{ option }">
                  <div class="ml-0 option-text">
                    {{ option.content }}
                  </div>
                </template>
                <template #BUTTON_PLACEHOLDER>
                  <span class="button-placehoder-filter">
                    <i class="fa fa-plus"></i>
                    <span class="ml-8">Create Campaign</span>
                  </span>
                </template>
              </vue-opti-select-light>
              <vue-opti-select-light
                class="optimizer-select filters pl-0 pr-2 max-height-240"
                :options="status"
                button-type="static"
                option-type="checkbox"
                allowClear
                @clear="$_onStatusesClear"
                @change="$_onStatusChange"
              >
                <template #BUTTON_PLACEHOLDER="{ options }">
                  <span class="button-placehoder-filter">
                    <i class="fa fa-filter"></i>
                    <span class="ml-8">Filter By Status</span>
                    <b-badge v-show="options.length" pill variant="info">
                      {{ options.length }}
                    </b-badge>
                  </span>
                </template>
                <template #ITEM="{ option }">
                  <div class="option-text">
                    {{ option.content }}
                  </div>
                </template>
              </vue-opti-select-light>
              <vue-opti-select-light
                class="optimizer-select filters-action pl-0 pr-2 max-height-240"
                :options="campaignActions"
                unique-key="value"
                button-type="static"
                option-type="checkbox"
                allowClear
                @clear="$_onActionClear"
                @change="$_onActionChange"
              >
                <template #BUTTON_PLACEHOLDER="{ options }">
                  <span class="button-placehoder-filter">
                    <i class="fa fa-filter"></i>
                    <span class="ml-8">Filter By Action</span>
                    <b-badge v-show="options.length" pill variant="info">
                      {{ options.length }}
                    </b-badge>
                  </span>
                </template>
                <template #ITEM="{ option }">
                  <div class="ml-8 option-text">
                    {{ option.content }}
                  </div>
                </template>
              </vue-opti-select-light>
            </div>
            <div class="refresh-button" v-b-tooltip :title="apiLoading ? '' : 'Refresh Table'">
              <b-btn class="secondary-button" :disabled="apiLoading" @click="$_loadData"><i class="fa fa-refresh" /></b-btn>
            </div>
          </template>
          <template slot="calculated_error" slot-scope="props">
            <div class="field">
              <template v-if="props.item.calculated_error.length < 50">
                {{ props.item.calculated_error }}
              </template>
              <template v-else>
                {{ props.item.calculated_error.slice(0, 30) }}
                <b-badge
                  class="view-more-badge"
                  v-b-tooltip="{ title: props.item.calculated_error, placement: 'left', boundary: 'viewport' }"
                  variant="secondary"
                >[ <i class="fa fa-info" aria-hidden="true"></i>
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i> ]</b-badge>
                {{
                  props.item.calculated_error.slice(
                    props.item.calculated_error.length - 30,
                    props.item.calculated_error.length
                  )
                }}
              </template>
            </div>
          </template>
          <!--          from here -->
          <template slot="details" slot-scope="props">
            <b-btn class="secondary-button details-btn" @click="$_detailView(props.item)"> Details</b-btn>
          </template>
          <template slot="retry" slot-scope="props">
            <b-btn
              v-if="$_showRetryBtn(props.item)"
              class="secondary-button details-btn"
              @click="$_detailView(props.item)"
            >
              <i class="fa fa-repeat" /> Retry</b-btn>
          </template>
          <!--          to here -->
        </vue-opti-table-light>
        <loadizer :loading="apiLoading" />
      </div>

      <!-- Details Modal -->
      <details-modal ref="detailsViewModal"
                     :selectedItem="selectedItem"
                     :campaign-retry-loading="campaignRetryLoading"
                     :html-account-name-fn="$_getAccountNameHtml"
                     :html-status-fn="$_getStatusHtml"
                     :hidden-modal-fn="$_hiddenModal"
      />

      <csv-upload ref="uploadExcel" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import notifications from '@sh/mixins/notifications';
import _ from 'lodash';
import LoadingSkeleton from '@sh/components/Utils/Skeleton/LoadingSkeleton.vue';
import CsvUpload from './components/CsvUpload.ts.vue';
import { canAccess, getTrafficSourceLogo } from '@sh/helpers';
import DetailsModal from './components/DetailsModal.vue';

const FORMAT = 'YYYY-MM-DD, h:mm a';

export default {
  name: 'CampaignCreatorQueue',
  components: { DetailsModal, LoadingSkeleton, CsvUpload },
  mixins: [notifications],
  data() {
    return {
      preloader: false,
      apiLoading: false,
      campaignActions: [
        { content: 'Create Campaigns', value: 'CREATE_CAMPAIGNS' },
        { content: 'Clone Campaigns', value: 'CLONE_CAMPAIGNS' },
        { content: 'Edit Campaigns', value: 'EDIT_CAMPAIGNS' },
        { content: 'Create Campaigns Excel', value: 'CREATE_CAMPAIGNS_EXCEL' },
        { content: 'Create Contents', value: 'CREATE_CONTENTS' },
      ],
      table: {
        fields: [
          // {
          //   header: { content: 'ID', style: '' },
          //   item: {
          //     key: '_id',
          //     sortable: true,
          //     content: (item) => item._id
          //   }
          // },
          {
            header: { content: 'Date', style: '' },
            item: {
              key: 'formated_date',
              sortable: true,
              content: (item) => item.formated_date,
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Account', style: '' },
            item: {
              key: 'traffic_source_name',
              sortable: true,
              searchable: true,
              content: (item) => this.$_getAccountNameHtml(item),
            },
          },
          {
            header: { content: 'Campaign', style: '' },
            item: {
              key: 'campaign_name',
              sortable: true,
              searchable: true,
              content: (item) => (item.action === 'CREATE_CONTENTS' ? item.campaign_name : item.campaign.name),
            },
          },
          {
            header: { content: 'Action', style: '' },
            item: {
              key: 'action',
              sortable: true,
              content: (item) => this.$_formatText(item.action),
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Status', style: '' },
            item: {
              key: 'status',
              sortable: true,
              content: (item) => this.$_getStatusHtml(item.status),
              style: { textAlign: 'center' },
            },
          },
          {
            header: { content: 'Error Description', style: '' },
            item: {
              key: 'calculated_error',
              slot: 'calculated_error',
              sortable: true,
            },
          },
          {
            header: { content: 'Retry', style: '' },
            item: {
              key: 'retry',
              slot: 'retry',
            },
          },
          {
            header: { content: 'Details', style: 'width: 1%' },
            item: {
              key: 'details',
              slot: 'details',
              style: { textAlign: 'center' },
            },
            colClass: 'sticky sticky--details',
          },
        ],
        items: [],
        loadingStatus: false,
        page: 1,
        sortType: 'desc',
        sortField: '_id',
        search: '',
        limit: 100,
        pages: 1,
      },
      tableModel: {
        selectedRows: [],
        displayColumns: [],
        columnsOrder: [],
      },
      campaignRetryLoading: false,
      selectedItem: null,
      status: [
        {
          content: 'Finished',
          value: 'FINISHED',
        },
        {
          content: 'Partially Finished',
          value: 'PARTIALLY FINISHED',
        },
        {
          content: 'Failed',
          value: 'FAILED',
        },
        {
          content: 'Pending',
          value: 'PENDING',
        },
      ],
      selectedAction: '',
      selectedStatus: '',
      hasAccess: true,
    };
  },
  computed: {
    $c_tooltipTxt() {
      return '<span>This option is not available on your current plan. Please contact support for upgrade options.</span>';
    },
    $c_tableName() {
      return 'campaignCreatorQueueTable';
    },
    $c_disabledTsTypes() {
      if (!this.hasAccess) return this.$c_batchActionOptions.filter((item) => item.disabled);
    },
    $c_batchActionOptions() {
      return [
        { value: 'manual', fn: this.$_manualCreate, content: 'Create Manually' },
        { value: 'excel', fn: this.$_excelCreate, content: 'Upload Excel File' },
      ];
    },
  },
  async created() {
    this.preloader = true;
    this.hasAccess = await canAccess('CampaignCreatorExcel');
    if (!this.hasAccess) {
      this.$c_batchActionOptions.forEach((item) => {
        if (item.value === 'excel') {
          item.disabled = true;
        }
      });
    }
    try {
      this.$options.debounceLoadData = _.debounce(() => this.$_loadData(), 1000);
      await this.$_loadData();
    } catch (error) {
      this.$n_failNotification({ title: 'An error occurred' });
    }
    this.preloader = false;
  },
  methods: {
    getTrafficSourceLogo,
    $_showRetryBtn(item) {
      return item.status === 'FAILED' || item.status === 'PARTIALLY FINISHED';
    },
    $_manualCreate() {
      this.$router.push('/campaign-creator');
    },
    $_excelCreate() {
      this.$refs.uploadExcel.showModal();
    },
    $_bulkAction(data) {
      data.fn(data.value);
    },
    async $_loadData() {
      this.apiLoading = true;
      const { page, limit, sortType, sortField, search } = this.table;
      const { data } = await this.$api.campaignCreator.queueIndex(
        page,
        limit,
        sortType,
        sortField,
        search,
        this.selectedStatus,
        this.selectedAction,
      );
      this.table.items = data.uploaderActions.map((item) => {
        item.formated_date = this.$_formatDate(item.date);
        item.calculated_error = String(this.$_getCalculatedError(item));
        return item;
      });
      this.table.loadingStatus = false;
      this.table.pages = data.totalPages;
      this.apiLoading = false;
    },
    $_getAccountNameHtml(item) {
      const accountName = item.campaign?.destination_account?.name || item.traffic_source_name;
      return `<img src="${getTrafficSourceLogo(item.traffic_source_unique_name)}" class="source-logo" title="${item.traffic_source_unique_name}"/>${accountName}`;
    },
    $_getStatusHtml(status) {
      if (status === 'FINISHED') {
        return `<span class="text-success">${status}</span>`;
      } if (status === 'FAILED') {
        return `<span class="text-danger">${status}</span>`;
      }
      return `<span class="text-warning">${status}</span>`;
    },
    $_formatDate(date) {
      return moment.utc(date).format(FORMAT);
    },
    $_detailView(item) {
      if (item.contents && item.contents.length > 0) {
        item.contents.forEach((content) => {
          content.headline = content.headline?.text || content.headline;
        });
      }
      this.selectedItem = item;
      this.$refs.detailsViewModal.show();
    },
    $_getCalculatedError(item) {
      let error = item.error_message || '';
      if (!error) {
        error = item?.campaign?.error_message || '';
      }

      if (!error) {
        try {
          error = item.contents.find((content) => content.error_message).error_message || '';
        } catch (err) { /* Nothing here */ }
      }
      return error;
    },
    $_hiddenModal() {
      this.selectedItem = null;
    },
    $_setPaginationValues(event) {
      this.table.limit = event.limit;
      this.table.page = event.page;
      this.table.sortField = event.sortField;
      this.table.sortType = event.sortType;
      this.table.loadingStatus = true;
      this.$_loadData();
    },
    $_setSearchValue(event) {
      this.table.loadingStatus = true;
      this.table.search = event.search;
      this.table.page = 1;
      this.$options.debounceLoadData();
    },
    $_onStatusChange(event) {
      if (Array.isArray(event)) {
        this.selectedStatus = event.map((item) => item.value).join(',');
        this.$options.debounceLoadData();
      } else {
        this.selectedItem = event.value;
      }
    },
    $_onActionChange(event) {
      this.table.page = 1;
      if (Array.isArray(event)) {
        this.selectedAction = event.map((item) => item.value).join(',');
        this.$options.debounceLoadData();
      } else {
        this.selectedAction = event.value;
      }
    },
    $_onStatusesClear() {
      this.selectedStatus = '';
      this.$options.debounceLoadData();
    },
    $_onActionClear() {
      this.selectedAction = '';
      this.$options.debounceLoadData();
    },
    $_formatText(action) {
      return action.replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
};
</script>

<style lang="scss">
.campaign-queue-container {
  .optimizer-select {
    margin-right: 0.8rem;

    &.create-select {
      .dropdown-menu {
        width: 20rem;
      }
    }
    .dropdown {
      height: 100%;

      button {
        height: 100%;
      }
    }

    .btn.dropdown-toggle {
      color: $text-secondary !important;

      &::after {
        margin-left: -2rem;
      }

      .button-placehoder-filter {
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        width: -moz-available;
        width: -webkit-fill-available;

        .badge {
          top: -1.7rem;
          right: -5rem;
          background: #618dfd;
          padding: 0.4rem 0.7rem 0.3rem;
        }
      }

      .option-text {
        color: $text-secondary !important;
      }

      .clear-btn-wrapper {
        position: relative;
        top: unset !important;
        opacity: 1;
        min-width: 1.7rem;
        right: 2.25rem;
        background-color: $color-gray;

        &:hover {
          background-color: $color-light-gray;
        }

        i {
          margin-left: 0;
        }
      }
    }
  }

  &.filters,
    &.filters-action {
      .option-content {
        &::after {
          color: $blue-300 !important;
          max-height: 1.8rem;
        }
      }
    }
    .filters-action {
        .dropdown-menu {
        width: 22rem;
      }
    }

  .campaign-queue-table {
    .sticky {
      &.sticky--details {
        left: unset;
        right: 0;

        &::after {
          left: 0;
          right: unset;
        }
      }
    }
  }

  .section-header {
    margin-bottom: 1.5rem;
  }

  img.source-logo {
    height: 2.4rem;
    display: inline;
    margin-right: 5px;
    line-height: 10px;
  }

  .details-btn {
    font-size: 1.3rem;
    min-width: 100%;
    height: 3rem;
    padding: 0;
  }
}

.details-view-container {
  .alert-log {
    background: #f0f6f9;
    border-radius: 1rem;
    padding: 1.5rem;
    border: none;

    img.source-logo {
      height: 2rem;
      margin-inline: 0.6rem;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 0.5rem !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }

    .btn-outline-info {
      display: inline-block;
      font-size: 1.4rem;
      border-color: $color-light-blue;
      padding: 0.5rem 1.5rem;
      color: $color-light-blue;
      border-radius: 0.4rem;
      width: 22rem;
      margin-bottom: 1rem;

      &:hover {
        background: $color-light-blue;
        color: $color-white;
      }

      i {
        margin-right: 0.5rem;
      }
    }

    .error-alert {
      width: 100%;
      border-radius: 0.5rem;
      padding: 1rem;
      margin: 1.5rem 1.5rem 0 1.5rem;
      font-size: 1.4rem;
    }
  }

  .details-view-table {
    margin-top: 2.5rem;
    overflow-x: auto;

    table {
      border: 0.1rem solid $color-gray;
      thead {
        th {
          font-size: 1.3rem;
          border-right: 0.1rem solid $table-border-color;
          color: $text-primary;
          text-align: center;
        }
      }

      tbody {
        tr {
          &:hover {
            background: $color-light;
          }

          td {
            font-size: 1.3rem;
            border-right: 0.1rem solid $table-border-color;
            border-top: 0.1rem solid $table-border-color;
            vertical-align: middle;
          }
        }
      }
    }

    .image-thumb {
      max-height: 10rem;
      max-width: 15rem;
      border-radius: 1rem;
    }
  }
}

// .campaign-creator-queue {
//   .queue-wrapper {
//     .field {
//       padding: 0 5px;
//       .view-more-badge {
//         cursor: pointer;
//       }
//     }
//   }
//   .details-btn {
//     padding: 0.1rem 0.2rem;
//   }
//   #detailsViewModal {
//     &.modal {
//       top: 0px !important;
//     }
//     .alert-log {
//       color: #000;
//       background-color: #f2f4f8;
//       border-color: #e1e6ef;
//       p {
//         margin-top: 0;
//         margin-bottom: .2rem;
//       }
//     }
//     .contents-table {
//       overflow-x: scroll;
//       .image-thumb {
//         max-height: 100px;
//         max-width: 150px;
//       }
//     }
//   }
//   img.source-logo {
//     height: 17px;
//     display: inline;
//     margin-right: 5px;
//     line-height: 10px;
//     margin-bottom: 1px;
//   }
// }
</style>
