
import tableFields from '@/helpers/fields';
import { usePresetStore } from '@/stores/presets';
import { BackgroundReport, getFieldsDto, getTableColumnFilter } from '@/views/Tools/Reporting/helpers';
import { useReportingStore } from '@/views/Tools/Reporting/store';
import { ReportTab, ReportType, SavedReportStatus, TableItem, TableSort } from '@/views/Tools/Reporting/types';
import { FilterType } from '@/views/Tools/Reporting/types/Filter';
import NoData from '@sh/components/MediaManager/NoData/NoData.ts.vue';
import ContentPreview from '@sh/components/Utils/ContentPreview/Index.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { SystemField } from '@sh/configurations/fields/SystemField';
import { defaultColumnsWidth, getTrafficSourceLogo, pluralize } from '@sh/helpers';
import symbols from '@sh/helpers/symbols';
import uiHelper from '@sh/helpers/ui';
import columnsMixin from '@sh/mixins/columnsConfig';
import notifications from '@sh/mixins/notifications';
import preview from '@sh/mixins/preview';
import tableMixins from '@sh/mixins/table';
import { Presets, TrafficSource, VueOptiTable } from '@sh/types';
import { cloneDeep, isNull, isUndefined } from 'lodash';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  videoUrl: string;
  imageErrorTimeout: number;
  queue: Array<{ item: TableItem; i: number } | undefined>;
  tableColumnFilter: Record<string, VueOptiTable.Condition[]>;
  resizedColumns: Record<string, number>;
  tableSort?: TableSort;
  intervalId: number;
  isRefreshLoading: boolean;
}

interface FieldGroup {
  value: string;
  group: string;
  useInCustomMetric: boolean;
  priority: number;
  label: string;
  display?: boolean;
}

interface ComponentRefs {
  $refs: {
    ReportTable?: VueOptiTable.Ref;
  };
  $settings: {
    resizedColumns: {
      setResizedColumns: (key: string, type: string, payload: Record<string, number>) => void;
      getResizedColumns: (key: string, type: string) => Record<string, number>;
    };
    presets: {
      getPresetByTrafficSource: (storageKey: string) => string;
      setPresetByTrafficSource: (storageKey: string, value: string) => void;
    };
  };
  $options: {
    fieldsGroup: Array<FieldGroup>;
  };
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin & TableMixin & PreviewMixin & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    ContentPreview,
    OptimizerIcon,
    NoData,
  },
  mixins: [notifications, tableMixins, preview, columnsMixin],
  data(): ComponentData {
    return {
      videoUrl: '',
      imageErrorTimeout: 0,
      queue: [],
      tableColumnFilter: {},
      resizedColumns: {},
      intervalId: 0,
      tableSort: undefined,
      isRefreshLoading: false,
    };
  },
  setup() {
    return {
      SavedReportStatus,
      uiHelper,
      symbols,
      TrafficSource,
      ReportType,
    };
  },
  computed: {
    message(): string {
      if (this.currentReport.noDataMessage) {
        return this.currentReport.noDataMessage;
      }
      return 'No Report Generated Yet!';
    },
    tableMetadata(): Record<string, string> {
      if (this.isBackgroundReport) {
        return {
          name: 'savedReportsTable',
          exportLabel: 'saved_reports',
        };
      }

      return this.currentReport.getTableMetadata(this.reportTypeName);
    },
    headerFields(): SystemField[] {
      const fieldsDomain =
        this.reportOptions.find((option) => this.currentReport.activeReportType === option.reportType)?.fieldsDomain ??
        'DEFAULTS';

      if (this.isBackgroundReport) {
        const fields = this.currentReport.reportFields;
        return this.$_getFieldInfo(fields);
      }

      const fields = this.currentReport.reportFields.map((item) =>
        tableFields.getField(
          fieldsDomain,
          {
            name: item.item.key,
            uniqueName: this.trafficSourceFilter?.value ?? '',
          },
          item
        )
      );
      return this.$_getFieldInfo(fields);
    },
    saveSettings(): Function | undefined {
      return this.isBackgroundReport ? undefined : this.onSaveSettings;
    },
    tableKey(): string {
      return JSON.stringify({
        isBackgroundReport: this.isBackgroundReport,
        sort: this.tableSort,
        pagination: this.currentReport.table.pagination,
      });
    },
    searchClass(): string {
      return [ReportType.UniqueName, ReportType.Account, BackgroundReport].includes(this.currentReport.activeReportType)
        ? 'search-hidden'
        : '';
    },
    onRefreshClick(): Function | undefined {
      if (this.isBackgroundReport) {
        return () => (this.currentReport = this.onBackgroundReportsLoad());
      }
      return async () => {
        this.isRefreshLoading = true;
        await this.onGenerateReport(this.currentReport);
        this.isRefreshLoading = false;
      };
    },
    nativeFields(): FieldGroup[] {
      const fields = this.headerFields;
      return this.$_filterNativeFields(this.currentReport.trafficSource, fields, this.$options.fieldsGroup);
    },
    ...mapState(useReportingStore, [
      'reportOptions',
      'isBackgroundReport',
      'reportTypeName',
      'trafficSourceFilter',
      'isTableShown',
      'hasLoadizer',
      'BackgroundReportTab',
      'cachedTableFields',
      'tabOptions',
    ]),
    ...mapState(usePresetStore, ['presets', 'selectedPreset', 'hasSubuserFeatureAccess']),
    ...mapWritableState(useReportingStore, ['currentReport', 'cachedTableFields']),
  },
  watch: {
    currentReport: {
      handler() {
        this.setTableData();
      },
      deep: true,
    },
    'currentReport.trafficSource': {
      async handler() {
        await this.setPresets();
      },
    },
    'currentReport.tab': {
      async handler() {
        await this.setPresets();
      },
    },
  },
  async created() {
    this.setPresetId(this.$settings.presets.getPresetByTrafficSource(this.currentReport.trafficSource));
    await this.setPresets();
  },
  mounted() {
    this.resizedColumns = this.getResizedColumns();
    this.intervalId = setInterval(() => {
      if (this.currentReport.generatedTime) {
        const timeMilliseconds = this.currentReport.generatedTime.getTime() + 1;
        this.currentReport.generatedTime = new Date(timeMilliseconds);
      }
    }, 60000);
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions(useReportingStore, [
      'getAccountName',
      'getCsvItems',
      'saveOfflineReport',
      'setCachedReport',
      'createReport',
      'getComplianceData',
      'onBackgroundReportsLoad',
      'onReportTabChange',
      'onTableChange',
      'onTableSearch',
      'onGenerateReport',
      'setPresetId',
    ]),
    ...mapActions(usePresetStore, [
      'editPresetName',
      'changePreset',
      'deletePreset',
      'createPreset',
      'getFilteredPresets',
      'setSpecificScope',
      'switchPresetAccess',
      'setFilter',
    ]),
    getTrafficSourceLogo,
    pluralize,
    isReportStatus(value: number): value is SavedReportStatus {
      return value in SavedReportStatus;
    },
    async setPresets() {
      this.setFilter({
        type: {
          uniqueName: this.currentReport.trafficSource,
        },
      });
      await this.getFilteredPresets();
      this.setPresetId(this.$settings.presets.getPresetByTrafficSource(this.currentReport.trafficSource));
      await this.onGenerateReport(this.currentReport);
    },
    async updateTableComponent(
      sortKey: string,
      sortOrder: string,
      columnFilter: Record<string, VueOptiTable.Condition[]>,
      search: string
    ) {
      const table = (
        (document.getElementById('report-table') ?? { __vue__: {} }) as unknown as { __vue__: VueOptiTable.Ref }
      ).__vue__;
      table.sortField = sortKey;
      table.sortKey = sortKey;
      table.sortOrder = sortOrder;
      table.columnFilterLocal = cloneDeep(columnFilter);

      if (table.models) {
        table.models.search = search;
      }
    },
    async setTableData() {
      const { sort } = this.currentReport.table;
      const order = sort.startsWith('+') ? 'asc' : 'desc';
      const key = sort.slice(1);

      this.resizedColumns = this.getResizedColumns();
      this.tableColumnFilter = getTableColumnFilter(this.currentReport.table.conditions ?? []);
      this.tableSort = { key, order };
      this.updateTableComponent(key, order, this.tableColumnFilter, this.currentReport.table.search ?? '');
    },
    getImageURL(item: TableItem) {
      if (item.image_url.includes('sh_static')) {
        return item.image_url;
      }

      if (this.isVideoUrl(item.image_url)) {
        return uiHelper.table.urlFix(item.video_url);
      }

      return uiHelper.table.urlFix(item.image_url);
    },
    getResizedColumns() {
      return {
        ...defaultColumnsWidth,
        ...this.$settings.resizedColumns.getResizedColumns('reporting', this.currentReport.trafficSource),
      };
    },
    async manageBackgroundReport() {
      const swal = await this.$swal({
        icon: 'info',
        html: 'This report is too big and will be generated in background. Once completed you will be able to download the CSV from Queued Reports tab.',
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Go to Queued Reports',
        cancelButtonText: 'Cancel',
        customClass: {
          container: 'confirm-reporting-swal xl',
          confirmButton: 'primary-button',
          cancelButton: 'secondary-button',
        },
      });

      if (swal.value === true) {
        const message =
          'This report is being generated in the background. Please refresh the table to check if uploaded.';
        this.onReportTabChange(this.BackgroundReportTab ?? ({} as ReportTab), message);
      }
    },
    getBudgetValue(item: string | number | null) {
      return isUndefined(item) || isNull(item) ? '-' : item;
    },
    capitalizeWords(input: string) {
      const words = input.split('_');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
      const result = capitalizedWords.join(' ');
      return ` ${result}`;
    },
    setSuffixBudget(item: any) {
      if (item.budget !== null && [TrafficSource.Facebook].includes(item.traffic_source_unique_name)) {
        return '  Lifetime';
      }
      if (item.budget !== null && [TrafficSource.TikTok].includes(item.traffic_source_unique_name)) {
        return '  Total';
      }
      if (
        item.budget !== null &&
        item.budget_type &&
        [TrafficSource.Taboola].includes(item.traffic_source_unique_name)
      ) {
        if (item.budget_type === 'ENTIRE') {
          return '  Lifetime';
        }
        return ` ${this.capitalizeWords(item.budget_type)}`;
      }
      if (
        item.budget !== null &&
        item.budget_type &&
        [TrafficSource.Outbrain, TrafficSource.RevContent, TrafficSource.Mgid].includes(item.traffic_source_unique_name)
      ) {
        return ` ${this.capitalizeWords(item.budget_type)}`;
      }
      return '';
    },
    onImageError(data: { item: TableItem; i: number }) {
      this.queue[data.i] = data;

      clearTimeout(this.imageErrorTimeout);
      this.imageErrorTimeout = setTimeout(() => {
        this.queue.forEach((element, index) => {
          if (element) {
            element.item.image_url = '/sh_static/other/no-image.svg';
            element.item.hasVideoError = true;
          }
          this.queue[index] = undefined;
        });
        this.imageErrorTimeout = 0;
      }, 100);
    },
    async onCsvDownload() {
      try {
        return await this.getCsvItems();
      } catch (error) {
        this.$n_failNotification({ title: 'Download failed. Retry or contact support for assistance.' });
      }
    },
    async onSaveSettings(newFields: VueOptiTable.Field[]) {
      const fields = cloneDeep(this.currentReport.table.columns);
      const reportType = this.currentReport.activeReportType ?? this.currentReport.tab;
      const data = newFields.map((newField) => {
        const currentFieldData = fields.find((field) => field.item.key === newField.item.key);

        return {
          ...currentFieldData,
          ...newField,
        };
      });

      this.currentReport.table.columns = cloneDeep(data);
      this.cachedTableFields[`${this.currentReport.trafficSource}_${reportType}`] = cloneDeep(data);

      try {
        await this.$_saveSettingsOptionsReporting(
          getFieldsDto(this.currentReport.table.columns),
          {
            type: this.currentReport.trafficSource,
            level: this.parseLevel(this.currentReport.activeReportType),
          },
          true,
          this.$settings.presets.getPresetByTrafficSource(this.currentReport.trafficSource)
        ).then(() => {
          // clean cache for current preset
          const allKeysWithPresetId = Object.keys(this.cachedTableFields).filter((key) =>
            key.includes(this.$settings.presets.getPresetByTrafficSource(this.currentReport.trafficSource))
          );
          for (const key of allKeysWithPresetId) {
            delete this.cachedTableFields[key];
          }
          this.$apiStore.presets.getPresets.clearCache();
          this.getFilteredPresets();
        });
      } catch (error) {
        this.currentReport.table.columns = fields;
      }
    },
    onTableResize(payload: Record<string, number>) {
      this.$settings.resizedColumns.setResizedColumns('reporting', this.currentReport.trafficSource, payload);
    },
    onSaveReportClick() {
      try {
        this.saveOfflineReport(this.currentReport);
        this.setCachedReport(BackgroundReport);
        this.manageBackgroundReport();
      } catch {
        this.$n_failNotification({ title: 'Request failed, retry or contact support for assistance.' });
      }
    },
    // presets
    async onPresetCreate(preset: Presets.Preset) {
      try {
        if (this.currentReport.trafficSource === 'ALL') {
          this.setSpecificScope([
            {
              constraint: Presets.ConstraintEnum.AllTrafficSources,
              values: {
                availableTsTypes: [],
              },
            },
          ]);
        }
        await this.createPreset(preset, this.parseLevel(this.currentReport.activeReportType));
      } catch {
        this.$n_failNotification({ title: 'Could not create preset, please try again!' });
      }
    },
    async onChangePreset(preset: Presets.Preset) {
      this.changePreset(preset);
      this.setPresetId(preset._id);
      await this.onGenerateReport(this.currentReport);
    },
    async onPresetDelete(preset: Presets.Preset) {
      try {
        const previousSelected = this.selectedPreset._id;
        await this.deletePreset(preset);
        if (previousSelected === preset._id) {
          this.setPresetId(this.selectedPreset._id);
          this.onGenerateReport(this.currentReport);
        }
        this.$n_successNotification({ title: `Preset with name ${preset.name} was deleted successfully` });
      } catch {
        this.$n_failNotification({ title: 'Could not delete preset, please try again!' });
      }
    },
    async onPresetEditName(preset: Presets.Preset) {
      try {
        await this.editPresetName(preset);
        this.$n_successNotification({ title: `Preset with name ${preset.name} was edited successfully` });
      } catch {
        this.$n_failNotification({ title: 'Could not edit preset, please try again!' });
      }
    },
    parseLevel(level: string) {
      const checkIfPlural = level.charAt(level.length - 1) === 's';
      let levelParsed: string;
      if (checkIfPlural) {
        levelParsed = level.substr(0, level.length - 1);
      } else {
        levelParsed = level;
      }
      return `REPORT${levelParsed.toUpperCase()}`;
    },
    onReportChange(type: string, email: string) {
      const newReport = this.createReport(type);
      const userFilter = newReport.filters[FilterType.User];
      const trafficSourceFilter = newReport.filters[FilterType.TrafficSourceComplianceFilter];

      if (userFilter) {
        userFilter.value = email;

        this.getComplianceData(userFilter.selectedData, newReport);

        if (trafficSourceFilter) {
          trafficSourceFilter.user = userFilter.selectedData;
        }
      }

      this.onGenerateReport(newReport);
      this.currentReport = newReport;
    },
  },
});
