import { ColDef } from 'ag-grid-community';

export enum RowModelType {
  CLIENT_SIDE = 'clientSide',
  SERVER_SIDE = 'serverSide',
}

export interface Pagination {
  enabled: boolean;
  pageSize: number;
}

export interface NoRowsOverlayComponentParams {
  noRowsOverlayMessage?: string;
  noRowsOverlayErrorMessage: string;
  noRowsOverlayComponentClassList: string[];
}

export interface ColumnDef extends ColDef {
  cellClassKey?: string;
  colorize?: boolean;
}
