export default (campaignModel) => ({
  campaign: {
    getComponents() {
      return campaignModel.getComponents();
    },
    getWatches() {
      return campaignModel.getWatches();
    },
    setForm(form, nextTick, mode) {
      campaignModel.setModel(form, nextTick, mode);
    },
    getForm(mode, campaign) {
      return campaignModel.getForm(mode, campaign);
    },
    onInit(props) {
      return campaignModel.onInit(props);
    },
  },
});
