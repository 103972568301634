<template>
  <div class="account-wizard-search">
    <div class="account-wizard-search__container">
      <b-form-input
        type="text"
        v-model="localValue"
        @input="$_onInput"
        placeholder="Search..."
      />
      <i class="fa fa-search"></i>
      <i v-if="localValue.length" @click="$_clear" class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';

export default {
  name: 'Search',
  props: {
    items: { type: Array, default: () => [] },
    mapKey: { type: Function, default: (item) => null },
    mapValue: { type: Function, default: (item) => null },
  },
  data() {
    return {
      localItems: [],
      localValue: '',
    };
  },
  methods: {
    $_onInput(search) {
      this.$_search(search, this);
    },
    $_search: _.debounce((searchValue, vm) => {
      searchValue = searchValue.toLowerCase();
      if (searchValue === '') {
        vm.$emit('input', null);
      } else {
        const items = vm.localItems.filter((item) => item.value.indexOf(searchValue) > -1);
        const itemsMap = {};
        items.forEach(({ key, value }) => { itemsMap[key] = value; });
        vm.$emit('input', itemsMap);
      }
    }, 150),
    $_clear() {
      this.localValue = this.$_search('', this);
      this.localValue = '';
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        if (this.items.length) {
          this.localItems = this.items.map((item) => {
            const value = `${this.mapValue(item)}`;
            return { key: this.mapKey(item), value: value.toLowerCase() };
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
.account-wizard-search {
  text-align: center;

  &__container {
    display: inline-block;
    position: relative;

    input {
      border: .1rem solid #E5E5E5;
      font-size: 1.4rem;
      padding: .7rem 3.3rem .7rem 3.5rem;
      border-radius: .6rem;
      background: $color-white;
      width: 25vw;
      height: 3.8rem;
  
      &::placeholder {
        color: $black-200;
      }
    }

    .fa-search {
      position: absolute;
      left: 1rem;
      top: 1rem;
      font-size: 1.5rem;
      color: $black-400;
      z-index: 3;
    }

    .fa-times-circle {
      position: absolute;
      right: 1.3rem;
      top: 1.1rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: #6c757d;
      cursor: pointer;
    }
  }
}
</style>
