<template>
  <div class="row">
    <div class="col-md-6">
      <b-form-group
        class="optimizer-form-group"
        label="Traffic Source Type"
        :state="$_hasError('trafficSourceType')"
      >
        <!-- Traffic Source Type -->
        <vue-opti-select-light
          :class="[{ 'has-error': $_hasError('trafficSourceType') === false }, 'optimizer-select w-100']"
          :options="$options.trafficSourceType"
          unique-key="uniqueName"
          label-key="name"
          @change="$_setTrafficSource"
          button-placeholder="Select Traffic Source"
          button-block
          single
          :disabled="mode !== 'create' || retry"
          v-model="trafficSourceType"
          data-vv-as="Traffic Source Type"
          name="trafficSourceType"
          v-validate="'required'"
          data-vv-validate-on="change|hidden"
        >
          <template #ITEM="{ option }">
            <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.uniqueName)" alt="traffic-source-logo" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
        </vue-opti-select-light>
      </b-form-group>
    </div>
    <div v-if="hasAccountSelection" class="col-md-6">
      <b-form-group
        class="optimizer-form-group"
        label="Traffic Source Account"
        :state="$_hasError('trafficSourceAccount')"
        v-b-tooltip.hover.bottom="{
          customClass: 'custom-tooltip max-width-250',
          title: trafficSourceType ? '' : 'Please select Traffic Source type first.',
          interactive: false,
          boundary: 'viewport',
        }"
      >
        <!-- Traffic Source Account -->
        <vue-opti-select-light
          :class="[{ 'has-error': $_hasError('trafficSourceAccount') === false }, 'optimizer-select w-100']"
          :options="$c_trafficSourceAccountOptions"
          unique-key="id"
          label-key="name"
          @change="$_setAccount"
          button-placeholder="Select Account"
          button-block
          single
          searchable
          :disabled="mode === 'edit' || retry || !trafficSourceType"
          v-model="selectedAccount"
          data-vv-as="Traffic Source Account"
          name="trafficSourceAccount"
          v-validate="'required'"
          data-vv-validate-on="change|hidden"
        >
          <template #ITEM="{ option }">
            <img class="acc-item-icon new-icon" :src="getTrafficSourceLogo(option.type.uniqueName)" alt="traffic-source-logo" />
            <div class="acc-item-name">
              {{ option.name }}
            </div>
          </template>
        </vue-opti-select-light>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { TrafficSource } from '@sh/types';
import { getTrafficSourceLogo } from '@sh/helpers';
import base from '@/views/Automation/CampaignCreator/mixins/base';

export default {
  name: 'AccountSelect',
  mixins: [base],
  props: {
    value: { type: [Object, null] },
    isCampaignCreator: { type: Boolean, default: false },
  },
  data() {
    return {
      trafficSourceType: null,
      selectedAccount: null,
    };
  },
  computed: {
    $c_trafficSourceAccountOptions() {
      return this.trafficSourceType ? this.config.Account.accounts.filter((item) => item.type.uniqueName === this.trafficSourceType.uniqueName) : [];
    },
    hasAccountSelection() {
      return this.isCampaignCreator && this.trafficSourceType?.uniqueName !== TrafficSource.Facebook;
    },
  },
  created() {
    // Prepare data
    this.$options.trafficSourceType = this.config.Account.types.filter((item) => this.config.Account.allowedTypesMap[item.uniqueName]);
    this.$options.trafficSourceAccountMap = this.config.Account.accountsMap;
    // Bind Model
    this.$watch('value', (value) => {
      if (value) {
        this.trafficSourceType = value.type;
        this.selectedAccount = value;
      } else if (value === null) {
        // this.trafficSourceType = null;
        this.selectedAccount = null;
      }
    }, { immediate: true });
  },
  methods: {
    getTrafficSourceLogo,
    $_setTrafficSource() {
      this.config.Account.selectedTrafficSource = this.trafficSourceType;
      this.$emit('onTrafficSourceTypeUpdate', this.trafficSourceType);

      this.selectedAccount = null;
      this.$_emit('selectedAccount');
    },
    $_setAccount(acc) {
      if (this.trafficSourceType.uniqueName === TrafficSource.Taboola && this.mode === 'clone') {
        this.config.Account.setDestinationAccount(acc.id);
      } else {
        this.$_emit('selectedAccount');
      }
    },
  },
};
</script>
