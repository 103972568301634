/* eslint-disable import/no-cycle */
import { Status } from '@/views/Automation/CampaignCreatorV2/helpers';
import * as Ad from '@/views/Automation/CampaignCreatorV2/helpers/facebook/Ad';
import { nameValidation } from '@/views/Automation/CampaignCreatorV2/validation/Campaign';
import {
  FieldConfiguration,
  RequiredLiteralKeys,
} from '@/views/Automation/CampaignCreatorV2/validation/ValidationTypes';
import Joi from 'joi';

//  const optionalFields: Partial<Record<OptionalLiteralKeys<Ad.Create>, FieldConfiguration>> = {
//   ad_schedule_end_time: { validation: Joi.number().optional(), isActive: true },
//   ad_schedule_start_time: { validation: Joi.number().optional(), isActive: true },
//   adlabels: {
//     validation: Joi.array()
//       .items(Joi.object().keys({ name: Joi.string() }))
//       .optional(),
//     isActive: false,
//   },
//   audience_id: { validation: Joi.string().optional(), isActive: true },
//   conversion_domain: { validation: Joi.string().domain({ maxDomainSegments: 2 }).optional(), isActive: true },
// };

export enum AdABGroups {
  DEFAULT = 'DEFAULT',
  CONTROL = 'CONTROL',
  IDENTITY = 'IDENTITY',
  DESTINATION = 'DESTINATION',
  TRACKING = 'TRACKING',
  CREATIVE_ITEMS = 'CREATIVE_ITEMS',
}

export const AdABGroupPublic = {};

export const AdValidation: Record<RequiredLiteralKeys<Ad.Create>, FieldConfiguration> = {
  adset_id: { validation: Joi.number().required(), isActive: true },
  name: {
    validation: nameValidation,
    isActive: true,
    fieldGroup: AdABGroups.DEFAULT,
    readableName: 'Name',
    renderFunction(v) {
      return v.name || v;
    },
  },
  status: {
    validation: Joi.string()
      .valid(...Object.values(Status))
      .required(),
    isActive: true,
    readableName: 'Status',
    default: Status.ACTIVE,
  },
};
