import RuleModel from '@/views/Automation/Rules/config/RuleModel';

export interface ComponentData {
  showMinMax: boolean;
  config: any;
  ruleModel: RuleModel | null;
  type: string;
  form: Form;
  enableMinMax: boolean;
  alert: {
    type: string;
    message: string;
  };
}

export interface RuleBulkModalRefs {
  $refs: {
    ruleMetricCalculation: {
      $c_getField: string;
      $_isValid: Function;
      $_showInlineError: Function;
      alert: {
        message: string;
        type: string;
      };
      $_clear: Function;
      $c_skipMin: Function;
      $_getCalculatedMetric: Function;
      $c_filterBy: string;
      form: any;
    };
    createBulkModal: {
      show: Function;
      hide: Function;
    };
  };
}

export interface RuleMetricCalculationRef {
  $refs: {
    ruleBidOptions: {
      form: {
        action: string;
        unit: string;
        to: string;
      };
    };
  };
}

export interface BulkActions {
  $refs: {
    createTableModal: {
      $_clear: Function;
    };
  };
  $api: {
    [level: string]: {
      [method: string]: Function;
    };
  };
}

export type Limits = {
  max: number;
  min: number;
  step: string;
};

export interface RuleComponent {
  active: boolean;
  limits: Limits;
  template: string;
  value: {
    action: string;
    max: string;
    min: string;
    to: string;
    static: string;
    value: string;
  };
}

export interface RuleTemplate {
  action: {
    set: {
      unit: {
        static: any;
      };
    };
    increase: {
      unit: {
        static: any;
        precentage: {
          to: {
            [key: string]: any;
          };
        };
      };
    };
    decrease: {
      unit: {
        static: any;
        precentage: {
          to: {
            [key: string]: any;
          };
        };
      };
    };
  };
}

export type Options = Array<{ value: string; text: string }>;

export enum AvailableProperties {
  bid = 'bid',
  budet = 'budget',
}

export enum Levels {
  campaign = 'campaign',
  adgroup = 'adgroup',
  widget = 'widget',
  content = 'content',
}

export enum Mode {
  bulk = 'bulk',
  clone = 'clone',
}

export type RuleType = 'campaign_change_bid' | 'campaign_change_budget';
export type Action = 'set' | 'increase' | 'decrease';
export type Unit = 'static' | 'percentage';
export type BulkAction =
  | 'change_budget_bulk'
  | 'change_bid_bulk'
  | 'change_daily_budget_bulk'
  | 'change_target_cpa_bulk'
  | 'change_bid_cap_bulk';
export type MinObject = {
  unit: Unit;
  to: string;
  value: string;
};
export type MaxObject = {
  unit: Unit;
  to: string;
  value: string;
};

export type Form = {
  action: Action;
  unit: Unit;
  to: string;
  value: string;
  min: string;
  max: string;
  minObject: MinObject;
  maxObject: MaxObject;
};

export interface FinalValues {
  id: string;
  campaign_id: string;
  traffic_source_type: string;
  traffic_source_campaign_id?: string;
  traffic_source_widget_id?: string;
  traffic_source_content_id?: string;
  traffic_source_adgroup_id?: string;
  value: string | number;
  action: string;
  type: string;
  budget: number;
  level: 'campaign' | 'content' | 'widget' | 'adgroup';
  name: string;
  budget_type: string;
  searchBid: number;
  publisher_id: string;
  current: any;
  daily_budget: number;
  columnName: string;
}

export interface Item {
  user_id: number;
  platform: string;
  campaign_id: string;
  traffic_source_unique_name: string;
  traffic_source_account_id: number;
  type: string;
  id: string;
  traffic_source_campaign_id: string;
  traffic_source_widget_id: string;
  traffic_source_content_id: string;
  traffic_source_adgroup_id: string;
  name: string;
  additional_fields: {
    bid: number;
    budget: number;
    daily_budget: number;
    budget_type: string;
    bid_type: string;
  };
  cpc_native: number;
  bid: number;
  budget: number;
  budget_type: string;
  daily_budget: number;
  publisher_id: string;
  [key: string]: any;
}

export interface FailedItems {
  id: string;
  message: string;
  type: string;
  current: any;
  incomingValue: number;
  columnName: string;
}

export interface SharedProperties {
  columnName: string; // the name of the column
  type: 'budget' | 'bid'; // this specific modal can only have to types budget or bid
  conditionalField?: (i: Item) => string; //  In some traffic sources the field that will be updated can depend on other fields/properties
  hasConditionalField?: boolean;
  field: string; // the field that will be updated
  minInfo: string; // the info that appears in the (i) icon
  maxInfo: string; // the info that appears in the (i) icon
}

export interface Properties extends SharedProperties {
  method: string; // API method
  stateKey: string[] | string; // each live edit has a specific state key
  title: string; // the title of the modal
  filterBy: 'budget' | 'bid' | 'daily_budget' | 'target_cpa' | 'bid_cap'; // this field is needed to filter preview items or failed items
  fieldOptionName: string; // the name that will appear in decrease/increase
  isAdditionalField: boolean; // some fields cannot be accessed in additional_fields
  columnName: string; // the name of the column
  classSelector: string; // needed for the blue hightlight
  payloadHandler: (i: Item) => any; // the payload builder for each source & entity
  getMethod: (i: Item) => string | null; // sometimes the method name can be conditional
  hasConditionalMethod: boolean; // specifies if the method name is conditional
  isNotAnEditableField?: (i: any) => boolean; // some fields cannot be edited
  action_name: string; // the name that appears in the action dropdown
  limits: {
    step: string;
    min: string;
    max: string;
  }; // limits for each action
  skipMin?: boolean; // in cases of facebook there are some fields that cannot be changed and the value is always 0 but in athor cases the value should be the calculated min
}

export type CloneActionsProperties = SharedProperties;

export interface BulkConfig {
  name: string;
  entities: {
    [entity: string]: {
      entityName?: string;
      conditionalEntityName?: (campaign: any) => string;
      bulkActions: {
        [action: string]: Properties;
      };
      cloneActions?: {
        [clone_action: string]: CloneActionsProperties;
      };
    };
  };
}

export interface BulkOperationData {
  trafficSources: {
    [ts: string]: BulkConfig;
  };
  toBeUpdatedItems: FinalValues[];
  showApplyChangesButton: boolean;
  rowsWithPreview: any[];
  failedItems: FailedItems[];
  bulkRuleConfig: any;
  bulkAction: string;
  classSelector: string;
  disableActions: boolean;
}
