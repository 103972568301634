import { Ga4Fields, Metric } from '@sh/types/AccountWizard/GoogleAnalytics';

export enum MetricType {
  TYPE_CURRENCY = 'TYPE_CURRENCY',
  TYPE_INTEGER = 'TYPE_INTEGER',
  TYPE_FLOAT = 'TYPE_FLOAT',
  METRIC_TYPE_UNSPECIFIED = 'METRIC_TYPE_UNSPECIFIED',
  TYPE_SECONDS = 'TYPE_SECONDS',
  TYPE_MILLISECONDS = 'TYPE_MILLISECONDS',
  TYPE_MINUTES = 'TYPE_MINUTES',
  TYPE_HOURS = 'TYPE_HOURS',
  TYPE_STANDARD = 'TYPE_STANDARD',
  TYPE_FEET = 'TYPE_FEET',
  TYPE_MILES = 'TYPE_MILES',
  TYPE_METERS = 'TYPE_METERS',
  TYPE_KILOMETERS = 'TYPE_KILOMETERS',
}

export enum DBConfigType {
  MONEY = 'MONEY',
  NUMERIC = 'NUMERIC',
}

export const getDbConfigType = (type: MetricType | 'string') => {
  if (type === MetricType.TYPE_CURRENCY) {
    return DBConfigType.MONEY;
  }
  return DBConfigType.NUMERIC;
};

export const gaTypeStyle = (type: string, name: string) => {
  switch (type) {
    case MetricType.TYPE_FLOAT:
      if (name === 'Conversions') {
        return {
          format: 'numeric',
          precision: 3,
          style: '',
        };
      }
      return {
        format: 'percentage',
        precision: 3,
        style: 'color',
      };
    case MetricType.TYPE_CURRENCY:
      return {
        format: 'currency',
        precision: 3,
        style: 'color',
      };
    case MetricType.TYPE_SECONDS:
    case MetricType.TYPE_MILLISECONDS:
    case MetricType.TYPE_MINUTES:
    case MetricType.TYPE_HOURS:
      return {
        format: 'time',
        precision: 3,
        style: '',
      };
    default:
      return {
        format: 'numeric',
        precision: 3,
        style: '',
      };
  }
};

export const ga4FieldFormatter = (gaFields: Metric[]): Ga4Fields[] =>
  gaFields.map((item) => ({
    header: {
      content: `GA4: ${item.uiName}`,
    },
    item: {
      key: `ga4_${item.dbName}`,
      sortable: true,
    },
    options: gaTypeStyle(item.help?.includes('percentage') ? MetricType.TYPE_FLOAT : item.type, item.uiName),
    data: {
      text: item.uiName,
      value: item.apiName,
      dbName: item.dbName,
      group: item.category,
      formula: item.formula,
      type: item.type,
    },
    display: true,
  }));

export const getGaFieldIndex = (text?: string) => parseInt(text?.split('gafield')?.[1] ?? '1', 10);
