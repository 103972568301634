/* eslint-disable */
const getMimeType = (base64Data) => {
  const base64Str = base64Data.slice(0, 6);
  const byteString = atob(base64Str);
  const ab = new ArrayBuffer(byteString.length);
  const uint = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    uint[i] = byteString.charCodeAt(i);
  }
  const bytes = [];
  uint.forEach((byte) => {
    bytes.push(byte.toString(16));
  });
  const hex = bytes.join('').toUpperCase();
  switch (hex) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    // case '25504446':
    //     return 'application/pdf';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
    case 'FFD8FFE2':
    case 'FFD8FFE3':
    case 'FFD8FFE8':
      return 'image/jpeg';
    // case '504B0304':
    //     return 'application/zip';
    default:
      return 'unknown_filetype';
  }
};

const getInfoFromBase64Image = async (base64) => {
  const image = {
    data: null,
    width: null,
    height: null,
    size: null,
    mimeType: null,
    ext: null,
  };
  const base64Explode = base64.split(';base64,');
  let data = '';
  if (base64Explode.length === 2) {
    data = base64Explode[1];
  } else if (base64Explode.length === 1) {
    data = base64Explode[0];
  } else {
    throw new Error('Base64 non valid');
  }

  const mimeType = getMimeType(data);
  if (mimeType === 'unknown_filetype') {
    throw new Error('Unknown FileType');
  }
  image.mimeType = mimeType;
  image.ext = mimeType.split('/')[1];
  image.data = `data:${mimeType};base64,${data}`;
  image.size = parseInt(data.replace(/=/g, '').length * 0.75, 10) / 1000;
  return new Promise((resolve, reject) => {
    const _image = new Image();
    _image.onload = () => {
      image.width = _image.width;
      image.height = _image.height;
      resolve(image);
    };
    _image.onerror = reject;
    _image.src = image.data;
  });
};

const getBase64ImageSize = (base64) => {
  const base64Explode = base64.split(';base64,');
  let data = '';
  if (base64Explode.length === 2) {
    data = base64Explode[1];
  } else if (base64Explode.length === 1) {
    data = base64Explode[0];
  }
  return parseInt(data.replace(/=/g, '').length * 0.75, 10) / 1000;
};

const readUploadFileAsDataURL = async (inputFile) => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export default {
  getInfoFromBase64Array: async (base64Array) => {
    const result = await Promise.all(
      base64Array.map((base64) =>
        (async () => {
          try {
            return await getInfoFromBase64Image(base64);
          } catch (err) {
            return err;
          }
        })()
      )
    );
    const resultObj = { images: [], errors: [] };
    result.forEach((item) => {
      !(item instanceof Error) ? resultObj.images.push(item) : resultObj.errors.push(item);
    });
    return resultObj;
  },
  getInfoFromFiles: async (inputFiles) => {
    const inputFilesArray = [...inputFiles];
    const result = await Promise.all(
      inputFilesArray.map((file, index) =>
        (async () => {
          try {
            const fileBase64 = await readUploadFileAsDataURL(file);
            return await getInfoFromBase64Image(fileBase64);
          } catch (err) {
            return err;
          }
        })()
      )
    );
    const resultObj = { images: [], errors: [] };
    result.forEach((item, index) => {
      if (item instanceof Error) {
        resultObj.errors.push(item);
      } else {
        resultObj.images.push(item);
      }
    });
    return resultObj;
  },
  getInfoFromBase64Image,
  getBase64ImageSize,
  getMimeType,
};
