import { api } from '@sh/services/api';
import md5 from 'md5';
import moment from 'moment';
import CampaignModel from '../../lib/CampaignModel';
import { DAYS, SCHEDULEOPTIONS as scheduleOptions } from './consts';
import { generateIdsforAudience, generateIdsforInterests, getConversionsValue, popullateComponentCategories, popullateComponentInterests } from './helpers';
import { isObject } from 'lodash';

/**
 * API Model: {
 *  name: <String>,
 *  objective: <String>,
 *  budget: {
 *    runForever: <Boolean>,
 *    startDate: <String>,
 *    endDate: <String>,
 *    amount: <Number>,
 *    type: <String>,
 *    pacing: <String>,
 *    dailyTarget: <Number>
 *  },
 *  bids: {
 *    bySection: <Array|[{sectionId: <String>, cpcAdjustment: <Number>}]>,
 *  },
 *  startHour: <String>,
 *  cpc: <Number>,
 *  selectedLocations: <Array|[{id: <String>, name: <String>, ...}]>,
 *  targeting: {
 *    platform: <Array|[<String>]>,
 *    operatingSystems: <Array|[<String>]>,
 *    browsers: <Array|[<String>]>,
 *    excludeAdBlockUsers: <Boolean>
 *    excludedLocations: <Array|[{id: <String>, name: <String>, ...}]>,
 *    locations: <Array|[{id: <String>, name: <String>, ...}]>
 *  },
 *  suffixTrackingCode: <String>
 *  networkMSN: <Object> //Optional
 *  nativePlacementsEnabled: <Boolean>
 *  scheduling: <Object>
 *  onAirType: <String>
 *  creativeFormat: <String>
 *  iabCategories: <Object>
 *  interests: <Object>
 *  campaignOptimizationType: <String>
 *  conversions: <String>
 *  targetROAS: <Number>
 *  targetCPA: <Number>
 * }
 */

let CATEGORIES = [];
let INTERESTS = [];

export default class OutbrainCampaignModel extends CampaignModel {
  async setModel(form, nextTick, mode) {
    if (mode === 'edit') {
      this.components.creativeFormat.disabled = true;
    }
    this.components.name.value = mode === 'clone' ? `${form.name} CLONE` : form.name;
    if (this.components.objective.options.map((item) => item.value).indexOf(form.objective) > -1) {
      this.components.objective.value = form.objective;
    } else {
      this.components.objective.value = '';
    }
    this.components.runForever.value = form.budget.runForever;
    if (mode !== 'clone') {
      this.components.startDate.value = form.budget.startDate;
      if (!form.budget.runForever) {
        this.components.endDate.value = form.budget.endDate;
      }
      this.components.startHour.value = form.startHour;
    }
    this.components.cpc.value = form.cpc;
    this.components.enabled.value = form.enabled;
    this.components.amount.value = form.budget.amount;
    this.components.budgetType.value = form.budget.type;
    this.components.pacing.value = form.budget.pacing;
    if (form.budget.dailyTarget) {
      this.components.dailyTarget.value = form.budget.dailyTarget;
    }

    if (form.selectedLocations) {
      this.components.locations.value = form.selectedLocations.map((location) => location);
    }

    if (form.targeting.excludedLocations) {
      this.components.excludedLocations.value = form.targeting.excludedLocations.map((location) => location.name);
    }

    this.components.platform.value = form.targeting.platform;
    if (form.targeting.operatingSystems) {
      this.components.operatingSystems.value = form.targeting.operatingSystems;
    } else {
      this.components.operatingSystems.value = ['Ios', 'Android', 'MacOs', 'Windows'];
    }
    if (form.targeting.browsers) {
      this.components.browsers.value = form.targeting.browsers;
    } else {
      this.components.browsers.value = ['Chrome', 'Firefox', 'Safari', 'InternetExplorer', 'Opera', 'Samsung', 'UCBrowser', 'InApp'];
    }
    this.components.excludeAdBlockUsers.value = form.targeting.excludeAdBlockUsers ? [true] : [];
    this.components.suffixTrackingCode.value = form.suffixTrackingCode;
    // this.components.isTemplate.active = false;
    if (form.targeting.network) {
      this.components.networkMSN.value = [true];
    }
    this.components.nativePlacementsEnabled.value = form.targeting.nativePlacementsEnabled ? [true] : [];
    this.components.creativeFormat.value = form.creativeFormat;

    const oldUnselectedRules = scheduleOptions.rules;
    if (form.onAirType === 'Scheduled') {
      this.components.onAirType.value = [true];
      this.components.activityScheduleRules.value.rules = [];
      form.scheduling.onAir.forEach((rule) => {
        if (rule.endHour === 0) {
          rule.endHour = 24;
        }

        rule.type = [rule.startDay];
        rule.day = rule.startDay;
        rule.from_hour = rule.startHour;
        rule.until_hour = rule.endHour;

        delete rule.startDay;
        delete rule.endDay;
        delete rule.startHour;
        delete rule.endHour;
        oldUnselectedRules.shift();
        this.components.activityScheduleRules.value.rules.push(rule);
      });
      this.components.activityScheduleRules.value.rules = [...this.components.activityScheduleRules.value.rules, ...oldUnselectedRules];
    }
    this.components.audienceTargeting.value = 'ALL';
    if (form.targeting.iabCategories) {
      this.components.audienceTargeting.value = 'IAB';
      if (form.targeting.iabCategories.op === 'Or') {
        this.components.addOrRemoveOptions.value = 'INCLUDE';
        const categories = await api.campaignCreator.getOutbrainIabCategories(this.account.value.id);
        this.components.iabCategories.value = popullateComponentCategories(categories, form.targeting.iabCategories);
      } else {
        this.components.addOrRemoveOptions.value = 'EXCLUDE';
        const categories = await api.campaignCreator.getOutbrainIabCategories(this.account.value.id);
        this.components.iabCategories.value = popullateComponentCategories(categories, form.targeting.iabCategories.terms);
      }
    }
    if (form.targeting.interests) {
      this.components.audienceTargeting.value = 'INTERESTS';
      if (form.targeting.interests.op === 'Or') {
        this.components.addOrRemoveOptions.value = 'INCLUDE';
        const interests = await api.campaignCreator.getOutbrainInterests(this.account.value.id);
        this.components.interests.value = popullateComponentInterests(interests, form.targeting.interests);
      } else {
        this.components.addOrRemoveOptions.value = 'EXCLUDE';
        const interests = await api.campaignCreator.getOutbrainInterests(this.account.value.id);
        this.components.interests.value = popullateComponentInterests(interests, form.targeting.interests.terms);
      }
    }
    this.components.language.value = this.options.languageOptions.find((item) => item.value === form.targeting.language)?.text || undefined;
    if (form.campaignOptimization?.optimizationType) { this.components.campaignOptimizationType.value = form.campaignOptimization.optimizationType; }
    if (form.campaignOptimization?.targetRoas) { this.components.targetROAS.value = Number(form.campaignOptimization.targetRoas) * 100; }
    if (form.campaignOptimization?.targetCpa) {
      this.components.targetCPA.value = form.campaignOptimization.targetCpa;
    }
    if (form.campaignOptimization?.automaticModeMaxCpc || form.campaignOptimization?.automaticModeMaxAvgDailyCpc) {
      if (form.campaignOptimization?.automaticModeMaxAvgDailyCpc) {
        this.components.bidCap.label = 'Max Average CPC';
        this.components.bidCap.popover = 'The daily average CPC limit.  Our algorithm will optimize your campaign  without surpassing the set daily average.';
      }
      this.components.automaticModeMaxCpc.switchButtonChecked = true;
      this.components.automaticModeMaxCpc.value = form.campaignOptimization.automaticModeMaxCpc ? form.campaignOptimization.automaticModeMaxCpc : form.campaignOptimization.automaticModeMaxAvgDailyCpc ? form.campaignOptimization.automaticModeMaxAvgDailyCpc : undefined;
    }
    if (form.campaignOptimization?.targetRoasConversionId) {
      getConversionsValue(nextTick, this.components.conversions, [form.campaignOptimization.targetRoasConversionId], this.__helperSetMultiSelectValue);
    }
    if (form.campaignOptimization?.targetCpaConversionId) {
      getConversionsValue(nextTick, this.components.conversions, [form.campaignOptimization.targetCpaConversionId], this.__helperSetMultiSelectValue);
    }
    if (form.campaignOptimization?.targetConversionId) {
      getConversionsValue(nextTick, this.components.conversions, [form.campaignOptimization.targetConversionId], this.__helperSetMultiSelectValue);
    }
    if (form.campaignOptimization?.conversions) {
      getConversionsValue(nextTick, this.components.softConversions, form.campaignOptimization.conversions, this.__helperSetMultiSelectValue);
    }
    if (form.campaignOptimization.optimizationType === 'CPC') {
      this.components.semiManualOptimization.active = true;
      this.components.semiManualOptimization.value = 'ENABLED';
    }
    if (this.components.objective.value === 'Awareness' || this.components.objective.value === 'Traffic') {
      this.components.cpc.active = true;
      this.components.campaignOptimizationType.value = 'TRAFFIC';
      delete form.campaignOptimization;
    }
  }

  getForm() {
    const modelForm = {};
    Object.entries(this.components).forEach(([key, component]) => {
      if (component.active) {
        modelForm[key] = component.value;
      }
    });
    const budgetName = md5(Math.random()) + modelForm.name;
    const language = isObject(modelForm.language) ? modelForm.language.text : modelForm.language;
    const form = {
      campaignCreatorV2: true,
      name: modelForm.name,
      objective: modelForm.objective,
      // optimizationType: modelForm.campaignOptimizationType,
      // maxCpcBidPercentage: modelForm.maxCpcBidPercentage,
      budget: {
        name: budgetName.substring(0, 100),
        runForever: modelForm.runForever,
        startDate: modelForm.startDate,
        amount: modelForm.amount,
        type: modelForm.budgetType,
        pacing: modelForm.pacing,
      },
      startHour: modelForm.startHour,
      enabled: modelForm.enabled,
      cpc: modelForm.cpc,
      selectedLocations: [],
      targeting: {
        platform: modelForm.platform,
        operatingSystems: modelForm.operatingSystems,
        browsers: modelForm.browsers,
        excludeAdBlockUsers: modelForm.excludeAdBlockUsers[0] || false,
        nativePlacementsEnabled: modelForm.nativePlacementsEnabled[0] || false,
        network: { type: 'PublisherList', selection: [] },
        language: this.options.languageOptions.find((item) => item.text === language)?.value,
        excludedLocations: [],
      },
      suffixTrackingCode: modelForm.suffixTrackingCode,
      // isTemplate: modelForm.isTemplate[0] || false,
    };
    if (modelForm.endDate) form.budget.endDate = modelForm.endDate;
    if (modelForm.dailyTarget) form.budget.dailyTarget = modelForm.dailyTarget;
    // if (mode === 'edit' && campaign) {
    //   form.budget.id = campaign.budget.id;
    // }
    // if (['Conversions', 'AppInstalls'].includes(modelForm.objective)) {
    //   form.campaignOptimization = { optimizationType: form.optimizationType };
    //   if (form.optimizationType === 'CPC') {
    //     form.campaignOptimization.maxCpcBidPercentage = parseFloat(form.maxCpcBidPercentage / 100);
    //   }
    //   delete form.optimizationType;
    //   delete form.maxCpcBidPercentage;
    // } else if (mode === 'clone' && this.cloneMode === 'EXACT_CLONE' && campaign.bids) {
    //   // optimizationType -> ['MAX_CONVERSION_FULLY_AUTOMATED', 'TARGET_ROAS_FULLY_AUTOMATED', 'TARGET_CPA_FULLY_AUTOMATED'] are not allowed
    //   form.bids = campaign.bids;
    // }

    if (['Conversions', 'AppInstalls'].includes(modelForm.objective)) {
      form.campaignOptimization = { optimizationType: modelForm.campaignOptimizationType };
      if (modelForm.automaticModeMaxCpc && modelForm.automaticModeMaxCpc !== '') {
        form.campaignOptimization.automaticModeMaxCpc = modelForm.automaticModeMaxCpc;
      }
      const semiManualOptimization = modelForm.semiManualOptimization === 'ENABLED';
      if (semiManualOptimization) {
        form.campaignOptimization.optimizationType = 'CPC';
        form.campaignOptimization.conversions = modelForm.softConversions.map((conversion) => conversion.value);
        form.campaignOptimization.targetConversionId = modelForm.conversions.value || modelForm.conversions[0].value;
        form.cpc = modelForm.cpc;
      } else if (modelForm.campaignOptimizationType === 'TARGET_ROAS_FULLY_AUTOMATED') {
        form.campaignOptimization.targetRoas = parseFloat(modelForm.targetROAS / 100);
        form.campaignOptimization.conversions = modelForm.softConversions.map((conversion) => conversion.value);
        form.campaignOptimization.targetRoasConversionId = modelForm.conversions.value || modelForm.conversions[0].value;
        form.cpc = undefined;
      } else if (modelForm.campaignOptimizationType === 'TARGET_CPA_FULLY_AUTOMATED') {
        form.campaignOptimization.targetCpa = modelForm.targetCPA;
        form.campaignOptimization.conversions = modelForm.softConversions.map((conversion) => conversion.value);
        form.campaignOptimization.targetCpaConversionId = modelForm.conversions.value || modelForm.conversions[0].value;
        form.cpc = undefined;
      } else if (modelForm.campaignOptimizationType === 'MAX_CONVERSION_FULLY_AUTOMATED') {
        form.campaignOptimization.conversions = modelForm.softConversions.map((conversion) => conversion.value);
        form.campaignOptimization.targetConversionId = modelForm.conversions.value || modelForm.conversions[0].value;
        form.cpc = undefined;
      } else if (!semiManualOptimization) {
        form.campaignOptimization.conversions = [modelForm.conversions.value];
        form.cpc = undefined;
        // form.campaignOptimization.experimentEnabled = false;
      }
    } else if (modelForm.objective === 'Traffic') {
      form.campaignOptimization = { optimizationType: 'TRAFFIC' };
    } else if (modelForm.objective === 'Awareness') {
      form.campaignOptimization = { };
    }

    form.selectedLocations = modelForm.locations.map((location) => location);
    form.targeting.excludedLocations = modelForm.excludedLocations.map((location) => location);

    if (modelForm.networkMSN.length) {
      form.targeting.network = { type: 'PublisherList', selection: ['005989514658fab332ace27125dc90eb0c'] };
    }

    if (modelForm.audienceTargeting === 'IAB') {
      const ids = generateIdsforAudience(modelForm.iabCategories, CATEGORIES);
      if (modelForm.addOrRemoveOptions === 'INCLUDE' && ids.length) {
        form.targeting.iabCategories = { op: 'Or', ids };
      } else if (ids.length) {
        form.targeting.iabCategories = { op: 'Not', terms: { op: 'Or', ids } };
      }
    } else if (modelForm.audienceTargeting === 'INTERESTS') {
      const ids = generateIdsforInterests(modelForm.interests, INTERESTS);
      if (modelForm.addOrRemoveOptions === 'INCLUDE' && ids.length) {
        form.targeting.interests = { op: 'Or', ids };
      } else if (ids.length) {
        form.targeting.interests = { op: 'Not', terms: { op: 'Or', ids } };
      }
    }

    form.creativeFormat = modelForm.creativeFormat;
    form.scheduling = {};
    if (modelForm.onAirType.length) {
      form.scheduling.onAir = [];
      modelForm.activityScheduleRules.rules.forEach((schedule) => {
        if (schedule.type?.length) {
          [schedule.startDay] = schedule.type;
          [schedule.endDay] = schedule.type;
          schedule.startHour = Number(schedule.from_hour);
          schedule.endHour = Number(schedule.until_hour);

          delete schedule.from_hour;
          delete schedule.until_hour;
          delete schedule.type;
          delete schedule.day;

          if (schedule.endHour === 24) {
            const index = DAYS.indexOf(schedule.startDay);
            schedule.endHour = 0;
            schedule.endDay = DAYS[index + 1];
            if (!schedule.endDay) {
              schedule.endDay = 'Monday';
            }
          }
          form.scheduling.onAir.push(schedule);
        }
      });

      if (form.scheduling.onAir.length) {
        form.onAirType = 'Scheduled';
      } else {
        form.scheduling = {};
        form.onAirType = 'StartHour';
      }
    }

    if (modelForm.semiManualOptimization === 'DISABLED' && form.cpc) {
      form.cpc = undefined;
    }

    return form;
  }

  // OPTIONS
  __generateOptions() {
    return {
      objectiveOptions: [
        { text: 'Awareness', value: 'Awareness', icon: 'fa fa-bullhorn' },
        { text: 'Traffic', value: 'Traffic', icon: 'fa fa-car' },
        { text: 'AppInstalls', value: 'AppInstalls', icon: 'fa fa-mobile' },
        { text: 'Conversions', value: 'Conversions', icon: 'fa fa-filter' },
      ],
      // optimizationType: [
      //   { text: 'Fully automated', value: 'MAX_CONVERSION_FULLY_AUTOMATED' },
      //   { text: 'Semi automated', value: 'CPC' },
      // ],
      runForeverOptions: [
        { text: 'Run continuously', value: true },
        { text: 'Set dates', value: false },
      ],
      budgetTypeOptions: [
        { text: 'Campaign', value: 'CAMPAIGN' },
        { text: 'Monthly', value: 'MONTHLY' },
        { text: 'Daily', value: 'DAILY' },
      ],
      pacingOptions: [
        { text: 'Asap', value: 'SPEND_ASAP', disabled: false },
        { text: 'Automatic', value: 'AUTOMATIC', disabled: false },
        { text: 'Daily target', value: 'DAILY_TARGET', disabled: false },
      ],
      semiManualOptions: [
        { text: 'Enabled', value: 'ENABLED', disabled: false },
        { text: 'Disabled', value: 'DISABLED', disabled: false },
      ],
      campaingStatusOptions: [
        { text: 'Active', value: true },
        { text: 'Paused', value: false },
      ],
      getLocations: async (term) => {
        if (term.length < 2) return [];
        const options = await api.campaignCreator.searchOutbrainLocations(this.account.value.id, term);
        return options;
      },
      platformOptions: [
        { text: 'Desktop', value: 'DESKTOP' },
        { text: 'Mobile', value: 'MOBILE' },
        { text: 'Tablet', value: 'TABLET' },
      ],
      osOptions: [
        { text: 'iOS', value: 'Ios' },
        { text: 'Android', value: 'Android' },
        { text: 'MacOs', value: 'MacOs' },
        { text: 'Windows', value: 'Windows' },
      ],
      browserOptions: [
        { text: 'Chrome', value: 'Chrome' },
        { text: 'Firefox', value: 'Firefox' },
        { text: 'Safari', value: 'Safari' },
        { text: 'Internet Explorer', value: 'InternetExplorer' },
        { text: 'Opera', value: 'Opera' },
        { text: 'Samsung', value: 'Samsung' },
        { text: 'UC Browser', value: 'UCBrowser' },
        { text: 'InApp', value: 'InApp' },
      ],
      adBlockOptions: [{ text: 'Exclude AdBlock users', value: true }],
      networkMSNOptions: [{ text: 'Run on Microsoft News (MSN) only', value: true }],
      nativePlacementsEnabled: [
        { text: 'Run in single, brand exclusive placements across our publishers', value: true },
      ],
      audienceTargetingOptions: [
        { text: 'Outbrain’s full audience', value: 'ALL' },
        { text: 'Contextual (IAB)', value: 'IAB' },
        { text: 'Outbrain Interests', value: 'INTERESTS' },
      ],
      addOrRemoveOptions: [
        { text: 'Include', value: 'INCLUDE' },
        { text: 'Exlude', value: 'EXCLUDE' },
      ],
      creativeFormatOptions: [
        { text: 'Standard', value: 'Standard', icon: 'fa fa-picture-o' },
        { text: 'Clip', value: 'Clip', icon: 'fa fa-film' },
        { text: 'Carousel', value: 'Carousel', icon: 'fa fa-trello' },
      ],
      onAirTypeOptions: [{ text: 'Specific days and hours', value: true }],
      campaignOptimizationTypeOptions: [
        {
          text: 'Max conversions',
          value: 'MAX_CONVERSION_FULLY_AUTOMATED',
          icon: 'fa fa fa-line-chart',
          textBottom: 'test',
        },
        { text: 'Target ROAS', value: 'TARGET_ROAS_FULLY_AUTOMATED', icon: 'fa fa-shopping-cart' },
        { text: 'Target CPA', value: 'TARGET_CPA_FULLY_AUTOMATED', icon: 'fa fa-filter' },
      ],
      languageOptions: [
        { text: 'Slovenian', value: 'sl' },
        { text: 'Lithuanian', value: 'lt' },
        { text: 'Indonesian', value: 'id' },
        { text: 'Norwegian', value: 'no' },
        { text: 'Bulgarian', value: 'bg' },
        { text: 'Vietnamese', value: 'vi' },
        { text: 'Telugu', value: 'te' },
        { text: 'Hebrew', value: 'he' },
        { text: 'French', value: 'fr' },
        { text: 'Czech', value: 'cs' },
        { text: 'Croatian', value: 'hr' },
        { text: 'Georgian', value: 'ka' },
        { text: 'Russian', value: 'ru' },
        { text: 'Latvian', value: 'lv' },
        { text: 'English', value: 'en' },
        { text: 'Ukrainian', value: 'uk' },
        { text: 'Turkish', value: 'tr' },
        { text: 'Albanian', value: 'sq' },
        { text: 'Chinese', value: 'zh' },
        { text: 'Romanian', value: 'ro' },
        { text: 'Dutch', value: 'nl' },
        { text: 'Serbian', value: 'sr' },
        { text: 'Korean', value: 'ko' },
        { text: 'Italian', value: 'it' },
        { text: 'Thai', value: 'th' },
        { text: 'German', value: 'de' },
        { text: 'Spanish', value: 'es' },
        { text: 'Slovak', value: 'sk' },
        { text: 'Greek', value: 'el' },
        { text: 'Japanese', value: 'ja' },
        { text: 'Arabic', value: 'ar' },
        { text: 'Estonian', value: 'et' },
        { text: 'Portuguese', value: 'pt' },
        { text: 'Polish', value: 'pl' },
        { text: 'Hungarian', value: 'hu' },
        { text: 'Danish', value: 'da' },
        { text: 'Finnish', value: 'fi' },
        { text: 'Swedish', value: 'sv' },
        { text: 'Armenian', value: 'hy' },
        { text: 'Hindi', value: 'hi' },
      ],

      scheduleOptions,
      getConversionEvents: async () => {
        const { conversionEvents } = await api.campaignCreator.getOutbrainConversions(this.account.value.id);
        return conversionEvents.map((conversion) => ({ name: conversion.name, value: conversion.id }));
      },
      getCategories: async () => {
        const { categories } = await api.campaignCreator.getOutbrainIabCategories(this.account.value.id);
        CATEGORIES = categories;
        const result = categories.map((category) => ({
          text: category.categoryName,
          value: category.categoryId,
          children: category.children.map((child) => ({ text: child.categoryName, value: child.categoryId })),
        }));
        return result;
      },
      getInterests: async () => {
        const { interests } = await api.campaignCreator.getOutbrainInterests(this.account.value.id);
        INTERESTS = interests;
        const result = interests.map((interes) => ({
          text: interes.name,
          value: interes.id,
          children: interes.subConcepts.map((subConcept) => ({ text: subConcept.name, value: subConcept.id })),
        }));

        return result;
      },
    };
  }

  __generatePopOver() {
    return {
      locationsPopover: {
        content: 'Enter the locations that you would like to target, either by country, region, or DMA (in U.S.), or by postal code. If no locations are entered, global audiences within your content\'s language are targeted.',
        html: true,
      },
      networkMSNPopover: {
        content: '<div>MSN has specific advertising guidelines,lease ensure full compliance.</div><br><a href="https://www.outbrain.com/help/advertisers/msn-exclusive-feature/" target="_blank">Learn More</a>',
        html: true,
      },
      nativePlacementsEnabledPopover: {
        content: '<div>Requires a minimum CPC of $1.00 (USD)</div><br><a href="https://www.outbrain.com/help/advertisers/what-are-high-impact-placements/" target="_blank">Learn More</a>',
        html: true,
      },
      campaignOptimizationPopover: {
        content: '<a href="https://www.outbrain.com/help/advertisers/what-are-high-impact-placements/" target="_blank">Learn more: CBS best practices</a>',
        html: true,
      },
      conversionPopover: {
        content: 'Select the conversion Outbrain would optimize towards.Optimization would be based on click-through conversions.<br><a href="https://www.outbrain.com/help/advertisers/which-bid-strategy-is-right-for-my-campaign-objectives/" target="_blank">Learn more</a>',
        html: true,
      },
      softConversionsPopover: {
        content: 'Upper funnel conversions that are part of the same funnel (Usually 10%-20% CVR).  These will help our algorithm direct relevant traffic on your campaign.<br><a href="https://www.outbrain.com/help/advertisers/troubleshoot-pixel-installation/" target="_blank">Learn more</a>',
        html: true,
      },
      semiManualOptimizationPopover: {
        content: 'Directs traffic to the highest-converting publisher sections.',
        html: true,
      },
      languagePopover: {
        content: 'Select the language of the users that the campaign will serve for.<br><a href="https://www.outbrain.com/help/advertisers/define-language-settings/?s=language" target="_blank">Learn more</a>',
        html: true,
      },
      excludeLocationsPopover: {
        content: 'Enter the locations that you would like to exclude, either by country, region, or DMA (in U.S.), or by postal code. If no locations are entered, global audiences within your content\'s language are targeted.',
        html: true,
      },
      bidCap: {
        content: 'The maximum CPC you are willing to pay for auctions with higher expected conversion rate. Your average CPC will typically belower than the Bid Cap.',
        html: true,
      },
    };
  }

  // MODEL
  __generateComponents() {
    const components = {
      name: {
        active: true, component: 'cc-input', value: '', label: 'Name Your Campaign', 'v-validate': 'required',
      },
      objective: {
        active: true, component: 'cc-radio-button', value: 'Awareness', label: 'Campaign Objective', 'v-validate': 'required', options: this.options.objectiveOptions, gap: true, textBottom: null,
      },
      // optimizationType: {
      //   active: false, component: 'cc-radio-button', value: '', label: 'Campaign Optimization Type', 'v-validate': '', options: this.options.optimizationType, gap: true, textBottom: null,
      // },
      runForever: {
        active: true, component: 'cc-radio-button', value: true, label: 'Start Date', 'v-validate': 'required', options: this.options.runForeverOptions,
      },
      startDate: {
        active: true, component: 'cc-date-picker', value: '', label: 'Start Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select Start Date',
      },
      endDate: {
        active: false, component: 'cc-date-picker', value: '', label: 'End Date', disableLabel: true, 'v-validate': 'required', disabledDate: { to: this.data.today }, placeholder: 'Select End Date',
      },
      startHour: {
        active: true, component: 'cc-time-picker', value: '', label: 'On Air Time', disableLabel: true, 'v-validate': 'required', textRight: 'Eastern Standard Time (UTC-05:00), NYC', placeholder: 'Start Time',
      },
      cpc: {
        active: true, component: 'cc-input', value: '', label: 'Cost per Click', 'v-validate': 'required|min_value:0|excluded:0', left: '$', right: 'per click',
      },
      maxCpcBidPercentage: {
        active: false, component: 'cc-input', value: '', label: 'Max Cpc Bid Percentage', 'v-validate': '', right: '%',
      },
      amount: {
        active: true, component: 'cc-input', value: 20, label: 'Budget', 'v-validate': 'required|min_value:20', left: '$',
      },
      budgetType: {
        active: true, component: 'cc-radio-button', value: 'DAILY', label: 'Budget Type', disableLabel: true, 'v-validate': 'required', options: this.options.budgetTypeOptions,
      },
      pacing: {
        active: true, component: 'cc-radio-button', value: 'SPEND_ASAP', label: 'Pacing', 'v-validate': 'required', options: this.options.pacingOptions,
      },
      dailyTarget: {
        active: false, component: 'cc-input', value: '', label: 'Target', disableLabel: true, 'v-validate': 'required|min_value:1', left: '$',
      },
      locations: {
        active: true, component: 'cc-multi-select', value: [], label: 'Location', options: [], getSearchOptions: this.options.getLocations, optionLabel: 'name', placeholder: 'Start typing locations you want to include', popover: this.popover.locationsPopover,
      },
      excludedLocations: {
        active: true, component: 'cc-multi-select', value: [], label: 'Location', options: [], getSearchOptions: this.options.getLocations, optionLabel: 'name', placeholder: 'Start typing locations you want to exclude', popover: this.popover.excludeLocationsPopover,
      },
      platform: {
        active: true, component: 'cc-checkbox', value: ['DESKTOP', 'MOBILE', 'TABLET'], label: 'Device Targeting', 'v-validate': 'required', options: this.options.platformOptions,
      },
      operatingSystems: {
        active: true, component: 'cc-checkbox', value: ['Ios', 'Android', 'MacOs', 'Windows'], label: 'Operating System', 'v-validate': 'required', options: this.options.osOptions,
      },
      browsers: {
        active: true, component: 'cc-checkbox', value: ['Chrome', 'Firefox', 'Safari', 'InternetExplorer', 'Opera', 'Samsung', 'UCBrowser', 'InApp'], label: 'Browsers', 'v-validate': 'required', options: this.options.browserOptions,
      },
      excludeAdBlockUsers: {
        active: true, component: 'cc-checkbox', value: [true], label: 'AdBlock', options: this.options.adBlockOptions,
      },
      suffixTrackingCode: {
        active: true, component: 'cc-textarea', value: '', label: 'Tracking Code', placeholder: 'Tracking Code', rows: 3,
      },
      includeLocation: {
        active: true, component: 'cc-radio-button', value: 'INCLUDE', label: 'Include Location', 'v-validate': 'required', options: [{ value: 'INCLUDE', text: 'Include Location' }], popover: this.popover.locationsPopover,
      },
      excludeLocation: {
        active: true, component: 'cc-radio-button', value: 'EXCLUDE', label: 'Exclude Location', 'v-validate': 'required', options: [{ value: 'EXCLUDE', text: 'Exclude Location' }], popover: this.popover.excludeLocationsPopover,
      },
      networkMSN: {
        active: true, component: 'cc-checkbox', value: [], label: 'MSN exclusively', rows: 3, options: this.options.networkMSNOptions, popover: this.popover.networkMSNPopover, disabled: false,
      },
      nativePlacementsEnabled: {
        active: true, component: 'cc-checkbox', value: [], label: 'High impact placements', rows: 3, options: this.options.nativePlacementsEnabled, popover: this.popover.nativePlacementsEnabledPopover,
      },
      audienceTargeting: {
        active: true, component: 'cc-radio-button', value: 'ALL', label: '', 'v-validate': 'required', options: this.options.audienceTargetingOptions,
      },
      iabCategories: {
        active: false, component: 'cc-targeting-categories', value: [], label: '', options: [], getOptions: this.options.getCategories,
      },
      language: {
        active: true, component: 'cc-multi-select', value: [], label: 'Language', 'v-validate': 'required', options: this.options.languageOptions, popover: this.popover.languagePopover, optionLabel: 'text', placeholder: 'Select Language', multiple: false,
      },
      addOrRemoveOptions: {
        active: true, component: 'cc-radio-button', value: 'INCLUDE', label: '', 'v-validate': 'required', options: this.options.addOrRemoveOptions,
      },
      interests: {
        active: false, component: 'cc-targeting-categories', value: [], label: '', options: [], getOptions: this.options.getInterests,
      },
      creativeFormat: {
        active: true, component: 'cc-radio-button', value: 'Standard', label: '', 'v-validate': 'required', options: this.options.creativeFormatOptions, disabled: false,
      },
      onAirType: {
        active: true, component: 'cc-checkbox', value: [], label: '', options: this.options.onAirTypeOptions,
      },
      activityScheduleRules: {
        active: false, component: 'cc-activity-schedule', value: this.options.scheduleOptions, label: '', options: this.options.scheduleOptions,
      },
      campaignOptimizationType: {
        active: false, component: 'cc-radio-button', value: 'MAX_CONVERSION_FULLY_AUTOMATED', label: 'Conversion bid strategy', options: this.options.campaignOptimizationTypeOptions, popover: this.popover.campaignOptimizationPopover,
      },
      conversions: {
        active: false, component: 'cc-multi-select', value: [], label: 'Conversions', 'v-validate': 'required', options: [], getOptions: this.options.getConversionEvents, optionLabel: 'name', placeholder: 'Select a conversion', popover: this.popover.conversionPopover, multiple: false,
      },
      softConversions: {
        active: false, component: 'cc-multi-select', value: [], label: 'Supporting soft conversions', options: [], getOptions: this.options.getConversionEvents, optionLabel: 'name', placeholder: 'Select additional conversions that are part of the same funnel(recommended)', popover: this.popover.softConversionsPopover, multiple: true,
      },
      targetROAS: {
        active: false, component: 'cc-input', value: '', label: 'Target ROAS', 'v-validate': 'required|min_value:1|max_value:10000', left: '%',
      },
      targetCPA: {
        active: false, component: 'cc-input', value: '', label: 'Target CPA', 'v-validate': 'required|min_value:0.001', left: '$',
      },
      automaticModeMaxCpc: {
        active: false, component: 'cc-input', label: '', left: '$', switchButtonChecked: false, popover: this.popover.bidCap,
      },
      bidCap: {
        active: true, label: 'Bid Cap', popover: this.popover.bidCap,
      },
      semiManualOptimization: {
        active: false, component: 'cc-radio-button', value: 'DISABLED', label: 'Semi manual optimization', options: this.options.semiManualOptions, 'v-validate': 'required', popover: this.popover.semiManualOptimizationPopover,
      },
      conversionBidStrategySection: {
        active: false, budgetSectionIndex: 6,
      },
      enabled: {
        active: true, component: 'cc-radio-button', value: true, label: 'Status After Creation', 'v-validate': 'required', options: this.options.campaingStatusOptions, popover: this.popover.statusPopover, gap: true,
      },
      // isTemplate: {
      //   active: true, component: 'cc-checkbox', label: 'Template', value: [], options: [{ value: true, text: 'Save also as template' }],
      // },
    };
    return components;
  }

  // LOGIC
  __generateWatchList() {
    const _this = this;
    return {
      objective: {
        fn: (value) => {
          if (['Conversions', 'AppInstalls'].includes(value)) {
            _this.components.conversionBidStrategySection.active = true;
            _this.components.automaticModeMaxCpc.active = true;
            _this.components.campaignOptimizationType.value = 'MAX_CONVERSION_FULLY_AUTOMATED';
            _this.components.objective.textBottom = 'Conversion bid strategy will be set to “Fully Automatic”. Other options are not supported yet. So if you need to chose one of the other bid strategy options please do so from Outbrain dashboard. ';
            // _this.components.optimizationType.active = true;
            // _this.components.optimizationType.value = '';
            // _this.components.optimizationType['v-validate'] = 'required';
            _this.components.campaignOptimizationType.active = true;
            _this.components.conversions.active = true;
            _this.components.softConversions.active = true;
            _this.components.semiManualOptimization.active = true;
            _this.components.cpc.active = false;
            if (_this.components.semiManualOptimization.value === 'ENABLED') {
              this.components.cpc.active = true;
              this.components.campaignOptimizationType.disabled = true;
            } else if (_this.components.campaignOptimizationType.value === 'TARGET_ROAS_FULLY_AUTOMATED') {
              this.components.targetROAS.active = true;
            } else if (_this.components.campaignOptimizationType.value === 'TARGET_CPA_FULLY_AUTOMATED') {
              this.components.targetCPA.active = true;
            }
          } else {
            // _this.components.optimizationType.value = '';
            // _this.components.optimizationType['v-validate'] = '';
            // _this.components.optimizationType.active = false;
            _this.components.conversionBidStrategySection.active = false;
            _this.components.objective.textBottom = null;
            _this.components.campaignOptimizationType.active = false;
            _this.components.conversions.active = false;
            _this.components.softConversions.active = false;
            _this.components.semiManualOptimization.active = false;
            _this.components.cpc.active = true;
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = false;
            _this.components.automaticModeMaxCpc.active = false;
          }
        },
      },
      // optimizationType: {
      //   fn: (value) => {
      //     console.log(this.account.isTe);
      //     if (value === 'CPC') {
      //       _this.components.maxCpcBidPercentage.active = true;
      //       _this.components.maxCpcBidPercentage.value = 100;
      //       _this.components.maxCpcBidPercentage['v-validate'] = 'required|max_value:100|min_value:30';
      //     } else {
      //       _this.components.maxCpcBidPercentage.active = false;
      //       _this.components.maxCpcBidPercentage.value = '';
      //       _this.components.maxCpcBidPercentage['v-validate'] = '';
      //     }
      //   },
      // },
      runForever: {
        fn: (value) => {
          if (value) {
            _this.components.endDate.active = false;
            _this.components.endDate.value = '';
            _this.components.endDate.disabledDate.to = _this.data.today;
          } else {
            _this.components.endDate.active = true;
          }
        },
      },
      semiManualOptimization: {
        fn: (value) => {
          if (value === 'ENABLED') {
            _this.components.campaignOptimizationType.disabled = true;
            _this.components.campaignOptimizationType.value = 'CPC';
            _this.components.campaignOptimizationType.textBottom = '';
            _this.components.cpc.active = true;
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = false;
          } else {
            _this.components.campaignOptimizationType.disabled = false;
            _this.components.cpc.active = false;
            if (['Awareness', 'Traffic'].includes(_this.components.objective.value)) {
              _this.components.campaignOptimizationType.value = 'TRAFFIC';
            } else if (['Conversions', 'AppInstalls'].includes(_this.components.objective.value)) {
              _this.components.campaignOptimizationType.value = 'MAX_CONVERSION_FULLY_AUTOMATED';
            }
          }
        },
      },
      // Start Date <=> End Date // Logic
      startDate: {
        fn: (value) => {
          const startDate = value;
          const endDate = _this.components.endDate.value;
          if (startDate) {
            _this.components.endDate.disabledDate.to = new Date(startDate);
            if (endDate && moment(startDate) > moment(endDate)) {
              _this.components.endDate.value = '';
            }
          } else {
            _this.components.endDate.disabledDate.to = _this.data.today;
          }
        },
      },
      budgetType: {
        fn: (value) => {
          if (_this.mode === 'create') {
            if (value === 'MONTHLY') {
              _this.components.amount['v-validate'] = 'required|min_value:600';
            } else {
              _this.components.amount['v-validate'] = 'required|min_value:20';
            }
          }

          if (value === 'DAILY') {
            _this.components.pacing.options.forEach((option) => {
              option.disabled = option.value !== 'SPEND_ASAP';
              _this.components.pacing.value = 'SPEND_ASAP';
            });
          } else {
            _this.components.pacing.options.forEach((option) => { option.disabled = false; });
          }
        },
      },
      pacing: {
        fn: (value) => {
          if (value === 'DAILY_TARGET') {
            _this.components.dailyTarget.active = true;
          } else {
            _this.components.dailyTarget.active = false;
            _this.components.dailyTarget.value = '';
          }
        },
      },
      audienceTargeting: {
        fn: (value) => {
          _this.resetComponent('iabCategories');
          _this.resetComponent('interests');
          if (value === 'IAB') {
            _this.components.iabCategories.active = true;
            _this.resetComponent('interests');
          } else if (value === 'INTERESTS') {
            _this.components.interests.active = true;
            _this.resetComponent('iabCategories');
          }
        },
      },
      onAirType: {
        fn: (value) => {
          if (value.length) {
            _this.components.activityScheduleRules.active = true;
          } else {
            _this.components.activityScheduleRules.active = false;
          }
        },
      },
      campaignOptimizationType: {
        fn: (value) => {
          if (value === 'MAX_CONVERSION_FULLY_AUTOMATED') {
            _this.components.campaignOptimizationType.textBottom = 'Generate as many conversions possible given your allotted budget';
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = false;
            _this.components.cpc.active = false;
          } else if (value === 'TARGET_ROAS_FULLY_AUTOMATED') {
            _this.components.campaignOptimizationType.textBottom = 'Generate conversions while aiming for a specific return-on-ad-spend (ROAS) goal. Best for purchase-oriented campaigns.';
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = true;
            _this.components.cpc.active = false;
          } else if (value === 'TRAFFIC') {
            _this.components.campaignOptimizationType.textBottom = 'Generate conversions while aiming for a specific cost-per-acquisition (CPA) goal.';
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = false;
            _this.components.cpc.active = true;
          } else if (value === 'CPC') {
            _this.components.campaignOptimizationType.diabled = true;
            _this.components.campaignOptimizationType.textBottom = '';
            _this.components.cpc.active = true;
            _this.components.targetCPA.active = false;
            _this.components.targetROAS.active = false;
          } else {
            _this.components.campaignOptimizationType.textBottom = '';
            _this.components.targetCPA.active = true;
            _this.components.targetROAS.active = false;
            _this.components.cpc.active = false;
          }
        },
      },
      creativeFormat: {
        fn: (value) => {
          _this.components.networkMSN.disabled = value !== 'Standard';
        },
      },
      conversions: {
        fn: (value) => {
          if (value) {
            if (_this.mode !== 'create') {
              if (value.length > 0) {
                [value] = value;
              }
            }
            if (_this.components.softConversions.value.find((conversion) => conversion.value === value.value)) {
              const findIndex = _this.components.softConversions.value.findIndex((conversion) => conversion.value === value.value);
              this.components.softConversions.value = this.components.softConversions.value.filter((conversion, index) => index !== findIndex);
            }
            _this.components.softConversions.options = _this.components.softConversions.options.map((option) => {
              if (value.name.includes(option.name)) {
                option.disabled = true;
              } else {
                option.disabled = false;
              }
              return option;
            });
          } else {
            _this.components.softConversions.value = [];
          }
        },
      },
    };
  }
}
