import { api } from '@sh/services/api';

const backendApi = {
  getActivityLogsWithPages(query, pagination) {
    return api.activityLogs.getActivityLogsWithPages(query, pagination);
  },
  getAllCampaigns(tsTypes) {
    return api.campaigns.all(tsTypes);
  },
};

// eslint-disable-next-line import/prefer-default-export
export { backendApi };
