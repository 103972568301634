
import { useMediaManagerStore } from '@/stores/media-manager';
import { useTrafficSourcesStore } from '@/stores/traffic-sources';
import AdActionButton from '@sh/components/MediaManager/AdActionButton/AdActionButton.ts.vue';
import AdCheckbox from '@sh/components/MediaManager/AdCheckbox/AdCheckbox.ts.vue';
import BulkTagInsertModal from '@sh/components/MediaManager/BulkTagInsertModal/BulkTagInsertModal.ts.vue';
import MetricFilter from '@sh/components/MediaManager/MetricFilter/MetricFilter.ts.vue';
import TagFilter from '@sh/components/MediaManager/TagFilter/TagFilter.ts.vue';
import AccountsFooter from '@sh/components/Utils/AccountsFilters/AccountsFooter.ts.vue';
import Checkbox from '@sh/components/Utils/Checkbox.ts.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { MediaManagerHelpers, TheOptimizer } from '@sh/helpers';
import notifications from '@sh/mixins/notifications';
import { MediaManager } from '@sh/types';
import { BDropdown } from 'bootstrap-vue';
import { cloneDeep, isArray, union, uniq, uniqBy } from 'lodash';
import { mapActions, mapState, mapWritableState } from 'pinia';
import Vue, { VueConstructor } from 'vue';

interface ComponentData {
  trafficSources: MediaManager.ResponseTypes.TrafficSource[];
  accounts: MediaManager.ResponseTypes.Account[];
  sortBy?: MediaManager.ResponseTypes.OrderDropDown;
  isBulkLoading: boolean;
  tags: Array<string>;
  TAGS_LIMIT: number;
  COMPONENT_ID: string;
}

interface ComponentRefs {
  $refs: {
    // @ts-ignore
    adBulkTagInsert: NullableType<InstanceType<typeof BulkTagInsertModal>>;
  };
}

type ComponentTypes = VueConstructor<Vue & NotificationMixin & ComponentRefs>;
const VueComponent = Vue as ComponentTypes;

export default VueComponent.extend({
  components: {
    AccountsFooter,
    Checkbox,
    AdCheckbox,
    AdActionButton,
    TagFilter,
    MetricFilter,
    OptimizerIcon,
    BulkTagInsertModal,
  },
  mixins: [notifications],
  data(): ComponentData {
    return {
      TAGS_LIMIT: 4,
      COMPONENT_ID: 'CreativesFilter',
      trafficSources: [],
      accounts: [],
      tags: [],
      sortBy: undefined,
      isBulkLoading: false,
    };
  },
  computed: {
    selectedAccountsLength(): number {
      return uniqBy(this.accounts, 'value').length;
    },
    selectState(): { indeterminate: boolean; allAccountsSelected: boolean } {
      return {
        indeterminate: (this.accounts.length && this.accounts.length !== this.accountOptions.length) || false,
        allAccountsSelected: this.accounts.length === this.accountOptions.length,
      };
    },
    filteredAvailableTagsForAccounts(): string[] {
      const ts = this.trafficSources.map((ts) => ts.name);
      return this.trafficSources ? this.getAllAvailableTagsForAccounts(ts) : this.getTags;
    },
    availableTagsForAccountsLength(): boolean {
      const totalLength = this.filteredAvailableTagsForAccounts.join('').length;
      return totalLength > 55 && this.filteredAvailableTagsForAccounts.length > this.TAGS_LIMIT;
    },
    replaceMode(): string {
      return this.mode === MediaManager.Mode.ADS ? 'Creative' : this.mode;
    },
    showLoader(): boolean {
      return !(
        this.settings.metricsDropdown.length ||
        this.settings.uniqueTags.length ||
        this.settings.uniqueTrafficSources.length ||
        this.settings.accounts.length ||
        this.settings.orderDropDown.length
      );
    },
    MediaManagerMode() {
      return MediaManager.Mode;
    },
    TheOptimizer() {
      return TheOptimizer;
    },
    actionType() {
      return MediaManager.ActionType;
    },
    disabledFilterTooltip(): string {
      if (this.isTheOptimizerMode && !this.hasTrafficSourceFilter) {
        return `This filter is not available for uploaded ${this.mode}s.`;
      }
      return '';
    },
    allSelected(): boolean {
      return this.selectedAds.length === this.ads.length && !!this.ads.length && !this.isLoadingAds;
    },
    someSelected(): boolean {
      return (
        !!this.selectedAds.length &&
        ((this.selectedAds.length === this.ads.length && this.isLoadingAds) ||
          this.selectedAds.length !== this.ads.length)
      );
    },
    trafficSourcesOptions(): MediaManager.ResponseTypes.TrafficSource[] {
      return this.settings.uniqueTrafficSources.filter((trafficSource) => !trafficSource.name.includes(TheOptimizer));
    },
    autocompleteItems(): MediaManager.Tag[] {
      return this.settings.uniqueTags.map((text) => ({ text }));
    },
    accountOptions(): MediaManager.ResponseTypes.Account[] {
      return this.settings.accounts.filter((account) => {
        if (this.trafficSources.length) {
          return !!this.trafficSources.find(
            (trafficSource) => trafficSource.name === account.traffic_source_unique_name
          );
        }
        return true;
      });
    },
    ...mapState(useTrafficSourcesStore, [
      'getAllAvailableTagsForAccounts',
      'getTags',
      'filterTrafficSourceAccounts',
      'filterState',
    ]),
    ...mapState(useMediaManagerStore, [
      'mode',
      'settings',
      'filters',
      'isCampaignCreator',
      'selectedAds',
      'orderOptions',
      'totalPages',
      'isLoadingAds',
      'isLoading',
      'isTheOptimizerMode',
      'hasTrafficSourceFilter',
    ]),
    ...mapWritableState(useMediaManagerStore, ['ads', 'isPendingRequest', 'isTableView']),
    deletedTags(): string[] {
      const tags = union(...this.selectedAds.map((content) => content.tags));
      return tags.filter((tag) => !this.tags.includes(tag));
    },
    newTags(): string[] {
      const tags = union(...this.selectedAds.map((content) => content.tags));
      return this.tags.filter((tag) => !tags.includes(tag));
    },
  },
  watch: {
    filters: {
      handler() {
        if (this.filters.trafficsource) {
          this.trafficSources = this.settings.uniqueTrafficSources.filter((trafficSource) =>
            this.filters.trafficsource.includes(trafficSource.name)
          );
        }
        if (this.filters.selectedAccounts) {
          this.accounts = this.settings.accounts.filter((account) =>
            this.filters.selectedAccounts.includes(account.value)
          );
        }
        if (this.filters.order) {
          [this.sortBy] = this.filters.order;
        }
      },
      deep: true,
    },
  },
  created() {
    this.initFilters(this.COMPONENT_ID);
    this.registerAccounts();
  },
  methods: {
    ...mapActions(useTrafficSourcesStore, ['registerAccounts', 'initFilters']),
    ...mapActions(useMediaManagerStore, [
      'updateFilters',
      'insertNewAdsData',
      'getUploadedTrafficSourcesFilter',
      'onUploadedFilterChange',
      'onAdModalOpen',
      'onSelectAllClick',
      'onBulkTagInsert',
      'onDeleteAds',
      'onRemoveTags',
      'onDisplayChange',
    ]),
    onTagActionClick() {
      this.$refs.adBulkTagInsert?.onTagActionClick(this.selectedAds);
    },
    handleDateRangeChange(customDateRange: MediaManager.RequestTypes.DateRangeProps) {
      this.updateFilters({ customDateRange });
    },
    isTagSelected(tag: string) {
      return this.filterState[this.COMPONENT_ID].filterSession.tags?.includes(tag);
    },
    toggleTagsSelection(tag: string) {
      const index = this.filterState[this.COMPONENT_ID].filterSession.tags.indexOf(tag);
      const filterOptions = { trafficSource: [], linkedTrackers: [], status: '', currency: [] };

      if (index === -1) {
        // save selected tag to filterState
        this.filterState[this.COMPONENT_ID].filterSession.tags = [
          ...this.filterState[this.COMPONENT_ID].filterSession.tags,
          tag,
        ];
        // select the accounts checkbox options based on tag selection
        const selectedAccounts = this.filterTrafficSourceAccounts({
          ...filterOptions,
          tags: this.filterState[this.COMPONENT_ID].filterSession.tags,
        });
        const uniqueTrafficSourceNames = [...new Set(selectedAccounts.map((item) => item.id))];
        const filteredData = this.accountOptions.filter((item) => uniqueTrafficSourceNames.includes(item.value));
        if (filteredData.length) {
          this.onAccountsChange(filteredData, true);
        }
      } else if (index !== -1) {
        this.filterState[this.COMPONENT_ID].filterSession.tags.splice(index, 1);

        // un-select the accounts checkbox options based on tag un-selection
        const selectedAccounts = this.filterTrafficSourceAccounts({ ...filterOptions, tags: [tag] });
        const remainSelectedAccounts = this.filterTrafficSourceAccounts({
          ...filterOptions,
          tags: this.filterState[this.COMPONENT_ID].filterSession.tags.length
            ? this.filterState[this.COMPONENT_ID].filterSession.tags
            : [''],
        }).map((ts) => ts.id);

        const accountsForRemove = selectedAccounts
          .filter((e) => !remainSelectedAccounts.includes(e.id))
          .map((el) => el.id);

        const filteredData = this.accountOptions.filter((item) => accountsForRemove.includes(item.value));
        this.onAccountsChange(filteredData, false);
      }
    },
    toggleAllAccounts(checked: boolean) {
      this.accounts = checked ? this.accountOptions : [];
    },
    async onSaveTags(data: MediaManager.BulkTagSubmit) {
      try {
        const message = data.addedTags.tags.length ? 'added' : 'deleted';
        const promises = [];
        if (data.deletedTags.tags.length > 0) {
          promises.push(
            this.$api.mediaManager.editTags(data.mode, {
              id: data.deletedTags.id,
              tags: data.deletedTags.tags,
              type: 'DELETE',
            })
          );
        }

        if (data.addedTags.tags.length > 0) {
          promises.push(
            this.$api.mediaManager.editTags(data.mode, {
              id: data.addedTags.id,
              tags: uniq(data.addedTags.tags),
              type: 'ADD',
            })
          );
        }
        const [addResponse, deleteResponse] = await Promise.all(promises);

        (addResponse || deleteResponse) &&
          this.$n_successNotification({
            title: `Tags were ${message} successfully!`,
          });
      } catch {
        this.$n_failNotification({ title: 'Failed to add tags!' });
      }
    },
    showDeleteNotification(hasErrors: boolean, hasReferenceErrors: boolean) {
      if (hasErrors) {
        if (hasReferenceErrors) {
          this.$n_failNotification({
            title: `Some ${this.mode}s are ${MediaManager.ResponseTypes.StatusMessage.REFERENCED_ERROR} and can not be deleted.`,
          });
        } else {
          this.$n_failNotification({ title: `Some ${this.mode}s failed to be deleted!` });
        }
      } else {
        this.$n_successNotification({
          title: `${this.mode.charAt(0).toUpperCase() + this.mode.slice(1)}s were deleted successfully!`,
        });
      }
    },
    onSearchInput(value: string) {
      this.updateFilters({ querySearch: value });
    },
    async onTagsDelete(options: SelectOption<number>[]) {
      try {
        await this.onRemoveTags(options);
        this.$n_successNotification({ title: 'Tags were deleted successfully!' });
      } catch {
        this.isPendingRequest = false;
        this.$n_failNotification({ title: 'Failed to delete tags!' });
      }
    },
    onTagsFilter(options: SelectOption<number>[]) {
      if (
        options.length !== this.filters.tags.length ||
        options.find((option) => !this.filters.tags.includes(option.content))
      ) {
        this.updateFilters({ tags: options.map((option) => option.content) });
      }
    },
    onMetricsFilter(data: MediaManager.MetricFilterTypes.Filter[]) {
      this.updateFilters({ metrics: data });
    },
    onTrafficSourcesChange() {
      if (
        this.trafficSources.length !== this.filters.trafficsource.length ||
        this.trafficSources.find((selected) => !this.filters.trafficsource.includes(selected.name))
      ) {
        const trafficsource =
          this.isTheOptimizerMode && this.hasTrafficSourceFilter
            ? this.getUploadedTrafficSourcesFilter(this.trafficSources.map((selected) => selected.name))
            : this.trafficSources.map((selected) => selected.name);
        this.updateFilters({ trafficsource, selectedAccounts: [] });
      }
    },
    onAccountsChange(data: MediaManager.ResponseTypes.Account[], checked: boolean) {
      const selectedAccounts: MediaManager.ResponseTypes.Account[] = isArray(data) ? data : [data];
      if (checked) {
        this.accounts.push(
          ...selectedAccounts.filter(
            (ts: MediaManager.ResponseTypes.Account) => !this.accounts.map((e) => e.value).includes(ts.value)
          )
        );
      } else {
        this.accounts = this.accounts.filter(
          (v) => !selectedAccounts.map((ts: MediaManager.ResponseTypes.Account) => ts.value).includes(v.value)
        );
      }

      if (this.accounts.length === 0) {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: {
            ...this.filterState[this.COMPONENT_ID].filterSession,
            tags: [],
          },
        };
      }
      if (this.accounts.length === this.accountOptions.length) {
        this.accounts = this.accountOptions;
      }
    },
    filterAccounts() {
      this.updateFilters({ selectedAccounts: this.accounts.map((selected) => selected.value) });
      // when filter the values, save applied tags and accounts to savedFilters
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        savedFilters: [
          {
            ...this.filterState[this.COMPONENT_ID].filterSession,
            id: this.COMPONENT_ID,
            type: 'temp',
            tags: this.filterState[this.COMPONENT_ID].filterSession.tags,
            trafficSource: this.accounts.map((ts) => String(ts.value)),
          },
        ],
      };
      this.hideDropdown();
    },
    handleAccountValueClear() {
      this.accounts = [];
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        filterSession: {
          ...this.filterState[this.COMPONENT_ID].filterSession,
          trafficSource: [],
          tags: [],
        },
      };
    },
    handleAccountDropdownClose() {
      this.hideDropdown();
      this.handleAccountValueClear();
    },
    hideDropdown() {
      const dropdownRef = this.$refs.adFilters as unknown as BDropdown;
      dropdownRef.hide(true);
    },
    onAdFilterClose() {
      const savedFilters = this.filterState[this.COMPONENT_ID].savedFilters.find(
        (item) => item.id === this.COMPONENT_ID
      );
      if (savedFilters) {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: {
            ...this.filterState[this.COMPONENT_ID].filterSession,
            tags: savedFilters.tags,
          },
        };

        this.accounts = this.accountOptions.filter((item) => savedFilters.trafficSource.includes(String(item.value)));
      } else {
        this.filterState[this.COMPONENT_ID] = {
          ...this.filterState[this.COMPONENT_ID],
          filterSession: {
            tags: [],
            currency: [],
            linkedTrackers: [],
            status: '',
            trafficSource: [],
          },
        };
        this.accounts = [];
      }
    },
    onSortByChange() {
      if (
        this.sortBy &&
        this.filters.order &&
        this.filters.order.length &&
        this.sortBy.name !== this.filters.order[0].name
      ) {
        this.updateFilters({ order: [this.sortBy] });
      }
    },
    onTrafficSourcesClear() {
      if (this.isTheOptimizerMode) {
        this.updateFilters({ trafficsource: this.getUploadedTrafficSourcesFilter() });
      } else {
        this.updateFilters({ trafficsource: [] });
      }
      this.trafficSources = [];
    },
    onAccountsClear() {
      this.updateFilters({ selectedAccounts: [] });
      this.accounts = [];
      // Reset saved filters when 'x' is clickd
      this.filterState[this.COMPONENT_ID] = {
        ...this.filterState[this.COMPONENT_ID],
        savedFilters: [
          {
            tags: [],
            trafficSource: [],
            linkedTrackers: [],
            status: '',
            currency: [],
            id: this.COMPONENT_ID,
            type: 'temp',
          },
        ],
      };
    },
    onCreateAdsClick() {
      this.$router.push(`/media-manager/create/${this.mode}s`);
    },
    async onCloneContentsClick() {
      const selectedAds = cloneDeep(this.selectedAds);
      this.isPendingRequest = true;
      this.onSelectAllClick(false);
      const responses = await Promise.allSettled(
        selectedAds.map((ad) => this.$api.mediaManager.cloneContent(this.mode, { id: ad.id }))
      );
      const successfulResponses = responses.reduce(
        (filtered: MediaManager.ResponseTypes.Content[], response, index) => {
          if (response && response.status === 'fulfilled' && response.value) {
            filtered.push(response.value.data);
            const ad = this.ads.find((ad) => ad.id === selectedAds[index].id);

            if (ad) {
              ad.selected = false;
            }
          }
          return filtered;
        },
        []
      );

      if (this.isTableView) {
        this.updateFilters({ page: 1 });
      } else {
        await this.insertNewAdsData(successfulResponses);
      }

      this.isPendingRequest = false;
      this.onSelectAllClick(false);

      if (responses.length === successfulResponses.length) {
        this.$n_successNotification({ title: 'Changes were saved successfully!' });
      } else {
        this.selectedAds.forEach((ad) => {
          if (!successfulResponses.find((successfulResponse) => successfulResponse.id === ad.id)) {
            ad.selected = true;
          }
        });
        this.$n_failNotification({ title: `Remaining selected ${this.mode}s failed to clone!` });
      }
    },
    async onDeleteContentsClick() {
      const data = this.selectedAds;
      const deletableAds = data.filter((ad) => ad.isEditable);
      const swal = await this.onDeleteModalOpen(deletableAds.length, data.length, this.mode);

      if (swal.value === true && deletableAds.length) {
        this.ads.forEach((ad) => {
          ad.selected = ad.selected && ad.isEditable;
        });

        let hasErrors = false;
        let hasReferenceErrors = false;
        this.isPendingRequest = true;
        const responses = await this.onDeleteAds(deletableAds);
        this.isPendingRequest = false;

        responses.forEach((response) => {
          if (response.status === 'rejected') {
            hasReferenceErrors = response.reason.response?.data?.message?.includes(
              MediaManager.ResponseTypes.StatusMessage.REFERENCED_ERROR
            );
            hasErrors = true;
          }
        });

        this.showDeleteNotification(hasErrors, hasReferenceErrors);
      }

      if (!this.ads.length && this.totalPages !== 1) {
        this.updateFilters({ page: 1 });
      }

      if (!deletableAds.length) {
        this.onSelectAllClick(false);
      }
    },
    onDeleteModalOpen(deletableAdsLength: number, totalAdsLength: number, mode: string) {
      return this.$swal({
        ...(deletableAdsLength !== totalAdsLength && {
          icon: deletableAdsLength ? 'warning' : 'error',
          title: deletableAdsLength ? 'Warning' : 'Oops...',
        }),
        html: `
          ${MediaManagerHelpers.getUndeletableText(deletableAdsLength, totalAdsLength, mode)}
          <br>
          ${MediaManagerHelpers.getDeletableText(deletableAdsLength, deletableAdsLength !== totalAdsLength, mode)}
        `,
        allowOutsideClick: !deletableAdsLength,
        showConfirmButton: true,
        showCancelButton: !!deletableAdsLength,
        confirmButtonText: deletableAdsLength ? 'Confirm' : 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          container: 'confirm-delete-swal larger box',
          confirmButton: 'primary-button primary-button--danger',
          cancelButton: 'secondary-button',
        },
      });
    },
    onSwitchContents(value: boolean) {
      this.trafficSources = [];
      this.onUploadedFilterChange(value);
    },
  },
});
