import { Field, FieldGroup, PublisherTrackers } from '@sh/configurations/fields/Field';
import { RuleTypes, Tracker, TrafficSource } from '@sh/types';

export const placeholder = {
  $: { placeholder: '$', placeholderHtml: '<i class="fa fa-dollar"></i>', placeholderPosition: 'before' },
  '%': { placeholder: '%', placeholderHtml: '<i class="fa fa-percent"></i>', placeholderPosition: 'after' },
};

const modelFields: Record<string, Field> = {
  status: {
    key: 'status',
    useInCustomMetric: false,
    notAvailableAsParentCampaignCondition: true,
    availableOnLevel: ['Widget', 'Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Status,
    value: { behind: 'IS' },
    text: 'Status',
    notAvailableForToComparison: true,
    class: 'badge-warning',
    sourceType: 'TrafficSource',
    description: {
      default: {
        text: 'The current status of the item. Status can be Running or Paused',
      },
    },
  },
  hour_of_day: {
    key: 'hour_of_day',
    group: 'other',
    notAvailableAsParentCampaignCondition: true,
    notAvailableForToComparison: true,
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.HOUR_OF_DAY,
    value: { behind: 'IS IN', type: 'hour_of_day' },
    text: 'Hour of Day',
    disabled: false,
    description: {
      default: {
        text: 'Allows you to run rule in a specific hour of the day',
      },
    },
  },
  live_spent: {
    key: 'live_spent',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Live Spent',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: "Live spent is Outbrain's real time reporting of the amount a campaign is spending for the current day. It is available only on campaign level",
      },
    },
  },
  budget: {
    key: 'budget',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Budget',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The current budget of the item as reported by the traffic source',
      },
      overrides: {
        Taboola: {
          text: 'The Daily, Monthly or Life Time budget of your campaign',
        },
        Outbrain: {
          text: 'The Daily, Monthly or Life Time budget of your campaign',
        },
      },
    },
  },
  daily_budget: {
    key: 'daily_budget',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Daily Budget',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The daily spending limit of your campaign',
      },
    },
  },

  bid: {
    key: 'bid',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'CPC',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The amount you are paying based on the bidding model (CPC, CPM, CPA etc)',
      },
    },
  },
  learning_state: {
    key: 'learning_state',
    useInCustomMetric: false,
    trafficSource: [TrafficSource.Taboola],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    notAvailableForToComparison: true,
    value: { behind: 'IS' },
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.TABOOLA_LEARNING_STATE,
    text: 'Taboola Learning State',
    class: 'badge-warning',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The learning state of your campaign. Depending on the traffic source, a campaign might go through different learning states',
      },
    },
  },
  name: {
    key: 'name',
    notAvailableForToComparison: true,
    useInCustomMetric: false,
    availableOnLevel: ['Widget', 'AdGroup', 'Target', 'Site', 'Exchange', 'Domain', 'Section', 'Content'],
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.String,
    group: 'ts',
    value: { behind: 'MATCH' },
    text: 'Name',
    class: 'badge-yellow-haze',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The name of the item as reported from the traffic source',
      },
    },
  },
  campaign_name: {
    key: 'campaign_name',
    availableOnLevel: ['Campaign'],
    renameOnLevel: ['Campaign'],
    renameKey: 'name',
    notAvailableForToComparison: true,
    notAvailableAsParentCampaignCondition: true,
    useInCustomMetric: false,
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.String,
    group: 'ts',
    value: { behind: 'MATCH' },
    text: 'Campaign Name',
    class: 'badge-yellow-haze',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The name of the campaign as reported from the traffic source',
      },
    },
  },
  headline: {
    key: 'headline',
    useInCustomMetric: false,
    availableOnLevel: ['Content'],
    notAvailableForToComparison: true,
    notAvailableAsParentCampaignCondition: true,
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Entity,
    type: RuleTypes.Conditions.ValueType.String,
    value: { behind: 'MATCH' },
    text: 'Content Headline',
    class: 'badge-yellow-haze',
  },
};

const tsBaseFields: Record<string, Field> = {
  impressions: {
    key: 'impressions',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Impressions',
    class: 'badge-yellow-haze',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The total number of impressions as reported by the traffic source. Each time your campaign item is displayed, it’s counted as one impression',
      },
    },
  },
  viewable_impressions: {
    key: 'viewable_impressions',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Viewable Impressions',
    class: 'badge-yellow-haze',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The total number of viewable impressions as reported by the traffic source.  Each time your campaign item is on the screen for 1 second, it’s counted as one impression',
      },
    },
  },
  performance_viewable_impressions_live: {
    key: 'performance_viewable_impressions_live',
    group: 'ts',
    trafficSource: [TrafficSource.Taboola],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Realtime Viewable Impressions',
    class: 'badge-yellow-haze',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The total number of viewable impressions as reported by the traffic source (near) realtime.  Each time your campaign item is on the screen for 1 second, it’s counted as one impression',
      },
    },
  },
  ts_clicks: {
    key: 'ts_clicks',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Traffic Source Clicks',
    class: 'badge-warning',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The number of clicks as reported by the traffic source',
      },
    },
  },
  performance_ts_clicks_live: {
    key: 'performance_ts_clicks_live',
    group: 'ts',
    trafficSource: [TrafficSource.Taboola],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Realtime Traffic Source Clicks',
    class: 'badge-warning',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The number of clicks as reported by the traffic source (near) realtime',
      },
    },
  },
  ts_conversions: {
    key: 'ts_conversions',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Traffic Source Conversions',
    class: 'badge-purple-plum',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The number of conversions as reported by the traffic source',
      },
    },
  },
  performance_ts_conversions_live: {
    key: 'performance_ts_conversions_live',
    group: 'ts',
    trafficSource: [TrafficSource.Taboola],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Realtime Traffic Source Conversions',
    class: 'badge-purple-plum',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The number of conversions as reported by the traffic source (near) realtime',
      },
    },
  },
  cost: {
    key: 'cost',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Traffic Source Spent',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The amount an item spent in the selected period, as reported by the traffic source',
      },
    },
  },
  performance_cost_live: {
    key: 'performance_cost_live',
    group: 'ts',
    trafficSource: [TrafficSource.Taboola],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Realtime Traffic Source Spent',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The amount an item spent in the selected period, as reported by the traffic source (near) realtime',
      },
    },
  },
  ts_revenue: {
    key: 'ts_revenue',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Traffic Source Revenue',
    class: 'badge-coffe',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The revenue as reported from the traffic source',
      },
    },
  },
  performance_ts_revenue_live: {
    key: 'performance_ts_revenue_live',
    group: 'ts',
    trafficSource: [TrafficSource.Taboola],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Realtime Traffic Source Revenue',
    class: 'badge-coffe',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The revenue as reported from the traffic source (near) realtime',
      },
    },
  },
  v_ctr: {
    key: 'v_ctr',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.001' },
    text: 'Traffic Source vCTR',
    class: 'badge-yellow-gold',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'Viewable CTR = {{formula}}. Viewable CTR is calculated by dividing the number of clicks for your campaign items by the number of viewable impressions for your campaign items.',
        variables: {
          formula: { value: 'clicks/viewable_impressions', type: 'FORMULA' },
        },
      },
    },
  },
  ts_epc: {
    key: 'ts_epc',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Traffic Source EPC',
    class: 'badge-yellow-gold',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'Epc = {{formula}}. Epc is calculated by dividing the revenue by the number of clicks, as reported by the traffic source, for your campaigns and campaign items items.',
        variables: {
          formula: { value: 'ts_revenue/ts_clicks', type: 'FORMULA' },
        },
      },
    },
  },
  ctr: {
    key: 'ctr',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'CTR',
    class: 'badge-blue-chambray',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'ts_clicks/impressions', type: 'FORMULA' },
        },
        text: 'Ctr = {{formula}}. CTR is calculated by dividing the number of clicks by the number of impressions, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  ts_cvr: {
    key: 'ts_cvr',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Traffic Source CVR',
    class: 'badge-blue-chambray',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'ts_conversions/ts_clicks', type: 'FORMULA' },
        },
        text: 'Cvr = {{formula}}. Cvr (conversion rate) is calculated by dividing the number of conversions by the number of clicks, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  ts_net: {
    key: 'ts_net',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Traffic Source NET',
    class: 'badge-light',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'ts_revenue-cost', type: 'FORMULA' },
        },
        text: 'Net = {{formula}}. Net is calculated as the difference between the revenue and the amount spent, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  ts_roi: {
    key: 'ts_roi',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Traffic Source ROI',
    class: 'badge-green-sharp',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: '(ts_revenue-cost)/cost', type: 'FORMULA' },
        },
        text: 'Roi = {{formula}}. Roi is calculated by dividing the difference between the revenue and the amount spent (or Net) by the amount spent, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  ts_cpa: {
    key: 'ts_cpa',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Traffic Source CPA',
    class: 'badge-green-soft',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'cost/ts_conversions', type: 'FORMULA' },
        },
        text: 'Cpa = {{formula}}. Cpa is calculated by dividing the amount spent by the number of conversions, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  avg_cpc: {
    key: 'avg_cpc',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Avg. CPC',
    class: 'badge-green-soft',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'cost/ts_clicks', type: 'FORMULA' },
        },
        text: 'Avg. Cpc = {{formula}}. Average Cpc is calculated by dividing the amount spent by the number of clicks, as reported by the traffic source, for your campaigns and campaign items.',
      },
    },
  },
  est_cost: {
    key: 'est_cost',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Estimated Spent',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'avg_cpc*tr_clicks', type: 'FORMULA' },
        },
        text: 'NOTE: Estimated Spend is only available if you have connected a tracking platform. Est. Spend = {{formula}}. To make up for the delay some traffic soruces have in reporting the accurate spend for the day, we try to estimate its value using the average CPC and multiplying it by the amount of clicks reported by your trackign platform (TR Clicks).',
      },
    },
  },
  ecpm: {
    key: 'ecpm',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'eCPM',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'ecpm =(cost / impressions) * 1000', type: 'FORMULA' },
        },
        text: 'Value calculated as {{formula}}. This value represents amount of money spent for 1000 impressions.',
      },
    },
  },
  est_net: {
    key: 'est_net',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Estimated NET',
    class: 'badge-light',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'ts_revenue-est_cost', type: 'FORMULA' },
        },
        text: 'NOTE: Estimated Net is only available if you have connected a tracking platform. Est. Net = {{formula}}. .',
      },
    },
  },
  est_roi: {
    key: 'est_roi',
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Estimated ROI',
    class: 'badge-green-sharp',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'est_net/est_cost', type: 'FORMULA' },
        },
        text: 'NOTE: Estimated Roi is only available if you have connected a tracking platform. Est. Roi = {{formula}}.',
      },
    },
  },
  target_cpa: {
    key: 'target_cpa',
    trafficSource: ['Taboola', 'Outbrain'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Target CPA',
    class: 'badge-secondary',
    sourceType: 'TrafficSource', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The target cost per action that you have set for this campaign on the traffic source',
      },
    },
  },
};

const trBaseFields: Record<string, Field> = {
  tr_clicks: {
    key: 'tr_clicks',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Tracker Clicks',
    class: 'badge-success',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The amount of clicks (or visits) reported by your connected tracking platform.',
      },
    },
  },
  tr_conversions: {
    key: 'tr_conversions',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Tracker Conversions',
    class: 'badge-primary',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The amount of conversions reported by your connected tracking platform',
      },
    },
  },
  tr_revenue: {
    key: 'tr_revenue',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Tracker Revenue',
    class: 'badge-dark',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'The total revenue (sum of all revenues) reported by all connected tracking platforms and revenue streams (Google Analytics, Assertive Yield, Search Feeds, or any click tracker)',
      },
    },
  },
  lp_clicks: {
    key: 'lp_clicks',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Landing Page Clicks',
    class: 'badge-danger',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        text: 'Landing Page Clicks. The number of clicks to your landing pages (or click to CTA), as reported by your connected tracking platform.',
      },
    },
  },
  payout: {
    key: 'payout',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Payout',
    class: 'badge-secondary',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_revenue/tr_conversions', type: 'FORMULA' },
        },
        text: 'The average revenue that each conversion is generating. Payout is automatically calculated as (Manual Payout or {{formula}}), but you can also override the automatic payout by entering a static value manually.',
      },
    },
  },
  tr_epc: {
    key: 'tr_epc',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Tracker EPC',
    class: 'badge-yellow-gold',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_revenue/tr_clicks', type: 'FORMULA' },
        },
        text: 'TR Epc = {{formula}}. Tracker Earn Per Click is calculated by dividing the revenue by the number of clicks, as reported by your connected tracking platform',
      },
    },
  },
  lp_ctr: {
    key: 'lp_ctr',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Landing Page CTR',
    class: 'badge-blue-chambray',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'lp_clicks/tr_clicks', type: 'FORMULA' },
        },
        text: 'LP Ctr = {{formula}}. Landing Page Click Through Rate is calculated by dividing the landing page clicks by the total number of tracker clicks, as reported by your connected tracking platform',
      },
    },
  },
  lp_cr: {
    key: 'lp_cr',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Landing Page CR',
    class: 'badge-secondary',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker

    description: {
      default: {
        variables: {
          formula: { value: 'tr_conversions/lp_clicks', type: 'FORMULA' },
        },
        text: 'LP Cr = {{formula}}. Landing Page Conversion Rate is calculated by dividing the number of tracker conversions by the  number of landing page clicks, as reported by your connected tracking platform',
      },
    },
  },
  cp_lp_clicks: {
    key: 'cp_lp_clicks',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Landing Page CPC',
    class: 'badge-yellow-gold',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'cost/lp_clicks', type: 'FORMULA' },
        },
        text: 'LP Cpc = {{formula}}. Landing Page Cpc is calculated by dividing the amount spent, reported by the traffic soruce, by the  number of landing page clicks, as reported by your connected tracking platform',
      },
    },
  },
  tr_cvr: {
    key: 'tr_cvr',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Tracker CVR',
    class: 'badge-blue-chambray',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_conversions/tr_clicks', type: 'FORMULA' },
        },
        text: 'TR Cvr = {{formula}}. Tracker Conversion Rate is calculated by dividing the number of conversions,by the number of clicks, as reported by your connected tracking platform',
      },
    },
  },

  tr_net: {
    key: 'tr_net',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Tracker NET',
    class: 'badge-light',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_revenue-cost', type: 'FORMULA' },
        },
        text: 'TR Net = {{formula}}. Tracker Net  is calculated as the difference between the amount spent, reported by the traffic soruce, and the revenue reported by your connected tracking platform',
      },
    },
  },

  tr_roi: {
    key: 'tr_roi',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder['%'], step: '0.01' },
    text: 'Tracker ROI',
    class: 'badge-green-sharp',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_net/cost', type: 'FORMULA' },
        },
        text: 'Tracker ROI = {{formula}}. Tracker Roi  is calculated as the difference between the amount spent (reported by the traffic soruce) and the revenue (reported by your connected tracking platform), all divided by the amount spent (reported by the traffic source)',
      },
    },
  },
  tr_cpa: {
    key: 'tr_cpa',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'Tracker CPA',
    class: 'badge-green-soft',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'cost/tr_conversions', type: 'FORMULA' },
        },
        text: 'TR Cpa = {{formula}}. Tracker Cpa is calculated dividing the amount spent by the number of conversions reported by the trackign platform',
      },
    },
  },
  epc: {
    key: 'epc',
    group: 'tr',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.001' },
    text: 'EPC',
    class: 'badge-yellow-gold',
    sourceType: 'Tracker', // Field Depends On TrafficSource or Tracker
    description: {
      default: {
        variables: {
          formula: { value: 'tr_revenue/tr_clicks', type: 'FORMULA' },
        },
        text: 'Epc = {{formula}}. NOTE: Only available if you are using and have connected a tracking platform. In difference from TR Epc (which is calculated using only tracker metrics) or from TS Epc (which is calculated using only traffic source metrics), Epc is calculated by using sthe amount spent from the traffic soruce and the clicks from the tracker. It is usually the most accurate Epc metric.',
      },
    },
  },
};

const publisherFields: Record<string, Field> = {
  publisher_revenue: {
    key: 'publisher_revenue',
    trackers: ['Sedo', 'System1', 'Bodis', 'Tonic', 'DomainActive', 'Ads'],
    group: 'pf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Search Feed Revenue',
    class: 'badge-dark',
  },
  publisher_clicks: {
    key: 'publisher_clicks',
    trackers: ['Sedo', 'System1', 'Bodis', 'Tonic', 'DomainActive', 'Ads'],
    group: 'pf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Search Feed Clicks',
    class: 'badge-success',
  },
  publisher_conversions: {
    key: 'publisher_conversions',
    trackers: ['Sedo', 'System1', 'Bodis', 'Tonic', 'DomainActive', 'Ads'],
    group: 'pf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Search Feed Conversions',
    class: 'badge-primary',
  },
};

const ayFields: Record<string, Field> = {
  clicks: {
    key: 'clicks',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Clicks',
    class: 'badge-primary',
  },
  clicksbounced: {
    key: 'clicksbounced',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Clicks Bounced',
    class: 'badge-primary',
  },
  clicksreturned: {
    key: 'clicksreturned',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Clicks Returned',
    class: 'badge-primary',
  },
  direct_impressions: {
    key: 'direct_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Direct Impressions',
    class: 'badge-primary',
  },
  direct_revenue: {
    key: 'direct_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Direct Revenue',
    class: 'badge-primary',
  },
  dynamicallocation_impressions: {
    key: 'dynamicallocation_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Dynamic Allocation Impressions',
    class: 'badge-primary',
  },
  dynamicallocation_predicted_impressions: {
    key: 'dynamicallocation_predicted_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Dynamic Allocation Predicted Impressions',
    class: 'badge-primary',
  },
  dynamicallocation_predicted_revenue: {
    key: 'dynamicallocation_predicted_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Dynamic Allocation Predicted Revenue',
    class: 'badge-primary',
  },
  dynamicallocation_predicted_revenue_server: {
    key: 'dynamicallocation_predicted_revenue_server',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Dynamic Allocation Predicted Revenue Server',
    class: 'badge-primary',
  },
  dynamicallocation_revenue: {
    key: 'dynamicallocation_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Dynamic Allocation Revenue',
    class: 'badge-primary',
  },
  dynamicallocation_revenue_with_forecast: {
    key: 'dynamicallocation_revenue_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Dynamic Allocation Revenue With Forecast',
    class: 'badge-primary',
  },
  dynamicallocation_revenue_with_forecast_client: {
    key: 'dynamicallocation_revenue_with_forecast_client',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Dynamic Allocation Revenue With Forecast Client',
    class: 'badge-primary',
  },
  first_five_indicator: {
    key: 'first_five_indicator',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'First Five Indicator',
    class: 'badge-primary',
  },
  ayimpressions: {
    key: 'ayimpressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Impressions',
    class: 'badge-primary',
  },
  lineitem_revenue: {
    key: 'lineitem_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'AY Line Item Revenue',
    class: 'badge-primary',
  },
  pageviews: {
    key: 'pageviews',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Page Views',
    class: 'badge-primary',
  },
  prebid_lost_impressions: {
    key: 'prebid_lost_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Prebid Lost Impressions',
    class: 'badge-primary',
  },
  prebid_lost_revenue: {
    key: 'prebid_lost_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Prebid Lost Revenue',
    class: 'badge-primary',
  },
  prebid_revenue: {
    key: 'prebid_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Prebid Revenue',
    class: 'badge-primary',
  },
  prebid_won_impressions: {
    key: 'prebid_won_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Prebid Won Impressions',
    class: 'badge-primary',
  },
  prebid_won_revenue: {
    key: 'prebid_won_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Prebid Won Revenue',
    class: 'badge-primary',
  },
  session_starts: {
    key: 'session_starts',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Session Starts',
    class: 'badge-primary',
  },
  sessions: {
    key: 'sessions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Sessions',
    class: 'badge-primary',
  },
  viewable: {
    key: 'viewable',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Viewable',
    class: 'badge-primary',
  },
  nativo_revenue: {
    key: 'nativo_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Nativo Revenue',
    class: 'badge-primary',
  },
  nativo_impressions: {
    key: 'nativo_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Nativo Impressions',
    class: 'badge-primary',
  },
  ads_per_pageview: {
    key: 'ads_per_pageview',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Ads Per Pageview',
    class: 'badge-primary',
  },
  ads_per_session: {
    key: 'ads_per_session',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'AY Ads Per Session',
    class: 'badge-primary',
  },
  clr: {
    key: 'clr',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Click-Leave Rate',
    class: 'badge-primary',
  },
  ayctr: {
    key: 'ayctr',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'AY CTR',
    class: 'badge-primary',
  },
  direct_pageview_rpm: {
    key: 'direct_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D PageView RPM',
    class: 'badge-primary',
  },
  direct_pageview_valuerpm: {
    key: 'direct_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D PageView Value-RPM',
    class: 'badge-primary',
  },
  direct_rpm: {
    key: 'direct_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D RPM',
    class: 'badge-primary',
  },
  direct_session_rpm: {
    key: 'direct_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D Session RPM',
    class: 'badge-primary',
  },
  direct_winrate: {
    key: 'direct_winrate',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D Win Rate',
    class: 'badge-primary',
  },
  dynamicallocation_pageview_rpm: {
    key: 'dynamicallocation_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA PageView RPM',
    class: 'badge-primary',
  },
  dynamicallocation_rpm: {
    key: 'dynamicallocation_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'DA RPM',
    class: 'badge-primary',
  },
  dynamicallocation_session_rpm: {
    key: 'dynamicallocation_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA Session RPM',
    class: 'badge-primary',
  },
  dynamicallocation_session_rpm_with_forecast: {
    key: 'dynamicallocation_session_rpm_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA Session RPM with Forecast',
    class: 'badge-primary',
  },
  dynamicallocation_winrate: {
    key: 'dynamicallocation_winrate',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA Win Rate',
    class: 'badge-primary',
  },
  cbr: {
    key: 'cbr',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'AY Miss-Click Rate',
    class: 'badge-primary',
  },
  programmatic_impressions: {
    key: 'programmatic_impressions',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'P Impressions',
    class: 'badge-primary',
  },
  programmatic_revenue: {
    key: 'programmatic_revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Revenue',
    class: 'badge-primary',
  },
  programmatic_revenue_with_forecast: {
    key: 'programmatic_revenue_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Revenue with DA Forecast',
    class: 'badge-primary',
  },
  pageviews_per_session: {
    key: 'pageviews_per_session',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'PageViews per Session',
    class: 'badge-primary',
  },
  prebid_pageview_rpm: {
    key: 'prebid_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB PageView RPM',
    class: 'badge-primary',
  },
  prebid_rpm: {
    key: 'prebid_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB RPM',
    class: 'badge-primary',
  },
  prebid_session_rpm: {
    key: 'prebid_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB Session RPM',
    class: 'badge-primary',
  },
  prebid_winrate: {
    key: 'prebid_winrate',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB Win Rate',
    class: 'badge-primary',
  },
  prebid_lost_pageview_rpm: {
    key: 'prebid_lost_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBL PageView RPM',
    class: 'badge-primary',
  },
  prebid_lost_rpm: {
    key: 'prebid_lost_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBL RPM',
    class: 'badge-primary',
  },
  prebid_lost_session_rpm: {
    key: 'prebid_lost_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'PBL Session RPM',
    class: 'badge-primary',
  },
  prebid_lost_session_valuerpm: {
    key: 'prebid_lost_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBL Session Value-RPM',
    class: 'badge-primary',
  },
  prebid_lost_valuerpm: {
    key: 'prebid_lost_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBL Value-RPM',
    class: 'badge-primary',
  },
  prebid_won_pageview_valuerpm: {
    key: 'prebid_won_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW PageView Value-RPM',
    class: 'badge-primary',
  },
  prebid_won_pageview_rpm: {
    key: 'prebid_won_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW PageView RPM',
    class: 'badge-primary',
  },
  prebid_won_rpm: {
    key: 'prebid_won_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW RPM',
    class: 'badge-primary',
  },
  prebid_won_session_rpm: {
    key: 'prebid_won_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW Session RPM',
    class: 'badge-primary',
  },
  prebid_won_session_valuerpm: {
    key: 'prebid_won_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW Session Value-RPM',
    class: 'badge-primary',
  },
  prebid_won_valuerpm: {
    key: 'prebid_won_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBW Value-RPM',
    class: 'badge-primary',
  },
  rpm_with_forecast: {
    key: 'rpm_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'RPM with DA Forecast',
    class: 'badge-primary',
  },
  session_rpm_with_forecast: {
    key: 'session_rpm_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Session RPM with DA Forecast',
    class: 'badge-primary',
  },
  session_valuerpm: {
    key: 'session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Session Value-RPM',
    class: 'badge-primary',
  },
  valuecpm: {
    key: 'valuecpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Value-RPM',
    class: 'badge-primary',
  },
  nativo_pageview_rpm: {
    key: 'nativo_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'NTV PageView RPM',
    class: 'badge-primary',
  },
  nativo_rpm: {
    key: 'nativo_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'NTV RPM',
    class: 'badge-primary',
  },
  nativo_session_rpm: {
    key: 'nativo_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'NTV Session RPM',
    class: 'badge-primary',
  },
  revenue: {
    key: 'revenue',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'AY Revenue',
    class: 'badge-primary',
  },
  revenue_with_forecast: {
    key: 'revenue_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Revenue with DA Forecast',
    class: 'badge-primary',
  },
  viewability: {
    key: 'viewability',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Viewability',
    class: 'badge-primary',
  },
  rpm: {
    key: 'rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'RPM',
    class: 'badge-primary',
  },
  session_rpm: {
    key: 'session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Session RPM',
    class: 'badge-primary',
  },
  direct_session_valuerpm: {
    key: 'direct_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D Session Value-RPM',
    class: 'badge-primary',
  },
  prebid_pageview_valuerpm: {
    key: 'prebid_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB PageView Value-RPM',
    class: 'badge-primary',
  },
  prebid_session_valuerpm: {
    key: 'prebid_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB Session Value-RPM',
    class: 'badge-primary',
  },
  prebid_valuerpm: {
    key: 'prebid_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PB Value-RPM',
    class: 'badge-primary',
  },
  prebid_lost_pageview_valuerpm: {
    key: 'prebid_lost_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PBL PageView Value-RPM',
    class: 'badge-primary',
  },
  direct_valuerpm: {
    key: 'direct_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'D Value-RPM',
    class: 'badge-primary',
  },
  programmatic_rpm: {
    key: 'programmatic_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P RPM',
    class: 'badge-primary',
  },
  programmatic_session_rpm: {
    key: 'programmatic_session_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Session RPM',
    class: 'badge-primary',
  },
  programmatic_session_rpm_with_forecast: {
    key: 'programmatic_session_rpm_with_forecast',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Session RPM with Forecast',
    class: 'badge-primary',
  },
  programmatic_session_valuerpm: {
    key: 'programmatic_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Session Value-RPM',
    class: 'badge-primary',
  },
  programmatic_valuerpm: {
    key: 'programmatic_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Value-RPM',
    class: 'badge-primary',
  },
  programmatic_winrate: {
    key: 'programmatic_winrate',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P Win Rate',
    class: 'badge-primary',
  },
  pageview_rpm: {
    key: 'pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PageView RPM',
    class: 'badge-primary',
  },
  pageview_valuerpm: {
    key: 'pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'PageView Value-RPM',
    class: 'badge-primary',
  },
  dynamicallocation_pageview_valuerpm: {
    key: 'dynamicallocation_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA PageView Value-RPM',
    class: 'badge-primary',
  },
  dynamicallocation_session_valuerpm: {
    key: 'dynamicallocation_session_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA Session Value-RPM',
    class: 'badge-primary',
  },
  dynamicallocation_valuerpm: {
    key: 'dynamicallocation_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DA Value-RPM',
    class: 'badge-primary',
  },
  programmatic_pageview_rpm: {
    key: 'programmatic_pageview_rpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P PageView RPM',
    class: 'badge-primary',
  },
  programmatic_pageview_valuerpm: {
    key: 'programmatic_pageview_valuerpm',
    trackers: ['AssertiveYield'],
    group: 'ayf',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'P PageView Value-RPM',
    class: 'badge-primary',
  },
};

const facebookFields: Record<string, Field> = {
  // Facebook fields
  facebook_reach: {
    key: 'facebook_reach',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Reach',
    class: 'badge-primary',
  },
  facebook_comment_action: {
    key: 'facebook_comment_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Comments',
    class: 'badge-primary',
  },
  facebook_impressions: {
    key: 'facebook_impressions',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Impressions',
    class: 'badge-primary',
  },
  facebook_inline_link_clicks: {
    key: 'facebook_inline_link_clicks',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Inline Link Clicks',
    class: 'badge-primary',
  },
  facebook_like_action: {
    key: 'facebook_like_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Likes',
    class: 'badge-primary',
  },
  facebook_link_click_action: {
    key: 'facebook_link_click_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Link Clicks',
    class: 'badge-primary',
  },
  facebook_mobile_app_install_action: {
    key: 'facebook_mobile_app_install_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'App installs',
    class: 'badge-primary',
  },
  facebook_page_engagement_action: {
    key: 'facebook_page_engagement_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Page Engagment',
    class: 'badge-primary',
  },
  facebook_post_engagement_action: {
    key: 'facebook_post_engagement_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Post Engagment',
    class: 'badge-primary',
  },
  facebook_unique_inline_link_clicks: {
    key: 'facebook_unique_inline_link_clicks',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Unique Inline Clicks',
    class: 'badge-primary',
  },
  facebook_off_conv_view_content: {
    key: 'facebook_off_conv_view_content',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Content View',
    class: 'badge-primary',
  },
  facebook_off_conv_add_payment_info: {
    key: 'facebook_off_conv_add_payment_info',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: ' Add Payment Info',
    class: 'badge-primary',
  },
  facebook_off_conv_add_to_cart: {
    key: 'facebook_off_conv_add_to_cart',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Add to Cart',
    class: 'badge-primary',
  },
  facebook_off_conv_add_to_wishlist: {
    key: 'facebook_off_conv_add_to_wishlist',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Add to wishlist',
    class: 'badge-primary',
  },
  facebook_off_conv_complete_registration: {
    key: 'facebook_off_conv_complete_registration',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Complete Registration',
    class: 'badge-primary',
  },
  facebook_off_conv_initiate_checkout: {
    key: 'facebook_off_conv_initiate_checkout',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Initiate Checkout',
    class: 'badge-primary',
  },
  facebook_off_conv_lead: {
    key: 'facebook_off_conv_lead',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Offsite Lead',
    class: 'badge-primary',
  },
  facebook_off_conv_purchase: {
    key: 'facebook_off_conv_purchase',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchase',
    class: 'badge-primary',
  },
  facebook_off_conv_search: {
    key: 'facebook_off_conv_search',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Search',
    class: 'badge-primary',
  },
  facebook_off_conv_all_custom: {
    key: 'facebook_off_conv_all_custom',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'All custom events',
    class: 'badge-primary',
  },
  facebook_results: {
    key: 'facebook_results',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Ad Results',
    class: 'badge-primary',
  },
  facebook_cost_link_click: {
    key: 'facebook_cost_link_click',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$, step: '0.01' },
    text: 'Avg. Link Click Cost',
    class: 'badge-primary',
  },
  facebook_lead_action: {
    key: 'facebook_lead_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Leads',
    class: 'badge-primary',
  },
  facebook_revenue: {
    key: 'facebook_revenue',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$ },
    text: 'ROAS',
    class: 'badge-primary',
  },
  facebook_post_reaction_action: {
    key: 'facebook_post_reaction_action',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Reactions',
    class: 'badge-primary',
  },
  facebook_conversions: {
    key: 'facebook_conversions',
    trafficSource: ['Facebook'],
    group: 'facebook',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Conversions',
    class: 'badge-primary',
  },
};

const tikTokFields: Record<string, Field> = {
  tiktok_reach: {
    key: 'tiktok_reach',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Reach',
    class: 'badge-primary',
  },
  tiktok_real_time_conversion: {
    key: 'tiktok_real_time_conversion',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Real Time Conversion',
    class: 'badge-primary',
  },
  tiktok_result: {
    key: 'tiktok_result',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Result',
    class: 'badge-primary',
  },
  tiktok_real_time_result: {
    key: 'tiktok_real_time_result',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Real Time Result',
    class: 'badge-primary',
  },
  tiktok_secondary_goal_result: {
    key: 'tiktok_secondary_goal_result',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Secondary Goal Result',
    class: 'badge-primary',
  },
  tiktok_profile_visits: {
    key: 'tiktok_profile_visits',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Profile Visits',
    class: 'badge-primary',
  },
  tiktok_likes: {
    key: 'tiktok_likes',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Likes',
    class: 'badge-primary',
  },
  tiktok_comments: {
    key: 'tiktok_comments',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Comments',
    class: 'badge-primary',
  },
  tiktok_shares: {
    key: 'tiktok_shares',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Shares',
    class: 'badge-primary',
  },
  tiktok_follows: {
    key: 'tiktok_follows',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Follows',
    class: 'badge-primary',
  },
  tiktok_clicks_on_music_disc: {
    key: 'tiktok_clicks_on_music_disc',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'ARE',
    },
    text: 'Clicks On Music Disc',
    class: 'badge-primary',
  },
  tiktok_vta_app_install: {
    key: 'tiktok_vta_app_install',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Vta App Install',
    class: 'badge-primary',
  },
  tiktok_vta_conversion: {
    key: 'tiktok_vta_conversion',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Vta Conversion',
    class: 'badge-primary',
  },
  tiktok_vta_registration: {
    key: 'tiktok_vta_registration',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Vta Registration',
    class: 'badge-primary',
  },
  tiktok_vta_purchase: {
    key: 'tiktok_vta_purchase',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Vta Purchase',
    class: 'badge-primary',
  },
  tiktok_cta_app_install: {
    key: 'tiktok_cta_app_install',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Cta App Install',
    class: 'badge-primary',
  },
  tiktok_cta_conversion: {
    key: 'tiktok_cta_conversion',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Cta Conversion',
    class: 'badge-primary',
  },
  tiktok_cta_registration: {
    key: 'tiktok_cta_registration',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Cta Registration',
    class: 'badge-primary',
  },
  tiktok_cta_purchase: {
    key: 'tiktok_cta_purchase',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Cta Purchase',
    class: 'badge-primary',
  },
  tiktok_total_registration: {
    key: 'tiktok_total_registration',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Registration',
    class: 'badge-primary',
  },
  tiktok_total_purchase: {
    key: 'tiktok_total_purchase',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Purchase',
    class: 'badge-primary',
  },
  tiktok_total_purchase_value: {
    key: 'tiktok_total_purchase_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Purchase Value',
    class: 'badge-primary',
  },
  tiktok_total_active_pay_roas: {
    key: 'tiktok_total_active_pay_roas',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Active Pay Roas',
    class: 'badge-primary',
  },
  tiktok_total_app_event_add_to_cart: {
    key: 'tiktok_total_app_event_add_to_cart',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total App Event Add To Cart',
    class: 'badge-primary',
  },
  tiktok_total_app_event_add_to_cart_value: {
    key: 'tiktok_total_app_event_add_to_cart_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total App Event Add To Cart Value',
    class: 'badge-primary',
  },
  tiktok_total_checkout: {
    key: 'tiktok_total_checkout',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Checkout',
    class: 'badge-primary',
  },
  tiktok_total_checkout_value: {
    key: 'tiktok_total_checkout_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Checkout Value',
    class: 'badge-primary',
  },
  tiktok_total_view_content: {
    key: 'tiktok_total_view_content',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total View Content',
    class: 'badge-primary',
  },
  tiktok_total_view_content_value: {
    key: 'tiktok_total_view_content_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total View Content Value',
    class: 'badge-primary',
  },
  tiktok_total_add_payment_info: {
    key: 'tiktok_total_add_payment_info',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Add Payment Info',
    class: 'badge-primary',
  },
  tiktok_total_add_to_wishlist: {
    key: 'tiktok_total_add_to_wishlist',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Add To Wishlist',
    class: 'badge-primary',
  },
  tiktok_total_add_to_wishlist_value: {
    key: 'tiktok_total_add_to_wishlist_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Add To Wishlist Value',
    class: 'badge-primary',
  },
  tiktok_total_launch_app: {
    key: 'tiktok_total_launch_app',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Launch App',
    class: 'badge-primary',
  },
  tiktok_total_complete_tutorial: {
    key: 'tiktok_total_complete_tutorial',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Complete Tutorial',
    class: 'badge-primary',
  },
  tiktok_total_complete_tutorial_value: {
    key: 'tiktok_total_complete_tutorial_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Complete Tutorial Value',
    class: 'badge-primary',
  },
  tiktok_total_create_group: {
    key: 'tiktok_total_create_group',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Create Group',
    class: 'badge-primary',
  },
  tiktok_total_create_group_value: {
    key: 'tiktok_total_create_group_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Create Group Value',
    class: 'badge-primary',
  },
  tiktok_total_join_group: {
    key: 'tiktok_total_join_group',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Join Group',
    class: 'badge-primary',
  },
  tiktok_total_join_group_value: {
    key: 'tiktok_total_join_group_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Join Group Value',
    class: 'badge-primary',
  },
  tiktok_total_create_gamerole: {
    key: 'tiktok_total_create_gamerole',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Create Gamerole',
    class: 'badge-primary',
  },
  tiktok_total_create_gamerole_value: {
    key: 'tiktok_total_create_gamerole_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Create Gamerole Value',
    class: 'badge-primary',
  },
  tiktok_total_spend_credits: {
    key: 'tiktok_total_spend_credits',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Spend Credits',
    class: 'badge-primary',
  },
  tiktok_total_spend_credits_value: {
    key: 'tiktok_total_spend_credits_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Spend Credits Value',
    class: 'badge-primary',
  },
  tiktok_total_achieve_level: {
    key: 'tiktok_total_achieve_level',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Achieve Level',
    class: 'badge-primary',
  },
  tiktok_total_achieve_level_value: {
    key: 'tiktok_total_achieve_level_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Achieve Level Value',
    class: 'badge-primary',
  },
  tiktok_total_unlock_achievement: {
    key: 'tiktok_total_unlock_achievement',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Unlock Achievement',
    class: 'badge-primary',
  },
  tiktok_total_unlock_achievement_value: {
    key: 'tiktok_total_unlock_achievement_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Unlock Achievement Value',
    class: 'badge-primary',
  },
  tiktok_total_sales_lead: {
    key: 'tiktok_total_sales_lead',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Sales Lead',
    class: 'badge-primary',
  },
  tiktok_total_sales_lead_value: {
    key: 'tiktok_total_sales_lead_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Sales Lead Value',
    class: 'badge-primary',
  },
  tiktok_total_in_app_ad_click: {
    key: 'tiktok_total_in_app_ad_click',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total In App Ad Click',
    class: 'badge-primary',
  },
  tiktok_total_in_app_ad_click_value: {
    key: 'tiktok_total_in_app_ad_click_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total In App Ad Click Value',
    class: 'badge-primary',
  },
  tiktok_total_in_app_ad_impr: {
    key: 'tiktok_total_in_app_ad_impr',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total In App Ad Impr',
    class: 'badge-primary',
  },
  tiktok_total_in_app_ad_impr_value: {
    key: 'tiktok_total_in_app_ad_impr_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total In App Ad Impr Value',
    class: 'badge-primary',
  },
  tiktok_total_loan_apply: {
    key: 'tiktok_total_loan_apply',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Loan_apply',
    class: 'badge-primary',
  },
  tiktok_total_loan_credit: {
    key: 'tiktok_total_loan_credit',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Loan Credit',
    class: 'badge-primary',
  },
  tiktok_total_loan_disbursement: {
    key: 'tiktok_total_loan_disbursement',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Loan Disbursement',
    class: 'badge-primary',
  },
  tiktok_total_login: {
    key: 'tiktok_total_login',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Login',
    class: 'badge-primary',
  },
  tiktok_total_ratings: {
    key: 'tiktok_total_ratings',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Ratings',
    class: 'badge-primary',
  },
  tiktok_total_ratings_value: {
    key: 'tiktok_total_ratings_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Ratings Value',
    class: 'badge-primary',
  },
  tiktok_total_search: {
    key: 'tiktok_total_search',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Search',
    class: 'badge-primary',
  },
  tiktok_total_start_trial: {
    key: 'tiktok_total_start_trial',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Start Trial',
    class: 'badge-primary',
  },
  tiktok_total_subscribe: {
    key: 'tiktok_total_subscribe',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Subscribe',
    class: 'badge-primary',
  },
  tiktok_total_subscribe_value: {
    key: 'tiktok_total_subscribe_value',
    trafficSource: ['TikTok'],
    group: 'tiktok',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: {
      behind: 'IS',
    },
    text: 'Total Subscribe Value',
    class: 'badge-primary',
  },
};

const googleadsFields: Record<string, Field> = {
  // Google Ads fields
  googleads_active_view_impressions: {
    key: 'googleads_active_view_impressions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Active View Impressions',
    class: 'badge-primary',
  },
  googleads_active_view_measurable_cost_micros: {
    key: 'googleads_active_view_measurable_cost_micros',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Active View Measurable Cost',
    class: 'badge-primary',
  },
  googleads_all_conversions: {
    key: 'googleads_all_conversions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'All Conversions',
    class: 'badge-primary',
  },
  googleads_cross_device_conversions: {
    key: 'googleads_cross_device_conversions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Cross Device Conversions',
    class: 'badge-primary',
  },
  googleads_clicks: {
    key: 'googleads_clicks',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Clicks',
    class: 'badge-primary',
  },
  googleads_conversions: {
    key: 'googleads_conversions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Conversions',
    class: 'badge-primary',
  },
  googleads_cost_micros: {
    key: 'googleads_cost_micros',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS', ...placeholder.$ },
    text: 'Cost',
    class: 'badge-primary',
  },
  googleads_engagements: {
    key: 'googleads_engagements',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Engagements',
    class: 'badge-primary',
  },
  googleads_gmail_forwards: {
    key: 'googleads_gmail_forwards',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Gmail Forwards',
    class: 'badge-primary',
  },
  googleads_gmail_saves: {
    key: 'googleads_gmail_saves',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Gmail Saves',
    class: 'badge-primary',
  },
  googleads_gmail_secondary_clicks: {
    key: 'googleads_gmail_secondary_clicks',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Gmail Secondary Clicks',
    class: 'badge-primary',
  },
  googleads_historical_quality_score: {
    key: 'googleads_historical_quality_score',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Historical Quality Score',
    class: 'badge-primary',
  },
  googleads_impressions: {
    key: 'googleads_impressions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Impressions',
    class: 'badge-primary',
  },
  googleads_interactions: {
    key: 'googleads_interactions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Interactions',
    class: 'badge-primary',
  },
  googleads_invalid_clicks: {
    key: 'googleads_invalid_clicks',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Invalid Clicks',
    class: 'badge-primary',
  },
  googleads_message_chats: {
    key: 'googleads_message_chats',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Message Chats',
    class: 'badge-primary',
  },
  googleads_message_impressions: {
    key: 'googleads_message_impressions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Message Impressions',
    class: 'badge-primary',
  },
  googleads_phone_calls: {
    key: 'googleads_phone_calls',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Phone Calls',
    class: 'badge-primary',
  },
  googleads_phone_impressions: {
    key: 'googleads_phone_impressions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Phone Impressions',
    class: 'badge-primary',
  },
  googleads_video_views: {
    key: 'googleads_video_views',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Video Views',
    class: 'badge-primary',
  },
  googleads_view_through_conversions: {
    key: 'googleads_view_through_conversions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'View Through Conversions',
    class: 'badge-primary',
  },
  googleads_active_view_ctr: {
    key: 'googleads_active_view_ctr',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Active View CTR',
    class: 'badge-primary',
  },
  googleads_all_conversions_from_interactions: {
    key: 'googleads_all_conversions_from_interactions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'All Conversions From Interactions',
    class: 'badge-primary',
  },
  googleads_all_conversions_value_per_cost: {
    key: 'googleads_all_conversions_value_per_cost',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'All Conversions Value Per Cost',
    class: 'badge-primary',
  },
  googleads_content_impression_share: {
    key: 'googleads_content_impression_share',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Content Impression Share',
    class: 'badge-primary',
  },
  googleads_conversion_per_interaction: {
    key: 'googleads_conversion_per_interaction',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Conversion Per Interaction',
    class: 'badge-primary',
  },
  googleads_cost_per_all_conversions: {
    key: 'googleads_cost_per_all_conversions',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Cost Per All Conversions',
    class: 'badge-primary',
  },
  googleads_cost_per_conversion: {
    key: 'googleads_cost_per_conversion',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Cost Per Conversion',
    class: 'badge-primary',
  },
  googleads_cpc: {
    key: 'googleads_cpc',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'CPC',
    class: 'badge-primary',
  },
  googleads_ctr: {
    key: 'googleads_ctr',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'CTR',
    class: 'badge-primary',
  },
  googleads_engagements_rate: {
    key: 'googleads_engagements_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Engagements Rate',
    class: 'badge-primary',
  },
  googleads_invalid_click_rate: {
    key: 'googleads_invalid_click_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Invalid Click Rate',
    class: 'badge-primary',
  },
  googleads_message_chat_rate: {
    key: 'googleads_message_chat_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Message Chat Rate',
    class: 'badge-primary',
  },
  googleads_phone_through_rate: {
    key: 'googleads_phone_through_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Phone Through Rate',
    class: 'badge-primary',
  },
  googleads_video_view_rate: {
    key: 'googleads_video_view_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Video View Rate',
    class: 'badge-primary',
  },
  googleads_interaction_rate: {
    key: 'googleads_interaction_rate',
    trafficSource: ['GoogleAds'],
    group: 'googleads',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Interaction Rate',
    class: 'badge-primary',
  },
};

const yahoodspFields: Record<string, Field> = {
  yahoodsp_ydsp_view_through_conversion: {
    key: 'yahoodsp_ydsp_view_through_conversion',
    trafficSource: ['YahooDSP'],
    group: 'yahoodsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'is' },
    text: 'View Through Conversion',
    class: 'badge-primary',
  },
  yahoodsp_ydsp_click_through_conversion: {
    key: 'yahoodsp_ydsp_click_through_conversion',
    trafficSource: ['YahooDSP'],
    group: 'yahoodsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'is' },
    text: 'Click Through Conversion',
    class: 'badge-primary',
  },
  yahoodsp_ydsp_multiplied_ecpm: {
    key: 'yahoodsp_ydsp_multiplied_ecpm',
    trafficSource: ['YahooDSP'],
    group: 'yahoodsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'is' },
    text: 'Multiplied Ecpm',
    class: 'badge-primary',
  },
};

const amazonFields: Record<string, Field> = {
  // Amazon DSP fields
  amz_total_fee: {
    key: 'amz_total_fee',
    group: 'amazondsp',
    trafficSource: ['AmazonDSP'],
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Fee',
    class: 'badge-primary',
  },
  amz_supply_cost: {
    key: 'amz_supply_cost',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Supply Cost',
    class: 'badge-primary',
  },
  amz_sales: {
    key: 'amz_sales',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Sales',
    class: 'badge-primary',
  },
  amz_measurable_impressions: {
    key: 'amz_measurable_impressions',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Measurable Imp',
    class: 'badge-primary',
  },
  amz_video_started: {
    key: 'amz_video_started',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Video plays',
    class: 'badge-primary',
  },
  amz_video_completed: {
    key: 'amz_video_completed',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Video Completed',
    class: 'badge-primary',
  },
  amz_mobile_app_first_start_views: {
    key: 'amz_mobile_app_first_start_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'App Views',
    class: 'badge-primary',
  },
  amz_mobile_app_first_start_clicks: {
    key: 'amz_mobile_app_first_start_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'App Clicks',
    class: 'badge-primary',
  },
  amz_dpv_views: {
    key: 'amz_dpv_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'DPV Views',
    class: 'badge-primary',
  },
  amz_dpv_clicks: {
    key: 'amz_dpv_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'DPV Clicks',
    class: 'badge-primary',
  },
  amz_prpv_views: {
    key: 'amz_prpv_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'PRPV Views',
    class: 'badge-primary',
  },
  amz_prpv_clicks: {
    key: 'amz_prpv_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'PRPV Clicks',
    class: 'badge-primary',
  },
  amz_atl_views: {
    key: 'amz_atl_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'ATL Views',
    class: 'badge-primary',
  },
  amz_atl_clicks: {
    key: 'amz_atl_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'ATL Clicks',
    class: 'badge-primary',
  },
  amz_atc_views: {
    key: 'amz_atc_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'ATC Views',
    class: 'badge-primary',
  },
  amz_atc_clicks: {
    key: 'amz_atc_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'DPV Views',
    class: 'badge-primary',
  },
  amz_purchases_clicks: {
    key: 'amz_purchases_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchase Clicks',
    class: 'badge-primary',
  },
  amz_add_to_watchlist_views: {
    key: 'amz_add_to_watchlist_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Watchlist Views',
    class: 'badge-primary',
  },
  amz_subscription_page_clicks: {
    key: 'amz_subscription_page_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Subscription Clicks',
    class: 'badge-primary',
  },
  amz_subscription_page_views: {
    key: 'amz_subscription_page_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Subscription Views',
    class: 'badge-primary',
  },
  amz_units_sold: {
    key: 'amz_units_sold',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Units Sold',
    class: 'badge-primary',
  },
  amz_new_subscribe_and_save_views: {
    key: 'amz_new_subscribe_and_save_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Subscribe Clicks',
    class: 'badge-primary',
  },
  amz_new_subscribe_and_save_clicks: {
    key: 'amz_new_subscribe_and_save_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'PRPV Clicks',
    class: 'badge-primary',
  },
  amz_product_purchased_views: {
    key: 'amz_product_purchased_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchase Views',
    class: 'badge-primary',
  },
  amz_product_purchased_clicks: {
    key: 'amz_product_purchased_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchase Clicks',
    class: 'badge-primary',
  },
  amz_ecpp: {
    key: 'amz_ecpp',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'eCPP',
    class: 'badge-primary',
  },
  amz_total_pixel_cvr: {
    key: 'amz_total_pixel_cvr',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Pixel CVR',
    class: 'badge-primary',
  },
  amz_total_pixel_cpa: {
    key: 'amz_total_pixel_cpa',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Pixel CPA',
    class: 'badge-primary',
  },
  amz_product_purchased_cvr: {
    key: 'amz_product_purchased_cvr',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Product Purchased CVR',
    class: 'badge-primary',
  },
  amz_total_product_purchased_cvr: {
    key: 'amz_total_product_purchased_cvr',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Product Purchased CVR',
    class: 'badge-primary',
  },
  amz_ecp_dpv: {
    key: 'amz_ecp_dpv',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'eCPDPV',
    class: 'badge-primary',
  },
  amz_ecp_atc: {
    key: 'amz_ecp_atc',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'eCPATC',
    class: 'badge-primary',
  },
  amz_purchases_rate: {
    key: 'amz_purchases_rate',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Purchases Rate',
    class: 'badge-primary',
  },
  amz_ecp_purchase: {
    key: 'amz_ecp_purchase',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'eCP Purchase',
    class: 'badge-primary',
  },
  amz_new_subscribe_and_save_ecpp: {
    key: 'amz_new_subscribe_and_save_ecpp',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Amz New Subscribe And Save eCPP',
    class: 'badge-primary',
  },
  amz_cost_per_subscription_and_save: {
    key: 'amz_cost_per_subscription_and_save',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'eCP Subscription And Save',
    class: 'badge-primary',
  },
  amz_total_subscribe_and_save_subscription_rate: {
    key: 'amz_total_subscribe_and_save_subscription_rate',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Subscribe And Save Subscription Rate',
    class: 'badge-primary',
  },
  amz_dpv_rate: {
    key: 'amz_dpv_rate',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'DPV Rate',
    class: 'badge-primary',
  },
  amz_total_add_to_cart_rate: {
    key: 'amz_total_add_to_cart_rate',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Add To Cart Rate',
    class: 'badge-primary',
  },
  amz_total_add_to_cart_cpa: {
    key: 'amz_total_add_to_cart_cpa',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Add To Cart eCPA',
    class: 'badge-primary',
  },
  amz_total_ecpp: {
    key: 'amz_total_ecpp',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total eCPP',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_product_ecpp: {
    key: 'amz_total_new_to_brand_product_ecpp',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total New To Brand Product eCPP',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_purchase_rate: {
    key: 'amz_total_new_to_brand_purchase_rate',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total New To Brand Purchase Rate',
    class: 'badge-primary',
  },
  amz_total_roas: {
    key: 'amz_total_roas',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total ROAS',
    class: 'badge-primary',
  },
  amz_purchases_views: {
    key: 'amz_purchases_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchases Views',
    class: 'badge-primary',
  },
  amz_add_to_watchlist_clicks: {
    key: 'amz_add_to_watchlist_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Add To Watchlist Clicks',
    class: 'badge-primary',
  },
  amz_total_detail_page_clicks: {
    key: 'amz_total_detail_page_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Detail Page Clicks',
    class: 'badge-primary',
  },
  amz_total_detail_page_views: {
    key: 'amz_total_detail_page_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Detail Page Views',
    class: 'badge-primary',
  },
  amz_total_add_to_list_views: {
    key: 'amz_total_add_to_list_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To List Views',
    class: 'badge-primary',
  },
  amz_total_add_to_list_clicks: {
    key: 'amz_total_add_to_list_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To List Clicks',
    class: 'badge-primary',
  },
  amz_total_add_to_cart_views: {
    key: 'amz_total_add_to_cart_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To Cart Views',
    class: 'badge-primary',
  },
  amz_total_add_to_cart_clicks: {
    key: 'amz_total_add_to_cart_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To Cart Clicks',
    class: 'badge-primary',
  },
  amz_total_purchases_views: {
    key: 'amz_total_purchases_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Purchases Views',
    class: 'badge-primary',
  },
  amz_total_purchases_clicks: {
    key: 'amz_total_purchases_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Purchases Clicks',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_purchases_views: {
    key: 'amz_total_new_to_brand_purchases_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total New To Brand Purchases Views',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_purchases_clicks: {
    key: 'amz_total_new_to_brand_purchases_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total New To Brand Purchases Clicks',
    class: 'badge-primary',
  },
  amz_total_subscribe_and_save_subscription_views: {
    key: 'amz_total_subscribe_and_save_subscription_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Subscribe And Save Subscription Views',
    class: 'badge-primary',
  },
  amz_total_subscribe_and_save_subscription_clicks: {
    key: 'amz_total_subscribe_and_save_subscription_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Subscribe And Save Subscription Clicks',
    class: 'badge-primary',
  },
  amz_total_pixel_views: {
    key: 'amz_total_pixel_views',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Pixel Views',
    class: 'badge-primary',
  },
  amz_total_pixel_clicks: {
    key: 'amz_total_pixel_clicks',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Pixel Clicks',
    class: 'badge-primary',
  },
  amz_mobile_app_first_start: {
    key: 'amz_mobile_app_first_start',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Mobile App First Start',
    class: 'badge-primary',
  },
  amz_dpv: {
    key: 'amz_dpv',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'DPV',
    class: 'badge-primary',
  },
  amz_pprv: {
    key: 'amz_pprv',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Product purchased',
    class: 'badge-primary',
  },
  amz_atl: {
    key: 'amz_atl',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'ATL',
    class: 'badge-primary',
  },
  amz_atc: {
    key: 'amz_atc',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'ATC',
    class: 'badge-primary',
  },
  amz_purchases: {
    key: 'amz_purchases',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Purchases',
    class: 'badge-primary',
  },
  amz_add_to_watchlist: {
    key: 'amz_add_to_watchlist',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Add To Watchlist',
    class: 'badge-primary',
  },
  amz_subscription_page: {
    key: 'amz_subscription_page',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Subscription Page',
    class: 'badge-primary',
  },
  amz_new_subscribe_and_save: {
    key: 'amz_new_subscribe_and_save',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'New Subscribe And Save',
    class: 'badge-primary',
  },
  amz_product_purchased: {
    key: 'amz_product_purchased',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Product Purchased',
    class: 'badge-primary',
  },
  amz_total_detail_page: {
    key: 'amz_total_detail_page',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Detail Page',
    class: 'badge-primary',
  },
  amz_total_add_to_list: {
    key: 'amz_total_add_to_list',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To List',
    class: 'badge-primary',
  },
  amz_total_add_to_cart: {
    key: 'amz_total_add_to_cart',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Add To Cart',
    class: 'badge-primary',
  },
  amz_total_purchases: {
    key: 'amz_total_purchases',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Purchases',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_purchases: {
    key: 'amz_total_new_to_brand_purchases',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total New To Brand Purchases',
    class: 'badge-primary',
  },
  amz_total_subscribe_and_save_subscription: {
    key: 'amz_total_subscribe_and_save_subscription',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Subscribe And Save Subscription',
    class: 'badge-primary',
  },
  amz_total_pixel: {
    key: 'amz_total_pixel',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Pixel',
    class: 'badge-primary',
  },
  amz_total_cost: {
    key: 'amz_total_cost',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'IS' },
    text: 'Total Cost',
    class: 'badge-primary',
  },
  amz_click_throughs: {
    key: 'amz_click_throughs',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Click Throughs',
    class: 'badge-primary',
  },
  amz_impressions: {
    key: 'amz_impressions',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Impressions',
    class: 'badge-primary',
  },
  amz_viewable_impressions: {
    key: 'amz_viewable_impressions',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Viewable Impressions',
    class: 'badge-primary',
  },
  amz_video_midpoint: {
    key: 'amz_video_midpoint',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Video Midpoint',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_units_sold: {
    key: 'amz_total_new_to_brand_units_sold',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total New To Brand Units Sold',
    class: 'badge-primary',
  },
  amz_new_to_brand_units_sold: {
    key: 'amz_new_to_brand_units_sold',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'New To Brand Units Sold',
    class: 'badge-primary',
  },
  amz_new_to_brand_product_sales: {
    key: 'amz_new_to_brand_product_sales',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'New To Brand Product Sales',
    class: 'badge-primary',
  },
  amz_total_new_to_brand_product_sales: {
    key: 'amz_total_new_to_brand_product_sales',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total New To Brand Product Sales',
    class: 'badge-primary',
  },
  amz_total_units_sold: {
    key: 'amz_total_units_sold',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Units Sold',
    class: 'badge-primary',
  },
  amz_total_sales: {
    key: 'amz_total_sales',
    trafficSource: ['AmazonDSP'],
    group: 'amazondsp',
    baseType: RuleTypes.Conditions.BaseFieldType.Metric,
    type: RuleTypes.Conditions.ValueType.Number,
    value: { behind: 'ARE' },
    text: 'Total Sales',
    class: 'badge-primary',
  },
};

export const dateFields: Record<string, Field> = {
  [RuleTypes.ConditionsV2.CustomDateCondition.CreatedAt]: {
    key: RuleTypes.ConditionsV2.CustomDateCondition.CreatedAt,
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Facebook,
      TrafficSource.TikTok,
      TrafficSource.YahooDSP,
      TrafficSource.Mgid,
      TrafficSource.Adskeeper,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: RuleTypes.ConditionsV2.CustomDateCondition.CreatedAt,
    },
    text: 'Days since creation',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
  [RuleTypes.ConditionsV2.CustomDateCondition.StartDate]: {
    key: RuleTypes.ConditionsV2.CustomDateCondition.StartDate,
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Taboola,
      TrafficSource.GoogleAds,
      TrafficSource.Facebook,
      TrafficSource.MediaGo,
      TrafficSource.YahooDSP,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: RuleTypes.ConditionsV2.CustomDateCondition.StartDate,
    },
    text: 'Days since started',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
  [RuleTypes.ConditionsV2.CustomDateCondition.EndDate]: {
    key: RuleTypes.ConditionsV2.CustomDateCondition.EndDate,
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Taboola,
      TrafficSource.GoogleAds,
      TrafficSource.Facebook,
      TrafficSource.MediaGo,
      TrafficSource.YahooDSP,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: RuleTypes.ConditionsV2.CustomDateCondition.EndDate,
    },
    text: 'Days until end',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
  created_at: {
    key: 'created_at',
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Facebook,
      TrafficSource.TikTok,
      TrafficSource.YahooDSP,
      TrafficSource.Mgid,
      TrafficSource.Adskeeper,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: 'created_at',
    },
    text: 'Created Date',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
  startDate: {
    key: 'startDate',
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Taboola,
      TrafficSource.GoogleAds,
      TrafficSource.Facebook,
      TrafficSource.MediaGo,
      TrafficSource.YahooDSP,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: 'startDate',
    },
    text: 'Start Date',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
  endDate: {
    key: 'endDate',
    trafficSource: [
      TrafficSource.RevContent,
      TrafficSource.Taboola,
      TrafficSource.GoogleAds,
      TrafficSource.Facebook,
      TrafficSource.MediaGo,
      TrafficSource.YahooDSP,
    ],
    availableOnLevel: ['Campaign'],
    group: 'ts',
    baseType: RuleTypes.Conditions.BaseFieldType.Date,
    type: RuleTypes.Conditions.ValueType.DateField,
    value: {
      behind: 'IS',
      key: 'endDate',
    },
    text: 'End Date',
    notAvailableForToComparison: true,
    class: 'badge-tea-green',
  },
};

export const fieldsGroup: FieldGroup[] = [
  {
    value: 'tr',
    group: 'tr',
    useInCustomMetric: true,
    priority: 2,
    label: 'Tracker Metrics',
  },
  {
    value: 'ts',
    group: 'ts',
    priority: 1,
    useInCustomMetric: true,
    label: 'Traffic Source Metrics',
  },
  {
    value: 'ga',
    group: 'ga',
    priority: 4,
    useInCustomMetric: true,
    trackers: [Tracker.GoogleAnalytics],
    label: 'Google Analytics Metrics',
  },
  {
    value: 'ga4',
    group: 'ga4',
    useInCustomMetric: true,
    priority: 3,
    trackers: [Tracker.GoogleAnalytics4],
    label: 'Google Analytics 4 Metrics',
  },
  {
    value: 'cc',
    group: 'cc',
    useInCustomMetric: true,
    priority: 6,
    label: 'Custom Conversion Metrics',
  },
  {
    value: 'cm',
    priority: 5,
    group: 'cm',
    label: 'Custom Metric Metrics',
  },
  {
    value: 'pf',
    group: 'pf',
    priority: 7,
    useInCustomMetric: true,
    trackers: [
      PublisherTrackers.Ads,
      PublisherTrackers.Bodis,
      PublisherTrackers.Sedo,
      PublisherTrackers.System1,
      PublisherTrackers.DomainActive,
    ],
    label: 'Search Feed Metrics',
  },
  {
    value: 'ayf',
    group: 'ayf',
    priority: 13,
    useInCustomMetric: true,
    trackers: [Tracker.AssertiveYield],
    label: 'AssertiveYield Metrics',
  },
  {
    value: 'facebook',
    group: 'facebook',
    priority: 8,
    useInCustomMetric: true,
    trafficSource: [TrafficSource.Facebook],
    label: 'Facebook Metrics',
  },
  {
    value: 'amazondsp',
    group: 'amazondsp',
    priority: 10,
    useInCustomMetric: true,
    trafficSource: [TrafficSource.AmazonDSP],
    label: 'AmazonDSP',
  },
  {
    value: 'tiktok',
    group: 'tiktok',
    priority: 9,
    useInCustomMetric: true,
    trafficSource: [TrafficSource.TikTok],
    label: 'TikTok Metrics',
  },
  {
    value: 'googleads',
    group: 'googleads',
    priority: 13,
    useInCustomMetric: true,
    trafficSource: [TrafficSource.GoogleAds],
    label: 'GoogleAds Metrics',
  },
  {
    value: 'yahoodsp',
    group: 'yahoodsp',
    priority: 14,
    useInCustomMetric: true,
    trafficSource: [TrafficSource.YahooDSP],
    label: 'YahooDSP Metrics',
  },
  {
    value: 'campaign',
    group: 'campaign',
    label: 'Campaign Level Metrics',
    priority: 12,
  },
  {
    value: 'other',
    group: 'other',
    label: 'Other',
    priority: 11,
  },
];

export const fieldsMap = {
  ...dateFields,
  ...modelFields,
  ...trBaseFields,
  ...tsBaseFields,
  ...facebookFields,
  ...publisherFields,
  ...tikTokFields,
  ...googleadsFields,
  ...yahoodspFields,
  ...ayFields,
  ...amazonFields,
};
