import componentWrapper from '../../../components/ComponentWrapper';
import ccActivitySchedule from '../../../components/ccActivitySchedule';
import ccBlockedSites from '../../../components/ccBlockedSites';
import ccCheckbox from '../../../components/ccCheckbox';
import ccDatePicker from '../../../components/ccDatePicker';
import ccInput from '../../../components/ccInput';
import ccInputTags from '../../../components/ccInputTags';
import ccMultiSelect from '../../../components/ccMultiSelect';
import ccNestedSelect from '../../../components/ccNestedSelect';
import ccOsTargetingSelect from '../../../components/ccOsTargetingSelect';
import ccRadioButton from '../../../components/ccRadioButton';
import ccTargetingCategories from '../../../components/ccTargetingCategories';
import ccTextarea from '../../../components/ccTextarea';
import ccTimePicker from '../../../components/ccTimePicker';
import props from '../../../mixins/props';

export default {
  mixins: [props],
  props: {
    payload: { type: Object },
  },
  components: {
    componentWrapper,
    ccInput,
    ccRadioButton,
    ccDatePicker,
    ccTimePicker,
    ccMultiSelect,
    ccCheckbox,
    ccOsTargetingSelect,
    ccTextarea,
    ccTargetingCategories,
    ccActivitySchedule,
    ccNestedSelect,
    ccInputTags,
    ccBlockedSites,
  },
  data() {
    return {
      campaignComponents: {},
      campaignWatches: {},
    };
  },
  async created() {
    this.campaignComponents = this.$c_model.campaign.getComponents();
    this.campaignWatches = this.$c_model.campaign.getWatches();
    const watchList = {};
    this.$watch('$c_model', (model) => {
      // UnBind
      Object.values(watchList).forEach((unWatch) => { unWatch(); });
      if (model && this.campaignWatches) {
        // Bind watch Logic
        // Hint: data.trigger() for custom watch
        Object.entries(this.campaignWatches).forEach(([key, data]) => {
          const target = data.trigger ? data.trigger : `campaignComponents.${key}.value`;
          watchList[key] = this.$watch(target, data.fn, { immediate: true, deep: true });
        });
      }
    }, { immediate: true });
    // Set Form
    if (this.payload.campaign) {
      await this.$c_model.campaign.setForm(this.payload.campaign, this.$nextTick, this.mode);
    }
  },
  computed: {
    $c_model() {
      if (this.config.Account.value) {
        try {
          return this.config.Models[this.config.Account.value.type.uniqueName] || null;
        } catch (err) {
          console.log(err);
        }
      }
      return null;
    },
    $c_components() {
      try {
        return this.campaignComponents;
      } catch (err) {
        return null;
      }
    },
  },
  methods: {
    async validate() {
      const components = [];
      Object.entries(this.$refs).forEach(([name, component]) => {
        if (component && this.$_isActive(name)) {
          components.push(component);
        }
      });
      try {
        const resultArr = await Promise.all(components.map((cmp) => cmp.validate()));
        return resultArr.reduce((total, value) => total && value, true);
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    $_isActive(name) {
      try {
        return this.$c_components[name].active;
      } catch (err) {
        return false;
      }
    },
    $_selectedValue(name) {
      if (this.$c_components[name].value) {
        return this.$c_components[name].value.value;
      }
      return '';
    },
    $_getLabelProps(name) {
      const component = this.$c_components[name];
      return { label: component.label, popover: component.popover, disableLabel: !!component.disableLabel };
    },
    $_getProps(name) {
      const component = this.$c_components[name];
      const key = component.key || name;
      return {
        ref: component.name, mode: this.mode, retry: this.retry, config: this.config, model: component, key,
      };
    },
    $_getOptions(name) {
      const component = this.$c_components[name];
      return component.options;
    },
    $_getEvents(name) {
      const components = this.$c_components;
      const component = components[name];
      const events = {};
      if (component.events) {
        Object.entries(component.events).forEach(([event, fn]) => {
          events[event] = function () {
            // eslint-disable-next-line prefer-rest-params
            fn({ components, component }, ...arguments);
          };
        });
      }
      return events;
    },
  },
};
