var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cc-preview-tabs",class:`cc-preview-tabs--${_vm.variant}`},_vm._l((_vm.tabs),function(tab){return _c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
      content: tab.popover?.position === 'hover' ? tab.popover.content : '',
      customClass: 'cc-popover',
      interactive: false,
      html: true,
      boundary: 'viewport',
    }),expression:"{\n      content: tab.popover?.position === 'hover' ? tab.popover.content : '',\n      customClass: 'cc-popover',\n      interactive: false,\n      html: true,\n      boundary: 'viewport',\n    }",modifiers:{"hover":true,"top":true}}],key:tab.id,staticClass:"cc-preview-tabs__btn cc-btn cc-btn--original-icon",class:{ active: _vm.activeTab === tab.id },attrs:{"id":tab.id},on:{"click":function($event){return _setup.onTabClick(tab.id)}}},[_vm._v(" "+_vm._s(tab.title)+" "),(_vm.activeTab === tab.id && tab.popover?.position === 'icon')?_c(_setup.CcIcon,{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
        content: tab.popover.content ?? '',
        customClass: 'cc-popover',
        interactive: false,
        html: true,
        boundary: 'viewport',
        delay: tab.popover.delay,
      }),expression:"{\n        content: tab.popover.content ?? '',\n        customClass: 'cc-popover',\n        interactive: false,\n        html: true,\n        boundary: 'viewport',\n        delay: tab.popover.delay,\n      }",modifiers:{"hover":true,"top":true}}],attrs:{"type":"infoIcon","height":16}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }