export type ViewLevelActionsEvent = AddMediaDropdownEvent | FilterImageEvent | AddBudgetTypeDropdownEvent;

export enum AddMediaDropdownEvent {
  ADD_IMAGE = 'ADD_IMAGE',
  ADD_VIDEO = 'ADD_VIDEO',
}

export enum AddBudgetTypeDropdownEvent {
  ADD_DAILY_BUDGET = 'ADD_DAILY_BUDGET',
  ADD_LIFETIME_BUDGET = 'ADD_LIFETIME_BUDGET',
}

export enum FilterImageEvent {
  FULL_HORIZONTAL = 'FULL_HORIZONTAL',
  FULL_VERTICAL = 'FULL_VERTICAL',
  VERTICAL = 'VERTICAL',
  SQUARE = 'SQUARE',
  WIDTH_600PX = 'WIDTH_600PX',
  WIDTH_1080PX = 'WIDTH_1080PX',
  HEIGHT_600PX = 'HEIGHT_600PX',
  HEIGHT_1080PX = 'HEIGHT_1080PX',
}
