<template>
  <div class="animated">
    <system-activity uri :showUTCtime="showUTCtime" />
  </div>
</template>

<script>
import SystemActivity from '@/components/SystemActivity/SystemActivity.vue';

export default {
  name: 'SystemActivityPage',
  components: { SystemActivity },
  props: {
    showUTCtime: Boolean,
  },
};
</script>
