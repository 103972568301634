<template>
  <div id="config_account" class="account-config-container item max account-config-google-analytics">
    <div class="account-config-header">
      <div class="account-config-header__title">
        <h2>Connect GA Views</h2>
      </div>
    </div>

    <div v-if="collection.length && Object.keys($c_accountsMap).length > 0" class="collections">
      <b-btn v-for="(item, index) in collection" :key="index" block class="ga-view-btn" @click="$_edit(item, index)">
        <p class="collection-info">
          <span class="title">
            {{ $c_accountsMap[item.accountId].properties[item.propertyId].views[item.viewId].name }}
            <i class="fa fa-info-circle ml-8 info-icon" :id="`view-item-index-${index}`" />
          </span>
          <b-popover :target="`view-item-index-${index}`" triggers="hover focus">
            <!-- <template slot="title">Config</template> -->
            <p class="mb-1"><b>Account:</b> {{ $c_accountsMap[item.accountId].name }}</p>
            <p class="mb-1"><b>Property:</b> {{ $c_accountsMap[item.accountId].properties[item.propertyId].name }}</p>
            <p class="mb-1"><b>View:</b> {{ $c_accountsMap[item.accountId].properties[item.propertyId].views[item.viewId].name }}</p>
          </b-popover>
        </p>
        <span v-if="!item.keep" class="remove-icon" @click.prevent.stop="$_remove(index)">
          <i class="fa fa-times" />
        </span>
      </b-btn>
    </div>

    <preloader v-if="$c_preloader" margin="30px" />
    <b-btn v-else class="add-ga-view-btn" @click.prevent="modal.model = true">
      <OptimizerIcon class="mr-8" type="insertNew" />
      Add Analytics View
    </b-btn>

    <b-modal
      id="PreventModal"
      modal-class="optimizer-modal add-ga-view-modal"
      v-model="modal.model"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
      centered
    >
      <template slot="modal-header">
        <h2 class="modal-header__title">Configure Tracking Tokens</h2>
        <div class="modal-header__close-icon d-flex justify-content-center align-items-center" @click="$_hideModal">
          <OptimizerIcon type="close" />
        </div>
      </template>

      <b-form
        :ref="$_getScope()"
        @submit.prevent="$_validateForm"
        :data-vv-scope="$_getScope()"
        class="modal-body-form"
      >
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              class="optimizer-form-group mb-15 position-relative"
              label="Account:"
              :invalid-feedback="$_getError('googleAnalyticsAccounts')"
              :state="$_hasError('googleAnalyticsAccounts')"
            >
              <vue-opti-select-light
                id="googleAnalyticsAccounts"
                class="optimizer-select w-100"
                :class="[{ 'has-error': $_hasError('googleAnalyticsAccounts') === false }]"
                name="googleAnalyticsAccounts"
                v-validate="'required'"
                data-vv-as="Account"
                data-vv-validate-on="input|update"
                data-vv-value-path="$c_model"
                :options="modal.options.accounts"
                label-key="text"
                :value="modal.form.account"
                @change="({value}) => { modal.form.account = value; modal.form.property = null; modal.form.view = null; }"
                single
                button-block
                button-placeholder="-- Select Account --"
              />
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              class="optimizer-form-group mb-15 position-relative"
              label="Property:"
              :invalid-feedback="$_getError('googleAnalyticsProperties')"
              :state="$_hasError('googleAnalyticsProperties')"
            >
              <span
                v-b-tooltip.hover.top="{
                  title: $c_viewOptions.length ? '' : 'Please select an account.',
                  html: true,
                }"
              >
                <vue-opti-select-light
                  id="googleAnalyticsProperties"
                  class="optimizer-select w-100"
                  :class="[{ 'has-error': $_hasError('googleAnalyticsProperties') === false }]"
                  name="googleAnalyticsProperties"
                  v-validate="'required'"
                  data-vv-as="Property"
                  data-vv-validate-on="input|update"
                  data-vv-value-path="$c_model"
                  :options="modal.options.properties[modal.form.account] || []"
                  label-key="text"
                  :value="modal.form.property"
                  :disabled="!modal.options.properties[modal.form.account] || !modal.options.properties[modal.form.account].length"
                  @change="({value}) => { modal.form.property = value; modal.form.view = null; }"
                  single
                  button-block
                  button-placeholder="-- Select Property --"
                />
              </span>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              class="optimizer-form-group position-relative"
              label="Views:"
              :invalid-feedback="$_getError('googleAnalyticsViews')"
              :state="$_hasError('googleAnalyticsViews')"
            >
              <span
                v-b-tooltip.hover.top="{
                  title: $c_viewOptions.length ? '' : 'Please select an account and a property.',
                  html: true,
                }"
              >
                <vue-opti-select-light
                  id="googleAnalyticsViews"
                  ref="googleAnalyticsViews"
                  class="optimizer-select w-100"
                  :class="{ 'has-error': $_hasError('googleAnalyticsViews') === false }"
                  name="googleAnalyticsViews"
                  v-model="modal.form.metric"
                  v-validate="'required|is_unique_view'"
                  data-vv-as="View"
                  data-vv-validate-on="input|update"
                  data-vv-value-path="$c_model"
                  label-key="text"
                  option-type="checkbox"
                  :disabled="!modal.form.property || !modal.form.account"
                  :options="$c_viewOptions"
                  :value="modal.form.view"
                  single
                  button-block
                  button-placeholder="-- Select View --"
                  @change="({value}) => { modal.form.view = value }"
                />
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="tokens">
          <h5 class="tokens__title">Tokens</h5>
          <div class="row">
            <div class="col-md-6" v-for="(token, key) in data.tokens" :key="key">
              <b-form-group class="optimizer-form-group">
                <template slot="label">
                  {{ token.message }}
                  <b>
                    <code>{{ token.values.join(' Or ') }}</code>
                  </b>
                </template>
                <vue-opti-select-light
                  id="`googleAnalytics${key}`"
                  class="optimizer-select w-100 custom-tracking-select"
                  :options="modal.options.tokens"
                  label-key="text"
                  :value="modal.form.tokens[key]"
                  allowClear
                  @clear="()=> { modal.form.tokens[key] = null }"
                  @change="({value}) => { checkIfInCustomToken(value); modal.form.tokens[key] = value; $_tokenSelect(value, key); }"
                  single
                  button-block
                  :disabled="isInCustomToken(key)"
                  button-placeholder="-- Select Token --"
                >
                  <template #BUTTON_PLACEHOLDER v-if="isInCustomToken(key)">
                    <div
                      class="custom-tracking-tooltip"
                      v-b-tooltip.hover.bottom
                      title="To use this token please remove it from Custom Tracking"
                    >
                      -- Select Token --
                    </div>
                  </template>
                </vue-opti-select-light>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
      <div class="fieldsContainer mt-3">
        <div class="fieldRow">
          <switch-button
            :checked="modal.form.customTrackingEnabled"
            :hover-title="{ enabled: 'Disable', disabled: 'Enable' }"
            :update="(value) => $_enableCustomTracking(value)"
            class="mb-2"
          />
          <span class="mb-1">Enable Custom Tracking
            <i
              class="fa fa-info-circle info-icon"
              style="color: rgba(84, 101, 130, 0.6)"
              v-b-tooltip.hover.top
              title="Custom tracking allows to pass all Google Analytics info in 1 utm token using a special format. This is useful if you are already using other utm tokens for other purposes and it is not possible to use them with TheOptimizer."
            />
          </span>
        </div>
        <div class="row fieldRow">
          <template>
            <span class="col-12 col-md-6 fieldInput">
              <b-form-input
                class="optimizer-form-input m-0"
                type="text"
                :placeholder="'Custom Tracking Token'"
                :disabled="!modal.form.customTrackingEnabled"
                size="sm"
                v-model="modal.form.customTrackingToken"
              />
            </span>
          </template>
          <template>
            <vue-opti-select-light
              class="optimizer-select col-12 col-md-6"
              :options="$_subOptions()"
              unique-key="id"
              label-key="name"
              single
              :value="modal.form.subSelected"
              button-block
              allowClear
              @clear="()=> { modal.form.subSelected = null }"
              :disabled="!modal.form.customTrackingEnabled"
              button-placeholder="Select Subid"
              @change="({id}) => { checkIfInTokens(id); modal.form.subSelected = id }"
            />
          </template>
        </div>
        <div class="fieldRow">
          <div v-if="modal.form.trackingCode != ''" class="ml-2 tokens__title fs-13">
            {{ `IMPORTANT: Make sure to update your ${source[0].type.uniqueName} campaigns so that they include the tracking code below` }}
            <br />
            <span class="font-italic">{{ modal.form.trackingCode }}</span>
          </div>
        </div>
      </div>
      <template slot="modal-footer">
        <b-btn class="secondary-button ml-0 mr-8" @click="$_hideModal">Cancel</b-btn>
        <b-btn class="primary-button ml-0 mr-16" @click="$_apply">Add</b-btn>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Preloader from '@sh/components/Utils/Preloader.vue';
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import notifications from '@sh/mixins/notifications';

const scope = 'googleAnalyticsForm';

export default {
  name: 'GoogleAnalyticsTokens',
  components: {
    Preloader,
    OptimizerIcon,
  },
  mixins: [notifications],
  props: {
    tracker: {
      type: Object,
      default: null,
      required: true,
    },
    source: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      data: {
        accounts: null,
        tokens: null,
      },
      collection: [],
      modal: {
        model: false,
        options: {
          accounts: [],
          properties: {},
          views: {},
          tokens: [
            { text: 'utm_campaign', value: 'ga:campaign' },
            { text: 'utm_source', value: 'ga:source' },
            { text: 'utm_medium', value: 'ga:medium' },
            { text: 'utm_term', value: 'ga:keyword' },
            { text: 'utm_content', value: 'ga:adContent' },
          ],
        },
        form: {
          account: null,
          property: null,
          view: null,
          tokens: {},
          customTrackingEnabled: false,
          customTrackingToken: '',
          trackingCode: '',
          subSelected: null,
          index: null,
        },
      },
      preloaderMulti: { tracker: true, source: true },
    };
  },
  computed: {
    $c_preloader() {
      return this.preloaderMulti.tracker || this.preloaderMulti.source;
    },
    $c_accountsMap() {
      const accounts = {};
      if (this.data.accounts) {
        this.data.accounts.forEach((acc) => {
          accounts[acc.id] = { id: acc.id, name: acc.name, properties: {} };
          acc.properties.forEach((prop) => {
            accounts[acc.id].properties[prop.id] = { id: acc.id, name: prop.name, views: {} };
            prop.views.forEach((view) => {
              accounts[acc.id].properties[prop.id].views[view.id] = view;
            });
          });
        });
      }
      return accounts;
    },
    $c_viewOptions() {
      return this.modal.form.account && this.modal.form.property ? this.modal.options.views[this.modal.form.account][this.modal.form.property] ?? [] : [];
    },
  },
  watch: {
    tracker: {
      immediate: true,
      async handler() {
        if (this.tracker.id) {
          this.preloaderMulti.tracker = true;
          // Get Google Analytics Account Data
          const accounts = await this.$api.trackers.getAccounts(this.tracker.id);
          this.data.accounts = accounts;
          this.data.accounts.forEach((acc) => {
            this.modal.options.accounts.push({ text: acc.name, value: acc.id });
            this.modal.options.properties[acc.id] = [];
            this.modal.options.views[acc.id] = {};
            acc.properties.forEach((prop) => {
              this.modal.options.properties[acc.id].push({ text: prop.name, value: prop.id });
              this.modal.options.views[acc.id][prop.id] = [];
              prop.views.forEach((view) => {
                this.modal.options.views[acc.id][prop.id].push({ text: view.name, value: view.id });
              });
            });
          });
          this.preloaderMulti.tracker = false;
        }
      },
    },
    source: {
      immediate: true,
      async handler() {
        if (this.source) {
          this.preloaderMulti.source = true;
          this.data.tokens = await this.$api.trafficSources.tokens(this.source[0].type.uniqueName);
          for (const key in this.data.tokens) {
            if (typeof this.modal.form.tokens[key] === 'undefined') this.modal.form.tokens[key] = null;
          }
          this.modal.form.customTrackingToken = Object.values(this.data.tokens).map((token) => token.values[0]).join('|');
          // Set default config
          const config = this.source.linkedTrackers;
          if (config) {
            const { variables } = config;
            if (Array.isArray(variables)) {
              const collection = [];
              variables.forEach((item) => {
                if (item) {
                  const itemPayload = {
                    keep: true, accountId: item.accountId, propertyId: item.propertyId, viewId: item.viewId, customTrackingToken: item.customTrackingToken, tokens: {},
                  };
                  const customTrackingToken = Object.keys(item).find((c) => c.includes('|'));
                  if (customTrackingToken) {
                    itemPayload.customTrackingToken = customTrackingToken;
                    itemPayload.customTrackingEnabled = true;
                    itemPayload.subSelected = item[customTrackingToken];
                  }
                  for (const key in this.data.tokens) {
                    const token = this.data.tokens[key];
                    if (item[token.values[0]]) itemPayload.tokens[key] = item[token.values[0]];
                  }
                  collection.push(itemPayload);
                }
              });
              this.collection = collection;
            }
          }
          this.preloaderMulti.source = false;
        }
      },
    },
    collection: {
      handler(collection) {
        const data = {
          variables: [],
        };
        if (collection.length) {
          collection.forEach((item) => {
            const itemPayload = {
              accountId: item.accountId,
              propertyId: item.propertyId,
              viewId: item.viewId,
            };
            if (item.customTrackingEnabled && item.subSelected) {
              itemPayload[item.customTrackingToken] = item.subSelected;
            }
            for (const key in item.tokens) {
              itemPayload[this.data.tokens[key].values[0]] = item.tokens[key];
            }
            data.variables.push(itemPayload);
          });
          this.$emit('completed', data);
        } else {
          this.$emit('invalid');
        }
      },
      deep: true,
    },
    'modal.form.customTrackingToken': {
      immediate: true,
      async handler() {
        const newTokens = this.modal.form.tokens;
        Object.keys(this.modal.form.tokens).forEach((token) => {
          if (this.modal.form.tokens[token] && this.isInCustomToken(token)) {
            newTokens[token] = null;
          }
        });
        this.modal.form.tokens = newTokens;
        this.changeTrackingCode();
      },
    },
    'modal.form.subSelected': {
      immediate: true,
      async handler() {
        this.changeTrackingCode();
      },
    },
  },
  created() {
    this.$validator.extend('is_unique_view', {
      getMessage: (field) => `${field} already exist.`,
      validate: (value) => {
        const { form } = this.modal;
        if (form.index !== null && this.collection[form.index] && this.collection[form.index].viewId === value) return true;
        return !this.collection.find((item) => item.viewId === value);
      },
    });
  },
  methods: {
    changeTrackingCode() {
      const newStr = Object.keys(this.modal.form.tokens).filter((c) => !!this.modal.form.tokens[c]).map((tokenKey) => {
        const tokenVal = this.modal.form.tokens[tokenKey];
        const trToken = this.modal.options.tokens.find((c) => c.value === tokenVal)?.text;
        const tsToken = this.data.tokens[tokenKey].values[0];
        return `${trToken}=${tsToken}`;
      });
      // manage custom tracking token
      if (this.modal.form.customTrackingEnabled && this.modal.form.subSelected) {
        const trToken = this.modal.options.tokens.find((c) => c.value === this.modal.form.subSelected)?.text;
        newStr.push(`${trToken}=${this.modal.form.customTrackingToken}`);
      }
      this.modal.form.trackingCode = newStr.join('&');
    },
    async $_apply(event) {
      event.preventDefault();
      if (await this.$_validateForm()) {
        const data = {
          accountId: this.modal.form.account,
          propertyId: this.modal.form.property,
          viewId: this.modal.form.view,
          customTrackingEnabled: this.modal.form.customTrackingEnabled,
          subSelected: this.modal.form.subSelected,
          tokens: { ...this.modal.form.tokens },
        };
        if (this.modal.form.customTrackingEnabled && this.modal.form.subSelected) {
          data.customTrackingToken = this.modal.form.customTrackingToken;
        }
        if (this.modal.form.index !== null) {
          Object.assign(this.collection[this.modal.form.index], data);
        } else {
          this.collection.push(data);
        }
        this.$_hideModal();
      }
    },
    $_subOptions() {
      return this.modal.options.tokens.map((c) => ({ name: c.text, id: c.value }));
    },
    $_enableCustomTracking(value) {
      this.modal.form.customTrackingEnabled = value;
      for (const key in this.modal.form.tokens) {
        this.modal.form.tokens[key] = null;
      }
    },
    $_edit(item, index) {
      this.modal.form.account = item.accountId;
      this.modal.form.property = item.propertyId;
      this.modal.form.view = item.viewId;
      this.modal.form.tokens = { ...item.tokens };
      this.modal.form.customTrackingEnabled = item.customTrackingEnabled;
      this.modal.form.subSelected = item.subSelected;
      this.modal.form.customTrackingToken = item.customTrackingToken || Object.values(this.data.tokens).map((token) => token.values[0]).join('|');
      this.modal.form.index = index;
      this.modal.model = true;
    },
    $_remove(index) {
      if (this.collection.length > 0) {
        this.collection.splice(index, 1);
      }
    },
    checkIfInCustomToken(id) {
      if (this.modal.form.subSelected === id) {
        this.modal.form.subSelected = null;
      }
    },
    isInCustomToken(token) {
      const duplicateTokens = Object.keys(this.data.tokens).filter(
        (key) => this.modal.form.customTrackingToken.split('|').filter((c) => this.data.tokens[key].values.includes(c)).length,
      );
      return duplicateTokens.includes(token) && this.modal.form.customTrackingEnabled;
    },
    checkIfInTokens(id) {
      const prevIndx = Object.values(this.modal.form.tokens).findIndex((c) => c === id);

      if (prevIndx > -1) {
        const prevToken = Object.keys(this.modal.form.tokens)[prevIndx];
        const tokens = { ...this.modal.form.tokens, [prevToken]: null };
        this.modal.form.tokens = tokens;
      }
    },
    $_resetModal() {
      this.modal.form.account = null;
      this.modal.form.property = null;
      this.modal.form.view = null;
      this.modal.form.index = null;
      this.modal.form.subSelected = null;
      this.modal.form.customTrackingEnabled = false;
      for (const key in this.modal.form.tokens) {
        this.modal.form.tokens[key] = null;
      }
      this.$validator.reset(this.$_getScope());
    },
    validate() {
      return this.collection.length > 0;
    },
    async $_validateForm() {
      try {
        const validation = await this.$validator.validateAll(this.$_getScope());
        return !!validation;
      } catch (error) {
        return false;
      }
    },
    $_tokenSelect(value, key) {
      if (value !== null) {
        for (const key1 in this.modal.form.tokens) {
          if (key !== key1 && value === this.modal.form.tokens[key1]) this.modal.form.tokens[key1] = null;
        }
      }
      this.changeTrackingCode();
    },
    $_hideModal() {
      this.modal.model = false;
      this.$_resetModal();
    },
    $_getScope() {
      return scope;
    },
    $_hasError(name) {
      return this.$validator.errors.has(`${scope}.${name}`) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(`${scope}.${name}`);
    },
  },
};
</script>
<style lang="scss">
.account-config-google-analytics {
  .collections {
    .ga-view-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      color: #546582;
      border: 0.1rem solid #d6dfe3;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      padding: 0.7rem 1rem;
      transition: 0.25s ease-in-out;

      &:hover {
        background: #f7f7f7;
      }

      .title {
        font-size: 1.6rem;
      }

      .info-icon {
        color: rgba(84, 101, 130, 0.6);
      }

      .remove-icon {
        position: absolute;
        right: 1rem;
        background: #7fa1f8;
        color: $color-white;
        padding: 0.1rem 0.3rem 0.2rem 0.3rem;
        line-height: 0.55;
        border-radius: 50%;
        font-size: 1.4rem;

        &:hover {
          background: #4274f3;
          color: $color-white;
        }
      }
    }
  }

  .add-ga-view-btn {
    display: inline-flex;
    justify-content: center;
    padding: 0.7rem 1.5rem;
    margin: 0.5rem 2rem 2rem 2rem;
    font-size: 1.4rem;
    width: calc(100% - 4rem);
    background: #779dff;
    border-radius: 0.5rem;
    color: $color-white;
    border-color: #779dff;

    &:hover {
      background: #638efa;
      border-color: #638efa;
    }
  }
}

.add-ga-view-modal {
  .optimizer-form-group {
    margin-top: 1rem;

    .invalid-feedback {
      position: absolute !important;
    }
  }

  .tokens {
    &__title {
      width: 100%;
      background: #f0f5f7;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem;
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;
    }

    code {
      color: rgb(235, 110, 21);
    }
  }

  .custom-tracking-select {
    .dropdown-toggle {
      display: flex;

      .custom-tracking-tooltip {
        width: 100%;
        text-align: left;
      }
    }
  }

  .optimizer-select {
    .dropdown-toggle {
      color: $black-500;

      &:focus,
      &:active {
        color: $black-500;
      }
    }

    .acc-select {
      .acc-item-icon {
        display: inline;
      }

      .acc-item-name {
        display: inline;
        padding-left: 1rem;
      }
    }

    .select-dropdown-actions {
      padding-bottom: 0.5rem;
      border-bottom: 0.1rem solid $table-border-color;
    }

    .dropdown-menu .options-list {
      max-height: 26rem !important;
    }
  }

  .fieldsContainer {
    .fieldRow {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      b {
        width: 30%;
        margin-right: 1rem;
      }

      .fieldInput {
        width: 100%;
      }
    }
  }

  .info-footer {
    border-top: 0.1rem solid $color-light;
    color: $black-500;
  }

  #googleAnalyticsViews {
    .button-placehoder-tag {
      max-height: 10.5rem;
      overflow: auto;
      margin-right: 2.5rem;
    }

    .tag-remove svg {
      width: 1.2rem;
      height: 1rem;
    }
  }
}
</style>
