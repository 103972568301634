import TrackerViewTable from '@sh/components/misc/TrackerViewTable';
import fields from './fields';
import LiveEdit from '@sh/components/Utils/LiveEdit';

export default {
  components: { TrackerViewTable, LiveEdit },
  props: {
    account: { type: Object, required: true },
    tracker: { type: Object, required: true },
    performance: { type: Object, required: true },
  },
  methods: {
    $_getField(key, data = {}) {
      return fields.getField(this.tracker.type.uniqueName, key, data);
    },
    $_inlineEditArrayPress(refName, index, direction) {
      let nextIndex = direction === 'up' ? index - 1 : index + 1;
      while (this.$refs[`${refName}-${nextIndex}`]) {
        const component = Array.isArray(this.$refs[`${refName}-${nextIndex}`]) ? this.$refs[`${refName}-${nextIndex}`][0] : this.$refs[`${refName}-${nextIndex}`];
        if (component.getState() === 'READY') {
          component.focus();
          break;
        }
        nextIndex = direction === 'up' ? nextIndex - 1 : nextIndex + 1;
      }
    },
  },
};
