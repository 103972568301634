<template>
  <div :class="`cc-multi-select-wrapper component-${model.name}`">
    <b-form-group :state="$_hasError('multiSelect')">
      <b-input-group class="blocked-sites campaign-select-container col-md-12">
        <v-select
          name="multiSelect"
          ref="multiSelect"
          class="col-md-6 w-100"
          v-validate="model['v-validate']"
          v-model="selected"
          data-vv-validate-on="input|search:blur"
          :class="{ 'is-invalid': $_hasError('multiSelect') === false }"
          :data-vv-as="model.label"
          :label="$options.model.optionLabel"
          :max-height="$options.model.maxHeight"
          :placeholder="$options.model.placeholder"
          :close-on-select="false"
          :clear-search-on-select="false"
          :multiple="$options.model.multiple"
          :loading="loading"
          :disabled="$options.model.disabled || loading"
          :options="displayItems"
          :filterable="false"
          @search="$_onSearch"
          @input="$_input"
          @search:focus="$_onFocus"
          @search:blur="$_onBlur"
          v-b-popover.hover="model.componentPopover"
        >
          <template slot="option" slot-scope="option">
            <span v-html="$options.OptionLabel(option)['option']"></span>
          </template>
          <template slot="selected-option" slot-scope="option">
            <span v-html="$options.OptionLabel(option)['selected-option']"></span>
          </template>
        </v-select>
        <span class="or-text">OR</span>
        <b-btn class="block-btn" v-b-modal.selectedItemsModal><i class="fa fa-align-left" aria-hidden="true"></i> Upload List of Sites to Block</b-btn>
      </b-input-group>
      <small slot="invalid-feedback">{{ $_getError("multiSelect") }}</small>
    </b-form-group>

    <!-- Block Sites Modal -->
    <b-modal centered size="lg" id="selectedItemsModal" @ok.prevent="$_addSelectedItems" @hidden="$_hiddenSelectedItemsModal" @show="$_showSelectedItemsModal"
             modal-class="optimizer-modal" ok-title="Add Blocked Sites..." ref="selectedItemsModal"
    >
      <template slot="modal-header">
        <h2 class="modal-header__title">Paste a list of sites to block for this campaign</h2>
        <svg @click="$_hideSelectedItemsModal" class="modal-header__close-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L13 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
          <path d="M13 1L1 13" stroke="#546582" stroke-width="2" stroke-linecap="round" />
        </svg>
      </template>

      <b-form-group label-for="headlinesTextarea">
        <b-form-textarea :rows="10" :max-rows="10" class="optimizer-form-textarea" id="selectedItemsTextarea"
                         v-model="selectedItemsTextArea" placeholder="Paste a list of sites separated by comma. Exampe: msn-msn, msn-home, espnnetwork-espn, nbcnewsappios"
        />
      </b-form-group>

      <template slot="modal-footer">
        <b-btn class="secondary-button" @click="$_hideSelectedItemsModal">Cancel</b-btn>
        <b-btn class="primary-button" @click="$_addSelectedItems">Add</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import component from '../mixins/component';
import _ from 'lodash';

export default {
  name: 'CcBlockedSites',
  mixins: [component],
  // eslint-disable-next-line vue/require-prop-types
  props: ['value'],
  data() {
    return {
      selected: [],
      localOptions: [],
      filteredOptions: [],
      displayOptions: [],
      loading: false,
      selectedItemsTextArea: '',
    };
  },
  computed: {
    displayItems() {
      return this.displayOptions;
    },
  },
  created() {
    // Default Values
    this.$options.model = {
      placeholder: this.model.placeholder || 'Select...',
      optionLabel: this.model.optionLabel || 'name',
      disabled: this.model.disabled || false,
      maxHeight: this.model.maxHeight || '400px !important',
      multiple: true,
    };
    this.$options.OptionLabel = this.$_getOptionLabel;
    // Set Static OR Dynamic Options
    this.localOptions = this.model.options || [];
    this.filteredOptions = this.localOptions;
    if (this.model.getOptions && this.localOptions.length === 0) {
      this.loading = true;
      this.model.getOptions().then((res) => {
        if (res) {
          this.model.options = res;
          this.localOptions = this.model.options;
          this.filteredOptions = this.localOptions;
          this.model.trigger('ready');
        }
        this.loading = false;
      }).catch((err) => {
        console.error(err);
        this.loading = false;
      });
    }
    // Bind Model
    this.$watch('value', (value) => { this.selected = value; }, { immediate: true });
  },
  methods: {
    $_input(value) {
      if (Array.isArray(value)) {
        const resetItem = value.find((item) => item.reset);
        if (resetItem) this.selected = [resetItem];
      }
      this.$_emit('selected');
    },
    $_onSearch(search) {
      this.$_search(search, this);
    },
    $_search: _.debounce(async (search, vm) => {
      search = search.toLowerCase();
      if (search === '') {
        vm.filteredOptions = vm.localOptions;
      } else if (vm.model.getSearchOptions) {
        vm.filteredOptions = await vm.model.getSearchOptions(search);
      } else {
        vm.filteredOptions = vm.localOptions.filter((item) => item.name.toLowerCase().indexOf(search) > -1);
      }
      vm.$_dropdownRenderItems(true);
    }, 500),
    $_dropdownRenderItems(reset = false) {
      const ul = this.$refs.multiSelect.$el.querySelector('.vs__dropdown-menu');
      if (ul) {
        const scrollTop = ul.scrollTop || 0;
        if (reset) this.displayOptions = [];
        if (!this.displayOptions.length || ul.scrollHeight - 30 <= ul.clientHeight + scrollTop) {
          if (this.displayOptions.length < this.filteredOptions.length) {
            // Load items
            this.displayOptions.push(...this.filteredOptions.slice(this.displayOptions.length, this.displayOptions.length + 30));
            this.$nextTick(() => { ul.scrollTo(0, scrollTop); });
          }
        }
      }
    },
    $_onFocus() {
      this.$nextTick(() => {
        const ul = this.$refs.multiSelect.$el.querySelector('.vs__dropdown-menu');
        if (ul) {
          ul.onscroll = () => { this.$_dropdownRenderItems(); };
          this.$_dropdownRenderItems();
        }
      });
    },
    $_onBlur() {
      this.$refs.multiSelect.$data.search = '';
    },
    $_getOptionLabel(option) {
      return {
        option: option[this.$options.model.optionLabel],
        'selected-option': option[this.$options.model.optionLabel],
      };
    },
    $_addSelectedItems() {
      let items = this.selectedItemsTextArea.split(',');
      items = items.filter((item) => item.trim() !== '').map((item) => item.trim());
      this.selected = [...this.selected, ...items];
      this.$_input(this.selected);
      if (items.length) { this.$_hideSelectedItemsModal(); }
    },
    $_hiddenSelectedItemsModal() {
      this.selectedItemsTextArea = '';
    },
    $_showSelectedItemsModal() {
      this.$refs.selectedItemsModal.show();
    },
    $_hideSelectedItemsModal() {
      this.$refs.selectedItemsModal.hide();
    },
  },
};
</script>

<style lang="scss">
.cc-multi-select-wrapper .blocked-sites {
  display: flex;
  align-items: center;
  padding-left: 0;

  .vs__selected-options {
    min-height: 3.7rem;
    max-height: 15rem;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .block-btn{
    background: $color-white;
    border-radius: 0.6rem;
    border: 0.1rem solid $blue-300;
    padding: 1.1rem 2.5rem;
    color: $blue-300;
    font-size: 1.4rem;
  }

  .or-text{
    color: $text-primary;
    font-size: 1.7rem;
    padding: 0 15px;
  }
}
</style>
