import Task from '@sh/lib/Task.class';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      userAction: {
        task: new Task(),
        loading: false,
      },
    };
  },
  methods: {
    $_registerUserAction() {
      this.userAction.task.on('change:empty', () => {
        this.userAction.loading = false;
      });
      this.userAction.task.on('change:not-empty', () => {
        this.userAction.loading = true;
      });
    },
  },
});
