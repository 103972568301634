import contents from './contents';
import report from './report';

export default (F) => ({
  ...contents(F),
  ...report(F),
  headline: F.create({
    header: { content: 'Content Preview' },
    item: {
      key: 'headline',
      slot: 'content_preview',
      content: (item) => (item.headline.trim().length ? item.headline.trim() : item.traffic_source_content_id),
      sortable: true,
      total: {
        parse: () => 1,
        content: (totals) => (totals.headline ? `Total: ${totals.headline}` : '-'),
      },
    },
    colClass: 'sticky sticky--headline',
  }),
});
