<template>
  <div class="slack-settings-wrapper">
    <channel-ui
      :name="name"
      label="Slack"
      :channel="$c_channel"
      :columns="['name', 'team_name', 'channel', 'RE', 'AI', 'AN']"
      :update="$_update"
      :get-notification-field="config.notification.model.getTableHeaderField"
      @add="$_openAccountModal"
    />
    <slack-modal ref="account-modal" :config="config" @add="$_addAccount" />
    <channel-action-bar v-if="$c_hasChanges" :loading="loadingUpdateSettings" @update="$_updateSettings" />
  </div>
</template>

<script>
import notificationsMixins from './notificationMixins';
import SlackModal from '../modals/SlackModal';

export default {
  name: 'SlackSettings',
  components: { SlackModal },
  mixins: [notificationsMixins],
  data() {
    return {
      name: 'slack', // Channel identifier
    };
  },
  async created() {
    // Catch Url Callback new Slack account
    const { code } = this.$route.query;
    if (code) {
      // Replace Url
      const query = JSON.parse(JSON.stringify(this.$route.query));
      delete query.code;
      delete query.state;
      this.$router.replace({ query });
      // Get slack account
      const { account } = await this.$api.notification.activateSlack(code);
      if (account.error) {
        this.$n_failNotification({ title: account.error });
      } else {
        this.$nextTick(() => {
          this.$_openAccountModal(account);
        });
      }
    }
  },
};
</script>

<style lang="scss">
.slack-settings-wrapper {

}
</style>
