const colors = {
  native: {
    primary: '#20a8d8',
    loader: '1.1em solid #20a8d8',
  },
  mobile: {
    primary: '#e74c3c',
    loader: '1.1em solid #e74c3c',
  },
  adult: {
    primary: '#38bd6f',
    loader: '1.1em solid #38bd6f',
  },
};

const model = {
  images: {
    facebook: '/sh_static/logos/trafficsources/Facebook.svg',
    revcontent: '/sh_static/logos/trafficsources/RevContent.svg',
    contentad: '/sh_static/logos/trafficsources/ContentAd.svg',
    mgid: '/sh_static/logos/trafficsources/Mgid.svg',
    taboola: '/sh_static/logos/trafficsources/Taboola.svg',
    outbrain: '/sh_static/logos/trafficsources/Outbrain.svg',
    adskeeper: '/sh_static/logos/trafficsources/Adskeeper.svg',
    gemini: '/sh_static/logos/trafficsources/Gemini.svg',
    voluumdsp: '/sh_static/logos/trafficsources/VoluumDSP.svg',
    runative: '/sh_static/logos/trafficsources/Runative.svg',
    engageya: '/sh_static/logos/trafficsources/Engageya.svg',
    activerevenue: '/sh_static/logos/trafficsources/ActiveRevenue.svg',
    plugrush: '/sh_static/logos/trafficsources/PlugRush.svg',
    popcash: '/sh_static/logos/trafficsources/PopCash.svg',
    propeller: '/sh_static/logos/trafficsources/Propeller.svg',
    kadam: '/sh_static/logos/trafficsources/Kadam.png',
    bidvertiser: '/sh_static/logos/trafficsources/BidVertiser.png',
    popads: '/sh_static/logos/trafficsources/PopAds.svg',
    zeropark: '/sh_static/logos/trafficsources/ZeroPark.png',
    exoclick: '/sh_static/logos/trafficsources/ExoClick.svg',
    clickadu: '/sh_static/logos/trafficsources/ClickAdu.svg',
    pushground: '/sh_static/logos/trafficsources/Pushground.png',
    hilltopads: '/sh_static/logos/trafficsources/HillTopAds.svg',
    rtxpops: '/sh_static/logos/trafficsources/RtxPops.svg',
    richpush: '/sh_static/logos/trafficsources/RichPush.svg',
    megapush: '/sh_static/logos/trafficsources/MegaPush.svg',
    activerevenuemobile: '/sh_static/logos/trafficsources/ActiveRevenueMobile.svg',
    evadav: '/sh_static/logos/trafficsources/Evadav.svg',
    exoclickadult: '/sh_static/logos/trafficsources/ExoClickAdult.svg',
    trafficforce: '/sh_static/logos/trafficsources/TrafficForce.svg',
    trafficjunky: '/sh_static/logos/trafficsources/TrafficJunky.svg',
    trafficstars: '/sh_static/logos/trafficsources/TrafficStars.svg',
    voluum: '/sh_static/logos/trackers/Voluum.svg',
    thrive: '/sh_static/logos/trackers/Thrive.svg',
    imobitrax: '/sh_static/logos/trackers/iMobiTrax.svg',
    adsbridge: '/sh_static/logos/trackers/Adsbridge.png',
    binom: '/sh_static/logos/trackers/Binom.svg',
    binomv2: '/sh_static/logos/trackers/Binom.svg',
    funnelflux: '/sh_static/logos/trackers/FunnelFlux.svg',
    funnelfluxpro: '/sh_static/logos/trackers/FunnelFluxPro.svg',
    bemob: '/sh_static/logos/trackers/Bemob.svg',
    googleanalytics: '/sh_static/logos/trackers/GoogleAnalytics.svg',
    googleanalytics4: '/sh_static/logos/trackers/GoogleAnalytics4.svg',
    redtrack: '/sh_static/logos/trackers/RedTrack.svg',
    kintura: '/sh_static/logos/trackers/Kintura.svg',
    adsterra: '/sh_static/logos/trafficsources/Adsterra.svg',
    everflow: '/sh_static/logos/trackers/Everflow.svg',
    tonic: '/sh_static/logos/trackers/Tonic.svg',
    sedo: '/sh_static/logos/trackers/Sedo.png',
    system1: '/sh_static/logos/trackers/System1.png?v=2',
    domainactive: '/sh_static/logos/trackers/DomainActive.png',
    cloudthrive: '/sh_static/logos/trackers/Thrive.svg',
    ezmob: '/sh_static/logos/trafficsources/EzMob.png',
    admaven: '/sh_static/logos/trafficsources/AdMaven.png',
    customtracker: '/sh_static/logos/trackers/CustomTracker.png',
    assertiveyield: '/sh_static/logos/trackers/AssertiveYield.png',
    amazondisplay: '/sh_static/logos/trafficsources/AmazonDisplay.svg',
    amazondsp: '/sh_static/logos/trafficsources/AmazonDSP.svg',
    cpvlab: '/sh_static/logos/trackers/CPVLab.jpg',
    mediago: '/sh_static/logos/trafficsources/MediaGo.png',
    tiktok: '/sh_static/logos/trafficsources/TikTok.svg',
    googleads: '/sh_static/logos/trafficsources/googleAds.png',
    clickflare: '/sh_static/logos/trackers/ClickFlare.svg',
    yahoodsp: '/sh_static/logos/trafficsources/YahooDSP.jpg',
    bodis: '/sh_static/logos/trackers/Bodis.png',
    ads: '/sh_static/logos/trackers/Ads.svg',
    media: '/sh_static/logos/trackers/Media.svg',
    csvsource: '/sh_static/logos/trackers/CSVSource.svg',
  },
  color: colors[process.env.VUE_APP_PLATFORM],
};

module.exports = { model };
