import AdAccountFilter from '@/views/Tools/Reporting/core/filters/AdAccountFilter';
import BreakDownFilter from '@/views/Tools/Reporting/core/filters/BreakDownFilter';
import CampaignFilter from '@/views/Tools/Reporting/core/filters/CampaignFilter';
import DateRangeFilter from '@/views/Tools/Reporting/core/filters/DateRangeFilter';
import ReportTypeFilter from '@/views/Tools/Reporting/core/filters/ReportTypeFilter';
import TrafficSourceComplianceFilter from '@/views/Tools/Reporting/core/filters/TrafficSourceComplianceFilter';
import TrafficSourceFilter from '@/views/Tools/Reporting/core/filters/TrafficSourceFilter';
import UserFilter from '@/views/Tools/Reporting/core/filters/UserFilter';
import { ReportingRequest } from '@/views/Tools/Reporting/types';
import { FilterOptions, Filters, FilterType, State } from '@/views/Tools/Reporting/types/Filter';

export function getFilter(filterType: FilterType, state: Partial<State>): Filters[FilterType] {
  switch (filterType) {
    case FilterType.AdAccount: {
      return new AdAccountFilter(
        state.options?.[filterType],
        state.selected?.[FilterType.TrafficSource],
        state.reportType,
        state.selected?.[filterType]
      );
    }
    case FilterType.TrafficSource: {
      return new TrafficSourceFilter(state.options?.[filterType], state.selected?.[filterType]);
    }
    case FilterType.ReportType: {
      return new ReportTypeFilter(
        state.options?.[filterType],
        state.selected?.[FilterType.TrafficSource],
        state.selected?.[filterType]
      );
    }
    case FilterType.DateRange: {
      return new DateRangeFilter(state.selected?.[filterType]);
    }
    case FilterType.BreakDown: {
      return new BreakDownFilter(state.options?.[filterType], state.selected?.[filterType]);
    }
    case FilterType.Campaign: {
      return new CampaignFilter(
        state.options?.[filterType] ?? {},
        state.selected?.[FilterType.TrafficSource],
        state.selected?.[FilterType.AdAccount],
        state.selected?.[filterType]
      );
    }
    case FilterType.User: {
      return new UserFilter(state.options?.[filterType] ?? [], state.selected?.[filterType]);
    }
    case FilterType.TrafficSourceComplianceFilter: {
      return new TrafficSourceComplianceFilter(
        state.options?.[filterType] ?? {},
        state.selected?.[filterType],
        state.userData
      );
    }
    default: {
      throw new Error('This filter type does not exist.');
    }
  }
}

export function getFiltersFromQueryParams(
  data: ReportingRequest,
  options: FilterOptions,
  availableFilters: FilterType[]
): Partial<Filters> {
  const filters: Partial<Filters> = {};

  const state: State = {
    options,
    selected: {
      [FilterType.TrafficSource]: data.typeName ?? undefined,
      [FilterType.AdAccount]: data.trafficSourceAccountIds,
      [FilterType.User]: data.clientEmails?.[0],
      [FilterType.TrafficSourceComplianceFilter]: data.trafficSourceTypes,
    },
    reportType: data.reportType,
    userData: {
      id: data.selectedUserId?.toString() ?? '',
      email: data.clientEmails?.[0] ?? '',
    },
  };

  if (data.startDate && data.endDate) {
    filters[FilterType.DateRange] = new DateRangeFilter({
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }

  if (data.dateType) {
    filters[FilterType.BreakDown] = new BreakDownFilter(options[FilterType.BreakDown], data.dateType);
  }

  if (data.typeName) {
    filters[FilterType.TrafficSource] = new TrafficSourceFilter(options[FilterType.TrafficSource], data.typeName);
  }

  filters[FilterType.ReportType] = new ReportTypeFilter(
    options[FilterType.ReportType],
    state.selected[FilterType.TrafficSource],
    state.reportType
  );

  filters[FilterType.AdAccount] = new AdAccountFilter(
    options[FilterType.AdAccount],
    state.selected[FilterType.TrafficSource],
    state.reportType,
    state.selected[FilterType.AdAccount]
  );

  filters[FilterType.User] = new UserFilter(options[FilterType.User] ?? [], state.selected?.[FilterType.User]);

  filters[FilterType.TrafficSourceComplianceFilter] = new TrafficSourceComplianceFilter(
    options?.[FilterType.TrafficSourceComplianceFilter] ?? {},
    state.selected?.[FilterType.TrafficSourceComplianceFilter],
    state.userData
  );

  if (data.campaignIds) {
    filters[FilterType.Campaign] = new CampaignFilter(
      options[FilterType.Campaign],
      state.selected[FilterType.TrafficSource],
      state.selected[FilterType.AdAccount],
      data.campaignIds
    );
  }

  return availableFilters.reduce((newFilters: Partial<Filters>, filterType) => {
    if (filters[filterType]) {
      // @ts-ignore
      newFilters[filterType] = filters[filterType] as Filters[FilterType];
    } else {
      // @ts-ignore
      newFilters[filterType] = getFilter(filterType, state, {});
    }
    return newFilters;
  }, {});
}
