<template>
  <div class="rule-min-max-wrapper">
    <div :class="[view, 'info-helper']">
      <!-- Min Bid Budget or Coefficient -->
      <b-form-group :state="$_hasError('minBidBudget')" class="col-lg-6 col-lg-auto limit-input-wrapper">
        <b-input-group>
          <b-input-group-addon class="state-addon">
            <!-- <b-form-checkbox v-model="form.min.status" @change="form.min.value = null; removeError('minBidBudget')"></b-form-checkbox>  -->
            <span class="label-text d-none d-sm-block" v-if="view === 'row'"> Do not allow the {{ name }} to be lower than <i v-if="minInfo" class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="minInfo"></i></span>
            <span class="label-text d-none d-sm-block" v-else> Min {{ name }} Cap <i v-if="minInfo" class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="minInfo"></i></span>
            <span class="label-text d-block d-sm-none">Min {{ name }} <i class="fa fa-info-circle info-icon" v-b-tooltip.html.hover :title="`Do not allow the ${name} to be lower than value. ${minInfo}`"></i></span>
          </b-input-group-addon>
          <b-form-input
            class="optimizer-form-input"
            type="number"
            name="minBidBudget"
            :min="limits.minLimit || '0'"
            :step="limits.step"
            :value="form.minObject.value ? form.minObject.value : form.min"
            @input="$_handleMin"
            :data-vv-as="'Min '+ name"
            v-validate="['required', ...$_vValidate('min')].join('|')"
          />
          <b-input-group-addon>
            <b v-if="unit === 'change_coefficient' " style="display: inline-block;">C</b>
            <!-- <i v-else-if="unit === 'change_bid_gemini'" class="fa fa-percent"></i> -->
            <b v-else-if="unit === 'change_bid_gemini'">%</b>
            <i v-if="form.name === 'Target Roas'" class="fa fa-percent"></i>
            <i v-else-if="!options.length" class="fa fa-dollar"></i>
            <vue-opti-select-light v-else
                                   class="optimizer-select dropdown-auto-width set-bid-type"
                                   :value="form.minObject.unit"
                                   @change="(option) => $_handleUnitChange(option, 'minObject')"
                                   :options="options"
                                   :button-placeholder="'$'"
                                   label-key="text"
                                   single
                                   button-block
            />
          </b-input-group-addon>
        </b-input-group>
        <small slot="invalid-feedback">{{ $_getError('minBidBudget') }}</small>
      </b-form-group>

      <!-- Percentage Options   -->
      <b-form-group v-if="form.minObject.unit === 'percentage'" :state="getErrorState('toOptionsMin')" :class="[{ spacing: ( view === 'column' ) }, 'col-lg-6', 'col-lg-auto', 'rule-bid-to-option-wrapper']">
        <b-input-group>
          <RuleDateConditionField
            ref="validate-min-percentage-value"
            validateType="Option"
            validatePath="$c_model"
            emptyButtonPlaceholder="-- Select Option --"
            :value="$c_minValue"
            :name="'toOptionsMin'"
            :options="$c_dropdownOptions"
            watchValue
            preventOverflowRight
            @onChange="onFieldChange"
            @onErrorUpdate="onErrorUpdate"
          />
          <b-input-group-append v-if="toOptions.length && $c_minTooltip">
            <i class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="$c_minTooltip"></i>
          </b-input-group-append>
        </b-input-group>
        <small slot="invalid-feedback">{{ $_getError('toOptions') }}</small>
      </b-form-group>
    </div>
    <div class="rule-min-max-wrapper">
      <div :class="[view, 'info-helper']">
        <!-- Max Bid Budget or Coefficient -->
        <b-form-group :state="$_hasError('maxBidBudget')" class="col-lg-6 col-lg-auto limit-input-wrapper">
          <b-input-group>
            <b-input-group-addon class="state-addon">
              <!-- <b-form-checkbox v-model="form.max.status" @change="form.max.value = null; removeError('maxBidBudget')"></b-form-checkbox>  -->
              <span class="label-text d-none d-sm-block" v-if="view === 'row'">Do not allow the {{ name }} to be higher than <i v-if="maxInfo" class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="maxInfo"></i></span>
              <span class="label-text d-none d-sm-block" v-else> Max {{ name }} Cap <i v-if="maxInfo" class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="maxInfo"></i></span>
              <span class="label-text d-block d-sm-none">Max {{ name }} <i class="fa fa-info-circle info-icon" v-b-tooltip.html.hover :title="`Do not allow the ${name} to be higher than value. ${maxInfo}`"></i></span>
            </b-input-group-addon>
            <b-form-input
              class="optimizer-form-input"
              type="number"
              name="maxBidBudget"
              :min="limits.minLimit || '0'"
              :step="limits.step"
              :value="form.maxObject.value ? form.maxObject.value : form.max"
              @input="$_handleMax"
              :data-vv-as="'Max '+ name"
              v-validate="['required', ...$_vValidate('max')].join('|')"
            />
            <b-input-group-addon>
              <b v-if="unit === 'change_coefficient' " style="display: inline-block;">C</b>
              <!-- <i v-else-if="unit === 'change_bid_gemini'" class="fa fa-percent"></i> -->
              <b v-else-if="unit === 'change_bid_gemini'">%</b>
              <i v-if="form.name === 'Target Roas'" class="fa fa-percent"></i>
              <i v-else-if="!options.length" class="fa fa-dollar"></i>
              <vue-opti-select-light v-else
                                     class="optimizer-select dropdown-auto-width set-bid-type"
                                     :value="form.maxObject.unit"
                                     @change="(option) => $_handleUnitChange(option, 'maxObject')"
                                     :options="options"
                                     :button-placeholder="'$'"
                                     label-key="text"
                                     single
                                     button-block
              />
            </b-input-group-addon>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('maxBidBudget') }}</small>
        </b-form-group>

        <!-- Percentage Options   -->
        <b-form-group v-if="form.maxObject.unit === 'percentage'" :state="getErrorState('toOptionsMax')" :class="[{ spacing: ( view === 'column' ) }, 'col-lg-6', 'col-lg-auto', 'rule-bid-to-option-wrapper']">
          <b-input-group>
            <RuleDateConditionField
              ref="validate-max-percentage-value"
              validateType="Option"
              validatePath="$c_model"
              emptyButtonPlaceholder="-- Select Option --"
              :value="$c_maxValue"
              :name="'toOptionsMax'"
              :options="$c_dropdownOptions"
              watchValue
              preventOverflowRight
              @onChange="onFieldChange($event, 'maxObject')"
              @onErrorUpdate="onErrorUpdate"
            />
            <b-input-group-append v-if="toOptions.length && $c_maxTooltip">
              <i class="fa fa-info-circle" style="cursor: pointer" v-b-popover.html.hover="$c_maxTooltip"></i>
            </b-input-group-append>
          </b-input-group>
          <small slot="invalid-feedback">{{ $_getError('toOptions') }}</small>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import RuleDateConditionField from '@sh/views/Rules/components/subcomponents/RuleDateConditionField.ts.vue';
import { RuleHelpers, isDefined } from '@sh/helpers';

export default {
  name: 'RuleMinMax',
  components: { RuleDateConditionField },
  props: {
    name: { type: String, default: '' },
    min: {
      type: [String, Number],
      default: '',
    },
    max: {
      type: [String, Number],
      default: '',
    },
    minObject: { type: Object, default: () => ({ unit: 'static', to: '', value: '' }) },
    maxObject: { type: Object, default: () => ({ unit: 'static', to: '', value: '' }) },
    minInfo: { type: String, default: 'If the new calculated CPC will be lower than this limit, then the CPC will be set to this value' },
    maxInfo: { type: String, default: 'If the new calculated CPC will be higher than this limit, then the CPC will be set to this value' },
    unit: { type: String, default: '' },
    limits: { type: Object, default: () => ({ step: '0.001', min: 0, max: 0.05 }) },
    options: { type: Array, default: () => [] },
    toOptions: { type: Array, default: () => [] },
    toViewOptions: { type: Array, default: () => [] },
    template: { type: Object, default: () => {} },
    view: { type: String, default: 'row' },
  },
  data() {
    return {
      form: {
        name: '',
        min: '',
        max: '',
        minObject: {
          unit: 'static',
          to: '',
          value: '',
          toField: undefined,
        },
        maxObject: {
          unit: 'static',
          to: '',
          value: '',
          toField: undefined,
        },
        unit: '',
      },
      errors: { },
    };
  },
  computed: {
    $c_minValue() {
      const option = this.toOptions.find((item) => item.value === this.form?.minObject?.to);

      if (option) {
        option.interval = option?.interval ?? this.form.minObject?.toField?.interval;
      }

      return option;
    },
    $c_maxValue() {
      const option = this.toOptions.find((item) => item.value === this.form?.maxObject?.to);

      if (option) {
        option.interval = option?.interval ?? this.form.maxObject?.toField?.interval;
      }

      return option;
    },
    $c_minTooltip() {
      return this.$_toOptionsTooltip(this.form.minObject.to);
    },
    $c_maxTooltip() {
      return this.$_toOptionsTooltip(this.form.maxObject.to);
    },
    $c_dropdownOptions() {
      if (this.toViewOptions.length > 0) {
        return this.toViewOptions;
      }
      return this.toOptions;
    },
  },
  watch: {
    'form.min': {
      // immediate: true,
      handler() {
        this.$_emit('min');
      },
      deep: true,
    },
    'form.max': {
      // immediate: true,
      handler() {
        this.$_emit('max');
      },
      deep: true,
    },
    'form.minObject': {
      handler() {
        this.$_emit('minObject');
      },
      deep: true,
    },
    'form.maxObject': {
      handler() {
        this.$_emit('maxObject');
      },
      deep: true,
    },
    min(value) {
      this.$_handleMin(value);
    },
    max(value) {
      this.$_handleMax(value);
    },
  },
  created() {
    this.form.name = this.name;
    this.form.min = this.min;
    this.form.max = this.max;
    this.form.minObject = this.minObject;
    this.form.maxObject = this.maxObject;
    this.form.unit = this.unit;
  },
  methods: {
    async validate() {
      try {
        const forms = [this.$validator.validateAll(), this.validateComponents()];
        const validationForms = await Promise.all(forms);
        const valid = validationForms.reduce((status, formStatus) => status && formStatus, true);
        return valid;
      } catch (error) {
        return false;
      }
    },
    validateComponents() {
      return Object.keys(this.$refs).reduce((isValid, ref) => {
        if (ref.includes('validate')) {
          const isRefValid = this.$refs[ref]?.validate() ?? true;
          isValid = isValid && isRefValid;
        }
        return isValid;
      }, true);
    },
    $_vValidate(prop) {
      try {
        return this.limits['v-validate'][prop];
      } catch (error) {
        // Do nothing
        return [];
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
    $_emit(name) {
      let val = this.form[name];
      if (val && ['min', 'max'].includes(name)) val = `${parseFloat(val)}`;
      if (val?.value && ['minObject', 'maxObject'].includes(name)) val.value = `${parseFloat(val.value)}`;
      this.$emit(name, val);
    },
    $_toOptionsTooltip(to) {
      try {
        return this.template.action.set.unit.percentage.to[to]?.info;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    $_handleMin(input) {
      const value = parseFloat(input) || input;
      this.form.minObject.value = value;
      this.form.min = value;

      if (isDefined(this.form.minObject.toField?.percentage)) {
        this.form.minObject.toField.percentage = { value };
      }
    },
    $_handleMax(input) {
      const value = parseFloat(input) || input;
      this.form.maxObject.value = value;
      this.form.max = value;

      if (isDefined(this.form.maxObject.toField?.percentage)) {
        this.form.maxObject.toField.percentage = { value };
      }
    },
    $_handleUnitChange(option, key) {
      this.form[key] = {
        ...this.form[key],
        unit: option.value,
        ...(option.value === 'static' && { to: '', toField: undefined }),
      };
    },
    getErrorState(name) {
      return this.$_hasError(name) ?? (this.errors[name] ? false : null);
    },
    onFieldChange(option, key = 'minObject') {
      this.form = {
        ...this.form,
        [key]: {
          ...this.form[key],
          to: option.value,
          toField: RuleHelpers.ConditionsV2.getField(RuleHelpers.ConditionsV2.getBidOption(option, this.form[key])),
        },
      };
    },
    onErrorUpdate(item) {
      this.errors = {
        ...this.errors,
        ...item,
      };
    },
  },
};
</script>

<style lang="scss">
.rule-min-max-wrapper {
  margin-top: 1.5rem;

  > .row {
    gap: 1rem 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .optimizer-form-input {
    height: 4rem;
  }

  .optimizer-select.set-bid-type {
    .dropdown-toggle {
      background: #F4F9FC;
      height: 4rem;

      &::after {
        margin-left: 1rem;
      }
    }

    .dropdown-toggle {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }

    .dropdown-toggle:active {
      background: #f4f9fc00;
    }

    .dropdown-toggle:focus {
      background: #f4f9fc00;
    }
  }

  .optimizer-select.dropdown-auto-width .dropdown-menu {
    width: auto !important;
    min-width: 8rem;
  }

  .info-helper {
    .input-group {
      flex-wrap: inherit;
    }
  }

  .input-group-append {
    color: $black-400;
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }
  .limit-input-wrapper {
    margin-bottom: 0;
    .state-addon,
    .input-group-prepend {
      height: 4rem;
      background: #F4F9FC;
      border: .1rem solid $color-light-gray;
      border-radius: .6rem;
      display: flex;
      align-items: center;
      color: $black-600;
      padding: 0.7rem 1.2rem;
      font-size: 1.4rem;
    }

    .state-addon {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-prepend:not(.state-addon) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;

      &:has(.optimizer-select) {
        padding: 0;
        border: none;
      }
    }
  }

  .least-one-checkbox-message {
    position: relative;
    top: -17px;
  }

  .spacing {
    margin-top: 1rem;
  }
  // .limit-input-wrapper {
  //   .state-addon {
  //     width: 320px;
  //     @media only screen and (max-width : 575px) {
  //       width: 150px;
  //     }
  //   }
  //   input.form-control {
  //     text-align: center;
  //   }
  //   .input-group-addon {
  //     &:last-child {
  //       text-align: center;
  //       display: inline-block;
  //     }
  //   }
  // }
}
</style>
