<template>
  <div class="contents-form-container">
    <div class="contents-form-container__title">
      <OptimizerIcon type="contentForm" />
      <h5>{{ mode === 'edit' ? 'Edit' : 'Upload New' }} Contents</h5>
    </div>

    <loadizer :loading="!init" message="Your contents is loading..." />
    <content-uploader-form
      v-if="model && init"
      :traffic-source-type="$c_trafficSourceType"
      :account="$c_account"
      :campaign="$c_campaignData"
      :cmp="payload.campaing"
      :adgroups="adgroups"
      v-model="uploaderModel"
      :default-contents="defaultContents"
      :mode="mode"
    />
  </div>
</template>

<script>
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import ImageHelper from '@sh/components/Utils/ImageEditor/ImageHelper';
import props from '../../mixins/props';
import contentUploaderForm from './Uploader/Index.vue';
import md5 from 'md5';

export default {
  name: 'ContentsForm',
  components: {
    contentUploaderForm, OptimizerIcon,
  },
  mixins: [props],
  props: {
    model: { type: [Object, null], default: null },
    payload: { type: Object },
    campaign: { type: Object },
  },
  data() {
    return {
      uploaderModel: null,
      adgroups: null,
      defaultContents: null,
      init: false,
    };
  },
  computed: {
    $c_trafficSourceType() {
      return { uniqueName: this.config.Account.value.type.uniqueName, name: this.config.Account.value.type.name };
    },
    $c_account() {
      return { id: this.config.Account.value.id, name: this.config.Account.value.name };
    },
    $c_campaignData() {
      const type = this.campaign.campaignType && this.campaign.campaignType.value ? this.campaign.campaignType.value : null;
      let disableCpc = false;
      try {
        if (this.campaign.traffic_source_unique_name === 'Outbrain' && ['Conversions', 'AppInstalls'].includes(this.campaign.objective)) {
          disableCpc = true;
        }
      } catch (err) {
        // Do nothing
      }
      return { name: this.campaign.name || null, type, disableCpc };
    },
  },
  async created() {
    this.init = false;
    if (this.payload.contents) {
      this.defaultContents = await this.$_getContentsPayload(this.payload.contents);
    }
    this.$watch('uploaderModel', (model) => {
      this.$emit('input', model);
    }, { deep: true, immediate: true });
    this.init = true;
  },
  methods: {
    async validate() {
      if (this.mode === 'edit') return true;
      if (this.uploaderModel === null) {
        const swal = await this.$swal({
          title: `${this.mode.toUpperCase()} campaign without contents ?`,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Continue Anyway',
          cancelButtonText: 'No',
        });
        return swal.value;
      }
      return true;
    },
    async $_getContentsPayload(_contents) {
      const contents = _contents.filter((content) => content.imageUrl !== '');
      const payload = {
        contents: [],
        images: [],
        targetUrl: '',
      };
      let imagesMapUrl;
      if (contents.length) {
        payload.targetUrl = contents[0].targetUrl;
        if (contents[0] && contents[0].imageUrl !== null) {
          const imagesBase64 = await this.$api.uploader.imageProxy(contents.map((item) => item.imageUrl));
          imagesMapUrl = {}; // tmp
          for (const url in imagesBase64) {
            const mimeType = ImageHelper.getMimeType(imagesBase64[url]);
            const image = {
              original: {
                base64: `data:${mimeType};base64,${imagesBase64[url]}`,
                mimeType,
              },
            };
            image.original.md5 = md5(image.original.base64);
            payload.images.push(image);
            imagesMapUrl[url] = image.original.md5;
          }
        }
        contents.forEach((item) => {
          const contentPayload = {
            headline: item.headline,
            imageMd5: imagesMapUrl[item.imageUrl],
            targetUrl: item.targetUrl,
          };
          if (item.cpc) contentPayload.cpc = item.cpc.toString();
          if (item.category) contentPayload.category = item.category;
          if (item.brandName) contentPayload.brandName = item.brandName;
          if (item.contentType) contentPayload.contentType = item.contentType;
          if (item.description) contentPayload.description = item.description;
          if (item.sponsoredBy) contentPayload.sponsoredBy = item.sponsoredBy;
          if (item.displayUrl) contentPayload.displayUrl = item.displayUrl;
          if (item.siteName) contentPayload.sectionName = item.siteName;
          if (item.id) Object.assign(contentPayload, { id: item.id });
          if (item.cta) contentPayload.cta = item.cta;
          payload.contents.push(contentPayload);
        });
      }
      return payload;
    },
  },
};
</script>

<style lang="scss">
  .contents-form-container {
    &__title {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;

      h5 {
        font-size: 2.2rem;
        font-weight: bold;
        margin-left: 1.5rem;
      }
    }
  }
</style>
