import uid from 'uid';

const abortControllers: Record<string, AbortController> = {};

export default {
  create(id?: string) {
    const uuid = id ?? uid(32);
    abortControllers[uuid] = new AbortController();
    return abortControllers[uuid].signal;
  },
  abort(id: string) {
    abortControllers[id]?.abort();
  },
  isAbortError(error: any) {
    return error?.message === 'canceled';
  },
};
