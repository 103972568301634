import userAction from '@sh/mixins/userAction';
import notifications from '@sh/mixins/notifications';

export default {
  mixins: [notifications, userAction],
  data() {
    return {
      rules: {
        campaignsMap: {},
      },
      maxCpcLimit: 0.5,
    };
  },
  methods: {
    async $_updateStatus(item, value) {
      try {
        const result = await this.$api.campaigns.changeStatus(item.id, value);
        item.enabled = result.enabled;
        item.status = result.status;
        item.traffic_source_status = result.traffic_source_status;
        this.$n_successNotification({ title: result.message || 'Status successfully updated' });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    async $_updateBid(id, value) {
      const task = this.userAction.task.create();
      try {
        await this.$api.campaigns.changeBid(id, value);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateTargetCpa(id, value, ts) {
      const task = this.userAction.task.create();
      try {
        let message = '';
        if (ts === 'GoogleAds') {
          message = 'Target ROAS successfully updated';
        } else {
          message = 'Target CPA successfully updated';
        }
        await this.$api.campaigns.changeTargetCpa(id, value);
        this.$n_successNotification({ title: message });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBidCap(id, value) {
      const task = this.userAction.task.create();
      try {
        await this.$api.campaigns.changeBidCap(id, value);
        this.$n_successNotification({ title: 'Bid Cap successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBidCad(id, payload) {
      const task = this.userAction.task.create();
      try {
        await this.$api.campaigns.changeBidCad(id, payload);
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBudget(item, value) {
      const task = this.userAction.task.create();
      try {
        const response = await this.$api.campaigns.changeBudget(item.id, value, item.traffic_source_unique_name === 'Facebook' ? 'Lifetime Budget' : item.additional_fields.budget_type);
        this.$n_successNotification({ title: response.message || 'Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateFacebookBudgetStrategy({ campaignId, payload }) {
      const task = this.userAction.task.create();
      try {
        const response = await this.$api.campaigns.changeFacebookBudgetStrategy(campaignId, payload);
        this.$n_successNotification({ title: response.message || 'Daily Budget successfully updated' });
        task.finish();
      } catch (error) {
        console.log(error);
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    async $_updateDailyBudget(item, value) {
      const task = this.userAction.task.create();
      try {
        const response = await this.$api.campaigns.changeDailyBudget(item.id, value);
        this.$n_successNotification({ title: response.message || 'Daily Budget successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBidGemini({ campaign, bids }) {
      const task = this.userAction.task.create();
      try {
        const payload = campaign.additional_fields.bids.map((item) => ({ ...item, ...bids[item.value] }));
        const { bids: newBids } = await this.$api.campaigns.changeBidGemini(campaign.id, payload);
        campaign.additional_fields.bids = newBids;
        this.$n_successNotification({ title: 'Bid successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        console.log(error);
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updateBidGoogle({ campaign, bids }) {
      const task = this.userAction.task.create();
      try {
        const payload = campaign.additional_fields.bids.map((item) => ({ ...item, ...bids[item.value] }));
        const { bids: newBids } = await this.$api.campaigns.changeBidAdjGoogleAds(campaign.id, payload);
        campaign.additional_fields.bids = newBids.bids;
        this.$n_successNotification({ title: 'Bids adj successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        console.log(error);
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_updatePayout(id, value) {
      const task = this.userAction.task.create();
      try {
        await this.$api.campaigns.changePayout(id, value);
        this.$n_successNotification({ title: 'Payout successfully updated' });
        task.finish();
        return true;
      } catch (error) {
        console.log(error);
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
      task.finish();
      return false;
    },
    async $_apiRules() {
      try {
        const result = await this.$api.rules.index();
        result.forEach((rule) => {
          rule.campaign_ids.forEach((id) => {
            if (!this.rules.campaignsMap[id]) this.rules.campaignsMap[id] = [];
            this.rules.campaignsMap[id].push({
              rule_action: rule.rule_action,
              name: rule.name,
              _id: rule._id,
            });
          });
        });
      } catch (error) {
        try {
          this.$n_failNotification({ title: error.response.data.message || 'An error occurred' });
        } catch (err) {
          this.$n_failNotification({ title: 'An error occurred' });
        }
      }
    },
    // async $_cloneCampaign_old(campaign) {
    //   const to = { name: 'CampaignCreator', query: { account: campaign.traffic_source_account_id, id: campaign.traffic_source_campaign_id, mode: 'clone' } };
    //   const rules = this.rules.campaignsMap[campaign.id] ? this.rules.campaignsMap[campaign.id].map((item) => item._id) : [];

    //   if (rules.length) {
    //     const swal = await this.$swal({
    //       title: 'This campaign has active rules. Do you want to clone with rules?',
    //       icon: 'info',
    //       // allowOutsideClick: false,
    //       showCancelButton: true,
    //       confirmButtonText: 'Yes',
    //       cancelButtonText: 'No',
    //     });
    //     if (swal.value) {
    //       to.query.rules = rules;
    //       this.$router.push(to);
    //     } else if (swal.dismiss === 'cancel') {
    //       this.$router.push(to);
    //     }
    //   } else {
    //     this.$router.push(to);
    //   }
    // },
    async $_cloneCampaign(campaign) {
      const to = { name: 'CampaignCreator', query: { account: campaign.traffic_source_account_id, id: campaign.traffic_source_campaign_id, mode: 'clone' } };
      const rules = this.rules.campaignsMap[campaign.id] ? this.rules.campaignsMap[campaign.id].map((item) => item._id) : [];
      const enableCloneMode = ['Outbrain'].includes(campaign.traffic_source_unique_name);

      if (rules.length || enableCloneMode) {
        const htmlEnableRulesCheckbox = `
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" name="cloneWithRules" id="cloneWithRules">
          <label class="form-check-label" for="cloneWithRules">
            Clone with rules
          </label>
        </div>
        `;

        const htmlEnableCloneMode = `
        <div class="d-flex justify-content-center">
          <div class="form-check mr-4">
            <input class="form-check-input" type="radio" name="cloneMode" id="freshClone" value="FRESH_CLONE" checked>
            <label class="form-check-label" for="freshClone">
              Fresh Clone
              <span class="cursor-pointer custom-tooltip">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span class="tooltiptext">This will create a new campaign with the same targeting options of the original campaign, but it will not preserve blocked publishers/sections or other existing optimisation.</span>
              </span>
              </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="cloneMode" id="exactClone" value="EXACT_CLONE">
            <label class="form-check-label" for="exactClone">
              Exact Clone
              <span class="cursor-pointer custom-tooltip">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span class="tooltiptext">This will create an exact copy of the original campaign, including the list of blocked publishers/sections and any other existing optimisation on the original campaign.</span>
              </span>
            </label>
          </div>
        </div>
        `;

        const htmlForm = `
        <div class="form-group clone-campaign-form">
          ${rules.length ? htmlEnableRulesCheckbox : ''}
          ${enableCloneMode ? htmlEnableCloneMode : ''}
        </div>
        `;

        const swal = await this.$swal({
          title: 'Do you want to clone this campaign?',
          icon: 'info',
          html: htmlForm,
          // allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          customClass: { popup: 'clone-campaign-popup' },
          preConfirm: () => {
            const cloneWithRules = document.getElementById('cloneWithRules');
            const freshClone = document.getElementById('freshClone');
            const exactClone = document.getElementById('exactClone');

            const result = {};
            if (cloneWithRules) result.cloneWithRules = cloneWithRules.checked;
            if (freshClone && exactClone) {
              if (freshClone.checked) {
                result.cloneMode = freshClone.value;
              } else if (exactClone.checked) {
                result.cloneMode = exactClone.value;
              }
            }
            return result;
          },
        });
        if (swal.value) {
          if (swal.value.cloneWithRules) to.query.rules = rules;
          if (swal.value.cloneMode) to.query.cloneMode = swal.value.cloneMode;
          this.$router.push(to);
        }
      } else {
        this.$router.push(to);
      }
    },
    $_smartMaxCpcMaxLimit(avgCpc, cpc) {
      return () => {
        if (avgCpc) {
          return parseFloat(2 * parseFloat(avgCpc));
        } if (cpc) {
          return parseFloat(2 * parseFloat(cpc));
        }
        return this.maxCpcLimit;
      };
    },
  },
};
