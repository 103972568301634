<template>
  <system-activity-base
    :uri="uri"
    :campaigns-id="campaignsId"
    :campaigns-filter="campaignsFilter"
    :date-range="dateRange"
    :config-file="config"
    :tab="tab"
    :timezone="timezone"
    :showUTCtime="showUTCtime"
  />
</template>

<script>
import SystemActivityBase from '@sh/components/SystemActivity/SystemActivityBase';
import config from './config';

export default {
  name: 'SystemActivity',
  components: {
    SystemActivityBase,
  },
  props: {
    uri: { type: Boolean },
    campaignsId: { type: Array },
    campaignsFilter: { type: Boolean },
    dateRange: { type: Object },
    tab: { type: Boolean },
    timezone: String,
    showUTCtime: Boolean,
  },
  data() {
    return {
      config,
    };
  },
};
</script>
