const tabsMap = {
  widgets: {
    name: 'Widgets',
    alias: 'Widgets',
    component: 'Widgets',
    types: ['RevContent', 'Taboola', 'ContentAd', 'Mgid', 'Outbrain', 'Adskeeper', 'Gemini', 'VoluumDSP', 'Runative', 'ActiveRevenue', 'GoogleAds', 'YahooDSP', 'MediaGo', 'Facebook'],
    timezone: true,
  },
  contents: {
    name: 'Contents',
    alias: 'Contents',
    component: 'Contents',
    types: ['MediaGo', 'AmazonDisplay', 'AmazonDSP', 'Facebook', 'RevContent', 'Taboola', 'ContentAd', 'Mgid', 'Outbrain', 'Adskeeper', 'Gemini', 'YahooDSP', 'VoluumDSP', 'Runative', 'ActiveRevenue', 'TikTok', 'GoogleAds'],
    timezone: true,
  },
  targets: {
    name: 'Targets',
    alias: 'Targets',
    component: 'Targets',
    types: ['AmazonDisplay', 'AmazonDSP', 'GoogleAds'],
    timezone: true,
  },
  sites: {
    name: 'Sites',
    alias: 'Sites',
    component: 'Sites',
    types: ['VoluumDSP', 'ActiveRevenue'],
    timezone: true,
  },
  exchanges: {
    name: 'Exchanges',
    alias: 'Exchanges',
    component: 'Exchanges',
    types: ['AmazonDSP', 'VoluumDSP', 'YahooDSP', 'Facebook'],
    timezone: true,
  },
  sections: {
    name: 'Sections',
    alias: 'Sections',
    component: 'Sections',
    types: ['Outbrain'],
    timezone: true,
  },
  adgroups: {
    name: 'AdGroups',
    alias: 'AdGroups',
    component: 'AdGroups',
    types: ['AmazonDisplay', 'AmazonDSP', 'Facebook', 'Gemini', 'YahooDSP', 'TikTok', 'GoogleAds'],
    timezone: true,
  },
  assetgroups: {
    name: 'Asset Group (All Time Performance)',
    alias: 'Asset Group',
    component: 'AdGroups',
    types: ['GoogleAds'],
    timezone: true,
  },
  spots: {
    name: 'Spots',
    alias: 'Spots',
    component: 'Spots',
    types: ['Runative'],
    timezone: true,
  },
  domains: {
    name: 'Domains',
    alias: 'Domains',
    component: 'Domains',
    types: ['AmazonDSP', 'Gemini', 'YahooDSP', 'Facebook', 'GoogleAds'],
    timezone: true,
  },
  rules: {
    name: 'Rules',
    alias: 'Rules',
    component: 'Rules',
    types: ['MediaGo', 'AmazonDSP', 'RevContent', 'Taboola', 'ContentAd', 'Mgid', 'Outbrain', 'Adskeeper', 'Gemini', 'VoluumDSP', 'Runative', 'ActiveRevenue', 'Facebook', 'AmazonDisplay', 'TikTok', 'GoogleAds'],
    timezone: false,
  },
  trackerview: {
    name: 'Tracker View',
    alias: 'Tracker View',
    component: 'TrackerView',
    types: ['Thrive', 'CloudThrive', 'Voluum', 'Binom'],
    timezone: true,
  },
  performance: {
    name: 'Performance',
    alias: 'Performance',
    component: 'Performance',
    types: ['AmazonDisplay', 'AmazonDSP', 'RevContent', 'Taboola', 'ContentAd', 'Mgid', 'Outbrain', 'Adskeeper', 'Gemini', 'YahooDSP', 'VoluumDSP', 'Runative', 'ActiveRevenue', 'Facebook', 'MediaGo', 'TikTok', 'GoogleAds'],
    timezone: true,
  },
  logs: {
    name: 'Logs',
    alias: 'Logs',
    component: 'SystemActivityCampaign',
    types: ['AmazonDisplay', 'AmazonDSP', 'RevContent', 'Taboola', 'ContentAd', 'Mgid', 'MediaGo', 'Outbrain', 'Adskeeper', 'Gemini', 'YahooDSP', 'VoluumDSP', 'Runative', 'ActiveRevenue', 'Facebook', 'TikTok', 'GoogleAds'],
    timezone: true,
  },
};


// Tabs that will be displayed by type of campaign
const tabsByType = {
  GoogleAds: {
    PERFORMANCE_MAX: ['assetgroups', 'rules', 'trackerview', 'performance', 'logs'],
    SEARCH: ['widgets', 'contents', 'adgroups', 'targets', 'rules', 'trackerview', 'performance', 'logs'],
    DISPLAY: ['contents', 'adgroups', 'domains', 'rules', 'trackerview', 'performance', 'logs'],
    DISCOVERY: ['contents', 'adgroups', 'domains', 'rules', 'trackerview', 'performance', 'logs'],
    VIDEO: ['contents', 'adgroups', 'domains', 'rules', 'trackerview', 'performance', 'logs'],
    SHOPPING: ['contents', 'adgroups', 'domains', 'rules', 'trackerview', 'performance', 'logs'],
  },
};

const campaignTypeMap = {
  GoogleAds: 'advertisingChannelType',
};


Object.entries(tabsMap).forEach(([key, tab]) => { tab.id = key; });

const tabsOrderMapping = {
  default: [
    tabsMap.widgets,
    tabsMap.contents,
    tabsMap.targets,
    tabsMap.sites,
    tabsMap.exchanges,
    tabsMap.sections,
    tabsMap.adgroups,
    tabsMap.assetgroups,
    tabsMap.spots,
    tabsMap.domains,
    tabsMap.rules,
    tabsMap.trackerview,
    tabsMap.performance,
    tabsMap.logs,
  ],
  Facebook: [
    tabsMap.adgroups,
    tabsMap.contents,
    tabsMap.widgets,
    tabsMap.domains,
    tabsMap.exchanges,
    tabsMap.rules,
    tabsMap.trackerview,
    tabsMap.performance,
    tabsMap.logs,
  ],
};

const tabRenaming = {
  Facebook: {
    widgets: 'Placements',
    domains: 'Devices',
    adgroups: 'AdSets',
    contents: 'Ads',
    exchanges: 'Geos',
    widget: {
      upper: 'Placement',
      lower: 'placement',
    },
  },
  TikTok: {
    contents: 'Ads',
  },
  GoogleAds: {
    contents: 'Ads',
    widgets: 'Keywords',
    targets: 'Search Terms',
    domains: 'Placements',
    widget: {
      upper: 'Keyword',
      lower: 'keyword',
    },
  },
  AmazonDSP: {
    adgroups: 'Line Items',
    contents: 'Creatives',
    targets: 'Deals',
    exchanges: 'Supply sources',
  },
  YahooDSP: {
    adgroups: 'Line Items',
    contents: 'Creatives',
    widgets: 'Packages',
  },
  ActiveRevenue: {
    widgets: 'Apps',
  },
  MediaGo: {
    widgets: 'Sites',
  },
  Taboola: {
    contents: 'Ads',
    widgets: 'Sites',
  },
  Outbrain: {
    contents: 'Ads',
    widgets: 'Publishers',
    widget: {
      lower: 'publisher',
      upper: 'Publisher',
    },
  },
};

const getAvailableTabs = (campaign, trafficSourceAccount, trackerAccount) => {
  const filteredTabs = [];
  let tabs = tabsOrderMapping[campaign.traffic_source_unique_name] || tabsOrderMapping.default;
  if (tabsByType[campaign.traffic_source_unique_name]) {
    const trafficSourceUniqueName = campaign.traffic_source_unique_name;
    const campaignType = campaignTypeMap[trafficSourceUniqueName];
    const selectedCampaignType = campaign[campaignType];
    tabs = tabs.filter((tab) => tabsByType[trafficSourceUniqueName][selectedCampaignType].includes(tab.id)) || tabsOrderMapping.default;
  }
  tabs.forEach((_tab) => {
    const tab = { ..._tab };
    // ActiveRevenue, Apps have replaced the widgets
    tab.name = tabRenaming[campaign.traffic_source_unique_name]?.[tab.id] || tab.name;
    tab.alias = tabRenaming[campaign.traffic_source_unique_name]?.[tab.id] || tab.alias;
    if (tab.id !== 'trackerview') {
      // Filter by Traffic Sources
      if (tab.types.includes(trafficSourceAccount.type.uniqueName)) filteredTabs.push(tab);
    } else if (trackerAccount && tab.types.includes(trackerAccount.type.uniqueName)) filteredTabs.push(tab);
  });
  return filteredTabs;
};

const getTabRenaming = (tab, trafficSourceUniqueName) => tabRenaming[trafficSourceUniqueName]?.[tab];


const getAdGroupTabName = (trafficSourceAccount) => {
  const name = tabRenaming?.[trafficSourceAccount]?.adgroups || 'Ad Group';
  // camel case to snake
  let camelCaseToSpace = name.replace(/([A-Z])/g, ' $1');
  camelCaseToSpace = camelCaseToSpace.charAt(0).toUpperCase() + camelCaseToSpace.slice(1);
  // plural to singular, might need fixes based on specific case :)
  return camelCaseToSpace.replace(/s\s*$/, '');
};

const editableFields = {
  Taboola: [
    { name: 'Budget', key: 'budget' },
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'Bid', key: 'bid' },
  ],
  RevContent: [
    { name: 'Budget', key: 'budget' },
    { name: 'Bid', key: 'bid' },
  ],
  ContentAd: [
    { name: 'Budget', key: 'budget' },
    { name: 'Desktop Bid', key: 'cpc_desktop' },
    { name: 'Mobile Bid', key: 'cpc_mobile' },
  ],
  Mgid: [
    { name: 'Budget', key: 'budget' },
    { name: 'Daily Budget', key: 'daily_budget' },
  ],
  Outbrain: [
    { name: 'Budget', key: 'budget' },
    { name: 'Bid', key: 'bid' },
  ],
  Adskeeper: [
    { name: 'Total Budget', key: 'budget' },
    { name: 'Daily Budget', key: 'daily_budget' },
  ],
  Gemini: [
    { name: 'Budget', key: 'budget' },
  ],
  VoluumDSP: [
    { name: 'Budget', key: 'budget' },
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'CPM', key: 'ecpm' },
  ],
  Runative: [
    { name: 'Budget', key: 'budget' },
    { name: 'CPC', key: 'bid' },
  ],
  ActiveRevenue: [
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'CPC', key: 'bid' },
  ],
  Facebook: [
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'Lifetime Budget', key: 'budget' },
  ],
  AmazonDisplay: [
    { name: 'Daily Budget', key: 'daily_budget' },
  ],
  AmazonDSP: [
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'Lifetime Budget', key: 'budget' },
  ],
  YahooDSP: [
    { name: 'Budget', key: 'budget' },
    { name: 'Daily Budget', key: 'daily_budget' },
  ],
  TikTok: [
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'Lifetime Budget', key: 'budget' },
  ],
  GoogleAds: [
    { name: 'Budget', key: 'budget' },
  ],
  MediaGo: [
    { name: 'Daily Budget', key: 'daily_budget' },
    { name: 'Lifetime Budget', key: 'budget' },
  ],
};

module.exports = { getAvailableTabs, getAdGroupTabName, getTabRenaming, editableFields };
