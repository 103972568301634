
import OptimizerIcon from '@sh/components/Utils/OptimizerIcon.ts.vue';
import { MediaManagerHelpers } from '@sh/helpers';
import { MediaManager } from '@sh/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    OptimizerIcon,
  },
  props: {
    metricTypes: Array as PropType<MediaManager.MetricFilterTypes.DropDown[]>,
    index: Number,
    data: {
      type: Object as PropType<MediaManager.MetricFilterTypes.Row>,
      required: true,
    },
    scope: String,
    operators: Array as PropType<MediaManager.MetricFilterTypes.Operator[]>,
    isDeleteIcon: { type: Boolean, default: true },
  },
  computed: {
    metricOptions(): MediaManager.MetricFilterTypes.DropDown[] {
      return (
        this.metricTypes?.map((metric) => ({
          ...metric,
          displayName: MediaManagerHelpers.getMetricOptionName(metric),
        })) ?? []
      );
    },
    symbol(): string {
      switch (this.data?.metricType.symbol) {
        case MediaManager.MetricFilterTypes.SYMBOL.PERCENTAGE: {
          return '%';
        }
        case MediaManager.MetricFilterTypes.SYMBOL.MONEY: {
          return '$';
        }
        case MediaManager.MetricFilterTypes.SYMBOL.BYTES: {
          return 'Mb';
        }
        case MediaManager.MetricFilterTypes.SYMBOL.PX: {
          return 'px';
        }
        default:
          return '';
      }
    },
  },
  async created() {
    await this.onInput();
  },
  methods: {
    async isValid() {
      this.data.valid = await this.validateForm();
    },
    async validateForm() {
      try {
        const validation = await this.$validator.validateAll(this.scope);
        return !!validation;
      } catch {
        return false;
      }
    },
    hasError(name: string) {
      return this.$validator.errors.has(`${this.scope}.${name}`) ? false : null;
    },
    onDeleteClick() {
      this.$emit('onDelete');
    },
    async onInput(event?: KeyboardEvent | boolean) {
      if (this.data.value || !!event) {
        await this.isValid();
      }
    },
    onMetricTypeChange() {
      this.data.value = '';
      this.isValid();
    },
  },
});
